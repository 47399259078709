import React, { useEffect} from "react";
import { Col, Row,  Form, Button,Table} from "antd";
import { FormInstance } from "antd/lib/form";
import { connect } from "react-redux";
import * as actions from "./actions";
import { ReloadOutlined } from '@ant-design/icons';

interface Prop {
  getSystemHistory: Function;
  csus: any;
  csuMaintOptions: any,
}

interface SystemHistory {
  [key: string]: string;
 }
 export const DeviceHistory: React.FC<Prop> = (props) =>
{
   const formRef = React.createRef<FormInstance>();
   const { tableConfig={}, tableData } = props.csus.systemhistory || {}; 
   
  useEffect(() =>
  {
     props.getSystemHistory(null,null,null,props.csuMaintOptions.record.mastertag);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefresh=()=>
  {
     props.getSystemHistory(null,null,null,props.csuMaintOptions.record.mastertag);
  }
  const tableColumns = [
     {
       title: 'Hardware Id',
       dataIndex: 'hardwareId',
     },
     {
       title: 'Software Type',
       dataIndex: 'softwareType',
     },
     {
       title: 'version',
       dataIndex: 'version',
     },
     {
       title: "Status",
       dataIndex: "status",
     },
     {
       title: "Action",
       dataIndex: "action",
     },
     {
       title: "Updated At",
       dataIndex: "updatedAt",
     },
  ]
  return (
          <>
          <Form
                id="SoftwareHistory"
                ref={formRef}
                layout="vertical"
          >
                {(props.csus.csuList.csuMaint.systemhistory != null && props.csus.systemhistory != null) ? (
              <Row gutter={24}>
              <Col xs={24} lg={10}>
              <Button
                            data-id=".csu-history-refresh-button"
                    className="mt-10"
                    onClick={onRefresh}
                    type="default"
                    icon={<ReloadOutlined />}
              >
                            Refresh
              </Button>
              </Col>
              <Col span={24}>
                          {tableConfig && ( 
              <Table
                              className="styled-table"
                              data-id="controllers-data"
                              style={{ overflowX: 'auto' }}
                              {...tableConfig}
                              columns={tableColumns}
                              dataSource={tableData}
                              showSorterTooltip={false}
                            />
                          )}
                          {!tableConfig && (
                    <div><p>There is no History available for this Device at this moment.</p></div>
                                )}
                    </Col>
                    </Row>
                          ) : (
                    <div><p>There is no History available for this Device at this moment.</p></div>
                          )}
            </Form>
        </>
      );
};
const mapStateToProps = (state: any) => {
  return {
    context: state.contextReducer.context,
        csus: state.DeviceReducers.DeviceState.csus,
        selectedCSU: state.DeviceReducers.DeviceState.csus.selectedCSU,
        permissions: state.contextReducer.data,
        csuMaintOptions: state.DeviceReducers.DeviceState.csus.csuMaintOptions
  };
};
export default connect(mapStateToProps, {
  ...actions,
})(DeviceHistory);