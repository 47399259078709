import { Button, Card, Col, Divider, Drawer, Form, Input, Row, Select, Space, Tabs } from "antd";
import { FormInstance } from 'antd/lib/form';
import moment from "moment";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { formInit } from "../../../utils/apiHelpers/formHelpers";
import { appConfigs } from "../../../utils/configurations";
import * as actions from "./actions";

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

interface Prop {
    context: any,
    controllerMaintOptions: any,
    closeDrawer: Function,
    drawerTabChange: Function,
}

const ControllerMaint: React.FC<Prop> = (props) => {

    const formRef = React.createRef<FormInstance>();
   
    const onDrawerClose = () => {
        props.closeDrawer();
    };

    const onTabChange = (key: string) => {
        props.drawerTabChange(key);
    }

    useEffect(() => {

        //console.log(formRef.current)
        //console.log(props.controllerMaintOptions.record)
        if (formRef.current != null) {
            formRef.current?.resetFields();    
            formRef.current?.setFieldsValue({
                mode: props.controllerMaintOptions.record.id ? "update" : "add", 
                controllerId: props.controllerMaintOptions.record.id,
                name: props.controllerMaintOptions.record.name,
                make: props.controllerMaintOptions.record.make,
                model: props.controllerMaintOptions.record.model,
            });
        }
    
    }, [props.controllerMaintOptions])


    const onFinish = (values: any) => {

        formRef.current!.setFieldsValue({
            mode: props.controllerMaintOptions.controllerId ? "update" : "add", 
        });

        //console.log(formRef.current?.getFieldsValue())
    }
        
    return (
        <Drawer
            title={props.controllerMaintOptions.title}
            width={props.controllerMaintOptions.width}
            onClose={onDrawerClose}
            visible={props.controllerMaintOptions.visible}
            destroyOnClose={true}
            footer={
                <Space size="large" style={{ float: 'right' }}>
                    <Button type="default" onClick={onDrawerClose}>
                        {appConfigs.settings.drawers.closeButtonText}
                    </Button>

                    <Button data-id="controllers-maint-submit-button" type="primary" form="controllerMaintOptionsInfoForm" key="submit" htmlType="submit">
                        {appConfigs.settings.form.submitButtonText}
                    </Button>
                    
                </Space>
            }
        >
            {
                <Tabs activeKey={props.controllerMaintOptions.activeTab} size="large" type="card" onChange={onTabChange}>                    
                    <TabPane tab={props.controllerMaintOptions.tabs[0].title} key="1" disabled={props.controllerMaintOptions.tabs[0].disabled}>
                        <Form
                            id="controllerMaintOptionsInfoForm"
                            ref={formRef}
                            layout="vertical"
                            onFinish={onFinish}
                        >       
                            <Row gutter={8}>
                                <Col span={24}>
                                    <Form.Item name="controllerId" style={{ display: 'none' }} >
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Form.Item name="mode" style={{ display: 'none' }} >
                                        <Input type="hidden" />
                                    </Form.Item>
                                </Col> 
                            </Row>   
                          
                            <Row gutter={8}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Device Name"
                                        name="name"    
                                        shouldUpdate={true}                                 
                                        rules={[
                                            { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                                        ]}
                                    >
                                        <Input maxLength={255} disabled={props.controllerMaintOptions.record.status == 'inactive'} />
                                    </Form.Item>
                                </Col>                        
                            </Row>    
                            
                            <Row gutter={8}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Make"
                                        name="make"
                                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >
                                        <Input maxLength={255} disabled={props.controllerMaintOptions.record.status == 'inactive'} />
                                    </Form.Item>
                                </Col> 
                            

                                <Col span={12}>
                                    <Form.Item
                                        label="Model"
                                        name="model"                                 
                                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >
                                        <Input maxLength={255} disabled={props.controllerMaintOptions.record.status == 'inactive'} />
                                    </Form.Item>
                                </Col>

                            </Row>    


                            <Row gutter={8}>
                        
                                <Col span={24}>
                                    <Form.Item
                                        label="Description"
                                        name="description"                                 
                                        rules={[{ required: false, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >
                                        <TextArea rows={4} disabled={props.controllerMaintOptions.record.status == 'inactive'} />
                                    </Form.Item>
                                </Col> 
                            
                            </Row>

                        </Form>
                    </TabPane> 
                </Tabs>   
            }         
        </Drawer>
    );
}

const mapStateToProps = (state: any) => {
    return {
        context: state.contextReducer.context,
        controllerMaintOptions: state.controllersReducers.controllersState.controllers.controllerMaintOptions
    };
};

export default connect(
    mapStateToProps,
    actions
)(ControllerMaint);