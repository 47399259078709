import React, { useEffect,  useState } from "react";

import { Space, Input, Switch, Tooltip, Affix, Button } from "antd";
import Icon, { SyncOutlined, BorderOuterOutlined, SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";

import FiltersBar from "../filtersBar/FiltersBar";
import { Filters } from "../filtersBar/models";
import filterAssets from "../filtersBar/filter-assets";

import AssetList from "../assetList/AssetList";
import { appConfigs } from "../../../../utils/configurations";

import { connect } from "react-redux";
import * as actions from "../../actions";
import { MapActions } from "../../../../sharedComponents/GoogleMap";
import { ReactComponent as maximizeIcon } from "../../../../assets/images/maximize.svg";

import "../../fleetOverview.css";

const { Search } = Input;

interface Props {
    fleetOverviewState: any,
    getFleetOverview: Function,
    assetSearchSearch: Function,
    assetSearchReset: Function,
    assetZoomToFitFilter: Function,
    setAssetSearchValue: Function,
    setAssetSearchFilters: Function,
    setPreSearchAssetsList: Function,
    mapsAssetDrawerClose: Function,
    context: any,
    mapActions?: MapActions,
    map?: any 
}

const AssetSearch = (props:Props) => {
    
    const [container, setContainer] = useState<any>(null);
    const [allAssets, setAllAssets] = useState<any>(props.fleetOverviewState.preSearchAssetList);
    const [searchText, setSearchText] = useState<string>(props.fleetOverviewState.assetSearchValue);
    const [, setStartPage] = useState<number>(1);
    const [globalSearch, setGlobalSearch] = useState<boolean>(false);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
    const [loading, setLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<Filters>({
        critical: false,
        warning: false,
        info: false,
        dm1: false,
        online: false,
        offline: false,
        asset_down: false
    });
    const [mapActions, setMapActions] = useState<MapActions>();
    
    useEffect(() => {
        let isMounted = true;         

        setTimeout(function () {
            if (isMounted) {
                setLoading(false);
            }
        }, 250);   
        
        setTypingTimeout(
            setTimeout(function () {
                props.setAssetSearchValue(searchText);
                setStartPage(1);
                if (globalSearch) {
                    props.getFleetOverview(null, null, "global-search", searchText, null);
                }
                else {
                    props.assetSearchSearch(searchText);
                }
            }, globalSearch ? appConfigs.app.fleetOverview.assetSearchKeystrokeDelay : appConfigs.app.fleetOverview.assetSearchGlobalKeystrokeDelay)
        )   

        return () => { isMounted = false };                 
    }, [])

    const toggleFilters = (f: keyof Filters) => {
        setFilters({
            ...filters,
            [f]: !filters[f],
        });
    };

    const ZoomFitFiltered = (e: any) => {
        console.log('ZoomFitFiltered')
        console.log(props.fleetOverviewState, assetDrawer, filters)

        if (assetDrawer.assetSearch.assets.length > 0) {
            //Save current search value
            props.setAssetSearchValue(searchText);

            //Save current filter value
            props.setAssetSearchFilters(filters)
            
            // Object.keys(filters).map(filterKey => {
            //     filtersp[filterKey]
            // })

            // let assetToZoom = assetDrawer.assetSearch.assets.filter((asset:any) => {
            //     if(filters.critical && asset.recentCriticalCount > 0){
            //         return asset;
            //     } else if(filters.critical && asset.recentCriticalCount > 0){
            //         return asset;
            //     } else if(filters.critical && asset.recentWarningCount > 0){
            //         return asset;
            //     } else if(filters.critical && asset.recentInfoCount > 0){
            //         return asset;
            //     } else if(filters.critical && asset.recentDm1Count > 0){
            //         return asset;
            //     } else if(filters.critical && asset.connectionStatus == 'online'){
            //         return asset;
            //     } else if(filters.critical && asset.recentInfoCount == 'offline'){
            //         return asset;
            //     } else if(filters.critical && (JSON.stringify(asset.assetCustomFields).includes('asset_down') || JSON.stringify(asset.assetCustomFields).includes('active') )){
            //         return asset;
            //     } else if ()
            // })

            //filter markers
            props.assetZoomToFitFilter(assetDrawer.assetSearch.assets, props.mapActions)
            
            //zoom to fit filtered assets
            setTimeout(function () {
                props.map.setZoom(props.map.getZoom() + 1)

                let bounds = new google.maps.LatLngBounds();
                
                
                assetDrawer.assetSearch.assets.map((marker:any) => {
                    bounds.extend(new google.maps.LatLng(marker.position.latitude, marker.position.longitude));
                });

                props.map.fitBounds(bounds)

            }, 200);
        }

    }


    const onSearchChange = (e: any) => {

        if(e.target.value === ''){
            props.assetZoomToFitFilter(allAssets, props.mapActions)
            props.setAssetSearchValue('');
            props.setAssetSearchFilters({
                critical: false,
                warning: false,
                info: false,
                dm1: false,
                online: false,
                offline: false,
                asset_down: false
            })

            setFilters({
                critical: false,
                warning: false,
                info: false,
                dm1: false,
                online: false,
                offline: false,
                asset_down: false
            });
        }

        const searchText = e.target.value;
        
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setTypingTimeout(
            setTimeout(function () {
                setSearchText(searchText);
                setStartPage(1);
                if (globalSearch) {
                    props.getFleetOverview(null, null, "global-search", searchText, null);
                }
                else {
                    props.assetSearchSearch(searchText);
                }
            }, globalSearch ? appConfigs.app.fleetOverview.assetSearchKeystrokeDelay : appConfigs.app.fleetOverview.assetSearchGlobalKeystrokeDelay)
        )        
    }

    const onGlobalChange = (checked: boolean, e:any) => {
        setLoading(true);        
        setTimeout(function () {
            setGlobalSearch(checked);
            if (checked) {
                if ((searchText || "") !== "") {
                    props.getFleetOverview(getFleetOverviewSuccess, null, "global-search", searchText, null);
                }
                else {
                    props.assetSearchReset();
                    setLoading(false);
                }
            }
            else {
                props.assetSearchSearch(searchText);
                setLoading(false);
            }
        }, 250);
        
    }
    const getFleetOverviewSuccess = (assets:any) => {
        setLoading(false);
    }

    const { assetDrawer } = props.fleetOverviewState;

    return (
        
        <>
            <div data-id="map-asset-list"ref={setContainer}>  
            
                <Affix data-id="map-asset-list-affix" offsetTop={1} target={() => container}>
                    <div> 
                        <FiltersBar
                            assetCount={assetDrawer.cluster.assets.length}
                            filters={filters}
                            toggleFilters={toggleFilters}
                        />
                    
                        <div data-id="map-asset-list-header"> 
                            <div> 
                                <Space>
                                    <Search
                                        data-id="map-asset-list-search-field"
                                        key="tree-search-field"
                                        placeholder="Filter assets by name, master tag, or tags"
                                        size="middle"
                                        allowClear
                                        defaultValue={searchText}
                                        onChange={onSearchChange}
                                        className="search-field"
                                        suffix={
                                            <Tooltip 
                                                title="Zoom Map to Fit Filtered Assets" 
                                                placement="topRight"
                                            >
                                                <Icon onClick={ZoomFitFiltered} component={maximizeIcon} style={{ fontSize: 20, marginLeft: 3, marginRight: 1, height: '100%', borderLeft: '1px solid #b3b3b3', color: '#707070', cursor: 'pointer', paddingLeft: '4px' }} />
                                            </Tooltip>
                                        }
                                    /> 
                                    {/* //commented as part of ops-1694 */}
                                    {/* <Tooltip title="Search all assets"> 
                                        <Switch 
                                            checkedChildren="GLOBAL"
                                            unCheckedChildren="GLOBAL"
                                            onClick={onGlobalChange}
                                        /> 
                                    </Tooltip> */}
                                </Space>
                                
                            </div>
                        </div>
                    </div>
                </Affix>

                {
                    !loading &&
                    <AssetList 
                        context={"list-asset-search" + (globalSearch ? "-global" : "")}
                        assets={filterAssets(assetDrawer.assetSearch.assets, filters)} 
                        assetsAll={props.fleetOverviewState.fleet.filteredAssets} 
                        searchText={searchText}
                        filters={filters}
                        assetDrawerClose={props.mapsAssetDrawerClose}
                        mapActions={props.mapActions}
                    />
                }
                
            </div>
            
            {
                loading &&
                <div data-id="map-asset-list-loading">
                    <SyncOutlined spin className="pr-5"/>
                </div>
            }
        </>
    );
};

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,        
        fleetOverviewState: state.fleetOverviewReducers.fleetOverviewState
    };
};

export default connect(
    mapStateToProps,
    actions
)(AssetSearch);

React.memo(AssetSearch)
  