import React, { useState, useEffect, CSSProperties, useRef } from "react";
import { Card, Space, message, Popconfirm, Tooltip, Switch } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
  ReloadOutlined,
  FilterOutlined,
  DownloadOutlined,
  FundOutlined,
} from "@ant-design/icons";
import {
  Template,
  SignalDatapoint,
  SignalId,
  SignalCollectionId,
  WidgetSignalId,
  EventData,
  WidgetFilterSetting,
} from "./models";
import { DataStore, WidgetState } from "./state";
import { loadSignalsData } from "./api";
import { createWidget } from "./widgets";
import { constructRows } from "./widgets/common";
import "./WidgetContainer.css";
import { errorResponse } from "../../utils/apiHelpers/apiHelpers";
import tzlookup from "tz-lookup";
import * as Api from "./api";
import { WidgetFilterConfig } from "./WidgetFilterForm";
import usePolling from "../../lib/use-polling";
import { appConfigs } from "../../utils/configurations";
import {
  loadingIndicatorEnd,
  loadingIndicatorStart,
} from "../../utils/commonHelpers";
import { CSVLink } from "react-csv";
import { State } from "../Dashboard2/state";
import {
  prepareWidgetGPSEvents,
  useExportGPSEvents,
} from "./WidgetExportForm/useExportGPSEvents";
// import { EventSignals } from "../Dashboard/models";
// import { AnyARecord } from "dns";
// import { filter } from "@amcharts/amcharts4/.internal/core/utils/Iterator";
// import { EventSignals } from "../Dashboard/models";
// import { AnyARecord } from "dns";
// import { filter } from "@amcharts/amcharts4/.internal/core/utils/Iterator";
import { array, isNumber } from "@amcharts/amcharts4/core";
import WidgetHeaderNew from "./WidgetHeaderNew";
import moment, { Moment } from "moment";
import { getDistance } from "../../utils/commonHelpers";
import useGoogleMapsLib from "../../sharedComponents/GoogleMap/use-google-maps-lib";
import { AnyMap } from "immer/dist/internal";

interface WidgetControlProps {
  onEdit: () => void;
  onDelete: () => void;
  onFilter: () => void;
  onExport: () => void;
  userRole: string;
  isCreate: boolean;
  isRead: boolean;
  isUpdate: boolean;
  isDelete: boolean;
  assetDetails?: any;
  context: any;
  SignalRInitialData: any;
  //isIqanKey: boolean; //ops-2839: Remove the iqanconnect key permission check
}

interface WidgetHeaderProps {
  onRefresh: () => void;
  onFilter: () => void;
  onExport: () => void;
  isLoading: boolean;
  widgetState: WidgetState;
  state: State;
  data: DataStore;
  rightYAxisData: DataStore;
  userRole: string;
  isCreate: boolean;
  isRead: boolean;
  isUpdate: boolean;
  isDelete: boolean;
  newWidgetHeader: boolean;
  setNewWidgetHeader: Function;
  isFilteredTransients: boolean;
  setFilteredTransients: any;
}

const WidgetHeader: React.FC<WidgetControlProps & WidgetHeaderProps> = (
  props
) => {
  const [sysTimescaleSetting, setSysTimescaleSetting] = useState<any>(
    props.context?.systemSettings?.filter(
      (sysSetting: any) =>
        sysSetting.settingName === "Enable Timescale Search Mode"
    )[0]
  );

  function confirm(e: any) {
    props.onDelete();
  }
  function switchSearchMode(e: any) {
    props.setNewWidgetHeader(!props.newWidgetHeader);
  }
  function cancel(e: any) {}
  const { type, title } = props.widgetState;

  function includeFilterToggle(
    filterSignals: String[],
    filterUnits: String[]
  ): Boolean {
    const { data, assetDetails } = props;
    const { datapoints } = assetDetails;
    const signalIds = data.keys();
    const collections = JSON.parse(datapoints);

    let include = false;
    let signal = signalIds.next().value;
    while (signal) {
      collections?.forEach((c: any) =>
        c.values?.forEach((v: any) => {
          if (
            v._id == signal &&
            filterSignals.includes(v.label.toLowerCase())
          ) {
            //this will allow battery to all but lng/lat only to parker
            if (
              !(
                v.label.toLowerCase() == "latitude" ||
                v.label.toLowerCase() == "longitude"
              ) &&
              filterUnits.includes(v.unit)
            ) {
              include = true;
            } else if (
              props.context.appContext.orgId ==
              "c6adcb40-be92-11e6-9ed6-a5bc9cb5279b"
            ) {
              include = true;
            }
          }
        })
      );

      signal = signalIds.next().value;
    }
    return include;
  }

  const csvLink = useRef<any>(null);
  const [
    dataForDownload,
    setDataForDownload,
    bDownloadReady,
    setDownloadReady,
    exportHeader,
    setExportHeader,
    exportFileName,
    setExportFileName,
  ] = useExportGPSEvents(csvLink);

  const onCSVGenerate = () => {
    loadingIndicatorStart();

    const csvExportDetails = prepareWidgetGPSEvents(props.data, props.state);

    setDataForDownload(csvExportDetails.eventArray);
    setExportHeader(csvExportDetails.eventHeader);
    setExportFileName(
      `${props.state.asset.name}_${props.widgetState.title}.csv`
    );
    setDownloadReady(true);

    loadingIndicatorEnd();
  };

  return (
    <div style={{ width: "100%" }}>
      <Space>
        {props.isUpdate ? (
          <Tooltip title="Edit Widget Properties">
            <EditOutlined onClick={props.onEdit} />
          </Tooltip>
        ) : (
          ""
        )}
        {props.isDelete ? (
          <Popconfirm
            title="Are you sure to delete this widget?"
            onConfirm={confirm}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete Widget">
              <DeleteOutlined />
            </Tooltip>
          </Popconfirm>
        ) : (
          ""
        )}

        {props.isLoading ? (
          <Tooltip title="Refresh Widget Data (Disabled)">
            <LoadingOutlined />
          </Tooltip>
        ) : (
          <Tooltip title="Refresh Widget Data">
            <ReloadOutlined onClick={props.onRefresh} />
          </Tooltip>
        )}
        {/* todo add || type === "chat* to support chat filter, export */}
        {type === "line" ||
        type === "map" ||
        type === "map events" ||
        type === "clusterbar" ? (
          <>
            <Tooltip title="Specify Data Time Range">
              <FilterOutlined onClick={props.onFilter} />
            </Tooltip>

            {/* OPS-3036 - adding new tooltip for filtration */}
            {includeFilterToggle(
              ["battery level", "latitude", "longitude", "battery kwh used"],
              ["%", "kWh"]
            ) ? (
              <Tooltip title="Toggle to Include/Exclude Outlier Data">
                <Switch
                  key="layoutView"
                  checkedChildren="On"
                  unCheckedChildren="Off"
                  onChange={() =>
                    props.setFilteredTransients(!props.isFilteredTransients)
                  }
                />
              </Tooltip>
            ) : (
              ""
            )}

            {props.isCreate ||
            props.isUpdate ||
            props.userRole.toLowerCase() === "barko_guest" ? (
              props.widgetState.type.includes("map") ? (
                <>
                  <Tooltip
                    title={
                      props.isFilteredTransients
                        ? "Must toggle filter off to export data"
                        : "Download row data in .csv format"
                    }
                  >
                    <DownloadOutlined
                      style={props.isFilteredTransients ? { color: "red" } : {}}
                      onClick={
                        props.isFilteredTransients ? () => "" : onCSVGenerate
                      }
                    />
                  </Tooltip>
                  {!props.isFilteredTransients ? (
                    <CSVLink
                      data={dataForDownload}
                      headers={exportHeader}
                      filename={exportFileName}
                      className="hidden"
                      ref={csvLink}
                      target="_blank"
                      uFEFF={true}
                    />
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  <Tooltip
                    title={
                      props.isFilteredTransients
                        ? "Must toggle filter off to export data"
                        : "Download row data in .csv format"
                    }
                  >
                    <DownloadOutlined
                      style={props.isFilteredTransients ? { color: "red" } : {}}
                      onClick={
                        props.isFilteredTransients ? () => "" : props.onExport
                      }
                    />
                  </Tooltip>
                </>
              )
            ) : (
              ""
            )}

            {sysTimescaleSetting &&
              (sysTimescaleSetting.orgsList == null
                ? true
                : sysTimescaleSetting.orgsList
                    .replaceAll('"', "")
                    .slice(1, -1)
                    .split(",")
                    .includes(props.context.appContext.orgId)) && (
                <Popconfirm
                  title={
                    <>
                      <span style={{ marginLeft: "-20px" }}>
                        Switch to new search mode?{" "}
                      </span>
                      <br />
                      <span
                        style={{
                          fontSize: "12px",
                          color: "#B9B9B9",
                          marginLeft: "-20px",
                        }}
                      >
                        Note: You may switch back to the classic search mode at
                        anytime!
                      </span>
                    </>
                  }
                  icon={<div style={{ display: "none" }}></div>}
                  onConfirm={switchSearchMode}
                  onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip
                    mouseEnterDelay={1}
                    placement="bottom"
                    title="Switch To New Search Mode. This allows you to select longer data ranges and set different time granularities. You may switch back to the classic view at anytime!"
                  >
                    <FundOutlined
                      className="wiggle"
                      style={{ color: "#00D8AA" }}
                    />
                  </Tooltip>
                </Popconfirm>
              )}
          </>
        ) : (
          <></>
        )}
      </Space>
    </div>
  );
};

export interface Props extends WidgetControlProps {
  widgetState: WidgetState;
  isResizable: boolean;
  template: Template;
  assetId: string;
  state: State;
  autoRefresh?: number;
  assetDetails?: any;
  dashboardId?:any;
  bgimageurl?:any;
  isNewDashboard?:any;
}

const WidgetContainer: React.FC<Props> = (props: Props): any => {
  useGoogleMapsLib();
  const { template, assetId, state, assetDetails,dashboardId,bgimageurl,isNewDashboard  } = props;
  let { widgetState } = props;
  const { signals, type, rightYAxisSignals } = widgetState;

  let sysSetting = JSON.parse(props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'Enable Timescale Search Mode')[0].settingSpecs)

  const [data, setData] = useState<DataStore>(
    new Map<SignalId, SignalDatapoint[]>()
  );
  const [rightYAxisData, setRightAxisData] = useState<DataStore>(
    new Map<SignalId, SignalDatapoint[]>()
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [zoomedStartEndTime, setZoomedStartEndTime] = useState<any>("");
  const [widgetFilterSetting, setWidgetFilterSetting] = useState<WidgetFilterSetting>({aggregate: 'Raw Data', granularity: 'Raw Data'});
  const [refreshTime, setRefreshTime] = useState<number>(0);
  const [error, setError] = useState<string>();
  const [originalWidgetData, setOriginalWidgetData] = useState<any>();
  const [timezone, setTimezone] = useState<any>(state.timezone.value);
  const [signalArray, setSignalArray] = useState<WidgetSignalId[]>(signals);
  const [newWidgetHeader, setNewWidgetHeader] = useState<boolean>((['line', 'map', 'map events', 'clusterbar'].includes(type)) ? sysSetting.defaultView ? true : false : false);
  // const [newWidgetHeader, setNewWidgetHeader] = useState<boolean>(false);
  const [refreshNewWidgetData, setRefreshNewWidgetData] = useState<boolean>(false);
  const [filterTransients, setFilterTransients] = useState<boolean>(false);
  const [originalDataValue, setOriginalDataValue] = useState<any>();
  const [initiallyLoaded, setInitiallyLoaded] = useState<Boolean>(false);

  useEffect(() => {
    if (initiallyLoaded) {
      setRefreshTime(Date.now());
    }
  }, [props.autoRefresh]);

  const onRefresh = () => {
    setRefreshTime(Date.now());
  };

  const {
    datapoint,
    startDate,
    endDate,
    startTime,
    endTime,
  } = props.widgetState;
  const loadWidgets = () => {
    setLoading(true);
    loadData(
      signals,
      {
        startDate: startDate,
        startTime: startTime,
        endDate: endDate,
        endTime: endTime,
        datapoint: datapoint,
      },
      false
    );
  };

  //commented the code for auto refersh as requested in sprint 5
  // usePolling(
  //   () => {
  //     loadWidgets();
  //   },
  //   appConfigs.app.assetOverview.autoRefreshPollingRate
  // );
  //filter
  const filterErrors = (response: any) => {
    const responseFilter = response.filter((res: any) => res instanceof Error);
    if (responseFilter.length > 0) {
      responseFilter.forEach((error: any) => {
        let errorStatus: number = !error.response
          ? 999
          : error.response.status || 999;
        if (errorStatus === 401) {
          errorResponse(null, error);
        }
      });
    }
  };

  const convertTimestamp = (data: any) => {
    if (
      state.timezone.coordinates.latitude &&
      state.timezone.coordinates.latitude
    ) {
      let locale_date_str: any;
      data.forEach((signalData: any, SignalID: any) => {
        signalData.filter((ele: any) => {
          locale_date_str = new Date(ele.timestamp).toLocaleString("en-US", {
            timeZone: tzlookup(
              state.timezone.coordinates.latitude,
              state.timezone.coordinates.longitude
            ),
          });
          locale_date_str = new Date(locale_date_str);
          if (locale_date_str) {
            ele.timestamp = locale_date_str;
          }
        });
      });
      return data;
    } else {
      return data;
    }
  };

  const formatData = (response: any, signals: WidgetSignalId[]) => {
    setOriginalWidgetData(response);
    const dataSet = constructRows(response, signals);
    if (
      state.timezone.value == "asset" &&
      state.timezone.isGpsSignalAvailable
    ) {
      let convertedData = convertTimestamp(dataSet);
      setData(convertedData);
    } else {
      setData(dataSet);
    }
    setLoading(false);
  };

  const formatRightYAxisData = (response: any, signals: WidgetSignalId[]) => {
    setOriginalWidgetData(response);
    const dataSet = constructRows(response, signals);
    if (
      state.timezone.value == "asset" &&
      state.timezone.isGpsSignalAvailable
    ) {
      let convertedData = convertTimestamp(dataSet);
      setRightAxisData(convertedData);
    } else {
      setRightAxisData(dataSet);
    }
    setLoading(false);
  };

  const loadData = async (
    signals: WidgetSignalId[],
    filterConfig: WidgetFilterConfig,
    isFormatRightYAxisData: boolean = false
  ) => {
    const C2DMessageCommandArray = [];

    if (newWidgetHeader) {
      // Do nothing since API calls will be done from within widget header
    } else {
      const datapointArray: SignalCollectionId[] = [];
      //find the signal collection id to make an api request
      signals.forEach((si) => {
        const datapoint = template.signalCollections.filter((s) =>
          s.signals.find((sg) => sg.id === (si.id ? si.id : si))
        )[0];
        if (datapoint && datapoint["id"]) {
          datapointArray.push(datapoint.id);
        }
      });

      //find the signal collection id to make an api request
      if (type === "chat") {
        signals.forEach((si) => {
          const c2dTextCommand = template.signalCollections.filter((c) =>
            c.signals.find(
              (sg) => sg.id === (si.id ? si.id : si) && c.type === "Command"
            )
          )[0];

          if (c2dTextCommand && c2dTextCommand["id"]) {
            C2DMessageCommandArray.push(c2dTextCommand.id);
          }
        });
      }
      //stop the repeataion of datapoints
      const uniqsignalCollectionId = datapointArray
        .filter((elem, pos) => {
          return datapointArray.indexOf(elem) === pos;
        })
        .filter((x) => !!x);
      if (uniqsignalCollectionId.length > 0) {
        //filterConfig.filter = filterTransients; // OPS-3036

        Api.loadSignalsData(assetId, uniqsignalCollectionId, filterConfig, type) //filterTransients
          .then((resp: any) => {
            template.signalCollections.filter((sc) => {
              const splitSignalCollection = (apiResponse: any) => {
                if (!(apiResponse.data.length == 0 || apiResponse.data == "")) {
                  let collection = apiResponse.data.find(
                    (val: any) => val.signalCollectionID === sc.id
                  );
                  if (collection) {
                    apiResponse.data.filter((val: any) => {
                      filterSignalData(val);
                    });
                  }
                }
              };
              const filterSignalData = (resData: any) => {
                if (sc.id == resData.signalCollectionID) {
                  sc.signals.filter((sunits) => {
                    if (sunits.units === "°C" || sunits.units === "°F") {
                      if (
                        state.temperature.value != sunits.units ||
                        state.temperature.value
                      ) {
                        resData.signals.filter((signalData: any) => {
                          if (sunits.id === signalData._id) {
                            let data = isNumber(signalData.value)
                              ? signalData.value
                              : null;
                            if (data != null) {
                              if (
                                sunits.units === "°F" &&
                                state.temperature.value != null &&
                                state.temperature.value === "°C"
                              ) {
                                data = ((data - 32) * 5) / 9;
                              }
                              if (
                                sunits.units === "°C" &&
                                (state.temperature.value === "°F" ||
                                  state.temperature.value == null ||
                                  state.temperature.value == undefined)
                              ) {
                                data = (data * 9) / 5 + 32;
                              }
                              signalData.value = data;
                            }
                          }
                        });
                      }
                    }
                  });
                }
              };
              resp.filter((res: any) => {
                if (res.data.signalCollectionID == undefined) {
                  splitSignalCollection(res);
                } else {
                  filterSignalData(res.data);
                }
              });
            });
            filterErrors(resp);
            if (isFormatRightYAxisData) {
              formatRightYAxisData(resp, signals);
            } else {
              formatData(resp, signals);
            }
          })
          .catch((err) => {
            errorResponse(null, err);
            setError(err.message);
            setLoading(false);
          });
      } else {
        setLoading(false);
        setError("500");
      }

      if (C2DMessageCommandArray.length > 0) {
        Api.loadC2DMessageData(assetId, filterConfig)
          .then((c2dResp: any) => {
            assetDetails.c2dMessages = c2dResp;
            setLoading(false);
          })
          .catch((err1) => {
            errorResponse(null, err1);
            setError(err1.message);
            setLoading(false);
          });
      }
    }
  }; // loadData

  // function checkIsIqanKey(e:EventSignals) {
  //   return (e.name === 'IQAN Connect Key');
  // }

  function compare(array1: WidgetSignalId[], array2: WidgetSignalId[]) {
    const newArray1 = [...array1];
    const newArray2 = [...array2];
    return (
      newArray1.length === newArray2.length &&
      newArray1.sort().every(function (value, index) {
        return value.id === newArray2.sort()[index].id;
      })
    );
  }

  const updateSignalArrayState = (signals: WidgetSignalId[]) => {
    const a = compare(signals, signalArray);
    if (!a) {
      setSignalArray(signals);
    }
  };

  updateSignalArrayState(signals);

  const calcMedianWindow = (window: any) => {
    const mid = Math.floor(window.length / 2);
    const nums = [...window].sort((a, b) => a.value - b.value);
    return Math.abs(nums[mid].value);
  };

  const calcMedian = (arr: any) => {
    const mid = Math.floor(arr.length / 2),
      nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
  };

  const windowFilter = (signalData: any, transientIndices: any) => {
    const windowPercentage = 0.05;
    const k = Math.ceil(signalData.length * windowPercentage);
    const N = signalData.length;

    let upper: number[] = [];
    let lower: number[] = [];
    for (let i = 0; i < N; i++) {
      const windowLowerBound = Math.max(i - k, 0);
      const windowUpperBound = Math.min(i + k, N);
      let leftWindow = signalData.slice(windowLowerBound, i);
      let rightWindow = signalData.slice(i + 1, windowUpperBound);
      let window = [...leftWindow, ...rightWindow];

      // filter window for points that are outside the max time difference
      const maxTimeDifference = 60 * 5 * 1000; // 5 minutes
      const t = signalData[i].timestamp;
      window = window.filter(
        (signal) => Math.abs(signal.timestamp - t) > maxTimeDifference
      );

      // need to make sure window length is greater than 1
      // when 0 -> we cannot calculate median
      // when 1 -> scaling factor = 0 which always filters out our point
      if (window.length > 1) {
        const median = calcMedianWindow(window);
        const deviations = window.map((signal) =>
          Math.abs(Math.abs(signal.value) - median)
        );
        const scalingFactor = calcMedian(deviations);
        // console.log(median, deviations, scalingFactor);

        upper.push(median + 12 * scalingFactor); // scaling factor value can be tuned
        lower.push(median - 12 * scalingFactor);
      } else {
        upper.push(Math.abs(signalData[i].value));
        lower.push(Math.abs(signalData[i].value));
      }
    }

    signalData.forEach((signal: any, i: any) => {
      if (
        Math.abs(signal.value) > upper[i] ||
        Math.abs(signal.value) < lower[i]
      ) {
        transientIndices.add(i);
      }
    });

    return transientIndices;
  };

  useEffect(() => {
    if (filterTransients) {
      let transientIndices = new Set();
      setOriginalDataValue(data);
      let newData = new Map();

      data.forEach((signalData: any, SignalID: any) => {
        if (
          signalData[0].ruleName == "latitude" ||
          signalData[0].ruleName == "longitude"
        ) {
          transientIndices = windowFilter(signalData, transientIndices);
          //console.log(transientIndices);
        } else {
          let tempData: any[] = [];
          signalData.forEach((datapoint: any, eIndex: any) => {
            if (datapoint.value != 0) {
              tempData.push(datapoint);
              //console.log("tempData:" + tempData);
            }
          });

          newData.set(SignalID, tempData);
        }
      });

      if (transientIndices.size > 0) {
        data.forEach((signalData: any, SignalID: any) => {
          if (
            signalData[0].ruleName == "latitude" ||
            signalData[0].ruleName == "longitude"
          ) {
            const filteredSignalData = signalData.filter(
              (signal: any, i: any) => !transientIndices.has(i)
            );
            newData.set(SignalID, filteredSignalData);
          }
        });
      }

      if (newData.size != 0) {
        setData(newData);
      }
    } else {
      if (originalDataValue) {
        setData(originalDataValue);
      }
    }
  }, [filterTransients]);
  // console.log(data);
  useEffect(() => {
    if (timezone != state.timezone.value && !newWidgetHeader) {
      if (
        state.timezone.value == "asset" &&
        state.timezone.isGpsSignalAvailable
      ) {
        if(!newWidgetHeader){
          let dataset = constructRows(originalWidgetData, signals);
          let convertedData = convertTimestamp(dataset);
          setTimezone(state.timezone.value);
          setData(convertedData);
        }
      } else {
        if(!newWidgetHeader){
          setTimezone(state.timezone.value);
          if (originalWidgetData) {
            let dataset = constructRows(originalWidgetData, signals);
            setData(dataset);
          }
        }
      }
    }
  }, [state.timezone.value]);

  useEffect(() => {
    if (signalArray.length > 0) {
      setLoading(true);

      if (!newWidgetHeader) {
        loadData(signals, {
          startDate: startDate,
          startTime: startTime,
          endDate: endDate,
          endTime: endTime,
          datapoint: datapoint,
        });
        if (rightYAxisSignals && rightYAxisSignals.length) {
          loadData(
            rightYAxisSignals || [],
            {
              startDate: startDate,
              startTime: startTime,
              endDate: endDate,
              endTime: endTime,
              datapoint: datapoint,
            },
            true
          );
        }
      } else if (initiallyLoaded) {
        setRefreshNewWidgetData(!refreshNewWidgetData) //triggers new widget refresh
      }

    }
  }, [
    signalArray,
    refreshTime,
    datapoint,
    startDate,
    endDate,
    startTime,
    endTime,
    rightYAxisSignals,
    state.temperature.value
  ]); //filterTransients

  useEffect(() => {
    if (signalArray.length > 0) {
      setLoading(true);
      loadData(signals, {
        startDate: startDate,
        startTime: startTime,
        endDate: endDate,
        endTime: endTime,
        datapoint: datapoint,
      });
      if (rightYAxisSignals && rightYAxisSignals.length) {
        loadData(
          rightYAxisSignals || [],
          {
            startDate: startDate,
            startTime: startTime,
            endDate: endDate,
            endTime: endTime,
            datapoint: datapoint,
          },
          true
        );
      }
    }
  }, [newWidgetHeader]); //filterTransients
  const parentOrgPath = state?.asset?.orgPathId?.includes("~") ? state?.asset?.orgPathId.split('~') : [state?.asset?.orgPathId];
  let parentOrg:any;
  if (parentOrgPath.length > 1) {
    parentOrg=parentOrgPath[1]
  }else{
    parentOrg=parentOrgPath[0]
  }
  const [cardHeaderShow, setCardHeaderShow] = useState(() => parentOrg==='7ff34c90-36b2-11e8-8e2e-6f1fbe0d469d' && isNewDashboard && (bgimageurl?true:false)? true : false);
  const mouseHover = () => 
    {
        setCardHeaderShow(false); 
    }
    const mouseLeave = () => 
      {
          setCardHeaderShow(true); 
      }
    const mouseHoverNull = () => 
    {
      //void
    }  
  const widgetPadding = (): CSSProperties => {
    switch (type) {
      case "text":
      case "boolean":
        return { padding: "0px 15px 0px 15px" };
      default:
        return { padding: "0" };
    }
  };
  
  const bodyStyle: CSSProperties = {
    height: "calc(100% - 15px)",
    ...widgetPadding(),
  };

  const timescaleAPIZooming = (starZoomTime: Moment, endZoomTime: Moment) => {
    setZoomedStartEndTime([starZoomTime, endZoomTime]);
  };

  const filterSetting = (filterSetting: WidgetFilterSetting) => {
    setWidgetFilterSetting(filterSetting)
    widgetState.widgetFilterSetting = filterSetting
  };

  if (newWidgetHeader && type == "line") {
    widgetState.timescaleAPIZooming = timescaleAPIZooming;
    widgetState.widgetFilterSetting = widgetFilterSetting;
  } else {
    widgetState.timescaleAPIZooming = undefined;
    widgetState.widgetFilterSetting = undefined;
  }
  const newWidgetStyles=()=>{
    if(parentOrg==='7ff34c90-36b2-11e8-8e2e-6f1fbe0d469d' && (bgimageurl!=='null' && bgimageurl!==null)?true:false && isNewDashboard){
      return true
    }else{
      return false;
    }
  }
  //console.log("widgetState",widgetState);
  
  return (
    <>
      {" "}
      {(widgetState?.type !== "command" ||
        (widgetState?.type === "command" &&
          (assetDetails.orgCmdPgnSpn === null ||
            assetDetails.orgCmdPgnSpns !== "-"))) && (
          <Card
            style={{width:"100%",height:'100%'}}
            onMouseEnter={widgetState?.type !== "command" && parentOrg==='7ff34c90-36b2-11e8-8e2e-6f1fbe0d469d'?mouseHover:mouseHoverNull}
            onMouseLeave={widgetState?.type !== "command" && parentOrg==='7ff34c90-36b2-11e8-8e2e-6f1fbe0d469d'?mouseLeave:mouseHoverNull}
            className={`toyotaWidgetStyles widgetShadow ${widgetState?.type === "information"?"information-widget-style":""} ${widgetState?.type !== "command" && cardHeaderShow && (bgimageurl!=='null' && bgimageurl!==null?true:false)? "card-extra-no-header" : ""} ${ newWidgetStyles() ? "toyotaWidgetStylesBg widgetNoShadow" : ""} ${props.isResizable ? "wiggleWidget" : ""}`}
            title={ <Tooltip title={props.widgetState.title} >
                  <span style={{ marginLeft: '0px', paddingLeft: '0px' }}> { props.widgetState.title } </span>
                </Tooltip>}
          extra={
            !newWidgetHeader ? (
              <WidgetHeader
                newWidgetHeader={newWidgetHeader}
                setNewWidgetHeader={setNewWidgetHeader}
                {...props}
                onRefresh={onRefresh}
                data={data}
                rightYAxisData={rightYAxisData}
                state={props.state}
                isLoading={isLoading}
                setFilteredTransients={setFilterTransients}
                isFilteredTransients={filterTransients}
              />
            ) : (
              <WidgetHeaderNew
                setData={setData}
                setRightAxisData={setRightAxisData}
                setLoading={setLoading}
                newWidgetHeader={newWidgetHeader}
                setNewWidgetHeader={setNewWidgetHeader}
                {...props}
                onRefresh={onRefresh}
                refreshData={refreshNewWidgetData}
                data={data}
                rightYAxisData={rightYAxisData}
                state={props.state}
                isLoading={isLoading}
                zoomedTime={zoomedStartEndTime}
                filterSetting={filterSetting}
                setInitiallyLoaded={setInitiallyLoaded}
                initiallyLoaded={initiallyLoaded}
                temperatureUnit={state.temperature.value}
              />
            )
          }
          // Makes the body the size of the card minus the size of the header
          bodyStyle={bodyStyle}
        >
          <div style={{ height: "100%"}}>
            {createWidget(
              widgetState,
              { template, data, rightYAxisData, state },
              error
            )}
          </div>
        </Card>
      )}{" "}
      {<></>}
    </>
  );
};

export default WidgetContainer;