import React, { useState, useEffect } from "react";
import { DETAILS as WIDGET_DETAILS } from "../widgets";
import { Template } from "../models";
import { Form, Select, ConfigProvider } from "antd";
import Event from "../../../lib/event";
import FilterForm from "./FilterForm";
import { WidgetFilterConfig } from ".";
import validationMessages from "./validation-messages";

type FormInstance = ReturnType<typeof Form.useForm>[0];

interface Props {
  config?: WidgetFilterConfig;
  onFinish: FilterFormProps["onFinish"];
  submitTrigger: Event;
}

export interface FilterFormProps {
  config?: WidgetFilterConfig;
  onFinish: (wfg: WidgetFilterConfig) => void;
  form: FormInstance;
  layout?: "horizontal" | "vertical";
}

const FilterFormContainer: React.FC<Props> = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const listener = props.submitTrigger.subscribe(() => form.submit());
    return () => listener.remove();
  }, [form, props.submitTrigger]);

  const filterProps: FilterFormProps = {
    config: props.config,
    form: form,
    onFinish: props.onFinish,
    layout: "vertical",
  };

  return (
    <div className="widget-filter-form">
      <FilterForm {...filterProps} />
    </div>
  );
};

export default FilterFormContainer;
