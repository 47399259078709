import React from "react";
import { Form, Collapse, Row, Col, Select, Button, Tooltip, Input, message, Switch } from 'antd';
import { FormInstance } from 'antd/lib/form';

import { connect } from "react-redux";
import * as actions from "../actions";

import { appConfigs } from "../../../utils/configurations";
import { resetFormErrors } from "../../../utils/apiHelpers/apiHelpers";

const { Option } = Select;
const { Panel } = Collapse;

interface Prop {
    dm1MessagingMaint: Function,
    templateDetails: any,
    isread: boolean
    iscreate: boolean,
    isupdate: boolean,
    context: any
}

interface State {
    can0: boolean,
    can1: boolean
}

class Dm1Messaging extends React.Component<Prop, State> {

    state: State = {
        can0: false,
        can1: false
    };

    formRef = React.createRef<FormInstance>();

    componentDidMount() {
        this.componentUpdate(this.props.templateDetails.templateRecord);  
    }

    componentDidUpdate(prevProps: Prop) {
        if (this.props.templateDetails.tabs[1].instance !== prevProps.templateDetails.tabs[1].instance) {
            this.componentUpdate(this.props.templateDetails.templateRecord);
        }
    }

    componentUpdate(record:any,flag: number = 0) {
        if (flag == 0)
            {
                this.closeCollapsed();
            }        
        if (this.formRef.current != null) {
            this.formRef.current!.resetFields();    
            this.formRef.current!.setFieldsValue({
                templateId: record.templateId,
                dm1MessagingCan0Enabled: record.dm1MessagingCan0Enabled,
                dm1MessagingCan0UpdateInterval: record.dm1MessagingCan0UpdateInterval,
                dm1MessagingCan1Enabled: record.dm1MessagingCan1Enabled,
                dm1MessagingCan1UpdateInterval: record.dm1MessagingCan1UpdateInterval,
                dm1WhitelistType: record.dm1WhitelistType,
                dm1NotificationDelaySeconds: record.dm1NotificationDelaySeconds, 
                maxDayDm1: record.maxDayDm1
            });
            this.setState({
                can0: record.dm1MessagingCan0Enabled,
                can1: record.dm1MessagingCan1Enabled
            });
        }       
    };

    onFinish = (values: any) => {    
        resetFormErrors(this, this.formRef.current);

        if (values.dm1MessagingCan0Enabled && (values.dm1MessagingCan0UpdateInterval || "") === "") {
            this.formRef.current!.setFieldsValue({
                dm1MessagingCan0UpdateInterval: 10
            });
        }
        if (values.dm1MessagingCan1Enabled && (values.dm1MessagingCan1UpdateInterval || "") === "") {
            this.formRef.current!.setFieldsValue({
                dm1MessagingCan1UpdateInterval: 10
            });
        }
        if ((values.dm1NotificationDelaySeconds || "") === "") {
            this.formRef.current!.setFieldsValue({
                dm1NotificationDelaySeconds: 60
            });
        }
        if ((values.maxDayDm1 || "") === "") {
            this.formRef.current!.setFieldsValue({
                maxDayDm1: 0
            });
        }
        
        this.props.dm1MessagingMaint(this, this.dm1MessagingMaintSuccess, null, this.formRef.current!.getFieldsValue());
    };

    dm1MessagingMaintSuccess = (data:any) => {          
        message.success("Your changes have been successfully processed");
    }

    onCan0Change = (checked:boolean) => {
        this.setState({
            can0: checked
        });
    }

    onCan1Change = (checked:boolean) => {
        this.setState({
            can1: checked
        });
    }

    closeCollapsed() {       
        let active:any = document.querySelectorAll("[data-id=template-dm1-faults] .ant-collapse-item-active");    
        if (active.length) {
            for (var i = 0; i < active.length; i++) { 
                active[i]!.children[0].click();  
            }
        }
    }
   
    render() {
        
        const { templateRecord } = this.props.templateDetails;

        return (
    
            <div data-id="template-dm1-messaging">
                <Collapse data-id="template-dm1-messaging-0" bordered={false} defaultActiveKey={['dm1-messaging']} className="auto-close">
                    <Panel data-id="template-dm1-messaging-1" header="DM1 Messaging" key="dm1-messaging">
                        <Form 
                            name="templateDm1MessagingForm"
                            ref={this.formRef}
                            layout="vertical"
                            onFinish={this.onFinish}
                        >
                            <Form.Item 
                                name="templateId"
                                style={{ display: 'none' }}
                            >
                                <Input type="hidden" />
                            </Form.Item>
                            
                           {/* Below DM1 messessaging section shall be visible to user who has read permission on either global or local teamplate
                             the admin or iscreate/isupdate is used to control the submit button visiblity
                            */}
                            <Row gutter={16}>
                                <Col xs={{ span: 24 }} md={{ span: 20 }} xl={{ span: 16 }}>
                                    <Row gutter={16}>
                                        <Col xs={{ span: 24 }} lg={{ span: 9, offset: 0 }}>
                                            <Form.Item
                                                label="DM1 Notification List"
                                                name="dm1WhitelistType"                           
                                            >
                                                <Select
                                                    data-id="template-dm1-messaging-dm1-whitelist-type"
                                                    disabled={(templateRecord.templateVisibleToSubOrg 
                                                        && (this.props.context.appContext.roleName.toLowerCase() !== "admin" && !this.props.isread && !this.props.iscreate && !this.props.isupdate))
                                                        || (!templateRecord.templateVisibleToSubOrg 
                                                            && (!templateRecord.objectAdmin && !this.props.isread && !this.props.iscreate && !this.props.isupdate))}
                                                >
                                                    <Option key={"all"} value="all">All</Option>
                                                    <Option key={"none"} value="none">None</Option>
                                                    <Option key={"whitelist"} value="whitelist">Whitelist</Option>
                                                    <Option key={"blacklist"} value="blacklist">Blacklist</Option>
                                                   {/* uncomment to add white + black list option
                                                   <Option key={"whiteandblacklist"} value="whiteandblacklist">Whitelist and Blacklist</Option>  */}
                                                   
                                                   
                                                </Select>      
                                            </Form.Item>
                                        </Col>      
                                        <Col xs={{ span: 24 }} lg={{ span: 9, offset: 0 }}>
                                            <Form.Item
                                                label="DM1 Notification Time Delay"
                                                name="dm1NotificationDelaySeconds"             
                                                initialValue={60}                           
                                            >
                                                <Input 
                                                    addonAfter="sec." 
                                                    type="number"
                                                    min="1" 
                                                    max="86400" 
                                                    step="1"
                                                    pattern="[0-9]*{4}"
                                                    disabled={(templateRecord.templateVisibleToSubOrg 
                                                        && (this.props.context.appContext.roleName.toLowerCase() !== "admin" && !this.props.isread && !this.props.iscreate && !this.props.isupdate)) 
                                                        || (!templateRecord.templateVisibleToSubOrg 
                                                            && (!templateRecord.objectAdmin && !this.props.isread && !this.props.iscreate && !this.props.isupdate))}
                                                />
                                            </Form.Item>
                                        </Col>    
                                        {/* <Col xs={{ span: 24 }} lg={{ span: 9, offset: 1 }}>
                                            <Form.Item
                                                label="Max Dm1 per day (0: unlimited)"
                                                name="maxDayDm1"             
                                                initialValue={5}                           
                                            >
                                                <Input 
                                                    type="number"
                                                    min="0" 
                                                    max="86400" 
                                                    step="1"
                                                    pattern="[0-9]*{4}"
                                                    disabled={(templateRecord.templateVisibleToSubOrg 
                                                        && (this.props.context.appContext.roleName.toLowerCase() !== "admin" && !this.props.iscreate && !this.props.isupdate)) 
                                                        || (!templateRecord.templateVisibleToSubOrg 
                                                            && (!templateRecord.objectAdmin && !this.props.iscreate && !this.props.isupdate))}
                                                />
                                            </Form.Item>
                                        </Col>    
                                        <Col xs={{ span: 0 }} lg={{ span: 4 }}>
                                        </Col>  */}
                                    </Row>    
                                    <Row gutter={16}>
                                        <Col xs={{ span: 6 }} lg={{ span: 2, offset: 0 }}>
                                            <Form.Item
                                                label="CAN0"
                                                name="dm1MessagingCan0Enabled"
                                                valuePropName="checked"
                                            >
                                                <Switch 
                                                    defaultChecked={false} 
                                                    onChange={this.onCan0Change}
                                                    disabled={(templateRecord.templateVisibleToSubOrg 
                                                        && (this.props.context.appContext.roleName.toLowerCase() !== "admin" && !this.props.isread && !this.props.iscreate && !this.props.isupdate))
                                                        || (!templateRecord.templateVisibleToSubOrg 
                                                            && (!templateRecord.objectAdmin && !this.props.isread && !this.props.iscreate && !this.props.isupdate))}
                                                />
                                            </Form.Item>                      
                                        </Col>                
                                        <Col xs={{ span: 18 }} lg={{ span: 7 }}>
                                            <Form.Item
                                                label="Can0 Update Interval"
                                                name="dm1MessagingCan0UpdateInterval"              
                                                initialValue={10}                     
                                            > 
                                                <Input 
                                                    addonAfter="sec." 
                                                    type="number"
                                                    min="0" 
                                                    max="86400" 
                                                    step="1"
                                                    pattern="[0-9]*{4}"
                                                    disabled={
                                                        (templateRecord.templateVisibleToSubOrg 
                                                            && (this.props.context.appContext.roleName.toLowerCase() !== "admin" && !this.props.isread && !this.props.iscreate && !this.props.isupdate))
                                                        || (!templateRecord.templateVisibleToSubOrg 
                                                            && (!this.state.can0 && !templateRecord.objectAdmin && !this.props.isread && !this.props.iscreate && !this.props.isupdate))
                                                    }     
                                                />                                                                        
                                            </Form.Item>          
                                        </Col>   
                                        <Col xs={{ span: 6 }} lg={{ span: 2, offset: 0 }}>
                                            <Form.Item
                                                label="CAN1"
                                                name="dm1MessagingCan1Enabled"
                                                valuePropName="checked"
                                            >
                                                <Switch 
                                                    defaultChecked={false} 
                                                    onChange={this.onCan1Change}
                                                    disabled={
                                                        (templateRecord.templateVisibleToSubOrg 
                                                            && (this.props.context.appContext.roleName.toLowerCase() !== "admin" && !this.props.isread && !this.props.iscreate && !this.props.isupdate))
                                                        || (!templateRecord.templateVisibleToSubOrg 
                                                            &&  (!templateRecord.objectAdmin && !this.props.isread && !this.props.iscreate && !this.props.isupdate))
                                                        }
                                                />
                                            </Form.Item>                      
                                        </Col>                
                                        <Col xs={{ span: 18 }} lg={{ span: 7 }}>
                                            <Form.Item
                                                label="Can1 Update Interval"
                                                name="dm1MessagingCan1UpdateInterval"              
                                                initialValue={10}                     
                                            > 
                                                <Input 
                                                    addonAfter="sec." 
                                                    type="number"
                                                    min="0" 
                                                    max="86400" 
                                                    step="1"
                                                    pattern="[0-9]*{4}"
                                                    disabled={
                                                        (templateRecord.templateVisibleToSubOrg 
                                                            && (this.props.context.appContext.roleName.toLowerCase() !== "admin" && !this.props.isread || !this.props.iscreate && !this.props.isupdate))
                                                        || (!templateRecord.templateVisibleToSubOrg &&
                                                        (!this.state.can1 && !templateRecord.objectAdmin === true && !this.props.isread && !this.props.iscreate && !this.props.isupdate))
                                                    }     
                                                />                                                                        
                                            </Form.Item>          
                                        </Col>   
                                        <Col xs={{ span: 2 }} lg={{ span: 2 }}>
                                            { <Tooltip title='CAN update interval is not Applicable for Assets with Lite Part Numbers. For Assets with Lite Part Numbers, update interval will be ignored. For each unique DM1 fault, Gateway will upload the first DM1 then discard any of subsequent duplicate ones until the next calendar month starts. Please note that it will upload only predetermined max number of unique DM1 faults in a day.
    ' >
                                                <Button style={{backgroundColor:'rgba(248, 248, 248, 1)', borderColor: 'rgba(248, 248, 248, 1)', color: 'rgba(48, 48, 48, 1)', fontSize: "100%"}}>{'\u24D8'}</Button>
                                            </Tooltip> }                                                                       
                                        </Col> 
                                    </Row>
                                    <Row>
                                        {
                                            ((templateRecord.templateVisibleToSubOrg 
                                                && (this.props.context.appContext.roleName.toLowerCase() === "admin"))
                                            || (!templateRecord.templateVisibleToSubOrg && (templateRecord.objectAdmin || (this.props.iscreate || this.props.isupdate)))) &&
                                            <div>
                                                    <Row gutter={16}>
                                                        <Col>
                                                            <Button
                                                            data-id="template-dm1-messaging-submit-button"
                                                            className="mt-30"
                                                            type="primary"
                                                            ghost={true}
                                                            onClick={() => this.componentUpdate(this.props.templateDetails.templateRecord,1)}
                                                            >
                                                            {appConfigs.settings.settingStyleForm.cancleButtonText}
                                                            </Button>
                                                        </Col>
                                                        <Col>
                                                            <Button
                                                            data-id="template-dm1-messaging-submit-button"
                                                            className="mt-30"
                                                            type="primary"
                                                            htmlType="submit"
                                                            >
                                                            {appConfigs.settings.settingStyleForm.saveButtonText}
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                            </div>      
                                        }
                                    </Row> 
                                </Col>      
                            </Row> 
                        </Form>
                    </Panel>
                </Collapse>
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        templateDetails: state.templateDetailsReducers.templateDetailsState
    };
};

export default connect(
    mapStateToProps,
    actions
)(Dm1Messaging);