import React, { Component } from "react";
import { Tabs, Row, Col, Button, Space, Tooltip } from "antd";
import { ReloadOutlined } from '@ant-design/icons';

import { connect } from "react-redux";
import * as actions from "./actions";
import history from '../../utils/history';

import { getSegmentFromUrl, getPermissions } from "../../utils/commonHelpers";

import Export from "./Export";
import AlertsSignals from "./AlertsSignals";
import AlertsDm1 from "./AlertsDm1";
import AlertsCmdHistory from "./AlertsCmdHistory";
import BackNavigation from "../../utils/Components/BackNavigation";
import { StringifyOptions } from "querystring";
import ActivityAlerts from "./ActivityAlerts";

const { TabPane } = Tabs;

interface Prop {
    alertsDm1: Function,
    alertsDm1Filters: Function,
    alertsSignals: Function,
    alertsSignalsFilters: Function,    
    alertsCmdHistory: Function,
    alertsCmdHistoryFilters: Function,
    context: any,
    alertsState: any,
    historyState: any,
    permissions: any , 
    maxCommandPermissionValue: any
}

interface State {
    activeTab: string,
    mastertag: string,
    status: string,
    selectedType: string, 
    historyType: string, 
    maxCommandPermissionValue: any
}

class Alerts extends Component<Prop, State> {    

    getRunAttributes = ():any => {
        let mode:string = "";
        let mastertag:string = "";
        let status:string = "";
        
        if (this.props.historyState && this.props.historyState.length > 0) {
            if ("mode" in this.props.historyState[0]) {
                mode = this.props.historyState[0].mode;  
            }
            if ("mastertag" in this.props.historyState[0]) {
                mastertag = this.props.historyState[0].mastertag;  
            }
            if ("status" in this.props.historyState[0]) {
                status = this.props.historyState[0].status;  
            }
        }
        else {
            mode = (getSegmentFromUrl(2) || "");
        }

        return {
            activeTab: mode === "signal-alarms" ? "2" : "1", 
            mastertag: mastertag || "",
            status: status || ""
        };
    }

    runAttributes:any = this.getRunAttributes();

    state:State = {
        activeTab: this.runAttributes.activeTab,
        mastertag: this.runAttributes.mastertag,
        status: this.runAttributes.status,
        selectedType: 'unseen',
        historyType: 'lastest', 
        maxCommandPermissionValue: 0
    }

    onTabChange = (key:string) => {
       key==='2' ? this.setState({activeTab: key, historyType: 'latest'})
                 : this.setState({ activeTab: key, selectedType: 'unseen'})
    }

    refreshData = (e: any, mode: string) => {         
        const mastertag = getSegmentFromUrl(3);

        if (mode === "dm1") {       
            const appliedFilters = this.props.alertsState.tabs[0].appliedFilters;     
            this.props.alertsDm1(
                this, 
                null, 
                null, 
                this.props.alertsState.tabs[0].tableConfig.pagination.current, 
                null,
                mastertag,
                this.state.selectedType,
                appliedFilters.timespanFrom,
                appliedFilters.timespanTo,
                appliedFilters.nickname,
                appliedFilters.sa,
                appliedFilters.spn,
                appliedFilters.fmi,
                appliedFilters.status
            );
            this.props.alertsDm1Filters(
                this, 
                null,
                null, 
                null, 
                mastertag,
                appliedFilters.timespanFrom, 
                appliedFilters.timespanTo
            );
        }
        else if (mode === "signal-alarms") {            
            const appliedFilters = this.props.alertsState.tabs[1].appliedFilters;     
            this.props.alertsSignals(
                this, 
                null, 
                null, 
                this.props.alertsState.tabs[1].tableConfig.pagination.current,
                null,
                mastertag,
                this.state.selectedType,
                appliedFilters.timespanFrom,
                appliedFilters.timespanTo,
                appliedFilters.nickname,
                appliedFilters.status
            );
            this.props.alertsSignalsFilters(
                this, 
                null,
                null, 
                null, 
                mastertag,
                appliedFilters.timespanFrom, 
                appliedFilters.timespanTo
            ); 
        } else {       
            const appliedFilters = this.props.alertsState.tabs[2].appliedFilters;     
            this.props.alertsCmdHistory(
                this, 
                null, 
                null, 
                this.props.alertsState.tabs[2].tableConfig.pagination.current, 
                null,
                mastertag,
                this.state.historyType,
                appliedFilters.timespanFrom,
                appliedFilters.timespanTo,
                appliedFilters.nickname,
                appliedFilters.command,
                appliedFilters.operator,
                appliedFilters.user,
                appliedFilters.type,
                appliedFilters.status
            );
            this.props.alertsCmdHistoryFilters(
                this, 
                null,
                null, 
                null, 
                mastertag,
                appliedFilters.timespanFrom, 
                appliedFilters.timespanTo
            );
        }
    }   

    allAlerts = (e:any) => {
        e.preventDefault();
        history.push("/alerts");
    }
    getSelectedType = (viewType:string) => {
        this.setState({
            selectedType: viewType,
        })
    }
    getHistoryType = (historyType:string) => {
        this.setState({
            historyType: historyType,
        })
    }

    render() {    
       
        const result = getPermissions(this.props.permissions.permissions.alert_settings);
      
        let iscreate = result[0];
        let isread = result[1];
        let isupdate = result[2];

        const commandPermission = getPermissions(this.props.maxCommandPermissionValue.maxCommandPermissionValue);
        
        // let isCommandCreate = commandPermission[0];
        let isCommandRead = commandPermission[1];
        // let isCommandUpdate = commandPermission[2];
        return (
            
            <div className="layout-content">
                <Row gutter={8}>
                    <Col xs={24} lg={14}>
                        <div className="content-header page-title">
                            <div style={{ display: "flex" }}>
                                <Tooltip title="Go To Previous Page"> 
                                    <BackNavigation /> 
                                </Tooltip>                            
                                {
                                    <span>Alerts</span>
                                }
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} lg={10} className="content-header-controls">
                        <Space>
                            {
                                this.props.alertsState.tabs[parseInt(this.state.activeTab) - 1].tableConfig.pagination.total > 0 && (iscreate && isupdate) &&
                                this.state.activeTab !== "4" &&
                                <Export
                                    selectedViewType={this.state.selectedType}
                                    mastertag={this.state.mastertag}
                                    mode={this.state.activeTab ==="1" ? "dm1-faults" : 
                                        (this.state.activeTab ==="2" ? "signal-alarms" : "cmd-history")}
                                    pageSize={this.props.alertsState.tabs[parseInt(this.state.activeTab) - 1].tableConfig.pagination.pageSize}
                                    totalRecords={this.props.alertsState.tabs[parseInt(this.state.activeTab) - 1].tableConfig.pagination.total}
                                    currentPage={this.props.alertsState.tabs[parseInt(this.state.activeTab) - 1].tableConfig.pagination.current}
                                />
                            }
                            {
                                (this.state.activeTab === "1" && isread) && 
                                <Button data-id="alerts-dm1-faults-refresh-button" className="mt-10" onClick={(e) => {this.refreshData(e, "dm1")}} type="default" icon={<ReloadOutlined />}>
                                    Refresh
                                </Button>
                            }
                            {
                                (this.state.activeTab === "2"  && isread) && 
                                <Button data-id="alerts-signal-alarms-refresh-button" className="mt-10" onClick={(e) => {this.refreshData(e, "signals")}} type="default" icon={<ReloadOutlined />}>
                                    Refresh
                                </Button>
                            }
                            {
                            (this.state.activeTab === "3"  && isread) && 
                                <Button data-id="alerts-cmd-history-refresh-button" className="mt-10" onClick={(e) => {this.refreshData(e, "cmdHistory")}} type="default" icon={<ReloadOutlined />}>
                                    Refresh
                                </Button>
                            }
                        </Space>
                    </Col>
                </Row>
                
                <div className="mt-15">
                    <Tabs activeKey={this.state.activeTab} size="large" type="card" onChange={this.onTabChange}>
                        {
                            isread &&
                            <TabPane tab="DM1 Faults" key="1" data-id="alerts-dm1-faults-tab-button">                    
                                <AlertsDm1 selectedType={this.getSelectedType} mastertag={this.state.mastertag} isread={isread} />
                            </TabPane> 
                        }
                        {
                            isread &&
                            <TabPane tab="Signal Alerts" key="2" data-id="alerts-signal-alarms-tab-button">
                                <AlertsSignals selectedType={this.getSelectedType} mastertag={this.state.mastertag} status={this.state.status} isread={isread}/>
                            </TabPane>
                        }
                        {                      
                            isCommandRead &&
                            <TabPane tab="Command History" key="3" data-id="alerts-cmd-history-tab-button">                    
                                <AlertsCmdHistory selectedType={this.getSelectedType} mastertag={this.state.mastertag} isread={isread} />
                            </TabPane> 
                        }
                        {                      
                            isread &&
                            <TabPane tab="Activity Alerts" key="4" data-id="alerts-activity-tab-button">                    
                                <ActivityAlerts/>
                            </TabPane> 
                        }
                    </Tabs>           
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        permissions: state.contextReducer.data,
        alertsState: state.alertsReducer.alertsState,
        historyState: history.location.state, 
        maxCommandPermissionValue: state.contextReducer.maxCommandPermissionValue
    };
};

export default connect(
    mapStateToProps,
    actions    
  )(Alerts);
  