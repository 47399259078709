import React, { useMemo, useState } from "react";

import { Form, Button, Select, Input, Row } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { SketchPicker } from "react-color";
import "./form-item.css";
import { Rule } from "../models";

type Value = string;
type ValueArray = Array<any | undefined>;

interface SignalValue {
  id: Value;
  label: string;
  color: string;
  value: string;
}

interface Selectable {
  value: SignalValue;
  label: string;
}
interface Props {
  options: SignalValue[];
  maxSelected?: number;
  value?: ValueArray;
  onChange?: (v: ValueArray) => void;
  valueLabel?: string;
  labels: string[];
  rules: Rule[];
}

const ColorSelectorList: React.FC<Props> = (props) => {
  // Must be optional to play nice with Ant Design's forms but won't
  // actually work without them
  const assertDefined = (propName: keyof Props) => {
    if (props[propName] === undefined) {
      console.error(`Property '${propName} must be defined for ListSelector`);
    }
  };
  assertDefined("onChange");

  const { value = [], onChange } = props;

  let onTemp = [];
  let offTemp = [];
  let onTempColor = [];
  let OffTempColor = [];

  for (let index = 0; index < value.length; index++) {
    onTemp.push(false);
    offTemp.push(false);
    let c =
      value[index].color === "#000000" || value[index].color == null
        ? "#008000$#FF0000"
        : // ? appConfigs.colorCodes.colorCodesLineChart[index]
          value[index].color;
    let s = c.split("$");
    onTempColor.push(s[0]);
    OffTempColor.push(s[1]);
  }
  const [isOnOpen, setIsOnOpen] = useState(onTemp);
  const [onColor, setOnColor] = useState(onTempColor);
  const [isOffOpen, setIsOffOpen] = useState(offTemp);
  const [Offcolor, setOffcolor] = useState(OffTempColor);

  const { add, remove, changeValue } = useMemo(() => {
    const triggerChange = (newValues: ValueArray) => {
      if (onChange) {
        onChange(newValues);
      }
    };

    const add = () => {
      triggerChange(value.concat({ id: "", color: "#008000$#FF0000" }));
    };

    const remove = (i: number) => {
      triggerChange([...value.slice(0, i), ...value.slice(i + 1)]);
    };

    const changeValue = (index: number, updatedValue: Value) => {
      const newVals = [...value];
      if (newVals.length > 0) {
        let obj = {
          id: updatedValue,
          color: "#008000$#FF0000", //appConfigs.colorCodes.colorCodesLineChart[index],
        };
        newVals[index] = obj;
        triggerChange(newVals);
      }
    };

    return { add, remove, changeValue };
  }, [onChange, value]);

  const filteredOptions = (v?: SignalValue) => {
    return props.options.filter(
      (opt) => opt.id === v?.id || !value.some((e) => e.id === opt.id) //!value.includes({ id: opt.id, color: opt.color })
    );
  };

  const onSearch = (val: string) => {
    return props.options.filter(
      (opt) => opt.label === val || !value.includes(opt.label)
    );
  };

  let isRules = false;
  if (value.length > 0) {
    for (let index = 0; index < value.length; index++) {
      const element = value[index];
      let rule = props.rules.filter((sig) => sig.signalId === element.id); //.length;
      if (rule.length > 0) {
        if (
          rule[0].criticalHigh &&
          rule[0].criticalLow &&
          rule[0].warningHigh &&
          rule[0].warningLow
        ) {
          isRules = true;
        }
      }
    }
  }

  const maxReached = value.length >= (props.maxSelected || Infinity);
  const valueLabel = props.valueLabel ? `${props.valueLabel} ` : "";
  const onLabel = props.labels.length === 2 ? props.labels[0] : "On";
  const offLabel = props.labels.length === 2 ? props.labels[1] : "On";

  let labelText = "";
  if (onLabel === "Fill") {
    if (isRules) {
      labelText = "*Rules are available for the selected signal.";
    } else {
      labelText = "*Rules are not available for the selected signal.";
    }
  }

  const colorPickerCover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  } as React.CSSProperties;

  const labelStyle = {
    marginLeft: "2px",
    textAlign: "left",
    display: "block",
    float: "left",
    width: "50%",
  } as React.CSSProperties;

  const label1Style = {
    marginLeft: "8px",
    display: "block",
    textAlign: "left",
    float: "left",
    width: "22%",
  } as React.CSSProperties;

  const label2Style = {
    marginLeft: "2px",
    textAlign: "left",
    display: "block",
    float: "left",
    width: "20%",
  } as React.CSSProperties;

  return (
    <div>
      <Form.Item>
        <label style={labelStyle}>Name</label>
        <label style={label1Style}>{onLabel}</label>
        <label style={label2Style}>{offLabel}</label>
      </Form.Item>
      <Form.Item className="form-item">
        {value.map((v, i) => (
          <Form.Item key={i}>
            <Select
              showSearch
              style={{ width: "50%" }}
              value={v?.id}
              onChange={(v) => changeValue(i, v)}
              options={filteredOptions(v)}
              optionFilterProp={"label"}
              onSearch={onSearch}
            />
            <Input
              disabled={isRules}
              value={v.color.split("$")[0] || onColor[i]}
              style={{ marginLeft: "8px", width: "15%" }}
            />
            <Button
              disabled={isRules}
              style={{
                marginLeft: "8px",
                width: "5%",
                background: v.color.split("$")[0] || onColor[i],
              }}
              shape="circle"
              onClick={() => {
                isOnOpen[i] = true;
                setIsOnOpen([...isOnOpen]);
              }}
            >
              .
            </Button>
            <Input
              disabled={isRules}
              value={v.color.split("$")[1] || Offcolor[i]}
              style={{ marginLeft: "8px", width: "15%" }}
            />
            <Button
              disabled={isRules}
              style={{
                marginLeft: "8px",
                width: "5%",
                background: v.color.split("$")[1] || Offcolor[i],
              }}
              shape="circle"
              onClick={() => {
                isOffOpen[i] = true;
                setIsOffOpen([...isOffOpen]);
              }}
            >
              .
            </Button>
            <MinusCircleOutlined
              style={{ marginLeft: "8px" }}
              onClick={() => remove(i)}
            />
            <div>
              {isOnOpen[i] ? (
                v ? (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 5,
                        right: 0,
                      }}
                    >
                      <div
                        style={colorPickerCover}
                        onClick={() => {
                          isOnOpen[i] = false;
                          setIsOnOpen([...isOnOpen]);
                        }}
                      />
                      <SketchPicker
                        color={
                          (v.color ? v.color.split("$")[0] : v.color) ||
                          onColor[i]
                        }
                        onChange={(c) => {
                          if (v.color) {
                            let s = v.color.split("$");
                            v.color = c.hex + "$" + s[1];
                          } else {
                            v.color = c.hex + "$#FF0000";
                          }
                          onColor[i] = c.hex;
                          setOnColor([...onColor]);
                        }}
                      />
                    </div>
                  </>
                ) : null
              ) : isOffOpen[i] ? (
                v ? (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 5,
                        right: 0,
                      }}
                    >
                      <div
                        style={colorPickerCover}
                        onClick={() => {
                          isOffOpen[i] = false;
                          setIsOffOpen([...isOffOpen]);
                        }}
                      />
                      <SketchPicker
                        color={
                          (v.color ? v.color.split("$")[1] : v.color) ||
                          Offcolor[i]
                        }
                        onChange={(c) => {
                          if (v.color) {
                            let s = v.color.split("$");
                            v.color = s[0] + "$" + c.hex;
                          } else {
                            v.color = "#008000$" + c.hex;
                          }
                          Offcolor[i] = c.hex;
                          setOffcolor([...Offcolor]);
                        }}
                      />
                    </div>
                  </>
                ) : null
              ) : null}
            </div>
          </Form.Item>
        ))}
        <Button disabled={maxReached} onClick={add} type="dashed">
          {maxReached ? `Max ${valueLabel}Added` : `Add ${valueLabel}`}
        </Button>
        <Form.Item>
          <Row justify="space-between">
            <span>{labelText}</span>
          </Row>
        </Form.Item>
      </Form.Item>
    </div>
  );
};

export default ColorSelectorList;
