export interface FormState {
    formError?: boolean;
    formErrorMessage?: string[];
    formSuccess?: boolean;
    formSuccessMessage?: string;
    formCommit?: boolean;
}

export const formInit = (
    me: any
) => {

    me.setState({ 
        formSuccess: false, 
        formSuccessMessage: "",
        formError: false,
        formErrorMessage: "",
        formCommit: false
    });
}