import React from "react";
import { Checkbox, Row, Col, Form, Select, Table } from "antd";
import { appConfigs } from "../../../utils/configurations";
import { DashboardPermission } from ".";
import { SuccessAlert } from "../../../utils/Components/formAlerts";

const { Option } = Select;

interface Props {
  datasource: any;
  dashboardPermissions: DashboardPermission[];
  orgRoles: any;
  onRoleChange: (value: any) => void;
  onFinish: (roleId: number, values: DashboardPermission[]) => void;
  saveSuccess: boolean;
}

interface State {
  dashboardPermissions: DashboardPermission[];
  role: string;
}

class DashboardRolePermissions extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dashboardPermissions: [],
      role: "",
    };
  }

  onCheckedChange = (e: any, permissionId: number) => {
    const permissionType = +e.target.id.split(":")[1];
    this.setState({
      dashboardPermissions: this.state.dashboardPermissions.map((p: any) => {
        if (p.permissionId === permissionId) {
          p.permissions[permissionType] = +e.target.checked;
          return p;
        } else {
          return p;
        }
      }),
    });
  };

  componentDidMount() {
    this.setState({
      dashboardPermissions: this.props.dashboardPermissions,
      role: this.props.orgRoles[0].roleName,
    });
  }

  componentDidUpdate(prevState: any, prevProps: any) {
    this.setState({ dashboardPermissions: this.props.dashboardPermissions });
  }

  onFinish = (form: any) => {
    // console.log(form.roles);
    this.props.onFinish(form.roles, this.state.dashboardPermissions);
  };

  onRoleChange = (value: any, options: any) => {
    this.setState({ role: options.key });
    this.props.onRoleChange(value);
  };

  render() {
    const { datasource, orgRoles, saveSuccess } = this.props;
    const { dashboardPermissions } = this.state;
    const tableColumns = [
      {
        title: "Dashboard",
        dataIndex: "dashboardTitle",
        width: "50%",
      },
      {
        title: "View",
        dataIndex: "View",
        key: "View",
        width: "12%",
        render: (View: any, record: any) => (
          <>
            <Checkbox
              id={`${record.permissionId.toString()}:1`}
              key={`${record.permissionId.toString()}:1`}
              disabled={this.state.role.toLowerCase() === "admin"}
              checked={
                dashboardPermissions.find(
                  (p: any) => p.permissionId === record.permissionId
                )?.permissions[1] === 1
                  ? true
                  : false
              }
              onChange={(e: any) =>
                this.onCheckedChange(e, record.permissionId)
              }
              //defaultChecked={record.View === 1}
            ></Checkbox>
          </>
        ),
      },
    ];
    return (
      dashboardPermissions &&
      dashboardPermissions.length > 0 && (
        <div data-id="dashboard-role-maint-info">
          {saveSuccess && (
            <SuccessAlert
              data-id="permission-maint-info-success-message"
              message={"Permissions has been successfully updated"}
            />
          )}
          <Row gutter={8} style={{ marginTop: 15 }}>
            <Col offset={1} span={23}>
              <Form
                {...appConfigs.settings.formLayout.mainLayout}
                id="dashboardPermissionMaintInfoForm"
                className="mt-15"
                onFinish={this.onFinish}
                initialValues={{
                  roles: orgRoles && orgRoles.length > 0 && orgRoles[0].roleId,
                }}
              >
                <Row>
                  <Col span={10}>
                    <Form.Item
                      name="roles"
                      label="Roles"
                      rules={[
                        {
                          required: false,
                          message: appConfigs.errors.fieldErrors.valueRequired,
                        },
                      ]}
                    >
                      {orgRoles && orgRoles.length > 0 && (
                        <Select onChange={this.onRoleChange}>
                          {orgRoles.map((role: any) => (
                            <Option key={role.roleName} value={role.roleId}>
                              {role.roleName}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <div className="mt-15">
                  <Table
                    data-id="dashboard-roles"
                    rowKey={(record) => record.permissionId}
                    columns={tableColumns}
                    dataSource={datasource}
                  />
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      )
    );
  }
}

export default DashboardRolePermissions;
