import React, { Component } from "react";
import { Moment } from "moment";
import moment from "moment";
import { Form, Row, Col, Collapse, Select, Button, Space, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CheckCircleTwoTone  } from "@ant-design/icons";

import { connect } from "react-redux";
import * as actions from "./actions";

import Timespan from "./Timespan";
import "./alerts.css";

const { Option } = Select;
const { Panel } = Collapse;

interface Prop {    
    alertsCmdHistory: Function,
    alertsCmdHistoryFilters: Function,
    context: any,
    alertsState: any,   
    nickname?: string[],
    isread: boolean,
    viewHistoryType: string
}

interface State {
    nickname?: string[],
    isread: boolean   
}

class AlertsCmdHistoryFilters extends Component<Prop, State> {

    state:State = {
        nickname: [],
        isread: false     
    }

    formRef = React.createRef<FormInstance>();

    componentDidUpdate(prevProps: Prop) {
        if ((this.props.nickname || []).toString() !== (prevProps.nickname || []).toString()) {
            this.setState({ nickname: this.props.nickname,isread: this.props.isread });   
        }                 
    }

    resetFilters = () => { 
        const me = this;

        this.setState({ nickname: [] });     
        setTimeout(function () {
            me.formRef.current?.resetFields();   
            me.applyFilters();   
        }, 150);  
    }

    applyFilters = () => {   
        let form = this.formRef.current;
        const timespan:Moment[] = form?.getFieldValue("timespan");
        const timespanFromLocal = (timespan ? timespan[0].format('YYYY-MM-DD HH:mm:ss') : "");
        const timespanToLocal = (timespan ? timespan[1].format('YYYY-MM-DD HH:mm:ss') : "");
      
        var timespanFromUtc, timespanToUtc;
        var timespanFromIso, timespanToIso;
        if (timespanFromLocal === "")  {
            timespanFromUtc = "";
        } else {
            var timespanFromDateFormat =new Date((Date.parse(timespanFromLocal)));
             timespanFromIso = timespanFromDateFormat.toISOString();
             timespanFromUtc = moment.utc(timespanFromIso).format('YYYY-MM-DD HH:mm:ss');
        }

        if (timespanToLocal === "") {
            timespanToUtc = "";
        } else {
            var timespanToDateFormat =new Date((Date.parse(timespanToLocal)));
            timespanToIso = timespanToDateFormat.toISOString();
            timespanToUtc = moment.utc(timespanToIso).format('YYYY-MM-DD HH:mm:ss');
        }
      
        this.props.alertsCmdHistory(
            this, 
            null, 
            null, 
            1, 
            null,
            null,
            this.props.viewHistoryType,
            timespanFromUtc,
            timespanToUtc,
            form?.getFieldValue("nickname") || "",
            form?.getFieldValue("command") || "",
            form?.getFieldValue("operator") || "",
            form?.getFieldValue("user") || "",
            form?.getFieldValue("type") || "",
            form?.getFieldValue("status") || "",
            form?.getFieldValue("tags") || ""
        );  
        this.props.alertsCmdHistoryFilters(
            this, 
            null, 
            null, 
            null, 
            null, 
            timespanFromUtc, 
            timespanToUtc
        );      
    };
   
    render() { 

        const me = this;
        const { filters, appliedFilters } = this.props.alertsState.tabs[2];
        const filtersApplied =             
            (appliedFilters.nickname && appliedFilters.nickname.length || 0) || //eslint-disable-line 
            (appliedFilters.command && appliedFilters.command.length || 0) || //eslint-disable-line 
            (appliedFilters.operator && appliedFilters.operator.length || 0) || //eslint-disable-line 
            (appliedFilters.user && appliedFilters.user.length || 0) || //eslint-disable-line 
            // (appliedFilters.type && appliedFilters.type.length || 0) || //eslint-disable-line 
            (appliedFilters.status && appliedFilters.status.length || 0) || //eslint-disable-line 
            (appliedFilters.tags && appliedFilters.tags.length || 0) || //eslint-disable-line 
            ((appliedFilters.timespanFrom || "") !== "") || //eslint-disable-line  
            ((appliedFilters.timespanTo || "") !== ""); //eslint-disable-line 
        const filtersHeader = (
            <span>Filters {filtersApplied ? <Tooltip title="Applied Filter"><CheckCircleTwoTone /></Tooltip> : ""}</span>
        );
        const filterLayout = {
            labelCol: { span: 3 },
            wrapperCol: { span: 21 }
        };
        return (
            <>
                {
                    filters !== null && this.props.isread &&
                    <div data-id="alerts-cmd-history-filters"> 
                        <Collapse bordered={false} defaultActiveKey={['0']} className="auto-close">
                            <Panel header={filtersHeader} key="1">
                                <Form 
                                    {...filterLayout}
                                    ref={this.formRef}
                                    name="filter"
                                    initialValues={{ 
                                        nickname: this.state.nickname
                                    }} 
                                >
                                    <Row gutter={16}>
                                        <Col xs={{ span: 24 }} md={{ span: 20 }} xl={{ span: 16 }}>
                                            <Row gutter={16}>
                                                <Col span={23} offset={1}>
                                                    <Timespan />
                                                </Col>                
                                            </Row> 
                                            <Row gutter={16}>
                                                <Col span={23} offset={1}>
                                                    <Form.Item
                                                        name="nickname"
                                                        label="Asset"
                                                    >
                                                        <Select 
                                                            data-id="alerts-cmd-history-filters-nickname"
                                                            mode="multiple"
                                                            showSearch
                                                            placeholder="Select..."
                                                            defaultActiveFirstOption={false}
                                                            showArrow={false}
                                                            filterOption={true}
                                                            notFoundContent={null}
                                                            allowClear
                                                        >
                                                            {filters !== null && filters.nicknameSorted.map((record:any, index:number) => (
                                                                <Option key={"nickname-" + index} value={record}>                                                
                                                                <div className="demo-option-label-item">
                                                                    <strong>{record}</strong>
                                                                </div>
                                                            </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>                
                                            </Row>    
                                            <Row gutter={16}>
                                                <Col span={23} offset={1}>
                                                    <Form.Item
                                                        name="command"
                                                        label="Command"
                                                    >
                                                        <Select 
                                                            data-id="alerts-cmd-history-filters-command"
                                                            mode="multiple"
                                                            showSearch
                                                            placeholder="Select..."
                                                            defaultActiveFirstOption={false}
                                                            showArrow={false}
                                                            filterOption={true}
                                                            notFoundContent={null}
                                                            allowClear
                                                        >
                                                            {filters !== null && filters.commandSorted.map((record:any, index:number) => (
                                                                <Option key={"command-" + index} value={record}>                                                
                                                                <div className="demo-option-label-item">
                                                                    <strong>{record}</strong>
                                                                </div>
                                                            </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>                
                                                            </Row>       
                                            <Row gutter={16}>
                                                <Col span={23} offset={1}>
                                                    <Form.Item
                                                        name="operator"
                                                        label="Operator"
                                                    >
                                                        <Select 
                                                            data-id="alerts-cmd-history-filters-operator"
                                                            mode="multiple"
                                                            showSearch
                                                            placeholder="Select..."
                                                            defaultActiveFirstOption={false}
                                                            showArrow={false}
                                                            filterOption={true}
                                                            notFoundContent={null}
                                                            allowClear
                                                        >
                                                            {filters !== null && filters.operatorSorted.map((record:any, index:number) => (
                                                                <Option key={"operator-" + index} value={record}>                                                
                                                                <div className="demo-option-label-item">
                                                                    <strong>{record}</strong>
                                                                </div>
                                                            </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>                
                                            </Row>       
                                            <Row gutter={16}>
                                                <Col span={23} offset={1}>
                                                    <Form.Item
                                                        name="user"
                                                        label="User"
                                                    >
                                                        <Select 
                                                            data-id="alerts-cmd-history-filters-user"
                                                            mode="multiple"
                                                            showSearch
                                                            placeholder="Select..."
                                                            defaultActiveFirstOption={false}
                                                            showArrow={false}
                                                            filterOption={true}
                                                            notFoundContent={null}
                                                            allowClear
                                                        >
                                                            {filters !== null && filters.userSorted.map((record:any, index:number) => (
                                                                <Option key={"user-" + index} value={record}>                                                
                                                                <div className="demo-option-label-item">
                                                                    <strong>{record}</strong>
                                                                </div>
                                                            </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>                
                                            </Row>        
                                           {/* <Row gutter={16}>
                                                <Col span={23} offset={1}>
                                                    <Form.Item
                                                        name="type"
                                                        label="Type"
                                                    >
                                                        <Select 
                                                            data-id="alerts-cmd-history-filters-type"
                                                            mode="multiple"
                                                            showSearch
                                                            placeholder="Select..."
                                                            defaultActiveFirstOption={false}
                                                            showArrow={false}
                                                            filterOption={true}
                                                            notFoundContent={null}
                                                            allowClear
                                                        >
                                                            {filters !== null && filters.typeSorted.map((record:any, index:number) => (
                                                                <Option key={"type-" + index} value={record}>                                                
                                                                <div className="demo-option-label-item">
                                                                    <strong>{record}</strong>
                                                                </div>
                                                            </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>                
                                            </Row>    
                                            */}
 
                                            <Row gutter={16}>
                                                <Col span={23} offset={1}>
                                                    <Form.Item
                                                        name="status"
                                                        label="Status"
                                                    >
                                                        <Select 
                                                            data-id="alerts-cmd-history-filters-status"
                                                            mode="multiple"
                                                            showSearch
                                                            placeholder="Select..."
                                                            defaultActiveFirstOption={false}
                                                            showArrow={false}
                                                            filterOption={true}
                                                            notFoundContent={null}
                                                            allowClear
                                                        >
                                                            {filters !== null && filters.statusSorted.map((record:any, index:number) => (
                                                                <Option key={"status-" + index} value={record}>                                                
                                                                <div className="demo-option-label-item">
                                                                    <strong>{record}</strong>
                                                                </div>
                                                            </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>                
                                            </Row>    


                                            <Row gutter={16}>


                                                <Col span={23} offset={1}>
                                                    <Form.Item
                                                        name="tags"
                                                        label="Tags"
                                                        
                                                    >
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            placeholder="Select..."
                                                            mode="multiple"
                                                            showSearch
                                                            defaultActiveFirstOption={false}
                                                            showArrow={false}
                                                            filterOption={true}
                                                            notFoundContent={null}
                                                            allowClear
                                                        >

                                                        {filters !== null && filters.tagsSorted?.map((record:any, index:number) => (
                                                            <Option key={"tag-" + index} value={record.toUpperCase()}>
                                                                <div className="demo-option-label-item">
                                                                    <strong>{record.toUpperCase()}</strong>
                                                                </div>
                                                            </Option>
                                                        ))}
                                                        </Select> 
                                                    </Form.Item>
                                                </Col>                


                                            </Row>    

                                            
                                            <Space size="middle">
                                                <Form.Item 
                                                    shouldUpdate={true}>                                            
                                                    {() => (
                                                        <Button
                                                            data-id="alerts-cmd-history-filters-reset-button"
                                                            type="default"
                                                            htmlType="submit"
                                                            onClick={this.resetFilters}              
                                                        >
                                                            Reset
                                                        </Button>
                                                    )}
                                                </Form.Item>
                                                <Form.Item 
                                                    shouldUpdate={true}>                                            
                                                    {() => (
                                                        <Button
                                                            data-id="alerts-cmd-history-filters-submit-button"
                                                            type="default"
                                                            htmlType="submit"
                                                            onClick={this.applyFilters}     
                                                            disabled={
                                                                !(
                                                                    (this.formRef.current?.getFieldValue("nickname") || []).length !== 0 ||
                                                                    (this.formRef.current?.getFieldValue("command") || []).length !== 0 ||
                                                                    (this.formRef.current?.getFieldValue("operator") || []).length !== 0 ||
                                                                    (this.formRef.current?.getFieldValue("user") || []).length !== 0 ||
                                                                    (this.formRef.current?.getFieldValue("type") || []).length !== 0 ||
                                                                    (this.formRef.current?.getFieldValue("status") || []).length !== 0 ||
                                                                    (this.formRef.current?.getFieldValue("tags") || []).length !== 0 ||
                                                                    this.formRef.current?.getFieldValue("timespan")
                                                                )   
                                                            }                                  
                                                        >
                                                            Submit
                                                        </Button>
                                                    )}
                                                </Form.Item>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Form>
                            </Panel>            
                        </Collapse>
                    </div>
                }
            </>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        permissions: state.contextReducer.data,
        alertsState: state.alertsReducer.alertsState
    };
};

export default connect(
    mapStateToProps,
    actions
)(AlertsCmdHistoryFilters);
  