import React, { useState, useEffect } from "react";
import {
  WidgetConfig,
  InformationWidgetConfig,
  getEditForm,
  DETAILS as WIDGET_DETAILS,
} from "../widgets";
import { Template, Asset } from "../models";
import { Form, Select, ConfigProvider, Row, Col } from "antd";
import Container from "./Container";
import Event from "../../../lib/event";
import validationMessages from "./validation-messages";
import barWidget from "../../../assets/images/widgets/bar_widget.jpg";
import booleanWidget from "../../../assets/images/widgets/boolean_widget.jpg";
import clusterWidget from "../../../assets/images/widgets/cluster_widget.jpg";
import gaugeWidget from "../../../assets/images/widgets/gauge_widget.jpg";
import gpsEventWidget from "../../../assets/images/widgets/gps_event_widget.jpg";
import lineWidget from "../../../assets/images/widgets/line_widget.jpg";
import locationWidget from "../../../assets/images/widgets/location_widget.jpg";
import textWidget from "../../../assets/images/widgets/text_widget.jpg";
import trackerWidget from "../../../assets/images/widgets/tracker_widget.jpg";
import commandWidget from "../../../assets/images/widgets/command_widget.jpg";
import chatHistoryWidget from "../../../assets/images/widgets/chat_history_widget.jpg";
import informationWidget from "../../../assets/images/widgets/information_widget.jpg";
import "./form-item.css";

type WidgetType = WidgetConfig["type"] | undefined;
type FormInstance = ReturnType<typeof Form.useForm>[0];

interface Props {
  config?: WidgetConfig;
  template: Template;
  onFinish: EditFormProps["onFinish"];
  submitTrigger: Event;
  onDualChange:EditFormProps["onDualChange"];
  enabledualaxis:EditFormProps["enabledualaxis"];
  assetDetails?: any;
}

export interface EditFormProps {
  config?: WidgetConfig;
  template: Template;
  assetDetails? : any;
  onFinish: (cfg: WidgetConfig) => void;
  form: FormInstance;
  layout?: "horizontal" | "vertical";
  onDualChange:(checked:boolean)=>void;
  enabledualaxis: boolean;
}

export interface AssetProps {
  assetDetails?: any;
}

const TypeSelectForm: React.FC<{
  onTypeChange: (t: WidgetType) => void;
} & AssetProps> = (props) => {

  /* If it's Lite asset it cannot view map events */
  let FILTERED_WIDGETS:any = []
  WIDGET_DETAILS.filter((wd) => {
    if(!props.assetDetails?.noOfSignals){
      FILTERED_WIDGETS.push({
        value: wd.type,
        label: wd.name,
      })
    } else if (props.assetDetails?.noOfSignals > 0 && wd.type != 'map events') {
      FILTERED_WIDGETS.push({
        value: wd.type,
        label: wd.name,
      })
    }
  })
  //Getting parentOrg to enable information widget only for GS Global and its Sub Orgs
  const parentOrgPath = props.assetDetails?.orgPathId?.includes("~") ? props.assetDetails?.orgPathId.split('~') : [props.assetDetails?.orgPathId];
  let parentOrg:any;
  if (parentOrgPath.length > 1) {
    parentOrg=parentOrgPath[1]
  }else{
    parentOrg=parentOrgPath[0]
  }
  return (
    <Container>
      <>
      {/* <Form layout="vertical">
        <Form.Item
          label="Widget Type"
          name="type"
          required
          rules={[{ required: true }]}
        >
          <Select
            listHeight={310}
            onChange={(value: WidgetConfig["type"]) =>
              props.onTypeChange(value)
            }
            options={FILTERED_WIDGETS}
          />
        </Form.Item>
      </Form> */}
      <Row>
        <Col style={{ textAlign: 'left', padding: '0px', margin: '0px' }} xs={24} sm={24} md={24} lg={24} xl={24}>
          <h3>Select Widget Type:</h3>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
          <Col style={{ textAlign: 'center'}} xs={24} sm={24} md={8} lg={8} xl={8}>
            <div onClick={() => props.onTypeChange('bar')} className="container">
              <img width='100' height='100' src={barWidget} className="image" />
              <div className="overlay">
                <div className="text">Bar Widget</div>
              </div>
              <div className="title">
                <h2>Bar Widget</h2>
              </div>
            </div>
          </Col>

          <Col style={{ textAlign: 'center'}} xs={24} sm={24} md={8} lg={8} xl={8}>
            <div onClick={() => props.onTypeChange('line')} className="container">
              <img width='175' height='100' src={lineWidget} className="image" />
              <div className="overlay">
                <div className="text">Line Widget</div>
              </div>
              <div className="title">
                <h2>Line Widget</h2>
              </div>
            </div>
          </Col>

          <Col style={{ textAlign: 'center' }} xs={24} sm={24} md={8} lg={8} xl={8}>
            <div onClick={() => props.onTypeChange('clusterbar')} className="container">
              <img width='175' height='100' src={clusterWidget} className="image" />
              <div className="overlay">
                <div className="text">Cluster Widget</div>
              </div>
              <div className="title">
                <h2>Cluster Widget</h2>
              </div>
            </div>
          </Col>



          
          <Col style={{ textAlign: 'center'}} xs={24} sm={24} md={8} lg={8} xl={8}>
            <div onClick={() => props.onTypeChange('map')} className="container">
              <img width='100' height='100' src={locationWidget} className="image" />
              <div className="overlay">
                <div className="text">Location Widget</div>
              </div>
              <div className="title">
                <h2>Location Widget</h2>
              </div>
            </div>
          </Col>

          <Col style={{ textAlign: 'center'}} xs={24} sm={24} md={8} lg={8} xl={8}>
            <div onClick={() => props.onTypeChange('gauge')} className="container">
              <img width='160' height='100' src={gaugeWidget} className="image" />
              <div className="overlay">
                <div className="text">Gauge Widget</div>
              </div>
              <div className="title">
                <h2>Gauge Widget</h2>
              </div>
            </div>
          </Col>

          {!props.assetDetails?.noOfSignals && 
            <Col style={{ textAlign: 'center'}} xs={24} sm={24} md={8} lg={8} xl={8}>
              <div onClick={() =>  props.onTypeChange('map events') } className="container">
                <img width='175' height='100' src={gpsEventWidget} className="image" />
                <div className="overlay">
                  <div className="text">GPS Event Widget</div>
                </div>
                <div className="title">
                  <h2>GPS Event Widget</h2>
                </div>
              </div>
            </Col>
          }

          

          <Col style={{ textAlign: 'center'}} xs={24} sm={24} md={8} lg={8} xl={8}>
            <div onClick={() => props.onTypeChange('text')} className="container">
              <img width='150' height='50' src={textWidget} className="image" />
              <div className="overlay">
                <div className="text">Text Widget</div>
              </div>
              <div className="title">
                <h2>Text Widget</h2>
              </div>
            </div>
          </Col>

          <Col style={{ textAlign: 'center'}} xs={24} sm={24} md={8} lg={8} xl={8}>
            <div onClick={() => props.onTypeChange('boolean')} className="container">
              <img width='150' height='50' src={booleanWidget} className="image" />
              <div className="overlay">
                <div className="text">Boolean Widget</div>
              </div>
              <div className="title">
                <h2>Boolean Widget</h2>
              </div>
            </div>
          </Col>

          <Col style={{ textAlign: 'center'}} xs={24} sm={24} md={8} lg={8} xl={8}>
            <div onClick={() => props.onTypeChange('tracker')} className="container">
              <img width='150' height='50' src={trackerWidget} className="image" />
              <div className="overlay">
                <div className="text">Tracker Widget</div>
              </div>
              <div className="title">
                <h2>Tracker Widget</h2>
              </div>
            </div>
          </Col>
          {props.assetDetails.orgCmdPgnSpns !== null && props.assetDetails.orgCmdPgnSpns !== "-" &&
          <Col style={{ textAlign: 'center'}} xs={24} sm={24} md={8} lg={8} xl={8}>
            <div onClick={() => props.onTypeChange('command')} className="container">
              <img width='150' height='50' src={commandWidget} className="image" />
              <div className="overlay">
                <div className="text">Command Widget</div>
              </div>
              <div className="title">
                <h2>Command Widget</h2>
              </div>
              <div style={{color: "red" ,paddingBottom:'12px' }}>
                <strong>C2D text only for TP1/TP2 Premium/Standard</strong>
              </div>
            </div>
        </Col>}
          
        <Col style={{ textAlign: 'center'}} xs={24} sm={24} md={8} lg={8} xl={8}>
            <div onClick={() => props.onTypeChange('chat')} className="container">
              <img width='150' height='50' src={chatHistoryWidget} className="image" />
              <div className="overlay">
                <div className="text">Chat History Widget</div>
              </div>
              <div className="title">
                <h2>Chat History Widget</h2>
              </div>
              <div style={{color: "red" ,paddingBottom:'12px' }}>
                <strong>Only for TP1/TP2 Premium/Standard </strong>
              </div>
            </div>
          </Col>
          {parentOrg==='7ff34c90-36b2-11e8-8e2e-6f1fbe0d469d' &&
          <Col style={{ textAlign: 'center'}} xs={24} sm={24} md={8} lg={8} xl={8}>
            <div onClick={() => props.onTypeChange('information')} className="container">
              <img width='100%' height='50' src={informationWidget} className="image" />
              <div className="overlay">
                <div className="text">Information Widget</div>
              </div>
              <div className="title">
                <h2>Information Widget</h2>
              </div>
            </div>
          </Col>}
      </Row>
        
      </>
    </Container>
  );
};

const EditFormContainer: React.FC<Props> = (props) => {
  const [widgetType, setWidgetType] = useState<WidgetType>(props.config?.type);
  const mode = props.config ? "edit" : "create";

  const [form] = Form.useForm();

  useEffect(() => {
    const listener = props.submitTrigger.subscribe(() => form.submit());
    return () => listener.remove();
  }, [form, props.submitTrigger]);

  return (
    <div className="widget-edit-form">
      <ConfigProvider form={{ validateMessages: validationMessages }}>
        {mode === "create" ? (
          <TypeSelectForm
            onTypeChange={typ => {
              setWidgetType(typ);
              form.resetFields();
            }}
            assetDetails={props.assetDetails}
          />
        ) : (
          <></>
        )}
        {widgetType ? (
          getEditForm(widgetType)({
            template: props.template,
            assetDetails: props.assetDetails,
            form: form,
            onFinish: props.onFinish,
            config: props.config,
            onDualChange:props.onDualChange,
            enabledualaxis: props.enabledualaxis,
          })
        ) : (
          <></>
        )}
      </ConfigProvider>
    </div>
  );
};

export default EditFormContainer;
