export function signalsOpen(
    me: any, 
    title: string,
    width: number,
    signalCollectionId: string,
    signalCollectionName: string,
    signalCollectionType: string
) {
    return (dispatch: any) => {
        dispatch({ type: "SIGNALS_OPEN", payload: {title: title, width: width, signalCollectionId: signalCollectionId, signalCollectionName: signalCollectionName, signalCollectionType: signalCollectionType} });
    };
}

export function signalsClose(
    me: any
) {   
    return (dispatch: any) => {
        dispatch({ type: "SIGNALS_CLOSE" });
    };
}