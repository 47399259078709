import React, { FormEvent, useState, useEffect } from "react";
import { Col, Input, Row, Space, Tabs, Tooltip  } from "antd";
import { connect } from "react-redux";
import * as actions from "./actions/csu";
import BackNavigation from "../../utils/Components/BackNavigation";
import "./csu.css";
import { getPermissions } from "../../utils/commonHelpers";
import CSU from "./CSU";
import CSUHistory from "./CSUHistory";
import { appConfigs } from "../../utils/configurations";


const { Search } = Input;
const { TabPane } = Tabs;
interface Props {    
    context: any;
    permissions: any;
    getCalibrations: Function;
    processAction: Function;
    setSearch: Function;
  }

  const OTA: React.FC<Props> = (props) => {
    const [calibrations, setCalibrations] = useState([]);
    const [ searchText, setSearchText ] = useState("");
    const [ passSearchText, setPassSearchText ] = useState("");
    const [ isSearch, setIsSearch ] = useState(false);
    const { contextUser, appContext } = props.context;
    const result = getPermissions(props.permissions.permissions.asset_widgets);
    const [activeTab, setActiveTab] = useState("1");
    const onSearchChange = (e: any) => {
        setIsSearch(false);
        let value = e.target.value.trim();
        setSearchText(value || "");
    }

    const onSearch = (searchText: string) => {
        setSearchText(searchText || "");
        setPassSearchText(searchText || "");
        props.setSearch(passSearchText);
        setIsSearch(true);
    }

    const onTabChange = (key:string) => {
        setActiveTab(key);
     }
    
    const resetSearch = () => {
        setIsSearch(false);
    }
 
    return(
        
        <>
        {
            (appConfigs.app.enableCumminsUI)&&
        <div data-id="ota-container" className="layout-content">
            <Row gutter={8}>
                <Col xs={24} lg={8}>
                    <div className="content-header page-title">
                        <div style={{ display: "flex" }}>
                            <Tooltip title="Go To Previous Page">
                                <BackNavigation />
                            </Tooltip>
                            ECM OTA Management
                        </div>
                    </div>
                </Col>
                <Col xs={24} lg={14} className="content-header-controls">
                        <Space size="middle">
                            {
                                //(permissions.isRead) &&
                                <Search
                                    data-id="ecm-ota-search-field"
                                    key="search-field"
                                    placeholder="Search by esn, version, mastertag"
                                    enterButton="Search"
                                    size="middle"
                                    allowClear
                                    onSearch={onSearch}
                                    onChange={onSearchChange}
                                    className="search-field"
                                    value={searchText}
                                /> 
                            }                    
                        </Space>
                    </Col>
            </Row>
            <Row gutter={8}>

                    <Tabs activeKey={activeTab} size="large" type="card" onChange={onTabChange}>
                        {                            
                            <TabPane tab="Calibrations" key="1" data-id="cmns-ota-all-tab-button">
                                <CSU tabData = {activeTab} search = {passSearchText} isSearch = {isSearch} resetSearch = {resetSearch}></CSU>
                            </TabPane> 
                        }
                        {                            
                            <TabPane tab="History" key="2" data-id="cmns-ota-history-tab-button">
                                <CSUHistory tabData = {activeTab} search = {passSearchText} isSearch = {isSearch} resetSearch = {resetSearch}></CSUHistory>
                            </TabPane>
                        }                        
                    </Tabs>           
                </Row>
            </div>
        }
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
      context: state.contextReducer.context,
      permissions: state.contextReducer.data,
      
    };
  };

export default connect(mapStateToProps, actions)(OTA);
