import React, { useRef, useLayoutEffect } from "react";

import GaugeChart, { Options, Signal, Rules } from "./Gauge";
import { CommonWidgetProps } from "../../models";
import { inputSignalMapper, signalLabel } from "../common";
import useDomNode from "../../../../lib/use-dom-node";
import { connect } from "react-redux";
import * as actions from "../../../../actions";

interface Props extends CommonWidgetProps {
  signalRData?: any;
  settings?: Options;
}

const createSignals = inputSignalMapper<Signal>((meta, data,userPreferences) => ({
  signalId: meta.id,
  name: signalLabel(meta,userPreferences),
  values: [data[0]],
  color: meta.color,
}));

const GaugeWidget: React.FC<Props> = (props) => {
  const [node, setNode] = useDomNode<HTMLDivElement>();
  const gaugeRef = useRef<GaugeChart>();

  const { signals, template, data, settings,state } = props;

  let signalList = createSignals(signals, template, data,state.temperature.value);

  /* updating with live value if present (SignalR) */
  if (Object.keys(props.signalRData.data).length > 0) {
    signalList = signalList.map((signal: Signal) => {
      if (props.signalRData.data.hasOwnProperty(signal.signalId)){
        signal.values[0].value = props.signalRData.data[signal.signalId][0].value
        signal.values[0].timestamp = new Date(props.signalRData.data[signal.signalId][0].bt - props.signalRData.data[signal.signalId][0].time)
      }
      return signal
    })
  }


  let rules: Rules = {
    criticalMax : -1,
    criticalMin : -1,
    warnMax : -1,
    warnMin : -1
  };

  if (signalList.length > 0) {
    for (let index = 0; index < template.rules.length; index++) {
      const element = template.rules[index];
      if (element.signalId === props.signals[0].id) {
        rules.criticalMax = element.criticalHigh!;
        rules.criticalMin = element.criticalLow!;
        rules.warnMax = element.warningHigh!;
        rules.warnMin = element.warningLow!;
      }
    }
  }

  useLayoutEffect(() => {
    if (node && data.size > 0 && signalList.length > 0) {
      const gauge = new GaugeChart(
        node,
        signalList,
        rules,
        settings,
        state.timezone
      );
      gaugeRef.current = gauge;
      return () => gauge.destroy();
    }
  }, [node, signals, rules, signalList, template, data, settings]);

  return <div style={{ height: "95%" }} ref={setNode} />;
};

// export default GaugeWidget;
const mapStateToProps = (state: any) => {
  return {
    signalRData: state.contextReducer.signalRData,
  };
};
export default connect(
  mapStateToProps,
  // actions
)(GaugeWidget);
