import React, { useState, useEffect } from "react";
import {  
  DETAILS as WIDGET_DETAILS,
} from "../widgets";
import { Template } from "../models";
import { Form, Select, ConfigProvider } from "antd";
import Event from "../../../lib/event";
import ExportForm from "./ExportForm";
import { WidgetExportConfig } from '.';
import validationMessages from "./validation-messages";
import {WidgetFilterConfig} from "../../Dashboard2/WidgetFilterForm/index"
type FormInstance = ReturnType<typeof Form.useForm>[0];

interface Props {
  config?: WidgetExportConfig;
  onFinish: ExportFormProps["onFinish"];
  submitTrigger: Event;
  filterConfig?:WidgetFilterConfig
}

export interface ExportFormProps {
  config?: WidgetExportConfig;  
  onFinish: (wfg: WidgetExportConfig) => void;
  form: FormInstance;
  filterConfig?: WidgetFilterConfig;
  layout?: "horizontal" | "vertical";
}


const ExportFormContainer: React.FC<Props> = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    const listener = props.submitTrigger.subscribe(() => form.submit());
    return () => listener.remove();
  }, [form, props.submitTrigger]);

  const filterProps: ExportFormProps = {
    config: props.config,
    form: form,
    onFinish: props.onFinish,
    filterConfig:props.filterConfig,
    layout: "vertical"
   }

  return (
    <div className="widget-export-form">      
        <ExportForm {...filterProps} />
    </div>
  );
};

export default ExportFormContainer;
