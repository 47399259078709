import React, { FormEvent, useState, useEffect } from "react";
import { Button, Col, Drawer, Input, Row, Space, Tabs, Tooltip  } from "antd";
import { connect, useDispatch } from "react-redux";
import * as actions from "./actions/csu";
import BackNavigation from "../../utils/Components/BackNavigation";
import "./csu.css";
import { getPermissions } from "../../utils/commonHelpers";
import CSU from "./CSU";
import CSUHistory from "./CSUHistory";
import { appConfigs } from "../../utils/configurations";
import CSUInfo from "./CSUInfo";


const { TabPane } = Tabs;
interface Props {    
    context: any;
    permissions: any;
    csuMaintClose: Function; 
    getCalibrations: Function;
    processAction: Function;
     csuList:any;
     assetMaintTabChange: Function;
  }

  const CalibrationMaint: React.FC<Props> = (props) => {
   // const [activeTab, setActiveTab] = useState("1");
    const { csuList } = props;
    const dispatch = useDispatch();
    console.log("CSU Maint: "+ JSON.stringify(csuList));
    const  onDrawerClose = () => {
        props.csuMaintClose();
    };

    const onTabChange = (key:string) => {
        props.assetMaintTabChange(key);
    };
     return(
        
        <>
            <Row gutter={8}>
            <Drawer
                data-id="csu-maint-drawer" 
                title={csuList.title}
                width={csuList.width}
                onClose={onDrawerClose}
                visible={csuList.visible}
                destroyOnClose={true}
                footer={
                    <Space size="large" style={{ float: 'right' }}>
                    <Button data-id="csu-maint-close-button" type="default" onClick={onDrawerClose}>
                        {appConfigs.settings.drawers.closeButtonText}
                    </Button>
                    </Space>
                } >
                     <Tabs activeKey={csuList.activeTab} size="large" type="card" onChange={onTabChange}>
                        {
                            
                            <TabPane tab={csuList.tabs[0].title} key="1" data-id="csu-maint-info-tab-button">
                                <CSUInfo tabData={csuList.activeTab} ></CSUInfo>
                            </TabPane> 
                        }
                        
                        
                    </Tabs>         

            </Drawer>


            </Row>
        </>
     )
  };

  const mapStateToProps = (state: any) => {
    return {
      context: state.contextReducer.context,
      permissions: state.contextReducer.data,
      csuList: state.csuReducer.csuState.csu.csuList.calibrationMaint,
    };
  };

//   const mapDispatchToProps = (dispatch: (arg0: { type: string; }) => any) => {
//     return {
//       // dispatching plain actions
//       calibrationMaintOpen: (record: any) => dispatch({ type: 'CALIBRATION_MAINT_OPEN'  }),
//       csuMaintClose: () => dispatch({ type: 'CSU_MAINT_CLOSE' }),
//       //reset: () => dispatch({ type: 'RESET' }),
//     }
//   }

export default connect(mapStateToProps, actions)(CalibrationMaint);
//export default connect(mapStateToProps, mapDispatchToProps)(CalibrationMaint);
