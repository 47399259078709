import React, { useRef, useLayoutEffect, useMemo } from "react";

import TrackerChart, { Props as TrackerProps } from "./TrackerChart";
import { CommonWidgetProps, SignalId } from "../../models";
import { AxisRange } from "../models";
import { signalLabel } from "../common";
import useDomNode from "../../../../lib/use-dom-node";
import { connect } from "react-redux";
import * as actions from "../../../../actions";

export interface SignalOpts {
  signalId: SignalId;
  opts: {
    axisRange?: AxisRange;
  };
}

const TrackerComponent: React.FC<TrackerProps> = (props) => {
  const [node, setNode] = useDomNode<HTMLDivElement>();
  const chartRef = useRef<TrackerChart>();
  const { signal, rule, opts, timezone } = props;

  useLayoutEffect(() => {
    if (node) {
      chartRef.current = new TrackerChart(node, signal, rule, opts, timezone);
      return () => chartRef.current?.destroy();
    }
  }, [node, signal, rule, opts,timezone.value]);

  return (
    <>
      <span>{props.signal.name}</span>
      <div style={{ height: "100%" }} ref={setNode} />
    </>
  );
};

function useTrackerProps({
  template,
  data,
  state,
  signals,
  settings,
  signalRData,
}: TrackerWidgetProps): TrackerProps[] {
  return useMemo(() => {
    let result: TrackerProps[] = [];

    for (let id of signals) {
      const datapts = data.get(id.id);
      const metadata = template.signals.find((s) => s.id === id.id);
      if (datapts && metadata) {
        result.push({
          signal: {
            signalId: id.id,
            name: signalLabel(metadata,state.temperature.value, false),
            values: datapts ? [{ ...datapts[0] }] : [{ ...{ value: 0, timestamp: new Date() } }],
          },
          rule: template.rules && template.rules.length > 0 ? template.rules.find((r) => r.signalId === id.id): undefined,
          opts: settings && settings.length > 0 ? settings?.find((s) => s.signalId === id.id)?.opts: undefined,
        });
      }
    }


    /* updating with live value if present (SignalR) */
    if (Object.keys(signalRData?.data).length > 0) {
      result = result.map((obj: TrackerProps) => {
        if (obj.signal?.signalId) {
          if (signalRData.data.hasOwnProperty(obj.signal?.signalId)) {
            obj.signal.values[0].value = signalRData.data[obj.signal?.signalId][0].value
            obj.signal.values[0].timestamp = new Date(signalRData.data[obj.signal?.signalId][0].bt - signalRData.data[obj.signal?.signalId][0].time)
          }
        }
        return obj
      })
    }

    return result;
  }, [template, data, signals, settings, signalRData]);
}

export interface TrackerWidgetProps extends CommonWidgetProps {
  settings?: SignalOpts[];
  signalRData?: any;
}

const TrackerWidget: React.FC<TrackerWidgetProps> = (props) => {
  const settings = useTrackerProps(props);
  return (
    <>
      {settings.map((s, i) => (
        <div key={i} style={{ height: "85px" }}>
          <TrackerComponent {...s}{...props.state} />
        </div>
      ))}
    </>
  );
};

// export default TrackerWidget;
const mapStateToProps = (state: any) => {
  return {
    signalRData: state.contextReducer.signalRData,
  };
};
export default connect(
  mapStateToProps,
  // actions
)(TrackerWidget);

