import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function userOrgsSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "USERS_ORGS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}   

export function userOrgsGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    userId: string,
    role: string,
    page: number = 1
) {
    return async (dispatch: any) => { 
        //ops-2680: added request paramerter "role", because a non-admin user should not see "admin" role in the "role" dropdown in "organization" tab.             
        Axios.get(            
            appConfigs.server.URL + "/ui/api/orgs/user/" + userId, 
            {
                responseType: "json",        
                headers: {},
                params: {
                    allData: true,
                    role: role,
                    page: page,
                    pageSize: appConfigs.tables.pageSize
                }
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.orgUsers);
                }
                dispatch({ type: "USERS_ORGS_GET_SUCCESS", payload: {data: response.data.data.orgUsers, page: page} });
            }
        )
        .catch(function (error) {
            errorResponse(me, error, false);
            if (callbackFnFailure != null) {
                callbackFnFailure(error);
            }
        });            
    };
}    

export function userOrgsRole(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgUserId: string,
    role: string,
    page: number = 1
) {
    return async (dispatch: any) => {
        Axios.put(
            appConfigs.server.URL + "/ui/api/orgs/user/" + orgUserId + "/" + role,
            {},
            {
                responseType: "json"
            }
        )
        .then(response => {
                let orgUsers = response.data.data.orgUsers == null || (response.data.data.orgUsers.length || 0) === 0 ? [] : response.data.data.orgUsers;

                if (callbackFnSuccess != null) {
                    callbackFnSuccess(orgUsers, orgUserId, role);           
                }
                if (orgUsers.length > 0) {
                    dispatch({ type: "USERS_ORGS_ROLE_UPDATE_SUCCESS", data: orgUsers});
                }
            }
        )
        .catch(function (error) {
                errorResponse(me, error);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

export function userOrgsRemove(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgUserId: string
) {
    return async (dispatch: any) => {
        Axios.delete(
            appConfigs.server.URL + "/ui/api/orgs/user/" + orgUserId,
            {}
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(orgUserId);           
                }
                dispatch({ type: "USERS_ORGS_REMOVE_SUCCESS", payload: {orgUserId: orgUserId} });
            }
        )
        .catch(function (error) {
                errorResponse(me, error);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

export function userOrgsMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgs: any,
    users: any,
    role:string,
    applyAll:boolean,
    page: number
) {
    return async (dispatch: any) => {        
        Axios.post(
            appConfigs.server.URL +  "/ui/api/orgs/user",
            {
                orgs: orgs,
                users: users,
                role: role,
                applyAll: applyAll
            },
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.orgUsers);
                }
                dispatch({ type: "USERS_ORGS_GET_SUCCESS", payload: {data: response.data.data.orgUsers, page: page} });
            }
        )
        .catch(function (error) {
            errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
    }
}