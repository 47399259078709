import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function alertsDm1SetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "ALERTS_DM1_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}   

export function alertsSignalsSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "ALERTS_SIGNALS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}   

export function alertsCmdHistorySetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "ALERTS_CMD_HISTORY_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
} 

export function alertsDm1(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,    
    page: number = 1, 
    gatewayId?: string,   
    mastertag?: string,
    isSeen?: string,
    timespanFromFilter?: string,
    timespanToFilter?: string,
    nicknameFilter?: string[],
    saFilter?: string[],
    spnFilter?: string[],
    fmiFilter?: string[],
    statusFilter?: string[],
    tagsFilter?: string[]
) {
    return async (dispatch: any) => {       
        let url = "/ui/api/alerts/dm1" + alertsAddAssetUrlParameters(gatewayId || "", mastertag || "");       

        Axios.get(
            appConfigs.server.URL + url, {
                responseType: "json",        
                headers: {},
                params: {
                    isSeen: isSeen,
                    timespanFromFilter: timespanFromFilter,
                    timespanToFilter: timespanToFilter,
                    nicknameFilter: (nicknameFilter || []).join("~"),
                    saFilter: (saFilter || []).join("~"),
                    spnFilter: (spnFilter || []).join("~"),
                    fmiFilter: (fmiFilter || []).join("~"),
                    statusFilter: (statusFilter || []).join("~"),
                    tagsFilter: (tagsFilter || []).join("~"),
                    page: page,
                    pageSize: appConfigs.tables.pageSize
                }
            }
        )
        .then(response => {
                let data = response.data.data.dm1 == null || (response.data.data.dm1.length || 0) === 0 ? [] : response.data.data.dm1;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ 
                    type: "ALERTS_DM1_GET_SUCCESS", 
                    payload: {
                        data: data, 
                        page: page, 
                        appliedFilters: {
                            timespanFrom: timespanFromFilter,
                            timespanTo: timespanToFilter,
                            nickname : nicknameFilter, 
                            sa: saFilter, 
                            spn: spnFilter, 
                            fmi: fmiFilter, 
                            status: statusFilter,
                            tags: tagsFilter
                        }
                    } 
                });                
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
    
}

export function alertsDm1Filters(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any, 
    gatewayId?: string,   
    mastertag?: string,
    timespanFromFilter?: string,
    timespanToFilter?: string,
) {
    return async (dispatch: any) => {       
        let url = "/ui/api/alerts/dm1/filters" + alertsAddAssetUrlParameters(gatewayId || "", mastertag || "");    

        Axios.get(
            appConfigs.server.URL + url, {
                responseType: "json",        
                headers: {},
                params: {
                    timespanFromFilter: timespanFromFilter,
                    timespanToFilter: timespanToFilter
                }
            }
        )
            .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.dm1Filters);
                }
                dispatch({ type: "ALERTS_DM1_FILTERS_GET_SUCCESS", data: response.data.data.dm1Filters });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function markAlerts(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    alertKeys: string[],
    viewAlertType: string,
    alertType: string,
    deleteAlerts: boolean,
    timespanFromFilter?: string,
    timespanToFilter?: string,
    gatewayId?: string,   
    mastertag?: string,   
    nicknameFilter?: string[],
    saFilter?: string[],
    spnFilter?: string[],
    fmiFilter?: string[],
    statusFilter?: string[],
    tagsFilter?: string[]
    ){
        return (dispatch: any) => {
            let url = "/ui/api/alerts/"+alertType+"/"+viewAlertType+"/"+deleteAlerts ;
            Axios.delete(
                appConfigs.server.URL + url, { data : {
                    alertKeys: alertKeys && alertKeys.length>0?(alertKeys|| []).join("~"):null,
                    timespanFromFilter: timespanFromFilter,
                    timespanToFilter: timespanToFilter,                    
                    gatewayId: gatewayId,   
                    mastertag: mastertag,
                    nicknameFilter: nicknameFilter && nicknameFilter.length>0?(nicknameFilter).join("~"):null,
                    saFilter: saFilter && saFilter.length>0?(saFilter || []).join("~"):null,
                    spnFilter: spnFilter && spnFilter.length>0? (spnFilter || []).join("~"):null,
                    fmiFilter: fmiFilter && fmiFilter.length>0?(fmiFilter || []).join("~"):null,
                    statusFilter: statusFilter && statusFilter.length>0?(statusFilter || []).join("~"):null,
                    tagsFilter: tagsFilter && tagsFilter.length>0?(tagsFilter || []).join("~"):null
                }  
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data);
                }
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(error);
            }
            errorResponse(me, error);
        });

        };
}

export function alertsSignals(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    page: number = 1, 
    gatewayId?: string,   
    mastertag?: string,
    isSeen?: string,
    timespanFromFilter?: string,
    timespanToFilter?: string,
    nicknameFilter?: string[],
    statusFilter?: string[],
    tagsFilter?: string[]
) {
    return async (dispatch: any) => {       
        let url = "/ui/api/alerts/signals" + alertsAddAssetUrlParameters(gatewayId || "", mastertag || "");

        Axios.get(
            appConfigs.server.URL + url, {
                responseType: "json",        
                headers: {},
                params: {
                    isSeen: isSeen,
                    timespanFromFilter: timespanFromFilter,
                    timespanToFilter: timespanToFilter,
                    nicknameFilter: (nicknameFilter || []).join("~"),
                    statusFilter: (statusFilter || []).join("~"),
                    tagsFilter: (tagsFilter || []).join("~"),
                    page: page,
                    pageSize: appConfigs.tables.pageSize
                }
            }
        )
        .then(response => {                     
                let data = response.data.data.signals == null || (response.data.data.signals.length || 0) === 0 ? [] : response.data.data.signals;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ 
                    type: "ALERTS_SIGNALS_GET_SUCCESS", 
                    payload: {
                        data: data, 
                        page: page, 
                        appliedFilters: {                            
                            timespanFrom: timespanFromFilter,
                            timespanTo: timespanToFilter,
                            nickname : nicknameFilter, 
                            status: statusFilter,
                            tags: tagsFilter
                        } 
                    } 
                });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function alertsSignalsFilters(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any, 
    gatewayId?: string,   
    mastertag?: string,
    timespanFromFilter?: string,
    timespanToFilter?: string,
) {
    return async (dispatch: any) => {       
        let url = "/ui/api/alerts/signals/filters" + alertsAddAssetUrlParameters(gatewayId || "", mastertag || "");

        Axios.get(
            appConfigs.server.URL + url, {
                responseType: "json",        
                headers: {},
                params: {
                    timespanFromFilter: timespanFromFilter,
                    timespanToFilter: timespanToFilter
                }
            }
        )
            .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.signalsFilters);
                }
                dispatch({ type: "ALERTS_SIGNALS_FILTERS_GET_SUCCESS", data: response.data.data.signalsFilters });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function alertsCmdHistory(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,    
    page: number = 1, 
    gatewayId?: string,   
    mastertag?: string,
    viewHistoryType?: string,
    timespanFromFilter?: string,
    timespanToFilter?: string,
    nicknameFilter?: string[],
    commandFilter?: string[],
    operatorFilter?: string[],
    userFilter?: string[],
    typeFilter?: string[],
    statusFilter?: string[],
    tagsFilter?: string[]
) {
    return async (dispatch: any) => {       
        let url = "/ui/api/alerts/cmd-history" + alertsAddAssetUrlParameters(gatewayId || "", mastertag || "");       

        Axios.get(
            appConfigs.server.URL + url, {
                responseType: "json",        
                headers: {},
                params: {
                    timespanFromFilter: timespanFromFilter,
                    timespanToFilter: timespanToFilter,
                    nicknameFilter: (nicknameFilter || []).join("~"),
                    commandFilter: (commandFilter || []).join("~"),
                    operatorFilter: (operatorFilter || []).join("~"),
                    userFilter: (userFilter || []).join("~"),
                    typeFilter: (typeFilter || []).join("~"),
                    statusFilter: (statusFilter || []).join("~"),
                    tagsFilter: (tagsFilter || []).join("~"),
                    viewHistoryType: (viewHistoryType || 'latest'),
                    page: page,
                    pageSize: appConfigs.tables.pageSize
                }
            }
        )
        .then(response => {
                let data = response.data.data.cmdHistory == null || (response.data.data.cmdHistory.length || 0) === 0 ? [] : response.data.data.cmdHistory;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ 
                    type: "ALERTS_CMD_HISTORY_GET_SUCCESS", 
                    payload: {
                        data: data, 
                        page: page, 
                        appliedFilters: {
                            timespanFrom: timespanFromFilter,
                            timespanTo: timespanToFilter,
                            nickname : nicknameFilter, 
                            command: commandFilter, 
                            operator: operatorFilter, 
                            user: userFilter, 
                            type: typeFilter, 
                            status: statusFilter,
                            tags: tagsFilter
                        }
                    } 
                });                
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
    
}

export function alertsCmdHistoryFilters(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any, 
    gatewayId?: string,   
    mastertag?: string,
    timespanFromFilter?: string,
    timespanToFilter?: string,
) {
    return async (dispatch: any) => {       
        let url = "/ui/api/alerts/cmd-history/filters" + alertsAddAssetUrlParameters(gatewayId || "", mastertag || "");    

        Axios.get(
            appConfigs.server.URL + url, {
                responseType: "json",        
                headers: {},
                params: {
                    timespanFromFilter: timespanFromFilter,
                    timespanToFilter: timespanToFilter
                }
            }
        )
            .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.cmdHistoryFilters);
                }
                dispatch({ type: "ALERTS_CMD_HISTORY_FILTERS_GET_SUCCESS", data: response.data.data.cmdHistoryFilters });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}


export function getAsset(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    mastertag: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/assets", {
                responseType: "json",        
                headers: {},
                params: {
                    allData: true,
                    mastertag: mastertag
                }
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    let record = response.data.data.assets == null || (response.data.data.assets.length || 0) === 0 ? {} : response.data.data.assets[0];
                    callbackFnSuccess(mastertag, record);
                }
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error);
            }
            errorResponse(me, error);
        });
    };
}

function alertsAddAssetUrlParameters(gatewayId:string, mastertag:string):string {
    if ((gatewayId || "") !== "" || (mastertag || "") !== "") {
        return "?" +
        ((gatewayId || "") !== "" ? "gatewayId=" + gatewayId : "") + 
        ((gatewayId || "") !== "" && (mastertag || "") !== "" ? "&" : "") + 
        ((mastertag || "") !== "" ? "mastertag=" + mastertag : "");
    }  
    else  {
        return "";
    }
}

export function getCumminsRecommendations(
    me: any,
    callbackFnSuccess: any,
    callbackFnFailure: any,
    alertId: string,
    gatewayId: string,
    sa: number,
    spn: number,
    fmi: number,
    oc: number,
    detectionTime:string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/alerts/dm1/cmns", {
            responseType: "json",
            headers: {},
            params: {
                gatewayId: gatewayId,
                sa: sa,
                spn: spn,
                fmi: fmi,
                oc: oc,
                detected_at:detectionTime
            }
        }
        )
            .then((response: any) => {
                 if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.dm1_cmns);
                }
             })
            .catch(function (error: any) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(me, error);
                }
                errorResponse(me, error);
            });

    }
}

export function getParkerRecommendations(
    me: any,
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: string,
    sa: number,
    spn: number,
    fmi: number
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/alerts/dm1/parker-recommendations", {
            responseType: "json",
            headers: {},
            params: {
                orgId: orgId,
                sa: sa,
                spn: spn,
                fmi: fmi
            }
        }
        )
            .then((response: any) => {
                 if (callbackFnSuccess != null) {
                    console.log("after dm1recommendations API call response.data", response.data.data)
                    callbackFnSuccess(response.data.data.ParkerDmRecommendations);
                }
             })
            .catch(function (error: any) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(me, error);
                }
                errorResponse(me, error);
            });

    }
}

export function getActivityAlerts(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    page: number=1,
    searchText?: string,
) {
    return async (dispatch: any) => {
        
        Axios.get(
            appConfigs.server.URL + "/ui/api/activityalerts", {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText,
                    page: page,
                    pageSize: appConfigs.tables.pageSize
                }
            }
        )
        .then(response => {        
                let data = response.data.data.activityalerts == null || (response.data.data.activityalerts.length || 0) === 0 ? [] : response.data.data.activityalerts;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "ACTIVITY_GET_ALERTS_SUCCESS", payload: {data: data, page: page} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function activityAlertsSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "ACTIVITY_ALERTS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
} 