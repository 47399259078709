import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function orgsState(state = initialState, action:any) {
    let data:any = null;

    switch (action.type) {    
        case "ORGS_SET_PAGINATION":
            return immerProduce(state, draft => {
                draft.orgsList.tableConfig.pagination.onChange = action.onPaginationChange;
            });
        case "ORGS_LIST_SET_SEARCH":
            return immerProduce(state, draft => {
                draft.orgsList.mode = "all";
                draft.orgsList.searchText = action.searchText;
                draft.orgsList.orgId = "";
            });
        case "ORGS_LIST_SET_DISPLAY":
            return immerProduce(state, draft => {
                draft.orgsList.mode = action.mode;
                draft.orgsList.searchText = action.searchText;
                draft.orgsList.orgId = action.orgId;
                draft.orgsList.orgName = action.orgName;
            });
        case "ORGS_GET_ORGS_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.orgsList.refreshOnUpdate = false;
                draft.orgsList.tableData = data;
                draft.orgsList.tableConfig.pagination.total = (data.length || 0) > 0 ? data[0].totalRecords : 0;
                draft.orgsList.tableConfig.pagination.current = action.payload.page;
            });
        case "ORGS_MAINT_OPEN":
            return immerProduce(state, draft => {
                const 
                    payload = action.payload,
                    instance = Math.random();
                    
                draft.orgsList.orgMaint.instance = instance;
                draft.orgsList.orgMaint.width = payload.width;
                draft.orgsList.orgMaint.visible = true;
                draft.orgsList.orgMaint.title = payload.title;
                draft.orgsList.orgMaint.record = payload.record;
                draft.orgsList.orgMaint.activeTab = "1";
                draft.orgsList.orgMaint.tabs[0].disabled = false;
                draft.orgsList.orgMaint.tabs[0].instance = instance;
                draft.orgsList.orgMaint.tabs[1].disabled = Object.keys(payload.record).length === 0;
                draft.orgsList.orgMaint.tabs[1].instance = instance;
                draft.orgsList.orgMaint.tabs[2].disabled = Object.keys(payload.record).length === 0;
                draft.orgsList.orgMaint.tabs[2].instance = instance;
            });                
        case "ORGS_MAINT_CLOSE":
            return immerProduce(state, draft => {
                draft.orgsList.orgMaint.tabs[2].searchText = "";
                draft.orgsList.orgMaint.visible = false;
            });   
        case "ORGS_LIST_REFRESH_ON_UPDATE":
            return immerProduce(state, draft => {
                draft.orgsList.refreshOnUpdate = true;
            });        
        case "ORGS_MAINT_REFRESH_INSTANCE":
            return immerProduce(state, draft => {
                const instance = Math.random();
                action.instances.forEach((element:number) => {
                    draft.orgsList.orgMaint.tabs[element].instance = instance;
                });
            });                          
        case "ORG_MAINT_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.orgsList.orgMaint.record = data;
                draft.orgsList.orgMaint.title = "Edit Org :: " + data.name;
            });                
        case "ORGS_MAINT_TAB_CHANGE":
            return immerProduce(state, draft => {
                draft.orgsList.orgMaint.activeTab = action.tab;
            });                
        case "ORGS_MAINT_TABS_DISABLED":
            return immerProduce(state, draft => {
                draft.orgsList.orgMaint.tabs[1].disabled = action.state;
                draft.orgsList.orgMaint.tabs[2].disabled = action.state;
                draft.orgsList.orgMaint.tabs[3].disabled = action.state;
            });                    
        case "ORGS_COUNTRIES_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.orgsList.orgMaint.tabs[0].countries! = data;
            });
        case "ORGS_PHONE_COUNTRIES_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.orgsList.orgMaint.tabs[0].phoneCountries! = data;
            });                
        case "ORGS_COUNTRY_STATES_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.orgsList.orgMaint.tabs[0].states! = data;
            });
        case "ORGS_ALL_CAPABILITIES":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.orgsList.orgMaint.tabs[1].allCapabilities = data;
            })
        case "ORGS_LANGUAGES_SUCCESS":
            return immerProduce(state, draft => {
                draft.orgsList.orgMaint.tabs[0].languages! = action.data;
            });                
        case "ORG_MAINT_ADMIN_SET_PAGINATION":
            return immerProduce(state, draft => {
                draft.orgsList.orgMaint.tabs[2].tableConfig!.pagination.onChange = action.onPaginationChange;
            });
        case "ORG_MAINT_ADMIN_SET_SEARCH":
            return immerProduce(state, draft => {
                draft.orgsList.orgMaint.tabs[2].searchText = action.searchText;                
            });           
                        
        case "ORG_MAINT_ADMIN_ORG_USERS_GET_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.orgsList.orgMaint.tabs[2].tableData! = data;
                draft.orgsList.orgMaint.tabs[2].tableConfig!.pagination.total = (data !== null && (data.length || 0) > 0 ? data[0].totalRecords : 0);
                draft.orgsList.orgMaint.tabs[2].tableConfig!.pagination.current = action.payload.page;
            });            
        case "ORG_MAINT_ADMIN_USERS_ADMINSTERED_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.orgsList.orgMaint.tabs[2].usersAdministered! = data;
            });
        case "ORGS_GLOBAL_SUBSCRIPTIONS_SET_PAGINATION":
            return immerProduce(state, draft => {
                draft.orgsList.orgMaint.tabs[3].tableConfig!.pagination.onChange = action.onPaginationChange;
            });
        case "ORGS_GLOBAL_SUBSCRIPTIONS_SET_SEARCH":
            return immerProduce(state, draft => {
                draft.orgsList.orgMaint.tabs[3].searchText = action.searchText;
            });
        case "ORGS_GLOBAL_SUBSCRIPTIONS_GET_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.orgsList.orgMaint.tabs[3].tableData! = data;
                draft.orgsList.orgMaint.tabs[3].tableConfig!.pagination.total = (data.length || 0) > 0 ? data[0].groupsTotal : 0;
                draft.orgsList.orgMaint.tabs[3].tableConfig!.pagination.current = action.payload.page;

            });
        case "ORGS_GLOBAL_SUBSCRIPTIONS_MAINT_SUCCESS":
            return immerProduce(state, draft => {
                const payload = action.payload;
                if (payload.scope === "alert") {
                    updateGlobal(draft, payload.record, payload.notification, payload.action);
                }
                else {
                    updateGlobalAlerts(draft, payload.record, payload.notification, payload.action);
                }
            });
        case "ORG_TAGS_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, (draft) => {
                draft.orgsList.orgMaint.selectedTag = data;
            });    
        case "ORGS_GET_GLOBAL_NOTES":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.orgsList.orgMaint.tabs[5].tableData = data;
            });
        case "ORG_GLOBAL_NOTES_MAINT_SUCCESS":
                data = action.payload.data;
                return immerProduce(state, draft => {
                    draft.orgsList.orgMaint.tabs[5].tableData = data;
                    //draft.orgsList.orgMaint.title = "Edit Global Notes :: " + data.name;
                });  
        case "ORGS_GET_GLOBAL_NOTES_REFRESH_ON_UPDATE":
                return immerProduce(state, draft => {
                 draft.orgsList.refreshOnUpdate = true;
                }); 
        default:
           return state;
    }    
}

const updateGlobal = (draft:any, record:any, notification: string, action: string) => {       
    draft.orgsList.orgMaint.tabs[3].tableData.forEach(function (global:any) {
        if (global.key === record.key) {
            // global.children.forEach(function (alert:any) {
            //     if (notification === "sms") {
            //         alert.alertSms = (action === "plus");                   
            //     }
            //     if (notification === "email") {
            //         alert.alertEmail = (action === "plus");                   
            //     }
            // });
            if (notification === "sms") {
                global.smsIndeterminate = false;
                global.smsChecked = (action === "plus");
            }
            else {
                global.emailIndeterminate = false;
                global.emailChecked = (action === "plus");
            }
        }
    });
}

const updateGlobalAlerts = (draft:any, record:any, notification: string, action: string) => {     
    draft.orgsList.orgMaint.tabs[3].tableData.forEach(function (global:any) {   
        global.children.forEach(function (alert:any) {
            if (alert.key === record.key) {
                let checkedCount = 0;
                if (notification === "sms") {
                    alert.alertSms = (action === "plus");                   
                }
                if (notification === "email") {
                    alert.alertEmail = (action === "plus");                    
                }
                
                global.children.forEach(function (alertCount:any) {
                    checkedCount = checkedCount + (notification === "sms" && alertCount.alertSms ? 1 : 0) + (notification === "email" && alertCount.alertEmail ? 1 : 0);
                });
                if (notification === "sms") {
                    global.smsIndeterminate = (checkedCount > 0 && checkedCount < global.children.length);
                    global.smsChecked = (checkedCount === global.children.length);
                }
                else {
                    global.emailIndeterminate = (checkedCount > 0 && checkedCount < global.children.length);
                    global.emailChecked = (checkedCount === global.children.length);
                } 
            }
        });
    });
}