import Axios from "axios";
import { appConfigs } from "../utils/configurations";

export function reportAccessGet(
    me?: any,
    callbackSuccessFn?: any,
    callbackErrorFn?: any,
    selectedOrgId?: any
) {
    return async (dispatch: any) => {
        await Axios.get(
            appConfigs.server.URL + `/ui/api/orgReports/${selectedOrgId}`, {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {                            
                if (callbackSuccessFn != null) {
                    callbackSuccessFn(response.data);
                }
                dispatch({ type: "REPORT_ACCESS_SUCCESS", payload: {data: response.data} });
            }
        )
        .catch(function (error) {                                
                if (callbackErrorFn != null) {
                    callbackErrorFn(error);
                }
                dispatch({ type: "REPORT_ACCESS_FAILURE", error: error });
            }
        );
    };
}