import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function signalListSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "SIGNAL_LIST_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}   

export function signalListSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "SIGNAL_LIST_SET_SEARCH", searchText: searchText });
    }
}   

export function signalListRefreshOnUpdate(
    me: any
) {
    return (dispatch: any) => {
        dispatch({ type: "SIGNAL_LIST_REFRESH_ON_UPDATE" });
    };
}

export function getSignals(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string,
    signalCollectionId: string,
    signalCollectionName: string,
    signalCollectionType: string,
    page: number = 1,
    searchText?: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/signals/template/" + templateId + "/collection/" + signalCollectionId, {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText,
                    page: page,
                    pageSize: appConfigs.tables.pageSize
                }
            }
        )
        .then(response => {          
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.signals);
                }
                dispatch({ type: "SIGNALS_GET_SUCCESS", payload: {signalCollectionId: signalCollectionId, signalCollectionName: signalCollectionName, signalCollectionType: signalCollectionType, data: response.data.data.signals, page: page} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function signalDelete(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string,
    signalId: string
) {
    return async (dispatch: any) => {
        Axios.delete(
            appConfigs.server.URL + "/ui/api/signals/template/" + templateId + "/signal/" + signalId,
            {}
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(signalId);           
                }
                dispatch({ type: "SIGNAL_DELETE_SUCCESS" });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}