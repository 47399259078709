import { message } from "antd";
import { appConfigs } from "../configurations";
import { loadPage, getLoginUrl, loadingIndicatorStart, clientStorageClear, messageExists } from "../commonHelpers";
import Axios from "axios";

export const successResponse = (
    me: any, 
    message: string
) => {

    me.setState({ 
        formSuccess: true, 
        formSuccessMessage: message || appConfigs.settings.form.alerts.successDefaultMessage,
        formError: false,
        formErrorMessage: "",
        formCommit: true,
        formWarning: false, 
        formWarningMessage: "",
    });
}

export const warningResponse = (
    me: any, 
    message: string
) => {

    me.setState({ 
        formWarning: true, 
        formWarningMessage: message,
        formError: false,
        formErrorMessage: "",
        formCommit: true,
        formSuccess: false,
        formSuccessMessage: ""
    });
}

export const errorResponse = (
    me: any, 
    error: any,
    formContext: boolean = true
) => {

    let errorStatus:number = !error.response ? 999 : (error.response.status || 999);
    if (errorStatus === 400) {        
        let generalError:string = "";
        let formErrors:any = [];
        let fieldErrors:any = [];

        if (error.response.data != null && error.response.data.errors != null && (error.response.data.errors.length || 0) !== 0) {
            error.response.data.errors.forEach(function(record:any) {
                if (record.type === "error" && !(record.message || "").startsWith("APP-ERR#")) {
                    generalError = record.message || appConfigs.errors.api.defaultErrorMessage;        
                }
                if (record.type === "form" && !(record.message || "").startsWith("APP-ERR#")) {
                    formErrors.push(record.message || "Empty error message");        
                }
                if (record.type === "field" && !(record.message || "").startsWith("APP-ERR#")) {
                    let fieldError:string[] = [];
                    error.response.data.errors.forEach(function(field:any) {
                        if (field.type === "field" && field.reference === record.reference) {
                            fieldError.push(field.message);                        
                        }
                    });
                    fieldErrors.push(
                        {
                            name: record.reference,
                            errors: fieldError
                        }
                    );
                }
            });
        }
        if (generalError !== "" && !(generalError || "").startsWith("APP-ERR#")) {
            const messageText = generalError;
            if (!messageExists(messageText)) {
                setTimeout(function() {
                    message.error(messageText);
                }, 100);
            }
        }
        else {
            if (formContext) {
                if (fieldErrors.length > 0) {
                    me.formRef.current!.setFields(fieldErrors);
                }
                if (formErrors.length > 0 || fieldErrors.length > 0) {
                    //As this is a common function Fix should be validated with all pages
                    // let errorMsgs: string[] = [];
                    // if(fieldErrors.length > 0 && fieldErrors) {
                    //     errorMsgs = fieldErrors.reduce((acc: any, elem: any) => {
                    //         const errorArray = elem.errors.map((e: any)=> e);
                    //         acc = [...acc, ...errorArray];
                    //         return acc;
                    //     },[]); 
                    //     if(errorMsgs.length > 1) {
                    //         errorMsgs = errorMsgs.filter((v, i, a) => a.indexOf(v) === i);
                    //     }
                    // }                    
                                      
                        
                    me.setState({ 
                        formSuccess: false, 
                        formSuccessMessage: "",
                        formError: true, 
                        //formErrorMessage: formErrors.length > 0 ? formErrors : fieldErrors.length > 0  ?  errorMsgs: []
                        formErrorMessage: formErrors
                    });
                }
                if (formErrors.length === 0 && fieldErrors.length === 0) {
                    const messageText = appConfigs.errors.api.defaultErrorMessage;
                    if (!messageExists(messageText)) {
                        setTimeout(function() {
                            message.error(messageText);
                        }, 100);
                    }
                }
            }
            else {
                if (formErrors.length > 0) {
                    formErrors.forEach((error:string) => message.error(error));
                }
                if (fieldErrors.length > 0) {
                    formErrors.forEach((error:string) => message.error(error));
                }
                if (formErrors.length === 0 && fieldErrors.length === 0) {
                    const messageText = appConfigs.errors.api.defaultErrorMessage;
                    if (!messageExists(messageText)) {
                        setTimeout(function() {
                            message.error(messageText);
                        }, 100);
                    }
                }
            }
        }
    }
    else if (errorStatus === 401) {
        if (window.location.pathname.toLowerCase().indexOf("/login") === -1) {
            const messageText = "Session expired - Please sign in again";            
            if (!messageExists(messageText)) {
                setTimeout(function() {
                    message.error(messageText);
                }, 100);
            }
            loadingIndicatorStart();
            setTimeout(function() {
                const loginUrl = getLoginUrl();
                clientStorageClear();
                loadPage(loginUrl);
            }, 2500); 
        }
    }
    else if (errorStatus === 404) {
    }
    else {
        if (!messageExists(appConfigs.errors.api.defaultErrorMessage)) {
            setTimeout(function() {
                message.error(appConfigs.errors.api.defaultErrorMessage);
            }, 100);
        }
    }
    
}

export const resetFormErrors = (
    me: any, 
    formRef: any
) => {

    let fieldErrors:any = [];

    formRef.getFieldsError().forEach((record: any, index: number) => {
        fieldErrors.push(
            {
                name: record.name[0],
                errors: null
            }
        );
    });

    formRef.setFields(fieldErrors);
}

