import React from "react";
import * as LineChart from "./LineChart";
import * as ChatHistory from "./Chat";
import * as BarChart from "./BarChart";
import * as BooleanWidget from "./BooleanWidget";
import * as TextWidget from "./TextWidget";
import * as CommandWidget from "./CommandWidget";
import * as TrackerWidget from "./TrackerWidget";
import * as GaugeWidget from "./GaugeWidget";
import * as LocationWidget from "./LocationWidget";
import * as EventsLocationWidget from "./EventsLocationWidget";
import * as InformationWidget from "./InformationWidget";
import { Template } from "../models";
import { DataStore, State } from "../state";
import { WidgetDetails } from "./models";
import { EditFormProps } from "../WidgetEditForm";
import * as ClusterBarWidget from "./ClusterBar";

export const DETAILS: WidgetDetails[] = [
  BarChart.details,
  BooleanWidget.details,
  GaugeWidget.details,
  LineChart.details,
  ChatHistory.details,
  TextWidget.details,
  CommandWidget.details,
  TrackerWidget.details,
  LocationWidget.details,
  EventsLocationWidget.details,
  ClusterBarWidget.details,
  InformationWidget.details
];

// Combining the different widget types allows Typescript to use
// discriminated unions to provide more type safety.
export type WidgetConfig =
  | LineChart.Config
  | ChatHistory.Config
  | BarChart.Config
  | BooleanWidget.Config
  | TextWidget.Config
  | CommandWidget.Config
  | TrackerWidget.Config
  | GaugeWidget.Config
  | LocationWidget.Config
  | EventsLocationWidget.Config
  | ClusterBarWidget.Config
  | InformationWidget.Config;

export type InformationWidgetConfig = InformationWidget.Config
export function createWidget(
  config: WidgetConfig,
  common: { template: Template; data: DataStore,rightYAxisData:DataStore, state: State },
  errorCode: string | undefined
): JSX.Element {
  //console.log("config:",config)
  switch (config.type) {
    case "line":
      return <LineChart.Widget {...config} {...common} errorCode={errorCode} />;
    case "chat":
        return <ChatHistory.Widget {...config} {...common}  />;
    case "bar":
      return <BarChart.Widget {...config} {...common} />;
    case "boolean":
      return <BooleanWidget.Widget {...config} {...common} />;
    case "text":
      return <TextWidget.Widget {...config} {...common} />;
    case "command":
      return <CommandWidget.Widget {...config} {...common} />;
    case "tracker":
      return <TrackerWidget.Widget {...config} {...common} />;
    case "gauge":
      return <GaugeWidget.Widget {...config} {...common} />;
    case "map":
      return <LocationWidget.Widget {...config} {...common} />;
    case "map events":
      return <EventsLocationWidget.Widget {...config} {...common} />;
    case "clusterbar":
      return <ClusterBarWidget.Widget {...config} {...common} />;
    case "information":
      return <InformationWidget.Widget {...config} {...common} />;
  }
}

export interface EditForm {
  form: JSX.Element;
  parser: (a: any) => WidgetConfig | undefined;
}

export function getEditForm(
  type: WidgetConfig["type"]
): React.FC<EditFormProps> {
  switch (type) {
    case "bar":
      return BarChart.EditForm;
    case "boolean":
      return BooleanWidget.EditForm;
    case "gauge":
      return GaugeWidget.EditForm;
    case "line":
      return LineChart.EditForm;
    case "text":
      return TextWidget.EditForm;
    case "chat":
        return ChatHistory.EditForm;
    case "command":
      return CommandWidget.EditForm;
    case "tracker":
      return TrackerWidget.EditForm;
    case "map":
      return LocationWidget.EditForm;
    case "map events":
        return EventsLocationWidget.EditForm;
    case "clusterbar":
      return ClusterBarWidget.EditForm;
    case "information":
      return InformationWidget.EditForm;
    default:
      return (props: EditFormProps) => <></>;
  }
}
