import { Space } from "antd";
import React from "react";
import { formatGps } from "../../../utils/commonHelpers";

import { AssetWithGps, ServiceTruck } from "../models";
import AssetList from "./assetList/AssetList";
import moment from "moment"

interface TooltipProps {
    asset: AssetWithGps;
    geotabAssets?: ServiceTruck[];
}

const MarkerTooltip: React.FC<TooltipProps> = ({ asset, geotabAssets }) => {
  
    return (
        <div key={asset.id} data-id="map-marker-tooltip">
            <AssetList 
                assets={[asset]} 
                assetsAll={[asset]} 
                context="tooltip"
                geotabAssets={geotabAssets}
            />
        </div>
    );
};

export function markerTooltipFromAsset(asset: AssetWithGps, geotabAssets?: ServiceTruck[]) {
    return (
        <MarkerTooltip asset={asset} geotabAssets={geotabAssets}/>
    );
}

export function markerTooltipFromGeotabAsset(geotabAsset: ServiceTruck){
    return(
        <div>
            <h3 style={{color: '#349beb', paddingTop: '7px'}}>Operator: {geotabAsset.operatorName?.length > 0 ? geotabAsset.operatorName : '(No Name Assigned)'}</h3>
            <Space direction="vertical" size={5}>
                {/* <span>Vehicle ID: {geotabAsset.VIN.substring(geotabAsset.VIN.length-6)}</span> */}
                <span>Vehicle ID: {geotabAsset.vehicleName}</span>
                <span>Location: {formatGps(geotabAsset.latitude, geotabAsset.longitude)}</span>
                <span>Speed: {(geotabAsset.speed * 0.621371).toFixed(2).toString()} mph</span>
                <span>Last Updated: {moment(geotabAsset.dateTime).format('MMM Do YYYY, h:mm:ss A')}</span>
            </Space>
        </div>
    );
}