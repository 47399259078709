import React from "react";
import { Form, Input, message, Row, Col, Tooltip, Switch,Tree } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';

import { appConfigs } from "../../../utils/configurations";
import { errorResponse, resetFormErrors } from "../../../utils/apiHelpers/apiHelpers";
import { FormState, formInit } from "../../../utils/apiHelpers/formHelpers";
import { SuccessAlert, ErrorAlert } from "../../../utils/Components/formAlerts";

import { connect } from "react-redux";
import * as actions from "../actions";

import TemplateDuplicateOrg from "./TemplateDuplicateOrg";
import { createTreeStructure } from "../../../utils/commonHelpers";


const { TextArea } = Input;

interface Prop {
    templateDuplicate: Function,
    templatesListRefreshOnUpdate: Function,
    context: any,
    templatesList: any,
    signalValidation:Function,
    onDrawerClose: Function
}

interface State extends FormState{ 
    signalData:any,
    expandedKeys:Array<string>,
    selectedkeys:Array<string>,
    enableSignalCollectons:boolean,
    autoExpandParent:boolean,
    checkedKeys:Array<string>,
    checkable:boolean,
    flattenedSignals:any
}

class TemplateDuplicate extends React.Component<Prop, State> {  
         
    state: State ={
        signalData:[],
        expandedKeys:[],
        selectedkeys:[],
        enableSignalCollectons:false,
        autoExpandParent:true,
        checkedKeys:[],
        checkable:false,
        flattenedSignals:[]
    };

    formRef = React.createRef<FormInstance>();
    
    componentDidMount() {
        this.componentUpdate(this.props.templatesList.templateMaint.record);
    }

    componentDidUpdate(prevProps: Prop, prevState: FormState) {
        if (this.props.templatesList.templateMaint.instance !== prevProps.templatesList.templateMaint.instance) {
            this.componentUpdate(this.props.templatesList.templateMaint.record);
        }
    }

    componentUpdate(record:any) {
        formInit(this);     
        if (this.formRef.current != null) {
            this.formRef.current!.resetFields(); 
            this.formRef.current!.setFieldsValue({
                templateId: record.templateId,
                orgId: this.props.context.appContext.orgId,
                templateVisibleToSubOrg: record.templateVisibleToSubOrg,
                clonedSignals:null
            });  
            this.setSignalTree(record);               
        }
    };

    onFinish = (values: any) => {
        resetFormErrors(this, this.formRef.current);
        const { templateId, name, description, orgId, templateVisibleToSubOrg,clonedSignals } = values
        this.props.templateDuplicate(this, this.onFinishSuccess, this.onFinishFailure, templateId, name, description, orgId, templateVisibleToSubOrg === undefined ? false : templateVisibleToSubOrg,clonedSignals);
    };

    onFinishSuccess = (record: any) => { 
        message.success("Template has been successfully duplicated");
        this.props.templatesListRefreshOnUpdate(this);
        this.props.onDrawerClose();
    }

    onFinishFailure = (error: any) => { 
        errorResponse(this, error);
    }

    setSignalTree = (record: any) => {    
        if(record.datapoints) {
          const treeData = this.formatTreeData(record.datapoints);
          let uniqueExpandKeys = this.setExpanededKey(treeData);
          this.setState({signalData:treeData,
           expandedKeys:uniqueExpandKeys,
           enableSignalCollectons:true,
           checkedKeys:uniqueExpandKeys        
          });  
          this.props.signalValidation(uniqueExpandKeys.length,true);
        }
      }

      formatTreeData = (datapoints: any) => {
        let signalCollection = JSON.parse(datapoints || []);
        const treeData = signalCollection.map(createTreeStructure);   
        this.getFlattenedSignals(signalCollection);
        return treeData;
      };

    getFlattenedSignals=(datapoints:any)=>{
    let signals:any=[];
    datapoints.forEach((sc:any)=>{
      if(sc.values && sc.values.length > 0)
      {
        sc.values.forEach((sig:any) => {
            signals.push({'signalCollectionId': sc._id, 'signalId': sig._id})
        });
      }
      else{
        signals.push({'signalCollectionId': sc._id, 'signalId': null})
      }
      })
      this.setState({flattenedSignals:signals});
    }
    
    setExpanededKey = (data: any) => {
        let uniqueExpandKeys;
          const expanedKeys = data.reduce(
            (acc: any, curr: any) => {
              acc = acc.concat(curr.key);
              return acc;
            },
            []
          );
          if (expanedKeys) {
            uniqueExpandKeys = expanedKeys.filter((elem: any, pos: any) => {
              return expanedKeys.indexOf(elem) === pos;
            });
          }
          return uniqueExpandKeys;
      }

     onExpand = (expandedKeysValue:any) => {
       this.setState({expandedKeys:expandedKeysValue,autoExpandParent:false});
      };

     onCheck = (checkedKeysValue:any) => {
        const selectedSignals = [];
        let flattenedSignals=this.state.flattenedSignals;
        for(var i =0; i < flattenedSignals.length; i++){
          if(checkedKeysValue.indexOf(flattenedSignals[i].signalCollectionId) > -1 || checkedKeysValue.indexOf(flattenedSignals[i].signalId) > -1){
                selectedSignals.push(flattenedSignals[i]);
          }
        }
        this.formRef.current!.setFieldsValue({
            clonedSignals:flattenedSignals.length-selectedSignals.length==0?null:selectedSignals
        });
        let isEnableSignalCollection=flattenedSignals.length-selectedSignals.length==0;
        this.setState({checkedKeys:checkedKeysValue,
            enableSignalCollectons:isEnableSignalCollection});
     
        this.props.signalValidation(checkedKeysValue.length,isEnableSignalCollection);
      };
       
      onEnableSignalCollection = (checked:any) => {
        let keyLength:number;
        if(!checked)
        {
          keyLength=0;
          this.setState({checkedKeys:[]})
        }
        else
        {
          keyLength=this.state.expandedKeys.length;
          this.setState({checkedKeys:this.state.expandedKeys})
          this.formRef.current!.setFieldsValue({
            clonedSignals:null
          });
        }
        this.setState({enableSignalCollectons: checked});        
        this.props.signalValidation(keyLength,checked);
      };
     
    render() {
        
        return (

            <>
                {this.state.formSuccess && !this.state.formError && <SuccessAlert data-id="template-duplicate-success-message" message={this.state.formSuccessMessage}/>}
                {this.state.formError && !this.state.formSuccess && <ErrorAlert data-id="template-duplicate-error-message" message={this.state.formErrorMessage}/>}

                <Form
                    style={{marginTop: 15}}
                    {...appConfigs.settings.formLayout.mainLayout}
                    id="templateDuplicateForm"
                    ref={this.formRef}
                    onFinish={this.onFinish}
                >     
                    <Form.Item 
                        name="clonedSignals"
                        style={{ display: 'none' }}
                    >
                        <Input type="hidden" />
                    </Form.Item>  
                    <Form.Item 
                        name="templateId"
                        style={{ display: 'none' }}
                    >
                        <Input type="hidden" />
                    </Form.Item>
                    
                    <Form.Item
                        label="New Name"
                        name="name"                                 
                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                    >
                        <Input maxLength={255}  placeholder="New template unique name" />
                    </Form.Item>                    

                    <Form.Item
                        label="Allow sub-orgs to use this master template"
                        labelCol={{ span: 10 }}
                    >  
                        <Row>
                            <Col span={3}>
                                <Form.Item noStyle name="templateVisibleToSubOrg" valuePropName="checked">
                                    <Switch />
                                </Form.Item>                                
                            </Col>
                            <Col span={1}>
                                <Tooltip title="Any change done to this master template will automatically apply to all the assets using this template in all sub-orgs">
                                    <InfoCircleOutlined style={{ fontSize: '120%', color: '#1890ff'}} />
                                </Tooltip>
                            </Col>
                        </Row>                        
                    </Form.Item>

                    <Form.Item
                        label="New Description"
                        name="description"  
                    >
                        <TextArea rows={6} placeholder="A brief description of new template" maxLength={255}/>
                    </Form.Item>

                    <TemplateDuplicateOrg />
         
                    <Form.Item
                        label="Signal Collections"
                        name="enableSignalCollectons"
                        valuePropName="checked"
                    >  
                    
                                    <Switch 
                                    
                                     checked={this.state.enableSignalCollectons}
                                     onChange={this.onEnableSignalCollection}
                                    />
                                    
                           <span style={{ marginLeft:'3px' }}>Select all</span>   

                                     {  this.state.checkedKeys.length ===0 &&
                          <div style={{ color: 'red',marginLeft:'2px' }}>Select atleast one signal</div>
                        }

                                
                    </Form.Item>
                   
                    <Form.Item
                    label=""
                    style={{marginLeft:'140px'}}
                    name="signals"
                    
                    >
                        <Tree
                            checkable
                            onExpand={this.onExpand}
                            expandedKeys={this.state.expandedKeys}
                            autoExpandParent={this.state.autoExpandParent}
                            onCheck={this.onCheck}
                            checkedKeys={this.state.checkedKeys}
                            selectedKeys={this.state.selectedkeys}
                            treeData={this.state.signalData}
                        />
                   </Form.Item>
                </Form>
                   
            </>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        templatesList: state.templatesReducers.templatesState.templatesList
    };
};

export default connect(
    mapStateToProps,
    actions
)(TemplateDuplicate);