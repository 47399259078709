import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'antd';
import { CloseCircleTwoTone } from "@ant-design/icons";

import { appConfigs } from "../../utils/configurations";

import "./login.css";

interface Props {
    visible: boolean;
    closeErrorModal: Function;
}

const LoginError: React.FC<Props> = (props) => {

    const [errorModalErrorMessage, setErrorModalErrorMessage] = useState("");
    
    const displayError = () => {
        const error:any = JSON.parse(window.sessionStorage.getItem("loginError") || "{}");
        let errorHtml: string = "";

        window.sessionStorage.removeItem("loginError");

        errorHtml += "<ul>";
        if (error.errorObject != null && (error.errorObject.length || 0) !== 0) {
            error.errorObject.forEach(function(record:any) {
                errorHtml += "<li>" + (record.message.startsWith("APP-ERR#") ? record.message.substring(record.message.indexOf(':') + 1) : record.message)+ "</li>";
            });
        }
        else {
            errorHtml += "<li>Error message not captured - Please try again later</li>"; 
        }
        errorHtml += "</ul>";

        setErrorModalErrorMessage(errorHtml);
    }

    const closeErrorModal = () => {
        props.closeErrorModal();
    }

    useEffect(() => {
        if (props.visible) {
            displayError();
        }
    }, [props.visible]);

    return (

        <Modal
            data-id="login-modal-error"
            title="IQAN Connect"
            visible={props.visible}
            onOk={closeErrorModal}
            onCancel={closeErrorModal}
            footer={false}
        >
            <Row gutter={16}>
                <Col xs={3}>
                    <CloseCircleTwoTone 
                        data-id="login-modal-error-icon"
                        twoToneColor="#ff4d4f" 
                    />
                </Col>
                <Col xs={21}>
                    <div><h4>Sorry, we've encountered the following issues:</h4></div>
                    <div className="mt-20" dangerouslySetInnerHTML={{__html: errorModalErrorMessage}}></div> 
                </Col>
            </Row>
        </Modal>

    );
};

export default LoginError;
  
