import React, { CSSProperties, Component } from "react";
import { Link } from "react-router-dom";
import { Space, Typography,Tooltip,Row,Col, Badge } from "antd";
import history from '../../utils/history';
import { stringToUrlString } from "../../utils/commonHelpers";
import { useAssetStatus } from "./useAssetStatus";
import AssetStatus from "./AssetStatus";
import Icon from "@ant-design/icons";
import { BarcodeOutlined, FileTextOutlined, CaretLeftOutlined } from "@ant-design/icons";
import { ReactComponent as TractorIcon } from "../../assets/images/tractor-black.svg";
import { ReactComponent as BellSvg } from "../../assets/images/bell.svg";
import { ReactComponent as Dm1Pin } from "../../assets/images/map-pins/dm1-pin.svg";
import BackNavigation from "../../utils/Components/BackNavigation";
import { ReactComponent as OperatorIcon } from "../../assets/images/operator.svg";

const { Title } = Typography;

interface Props {
  name: string;
  id: string;
  mastertag: string;
  templateId: string;
  templateName: string;
  alertDm1Count: number;
  alertInfoCount: number;
  alertWarningCount: number;
  alertCriticalCount: number;
  mac: string;
  userRole: string;  
  isRead: boolean;
  isUpdate: boolean;
  operatorId: string;
  operatorIdValue: string;
  accessCardValue: string;
  operatorsHistoryId: string;
  isOprRead: any;
  isOprUpdate:any
  isassetUpdate: boolean;
  istemplatesRead: boolean;
}

const Header: React.FC<Props> = ({
    name, id, mastertag, templateName, templateId, mac, alertInfoCount, alertDm1Count, alertWarningCount, alertCriticalCount, userRole, isRead, isUpdate, operatorId, accessCardValue, operatorIdValue, operatorsHistoryId, isOprRead, isOprUpdate, isassetUpdate, istemplatesRead
  }) => {
  const styles: CSSProperties = {
    fontSize: "14px",
    fontWeight: 400,
    verticalAlign: "text-bottom",
    marginRight: "10px"
  };

  const goToDetails = (e: any,templateName: string, templateId: string) => {
    e.preventDefault();
    history.push("/template/details/" + stringToUrlString(templateName) + "/" + templateId);
  };

  const goToAsset = (e: any, mac?:string) => {
    e.preventDefault();    
    history.push("/assets" + ((mac || "") === "" ? "" : "/" + mac + "/details"));
  };

  const assetStatus = useAssetStatus(id);

 const goToOperators = (e: any, id: string, operatorsHistoryId?: string,isOperator?:boolean) => {
    e.preventDefault();
    history.push({pathname:"/operators/" + stringToUrlString(id) + ((operatorsHistoryId || "") === "" ? "" : "/" + operatorsHistoryId),state:{isOperator:isOperator}});
  };

    //const oprIdValue = (operatorIdValue || "") === "" ? accessCardValue : operatorIdValue;
    //const operatorHistoryId = (accessCardValue || "") !== "" ? operatorsHistoryId : "";

  return (      
    <Row>      
      <Col>
    {/* <Space direction="horizontal" align="center" size="middle"> */}
    <div className="column">     
      <Title style={styles} level={4}>
        <Tooltip title="Go To Previous Page">
          <div style={{ position: "relative", top: "6px", left: "5px"}}>
            <BackNavigation />
          </div>
        </Tooltip>
      </Title>  
      <Title style={styles} level={4}>
        <AssetStatus assetStatus={assetStatus} />
      </Title>       
      <Title style={styles} level={4}>     
        <Tooltip title="AssetName">       
          <Icon type="setting" component={TractorIcon} style={{ verticalAlign: "middle", fontSize: '22px'}} />
        </Tooltip> {name}
      </Title>
      {istemplatesRead ? 
        <Title style={styles} level={4}>
        <Tooltip title="Template"><FileTextOutlined style={{ verticalAlign: "middle", fontSize: '22px' }} /></Tooltip> 
          <a href="# " onClick={(e) => goToDetails(e, templateName, templateId)}>{templateName}</a>
        </Title> : <Title style={styles} level={4}>
        <Tooltip title="Template"><FileTextOutlined style={{ verticalAlign: "middle", fontSize: '22px' }} /></Tooltip> 
          {templateName}
        </Title>
      }     
      {isassetUpdate ? 
        <Title style={styles} level={4}>
          <Tooltip title="SerialNo"><BarcodeOutlined style={{ verticalAlign: "middle", fontSize: '22px'}} /></Tooltip>
            <a href="# " onClick={(e) => goToAsset(e, mac)}> {mac} </a>
        </Title> : <Title style={styles} level={4}>
          <Tooltip title="SerialNo"><BarcodeOutlined style={{ verticalAlign: "middle", fontSize: '22px'}} /></Tooltip>
            {mac} 
        </Title>
       }
       {
                      (isOprRead && isOprUpdate) && (operatorIdValue || accessCardValue) ?
                          <Title style={styles} level={4}>
                              <Tooltip title={(operatorIdValue || "") === "" ? "RFID" : "OperatorID"}>
                                  <Icon type="setting" component={OperatorIcon} style={{ verticalAlign: "middle", fontSize: '22px' }} />
                              </Tooltip> {
                                  (operatorIdValue || "") === "" ?
                                      <a href="# " onClick={(e) => goToOperators(e, accessCardValue, operatorsHistoryId,false)}> {accessCardValue} </a>
                                      : <a href="# " onClick={(e) => goToOperators(e, operatorIdValue, operatorId,true)}> {operatorIdValue} </a>
                              }
                          </Title> : ""
                          
      }     

      </div>
       <div className="asset-header asset-header1">
      <Title style={styles} level={4}>
        <div style={{ position: "relative", top: "6px", left: "5px"}}>
          <Tooltip title="Go To Previous Page">         
            <BackNavigation />        
          </Tooltip>
        </div>
      </Title>  
      <Title style={styles} level={4}>
        <AssetStatus assetStatus={assetStatus} />
      </Title>       
      <Title style={styles} level={4}>     
        <Tooltip title="AssetName">       
          <Icon type="setting" component={TractorIcon} style={{ verticalAlign: "middle", fontSize: '22px'}} />
        </Tooltip> {name}
      </Title>
      {/* <Title style={styles} level={4}>     
      <Link to={{
          pathname:"/alerts/signal-alarms/" + mastertag          
          }}>
          <Badge count={alertInfoCount + alertWarningCount + alertCriticalCount}>
            <Tooltip title="Signal Alarms"  >
              <BellSvg style={{ verticalAlign: "middle" }} /> 
            </Tooltip>        
          </Badge>
        </Link>
        </Title> */}
        {/* <Title style={styles} level={4}> 
         <Link to={{
          pathname:"/alerts/dm1-faults/" + mastertag
          //state:[{mastertag:mastertag, mode:"1"}]
          }}>
          <Badge count={alertInfoCount + alertWarningCount + alertCriticalCount}>
            <Tooltip title="DM1 Faults" >
              <Dm1Pin style={{ verticalAlign: "middle" }} /> 
            </Tooltip>        
          </Badge>
        </Link>  
        </Title>   */}
      </div>
      {/* <div className="asset-header asset-header2"> */}
      <div className="asset-header">
         {isRead ? 
            <Title style={styles} level={4}>
              <Tooltip title="Template"><FileTextOutlined style={{ verticalAlign: "middle", fontSize: '22px' }} /></Tooltip> 
                <a href="# " onClick={(e) => goToDetails(e, templateName, templateId)}>{templateName}</a>
            </Title> : <Title style={styles} level={4}>
            <Tooltip title="Template"><FileTextOutlined style={{ verticalAlign: "middle", fontSize: '22px' }} /></Tooltip> 
              {templateName}
            </Title>
          }
          {isRead && isUpdate ?
            <Title style={styles} level={4}>
              <Tooltip title="SerialNo"><BarcodeOutlined style={{ verticalAlign: "middle", fontSize: '22px'}} /></Tooltip>
                <a href="# " onClick={(e) => goToAsset(e, mac)}> {mac} </a>
            </Title> : <Title style={styles} level={4}>
              <Tooltip title="SerialNo"><BarcodeOutlined style={{ verticalAlign: "middle", fontSize: '22px'}} /></Tooltip>
                {mac} 
            </Title>
          }
          {
                  (isOprRead && isOprUpdate) && (operatorIdValue || accessCardValue) ?
                          <Title style={styles} level={4}>
                              <Tooltip title={(operatorIdValue || "") === "" ? "RFID" : "OperatorID"}>
                                  <Icon type="setting" component={OperatorIcon} style={{ verticalAlign: "middle", fontSize: '22px' }} />
                              </Tooltip> {
                                  (operatorIdValue || "") === "" ?
                                      <a href="# " onClick={(e) => goToOperators(e, accessCardValue, operatorsHistoryId,false)}> {accessCardValue} </a>
                                      : <a href="# " onClick={(e) => goToOperators(e, operatorIdValue, operatorId,true)}> {operatorIdValue} </a>
                              }
                          </Title> : ""
          }     
      </div>
    {/* </Space> */}
    </Col>
    </Row>
  );
};

export default Header;
