import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function scheduleMaintInfoOrgsGet(
  me: any,
  callbackFnSuccess?: any,
  callbackFnFailure?: any,
  orgId?: string,
  searchText?: string
) {
  return async (dispatch: any) => {
    Axios.get(
      appConfigs.server.URL +
        "/ui/api/orgs-lov" +
        ((orgId || "") !== "" ? "/" + orgId : ""),
      {
        responseType: "json",
        headers: {},
        params: {
          mode: (orgId || "") === "" ? "all" : "single",
          scope: "all",
          includeTree: false,
          orgId: orgId,
          searchText: searchText,
          page: 1,
          pageSize: 9999,
        },
      }
    )
      .then((response) => {
        if (callbackFnSuccess != null) {
          callbackFnSuccess(response.data.data.orgs);
        }
        dispatch({
          type: "REPORT_SCHEDULES_ORGS_SUCCESS",
          payload: { data: response.data.data.orgs },
        });
      })
      .catch(function (error) {
        errorResponse(me, error, false);
        if (callbackFnFailure != null) {
          callbackFnFailure();
        }
      });
  };
}

export function scheduleMaintInfoReportsGet(
  me: any,
  callbackFnSuccess: any,
  callbackFnFailure: any,
  orgId: string
) {
  return async (dispatch: any) => {
    Axios.get(appConfigs.server.URL + "/ui/api/orgReports/" + orgId, {
      responseType: "json",
      headers: {},
    })
      .then((response) => {
        if (callbackFnSuccess != null) {
          callbackFnSuccess(response.data.reports);
        }
        dispatch({
          type: "REPORT_SCHEDULES_REPORTS_GET_SUCCESS",
          payload: { data: response.data.reports, orgId: response.data.orgId },
        });
      })
      .catch(function (error) {
        if (callbackFnFailure != null) {
          callbackFnFailure(me, error);
        }
        errorResponse(me, error);
      });
  };
}

export function scheduleMaintInfoUsersGet(
  me: any,
  callbackFnSuccess?: any,
  callbackFnFailure?: any
) {
  return async (dispatch: any) => {
    Axios.get(
      appConfigs.server.URL +
        "/ui/api/users/okta?administered=true&pageSize=9999",
      {
        responseType: "json",
        headers: {},
      }
    )
      .then((response) => {
        if (callbackFnSuccess != null) {
          callbackFnSuccess(response.data.data.users);
        }
        dispatch({
          type: "REPORT_SCHEDULES_USERS_SUCCESS",
          payload: { data: response.data.data.users },
        });
      })
      .catch(function (error) {
        errorResponse(me, error, false);
        if (callbackFnFailure != null) {
          callbackFnFailure();
        }
      });
  };
}

export function scheduleMaint(
  me: any,
  callbackFnSuccess: any,
  callbackFnFailure: any,
  record: any
) {
  return async (dispatch: any) => {
    Axios.post(appConfigs.server.URL + "/ui/api/report-schedules", record, {
      responseType: "json",
      headers: {},
    })
      .then((response) => {
        if (callbackFnSuccess != null) {
          callbackFnSuccess(response.data.data.scheduleId, record.mode);
        }
        dispatch({
          type: "REPORT_SCHEDULES_MAINT_SUCCESS",
          payload: { data: response.data.schedule },
        });
      })
      .catch(function (error) {
        if (callbackFnFailure != null) {
          callbackFnFailure(error);
        }
      });
  };
}

export function getOrgTags(
  me: any,
  callbackFnSuccess: any,
  callbackFnFailure: any,
  status: string,
  orgId: string
) {
  return async (dispatch: any) => {
    Axios.get(appConfigs.server.URL + "/ui/api/tags", {
      responseType: "json",
      headers: {},
      params: {
        orgId: orgId,
      },
    })
      .then((response) => {
        let data =
          response.data.data.orgTags == null ||
          (response.data.data.orgTags.length || 0) === 0
            ? []
            : response.data.data.orgTags;
        if (callbackFnSuccess != null) {
          callbackFnSuccess(data);
        }

        data = data
          .map((tag: any) => tag["tagName"].toUpperCase())
          .filter((item: any, i: any, ar: any) => ar.indexOf(item) === i)
          .sort();

        dispatch({ type: "ORG_TAGS_SUCCESS", payload: { data: data } });
      })
      .catch(function (error) {
        if (callbackFnFailure != null) {
          callbackFnFailure(me, error);
        }
        errorResponse(me, error);
      });
  };
}
export function getScheduleOrgTags(
  me: any,
  callbackFnSuccess: any,
  callbackFnFailure: any,
  status: string,
  orgId: string
) {
  
  return async (dispatch: any) => {
    Axios.get(appConfigs.server.URL + "/ui/api/orgtags", {
      responseType: "json",
      headers: {},
      params: {
        orgId: orgId,
      },
    })
      .then((response) => {
        let data =
          response.data.data.orgTags == null ||
          (response.data.data.orgTags.length || 0) === 0
            ? []
            : response.data.data.orgTags;
        if (callbackFnSuccess != null) {
          callbackFnSuccess(data);
        }

        data = data
          .map((tag: any) => tag["tagName"].toUpperCase())
          .filter((item: any, i: any, ar: any) => ar.indexOf(item) === i)
          .sort();

        dispatch({ type: "SCHEDULE_ORG_TAGS_SUCCESS", payload: { data: data } });
      })
      .catch(function (error) {
        if (callbackFnFailure != null) {
          callbackFnFailure(me, error);
        }
        errorResponse(me, error);
      });
  };
}
