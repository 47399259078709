import React, { Component } from "react";
import { Table, Button, Space, Popover, Tag, Popconfirm, Tooltip, message, Input,Row,Col } from "antd";
import { PlusOutlined, EditOutlined, EllipsisOutlined, DeleteOutlined, UnorderedListOutlined, EyeInvisibleFilled } from "@ant-design/icons";
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { appConfigs } from "../../../utils/configurations";
import history from '../../../utils/history';
import { closePopover, getPermissions } from "../../../utils/commonHelpers";

import { connect } from "react-redux";
import * as actions from "../actions";

import Signals from "../signals/Signals";
import SignalMaint from "../signalMaint/SignalMaint";
import IqanFileUpload from "../iqanFileUpload/IqanFileUpload";

import "./signalCollections.css";

const { Search } = Input;

interface Prop {
    signalCollectionsSetPagination: Function,
    signalCollectionsSetSearch: Function,
    getSignalCollection: Function,
    getSignalCollections: Function,
    signalCollectionDelete: Function,
    getSignals: Function,
    signalsOpen: Function,
    signalCollectionMaintOpen: Function,
    context: any,
    signalCollections: any,
    templateDetails: any,
    isread: boolean,
    iscreate: boolean,
    isdelete: boolean,
    iscreateRules: boolean, // rules
    isreadRules: boolean,
    isupdateRules: boolean,
    isdeleteRules: boolean,
    iscreateOrUpdateTemplate: boolean,
    isGlobalTemplate: boolean, 
    orgCmdPgnSpns: String
}

class SignalCollections extends Component<Prop, {}> {

    drawerWidth:number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);

    getTemplateId() {
        const pathnameArr = history.location.pathname.split("/");
        return pathnameArr[pathnameArr.length - 1];
    }

    componentDidMount () {
        this.props.signalCollectionsSetPagination(this.onPaginationChange);
        this.props.signalCollectionsSetSearch("");
        this.props.getSignalCollections(this, null, null, this.getTemplateId(), 1, "");       
    }
    
    onPaginationChange = (page: number) => {
        this.props.getSignalCollections(this, null, null, this.getTemplateId(), page, this.props.signalCollections.searchText);
    };

    onAction = (e:any, record:any, action:string) => {
        e.preventDefault();

        if ( action === "add") {
            this.props.signalCollectionMaintOpen(this, this.drawerWidth, {});
        }
        else if ( action === "edit") {
            this.props.getSignalCollection(this, this.getSignalCollectionSuccess, null, this.getTemplateId(), record.signalCollectionId);
        }
        else if ( action === "delete") {
            this.props.signalCollectionDelete(this, this.signalCollectiondDeleteSuccess, null, this.getTemplateId(), record.signalCollectionId);
        }
        else if ( action === "signals" ) {
            this.props.signalsOpen(this, "Signals", this.drawerWidth, record.signalCollectionId, record.name, record.type);
        }
        else if ( action === "assign" ) {
            message.info("TBD");            
        }
       
        closePopover();       
    }
    
    getSignalCollectionSuccess = (record: any) => { 
        this.props.signalCollectionMaintOpen(this, this.drawerWidth, record);
    }
    
    signalCollectiondDeleteSuccess = (record: any) => { 
        const signalCollections = this.props.signalCollections;
        this.props.getSignalCollections(this, this.signalCollectiondDeleteSuccess2, null, this.getTemplateId(), signalCollections.tableConfig.pagination.current, signalCollections.searchText);
        message.success("Signal collection has been successfully deleted");    
    }

    signalCollectiondDeleteSuccess2 = (record: any) => { 
        const signalCollections = this.props.signalCollections;
        if ((signalCollections.tableData.length || 0) === 0) {
            let goToPage = signalCollections.tableConfig.pagination.current;
            goToPage = (goToPage === 1 ? null : goToPage - 1); 
            this.props.getSignalCollections(this, null, null, this.getTemplateId(), goToPage, signalCollections.searchText);
        }
    }

    confirmDeletion = (record:any) => (
        <Popconfirm
            title={"Are you sure to delete this signal collection?"}
            onCancel={closePopover}
            onConfirm={(e) => {this.onAction(e, record, "delete")}}
            okText="Yes"
            cancelText="No"
        >
            <Tooltip title="Delete Signal Collection">            
                <Button data-id="signal-collections-actions-delete-button" type="default" icon={<DeleteOutlined />} className="delete-signal-collection" />
            </Tooltip>
        </Popconfirm>
    );
    
    listColectionSignals = (record:any) => (
        (
            record.type === "none" ||
            record.type === "GPS" ||
            record.type === "IQAN Diagnostics" ||
            record.type === "Command"
        ) &&
        <Tooltip title="List Collection Signals">
            <Button  data-id="signal-collections-actions-signals-button" type="default" icon={<UnorderedListOutlined />} onClick={(e) => {this.onAction(e, record, "signals")}}/>                     
        </Tooltip>
    );

    adminActions = (record:any, isRead:any, isCreateOrUpdate:any, isDelete:any) => (
        <Popover
            content={
                <div className="center-div">
                    <Space size="large">
                        {
                            //if template is a global template and role of the user is admin or access permission logic
                            // Non-admin shall be able to view template collection, but not modify it
                            ((this.props.templateDetails.templateRecord.templateVisibleToSubOrg 
                                && (this.props.context.appContext.roleName.toLowerCase() === "admin" || isRead || isCreateOrUpdate) &&
                                (record.type === "none" ||  record.type === "GPS" ||  record.type === "IQAN Diagnostics" || 
                                         (record.type === "Command" && (this.props.orgCmdPgnSpns === null || this.props.orgCmdPgnSpns !== '-'))))  ||
                             (!this.props.templateDetails.templateRecord.templateVisibleToSubOrg 
                                && ((isCreateOrUpdate || isRead) &&
                                     (record.type === "none" ||  record.type === "GPS" ||  record.type === "IQAN Diagnostics" || 
                                         (record.type === "Command" && (this.props.orgCmdPgnSpns === null || this.props.orgCmdPgnSpns !== '-'))))
                             )
                            ) &&
                                <Tooltip title="Edit Signal Collection">
                                    <Button  data-id="signal-collections-actions-edit-button" type="default" icon={<EditOutlined />} onClick={(e) => {this.onAction(e, record, "edit")}}/>                     
                                </Tooltip>
                        }
                        {
                            //if template is a global template and role of the user is admin or access permission logic
                            ((this.props.templateDetails.templateRecord.templateVisibleToSubOrg && this.props.context.appContext.roleName.toLowerCase() === "admin" &&
                                (record.type === "none" ||  record.type === "GPS" ||  record.type === "IQAN Diagnostics" || 
                                    (record.type === "Command" && (this.props.orgCmdPgnSpns === null || this.props.orgCmdPgnSpns !== '-'))))
                             ||
                             (!this.props.templateDetails.templateRecord.templateVisibleToSubOrg && isDelete  &&
                                (record.type === "none" ||  record.type === "GPS" ||  record.type === "IQAN Diagnostics" || 
                                    (record.type === "Command" && (this.props.orgCmdPgnSpns === null || this.props.orgCmdPgnSpns !== '-'))))) &&
                                this.confirmDeletion(record)                             
                            
                        }
                        {                            
                            isRead &&
                            this.listColectionSignals(record)
                        }
                    </Space>
                </div>
            }
            title={
                <div className="center-div">Actions</div>
            }
            trigger="click"
        >
            <Button data-id="signal-collections-actions-button" type="default" icon={<EllipsisOutlined />} />  
        </Popover>
    );

    onSearch = (searchText: string) => {   
        let me = this;

        this.props.signalCollectionsSetSearch((searchText || ""));
        setTimeout(function(){
            me.props.getSignalCollections(me, null, null, me.getTemplateId(), 1, searchText);
        }, 100);      
    }

    onSearchChange = (e: any) => {
        let value = e.target.value;
        this.props.signalCollectionsSetSearch((value || ""));
    }

    render() {    

        const { tableConfig, tableData, searchText } = this.props.signalCollections;
        const { templateRecord } = this.props.templateDetails;       

        const tableColumns = [
            {
                title: "Collection Name",
                dataIndex: "name",
                key: "name",
                render: (name: number, record: any) => (
                    <>
                        {
                            !(
                                record.type === "none" ||
                                record.type === "GPS" ||
                                record.type === "IQAN Diagnostics" ||
                                record.type === "Command" 
                            ) &&
                            <span>{name}</span>
                        } 
                        {
                            (
                                record.type === "none" ||
                                record.type === "GPS" ||
                                record.type === "IQAN Diagnostics" ||
                                (record.type === "Command" && (templateRecord.orgCmdPgnSpns == null || templateRecord.orgCmdPgnSpns !== '-') )
                            ) &&
                            <Tooltip title="List Collection Signals">
                                <a href="# " onClick={(e) => {this.onAction(e, record, "signals")}}>{name}</a>
                            </Tooltip>   
                        } 
                        {
                              (record.type === "Command" && (templateRecord.orgCmdPgnSpns === '-') ) &&
                            <>
                                <EyeInvisibleFilled className="invisible-collection"/>
                                &nbsp;
                                {/* <Text type="danger">{fullName}</Text> */}
                                <Tooltip title="List Command Collection Not Applicable to the Org">
                                <a href = "#" style={{ backgroundColor:'#EBEBEB',  color: '#A6A6A6', textDecorationLine: 'line-through' }} onClick={(e) => {this.onAction(e, record, "signals")}}>{name}</a>
                                </Tooltip>  
                            </>  
                        }
                    </>                
                ),
                width: "50%",
            },
            {
                title: "Capture Interval",
                dataIndex: "updateInterval",
                key: "updateInterval",
                align: "center" as "center",
                render: (type: string, record: any) => (
                    <> 
                        {
                            (
                                record.type === "none" ||
                                record.type === "GPS" ||
                                record.type === "IQAN Diagnostics" ||
                                (record.type === "Command"  && (templateRecord.orgCmdPgnSpns == null || templateRecord.orgCmdPgnSpns !== '-') )
                            ) &&
                             <span>{record.updateInterval}</span>
                        }
                        {
                            ( record.type === "Command"  && templateRecord.orgCmdPgnSpns === '-') &&
                            <span style={{ backgroundColor:'#EBEBEB',  color: '#A6A6A6',  textDecorationLine: 'line-through' }}>{record.updateInterval}</span>   
                        }
                   </>
                )
                   
            },
            {
                title: "Type",
                dataIndex: "type",
                key: "type",
                align: "center" as "center",
                responsive: ["md"] as Breakpoint[],
                render: (type: string, record: any) => (
                    <>
                        {
                            (
                                record.type === "none" ||
                                record.type === "GPS" ||
                                record.type === "IQAN Diagnostics" ||
                                ( record.type === "Command"  && (templateRecord.orgCmdPgnSpns == null || templateRecord.orgCmdPgnSpns !== '-') )
                            ) &&
                            <span>{record.type === "none" ? "Generic" : type}</span>
                        }
                        {
                            ( record.type === "Command"  && templateRecord.orgCmdPgnSpns === '-')  &&
                            <span style={{backgroundColor:'#EBEBEB',  color: '#A6A6A6', textDecorationLine: 'line-through' }}>{record.type}</span>   
                        }
            </>
                )
            },
            {
                title: "Signals",
                dataIndex: "signalCount",
                key: "signalCount",
                align: "center" as "center",
                responsive: ["md"] as Breakpoint[],
                width: "140px",
                render: (signalCount: number, record: any) => (
                    <>
                        {
                            (
                                record.type === "none" ||
                                record.type === "GPS" ||
                                record.type === "IQAN Diagnostics" ||
                            ( record.type === "Command"  && (templateRecord.orgCmdPgnSpns == null || templateRecord.orgCmdPgnSpns !== '-') )
                            ) &&
                            <div className="notifications-tag">
                                {
                                    signalCount > 0 && 
                                    <Tag color="geekblue">{signalCount}</Tag>
                                }
                            </div>
                        }
                        {
                            ( record.type === "Command"  && templateRecord.orgCmdPgnSpns === '-')  &&
                            <Tag style={{ backgroundColor:'#EBEBEB',  color: '#A6A6A6',  textDecorationLine: 'line-through' }}>{signalCount}</Tag>   
                        }
                    </>
                )
            },
            {
                title: "Actions",
                key: "action",
                align: "center" as "center",
                width: "140px",
                render: (text: any, record: any) => (  
                    <>                  
                        {
                            (
                                record.type === "none" ||
                                record.type === "GPS" ||
                                record.type === "IQAN Diagnostics" ||
                                record.type === "Command"
                            ) &&
                            // Non-admin role can view global template but not modify it
                            ((templateRecord.templateVisibleToSubOrg && (this.props.context.appContext.roleName.toLowerCase() === "admin" || this.props.isread || this.props.iscreateOrUpdateTemplate)) ||
                            (!templateRecord.templateVisibleToSubOrg && (templateRecord.objectAdmin || this.props.isread || this.props.iscreateOrUpdateTemplate))) && 
                            this.adminActions(record, this.props.isread, this.props.iscreateOrUpdateTemplate, this.props.isdelete)
                        }
                        {/* {
                            !templateRecord.objectAdmin && this.props.isread &&
                            this.listColectionSignals(record)
                        } */}
                    </>
                )
            }
        ];

        return (
            <>

            <Row>
                <Col>
                    <Space size="middle">
                        {
                            this.props.isread &&
                            <Search
                            data-id="signal-collections-search-field"
                            placeholder="Search by name"
                            enterButton="Search"
                            size="middle"
                            allowClear
                            onSearch={this.onSearch}
                            onChange={this.onSearchChange}
                            className="search-field template-search"
                            value={searchText}
                        />   
                        }
                        
                        <div className="hideshow">
                            {
                                // (this.props.isGlobalTemplate && this.props.context.appContext.roleName.toLowerCase() === "admin") ?
                                //     <IqanFileUpload data-id="signal-collections-upload-iqan-button"/>  :
                                ((templateRecord.templateVisibleToSubOrg && this.props.context.appContext.roleName.toLowerCase() === "admin") 
                                || (!templateRecord.templateVisibleToSubOrg && (templateRecord.objectAdmin || this.props.iscreate))) &&
                                <IqanFileUpload data-id="signal-collections-upload-iqan-button"/>       
                            }
                        </div>                                   
                        {
                            // (this.props.isGlobalTemplate && this.props.context.appContext.roleName.toLowerCase() === "admin") ?
                            //     <Tooltip title="Create new signal collection">
                            //         <Button data-id="signal-collections-new-collection-button" type="primary" icon={<PlusOutlined />} onClick={(e) => this.onAction(e, {}, "add")} className="template-button">
                            //             <span>Collection</span>
                            //         </Button>              
                            //     </Tooltip> : 
                            ((templateRecord.templateVisibleToSubOrg && this.props.context.appContext.roleName.toLowerCase() === "admin") 
                            || (!templateRecord.templateVisibleToSubOrg && (templateRecord.objectAdmin || this.props.iscreate))) &&
                            <Tooltip title="Create new signal collection">
                                <Button data-id="signal-collections-new-collection-button" type="primary" icon={<PlusOutlined />} onClick={(e) => this.onAction(e, {}, "add")} className="template-button">
                                    <span>Collection</span>
                                </Button>              
                            </Tooltip>                                
                        }                   
                    </Space>
                </Col>
            </Row>
            {
                this.props.isread &&
                <Table  
                    data-id="signal-collections-data"
                    {...tableConfig}
                    rowKey={(record) => record.signalCollectionId}
                    columns={tableColumns}
                    dataSource={tableData}
                    className="mt-10"
                />
            }
           

            <Signals iscreateOrUpdateTemplate={this.props.iscreateOrUpdateTemplate} iscreate={this.props.iscreate} isread={this.props.isread} isdelete={this.props.isdelete}  
            iscreateRules={this.props.iscreateRules} isreadRules={this.props.isreadRules} isupdateRules={this.props.isupdateRules} isdeleteRules={this.props.isdeleteRules}
            isGlobalTemplate = {this.props.isGlobalTemplate}/>
            <SignalMaint iscreateOrUpdateTemplate={this.props.iscreateOrUpdateTemplate} iscreate={this.props.iscreate} isread={this.props.isread} isdelete={this.props.isdelete} 
            iscreateRules={this.props.iscreateRules} isreadRules={this.props.isreadRules} isupdateRules={this.props.isupdateRules} isdeleteRules={this.props.isdeleteRules}
            isGlobalTemplate = {this.props.isGlobalTemplate} context={this.props.context}/>
            </>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        signalCollections: state.templateDetailsReducers.templateDetailsState.tabs[0],
        templateDetails: state.templateDetailsReducers.templateDetailsState
    };
};

export default connect(
    mapStateToProps,
    actions
)(SignalCollections);
  