/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect,useState} from "react";
import { connect } from "react-redux";
import * as actions from "./actions";
import { Row, Col, Tooltip,  Button, Space, Table, Input } from "antd";
import BackNavigation from "../../../utils/Components/BackNavigation";
import  { EditOutlined,FileTextOutlined, ClockCircleOutlined} from "@ant-design/icons";
import history from '../../../utils/history';
import { appConfigs } from "../../../utils/configurations";
import SoftwareInstallationProgress from "./SoftwareInstallationProgress";
import onlineIcon from "../../../assets/images/map-status/online.png";
import offlineIcon from "../../../assets/images/map-status/offline.png";
import { 
    CheckCircleOutlined, CloseCircleOutlined
} from "@ant-design/icons";
import "./uds.css";

const { Search } = Input;

interface Props{
    context: any,
    csus: any,
    selectedCSU: any,
    getControllerOtaManagement:Function,
    ControllerOtaManagement:Function,
    ControllerOtaManagementSetPagination:Function,
    updateControllerOtaManagementSelection:Function,
    controllerOtaManagementSetSearch:Function,
    openDrawer: Function,
    openInstallationProgressDrawer: Function,
}

const UDS: React.FC<Props> = (props) => {
    const drawerWidth: number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);
    const [selectedFilters, setSelectedFilters] = useState<any>({});
    const { tableConfig, tableData } = props.csus.csuList;
    const [page, setPage] = useState(1);

    useEffect(() => {
        props.ControllerOtaManagement(null, null, null, 1, props.csus.csuList.searchText, [{"status": null}])
        props.ControllerOtaManagementSetPagination(onPaginationChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const renderControllersTable = (pagination: any, filters: any, sorter: any) => 
    { 
        setSelectedFilters(filters);
        setPage(pagination.current);
    }
    const onSearch = (searchText: string) => {   
        props.controllerOtaManagementSetSearch((searchText || ""));

        if((searchText || "")==="") {
            history.push("/csu");
        }
        
        setTimeout(function() {
            props.ControllerOtaManagement(null, null, null, 1, searchText, selectedFilters);   
        }, 100);      
    }

    const onSearchChange = (e: any) => {
        let value = e.target.value;
        props.controllerOtaManagementSetSearch((value || ""));
    }
    const onPaginationChange = (page: number) => {

        props.ControllerOtaManagement(null, null, null, page, '', null);
    };
    const tableColumns = [
        {
            title: 'Asset',
            dataIndex: 'gatewayName',
            render: (text: any, record: any) => 
                        <span className="no-word-wrap">
                        {
                            record.notesCount > 0 &&
                            <span className="asset-status-yellow pr-3" ><FileTextOutlined /></span>
                        }
                        {
                            record.status === "active" &&
                            <span className="asset-status-green pr-5" ><CheckCircleOutlined /></span>
                        }
                        {
                            record.status === "inactive" &&
                            <span className="asset-status-red pr-5" ><CloseCircleOutlined /></span>
                        }
                        {
                            record.status === "whitelisted" &&
                            <span className="asset-status-gray pr-5" ><ClockCircleOutlined /></span>
                        }
                        {record.gatewayName}
                        </span>
        },
        {
            title: 'Connection',
            dataIndex: "gatewayName",
            render: (text: any, record: any) => (
                <>
                     {
                        record.connectionStatus === "ONLINE" && record.status !== "inactive" && record.status !== "whitelisted" &&
                        <Tooltip title="Online">
                            <span className="asset-status-green alarm-info-icon pr-5" ><img src={onlineIcon} alt="" /></span>
                        </Tooltip>
                    }
                    {
                        record.connectionStatus === "ONLINE" && record.status !== "active" &&
                        <Tooltip title="Offline">
                            <span className="asset-status-red alarm-info-icon pr-5" ><img src={offlineIcon} alt="" /></span>
                        </Tooltip>
                    }
                    {
                        record.connectionStatus === "OFFLINE" &&
                        <Tooltip title="Offline">
                            <span className="asset-status-red alarm-info-icon pr-5" ><img src={offlineIcon} alt="" /></span>
                        </Tooltip>
                    }
                </>
            )
        },
        {
            title: 'Mastertag',
            dataIndex: 'mastertag',
        },
        {
            title: 'Hardware Id',
            dataIndex: 'hardwareId',
        },
        {
            title: 'Serial Number',
            dataIndex: 'serialNumber',
        },
        {
            title: "Device Name",
            dataIndex: "deviceName",
        },
        {
            title: "Actions",
            align: "center" as "center",
            render: (text: string, record: any) => (
                <span className="no-word-wrap">
                {
                        <Tooltip title="Manage Controllers">
                            <Button
                                type="default" 
                                icon={<EditOutlined />}
                                onClick={(e) => { props.openInstallationProgressDrawer("Asset :: (" + record.gatewayName + ' - ' + record.status + ')', drawerWidth, record); }}
                                
                            />
                        </Tooltip>
        }</span>
            )
        }
    ]
    return (

        <div className="layout-content">
            <Row gutter={8}>
                
                <Col xs={24} lg={14}>
                    <div className="content-header page-title">
                        <div style={{ display: "flex" }}>
                            <Tooltip title="Go To Previous Page"> 
                                <BackNavigation /> 
                            </Tooltip>                            
                            {
                                <span>Device OTA Management</span>
                            }
                        </div>
                    </div>
                </Col>

            </Row>
            
            <div className="mt-15">
                <Row justify="end" gutter={24}>
                    
                    <Col xs={24} lg={24} className="content-header-controls">
                        <Space>
                            <Search
                                data-id="csu-search-field"
                                key="search-field"
                                placeholder="Search By Name, Make, Model, Tags"
                                enterButton="Search"
                                size="middle"
                                allowClear
                                onSearch={onSearch}
                                onChange={onSearchChange}
                                className="search-field"
                                value={props.csus.csuList.searchText}
                            />

                            {/* <Button data-id="csu-search-new-button" 
                            //</Space>disabled={props.selectedController == null} 
                            type="primary" icon={<PlusOutlined />} onClick={(e) =>  props.openDrawer("Install Application", drawerWidth, {})}>
                                <span>App Installation</span>
                            </Button> */}
                        </Space>
                    </Col>

                    
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Table 
                            data-id="controllers-data" 
                            className="mt-15"
                            style={{ overflowX: 'auto' }} 
                            {...tableConfig}
                            rowKey={(record) => record.id}
                            columns={tableColumns}
                            dataSource={tableData}
                            onChange={renderControllersTable}
                            showSorterTooltip={false}
                        />
                    </Col>       
                </Row>
                    
            </div>
        <SoftwareInstallationProgress/> 
        </div>

    );
}


const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        csus: state.DeviceReducers.DeviceState.csus,
        selectedCSU: state.DeviceReducers.DeviceState.csus.selectedCSU,
        permissions: state.contextReducer.data,
    };
};

export default connect(
    mapStateToProps,
    actions
)(UDS);