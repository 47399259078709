import React, { useRef, useLayoutEffect } from "react";
import BarGraph, { Options, Signal } from "./BarChart";
import { CommonWidgetConfig, CommonWidgetProps } from "../../models";
import { WidgetDetails } from "../models";
import { inputSignalMapper, signalLabel } from "../common";
import { EditForm } from "./BarChartEditForm";
import useDomNode from "../../../../lib/use-dom-node";
export type BarOptions = Options;

export { EditForm };

export type Config = CommonWidgetConfig & { type: "bar"; settings?: Options };

export type Props = CommonWidgetProps & { settings?: Options };

export const details: WidgetDetails = {
  type: "bar",
  name: "Bar Chart",
};

const createSignals = inputSignalMapper<Signal>((meta, data) => ({
  ...meta,
  ...data[0],
  name: signalLabel(meta),
}));

export const Widget: React.FC<Props> = (props) => {
  const [node, setNode] = useDomNode<HTMLDivElement>();
  const chartRef = useRef<BarGraph>();

  const { template, data, settings, signals } = props;

  useLayoutEffect(() => {
    if (node) {
      chartRef.current = new BarGraph(
        node,
        createSignals(signals, template, data),
        settings
      );
      return () => chartRef.current?.destroy();
    }
  }, [node, template, settings, signals, data]);

  return <div style={{ height: "100%" }} ref={setNode} />;
};
