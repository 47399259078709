interface Cache {
  [index: string]: Promise<void>
}

class ScriptCache {
  private _scripts: Cache = {};

  loadScript(script: string): Promise<void> {

    if (this._scripts[script] !== undefined) {
      return this._scripts[script]
    }

    let scriptTag = document.createElement("script");
    scriptTag.src = script;
    window.document.body.appendChild(scriptTag);

    let result = new Promise<void>((resolve, reject) => {
      scriptTag.addEventListener("load", () => {
        resolve();
      });
    });

    this._scripts[script] = result;
    return result;
  }
}

export default new ScriptCache();
