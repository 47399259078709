import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function templateCopyCollection(
    me: any,
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string,
    orgId: string, 
    clonedSignals: any
) {
    return async (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL + `/ui/api/templates/${templateId}/copycollection`,
            clonedSignals,
            {
                responseType: "json",
                headers: {},
                params: {orgId:orgId}
            }
        )
            .then(response => {
                if (callbackFnSuccess != null) {
                   callbackFnSuccess(response,orgId);
                }
            }
            )
            .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                   callbackFnFailure(error);
                }
            }
            );
    }
}