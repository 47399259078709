export const initialState = {
    map: "",
    mapMode: "",
    loading: false,
    assetDrawer: {
        instance: 0,
        show: false,
        width: 440,
        title: "",
        mode: "",
        cluster: {
            assets: [],
            filteredAssets: [],
            zoomTo: null,
            show: false 
        },
        assetSearch: {
            assets: [],
            show: false
        }
    },
    fleet: { 
        assets: [],
        filteredAssets: []
    },
    assetSearchValue: '',
    assetSearchFilters: {
        critical: false,
        warning: false,
        info: false,
        dm1: false,
        online: false,
        offline: false,
        asset_down: false
    },
    preSearchAssetList: [],
    showFilterPanel: false,
    filterEnabled:true,
    filters: {
        critical: false,
        warning: false,
        info: false,
        dm1: false
    },
    filterCounts: {
        critical: 0,
        warning: 0,
        info: 0,
        dm1: 0
    },
    preferedSignals: {
        assetId: null,        
        signals: []
    },
    tooltip: {
        visibility: false,
        overlay: null,
        marker: null,  
        gmarker: null,   
        asset: null    
    }
};
  