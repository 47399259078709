import React, { Component } from "react";
import {
  Table,
  Button,
  Popover,
  Popconfirm,
  Input,
  Row,
  Col,
  Space,
  Tooltip,
  Switch,
  Typography,
  message,
} from "antd";
import BackNavigation from "../../utils/Components/BackNavigation";
import history from "../../utils/history";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { appConfigs } from "../../utils/configurations";
import { closePopover } from "../../utils/commonHelpers";
import * as actions from "./actions";
import { localizeUtcDate, getPermissions } from "../../utils/commonHelpers";
import SchedulesMaint from "./SchedulesMaint";
import "./schedules.css";

const { Search } = Input;
const { Text } = Typography;

interface Prop {
  getReportSchedulesList: Function;
  context: any;
  scheduledList: any;
  scheduleMaintOpen: Function;
  permissions: any;
  scheduleSetPagination: Function;
  scheduleSetSearch: Function;
  scheduleStatus: Function;
  getOrgTags: Function;
  getScheduleOrgTags: Function;
}

class ReportSchedules extends Component<Prop> {
  drawerWidth: number = Math.min(
    window.innerWidth,
    appConfigs.settings.drawers.defaultWidth
  );

  componentDidMount() {
    const reportPermissions = getPermissions(
      this.props.permissions.permissions.reports
    );
    if (!this.props.context.user.contextUserIsAdmin && !reportPermissions[1]) {
      history.push("/not-found");
    } else {
      this.props.scheduleSetPagination(this.onPaginationChange);
      this.props.getReportSchedulesList(this, null, null, 1, null);
    }
  }

  onPaginationChange = (page: number) => {
    this.props.getReportSchedulesList(
      this,
      null,
      null,
      page,
      this.props.scheduledList.searchText
    );
  };

  confirmStatus = (record: any, appContext: any, action: string) => (
    <Popconfirm
      title={
        "Are you sure to " +
        (action === "enable" ? "enable" : "disable") +
        " this schedule?"
      }
      onCancel={closePopover}
      onConfirm={(e) => {
        this.onAction(e, record, action);
      }}
      okText="Yes"
      cancelText="No"
    >
      <div>
        {action === "enable" && (
          <Tooltip title="Enable Schedule">
            <Switch
              checkedChildren="on"
              unCheckedChildren="off"
              checked={record.active}
            />
          </Tooltip>
        )}
        {action !== "enable" && appContext.roleName.toLowerCase() === "admin" && (
          <Tooltip title="Disable Schedule">
            <Switch
              checkedChildren="on"
              unCheckedChildren="off"
              checked={record.active}
            />
          </Tooltip>
        )}
      </div>
    </Popconfirm>
  );

  confirmDeletion = (record: any) => (
    <Popconfirm
      title={
        "Are you absolutely sure to delete this schedule?\n\nThis action cannot be undone!"
      }
      onCancel={closePopover}
      onConfirm={(e) => {
        this.onAction(e, record, "delete");
      }}
      okText="Yes"
      cancelText="No"
    >
      <div>
        {
          <Tooltip title="Delete schedule">
            <Button
              data-id="schedule-delete-button"
              type="default"
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        }
      </div>
    </Popconfirm>
  );

  onAction = (e: any, record: any, action: string) => {
    e.preventDefault();

    if (action === "new") {
      this.props.scheduleMaintOpen(
        this,
        "New Schedule",
        this.drawerWidth,
        {},
        action
      );
      this.props.getOrgTags(this, null, null, "all", record.orgId);
      this.props.getScheduleOrgTags(this, null, null, "all", record.orgId);
      
    } else if (action === "edit") {
      this.props.getScheduleOrgTags(this, null, null, "all", record.orgId);
      this.props.scheduleMaintOpen(
        this,
        "Edit Schedule :: " + record.scheduleName,
        this.drawerWidth,
        record,
        action
      );
      //this.props.getOrgTags(this, null, null, "all", record.orgId);
      
    } else if (
      action === "enable" ||
      action === "disable" ||
      action === "delete"
    ) {
      this.props.scheduleStatus(
        this,
        this.onStatusSuccess,
        null,
        record.scheduleId,
        action
      );
    }

    closePopover();
  };

  onStatusSuccess = (record: any, userId: string) => {
    message.success("Schedule has been successfully updated");
    this.props.getReportSchedulesList(
      this,
      null,
      null,
      this.props.scheduledList.tableConfig.pagination.current,
      this.props.scheduledList.searchText
    );
  };

  onSearch = (searchText: string) => {
    let me = this;

    this.props.scheduleSetSearch(searchText || "");
    setTimeout(function () {
      me.props.getReportSchedulesList(me, null, null, 1, searchText);
    }, 100);
  };

  onSearchChange = (e: any) => {
    let value = e.target.value;
    this.props.scheduleSetSearch(value || "");
  };

  actions = (record: any, appContext: any, contextUser: any) => (
    <Popover
      content={
        <div className="center-div">
          <Space size="large">
            {record.active ? <Tooltip title="Edit Schedule">
              <Button
                data-id="sch-edit-button"
                type="default"
                icon={<EditOutlined />}
                onClick={(e) => {
                  this.onAction(e, record, "edit");
                }}
              /></Tooltip>: <Tooltip title="Please Enable Schedule To Edit">
              <Button
                  data-id="sch-edit-button"
                  type="default"
                  icon={<EditOutlined />}
                  disabled
                />
              </Tooltip>}
            {this.props.context.user.contextUserIsAdmin &&
              this.confirmDeletion(record)}
            {!record.active &&
              this.props.context.user.contextUserIsAdmin &&
              this.confirmStatus(record, appContext, "enable")}
            {record.active &&
              this.props.context.user.contextUserIsAdmin &&
              this.confirmStatus(record, appContext, "disable")}
          </Space>
        </div>
      }
      title={<div className="center-div">Actions</div>}
      trigger="click"
    >
      <Button
        data-id="sch-actions-button"
        type="default"
        icon={<EllipsisOutlined />}
      />
    </Popover>
  );

  render() {
    const { contextUser, appContext } = this.props.context;
    const { tableConfig, tableData } = this.props.scheduledList;

    const tableColumns = [
      {
        title: "Name",
        dataIndex: "scheduleName",
        render: (scheduleName: string, record: any) => (
          <>
            {record.active.toString() !== "true" && (
              <>
                <Text type="danger">{scheduleName}</Text>
              </>
            )}
            {record.active.toString() === "true" && <span>{scheduleName}</span>}
          </>
        ),
      },
      {
        title: "Organization",
        dataIndex: "orgName",
      },
      {
        title: "Reports",
        dataIndex: "reports",
      },
      {
        title: "Org Tags",
        dataIndex: "orgTags",
      },
      {
        title: "Asset Tags",
        dataIndex: "tags",
      }, 
      {
        title: "Frequency",
        dataIndex: "scheduled",
      },
      {
        title: "Scheduled Time",
        dataIndex: "scheduledTime",
        render: (text: any, record: any) => (
          <>
            {record.scheduledTime ? (
              <span>{new Date(record.scheduledTime).toLocaleTimeString()}</span>
            ) : (
              <>{appConfigs.app.assets.offlineHavingNoDateText}</>
            )}
          </>
        ),
      },
      {
        title: "Created By",
        dataIndex: "createdBy",
      },
      {
        title: "Last Run",
        dataIndex: "lastRun",
        render: (lastRun: string, record: any) => (
          // <>{lastRun ? localizeUtcDate(lastRun, this.props.scheduledList.dateFormatOptions) : lastRun}</>
          <>
            {record.status !== "success" && (
              <>
                <Tooltip title="System failed to send the scheduled report. We will attempt to run manually.">
                  <Text type="warning">
                    {lastRun
                      ? localizeUtcDate(
                          lastRun,
                          this.props.scheduledList.dateFormatOptions
                        )
                      : lastRun}
                  </Text>
                </Tooltip>
              </>
            )}
            {record.status === "success" && (
              <span className="last-run-sucess-text">
                {lastRun
                  ? localizeUtcDate(
                      lastRun,
                      this.props.scheduledList.dateFormatOptions
                    )
                  : lastRun}
              </span>
            )}
          </>
        ),
      },

      {
        title: "Actions",
        key: "action",
        align: "center" as "center",
        fixed: 'right',
        render: (text: any, record: any) =>
          this.actions(record, appContext, contextUser),
      },
    ];

    return (
      <div data-id="schedules-container" className="layout-content">
        <Row gutter={8}>
          <Col xs={24} lg={6}>
            <div className="content-header page-title">
              <div style={{ display: "flex" }}>
                <Tooltip title="Go To Previous Page">
                  <BackNavigation />
                </Tooltip>
                Schedules
              </div>
            </div>
          </Col>

          <Col xs={24} lg={18} className="content-header-controls">
            <Space size="middle">
              <Search
                data-id="schedules-search-field"
                key="search-field"
                placeholder="Search by Org, Report, Name"
                enterButton="Search"
                size="middle"
                allowClear
                className="search-field"
                onSearch={this.onSearch}
                onChange={this.onSearchChange}
                value={this.props.scheduledList.searchText}
              />
              <Tooltip title="Create New Schedule">
                <Button
                  data-id="add-new-schedule-button"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={(e) => this.onAction(e, {}, "new")}
                >
                  <span>Schedule</span>
                </Button>
              </Tooltip>
            </Space>
          </Col>
        </Row>
        <div className="mt-15">
          <SchedulesMaint />
          <Table
           size='small'
          //  scroll={{ x: 1200, y: 400 }}
            data-id="schedules-data"
            {...tableConfig}
            rowKey={(record) => record.scheduleId}
            columns={tableColumns}
            dataSource={tableData}
            tableLayout='fixed'
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    context: state.contextReducer.context,
    permissions: state.contextReducer.data,
    scheduledList:
      state.reportSchedulesReducers.reportScheduledState.scheduledList,
  };
};

export default connect(mapStateToProps, actions)(ReportSchedules);
