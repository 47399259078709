import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";
import { Space, Badge, Switch, Tooltip, Row, Col, Divider } from "antd";
import { ReactComponent as BellSvg } from "../../../assets/images/bell.svg";
import { ReactComponent as ChatSvg } from "../../../assets/images/chat.svg";
import { ReactComponent as Dm1Pin } from "../../../assets/images/map-pins/dm1-pin.svg";
import { PermissionProps } from "../models";
import { connect } from "react-redux";
import * as actions from "../../../actions";
import { templateContainsMSIntervals } from "../../../utils/commonHelpers";
import "../assetnotes.css";


export interface ExtraProps {
  orgId?: string;  
  mastertag?: string;
  temperature?: any;
  timezone?: any;
  assetCallBackFnSuccess: (filterData: any,isTimezoneChanged: any) => any;
  onLayoutViewChange: (checked: boolean) => void;
  refreshWidgets?: () => void;
  datapoints?: any;
  gpsCoordinates?:any;
}
interface SignalR {
  enableSignalRData: Function;
  disableSignalRData: Function;
  onAssetNotesDrawerToggle: Function,
  signalRDataEnabled: boolean;
  context: any;
  filterPreferences: Function;
}

interface Dashboard {
  dashboard: any;
  onAssetNotesDrawerToggle: Function,
}


type Props = ExtraProps & PermissionProps & SignalR & Dashboard;

const DashboardHeaderExtra: React.FC<Props> = (props) => {
  const { isUpdate, mastertag, onLayoutViewChange, refreshWidgets, temperature,timezone } = props;
  const styles: CSSProperties = {
    fontSize: "14px",
    fontWeight: 400,
    verticalAlign: "text-bottom",
    marginRight: "10px",
  };
  const convertTemperature = (checked: boolean) => {
    let value = temperature.value == "°C" ? '°F' : '°C';
    props.filterPreferences('assets', 'temperature', value, false, props.assetCallBackFnSuccess,false);
  };

  const convertTimezone = (checked: boolean) => {
    let value = timezone.value == "asset" ? 'local' : 'asset';
    props.filterPreferences('assets', 'timezone', value, false, props.assetCallBackFnSuccess,true);
       
  }; 

  const enableLiveData = (enabled: any) => {

    // Refresh all widgets with news values
    if (refreshWidgets) {
      refreshWidgets()
    }

    if (enabled) {
      props.enableSignalRData()
    } else {
      props.disableSignalRData()
    }

  }

  const toggleAssetNotesDrawer = () => {
    props.onAssetNotesDrawerToggle(!props.dashboard.assetNotes.visible);
  };

  return (
    <>
      
      <Space key="page-header-space" direction="horizontal" size="small">
        {
          <Tooltip placement="bottom" overlayStyle={{ maxWidth: '450px' }} title={
            props.dashboard.assetNotes.notes.all.length > 0 ?
            <>
              <span><b>Asset Notes</b></span>
              <hr/>
              <span>Most Recent Note:</span><br/>
                <span>{props.dashboard.assetNotes.notes.all[props.dashboard.assetNotes.notes.all.length - 1].noteBody}</span><br /><br />
                <span style={{'float': 'right'}}>{props.dashboard.assetNotes.notes.all[props.dashboard.assetNotes.notes.all.length - 1].userName} - {props.dashboard.assetNotes.notes.all[props.dashboard.assetNotes.notes.all.length - 1].createdAt}</span><br />
            </>
            :
            "Asset Notes"
          }>
            <Badge dot={props.dashboard.assetNotes.notes.pinned.length > 0}>
              <ChatSvg onClick={toggleAssetNotesDrawer} style={{ verticalAlign: "middle", fill: props.dashboard.assetNotes.notes.all.length ? "#18990f" : "gray", cursor: 'pointer'  }} />
            </Badge>
          </Tooltip>
        } 
        <Link
          to={{
            pathname: "/alerts",
            state: [{ mode: "signal-alarms", mastertag: mastertag }],
          }}
        >
          <Tooltip title="Click To View Asset's Signal Alarms List">
            <BellSvg style={{ verticalAlign: "middle" }} />
          </Tooltip>
        </Link>

        <Link
          to={{
            pathname: "/alerts",
            state: [{ mode: "dm1-faults", mastertag: mastertag }],
          }}
        >
          <Tooltip title="Click To View Asset's DM1 Faults List">
            <Dm1Pin style={{ verticalAlign: "middle" }} />
          </Tooltip>
        </Link>
        {isUpdate ?
          <Tooltip title="Click and drag and drop widget's headers to rearrange or click on widget's bottom right corner to resize">
            <Switch
              key="layoutView"
              checkedChildren="Off"
              unCheckedChildren="On"
              onChange={onLayoutViewChange}
            />
          </Tooltip>
          : ''
        }
        {
          <Tooltip title={(!props.gpsCoordinates.latitude && !props.gpsCoordinates.longitude)?"GPS signal not available":"Toggle between Local and Asset timezone"}>
            <Switch
              key="layoutView"
              checkedChildren={timezone.value == "asset" ? (!props.gpsCoordinates.latitude && !props.gpsCoordinates.longitude)? "Local timezone": "Asset timezone": "Local timezone"}
              unCheckedChildren={timezone.value == "asset" ? "Local timezone": "Asset timezone"}
              checked={(timezone.value == null || timezone.value) ? true : false}
              disabled={!props.gpsCoordinates.latitude && !props.gpsCoordinates.longitude}
              onChange={convertTimezone}
            />
          </Tooltip>
        }
        {
          <Tooltip title="Toggle between °F and °C">
            <Switch
              key="layoutView"
              checkedChildren={temperature.value == "°C" ? "°C" : "°F"}
              unCheckedChildren={temperature.value == "°C" ? "°F" : "°C"}
              checked={(temperature.value == null || temperature.value) ? true : false}
              onChange={convertTemperature}
            />
          </Tooltip>
        }    
        { props.hasOwnProperty('datapoints') ?
          <>
            { !templateContainsMSIntervals(props?.datapoints) ?
              <Tooltip title="If enabled, widgets will auto refresh automatically (except Cloud-to-Device chat history)">
                <Switch
                  key="enableSignalRData"
                  checkedChildren="Auto Refresh"
                  unCheckedChildren="Auto Refresh"
                  defaultChecked={props.signalRDataEnabled}
                  checked={props.signalRDataEnabled}
                  onChange={enableLiveData}
                />
              </Tooltip>
              : ''
            }
          </>
          : ''
        }
        
      </Space>
    </>
  );
};

// export default DashboardHeaderExtra;
const mapStateToProps = (state: any) => {
  return {
    signalRDataEnabled: state.contextReducer.signalRData.enabled,
    context: state.contextReducer.context,
    dashboard: state.dashboardReducer.context,
    gpsCoordinates: state.contextReducer.gpsCoordinates
  };
};
export default connect(
  mapStateToProps,
  actions
)(DashboardHeaderExtra);
