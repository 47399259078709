import { AssetOverview, Filters } from "../../models";

interface FilterFunc {
  (asset: AssetOverview): boolean;
}

function filterStatus(
    status:
        | "recentDm1Count"
        | "recentCriticalCount"
        | "recentWarningCount"
        | "recentInfoCount"
        | "assetCustomFields"
) {

      return (asset: AssetOverview) => {
        if(status !== 'assetCustomFields'){
          return asset[status] > 0;
        } else if (status === 'assetCustomFields' && asset[status]) {
            return asset[status].includes(`"additional_info":"active"`)
          } else {
            return false
          }
      }
}

export function acceptAll(asset: AssetOverview) {
  return true;
}

export function statusFilter(filters: Filters) {
  let conditions: FilterFunc[] = [];

  if (filters.critical) {
    conditions.push(filterStatus("recentCriticalCount"));
  }

  if (filters.warning) {
    conditions.push(filterStatus("recentWarningCount"));
  }

  if (filters.info) {
    conditions.push(filterStatus("recentInfoCount"));
  }

  if (filters.dm1) {
    conditions.push(filterStatus("recentDm1Count"));
  }

  if (filters.asset_down) {
    conditions.push(filterStatus("assetCustomFields"));
  }

  if (conditions.length === 0) {
    return acceptAll;
  }

  return (asset: AssetOverview) => conditions.some((f) => f(asset));
}


