import React, { useEffect, useRef, useState } from "react";
import { Drawer, Button, Tabs, Space, Input, Row, Col, Divider, message, Collapse } from "antd";


import { connect } from "react-redux";
import * as actions from "../../actions";

import { appConfigs } from "../../utils/configurations";
import { EnterOutlined, StarFilled, StarOutlined, StarTwoTone } from "@ant-design/icons";

import "./assetnotes.css";
const { Panel } = Collapse;
const { TextArea } = Input;
interface Props {
    onAssetNotesDrawerToggle: Function,    
    getAssetNotes: Function,    
    addNewNote: Function,    
    pinMessage: Function,
    getAssetGlobalNotes: Function,  
    assetDetails: any,
    dashboard: any,
    context: any,
}

const AssetNotes: React.FC<Props> = (props) => {

    const [newNote, setNewNote] = useState<string>('');
    const messagesEndRef = useRef<any>(null)
    useEffect(() => {
        props.getAssetNotes(props.assetDetails.id, getNotesError);
        props.getAssetGlobalNotes(props.assetDetails.orgId, getNotesError)
    }, [])

    const getNotesError = (resMessage: any) => {
        message.error(resMessage)
    };
   
    const onAssetNotesDrawerToggle = () => {
        props.onAssetNotesDrawerToggle(!props.dashboard.assetNotes.visible);
    };
    
    const addNewNote = () => {

        if (newNote.length >= 2000) {
            message.warning("Max characters allowed is 2000")
            return 
        } 

        if (newNote.trim() != '') {
            props.addNewNote(newNote.replaceAll("\n", "<br/>"), props.assetDetails.id, addNoteSuccess, addNoteError);
            setNewNote("")
        } else {
            message.warning("Note cannot be empty")
        }
        
    }, addNoteSuccess = async (resMessage: any) => {
        await props.getAssetNotes(props.assetDetails.id, getNotesError)
        message.success(resMessage)
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
        setNewNote("")
    }, addNoteError = (resMessage: any) => {
        message.error(resMessage)
        setNewNote("")
        };
    
    

    const pinMessage = (messageId: any, currentPinStatus: any) => {
        let pinStatus =currentPinStatus == 'true' ? 'false' : 'true'
        props.pinMessage(messageId, pinStatus, pinNoteSuccess, pinNoteError);
    }, pinNoteSuccess = async (resMessage: any) => {
        await props.getAssetNotes(props.assetDetails.id, getNotesError)
        message.success(resMessage)
    }, pinNoteError = (resMessage: any) => {
        message.error(resMessage)
    };

    
    const onTypingNotes = (e: any) => {
        setNewNote(e.target.value);
    };
    return (
      <Drawer
        data-id="asset-notes-drawer"
        title={props.dashboard.assetNotes.title}
        width={props.dashboard.assetNotes.width}
        visible={props.dashboard.assetNotes.visible}
        onClose={onAssetNotesDrawerToggle}
        destroyOnClose={true}
        footer={
          <Row gutter={8}>
            <Col span={24}>
              <TextArea
                value={newNote}
                rows={4}
                onChange={(e) => onTypingNotes(e)}
                placeholder="Enter New Note"
              />
            </Col>
            <Col className="mt-5" span={24}>
              <Button block type="primary" onClick={addNewNote}>
                Add New Note
              </Button>
            </Col>
          </Row>
        }
      >
        <Collapse accordion defaultActiveKey="3">
          <Panel header="Global Notes" key="1">
          {props.dashboard.assetNotes.notes.globalNotes?.length > 0 && (
          <div>
            {props.dashboard.assetNotes.notes.globalNotes?.map(
              (globalNote: any, key: number) => {
                return (
                  <p
                    key={key}
                    className={`note othersMessages`}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: globalNote.noteBody }}
                    ></span>
                    <br />
                    <span className="timestamp">
                      {globalNote.createdBy} - {globalNote.createdAt}
                    </span>
                  </p>
                );
              }
            )}
            <Divider />
          </div>
        )}
          </Panel>
          <Panel header="Pinned Asset Notes" key="2">
          {props.dashboard.assetNotes.notes.pinned.length > 0 && (
          <div>
            {props.dashboard.assetNotes.notes.pinned?.map(
              (pinnedNote: any, key: number) => {
                return (
                  <p
                    key={key}
                    className={`note ${
                      pinnedNote.myMessage == "true"
                        ? "myMessages "
                        : "othersMessages"
                    }`}
                  >
                    <StarFilled
                      className={
                        pinnedNote.isPinned == "true"
                          ? "pinnedMessage"
                          : "normalMessage"
                      }
                      onClick={() =>
                        pinMessage(pinnedNote.noteId, pinnedNote.isPinned)
                      }
                    />
                    <span
                      dangerouslySetInnerHTML={{ __html: pinnedNote.noteBody }}
                    ></span>
                    <br />
                    <span className="timestamp">
                      {pinnedNote.userName} - {pinnedNote.createdAt}
                    </span>
                  </p>
                );
              }
            )}
            <Divider />
          </div>
        )}
          </Panel>
          <Panel header="Asset Notes" key="3">
          {props.dashboard.assetNotes.notes.all.length > 0 && (
          <>
            {props.dashboard.assetNotes.notes.all?.map(
              (pinnedNote: any, key: number) => {
                if (pinnedNote.isPinned == "false") {
                  return (
                    <p
                      key={key}
                      className={`note ${
                        pinnedNote.myMessage == "true"
                          ? "myMessages "
                          : "othersMessages"
                      }`}
                    >
                      <StarFilled
                        className={
                          pinnedNote.isPinned == "true"
                            ? "pinnedMessage"
                            : "normalMessage"
                        }
                        onClick={() =>
                          pinMessage(pinnedNote.noteId, pinnedNote.isPinned)
                        }
                      />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: pinnedNote.noteBody,
                        }}
                      ></span>
                      <br />
                      <span className="timestamp">
                        {pinnedNote.userName} - {pinnedNote.createdAt}
                      </span>
                    </p>
                  );
                }
              }
            )}
          </>
        )}
          </Panel>
        </Collapse>
     
        <div style={{ float: "left", clear: "both" }} ref={messagesEndRef} />
      </Drawer>
    );
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        dashboard: state.dashboardReducer.context
    };
};

export default connect(
    mapStateToProps,
    actions
)(AssetNotes);