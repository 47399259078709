import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";
import { formatTagsForPosting } from "../../../utils/commonHelpers";

export function countriesGet(
    me: any, 
    callbackFnSuccess?: any,
    callbackFnFailure?: any
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/countries", {
                responseType: "json",        
                headers: {},
                params: {
                    scope: "all"
                }
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.countries);
                }
                dispatch({ type: "ORGS_COUNTRIES_SUCCESS", payload: {data: response.data.data.countries} });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
    };
}

export function countryStatesGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure?: any,
    countryId?: string,
    countryCode?: string,
    countryName?: string
) {
    return async (dispatch: any) => {
        let url = "/ui/api/states";

        if ((countryId || "") !== "") {
            url = "/country/id/" + countryId;
        }
        else if ((countryCode || "") !== "") {
            url = "/country/code/" + countryCode;
        }
        else if ((countryCode || "") !== "") {
            url = "/country/name/" + countryName;
        }
        
        Axios.get(
            appConfigs.server.URL + url, {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.states);
                }
                dispatch({ type: "ORGS_COUNTRY_STATES_SUCCESS", payload: {data: response.data.data.states} });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
    };
}

export function phoneCountriesGet(
    me: any, 
    callbackFnSuccess?: any,
    callbackFnFailure?: any
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/countries", {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.countries);
                }
                dispatch({ type: "ORGS_PHONE_COUNTRIES_SUCCESS", payload: {data: response.data.data.countries} });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
    };
}
export function orgMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    record: any
) {
        return async (dispatch: any) => {
            record.orgTags = formatTagsForPosting(record.orgTags);
            record.removedTags = formatTagsForPosting(record.removedTags);
            record.newTags = formatTagsForPosting(record.newTags);
            Axios.post(
            appConfigs.server.URL + "/ui/api/orgs",
            record,
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
            let data = response.data.data.org
            data.orgTags = response.data.data.org.orgTags?JSON.parse(response.data.data.org.orgTags): undefined;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "ORG_MAINT_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
        }
}

export function orgLevelTags(
    me: any,
    callbackFnSuccess: any,
    callbackFnFailure: any,
    status: string,
    orgId: string
  ) {
    return async (dispatch: any) => {
      Axios.get(appConfigs.server.URL + "/ui/api/orgtags", {
        responseType: "json",
        headers: {},
        params: {
          orgId: orgId,
          
        },
      })
        .then((response) => {
          let data =
            response.data.data.orgTags == null ||
            (response.data.data.orgTags.length || 0) === 0
              ? []
              : response.data.data.orgTags;
          if (callbackFnSuccess != null) {
            callbackFnSuccess(data);
          }
          data = data
            .map((tag: any) => tag["tagName"].toUpperCase())
            .filter((item: any, i: any, ar: any) => ar.indexOf(item) === i)
            .sort();

          dispatch({ type: "ORG_TAGS_SUCCESS", payload: { data: data } });
        })
        .catch(function (error) {
          if (callbackFnFailure != null) {
            callbackFnFailure(me, error);
          }
          errorResponse(me, error);
        });
    };
  }
  

export function orgLogoMaint(
    me: any, 
    callbackFileSuccess: any,
    callbackFileFailure: any,
    callbackFnSuccess: any,
    callbackFnFailure: any,
    action: string,
    formData: any,
    file: any
) {
    return async (dispatch: any) => {
        Axios.post(
            action,
            formData,
            {
                responseType: "json",       
                headers: {
                    "content-type": "multipart/form-data" 
                }
            }
        )
        .then(response => {                
                callbackFileSuccess(response.data, file);
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.logo.uploadId, response.data.data.logo.url);
                }
            }
        )        
        .catch(function (error) {
                errorResponse(me, error, false);
                callbackFileFailure(error);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    };
}    

export function orgLoginBackgroundMaint(
    me: any, 
    callbackFileSuccess: any,
    callbackFileFailure: any,
    callbackFnSuccess: any,
    callbackFnFailure: any,
    action: string,
    formData: any,
    file: any
) {
    return async (dispatch: any) => {
        Axios.post(
            action,
            formData,
            {
                responseType: "json",       
                headers: {
                    "content-type": "multipart/form-data" 
                }
            }
        )
        .then(response => {                
                callbackFileSuccess(response.data, file);
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.loginBackground.uploadId, response.data.data.loginBackground.url);
                }
            }
        )        
        .catch(function (error) {
                errorResponse(me, error, false);
                callbackFileFailure(error);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    };
}

export function languagesGet(
    me: any, 
    callbackFnSuccess?: any,
    callbackFnFailure?: any
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/languages", {
                responseType: "json",        
                headers: {},
                params: {
                    scope: "all"
                }
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.languages);
                }
                dispatch({ type: "ORGS_LANGUAGES_SUCCESS", data: response.data.data.languages });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
    };
}
export function getOrgGlobalNotesList(
    me: any,
    callbackFnSuccess: any,
    callbackFnFailure: any,
    noteType: string,
    orgId: string
  ) {
    
    return async (dispatch: any) => {
      Axios.get(appConfigs.server.URL + "/ui/api/orgs/" + orgId + "/getGlobalNotes", {
        responseType: "json",
        headers: {},
        params: {
          noteType: noteType
        },
      })
        .then((response) => {
          let data =
            response.data.data.globalNotes == null ||
            (response.data.data.globalNotes.length || 0) === 0
              ? []
              : response.data.data.globalNotes;
          if (callbackFnSuccess != null) {
            callbackFnSuccess(data);
          }
          dispatch({ type: "ORGS_GET_GLOBAL_NOTES", payload: { data: data } });
        })
        .catch(function (error) {
          if (callbackFnFailure != null) {
            callbackFnFailure(me, error);
          }
          errorResponse(me, error);
        });
    };
  }
export function orgGlobalNotesMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    mode:string,
    record: any
) {
        return async (dispatch: any) => {
            Axios.post(
            appConfigs.server.URL + "/ui/api/orgs/globalNote",
            record,
            {
                responseType: "json",       
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json' 
                },
                params: {
                    mode: mode
                }
            }
        )
        .then(response => {
                let data = response.data.data.org
                dispatch({ type: "ORG_GLOBAL_NOTES_MAINT_SUCCESS", payload: {data: data} });
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data,mode);
                }
                
            }
        )
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
        }
}
export function getServicePlansComparisionSheet() {
    
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/get_tutorials"
        )
        .then(response => { 
                dispatch({ type: "TUTORIALS_GET_SUCCESS", data: response.data.data.tutorials });
                console.log(response.data.data.tutorials);
            }
        )
        .catch(function (error) {
            console.log(`error`, error)
        });
    };
}