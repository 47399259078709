import { Button, Card, Col, Divider, Drawer, Form, Input, message, Row, Select, Space, Tabs } from "antd";
import { FormInstance } from 'antd/lib/form';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formInit } from "../../utils/apiHelpers/formHelpers";
import { appConfigs } from "../../utils/configurations";
import * as actions from "./actions";

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

interface Prop {
    context: any,
    controllers: any,
    controllerMaintOptions: any,
    selectedController: any,
    closeDrawer: Function,
    drawerTabChange: Function,
    getApplications: Function,
    getApplicationVersions: Function,
    initiateControllerOTA: Function,
}

const AppMaintInstallation: React.FC<Prop> = (props) => {

    const formRef = React.createRef<FormInstance>();
    
    const [selectedApp, setSelectedApp] = useState<any>({})

    const { applications } = props.controllers;  
    
    const onDrawerClose = () => {
        props.closeDrawer();
    };

    const onTabChange = (key: string) => {
        props.drawerTabChange(key);
    }

    useEffect(() => {
        props.getApplications(props.controllerMaintOptions.controllerId, callbackFnFailure)
    }, [])


    const onFinish = (values: any) => {

        console.log("onFinish")
        console.log(props.selectedController)
        console.log(formRef.current?.getFieldsValue())
        props.initiateControllerOTA(props.selectedController, formRef.current?.getFieldsValue(), callbackFnSuccess, callbackFnFailure);

        props.closeDrawer();

    }

    const getVersions = (value: any) => {
        let selectedApp = applications.filter((app: any) => app.id == value)[0]

        formRef.current!.setFieldsValue({
            applicationType: selectedApp.type.charAt(0).toUpperCase() + selectedApp.type.slice(1),
            requiresApproval: selectedApp.requiresApproval.charAt(0).toUpperCase() + selectedApp.requiresApproval.slice(1),
            currentVersion: selectedApp.version,
            gatewayId: props.selectedController.gatewayId,
            controllerId: props.selectedController.controllerId,
            orgId: selectedApp.orgId,
            storageLocation: selectedApp.storageLocation,
        });
        // props.getApplicationVersions(value, callbackFnFailure)
    }

    const callbackFnFailure = (error: any) => {
        message.error("Failed to initiate OTA")
    }

    const callbackFnSuccess = (messageDetails: any) => {
        message.success("OTA Initiated Successfully")
    }

    return (
        <Drawer
            title={props.controllerMaintOptions.title}
            width={props.controllerMaintOptions.width}
            onClose={onDrawerClose}
            visible={props.controllerMaintOptions.visible}
            destroyOnClose={true}
            footer={
                <Space size="large" style={{ float: 'right' }}>
                    <Button type="default" onClick={onDrawerClose}>
                        {appConfigs.settings.drawers.closeButtonText}
                    </Button>

                    <Button data-id="controllers-maint-submit-button" type="primary" form="appMaintInstallationForm" key="submit" htmlType="submit">
                        {appConfigs.settings.form.submitButtonText}
                    </Button>
                    
                </Space>
            }
        >
            {
                <Tabs activeKey={props.controllerMaintOptions.activeTab} size="large" type="card" onChange={onTabChange}>                    
                    <TabPane tab={props.controllerMaintOptions.tabs[0].title} key="1" disabled={props.controllerMaintOptions.tabs[0].disabled}>
                        <Form
                            id="appMaintInstallationForm"
                            ref={formRef}
                            layout="vertical"
                            onFinish={onFinish}
                        >       
                            <Row gutter={8}>
                                <Col span={24}>
                                    <Form.Item name="controllerId" style={{ display: 'none' }} >
                                        <Input type="hidden" />
                                    </Form.Item>
                                </Col> 
                            </Row>   
                            <Row gutter={8}>
                                <Col span={24}>
                                    <Form.Item name="gatewayId" style={{ display: 'none' }} >
                                        <Input type="hidden" />
                                    </Form.Item>
                                </Col> 
                            </Row>   
                            <Row gutter={8}>
                                <Col span={24}>
                                    <Form.Item name="orgId" style={{ display: 'none' }} >
                                        <Input type="hidden" />
                                    </Form.Item>
                                </Col> 
                            </Row>   
                            <Row gutter={8}>
                                <Col span={24}>
                                    <Form.Item name="storageLocation" style={{ display: 'none' }} >
                                        <Input type="hidden" />
                                    </Form.Item>
                                </Col> 
                            </Row>   
                          
                            <Row gutter={8}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Select Application"
                                        name="applicationId"    
                                        shouldUpdate={true}    
                                        rules={[
                                            { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                                        ]}
                                    >
                                        <Select
                                            placeholder="Select Application to Install"
                                            onChange={getVersions}
                                        >
                                            {applications !== null && applications.map((application:any, index:number) => (
                                                <Option key={application.id} value={application.id} >
                                                    Type: { application?.type?.charAt(0).toUpperCase() + application?.type?.slice(1)  } - Name: {application?.name} - Version: {application?.version}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>                        
                            </Row>    
                            
                            <Row gutter={8}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Application Type"
                                        name="applicationType"
                                        rules={[{ required: false, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >
                                        <Input maxLength={255} disabled={true} />
                                    </Form.Item>
                                </Col> 
                            

                                {/* <Col span={12}>
                                    <Form.Item
                                        label="Application Version"
                                        name="appVersion"                                 
                                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >
                                        <Select
                                            placeholder="Select Application Version"
                                        >
                                            {appVersions !== null && appVersions.map((version:any, index:number) => (
                                                <Option key={version.versionId} value={version.versionId} >
                                                    {version.versionNumber}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col> */}

                            </Row>    


                            <Row gutter={8}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Requires Operator Approval"
                                        name="requiresApproval"
                                        rules={[{ required: false, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >
                                        <Input maxLength={255} disabled={true} />
                                    </Form.Item>
                                </Col> 
                            

                                <Col span={12}>
                                    <Form.Item
                                        label="Current Installed Version"
                                        name="currentVersion"                                 
                                        rules={[{ required: false, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >
                                        <Input maxLength={255} disabled={true} />
                                    </Form.Item>
                                </Col>

                            </Row> 
                           

                        </Form>
                    </TabPane> 
                </Tabs>   
            }         
        </Drawer>
    );
}

const mapStateToProps = (state: any) => {
    return {
        context: state.contextReducer.context,
        controllers: state.userControllersReducers.userControllersState.controllers,
        controllerMaintOptions: state.userControllersReducers.userControllersState.controllers.controllerMaintOptions,
        selectedController: state.userControllersReducers.userControllersState.controllers.selectedController
    };
};

export default connect(
    mapStateToProps,
    actions
)(AppMaintInstallation);