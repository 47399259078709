import React, { useEffect } from "react";
import { Row, Col, Tooltip, Tabs, Card } from "antd";
import BackNavigation from "../../../utils/Components/BackNavigation";
import { connect } from "react-redux";
import * as actions from "./actions";
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import Icon, {FilePdfOutlined} from "@ant-design/icons";
import { appConfigs } from "../../../utils/configurations";
// import { getToken } from "../../../utils/commonHelpers";
import axios from "axios";


const { TabPane } = Tabs;
interface Props {
    getReleaseNotes: Function,
    setTab: Function,
    context: any,
    releasenotes: any,
    permissions: any
}
const ReleaseNotes: React.FC<Props> = (props) => {
    useEffect(() => {
        props.getReleaseNotes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onTabChange = (key: string) => {
        props.setTab(key)
    }

    const handleFileUpload = async (options: any) => {
        const { onSuccess, onError, file, onProgress } = options; 
        // let token=getToken();

        const formData = new FormData();
        formData.append('file', file);
        formData.append('category', "Release Notes");
        formData.append('fileName', file.name);
        try {
            const response = await axios.post(appConfigs.server.URL + "/ui/api/upload-release-notes", formData, {
                                headers: {
                                    "Content-Type": "multipart/form-data"                                   
                                }
                            });

            if (response.status === 200) {
                message.success('File uploaded successfully!');
                props.getReleaseNotes();
            } else {
                message.error('File upload failed!');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            message.error('File upload failed!');
        }
    };

    const beforeFileUpload = (file:any) => {
        const fileType = file.type === 'application/pdf';
        // const fileSize = file.size / 1024 < 500;

        if (!fileType) {
            message.error('Only PDF file type is allowed');
        }       
        
        return fileType;
    }


    const filteredList = props.releasenotes.releasenotesList.filter((releasenotesdata: any) =>
        releasenotesdata.category === 'Release Notes');
    filteredList.sort((a: any, b: any) => b.id - a.id);


    return (<div className="layout-content">
        <Row gutter={8}>
            <Col xs={24} lg={14}>
                <div className="content-header page-title">
                    <div style={{ display: "flex" }}>
                        <Tooltip title="Go To Previous Page">
                            <BackNavigation />
                        </Tooltip>
                        <span>Release Notes</span>
                    </div>
                </div>
            </Col>
        </Row>
        <div className="mt-15">
            <Tabs activeKey={props.releasenotes.activeTab} size="large" type="card" onChange={onTabChange}>
                <TabPane tab="All Release Notes" key="1">
                    {(
                        props.context.appContext.userId === 'da915c00-d3c9-11ed-92c5-b79e53de7502' || // Rupa prod
                        props.context.appContext.userId === 'ba085340-afa0-11ee-b3af-9f9a6f95957b' || // Rupa dev
                        props.context.appContext.userId === 'a44d5840-5f65-11eb-818b-7f8b88f38ec0' || // Sanath prod
                        props.context.appContext.userId === '52f11900-5f65-11eb-9e05-ef71659b2f26' || // Sanath dev
                        props.context.appContext.userId === '9bb0b810-1d5f-11ea-b10c-611371d47cc6' || // Ravi Teja - Prod
                        props.context.appContext.userId === 'cad29b40-1d5f-11ea-abaa-a34583f0c422' || // Ravi Teja - Dev
                        props.context.appContext.userId === '0a967c60-4015-11ef-8399-f3910b970a6f' ) && // Arsheen - Dev
                        <div>
                            <Upload                               
                                multiple={false}                                
                                beforeUpload={beforeFileUpload}
                                customRequest={handleFileUpload}
                                showUploadList={false}
                            >
                                <Button icon={<UploadOutlined />}>Upload File</Button>
                            </Upload>
                        </div>}

                    <div>
                        <ul style={{ listStyleType: 'disc', padding: '5px' }}>
                            {filteredList.slice(0, 20).map((releasenotesdata: any) => (
                                <li key={releasenotesdata.id} style={{ marginBottom: '5px', fontSize: '16px' }}>
                                    <a href={releasenotesdata.link} target="_blank" rel="noopener noreferrer">
                                        <FilePdfOutlined style={{ margin: '15px', fontSize: '180%' }} />
                                        {releasenotesdata.title}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </TabPane>
            </Tabs>
        </div>
    </div>
    );
}
const mapStateToProps = (state: any) => {
    return {
        permissions: state.contextReducer.data,
        context: state.contextReducer.context,
        releasenotes: state.releasenotesReducers.releasenotesState.releasenotes,
    };
};
export default connect(
    mapStateToProps,
    actions
)(ReleaseNotes);