import * as amCore from "@amcharts/amcharts4/core";
import {
  ValueAxis,
  AxisRendererCircular,
  RadarChart,
  RadarColumnSeries,
  CategoryAxis,
  AxisRendererRadial,
} from "@amcharts/amcharts4/charts";
import {
  TOOLTIP_FORMAT_H,
  NUMBER_FORMAT,
  DATE_FORMAT,
  extendRange,
  TOOLTIP_COLOR,
} from "../common";

export interface Signal {
  name: string;
  values: Array<{ timestamp: Date; value: number }>;
}

export interface Options {
  max: number;
}

export default class Gauge {
  private _chart: RadarChart;

  constructor(ref: HTMLDivElement, signals: Signal[], opts?: Options) {
    const chart = (this._chart = amCore.create(ref, RadarChart));

    const maxValue =
      opts?.max || extendRange(signals[0].values[0].value, 20, "max");

    chart.data = [
      {
        name: signals[0].name,
        ...signals[0].values[0],
        maxValue,
      },
    ];
    chart.startAngle = -180;
    chart.endAngle = 0;
    chart.innerRadius = 80;
    chart.numberFormatter.numberFormat = NUMBER_FORMAT;
    chart.dateFormatter.dateFormat = DATE_FORMAT;

    const xAxis = chart.xAxes.push(new ValueAxis<AxisRendererCircular>());
    xAxis.min = 0;
    xAxis.max = maxValue;
    xAxis.strictMinMax = true;
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.minGridDistance = 100;

    const yAxis = chart.yAxes.push(new CategoryAxis<AxisRendererRadial>());
    yAxis.dataFields.category = "name";
    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.labels.template.disabled = true;

    const fillSeries = chart.series.push(new RadarColumnSeries());
    fillSeries.dataFields = {
      categoryY: "name",
      valueX: "maxValue",
    };
    fillSeries.clustered = false;
    fillSeries.columns.template.fill = new amCore.InterfaceColorSet().getFor(
      "alternativeBackground"
    );
    fillSeries.columns.template.fillOpacity = 0.08;
    fillSeries.columns.template.strokeWidth = 0;

    const valueSeries = chart.series.push(new RadarColumnSeries());
    valueSeries.dataFields = {
      categoryY: "name",
      valueX: "value",
      dateX: "timestamp",
    };
    valueSeries.clustered = false;
    valueSeries.name = chart.data[0].name;
    valueSeries.columns.template.fillOpacity = 0.5;
    valueSeries.columns.template.fill = amCore.color("#333");
    valueSeries.columns.template.tooltipText = TOOLTIP_FORMAT_H;
    if (valueSeries.tooltip) {
      valueSeries.tooltip.getFillFromObject = false;
      valueSeries.tooltip.background.fill = amCore.color(TOOLTIP_COLOR);
      valueSeries.tooltip.pointerOrientation = "vertical";
    }

    const label = valueSeries.createChild(amCore.Label);

    const valueString = chart.data[0].value.toFixed(2);

    // Data formats don't actually work in labels for some reason so
    // it must be manually set.
    label.text = `[font-size: 20px]${valueString}[/]\n[font-size: 10px]{name}`;
    label.align = "center";
    label.horizontalCenter = "middle";
    label.verticalCenter = "bottom";
  }

  public destroy() {
    this._chart.dispose();
  }
}
