import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function templatesState(state = initialState, action:any) {
    let data:any = null;

    switch (action.type) {    
        case "TEMPLATES_SET_PAGINATION":
            return immerProduce(state, draft => {
                draft.templatesList.tableConfig.pagination.onChange = action.onPaginationChange;
            });
        case "TEMPLATES_SET_SEARCH":
            return immerProduce(state, draft => {
                draft.templatesList.searchText = action.searchText;
            });
        case "TEMPLATES_GET_TEMPLATES_SUCCESS":            
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.templatesList.refreshOnUpdate = false;
                draft.templatesList.tableData = data;
                draft.templatesList.tableConfig.pagination.total = (data.length || 0) > 0 ? data[0].totalRecords : 0;
                draft.templatesList.tableConfig.pagination.current = action.payload.page;
            });
        case "TEMPLATES_TEMPLATE_MAINT_OPEN":
            return immerProduce(state, draft => {
                const 
                    payload = action.payload,
                    instance = Math.random();
                draft.templatesList.templateMaint.instance = instance;
                draft.templatesList.templateMaint.width = payload.width;
                draft.templatesList.templateMaint.visible = true;
                draft.templatesList.templateMaint.mode = payload.mode;
                draft.templatesList.templateMaint.title = payload.title;
                draft.templatesList.templateMaint.record = payload.record;

                draft.templatesList.templateMaint.modes[0].visible = payload.mode === "template";
                draft.templatesList.templateMaint.modes[1].visible = payload.mode === "duplicate";
            });                
        case "TEMPLATES_TEMPLATE_MAINT_CLOSE":
            return immerProduce(state, draft => {
                draft.templatesList.templateMaint.visible = false;
            });   
        case "TEMPLATES_LIST_REFRESH_ON_UPDATE":
            return immerProduce(state, draft => {
                draft.templatesList.refreshOnUpdate = true;
            });        
        case "TEMPLATES_MAINT_REFRESH_INSTANCE":
            return immerProduce(state, draft => {
                const instance = Math.random();
                action.instances.forEach((element:number) => {
                    draft.templatesList.templateMaint.modes[element].instance = instance;
                });
            });                          
        case "TEMPLATES_MAINT_SUCCESS":
            return immerProduce(state, draft => {
                draft.templatesList.templateMaint.record = action.record;
                draft.templatesList.templateMaint.title = "Edit Template :: " + action.record.name;
            });                             
        case "TEMPLATES_MAINT_ORGS_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.templatesList.templateMaint.modes[0].orgs = data;
            });
        case "GET_TEMPLATES_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft["templatesList"].templates=data;
            });
        case "TEMPLATE_DETAILS_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft=> {                  
                  draft["templatesList"].selectedTemplateDetails = data;
            });     
        case "TEMPLATES_DUPLICATE_ORGS_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.templatesList.templateMaint.modes[1].orgs = data;
            });
        case "TEMPLATE_SET_GLOBAL_MODE":
            return immerProduce(state, draft => {
                draft.templatesList.mode = action.mode;
            });
        default:
            return state;
    }
}