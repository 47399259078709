import Axios from "axios";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";
import { appConfigs } from "../../../utils/configurations";

export function getReportSchedulesList(
  me: any,
  callbackFnSuccess: any,
  callbackFnFailure: any,
  page: number = 1,
  searchText?: string
) {
  return async (dispatch: any) => {
    Axios.get(appConfigs.server.URL + "/ui/api/report-schedules", {
      responseType: "json",
      headers: {},
      params: {
        searchText: searchText,
        page: page,
        pageSize: appConfigs.tables.pageSize,
      },
    })
      .then((response) => {
        let data =
          response.data.data.schedules == null ||
          (response.data.data.schedules.length || 0) === 0
            ? []
            : response.data.data.schedules;
        if (callbackFnSuccess != null) {
          callbackFnSuccess(data);
        }
        dispatch({
          type: "REPORT_SCHEDULES_LIST_GET_SUCCESS",
          payload: { data: data, page: page },
        });
      })
      .catch(function (error) {
        if (callbackFnFailure != null) {
          callbackFnFailure(me, error);
        }
        errorResponse(me, error);
      });
  };
}

export function scheduleMaintOpen(
  me: any,
  title: string,
  width: number,
  record: any,
  action: any
) {
  return async (dispatch: any) => {
    Axios.get(appConfigs.server.URL + "/ui/api/tags", {
      responseType: "json",
      headers: {},
      params: {},
    }).then((response) => {
      let data =
        response.data.data.orgTags == null ||
        (response.data.data.orgTags.length || 0) === 0
          ? []
          : response.data.data.orgTags;
      data = data
        .map((tag: any) => tag["tagName"].toUpperCase())
        .filter((item: any, i: any, ar: any) => ar.indexOf(item) === i)
        .sort();

      dispatch({ type: "ORG_TAGS_SUCCESS", payload: { data: data } });
      dispatch({
        type: "REPORT_SCHEDULES_MAINT_OPEN",
        payload: {
          title: title,
          width: width,
          record: record,
          action: action,
        },
      });
    });
  };
}

export function scheduleMaintClose(me: any) {
  return (dispatch: any) => {
    dispatch({ type: "REPORT_SCHEDULES_MAINT_CLOSE" });
  };
}

export function scheduleSetPagination(onPaginationChange: any) {
  return (dispatch: any) => {
    dispatch({
      type: "REPORT_SCHEDULES_SET_PAGINATION",
      onPaginationChange: onPaginationChange,
    });
  };
}

export function scheduleSetSearch(searchText: string) {
  return (dispatch: any) => {
    dispatch({ type: "REPORT_SCHEDULES_SET_SEARCH", searchText: searchText });
  };
}

export function scheduleStatus(
  me: any,
  callbackSuccessFn: any,
  callbackFailureFn: any,
  scheduleId: string,
  status: string
) {
  return async (dispatch: any) => {
    Axios.put(
      appConfigs.server.URL +
        "/ui/api/report-schedules/" +
        scheduleId +
        "/" +
        status,
      {},
      {
        responseType: "json",
      }
    )
      .then((response) => {
        if (callbackSuccessFn != null) {
          callbackSuccessFn(response.data.data.scheduleId, scheduleId);
        }
      })
      .catch(function (error) {
        errorResponse(me, error, false);
        if (callbackFailureFn != null) {
          callbackFailureFn(error);
        }
      });
  };
}
