import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function softwaresState(state = initialState, action: any) 
{
    let data: any = null,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    payload: any;
    switch (action.type) 
    {
        case "SOFTWARES_GET_SUCCESS": data = action.payload.data;
          return immerProduce(state, (draft) =>
          {
              draft.softwares.softwaresList.refreshOnUpdate = false;
              draft.softwares.softwaresList.tableData = data;
              draft.softwares.softwaresList.tableConfig.pagination.total = data? data[0].totalRecords: 0;
              draft.softwares.softwaresList.tableConfig.pagination.current =
              action.payload.page;
              draft.softwares.softwareMaintOptions.record = data;
          });
        case "SOFTWARE_MAINT_ORGS_SUCCESS": data = action.payload.data;
          return immerProduce(state, draft => 
          {
              draft.softwares.softwareMaintOptions.orgs = data;
          });
        case "SOFTWARE_SET_SEARCH":
          return immerProduce(state, (draft) => 
          {
              draft.softwares.softwaresList.searchText = action.searchText;
          });
        case "SOFTWARES_SET_PAGINATION":
          return immerProduce(state, (draft) => 
          {
              draft.softwares.softwaresList.tableConfig.pagination.onChange =
              action.onPaginationChange;
          });
        case "SET_TAB":
          return immerProduce(state, (draft) => 
          {
              draft.softwares.activeTab = action.tabNumber;
          });
        case "OPEN_DRAWER":
          return immerProduce(state, (draft) => 
          {
              const payload = action.payload,
              instance = Math.random();
              draft.softwares.softwareMaintOptions.instance = instance;
              draft.softwares.softwareMaintOptions.width = payload.width;
              draft.softwares.softwareMaintOptions.visible = true;
              draft.softwares.softwareMaintOptions.title = payload.title;
              draft.softwares.softwareMaintOptions.activeTab = "1";
              draft.softwares.softwareMaintOptions.record = payload.record;
          });
        case "CLOSE_DRAWER":
          return immerProduce(state, (draft) => 
          {
              draft.softwares.softwareMaintOptions.visible = false;
              draft.softwares.softwareMaintOptions.activeTab = "1";
          });
        case "DRAWER_TAB_CHANGE":
          return immerProduce(state, (draft) => 
          {
              draft.softwares.softwareMaintOptions.activeTab = action.activeTab;
          });
        case "SET_SOFTWARES_LIST":
          return immerProduce(state, (draft) => 
          {
              draft.softwares.softwaresModification.softwaresList =
              action.payload.softwaresList;
              draft.softwares.softwaresModification.loadingSoftwaresList = false;
          });
        case "SET_LOADING_SOFTWARES":
          return immerProduce(state, (draft) => 
          {
              draft.softwares.softwaresModification.loadingSoftwaresList =
              action.payload.state;
          });
        case "ADD_SOFTWARE":
          return immerProduce(state, (draft) => 
          {
              draft.softwares.softwaresModification.submittingSoftwareList =
              action.payload.state;
          });
        case "ADD_SOFTWARE_SUCCESSFULLY":
          return immerProduce(state, (draft) => 
          {
              draft.softwares.softwaresModification.submittingSoftwareList = false;
              draft.softwares.softwaresModification.selectedSoftwareList = [];
          });
        case "UPDATE_FIELD":
          return immerProduce(state, (draft) => 
          {
            if (draft.softwares.softwaresModification.selectedSoftwareList) 
            {
              draft.softwares.softwaresModification.selectedSoftwareList = 
                draft.softwares.softwaresModification.selectedSoftwareList.map((software: any) => {
                  if (software.mastertag === action.payload.mastertag) 
                  {
                    software[action.payload.filedName] = action.payload.value;
                  }
                  return software;
                });
            }
          });
        case "GET_CONTROLLERS_LIST":
          return immerProduce(state, (draft) => 
          {
              draft.softwares.controllersList = action.controllersList;
          });
        case "SOFTWARE_DEVICE_MODEL_SUCCESS":
          data = action.payload.data;
          return immerProduce(state, draft => 
          {
              draft.softwares.softwareMaintOptions.devicemodel = data;
          });
        default: return state;
    }
}
