import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function signalMaintOpen(
    me: any, 
    width: number,
    record: any,
    collectionType: any
) {
    return (dispatch: any) => {
        dispatch({ type: "SIGNAL_MAINT_OPEN", payload: {width: width, record: record, collectionType: collectionType} });
    };
}

export function signalMaintClose(
    me: any
) {   
    return (dispatch: any) => {
        dispatch({ type: "SIGNAL_MAINT_CLOSE" });
    };
}

export function signalMaintSetRecord(
    me: any, 
    record: any
) {
    return (dispatch: any) => {
        dispatch({ type: "SIGNAL_MAINT_SET_RECORD", record: record });
    };
}

export function getSignal(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string,
    signalId: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/signals/template/" + templateId + "/signals/" + signalId, {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {          
            let record = response.data.data.signals == null || (response.data.data.signals.length || 0) === 0 ? {} : response.data.data.signals[0];
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(record);
                }
                dispatch({ type: "SIGNAL_MAINT_SET_RECORD", record: record });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function getJ1939s(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    searchText?: string,
    j1939Id?: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/j1939s", {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText,
                    j1939Id: j1939Id,
                    page: 1,
                    pageSize: appConfigs.tables.j1939PageSize
                }
            }
        )
        .then(response => {      
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.j1939s);
                }
                dispatch({ type: "SIGNAL_GET_J1939S_SUCCESS", payload: {data: response.data.data.j1939s} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function getCommandPgns(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    searchText?: string,
    commandPgn?: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/command-pgns", {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText,
                    commandPgn: commandPgn,
                    page: 1,
                    pageSize: appConfigs.tables.commandPgnPageSize
                }
            }
        )
        .then(response => {      
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.commandPgns);
                }
                dispatch({ type: "SIGNAL_GET_COMMAND_PGN_SUCCESS", payload: {data: response.data.data.commandPgns} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function getCommandControllers(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    searchText?: string,
    commandControllerSaId?: number, 
    commandPgn?: number
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/command-controllers-lov", {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText, 
                    commandControllerSaId: commandControllerSaId
                }
            }
        )
        .then(response => {      
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.commandControllers);
                }
                 
                 if (commandPgn !== null && commandPgn === 65305) {
                    //Only gateway 37 is allowed as receiving from MD4 text to gateway for D2C text; for C2D text, it is broadcasting
                    let allowedControllers = [];
                    let defaultGatewayDevice = {
                        cmdControllerSaId: 37, 
                        cmdControllerSaName: 'Off Vehicle Gateway'};
                        allowedControllers.push(defaultGatewayDevice);        
                     dispatch({ type: "SIGNAL_GET_COMMAND_CONTROLLER_SUCCESS", payload: {data: allowedControllers} });
                } else {
                    dispatch({ type: "SIGNAL_GET_COMMAND_CONTROLLER_SUCCESS", payload: {data: response.data.data.commandControllers} });
                }
                
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function signalMaintSubmit(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: String,
    record: any
) {
    const editMode = (record.signalId || "") !== "" ? "edit" : "insert";
    return async (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL + "/ui/api/templates/" + templateId + "/signals",
            {
                ...record, 
                "signalRule": {
                    "ruleId" : record.ruleId,
                    "ruleName" : record.ruleName,
                    "criticalLowValue" : record.criticalLowValue,
                    "warningLowValue" : record.warningLowValue,
                    "warningHighValue" : record.warningHighValue,
                    "criticalHighValue" : record.criticalHighValue,
                    "infoLowValue" : record.infoLowValue,
                    "infoHighValue" : record.infoHighValue
                },
                "signalIncrementalRule":{
                "ruleId":record.incrementalRuleId,
                "ruleName":record.incrementalRuleName,
                "ruleType":record.incrementalRuleType,
                "ruleValue":record.incrementalRuleValue
                },
                "offlineRule":{
                    "ruleId":record.offlineRuleId,
                    "ruleName":record.offlineRuleName,
                    "offlineSince":record.offlineSince,
                    "offlineDuration":record.offlineDuration
                },
                "mergeRule":{
                    "mergeRuleId":record.mergeRuleId,
                    "mergeRuleName": record.mergeRuleName,
                    "leftMergeRule":record.leftMergeRule,
                    "rightMergeRule":record.rightMergeRule,
                    "mergeRuleOperator":record.mergeRuleOperator
                }
            },
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
                let record = response.data.data.signals == null || (response.data.data.signals.length || 0) === 0 ? {} : response.data.data.signals[0];
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(editMode, record);
                }
                dispatch({ type: "SIGNAL_MAINT_SUCCESS", record: record, editMode: editMode });
            }
        )
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

export function getUnits(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    
) {

    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/signals/units", {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => { 
                let result = response.data.data.units == null || (response.data.data.units.length || 0) === 0 ? {} : response.data.data.units;
                let units=result.filter((o:any)=>o.name!=='Other');
                let otherUnits=result.filter((o:any)=>o.name==='Other');
                if(otherUnits.length)
                {
                    for (let i = 0; i < otherUnits.length; i++) {
                        units.push(otherUnits[i]);
                    }
                }
                else
                {
                    units.push({ name: "Other",symbol:"",stdSymbol:"" });
                }
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(units);
                }
                dispatch({ type: "Units_GET_SUCCESS", data: units });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };

}
