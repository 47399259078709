import React, { Component } from "react";
import { Tree,  Radio, Input, Row, Col, Tooltip, message } from "antd";
import { UnorderedListOutlined, ApartmentOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import * as rootActions from "../../actions";
import * as actions from "./actions";

import { reloadPage, loadingIndicatorStart, loadingIndicatorEnd } from "../../utils/commonHelpers";

const { DirectoryTree } = Tree;
const { Search } = Input;

interface Prop {
    orgSelectorTreeExpand: Function,
    orgSelectorTreeCheck: Function,
    orgSelectorTreeSelection: Function,
    orgSelectorTreeOrgsGet: Function,
    orgSelectorTreeMaint: Function,
    orgSelectorSetView: Function,
    orgSelector: any,
    orgSelectorTree: any,
    issupportmode:boolean,
    iscreate: boolean,
    isupdate: boolean
}

interface State {
    searchValue: string;
}

class OrgSelectorTree extends Component<Prop, State> {
    
    state:State = {
        searchValue: "",
    }

    componentDidMount() {
        this.componentUpdate();
    }

    componentDidUpdate(prevProps: Prop) {
        if (this.props.orgSelector.instance !== prevProps.orgSelector.instance) {
            this.componentUpdate();
        }
    }

    componentUpdate() {
        if ((this.props.orgSelectorTree.treeData.length || 0) === 0 || this.props.orgSelectorTree.refreshOnEntry) {
            this.setState({ searchValue: "" });
            this.props.orgSelectorTreeSelection(this, "", "");
            this.props.orgSelectorTreeOrgsGet(this, null, null, this.props.orgSelectorTree.mode)
        }
    }
        
    arrayDiff = (arr1:any, arr2:any) => {
        let arr = [], 
            diff = [];
    
        for (var i = 0; i < arr1.length; i++) {
            arr[arr1[i]] = true;
        }
    
        for (i = 0; i < arr2.length; i++) {
            if (arr[arr2[i]]) {
                delete arr[arr2[i]];
            } else {
                arr[arr2[i]] = true;
            }
        }
    
        for (var k in arr) {
            diff.push(k);
        }
    
        return diff;
    }

    statsGetSuccess = () => {   
    };

    statsGetFailure = () => {   
    } 

    supportModeSetSuccess = () => {
        reloadPage();
    }

    onSelect = (selectedKeys:any[], info:any) => {
        this.props.orgSelectorTreeSelection(this, selectedKeys[0]);
    };

    onExpand = (expandedKeys:any) => {
        this.props.orgSelectorTreeExpand(this, expandedKeys);
    };

    onCheckChange = (checkedKeys:any, info:any) => {
        let diff:string[] = this.arrayDiff(checkedKeys.checked, this.props.orgSelectorTree.treeDataChecked),
            orgId:string = diff.length > 0 ? diff[0] : "",
            checked:boolean = checkedKeys.checked.length > this.props.orgSelectorTree.treeDataChecked.length ? true : false;

        if (orgId !== "" ) {
            this.props.orgSelectorTreeMaint(this, this.onCheckChangeSuccess, this.onCheckChangeFailure, orgId, checked, checkedKeys.checked);
        }
        else {
            message.error("Unable to record user's selection");
        }
    };
      
    onCheckChangeSuccess = (data:any, orgId:string, checkedKeys:any) => {  
        this.props.orgSelectorTreeCheck(this, checkedKeys);
    };

    onCheckChangeFailure = () => {   
    }      

    getParentKey = (orgId:string, tree:any):string => {
        let parentOrgId;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some((item: any) => item.orgId === orgId)) {
                    parentOrgId = node.orgId;
                } 
                else if (this.getParentKey(orgId, node.children)) {
                    parentOrgId = this.getParentKey(orgId, node.children);
                }
            }
        }
        return parentOrgId;
    };

    onSearchChange = (e: any) => {

        let value = e.target.value;
        const loadingIndicator = (this.props.orgSelectorTree.treeDataFlat.length || 0 ) > 50;
        const me = this;

        this.setState({ searchValue: value });

        if (loadingIndicator) {
            loadingIndicatorStart();
        }

        let expandedKeys = 
            this.props.orgSelectorTree.treeDataFlat
                .map((record:any) => {
                    if (record.name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                        return this.getParentKey(record.orgId, this.props.orgSelectorTree.treeData);
                    }
                    return null;
                })
                .filter((item:any, i:number, self:any) => 
                    item && self.indexOf(item) === i
                );
        
        setTimeout(function() {
            me.props.orgSelectorTreeExpand(me, expandedKeys);

            if (loadingIndicator) {
                loadingIndicatorEnd();
            }
        }, 100);  
    }

    viewChange = (e:any) => {
        this.props.orgSelectorSetView(this, e.target.value);
    };

    getExpandedKeys = (treeDataFlat: any, treeDataChecked: any) => {        
        const selection = treeDataChecked.reduce((acc: any, elem: any) => {
            const orgFound = treeDataFlat.find((t: any) => t.orgId === elem);
            if(orgFound) {
                const pathId = orgFound.pathId;
                let pathArray = pathId.split('~');
                if(pathArray.length > 1) {
                    const pathArrayNew = pathArray.pop();
                }
                if(pathArray.length > 1) {
                    acc = [...acc, ...pathArray];
                    return acc;
                }
                return acc;                
            }
            return acc;
        },[]);
        
        return selection.filter((elem: any, index: any) => selection.indexOf(elem) === index);
    }

    render() {
        let defaultSelection=[];
        const { treeData, treeDataChecked , treeDataExpanded, treeDataFlat, isExpanded } = this.props.orgSelectorTree;
        //after removing Autoexpandparent property from tree, the selected nodes were not expaning, so need to
        //provide the expanded keys manually.
        if(!isExpanded) {
            defaultSelection = this.getExpandedKeys(treeDataFlat, treeDataChecked);
        } else {
            defaultSelection = treeDataExpanded;
        }
        
        const getTreeData = (data: any) =>
            data.map((item: any) => {
                const index = item.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase());
                const beforeStr = index > - 1 ? item.name.substr(0, index) : "";
                const afterStr = index > - 1 ? item.name.substr(index + this.state.searchValue.length) : "";
                const string = index > - 1 ? item.name.substr(index, this.state.searchValue.length) : "";
                const title =
                    index > -1 ? (
                        <span>
                            {beforeStr}
                            <span className="tree-search-value">{string}</span>
                            {afterStr}
                        </span>
                    ) : 
                    (
                        <span>{item.name}</span>
                    );

                if (item.children) {
                    return { title, key: item.orgId, children: getTreeData(item.children) };
                }

                return {
                    title,
                    key: item.orgId,
                };
            });

        return (
        
            <div data-id="org-selector-tree">         
                <Row gutter={8}>
                    <Col xs={24} md={20}>
                        <Search
                            data-id="org-selector-tree-search-field"
                            key="tree-search-field"
                            placeholder="Search by typing org name"
                            size="middle"
                            allowClear
                            onChange={this.onSearchChange}
                            className="search-field"
                            value={this.state.searchValue}
                        /> 
                    </Col>
                    <Col xs={24} md={4}>
                        <Radio.Group key="tree-view-switch" onChange={this.viewChange} value={this.props.orgSelector.currentView}>
                            <Radio.Button 
                                data-id="org-selector-tree-list-view-button"
                                value="list-view"
                            >
                                <Tooltip title="List View"><UnorderedListOutlined /></Tooltip>
                            </Radio.Button>
                            <Radio.Button 
                                data-id="org-selector-tree-tree-view-button"
                                value="tree-view"
                            >
                                <Tooltip title="Tree View"><ApartmentOutlined /></Tooltip>
                            </Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
                
                    <DirectoryTree
                        data-id="org-selector-tree-data"
                        className="mt-25"
                        checkable
                        showIcon={false}
                        onExpand={this.onExpand}
                        onCheck={this.onCheckChange}
                        onSelect={this.onSelect}
                        checkStrictly={true}                        
                        checkedKeys={treeDataChecked}
                        expandedKeys={defaultSelection}                    
                        treeData={getTreeData(treeData)}     
                               
                />
            
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        orgSelector: state.orgSelectorReducers.orgSelectorState.orgSelector,
        orgSelectorTree: state.orgSelectorReducers.orgSelectorState.orgSelector.tree
    };
};

export default connect(
    mapStateToProps,
    {
        ...actions,
        ...rootActions
    }
)(OrgSelectorTree);
