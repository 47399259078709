import React, { useEffect, useState } from "react";
import { Drawer, Tabs, Button, Space,Input,Radio,message } from "antd";

import { connect } from "react-redux";
import * as actions from "./actions";

import { appConfigs } from "../../utils/configurations";

import OrgMaintInfo from "./orgMaintInfo/OrgMaintInfo";
import OrgMaintAdmins from "./orgMaintAdmins/OrgMaintAdmins";
import OrgMaintGlobalSubscription from "./orgMaintGlobalSubscription/OrgMaintGlobalSubscription";
import OrgMaintSettings from "../OrgMaintSettings/OrgMaintSettings";
import OrgContractsDetails from "./orgContractsDetails/OrgContractsDetails";
import OrgMaintNotes from "./orgMaintNotes/OrgMaintNotes";
const { TextArea } = Input;

const { TabPane } = Tabs;

interface Prop {
    orgsListRefreshOnUpdate: Function,
    orgsGet: Function,
    orgMaintClose: Function,    
    orgMaintTabChange: Function,
    orgMaintTabsDisabled: Function,
    orgLevelTags:Function,
    orgGlobalNotesMaint:Function,
    getOrgGlobalNotesList:Function,
    context: any,
    orgsList: any,
    iscreate: boolean,
    isread: boolean,
    isupdate: boolean,
    userOrgAccess: any,
}

  


class OrgMaint extends React.Component<Prop, {}> {  
    
    componentDidUpdate(prevProps: Prop) {
        if (this.props.orgsList.orgMaint.instance !== prevProps.orgsList.orgMaint.instance) {
            this.props.orgMaintTabsDisabled(this, (this.props.orgsList.orgMaint.record.orgId || "") === "");
        }
    }    
    state = {
        id:'',noteBody: '', noteType:'Internal',orgId:''
      };
    
    onDrawerClose = () => {
        if (this.props.orgsList.refreshOnUpdate) {
            const orgsList = this.props.orgsList;
            this.props.orgsGet(this, null, null, orgsList.mode, orgsList.scope, orgsList.tableConfig.pagination.current, orgsList.searchText, orgsList.orgId); 
        }
        this.props.orgMaintClose(this);
    };
    onTabChange = (key:string) => {
        this.props.orgMaintTabChange(this, key);
    }
    onCreateSuccess = async (record: any,mode:any) => {
        message.success("Global Note Created Successfully");
    }
    onFailure = (error: any,mode:any) => { 
        message.error(error);
    }
    
    submitAddNote = async () =>{
        if(this.state.noteBody===''){
            message.error("Please Enter Global Note Description");
        }else{
        let noteObj={...this.state}
        noteObj.orgId=this.props.orgsList.orgMaint.record.orgId;
        await this.props.orgGlobalNotesMaint(null,this.onCreateSuccess,this.onFailure,"create",JSON.stringify(noteObj));
        this.setState({
            id:null,
            noteBody: ''
        });
        }
    }
    handleNoteChange = (e:any) => {
        this.setState({
          id:null,
          noteBody: e.target.value,
          orgId: this.props.orgsList.orgMaint.record.orgId
        });
    }
    handleNoteTypeChange = (e:any) => {
        this.setState({
            noteType: e.target.value
        });
    }
    

    render() {
        //console.log("this.props.userOrgAccess",this.props.userOrgAccess);
        let isUserAdminToOrg = this.props.userOrgAccess ? true : false;
        const { orgMaint } = this.props.orgsList;

        return (
            <Drawer
                data-id="org-maint-drawer" 
                title={orgMaint.title}
                width={848}
                onClose={this.onDrawerClose}
                visible={orgMaint.visible}
                destroyOnClose={true}
                footer={
                    <Space size="large" style={{paddingBottom:5,float:"right"}}>
                        {
                            (orgMaint.activeTab === "6"  ) &&
                            <span>
                                <h3 style={{marginLeft:15,}}>Add Global Note here:</h3>
                            <TextArea required onChange={this.handleNoteChange} value={this.state.noteBody} rows={3} style={{width:500,marginLeft:15}} />
                            </span>
                        }
                        {
                            (orgMaint.activeTab === "6"  ) &&
                            <span>
                            <Radio.Group defaultValue={this.state.noteType} style={{marginBottom:10,marginLeft:20}} onChange={this.handleNoteTypeChange} >
                            <Radio value={'Internal'}>Internal</Radio>
                            <Radio value={'External'}>External</Radio>
                            </Radio.Group>
                            <Button style={{width:"100%"}}  data-id="orgs-maint-settings-submit-button" type="primary" onClick={this.submitAddNote} key="submit" htmlType="submit">
                            {"Add New Note"}
                            </Button>
                            </span>
                        }
                        {
                            (orgMaint.activeTab === "1"  ) &&
                            <Button data-id="orgs-maint-submit-button" style={{marginTop:30}} type="primary" form="orgMaintInfoForm" key="submit" htmlType="submit">
                                {appConfigs.settings.form.submitButtonText}
                            </Button>
                        }
                        {
                            (orgMaint.activeTab === "4"  ) &&
                            <Button data-id="orgs-maint-settings-submit-button" style={{marginTop:30}} type="primary" form="orgMaintSettingsForm" key="submit" htmlType="submit">
                                {appConfigs.settings.form.submitButtonText}
                            </Button>
                        }
                        {
                            (orgMaint.activeTab === "5"  ) &&
                            <Button data-id="orgs-maint-settings-submit-button" style={{marginTop:30}} type="primary" form="transferServicePlanForm" key="submit" htmlType="submit">
                                {appConfigs.settings.form.submitButtonText}
                            </Button>
                        }
                        <Button data-id="orgs-maint-close-button" type="default" onClick={this.onDrawerClose} style={{ float: 'right',marginTop:30}}>
                            {appConfigs.settings.drawers.closeButtonText}
                        </Button>
                    </Space>
                }
            >
                <Tabs data-id="orgs-maint-tabs" activeKey={orgMaint.activeTab} size="large" type="card" onChange={this.onTabChange}>
                    <TabPane data-id="orgs-maint-info-button" tab={orgMaint.tabs[0].title} key="1">                    
                        <OrgMaintInfo />
                    </TabPane>  
                    <TabPane data-id="orgs-maint-admins-button" tab={orgMaint.tabs[2].title} key="2" disabled={orgMaint.tabs[2].disabled}>
                        <OrgMaintAdmins iscreate= {this.props.iscreate} isread= {this.props.isread} isupdate= {this.props.isupdate} />
                    </TabPane>
                    {
                        // Should disable only for Parker Org
                        (this.props.orgsList.orgMaint.record.orgId !== 'c6adcb40-be92-11e6-9ed6-a5bc9cb5279b') &&
                        <TabPane data-id="orgs-maint-subscription-button" tab={orgMaint.tabs[3].title} key="3" disabled={orgMaint.tabs[3].disabled}>
                            <OrgMaintGlobalSubscription iscreate={this.props.iscreate} isread={this.props.isread} isupdate={this.props.isupdate} />
                        </TabPane>
                    }
                    <TabPane data-id="orgs-maint-settings-button" tab={orgMaint.tabs[1].title} key="4">                    
                        <OrgMaintSettings />
                    </TabPane>
                    {
                        this.props.context.appContext.mode !== 'support' && ((this.props.orgsList.orgMaint.record.orgId === this.props.context.appContext.orgId && this.props.context.user.contextOrgHasTransferredContracts) || this.props.context.user.contextUserOrgIsBillableCustomer) &&
                            <TabPane data-id="orgs-maint-contracts-button" tab={orgMaint.tabs[4].title} key="5">                    
                                <OrgContractsDetails currentOrgDetails={orgMaint.record} />
                            </TabPane>
                    }
                    {
                    isUserAdminToOrg && (orgMaint.title!=='New Organization') &&
                    <TabPane data-id="orgs-maint-Notes-button" tab="Global Notes" key="6">                    
                        <OrgMaintNotes currentOrgDetails={orgMaint.record} />
                    </TabPane> 
                    }
                    
                
                </Tabs>
                           
            </Drawer>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        orgsList: state.orgsReducers.orgsState.orgsList,
        userOrgAccess: state.userOrgAccessReducer.data.userOrgAccess
    };
};

export default connect(
    mapStateToProps,
    actions
)(OrgMaint);