import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function reportScheduledState(state = initialState, action: any) {
  let data: any = null,
    payload: any;
  switch (action.type) {
    case "REPORT_SCHEDULES_LIST_GET_SUCCESS":
      data = action.payload.data;
      return immerProduce(state, (draft) => {
        draft.scheduledList.refreshOnUpdate = false;
        draft.scheduledList.tableData = data;
        draft.scheduledList.tableConfig.pagination.total =
          (data.length || 0) > 0 ? data[0].totalRecords : 0;
        draft.scheduledList.tableConfig.pagination.current =
          action.payload.page;
      });
    case "REPORT_SCHEDULES_MAINT_OPEN":
      return immerProduce(state, (draft) => {
        const payload = action.payload,
          instance = Math.random();
        draft.scheduledList.scheduledMaint.instance = instance;
        draft.scheduledList.scheduledMaint.width = payload.width;
        draft.scheduledList.scheduledMaint.visible = true;
        draft.scheduledList.scheduledMaint.mode = payload.action;
        draft.scheduledList.scheduledMaint.title = payload.title;
        draft.scheduledList.scheduledMaint.record = payload.record;
      });
    case "REPORT_SCHEDULES_MAINT_CLOSE":
      return immerProduce(state, (draft) => {
        draft.scheduledList.scheduledMaint.visible = false;
      });
    case "REPORT_SCHEDULES_ORGS_SUCCESS":
      data = action.payload.data;
      return immerProduce(state, (draft) => {
        draft.scheduledList.scheduledMaint.orgs = data;
      });
    case "REPORT_SCHEDULES_SET_PAGINATION":
      return immerProduce(state, (draft) => {
        draft.scheduledList.tableConfig.pagination.onChange =
          action.onPaginationChange;
      });
    case "REPORT_SCHEDULES_SET_SEARCH":
      return immerProduce(state, (draft) => {
        draft.scheduledList.searchText = action.searchText;
      });
    case "REPORT_SCHEDULES_REPORTS_GET_SUCCESS":
      data = action.payload.data;
      return immerProduce(state, (draft) => {
        draft.scheduledList.scheduledMaint.reports = data;
        draft.scheduledList.scheduledMaint.selectedOrgId = action.payload.orgId;
      });
    case "REPORT_SCHEDULES_USERS_SUCCESS":
      data = action.payload.data;
      return immerProduce(state, (draft) => {
        draft.scheduledList.scheduledMaint.users = data;
      });
    case "REPORT_SCHEDULES_MAINT_SUCCESS":
      data = action.payload.data;
      return immerProduce(state, (draft) => {
        draft.scheduledList.scheduledMaint.record = data;
        draft.scheduledList.scheduledMaint.title = "Edit Schedule";
      });
    case "ORG_TAGS_SUCCESS":
      data = action.payload.data;
      // console.log(data);
      return immerProduce(state, (draft) => {
        draft.scheduledList.scheduledMaint.selectedTag = data;
      });
    case "SCHEDULE_ORG_TAGS_SUCCESS":
      data = action.payload.data;
      // console.log(data);
      return immerProduce(state, (draft) => {
        draft.scheduledList.scheduledMaint.selectedOrgTag = data;
      });
    default:
      return state;
  }
}
