import { SignalDatapoint, Template, SignalId, DBWidgetConfig, Dashboard, AlertSignalDatapointData, LineWidgetConfig, TextMessage, C2DWlist } from "./models";
import { WidgetConfig } from "./widgets";
import { Layout } from "react-grid-layout";
import { AssetDashboardResponse } from "./api";
import { WidgetFilterConfig } from "./WidgetFilterForm";
import { appConfigs } from "../../utils/configurations";

export type DataStore = Map<SignalId, SignalDatapoint[] | AlertSignalDatapointData[]>;

export interface State {
  loaded: true;
  dashboards: DashboardState[];
  //widgets: WidgetState[];
  template: Template;
  layout: Layout[];
  columns: number;
  asset: {
    orgPathId: string;
    name: string;
    id: string;
    mastertag: string;
    gatewayId: string;
    datapoints: any;
    templateId: string;
    templateName: string;
    alertDm1Count: number;
    alertInfoCount: number;
    alertWarningCount: number;
    alertCriticalCount: number;
    mac: string;
    orgId: string;
    orgName: string;
    operatorId: string;
    operatorIdValue: string;
    accessCardValue: string;
    operatorsHistoryId: string;
    connectionStatus: string;
    contractEndDate?: string;
    remoteControl: RemoteControl[];
    orgCmdPgnSpns: string;
    c2dMessages: TextMessage[];
    c2dWhitelist: C2DWlist[];
    resetTimestamp: string;
    resetlocationLatitude: number;
    resetlocationLongitude: number;
    blockedassetOrgId: string;
    assetDownDesc:string;
  };
  activeDashboard: string;
  temperature: {
    value: string;
    checked: boolean;
  };
  timezone: {
    value: string;
    //checked: boolean;
    isGpsSignalAvailable: boolean;
    gpsSignalColId: string[];
    coordinates: {
      latitude: any;
      longitude: any;
    }
  };
}
interface UpdateLayout {
  type: "update-layout";
  dashboardId: number;
  columns: number;
}

interface SaveLayout {
  type: "save-layout";
  layout: Layout[];
}

interface SaveLayoutSuccess {
  type: "save-layout-success";
  dashboardId: number;
  layout: Layout[];
}

interface DeleteWidget {
  type: "delete-widget";
  dashboardId: number;
  id: WidgetState["id"];
}

interface AddWidget {
  type: "add-widget";
  dashboardId: number;
  id: string;
  widget: DBWidgetConfig;
}

interface UpdateWidget {
  type: "update-widget";
  dashboardId: number;
  id: WidgetState["id"];
  config: DBWidgetConfig;
}

interface UpdateWidgetFilter {
  type: "update-filter";
  dashboardId: number;
  id: WidgetState["id"];
  config: WidgetFilterConfig;
}

interface AssetPreferences {
  type: "asset-preferences";
  filterPreferences: any;
}

interface GPSCoordinates {
  type: "gps-coordinates";
  coordinates: any;
}

interface ClearWidgetFilter {
  type: "clear-filter";
  dashboardId: number;
  id: WidgetState["id"];
}

export interface RemoteControl {
  commandId: string,
  gatewayId: string,
  responseInfo: any,
}

interface CommandStatus {
  type: "command-status";
  commandId: string;
  gatewayId: string;
  responseInfo: any;
}

export interface DbLayoutConfig {
  sizeX?: number;
  sizeY?: number;
  row?: number;
  col?: number;
}

export interface LoadWidgetsOnTabClick {
  type: 'load-widgets-on-tab-click',
  dashboardId: number,
  columns: number,
  widgets: DBWidgetConfig[]
}

export interface DashboardState {
  dashboardId: number,
  title: string,
  bgimageurl:string,
  isdefaultDashboard: boolean,
  isNewDashboard: boolean,
  widgets: WidgetState[]
}

export interface AddDashboard {
  type: 'add-dashboard'
  dashboardId: number,
  title: string,
  bgimageurl: string,
  isdefaultDashboard: boolean
}

export interface DeleteDashboard {
  type: 'delete-dashboard'
  dashboardId: number
}

export interface ActivateDashboard {
  type: 'activate-dashboard'
  dashboardId: number
}

export interface UpdateDashboardTitle {
  type: 'update-dashboard-title',
  dashboardId: number,
  title: string
}


export type DbLayout = DbLayoutConfig & { _id: string };

export type WidgetState = WidgetConfig & {
  id: string;
} & DbLayoutConfig &
  WidgetFilterConfig & LineWidgetConfig;

export type Action =
  | UpdateLayout
  | SaveLayout
  | DeleteWidget
  | AddWidget
  | UpdateWidget
  | SaveLayoutSuccess
  | UpdateWidgetFilter
  | ClearWidgetFilter
  | LoadWidgetsOnTabClick
  | AddDashboard
  | DeleteDashboard
  | ActivateDashboard
  | AssetPreferences
  | GPSCoordinates
  | UpdateDashboardTitle
  | CommandStatus;


export function bindActions(dispatch: (action: Action) => void) {
  return {
    updateLayout: (columns: number, dashboardId: number) =>
      dispatch({ type: "update-layout", dashboardId, columns }),
    assetPreferences: (filterPreferences: any) =>
      dispatch({ type: "asset-preferences", filterPreferences }),
    gpsCoordinates: (coordinates: any) =>
      dispatch({ type: "gps-coordinates", coordinates }),
    saveLayout: (layout: Layout[]) => dispatch({ type: "save-layout", layout }),
    saveLayoutSuccess: (dashboardId: number, layout: Layout[]) =>
      dispatch({ type: "save-layout-success", dashboardId, layout }),
    deleteWidget: (dashboardId: number, id: string) => dispatch({ type: "delete-widget", dashboardId, id }),
    addWidget: (dashboardId: number, id: string, widget: DBWidgetConfig) =>
      dispatch({ type: "add-widget", dashboardId, id, widget }),
    updateWidget: (dashboardId: number, id: WidgetState["id"], config: DBWidgetConfig) =>
      dispatch({ type: "update-widget", dashboardId, id, config }),
    updateWidgetFilter: (dashboardId: number, id: WidgetState["id"], config: WidgetFilterConfig) =>
      dispatch({ type: "update-filter", dashboardId, id, config }),
    clearWidgetFilter: (dashboardId: number, id: WidgetState["id"]) =>
      dispatch({ type: "clear-filter", dashboardId, id }),
    loadWidgetsOnTabClick: (dashboardId: number, columns: number, widgets: DBWidgetConfig[],) =>
      dispatch({ type: "load-widgets-on-tab-click", dashboardId, columns, widgets }),
    addDashboard: (dashboardId: number, title: string, bgimageurl:string, isdefaultDashboard: boolean) => dispatch({ type: "add-dashboard", dashboardId, title, bgimageurl, isdefaultDashboard }),
    deleteDashboard: (dashboardId: number) => dispatch({ type: "delete-dashboard", dashboardId }),
    activateDashboard: (dashboardId: number) => dispatch({ type: 'activate-dashboard', dashboardId }),
    updateDashboardTitle: (dashboardId: number, title: string) => dispatch({ type: "update-dashboard-title", dashboardId, title }),
    commandStatus: (gatewayId: string, commandId: string, responseInfo: any) => dispatch({ type: "command-status", gatewayId, commandId, responseInfo }),
  };
}
///If column lenegth is 4, then widgets has to be displayed
// how ever it is coming from  DB
export function computeLayout(
  widgets: WidgetState[],
  columns: number
): Layout[] {
  //console.log("computeLayout state.ts:",columns)
  if(columns < 4){
    return widgets.map((w, i) => ({
      i: w.id,
      x: i % 1,
      y: Math.floor(i / 1),
      h: 1,
      w: columns,
    }));
  } else {
    const layout = widgets.map((w) => {
      return {
        i: w.id,
        x: w.col === undefined ? 1 : w.col,
        y: w.row === undefined ? 1 : w.row,
        w: w.sizeX || 1,
        h: w.sizeY || 1,
      };
    });

    return layout;
  }
}

export function initState(
  data: AssetDashboardResponse,
  columns: number,
  userAssetPreferences?: any,
  coordinates?: any

  //context: any,
  //dashboardId: number
): State {
  let widgets: DBWidgetConfig[] = [];
  let widgs: WidgetState[] = [];
  let layout: Layout[] = [];
  const { asset } = data;
  const { template, dashboards } = asset;
  if ((!asset.remoteControl) || (asset.remoteControl && asset.remoteControl.length === 0)) {
    asset.remoteControl = [];
  }

  if (!asset.orgCmdPgnSpns) {
    asset.orgCmdPgnSpns = '-';
  }


  // const dashboard = asset.tabs.length > 0 && asset.tabs.filter((tab: Tab) => tab.dashboardId === dashboardId);
  // if(dashboard && dashboard.length > 0) {
  //   if(dashboard[0] && dashboard[0].widgets && dashboard[0].widgets.length > 0) {
  //     widgets = dashboard[0].widgets;
  //   }
  // }

  // We are using a unique widget id so that React can tell the difference
  // between widgets even as we create/delete them. This prevents a re-render
  // of existing widgets and greatly improves performance. The counter is reset anytime
  // the component is unmounted. It is highly unlikely that this counter will ever
  // wrap-around as we would need to create 18014398509481982 widgets in one instance.
  let widgetIdCounter = Number.MIN_SAFE_INTEGER;
  const initWidget = (w: DBWidgetConfig): WidgetState => {
    return {
      ...w,
      type: w.type as "boolean",
      id: w._id,
      signals: w.signals && w.signals.map((s, i) => { return getSignalObject(s, w.type, i) }) || [],
      ...(w.rightYAxisSignals && w.rightYAxisSignals.length > 0 ? {
        rightYAxisSignals:
          w.rightYAxisSignals.map((s, i) => { return getSignalObject(s, w.type, i) })
      } : {})
    };
  };

  const initDashboard = (d: Dashboard): DashboardState => {
    widgs = d.widgets && d.widgets.map(initWidget) || [];
    layout = computeLayout(widgs, columns);
    
    return {
      ...d,
      dashboardId: d.dashboardId,
      bgimageurl: d.bgimageurl,
      isdefaultDashboard: d.isdefaultDashboard !== null ? d.isdefaultDashboard : false,
      widgets: widgs
    }
  }
  let isGpsSignalAvailable = false;
  let gpsCollectionId: string[] = [];
  let timezoneCheckedValue = null;
  let preferences = false;
  let temperatureCheckedValue = null;
  JSON.parse(data.asset.datapoints)?.filter((ele: any) => {
    if (ele.type?.toLowerCase() === "gps") {
      isGpsSignalAvailable = true;
      gpsCollectionId.push(ele._id);
    }
  });
  if (gpsCollectionId.length <= 0) {
    gpsCollectionId = [];
  }
  var latlong: any = [];
  if (coordinates != undefined) {
    latlong.latitude = coordinates.latitude;
    latlong.longitude = coordinates.longitude;
  }
  if (userAssetPreferences != null && userAssetPreferences != undefined) {
    preferences = userAssetPreferences.assets != undefined ? (userAssetPreferences.assets.temperature == "°C" ? true : false) : false;
    temperatureCheckedValue = userAssetPreferences.assets != undefined ? (userAssetPreferences.assets.temperature ? userAssetPreferences.assets.temperature : null) : null;
    timezoneCheckedValue = userAssetPreferences.assets != undefined ? (userAssetPreferences.assets.timezone ? userAssetPreferences.assets.timezone : null) : null;
  }
  let temperature = {
    checked: preferences,
    value: temperatureCheckedValue
  }
  let timezone = {
    value: timezoneCheckedValue,
    isGpsSignalAvailable: isGpsSignalAvailable,
    gpsSignalColId: gpsCollectionId,
    coordinates: latlong
  }


  return {
    loaded: true,
    asset: { ...asset },
    template: new Template(template.signalCollections, template.rules),
    columns,
    dashboards: dashboards && dashboards.length > 0 && dashboards.map(initDashboard) || [],
    layout: layout,
    activeDashboard: dashboards && dashboards.length > 0 && dashboards[0].dashboardId.toString() || "0",
    temperature: temperature,
    timezone: timezone
  };

}

function getWidgets(dashboardId: number, dashboards: DashboardState[]): WidgetState[] {
  let widgets: WidgetState[] = [];

  const dashboard = dashboards.find((d) => d.dashboardId === dashboardId);
  if (dashboard && dashboard.widgets.length > 0) {
    widgets = dashboard.widgets;
  }
  return widgets;
}

function UpdateAssetPreferences(state: State, action: any): State {
  state.temperature.checked = action.filterPreferences.temperature == "°C" ? true : false;
  state.temperature.value = action.filterPreferences.temperature;
  state.timezone.value = action.filterPreferences.timezone;
  return state;
}

function UpdateGPSCoordinates(state: State, action: any): State {
  state.timezone.coordinates.latitude = action.coordinates.latitude;
  state.timezone.coordinates.longitude = action.coordinates.longitude;
  return state;
}

function updateLayout(state: State, action: UpdateLayout): State {
  const dashboardArray = state.dashboards.find((dashboard) => dashboard.dashboardId === action.dashboardId)
  let DashboardObj;
  if(dashboardArray){
    DashboardObj =JSON.parse(JSON.stringify(dashboardArray));
  }else{
    DashboardObj=null;
  }
  const widgets = getWidgets(action.dashboardId, state.dashboards);
  let widgetcolumns:number;
  const parentOrgPath = state?.asset?.orgPathId?.includes("~") ? state?.asset?.orgPathId.split('~') : [state?.asset?.orgPathId];
  let parentOrg:any;
  if (parentOrgPath.length > 1) {
    parentOrg=parentOrgPath[1]
  }else{
    parentOrg=parentOrgPath[0]
  }
  if(parentOrg==='7ff34c90-36b2-11e8-8e2e-6f1fbe0d469d' && DashboardObj?.isNewDashboard){
    widgetcolumns=8;
  }else{
    widgetcolumns=4;
  }
  const layout = computeLayout(widgets, widgetcolumns);

  return {
    ...state,
    columns: action.columns,
    layout: layout
  };
}

function deleteWidget(state: State, action: DeleteWidget): State {
  const widgets = getWidgets(action.dashboardId, state.dashboards);
  const newWidgets = widgets.filter((w) => w.id !== action.id);

  return {
    ...state,
    dashboards: state.dashboards.map((d: DashboardState) => {
      if (d.dashboardId === action.dashboardId) {
        return { ...d, widgets: newWidgets }
      } else {
        return d;
      }
    }),
    //widgets: widgets,
    layout: computeLayout(newWidgets, state.columns),
  };
}

function addWidget(state: State, action: AddWidget): State {
  //console.log("add widget Action",action);
  const newWidget: WidgetState = {
    ...action.widget,
    signals: action.widget.signals.map((s, i) => { return getSignalObject(s, action.widget.type, i) }),
    type: action.widget.type as "boolean",
    id: action.id,
    ...(action.widget.rightYAxisSignals && action.widget.rightYAxisSignals.length > 0 ? {
      rightYAxisSignals:
        action.widget.rightYAxisSignals.map((s, i) => { return getSignalObject(s, action.widget.type, i) })
    } : {})
  };

  const newDashboards = state.dashboards.map((d: DashboardState) => {
    if (d.dashboardId === action.dashboardId) {
      return { ...d, widgets: d.widgets.concat(newWidget) }
    } else {
      return d;
    }
  });
  
  const widgets = getWidgets(action.dashboardId, newDashboards);
  return {
    ...state,
    dashboards: newDashboards,
    layout: computeLayout(widgets, state.columns),
  };
}

function updateWidget(state: State, action: UpdateWidget): State {
  const newWidget: WidgetState = {
    ...action.config,
    id: action.id,
    signals: action.config.signals.map((s, i) => { return getSignalObject(s, action.config.type, i) }),
    type: action.config.type as "boolean",
    ...(action.config.rightYAxisSignals && action.config.rightYAxisSignals.length > 0 ? {
      rightYAxisSignals:
        action.config.rightYAxisSignals.map((s, i) => { return getSignalObject(s, action.config.type, i) })
    } : {})
  };


  const newDashboards = state.dashboards.map((d: DashboardState) => {
    if (d.dashboardId === action.dashboardId) {
      return { ...d, widgets: d.widgets.map((w) => (w.id === action.id ? newWidget : w)) }
    } else {
      return d;
    }
  });

  return {
    ...state,
    dashboards: newDashboards
  };
}

function updateWidgetLayout(
  dashboards: DashboardState[],
  layout: Layout[],
  dashboardId: number
): DashboardState[] {
  // console.log("updateWidgetLayout");
  const newDashboards = dashboards.map((d: DashboardState) => {
    if (d.dashboardId === dashboardId) {
      return {
        ...d, widgets: d.widgets.map((wg) => {
          const { w, h, x, y } = layout.filter((l) => l.i === wg.id)[0];
          return {
            ...wg,
            sizeX: w,
            sizeY: h,
            col: x,
            row: y
          }
        })
      }
    } else {
      return d;
    }
  });
  return newDashboards;
}

function updateWidgetFilter(state: State, action: UpdateWidgetFilter): State {

  const { startDate, endDate, datapoint, startTime, endTime } = action.config;

  const newDashboards = state.dashboards.map((d: DashboardState) => {
    if (d.dashboardId === action.dashboardId) {
      return {
        ...d, widgets: d.widgets.map((w) => (w.id === action.id ?
          {
            ...w,
            startDate,
            startTime,
            endTime,
            endDate,
            datapoint
          } : w))
      }
    } else {
      return d;
    }
  });


  return {
    ...state,
    dashboards: newDashboards
  };
}

function clearWidgetFilter(state: State, action: ClearWidgetFilter): State {
  const newDashboards = state.dashboards.map((d: DashboardState) => {
    if (d.dashboardId === action.dashboardId) {
      return {
        ...d, widgets: d.widgets.map((w) => (w.id === action.id ?
          {
            ...w,
            startDate: undefined,
            endDate: undefined,
            datapoint: 50,
          } : w))
      }
    } else {
      return d;
    }
  });

  return {
    ...state,
    dashboards: newDashboards
  };
}

function getSignalObject(s: any, wtype: string, index: number) {
  if (wtype === "clusterbar" || wtype === "line" || wtype === "text" || wtype === "bar" || wtype === "tracker") {
    if (s.color === "#000000") {
      return { id: s.signalId, color: appConfigs.colorCodes.colorCodesLineChart[index] };
    } else {
      return { id: s.signalId, color: s.color };
    }
  } else if (wtype === "boolean" || wtype === "gauge" || wtype === "map events") {
    if (s.color) {
      if (s.color.includes('$')) {
        return { id: s.signalId, color: s.color };
      } else {
        return { id: s.signalId, color: "#008000$#FF0000" };
      }
    } else {
      return { id: s.signalId, color: "#008000$#FF0000" };
    }
  } else {
    return s.signalId;
  }
}


function loadWidgetsOnTabClick(state: State, action: LoadWidgetsOnTabClick): State {
  const initWidget = (w: DBWidgetConfig): WidgetState => {
    return {
      ...w,
      type: w.type as "boolean",
      id: w._id,
      signals: w.signals && w.signals.map((s, i) => { return getSignalObject(s, w.type, i) }) || [],
      ...(w.rightYAxisSignals && w.rightYAxisSignals.length > 0 ? {
        rightYAxisSignals:
          w.rightYAxisSignals.map((s, i) => { return getSignalObject(s, w.type, i) })
      } : {})
    };
  };
  const widgets = action.widgets && action.widgets.length > 0 && action.widgets.map(initWidget) || [];
  return {
    ...state,
    dashboards: state.dashboards.map((d: DashboardState) => {
      if (d.dashboardId === action.dashboardId) {
        return { ...d, widgets: widgets }
      } else {
        return d
      }
    }),
    layout: computeLayout(widgets, action.columns),
    activeDashboard: action.dashboardId.toString()
  }
}

function addDashboard(state: State, action: AddDashboard): State {
  return {
    ...state,
    dashboards: [...state.dashboards, { dashboardId: action.dashboardId, title: action.title, bgimageurl:action.bgimageurl, widgets: [], isdefaultDashboard: action.isdefaultDashboard, isNewDashboard: true }],
    activeDashboard: action.dashboardId.toString()
  }
}

function deleteDashboard(state: State, action: DeleteDashboard): State {
  let activeDashboardId: number = -1;
  let newLayout: Layout[] = [];
  //const deletedDashboardIndex: number = state.dashboards.findIndex(d=>d.dashboardId === action.dashboardId);
  //const activeDashboardIndex = deletedDashboardIndex > -1 ? (deletedDashboardIndex -1) : 0;
  // if(+activeDashboardIndex > 0) {
  //   activeDashboardId = state.dashboards[+activeDashboardIndex].dashboardId;
  // }  

  const remainingDashboards = state.dashboards.filter(d => d.dashboardId !== action.dashboardId);
  if (remainingDashboards.length > 0) {
    activeDashboardId = remainingDashboards[0].dashboardId;
    newLayout = computeLayout(remainingDashboards[0].widgets, state.columns);
  }

  return {
    ...state,
    dashboards: remainingDashboards,
    activeDashboard: activeDashboardId.toString(),
    layout: newLayout
  }
}

function activateDashboard(state: State, action: ActivateDashboard): State {
  return {
    ...state,
    activeDashboard: action.dashboardId.toString()
  }
}


function updateDashboardTitle(state: State, action: UpdateDashboardTitle): State {
  return {
    ...state,
    dashboards: state.dashboards.map((d: DashboardState) => {
      if (d.dashboardId === action.dashboardId) {
        return { ...d, title: action.title }
      } else {
        return d;
      }
    })
  }
}

function commandStatus(state: State, action: CommandStatus): State {
  if (state.asset.remoteControl && state.asset.remoteControl.length > 0) {
    let dataset: Boolean = false;
    state.asset.remoteControl.map((remoteData: any) => {
      if (remoteData.gatewayId === action.gatewayId && remoteData.commandId === action.commandId && !dataset) {
        remoteData.responseInfo = action.responseInfo
        dataset = true
      }
    });
    if (!dataset) {
      state.asset.remoteControl.push({
        gatewayId: action.gatewayId,
        commandId: action.commandId, responseInfo: action.responseInfo
      });
    }
  }
  else {
    state.asset.remoteControl.push({
      gatewayId: action.gatewayId,
      commandId: action.commandId, responseInfo: action.responseInfo
    });
  }
  return state;
}

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "update-layout":
      return updateLayout(state, action);

    case "save-layout":
      return {
        ...state,
        layout: action.layout,
      };
    case "save-layout-success":
      return {
        ...state,
        dashboards: updateWidgetLayout(state.dashboards, action.layout, action.dashboardId)
        //widgets: updateWidgetLayout(state.widgets, action.layout),
      };
    case "delete-widget":
      return deleteWidget(state, action);
    case "add-widget":
      return addWidget(state, action);
    case "update-widget":
      return updateWidget(state, action);
    case "update-filter":
      return updateWidgetFilter(state, action);
    case "clear-filter":
      return clearWidgetFilter(state, action);
    case "load-widgets-on-tab-click":
      return loadWidgetsOnTabClick(state, action);
    case "add-dashboard":
      return addDashboard(state, action);
    case "delete-dashboard":
      return deleteDashboard(state, action);
    case "activate-dashboard":
      return activateDashboard(state, action);
    case "update-dashboard-title":
      return updateDashboardTitle(state, action);
    case "command-status":
      return commandStatus(state, action);
    case "asset-preferences":
      return UpdateAssetPreferences(state, action);
    case "gps-coordinates":
      return UpdateGPSCoordinates(state, action);
    default:
      return state;
  }
}
