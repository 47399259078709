import React, { Component } from "react";
import { Table, Checkbox, Breadcrumb, Button, Radio, Input, Row, Col, Tooltip } from "antd";
import { SwapOutlined, UnorderedListOutlined, ApartmentOutlined, RightOutlined } from "@ant-design/icons";

import { connect } from "react-redux";

import * as rootActions from "../../actions";
import * as orgSelectorActions from "./actions";

import { reloadPage, loadingIndicatorStart } from "../../utils/commonHelpers";
import { appConfigs } from "../../utils/configurations";

const { Search } = Input;

interface Prop {
    supportModeSet: Function,
    orgSelectorListSetPagination: Function,
    orgSelectorListSetOrgTree: Function,
    orgSelectorListSetDisplay: Function,
    orgSelectorListOrgsGet: Function,
    orgSelectorListMaint: Function,
    orgSelectorListSetSearch: Function,
    orgSelectorSetView: Function,
    supportMode: any,
    orgSelector: any,
    orgSelectorList: any,
    issupportmode: boolean,
    iscreate: boolean,
    isupdate: boolean
}
class OrgSelectorList extends Component<Prop, {}> {

    componentDidMount () {
        this.props.orgSelectorListSetPagination(this.onPaginationChange);
        this.componentUpdate();        
    }

    componentDidUpdate(prevProps: Prop) {
        if (this.props.orgSelector.instance !== prevProps.orgSelector.instance) {
            this.componentUpdate();
        }
    }

    componentUpdate() {
        if ((this.props.orgSelectorList.tableData.length || 0) === 0 || this.props.orgSelectorList.refreshOnEntry) {
            this.props.orgSelectorListSetSearch(this, "");
            this.props.orgSelectorListOrgsGet(this, null, null, this.props.orgSelectorList.mode, 1);
        }
    }

    onPaginationChange = (page: number) => {
        let orgSelectorList = this.props.orgSelectorList;
        this.props.orgSelectorListOrgsGet(this, null, null, orgSelectorList.mode, page, orgSelectorList.searchText, orgSelectorList.orgId);
    };

    drillIn = (e:any, mode:string, orgId:string, orgName:string) => {
        e.preventDefault();
        let orgSelectorList = this.props.orgSelectorList;
        this.props.orgSelectorListSetDisplay(mode, orgSelectorList.searchText, mode === "all" ? "" : orgId, mode === "all" ? "" : orgName);

        if (mode === "all") {
            this.props.orgSelectorListSetOrgTree(this, []);
        }
        this.props.orgSelectorListOrgsGet(this, this.drillInSuccess, null, mode, 1, orgSelectorList.searchText, orgId);
    };
    
    drillInSuccess = (data:any, mode:string) => {
        if (mode !== "all" && data.length > 0) {
            this.props.orgSelectorListSetOrgTree(this, data[0].orgTree);
        }
    }

    onCheckChange = (e:any, record:any) => {
        this.props.orgSelectorListMaint(this, this.onCheckChangeSuccess, this.onCheckChangeFailure, record.orgId, e.target.checked);
    }

    statsGetSuccess = () => {   
    };

    statsGetFailure = () => {   
    } 

    onCheckChangeSuccess = () => {   
    };

    onCheckChangeFailure = () => {   
    }        
    
    supportModeSet = (e: any, orgId:string, orgName: string) => {
        this.props.supportModeSet(this, this.supportModeSetSuccess, orgId, orgName);
    }

    supportModeSetSuccess = () => {
        loadingIndicatorStart();
        setTimeout(function () {
            window.location.href = "/map"; 
        }, 500);

        //     setTimeout(function () {
        //         reloadPage();
        //     }, 250);
        // }
    }

    onSearch = (searchText: string) => {
        this.props.orgSelectorListSetOrgTree(this, []);
        this.props.orgSelectorListSetSearch(this, searchText || "");
        this.props.orgSelectorListOrgsGet(this, null, null, "all", 1, (searchText || ""),  ""); 
    }

    onSearchChange = (e: any) => {
        this.props.orgSelectorListSetSearch(this, e.target.value || "");
    }
    
    viewChange = (e:any) => {
        this.props.orgSelectorSetView(this, e.target.value);
    };

    render() {    

        const { tableConfig, tableData, orgTree, searchText } = this.props.orgSelectorList;

        const tableColumns = [
            {
                dataIndex: "name",
                key: "name",
                width: "100%",
                render: (name: number, record: any) => (
                    <>
                        <Checkbox 
                            checked={record.orgSelection} 
                            onChange={(e) => this.onCheckChange(e, record)}
                            className="pr-5"                            
                        >
                            {
                                record.logoDisplay &&
                                <img className="pl-10 org-selector-logo" src={appConfigs.server.URL + record.logoDisplay} alt=""/>
                            }
                        </Checkbox>
                        {
                            record.subOrgCount > 0 &&
                            <a href="# " onClick={(e) => {this.drillIn(e, "direct-children", record.orgId, record.name)}}>{name}</a>
                        }
                        {
                            record.subOrgCount === 0 &&
                            <span>{name}</span>
                        }
                    </>
                )
            },            
            {
                key: "action",
                width: 150,
                align: "center" as "center",
                render: (text:any, record:any) => (    
                    <>
                        {
                            record.orgId !== this.props.supportMode.orgId && this.props.issupportmode &&
                            <Tooltip title="Support">
                                <Button icon={<RightOutlined />} onClick={(e) => {this.supportModeSet(e, record.orgId, record.name)}} />
                            </Tooltip>
                        }
                    </>
                ),
              }
        ];

        return (
            <div data-id="org-selector-list">                        
                <Row gutter={8}>
                    <Col xs={24} md={20}>
                        <Search
                            data-id="org-selector-list-search-field"
                            key="list-search-field"
                            placeholder="Search by org name"
                            enterButton="Search"
                            size="middle"
                            allowClear
                            onSearch={this.onSearch}
                            className="search-field"
                            onChange={this.onSearchChange}
                            value={searchText}
                        /> 
                    </Col>
                    <Col xs={24} md={4}>
                        <Radio.Group key="list-view-switch" onChange={this.viewChange} value={this.props.orgSelector.currentView}>
                            <Radio.Button 
                                data-id="org-selector-list-list-view-button"
                                value="list-view"
                            >
                                <Tooltip title="List View"><UnorderedListOutlined /></Tooltip>
                            </Radio.Button>
                            <Radio.Button 
                                data-id="org-selector-list-tree-view-button"
                                value="tree-view"
                            >
                                <Tooltip title="Tree View"><ApartmentOutlined /></Tooltip>
                            </Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>

                <Breadcrumb separator="/">
                    <Breadcrumb.Item key="breadcrumb-all-orgs" href="# " onClick={(e) => {this.drillIn(e, "all", "", "")}}>All Orgs</Breadcrumb.Item>
                    {orgTree && 
                        orgTree
                            .filter((record:any, index:number) => (
                                index < orgTree.length - 1
                            ))
                            .map((record:any, index:number) => (
                                <Breadcrumb.Item key={"breadcrumb-" + index} href="# " onClick={(e) => {this.drillIn(e, "direct-children", record.orgId, record.name)}}>
                                    {record.name}
                                </Breadcrumb.Item>
                            ))
                    }
                </Breadcrumb>

                <hr className="hr" />

                <Table 
                    data-id="org-selector-list-data"
                    {...tableConfig}
                    rowKey={(record) => record.orgId}    
                    columns={tableColumns}
                    dataSource={tableData}
                />
            </div>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        supportMode: state.supportModeReducer,
        orgSelector: state.orgSelectorReducers.orgSelectorState.orgSelector,
        orgSelectorList: state.orgSelectorReducers.orgSelectorState.orgSelector.list
    };
};

export default connect(
    mapStateToProps,
    { 
        ...rootActions,
        ...orgSelectorActions 
    }
)(OrgSelectorList);
