declare global {
    interface Window {
        config?: {
            API_ENDPOINT?: string
            UI_DOMAIN?: string,
            OKTA_ISSUER?: string,
            OKTA_CLIENT_ID?: string,
            REPORT_ENDPOINT?: string ,
            EMAIL_LOGIC_APP_URL?: string  ,
            CDN_URL?: string,
            CLAYTON_URL?:string,
            ENABLE_DASHBOARD2?: boolean,
            ENABLE_GEOFENCING?: boolean,
            ENABLE_SCHEDULE?: boolean,
            ENABLE_CUMMINS_DIAGNOSTICS?: boolean,
            ENABLE_GATEWAY_SETTINGS?: boolean,
            ENABLE_CMNS_UI?: boolean,
            DEFAULT_IP?: string,       
            SIGNALR_URL?: string,             
            SIGNALR_TOKEN?: string,
            COMMAND_SIGNAL_FAKE_TESTING?: boolean,
            HOIST_URL?:string,
            TOYOTA_URL?:string          
        }
    }
}

export const appConfigs = {

    app: {
        title: "IQAN Connect v2.2.0",
        versionMajor: "2",
        versionMinor: "0",
        login: {
            en: {
                //
                //https://github.com/okta/okta-signin-widget/blob/master/packages/@okta/i18n/src/properties/login.properties
                //
                copyrightText: "© Parker Hannifin. All rights reserved.",
                termsAndConditionsLinkText: "Terms and Conditions",
                termsAndConditions: {
                    title: "Welcome To IQAN Connect v2.2.0"
                },
                "primaryauth.title": "Welcome",
                "primaryauth.username.placeholder": "Email Address",
                "error.username.required": "Please enter your Email Address",
                "primaryauth.password.placeholder": "Password",
                "error.password.required": "Please enter your password",
                "errors.E0000004": "Sign in failed",
                "password.forgot.email.or.username.placeholder": "Email Address",
                "password.forgot.email.or.username.tooltip": "Email Address",
                "password.forgot.sendEmail": "Reset",
                "forgotpassword": "Forgot your password?",
                "password.reset.title": "Reset Password",
                "password.forgot.sendText": "Reset",
                "goback": "Back to Sign In",
                "password.forgot.emailSent.title": " FORGOT PASSWORD ",
                "password.forgot.emailSent.parker.title": "Error",
                "password.forgot.emailSent.desc": " <br /> If you are a registered user, we have sent you an email with instructions to reset your password.",
                "password.forgot.emailSent.parker.desc": "You cannot reset your Parker password here. Please follow the procedure to change your Active Directory login password or contact your Active Directory administrator.",
                "password.oldPassword.placeholder": "Temporary password",
                "password.oldPassword.tooltip": "Temporary password",
                "password.expired.title.generic": "Your password has expired"
            }
        },
        alerts: {
            exportMaxPages: 100
        },
        fleetOverview: {
            mapDataPoolingRate: 60 * 1000, //ms
            mapGeoTabDataPoolingRate: 10 * 60 * 1000, //ms
            assetSearchKeystrokeDelay: 250, //ms
            assetSearchGlobalKeystrokeDelay: 500 //ms
        },
        assetOverview: {
            widgetscreensizefortoggle: "4",
            minsignalsformapwidgets: "2",
            connectdisconnectdatapointid: "2aedf7c0-7072-11e6-9616-d1436e22fac1",            
            deviceconnectedtext: "Microsoft.Devices.DeviceConnected",
            datapointlimits: [50, 100, 500, 1000, 2000, 3000],
            alertsInterval: '24 hours',
            maxdatapointlimit: 30000,
            maxapilimit: 3000,
            startdateenddatedaysdiff: 7,
            exportstartdateenddatedaysdiff: 30,
            autoRefreshPollingRate: 60 * 1000,
            PermissionDrawerTitle: 'Dashboard Permissions',
            cosmosdataretentiondays: 90
        },
        termsAndConditions: {
            title: "Welcome To Parker Connect",
            subTitle: "Please review our Terms and Conditions"
        },
        assets: {
            offlineHavingNoDateText: "Not Available",
            onlineText: "NA"
        },
        pinnedSignals: {
            maxSignalsSelection: 7
        },
        operatorSignals: {
            maxSignalsSelection: 1
        },
        assetTags: {
            maxAssetTags: 5,
            maxAllowCharactersPerTags: 50,
        },
        orgTags: {
            maxOrgTags: 5,
            maxAllowCharactersPerTags: 50,
        }, 
        enableDashboard2: window.config?.ENABLE_DASHBOARD2 || true,
        enableGeoFencing: window.config?.ENABLE_GEOFENCING || true,
        enableSchedule: window.config?.ENABLE_SCHEDULE || false,
        enableCumminsDiagnostics: window.config?.ENABLE_CUMMINS_DIAGNOSTICS || false,
        enableGatewaySettings: window.config?.ENABLE_GATEWAY_SETTINGS || true,
        enableCumminsUI: window.config?.ENABLE_CMNS_UI || false,
        enableCommandSignalFakeTesting: window.config?.COMMAND_SIGNAL_FAKE_TESTING || false,
        defaultIP: window.config?.DEFAULT_IP || "10.42.0.111"
    },
    
    server: {
        URL: window.config?.API_ENDPOINT || "http://localhost:8080",
        deploymentServerURL: window.config?.UI_DOMAIN || "http://localhost:3000",
        reportServerURL: window.config?.REPORT_ENDPOINT || "http://localhost:8782/",
        claytonServerURL:window.config?.CLAYTON_URL || "http://localhost:3000/",  
        hoistServerURL:window.config?.HOIST_URL ||  "http://localhost:3000/",
        toyotaServerURL:window.config?.TOYOTA_URL ||  "http://localhost:3000/"
    },
    okta: {
        oidc: {
            issuer: window.config?.OKTA_ISSUER || "https://logintest.parker.com/oauth2/austxx35r3mrCxtRI0h7",
            clientId: window.config?.OKTA_CLIENT_ID || "0oaryxvnuqyY47FUd0h7",
            scopes: ["openid", "profile", "email"],
            pkce: true,
            disableHttpsCheck: false
        },
        parkerUserRegstrationUrl: "https://phdev.parker.com/us/en/Login?myAcctMain=1"        
    },
    errors: {
        api: {
            defaultErrorMessage: "An error has been encountered while processing your request - Please try later",
        },
        fieldErrors: {
            valueRequired: "Value is required",
            atLeastTwoCharacters: "Enter at least two characters",
            invalidEmail: "Email format is invalid"
        }
    },
    signalR: {
        code: window.config?.SIGNALR_TOKEN,
        url: window.config?.SIGNALR_URL
    },
    settings: {
        form: {
            alerts: {
                successTitle: "Success",
                successDefaultMessage: "Your changes are successfully procesed",
                errorTitle: "Please resolve all errors before continuing",
                warningTitle: "Warning"
            },
            submitButtonText: "Submit"
        },
        settingStyleForm:{
            saveButtonText: "Save",
            cancleButtonText: "Cancel"
        },
        formReset: {
            message: "Do you really want to reset form data?",
            okText: "Yes",
            cancelText: "No"
        },
        formLayout: {
            mainLayout: {
                labelCol: { span: 5 },
                wrapperCol: { span: 19 }
            },
            tailLayout: {
                wrapperCol: {offset: 5, span: 19}
            }
        },
        drawers: {
            defaultWidth: 768,
            xlWidth: 992,
            closeButtonText: "Close",
            cancelButtonText: "Cancel"
        }
    },
    messages: {
        usersPhoneTooltip: "Please contact our Customer Service, at +1 (555) 555-5555, if the country you're looking for is not listed",
        headerColorTooltip: "To open Color Picker, click/tap Pick button. Click/Tap outside of Color Picker to select a color. Color value is a hex(hexadecimal) value.",
        companyLogoTooltip: "File type must be .jpg or .png. Maximum logo dimensions are 140x68 pixels. Tip: Use a logo equal to, or less than, 140x68px to prevent scaling. Maximum file size is 100kB.",
        companyLoginUrlTooltip: "Customize displayed company name within login URL. Avoid space and special characters. Valid examples are 'my-company' and 'my-company/acme'",
        companyLoginLogoTooltip: "File type must be .jpg or .png. Maximum logo dimensions are 640x480 pixels. Maximum file size is 100kB. Tip: Use a logo equal to, or less than, 640x480px to prevent scaling. This logo will be displayed in login page only.",
        companyLoginBackgroundTooltip: "Splash image is login page background image. File type must be .jpg or .png. Recommended login splash image is 1920 x 1080 pixels. Maximum file size is 500kB.",
        dm1FaultsUploadTooltip: "File type must be CSV (Comma Separated Values) or text. Maximum file size is 500kB. The first row must contain column headers: DM1Enable,SA, SPN and FMI. Only unique records will be processed.",
        iqanTelematicSignalFileTooltip: "File type must be JSON file. Maximum file size is 500kB. File data will be merged with existing template data.",
        
    },
    tables: {
        pageSize: 12,
        j1939PageSize: 128,
        commandPgnPageSize: 32,
        defaultCurrent: 1,
        defaultTotal: 0,
        showSizeChanger: false,
        showQuickJumper: true,
        hideOnSinglePage: true,
        responsive: true,
        showLessItems: true
    },
    emailNotifyLogicApps:{
        logic_app_url : window.config?.EMAIL_LOGIC_APP_URL,
         email_subject : "Reset your Password ",
         parker_AD_Password : "Please use parker active directory password"
    },
    colorCodes:{
        colorCodesLineChart:[
            "#66CC00",
            "#006699",
            "#000000",
            "#cc3300",
            "#993300",
            "#9966cc",
            "#FF5733",
            "#A6351C",
            "#6A1301",
            "#CAADA8"
        ]

    },
    accessTypes:["RFID", "Asset-Mapping"]  
}    
