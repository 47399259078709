import React from "react";
import { Form, Card } from "antd";


import {
  EditFormProps,
  WidgetTitleFormField,
} from "../../WidgetEditForm";
import { Config } from ".";
import LocationSignalSelectorList from "./LocationSignalSelectorList";

function sanitizeInputs(c: Config): Config {
  return {
    title: c.title,
    signals: c.signals.filter((s) => !!s),
    type: "map",
  };
}

const EditForm: React.FC<EditFormProps> = (props) => {
  return (
    <div className="text-widget-edit-form">
      <Form
        onFinish={(values) => props.onFinish(sanitizeInputs(values as Config))}
        layout="vertical"
        form={props.form}
        initialValues={props.config}
      >
        <Card title="Location Widget Settings">
          <WidgetTitleFormField />
          <LocationSignalSelectorList max={2} signals={props.template.signals} />          
        </Card>
      </Form>
    </div>
  );
};

export default EditForm;
