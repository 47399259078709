import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Form, Button, Empty, message } from "antd";
import * as actions from "../actions";
import SignalTree from "./signalTree";
// import { useForm } from "antd/lib/form/util";
import history from "../../../utils/history";
import {
  createTreeStructure,
  createJsonForSignalMaint,
} from "./formatOperatorSignals";
import { appConfigs } from '../../../utils/configurations';
import { isArray } from "@amcharts/amcharts4/core";



interface Props {
  context: any;
  activeTab: any;
  getTemplate: Function;
  operatorSignals: any;
  getOperatorSignals: Function;
  saveOperatorSignals: Function;
  iscreate: boolean;
  isupdate: boolean;
  isread: boolean;
  // isdelete: boolean;
  isGlobalTemplate: boolean;
  isadmin: boolean;
}

const OperatorSignals: React.FC<Props> = (props) => {
  //state variables
  const [form] = Form.useForm();
  const [maxSelected, setMaxSelected] = useState<number>(0);
  const {    
    getOperatorSignals,
    saveOperatorSignals,
    operatorSignals,
  } = props;
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [treeSignals, setTreeSignals] = useState([]);
  const [datapoints, setDatapoints] = useState<any>(); 


  const loadPreferedSignals = () => {    
    getOperatorSignals(null, null, getTemplateId(), "operatorsignals");
  };

  const loadPreferedSignalsCallback = useCallback(loadPreferedSignals, []);

  const { activeTab, getTemplate } = props;
  const { isadmin, iscreate, isupdate, isread, isGlobalTemplate } = props;

  const getTemplateId = () => {
    const pathnameArr = history.location.pathname.split("/");
    return pathnameArr[pathnameArr.length - 1];
  }  
  
  //Load the updated signal collections.
  useEffect(() => {
    if(activeTab === "4") {
      getTemplate(null, getTemplateSuccess, null, getTemplateId());
    }    
  }, [activeTab]);

  const formatTreeData = (datapoints: any) => {
    let submitVisible = false;
    let signalCollection = JSON.parse(datapoints || []);
    signalCollection = signalCollection.filter((s: any) => s.name !== "GPS" && s.type !== "Command");
    const treeData = signalCollection.map(createTreeStructure);      
    return treeData;
  };


  const getTemplateSuccess = (response: any) => {   
    if(response.datapoints) {
      setDatapoints(response.datapoints);
      const treeData = formatTreeData(response.datapoints);
      setTreeSignals(treeData);
      loadPreferedSignalsCallback();
    }
  }

  const setTreeOperatorSignals = (preferedSignals: any): string[] => {
    const treeSignals = operatorSignals.preferedSignals.reduce((acc: any, curr: any) => {
      acc = acc.concat(curr.signalId);
      return acc;
    }, []);
    return treeSignals;
  }

  const setExpanededKey = (preferedSignals: any) => {
    let uniqueExpandKeys;
      const expanedKeys = operatorSignals.preferedSignals.reduce(
        (acc: any, curr: any) => {
          acc = acc.concat(curr.signalCollectionId);
          return acc;
        },
        []
      );
      if (expanedKeys) {
        uniqueExpandKeys = expanedKeys.filter((elem: any, pos: any) => {
          return expanedKeys.indexOf(elem) === pos;
        });
      }
      return uniqueExpandKeys;
  }



  //Once the API response received, update the tree with new data and selection 
  useEffect(() => {
    let signals: string[] = [];
    if (operatorSignals.preferedSignals) {
      signals = setTreeOperatorSignals(operatorSignals.preferedSignals);
      //Assign the expanded keys
      let uniqueExpandKeys = setExpanededKey(operatorSignals.preferedSignals);     
      setExpandedKeys(uniqueExpandKeys);
    }
    form.setFieldsValue({ signals: signals });
  }, [operatorSignals.preferedSignals]);


  const preferedSignalsMaint = (preferedSignalJson: any) => {
    saveOperatorSignals(
      preferedSignalsMaintSuccess,
      null,
      getTemplateId(),
      "operatorsignals",
      preferedSignalJson      
    );
  };

  const preferedSignalsMaintSuccess = (response: any) => {
    message.success('Signal selection saved successfully');
  }

  const onExpand = (expandedKeys: any) => {    
    setExpandedKeys(expandedKeys);
  };

  const saveSignalMaint = useCallback(preferedSignalsMaint, []);

  //save the signals into DB.
  const onFinish = (values: any) => {
   
    const preferedSignalJson = createJsonForSignalMaint(
      JSON.parse(datapoints),
      values
    );

    saveSignalMaint(preferedSignalJson);
  };

  const checkValidations = (rule: any, value: any) => {
     const newValue = value.checked.filter((v: any) =>
      JSON.parse(datapoints).every((d: any) => d._id !== v)
    );
    setMaxSelected(newValue.length);    
    if (!newValue || newValue.length > appConfigs.app.operatorSignals.maxSignalsSelection) {
      return Promise.reject(`Maximum ${appConfigs.app.operatorSignals.maxSignalsSelection} signal must be selected`);
    }
    return Promise.resolve();
  };

  const fomInitialValues = (): string[] => {
    let signals: string[] = [];
    if (operatorSignals.preferedSignals) {
      signals = operatorSignals.preferedSignals.reduce((acc: any, curr: any) => {
        acc = acc.concat(curr.signalId);
        return acc;
      }, []);
    }
    return signals;
  };

    if (treeSignals && treeSignals?.length > 0)  {
    return (
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}        
        initialValues={{ signals: fomInitialValues() }}
      >
        <Form.Item
          label=""
          name="signals"
          rules={[{ validator: checkValidations }]}
        >
          <SignalTree
            treeData={treeSignals}
            expandedKeys={expandedKeys}
            onExpand={onExpand}
            iscreate={iscreate} isupdate={isupdate} isread={isread} isGlobalTemplate={isGlobalTemplate} isadmin={isadmin}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" 
          disabled={isGlobalTemplate && !isadmin || 
            !isGlobalTemplate && (!iscreate && !isupdate) ||
           maxSelected > appConfigs.app.operatorSignals.maxSignalsSelection}  htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  } else {
    return <Empty />;
  }
};

const mapStateToProps = (state: any) => {
  return {
    context: state.contextReducer.context,
    operatorSignals:
      state.templateDetailsReducers.templateDetailsState.tabs[3].operatorSignals   
  };
};


export default connect(mapStateToProps, actions)(OperatorSignals);
