import React from "react";
import { Drawer, Button, message, Space } from "antd";
import { SwapOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import * as rootActions from "../../actions";
import * as geofenceActions from "../../pages/GeoFences/actions";
import * as orgSelectorActions from "./actions";
import history from '../../utils/history';

import { reloadPage, refreshPage, getPermissions } from "../../utils/commonHelpers";

import OrgSelectorHeader from "./OrgSelectorHeader";
import OrgSelectorList from "./OrgSelectorList";
import OrgSelectorTree from "./OrgSelectorTree";

import "./orgSelector.css";
//import { getPermissions } from "../../actions/permissions";

interface Prop {
    orgSelectorClose: Function,    
    supportModeSet: Function,
    orgSelectorUserOrgSelectionGet: Function,
    setFenceListLoaded: Function,
    fleetStatsInitialization: Function,
    fleetStatsVisibility: Function,
    fleetStatsLoading: Function,
    getQuickStats: Function,
    context: any,
    orgSelector: any
    orgSelectorTree: any,
    permissions: any,
    supportMode: any
}

class OrgSelector extends React.Component<Prop, {}> {  
      
    onDrawerClose = () => {        
        this.props.orgSelectorClose(this);        
        
        if (this.props.orgSelector.updated) {
            this.props.setFenceListLoaded(false);
            this.props.fleetStatsInitialization(false);
            this.props.fleetStatsVisibility(false);
            this.props.fleetStatsLoading(true);
            this.props.getQuickStats(this, null, null);
            refreshPage(history);            
        }        
    };    
    
    findOrgNameById = (data:any, orgId:string) => {
        for (var i = 0; i < data.length; i++) {
            if (data[i].orgId === orgId) {
                return data[i].name;
            } 
            else if (data[i].children) {
                this.findOrgNameById(data[i].children, orgId);
            }
        }
    }

    supportModeSet = (e:any) => {
        let me = this,
            orgName = this.findOrgNameById(this.props.orgSelectorTree.treeData, this.props.orgSelectorTree.selectedOrgId);

        setTimeout(function() {
            if (me.props.orgSelectorTree.selectedOrgId) {
                me.props.supportModeSet(me, me.supportModeSetSuccess, me.props.orgSelectorTree.selectedOrgId, orgName);
            }
            else {
                message.error("Unable to locate user's selection");
            }
        }, 100);      
    }

    supportModeSetSuccess = () => {
        setTimeout(function () {
            window.location.href = "/map"; 
        }, 500); 
        
        //reloadPage();
    }

    render() {       
        const { orgSelector } = this.props;
        const result = getPermissions(this.props.permissions.permissions.org_options);
        const supportmode = getPermissions(this.props.permissions.permissions.support_mode);
        const suborgs = getPermissions(this.props.permissions.permissions.sub_orgs);
        let iscreate = result[0];
        let isread = result[1];
        let isupdate =  result[2];
        let isdelete = result[3];

        let issupportmode = supportmode[1];
        let issuborgsread = suborgs[1];

        return (
            <Drawer
                data-id="org-selector-drawer" 
                title={orgSelector.title}
                width={orgSelector.width}
                visible={orgSelector.visible}
                onClose={this.onDrawerClose}
                destroyOnClose={true}
                footer={
                    <Space size="large" style={{ float: 'right' }}>
                        <Button     
                            data-id="org-selector-close-button"                        
                            onClick={this.onDrawerClose} 
                            type="default"
                        >
                            Close
                        </Button>
                        {
                            this.props.orgSelector.currentView === "tree-view" &&
                            this.props.orgSelector.tree.selectedOrgId && issupportmode && 
                            <Button                                 
                                icon={<SwapOutlined />} 
                                onClick={(e) => {this.supportModeSet(e)}}
                                type="primary"
                            >
                                Support
                            </Button>
                        }
                    </Space>
                }
            >
                {
                    (issuborgsread) &&
                    <OrgSelectorHeader /> 
                }   
                {
                    orgSelector.currentView === "list-view" && isread &&
                    <div className={orgSelector.currentView === "list-view" ? "visible" : "not-visible"}>
                        <OrgSelectorList issupportmode={issupportmode} isupdate={isupdate} iscreate={iscreate}/>
                    </div> 
                }
                {
                    orgSelector.currentView === "tree-view" && isread &&
                    <div className={orgSelector.currentView === "tree-view" ? "visible" : "not-visible"}>
                        <OrgSelectorTree issupportmode={issupportmode} isupdate={isupdate} iscreate={iscreate}/>
                    </div> 
                }
            </Drawer>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        orgSelector: state.orgSelectorReducers.orgSelectorState.orgSelector,        
        orgSelectorTree: state.orgSelectorReducers.orgSelectorState.orgSelector.tree,
        permissions: state.contextReducer.data,
        supportMode: state.supportModeReducer
    };
};

export default connect(
    mapStateToProps,
    { 
        ...rootActions,
        ...orgSelectorActions,
        ...geofenceActions
    }
)(OrgSelector);