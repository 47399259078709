import moment from "moment";
import "moment-timezone";
import React, { useEffect, useState } from "react";
import { DataStore } from "../state";
import { findTimezone } from "../widgets/common";

interface CsvHeader {
	label: string;
	key: string;
}

export const useExportGPSEvents = (csvLink: any) => {
	const [dataForDownload, setDataForDownload] = useState<any>([]);
	const [bDownloadReady, setDownloadReady] = useState(false);
	const [exportHeader, setExportHeader] = useState<CsvHeader[]>([]);
	const [exportFileName, setExportFileName] = useState<string>();

	useEffect(() => {
		if (csvLink && csvLink.current && bDownloadReady) {
			setTimeout(() => {
				csvLink.current.link.click();
			}, 1);
			setDownloadReady(false);
		}
	}, [bDownloadReady]);

	return [
		dataForDownload,
		setDataForDownload,
		bDownloadReady,
		setDownloadReady,
		exportHeader,
		setExportHeader,
		exportFileName,
		setExportFileName,
	];
};

export const groupBy = (items: any[], key: string | number) => {
	let groupsObj = items.reduce(
		(result: { [x: string]: any }, item: { [x: string]: string | number }) => ({
			...result,
			[item[key]]: [...(result[item[key]] || []), item],
		}),
		{}
	);
	return [groupsObj];
};

export const sortObjByKey = (arr: any[]) => {
	let sortedArray: any = [];
	let sortObj = Object.keys(arr[0])
		.sort((a, b) => {
			let dateA: any = new Date(a);
			let dateB: any = new Date(b);
			return dateA - dateB;
		})
		.reduce(function (result: any, key: any) {
			result[key] = arr[0][key];
			return result;
		}, {});

	Object.keys(sortObj).map(function (key) {
		sortedArray.push({ [key]: sortObj[key] });
		return sortedArray;
	});

	return sortedArray;
};

export const prepareWidgetGPSEvents = (data: DataStore, state: any) => {
	let details=findTimezone(state.timezone)
	let headerArray: CsvHeader[] = [
		{ label: ("Time Stamp"+" (").concat(details?.timezone)+(")"),key: "timestamp"},
		//{ label: ("Time Stamp"+" (").concat(moment().tz(moment.tz.guess()).format('z'))+(")"), key: "timestamp" },
		{ label: "Organization Name", key: "orgName" },
		{ label: "Master Tag", key: "mastertag" },
		{ label: "Asset Name", key: "assetName" },
		{ label: "Latitude", key: "latitude" },
		{ label: "Longitude", key: "longitude" },
	];

	let notPresentKeys: any[] = [];
	const tempUnits = ["°C", "°F"];
    let checkStr: any;
    data.forEach((d: any, k: any) => {
        let signalunit: any;
        d.every((val: any) => {
            checkStr = tempUnits.some(temp => val.ruleName.includes(temp));
            return false;
        })
        if (!checkStr) {
            state.template.signalCollections.filter((sc: any) => {
                let signal = sc.signals.find((sg: any) => sg.id === k);
                if (signal && (signal.units == "°C" || signal.units == "°F")) {
                    signalunit = signal.units;
                    d.filter((data: any) => {
                        // Convert to °C
                        if (signal.units == "°F" && state.temperature.value == "°C" && state.temperature.value != null) {
                            data.value = (data.value - 32) * 5 / 9;
							data.value=Math.round(data.value*100)/100;
                        }
                        // Convert to °F
                        if (signal.units == "°C" && (state.temperature.value == "°F" || state.temperature.value == null)) {
                            data.value = (data.value * 9 / 5) + 32;
							data.value=Math.round(data.value*100)/100;
                        }
                    })
                }
            });
        }
        if (headerArray.findIndex((s: any) => s.key.toLowerCase() == d[0].ruleName.toLowerCase()) === -1) {
            if (!checkStr && signalunit) {
                d.filter((rule: any) => {
                    rule.ruleName += `(${state.temperature.value})`;
                })
            }
            notPresentKeys.push(d[0].ruleName);
        }
    });
	// data.forEach((d: any, k: any) => {
	// 	if (
	// 		headerArray.findIndex(
	// 			(s: any) => s.key.toLowerCase() == d[0].ruleName.toLowerCase()
	// 		) === -1
	// 	) {
	// 		notPresentKeys.push(d[0].ruleName);
	// 	}
	// });
	const headerKeys = notPresentKeys.reduce((cum1: any, elem1: any) => {
		return [...cum1, { label: elem1, key: elem1.toLowerCase() }];
	}, []);
	headerArray = [...headerArray, ...headerKeys];

	let dataToDownload: any = [];
	let formattedData: any = [];
	formattedData = sortObjByKey(
		groupBy(
			Array.from(data.entries())
				.flat()
				.filter((i: any) => Array.isArray(i))
				.flat(),
			"timestamp"
		)
	);

	let tmpRow: any = [];
	formattedData.forEach((dateGroup: any) => {
		Object.keys(dateGroup).forEach((date) => {
			tmpRow = [];
			tmpRow.push([]);
			dateGroup[date].forEach((signalData: any, key: number) => {
				headerArray.forEach((header: CsvHeader) => {
					if (header.key.toLowerCase() == signalData.ruleName.toLowerCase()) {
						tmpRow[tmpRow.length - 1][signalData.ruleName.toLowerCase()] =
							signalData.value;
					}
				});
			});
			dataToDownload.push({
				timestamp: moment(date).format(),
				orgName: state.asset.orgName,
				mastertag: state.asset.mastertag,
				assetName: state.asset.name,
			});

			tmpRow.forEach((tmpRowKey: any) => {
				Object.keys(tmpRowKey).forEach((key) => {
					dataToDownload[dataToDownload.length - 1][key.toLowerCase()] =
						tmpRowKey[key];
				});
			});
		});
	});

	return {
		eventHeader: headerArray,
		eventArray: dataToDownload,
	};
};
