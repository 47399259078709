import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function userControllersState(state = initialState, action:any) {
    let data:any = null,
        payload:any;
        switch (action.type) {  
            case "CONTROLLERS_GET_SUCCESS":

                data = action.payload;

                return immerProduce(state, draft => {
                    draft.controllers.controllersList.refreshOnUpdate = false;
                    draft.controllers.controllersList.tableData = data;
                    draft.controllers.controllersList.tableConfig.pagination.total = data ? data[0].totalRecords : 0;
                    draft.controllers.controllersList.tableConfig.pagination.current = action.payload.page;
                });
            
            case "APPLICATIONS_LIST_GET_SUCCESS":

                data = action.applications;

                // data = [
                //     {
                //         "applicationId": 1,
                //         "applicationName": "BSP",
                //         "applicationType": "Firmware",
                //         "requiresApproval": "Yes"
                //     },
                //     {
                //         "applicationId": 2,
                //         "applicationName": "Runtime",
                //         "applicationType": "Runtime",
                //         "requiresApproval": "Yes"
                //     },
                //     {
                //         "applicationId": 3,
                //         "applicationName": "Application 1",
                //         "applicationType": "Application",
                //         "requiresApproval": "No"
                //     },
                //     {
                //         "applicationId": 4,
                //         "applicationName": "Application 2",
                //         "applicationType": "Application",
                //         "requiresApproval": "No"
                //     }
                // ]

                return immerProduce(state, draft => {
                    draft.controllers.applications = data;
                });
            case "APP_VERSIONS_GET_SUCCESS":
                return immerProduce(state, draft => {
                    draft.controllers.appVersions = action.versions;
                });
            case "GET_CONTROLLER_INSTALLATIONS_SUCCESS":
                return immerProduce(state, draft => {
                    draft.controllers.controllerMaintOptions.installationHistory = action.installationHistory;
                });
            case "CONTROLLER_SET_SEARCH":
                    return immerProduce(state, draft => {
                    draft.controllers.controllersList.searchText = action.searchText;
            });
            case "UPDATE_CONTROLLER_SELECTION":
                    return immerProduce(state, draft => {
                    draft.controllers.selectedController = action.selectedController;
            });
            case "CONTROLLERS_SET_PAGINATION":
                    return immerProduce(state, draft => {
                    draft.controllers.controllersList.tableConfig.pagination.onChange = action.onPaginationChange;
            });
            case "SET_TAB":
                    return immerProduce(state, draft => {
                    draft.controllers.activeTab = action.tabNumber;
            });
            case "OPEN_DRAWER":
                    return immerProduce(state, (draft) => {
                        const payload = action.payload,
                            instance = Math.random();
                        draft.controllers.controllerMaintOptions.instance = instance;
                        draft.controllers.controllerMaintOptions.width = payload.width;
                        draft.controllers.controllerMaintOptions.visible = true;
                        draft.controllers.controllerMaintOptions.title = payload.title;
                        draft.controllers.controllerMaintOptions.activeTab = "1";
                        draft.controllers.controllerMaintOptions.record = null;
                    });
            case "OPEN_INSTALL_PROGRESS_DRAWER":
                    return immerProduce(state, (draft) => {
                        const payload = action.payload,
                            instance = Math.random();
                        draft.controllers.controllerMaintOptions.instance = instance;
                        draft.controllers.controllerMaintOptions.width = payload.width;
                        draft.controllers.controllerMaintOptions.info_visible = true;
                        draft.controllers.controllerMaintOptions.title = payload.title;
                        draft.controllers.controllerMaintOptions.activeTab = "1";
                        draft.controllers.controllerMaintOptions.record = payload.record;
                    });
            case "CLOSE_DRAWER":
                    return immerProduce(state, (draft) => {
                        draft.controllers.controllerMaintOptions.visible = false;
                        draft.controllers.controllerMaintOptions.activeTab = "1";
                        draft.controllers.controllerMaintOptions.record = null;
                    });
            case "CLOSE_INSTALL_PROGRESS_DRAWER":
                    return immerProduce(state, (draft) => {
                        draft.controllers.controllerMaintOptions.info_visible = false;
                        draft.controllers.controllerMaintOptions.activeTab = "1";
                        draft.controllers.controllerMaintOptions.record = null;
                    });
            case "DRAWER_TAB_CHANGE":
                    return immerProduce(state, (draft) => {
                        draft.controllers.controllerMaintOptions.activeTab = action.activeTab;
                    });
            case "SET_CONTROLLERS_LIST":
                    return immerProduce(state, (draft) => {
                        draft.controllers.controllersModification.controllersList = action.payload.controllersList;
                        draft.controllers.controllersModification.loadingControllersList = false;
                    });
            case "SET_LOADING_CONTROLLERS":
                return immerProduce(state, (draft) => {
                    draft.controllers.controllersModification.loadingControllersList = action.payload.state;
                });
            case "ADD_CONTROLLER":
                return immerProduce(state, (draft) => {
                    draft.controllers.controllersModification.submittingControllerList = action.payload.state;
                });
            case "ADD_CONTROLLER_SUCCESSFULLY":
                return immerProduce(state, (draft) => {
                    draft.controllers.controllersModification.submittingControllerList = false;
                    draft.controllers.controllersModification.selectedControllerList = []
                });
            case "UPDATE_FIELD":
                return immerProduce(state, (draft) => {
                    if(draft.controllers.controllersModification.selectedControllerList){
                        draft.controllers.controllersModification.selectedControllerList = draft.controllers.controllersModification.selectedControllerList.map((controller: any) => {
                            if(controller.mastertag === action.payload.mastertag){
                                controller[action.payload.filedName] = action.payload.value
                            }
                            return controller
                        });
                    }
                });
            default:
                return state;
        }
}