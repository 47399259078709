import { produce as immerProduce } from "immer";
import { initialState } from "./dashboardState";

export function dashboardReducer(state = initialState, action:any) {
    switch (action.type) {      
        case "TOGGLE_MACHINE_NOTE_DRAWER":
            return immerProduce(state, draft => {
                draft.context.assetNotes.visible = action.payload.state;
            });             
        case "GET_NOTES_SUCCESS":
            return immerProduce(state, draft => {
                const pinnedNotes = action.payload.notesList.filter((note:any) => note.isPinned == 'true');
                const allNotes = action.payload.notesList;
                draft.context.assetNotes.notes.pinned = pinnedNotes;
                draft.context.assetNotes.notes.all = allNotes;
            });
        case "GET_ASSET_GLOBAL_NOTES_SUCCESS":
                return immerProduce(state, draft => {
                    draft.context.assetNotes.notes.globalNotes = action.payload.globalNotes;
                });             
        case "NEW_NOTE_ADDED":
            return immerProduce(state, draft => {
                let newNotes: any = [...draft.context.assetNotes.notes.all, action.payload]
                draft.context.assetNotes.notes.all = newNotes;
            });
        case "NOTE_PINNED_SUCCESSFULLY":
            return immerProduce(state, draft => {
                // draft.context.assetNotes.notes.all = [...draft.context.assetNotes.notes.all, action.payload];
            });  
        default:
            return state;
    }
}