import React from "react";
import { Drawer, Tooltip, Space, Row, Col } from "antd";
import { CloseOutlined, ZoomInOutlined } from "@ant-design/icons";

import ClusterInfo from "./clusterInfo/ClusterInfo";
import AssetSearch from "./assetSearch/AssetSearch";
import { MapActions } from "../../../sharedComponents/GoogleMap";

import { connect } from "react-redux";
import * as actions from "../actions";

interface Prop {
    mapsAssetDrawerClose: Function,
    fleetOverviewState: any,
    context: any,
    mapActions?: MapActions,
    map?: any
}

class AssetDrawer extends React.Component<Prop, {}> {  
   
    onClose = () => {        
        this.props.mapsAssetDrawerClose();
    };

    onZoom = () => {
        this.props.fleetOverviewState.assetDrawer.cluster.zoomTo();
        this.onClose();
    }

    render() {
        
        const { assetDrawer } = this.props.fleetOverviewState;

        return (
            <Drawer
                data-id="map-asset-drawer" 
                title={
                    <Row align="middle" data-id="map-cluster-info-title-bar">
                        <Col span={16}>
                            {assetDrawer.title}
                        </Col>
                        <Col span={8}>
                            <Space>
                                {
                                    assetDrawer.mode === "cluster" &&
                                    <Tooltip title="Zoom Into Cluster"> 
                                        <ZoomInOutlined onClick={() => this.onZoom()} />
                                    </Tooltip>
                                }
                                <Tooltip title="Close"> 
                                    <CloseOutlined onClick={() => this.onClose()} />
                                </Tooltip>
                            </Space>
                        </Col>
                    </Row>
                }
                width={assetDrawer.width}
                closable={false}
                onClose={this.onClose}
                visible={assetDrawer.show}
                destroyOnClose={true}
            >
                
                {
                    assetDrawer.mode === "cluster" &&
                    <ClusterInfo mapActions={this.props.mapActions} />
                }
                {
                    assetDrawer.mode === "asset-search" &&                    
                    <AssetSearch map={this.props.map} mapActions={this.props.mapActions} />
                }

            </Drawer>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,        
        fleetOverviewState: state.fleetOverviewReducers.fleetOverviewState
    };
};

export default connect(
    mapStateToProps,
    actions
)(AssetDrawer);