import React, { Component } from "react";
import { Table } from "antd";

import { appConfigs } from "../../../utils/configurations";
import history from '../../../utils/history';

import { connect } from "react-redux";
import * as actions from "../actions";



import "./quotasApplied.css";

interface Prop {
    getQuotasApplied: Function,
    quotasApplied: any,
    templateDetails: any,
    iscreate: boolean,
    isread: boolean,
    isupdate: boolean,
    isdelete: boolean,
    activeTab: any
}



class QuotasApplied extends Component<Prop, {}> {

    drawerWidth:number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);

    getTemplateId() {
        const pathnameArr = history.location.pathname.split("/");
        return pathnameArr[pathnameArr.length - 1];
    }

    componentDidMount () {
        this.props.getQuotasApplied(this, null, null, this.getTemplateId());       
    }

    getQuotasAppliedSuccess = (record: any) => { 
      // Do nothing
    }

    render() {    

        const { tableConfig, tableData} = this.props.quotasApplied;

        const tableColumns = [
            {
                title: "Quota Plan",
                dataIndex: "planQuotaName",
                key: "planQuotaName",
                align: "center" as "center",
                render: (planQuotaName: string, record: any) => (
                    <span>{planQuotaName}</span>
                 )
            },
            {
                title: "Part Number",
                dataIndex: "partNumber",
                key: "partNumber",
                align: "center" as "center",
                render: (partNumber: string, record: any) => (
                    <span>{partNumber}</span>
                 )
            },
            {
                title: "Min Capture Interval",
                dataIndex: "allowedMinCollectionFreq",
                key: "allowedMinCollectionFreq",
                align: "center" as "center",
                render: (allowedMinCollectionFreq: string, record: any) => (
                    <span>{allowedMinCollectionFreq}</span>
                 )
            },
            {
                title: "Max Signals",
                dataIndex: "allowedNoOfSignals",
                key: "allowedNoOfSignals",
                align: "center" as "center",
                render: (allowedNoOfSignals: number, record: any) => (
                    <span>{allowedNoOfSignals}</span>
                 )
            },
            {
                title: "Max Dm1 per day",
                dataIndex: "allowedMaxDayDm1",
                key: "allowedMaxDayDm1",
                align: "center" as "center",
                render: (allowedMaxDayDm1: number, record: any) => (
                    <span>{allowedMaxDayDm1}</span>
                 )
            },
            {
                title: "Assets",
                dataIndex: "assetsApplied",
                key: "assetsApplied",
                align: "center" as "center",
                render: (assetsApplied: number, record: any) => (
                   <span>{assetsApplied}</span>
                )
            }
    
        ];

        return (
            <>
            {
                this.props.isread &&
                <Table  
                    data-id="quotas-applied-data"
                    {...tableConfig}
                    rowKey={(record) => record.planQuotaId}
                    columns={tableColumns}
                    dataSource={tableData}
                    className="mt-10"
                />
            }
           
            </>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        quotasApplied: state.templateDetailsReducers.templateDetailsState.tabs[4],
        templateDetails: state.templateDetailsReducers.templateDetailsState
    };
};

export default connect(
    mapStateToProps,
    actions
)(QuotasApplied);
  