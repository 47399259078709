import { CommonWidgetConfig,InformationWidgetConfig } from '../../models';
import { WidgetDetails } from '../models';
import Widget from './Widget'
import EditForm from './EditForm';

export type Config = CommonWidgetConfig & InformationWidgetConfig & { type: "information" };

export const details: WidgetDetails = {
  type: "information",
  name: "Information Widget"
}

export { EditForm, Widget }