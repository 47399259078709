import { SignalDatapoint, Template, SignalId, DBWidgetConfig, LineWidgetConfig } from "./models";
import { WidgetConfig } from "./widgets";
import { Layout } from "react-grid-layout";
import { AssetDashboardResponse } from "./api";
import { WidgetFilterConfig } from "./WidgetFilterForm";

export type DataStore = Map<SignalId, SignalDatapoint[]>;

export interface State {
  loaded: true;
  widgets: WidgetState[];
  template: Template;  
  layout: Layout[];
  columns: number;  
  asset: {
    name: string;
    id: string;
    mastertag: string;
    templateId: string;
    templateName: string;
    alertDm1Count: number;
    alertInfoCount: number;
    alertWarningCount: number;
    alertCriticalCount: number;
    mac: string;   
    orgId: string;
    orgName: string;
    userRole: string;    
    isRead: boolean;
    isUpdate: boolean;  
    operatorId: string;
    operatorIdValue: string;
    accessCardValue: string;
    operatorsHistoryId: string;
    isassetUpdate: boolean;
    istemplatesRead: boolean;
  };
}
interface UpdateLayout {
  type: "update-layout";
  columns: number;
}

interface SaveLayout {
  type: "save-layout";
  layout: Layout[];
}

interface SaveLayoutSuccess {
  type: "save-layout-success";
  layout: Layout[];
}

interface DeleteWidget {
  type: "delete-widget";
  id: WidgetState["id"];
}

interface AddWidget {
  type: "add-widget";
  id: string;
  widget: DBWidgetConfig;
}

interface UpdateWidget {
  type: "update-widget";
  id: WidgetState["id"];
  config: DBWidgetConfig;
}

interface UpdateWidgetFilter {
  type: "update-filter";
  id: WidgetState["id"];
  config: WidgetFilterConfig;
}

interface ClearWidgetFilter {
  type: "clear-filter";
  id: WidgetState["id"];
}

export interface DbLayoutConfig {
  sizeX?: number;
  sizeY?: number;
  row?: number;
  col?: number;
}

export type DbLayout = DbLayoutConfig & { _id: string };

export type WidgetState = WidgetConfig & {
  id: string;
} & DbLayoutConfig &
  WidgetFilterConfig & LineWidgetConfig;

export type Action =
  | UpdateLayout
  | SaveLayout
  | DeleteWidget
  | AddWidget
  | UpdateWidget
  | SaveLayoutSuccess
  | UpdateWidgetFilter
  | ClearWidgetFilter;

export function bindActions(dispatch: (action: Action) => void) {
  return {
    updateLayout: (columns: number) =>
      dispatch({ type: "update-layout", columns }),
    saveLayout: (layout: Layout[]) => dispatch({ type: "save-layout", layout }),
    saveLayoutSuccess: (layout: Layout[]) =>
      dispatch({ type: "save-layout-success", layout }),
    deleteWidget: (id: string) => dispatch({ type: "delete-widget", id }),
    addWidget: (id: string, widget: DBWidgetConfig) =>
      dispatch({ type: "add-widget", id, widget }),
    updateWidget: (id: WidgetState["id"], config: DBWidgetConfig) =>
      dispatch({ type: "update-widget", id, config }),
    updateWidgetFilter: (id: WidgetState["id"], config: WidgetFilterConfig) =>
      dispatch({ type: "update-filter", id, config }),
    clearWidgetFilter: (id: WidgetState["id"]) =>
      dispatch({ type: "clear-filter", id }),
  };
}
///If column lenegth is 4, then widgets has to be displayed
// how ever it is coming from  DB
export function computeLayout(
  widgets: WidgetState[],
  columns: number
): Layout[] {
  if (columns < 4) {
    return widgets.map((w, i) => ({
      i: w.id,
      x: i % 1,
      y: Math.floor(i / 1),
      h: 1,
      w: columns,
    }));
  } else {
    const layout = widgets.map((w) => {
      return {
        i: w.id,
        x: w.col === undefined ? 1 : w.col,
        y: w.row === undefined ? 1 : w.row,
        w: w.sizeX || 1,
        h: w.sizeY || 1,
      };
    });
    return layout;
  }
}

export function initState(
  data: AssetDashboardResponse,
  columns: number,
  context: any
): State {
  const { asset } = data;
  const { template } = asset;

  const dataStore: DataStore = new Map<SignalId, SignalDatapoint[]>();
  const widgets: DBWidgetConfig[] = asset.widgets;

  // We are using a unique widget id so that React can tell the difference
  // between widgets even as we create/delete them. This prevents a re-render
  // of existing widgets and greatly improves performance. The counter is reset anytime
  // the component is unmounted. It is highly unlikely that this counter will ever
  // wrap-around as we would need to create 18014398509481982 widgets in one instance.
  let widgetIdCounter = Number.MIN_SAFE_INTEGER;
  const initWidget = (w: DBWidgetConfig): WidgetState => {
    return {
      ...w,
      type: w.type as "boolean",
      id: w._id,
      signals: w.signals.map((s) => s.signalId),
      ...(w.rightYAxisSignals && w.rightYAxisSignals.length>0 ? {rightYAxisSignals:
        w.rightYAxisSignals.map((s) => s.signalId)} : {})
    };
  };

  const widgs = widgets.map(initWidget);

  return {
    loaded: true,
    asset: { ...asset },
    template: new Template(template.signalCollections, template.rules),    
    columns,
    widgets: widgs,
    layout: computeLayout(widgs, columns)      
  };
}

function updateLayout(state: State, action: UpdateLayout): State {
  if (state.columns === action.columns) return state;

  return {
    ...state,
    columns: action.columns,
    layout: computeLayout(state.widgets, action.columns),
  };
}

function deleteWidget(state: State, action: DeleteWidget): State {
  const widgets = state.widgets.filter((w) => w.id !== action.id);

  return {
    ...state,
    widgets: widgets,
    layout: computeLayout(widgets, state.columns),
  };
}

function addWidget(state: State, action: AddWidget): State {
  const newWidget: WidgetState = {
    ...action.widget,
    signals: action.widget.signals.map((s) => s.signalId),
    type: action.widget.type as "boolean",
    id: action.id,
    ...(action.widget.rightYAxisSignals && action.widget.rightYAxisSignals.length>0 ? {rightYAxisSignals:
      action.widget.rightYAxisSignals.map((s) => s.signalId)} : {})
  };
  const widgets = state.widgets.concat(newWidget);
  return {
    ...state,
    widgets,    
    layout: computeLayout(widgets, state.columns),
  };
}

function updateWidget(state: State, action: UpdateWidget): State {
  const newWidget: WidgetState = {
    ...action.config,
    id: action.id,
    signals: action.config.signals.map((s) => s.signalId),
    type: action.config.type as "boolean",
    ...(action.config.rightYAxisSignals && action.config.rightYAxisSignals.length>0 ? {rightYAxisSignals:
      action.config.rightYAxisSignals.map((s) => s.signalId)} : {})
  };

  return {
    ...state,
    widgets: state.widgets.map((w) => (w.id === action.id ? newWidget : w)),
  };
}

function updateWidgetLayout(
  widgetState: WidgetState[],
  layout: Layout[]
): WidgetState[] {
  return widgetState.map((wg) => {
    const { w, h, x, y } = layout.filter((l) => l.i === wg.id)[0];
    return {
      ...wg,
      sizeX: w,
      sizeY: h,
      col: x,
      row: y,
    };
  });
}

function updateWidgetFilter(state: State, action: UpdateWidgetFilter): State {
  const widget = state.widgets.filter((w) => w.id === action.id)[0];
  const { startDate, endDate, datapoint, startTime, endTime } = action.config;

  const newWidget: WidgetState = {
    ...widget,
    startDate,
    startTime,
    endTime,
    endDate,
    datapoint,
  };
  return {
    ...state,
    widgets: state.widgets.map((w) => (w.id === action.id ? newWidget : w)),
  };
}

function clearWidgetFilter(state: State, action: ClearWidgetFilter): State {
  const widget = state.widgets.filter((w) => w.id === action.id)[0];
  const newWidget: WidgetState = {
    ...widget,
    startDate: undefined,
    endDate: undefined,
    datapoint: 50,
  };
  return {
    ...state,
    widgets: state.widgets.map((w) => (w.id === action.id ? newWidget : w)),
  };
}

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "update-layout":
      return updateLayout(state, action);

    case "save-layout":
      return {
        ...state,
        layout: action.layout,
      };
    case "save-layout-success":
      return {
        ...state,
        widgets: updateWidgetLayout(state.widgets, action.layout),
      };
    case "delete-widget":
      return deleteWidget(state, action);

    case "add-widget":
      return addWidget(state, action);

    case "update-widget":
      return updateWidget(state, action);
    case "update-filter":
      return updateWidgetFilter(state, action);
    case "clear-filter":
      return clearWidgetFilter(state, action);

    default:
      return state;
  }
}
