import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function roleMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    record: any
) {
    return async (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL + "/ui/api/customer-org-permissions",
            record,
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
          
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.org);
                }
                dispatch({ type: "ROLE_PERMISSION_MAINT_SUCCESS", payload: {data: ""} });
            }
        )
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

export function getOrgRolesMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: string
) {

    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/org-roles/" + orgId , {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
                         
            let data = response.data;
           
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data);
                }
                dispatch({ type: "GET_ORG_ROLE_MAINT_GET_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

export function rolePermissionMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    record: any,
    orgId:any
) {
       
    return async (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL + "/ui/api/org-role-permissions/" + orgId,
            record,
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {                   
                    callbackFnSuccess(record, response.data);
                }
                
                dispatch({ type: "ROLE_PERMISSION_MAINT_SUCCESS", payload: {data: response.data} });
            }
        )
        .catch(function (error) {
           
            if (callbackFnFailure != null) {
                callbackFnFailure(error);
            }
        });
    }
}


export function getOrgsMaint(
    me: any, 
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
    orgId?: string,
    searchText?: string
) {
    return async (dispatch: any) => {            
        Axios.get(
            appConfigs.server.URL + "/ui/api/orgs-lov", {
                responseType: "json",        
                headers: {},
                params: {
                    mode: (orgId || "") === "" ? "administered" : "single",
                    scope: "all",
                    includeTree: false,
                    orgId: orgId,
                    searchText: searchText,
                    page: 1,
                    pageSize: 9999
                }
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.orgs);
                }
                dispatch({ type: "ROLES_ADMINISTERED_ORGS_SUCCESS", payload: {data: response.data.data.orgs} });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
    };
}

export function rolesMaintClose(
    me: any
) {   
    return (dispatch: any) => {
        dispatch({ type: "ROLES_MAINT_CLOSE" });
    };
}

