import React, { CSSProperties } from "react";

import Icon from "@ant-design/icons";

import { ReactComponent as CriticalSvg } from "../../assets/images/map-status/critical.svg";
import { ReactComponent as WarningSvg } from "../../assets/images/map-status/warning.svg";
import { ReactComponent as InfoSvg } from "../../assets/images/map-status/information.svg";
import { ReactComponent as OnlineSvg } from "../../assets/images/map-status/online.svg";
import { ReactComponent as OfflineSvg } from "../../assets/images/map-status/offline.svg";
import { ReactComponent as EngineSvg } from "../../assets/images/map-status/engine.svg";
import { ReactComponent as AssetDownSvg } from "../../assets/images/vehicle-icons/maintenance/car_repair_service_purple.svg";

interface IconProps {
  onClick?: (a: any) => void;
  style?: CSSProperties;
}

export type Icon = React.FC<IconProps>;

function customIcon(Svg: React.FC<any>): Icon {
  return ({ onClick, style }) => (
    <Icon component={Svg} onClick={onClick} style={style} />
  );
}

export const OnlineIcon: Icon = customIcon(OnlineSvg);
export const OfflineIcon: Icon = customIcon(OfflineSvg);
export const CriticalIcon: Icon = customIcon(CriticalSvg);
export const WarningIcon: Icon = customIcon(WarningSvg);
export const InfoIcon: Icon = customIcon(InfoSvg);
export const DM1Icon: Icon = customIcon(EngineSvg);
export const AssetDown: Icon = customIcon(AssetDownSvg);
