import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { GeofenceShape } from "../geofenceHelper";

export function closeDrawer() {
	return (dispatch: any) => {
		dispatch({ type: "SET_DRAWER_CLOSE" });
	};
}

export function setName(name: string) {
	return (dispatch: any) => {
		dispatch({ type: "SET_NAME", payload: { fenceName: name } });
	};
}

export function setColor(color: string) {
	return (dispatch: any) => {
		dispatch({ type: "SET_COLOR", payload: { fenceColor: color } });
	};
}

export function setDescription(description: string) {
	return (dispatch: any) => {
		dispatch({
			type: "SET_DESCRIPTION",
			payload: { description: description },
		});
	};
}

export function setAssetsList(assetsList: any) {
	return (dispatch: any) => {
		dispatch({ type: "SET_ASSETSLIST", payload: { assetsList: assetsList } });
	};
}

// export function setMastertags(mastertags: any) {
// 	return (dispatch: any) => {
// 		dispatch({ type: "SET_MASTERTAGS", payload: { mastertags: mastertags } });
// 	};
// }

export function getGeoFences() {
	return (dispatch: any) => {
		Axios.get(appConfigs.server.URL + "/ui/api/geofences", {
			responseType: "json",
			headers: {},
			params: {},
		})
			.then((response) => {
				dispatch({
					type: "SET_FENCELIST",
					payload: { fenceList: response.data.data.geofences },
				});
			})
			.catch(function (error) {
				dispatch({type: "SET_FENCELIST_LOADED", payload: { fenceListLoaded: true }});
			});
	
	};
}

export function setChangeInGateways(changeInGateways: any) {
	return (dispatch: any) => {
		dispatch({
			type: "SET_CHANGE_IN_GATEWAYS",
			payload: { changeInGateways: changeInGateways },
		});
	};
}

export function setFenceListLoaded(fenceListLoaded: boolean) {
	return (dispatch: any) => {
		dispatch({type: "SET_FENCELIST_LOADED", payload: { fenceListLoaded: fenceListLoaded }});
	};
}

export function setShapeGateways(id: any, gateways: any) {
	return (dispatch: any) => {
		dispatch({
			type: "SET_SHAPE_GATEWAYS",
			payload: { id: id, gateways: gateways },
		});
	};
}

export function resetChangeGateways() {
	return (dispatch: any) => {
		dispatch({ type: "RESET_CHANGE_IN_GATEWAYS" });
	};
}

export function removeShapeFromMap(id: any) {
	return (dispatch: any) => {
		dispatch({ type: "REMOVE_SHAPE_FROM_MAP", payload: { id: id } });
	};
}

export function updateShape(id: any, record: any) {
	return (dispatch: any) => {
		dispatch({ type: "UPDATE_SHAPE", payload: { id: id, record: record } });
	};
}

export function updateShapeOnMap(geofenceShape: GeofenceShape, newOptions: any){
	return (dispatch:any) => {
		dispatch({ type: "UPDATE_SHAPE_ON_MAP", payload: { geofenceShape: geofenceShape, newOptions: newOptions } });
	}
}

export function addShapeSubmit(geofenceShape: any, record: any, id: any) {
	return (dispatch: any) => {
		dispatch({
			type: "ADD_SHAPE_SUBMIT",
			payload: { geofenceShape: geofenceShape, record: record, id: id },
		});
	};
}

export function removeShape(geofenceShape: GeofenceShape) {
	return (dispatch: any) => {
		dispatch({ type: "REMOVE_SHAPE", payload: { geofenceShape: geofenceShape } });
	};
}

export function removeShapesFromMap(){
	return (dispatch:any) => {
		dispatch({ type: "REMOVE_SHAPES_FROM_MAP"});
	}
}

export function addShapesToMap(geofenceShape: GeofenceShape){
	return (dispatch:any) => {
		dispatch({ type: "ADD_SHAPES_TO_MAP"});
	}
}