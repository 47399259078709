import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function userSubscriptionsSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "USERS_SUBSCRIPTIONS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}   

export function userSubscriptionsSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "USERS_SUBSCRIPTIONS_SET_SEARCH", searchText: searchText });
    }
}   

export function userSubscriptionsGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    userId: string,
    searchText: string,
    page: number = 1
) {
    return async (dispatch: any) => {            
        Axios.get(
            appConfigs.server.URL + "/ui/api/users/" + ((userId || "") !== "" ? userId + "/": "") + "subscriptions", {
                    responseType: "json",        
                    headers: {},
                    params: {
                        searchText: searchText,
                        page: page,
                        pageSize: appConfigs.tables.pageSize
                    }
                }
            )
            .then(response => {
                    if (callbackFnSuccess != null) {
                        callbackFnSuccess(response.data.data.orgs);
                    }
                    dispatch({ type: "USERS_SUBSCRIPTIONS_GET_SUCCESS", payload: {data: response.data.data.userSubscriptions, page: page} });
                }
            )
            .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
            
    };
}    

export function userSubscriptionsMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    userId: string,
    record: any,
    notification: string,
    action: string,
    scope: string
) {
    return async (dispatch: any) => {
        Axios.put(
            appConfigs.server.URL + "/ui/api/users/" + userId + "/subscriptions" + 
                "?gatewayId=" + record.gatewayId + 
                "&signalId=" + record.signalId + 
                "&notification=" + notification + 
                "&action=" + action +
                "&scope=" + scope,
            {},
            {
                responseType: "json"
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(userId, record, notification, action, scope);           
                }
                dispatch({ type: "USERS_SUBSCRIPTIONS_MAINT_SUCCESS", payload: {record: record, notification: notification, action: action, scope: scope} });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error, userId, record, notification, action, scope);
                }
            }
        );
    }
}
