import React from "react";
import moment from "moment";
import { Form, DatePicker } from 'antd';

const { RangePicker } = DatePicker;

class Timespan extends React.Component<{}, {}> {

    disabledDate = (value:any) => {
        return value && !value.isBetween(moment().subtract(6, 'months'), moment());
    }; 
    
    timeRange = (start:number, end:number):number[] => {
        const arr:number[] = [];

        for (let i = start; i <= end; i++) {
            arr.push(i);
        }

        return arr;
    }; 

    disabledTime = (value:any, type:string) => {
        return {
            disabledHours: () => (value && moment().isSame(value, "d") ? this.timeRange(Math.min(moment().hours() + 1, 23), 23) : []),
            disabledMinutes: () => [],
            disabledSeconds: () => []
        };
    }; 

    render() {    

        return (

            <Form.Item 
                name="timespan" 
                label="Timespan"
            >
                <RangePicker 
                    showTime 
                    disabledDate={this.disabledDate}
                    disabledTime={this.disabledTime}
                    ranges={{
                        Today: [moment().startOf('day'), moment()],
                        'This Week': [moment().startOf('week'), moment()],
                        'This Month': [moment().startOf('month'), moment()]
                    }}
                />
            </Form.Item>
        )
    }
}

export default Timespan;