import Axios from "axios";
import { appConfigs } from "../utils/configurations";
import { errorResponse } from "../utils/apiHelpers/apiHelpers";

export function userOrgAccessGet(
    me?: any,
    callbackSuccessFn?: any,
    callbackErrorFn?: any,
    selectedOrgId?: any,
    userId?: any
) {
    return async (dispatch: any) => {
        await Axios.get(
            appConfigs.server.URL + `/api/users/${userId}/org/${selectedOrgId}?admin=true`, {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {                            
                if (callbackSuccessFn != null) {
                    callbackSuccessFn(response.data);
                }
                dispatch({ type: "USER_ORG_ACCESS_SUCCESS", payload: {data: response.data} });
            }
        )
        .catch(function (error) {                
                errorResponse(me, error, false);
                if (callbackErrorFn != null) {
                    callbackErrorFn(error);
                }
                dispatch({ type: "USER_ORG_ACCESS_FAILURE", error: error });
            }
        );
    };
}