import React from "react";
import { connect } from "react-redux";
import { Drawer, Tabs, Button, Space } from "antd";
import * as actions from "./actions";
import { appConfigs } from "../../utils/configurations";
import { getPermissions } from "../../utils/commonHelpers";
import RoleMaintInfo from "./roleMaintInfo/RoleMaintInfo";
import RoleCopyMaintInfo from "./roleMaintInfo/RoleCopyMaintInfo";
import RoleViewMaintInfo from "./roleMaintInfo/RoleViewMaintInfo";
import RolePermissionMaintInfo from "./roleMaintInfo/RolePermissionMaintInfo";

const { TabPane } = Tabs;

interface Prop {
    roleMaintClose: Function,
    getOrgRoles: Function,
    getRolePermissions:Function,
    getSubmitButton: Function,
    context: any,
    roleList: any,
    permissions: any
}

class RoleMaint extends React.Component<Prop, {}> {
    
    componentDidUpdate(prevProps: Prop) {
       
        if (this.props.roleList.roleMaint.instance !== prevProps.roleList.roleMaint.instance) {
            this.closeCollapsed();
        }        
    }
    
    closeCollapsed() {       
        let active:any = document.querySelectorAll("[data-id=role-maint-drawer] .ant-collapse-item-active");    
        this.props.getSubmitButton(false);
        if (active.length) {
            for (var i = 0; i < active.length; i++) { 
                active[i]!.children[0].click();  
            }
        }
    }

    onDrawerClose = () => {
        const { appContext } = this.props.context;
        this.props.getOrgRoles(this,null,null,appContext.orgId,this.props.roleList.searchText);   
        //ops-2689
        //appContext.roleName === "admin" ? this.props.getRolePermissions(this,null,null,"2") : this.props.getRolePermissions(this,null,null,"1");
        this.props.getRolePermissions(this,null,null,appContext.roleId);
        
        this.props.getSubmitButton(false);
        this.props.roleMaintClose(this);
    };

    onTabChange = (key:string) => {
        //this.props.roleMaintTabChange(this, key);
    }

    render() {

        const { roleMaint } = this.props.roleList;
        
        const suborgs = getPermissions(this.props.permissions.permissions.sub_orgs);
        let issuborgsread = suborgs[1];
       
        return(
            <Drawer
                data-id="role-maint-drawer" 
                title={roleMaint.title}
                width={roleMaint.width}
                onClose={this.onDrawerClose}
                visible={roleMaint.visible}
                destroyOnClose={true}
                footer={
                    <Space size="large" style={{ float: 'right' }}>
                        <Button data-id="role-maint-close-button" type="default" onClick={this.onDrawerClose}>
                            {appConfigs.settings.drawers.closeButtonText}
                        </Button>
                        {
                            roleMaint.mode === "add-role" &&    
                            <Button data-id="role-add-maint-info" type="primary" form="roleAddMaintInfoForm" key="submit" htmlType="submit" disabled={!roleMaint.isSubmitButton}>
                                {appConfigs.settings.form.submitButtonText}
                            </Button>
                        }
                        {
                            roleMaint.mode === "edit-role" &&    
                            <Button data-id="role-maint-info" type="primary" form="roleMaintInfoForm" key="submit" htmlType="submit" disabled={!roleMaint.isSubmitButton}>
                                {appConfigs.settings.form.submitButtonText}
                            </Button>
                        }
                        {
                            roleMaint.mode === "copy-role" &&    
                            <Button data-id="role-copy-maint-submit-button" type="primary" form="roleCopyMaintInfoForm" key="submit" htmlType="submit" disabled={!roleMaint.isSubmitButton}>
                                {appConfigs.settings.form.submitButtonText}
                            </Button>
                        }
                        
                    </Space>
                }
            >
                {
                    roleMaint.mode === "add-role" &&
                    <Tabs data-id="role-maint-info-tabs" activeKey={roleMaint.activeTab} size="large" type="card" onChange={this.onTabChange}>
                        <TabPane data-id="role-maint-info-tab-button" tab="Role Info" key="1">                    
                                <RolePermissionMaintInfo issuborgsread = {issuborgsread}/>
                        </TabPane>
                    </Tabs>
                }
                {
                    roleMaint.mode === "view-role" &&
                    <Tabs data-id="role-maint-info-tabs" activeKey={roleMaint.activeTab} size="large" type="card" onChange={this.onTabChange}>
                        <TabPane data-id="role-maint-info-tab-button" tab="Role Info" key="1">                    
                                <RoleViewMaintInfo />
                        </TabPane>
                    </Tabs>
                }
                {
                    roleMaint.mode === "edit-role" &&
                    <Tabs data-id="role-maint-info-tabs" activeKey={roleMaint.activeTab} size="large" type="card" onChange={this.onTabChange}>
                        <TabPane data-id="role-maint-info-tab-button" tab="Role Info" key="1">                    
                                <RoleMaintInfo issuborgsread = {issuborgsread} />
                        </TabPane>
                    </Tabs>
                }
                {
                    roleMaint.mode === "copy-role" &&
                    <Tabs data-id="role-maint-info-tabs" activeKey={roleMaint.activeTab} size="large" type="card" onChange={this.onTabChange}>
                        <TabPane data-id="role-maint-info-tab-button" tab="Role Info" key="1">                    
                                <RoleCopyMaintInfo issuborgsread = {issuborgsread} />
                        </TabPane>
                    </Tabs>
                }
            </Drawer>
        );
    }

}
const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        roleList: state.rolesReducer.rolesState.roleList,
        permissions: state.contextReducer.data,
    };
};

export default connect(
    mapStateToProps,
    actions
)(RoleMaint);