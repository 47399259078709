import React, { Component } from "react";
import { Table,Badge, Tooltip } from "antd";

import { appConfigs } from "../../../utils/configurations";
import history from '../../../utils/history';

import { connect } from "react-redux";
import * as actions from "../actions";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, CloudServerOutlined } from "@ant-design/icons";
import { stringToUrlString, getPermissions } from "../../../utils/commonHelpers";





interface Prop {
    getLiteAssets: Function,
    assetsList: any,
    liteAssetsSetPagination:Function,
    context: any,
    permissions: any,
}



class LiteAssets extends Component<Prop, {}> {

       
    componentDidMount () {
        this.props.liteAssetsSetPagination(this.onPaginationChange);
        this.props.getLiteAssets(this, null, null,1, "","","","");      
    }

    onPaginationChange = (page: number) => {
        this.props.getLiteAssets(this, null, null,page, this.props.assetsList.liteAssets.searchText,"","",""); 
       };

       goToTemplate = (e: any, templateName: string, templateId: string) => {
        e.preventDefault();

        let templateNameUrl = stringToUrlString(templateName);
        templateNameUrl = (templateNameUrl || "") === "" ? "details-" + templateId : templateNameUrl;

        history.push("/template/details/" + templateNameUrl + "/" + templateId);
    };
    goToAsset = (e: any, mastertag: number) => {
        e.preventDefault();
        history.push("/assets/" + mastertag);
    };
   
    render() {    
        const { user:contextUser, appContext} = this.props.context;

        const { tableConfig, tableData} = this.props.assetsList.liteAssets;
        const templatesPermission = getPermissions(this.props.permissions.permissions.templates);
        let isTemplateRead = templatesPermission[1];
        let access = JSON.parse(appContext.disabledFeaturesOfContextOrg);
        const tableColumns = [
            {
                title: "Asset",
                dataIndex: "nickname",
                sorter: false,
                render: (text: any, record: any) => (
                ((access==null || (access && access.length===0))
                    && (!record.blockedassetOrgId ? true : (contextUser.homeOrg.orgId !== record.blockedassetOrgId? true:false)))?
                    <a href="# " onClick={(e) => {this.goToAsset(e, record.mastertag)}}> 
                        {   
                            record.status === "active" &&
                            <span className="asset-status-green pr-5" ><CheckCircleOutlined /></span>
                        }
                        {   
                            record.status === "inactive" &&
                            <span className="asset-status-red pr-5" ><CloseCircleOutlined /></span>
                        }
                        {   
                            record.status === "whitelisted" &&
                            <span className="asset-status-gray pr-5" ><ClockCircleOutlined /></span>
                        }
                        {record.nickname || record.mastertag} 
                    </a>
                    :
                    <Tooltip title="Access Denied" >
                    {    
                    <a style={{pointerEvents: 'none', cursor: 'default', opacity: '0.5', color:'#808080'}}> 
                        {   
                            record.status === "active" &&
                            <span className="asset-status-green pr-5" ><CheckCircleOutlined /></span>
                        }
                        {   
                            record.status === "inactive" &&
                            <span className="asset-status-red pr-5" ><CloseCircleOutlined /></span>
                        }
                        {   
                            record.status === "whitelisted" &&
                            <span className="asset-status-gray pr-5" ><ClockCircleOutlined /></span>
                        }
                        {record.nickname || record.mastertag} 
                    </a>
                    }
                    </Tooltip>

                )              
            },
            {
                title: "Status",
                responsive: ["md"] as Breakpoint[],
                align: "center" as "center",
                render: (text: string, record: any) => (
                    <span>{record.status}</span>
                )
            },
            {
                title: "Part Number",
                dataIndex: "partNumber",
                key: "partNumber",
                align: "center" as "center",
                render: (text: string, record: any) => (
                    <span>{record.partNumber}</span>
                 )
            },
            {
                title: "Quota Name",
                dataIndex: "quotaName",
                key: "quotaName",
                align: "center" as "center",
                render: (text: string, record: any) => (
                    <span>{record.quotaName}</span>
                 )
            },
            {
                title: "#Signals",
                dataIndex: "noOfSignals",
                key: "noOfSignals",
                align: "center" as "center",
                render: (text: number, record: any) => (
                    <span>{record.noOfSignals}</span>
                 )
            },
            {
                title: "Min Feq",
                dataIndex: "minCollectionFreq",
                key: "minCollectionFreq",
                align: "center" as "center",
                render: (text: number, record: any) => (
                   <span>{record.minCollectionFreq}</span>
                )
            },
            {
                title: "Max DM1",
                dataIndex: "maxDayDm1",
                key: "maxDayDm1",
                align: "center" as "center",
                render: (text: number, record: any) => (
                   <span>{record.maxDayDm1}</span>
                )
            },
            {
                title: "Mastertag",
                dataIndex: "mastertag",
                responsive: ["lg"] as Breakpoint[]
            },
            {
                title: "Org Name",
                dataIndex: "orgName",
                responsive: ["lg"] as Breakpoint[]
            },
            {
                title: "Template",
                dataIndex: "templateName",
                responsive: ["lg"] as Breakpoint[],
                className: appContext.roleName === "guest" ? "hidden" : "",
                
                render: (text: any, record: any) => (
                    <>
                       {
                             !record.templateName &&
                             <span></span>
                        }
                        {
                            record.templateName && isTemplateRead && record.templateVisibleToSubOrg &&
                            <Badge dot offset={[5, 5]}>
                                <a href="# " onClick={(e) => {this.goToTemplate(e, record.templateName, record.templateId)}}> 
                                    {record.templateName} 
                                </a>
                            </Badge> 
                        }
                        {
                            record.templateName && isTemplateRead && !record.templateVisibleToSubOrg &&
                            <a href="# " onClick={(e) => {this.goToTemplate(e, record.templateName, record.templateId)}}> 
                                {record.templateName} 
                            </a>
                        }
                        {
                            record.templateName && !isTemplateRead && record.templateVisibleToSubOrg &&
                            <Badge dot offset={[5, 5]}>
                                <span>{record.templateName}</span> 
                            </Badge>
                        }
                        {
                             record.templateName && !isTemplateRead && !record.templateVisibleToSubOrg &&
                             <span>{record.templateName}</span> 
                        }
                    </>
                )              
            }
    
        ];

        return (
            <>
            {
                // this.props.isread &&
                <Table  
                    data-id="lite_assets_data"
                    {...tableConfig}
                    rowKey={(record) => record.gatewayId}
                    columns={tableColumns}
                    dataSource={tableData}
                    className="mt-10"
                />
            }
           
            </>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        permissions: state.contextReducer.data,
        assetsList: state.assetsReducer.assetsState.assetsList,        
       
    };
};

export default connect(
    mapStateToProps,
    actions
)(LiteAssets);
  
