import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function templateDetailsTabChange(
    me: any,
    tab: string
) {
    return (dispatch: any) => {
        dispatch({ type: "TEMPLATE_DETAILS_TAB_CHANGE", tab: tab });
    };
}

export function getTemplate(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/templates/" + templateId, {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
                let record = response.data.data.templates == null || (response.data.data.templates.length || 0) === 0 ? {} : response.data.data.templates[0];
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(record);
                }
                dispatch({ type: "TEMPLATE_DETAILS_GET_TEMPLATE_SUCCESS", record: record });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error);
            }
            errorResponse(me, error);
        });
    };
}
//Request URL: http://localhost:8080/ui/api/templates/242bf9b0-c8f3-11e8-a384-d1aa7ba0fd74/info?fieldName=name&fieldValue=Demo%20Case%2031

export function templateMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string,
    fieldName: string,
    fieldValue: string
) {
    return async (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL + "/ui/api/templates/" + templateId + "/info?fieldName=" + fieldName + "&fieldValue=" + fieldValue,
            {},
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.templates[0]);
                }
                dispatch({ type: "TEMPLATE_DETAILS_MAINT_TEMPLATE_SUCCESS", record: response.data.data.templates[0] });
            }
        )
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
                errorResponse(me, error, false);
            }
        );
    }
}