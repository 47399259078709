export const childNodes = (signals: any) => {
  return {
    key: signals._id,
    title: signals.label,
  };
};

export const createTreeStructure = (collection: any) => {
  return {
    key: collection._id,
    title: collection.name,
    children: collection.values && collection.values.map(childNodes),
  };
};

export const createJsonForSignalMaint = (datapoints: any, selectedValues: any) => {
    const { signals } = selectedValues;
    const selectedSignals = signals.filter((v: any) => datapoints.every((d: any) => d._id !== v));

    const preferedSignalJson = selectedSignals.reduce((acc: any, cur: any) => {
        const signalObject = datapoints.filter((cur1: any) => {
            if(cur1.values) {
              return cur1.values.some((sc: any) => cur === sc._id);
            } else {
              return false;
            }         
        });
        return acc.concat({'signalCollectionId': signalObject[0]._id, 'signalId': cur});
    },[]);

    return preferedSignalJson;
}
