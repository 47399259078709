import {
  XYChart,
  ColumnSeries,
  ValueAxis,
  Legend,
  DateAxis,
  XYCursor,
} from "@amcharts/amcharts4/charts";
import * as amCore from "@amcharts/amcharts4/core";

import { AxisRange } from "../models";
import {
  DATE_FORMAT,
  TOOLTIP_FORMAT,
  NUMBER_FORMAT,
  signalLabel,
} from "../common";
import { appConfigs } from "../../../../utils/configurations";

export interface Options {
  axisRange?: AxisRange;
}

export type TimeSeriesSignal = {
  name: string;
  units: string;
  values: Array<{ timestamp: Date; value: number }>;
};

export default class ClusterBarChart {
  private _chart: XYChart;
  private  count = 0 ;
  constructor(ref: HTMLDivElement, series: TimeSeriesSignal[], opts?: Options) {
    const chart = amCore.create(ref, XYChart); 
    chart.colors.step = 3;
    
    
  
    const xAxis = chart.xAxes.push(new DateAxis());
    xAxis.renderer.cellStartLocation = 0.1
    xAxis.renderer.cellEndLocation = 0.9
    xAxis.renderer.grid.template.location = 0;
    xAxis.cursorTooltipEnabled = false;

    const yAxis = chart.yAxes.push(new ValueAxis());
    yAxis.cursorTooltipEnabled = false;
    yAxis.min = opts?.axisRange?.min;
    yAxis.max = opts?.axisRange?.max;

    const cursor = (chart.cursor = new XYCursor());
    cursor.lineY.disabled = true;
    cursor.lineX.disabled = true;
    // Show only one tooltip
    // https://www.amcharts.com/docs/v4/concepts/chart-cursor/#Relation_to_series
    cursor.maxTooltipDistance = -1;

    chart.legend = new Legend();
    chart.legend.useDefaultMarker = false;     
    chart.legend.itemContainers.template.tooltipText="{name}";
    
    chart.dateFormatter.dateFormat = DATE_FORMAT;
    chart.numberFormatter.numberFormat = NUMBER_FORMAT;
  
    this._chart = chart;
   
    series.forEach(this.addSeries);
  }
   
  addSeries = (dataSeries: TimeSeriesSignal) => {

    const series = new ColumnSeries();
   
    series.dataFields = {
      valueY: "value",
      dateX: "timestamp",
    };
    
    series.data = dataSeries.values;     
    series.strokeWidth = 4;  
    series.paddingRight = 2;
    series.name = signalLabel(dataSeries);
    series.tooltipText = TOOLTIP_FORMAT;

    this._chart.series.push(series);

    this.count++;
  };

  destroy() {
    this._chart?.dispose();
  }
}
