import { DataStore, DbLayoutConfig, RemoteControl } from "./state";
import { AxisRange } from "./widgets/models";
import { LineOptions } from "./widgets/LineChart";
import { TrackerOptions } from "./widgets/TrackerWidget";
import { GaugeOptions } from "./widgets/GaugeWidget";
import { BarOptions } from "./widgets/BarChart";
import { ClusterBarOptions } from "./widgets/ClusterBar";

export type SignalId = string;
type widgetId = string;
export type SignalCollectionId = string;
type SignalValue = number;

export interface WidgetSignalId {
  id: string,
  color: string
}

export interface Rule {
  signalId: SignalId;
  criticalHigh?: number;
  warningHigh?: number;
  infoHigh?: number;
  infoLow?: number;
  warningLow?: number;
  criticalLow?: number;
}

export interface SignalCollection {
  id: SignalCollectionId;
  name: string;
  type: string | null;
  signals: Signal[];
}

export interface Signal {
  format: string;
  id: SignalId;
  name: string;
  color: string;
  units: string;
  pgn: string;
}

export interface WidgetFilterSetting {
  aggregate: string;
  granularity: string;
}


export interface CommonWidgetConfig extends LineWidgetConfig{
  title: string;
  signals: WidgetSignalId[];
}

export interface LineWidgetConfig {
  rightYAxisSignals?:WidgetSignalId[];
  timescaleAPIZooming?: Function;
  widgetFilterSetting?: WidgetFilterSetting;
}

export interface CommonWidgetProps {
  signals: WidgetSignalId[];
  data: DataStore;
  template: Template; 
  signalRData?: any; 
  state:any;
  informationValue?:string;
}
export interface InformationWidgetProps {
  template: Template; 
}
export interface LineWidgetProps{
  rightYAxisSignals?:WidgetSignalId[];
  rightYAxisData: DataStore;
  timescaleAPIZooming?: Function;
  widgetFilterSetting?: WidgetFilterSetting;
}

export interface SignalDatapoint {
  timestamp: Date;
  value: SignalValue;
}

export interface AlertSignalDatapointData {
  timestamp: Date;
  value: SignalValue;
  severity?: string;
  ruleName?: string;
}

export interface Dashboard {
  dashboardId: number,
  title: string,
  bgimageurl: string,
  isNewDashboard:boolean,
  isdefaultDashboard: boolean,
  widgets: DBWidgetConfig[];
}

export interface TextMessage {
  signalId: string,
  firstName: string,
  lastName: string,
  type: string, 
  text: string,
  timestamp: Date
}

export interface C2DWlist{
  whitelistMessages: string
}

export interface Asset {
  id: string;
  name: string;  
  gatewayId: string,
  mastertag: string;
  orgId: string;
  template: {
    signalCollections: SignalCollection[];
    rules: Rule[];
  };
  datapoints: any,
  templateId: string;
  templateName: string;
  orgName: string;
  orgPathId: string;
  //widgets: DBWidgetConfig[];
  alertDm1Count: number;
  alertInfoCount: number;
  alertWarningCount: number;
  alertCriticalCount: number;
  mac: string;
  dashboards: Dashboard[];
  userRole: string; 
  isRead: boolean;
  isUpdate: boolean; 
  operatorId: string;
  operatorIdValue: string;
  accessCardValue: string;
  operatorsHistoryId: string;
  connectionStatus: string;
  remoteControl: RemoteControl[];
  orgCmdPgnSpns: string;
  c2dMessages: TextMessage[];
  c2dWhitelist: C2DWlist[];
  resetTimestamp: string;
  resetlocationLatitude: number;
  resetlocationLongitude: number;
  blockedassetOrgId: string;
  assetDownDesc:string;
}

export interface EventSignals {
  _id: string;
  name: string;
  value: number;
  uom: string;
  severity?: string;
}

export interface EventData {
  signalCollectionID: SignalCollectionId;
  orgID: string;
  createdAt: string;
  timestamp: string;
  signals: EventSignals[];
}

export interface WidGetSignalConfig {
  name: string;
  color: string;
  datapointId: SignalCollectionId;
  signalId: SignalId;
}

export interface DBWidgetConfig {
  _id: widgetId;
  type: string;
  signals: WidGetSignalConfig[];
  sizeX?: number;
  sizeY?: number;
  row?: number;
  col?: number;
  title: string;
  settings?: LineOptions | BarOptions | GaugeOptions | TrackerOptions | ClusterBarOptions;
  rightYAxisSignals?: any[];
  informationValue?:string;
}

export interface PermissionProps {
  userRole: string;
  isCreate?: boolean;
  isRead?: boolean;
  isUpdate?: boolean;
  isDelete?: boolean;
  isAssetUpdate?: boolean;
  isTemplatesRead?: boolean;
  isOprRead?: boolean;
  isOprUpdate?:boolean;

}

export interface LineWidgetSignalsConfigs {
  collectionId: string;
  signalId: string;
  agg: string;
  axis: string; 
  units: string; 
}

export class Template {
  constructor(
    public readonly signalCollections: SignalCollection[],
    public readonly rules: Rule[]
  ) {}

  get signals(): Signal[] {
    const signals = this.signalCollections.map((sc) => sc.signals);
    return signals.reduce((result, signals) => result.concat(signals), []);
  }
}
export interface Information{
  name: string
}
export interface InformationWidgetConfig{
  title: string;
  informationValue:string;
}
