import React from "react";
import { Form, Row, Col, Input, Collapse, Switch, Button, Select, Tooltip, InputNumber, Modal} from 'antd';
import {CheckOutlined, CheckCircleOutlined, CloseOutlined,PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FormInstance } from 'antd/lib/form';


import { appConfigs } from "../../../utils/configurations";

import { formInit } from "../../../utils/apiHelpers/formHelpers";
import { SuccessAlert, ErrorAlert, WarningAlert } from "../../../utils/Components/formAlerts";

import { connect } from "react-redux";
import * as actions from "../actions";
import { getPermissions } from "../../../utils/commonHelpers";

import WiFiConnect from "./WiFiConnect";
import { errorResponse, resetFormErrors, successResponse, warningResponse } from "../../../utils/apiHelpers/apiHelpers";
import { debug } from "console";
import FilterForm from "../../Dashboard2/WidgetFilterForm/FilterForm";
import useModal from "antd/lib/modal/useModal";



const _ = require('lodash');
const { Option } = Select;
const { Panel } = Collapse;
interface Prop {
    setAssetSettings: Function,
    getAssetSettings: Function,
    assetsList: any,
}
interface SSID{
    wifiSSID: string,
    wifiPassword: string
}

interface State {

    formError?: boolean,
    formErrorMessage?: string[],
    formSuccess?: boolean,
    formSuccessMessage?: string,
    formWarning?: boolean;
    formWarningMessage?: string,
    formCommit?: boolean,
    mastertag?: string,
    viewWiFiStatus: boolean,
    viewWiFiPanel: boolean;
    viewEthernetPanel: boolean,
    enableHotspot: boolean,
    enableEthernet: boolean,
    wifiActiveKey: string,
    ethernetActiveKey: string,   
    sleepModeActiveKey: string,
    SSIDProviders: Array<SSID | undefined>,
    allowViewSleepMode: boolean,
    allowChangeSleepMode: boolean,
    enableSleepMode: boolean,
    sleepCycleInSeconds: string,
    sleepCycleApplied: boolean, 
    sleepCycleValue: number,
    sleepCycleUom: string,
    nextWakeUpTime: string,
    initialValues: any,
    isAlertModalOpen: boolean
    triedEnablingHotspot: boolean,
    triedEnablingEthernet: boolean,
    triedEnablingWiFi: boolean,
    ethernetIPAddress: string,
}

class AssetWiFiClient extends React.Component<Prop, State> {  
        
    state: State = {
        enableHotspot: false,
        enableEthernet: false,
        viewWiFiStatus: false,
        viewWiFiPanel: false,
        viewEthernetPanel: false,
        wifiActiveKey: "0",
        ethernetActiveKey: "0",
        mastertag:"",
        SSIDProviders: [],
        allowViewSleepMode: false, 
        allowChangeSleepMode: false,
        enableSleepMode: false,
        sleepModeActiveKey: "3",
        sleepCycleInSeconds: "",
        sleepCycleValue: 0,
        sleepCycleUom: "second",
        sleepCycleApplied: false,
        nextWakeUpTime: "",
        initialValues:{},
        isAlertModalOpen: false,
        triedEnablingHotspot: false,
        triedEnablingWiFi: false,
        triedEnablingEthernet: false,
        ethernetIPAddress: appConfigs.app.defaultIP
    };

    formRef = React.createRef<FormInstance>();
    handleOk = () =>{
        if(this.state.triedEnablingWiFi){
            this.setState({
                viewWiFiStatus: true,
                viewWiFiPanel: true,
                wifiActiveKey: "1",
                ethernetActiveKey: "0",
                enableEthernet: false,
                enableHotspot: false,
                isAlertModalOpen: false          
            });
        }
        if(this.state.triedEnablingEthernet){
            this.setState({
                enableEthernet: true,
                viewWiFiStatus: false,
                viewWiFiPanel: false,
                wifiActiveKey: "0",
                ethernetActiveKey: "1",
                SSIDProviders: [],
                isAlertModalOpen: false
            });
        }
        if(this.state.triedEnablingHotspot){
            this.setState({
                enableHotspot: true,
                viewWiFiStatus: false,
                viewWiFiPanel: false,
                wifiActiveKey: "0",
                SSIDProviders: [],
                isAlertModalOpen: false
            });
        }
    } 
    handleCancel = () => {
        this.setState({
            isAlertModalOpen: false
        })
    };

    componentDidMount() {    
        //this.props.getAssetSettings(this, this.onSuccessGetSettings, this.onFailureGetSettingsError, this.props.assetsList.assetMaint.record.mastertag);
        this.componentUpdate();
    }
    componentDidUpdate(prevProps: Prop) {
        // Fix defect, the componentDidUpdate previusly nevever update the componentUpdate since it always this this.props != this.props
        // It shall check this.props != prevProps
         if ( this.props.assetsList.assetMaint.tabs[2].instance !== prevProps.assetsList.assetMaint.tabs[2].instance) {
            this.componentUpdate();
         }
    }
    componentUpdate() { 
        
        // console.log("before getAssetSettings in the assetWifiClient line 91 this.props.assetsList.assetMaint.record")
        // console.log(this.props.assetsList.assetMaint.record)
        this.props.getAssetSettings(this, this.onSuccessGetSettings, this.onFailureGetSettingsError, this.props.assetsList.assetMaint.record.mastertag);
        // console.log("before formInit(this) in the assetWifiClient line 93 this.props.assetsList.assetMaint.record")
        // console.log(this.props.assetsList.assetMaint.record)
        formInit(this);
        // console.log("before setInitialvalues() in the assetWifiClient line 96 this.props.assetsList.assetMaint.record")
        // console.log(this.props.assetsList.assetMaint.record)
        // Tp1 lite read-only sleep mode show "2 hours" instead of 6450 seconds
        let sleepCycleInSecondsValue = 0; 
        if (this.props.assetsList.assetMaint.tabs[2].allowViewSleepMode &&
            !this.props.assetsList.assetMaint.tabs[2].allowChangeSleepMode) {
                sleepCycleInSecondsValue = 7200; 
        } else {
            sleepCycleInSecondsValue = this.props.assetsList.assetMaint.tabs[2].sleepCycleInSeconds == null?
                0: this.props.assetsList.assetMaint.tabs[2].sleepCycleInSeconds ;
        }

        let {sleepCycleValue, sleepCycleUom} = this.convertSecondsToTimeAndUnit(
            sleepCycleInSecondsValue);
        this.setintialvalues(this.props.assetsList.assetMaint.tabs[2].ssidList, 
            this.props.assetsList.assetMaint.tabs[2].allowViewSleepMode,
            this.props.assetsList.assetMaint.tabs[2].allowChangeSleepMode,
            this.props.assetsList.assetMaint.tabs[2].enableSleepMode,
            sleepCycleInSecondsValue.toString(),
            sleepCycleValue, sleepCycleUom,
            this.props.assetsList.assetMaint.tabs[2].sleepCycleApplied,
            this.props.assetsList.assetMaint.tabs[2].nextWakeUpTime); 
            
        // console.log("After setInitialvalues() in the assetWifiClient line 102 this.props.assetsList.assetMaint.record")
        // console.log(this.props.assetsList.assetMaint.record)

        // console.log("after setInitialValue this state out")
        // console.log(this.state)
        
        if (this.formRef.current != null) { 
                if(this.state.SSIDProviders.length===0 && this.state.viewWiFiStatus)
                {           this.setState({
                    SSIDProviders: [{wifiSSID:"",wifiPassword:""}],
                    })
                }
                else
                {
                    this.formRef.current!.resetFields();    
                    this.formRef.current!.setFieldsValue(this.state.initialValues);
                }
            }      
    };
    onFinish=(values: any)=>{
   
        resetFormErrors(this, this.formRef.current);
        const data = values;
        const ssiddetails: SSID[]=[];
        let ssid:any = {};        
        Object.keys(data).forEach(function(key){
            if(key.slice(0,-1)==="ssidname"){
                ssid.wifiSSID=data[key];}
            if(key.slice(0,-1)==="password"){
                    ssid.wifiPassword=data[key];}
            if(ssid.wifiSSID && ssid.wifiPassword){
                ssiddetails.push(ssid);
                ssid={};
            }
          });

          let targetSleepCycleInSeconds = this.convertTimeAndUnitToSeconds(values.sleepCycleValue, values.sleepCycleUom);
          let targetEnableSleepMode = targetSleepCycleInSeconds > 0 ? true: false; 
          if(!_.isEqual({enableWiFi: this.state.viewWiFiStatus, 
            enableHotspot: this.state.enableHotspot, 
            enableEthernet: this.state.enableEthernet, 
            enableSleepMode: targetEnableSleepMode,
            sleepCycleInSeconds: targetSleepCycleInSeconds,
            sleepCycleApplied: values.sleepCycleApplied, 
            nextWakeUpTime: values.nextWakeUpTime,
            ssidList: ssiddetails,
            ethernetIPAddress: values.ethernetIPAddress} ,
             
             {enableWiFi:this.props.assetsList.assetMaint.tabs[2].enableWiFi, 
                enableHotspot: this.props.assetsList.assetMaint.tabs[2].enableHotspot,
                enableEthernet: this.props.assetsList.assetMaint.tabs[2].enableEthernet, 
                enableSleepMode: this.props.assetsList.assetMaint.record.enableSleepMode, 
                sleepCycleInSeconds: this.props.assetsList.assetMaint.record.sleepCycleInSeconds,
                ssidList: this.props.assetsList.assetMaint.tabs[2].ssidList,
                ethernetIPAddress: this.props.assetsList.assetMaint.tabs[2].ethernetIPAddress})){      
                    
                    let {sleepCycleValue, sleepCycleUom} = this.convertSecondsToTimeAndUnit(targetSleepCycleInSeconds);

                    this.props.setAssetSettings(this, this.onFinishSuccess, this.onFinishFailure, 
                        this.props.assetsList.assetMaint.record.mastertag, 
                         {enableWiFi: this.state.viewWiFiStatus, enableHotspot: this.state.enableHotspot, enableEthernet: this.state.enableEthernet, ssidList: ssiddetails, 
                          enableSleepMode: targetEnableSleepMode, sleepCycleInSeconds: targetSleepCycleInSeconds, sleepCycleValue, sleepCycleUom,
                          sleepCycleApplied: values.sleepCycleApplied, nextWakeUpTime: values.nextWakeUpTime, ethernetIPAddress: values.ethernetIPAddress} 
                     );
                }
                else
                {
                   warningResponse(this, "No changes detected to update Gateway Settings");
                }

    }
    onFinishFailure = (error: any) => { 
        errorResponse(this, error);
    }
    onFinishSuccess=() => {
       successResponse(this, "Gateway settings updated successfully");
    }

    onSuccessGetSettings = (data: any) => {      
        let {sleepCycleValue, sleepCycleUom} = this.convertSecondsToTimeAndUnit(data.sleepCycleInSeconds);
        this.setState({            
            SSIDProviders: data.ssidList,
            enableEthernet: data.enableEthernet,
            enableHotspot: data.enableHotspot,
            viewWiFiStatus: data.enableWiFi,
            viewWiFiPanel: data.enableWiFi,
            wifiActiveKey: (data.enableWiFi)?"1":"0",
            ethernetActiveKey: (data.enableEthernet)?"1":"0",
            sleepModeActiveKey: (data.enableSleepMode)?"2":"3",
            enableSleepMode: data.enableSleepMode || false,
            sleepCycleInSeconds: data.sleepCycleInSeconds || 0,
            sleepCycleValue, sleepCycleUom,
            sleepCycleApplied: data.sleepCycleApplied || false,
            nextWakeUpTime: data.nextWakeUpTime || "",
            ethernetIPAddress: data.ethernetIPAddress? data.ethernetIPAddress: appConfigs.app.defaultIP
        })
        
        let sleepCycleInSecondsValue=0;
        if (this.props.assetsList.assetMaint.tabs[2].allowViewSleepMode &&
            !this.props.assetsList.assetMaint.tabs[2].allowChangeSleepMode) {
                sleepCycleInSecondsValue = 7200; 
        } else {
            sleepCycleInSecondsValue = data.sleepCycleInSeconds == null?
                0: data.sleepCycleInSeconds ;
        }
        this.setintialvalues(data.ssidList, 
            this.props.assetsList.assetMaint.tabs[2].allowViewSleepMode,
            this.props.assetsList.assetMaint.tabs[2].allowChangeSleepMode,
            data.enableSleepMode || false, 
            sleepCycleInSecondsValue.toString(), 
            sleepCycleValue || 0, 
            sleepCycleUom || "second",
            data.sleepCycleApplied || false, 
            data.nextWakeUpTime || "");
    }

    setintialvalues(ssidList: any, 
        allowViewSleepMode: boolean,
        allowChangeSleepMode: boolean, 
        enableSleepMode: boolean, 
        sleepCycleInSeconds: string,
        sleepCycleValue: number,
        sleepCycleUom: string,
        sleepCycleApplied: boolean, 
        nextWakeUpTime: string)
    {
        let initialValues: any = {};
        let sleepCycleValueOverride = sleepCycleValue;
        let sleepCycleUomOverride = sleepCycleUom;
        let sleepCycleInSecondsOverride = sleepCycleInSeconds;  
        if (allowViewSleepMode && !allowChangeSleepMode) {
            // Tp1 lite, hard-coded to 2 hour to customer
            sleepCycleValueOverride=2;
            sleepCycleUomOverride = "hour"
            sleepCycleInSecondsOverride = "7200";
        }
        initialValues = {...initialValues, 
            allowViewSleepMode: allowViewSleepMode,
            allowChangeSleepMode: allowChangeSleepMode, 
            enableSleepMode: enableSleepMode,
            sleepCycleInSeconds: sleepCycleInSecondsOverride,
            sleepCycleValue: sleepCycleValueOverride,
            sleepCycleUom: sleepCycleUomOverride,
            sleepCycleApplied: sleepCycleApplied, 
            nextWakeUpTime: nextWakeUpTime,
            ethernetIPAddress: this.state.ethernetIPAddress
        }

       
        this.setState({initialValues : initialValues,
            SSIDProviders : []});

        this.formRef.current!.resetFields();    
        this.formRef.current!.setFieldsValue(initialValues);
            
        if(ssidList  && ssidList.length > 0){
            ssidList.map((ssid:any, index: number) => {              
             initialValues = {...initialValues, [`ssidname${index}`]: `${ssid.wifiSSID}`};
             initialValues = {...initialValues, [`password${index}`]: `${ssid.wifiPassword}`};
            });            
            this.setState({initialValues : initialValues,
                SSIDProviders : [...ssidList]});
            this.formRef.current!.resetFields();    
            this.formRef.current!.setFieldsValue(initialValues);
         }
 
    }

    onFailureGetSettingsError = (error: any) => { 
        errorResponse(this, error);
    }
    
    AddSSID=()=>
    {  

        let initialValues: any = {
            allowChangeSleepMode: this.state?.initialValues?.allowChangeSleepMode,
            allowViewSleepMode: this.state?.initialValues?.allowViewSleepMode,
            enableSleepMode: this.state?.initialValues?.enableSleepMode,
            nextWakeupTime: this.state?.initialValues?.nextWakeupTime,
            sleepCycleApplied: this.state?.initialValues?.sleepCycleAppied,
            sleepCycleInSeconds: this.state?.initialValues?.sleepCycleInSeconds,
            sleepCycleUom: this.state?.initialValues?.sleepCycleUom,
            sleepCycleValue: this.state?.initialValues?.sleepCycleValue,
            ethernetIPAddress: this.state?.initialValues?.ethernetIPAddress
        };
 
       const {SSIDProviders} = this.state;
       if(SSIDProviders.length === 0){
            let ssid = this.formRef.current?.getFieldValue(`ssidname0`) || ""
            let password = this.formRef.current?.getFieldValue(`password0`) || ""
            SSIDProviders[0] = { wifiSSID: ssid, wifiPassword: password };
       }
       else{
            for(let i=0;i<SSIDProviders.length;i++){           
                let ssid = this.formRef.current?.getFieldValue(`ssidname${i}`) || ""
                let password = this.formRef.current?.getFieldValue(`password${i}`) || ""
                SSIDProviders[i] = { wifiSSID: ssid, wifiPassword: password };
            }
       }
       let newSSID:any = {wifiSSID:"", wifiPassword:""};   
       SSIDProviders.push(newSSID);
       if(SSIDProviders.length > 0){
        SSIDProviders.map((ssid:any, index: number) => {              
            initialValues = {...initialValues, [`ssidname${index}`]: `${ssid.wifiSSID}`};
            initialValues = {...initialValues, [`password${index}`]: `${ssid.wifiPassword}`};
            }); 
        }          
        this.formRef.current!.resetFields();    
        this.formRef.current!.setFieldsValue(initialValues);
        this.setState({
            SSIDProviders: SSIDProviders,
           });

    }
    RemoveSSID=(deletedIndex:any)=>
    {

        const {SSIDProviders} = this.state;        
        let initialValues: any = {
            allowChangeSleepMode: this.state?.initialValues?.allowChangeSleepMode,
            allowViewSleepMode: this.state?.initialValues?.allowViewSleepMode,
            enableSleepMode: this.state?.initialValues?.enableSleepMode,
            nextWakeupTime: this.state?.initialValues?.nextWakeupTime,
            sleepCycleApplied: this.state?.initialValues?.sleepCycleAppied,
            sleepCycleInSeconds: this.state?.initialValues?.sleepCycleInSeconds,
            sleepCycleUom: this.state?.initialValues?.sleepCycleUom,
            sleepCycleValue: this.state?.initialValues?.sleepCycleValue,
            ethernetIPAddress: this.state?.initialValues?.ethernetIPAddress
        };
        for(let i=0;i<SSIDProviders.length;i++){           
            let ssidname = this.formRef.current?.getFieldValue(`ssidname${i}`) || ""
            let password = this.formRef.current?.getFieldValue(`password${i}`) || ""
            SSIDProviders[i] = { wifiSSID: ssidname, wifiPassword: password };
        }
        SSIDProviders.splice(deletedIndex,1);
        SSIDProviders.map((ssid:any, index: number) => {              
            initialValues = {...initialValues, [`ssidname${index}`]: `${ssid.wifiSSID}`};
            initialValues = {...initialValues, [`password${index}`]: `${ssid.wifiPassword}`};
           });
         this.formRef.current!.resetFields();    
         this.formRef.current!.setFieldsValue(initialValues);           
         this.setState({
            initialValues : initialValues,           
            SSIDProviders: SSIDProviders

        })
    }
    // setHotspot=(enabled:any)=>{
    //     if (enabled) {
    //         if(this.state.viewWiFiStatus) {
    //             this.setState({
    //                 triedEnablingHotspot: true,
    //                 triedEnablingEthernet: false,
    //                 triedEnablingWiFi: false,
    //                 isAlertModalOpen: true,
                    
    //             })
    //         }
    //         else {
    //         this.setState({
    //             enableHotspot: true,
    //             viewWiFiStatus: false,
    //             viewWiFiPanel: false,
    //             wifiActiveKey: "0",
    //             SSIDProviders: []
    //         }); 
    //         }
    //       } 
    //       else {
    //         this.setState({
    //             enableHotspot: false,
    //         });   
    // }
    // }

    setEthernet=(enabled:any)=>{
        if (enabled) {
            if(this.state.viewWiFiStatus) {
                this.setState({
                    triedEnablingEthernet: true,
                    triedEnablingHotspot: true,
                    triedEnablingWiFi: false,
                    isAlertModalOpen: true                    
                })
            }
            else {
            this.setState({
                enableEthernet: true,
                enableHotspot: true,
                viewEthernetPanel: true,
                ethernetActiveKey: "1",
                viewWiFiStatus: false,
                viewWiFiPanel: false,
                wifiActiveKey: "0",
                SSIDProviders: []
            });     
          } 
          }
          else {
            this.setState({
                enableEthernet: false,
                enableHotspot: false,
                viewEthernetPanel: false,
                ethernetActiveKey: "0"
            });   
    }
    }

    sleepModeHandleChange=(checked:any)=>{
        this.validateTime();
        this.setState({ enableSleepMode: true });  
        let sleepCycleValue=this.formRef.current!.getFieldValue("sleepCycleValue");
        let sleepCycleUom=this.formRef.current!.getFieldValue("sleepCycleUom");
      //   let {sleepCycleValue, sleepCycleUom} = this.convertSecondsToTimeAndUnit(sleepCycleInSecondsValue);
       let sleepCycleInSecondsValue=this.convertTimeAndUnitToSeconds(sleepCycleValue, sleepCycleUom);
      
        this.setState({
            sleepCycleInSeconds:sleepCycleInSecondsValue.toString(), 
            sleepCycleValue: sleepCycleValue,
            sleepCycleUom: sleepCycleUom        
        });

    }

    showSleepModePanel=(enabled:any)=>{
        let sleepCycleInSecondsValue=0;
        if (enabled) {       
            this.setState({
                sleepModeActiveKey: "2",        
            });    
            
            sleepCycleInSecondsValue=this.props.assetsList.assetMaint.tabs[2].sleepCycleInSeconds!==null
                 ?this.props.assetsList.assetMaint.tabs[2].sleepCycleInSeconds:0;
        
                //  this.validateTime(); 
        } else {
            this.setState({
                sleepModeActiveKey: "3",
            });
        }
        let {sleepCycleValue, sleepCycleUom} = this.convertSecondsToTimeAndUnit(sleepCycleInSecondsValue);
       
        this.setState({ 
            enableSleepMode:enabled,
            sleepCycleInSeconds:sleepCycleInSecondsValue.toString(), 
            sleepCycleValue: sleepCycleValue,
            sleepCycleUom: sleepCycleUom       
        });
        
        this.formRef.current!.setFieldsValue(
            { 
                enableSleepMode:enabled,
                sleepCycleInSeconds:sleepCycleInSecondsValue.toString(), 
                sleepCycleValue: sleepCycleValue,
                sleepCycleUom: sleepCycleUom   
            }
        );         
     
     console.log("after show sleep mode this.formRef: ", this.formRef)
    }
    convertTimeAndUnitToSeconds = (value:number, uom:string) : number => (
        uom === 'hour' ? value * 3600 : ((uom === 'minute') ? value * 60 : value)
    );

    convertSecondsToTimeAndUnit(valueInSec:number) : any{
       let uom="minute";
       let duration = valueInSec;

        if (valueInSec % 3600 === 0) {
            uom = "hour";
            duration = valueInSec / 3600;
       } else if (valueInSec % 60 === 0) {
            uom = "minute";
            duration = valueInSec / 60;
       } else {
            uom = "second";
            duration = valueInSec;
       }
       return {
        sleepCycleValue:duration,
        sleepCycleUom: uom
       };
   };


    validateTimeUnit= (rule: any, value: any, callback: any) => {
        if(value){
          const duration:number = this.formRef.current!.getFieldValue('sleepCycleValue');
          if ((value === 'hour' && duration > 24) ||
               (value === 'minute' && duration > 24*60 ||
               (value === 'second' && duration > 24*3600)) ) {
            callback("Please enter a sleep cycle up to 24 hours");
          } else if (
            (value === 'minute' && duration < 15) ||
            (value === 'second' && duration > 15*60)) {
            callback("Please enter a sleep duration at least 15 minutes");
          } else {
            let durationSecStr = this.convertTimeAndUnitToSeconds(duration, value).toString();
            this.setState({sleepCycleInSeconds: durationSecStr})
            callback();
           } 
        } else {
          callback();
        }
      };

    validateTimeValue= (rule: any, value: any, callback: any) => {
        
        if(value){
          const unit = this.formRef.current!.getFieldValue('sleepCyclelUom');
          if ((unit === 'hour'&& value > 24) ||
              (unit === 'minute'&& value > 24*60) ||
              (unit === 'second' && value > 24*3600)) {
              callback("Please enter a sleep duration up to 24 hours");
          } else if (
            (unit === 'minute' && value < 15) ||
            (unit === 'second' && value > 15*60)) {
            callback("Please enter a sleep duration at least 15 minutes");
          } else {
            let durationSecStr = this.convertTimeAndUnitToSeconds(value, unit).toString();
            this.setState({sleepCycleInSeconds: durationSecStr})
            callback();
           } 
        } else {
          callback();          
        }
      };

    validateTime = () => {          
        this.formRef.current!.validateFields(['sleepCycleValue','sleepCycleUom']);
    };

    showWiFiPanel=(enabled:any)=>{
        if (enabled) { 
            if(this.state.enableEthernet || this.state.enableHotspot) {
                this.setState({
                    triedEnablingWiFi: true,
                    triedEnablingEthernet: false,
                    triedEnablingHotspot: false,
                    isAlertModalOpen: true
                });
            }
            else
            {    
            this.setState({
                viewWiFiStatus: true,
                viewWiFiPanel: true,
                wifiActiveKey: "1",
                enableEthernet: false,
                enableHotspot: false,           
            });    
          } 
          }
          else {
            this.setState({
                viewWiFiStatus: false,
                viewWiFiPanel: false,
                wifiActiveKey: "0",
            });
          }
    }

    utcStringToLocalTime=(utcStr: string) => new Date(utcStr).toLocaleString();

    render() {

        const { assetMaint } = this.props.assetsList;      
        const ssidProviders=this.state.SSIDProviders;
        const connected_SSID = this.props.assetsList.assetMaint.tabs[2].connected_SSID;
        
        let sleepNote = "";
        // if (this.state.sleepCycleApplied) {
        //     sleepNote = "Sleep setting detected. "
            
        //     if (this.state.nextWakeUpTime !== "") {
                
        //         let nowTime = new Date(); 
        //         if (new Date(this.state.nextWakeUpTime).getTime() >= nowTime.getTime()) {
        //             sleepNote = "Next Wake-up: "  + this.utcStringToLocalTime(this.state.nextWakeUpTime); 
        //         } else {
        //             sleepNote = "Last Wake-up: "  + this.utcStringToLocalTime(this.state.nextWakeUpTime);
        //         }
        //     } 
        // } else {
        //     sleepNote = "Sleep Setting Undetected";
        // }
        sleepNote = sleepNote + "Sleep mode allows to periodically send select signals from gateway to cloud, even when ignition is OFF, and gateway is connected to direct power. "
       
        let allowChangeSleepModeConfig = assetMaint.record.allowChangeSleepMode;
        let allowViewSleepModeConfig = assetMaint.record.allowViewSleepMode;
       
        console.log("before rendering this.state.sleepmodeactivekey",this.state.sleepModeActiveKey)
        return (
            <>
                {this.state.formSuccess && !this.state.formWarning && !this.state.formError && <SuccessAlert data-id="assets-maint-info-success-message" message={this.state.formSuccessMessage}/>}
                {this.state.formWarning && !this.state.formError && !this.state.formError && <WarningAlert data-id="assets-maint-info-success-message" message={this.state.formWarningMessage}/>}
                {this.state.formError && !this.state.formWarning && !this.state.formSuccess && <ErrorAlert data-id="assets-maint-info-error-message" message={this.state.formErrorMessage}/>}
                
                <Row gutter={8} style={{marginTop: 15}}>
                    <Col offset={1} span={23}>
                        <Form
                            id="assetMaintSettingsForm"
                            ref={this.formRef}
                            onFinish={this.onFinish}
                        >   <Row gutter={8}>
                        <Col span={24}>     
                           <span style={{color:'#0052CC',fontSize:'16px'}}>{"Minimum Requirements: TP1 2.4.1.5 or later"}</span>
                           </Col>
                           </Row>
                           <br/>
                            <Row gutter={8}>
                                <Col span={24}>
                            {
                                (assetMaint.record.status || "") !== "whitelisted" &&
                                (assetMaint.record.allowWifiClient) &&
                                <>
                                <br/>
                                 <Collapse activeKey = {this.state.wifiActiveKey}>
                                <Panel 
                                header="Enable Wi-Fi Client"
                                key="1"
                                extra={                                    
                                    <div>                                        
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}                                           
                                            onChange = {this.showWiFiPanel} 
                                            checked = {this.state.viewWiFiStatus} />
                                    </div>                                    
                                }
                                >
                                <Row gutter={8}>
                                    <Col span={22}>&nbsp;</Col>
                                    <Col span={2}>
                                        <Button type="primary" onClick={this.AddSSID} disabled={ssidProviders.length>4} icon={<PlusOutlined></PlusOutlined>}></Button></Col>                                    
                                </Row>
                                <br/>
                                {
                                    ssidProviders.length>0 ?
                                    ssidProviders.map((ssidProvider:any,index: any)=>
                                    ssidProvider!==undefined?(<WiFiConnect key={"provider"+index} connected={(ssidProvider.wifiSSID === connected_SSID) ? true : false} remove={this.RemoveSSID} Name= {ssidProvider.wifiSSID} Password={ssidProvider.wifiPassword} index={index}></WiFiConnect>)
                                    :(<WiFiConnect key={index} index={index} Name= {ssidProvider.wifiSSID} Password={ssidProvider.wifiPassword} remove={this.RemoveSSID} connected={false} ></WiFiConnect>)
                                    )
                                    :
                                    (<WiFiConnect key={"provider"+ssidProviders.length}  remove={this.RemoveSSID} index={ssidProviders.length}></WiFiConnect>)
                                }
                                </Panel>
                                </Collapse>                                
                                </>                                     
                            } 
                            </Col>
                            </Row>
                            {/* <br/>
                            { //false &&   
                            <Row gutter={8}>
                                <Col span={24}>
                                <Form.Item 
                                        name="enableHotspot"
                                        label="Enable Hotspot" 
                                    >
                                    <Switch
                                            checkedChildren={<CheckOutlined style={{color: "green"}}/>}
                                            unCheckedChildren={<CloseOutlined />}                                           
                                            onChange = {this.setHotspot} 
                                            checked = {this.state.enableHotspot} />                                    
                                </Form.Item>
                                </Col> 
                                </Row>
                            } */}
                            {/* { //false && 
                                <Row  gutter={8}>
                                <Col span={24}>
                                <Form.Item
                                        name="enableEthernet"
                                        label= "Enable Ethernet"
                                    >                               
                                    <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}                                           
                                            onChange = {this.setEthernet} 
                                            checked = {this.state.enableEthernet} />
                                </Form.Item>
                                </Col> 
                            </Row>                      
                            }       */}

                            <br/>
                            <Row gutter={8}>
                                <Col span={24}>
                            {
                                (assetMaint.record.status || "") !== "whitelisted" &&
                                <>
                                <br/>
                                 <Collapse activeKey = {this.state.ethernetActiveKey}>
                                <Panel 
                                header="Enable Internet Sharing"
                                key="1"
                                extra={                                    
                                    <div>                                        
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}                                           
                                            onChange = {this.setEthernet} 
                                            checked = {this.state.enableEthernet} />
                                    </div>                                    
                                }
                                >
                                <Row gutter={8}>
                                    <Col span={24}>
                                    <Form.Item 
                                            name="ethernetIPAddress"
                                            label="IP Address" 
                                            rules={[
                                                {
                                                    required: this.state.enableEthernet,
                                                    message: "Enter IP address"
                                                },
                                                {
                                                  pattern: /^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\.(?!$)|$)){4}$/,
                                                  message: "Enter a valid IP address"
                                                }
                                              ]}
                                             >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <br/>
                               
                                </Panel>
                                </Collapse>                                
                                </>                                     
                            } 
                            </Col>
                            </Row>

                            <br/>
                            <Row gutter={8}>
                            <Col span={24}>
                            { 
                            (assetMaint.record.status || "") !== "whitelisted" &&
                            (assetMaint.record.allowViewSleepMode) &&
                            //  this.props.context.appContext.orgId === 'c6adcb40-be92-11e6-9ed6-a5bc9cb5279b' &&
                            <Row gutter={8}>
                            <Col span={24}> 
                           <>
                            <span style={{color:'#0052CC',fontSize:'16px'}}></span>
                             <Collapse activeKey = {this.state.sleepModeActiveKey}>
                            <Panel 
                                header=
                                {(<span>
                                    Enable sleep mode&nbsp;
                                <Tooltip title={sleepNote}>
                                    <InfoCircleOutlined />
                                </Tooltip>
                                </span>)} 
                                key="2"
                                extra={     
                                    <div>                                        
                                    <Switch
                                        disabled={!allowChangeSleepModeConfig}
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}                                           
                                        onChange = {this.showSleepModePanel}     
                                        checked = {this.state.enableSleepMode} />
                                    </div>                                    
                                }
                            >
                            <br/>
                            <Row gutter={8}>
                                <Col span={12}>
                                        <Form.Item 
                                            name="sleepCycleValue"
                                            label="Sleep duration" 
                                             >
                                            <InputNumber onChange={this.sleepModeHandleChange}
                                                min={0}
                                                max={86400}
                                                pattern="[0-9]*"
                                                disabled={ !assetMaint.record.allowChangeSleepMode} 
                                                style={{ width: '50%' }} 
                                                precision={0} 
                                                />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item 
                                            name="sleepCycleUom"
                                            rules={[
                                            {required: this.state.allowViewSleepMode, message: appConfigs.errors.fieldErrors.valueRequired },
                                            {validator: this.validateTimeUnit }
                                        ]} 
                                    >
                                         <Select onChange={this.sleepModeHandleChange}
                                            disabled={!assetMaint.record.allowChangeSleepMode}>
                                            <Option key="minute" value="minute">Minutes</Option>
                                            <Option key="hour" value="hour">Hours</Option>
                                        </Select> 
                                        </Form.Item>
                                    </Col>
                                    {this.state.sleepCycleApplied &&
                                    <Col span={4}>
                                        <Form.Item
                                            name="sleepCycleApplied"    
                                        ><CheckCircleOutlined style={{color:"green"}} />
                                        </Form.Item>
                                    </Col>}
                                </Row>
                                </Panel>
                                </Collapse>   
                            </>
                        </Col>
                        </Row>
                    }
                            
                        <Modal title= {this.state.triedEnablingEthernet?'Enable Ethernet': this.state.triedEnablingHotspot?'Enable Hotspot': this.state.triedEnablingWiFi? 'Enable Wifi': 'Warning'} visible={this.state.isAlertModalOpen} onOk={this.handleOk} onCancel={this.handleCancel}>
                            <div>
                                {this.state.triedEnablingWiFi? "Enabling Wi-Fi Client will disable Ethernet/Hotspot. Want to proceed? " :
                                (this.state.triedEnablingEthernet || this.state.triedEnablingHotspot)? "Enabling Ethernet/Hotspot will disable Wi-Fi Client. Want to proceed? ":""}
                            </div>
                        </Modal>                       
                    </Col>
                    </Row>
                  </Form>
                  </Col>
                    </Row>
                   
                
            </>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        assetsList: state.assetsReducer.assetsState.assetsList,
        permissions: state.contextReducer.data
    };
};

export default connect(
    mapStateToProps,
    actions
)(AssetWiFiClient);


