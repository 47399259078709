import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function orgMaintAdminsSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "ORG_MAINT_ADMIN_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}   

export function orgMaintAdminsSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "ORG_MAINT_ADMIN_SET_SEARCH", searchText: searchText });
    }
}   

export function orgUsersGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: string,
    role: string,
    page: number = 1,
    searchText?: string
) {
    return async (dispatch: any) => { 
        //ops-2680: added login user role to request parameter "role", because a non-admin user should not see "admin" role in the "role" dropdown in "administrators" tab.  
        Axios.get(
            appConfigs.server.URL + "/ui/api/orgs/" + orgId + "/users", 
            {
                responseType: "json",        
                headers: {},
                params: {
                    allData: true,
                    role: role,
                    searchText: searchText,
                    page: page,
                    pageSize: appConfigs.tables.pageSize
                }
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    
                    callbackFnSuccess(response.data.data.orgUsers);
                }
                dispatch({ type: "ORG_MAINT_ADMIN_ORG_USERS_GET_SUCCESS", payload: {data: response.data.data.orgUsers, page: page} });
            }
        )
        .catch(function (error) {
            errorResponse(me, error, false);
            if (callbackFnFailure != null) {
                callbackFnFailure(error);
            }
        });            
    };
}    

export function orgUserRole(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgUserId: string,
    role: string
) {
    return async (dispatch: any) => {
        Axios.put(
            appConfigs.server.URL + "/ui/api/orgs/user/" + orgUserId + "/" + role,
            {},
            {
                responseType: "json"
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.orgUsers, orgUserId, role);           
                }
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

export function usersAdministeredGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    userId: string,
    searchText: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/users/okta", {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText,
                    administered: true,
                    page: 1,
                    pageSize: appConfigs.tables.pageSize
                }
            }
        )
        .then(response => {                       
                let data = response.data.data.users === null ? [] : response.data.data.users;
                data.filter((user:any) => {
                    return user.userId !== userId;
                })
                .map((user:any) => ({
                    text: user.fullName,
                    textExtra: user.orgHomeName,
                    value: user.userId
                }));
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "ORG_MAINT_ADMIN_USERS_ADMINSTERED_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function usersAdministeredMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgs: any,
    users: any,
    role:string,
    applyAll:boolean,
    page: number
) {
    return async (dispatch: any) => {        
        Axios.post(
            appConfigs.server.URL +  "/ui/api/orgs/user",
            {
                orgs: orgs,
                users: users,
                role: role,
                applyAll: applyAll
            },
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.orgUsers);
                }
                dispatch({ type: "ORG_MAINT_ADMIN_USERS_ADMINSTERED_MAINT" });
            }
        )
        .catch(function (error) {
            errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
    }
}
