import React, { useState,useEffect } from "react";
import { Row, Col, Tooltip, Tabs, Card } from "antd";
import BackNavigation from "../../../utils/Components/BackNavigation";
import { connect } from "react-redux";
import * as actions from "./actions";
import Meta from "antd/lib/card/Meta";
import Icon, {
    FilePdfOutlined} from "@ant-design/icons";
const { TabPane } = Tabs;
interface Props 
{
   getUserGuide: Function,
   setTab: Function,
   context: any,
   userguide: any,
   permissions: any,
}
const UserGuide: React.FC<Props> = (props) => 
{
   const [playingUrl, setPlayingUrl] = useState<string | null>(null);
   const [numPages, setNumPages] = useState<number>();
   const [pageNumber, setPageNumber] = useState<number>(1);

   useEffect(() => {
    props.getUserGuide();
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const onTabChange = (key: string) => {
       props.setTab(key)
   }
   const onDocumentLoadSuccess=({ numPages }: { numPages: number }): void=> {
    setNumPages(numPages);
  }
 
   return (<div className="layout-content">
            <Row gutter={8}>
                <Col xs={24} lg={14}>
                    <div className="content-header page-title">
                        <div style={{ display: "flex" }}>
                            <Tooltip title="Go To Previous Page">
                                <BackNavigation />
                             </Tooltip>
                                <span>User Guide</span>
                         </div>
                    </div>
                </Col>
            </Row>
            <div className="mt-15">
                <Tabs activeKey={props.userguide.activeTab} size="large" type="card" onChange={onTabChange}>
                <TabPane tab="All User Guides" key="1">                    
                <ul style={{ listStyleType: 'disc', padding:'5px' }}>
                        {props.userguide.userguideList.map((userguidedata:any, index:number) => (
                            userguidedata.category === "Userguide" && (
                    <li key={userguidedata.id} style={{ marginBottom: '5px',fontSize: '16px' }}>
                    <a href={userguidedata.link} target="_blank" rel="noopener noreferrer">
                    <FilePdfOutlined style={{ margin: '15px', fontSize: '180%' }} />
                                {userguidedata.title}
                    </a>
                    </li>
                            )
                        ))}
                    </ul>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
}
const mapStateToProps = (state: any) => {
   return {
       permissions: state.contextReducer.data,
       context: state.contextReducer.context,
       userguide: state.userguideReducer.userguideState.userguide,
   };
};
export default connect(
   mapStateToProps,
   actions
)(UserGuide);