import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";

export function iqanFileUploadMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string,
    record: any
) {
    return async (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL + "/ui/api/templates/" + templateId + "/iqan-telematic-file-process",
            record,
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(record);
                }
            }
        )
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}