import React from "react";
import { Form, Row, Col, Table, Collapse, Select, Tooltip, Button, Space, Input, message } from 'antd';
import { QuestionCircleOutlined } from "@ant-design/icons";
import { FormInstance } from 'antd/lib/form';

import { connect } from "react-redux";
import * as actions from "../actions";

import { loadingIndicatorStart } from "../../../utils/commonHelpers";

const { Option } = Select;
const { Panel } = Collapse;
const { Search } = Input;

interface Prop {
    orgMaintAdminsSetPagination: Function,
    orgUsersGet: Function,
    orgUserRole: Function,
    usersAdministeredGet: Function,
    usersAdministeredMaint: Function,
    orgMaintAdminsSetSearch: Function,
    orgsListRefreshOnUpdate: Function,
    context: any,
    orgsList: any,
    iscreate: boolean,
    isread: boolean,
    isupdate: boolean
}

class OrgMaintAdmins extends React.Component<Prop, {}> {  
   
    formRef = React.createRef<FormInstance>();

    componentDidMount() {
        this.props.orgMaintAdminsSetPagination(this.onPaginationChange);
        this.componentUpdate();
    }

    componentDidUpdate(prevProps: Prop) {
        if (this.props.orgsList.orgMaint.instance !== prevProps.orgsList.orgMaint.instance) {
            this.componentUpdate();
        }
    }

    componentUpdate() {
        if ((this.props.orgsList.orgMaint.record.orgId || "") !== "") {
            //ops-2680
            this.props.orgUsersGet(this, null, null, this.props.orgsList.orgMaint.record.orgId, this.props.context.appContext.roleName, 1);
        }
    }

    onPaginationChange = (page: number) => {
        //ops-2680
        this.props.orgUsersGet(this, null, null, this.props.orgsList.orgMaint.record.orgId, this.props.context.appContext.roleName, page, this.props.orgsList.orgMaint.tabs[2].searchText);
    };

    roleChange = (role:string, record:any) => {           
        const userAutoChange = this.props.context.appContext.userId === record["data-user-id"] && role !== "admin";        
        this.props.orgUserRole(this, this.roleChangeSuccess(userAutoChange), null, record["data-id"], role);        
    };

    roleChangeSuccess = (userAutoChange: boolean) => {
        //ops-2680    
        this.props.orgUsersGet(this, null, null, this.props.orgsList.orgMaint.record.orgId, this.props.context.appContext.roleName, this.props.orgsList.orgMaint.tabs[2].tableConfig.pagination.current, this.props.orgsList.orgMaint.tabs[2].searchText);       
        message.success("User has been successfully updated");
        if (userAutoChange || false) {
            loadingIndicatorStart();
            setTimeout(function () {
                window.location.href = "/map"; 
            }, 500);  
        }
    };

    resetUsers = () => { 
       this.formRef.current?.resetFields();      
    }

    onUsersSearch = (value:string) => {
        this.props.usersAdministeredGet(this, null, null, this.props.context.user.userId, value);
    };

    onUsersChange = (value:string) => {
    };

    addUser = () => {   
        let form =  this.formRef.current;
        this.props.usersAdministeredMaint(
            this, 
            this.addUserSuccess, 
            null, 
            [this.props.orgsList.orgMaint.record.orgId],
            (form?.getFieldValue("userId") || null), 
            "admin", 
            false,
            this.props.orgsList.orgMaint.tabs[2].tableConfig.pagination.current
        );
    };
    
    addUserSuccess = (data:any) => {    
        this.resetOrgs();
        this.props.orgsListRefreshOnUpdate();
        //ops-2680
        this.props.orgUsersGet(this, null, null, this.props.orgsList.orgMaint.record.orgId, this.props.context.appContext.roleName, this.props.orgsList.orgMaint.tabs[2].tableConfig.pagination.current, this.props.orgsList.orgMaint.tabs[2].searchText);
        message.success("Action have been successfully completed");
    }

    resetOrgs = () => { 
        this.formRef.current?.resetFields();      
    }
 
    onSearch = (searchText: string) => {   
        this.props.orgMaintAdminsSetSearch(searchText || "");    

        const me = this;
        setTimeout(function(){
            //ops-2680
            me.props.orgUsersGet(me, null, null, me.props.orgsList.orgMaint.record.orgId, me.props.context.appContext.roleName, me.props.orgsList.orgMaint.tabs[2].tableConfig.pagination.current, me.props.orgsList.orgMaint.tabs[2].searchText);       
        }, 100);      
    }

    onSearchChange = (e: any) => {
        let value = e.target.value;
        this.props.orgMaintAdminsSetSearch((value || ""));
    }

    columns = [
        {
            title: "Name",
            dataIndex: "userFullName",
            key: "userFullName",
            render: (text: any, record: any) => (
                <>
                    <strong>{record.userFullName}</strong>
                    &nbsp;&nbsp;
                    <br/>
                    <span>{record.userOrgHomeName}</span>
                </>
            )
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            width: "180px",
            render: (text: any, record: any) => (
                <>
                    {
                        (record.contextUserIsOrgAdmin || this.props.iscreate || this.props.isupdate) &&
                        <Select 
                            data-id="orgs-maint-subscriptions-role"
                            defaultValue={record.role}
                            onChange={this.roleChange}
                            key={"role-" + record.recordNo}
                        >
                            <Option key={"user-" + record.orgUserId} data-id={record.orgUserId} data-user-id={record.userId} value="user">User</Option>
                            <Option key={"guest-" + record.orgUserId} data-id={record.orgUserId} data-user-id={record.userId} value="guest">Guest</Option>
                            <Option key={"admin-" + record.orgUserId} data-id={record.orgUserId} data-user-id={record.userId} value="admin">Admin</Option>
                            <Option key={"no-access-" + record.orgUserId} data-id={record.orgUserId} data-user-id={record.userId} value="no-access" disabled={record.isHomeOrg}>No Access</Option>
                        </Select>     
                    }              
                    {
                        //!record.contextUserIsOrgAdmin &&
                        (!this.props.iscreate || !this.props.isupdate || this.props.isread) &&
                        <span>{record.role}</span>   
                    }
                </>
            )
        }
    ];

    render() {

        const { user:contextUser } = this.props.context;
        const { tableConfig, tableData, usersAdministered, searchText } = this.props.orgsList.orgMaint.tabs[2];

        const tablecolumns = [
            {
                title: "Name",
                dataIndex: "userFullName",
                key: "userFullName",
                render: (text: any, record: any) => (
                    <>
                        <strong>{record.userFullName}</strong>
                        &nbsp;&nbsp;
                        <br/>
                        <span>{record.userOrgHomeName}</span>
                    </>
                )
            },
            {
                title: "Role",
                dataIndex: "role",
                key: "role",
                width: "180px",
                render: (role: string, record: any) => (
                    <>
                        
                        {
                           (record.contextUserIsOrgAdmin || this.props.iscreate || this.props.isupdate) &&
                            <Select 
                                data-id="users-maint-info-home-org-role"
                                value={record.role}
                                style={{ width: 180 }}
                                showSearch
                                showArrow={true}
                                allowClear                           
                                optionFilterProp="children"
                                className="select-before" 
                                onChange={this.roleChange}                            
                                key={"role-" + record.recordNo}
                            >
                            {JSON.parse(record.roles) !== null && JSON.parse(record.roles).map((recordrole:any, index:number) => (
                                <Option key={record.orgUserId} data-id={record.orgUserId} data-user-id={record.userId} value={recordrole.role_name}>
                                        {recordrole.role_name}
                                </Option>
                            ))}
                        </Select>  
                        }
                    </>
                )
            }
        ];

        const header = (
            <>
                <span>Add Organization Administrators</span>
                <Tooltip title="Assign users from any organization you have access to">
                    <QuestionCircleOutlined />
                </Tooltip>
            </>
        );

        return (         
            <div data-id="orgs-maint-subscriptions-add-admin"> 
                {
                    contextUser.contextUserIsAdmin &&
                    <Collapse bordered={false} defaultActiveKey={['0']} className="auto-close">
                        <Panel header={header} key="1">
                            <Form 
                                layout="vertical"
                                ref={this.formRef}
                                name="add" 
                            >
                                <Row gutter={16}>
                                    <Col span={23} offset={1}>
                                        <Form.Item
                                            name="userId"
                                        >
                                            <Select 
                                                data-id="orgs-maint-subscriptions-user-id"
                                                mode="multiple"
                                                showSearch
                                                placeholder="Select a user (start typing name)"
                                                defaultActiveFirstOption={false}
                                                showArrow={false}
                                                filterOption={false}
                                                onSearch={this.onUsersSearch}
                                                onChange={this.onUsersChange}
                                                notFoundContent={null}
                                                allowClear
                                            >
                                                {usersAdministered.map((record:any, index:number) => (
                                                    <Option key={record.userId} value={record.userId}>                                                
                                                        <div className="demo-option-label-item">
                                                            <strong>{record.fullName}</strong>
                                                            <span role="img">
                                                                &nbsp;&nbsp;{record.orgHomeName}
                                                            </span>
                                                        </div>
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>                
                                </Row>    
                                <Space size="middle">
                                    <Form.Item 
                                        shouldUpdate={true}>                                            
                                        {() => (
                                            <Button
                                                data-id="orgs-maint-subscriptions-add-admin-reset-button"
                                                type="default"
                                                htmlType="submit"
                                                onClick={this.resetUsers}
                                                disabled={            
                                                    !(
                                                        this.formRef.current?.getFieldValue("userId") 
                                                    )
                                                }                                   
                                            >
                                                Reset
                                            </Button>
                                        )}
                                    </Form.Item>
                                    <Form.Item 
                                        shouldUpdate={true}>                                            
                                        {() => (
                                            <Button
                                                data-id="orgs-maint-subscriptions-add-admin-submit-button"
                                                type="default"
                                                htmlType="submit"
                                                onClick={this.addUser}
                                                disabled={
                                                    !(
                                                        (this.formRef.current?.getFieldValue("userId") || "") !== "" 
                                                    )   
                                                }                                   
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </Form.Item>
                                </Space>
                            </Form>
                        </Panel>            
                    </Collapse>
                }

                {
                     (
                        ((searchText || "") === "" && tableData && (tableData.length || 0) > 0) ||
                        ((searchText || "") !== "") || this.props.isread
                    ) &&
                    <Search
                        data-id="orgs-maint-subscriptions-search-field"
                        placeholder="Search by user name"
                        enterButton="Search"
                        size="middle"
                        allowClear
                        onSearch={this.onSearch}
                        onChange={this.onSearchChange}
                        value={searchText}
                    /> 
                } 
                {
                    this.props.isread &&
                    <Table 
                         data-id="orgs-maint-subscriptions-data"
                        {...tableConfig}
                        rowKey={(record) => record.orgUserId}                                
                        columns={tablecolumns} 
                        dataSource={tableData}
                    />
                }  
                
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        orgsList: state.orgsReducers.orgsState.orgsList
    };
};

export default connect(
    mapStateToProps,
    actions
)(OrgMaintAdmins);