import React, { useEffect, useState } from "react";
import { Form, Select, Tooltip, Button } from 'antd';
import { MinusCircleOutlined } from "@ant-design/icons";
import { Rule, Signal, SignalCollection, Template } from "../../models";
import { SelectorList } from "../../WidgetEditForm";
import { InfoCircleOutlined } from '@ant-design/icons'
import BooleanSelectorList from "../../WidgetEditForm/BooleanSelectorList";
import { groupObjsByValue } from "../../../../utils/commonHelpers";

const { Option, OptGroup } = Select;

type Value = string;
type ValueArray = Array<Value | undefined>;

interface SignalValue {
  id: Value;
  label: string;
  color: string;
  value: string;
}

interface Selectable {
  value: Value;
  label: string;
  key?: string;
  collectionName?: string;
}

interface Props {
  max?: number;
  signalCollections: SignalCollection[];
  signals: Signal[];
  rules: Rule[];
  existingSignals?: ValueArray;
  selectedGPSSignalsEdit?: string[];

}

const EventsLocationSignalSelectorList: React.FC<Props> = (props) => {

  const { rules, existingSignals, signalCollections, selectedGPSSignalsEdit } = props;
  const boolLabels = ["ON", "OFF"];
  
  let signalArray: Signal[];
  signalArray =
    props.signals.filter(
      (s) =>
        s.name.toLowerCase().indexOf("longitude") === -1  &&
        s.name.toLowerCase().indexOf("latitude") === -1
        // && rules?.some((r:any) => r.signalId == s.id)
    ) || [];
  
  
  const colorSelectable = signalArray.map((s) => ({
    id: s.id,
    label: s.name,
    color: s.color,
    value: s.id,
  }));

  const selectableSignals = signalArray.map((s) => ({
    value: s.id,
    label: s.name,
  }) as Selectable);


  let preSelectedSignalArray: Signal[];
  preSelectedSignalArray = 
  props.signals.filter(
    (s) =>
    s.name.toLowerCase().indexOf("longitude") !== -1  ||
    s.name.toLowerCase().indexOf("latitude") !== -1 
    ) || [];
  
  const GPSSelectableSignals = preSelectedSignalArray.map((s:any) => {
    let collectionName = '';
    signalCollections.map((collection, i) => {
      collection.signals.map((signal, x) => {
        if (s.id == signal.id) {
          collectionName = collection.name
        }
      })
    })

    return {
      id: s.id,
      value: s.id,
      label: s.name,
      key: s.id,
      collectionName: collectionName
    }

  })

  const [selectedGPSSignals, setSelectedGPSSignals] = useState<string[]>([])
  const [preSelectedSignals, setPreSelectedSignals] = useState<any[]>(groupObjsByValue(GPSSelectableSignals.filter((o: any) => !selectedGPSSignals.some((s) => s == o.value)), 'collectionName'))

  useEffect(() => {

    setPreSelectedSignals(groupObjsByValue(GPSSelectableSignals.filter((o: any) => !selectedGPSSignals.some((s) => s == o.value)), 'collectionName'))
  }, [selectedGPSSignals])

  const filteredOptions = (selectedGPSSignal: string, selectNumber: number) => {
    
    setSelectedGPSSignals(prevState => {

      if (prevState.length == 2 && selectNumber == 1) {
        return [prevState[1], selectedGPSSignal]
      } else if (prevState.length == 2 && selectNumber == 2) {
        return [prevState[0], selectedGPSSignal]
      } else if (prevState.length == 1 && selectNumber == 1) {
        return [selectedGPSSignal]
      } else if (prevState.length == 1 && selectNumber == 2) {
        return [prevState[0], selectedGPSSignal]
      } else {
        return [selectedGPSSignal]
      }
      
    });

  };

  return (

    <div style={{ marginTop: '20px' }}>

      <Form.Item 
        label="GPS Signals"
        name="preselectedSignals"
          required
          rules={[
            {
              validator(rule, value) {
                return (!!(selectedGPSSignals && selectedGPSSignals.length == 2) || !!(value && value.length == 2))
                  ? Promise.resolve()
                  : Promise.reject("GPS signals (Longitude/Latitude) must be specified")
              },
            },
          ]}
      >
          <Form.Item
            name={["preselectedSignals", 0]}
            initialValue={selectedGPSSignals[0]}
          >
            <Select
              value={selectedGPSSignals[0]}
              placeholder="Select Latitude and Longitude Signal"
              onChange={(v) => filteredOptions(v,1)}
              style={{ width: '100%' }}
              optionFilterProp={"label"}
            >
              {Object.values(preSelectedSignals).map((signals:any, key:number) => (
                <OptGroup key={key} label={signals[0].collectionName}>
                  {signals.map((signal: any) => (
                    <Option key={`${signal.value}-${key}`} value={signal.value}>
                      {signal.label}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={["preselectedSignals", 1]}
            style={{ width: '100%', marginTop: '10px' }}
            initialValue={selectedGPSSignals[1]}
            
          >
            <Select
              value={ selectedGPSSignals[1] }
              placeholder="Select Latitude and Longitude Signal"
              onChange={(v) => filteredOptions(v, 2)}
              optionFilterProp={"label"}
              
            >
              {Object.values(preSelectedSignals).map((signals:any, key:number) => (
                <OptGroup key={key} label={signals[0].collectionName}>
                  {signals.map((signal: any) => (
                    <Option key={`${signal.value}-${key}`} value={signal.value}>
                      {signal.label}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
        
          </Form.Item>
      </Form.Item>

      <Form.Item
        label={<span>Signals </span>}
        name="signals"
        required
        rules={[
          {
            validator(rule, value) {
              return !value || value?.some((v: any) => v.id == '') || value?.filter((v: any) => !!v.id).length < 1
                ? Promise.reject("Signal selection cannot be empty")
                : Promise.resolve();
            },
          },
        ]}
      >

        <BooleanSelectorList
          options={colorSelectable}
          maxSelected={props.max}
          valueLabel="Signal"
          rules={[]}
          labels= {boolLabels} />

      </Form.Item>
    </div>

  );
};

export default EventsLocationSignalSelectorList;