import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function rolesState(state = initialState, action:any) {
    let data:any = null,
    payload:any;

    switch (action.type) {

        case "ORG_ROLE_GET_SUCCESS":                   
            data = action.payload.data;            
            return immerProduce(state, draft => {               
                //draft.roleList.roleMaint.orgRoles.data.roles = data.data.orgRolePermissions.shift();
                draft.roleList.roleMaint.orgRoles.data.roles = data.data.orgRolePermissions.filter((res: { roleId: number; }) => res.roleId !== 1);
                draft.roleList.tableRolesData =  data.data.orgRolePermissions.filter((res: { roleId: number; }) => res.roleId !== 1);
                draft.roleList.tableRolesConfig.pagination.total = (data.data.orgRolePermissions.length || 0) > 0 ? data.data.orgRolePermissions[0].totalRecords : 0;
                draft.roleList.tableRolesConfig.pagination.current = action.payload.page;
            });
        case "ROLE_PERMISSIONS_GET_SUCCESS":            
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.roleList.refreshOnUpdate = false;
                draft.roleList.tableData = data;
                draft.roleList.tableConfig.pagination.total = (data.length || 0) > 0 ? data[0].totalRecords : 0;
                draft.roleList.tableConfig.pagination.current = action.payload.page;
            });
        case "ORG_ROLE_PERMISSIONS_GET_SUCCESS":            
        data = action.payload.data;
        return immerProduce(state, draft => {
            draft.roleList.refreshOnUpdate = false;
            draft.roleList.tableOrgData = data.data.orgRolePermissions;
            draft.roleList.tableOrgConfig.pagination.total = (data.data.orgRolePermissions.length || 0) > 0 ? data.data.orgRolePermissions.permissions[0].totalRecords : 0;
            draft.roleList.tableOrgConfig.pagination.current = action.payload.page;
        });
        case "ROLE_MAINT_OPEN":
            return immerProduce(state, draft => {
            const
                payload = action.payload,
                instance = Math.random();
                
            draft.roleList.roleMaint.instance = instance;
            draft.roleList.roleMaint.width = payload.width;
            draft.roleList.roleMaint.visible = true;
            draft.roleList.roleMaint.title = payload.title;
            draft.roleList.roleMaint.mode =payload.mode;
            draft.roleList.roleMaint.record = payload.record;
            draft.roleList.roleMaint.activeTab = "1";            
            draft.roleList.roleMaint.tabs[0].disabled = false;
            draft.roleList.roleMaint.tabs[0].instance = instance;
            draft.roleList.roleMaint.tabs[0].roleCopy!.data = [];
            
        });
        case "ROLE_MAINT_CLOSE":
            return immerProduce(state, draft => {
                // draft.roleList.roleMaint.tabs[0].searchText = "";
                draft.roleList.roleMaint.visible = false;
            });
        case "ROLE_PERMISSION_MAINT_SUCCESS":
            data = action.payload.data;            

            return immerProduce(state, draft => {                
                draft.roleList.roleMaint.record = [];
                draft.roleList.roleMaint.record = data.data.orgRolePermissions[0];
                draft.rolePermissionList.rolePermissionMaint.successData = data.data.orgRolePermissions[0];
                //draft.roleList.roleMaint.title = "Edit Role :: " + data.data.orgRolePermissions[0].roleName;
            });
        case "GET_ORG_ROLE_MAINT_GET_SUCCESS":            
        data = action.payload.data;            
        return immerProduce(state, draft => {
            draft.roleList.roleMaint.roles.data.roles = data.data.orgRoles;
        });
        case "ROLE_PERMISSION_MAINT_OPEN":
            return immerProduce(state, draft => {
            const
                payload = action.payload,
                instance = Math.random();
                
            draft.rolePermissionList.rolePermissionMaint.instance = instance;
            draft.rolePermissionList.rolePermissionMaint.width = payload.width;
            draft.rolePermissionList.rolePermissionMaint.visible = true;
            draft.rolePermissionList.rolePermissionMaint.title = payload.title;
            draft.rolePermissionList.rolePermissionMaint.record = payload.record;
            draft.rolePermissionList.rolePermissionMaint.activeTab = "1";            
            draft.rolePermissionList.rolePermissionMaint.tabs[0].disabled = false;
            draft.rolePermissionList.rolePermissionMaint.tabs[0].instance = instance;
            draft.rolePermissionList.rolePermissionMaint.tabs[0].roleCopy!.data = [];
        });
        case "ROLE_PERMISSION_MAINT_CLOSE":
            return immerProduce(state, draft => {
                // draft.roleList.roleMaint.tabs[0].searchText = "";
                draft.rolePermissionList.rolePermissionMaint.visible = false;
            }); 
        case "ROLES_SET_SEARCH":
        return immerProduce(state, draft => {
            draft.roleList.searchText = action.searchText;
        });
        case "CUSTONER_ROLE_PERMISSIONS_GET_SUCCESS":
            return immerProduce(state, draft => {
                draft.customerOrgPermissions = action.payload.data.data.customerOrgPermissions;
            });
        case "ROLE_STATUS_MAINT_SUCCESS":
            data = action.payload.data; 
            return immerProduce(state, draft => {                
                draft.roleList.roleMaint.record = [];
                draft.roleList.roleMaint.record = data.data.orgRoles[0];
                draft.roleList.roleMaint.successData = data.data.orgRoles[0];
            });
        case "ROLES_ADMINISTERED_ORGS_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.roleList.roleMaint.orgs = data;
            });
        case "ROLES_MAINT_CLOSE":
            return immerProduce(state, draft => {
                draft.roleList.roleMaint.visible = false;
        });
        case "ROLES_GET_SUBMIT_BUTTON":
            return immerProduce(state, draft => {
                draft.roleList.roleMaint.isSubmitButton = action.isSubmitButton;      
        }); 
        default:
            return state;
    }
}