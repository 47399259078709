import React from "react";
import { Drawer, Space, Button } from "antd";
import { appConfigs } from "../../../utils/configurations";
import DashboardRolePermissions from "./DashboardRolePermissions";
import { DashboardPermission } from ".";

interface Props {
  width: number;
  visible: boolean;
  onDrawerClose: () => void;
  datasource: any;
  dashboardPermissions: DashboardPermission[];
  orgRoles: any;
  onRoleChange: (value: any) => void;
  onFinish: (roleId: number, values: DashboardPermission[]) => void;
  saveSuccess: boolean;
}

const PermissionDrawer: React.FC<Props> = (props) => {
  const {
    width,
    visible,
    onDrawerClose,
    dashboardPermissions,
    datasource,
    orgRoles,
    onRoleChange,
    onFinish,
    saveSuccess,
  } = props;

  return (
    <Drawer
      data-id="dashboard-role-maint-drawer"
      title={appConfigs.app.assetOverview.PermissionDrawerTitle}
      width={width}
      visible={visible}
      onClose={onDrawerClose}
      destroyOnClose={true}
      footer={
        <Space size="large" style={{ float: "right" }}>
          <Button
            data-id="dashboard-role-maint-close-button"
            onClick={onDrawerClose}
            type="default"
          >
            {appConfigs.settings.drawers.closeButtonText}
          </Button>
          <Button
            data-id="dashboard-role-maint-submit-button"
            type="primary"
            form="dashboardPermissionMaintInfoForm"
            key="submit"
            htmlType="submit"
          >
            {appConfigs.settings.form.submitButtonText}
          </Button>
        </Space>
      }
    >
      {orgRoles &&
        orgRoles.length > 0 &&
        dashboardPermissions &&
        dashboardPermissions.length > 0 &&
        datasource &&
        datasource.length > 0 && (
          <DashboardRolePermissions
            orgRoles={orgRoles}
            dashboardPermissions={dashboardPermissions}
            datasource={datasource}
            onRoleChange={onRoleChange}
            onFinish={onFinish}
            saveSuccess={saveSuccess}
          />
        )}
    </Drawer>
  );
};

export default PermissionDrawer;
