import { appConfigs } from "../../../utils/configurations";

export const initialState = {
    orgsList: {
        orgMaint: {
            instance: 0,
            width: 720,
            visible: false,
            record: {},
            selectedTag: [],
            title: "",
            activeTab: "1",
            tabs: [
                {
                    title: "Org Info",
                    instance: 0,
                    disabled: false,
                    countries: [],
                    states: [],
                    phoneCountries: [],
                    languages: []
                },
                {
                    title: "Settings",
                    instance: 0,
                    disabled: true,
                    allCapabilities: []
                },
                {
                    title: "Administrators",
                    instance: 0,
                    disabled: true,
                    searchText: "",
                    usersAdministered: [],
                    tableData: [],
                    tableConfig : { 
                        pagination : {                  
                            showSizeChanger: appConfigs.tables.showSizeChanger,
                            showQuickJumper: appConfigs.tables.showQuickJumper,
                            pageSize: appConfigs.tables.pageSize,
                            defaultCurrent: appConfigs.tables.defaultCurrent,
                            current: appConfigs.tables.defaultCurrent,
                            total: appConfigs.tables.defaultTotal,
                            hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                            responsive: appConfigs.tables.responsive,
                            onChange: null,
                            showLessItems: appConfigs.tables.showLessItems,  
                            showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} user${total > 1 ? 's' : ''}`
                        }
                    }
                },
                {
                    title: "Global Subscriptions",
                    instance: 0,
                    disabled: true,
                    searchText: "",
                    globalSubscriptions: [],
                    tableData: [],
                    tableConfig : { 
                        pagination : {                  
                            showSizeChanger: appConfigs.tables.showSizeChanger,
                            showQuickJumper: appConfigs.tables.showQuickJumper,
                            pageSize: appConfigs.tables.pageSize,
                            defaultCurrent: appConfigs.tables.defaultCurrent,
                            current: appConfigs.tables.defaultCurrent,
                            total: appConfigs.tables.defaultTotal,
                            hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                            responsive: appConfigs.tables.responsive,
                            onChange: null,
                            showLessItems: appConfigs.tables.showLessItems,  
                            showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} user${total > 1 ? 's' : ''}`
                        }
                    }
                },
                {
                    title: "Service Plans",
                    instance: 0,
                    disabled: false
                },
                {
                    title: "Global Notes",
                    instance: 0,
                    disabled: false,
                    globalNotes:[],
                    tableData: []
                }
            ]
        },
        refreshOnUpdate: false,
        mode: "all",
        scope: "all",
        searchText: "",
        orgId: "",
        orgName: "",
        orgTree: [],
        tableData: [],
        tableConfig : { 
            pagination : {                  
                showSizeChanger: appConfigs.tables.showSizeChanger,
                showQuickJumper: appConfigs.tables.showQuickJumper,
                pageSize: appConfigs.tables.pageSize,
                defaultCurrent: appConfigs.tables.defaultCurrent,
                current: appConfigs.tables.defaultCurrent,
                total: appConfigs.tables.defaultTotal,
                hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                responsive: appConfigs.tables.responsive,
                onChange: null,
                showLessItems: appConfigs.tables.showLessItems,  
                showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} org${total > 1 ? 's' : ''}`
            }
        }
    }
};
  