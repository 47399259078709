import React from "react";
import { Form, Select, Card, Input } from 'antd';

import { appConfigs } from "../../../utils/configurations";

import { connect } from "react-redux";
import * as actions from "../actions";


const { Option } = Select;

interface Prop {
   
    oprList: any,
    context: any,
    operatorOrgsGet:Function,
    operatorOrgUsersGet:Function,
    operatorOrgAssetsGet:Function,
    operatorOrgAccessTypesGet: Function,
    formRef: any
}

class OprHomeOrg extends React.Component<Prop, {}> {

    componentDidMount() {
        this.componentUpdate();
    }
   
    componentDidUpdate(prevProps: Prop) {
        if (this.props.oprList.oprMaint.instance !== prevProps.oprList.oprMaint.instance) {
            this.componentUpdate();
        }
    }

    componentUpdate() {
        this.props.operatorOrgsGet(this, null, null,"");
    }
    
    onOperatorOrgChange=(value: string)=>{
        if((value||"")!=="")
        {
            this.props.formRef.setFieldsValue({
                userId: undefined
            })
            this.props.operatorOrgUsersGet(this,null,null,value);
            this.props.operatorOrgAssetsGet(this,null,null,'all', value);
            this.props.operatorOrgAccessTypesGet(this,null,null,'all', value);
        }
    }
    
    render() {
        const { operatorOrgs } = this.props.oprList.oprMaint;
        return (
          
                   
                    <Form.Item
                        name="orgId"
                        label="Organization"
                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                     >                    
                        <Select 
                            data-id="oprs-maint-info-home-org-id"
                            showSearch
                            showArrow={true}
                            allowClear
                            placeholder="Select..."
                            optionFilterProp="children"
                            className="select-before"
                            onChange={this.onOperatorOrgChange}                          
                        >
                            {operatorOrgs !== null && operatorOrgs.map((record:any, index:number) => (
                                <Option key={record.orgId} value={record.orgId}>
                                    {record.name}
                                </Option>
                            ))}
                        </Select>                         
                    </Form.Item>
               
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        oprList: state.oprReducers.oprState.oprList
    };
};

export default connect(
    mapStateToProps,
    actions
)(OprHomeOrg);