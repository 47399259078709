interface EventHandler<T> {
  (t: T): void;
}
interface EventListener {
  remove: () => void;
}

export default class Event<T = void> {
  private _listeners: Map<symbol, EventHandler<T>> = new Map();

  trigger(t: T) {
    this._listeners.forEach((l) => l(t));
  }

  subscribe(listener: EventHandler<T>): EventListener {
    const id = Symbol();
    this._listeners.set(id, listener);
    return {
      remove: () => this._listeners.delete(id),
    };
  }
}
