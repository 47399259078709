import React, { useState, useEffect, CSSProperties } from "react";
import { Card, Space, message, Popconfirm } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
  ReloadOutlined,
  FilterOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import {
  Template,
  SignalDatapoint,
  SignalId,
  SignalCollectionId,
} from "./models";
import { DataStore, WidgetState } from "./state";
import { loadSignalsData } from "./api";
import { createWidget } from "./widgets";
import { constructRows } from "./widgets/common";
import "./WidgetContainer.css";
import { errorResponse } from "../../utils/apiHelpers/apiHelpers";

import * as Api from "./api";
import { WidgetFilterConfig } from "./WidgetFilterForm";
import usePolling from "../../lib/use-polling";
import { appConfigs } from "../../utils/configurations";

interface WidgetControlProps {
  onEdit: () => void;
  onDelete: () => void;
  onFilter: () => void;
  onExport: () => void;
  userRole: string;
  isCreate: boolean;
  isRead: boolean;
  isUpdate: boolean;
  isDelete: boolean; 
  //isIqanKey: boolean;//ops-2839: Remove the iqanconnect key permission check 
}

interface WidgetHeaderProps {
  onRefresh: () => void;
  onFilter: () => void;
  onExport: () => void;
  isLoading: boolean;
  widgetState: WidgetState;
  userRole: string;
  isCreate: boolean;
  isRead: boolean;
  isUpdate: boolean;
  isDelete: boolean; 
}

const WidgetHeader: React.FC<WidgetControlProps & WidgetHeaderProps> = (
  props
) => {

  function confirm(e: any) {
    props.onDelete();
  }
  function cancel(e: any) {}  

  const { type } = props.widgetState;
  return (
    <div style={{ width: "100%" }}>
      <Space>

      {props.isUpdate ? <EditOutlined onClick={props.onEdit} /> : ''}
      {props.isDelete ?       
        <Popconfirm 
          title="Are you sure to delete this widget?"
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
          >
            <DeleteOutlined />

        </Popconfirm> : '' }

        {props.isLoading ? (
          <LoadingOutlined />
        ) : (
          <ReloadOutlined onClick={props.onRefresh} />
        )}
        {type === "line" || type === "map" || type === "clusterbar" ? (
          <>
          <FilterOutlined onClick={props.onFilter} />
          {props.isCreate || props.isUpdate || props.userRole.toLowerCase() === "barko_guest"  ? <DownloadOutlined onClick={props.onExport} /> : ''}          
          </>
        ) : (
          <></>
        )}
      </Space>
    </div>
  );
};

export interface Props extends WidgetControlProps {
  widgetState: WidgetState;
  template: Template;
  assetId: string;
}

const WidgetContainer: React.FC<Props> = (props) => {
  const { widgetState, template, assetId } = props;
  const { signals, type , rightYAxisSignals} = widgetState;

  const [data, setData] = useState<DataStore>(
    new Map<SignalId, SignalDatapoint[]>()
  );

  const [rightYAxisData, setRightAxisData] = useState<DataStore>(
    new Map<SignalId, SignalDatapoint[]>()
  );

  const [isLoading, setLoading] = useState<boolean>(false);
  const [refreshTime, setRefreshTime] = useState<number>(0);
  const [error, setError] = useState<string>();
  const [signalArray, setSignalArray] = useState<SignalId[]>(signals);

  const onRefresh = () => {
    setRefreshTime(Date.now());
  };

  const { datapoint, startDate, endDate, startTime, endTime } = props.widgetState;
  const loadWidgets = () => {
    setLoading(true);
    loadData(signals, {       
      startDate: startDate,
      startTime: startTime,
      endDate: endDate,  
      endTime: endTime,      
      datapoint: datapoint,
    });
  }

  //commented the code for auto refersh as requested in sprint 5
  // usePolling(
  //   () => {
  //     loadWidgets();
  //   }, 
  //   appConfigs.app.assetOverview.autoRefreshPollingRate
  // );
 //filter
  const filterErrors = (response: any) => {
    const responseFilter = response.filter((res:any) => (res instanceof Error));
    if(responseFilter.length > 0) {
      responseFilter.forEach((error: any) => {
        let errorStatus:number = !error.response ? 999 : (error.response.status || 999);
        if(errorStatus === 401) {
          errorResponse(null, error);
        }
      });      
    }
  }

  const formatData = (response: any, signals: SignalId[]) => {
    const dataSet = constructRows(response, signals);
    setData(dataSet);
    setLoading(false);
  };

  const formatRightYAxisData = (response: any, signals: SignalId[]) => {
    const dataSet = constructRows(response, signals);
    setRightAxisData(dataSet);
    setLoading(false);
  };

  const loadData = async (
    signals: SignalId[],
    filterConfig: WidgetFilterConfig,
    isFormatRightYAxisData:boolean=false
  ) => {
    const datapointArray: SignalCollectionId[] = [];
    //find the signal collection id to make an api request
    signals.forEach((si) => {
      const datapoint = template.signalCollections.filter((s) =>
        s.signals.find((sg) => sg.id === si)
      )[0];
      if (datapoint && datapoint["id"]) {
        datapointArray.push(datapoint.id);
      }
    });
    //stop the repeataion of datapoints
    const uniqsignalCollectionId = datapointArray
      .filter((elem, pos) => {
        return datapointArray.indexOf(elem) === pos;
      })
      .filter((x) => !!x);

    if (uniqsignalCollectionId.length > 0) {
      Api.loadSignalsData(assetId, uniqsignalCollectionId, filterConfig, type)
        .then((resp: any) => {
          filterErrors(resp);
          if(isFormatRightYAxisData){
            formatRightYAxisData(resp,signals)
          }
          else
          {
            formatData(resp, signals);
          }
          //ops-2440          
          // const responseData = resp.map((res: any) => res.data);
          // let iqanConnKey = false;
          // //const iqanConnKey = responseData.map((s: any) => s.signals.filter((r: any) => r.name === "IQAN COnnect Key")).length;
          // for(let i=0; i<responseData.length; i++){
          //   if(responseData[i].hasOwnProperty(responseData[i].signals)){ //ops-2681 asset dashboard error fix
          //     for (let j=0; j<responseData[i].signals.length;j++){
          //       if(responseData[i].signals[j].name === "IQAN Connect Key" && props.isIqanKey===true){
          //         formatData(resp, signals);
          //         iqanConnKey = true;
          //       }
          //       else if(responseData[i].signals[j].name !== "IQAN Connect Key")
          //       {
          //         formatData(resp, signals);
          //       }
          //     }
          //   }
          // }
          // if(iqanConnKey !== true){
          //   formatData(resp, signals);
          // }
        })
        .catch((err) => {
          errorResponse(null, err);
          setError(err.message);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setError("500");
    }
  };

  function compare(array1: SignalId[], array2: SignalId[]) {
    const newArray1 = [...array1];
    const newArray2 = [...array2];
    return (
      newArray1.length === newArray2.length &&
      newArray1.sort().every(function (value, index) {
        return value === newArray2.sort()[index];
      })
    );
  }

  const updateSignalArrayState = (signals: SignalId[]) => {
    const a = compare(signals, signalArray);
    if (!a) {
      setSignalArray(signals);
    }
  };

  updateSignalArrayState(signals);

  

  useEffect(() => {
    if (signalArray.length > 0) {
      setLoading(true);
      loadData(signals, {       
        startDate: startDate,
        startTime: startTime,
        endDate: endDate,  
        endTime: endTime,      
        datapoint: datapoint,
      });
      if(rightYAxisSignals && rightYAxisSignals.length)
      {
        loadData((rightYAxisSignals||[]),{startDate: startDate,
          startTime: startTime,
          endDate: endDate,  
          endTime: endTime,      
          datapoint: datapoint},true)
      }
    }
  }, [signalArray, refreshTime, datapoint, startDate, endDate, startTime, endTime]);

  const widgetPadding = (): CSSProperties => {
    switch (type) {
      case "text":
      case "boolean":
        return { padding: "0px 15px 0px 15px" };
      default:
        return { padding: "0" };
    }
  };

  const bodyStyle: CSSProperties = {
    height: "calc(100% - 58px)",
    ...widgetPadding(),
  };

  return (
    <Card
      style={{ width: "100%", height: "100%" }}
      title={props.widgetState.title}
      extra={
        <WidgetHeader {...props} onRefresh={onRefresh} isLoading={isLoading} />
      }
      // Makes the body the size of the card minus the size of the header
      bodyStyle={bodyStyle}
    >
      <div style={{ height: "100%" }}>
        {createWidget(widgetState, { template, data,rightYAxisData }, error)}
      </div>
    </Card>
  );
};

export default WidgetContainer;
