import { withRouter  } from "react-router-dom";
import React from 'react';
import { Tooltip } from "antd";
import {  CaretLeftOutlined } from "@ant-design/icons";


export default withRouter(({ history }) => {    
    return (
        <div>
            <Tooltip title="Go To Previous Page"> 
                <CaretLeftOutlined onClick={() => history.goBack()} style={{ fontSize: '27px' }}/>
            </Tooltip>
        </div>
    )
});