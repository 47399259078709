import { Layout } from "react-grid-layout";
import { WidgetConfig } from ".";
import { DBWidgetConfig, Template, WidGetSignalConfig } from "../models";
import { computeLayout, DbLayoutConfig, WidgetState } from "../state";

///Db requires to save the signals in with 3 attributes, signal id, name
///and name. Before saving underline function fetch the datapoint and name
/// from Template object for respective signals received from edit and add functionality

function constructSignalArray(
    signalId: string,
    template: Template
  ): WidGetSignalConfig {
    const datapoint = template.signalCollections.filter((s) =>
      s.signals.find((sv) => sv.id === signalId)
    )[0];
    const signal = datapoint.signals.filter((s) => s.id === signalId)[0];
    return {
      name: signal.name,
      signalId: signalId,
      datapointId: datapoint.id,
    };
  }
  
  ///create the layout as required by DB from state sizeX, sizeY, row and col attributes.
  function constructLayout(widgetState: WidgetState) {
    return {
      sizeX: widgetState.sizeX,
      sizeY: widgetState.sizeY,
      row: widgetState.row,
      col: widgetState.col,
    };
  } 
  
  export function saveLayoutDb(
    layout: Layout[]
  ): (DbLayoutConfig & { _id: string })[] {
    function constructDbLayout(layout: Layout): DbLayoutConfig & { _id: string } {
      const { i, x, y, w, h } = layout;
      return {
        col: x,
        row: y,
        sizeX: w,
        sizeY: h,
        _id: i,
      };
    }
    return layout.map(constructDbLayout);
  }

///before send a api request to save the edit widget details.
export function editWidgetStructureForDb(
    widgetId: string,
    cfg: WidgetConfig,
    template: Template,
    widgetState: WidgetState
  ): DBWidgetConfig {
    const cnfg: any = cfg;
    // console.log(cnfg);
  
    return {
      _id: widgetId,
      type: cfg.type,
      title: cfg.title,
      ...constructLayout(widgetState),
      signals: cfg.signals.map((s) => constructSignalArray(s, template)),
      ...(cfg.rightYAxisSignals && cfg.rightYAxisSignals.length>0 ? {rightYAxisSignals:
        cfg.rightYAxisSignals.map((s) => constructSignalArray(s, template))} : {}),
      settings: cnfg.settings,
    };
  }
  
  ///function to construct the widget struture out of various paremeters
  ///before send a api request to save the Add widget details.
  export function addWidgetStructureForDb(
    widgetId: string,
    cfg: WidgetConfig,
    template: Template,
    widgetState: WidgetState[],
    widgetColumns:number
  ): DBWidgetConfig {
    const cnfg: any = cfg;
    let layout: Layout[] = [];
  
    if (widgetState.length === 0) {
      layout = [
        {
          i: widgetId, //dummy id which will be over-written later
          x: 0,
          y: 0,
          w: 1,
          h: 1,
        },
      ];
    } else {
      layout = computeLayout(widgetState, widgetColumns);
    }
  
    const newLayout = layoutAlgorithm(widgetState, layout);
  
    return {
      _id: widgetId,
      type: cfg.type,
      title: cfg.title,
      ...newLayout,
      signals: cfg.signals.map((s) => constructSignalArray(s, template)),
      ...(cfg.rightYAxisSignals && cfg.rightYAxisSignals.length>0 ? {rightYAxisSignals:
        cfg.rightYAxisSignals.map((s) => constructSignalArray(s, template))} : {}),
      settings: cnfg.settings,
    };
  }
  
  ///Algorithm steps
  ///step 1: sort by y and find the element with same y
  ///step 2: Accumulate the value of w for all y's in step1
  ///step 3: Check or 1 or more space available beside x in the same row
  ///step 4: If yes, create the new layout having y same as step 1 and x=accumulated w (step2)
  ///step 4 cont..: if no, y=previous element y+1, x=0, w=1, h=1
  
  function layoutAlgorithm(
    widgetState: WidgetState[],
    layout: Layout[]
  ): DbLayoutConfig {
    //if there are no widgets currently on the page
    if (widgetState.length === 0) {
      return {
        sizeX: 1,
        sizeY: 1,
        row: 0,
        col: 0,
      };
    }
    const sortedLayout = layout.sort(function (a, b) {
      return a["y"] - b["y"];
    });
  
    const lastElementY = sortedLayout[sortedLayout.length - 1].y;
    const lastRowcumulativeW = sortedLayout.reduce((acc, current) => {
      if (current.y === lastElementY) {
        acc += current.w;
      }
      return acc;
    }, 0);
  
    let newElementY = 0;
    let newElementX = 0;
    if (4 - lastRowcumulativeW >= 1) {
      newElementX = lastRowcumulativeW;
      newElementY = lastElementY;
    } else {
      newElementY = lastElementY + 1;
    }
  
    return {
      sizeX: 1,
      sizeY: 1,
      row: newElementY,
      col: newElementX,
    };
  }