import {  
  DBWidgetConfig,  
  SignalCollection,
  Rule  
} from "./models";

import { AssetDashboardResponse, Datapoint } from "./api";

export function constructAssetData(
  apiResponse: any,
  mastertag: string
): AssetDashboardResponse {
  function constructSignalCollection(datapoint: Datapoint): SignalCollection {
    const { _id, name, type, values = [] } = datapoint;
    return {
      id: _id,
      name,
      type,
      signals:
        values?.map((v) => {
          return {
            id: v._id,
            name: v.name || v.label,
            units: v.unit,
          };
        }) || [],
    };
  }

  const assets = apiResponse.data.assets[0];
  const datapoints = (JSON.parse(assets["datapoints"]) || []) as Datapoint[];
  const widgets = (JSON.parse(assets["widgets"]) || []) as DBWidgetConfig[];
  const rawRules = JSON.parse(assets["signalRules"]) || [];
  const rulesList = rawRules?.flatMap((s: any) => s.conditions) || [];

  function formatRules(rule: any): Rule {
    const {
      CriticalHigh,
      WarningHigh,
      InfoHigh,
      InfoLow,
      WarningLow,
      CriticalLow,
    } = rule.Value;
    return {
      signalId: rule.SignalId,
      criticalHigh: CriticalHigh,
      warningHigh: WarningHigh,
      infoHigh: InfoHigh,
      infoLow: InfoLow,
      warningLow: WarningLow,
      criticalLow: CriticalLow,
    };
  }

  const rules: Rule[] = rulesList?.map(formatRules) || [];

  const { nickname } = assets;

  const asset: AssetDashboardResponse = {
    asset: {
      ...assets,
      id: assets.gatewayId,
      name: nickname,
      template: {
        signalCollections: datapoints.map(constructSignalCollection) || [],
        rules: rules,
      },
      widgets: widgets,
    },
  };
  return asset;
}

