import React from "react";
import { CommonWidgetProps } from "../../models";
import { connect } from "react-redux";
interface Props {
  signalRData: any;
}

const Widget: React.FC<CommonWidgetProps & Props> = (props) => {
  return (
    <div style={{width:"100%",height:'100%',overflow:"auto",whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
      <p>{props?.informationValue}</p>
    </div>
  );
};

// export default Widget;
const mapStateToProps = (state: any) => {
  return {
    signalRData: state.contextReducer.signalRData,
  };
};
export default connect(
  mapStateToProps,
  // actions
)(Widget);
