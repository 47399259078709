import React, { useState } from "react";
import {
  Form,
  Input,
  message,
  Select,
  Checkbox,
  DatePicker,
  Row,
  Col,
  Radio,
  Collapse,
  Space,
} from "antd";
import moment, { Moment } from "moment";
import { FormInstance } from "antd/lib/form";

import { appConfigs } from "../../../utils/configurations";
import {
  errorResponse,
  resetFormErrors,
} from "../../../utils/apiHelpers/apiHelpers";
import { FormState, formInit } from "../../../utils/apiHelpers/formHelpers";
import { SuccessAlert, ErrorAlert } from "../../../utils/Components/formAlerts";

import { connect } from "react-redux";
import * as actions from "../actions";
import history from "../../../utils/history";
import ScheduleMaintInfoOrg from "./scheduleMaintInfoOrg";
import ScheduleMaintInfoReports from "./scheduleMaintInfoReports";
import ScheduleMaintInfoUsers from "./scheduleMaintInfoUsers";
import { getPermissions } from "../../../utils/commonHelpers";
const { Option } = Select;
const weekOptions = [
  { label: "Mon", value: "1" },
  { label: "Tue", value: "2" },
  { label: "Wed", value: "3" },
  { label: "Thu", value: "4" },
  { label: "Fri", value: "5" },
  { label: "Sat", value: "6" },
  { label: "Sun", value: "7" },
];

const todayDate = new Date();
const firstDayofMonth = new Date(todayDate.getFullYear(), todayDate.getMonth());
const firstDayofNextMonth = new Date(
  todayDate.getFullYear(),
  todayDate.getMonth() + 1
);
let subscribers: string[] = [];
interface Prop {
  context: any;
  scheduledList: any;
  scheduleMaint: Function;
  scheduleMaintClose: Function;
  getReportSchedulesList: Function;
  scheduleMaintInfoReportsGet: Function;
  getOrgTags: Function;
  getScheduleOrgTags: Function;		   
  permissions: any;
}

interface State extends FormState {
  frequency: string;
  capabilityCheckbox: string[];
  formErrorMessage?: string[];
  formSuccessMessage?: string;
  assetTagDDL:boolean;
  orgTagDDL:boolean;  
  currentUnitValue:string;
  orgTagsErrorMsg:string;
}

class ScheduleMaintInfo extends React.Component<Prop, State> {
  state: State = {
    frequency: "",
    capabilityCheckbox: [],
	  assetTagDDL:false,
    orgTagDDL:false,
    currentUnitValue:'gallons',
    orgTagsErrorMsg:'' 
  };
 
  formRef = React.createRef<FormInstance>();

  componentDidMount() {
    this.componentUpdate(this.props.scheduledList.scheduledMaint.record);
    if(this.props.scheduledList.scheduledMaint.record.orgTags){
      this.setState({ assetTagDDL: true });
    }
  }

  componentDidUpdate(prevProps: Prop, prevState: FormState) {
    let isDifferentInstance =
      this.props.scheduledList.scheduledMaint.instance !==
      prevProps.scheduledList.scheduledMaint.instance;
    let isDifferentOrg =
      this.props.scheduledList.scheduledMaint.selectedOrgId !==
      prevProps.scheduledList.scheduledMaint.selectedOrgId;

    if (isDifferentInstance) {
      this.componentUpdate(this.props.scheduledList.scheduledMaint.record);
    } else if (
      isDifferentOrg &&
      this.props.scheduledList.scheduledMaint.mode === "new"
    ) {
      // When org change reset capabilityCheckbox
      this.setState({ capabilityCheckbox: [] });
    }
  }

  componentUpdate(record: any) {
    this.props.scheduleMaintInfoReportsGet(
      this,
      null,
      null,
      record.orgId ? record.orgId : this.props.context.appContext.orgId
    );

    let capabilities: string[] = [];
    let days: string[] = [];
    let tagValues: string[] = [];
    let orgTagValues: string[] = [];
    if (record.capabilities) {
      capabilities = record.capabilities.split(",");
      this.setState({ capabilityCheckbox: capabilities });
    }

    if (record.subscribers) {
      subscribers = record.subscribers.split(",");
    }
    if (record.tags) {
      tagValues = record.tags?.split(",");
    }
	 if (record.orgTags) {
      orgTagValues = record.orgTags?.split(",");
    }
    if (record.days) {
      days = record.days.split(",");
    }

    let hours, minutes;
    let currentClientTimeZone = Intl.DateTimeFormat().resolvedOptions()
      .timeZone;

    if (
      record.clientScheduledTime &&
      currentClientTimeZone === record.clientTimezone
    ) {
      let timeIndex = record.clientScheduledTime.indexOf(":");
      hours = record.clientScheduledTime.substring(
        record.clientScheduledTime.indexOf("T") + 1,
        timeIndex
      );
      minutes = record.clientScheduledTime.substring(
        timeIndex + 1,
        record.clientScheduledTime.indexOf("Z")
      );
    } else if (
      record.clientScheduledTime &&
      currentClientTimeZone !== record.clientTimezone
    ) {
      // If edit timezone is different covert UTC time to local
      let clientScheduledTime = new Date(record.scheduledTime);
      hours = clientScheduledTime.getHours();
      minutes = clientScheduledTime.getMinutes();
    }
    formInit(this);
    if (this.formRef.current != null) {
      this.formRef.current!.resetFields();
      this.setState({ frequency: record.frequency });
      if(record.unit){
        this.setState({ currentUnitValue: record.unit });
      }
      this.formRef.current!.setFieldsValue({
        orgId: record.orgId
          ? record.orgId
          : this.props.context.appContext.orgId,
        name: record.scheduleName,
        frequency: record.frequency,
        scheduletimeHours: hours,
        scheduletimeMintues: minutes,
        dayofmonth:
          (record.frequency === "Monthly" || record.frequency === "CalendarMonth")
            ? moment().date(Number(record.days))
            : "",
        tags: tagValues.map((tag: any) => {
          return {
            value: tag,
            label: tag,
            key: tag,
          };
        }),
        orgTags: orgTagValues.map((tag: any) => {
          return {
            value: tag,
            label: tag,
            key: tag,
          };
        }),			 
		dayofweeks: (record.frequency === "Weekly" || record.frequency === "CalendarWeek") ? days : "",
      });
    }
  }

  onFrequencyChange = (value: string, option: any) => {
    if ((value || "") !== "") {
      this.setState({ frequency: value });
    } else {
      this.setState({ frequency: "" });
    }
  };
  
  onOrgTagsChange = (values: any) => {
    if(values.length>0){
      this.setState({ assetTagDDL: true });
    }else{
      this.setState({ assetTagDDL: false });
    }    
  };

  onAssetTagsChange = (values: any) => {
   if(values.length===0){
     this.setState({ orgTagDDL: false });
    }
  };

  onFinish = (values: any) => {    
    let scheduleAddEdit = this.props.scheduledList.scheduledMaint
      .scheduleAddEdit;
    scheduleAddEdit.mode = this.props.scheduledList.scheduledMaint.mode;
    scheduleAddEdit.scheduleId = this.props.scheduledList.scheduledMaint.record
      .scheduleId
      ? this.props.scheduledList.scheduledMaint.record.scheduleId
      : -1;

    if (
      this.state.capabilityCheckbox &&
      this.state.capabilityCheckbox.length > 0
    ) {
      resetFormErrors(this, this.formRef.current);

      scheduleAddEdit.name = values.name;
      scheduleAddEdit.subscribers = values.subscribe;
      scheduleAddEdit.orgId = values.orgId;
      let tagArray = values.tags.map((a: any) => a.value);
      let scheduleOrgTagArray = values.orgTags.map((b: any) => b.value);
      if(this.state.assetTagDDL===false){
        if(scheduleOrgTagArray.length>0){
          scheduleAddEdit.tags = "";
          scheduleAddEdit.orgTags = scheduleOrgTagArray.toString();
        }else{
          scheduleAddEdit.tags = tagArray.toString();
          scheduleAddEdit.orgTags = "";
        }
      }else{
        scheduleAddEdit.tags = "";
        scheduleAddEdit.orgTags = scheduleOrgTagArray.toString();
      }
      scheduleAddEdit.frequency = values.frequency;
      scheduleAddEdit.reports = this.state.capabilityCheckbox;
      scheduleAddEdit.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      scheduleAddEdit.timeZoneOffset = todayDate.getTimezoneOffset();
      let localIsoDateString = todayDate.toISOString().substring(0, 10);
      let localIsoScheduleTimeString =
        "T" + values.scheduletimeHours + ":" + values.scheduletimeMintues + "Z";
      let clientScheduledTimeString = localIsoDateString.concat(
        localIsoScheduleTimeString
      );
      let scheduledTime =
        localIsoDateString +
        " " +
        values.scheduletimeHours +
        ":" +
        values.scheduletimeMintues;

      scheduleAddEdit.clientScheduledTime = clientScheduledTimeString;
      scheduleAddEdit.scheduledTime = scheduledTime;

      if ((values.frequency === "Weekly" || values.frequency === "CalendarWeek") && values.dayofweeks.length > 0) {
        scheduleAddEdit.dayOfWeek = values.dayofweeks.toString();
        scheduleAddEdit.dayOfMonth = "";
      } else if (values.frequency === "Monthly" || values.frequency === "CalendarMonth") {
        scheduleAddEdit.dayOfMonth = values.dayofmonth._d.getDate();
        scheduleAddEdit.dayOfWeek = "";
      } else if (values.frequency === "Daily") {
        scheduleAddEdit.dayOfWeek = "";
        scheduleAddEdit.dayOfMonth = "";
      }
      
      //capability id "26" is ePTOV2
      if(this.state.capabilityCheckbox.includes("26")){
        scheduleAddEdit.unit=this.state.currentUnitValue;
      }else{
        scheduleAddEdit.unit=null;
      }
      
      this.props.scheduleMaint(
        this,
        this.onFinishSuccess,
        this.onFinishFailure,
        scheduleAddEdit
      );
    } else {      
      // errorResponse(this, "Atleast one report should be selected / Org does not have reports access");
      // When no report is selected
      message.error("Atleast one report should be selected / Org does not have reports access");
    }
  };

  onFinishSuccess = (record: any, mode: string) => {
    this.props.scheduleMaintClose(this);
    const me = this;
    me.props.getReportSchedulesList(
      me,
      me.getScheduleSuccess(mode),
      null,
      me.props.scheduledList.tableConfig.pagination.current,
      me.props.scheduledList.searchText
    );
  };

  onFinishFailure = (error: any) => {
    errorResponse(this, error);
  };

  getScheduleSuccess = (mode: string) => {
    message.success(
      "Schedule has been successfully " +
      (mode === "new" ? "created" : "updated")
    );
    this.props.scheduleMaintClose(this);
  };

  filterReportsArray(element: any, index: number, array: any) {
    return element.access == true;
  }

  onCapabilityChange = (e: any) => {
    let checkBoxCurrentState = this.state.capabilityCheckbox;
    if (e.target.checked) {
      checkBoxCurrentState.push(e.target.value.toString());
    } else if (!e.target.checked) {
      const index = checkBoxCurrentState.indexOf(e.target.value.toString());
      if (index > -1) {
        checkBoxCurrentState.splice(index, 1);
      }
    }
    this.setState({ capabilityCheckbox: checkBoxCurrentState });
  };

  findCapability = (item: any) => {
    if (!item) {
    }
  };
  
  render() {
    const { reports } = this.props.scheduledList.scheduledMaint;
    
    let finalReports = [];
    if (reports && reports.length > 0) {
      finalReports = reports.filter(this.filterReportsArray);
    }
    const result = getPermissions(
      this.props.permissions.permissions.operator_support
    );
    const tagPermissions = getPermissions(
      this.props.permissions.permissions.asset_tagging
    );

    let isOprRead = result[1];
    let canTagCreate = tagPermissions[0];
    let canTagRead = tagPermissions[1];
    let canTagDelete = tagPermissions[3];
   
    const checkTagsValidations = async (rule: any, options: any) => {
      let newValues: any = [];
      let existingValues = [];
      newValues = options?.map((o: any) => o.value);
      existingValues = this.props.scheduledList.scheduledMaint.record.tags?.split(
        ","
      );
      
      if (options?.length) {       
          if (options?.length > appConfigs.app.assetTags.maxAssetTags) {
            options.pop();
            return Promise.reject(
              `Maximum of ${appConfigs.app.assetTags.maxAssetTags} tags allowed`
            );
          }
          if (
            options[options.length - 1]?.value.length >
            appConfigs.app.assetTags.maxAllowCharactersPerTags
          ) {
            options.pop();
            return Promise.reject(
              `Maximum of ${appConfigs.app.assetTags.maxAllowCharactersPerTags} characters allowed per tags`
            );
          }        
      }
      return Promise.resolve();
    };
    const checkOrgTagsValidations = async (rule: any, options: any) => {
      let newValues: any = [];
      let existingValues = [];
      newValues = options?.map((o: any) => o.value);
      existingValues = this.props.scheduledList.scheduledMaint.record.orgTags?.split(
        ","
      );
      
      if (options?.length) {        
          if (options?.length > appConfigs.app.orgTags.maxOrgTags) {
            options.pop();
            this.setState({ orgTagsErrorMsg: `Maximum of ${appConfigs.app.orgTags.maxOrgTags} tags allowed` });            
            return Promise.reject(
              `Maximum of ${appConfigs.app.orgTags.maxOrgTags} tags allowed`
            );
          }else{
            this.setState({ orgTagsErrorMsg: '' });
          }
          if (
            options[options.length - 1]?.value.length >
            appConfigs.app.orgTags.maxAllowCharactersPerTags
          ) {
            options.pop();
            this.setState({ orgTagsErrorMsg: `Maximum of ${appConfigs.app.orgTags.maxAllowCharactersPerTags} characters allowed per tags` });            
            return Promise.reject(
              `Maximum of ${appConfigs.app.orgTags.maxAllowCharactersPerTags} characters allowed per tags`
            );
          }       
      }
      return Promise.resolve();
    };
    return (
      <>
        {this.state.formSuccess && !this.state.formError && (
          <SuccessAlert
            data-id="schedule-maint-info-success-message"
            message={this.state.formSuccessMessage}
          />
        )}
        {this.state.formError && !this.state.formSuccess && (
          <ErrorAlert
            data-id="schedule-maint-info-error-message"
            message={this.state.formErrorMessage}
          />
        )}

        <Form
          style={{ marginTop: 15 }}
          {...appConfigs.settings.formLayout.mainLayout}
          id="scheduleMaintInfoForm"
          ref={this.formRef}
          onFinish={this.onFinish}
        >
          {this.formRef.current !== null && (
            <ScheduleMaintInfoOrg formRef={this.formRef.current} />
          )}          
          
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item
                  label="Org Tags"
                  name="orgTags"
                  initialValue={this.props.scheduledList.scheduledMaint.record?.orgTags?.split(
                    ","
                  )}
                  key={Math.random()}
                  rules={[{ validator: checkOrgTagsValidations }]}
                  help={<span style={{color:'#ff4d4f',fontSize:'14px'}}>{this.state.orgTagsErrorMsg}</span> }
                  extra={ <span style={{color:'#0052CC',fontSize:'14px'}}>Org Tags will have precedence over Asset Tags</span> }
                >
                  <Select
                    mode="tags"
                    style={{ width: "100%", textTransform: "uppercase" }}
                    placeholder="Select Org Tags"
                    disabled={
                      this.state.orgTagDDL
                    }
                    labelInValue
                    onInputKeyDown={(e) => e.preventDefault()}
					onChange={this.onOrgTagsChange}
                    id="orgTagsDDL"
                  >
                    {this.props.scheduledList.scheduledMaint.selectedOrgTag?.map(
                      (tagName: any, index: number) => (
                        <Option key={index} value={tagName.toUpperCase()}>
                          {tagName.toUpperCase()}
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}></Col>
            </Row>
         
            <Row gutter={8}>
              <Col span={24}>
                <Form.Item
                  label="Asset Tags"
                  name="tags"
                  initialValue={this.props.scheduledList.scheduledMaint.record?.tags?.split(
                    ","
                  )}
                  key={Math.random()}
                  rules={[{ validator: checkTagsValidations }]}
                >
                  
                  <Select
                    mode="tags"
                    style={{ width: "100%", textTransform: "uppercase" }}
                    placeholder="Select Asset Tags"
                    disabled={
                      this.state.assetTagDDL
                    }
                    labelInValue
                    onInputKeyDown={(e) => e.preventDefault()}
					onChange={this.onAssetTagsChange}
                    id="assetTagsDDL"
                  >
                    {this.props.scheduledList.scheduledMaint.selectedTag?.map(
                      (tagName: any, index: number) => (
                        <Option key={index} value={tagName.toUpperCase()}>
                          {tagName.toUpperCase()}
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}></Col>
            </Row>
          
          <Form.Item
            label={<div><label style={{ color: "red" }}>* </label><label>Reports</label></div>}
            name="reports"            
          >
            <Row gutter={[8, 24]}>
              {finalReports.map((item: any, index: number) => {
                let isSelected = this.state.capabilityCheckbox.includes(
                  item.id.toString()
                );
                return (
                  <Col span={12} key={index}>
                    <Checkbox
                      checked={isSelected}
                      value={item.id}
                      onChange={this.onCapabilityChange}                    
                    >
                      {item.name === "GatewayService" ? "Gateway" : (item.name === "ePTOExtended" ? "ePTOV2" : item.name)}

                    </Checkbox>
                    { item.name === "ePTOExtended" && isSelected &&
                      <Radio.Group name="ePTOExtendedRadio" onChange={(e) => { 
                        this.setState({currentUnitValue:e.target.value})
                      }} value={this.state.currentUnitValue}>
                      <Radio value={'gallons'}>Gallons</Radio>
                      <Radio value={'liters'}>Liters</Radio>                      
                    </Radio.Group>
                    }
                  </Col>
                );
              })}

              {finalReports && finalReports.length == 0 && (
                <span className="no-reports-access-text">
                  Org does not have reports access
                </span>
              )}
            </Row>
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: appConfigs.errors.fieldErrors.valueRequired,
              },
            ]}
          >
            <Input maxLength={255} placeholder="Schedule name" />
          </Form.Item>
          {this.formRef.current !== null && (
            <ScheduleMaintInfoUsers
              formRef={this.formRef.current}
              subscribers={subscribers}
            />
          )}
          <Form.Item
            name="frequency"
            label="Frequency"
            rules={[
              {
                required: true,
                message: appConfigs.errors.fieldErrors.valueRequired,
              },
            ]}
            help={ this.state.frequency &&
            <span style={{color:'#0052CC',fontSize:'14px'}}>{this.state.frequency == "Daily" ?
             "Retrive data from the previous day": (this.state.frequency == "CalendarWeek" ? 
             "Retrieve data from last week, spanning from Sunday to Saturday": (this.state.frequency == "CalendarMonth" ? 
             "Retrieve data from last month": (this.state.frequency == "Weekly" ? 
             "Retrieve data from the past seven days": (this.state.frequency == "Monthly" ? 
             "Retrieve data from the past 30 days": ""))))
             }</span> 
          }
          >
            <Select
              data-id="schedule-maint-frequency"
              showArrow={true}
              allowClear
              placeholder="Select frequency"
              onChange={this.onFrequencyChange}
            >
              <Option key="Daily" value="Daily" title="Retrieve data from the previous day">
                Daily
              </Option>
              <Option key="CalendarWeek" value="CalendarWeek" title="Retrieve data from last week, spanning from Sunday to Saturday">
                Calendar Week
              </Option>
              <Option key="CalendarMonth" value="CalendarMonth" title="Retrieve data from last month">
                Calendar Month
              </Option>
              <Option key="Weekly" value="Weekly" title="Retrieve data from the past seven days">
                Weekly
              </Option>
              <Option key="Monthly" value="Monthly" title="Retrieve data from the past 30 days">
                Monthly
              </Option>
            </Select>
          </Form.Item>
          {this.state.frequency && (this.state.frequency === "Weekly" || this.state.frequency === "CalendarWeek") && (
            <Form.Item
              label="Select week"
              name="dayofweeks"
              valuePropName="value"
              rules={[
                {
                  required: true,
                  message: appConfigs.errors.fieldErrors.valueRequired,
                },
              ]}
            >
              <Checkbox.Group>
                {weekOptions.map((item, index) => (
                  <Checkbox value={item.value} key={index}>
                    {item.label}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
          )}
          {this.state.frequency && (this.state.frequency === "Monthly" || this.state.frequency === "CalendarMonth") && (
            <Form.Item
              label="Select day"
              name="dayofmonth"
              rules={[
                {
                  required: true,
                  message: appConfigs.errors.fieldErrors.valueRequired,
                },
              ]}
            >
              <DatePicker
                format="DD"
                disabledDate={(d) =>
                  !d ||
                  d.isAfter(firstDayofNextMonth) ||
                  d.isSameOrBefore(firstDayofMonth)
                }
              />
            </Form.Item>
          )}
          <Form.Item 
          label={<div><label style={{ color: "red" }}>* </label><label>Schedule Time</label></div>}
          name="scheduletime"
          >
            <Row gutter={8}>
              <Col span={6}>
                <Form.Item
                  name="scheduletimeHours"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: appConfigs.errors.fieldErrors.valueRequired,
                    },
                  ]}
                >
                  <Select placeholder="Select hours">
                    <Option key="07" value="07">
                      7
                    </Option>
                    <Option key="08" value="08">
                      8
                    </Option>
                    <Option key="09" value="09">
                      9
                    </Option>
                    <Option key="10" value="10">
                      10
                    </Option>
                    <Option key="12" value="12">
                      12
                    </Option>
                    <Option key="13" value="13">
                      13
                    </Option>
                    <Option key="14" value="14">
                      14
                    </Option>
                    <Option key="15" value="15">
                      15
                    </Option>
                    <Option key="16" value="16">
                      16
                    </Option>
                    <Option key="17" value="17">
                      17
                    </Option>
                    <Option key="18" value="18">
                      18
                    </Option>
                    <Option key="19" value="19">
                      19
                    </Option>
                    <Option key="20" value="20">
                      20
                    </Option>
                    <Option key="21" value="21">
                      21
                    </Option>
                    <Option key="22" value="22">
                      22
                    </Option>
                    <Option key="23" value="23">
                      23
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="scheduletimeMintues"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: appConfigs.errors.fieldErrors.valueRequired,
                    },
                  ]}
                >
                  <Select placeholder="Select minutes">
                    <Option key="00" value="00">
                      00
                    </Option>
                    <Option key="15" value="15">
                      15
                    </Option>
                    <Option key="30" value="30">
                      30
                    </Option>
                    <Option key="45" value="45">
                      45
                    </Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    context: state.contextReducer.context,
    permissions: state.contextReducer.data,
    scheduledList: state.reportSchedulesReducers.reportScheduledState.scheduledList,
  };
};

export default connect(mapStateToProps, actions)(ScheduleMaintInfo);
