import React, { Children, useMemo } from "react";

import { Form, Select, Button, Tooltip } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";

type Value = string;
type ValueArray = Array<Value | undefined>;

interface Selectable {
  value: Value;
  label: string;
}

interface Props {
  options: Selectable[];
  maxSelected?: number;
  value?: ValueArray;
  onChange?: (v: ValueArray) => void;
  valueLabel?: string;
  orgCmdPgnSpns? : string;
}

const SelectorList: React.FC<Props> = (props) => {
  // Must be optional to play nice with Ant Design's forms but won't
  // actually work without them
  const assertDefined = (propName: keyof Props) => {
    if (props[propName] === undefined) {
      console.error(`Property '${propName} must be defined for ListSelector`);
    }
  };
  assertDefined("onChange");

  const { value = [], onChange } = props;

  const { add, remove, changeValue } = useMemo(() => {
    const triggerChange = (newValues: ValueArray) => {
      if (onChange) {
        onChange(newValues);
      }
    };

    const add = () => {
      triggerChange(value.concat(undefined));
    };

    const remove = (i: number) => {
      triggerChange([...value.slice(0, i), ...value.slice(i + 1)]);
    };

    const changeValue = (index: number, updatedValue: Value) => {
      const newVals = [...value];
      newVals[index] = updatedValue;
      triggerChange(newVals);
    };

    return { add, remove, changeValue };
  }, [onChange, value]);

  const filteredOptions = (v?: Value) => {
    return props.options.filter(
      (opt) => opt.value === v || !value.includes(opt.value)
    );
  };

  const onSearch = (val: string) => {
    return props.options.filter(
      (opt) => opt.label === val || !value.includes(opt.label)
    );
  }

  const maxReached = value.length >= (props.maxSelected || Infinity);
  const valueLabel = props.valueLabel ? `${props.valueLabel} ` : "";
  const tooltipMsg = value.length >= (props.maxSelected || Infinity) ? 'Max value added': 
    (props.orgCmdPgnSpns != null && props.orgCmdPgnSpns === '-' ? "Remote control is not enabled for the org": "Add signal");
  return (
    <div>
      {value.map((v, i) => (
        <Form.Item key={i}>
          <Select
            showSearch
            style={{ width: "95%" }}
            value={v}
            onChange={(v) => changeValue(i, v)}
            options={filteredOptions(v)}
            optionFilterProp={'label'}
            onSearch={onSearch}
          />
          <MinusCircleOutlined
            style={{ marginLeft: "8px" }}
            onClick={() => remove(i)}
          />
        </Form.Item>
      ))}
      <Tooltip title={tooltipMsg}>                  
      <Button disabled={maxReached  || props.orgCmdPgnSpns != null && (props.orgCmdPgnSpns === '-')} onClick={add} type="dashed">
        {maxReached ? `Max ${valueLabel}Added` : `Add ${valueLabel}`}
      </Button>
      </Tooltip>
    </div>
  );
};

export default SelectorList;