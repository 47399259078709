import React, { Component } from "react";
import {  Button, Card, Form, Input, Select, Space } from 'antd';
import { connect } from "react-redux";
import * as actions from "../actions";
import { appConfigs } from "../../../utils/configurations";
import OprHomeOrg from "./OprHomeOrg";
import { FormInstance } from 'antd/lib/form';
import { errorResponse, resetFormErrors, successResponse } from "../../../utils/apiHelpers/apiHelpers";
import { FormState,formInit } from "../../../utils/apiHelpers/formHelpers";
import { createDebuggerStatement } from "typescript";
import { ErrorAlert, SuccessAlert } from "../../../utils/Components/formAlerts";
import { assetIqanConnectKey } from "../../Assets/actions";

const { Option } = Select;
interface Prop{
    oprList:any,
    oprMaint:Function,
    oprsListRefreshOnUpdate: Function,
    context: any,
    oprMaintClose:Function,
    operatorOrgUsersGet:Function, 
    operatorOrgAssetsGet:Function,
    operatorOrgAccessTypesGet: Function
    rfidSetValue: Function
}

interface State extends FormState {
    accessType: string;
    rfidValue: string;
    isOveridePanelDisplay: boolean;
    overrideErrorMessage: string;
    isOverrideRFID: boolean;
}

class OprMaintInfo extends React.Component<Prop> {  
    state: State = {
        accessType: "",
        rfidValue: "",
        isOveridePanelDisplay: false,
        overrideErrorMessage: "",
        isOverrideRFID: false
    };
    formRef = React.createRef<FormInstance>();
    componentDidMount() {
        this.componentUpdate(this.props.oprList.oprMaint.record);
    }
    componentDidUpdate(prevProps: Prop) {
        if (this.props.oprList.oprMaint.instance !== prevProps.oprList.oprMaint.instance) {
            this.componentUpdate(this.props.oprList.oprMaint.record);
        }
    }
    componentUpdate(record:any) {
        formInit(this);    
        let orgId = (record.orgId || "") === "" ? this.props.context.appContext.orgId:record.orgId;
        this.props.operatorOrgUsersGet(this, null, null,orgId);
        this.props.operatorOrgAssetsGet(this, null, null, 'all', orgId);
        this.props.operatorOrgAccessTypesGet(this, null, null, 'all', orgId);
        
        if (this.formRef.current != null) {
            this.formRef.current!.resetFields(); 
            this.formRef.current!.setFieldsValue({
                orgId: (record.orgId || "") === "" ? (this.props.context.appContext.roleName === "admin" ? this.props.context.appContext.orgId: null) : record.orgId,    
                operatorIdValue:record.operatorIdValue,
                accessType:record.accessType,
                accessCardValue:record.accessCardValue,
                userId:record.userId,
                assetName:record.assetName,
                accessCardId:record.accessCardId,
                mastertags: record.mastertags?.map((assetTag:any) => {return {value: assetTag.mastertag, label: assetTag.mastertag, key: assetTag.mastertag} }),
                id:record.id
              
            });
        }
    }
   
    onFinish = (values: any) => {
        resetFormErrors(this, this.formRef.current);
        this.props.oprMaint(this, this.onFinishSuccess, this.onFinishFailure, values, this.props.oprList.oprMaint.record.operatorsHistoryId, this.state.isOverrideRFID);
    };

    onFinishSuccess = (record: any) => { 
        this.props.oprsListRefreshOnUpdate(this);
        
        if (record != null && record.operatorIdValue != null && 
            Object.keys(record.operatorIdValue) != null && Object.keys(record.operatorIdValue).length !== 0) {
            this.componentUpdate(record);
        }
        this.setState({ isOverrideRFID: false, isOveridePanelDisplay: false, overrideErrorMessage: "" });
        successResponse(this, "Operator has been successfully processed");   
    }
    onFinishFailure = (error: any) => { 
        if (error.response.data.errors[0].reference === "override") {
            const errMsg = error.response.data.errors[0].message;
            this.setState({ isOveridePanelDisplay: true, overrideErrorMessage: errMsg });
        }
        else {
            this.setState({ isOverrideRFID: false, isOveridePanelDisplay: false, overrideErrorMessage: "" });
            errorResponse(this, error);
        }
    }

    onOverrideSubmit = (e: any) => {
        this.setState({ isOverrideRFID: true });
        this.formRef.current?.submit();
    }
    cancelOverride = (e: any) => {
        this.setState({ isOverrideRFID: false, isOveridePanelDisplay: false, overrideErrorMessage: "" });
    } 
    
    onAccessTypeChange = (value:any) => {
        this.setState({ accessType: value });
        if (value.toLowerCase() !== 'rfid') {
            this.setState({ rfidValue: ""})
            this.props.rfidSetValue(this, "");
        }
    };  

    onInputRfidChange = (value:any) => {
        this.setState({ rfidValue: value || "" == ""? "" : value.target.value})
        this.props.rfidSetValue(this, value || "" == ""? "" : value.target.value);
    };        

    render(){
        const { orgUsers, orgAssets, orgAccessTypes, rfidValue } = this.props.oprList.oprMaint.tabs[0];
        const {operatorsHistoryId, mastertags, accessType} = this.props.oprList.oprMaint.record;
 
        return(
            <div  data-id="opr-maint-info">
                 {this.state.formSuccess && !this.state.formError && <SuccessAlert data-id="oprs-maint-info-success-message"  message={this.state.formSuccessMessage}/>}
                {this.state.formError && !this.state.formSuccess && <ErrorAlert data-id="oprs-maint-info-error-message" message={this.state.formErrorMessage}/>}

                {
                    this.state.isOveridePanelDisplay &&

                    <Card style={{ height: '150px', backgroundColor: '#d9d9d94d' }}>
                        <p>{this.state.overrideErrorMessage}.</p>
                        <p>Do you want to override operator?</p>
                        <Space size="large" style={{ float: 'right' }}>
                            <Button data-id="opr-maint-close-button" type="default" onClick={(e) => this.cancelOverride(e)}>
                                No
                </Button>
                            <Button data-id="opr-maint-submit-button" type="primary" form="oprMaintInfoForm" key="submit" onClick={(e) => this.onOverrideSubmit(e)}>
                                Yes
                  </Button>

                        </Space>
                    </Card>

                }
           <Form
            {...appConfigs.settings.formLayout.mainLayout}
            id="oprMaintInfoForm"
            ref={this.formRef}
            onFinish={this.onFinish}
            className="mt-15"
        >       
           <OprHomeOrg formRef={this.formRef.current}/>
           <Form.Item 
                name="id"
                style={{ display: 'none' }}
            >
                <Input type="hidden" />
            </Form.Item>
            <Form.Item 
                name="accessCardId"
                style={{ display: 'none' }}
            >
                <Input type="hidden" />
            </Form.Item>
            <Form.Item 
                name="assetId"
                style={{ display: 'none' }}
            >
                <Input type="hidden" />
            </Form.Item>
                         
            <Form.Item
                label="Operator ID"
                name="operatorIdValue"                                 
                rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
            >
                <Input maxLength={50}/>
            </Form.Item>

            <Form.Item
                label="Access Type"
                name="accessType"                            
                // rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
            >
                 <Select 
                            showSearch
                            showArrow={true}
                            allowClear
                            placeholder="Select..."
                            optionFilterProp="children"
                            className="select-before"   
                            onChange={this.onAccessTypeChange}                          
                        >

                       {
                        orgAccessTypes !== null && orgAccessTypes.map((accessType:any, index:number) => {
                            return (
                            <Option key={index} value={accessType}>
                                {accessType}
                            </Option>
                            )})} 
                        </Select> 
                            
                                                                                                 
            </Form.Item>
            {
                   
                    <Form.Item
                    label="RFID"
                    name="accessCardValue"                            
                    rules={[{ required: false, message: appConfigs.errors.fieldErrors.valueRequired }]}
                    >
                    <Input value = {this.state.rfidValue} onChange={this.onInputRfidChange}
                         disabled={
                        ((operatorsHistoryId||"")==="" && ((this.state.accessType ||"") === "RFID" || accessType === "RFID"))?false:true}  maxLength={10}/>
                    </Form.Item>

            }
           
            <Form.Item
                        name="userId"
                        label="User"
                     >                    
                        <Select 
                            showSearch
                            showArrow={true}
                            allowClear
                            placeholder="Select..."
                            optionFilterProp="children"
                            className="select-before" 
                                                    
                        >
                            {orgUsers !== null && orgUsers.map((record:any, index:number) => (
                                <Option key={record.userId} value={record.userId}>
                                    {record.fullName}
                                </Option>
                            ))}
                        </Select>                         
                    </Form.Item>

                    <Form.Item
                    name="assetName"
                    label="Asset Last Accessed"
                    style={{display:(this.props.oprList.oprMaint.record.assetName||"")==""?'none':''}}
                    >
                     <Input disabled maxLength={32}/>
                    </Form.Item>

                <Form.Item
                    label="Controlled Assets"
                    name="mastertags"
                
                    initialValue={mastertags == null ? [] : mastertags?.map((asset: any) => {
                             return { value: asset.mastertag, label: asset.mastertag, key: asset.mastertag }
                             })} 
                >            
                <Select 

                        mode="tags"
                        style={{ width: '100%'}}
                        placeholder="Select mastertags"
                        disabled={((this.state.accessType ||"") === "RFID" || 
                            (accessType === "RFID" && (this.state.accessType || "").toLowerCase() !== "asset-mapping")) ||
                            this.props.oprList.oprMaint.record.status == 'inactive' } 
                         labelInValue
                        showSearch
                        showArrow={true}
                        allowClear
                        // optionFilterProp="children"
                        className="select-before" 
                                                
                    >
                        {orgAssets !== null && orgAssets.map((mastertag:any, index:number) => {
                            return (
                            <Option key={index} value={mastertag}>
                                {mastertag}
                            </Option>
                        )})}
               
                </Select>     
                 
                </Form.Item>                 
                            
            </Form>
            
            </div>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
       oprList: state.oprReducers.oprState.oprList
    };
};

export default connect(
    mapStateToProps,
    actions
)(OprMaintInfo);