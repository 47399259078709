import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function signalCollectionsSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "SIGNAL_COLLECTIONS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}   

export function signalCollectionsSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "SIGNAL_COLLECTIONS_SET_SEARCH", searchText: searchText });
    }
}   

export function getSignalCollection(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string,
    signalCollectionId: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/templates/"+ templateId +"/signal-collections/" + signalCollectionId, {
                responseType: "json",        
                headers: {},
                params: {
                    page: 1,
                    pageSize: 999
                }
            }
        )
        .then(response => {          
                let record = response.data.data.signalCollections == null || (response.data.data.signalCollections.length || 0) === 0 ? {} : response.data.data.signalCollections[0];
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(record);
                }
                dispatch({ type: "SIGNAL_COLLECTION_MAINT_SET_RECORD", record: record });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function getSignalCollections(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string,
    page: number = 1,
    searchText?: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/templates/"+ templateId + "/signal-collections", {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText,
                    page: page,
                    pageSize: appConfigs.tables.pageSize
                }
            }
        )
        .then(response => {          
                let data = response.data.data.signalCollections == null || (response.data.data.signalCollections.length || 0) === 0 ? [] : response.data.data.signalCollections;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "SIGNAL_COLLECTIONS_GET_SUCCESS", payload: {data: data, page: page} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function signalCollectionDelete(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string,
    signalCollectionId: string
) {
    return async (dispatch: any) => {
        Axios.delete(
            appConfigs.server.URL + "/ui/api/templates/"+ templateId + "/signal-collections/" + signalCollectionId,
            {}
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(templateId, signalCollectionId);           
                }
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}
