import { produce as immerProduce } from "immer";
import { initialState } from "./supportModeState";

export function supportModeReducer(state = initialState, action:any) {
    switch (action.type) {    
        case "SUPPORT_MODE_INIT":
            return immerProduce(state, draft => {
                let supportMode:string = window.sessionStorage.getItem("support-mode") || "{}";    
                if (supportMode !== "{}") {
                    let supportModeJson = JSON.parse(supportMode);          
                    draft.orgId = supportModeJson.orgId;
                    draft.orgName = supportModeJson.orgName;
                    draft.isSupportMode = true;
                } 
            });      
        case "SUPPORT_MODE_SET":           
            return immerProduce(state, draft => {
                window.sessionStorage.setItem("support-mode", JSON.stringify({orgId: action.orgId, orgName: action.orgName}));                
                draft.orgId = action.orgId;
                draft.orgName = action.orgName;
                draft.isSupportMode = true;
            });      
        case "SUPPORT_MODE_UNSET":
            return immerProduce(state, draft => {
                window.sessionStorage.setItem("support-mode", "{}");           
                draft.orgId = "";
                draft.orgName = "";
                draft.isSupportMode = false;
            });                      
        default:
            return state;
    }
}