import { Button, Card, Col, Divider, Drawer, Form, Input, Row, Select, Space, Tabs, Upload, message } from "antd";
import { FormInstance } from 'antd/lib/form';
import moment from "moment";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { formInit } from "../../../utils/apiHelpers/formHelpers";
import { appConfigs } from "../../../utils/configurations";
import * as actions from "./actions";
import { InboxOutlined } from '@ant-design/icons';
import { UploadProps } from "antd/lib/upload";

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;
const { Dragger } = Upload;

const dragProps: UploadProps = {
    name: 'file',
    multiple: false,
    beforeUpload(fileInfo: any) {
        return false;
    },
};

interface Prop {
    context: any,
    controllersList: any,
    applicationMaintOptions: any,
    closeDrawer: Function,
    drawerTabChange: Function,
    ApplicationMaint: Function,
    getApplications: Function,
}

const ControllerMaint: React.FC<Prop> = (props) => {

    const formRefNewApp = React.createRef<FormInstance>();
    const formRefNewVersion = React.createRef<FormInstance>();
   
    const onDrawerClose = () => {
        props.closeDrawer();
    };

    const onTabChange = (key: string) => {
        props.drawerTabChange(key);
    }

    useEffect(() => {

        console.log(props.applicationMaintOptions.record)

        if (formRefNewApp.current != null) {
            formRefNewApp.current!.resetFields();    
            formRefNewApp.current!.setFieldsValue({
                applicationId: props.applicationMaintOptions.record.id,
                applicationName: props.applicationMaintOptions.record.name,
                type: props.applicationMaintOptions.record.type,
                version: props.applicationMaintOptions.record.version,
                requiredApproval: props.applicationMaintOptions.record.requiresApproval,
                compatibleControllers: props.applicationMaintOptions.record.controllerId,
                description: props.applicationMaintOptions.record.description
            });
        }
    
    }, [props])


    const onFinish = (values: any) => {
        // console.log(values)
        props.ApplicationMaint(onFinishSuccess, onFinishFailure, values)
    }

    const onFinishSuccess = (record: any) => { 
        message.success("Application Added Successfully");
        props.getApplications(null, null, null, 1, "", {});
        props.closeDrawer();
    }

    const onFinishFailure = (error: any) => { 
        message.error("Error posting data");
    }

    const getUploadFile = (e:any) => {
        console.log('Upload event:', e);

        // if (Array.isArray(e)) {
        //     return e;
        // }
        return e && e.fileList;
    };
        
    return (
        <Drawer
            title={props.applicationMaintOptions.title}
            width={props.applicationMaintOptions.width}
            onClose={onDrawerClose}
            visible={props.applicationMaintOptions.visible}
            destroyOnClose={true}
            footer={
                <Space size="large" style={{ float: 'right' }}>
                    <Button type="default" onClick={onDrawerClose}>
                        {appConfigs.settings.drawers.closeButtonText}
                    </Button>

                    <Button data-id="applications-maint-submit-button" type="primary" form="applicationMaintOptionsInfoForm" key="submit" htmlType="submit">
                        {appConfigs.settings.form.submitButtonText}
                    </Button>
                    
                </Space>
            }
        >
            {
                <Tabs activeKey={props.applicationMaintOptions.activeTab} size="large" type="card" onChange={onTabChange}>                    
                    <TabPane tab={props.applicationMaintOptions.tabs[0].title} key="1" disabled={props.applicationMaintOptions.tabs[0].disabled}>
                        <Form
                            id="applicationMaintOptionsInfoForm"
                            ref={formRefNewApp}
                            layout="vertical"
                            onFinish={onFinish}
                        >       
                            <Row gutter={8}>
                                <Col span={24}>
                                    <Form.Item name="applicationId" style={{ display: 'none' }} >
                                        <Input type="hidden" />
                                    </Form.Item>
                                </Col> 
                            </Row>   
                          
                            <Row gutter={8}>
                                <Col span={24}>
                                    <Form.Item
                                        label="Application Name"
                                        name="applicationName"    
                                        shouldUpdate={true}                                 
                                        rules={[
                                            { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                                        ]}
                                    >
                                        <Input maxLength={255} disabled={props.applicationMaintOptions.record.status == 'inactive'} />
                                    </Form.Item>
                                </Col>                        
                                
                            </Row>    
                            
                            <Row gutter={8}>
                        
                                <Col span={12}>
                                    <Form.Item
                                        label="Type"
                                        name="type"
                                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >
                                        <Select>
                                            <Option value="application">Application</Option>
                                            <Option value="firmware">Firmware</Option>
                                            <Option value="Runtime">Runtime</Option>
                                            <Option value="bsp">BSP</Option>
                                            <Option value="Others">Others</Option>
                                        </Select>

                                    </Form.Item>
                                </Col> 
                            

                                <Col span={12}>
                                    <Form.Item
                                        label="Version"
                                        name="version"                                 
                                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >
                                        <Input maxLength={255} disabled={props.applicationMaintOptions.record.status == 'inactive'} />
                                    </Form.Item>
                                </Col> 

                                <Col span={12}>
                                    <Form.Item
                                        label="Requires Operator Approval"
                                        name="requiredApproval"                                 
                                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >
                                        <Select>
                                            <Option value="no">No</Option>
                                            <Option value="yes">Yes</Option>
                                        </Select>

                                    </Form.Item>
                                </Col> 

                                <Col span={12}>
                                    <Form.Item
                                        label="Compatible Controllers List"
                                        name="compatibleControllers"                                 
                                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >

                                        <Select>
                                            {props.controllersList !== null && props.controllersList.map((controller:any, index:number) => (
                                                <Option key={controller.id} value={controller.id} >
                                                    {controller.name}
                                                </Option>
                                            ))}
                                        </Select>
                                        
                                    </Form.Item>
                                </Col> 

                            </Row>

                            <Row gutter={8}>
                        
                                <Col span={24}>
                                    <Form.Item
                                        label="Description"
                                        name="description"                                 
                                        rules={[{ required: false, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >
                                        <TextArea rows={4} disabled={props.applicationMaintOptions.record.status == 'inactive'} />
                                    </Form.Item>
                                </Col> 

                                <Col span={24}>
                                <Form.Item
                                        label="Upload File"
                                        name="fileUpload"
                                        getValueFromEvent={getUploadFile}
                                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >
                                        <Dragger {...dragProps}>
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                            <p className="ant-upload-hint">Support for a single upload only. This file which will be loaded in to the controller
                                            </p>
                                        </Dragger>
                                    </Form.Item>
                                    
                                </Col>
                            
                            </Row>


                        </Form>
                    </TabPane> 



                    {/* <TabPane tab={props.applicationMaintOptions.tabs[1].title} key="2" disabled={props.applicationMaintOptions.tabs[1].disabled}>
                        <Form
                            id="applicationMaintOptionsVersionInfoForm"
                            ref={formRefNewVersion}
                            layout="vertical"
                            onFinish={onFinish}
                        >       
                            <Row gutter={8}>
                                <Col span={24}>
                                    <Form.Item name="applicationId" style={{ display: 'none' }} >
                                        <Input type="hidden" />
                                    </Form.Item>
                                </Col> 
                            </Row>   
                          
                            <Row gutter={8}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Application Version"
                                        name="applicationVersion"    
                                        shouldUpdate={true}                                 
                                        rules={[
                                            { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                                        ]}
                                    >
                                        <Input maxLength={255} disabled={props.applicationMaintOptions.record.status == 'inactive'} />
                                    </Form.Item>
                                </Col>
                                
                                <Col span={12}>
                                    <Form.Item
                                        label="Requires Operator Approval"
                                        name="requiredApproval"
                                        rules={[
                                            { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                                        ]}
                                    >
                                        <Select>
                                            <Option value="no">No</Option>
                                            <Option value="yes">Yes</Option>
                                        </Select>
                                    </Form.Item>
                                </Col> 
                                
                                <Col span={12}>
                                    <Form.Item
                                        label="Previous Compatible Version"
                                        name="type"                                 
                                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >
                                        <Input maxLength={255} disabled={props.applicationMaintOptions.record.status == 'inactive'} />
                                    </Form.Item>
                                </Col> 

                                <Col span={24}>
                                    <Form.Item
                                        label="Description"
                                        name="description"                                 
                                        rules={[{ required: false, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >
                                        <TextArea rows={4} disabled={props.applicationMaintOptions.record.status == 'inactive'} />
                                    </Form.Item>
                                </Col>


                                <Col span={24}>
                                    <Form.Item
                                        label="Upload File"
                                        name="fileUpload"                                 
                                        rules={[{ required: false, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >
                                        <TextArea rows={4} disabled={props.applicationMaintOptions.record.status == 'inactive'} />
                                    </Form.Item>
                                </Col>

                            </Row>    


                        </Form>
                    </TabPane>  */}

                </Tabs>   
            }         
        </Drawer>
    );
}

const mapStateToProps = (state: any) => {
    return {
        context: state.contextReducer.context,
        applicationMaintOptions: state.applicationsReducers.applicationsState.applications.applicationMaintOptions,
        controllersList: state.applicationsReducers.applicationsState.applications.controllersList
    };
};

export default connect(
    mapStateToProps,
    actions
)(ControllerMaint);