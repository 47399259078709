import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";
import { sortAlphabetically } from "../../../utils/commonHelpers";

export function assetsSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "ASSETS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}   

export function assetsSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "ASSETS_SET_SEARCH", searchText: searchText });
    }
}   

export function assetsSetStatus(
    status: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "ASSETS_SET_STATUS", status: status });
    }
}   

export function assetsSetGatewayId(
    gatewayId: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "ASSETS_SET_GATEWAY_ID", gatewayId: gatewayId });
    }
}   

export function assetsMaintSetTitle(
    me: any,
    title: string
) {
    return (dispatch: any) => {
        dispatch({ type: "ASSETS_MAINT_SET_TITLE", title: title });
    }
}  

export function assetsMaintRefreshInstance(
    me: any,
    instances: number[] 
) {
    return (dispatch: any) => {
        dispatch({ type: "ASSETS_MAINT_REFRESH_INSTANCE", instances: instances });
    }
}   

export function assetMaintTabChange(
    me: any,
    activeTab: string
) {
    return (dispatch: any) => {
        dispatch({ type: "ASSETS_ASSET_MAINT_TAB_CHANGE", activeTab: activeTab });
    };
}

export function getAsset(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    gatewayId: string
) {   
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/assets/" + gatewayId, {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {                    
                    let record = response.data.data.assets == null || (response.data.data.assets.length || 0) === 0 ? {} : response.data.data.assets[0];
                    callbackFnSuccess(record);
                }
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error);
            }
            errorResponse(me, error);
        });
    };
}

export function getAssets(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    page: number = 1,
    status: string,
    searchText?: string,
    alertInterval?: string,
    gatewayId?: string,
    listMode?: boolean,
    sortColumn?: string,
    sortDirection?: string,
    filters?: any[any]
) {
    return async (dispatch: any) => {
        
        let filtersObj:any = []; //This will allow for column by column filteration and search in the future DB function is looking at tagsFilter
        filters && filters['tags'] != null ? filtersObj = {'tagsFilter': filters['tags']} : filtersObj = {}
        
        Axios.get(
            appConfigs.server.URL + "/ui/api/assets" + (gatewayId ? "/" + gatewayId : ""), {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText,
                    filters: encodeURIComponent(JSON.stringify(filtersObj)),
                    alertInterval: alertInterval,
                    status: status,
                    listMode: listMode,
                    sortColumn: sortColumn,
                    sortDirection: sortDirection,
                    page: page,
                    pageSize: appConfigs.tables.pageSize
                }
            }
        )
        .then(response => {   
                let data = response.data.data.assets == null || (response.data.data.assets.length || 0) === 0 ? [] : response.data.data.assets;
                
                data.forEach((item: any, index: number) => {
                    data[index]['tags'] = JSON.parse(data[index]['tags'])
                    data[index]['tags'] = data[index]['tags']?.sort(sortAlphabetically)
                });

                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
            
                dispatch({ type: "ASSETS_GET_ASSETS_SUCCESS", payload: {data: data, page: page} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function getFilterTags(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    status: string,
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/tags", {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {                  
                let data = response.data.data.orgTags == null || (response.data.data.orgTags.length || 0) === 0 ? [] : response.data.data.orgTags;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                
                /* gets tag values and filter to get only unique tag names and sort alphabetically */
                data = data.map((tag:any) => tag['tagName'].toUpperCase()).filter((item:any, i:any, ar:any) => ar.indexOf(item) === i).sort();
                
                dispatch({ type: "ASSETS_FILTER_TAGS_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function editTagSubmit(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: string,
    oldTagName: string,
    newTagName: string,
    actionType: string,
) {

    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/edit_tag", {
                responseType: "json",        
                headers: {},
                params: {
                    orgId: orgId,
                    oldTagName: oldTagName,
                    newTagName: newTagName,
                    actionType: actionType,
                }
            }
        )
        .then(response => {                  
                let data = response.data.data.orgTags == null || (response.data.data.orgTags.length || 0) === 0 ? [] : response.data.data.orgTags;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function assetsListRefreshOnUpdate(
    me: any
) {
    return (dispatch: any) => {
        dispatch({ type: "ASSETS_LIST_REFRESH_ON_UPDATE" });
    };
}

export function assetMaintOpen(
    me: any, 
    title: string,
    width: number,
    record: any,
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
) {
    return async (dispatch: any) => {

        Axios.get(
            appConfigs.server.URL + "/ui/api/tags", {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {  
                let data = response.data.data.assetTags == null || (response.data.data.assetTags.length || 0) === 0 ? [] : response.data.data.assetTags;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "ORG_TAGS_SUCCESS", payload: {data: data} });
                dispatch({ type: "ASSETS_ASSET_MAINT_OPEN", payload: {title: title, width: width, record: record} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
        
        // dispatch({ type: "ASSETS_ASSET_MAINT_OPEN", payload: {title: title, width: width, record: record} });
    };
}

export function assetMaintClose(
    me: any
) {   
    return (dispatch: any) => {
        dispatch({ type: "ASSETS_ASSET_MAINT_CLOSE" });
    };
}

export function assetMaintTabsEnable(
    me: any
) {
    return (dispatch: any) => {
        dispatch({ type: "ASSETS_ASSET_MAINT_TABS_ENABLE" });
    };
}

export function getLiteAssets(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    page: number = 1,
    searchText?: string,
    gatewayId?: string,
    sortColumn?: string,
    sortDirection?: string,
    
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/assets-lite"+ (gatewayId ? "/" + gatewayId : "") , {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText,
                    sortColumn: sortColumn,
                    sortDirection: sortDirection,
                    page: page,
                    pageSize: appConfigs.tables.pageSize
                }
            }
        )
        .then(response => {  
          
            let data = response.data.data.assets == null || (response.data.data.assets.length || 0) === 0 ? [] : response.data.data.assets;
            if (callbackFnSuccess != null) {
                callbackFnSuccess(data);
            }
            dispatch({ type: "LITE_ASSETS_GET_SUCCESS", payload: {data: data} });
        })
        .catch(function (error) {
        
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });

    }

}

export function liteAssetsSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "LITEASSETS_SET_SEARCH", searchText: searchText });
    }
}

export function blockAsset(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    gatewayId?: string,
    isBlocked?: boolean,
    orgId?:string
) {
    Axios.post(
            appConfigs.server.URL + "/ui/api/assets/blockasset"+ (gatewayId ? "/" + gatewayId : "") ,null, {
            params: {
                isBlocked: isBlocked,
                orgId: orgId
            }
        }
    )
    .then(response => {
        let data = response.data.data.assets == null || (response.data.data.assets.length || 0) === 0 ? [] : response.data.data.assets;
            if (callbackFnSuccess != null) {
                callbackFnSuccess(isBlocked);
            }
    })
    .catch(function (error) {
        
        if (callbackFnFailure != null) {
            callbackFnFailure(me, error)
        }
        errorResponse(me, error);
    });

    return (dispatch: any) => {
        dispatch({ type: "LITEASSETS_SET_SEARCH", searchText: null });
    }
}

export function liteAssetsSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "LITEASSETS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
} 

export function getCustomerBucketDetails(
    organizationId: any,
    callbackFnSuccess?: any,
    callbackFnFailure?: any
) {
	return (dispatch: any) => {
		Axios.get(appConfigs.server.URL + "/ui/api/get_customer_bucket_details", {
			responseType: "json",
			headers: {},
			params: {
				orgId: organizationId,
			},
        }).then((response) => {
            console.log(response.data)
			dispatch({ type: "GET_CUSTOMER_BUCKET_DETAILS", payload: { customerBucketDetails: response.data.data.customerBucketDetails } });
		}).catch(function (error) {
            errorResponse(null, error);
        });
	};
}

export function getGatewayContractsDetails(
    gatewayId: any,
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
) {
	return (dispatch: any) => {
		Axios.get(appConfigs.server.URL + "/ui/api/get_contract_details", {
			responseType: "json",
			headers: {},
			params: {
                gatewayId: gatewayId
			},
		}).then((response) => {
            if (callbackFnSuccess != null) {
                callbackFnSuccess(response.data.data.contractStatus)
            }
		}).catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(error)
            }
            errorResponse(null, error);
        });
	};
}
export function getServicePlansComparisionSheet() {
    
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/get_tutorials"
        )
        .then(response => { 
                dispatch({ type: "TUTORIALS_GET_SUCCESS", data: response.data.data.tutorials });
                console.log(response.data.data.tutorials);
            }
        )
        .catch(function (error) {
            console.log(`error`, error)
        });
    };
}