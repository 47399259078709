import React, { Component } from "react";
import { Helmet } from "react-helmet"
import Axios from "axios";
import { appConfigs } from "./utils/configurations";

interface State{
    versionNo: string
}

class VersionNumber extends Component<{},State>{
    state: State={
        versionNo: ""
    }

    versionNumberGet = async()=> {        
        Axios.get(
            appConfigs.server.URL + "/public/ui/api/platform-versions?latest=" + true, 
        { 
            transformRequest: [(data, headers) => {          
                delete headers.common.Authorization;
            }] 
        }).then(response => {
            this.setState({versionNo:  "IQAN Connect v" + response.data.data.preferences[0].version});
           
        })          
    }

    componentDidMount() {
        this.versionNumberGet();        
    }

    render(){

        return(
            <Helmet>
                 <title>
                    {                        
                     (window.location.href.toLowerCase().includes(appConfigs.server.claytonServerURL.toLowerCase()))
                      ? "Clayton Equipment v" + this.state.versionNo.split("v").pop()
                      : (window.location.href.toLowerCase().includes(appConfigs.server.hoistServerURL.toLowerCase()))
                      ?"Hoist Telematics"
                      : (window.location.href.toLowerCase().includes(appConfigs.server.toyotaServerURL.toLowerCase()))
                      ?"THD Telematics"
                      : this.state.versionNo
                    }
                </title>             
            </Helmet> 
        );
    }
}

export default VersionNumber;
