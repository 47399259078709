import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function csuState(state = initialState, action:any) {
    let data:any = null,
        payload:any;

    switch (action.type) {
        case "CSU_HISTORY_SET_PAGINATION":
            return immerProduce(state, draft => {
                draft.csu.csuList.history.tableConfig.pagination.onChange = action.onPaginationChange;
            });
        case "CSU_ALL_SET_PAGINATION":
            return immerProduce(state, draft => {
                draft.csu.csuList.all.tableConfig.pagination.onChange = action.onPaginationChange;
            });

        case "CSU_HISTORY_DATA":
            return immerProduce(state, draft => {
                draft.csu.csuList.history.tableData = action.payload.data;
                draft.csu.csuList.history.tableConfig.pagination.total = (action.payload.data.length || 0) > 0 ? action.payload.data[0].totalRecords : 0;//(action.payload.data.length || 0) > 0 ? action.payload.data.length : 0;
                draft.csu.csuList.history.tableConfig.pagination.current = action.payload.page;
            });
        
        case "CSU_ALL_DATA":
            return immerProduce(state, draft => {
                draft.csu.csuList.all.tableData = action.payload.data;
                draft.csu.csuList.all.tableConfig.pagination.total = (action.payload.data.length || 0) > 0 ? action.payload.data[0].totalRecords : 0;
                draft.csu.csuList.all.tableConfig.pagination.current = action.payload.page;
            });
        case "CALIBRATION_MAINT_OPEN":
            return immerProduce(state, draft => {
                const 
                    payload = action.payload,
                    instance = Math.random();
                draft.csu.csuList.calibrationMaint.instance = instance;
                draft.csu.csuList.calibrationMaint.width = payload.width;
                draft.csu.csuList.calibrationMaint.visible = true;
                draft.csu.csuList.calibrationMaint.title = payload.title;
                draft.csu.csuList.calibrationMaint.record = payload.record;
    
                draft.csu.csuList.calibrationMaint.activeTab = "1";
                draft.csu.csuList.calibrationMaint.tabs[0].disabled = false;
                draft.csu.csuList.calibrationMaint.tabs[0].instance = instance;
            });
    
        case "SET_SEARCH":
            return immerProduce(state, draft => {
                draft.csu.csuList.all.search = action.search;
                draft.csu.csuList.calibrationMaint.record = {};
            });
        case "CSU_MAINT_CLOSE":
            return immerProduce(state, draft => {
                draft.csu.csuList.calibrationMaint.visible = false;
                draft.csu.csuList.calibrationMaint.record = {};
            });
        case "ASSETS_ASSET_MAINT_TAB_CHANGE":
            return immerProduce(state, draft => {
                draft.csu.csuList.calibrationMaint.activeTab = action.activeTab;
            });                
        default:
            return state;
        }
        
    }