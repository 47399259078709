import React from "react";
import { Form, Card } from "antd";


import {
  EditFormProps,
  WidgetTitleFormField,
} from "../../WidgetEditForm";
import { Config } from ".";
import EventsLocationSignalSelectorList from "./EventsLocationSignalSelectorList";
import { Signal } from "../../../Dashboard/models";

function sanitizeInputs(c: Config & {
  preselectedSignals: any[];
}): Config {

  return {
    title: c.title,
    signals: c.preselectedSignals.filter((ps) => !!ps).concat(c.signals.filter((s) => !!s)) ,
    type: "map events",
  };
}

const EditForm: React.FC<EditFormProps> = (props) => {

  /* Removing Preselected lng and lat signals from SelectorList (onEdit) */
  let gpsSignalArray:any = []
  props.template.signals.map((s) => {
    if (s.name.toLowerCase().indexOf("longitude") !== -1 ||
      s.name.toLowerCase().indexOf("latitude") !== -1) {
      gpsSignalArray.push(s.id)
    }
  });
  
  let initialValues
  if (props.config != undefined) {
    initialValues = JSON.parse(JSON.stringify(props.config));
    initialValues.preselectedSignals = initialValues?.signals?.filter((s: any, i: any) => gpsSignalArray.includes(s.id))
    initialValues.preselectedSignals = initialValues?.preselectedSignals?.map((s: any) => s.id)
    initialValues.signals = initialValues?.signals?.filter((s: any, i: any) => !gpsSignalArray.includes(s.id))
  } else {
    initialValues = undefined
  }

  return (
    <div className="text-widget-edit-form">
      <Form
        onFinish={(values) => props.onFinish(sanitizeInputs(values as Config & { preselectedSignals: any[] }))}
        layout="vertical"
        form={props.form}
        initialValues={initialValues}
      >
        <Card title="GPS Events Widget Settings">
          <WidgetTitleFormField />
          <EventsLocationSignalSelectorList
            max={6}
            signals={props.template.signals}
            signalCollections={props.template.signalCollections}
            rules={props.template.rules}
            selectedGPSSignalsEdit={ initialValues?.preselectedSignals }
          />
        </Card>
      </Form>
    </div>
  );
};

export default EditForm;
