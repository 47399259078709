import React from "react";
import {Table } from 'antd';
import { connect } from "react-redux";
import * as actions from "../actions";
import { localizeUtcDate } from "../../../utils/commonHelpers";

interface Prop {
  assetsList: any,
 }
 interface State {
   columns:any
 }
class AssetHistory extends React.Component<Prop, State> {
  state: State = {
     columns:[]
  }
   componentDidMount(){
     this.setState({columns:this.tableColumns})
   }
   tableColumns = [
    // {
    //   title: 'Replaced Order',
    //   dataIndex: 'replacedOrder',
    //   key: 'Sequence',
    //   align: 'left' as 'left',
    //   width: '35%',
    // },
    {
      title: 'Mastertag',
      dataIndex: 'mastertag',
      key: 'mastertag',
      width: '20%',
      align: 'left' as 'left'
    },
    {
      title: 'Old Mastertag',
      //dataIndex: 'oldMastertag',
      key: 'oldMastertag',
      width: '20%',
      align: 'left' as 'left',
      render: (text: string, record: any) => (
        <>
        {
          record.oldMastertag && record.oldMastertag !=="null"
          ?
          record.oldMastertag
          :'NA'
        }
        </>
    ) 
    },
    {
      title: 'Reason',
      //dataIndex: 'reasonForReplacement',
      key: 'reasonForReplacement',
      width: '25%',
      align: 'left' as 'left',
      render: (text: string, record: any) => (
        <>
        {
          record.reasonForReplacement && record.reasonForReplacement !== "null"
          ?
          record.reasonForReplacement
          :'NA'
        }
        </>
    ) 
    },
    {
      title: 'Replaced Date',
      //dataIndex: 'replacedDate',
      key: 'replacedDate',
      width: '35%',
      align: 'left' as 'left',
      render: (text: string, record: any) => (
        <>
        {
          record.replacedSequence !== 0
          ?
          localizeUtcDate(record.replacedDate, this.props.assetsList.assetMaint.dateFormatOptions)
          :'NA'
        }
        </>                    
    ) 
    
    }
    
  ]
  
     render() {    
        return (
            <Table id="history" columns={this.state.columns} 
            bordered 
            dataSource={this.props.assetsList.assetMaint.record.assetHistory} />
        )
    }
}
const mapStateToProps = (state:any) => {
  return {
      context: state.contextReducer.context,
      assetsList: state.assetsReducer.assetsState.assetsList,
  };
};

export default connect(
  mapStateToProps,
  actions
)(AssetHistory);