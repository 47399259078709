import React from "react";
import { Form, Row, Col, Table, Collapse, Select, Tooltip, Button, Space, message } from 'antd';
import { CloseCircleOutlined, HomeOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { FormInstance } from 'antd/lib/form';

import { connect } from "react-redux";
import * as actions from "../actions";

import { initCap, loadingIndicatorStart } from "../../../utils/commonHelpers";

const { Option } = Select;
const { Panel } = Collapse;

interface Prop {
    userOrgsSetPagination: Function,
    userOrgsGet: Function,
    userOrgsRole: Function,
    userOrgsRemove: Function,
    userOrgsMaint: Function,
    administeredOrgsGet: Function,
    usersListRefreshOnUpdate: Function,
    userMaintRefreshInstance: Function,
    getUser: Function,
    getOrgRoles: Function,
    context: any,
    usersList: any,
    roleList: any,
    iscreate: boolean,
    isupdate: boolean,
    isread: boolean,
    isdelete: boolean
}

class UserMaintOrgs extends React.Component<Prop, {}> {  
   
    formRef = React.createRef<FormInstance>();

    componentDidMount() {
        this.props.getOrgRoles(this,null,null,this.props.context.appContext.orgId);
        this.props.userOrgsSetPagination(this.onPaginationChange);
        this.componentUpdate();
    }

    componentDidUpdate(prevProps: Prop) {
        if (this.props.usersList.userMaint.tabs[1].instance !== prevProps.usersList.userMaint.tabs[1].instance) {
            this.componentUpdate();
        }
    }

    componentUpdate() {
        let userMaint = this.props.usersList.userMaint;        
        //ops-2680
        this.props.userOrgsGet(this, null, null, userMaint.record.userId, this.props.context.appContext.roleName);        
        if ((this.props.usersList.userMaint.administeredOrgs.length || 0) === 0 && (this.props.context.user.contextUserIsAdmin || false)) {
            this.props.administeredOrgsGet(this, null, null, null, null);
        }
    }

    onPaginationChange = (page: number) => {
         //ops-2680
        this.props.userOrgsGet(this, null, null, this.props.usersList.userMaint.record.userId, this.props.context.appContext.roleName, page);
    };
    
    addOrgs = () => {   
        let form =  this.formRef.current;
        this.props.userOrgsMaint(
            this, 
            this.addOrgsSuccess, 
            null, 
            //(form?.getFieldValue("orgs") || null),
            [form?.getFieldValue("orgs") || null], 
            [this.props.usersList.userMaint.record.userId], 
            (form?.getFieldValue("role") || ""), 
            false,
            this.props.usersList.userMaint.tabs[1].tableConfig.pagination.current
        );
    };
    
    addOrgsSuccess = (data:any) => {    
        this.resetOrgs();
        this.props.usersListRefreshOnUpdate(this);
        this.props.userMaintRefreshInstance(this, [2]);         
        message.success("User organizations have been successfully processed");
        //ops-2680
        this.props.userOrgsGet(this, null, null, this.props.usersList.userMaint.record.userId, this.props.context.appContext.roleName);
    }

    isAdminAutoChange = ():boolean => {   
        return (
            this.props.context.appContext.userId === this.props.usersList.userMaint.record.userId && 
            (this.props.context.user.contextUserIsAdmin || false)
        );        
    };

    adminAutoChange = () => {  
        this.props.getUser(this, this.adminAutoChangeSuccess, null, this.props.usersList.userMaint.record.userId);            
    }

    adminAutoChangeSuccess = (record:any) => {  
        if (!(record.contextUserIsAdmin || false)) {
            loadingIndicatorStart();
            setTimeout(function () {
                window.location.href = "/map"; 
            }, 500);  
        }
    }

    roleChange = (value: string, record:any) => {
        this.props.userOrgsRole(this, this.roleChangeSuccess, null, record["key"], value);               
    };

    roleChangeSuccess = (orgUsers:any, orgUserId:string, role:string) => {           
        this.props.userMaintRefreshInstance(this, [2]);
        message.success("User has been successfully updated");
        //ops-2680
        this.props.userOrgsGet(this, null, null, this.props.usersList.userMaint.record.userId, this.props.context.appContext.roleName);
        if (this.isAdminAutoChange()) {
            this.adminAutoChange();            
        }
    };

    removeOrg = (e:any, orgUserId:string) => {   
        e.preventDefault();
        this.props.userOrgsRemove(this, this.removeOrgSuccess, null, orgUserId);        
    };

    removeOrgSuccess = (orgUserId:string) => {   
        this.props.userMaintRefreshInstance(this, [2]);
        message.success("Organization has been successfully removed");

        if (this.isAdminAutoChange()) {
            this.adminAutoChange();            
        }
    };

    resetOrgs = () => { 
       this.formRef.current?.resetFields();      
    }

    onOrgIdChange = (value: string) => {
        this.props.getOrgRoles(this,null,null,value);
    }

   
    render() {

        const { user:contextUser } = this.props.context;
        const { administeredOrgs } = this.props.usersList.userMaint;
        const { tableConfig, tableData } = this.props.usersList.userMaint.tabs[1];
        const { roles } = this.props.roleList.roleMaint.orgRoles.data;
     
        const header = (
            <>
                <span>Add/Update Organizations</span>
                <Tooltip title="Assign user to any organization you have admin access to">
                    <QuestionCircleOutlined style={{ paddingLeft: '10px'}}/>
                </Tooltip>
            </>
        );

        const tablecolumns = [
        {
            title: "Org Name",
            dataIndex: "name",
            key: "name",
            render: (text: any, record: any) => (
                <>
                    <strong>{record.name}</strong>
                    <br/>
                    {record.city && ' ' + record.city}
                    {record.state && ', ' + record.state}
                    {record.zipCode && ' ' + record.zipCode}
                    {record.country && ', ' + record.country}
                </>
            )
        },
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            width: "180px",
            render: (role: string, record: any) => (  
                <>
                    {/* ops-2680 */}
                    {/* {    Based on user's role in each org to determine whether the user org role assignment can be done
                           admin-like role is determined by sub_orgs permission value =15
                           user update is determined by users permission value update bit = 1 
                           individual org-record update does not rely on the homeOrg isupdate flag, since homeorg can be guest-like role
                        !(contextUser.contextUserIsAdmin && record.contextUserIsOrgAdmin && record.contextUserCanSetOrgUser) &&
                        role
                    }  */}
                    {
                        //ops-2680
                        // (contextUser.contextUserIsAdmin && record.contextUserIsOrgAdmin && record.contextUserCanSetOrgUser) &&
                         <Select 
                            data-id="users-maint-info-home-org-role"
                            value={record.role}
                            style={{ width: 180 }}
                            showSearch
                            showArrow={true}
                            allowClear                           
                            optionFilterProp="children"
                            className="select-before" 
                            onChange={this.roleChange}                            
                           // key={"role-" + record.recordNo}
                        >
                        {JSON.parse(record.roles) !== null && JSON.parse(record.roles).map((recordrole:any, index:number) => (
                            <Option key={record.orgUserId} data-id={record.orgId} value={recordrole.role_name}>
                                    {recordrole.role_name}
                            </Option>
                        ))}
                    </Select>  
                    }
                </>
            )
        },
        {
            title: "Actions",
            key: "actions",
            width: "100px",
            align: "center" as "center",
            render: (text: any, record: any) => (
                <>
                    {
                        record.isHomeOrg &&
                        <HomeOutlined />
                    }
                    {
                        /* 1. delete orgUser does not require delete user delete user permission in general which is super-admin delete account
                           2. delete user does not requires persmision from home-org, since the  user can be a guest role in home, but admin role in org-records
                           3. delete user requires each record-org-admin role  */
                        (contextUser.contextUserIsAdmin && record.contextUserIsOrgAdmin && record.contextUserCanSetOrgUser) && 
                        !(record.isHomeOrg)
                         &&
                        <Tooltip title="Remove">
                            <a href="# " data-id="users-maint-orgs-remove-button" onClick={(e:any) => this.removeOrg(e, record.orgUserId)}>
                                <CloseCircleOutlined />
                            </a>
                        </Tooltip>
                    }
                </>
            )
        }
    ];

        return (         
            <div data-id="user-maint-orgs">
                {
                    contextUser.contextUserIsAdmin &&
                    <Collapse data-id="users-maint-orgs-add-orgs" bordered={false} defaultActiveKey={['0']} className="auto-close">
                        <Panel header={header} key="1">
                            <Form 
                                layout="vertical"
                                ref={this.formRef}
                                name="add" 
                            >
                                <Row gutter={16}>
                                    <Col xs={24} md={18}>
                                        <Form.Item
                                            name="orgs"
                                        >

                                            <Select 
                                                data-id="users-maint-orgs-add-orgs-orgs"
                                             // mode="multiple"
                                                showSearch
                                                showArrow={true}
                                                allowClear
                                                placeholder="Select one or more..."
                                                optionFilterProp="children"
                                                className="select-before"
                                                onChange={this.onOrgIdChange} 
                                            >
                                                {administeredOrgs !== null && administeredOrgs.map((record:any, index:number) => (
                                                    <Option key={record.orgId} value={record.orgId}>
                                                        {record.name}
                                                    </Option>
                                                ))}
                                            </Select> 
                                        </Form.Item>
                                    </Col>                
                                    <Col xs={24} md={6}>
                                        
                                        <Form.Item
                                            name="role"                                            
                                        >
                                            <Select 
                                                data-id="users-maint-orgs-add-orgs-role"
                                                showSearch
                                                showArrow={true}
                                                allowClear                                               
                                                optionFilterProp="children"
                                                className="select-before"
                                                placeholder="Role"
                                                style={{ width: 140 }}
                                            >
                                                {roles !== null && roles.map((record:any, index:number) => (
                                                    <Option key={record.roleName + "-" + record.roleId} value={record.roleName}>
                                                        {record.roleName}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>   
                                </Row>    
                                <Space size="middle">
                                    <Form.Item 
                                        shouldUpdate={true}>                                            
                                        {() => (
                                            <Button
                                                data-id="users-maint-orgs-add-orgs-reset-button"
                                                type="default"
                                                htmlType="submit"
                                                onClick={this.resetOrgs}
                                                disabled={            
                                                    !(
                                                        this.formRef.current?.getFieldValue("orgs") ||
                                                        this.formRef.current?.getFieldValue("role")
                                                    )
                                                }                                   
                                            >
                                                Reset
                                            </Button>
                                        )}
                                    </Form.Item>
                                    <Form.Item 
                                        shouldUpdate={true}>                                            
                                        {() => (
                                            <Button
                                                data-id="users-maint-orgs-add-orgs-submit-button"
                                                type="default"
                                                htmlType="submit"
                                                onClick={this.addOrgs}
                                                disabled={
                                                    !(
                                                        (this.formRef.current?.getFieldValue("orgs") || "") !== "" &&                                                             
                                                        (this.formRef.current?.getFieldValue("role") || "") !== ""    
                                                    )   
                                                }                                   
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </Form.Item>
                                </Space>                
                            </Form>
                        </Panel>            
                    </Collapse>
                }

                <Table 
                    data-id="users-maint-orgs-add-orgs-data"
                    {...tableConfig}
                    rowKey={(record) => record.orgUserId}                                
                    columns={tablecolumns} 
                    dataSource={tableData} 
                    style={{ marginTop: 10 }}
                />
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return {        
        context: state.contextReducer.context,
        usersList: state.usersReducers.usersState.usersList,
        roleList: state.usersReducers.usersState.roleList
    };
};

export default connect(
    mapStateToProps,
    actions
)(UserMaintOrgs);