import { appConfigs } from "../../../utils/configurations";

export const initialState = {
    templateRecord: {},
    activeTab: "1",
    tabs: [
        {
            title: "Signal Collections",
            instance: 0,
            disabled: false,
            searchText: "",
            refreshOnUpdate: false,
            tableData: [],
            tableConfig : { 
                pagination : {                  
                    showSizeChanger: appConfigs.tables.showSizeChanger,
                    showQuickJumper: appConfigs.tables.showQuickJumper,
                    pageSize: appConfigs.tables.pageSize,
                    defaultCurrent: appConfigs.tables.defaultCurrent,
                    current: appConfigs.tables.defaultCurrent,
                    total: appConfigs.tables.defaultTotal,
                    hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                    responsive: appConfigs.tables.responsive,
                    onChange: null,
                    showLessItems: appConfigs.tables.showLessItems,  
                    showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} collection${total > 1 ? 's' : ''}`
                }
            },
            signalCollectionMaint: {
                instance: 0,
                width: 620,
                visible: false,
                record: {},
                title: "",
                signalCollectionId: "",
                signalCollectionName: ""
            }
        },
        {
            title: "DM1 Faults",
            instance: 0,
            disabled: false,
            searchText: "",
            refreshOnUpdate: false,
            tableData: [[],[]],
            // importFlag: [false,false],  //feature can track when csv file is imported
            tableConfig : { 
                pagination : {                  
                    showSizeChanger: appConfigs.tables.showSizeChanger,
                    showQuickJumper: appConfigs.tables.showQuickJumper,
                    pageSize: 999,
                    defaultCurrent: appConfigs.tables.defaultCurrent,
                    current: appConfigs.tables.defaultCurrent,
                    total: appConfigs.tables.defaultTotal,
                    hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                    responsive: appConfigs.tables.responsive,
                    onChange: null,
                    showLessItems: appConfigs.tables.showLessItems,  
                    showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} DM1 Fault${total > 1 ? 's' : ''}`
                }
            }
        },
        //ops-1224
        {            
            title: "Pinned Signals",
            instance: 0,
            disabled: false,
            searchText: "",
            refreshOnUpdate: false,                    
            pinnedSignals: {
                id: "",
                preferedSignals: null
            }
        },
         {            
            title: "Operator ID Signals",
            instance: 0,
            disabled: false,
            searchText: "",
            refreshOnUpdate: false,                    
            operatorSignals: {
                id: "",
                preferedSignals: null
            }
        },
        {            
            title: "Quotas Applied",
            instance: 0,
            disabled: false,                  
            quotasApplied: {
                id: "",
                quotasApplied: null
            }
        }

    ],
    signals: {
        title: "Signals",        
        instance: 0,
        width: 720,
        visible: false,
        signalCollectionId: "",
        signalCollectionName: "",
        signalCollectionType: "",
        signalList: {
            searchText: "",
            refreshOnUpdate: false,
            tableData: [],
            tableConfig : { 
                pagination : {                  
                    showSizeChanger: appConfigs.tables.showSizeChanger,
                    showQuickJumper: appConfigs.tables.showQuickJumper,
                    pageSize: appConfigs.tables.pageSize,
                    defaultCurrent: appConfigs.tables.defaultCurrent,
                    current: appConfigs.tables.defaultCurrent,
                    total: appConfigs.tables.defaultTotal,
                    hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                    responsive: appConfigs.tables.responsive,
                    onChange: null,
                    showLessItems: appConfigs.tables.showLessItems,  
                    showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} Signal${total > 1 ? 's' : ''}`
                }
            }
        },
        signalMaint: {
            instance: 0,
            width: 620,
            visible: false,
            record: {},
            title: "",
            j1939s: {
                data: []
            },
            commandPgns: {
                data: []
            },
            commandControllers: {
                data: []
            },
            collectionType: "",
            units:{
                data: []
            }
        }
    }
};
  