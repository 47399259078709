import Axios from "axios";
import { errorResponse } from "../../../../utils/apiHelpers/apiHelpers";
import { appConfigs } from "../../../../utils/configurations";

export function getControllers(
    me: any, 
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
    page: number = 1,
    searchText?: string,
    filters?: any[any],
    controllerId?:string
) {
    
    return async (dispatch: any) => {

        let filtersObj:any = [];
        filters && filters['status'] != null ? filtersObj = {'statusFilter': filters['status']} : filtersObj = {}

        Axios.get(
            appConfigs.server.URL + "/ui/api/controllers", {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText,
                    page: page,
                    pageSize: appConfigs.tables.pageSize,
                    controllerId: (controllerId||""),
                    filters: (filters['status'] ? "" : encodeURIComponent(JSON.stringify(filtersObj)) )
                }
            }
        )
        .then(response => { 
            //console.log(response.data.data.controllers);
            
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.controllers);
                }
                dispatch({ type: "CONTROLLERS_GET_LIST_SUCCESS", payload: {data: response.data.data.controllers, page: page}  });
            }
        )
        .catch(function (error) {
            //console.log('error', error)
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function controllersSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "CONTROLLER_SET_SEARCH", searchText: searchText });
    }
}  

export function controllerSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "CONTROLLERS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
} 

export function setTab(
    tabNumber: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "SET_TAB", tabNumber: tabNumber });
    }
}

export function openDrawer(title: string, width: number, record: any) {
	return (dispatch: any) => {
		dispatch({ type: "OPEN_DRAWER", payload: { title: title, width: width, record: record } });
	};
}

export function closeDrawer() {
	return (dispatch: any) => {
		dispatch({ type: "CLOSE_DRAWER" });
	};
}

export function drawerTabChange(activeTab: string) {
	return (dispatch: any) => {
		dispatch({ type: "DRAWER_TAB_CHANGE", activeTab: activeTab });
	};
}



export function getAllControllersList() {
	return (dispatch: any) => {
		Axios.get(appConfigs.server.URL + "/ui/api/controllers", {
			responseType: "json",
			headers: {},
			params: {
				page: 1,
				pageSize: 9999,
			},
		}).then((response) => {
            let controllers = response.data.data.controllers;
			dispatch({ type: "SET_CONTROLLERS_LIST", payload: { assetsList: controllers } });
		}).catch(function (error) {
            dispatch({ type: "SET_LOADING_CONTROLLERS", payload: { state: false } });
            errorResponse(null, error);
        });
	};
}



export function modifyController(
    selectedControllersToModify: any,
    callbackFnSuccess?: any
) {
    return (dispatch: any) => {
        dispatch({ type: "ADD_CONTROLLER", payload: { state: true } });
		Axios.get(appConfigs.server.URL + "/ui/api/modify_controllers", {
			responseType: "json",
			headers: {},
            params: {
                controllers: encodeURIComponent(JSON.stringify(selectedControllersToModify))
			},
		}).then((response) => {
            if (callbackFnSuccess != null) {
                callbackFnSuccess(response.data.data);
            }
			dispatch({ type: "ADD_CONTROLLER_SUCCESSFULLY" });
		}).catch(function (error) {
            dispatch({ type: "ADD_CONTROLLER", payload: { state: false } });
            //console.log('error', error)
            errorResponse(null, error);
        });
	};
}