import React from "react";
import { connect } from "react-redux";
import { Form, Input, Typography, Select, Table, Checkbox, Row, Col } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { appConfigs } from "../../../utils/configurations";
import { getPermissions } from "../../../utils/commonHelpers";
import { errorResponse, resetFormErrors } from "../../../utils/apiHelpers/apiHelpers";
import { FormState, formInit } from "../../../utils/apiHelpers/formHelpers";
import { SuccessAlert, ErrorAlert } from "../../../utils/Components/formAlerts";
import * as actions from "../actions";
import "./roleMaintInfo.css";

const { Option } = Select;

interface Prop { 
    roleMaint: Function, 
    getRolePermissions: Function,   
    getOrgRoles: Function,
    context: any,
    roleList: any,
    rolePermissionList: any
}

class RoleMaintInfo extends React.Component<Prop, FormState> {
    state: FormState = {};

    formRef = React.createRef<FormInstance>();
    
    componentDidMount() {
        this.componentUpdate();
    }
    
    componentDidUpdate(prevProps: Prop, prevState: FormState) {       
        if (this.props.roleList.roleMaint.instance !== prevProps.roleList.roleMaint.instance) {
            this.componentUpdate();
        }       
    }

    componentUpdate() {
        formInit(this); 
       
        if (this.formRef.current != null) {
            this.formRef.current!.resetFields();
            this.formRef.current!.setFieldsValue({

            })
        }
    }

    onFinish = () => {  
        resetFormErrors(this, this.formRef.current);  
        //this.props.roleMaint(this, this.onFinishSuccess, this.onFinishFailure, values);
    };
    onFinishSuccess = () => { 

    }

    onFinishFailure = (error: any) => { 
        errorResponse(this, error);

    }

    onRoleIdChange = (value: string) => {        
        this.props.getRolePermissions(this,null,null,value);
    }

    onCheckChange = () => {
        //this.props.orgSelectorListMaint(this, this.onCheckChangeSuccess, this.onCheckChangeFailure, record.orgId, e.target.checked);
    }

    render() {
         
        const { roles } = this.props.roleList.roleMaint.orgRoles.data;   
        const { permissions } = this.props.rolePermissionList;
        let permissionList = [];
        
        for (let i=0; i<permissions.data.permissions.length; i++){           
            const result = getPermissions(permissions.data.permissions[i].permission_level);
            let permission = { id:permissions.data.permissions[i].permissionId, entityName:permissions.data.permissions[i].entity, create:result[0], read:result[1], update:result[2], delete: result[3]};
            permissionList.push(permission);
        }            

        const tableColumns = [
            {
                title: "Entity Name",
                dataIndex: "entityName",
                width: "50%",
            },
            {
                title:"View", 
                dataIndex: "read",
                key: "read",
                width: "12%",
                render: (read: any, record: any) => (
                    <>
                        <Checkbox 
                            checked={record.read === true}
                        > 
                        </Checkbox>
                    </>
                )
            },
            {
                title:"Add", 
                dataIndex: "create",
                key: "create",
                width: "12%",
                render: (create: any, record: any) => (
                    <>
                        <Checkbox 
                            checked={record.create === true}
                        > 
                        </Checkbox>
                    </>
                )
            },
            {
                title:"Edit", 
                dataIndex: "update",
                key: "update",
                width: "12%",
                render: (update: any, record: any) => (
                    <>
                        <Checkbox 
                            checked={record.update === true}
                        > 
                        </Checkbox>
                    </>
                )
            },
            {
                title:"Delete", 
                dataIndex: "delete",
                key: "delete",
                width: "12%",
                render: (update: any, record: any) => (
                    <>
                        <Checkbox 
                            checked={record.delete}                            
                        > 
                        </Checkbox>
                    </>
                )
            }
        ];

        return (
            <div data-id="role-maint-info">
                {this.state.formSuccess && !this.state.formError && <SuccessAlert data-id="role-maint-info-success-message"  message={this.state.formSuccessMessage}/>}
                {this.state.formError && !this.state.formSuccess && <ErrorAlert data-id="role-maint-info-error-message" message={this.state.formErrorMessage}/>}
                <Row gutter={8} style={{marginTop: 15}}>
                    <Col offset={1} span={23}>
                    <Form
                        {...appConfigs.settings.formLayout.mainLayout}
                        id="roleMaintInfoForm"
                        ref={this.formRef}
                        onFinish={this.onFinish}
                        className="mt-15"
                    >
                        <Row gutter={8}>
                            <Col span={8}>
                                <Form.Item
                                    name="orgHomeRole"
                                    label="Role"
                                    rules={[{ required: false, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                >
                                    <Select 
                                        data-id="role-maint-info-home-org-role" 
                                        defaultValue= {roles[0].roleId}                                       
                                        onChange={this.onRoleIdChange}   
                                    >

                                        {roles !== null && roles.map((record:any, index:number) => (
                                            <Option key={record.roleName} value={record.roleId} >
                                                {record.roleName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* <Row gutter={8}>
                            <Col span={12}>
                                <Form.Item
                                    label="Role Name"
                                    name="orgRole"                  
                                    rules={[{ required: false }]}
                                >
                                    <Input maxLength={25}/>
                                </Form.Item>
                            </Col>                        
                            <Col span={12}>
                                <Form.Item
                                    label="Description"
                                    name="desc"                                 
                                    rules={[{ required: false }]}
                                >
                                    <Input maxLength={100} />
                                </Form.Item>
                            </Col> 
                        </Row> */}
                        <div className="mt-15">                
                            <Table  
                                data-id="role-permissions-data"                            
                                rowKey={(record) => record.id}
                                columns={tableColumns}
                                dataSource={permissionList}
                            />        
                        </div>
                    </Form>
                </Col>
            </Row>  
        </div>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        roleList: state.usersReducers.usersState.roleList,
        rolePermissionList: state.usersReducers.usersState.data
    };
};

export default connect(
    mapStateToProps,
    actions
)(RoleMaintInfo);