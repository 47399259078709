import React, { useRef, useLayoutEffect, useMemo } from "react";

import TrackerChart, { Props as TrackerProps } from "./TrackerChart";
import { CommonWidgetProps, SignalId } from "../../models";
import { AxisRange } from "../models";
import { signalLabel } from "../common";
import useDomNode from "../../../../lib/use-dom-node";

export interface SignalOpts {
  signalId: SignalId;
  opts: {
    axisRange?: AxisRange;
  };
}

const TrackerComponent: React.FC<TrackerProps> = (props) => {
  const [node, setNode] = useDomNode<HTMLDivElement>();
  const chartRef = useRef<TrackerChart>();

  const { signal, rule, opts } = props;

  useLayoutEffect(() => {
    if (node) {
      chartRef.current = new TrackerChart(node, signal, rule, opts);
      return () => chartRef.current?.destroy();
    }
  }, [node, signal, rule, opts]);

  return (
    <>
      <span>{props.signal.name}</span>
      <div style={{ height: "100%" }} ref={setNode} />
    </>
  );
};

function useTrackerProps({
  template,
  data,
  signals,
  settings,
}: TrackerWidgetProps): TrackerProps[] {
  return useMemo(() => {
    const result: TrackerProps[] = [];

    for (let id of signals) {
      const datapts = data.get(id);
      const metadata = template.signals.find((s) => s.id === id);
      if (datapts && metadata) {
        result.push({
          signal: {
            name: signalLabel(metadata, false),
            values: [{ ...datapts[0] }],
          },
          rule: template.rules && template.rules.length > 0 ? template.rules.find((r) => r.signalId === id): undefined,
          opts: settings && settings.length > 0 ? settings?.find((s) => s.signalId === id)?.opts: undefined,
        });
      }
    }

    return result;
  }, [template, data, signals, settings]);
}

export interface TrackerWidgetProps extends CommonWidgetProps {
  settings?: SignalOpts[];
}

const TrackerWidget: React.FC<TrackerWidgetProps> = (props) => {
  const settings = useTrackerProps(props);
  return (
    <>
      {settings.map((s, i) => (
        <div key={i} style={{ height: "85px" }}>
          <TrackerComponent {...s} />
        </div>
      ))}
    </>
  );
};

export default TrackerWidget;
