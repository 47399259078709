import Axios from "axios";
import { errorResponse } from "../../../../utils/apiHelpers/apiHelpers";
import { appConfigs } from "../../../../utils/configurations";

export function getContracts(
    me: any, 
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
    page: number = 1,
    searchText?: string,
    filters?: any[any],
    contractId?:string
) {
    
    return async (dispatch: any) => {

        let filtersObj:any = [];
        filters && filters['status'] != null ? filtersObj = {'statusFilter': filters['status']} : filtersObj = {}

        Axios.get(
            appConfigs.server.URL + "/ui/api/contracts", {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText,
                    page: page,
                    pageSize: appConfigs.tables.pageSize,
                    contractId: (contractId||""),
                    filters: (filters['status'] ? "" : encodeURIComponent(JSON.stringify(filtersObj)) )
                }
            }
        )
        .then(response => { 
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.contracts);
                }
                dispatch({ type: "CONTRACTS_GET_SUCCESS", payload: {data: response.data.data.contracts, page: page}  });
            }
        )
        .catch(function (error) {
            console.log('error', error)
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function contractsSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "CONTRACT_SET_SEARCH", searchText: searchText });
    }
}  

export function contractSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "CONTRACTS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
} 

export function setTab(
    tabNumber: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "SET_TAB", tabNumber: tabNumber });
    }
}

export function openDrawer(title: string, width: number, record: any) {
	return (dispatch: any) => {
		dispatch({ type: "OPEN_DRAWER", payload: { title: title, width: width, record: record } });
	};
}

export function closeDrawer() {
	return (dispatch: any) => {
		dispatch({ type: "CLOSE_DRAWER" });
	};
}

export function drawerTabChange(activeTab: string) {
	return (dispatch: any) => {
		dispatch({ type: "DRAWER_TAB_CHANGE", activeTab: activeTab });
	};
}



export function getAllAssetsList() {
	return (dispatch: any) => {
		Axios.get(appConfigs.server.URL + "/ui/api/contracts", {
			responseType: "json",
			headers: {},
			params: {
				page: 1,
				pageSize: 9999,
			},
		}).then((response) => {
            let contracts = response.data.data.contracts;
			dispatch({ type: "SET_ASSETS_LIST", payload: { assetsList: contracts } });
		}).catch(function (error) {
            dispatch({ type: "SET_LOADING_ASSETS", payload: { state: false } });
            errorResponse(null, error);
        });
	};
}


export function updateField(value: string, filedName:string, mastertag: string) {
	return (dispatch: any) => {
		dispatch({ type: "UPDATE_FIELD", payload: { value: value, filedName: filedName, mastertag: mastertag } });
	};
}


export function updateRenewalField(value: string, filedName:string, mastertag: string) {
	return (dispatch: any) => {
		dispatch({ type: "UPDATE_RENEWAL_FIELD", payload: { value: value, filedName: filedName, mastertag: mastertag } });
	};
}

export function addToContractsList(
    selectedAsset: any,
    callbackFnFailure?: any,
) {
	return (dispatch: any) => {
		Axios.get(appConfigs.server.URL + "/ui/api/get_contract_details", {
			responseType: "json",
			headers: {},
			params: {
                gatewayId: selectedAsset.gatewayID
			},
		}).then((response) => {
			let contractStatus = response.data.data.contractStatus;
			dispatch({ type: "APPEND_TO_SELECTED_CONTRACTS_LIST", payload: { contract: contractStatus } });
		}).catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(error)
            }
            errorResponse(null, error);
        });
	};
}


export function addToRenewalsToolList(
    selectedAsset: any,
    callbackFnFailure?: any,
) {
	return (dispatch: any) => {
		Axios.get(appConfigs.server.URL + "/ui/api/get_contract_details", {
			responseType: "json",
			headers: {},
			params: {
                gatewayId: selectedAsset.gatewayID
			},
		}).then((response) => {
            let contractStatus = response.data.data.contractStatus;

            if (contractStatus.billingContractHistory == null) {
                callbackFnFailure(null, "Mastertag does not have a billable customer!")
            } else {
                dispatch({ type: "APPEND_TO_SELECTED_RENEWAL_TOOL_LIST", payload: { contract: contractStatus } });
            }

		}).catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(error)
            }
            errorResponse(null, error);
        });
	};
}


export function removeFromContractsList(
    mastertag: string
) {
	return (dispatch: any) => {
        // console.log('mastertag', mastertag)
		dispatch({ type: "REMOVE_FROM_SELECTED_CONTRACTS_LIST", payload: { mastertag: mastertag } });
	};
}


export function removeFromRenewalsToolList(
    mastertag: string
) {
	return (dispatch: any) => {
        // console.log('mastertag', mastertag)
		dispatch({ type: "REMOVE_FROM_SELECTED_RENEWALS_TOOL_LIST", payload: { mastertag: mastertag } });
	};
}


export function modifyContract(
    selectedContractsToModify: any,
    callbackFnSuccess?: any
) {
    return (dispatch: any) => {
        dispatch({ type: "SET_CONTRACT_SUBMITTING", payload: { state: true } });
		Axios.get(appConfigs.server.URL + "/ui/api/modify_contracts", {
			responseType: "json",
			headers: {},
            params: {
                contracts: encodeURIComponent(JSON.stringify(selectedContractsToModify))
			},
		}).then((response) => {
            if (callbackFnSuccess != null) {
                callbackFnSuccess(response.data.data);
            }
			dispatch({ type: "SET_CONTRACT_MODIFIED_SUCCESSFULLY" });
		}).catch(function (error) {
            dispatch({ type: "SET_CONTRACT_SUBMITTING", payload: { state: false } });
            console.log('error', error)
            errorResponse(null, error);
        });
	};
}


export function submitNewRenewal(
    newRenewals: any,
    callbackFnSuccess?: any,
    callbackFnFailure?: any
) {

    let PTSOrderFormModel = {
        orderNumber: newRenewals[0].orderNumber,
        customerNumber: newRenewals[0].customerNumber,
        customerPO: newRenewals[0].customerPO,
        billToName: newRenewals[0].billToName,
        partNumber: newRenewals[0].partNumber
    }

    // console.log("PTSOrderFormModel")
    // console.log(PTSOrderFormModel)

    return (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL + "/api/pts/service/mastertag/" + newRenewals[0].mastertag,
            PTSOrderFormModel,
            {
                responseType: "json",       
                headers: {},
                params: {
                    version: 1,
                    addLog: 'true'
                },
            }
            ).then((response) => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data);
                }
                // dispatch({ type: "SET_CONTRACT_MODIFIED_SUCCESSFULLY" });
            }).catch(function (error) {
                console.log(error.response)
                if (callbackFnFailure != null) {
                    if (error.response.data?.errors?.length > 0) {
                        callbackFnFailure(error.response.data.errors[0].message);
                    } else {
                        callbackFnFailure("Error While Processing Your Request. Code: " + error.response.status);
                    }
                }
            });
    };
    
}


export function getBillableCustomerList() {
	return (dispatch: any) => {
		Axios.get(appConfigs.server.URL + "/ui/api/get_billable_customer", {
			responseType: "json",
			headers: {},
			params: {
				billableOnly: true,
			},
        }).then((response) => {
            // console.log(response.data.data.billableCustomers)
			dispatch({ type: "SET_BILLABLE_CUSTOMER", payload: { billableCustomers: response.data.data.billableCustomers } });
		}).catch(function (error) {
            errorResponse(null, error);
        });
	};
}

export function getServicePlansList() {
	return (dispatch: any) => {
		Axios.get(appConfigs.server.URL + "/ui/api/get_service_plans", {
			responseType: "json",
			headers: {}
        }).then((response) => {
            // console.log(response.data.data.servicePlansList)
			dispatch({ type: "SET_SERVICE_PLANS_LIST", payload: { servicePlansList: response.data.data.servicePlansList } });
		}).catch(function (error) {
            errorResponse(null, error);
        });
	};
}


export function getCustomerBucketDetails(
    organizationId: any,
    callbackFnSuccess?: any,
    callbackFnFailure?: any
) {
	return (dispatch: any) => {
		Axios.get(appConfigs.server.URL + "/ui/api/get_customer_bucket_details", {
			responseType: "json",
			headers: {},
			params: {
				orgId: organizationId,
			},
        }).then((response) => {
            // console.log(response.data.data.customerBucketDetails)
			dispatch({ type: "GET_CUSTOMER_BUCKET_DETAILS", payload: { customerBucketDetails: response.data.data.customerBucketDetails } });
		}).catch(function (error) {
            console.log(error)
        });
	};
}


export function getOrgServicePlanBucketByOrdId(
    organizationId: any,
    servicePlansList: any,
    callbackFnSuccess?: any,
    callbackFnFailure?: any
) {
    return (dispatch: any) => {
        
		Axios.get(appConfigs.server.URL + "/ui/api/get_customer_bucket_details", {
			responseType: "json",
			headers: {},
			params: {
				orgId: organizationId,
			},
        }).then((response) => {
            
            const servicePlanTmp = JSON.parse(JSON.stringify(servicePlansList))
            const servicePlanBalance = servicePlanTmp?.map((servicePlan: any) => {
                let total_available_quantity = 0
                let partNumberBucketEntries: any = []
                response.data.data.customerBucketDetails?.map((bucketEntry:any) => {
                    if (bucketEntry.partNumber == servicePlan.partNumber) {
                        total_available_quantity = total_available_quantity + parseInt(bucketEntry.availableQuantity)
                        partNumberBucketEntries.push(bucketEntry)
                    }
                })

                servicePlan['total_available_quantity'] = total_available_quantity
                servicePlan['partNumberBucketEntries'] = partNumberBucketEntries

                return servicePlan
            })

            callbackFnSuccess(servicePlanBalance)
            
        
		}).catch(function (error) {
            console.log(error)
            callbackFnFailure("Error while getting Org's service plans")

        });

	};
}


export function addServicePlanToBucket(
    formValues: any,
    callbackFnSuccess?: any,
    callbackFnFailure?: any
) {
	return (dispatch: any) => {

        console.log(formValues)

		Axios.get(appConfigs.server.URL + "/ui/api/add_service_plan_to_bucket", {
			responseType: "json",
			headers: {},
			params: {
                ...formValues,
                Quantity: Math.floor(formValues['Quantity'])
			},
        }).then((response) => {
            console.log(response.data)
            if (callbackFnSuccess != null) {
                callbackFnSuccess(response.data.results, formValues['orgId']);
            }
		}).catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure('Error While Adding to Bucket!', error?.response?.data?.errors[0]?.message)
            }
        });
	};
}


export function returnServicePlanToTopParentCustomerOrg(
    formValues: any,
    callbackFnSuccess?: any,
    callbackFnFailure?: any
) {
	return (dispatch: any) => {

        // console.log("returnServicePlanToTopParentCustomerOrg")
        // console.log(formValues)

		Axios.get(appConfigs.server.URL + "/ui/api/return_service_plan_to_issuing_bucket", {
			responseType: "json",
			headers: {},
			params: {
                ...formValues,
			},
        }).then((response) => {
            console.log('return_service_plan_to_issuing_bucket returned data')
            console.log(response.data.data)
            if (callbackFnSuccess != null) {
                callbackFnSuccess(response.data.data.results, formValues['orgId']);
            }
		}).catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure('Error While Adding to Bucket!', error?.response?.data?.errors[0]?.message)
            }
        });
        
	};
}


export function transferServicePlanToOrg(
    formValues: any,
    callbackFnSuccess?: any,
    callbackFnFailure?: any
) {
	return (dispatch: any) => {

        // console.log("transferServicePlanToOrg")
        // console.log(formValues)

        if (!Number.isInteger(formValues.partNumberId)) {
            if (formValues.partNumberId.includes('-')) {
                formValues.partNumberId = ''
            }
        }

		Axios.get(appConfigs.server.URL + "/ui/api/transfer_service_plan_to_org", {
			responseType: "json",
			headers: {},
			params: {
                ...formValues,
			},
        }).then((response) => {
            // console.log('assign_service_plan_to_gateway')
            // console.log(response.data.data)
            if (callbackFnSuccess != null) {
                callbackFnSuccess(response.data.data, response.data.data.results);
            }
		}).catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure('Error While Assigning Service Plan!', error?.response?.data?.errors[0]?.message)
            }
        });
        
	};
}


export function assignServicePlanToGateway(
    formValues: any,
    callbackFnSuccess?: any,
    callbackFnFailure?: any
) {
	return (dispatch: any) => {

        console.log(formValues)

		Axios.get(appConfigs.server.URL + "/ui/api/assign_service_plan_to_gateway", {
			responseType: "json",
			headers: {},
			params: {
                ...formValues,
			},
        }).then((response) => {
            console.log('assign_service_plan_to_gateway')
            console.log(response.data.data)
            if (callbackFnSuccess != null) {
                callbackFnSuccess(response.data.data, response.data.data.results);
            }
		}).catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure('Error While Assigning Service Plan!', error?.response?.data?.errors[0]?.message)
            }
        });
        
	};
}

export function getGatewayContractsDetails(
    gatewayId: any,
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
) {
	return (dispatch: any) => {
		Axios.get(appConfigs.server.URL + "/ui/api/get_contract_details", {
			responseType: "json",
			headers: {},
			params: {
                gatewayId: gatewayId
			},
		}).then((response) => {
            if (callbackFnSuccess != null) {
                callbackFnSuccess(response.data.data.contractStatus)
            }
		}).catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(error)
            }
            errorResponse(null, error);
        });
	};
}
export function sendEmailNotifications() {
	return (dispatch: any) => {
		Axios.get(appConfigs.server.URL + "/ui/api/send_test_email", {
			responseType: "json",
			headers: {},
		}).then((response) => {
            console.log(response.data)
		}).catch(function (error) {
            console.log(error.response)
        });
	};
}