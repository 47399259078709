import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function usersSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "USERS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}   

export function usersSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "USERS_SET_SEARCH", searchText: searchText });
    }
}   

export function userMaintRefreshInstance(
    me: any,
    instances: number[] 
) {
    return (dispatch: any) => {
        dispatch({ type: "USERS_MAINT_REFRESH_INSTANCE", instances: instances });
    }
}   

export function getUser(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    userId: string
) {
    
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/users/id/" + userId, {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {                    
                    callbackFnSuccess(response.data.data.user);
                }
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error);
            }
            errorResponse(me, error);
        });
    };
}

export function getUsers(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    page: number = 1,
    searchText?: string,
    administered: boolean = false,
    sorterAndFilter?: any
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/users/okta", {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText,
                    administered: administered,
                    page: page,
                    pageSize: appConfigs.tables.pageSize,
                    sortColumn: sorterAndFilter ? sorterAndFilter.sortColumn : '',
                    sortDirection: sorterAndFilter ? sorterAndFilter.sortDirection : ''
                }
            }
        )
        .then(response => {          
                let data = response.data.data.users == null || (response.data.data.users.length || 0) === 0 ? [] : response.data.data.users;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "USERS_GET_USERS_SUCCESS", payload: {data: data, page: page} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function administeredOrgsGet(
    me: any, 
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
    orgId?: string,
    searchText?: string
) {
    return async (dispatch: any) => {            
        Axios.get(
            appConfigs.server.URL + "/ui/api/orgs-lov", {
                responseType: "json",        
                headers: {},
                params: {
                    mode: (orgId || "") === "" ? "administered" : "single",
                    scope: "all",
                    includeTree: false,
                    orgId: orgId,
                    searchText: searchText,
                    page: 1,
                    pageSize: 9999
                }
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.orgs);
                }
                dispatch({ type: "USERS_ADMINISTERED_ORGS_SUCCESS", payload: {data: response.data.data.orgs} });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
    };
}    

export function userStatus(
    me: any, 
    callbackSuccessFn: any,
    callbackFailureFn: any,
    userId: string,
    status: string = "activate"
) {
    return async (dispatch: any) => {
        Axios.put(
            appConfigs.server.URL + "/ui/api/users/" + userId + "/" + status,
            {},
            {
                responseType: "json"
            }
        )
        .then(response => {
                if (callbackSuccessFn != null) {
                    callbackSuccessFn(response.data.data.users, userId);           
                }
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFailureFn != null) {
                    callbackFailureFn(error);
                }
            }
        );        
    };
}

export function usersListRefreshOnUpdate(
    me: any
) {
    return (dispatch: any) => {
        dispatch({ type: "USERS_LIST_REFRESH_ON_UPDATE" });
    };
}

export function userMaintOpen(
    me: any, 
    title: string,
    width: number,
    record: any,
    pageName: string
) {
    return (dispatch: any) => {
        dispatch({ type: "USERS_USER_MAINT_OPEN", payload: {title: title, width: width, record: record, pageName: pageName} });
    };
}

export function userMaintClose(
    me: any
) {   
    return (dispatch: any) => {
        dispatch({ type: "USERS_USER_MAINT_CLOSE" });
    };
}

export function userMaintTabChange(
    me: any,
    tab: string
) {
    return (dispatch: any) => {
        dispatch({ type: "USERS_USER_MAINT_TAB_CHANGE", tab: tab });
    };
}

export function userMaintTabsEnable(
    me: any,
    tab: string
) {
    return (dispatch: any) => {
        dispatch({ type: "USERS_USER_MAINT_TABS_ENABLE" });
    };
}

export function roleMaintOpen(
    me: any, 
    title: string,
    width: number,
    record: any
) {
   
    return (dispatch: any) => {
        dispatch({ type: "ROLE_MAINT_OPEN", payload: {title: title, width: width, record: record} });
    };
}

export function roleMaintClose(
    me: any
) {   
    return (dispatch: any) => {
        dispatch({ type: "ROLE_MAINT_CLOSE" });
    };
}

export function getOrgRoles(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: string
) {
    
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/org-roles/" + orgId , {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {           
            let data = response.data;           
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data);
                }
                dispatch({ type: "USERS_ORG_ROLE_GET_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

export function getRolePermissions(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    roleId: string
) {
   
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/role-permissions/role/" + roleId , {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {            
            let data = response.data;            
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.org);
                }
                dispatch({ type: "ROLE_PERMISSION_GET_SUCCESS", payload: {data: data} });
                // dispatch({ type: "ROLE_MAINT_OPEN", payload: {data: data} });
            }
        )        
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }    
}

export function setOktaLastLogin(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    oktaUserId: string    
) {
    return async (dispatch: any) => {
        Axios.put(
            appConfigs.server.URL + "/ui/api/users/okta/setoktalastlogin" + 
                "?oktaUserId=" + oktaUserId,
            {},
            {
                responseType: "json"
            }
        )
        .then(response => {
            if (callbackFnSuccess != null) {
                 callbackFnSuccess();           
              }
             }
        )
        .catch(function (error) {
                errorResponse(me, error);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

export function getOrgUserRoles(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,    
    orgId: string,
    userRole: string,
    searchText?: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/org-role-permissions/" + orgId , {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText
                }
            }
        )
        .then(response => {      
                   
            let data = response.data;
           
            if (callbackFnSuccess != null) {
                callbackFnSuccess(response.data);
            }
            let userRoleData =  data.data.orgRolePermissions.filter((dat: { roleName: string; }) => dat.roleName === userRole);             
            let ispermission = false;
            var roles = [];
            // let res = data.data.orgRolePermissions.permissions.filter((item:{permissionId: number, permissionLevel: number;}) => userRoleData.permissions.filters((item2:{permissionId: number, permissionLevel: number;}) => item.permissionId === item2.permissionId && item.permissionLevel >= item2.permissionLevel));

            
            for(let i=0; i< data.data.orgRolePermissions.length;i++){
                ispermission = false;
                for(let j=0; j<data.data.orgRolePermissions[i].permissions.length; j++){
                    let otherRolePermission = Number(data.data.orgRolePermissions[i].permissions[j].permissionLevel).toString(2);
                    if(otherRolePermission.length <=2){
                        otherRolePermission = '00' + otherRolePermission;
                        otherRolePermission = otherRolePermission.split('').reverse().join('');
                    }
                    else if(otherRolePermission.length <=3){
                        otherRolePermission = '0' + otherRolePermission;
                        otherRolePermission = otherRolePermission.split('').reverse().join('');
                    }
                    else{
                        otherRolePermission = otherRolePermission.split('').reverse().join('');
                    }
                    for(let k=0; k<userRoleData[0].permissions.length; k++){
                        let userRolePermission = Number(userRoleData[0].permissions[k].permissionLevel).toString(2);
                        if(userRolePermission.length <=2){
                            userRolePermission = '00' + userRolePermission;
                            userRolePermission = userRolePermission.split('').reverse().join('');
                        }
                        else if(userRolePermission.length <=3){
                            userRolePermission = '0' + userRolePermission;
                            userRolePermission = userRolePermission.split('').reverse().join('');
                        }
                        else{
                            userRolePermission = userRolePermission.split('').reverse().join('');
                        }
                        if(userRoleData[0].permissions[k].permissionId === data.data.orgRolePermissions[i].permissions[j].permissionId){
                            // if(data.data.orgRolePermissions[i].permissions[j].permissionLevel > userRoleData[0].permissions[k].permissionLevel){
                            //     ispermission = true;
                            // }

                            if(otherRolePermission[1] > userRolePermission[1]){
                                ispermission = true;
                            }
                            else if(userRolePermission[1] === otherRolePermission[1] && ( otherRolePermission[0] > userRolePermission[0] || otherRolePermission[2] > userRolePermission[2] || otherRolePermission[3] > userRolePermission[3])){
                                ispermission = true;
                            }
                            else if( otherRolePermission[0] > userRolePermission[0] &&  otherRolePermission[1] > userRolePermission[1]){
                                ispermission = true;
                            }
                        }
                    }
                }
                if(!ispermission && userRole.toLowerCase() !== "admin" && data.data.orgRolePermissions[i].roleName.toLowerCase() !== "admin")
                {   
                    let roleItem = {
                        roleId: data.data.orgRolePermissions[i].roleId,
                        roleName: data.data.orgRolePermissions[i].roleName,
                    };
                    roles.push(roleItem);
                }
                else if(!ispermission && userRole.toLowerCase() === "admin"){
                    let roleItem = {
                        roleId: data.data.orgRolePermissions[i].roleId,
                        roleName: data.data.orgRolePermissions[i].roleName,
                    };
                    roles.push(roleItem);
                }
            }
            dispatch({ type: "USERS_ORG_USER_ROLE_GET_SUCCESS", payload: {data: roles} });
            }
        )
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}  