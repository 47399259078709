import React from "react";
import { Form } from "antd";

import { Signal } from "../../models";
import { SelectorList } from "../../WidgetEditForm";


interface Props {
  max?: number;
  signals: Signal[];
}

const RightAxisSignals: React.FC<Props> = (props) => {
  const selectable = props.signals.map((s) => ({
    value: s.id,
    label: s.name,
  }));

  return (
    <Form.Item
    label="Signals on right axis"
    name="rightYAxisSignals"
      required
      rules={[
        {
          validator(rule, value) {
            return !value || value.filter((v: any) => !!v).length < 1
              ? Promise.reject("At least one valid signal must be specified")
              : Promise.resolve();
          },
        },
      ]}
    >
      <SelectorList
        options={selectable}
        maxSelected={props.max}
        valueLabel="Signal"
      />
    </Form.Item>
  );
};

export default RightAxisSignals;
