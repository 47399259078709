import React from "react";
import { CommonWidgetProps } from "../../models";
import { State } from "./../../state";
import CommandControl from "./CommandControl";

const getCommandWidgetSignals = (signals:any, state: any) => {
  const commandSignals:any[] =[];
    if(signals.length > 0){
      signals.map((signal:any) => {
        if(JSON.parse(state.asset.datapoints).length > 0){
        JSON.parse(state.asset.datapoints).map((collection:any) => {
          if(collection.type && collection.type === "Command" ){
              collection.values.map((value:any) => {
                if(value._id == signal){
                  commandSignals.push(value);
              }
            })
          }
        })
      }
      })
    }
  return commandSignals;
}

interface CommandWidgettProps extends CommonWidgetProps{
  state: State; 
}

const Widget: React.FC<CommandWidgettProps> = (props) => {
    const { template, data, state } = props;   
    let widgetSignals = getCommandWidgetSignals(props.signals, state);   
    return (
        <div>
          {
            widgetSignals && widgetSignals.length > 0 && widgetSignals.map((signal:any) => 
               <CommandControl gatewayId={state.asset.gatewayId} signaldata = {signal} state = {state}></CommandControl>
            )
          }
        </div>
    );
};

export default Widget;

