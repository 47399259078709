import React, { useState, useEffect } from "react";
import {
  WidgetConfig,
  getEditForm,
  DETAILS as WIDGET_DETAILS,
} from "../widgets";
import { Template } from "../models";
import { Form, Select, ConfigProvider } from "antd";
import Container from "./Container";
import Event from "../../../lib/event";
import validationMessages from "./validation-messages";

type WidgetType = WidgetConfig["type"] | undefined;
type FormInstance = ReturnType<typeof Form.useForm>[0];

interface Props {
  config?: WidgetConfig;
  template: Template;
  onFinish: EditFormProps["onFinish"];
  submitTrigger: Event;
  onDualChange:EditFormProps["onDualChange"];
  enabledualaxis:EditFormProps["enabledualaxis"];
}

export interface EditFormProps {
  config?: WidgetConfig;
  template: Template;
  onFinish: (cfg: WidgetConfig) => void;
  form: FormInstance;
  layout?: "horizontal" | "vertical";
  onDualChange:(checked:boolean)=>void;
  enabledualaxis:boolean;
}

const TypeSelectForm: React.FC<{
  onTypeChange: (t: WidgetType) => void;
}> = (props) => {
  return (
    <Container>
      <Form layout="vertical" /* Here for styling only */>
        <Form.Item
          label="Widget Type"
          name="type"
          required
          rules={[{ required: true }]}
        >
          <Select
            onChange={(value: WidgetConfig["type"]) =>
              props.onTypeChange(value)
            }
            options={WIDGET_DETAILS.map((wd) => ({
              value: wd.type,
              label: wd.name,
            }))}
          />
        </Form.Item>
      </Form>
    </Container>
  );
};

const EditFormContainer: React.FC<Props> = (props) => {
  const [widgetType, setWidgetType] = useState<WidgetType>(props.config?.type);
  const mode = props.config ? "edit" : "create";

  const [form] = Form.useForm();

  useEffect(() => {
    const listener = props.submitTrigger.subscribe(() => form.submit());
    return () => listener.remove();
  }, [form, props.submitTrigger]);

  return (
    <div className="widget-edit-form">
      <ConfigProvider form={{ validateMessages: validationMessages }}>
        {mode === "create" ? (
          <TypeSelectForm onTypeChange={typ => {
            setWidgetType(typ);
            form.resetFields();
          }} />
        ) : (
          <></>
        )}
        {widgetType ? (
          getEditForm(widgetType)({
            template: props.template,
            form: form,
            onFinish: props.onFinish,
            config: props.config,
            onDualChange:props.onDualChange,
            enabledualaxis:props.enabledualaxis
          })
        ) : (
          <></>
        )}
      </ConfigProvider>
    </div>
  );
};

export default EditFormContainer;
