import { produce as immerProduce } from "immer";
import { initialState } from "./state";
import OrgTree from "../orgTree";

export function orgSelectorState(state = initialState, action: any) {
    let data: any = null;

    switch (action.type) {
        case "ORG_SELECTOR_OPEN":
            return immerProduce(state, draft => {
                const instance = Math.random();
                draft.orgSelector.instance = instance;
                draft.orgSelector.width = action.width;
                draft.orgSelector.visible = true;
                draft.orgSelector.title = action.title;
                draft.orgSelector.updated = false;                
            });
        case "ORG_SELECTOR_CLOSE":
            return immerProduce(state, draft => {
                draft.orgSelector.visible = false;
            });
        case "ORG_SELECTOR_REMOVE_SELECTIONS_SUCCESS":
            return immerProduce(state, draft => {
                draft.orgSelector.orgSelection = [];
                draft.orgSelector.list.refreshOnEntry = true;
                draft.orgSelector.tree.refreshOnEntry = true;
                draft.orgSelector.tree.treeDataChecked = [];                
            });
        case "ORG_SELECTOR_SET_VIEW":
            return immerProduce(state, draft => {
                draft.orgSelector.currentView = action.viewName;
            });
        case "ORG_SELECTOR_LIST_SET_PAGINATION":
            return immerProduce(state, draft => {
                draft.orgSelector.list.tableConfig.pagination.onChange = action.onPaginationChange;
            });
        case "ORG_SELECTOR_LIST_SET_CURRENT_PAGE":
            return immerProduce(state, draft => {
                draft.orgSelector.list.tableConfig.pagination.current = action.page;
            });
        case "ORG_SELECTOR_LIST_SET_ORG_TREE":
            return immerProduce(state, draft => {
                draft.orgSelector.list.orgTree = action.orgTree;
            });
        case "ORG_SELECTOR_LIST_SET_SEARCH":
            return immerProduce(state, draft => {
                draft.orgSelector.list.searchText = action.searchText;
                draft.orgSelector.list.orgId = "";
                if ((action.searchText || "") === "" && state.orgSelector.list.mode !== "all") {
                    draft.orgSelector.list.mode = "all";
                }
            });
        case "ORG_SELECTOR_LIST_SET_DISPLAY":
            return immerProduce(state, draft => {
                draft.orgSelector.list.mode = action.mode;
                draft.orgSelector.list.searchText = action.searchText;
                draft.orgSelector.list.orgId = action.orgId;
                draft.orgSelector.list.orgName = action.orgName;
            });
        case "ORG_SELECTOR_LIST_GET_ORGS_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.orgSelector.list.tableData = data;
                draft.orgSelector.list.tableConfig.pagination.total = (data.length || 0) > 0 ? data[0].totalRecords : 0;
                draft.orgSelector.list.tableConfig.pagination.current = action.payload.page;
                draft.orgSelector.list.refreshOnEntry = false;
            });
        case "ORG_SELECTOR_LIST_SET_SELECTION":
            return immerProduce(state, draft => {
                updateListDataChecked(draft, action.orgId, action.selected);
                draft.orgSelector.updated = true;
            });
        case "ORG_SELECTOR_TREE_EXPAND":
            return immerProduce(state, draft => {
                draft.orgSelector.tree.treeDataExpanded = action.expandedKeys;
                draft.orgSelector.tree.isExpanded = action.isExpanded
            });
        case "ORG_SELECTOR_TREE_CHECK":
            return immerProduce(state, draft => {
                draft.orgSelector.tree.treeDataChecked = action.checkedKeys;
            });
        case "ORG_SELECTOR_TREE_GET_ORGS_SUCCESS":
            return immerProduce(state, draft => {
                try {
                    loadListIntoTree(draft, action.payload.data);
                    draft.orgSelector.tree.refreshOnEntry = false;
                }
                catch (err) {
                    console.log(action.type + ": " + err);
                }                
            });
        case "ORG_SELECTOR_TREE_SET_SELECTION":
            return immerProduce(state, draft => {
                updateTreeDataChecked(draft, action.orgSelection);
            });
        case "ORG_SELECTOR_TREE_SELECTION":
            return immerProduce(state, draft => {
                draft.orgSelector.tree.selectedOrgId = action.orgId;
            });    
        case "ORG_SELECTOR_HEADER_SET_DISPLAY":
            return immerProduce(state, draft => {
                draft.orgSelector.selectonHeader = !draft.orgSelector.selectonHeader;
            });
        case "ORG_SELECTOR_SET_ORG_SELECTION":
            return immerProduce(state, draft => {
                draft.orgSelector.orgSelection = action.data;
            });
        case "ORG_SELECTOR_SET_INCLUDE_SUBORGS":
            return immerProduce(state, draft => {
                draft.orgSelector.includeSuborgs = action.includeSuborgs;       
                if (action.updateState || false) {
                    draft.orgSelector.updated = true;
                }
            });
        case "ORG_SELECTOR_HEADER_TAG_REMOVE":
            return immerProduce(state, draft => {
                removeHeaderTag(draft, action.orgId);
                draft.orgSelector.updated = true;
            });
        default:
            return state;
    }
}

const findFlatParent = (flatData: any[], parentOrgId: string): string => {
    let orgId:string = "";

    flatData.forEach((record: any) => {
        if (record.orgId === parentOrgId) {
            orgId = record.orgId;
        }
    });

    return orgId;
}

const loadListIntoTree = (draft: any, flatData: any[]): void => {

    flatData.forEach((record: OrgTree) => {
        record.orgIdParent = findFlatParent(flatData, record.orgIdParent || "");
    });

    const addChildren = (item: OrgTree) => {
        item.children = tempChild[item.orgId] || [];
        if (item.children.length) {
            item.children.forEach((child: OrgTree) => {
                addChildren(child);
            });
        }
    };

    const tempChild: any = {};
    flatData.forEach((item: OrgTree) => {
        const parentId = item.orgIdParent || 0;
        Array.isArray(tempChild[parentId]) ? tempChild[parentId].push(item) : (tempChild[parentId] = [item]);
    });

    const tree: OrgTree[] = tempChild[0];
    tree.forEach((base: OrgTree) => {
        addChildren(base);
    });
    draft.orgSelector.tree.treeData = tree;
    draft.orgSelector.tree.treeDataChecked = [];
    draft.orgSelector.tree.treeDataExpanded = [];
    draft.orgSelector.tree.treeDataFlat = flatData;

    flatData.forEach((record: any) => {
        if (record.orgSelection) {
            draft.orgSelector.tree.treeDataChecked.push(record.orgId);
        }
        if(record.properties && JSON.parse(record.properties).disableFeatures 
            && JSON.parse(record.properties).disableFeatures.length > 0){
            draft.orgSelector.tree.restrictedOrgs.push(record.orgId);
            if(draft.orgSelector.tree.restrictedFeatures.length===0){
                draft.orgSelector.tree.restrictedFeatures=JSON.parse(record.properties).disableFeatures;
            }
            if(draft.orgSelector.tree.restrictedOrgsExpansion.indexOf(record.orgIdParent) === -1){
                draft.orgSelector.tree.restrictedOrgsExpansion.push(record.orgIdParent);
            }
        }
    });

    draft.orgSelector.tree.treeData.forEach((record: any) => {
        draft.orgSelector.tree.treeDataExpanded.push(record.orgId);
    });
}

const updateListDataChecked = (draft: any, orgId: any, checked: boolean): void => {

    draft.orgSelector.list.tableData.forEach(function (record: any, index: number) {
        if (record.orgId === orgId) {
            record.orgSelection = checked;
        }
    });
}

const updateTreeDataChecked = (draft: any, orgSelection: any): void => {

    draft.orgSelector.tree.treeDataChecked = [];
    orgSelection.forEach(function (record: any, index: number) {
        draft.orgSelector.tree.treeDataChecked.push(record.orgId);
    });
}

const removeHeaderTag = (draft: any, orgId: any): void => {

    let orgSelection = draft.orgSelector.orgSelection,
        orgIndex = orgSelection.indexOf(orgId);

    if (orgIndex > -1) {
        orgSelection.splice(orgIndex, 1);
    }
    draft.orgSelector.orgSelection = orgSelection;

    updateListDataChecked(draft, orgId, false);
    updateTreeDataChecked(draft, draft.orgSelector.orgSelection);
}
