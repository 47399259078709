import React, { Component } from "react";
import { Table, Button, Space, message, notification, Switch, InputNumber, Tooltip, Collapse, Row, Col, Radio } from "antd";
import { PlusOutlined, DeleteOutlined, ExportOutlined, EditOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { appConfigs } from "../../../utils/configurations";
import history from '../../../utils/history';
import { uuidv4, closePopover, getPermissions } from "../../../utils/commonHelpers";

import Dm1Messaging from "./Dm1Messaging";
import Dm1UploadFile from "./Dm1UploadFile";

import { connect } from "react-redux";
import * as actions from "../actions";
import { CSVLink } from "react-csv";

import "./dm1Faults.css";
//import { getPermissions } from "../../../actions/permissions";
const { Panel } = Collapse;

interface Prop {
    dm1FaultsSetPagination: Function,
    dm1FaultsSetSearch: Function,
    getDm1Faults: Function,
    dm1FaultsDelete: Function,
    dm1FaultsDbDelete: Function,
    dm1FaultsAddRecord: Function,
    dm1FaultsMaint: Function,
    dm1FaultsSetFieldValue: Function,
    //dm1FaultsSetImportFlag : Function,  //feature can track when csv file is imported
    dm1ListType: any,
    templateDetails: any,
    dm1Faults: any,    
    iscreate: boolean, 
    isread: boolean,
    isupdate: boolean,
    isdelete: boolean,    
    context: any
}


interface State {
    editMode: boolean,
    csvData: any,
    csvFileName: string,
}


class Dm1Faults extends Component<Prop, State> {
    listType = this.props.dm1ListType.toLowerCase();
    listIndex = (this.listType=='whitelist')?0:1;
    
    drawerWidth:number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);
    csvLink:any = React.createRef<CSVLink>();    
    
    state: State = {
        editMode: false,
        csvData: [],
        csvFileName: ""
    };

    getTemplateId() {
        const pathnameArr = history.location.pathname.split("/");
        return pathnameArr[pathnameArr.length - 1];
    }

    getTemplateNameUrl() {
        const pathnameArr = history.location.pathname.split("/");
        return pathnameArr[pathnameArr.length - 2];
    }

    componentDidMount () {        
        this.props.dm1FaultsSetPagination(this.onPaginationChange);
        this.props.getDm1Faults(this, this.listType, null, null, this.getTemplateId(), 1, this.props.dm1Faults.searchText);
    }

    componentDidUpdate(prevProps: Prop) {
        // Typical usage (don't forget to compare props):
        if (this.props.templateDetails.templateRecord.dm1WhitelistType !== prevProps.templateDetails.templateRecord.dm1WhitelistType) {
          this.setState({editMode: false})
          this.props.getDm1Faults(this, this.listType, null, null, this.getTemplateId(), 1, this.props.dm1Faults.searchText);
        }  
      }
    onPaginationChange = (page: number) => {
        this.props.getDm1Faults(this, this.listType, null, null, this.getTemplateId(), page, this.props.dm1Faults.searchText);
    };

    onAction = (e:any, record:any, action:string) => {
        e.preventDefault();

        if ( action === "add" ) {
        }
        else if ( action === "assign" ) {
            message.info("TBD");            
        }
       
        closePopover();       
    }
    
    addRecord = (e:any) => {
        e.preventDefault();
        if (!this.state.editMode) {
            this.setState({
                editMode: true
            });
        }
        this.props.dm1FaultsAddRecord(this,this.listType);        
    }

    editMode = (e: any) => {   
        e.preventDefault();
        this.editModeSet();
    }

    editModeSet = () => {   
        this.setState({
            editMode: !this.state.editMode
        });
    }

    csvRecordPush = (csvRecord: any, attributeValue:string) => {  
        //Replace single " character with two " characters to escape it CSV style
        csvRecord.push(attributeValue.replace(/"/g,'""'));
    }
    
    csvData = () => {  
        let csvData:any = [];

        this.props.dm1Faults.tableData[this.listIndex].forEach((record: any, index: number) => {     
            let csvRecord:string[] = [];                
            if (index === 0) {
                this.csvRecordPush(csvRecord, "DM1Enable");
                this.csvRecordPush(csvRecord, "SA");
                this.csvRecordPush(csvRecord, "SPN");
                this.csvRecordPush(csvRecord, "FMI");
                csvData.push(csvRecord);
            }
            csvRecord = [];                 
            this.csvRecordPush(csvRecord, (record.enabled ? "Enabled" : "Disabled"));
            this.csvRecordPush(csvRecord, record.sa.toString());
            this.csvRecordPush(csvRecord, record.spn.toString());
            this.csvRecordPush(csvRecord, record.fmi.toString());
            csvData.push(csvRecord);
        });

        this.setState({ csvData: csvData, csvFileName: "dm1-faults-"+ this.listType + '-' + uuidv4() + ".csv" });
    }

    exportData = (e: any) => {   
        e.preventDefault();        

        this.csvData();

        const me = this;
        setTimeout(function () {
            me.csvLink.link.click();
        }, 250);   
    }
 
    onFinish = (e: any) => {   
        e.preventDefault();    
        
        if (!this.state.editMode) {
            this.setState({
                editMode: true
            });
        }
        
        const me = this;
        setTimeout(function() {
            me.onFinishSubmit();
        }, 250);             
    }

    onFinishSubmit = () => {       
        let recordValidation:boolean = true;
        let duplicatesExist:boolean = false;
        let tempData: any ={};
        
        this.props.dm1Faults.tableData[this.listIndex].every(function (record:any) {              
            
            if (
                ((typeof(record.sa) === "number" ? record.sa + "" : record.sa) || "") === "" || 
                ((typeof(record.spn) === "number" ? record.spn + "" : record.spn) || "") === "" || 
                ((typeof(record.fmi) === "number" ? record.fmi + "" : record.fmi) || "") === ""
            ) {
                recordValidation = false;
                return false;
            }

            let key:string = 'sa'+record.sa.toString()+'spn'+record.spn.toString()+'fmi'+record.fmi.toString() ;
            
            
            if (key in tempData) 
            {
                recordValidation = false;
                duplicatesExist = true;
                return false;
            }
            tempData[key]=1
            return true;
        });  


        if (!recordValidation) {
            if (duplicatesExist){
                notification.error({
                    message: "Validation Failure",
                    description: "Please ensure you enter a unique combination of SA, SPN and FMI values for all DM1 Faults. Delete duplicate records."
                });
            }
            else{
                notification.error({
                    message: "Validation Failure",
                    description: "Please provide SA, SPN and FMI values for all DM1 Faults. Delete unwanted records."
                });
            }
            return false;            
        }
        else {
            this.props.dm1FaultsMaint(this, this.onFinishSubmitSuccess, null, this.getTemplateId(), this.listType, this.props.dm1Faults.tableData[this.listIndex]);   
        }
    }

    onFinishSubmitSuccess = (record: any) => { 
        message.success("DM1 Faults records for "+this.listType+" have been successfully processed");  
        //if (this.props.dm1Faults.importFlag[this.listIndex]){
        //    this.props.dm1FaultsSetImportFlag(this,this.listType,false);
        //    }  
    }

    onInputChange = (index:number, fieldName:string, fieldValue:any) => {
        this.props.dm1FaultsSetFieldValue(this, this.listType, index, fieldName, fieldValue); 
    };
      
    deleteRecord = (e:any, index:number, record:any) => {
        e.preventDefault();

        if ((record.dm1Id || "") !== "") {
            this.props.dm1FaultsDbDelete(this, this.deleteRecordSuccess, null, this.getTemplateId(), this.listType, record.dm1Id);
        }
        else {
            this.props.dm1FaultsDelete(this, this.listType,index);
            this.deleteRecordSuccess("");
        }
    };

    deleteRecordSuccess = (dm1Id:string) => {       
        message.success("Record has been successfully deleted");    
    };

    render() {    
    
        const { tableConfig, tableData } = this.props.dm1Faults;  
        const { templateRecord } = this.props.templateDetails;
        

        const tableColumns = [
            {
                title: (this.listType=='whitelist')?"DM1Enable":"DM1Disable" ,
                dataIndex: "enabled",
                key: "enabled",
                align: "center" as "center",
                width: "25%",
                sorter: (record1:any, record2:any) => (record1.enabled ? "enabled" : "disabled").length - (record2.enabled ? "enabled" : "disabled").length,
                sortDirections: ['descend', 'ascend'],
                render: (text:number, record:any, index:number) => (
                    <>
                        {
                            this.state.editMode && 
                            <Switch 
                                checked={this.props.dm1Faults.tableData[this.listIndex][index].enabled}    
                                onChange={(value, event) => this.onInputChange(index, "enabled", value)} 
                            />      
                        }
                        {
                            !this.state.editMode &&
                            <>
                                {
                                    record.enabled &&
                                    <CheckCircleOutlined className="dm1-fault-enbled"/>
                                }
                                {
                                    !record.enabled &&
                                    <CloseCircleOutlined className="dm1-fault-disabled"/>
                                }
                            </>
                        }
                    </>
                )               
            },
            {   
                title: "SA",
                dataIndex: "sa",
                key: "sa",
                align: "center" as "center",
                width: "25%",
                sorter: (record1:any, record2:any) => record1.sa - record2.sa,
                sortDirections: ['descend', 'ascend'],
                render: (text:number, record:any, index:number) => (
                    <>
                        {
                            this.state.editMode &&
                            <InputNumber 
                                name="sa"
                                min={0} 
                                max={9999} 
                                precision={0}                                       
                                value={this.props.dm1Faults.tableData[this.listIndex][index].sa}    
                                onChange={(value) =>
                                    this.onInputChange(index, "sa", value)
                                }          
                            />
                        }
                        {
                            !this.state.editMode &&
                            <span>{text}</span>
                        }
                    </>
                )
            },
            {
                title: "SPN",
                dataIndex: "spn",
                key: "spn",
                align: "center" as "center",
                width: "25%",
                sorter: (record1:any, record2:any) => record1.sa - record2.sa,
                sortDirections: ['descend', 'ascend'],
                render: (text:number, record:any, index:number) => (
                    <>
                        {
                            this.state.editMode &&
                            <InputNumber 
                                name="spn"
                                min={0} 
                                max={9999} 
                                precision={0}                                       
                                value={this.props.dm1Faults.tableData[this.listIndex][index].spn}  
                                onChange={(value) =>
                                    this.onInputChange(index, "spn", value)
                                }          
                            />
                        }
                        {
                            !this.state.editMode &&
                            <span>{text}</span>
                        }
                    </>
                )
            },
            {
                title: "FMI",
                dataIndex: "fmi",
                key: "fmi",
                align: "center" as "center",
                width: "25%",
                sorter: (record1:any, record2:any) => record1.sa - record2.sa,
                sortDirections: ['descend', 'ascend'],
                render: (text:number, record:any, index:number) => (
                    <>
                        {
                            this.state.editMode &&
                            <InputNumber 
                                name="fmi"
                                min={0} 
                                max={9999} 
                                precision={0}                                       
                                value={this.props.dm1Faults.tableData[this.listIndex][index].fmi}   
                                onChange={(value) =>
                                    this.onInputChange(index, "fmi", value)
                                }          
                            />
                        }
                        {
                            !this.state.editMode &&
                            <span>{text}</span>
                        }
                    </>
                )
            },
            {
                title: "Actions",
                align: "center" as "center",
                render: (text:number, record:any, index:number) => (
                    <>
                        {
                            (((templateRecord.templateVisibleToSubOrg && (this.props.context.appContext.roleName.toLowerCase() === "admin"))) 
                             || (!templateRecord.templateVisibleToSubOrg && this.props.isdelete))  &&
                            <Tooltip title="Delete Record">
                                <Button disabled={!((templateRecord.dm1WhitelistType.toLowerCase() ===  this.listType)||(templateRecord.dm1WhitelistType.toLowerCase() === "whiteandblacklist"))} data-id="dm1-faults-delete-button" type="default" icon={<DeleteOutlined />} onClick={(e) => {this.deleteRecord(e, index, record)}}/>
                            </Tooltip>
                        } 
                    </>
                )
            }
        ];

        return (
            
            <div data-id="template-dm1-faults">
                <Collapse data-id="template-dm1-messaging-0" bordered={false} defaultActiveKey={['dm1-messaging']} className="auto-close">
                    <Panel data-id="template-dm1-messaging-1" header={this.props.dm1ListType} key="dm1-messaging">
                        <div className="mt-20">
                        
                            <Space size="middle">
                                {                            
                                    ((templateRecord.dm1WhitelistType.toLowerCase() ===  this.listType)||(templateRecord.dm1WhitelistType.toLowerCase() === "whiteandblacklist")) &&
                                    <>
                                        
                                        {
                                            (((templateRecord.templateVisibleToSubOrg 
                                                && (this.props.context.appContext.roleName.toLowerCase() === "admin")))
                                            || (!templateRecord.templateVisibleToSubOrg && this.props.iscreate)) &&                                   
                                            <Tooltip title="Add Blank Record">
                                                <Button data-id="dm1-faults-add-button" onClick={(e) => {this.addRecord(e)}} type="default" icon={<PlusOutlined />}>
                                                    <span>Add</span>
                                                </Button>
                                            </Tooltip>

                                        }
                                        {
                                            (tableData[this.listIndex].length || 0) > 0 &&    
                                            (((templateRecord.templateVisibleToSubOrg 
                                                && (this.props.context.appContext.roleName.toLowerCase() === "admin")))
                                            || (!templateRecord.templateVisibleToSubOrg && this.props.isupdate)) &&
                                            <Tooltip title="Edit Records">
                                                <Button data-id="dm1-faults-edit-button" onClick={(e) => {this.editMode(e)}} type="default" icon={<EditOutlined />}>
                                                    <span>Edit</span>
                                                </Button>
                                            </Tooltip>
                                        }
                                        {
                                            (((templateRecord.templateVisibleToSubOrg 
                                                && (this.props.context.appContext.roleName.toLowerCase() === "admin")))
                                            || (!templateRecord.templateVisibleToSubOrg && (this.props.iscreate || this.props.isupdate)))
                                            &&
                                            <Dm1UploadFile data-id="dm1-faults-upload-button" editModeSet={this.editModeSet} listType={this.listType}/>
                                        }
                                        
                                    </>
                                }
                                {
                                    (((templateRecord.templateVisibleToSubOrg 
                                        && (this.props.context.appContext.roleName.toLowerCase() === "admin")))
                                    || (!templateRecord.templateVisibleToSubOrg && (this.props.iscreate || this.props.isupdate)))
                                    &&
                                    <>
                                        <Tooltip title="Export Table Data">
                                            <Button data-id="dm1-faults-export-button" href="# " onClick={(e) => {this.exportData(e)}} target="_new" type="default" icon={<ExportOutlined />}>
                                                <span>Export</span>                        
                                            </Button>
                                        </Tooltip>
                                        <CSVLink
                                            data={this.state.csvData}
                                            filename={this.state.csvFileName}
                                            className="hidden"
                                            ref={(r:any) => this.csvLink = r}
                                            target="_blank"
                                        />
                                    </>
                                }
                            </Space>
                            {                        
                                 ((templateRecord.dm1WhitelistType.toLowerCase() ===  this.listType)||(templateRecord.dm1WhitelistType.toLowerCase() === "whiteandblacklist")) && 
                                ((tableData[this.listIndex].length || 0) > 0) &&     
                                (((templateRecord.templateVisibleToSubOrg 
                                    && (this.props.context.appContext.roleName.toLowerCase() === "admin")))
                                || (!templateRecord.templateVisibleToSubOrg && (this.props.iscreate || this.props.isupdate))) &&
                                <div>
                                    <Button data-id="dm1-faults-submit-top-button" href="# " onClick={(e) => {this.onFinish(e)}} type="primary">
                                        {appConfigs.settings.settingStyleForm.saveButtonText}
                                    </Button>   
                                </div>       
                            }
                        
                        </div>
                        
                        
                        {
                            this.props.isread &&
                            <Table  
                                data-id="template-dm1-faults-table"
                                {...tableConfig}
                                rowKey={(record) => record.dm1Id}
                                columns={tableColumns}
                                dataSource={tableData[this.listIndex]}     
                                className="mt-15"
                            />
                        }
                    </Panel>
                    
                </Collapse>
            </div>
        );
    }
}

const mapStateToProps = (state:any) => {    
    return {
        context: state.contextReducer.context,
        dm1Faults: state.templateDetailsReducers.templateDetailsState.tabs[1],
        templateDetails: state.templateDetailsReducers.templateDetailsState
    };
};

export default connect(
    mapStateToProps,
    actions
)(Dm1Faults);
  