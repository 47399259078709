import React, { useState, useEffect } from "react";
import { Table,  Row, Space, Button, Tooltip  } from "antd";
import { InfoOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import * as actions from "./actions/csu";
import { appConfigs } from "../../utils/configurations";
import "./csu.css";
import { getPermissions } from "../../utils/commonHelpers";
import onlineIcon from "../../assets/images/map-status/online.png";
import offlineIcon from "../../assets/images/map-status/offline.png";
import moment from "moment";

interface Props {    
    context: any;
    permissions: any;
    search: string,
    tabData: any;
    csus: any;
    isSearch: boolean;
    getOTAHistory: Function;
    csuMaintOpen: Function;
    csuSetPaginationForHistory: Function;
    processAction: Function;
    resetSearch: Function;
  }
const CSUHistory: React.FC<Props> = (props) => {
   // const [calibrationsHistory, setCalibrationsHistory] = useState([]);
    const { contextUser, appContext } = props.context;
    const [sortColumn, setSortColumn] = useState("assetname");
    const [page, setPage] = useState(1);
    const [sortDirection, setSortDirection] = useState("ascend");
    const result = getPermissions(props.permissions.permissions.asset_widgets); 
    const { tableConfig, tableData, search } = props.csus;
    console.log("Table Config: "+ JSON.stringify(tableConfig));
    useEffect(() => {
        if(props.tabData === "2") {
            props.csuSetPaginationForHistory(onPaginationChange);
            props.getOTAHistory(getOTAHistoryOnSuccess,getOTAHistoryOnError,null, null, null, props.search, "all", 1, null);
            
        }
        // return () => {
        //    // setCalibrationsHistory([]);
        // }
    }, [props.search, props.tabData]);
    useEffect(() => {
        if(props.tabData === "2") {
            props.csuSetPaginationForHistory(onPaginationChange);           
            props.getOTAHistory(getOTAHistoryOnSuccess,getOTAHistoryOnError,null, null, null, props.search, "all", page, null);
            
        }
        // return () => {
        //    // setCalibrationsHistory([]);
        // }
    }, [props.isSearch, page]);

    const getOTAHistoryOnSuccess = (ota:any)=>{
            props.resetSearch();
            ota.map((data:any) => {
                // console.log("CSU status history INFO: "+ JSON.stringify(data));
            });
         //   setCalibrationsHistory(ota);
    }

    const getOTAHistoryOnError = (error:any) =>  {
        props.resetSearch();
        console.log("Error: "+ JSON.stringify(error));
    }

    const initiateAction=(e:any,record:any, operationType: any) =>{
        console.log("e: "+ e);
        console.log("operationType: "+ operationType);
        console.log("record: "+ JSON.stringify(record));
        props.processAction(record, operationType.toString().toLowerCase(),actionOnSuccess,actionOnError);
    }

    const actionOnSuccess = (data:any) => {

    }

    const actionOnError = (error:any) => {

    }

    const calibrationMaintOpen = (e:any, record:any) => {    
        e.preventDefault();
        calibrationMaintOpenDo(record);
    };
    const drawerWidth: number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);
    const calibrationMaintOpenDo = (record:any) => {
        const title =  "Asset :: " + (record.nickname || record.mastertag) + " :: " + record.status.charAt(0).toUpperCase() + record.status.slice(1);
        props.csuMaintOpen(title, drawerWidth, record);
    }


    const onPaginationChange = (page: number) => {
        //if(tableData && tableData.length < 1) {
                props.getOTAHistory(getOTAHistoryOnSuccess,getOTAHistoryOnError,null, null, null, props.search, "all", page, null);
        //}
    };

    const sortAssetsTable = (pagination: any, filters: any, sorter: any) => {
        let sortColumn: string | undefined = undefined;
        let sortDirection: string | undefined = undefined;
        if (sorter && Object.keys(sorter).length > 0) {
            const { column, order } = sorter;
            sortDirection = order;
            if (column?.title?.hasOwnProperty('props')) {
                sortColumn = column?.title?.props['data-title'].toLowerCase().replace(/\s/g, '')
            } else {
                sortColumn = column?.title.toLowerCase().replace(/\s/g, '');
            }
        }
        setPage(pagination.current);
        setSortColumn(sortColumn || '');
        setSortDirection(sortDirection || '');
        //props.getOTAHistory(getOTAHistoryOnSuccess,getOTAHistoryOnError,null, sortColumn, sortDirection, props.search, 'all', page, null);

    }

    console.log("TABLE DATA");
    console.log("+++++++++++++++++++++++++++++++++++++++++++++++++");
    console.log("HOSTORY DATA: "+ tableData);
    console.log("+++++++++++++++++++++++++++++++++++++++++++++++++");
    const ParkerOrgId = 'c6adcb40-be92-11e6-9ed6-a5bc9cb5279b'
    let tableHistoryColumns = [
        {
            title: "Asset",
            column_name:"assetName",
            dataIndex: "assetName",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'assetname' ? sortDirection : false,
        },
        {
            title: "Mastertag",
            column_name:"mastertag",
            dataIndex: "mastertag",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'mastertag' ? sortDirection : false,
        },
        {
            title: "ESN",
            column_name:"esn",
            dataIndex: "esn",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'esn' ? sortDirection : false,
        },
        {
            title: "Current Version",
            column_name:"currentVersion",
            dataIndex: "currentVersion",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'currentversion' ? sortDirection : false,
        },
        {
            title: "New Version",
            column_name:"newVersion",
            dataIndex: "newVersion",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'newversion' ? sortDirection : false,
        },
        {
            title: "Old OTA Status",
            column_name:"oldStatus",
            dataIndex: "oldStatus",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'oldstatus' ? sortDirection : false,
        },       
        {
            title: "OTA Status",
            column_name:"otaStatus",
            dataIndex: "otaStatus",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'otastatus' ? sortDirection : false,
        },
        {
            title: "CSU Status at",
            column_name:"statusAt",
            //dataIndex: "statusAt"
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'statusat' ? sortDirection : false,
            render: (text: any, record: any) => (
                <>                    
                {  
                    <span>{moment(record.statusAt).format('MM/DD/YYYY, h:mm:ss A').toLocaleString()}</span>  
                }
                </>
            )
        },
        {
            title: "Attempts",
            column_name:"attempts",
            dataIndex: "attempts",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'attempts' ? sortDirection : false,
        },
        {
            title: "Requests",
            column_name:"reqs",
            dataIndex: "reqs",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'reqs' ? sortDirection : false,
        },
        {
            title: "Category",
            column_name:"category",
            dataIndex: "category",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'category' ? sortDirection : false,
        },
        {
            title: "Operation",
            column_name:"operation",
            dataIndex: "operation",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'operation' ? sortDirection : false,
        },
        {
            title: "Org Name",
            column_name:"orgName",
            dataIndex: "orgName",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'orgname' ? sortDirection : false,
        },
        {
            title: "Info",
            column_name:"info",
            align: "center" as "center",
            render: (text: string, record: any) => (
                <Space>
                    {
                        <Tooltip title="View Info">
                            <Button
                                type="default" 
                                icon={<InfoOutlined />}
                                onClick={(e:any) => {calibrationMaintOpen(e, record)}}
                            />
                        </Tooltip>
                    }
                </Space>
            )
        }//,
        // {
        //     title: "Actions",
        //     column_name:"actionList",
        //     //dataIndex: "orgName"
        //     align: "center" as "center",
        //     render: (text: string, record: any) =>(
        //         <>
        //             <div className="container">
        //             {/* //{record.actionList.replace(/['"]+/g, '').map((tag:any) => {                         */}
        //             { record.actionList.substr(0, record.actionList.length - 1).substr(1).split(',').map((tag:any,index:any) => {
        //             return (
        //                 (tag === "")?
        //                 <div style={{cursor: "default"}}>{record.otaStatus}</div>
        //                 :
        //                 null
        //             );
        //             })}
        //             </div>
        //         </>
                   
        //     ) 
            
        // }
    ]
  
    let tableHistoryColumnsWithConnectionStatus = [
        {
            title: "Asset",
            column_name:"assetName",
            dataIndex: "assetName",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'assetname' ? sortDirection : false,
        },
        {
            title: "Status",
            column_name:"connectionStatus",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'connectionstatus' ? sortDirection : false,
            render: (text: any, record: any) => (
                            <>
                                 {
                                    record.connectionStatus === "ONLINE" && //record.status !== "inactive" && record.status !== "whitelisted" &&
                                    <Tooltip title="Online">
                                        <span className="asset-status-green alarm-info-icon pr-5" ><img src={onlineIcon} alt="" /></span>
                                    </Tooltip>
                                }
                                {/* {
                                    record.connectionStatus === "ONLINE" && record.status !== "active" &&
                                    <Tooltip title="Offline">
                                        <span className="asset-status-red alarm-info-icon pr-5" ><img src={offlineIcon} alt="" /></span>
                                    </Tooltip>
                                } */}
                                {
                                    record.connectionStatus === "OFFLINE" &&
                                    <Tooltip title="Offline">
                                        <span className="asset-status-red alarm-info-icon pr-5" ><img src={offlineIcon} alt="" /></span>
                                    </Tooltip>
                                }
                            </>
                        )
        },
        {
            title: "Mastertag",
            column_name:"mastertag",
            dataIndex: "mastertag",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'mastertag' ? sortDirection : false,
        },
        {
            title: "ESN",
            column_name:"esn",
            dataIndex: "esn",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'esn' ? sortDirection : false,
        },
        {
            title: "Current Version",
            column_name:"currentVersion",
            dataIndex: "currentVersion",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'currentversion' ? sortDirection : false,
        },
        {
            title: "New Version",
            column_name:"newVersion",
            dataIndex: "newVersion",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'newversion' ? sortDirection : false,
        },
        {
            title: "Old OTA Status",
            column_name:"oldStatus",
            dataIndex: "oldStatus",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'oldstatus' ? sortDirection : false,
        },       
        {
            title: "OTA Status",
            column_name:"otaStatus",
            dataIndex: "otaStatus",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'otastatus' ? sortDirection : false,
        },
        {
            title: "CSU Status at",
            column_name:"statusAt",
            //dataIndex: "statusAt"
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'statusat' ? sortDirection : false,
            render: (text: any, record: any) => (
                <>                    
                {  
                    <span>{moment(record.statusAt).format('MM/DD/YYYY, h:mm:ss A').toLocaleString()}</span>  
                }
                </>
            )
        },
        {
            title: "Attempts",
            column_name:"attempts",
            dataIndex: "attempts",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'attempts' ? sortDirection : false,
        },
        {
            title: "Requests",
            column_name:"reqs",
            dataIndex: "reqs",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'reqs' ? sortDirection : false,
        },
        {
            title: "Category",
            column_name:"category",
            dataIndex: "category",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'category' ? sortDirection : false,
        },
        {
            title: "Operation",
            column_name:"operation",
            dataIndex: "operation",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'operation' ? sortDirection : false,
        },
        {
            title: "Org Name",
            column_name:"orgName",
            dataIndex: "orgName",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'orgname' ? sortDirection : false,
        },
        {
            title: "Info",
            column_name:"info",
            align: "center" as "center",
            render: (text: string, record: any) => (
                <Space>
                    {
                        <Tooltip title="View Info">
                            <Button
                                type="default" 
                                icon={<InfoOutlined />}
                                onClick={(e:any) => {calibrationMaintOpen(e, record)}}
                            />
                        </Tooltip>
                    }
                </Space>
            )
        }//,
        // {
        //     title: "Actions",
        //     column_name:"actionList",
        //     //dataIndex: "orgName"
        //     align: "center" as "center",
        //     render: (text: string, record: any) =>(
        //         <>
        //             <div className="container">
        //             {/* //{record.actionList.replace(/['"]+/g, '').map((tag:any) => {                         */}
        //             { record.actionList.substr(0, record.actionList.length - 1).substr(1).split(',').map((tag:any,index:any) => {
        //             return (
        //                 (tag === "")?
        //                 <div style={{cursor: "default"}}>{record.otaStatus}</div>
        //                 :
        //                 null
        //             );
        //             })}
        //             </div>
        //         </>
                   
        //     ) 
            
        // }
    ]

    return(
        
        <>
        <div>        
            <Row gutter={8}>
                <Table 
                        data-id="ota-history-data" 
                        className="mt-15"
                        style={{ overflowX: 'auto' }}
                        {...tableConfig }
                        rowKey={(record:any) => record.gatewayId}
                        // columns={(appContext.orgId === ParkerOrgId)? tableHistoryColumnsWithConnectionStatus :tableHistoryColumns}
                        columns = {tableHistoryColumnsWithConnectionStatus}
                        dataSource={tableData}
                        onChange={sortAssetsTable}
                        showSorterTooltip={false}
                    />
            </Row>
        </div>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
      context: state.contextReducer.context,
      permissions: state.contextReducer.data,
      csus: state.csuReducer.csuState.csu.csuList.history
    };
  };

export default connect(mapStateToProps, actions)(CSUHistory);
