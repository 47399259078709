import React from "react";
import { Form, Select } from 'antd';

import { appConfigs } from "../../../utils/configurations";

import { connect } from "react-redux";
import * as actions from "../actions";

const { Option } = Select;

interface Prop {
    templateMaintInfoOrgsGet: Function,
    templatesList: any,
    context: any
}

class TemplateMaintInfoOrg extends React.Component<Prop, {}> {
    
    componentDidMount() {
        this.componentUpdate();
    }

    componentDidUpdate(prevProps: Prop) {
        if (this.props.templatesList.templateMaint.instance !== prevProps.templatesList.templateMaint.instance) {
            this.componentUpdate();
        }
    }

    componentUpdate() {
        this.props.templateMaintInfoOrgsGet(this, null, null, null, null);
    }

    render() {
    
        const { orgs } = this.props.templatesList.templateMaint.modes[0];

        return (
            <Form.Item
                name="orgId"
                label="Org Name"
                rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
            >               
                <Select 
                    data-id="assets-maint-asset-info-org-id"
                    showSearch
                    showArrow={true}
                    allowClear
                    placeholder="Select..."
                    optionFilterProp="children"
                    className="select-before"                            
                >
                    {orgs.map((record:any, index:number) => (
                        <Option key={record.orgId} value={record.orgId}>
                            {record.name}
                        </Option>
                    ))}
                </Select>     
            </Form.Item>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        templatesList: state.templatesReducers.templatesState.templatesList
    };
};

export default connect(
    mapStateToProps,
    actions
)(TemplateMaintInfoOrg);