import React, { useRef, useLayoutEffect } from "react";
import ClusterBarChart, { Options } from "./ClusterBarChart";
import useDomNode from "../../../../lib/use-dom-node";
import { CommonWidgetProps } from "../../models";
import { inputSignalMapper } from "../common";


const createSignalSeries = inputSignalMapper((meta, data) => ({
  ...meta,
  values: data,
}));

interface Props extends CommonWidgetProps {
  settings?: Options;
  errorCode?: string;
}

export const Widget: React.FC<Props> = (props) => {
  const [node, setNode] = useDomNode<HTMLDivElement>();
  const chartRef = useRef<ClusterBarChart>();

  const { template, data, settings, signals } = props;

  useLayoutEffect(() => {
    if (node) {
      chartRef.current = new ClusterBarChart(
        node,
        createSignalSeries(signals, template, data),
        settings
      );
      return () => chartRef.current?.destroy();
    }
  }, [node, template, settings, signals, data]);

  return <div style={{ height: "100%" }} ref={setNode} />;
};


export default Widget;
