import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function templateMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    record: any
) {
    return async (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL + "/ui/api/templates",
            record,
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    const mode = Object.keys(record).length > 0 && record.hasOwnProperty("templateId") && (record.templateId || "") !== "" ? "update" : "insert";
                    callbackFnSuccess(response.data.data.templates[0], mode);
                }
                dispatch({ type: "TEMPLATES_MAINT_SUCCESS", record: response.data.data.templates[0] });
            }
        )
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

export function templateDelete(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string
) {
    return async (dispatch: any) => {
        Axios.delete(
            appConfigs.server.URL + "/ui/api/templates/" + templateId,
            {}
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(templateId);           
                }
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

export function templateMaintInfoOrgsGet(
    me: any, 
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
    orgId?: string,
    searchText?: string
) {
    return async (dispatch: any) => {            
        Axios.get(
            appConfigs.server.URL + "/ui/api/orgs-lov" + ((orgId || "") !== "" ? "/" + orgId : ""), {
                responseType: "json",        
                headers: {},
                params: {
                    mode: (orgId || "") === "" ? "all" : "single",
                    scope: "all",
                    includeTree: false,
                    orgId: orgId,
                    searchText: searchText,
                    page: 1,
                    pageSize: 9999
                }
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.orgs);
                }
                dispatch({ type: "TEMPLATES_MAINT_ORGS_SUCCESS", payload: {data: response.data.data.orgs} });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
    };
}    