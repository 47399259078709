import { produce as immerProduce } from "immer";
import { initialState } from "./permissionsState";

export function permissionsReducer(state = initialState, action:any) {    
    switch (action.type) { 
        case "PERMISSIONS_RESULT":
            return immerProduce(state, draft => {             
                let reportAccessData:string = JSON.stringify(action.payload.data) || "{}"; 
                //window.sessionStorage.setItem("permissionsset", "{}");           
                draft.IsCreate = true;
                draft.IsRead=true;
                draft.IsUpdate=true;
                draft.IsDelete=true;
            });                      
        default:
            return state;
    }
}