import React, { useState } from "react";
import moment, { Moment } from "moment";
import {
  Form,
  Card,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Select,
  Button,
  Input,
} from "antd";
import { FilterFormProps } from "./FilterFormContainer";
import { WidgetFilterConfig } from ".";
import { addDateAndTime, getUnixDate, validateForms } from "../widgets/common";
import { appConfigs } from "../../../utils/configurations";

const datapointValues = appConfigs.app.assetOverview.datapointlimits.map(
  (d) => ({
    value: d,
    label: d,
  })
);

function sanitizeInputs(c: WidgetFilterConfig): WidgetFilterConfig {
  return {
    startDate: c.startDate,
    endDate: c.endDate,
    startTime: c.startTime,
    endTime: c.endTime,
    datapoint: c.datapoint,
  };
}

const FilterForm: React.FC<FilterFormProps> = (props) => {
  const [datapoint, setDatapoint] = useState(props.config?.datapoint || 50);

  const initialValues: WidgetFilterConfig = {
    startDate: props.config?.startDate ? props.config?.startDate : moment().subtract(1, "days"),
    startTime: props.config?.startTime ? props.config?.startTime : moment().subtract(1, "days"),
    endDate: props.config?.endDate ? props.config?.endDate : moment(),
    endTime: props.config?.endTime ? props.config?.endTime : moment(),
    datapoint: props.config?.datapoint
  };

  const onStartTimeChange = (time: Moment | null) => {
    if (!props.form.getFieldValue("startDate"))
      props.form.setFieldsValue({ startDate: moment() });
  };

  const onEndTimeChange = (time: Moment | null) => {
    if (!props.form.getFieldValue("endDate"))
      props.form.setFieldsValue({ endDate: moment() });
  };

    const onDateChange = (date: Moment | null, timeFieldName: string) => {
        props.form.setFieldsValue({ [timeFieldName]: (date ? moment("00:00:00", "HH:mm:ss") : null) });
    };

  return (
    <div className="text-widget-edit-form">
      <Form
        onFinish={(values) =>
          props.onFinish(sanitizeInputs(values as WidgetFilterConfig))
        }
        layout="vertical"
        form={props.form}
        initialValues={initialValues}
      >
        <Card title="Filter Widget">
          <Row></Row>
          <Row justify="space-between" style={{ width: "95%" }}>
            <Col span={24}>
              <Form.Item label="Start Date">
                <Input.Group compact>
                  <Form.Item
                    noStyle
                    name="startDate"                    
                    dependencies={["startTime", "endDate", "endTime"]}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          return validateForms(
                            value,
                            getFieldValue("endDate"),
                            getFieldValue("startTime"),
                            getFieldValue("endTime")
                          );
                        },
                      }),
                    ]}
                  >
                    <DatePicker inputReadOnly onChange={(date) => onDateChange(date, "startTime")} />
                  </Form.Item>
                  <Form.Item noStyle name="startTime">
                    <TimePicker inputReadOnly onChange={onStartTimeChange} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="End Date">
                <Input.Group compact>
                  <Form.Item noStyle name="endDate">
                    <DatePicker inputReadOnly onChange={(date) => onDateChange(date, "endTime")} />
                  </Form.Item>
                  <Form.Item noStyle name="endTime">
                    <TimePicker inputReadOnly onChange={onEndTimeChange} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" style={{ width: "95%" }}>
            <Col span={24}>
              <Form.Item name="datapoint" label="Datapoint Limit">
                <Select
                  style={{ width: "95%" }}
                  onChange={(v) => setDatapoint(v)}
                  value={datapoint}
                  options={datapointValues}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default FilterForm;
