import {
	GeofenceShape,
	drawShapes,
	jsonPolygon,
	jsonRectangle,
	jsonCircle,
	addListenerToUpdateGeoCoords,
	removeShapes,
} from "../../pages/GeoFences/geofenceHelper";

const DEFAULT_POLYOPTIONS = {
	fillColor: "#f73e16",
	strokeWeight: 0.5,
	fillOpacity: 0.3,
	editable: true,
	draggable: true,
};

export interface Geofence {
	description: string;
	fenceName: string;
	fenceColor: string;
	gateways: any;
	geoCoordinates: string;
	id: string;
	isActive: boolean;
	orgId: string;
	type: string;
}

export interface DrawingOptions {
	drawOverlays: boolean;
}

export default class DrawingManager {
	private _drawerMgr?: google.maps.drawing.DrawingManager;
	private _geofences?: Geofence[];
	private _geofenceOptions?: any;

	constructor(
		map: google.maps.Map,
		options?: DrawingOptions,
		geofences?: Geofence[],
		geofenceOptions?: any
	) {
		
		if (options?.drawOverlays) {
			this.initilizeDrawingOnMap(map);
		}
		
		this._geofences = geofences ? geofences : []
		this._geofenceOptions = geofenceOptions

		if (geofences?.length && options?.drawOverlays) {
			this.initilizeGeofences(map, geofences, geofenceOptions)
		}
	}

	private showHideContols(map: google.maps.Map, showHide: boolean) {
		if (!this._drawerMgr && showHide) {
			this.initilizeDrawingOnMap(map);
			this.initilizeGeofences(map, this._geofences ? this._geofences : [], this._geofenceOptions);
		}

		this._drawerMgr?.setOptions({
			drawingControl: showHide
		});
	}

	public updateGeofences(
		map: google.maps.Map,
		options?: DrawingOptions,
		geofences?: any,
		geofenceOptions?: any
	) {
		if (!this._drawerMgr && geofences?.length && options?.drawOverlays) {
			if (geofences[0]?.shape) {
				removeShapes(geofences, map)
				setTimeout(() => {
					drawShapes(geofences, map);
				}, 10);
			} else {
				this.initilizeDrawingOnMap(map);
				this.initilizeGeofences(map, geofences, geofenceOptions);
			}
			
		}

		
		if(this._drawerMgr && geofences?.length){
			if (geofenceOptions.geofencesArrangeEnabled) {
				geofences.forEach((item:GeofenceShape, key: number) => {
					geofences[key]?.shape?.set('draggable', true)
				});
				
			} else {
				geofences.forEach((item:GeofenceShape, key: number) => {
					geofences[key]?.shape?.set('draggable', false)
				});
			}
		}
		
	}


	private initilizeGeofences(map: google.maps.Map, geofences: Geofence[], geofenceOptions: any, ) {
		
		geofenceOptions?.setMap(map)

		let getGeofencesShapes = drawShapes(geofences, map);

		

		const addClickListener = (geofenceShape: GeofenceShape) => {
			geofenceShape.shape.addListener(
				"click",
				(event: google.maps.MouseEvent) => {
					geofenceOptions?.openDrawer(geofenceShape);
					geofenceOptions?.setCurShape(geofenceShape);
				}
			);

			let infoWindowDetails = new google.maps.InfoWindow({
				content: `
					<h1 style="color: #349beb">${geofenceShape.fenceName ? geofenceShape.fenceName : `No Name`}</h1>
					${geofenceShape.description ? `<p><b>Description:</b> ${geofenceShape.description}</p>` : `` }
				`,
				pixelOffset: new google.maps.Size(0, -10),
			});

			//Adding Tooltip on Hover
			geofenceShape.shape.addListener(
				"mouseover",
				(event: google.maps.MouseEvent) => {
					infoWindowDetails.setPosition(event.latLng)
					infoWindowDetails.open(map);
				}
			);

			geofenceShape.shape.addListener(
				"mousemove",
				(event: any) => {
					infoWindowDetails.setPosition(event.latLng)
				}
			);

			geofenceShape.shape.addListener(
				"mouseout",
				(event: google.maps.MouseEvent) => {
					infoWindowDetails.close();
				}
			);

		};

		getGeofencesShapes.forEach((item, key) => {
			addClickListener(item);
			if (geofenceOptions.canGeofencingEdit) {
				addListenerToUpdateGeoCoords(item);
			} else {
				getGeofencesShapes[key].shape.set('draggable', false)
			}
			geofenceOptions?.addShape(item);
		});

		/* Handles Newly Created Functions */
		this._drawerMgr?.addListener(
			"polygoncomplete",
			(polygon: google.maps.Polygon) => {
				let shape: GeofenceShape = {
					shape: polygon,
					type: "polygon",
					fenceName: "",
					fenceColor: "",
					description: "",
					id: "",
					geoCoordinates: jsonPolygon(polygon),
					gatewayIds: [],
					mastertags: [],
					latestPositionStatuses: [],
				};

				addClickListener(shape);

				geofenceOptions?.addShape(shape);
			}
		);

		this._drawerMgr?.addListener(
			"circlecomplete",
			(circle: google.maps.Circle) => {
				let shape: GeofenceShape = {
					shape: circle,
					type: "circle",
					fenceName: "",
					fenceColor: "",
					description: "",
					id: "",
					geoCoordinates: jsonCircle(circle),
					gatewayIds: [],
					mastertags: [],
					latestPositionStatuses: [],
				};

				addClickListener(shape);

				geofenceOptions?.addShape(shape);
			}
		);

		this._drawerMgr?.addListener(
			"rectanglecomplete",
			(rect: google.maps.Rectangle) => {
				let shape: GeofenceShape = {
					shape: rect,
					type: "rectangle",
					fenceName: "",
					fenceColor: "",
					description: "",
					id: "",
					geoCoordinates: jsonRectangle(rect),
					gatewayIds: [],
					mastertags: [],
					latestPositionStatuses: [],
				};

				addClickListener(shape);

				geofenceOptions?.addShape(shape);
			}
		);
	}


	private initilizeDrawingOnMap(map: google.maps.Map) {
		this._drawerMgr = new google.maps.drawing.DrawingManager({
			//drawingMode: google.maps.drawing.OverlayType.POLYGON,
			drawingControlOptions: {
				drawingModes: [
					google.maps.drawing.OverlayType.CIRCLE,
					google.maps.drawing.OverlayType.RECTANGLE,
					google.maps.drawing.OverlayType.POLYGON,
				],
			},
			rectangleOptions: DEFAULT_POLYOPTIONS,
			circleOptions: DEFAULT_POLYOPTIONS,
			polygonOptions: DEFAULT_POLYOPTIONS,
			map: map,
		});
		this.setUpOverlayCompleteListner(this._drawerMgr);
	}

	private setUpOverlayClickListner(newShape: any) {
		google.maps.event.addListener(newShape, "click", (e) => {
			// const vertices  = newShape.getPath();
			// for (let i = 0; i < vertices.getLength(); i++) {
			//     const xy = vertices.getAt(i);
			// }
			// if (newShape.type === google.maps.drawing.OverlayType.POLYGON) {
			//     let path = newShape.getPaths().getAt(e.path);
			//     path.removeAt(e.vertex);
			//     if (path.length < 3) {
			//         newShape.setMap(null);
			//     }
			// }
			// if (newShape.type === google.maps.drawing.OverlayType.POLYLINE) {
			//     let path = newShape.getPath();
			//     path.removeAt(e.vertex);
			//     if (path.length < 2) {
			//         newShape.setMap(null);
			//     }
			// }
		});
	}

	private setUpOverlayCompleteListner(
		drawingMgr: google.maps.drawing.DrawingManager
	) {
		google.maps.event.addListener(drawingMgr, "overlaycomplete", (e) => {
			const newShape = e.overlay;
			newShape.type = e.type;
			if (e.type !== google.maps.drawing.OverlayType.MARKER) {
				drawingMgr.setDrawingMode(null);
				this.setUpOverlayClickListner(newShape);
			}
		});
	}
}
