import React, { Component } from "react";
import { Moment } from "moment";
import { Form, Row, Col, Collapse, Select, Button, Space, Tooltip } from 'antd';
import { CheckCircleTwoTone } from "@ant-design/icons";
import { FormInstance } from 'antd/lib/form';

import { connect } from "react-redux";
import * as actions from "./actions";

import Timespan from "./Timespan";
import "./alerts.css";

const { Option } = Select;
const { Panel } = Collapse;

interface Prop {
    alertsSignalsSetPagination: Function,
    alertsSignals: Function,
    alertsSignalsFilters: Function,    
    context: any,
    alertsState: any,    
    nickname?: string[],
    status: string[],
    isread: boolean,
    viewAlertType: string
}

interface State {
    nickname?: string[],
    status: string[],
    isread: boolean
}

class AssetsSignalsFilters extends Component<Prop, State> {
    
    state:State = {
        nickname: [],
        status: [],
        isread: false
    }

    formRef = React.createRef<FormInstance>();

    componentDidUpdate(prevProps: Prop) {
        if ((this.props.nickname || []).toString() !== (prevProps.nickname || []).toString()) {
            this.setState({ nickname: this.props.nickname, status: this.props.status, isread: this.props.isread });   
        }               
    }

    resetFilters = () => {    
        const me = this;

        this.setState({ nickname: [], status: [] });     
        setTimeout(function () {
            me.formRef.current?.resetFields();   
            me.applyFilters();   
        }, 150);  
    }

    applyFilters = () => {   
        let form =  this.formRef.current;
        const timespan:Moment[] = form?.getFieldValue("timespan");
        const timespanFrom = (timespan ? timespan[0].format('YYYY-MM-DD HH:mm:ss') : "");
        const timespanTo = (timespan ? timespan[1].format('YYYY-MM-DD HH:mm:ss') : "");

        this.props.alertsSignals(
            this, 
            null, 
            null, 
            1, 
            null,
            null,
            this.props.viewAlertType,
            timespanFrom,
            timespanTo,
            form?.getFieldValue("nickname") || "",
            form?.getFieldValue("status") || "",
            form?.getFieldValue("tags") || ""
        );      
        this.props.alertsSignalsFilters(
            this, 
            null, 
            null, 
            null, 
            null, 
            timespanFrom, 
            timespanTo
        );  
    };

    render() {    

        const { filters, appliedFilters } = this.props.alertsState.tabs[1];
        const filtersApplied = 
            (appliedFilters.nickname && appliedFilters.nickname.length || 0) || //eslint-disable-line 
            (appliedFilters.status && appliedFilters.status.length || 0) || //eslint-disable-line 
            (appliedFilters.tags && appliedFilters.tags.length || 0) || //eslint-disable-line 
            ((appliedFilters.timespanFrom || "") !== "") || //eslint-disable-line  
            ((appliedFilters.timespanTo || "") !== ""); //eslint-disable-line 
        const filtersHeader = (
            <span>Filters {filtersApplied ? <Tooltip title="Applied Filter"><CheckCircleTwoTone /></Tooltip> : ""}</span>
        );
        const filterLayout = {
            labelCol: { span: 3 },
            wrapperCol: { span: 21 }
        };
        

        return (
            <>
                {
                    filters !== null && this.props.isread &&
                    <div data-id="alerts-dm1-faults-filters"> 
                        <Collapse bordered={false} defaultActiveKey={['0']} className="auto-close">
                            <Panel header={filtersHeader} key="1">
                                <Form 
                                    {...filterLayout}
                                    ref={this.formRef}
                                    name="filter"
                                    initialValues={{ 
                                        nickname: this.state.nickname,
                                        status: this.state.status
                                    }} 
                                >
                                    <Row gutter={16}>
                                        <Col xs={{ span: 24 }} md={{ span: 20 }} xl={{ span: 16 }}>
                                            <Row gutter={16}>
                                                <Col span={23} offset={1}>
                                                    <Timespan />
                                                </Col>                
                                            </Row> 
                                            <Row gutter={16}>
                                                <Col span={23} offset={1}>
                                                    <Form.Item
                                                        name="nickname"
                                                        label="Asset"
                                                    >
                                                        <Select 
                                                            data-id="alerts-dm1-faults-filters-nickname"
                                                            mode="multiple"
                                                            showSearch
                                                            placeholder="Select..."
                                                            defaultActiveFirstOption={false}
                                                            showArrow={false}
                                                            filterOption={true}
                                                            notFoundContent={null}
                                                            allowClear
                                                        >
                                                            {filters !== null && filters.nicknameSorted.map((record:any, index:number) => (
                                                                <Option key={"nickname-" + index} value={record}>                                                
                                                                <div className="demo-option-label-item">
                                                                    <strong>{record}</strong>
                                                                </div>
                                                            </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>                
                                            </Row>    
                                            <Row gutter={16}>
                                                <Col span={23} offset={1}>
                                                    <Form.Item
                                                        name="status"
                                                        label="Status"
                                                    >
                                                        <Select 
                                                            data-id="alerts-dm1-faults-filters-status"
                                                            mode="multiple"
                                                            showSearch
                                                            placeholder="Select..."
                                                            defaultActiveFirstOption={false}
                                                            showArrow={false}
                                                            filterOption={true}
                                                            notFoundContent={null}
                                                            allowClear
                                                        >
                                                            {filters !== null && filters.statusSorted.map((record:any, index:number) => (
                                                                <Option key={"status-" + index} value={record}>                                                
                                                                    <div className="demo-option-label-item">
                                                                        <strong>{record}</strong>
                                                                    </div>
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>                
                                            </Row>    


                                            <Row gutter={16}>


                                                <Col span={23} offset={1}>
                                                    <Form.Item
                                                        name="tags"
                                                        label="Tags"
                                                    >

                                                        <Select
                                                            data-id="alerts-dm1-faults-filters-tags"
                                                            mode="multiple"
                                                            showSearch
                                                            placeholder="Select..."
                                                            defaultActiveFirstOption={false}
                                                            showArrow={false}
                                                            filterOption={true}
                                                            notFoundContent={null}
                                                            allowClear
                                                        >
                                                            {filters !== null && filters.tagsSorted?.map((record:any, index:number) => (
                                                                <Option key={"tag-" + index} value={record.toUpperCase()}>
                                                                    <div className="demo-option-label-item">
                                                                        <strong>{record.toUpperCase()}</strong>
                                                                    </div>
                                                                </Option>
                                                            ))}
                                                        </Select> 
                                                    </Form.Item>
                                                </Col>                
                                            </Row>    


                                            <Space size="middle">
                                                <Form.Item 
                                                    shouldUpdate={true}>                                            
                                                    {() => (
                                                        <Button
                                                            data-id="alerts-dm1-faults-filters-reset-button"
                                                            type="default"
                                                            htmlType="submit"
                                                            onClick={this.resetFilters}         
                                                        >
                                                            Reset
                                                        </Button>
                                                    )}
                                                </Form.Item>
                                                <Form.Item 
                                                    shouldUpdate={true}>                                            
                                                    {() => (
                                                        <Button
                                                            data-id="alerts-dm1-faults-filters-submit-button"
                                                            type="default"
                                                            htmlType="submit"
                                                            onClick={this.applyFilters}     
                                                            disabled={
                                                                !(
                                                                    (this.formRef.current?.getFieldValue("nickname") || []).length !== 0 ||
                                                                    (this.formRef.current?.getFieldValue("status") || []).length !== 0  ||
                                                                    (this.formRef.current?.getFieldValue("tags") || []).length !== 0  ||
                                                                    this.formRef.current?.getFieldValue("timespan")
                                                                )   
                                                            }                                  
                                                        >
                                                            Submit
                                                        </Button>
                                                    )}
                                                </Form.Item>
                                            </Space>
                                        </Col>
                                    </Row>
                                </Form>
                            </Panel>            
                        </Collapse>
                    </div>

                }
            </>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        alertsState: state.alertsReducer.alertsState
    };
};

export default connect(
    mapStateToProps,
    actions
)(AssetsSignalsFilters);
  