import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";
import { string } from "@amcharts/amcharts4/core";

export function loginGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    pathname: string
) {
    
    const currentUrl = window.location.href.toLowerCase();
    const { claytonServerURL, hoistServerURL, toyotaServerURL } = appConfigs.server;
    var orgUrl:any;
    if (currentUrl.includes(claytonServerURL.toLowerCase())) {
         orgUrl = "clayton";
     }
     else if (currentUrl.includes(toyotaServerURL.toLowerCase()))
     {
         orgUrl = "toyota";
     }
     else if (currentUrl.includes(hoistServerURL.toLowerCase()))
    {
         orgUrl = "hoist";
     }      
     else
     {
             var pathnameArr = pathname.split("/");
             orgUrl = (pathnameArr.length > 2 ? pathnameArr[pathnameArr.length - 1].trim() : "").toLowerCase();
     }   
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/public/ui/login", {
                responseType: "json",        
                headers: {},
                params: {
                    orgUrl: orgUrl
                }
            }
        )
        .then(response => {    
                if (callbackFnSuccess != null) {                   
                    callbackFnSuccess(response.data.data.login, orgUrl);
                }
                dispatch({ type: "LOGIN_GET_SUCCESS", data: response.data.data.login });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            //errorResponse(me, error);
        });
    };
}

export function forgotPassword(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    emailAddress: string
) {
    return async () => {        
        Axios.post(
            appConfigs.server.URL +  "/public/api/users/okta/forgot-password",
            {
                emailAddress: emailAddress
            },
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(emailAddress); 
                }
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
    }
}

