import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function orgSelectorTreeExpand(
    me: any, 
    expandedKeys: any
) {
    return (dispatch: any) => {
        dispatch({ type: "ORG_SELECTOR_TREE_EXPAND", expandedKeys: expandedKeys, isExpanded: true });
    };
}

export function orgSelectorTreeCheck(
    me: any, 
    checkedKeys: any
) {
    return (dispatch: any) => {
        dispatch({ type: "ORG_SELECTOR_TREE_CHECK", checkedKeys: checkedKeys });
    };
}

export function orgSelectorTreeSelection(
    me: any, 
    orgId: string
) {
    return (dispatch: any) => {
        dispatch({ type: "ORG_SELECTOR_TREE_SELECTION", orgId: orgId });
    };
}

export function orgSelectorTreeOrgsGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    mode: string = "all",
    searchText?: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/orgs/selector/tree", {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {    
                let data = response.data.data.orgs == null || (response.data.data.orgs.length || 0) === 0 ? [] : response.data.data.orgs;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data, mode);
                }
                dispatch({ type: "ORG_SELECTOR_TREE_GET_ORGS_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function orgSelectorTreeMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: string,
    selected: boolean,
    checkedKeys: any,
    includeSubOrgs?: boolean
) {
    return async (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL +  "/ui/api/users/orgs/selection?orgId=" + orgId + "&selected=" + selected + "&includeSubOrgs=" + (includeSubOrgs || false),
            {},
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.orgSelection, orgId, checkedKeys);
                }
                dispatch({ type: "ORG_SELECTOR_SET_ORG_SELECTION", data: response.data.data.orgSelection });
                dispatch({ type: "ORG_SELECTOR_TREE_SET_SELECTION", orgSelection: response.data.data.orgSelection });
                dispatch({ type: "ORG_SELECTOR_LIST_SET_SELECTION", orgId: orgId, selected: selected });
            }
        )
        .catch(function (error) {
            errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
    }
}