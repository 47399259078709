import { GeofenceShape } from "./../geofenceHelper";
import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";

export function openDrawer(geofenceShape: GeofenceShape) {
	return (dispatch: any) => {
		dispatch({
			type: "SET_DRAWER_OPEN",
			payload: { geofenceShape: geofenceShape },
		});
	};
}

export function setCurShape(geofenceShape: GeofenceShape) {
	return (dispatch: any) => {
		dispatch({
			type: "SET_CUR_SHAPE",
			payload: { geofenceShape: geofenceShape },
		});
	};
}

export function setMap(map: any) {
	return (dispatch: any) => {
		dispatch({ type: "SET_MAP", payload: { map: map } });
	};
}

export function setDrawingManager(drawingManager: any) {
	return (dispatch: any) => {
		dispatch({ type: "SET_DRAWING_MANAGER", payload: { drawingManager: drawingManager } });
	};
}

export function setMarkerManager(markerManager: any) {
	return (dispatch: any) => {
		dispatch({ type: "SET_MARKER_MANAGER", payload: { markerManager: markerManager } });
	};
}

export function getAllAssetsList() {
	return (dispatch: any) => {
		Axios.get(appConfigs.server.URL + "/ui/api/geofence-assets", {
			responseType: "json",
			headers: {},
			params: {
				page: 1,
				pageSize: 9999,
			},
		}).then((response) => {
			let assets = response.data.data.assets;
			dispatch({ type: "SET_ASSETSLIST", payload: { assetsList: assets } });
		});
	};
}

export function addShape(geofenceShape: GeofenceShape) {
	return (dispatch: any) => {
		dispatch({ type: "ADD_SHAPE", payload: { geofenceShape: geofenceShape } });
		dispatch({ type: "FILTER_FENCELIST_DRAWER", payload: { searchVal: "" } });
	};
}



export function clearGeoFencesState() {
	return (dispatch: any) => {
		dispatch({ type: "CLEAR_GEOFENCES_STATE" });
	};
}


