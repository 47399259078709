import React, { useState } from "react";
import moment, { Moment } from "moment";
import {
  Form,
  Card,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Select,
  Input,
} from "antd";
import { ExportFormProps } from "./ExportFormContainer";
import { WidgetExportConfig } from ".";
import { exportValidateForms } from "../widgets/common";
const { Option } = Select;

function sanitizeInputs(c: WidgetExportConfig): WidgetExportConfig {
  return {
    startDate: c.startDate,
    endDate: c.endDate,
    startTime: c.startTime,
    endTime: c.endTime,
  };
}

const ExportForm: React.FC<ExportFormProps> = (props) => {
  const initialValues: WidgetExportConfig = {
    startDate: props.config?.startDate
      ? props.config?.startDate
      : moment().subtract(1, "days"),
    startTime: props.config?.startTime
      ? props.config?.startTime
      : moment().subtract(1, "days").set({
          hour: 0,
          minute: 0,
          second: 0,
        }),
    endDate: props.config?.endDate ? props.config?.endDate : moment(),
    endTime: props.config?.endTime
      ? props.config?.endTime
      : moment().set({
          hour: 0,
          minute: 0,
          second: 0,
        }),
  };

  return (
    <div className="text-widget-edit-form">
      <Form
        onFinish={(values) =>
          props.onFinish(sanitizeInputs(values as WidgetExportConfig))
        }
        layout="vertical"
        form={props.form}
        initialValues={initialValues}
      >
        <Card title="Export Widget">
          <Row>
            <Col>
              <Form.Item label="Start Date">
                <Input.Group compact>
                  <Form.Item
                    noStyle
                    name="startDate"
                    dependencies={["startTime", "endDate", "endTime"]}
                    rules={[
                      { required: true, message: "Start Date required" },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          return exportValidateForms(
                            value,
                            getFieldValue("endDate"),
                            getFieldValue("startTime"),
                            getFieldValue("endTime")
                          );
                        },
                      }),
                    ]}
                  >
                    <DatePicker inputReadOnly />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name="startTime"
                    rules={[
                      {
                        required: true,
                        message: "Start Time required",
                      },
                    ]}
                  >
                    <TimePicker inputReadOnly />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="End Date">
                <Input.Group compact>
                  <Form.Item noStyle name="endDate">
                    <DatePicker inputReadOnly />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name="endTime"
                    rules={[
                      {
                        required: true,
                        message: "End Time required",
                      },
                    ]}
                  >
                    <TimePicker inputReadOnly />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default ExportForm;
