export function openFenceListDrawer(){
    return (dispatch: any) => {
        dispatch({ type: "OPEN_FENCELIST_DRAWER" });
        dispatch({ type: "FILTER_FENCELIST_DRAWER", payload: { searchVal: "" } });
    }
}

export function closeFenceListDrawer(){
    return (dispatch: any) => {
        dispatch({type: "CLOSE_FENCELIST_DRAWER"});
    }
}

export function changeOnSearch(searchVal:string){
    return (dispatch: any) => {
        dispatch({type:  "FILTER_FENCELIST_DRAWER", payload: {searchVal: searchVal}})
    }
}