import { SyncOutlined } from "@ant-design/icons";
import { Button, Card, Col, Divider, Drawer, Form, Input, message, Row, Select, Space, Table, Tabs, Tag, Tooltip, Typography } from "antd";
import { FormInstance } from 'antd/lib/form';
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formInit } from "../../utils/apiHelpers/formHelpers";
import { appConfigs } from "../../utils/configurations";
import * as actions from "./actions";
import './usercontrollers.css'

const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;

interface Prop {
    context: any,
    controllers: any,
    controllerMaintOptions: any,
    closeInstallationProgressDrawer: Function,
    updateOTAStatusFromDeviceTwin: Function,
    drawerTabChange: Function,
    getInstallationsHistory: Function,
}

const AppMaintInstallation: React.FC<Prop> = (props) => {

    const { tableConfig, installationHistory } = props.controllerMaintOptions;

    const onDrawerClose = () => {
        props.closeInstallationProgressDrawer();
    };

    const onTabChange = (key: string) => {
        props.drawerTabChange(key);
    }

    useEffect(() => {

        if (props.controllerMaintOptions.record != null) {
            
            props.getInstallationsHistory(props.controllerMaintOptions.record.controllerId, props.controllerMaintOptions.record.gatewayId, callbackFnFailure, callbackFnSuccess)
        }

    }, [props.controllerMaintOptions.record])

    const callbackFnFailure = (error: any) => {
        message.error("Failed to load data ")
    }

    const callbackFnSuccess = (messageInfo: string) => {
        // message.success(messageInfo)
    }


    const columns = [
        {
            title: "Process",
            dataIndex: "process",
            key: "process",
            render: (text: any, record: any) => (     
                <span>{record.process}</span>
            )
        },
        {
            title: "By",
            dataIndex: "doneBy",
            key: "doneBy",
            render: (text: any, record: any) => (     
                <span>{record.doneBy}</span>
            )
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text: any, record: any) => (     
                <span>{record.status}</span>
            )
        },
        {
            title: "Timestamp",
            dataIndex: "timestamp",
            key: "timestamp",
            render: (text: any, record: any) => (     
                <span>{record.timestamp}</span>
            )
        },
        {
            title: "Notes",
            dataIndex: "notes",
            key: "notes",
            render: (text: any, record: any) => (     
                <span>{record.notes}</span>
            )
        }
    ];

    const getDeviceTwinUpdates = (installationDetails: any) => {
        console.log(installationDetails);
        props.updateOTAStatusFromDeviceTwin(installationDetails, props.controllerMaintOptions.record, onOtaStatusUpdatedSuccess )
    }

    const onOtaStatusUpdatedSuccess = (message: any) => {
        // message.success(message)
        props.getInstallationsHistory(props.controllerMaintOptions.record.controllerId, props.controllerMaintOptions.record.gatewayId, callbackFnFailure, callbackFnSuccess)
    }
    
    console.log("props.controllerMaintOptions")
    console.log(props.controllerMaintOptions)

    return (
        <Drawer
            title={props.controllerMaintOptions.title}
            width={props.controllerMaintOptions.width}
            onClose={onDrawerClose}
            visible={props.controllerMaintOptions.info_visible}
            destroyOnClose={true}
            footer={
                <Space size="large" style={{ float: 'right' }}>
                    <Button type="default" onClick={onDrawerClose}>
                        {appConfigs.settings.drawers.closeButtonText}
                    </Button>
                </Space>
            }
        >
            {
                <Tabs activeKey={props.controllerMaintOptions.activeTab} size="large" type="card" onChange={onTabChange}>                    
                    <TabPane tab={props.controllerMaintOptions.tabs[0].title} key="1" disabled={props.controllerMaintOptions.tabs[0].disabled}>
                        <Row style={{ padding: "5px" }} gutter={4}>
                            { props.controllerMaintOptions.installationHistory?.map((installation:any) => 
                                <>
                                    <Row style={{padding: "8px", border: "1px dashed #a8a8a8"}} gutter={4}>
                                        <Col span={20}>
                                            <span style={{fontSize: "16px"}}><b>Application Name: </b> {installation.applicationName}</span>
                                        </Col>
                                        <Col span={4}>
                                            <Tooltip title="Fetch details from Device Twin">
                                                <Button style={{ float: "right" }} icon={<SyncOutlined />} type="ghost" onClick={() => getDeviceTwinUpdates(installation)} />
                                            </Tooltip>
                                        </Col>
                                        <Col span={12}>
                                            <span><b>Submitted By: </b>{ installation.createdBy }</span>
                                        </Col>
                                        <Col span={12}>
                                            <span><b>Version: </b>{ installation.applicationVersion }</span>
                                        </Col>
                                        <Col span={12}>
                                            <span><b>Submitted At: </b>{ installation?.createdAt != null ? moment(installation?.createdAt?.slice(0, -10), 'YYYY-MM-DD  HH:mm:ss').format("MM/DD/YYYY hh:mm:ss A") : "N/A" }</span>
                                        </Col>
                                        <Col span={12}>
                                            <span><b>Type: </b>{ installation.applicationType.charAt(0).toUpperCase() + installation.applicationType.slice(1) }</span>
                                        </Col>


                                        <table className="installation_progress" style={{ marginTop: "10px", width: '100%'}}>
                                            <thead>
                                                <th>Process</th>
                                                <th>By</th>
                                                <th>Status</th>
                                                <th>Timestamp</th>
                                                <th>Notes</th>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    {/* <Tooltip title="This is when the user sent the request to start the installation process"> */}
                                                        <td>OTA Scheduled:</td>
                                                    {/* </Tooltip> */}
                                                    <td>{ installation.createdBy }</td>
                                                    <td>
                                                        {
                                                            installation.createdStatus.toLowerCase() == 'success' ? <Tag style={{ marginTop: "2px" }} color="#87d068">Success</Tag> : 
                                                            installation.createdStatus.toLowerCase() == 'pending' ? <Tag style={{ marginTop: "2px" }} color="#ffc100">Pending</Tag> : 
                                                            installation.createdStatus.toLowerCase() == 'failed' ? <Tag style={{ marginTop: "2px" }} color="#f71010">Failed</Tag> :
                                                            <Tag style={{ marginTop: "2px" }} color="#CBCBCB">Unknown</Tag>
                                                        
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            installation?.createdAt != null ? moment(installation?.createdAt?.slice(0, -10), 'YYYY-MM-DD  HH:mm:ss').format("MM/DD/YYYY hh:mm:ss A") : "N/A"
                                                        }
                                                    </td>
                                                    
                                                    <Tooltip /* className={`${installation.createdNotes != null ? '' : 'none'}`} */ title={
                                                        installation?.createdNotes?.split("\n").map(function (noteLine: any) {
                                                            return (<span>{noteLine}</span>);
                                                        })
                                                    }>
                                                        <td className={`${installation.createdNotes != null ? 'process' : ''}`} >
                                                            {`${installation.createdNotes != null ? 'Notes' : ''}`}
                                                        </td>
                                                    </Tooltip>
                                                </tr>
                                                <tr>
                                                    {/* <Tooltip title="This indicates when the installation file is downloaded by the gateways and is ready for installation to the device but awaiting approval"> */}
                                                        <td>OTA file transferred to gateway:</td>
                                                    {/* </Tooltip> */}
                                                    <td>System</td>
                                                    <td>
                                                        {
                                                            installation.downloadedStatus.toLowerCase() == 'success' ? <Tag style={{ marginTop: "2px" }} color="#87d068">Success</Tag> : 
                                                            installation.downloadedStatus.toLowerCase() == 'pending' ? <Tag style={{ marginTop: "2px" }} color="#ffc100">Pending</Tag> : 
                                                            installation.downloadedStatus.toLowerCase() == 'failed' ? <Tag style={{ marginTop: "2px" }} color="#f71010">Failed</Tag> :
                                                            <Tag style={{ marginTop: "2px" }} color="#CBCBCB">Unknown</Tag>
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            installation?.downloadedAt != null ? moment(installation?.downloadedAt?.slice(0, -10), 'YYYY-MM-DD  HH:mm:ss').format("MM/DD/YYYY hh:mm:ss A") : "N/A"
                                                        }
                                                    </td>
                                                    <Tooltip /* className={`${installation.createdNotes != null ? '' : 'none'}`} */ title={
                                                        installation?.downloadedNotes?.split("\n").map(function (noteLine: any) {
                                                            return (<span>{noteLine}</span>);
                                                        })
                                                    }>
                                                        <td className={`${installation.downloadedNotes != null ? 'process' : ''}`} >
                                                            {`${installation.downloadedNotes != null ? 'Notes' : ''}`}
                                                        </td>
                                                    </Tooltip>
                                                </tr>
                                                {/* <tr>
                                                    <Tooltip title="This indicates the status of approval SMS delivery by the system">
                                                        <td className="process">Approval SMS Delivery:</td>
                                                    </Tooltip>
                                                    <td>System</td>
                                                    <td><Tag style={{marginTop: "2px"}} color="#87d068">Delivered</Tag> <Tag style={{marginTop: "2px"}} color="#ffc100">Pending</Tag><Tag style={{marginTop: "2px"}} color="#f71010">Failed</Tag></td>
                                                    <td>06/29/2022, 10:39:24 AM</td>
                                                    <td>N/A</td>
                                                </tr>
                                                <tr>
                                                    <Tooltip title="This indicates the status of SMS approval by the operator">
                                                        <td className="process">SMS Approval:</td>
                                                    </Tooltip>
                                                    <td>User Operator</td>
                                                    <td><Tag style={{marginTop: "2px"}} color="#87d068">Approved</Tag> <Tag style={{marginTop: "2px"}} color="#ffc100">Pending</Tag> <Tag style={{marginTop: "2px"}} color="#f71010">Not Approved</Tag></td>
                                                    <td>06/29/2022, 11:39:24 AM</td>
                                                    <td>N/A</td>
                                                </tr> */}
                                                <tr>
                                                    {/* <Tooltip title="This indicates the status of file installation after operator approval"> */}
                                                        <td>OTA Installed on controller:</td>
                                                    {/* </Tooltip> */}
                                                    <td>System</td>
                                                    <td>
                                                        {
                                                            installation.installedStatus.toLowerCase() == 'success' ? <Tag style={{ marginTop: "2px" }} color="#87d068">Success</Tag> : 
                                                            installation.installedStatus.toLowerCase() == 'pending' ? <Tag style={{ marginTop: "2px" }} color="#ffc100">Pending</Tag> : 
                                                            installation.installedStatus.toLowerCase() == 'failed' ? <Tag style={{ marginTop: "2px" }} color="#f71010">Failed</Tag> :
                                                            <Tag style={{ marginTop: "2px" }} color="#CBCBCB">Unknown</Tag>
                                                        
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            installation?.installedAt != null ? moment(installation?.installedAt?.slice(0, -10), 'YYYY-MM-DD  HH:mm:ss').format("MM/DD/YYYY hh:mm:ss A") : "N/A"
                                                        }
                                                    </td>
                                                    <Tooltip /* className={`${installation.createdNotes != null ? '' : 'none'}`} */ title={
                                                        installation?.installedNotes?.split("\n").map(function (noteLine: any) {
                                                            return (<span>{noteLine}</span>);
                                                        })
                                                    }>
                                                        <td className={`${installation.installedNotes != null ? 'process' : ''}`} >
                                                            {`${installation.installedNotes != '100' ? installation.installedNotes != null ? installation.installedNotes + '%' : "" : '100% Completed'}`}
                                                        </td>
                                                    </Tooltip>
                                                </tr>
                                            </tbody>
                                        </table>
                                            
                                    </Row>

                                    <Col span={24}>
                                        <Divider></Divider>
                                    </Col>
                                
                                </>
                            )}
                            
                        </Row>

                    </TabPane> 
                </Tabs>   
            }         
        </Drawer>
    );
}

const mapStateToProps = (state: any) => {
    return {
        context: state.contextReducer.context,
        controllers: state.userControllersReducers.userControllersState.controllers,
        controllerMaintOptions: state.userControllersReducers.userControllersState.controllers.controllerMaintOptions
    };
};

export default connect(
    mapStateToProps,
    actions
)(AppMaintInstallation);