import React from "react";
import { CommonWidgetConfig } from "../../models";
import { WidgetDetails } from "../models";
import Widget from "./Widget";
import EditForm from './EditForm';

export const details: WidgetDetails = {
  type: "map events",
  name: "GPS Events Widget",
};

export type Config = CommonWidgetConfig & {
  type: "map events";
};

export { Widget, EditForm };
