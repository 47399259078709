export const initialState = {
    context: {
        assetNotes: {
            title: "Asset Notes",
            visible: false,
            width: 600,
            notes: {
                pinned:[],
                all:[],
                globalNotes:[]
            }
        }
    }
};