import Axios from "axios";
import { appConfigs } from "../../utils/configurations";

const DEFAULT_POLYOPTIONS = {
	fillColor: "#f73e16",
	strokeWeight: 0.5,
	fillOpacity: 0.3,
	editable: true,
	draggable: true,
};

export type GeofenceShape = {
	shape: google.maps.MVCObject;
	type: string;
	fenceName: string;
	fenceColor: string;
	description: string;
	id: string;
	geoCoordinates: any;
	gatewayIds: string[];
	mastertags: string[];
	latestPositionStatuses: any[];
};

function getGeofenceGateways(shape:any){
	
	let mastertags:string[] = [];
	let gatewayIds:string[] = [];
	let latest_position_status_array:any = [];

	if (shape.gateways) {
		let geofenceGateways: any = JSON.parse(shape.gateways);

		for (let i = 0; i < geofenceGateways.length; i++) {
			mastertags.push(geofenceGateways[i].mastertag);
			gatewayIds.push(geofenceGateways[i].gatewayId);

			latest_position_status_array.push({
				'status': geofenceGateways[i].latest_position_status,
				'updated_at': geofenceGateways[i].updated_at
			});
		}
	}


	return  [gatewayIds, mastertags, latest_position_status_array];
}

export function removeShapes(
	geoFences: any,
	map: google.maps.Map
) {

	geoFences?.map((geofence: any) => {
		geofence.shape.setMap(null)
	})

	google.maps.event.clearListeners(map, 'bounds_changed');

}

export function drawShapes(
	geoFences: any,
	map: google.maps.Map
): GeofenceShape[] {
	var shapesArr: GeofenceShape[] = [];

	for (var i = 0; i < geoFences.length; i++) {
	
		let gateways = getGeofenceGateways(geoFences[i]);

		if (geoFences[i].type === "circle") {
			let circle = getCircle(geoFences[i], JSON.parse(geoFences[i].geoCoordinates), map);
			shapesArr.push({
				shape: circle,
				type: geoFences[i].type,
				fenceName: geoFences[i].fenceName,
				fenceColor: geoFences[i].fenceColor,
				description: geoFences[i].description,
				id: geoFences[i].id,
				geoCoordinates: geoFences[i].geoCoordinates,
				gatewayIds: gateways[0],
				mastertags: gateways[1],
				latestPositionStatuses: gateways[2]
			});
		} else if (geoFences[i].type === "rectangle") {
			let rect = getRectangle(geoFences[i], JSON.parse(geoFences[i].geoCoordinates), map);
			shapesArr.push({
				shape: rect,
				type: geoFences[i].type,
				fenceName: geoFences[i].fenceName,
				fenceColor: geoFences[i].fenceColor,
				description: geoFences[i].description,
				id: geoFences[i].id,
				geoCoordinates: geoFences[i].geoCoordinates,
				gatewayIds: gateways[0],
				mastertags: gateways[1],
				latestPositionStatuses: gateways[2]
			});
		} else if (geoFences[i].type === "polygon") {
			let polygon = getPolygon(geoFences[i], JSON.parse(geoFences[i].geoCoordinates), map);
			shapesArr.push({
				shape: polygon,
				type: geoFences[i].type,
				fenceName: geoFences[i].fenceName,
				fenceColor: geoFences[i].fenceColor,
				description: geoFences[i].description,
				id: geoFences[i].id,
				geoCoordinates: geoFences[i].geoCoordinates,
				gatewayIds: gateways[0],
				mastertags: gateways[1],
				latestPositionStatuses: gateways[2]
			});
		}
	}
	return shapesArr;
}

export function addListenerToUpdateGeoCoords(element: any) {
	if (element.type === "circle") {
		let shape = element.shape as google.maps.Circle;
		shape.addListener("radius_changed", () => {
			updateGeoCoords(element.id, jsonCircle(shape));
		});

		shape.addListener("dragstart", () => {
			google.maps.event.clearListeners(shape, "center_changed");
		});

		shape.addListener("dragend", () => {
			updateGeoCoords(element.id, jsonCircle(shape));
			shape.addListener("center_changed", () => {
				updateGeoCoords(element.id, jsonCircle(shape));
			});
		});

		shape.addListener("center_changed", () => {
			updateGeoCoords(element.id, jsonCircle(shape));
		});
	} else if (element.type === "rectangle") {
		let shape = element.shape as google.maps.Rectangle;

		shape.addListener("dragstart", () => {
			google.maps.event.clearListeners(shape, "bounds_changed");
		});

		shape.addListener("dragend", () => {
			updateGeoCoords(element.id, jsonRectangle(shape));
			shape.addListener("bounds_changed", () => {
				updateGeoCoords(element.id, jsonRectangle(shape));
			});
		});

		shape.addListener("bounds_changed", () => {
			updateGeoCoords(element.id, jsonRectangle(shape));
		});
	} else if (element.type === "polygon") {
		let shape = element.shape as google.maps.Polygon;

		shape.addListener("dragstart", () => {
			google.maps.event.clearInstanceListeners(shape.getPath());
		});

		shape.addListener("dragend", () => {
			updateGeoCoords(element.id, jsonPolygon(shape));
			shape.getPath().addListener("insert_at", () => {
				updateGeoCoords(element.id, jsonPolygon(shape));
			});

			shape.getPath().addListener("remove_at", () => {
				updateGeoCoords(element.id, jsonPolygon(shape));
			});

			shape.getPath().addListener("set_at", () => {
				updateGeoCoords(element.id, jsonPolygon(shape));
			});
		});

		shape.getPath().addListener("insert_at", () => {
			updateGeoCoords(element.id, jsonPolygon(shape));
		});

		shape.getPath().addListener("remove_at", () => {
			updateGeoCoords(element.id, jsonPolygon(shape));
		});

		shape.getPath().addListener("set_at", () => {
			updateGeoCoords(element.id, jsonPolygon(shape));
		});
	}
}

// export function addListeners(shapesArr: GeofenceShape[]) {
// 	for (let i = 0; i < shapesArr.length; i++) {
// 		addListenerToUpdateGeoCoords(shapesArr[i]);
// 	}
// }

// function getGeoFenceGateways(shape: GeofenceShape, callback: Function) {
// 	Axios.get(appConfigs.server.URL + "/ui/api/geofences/gateways", {
// 		responseType: "json",
// 		headers: {},
// 		params: {
// 			geofenceId: shape.id,
// 		},
// 	}).then((response) => {
// 		let geofenceGateways: any = response.data.data.geofencesGateways;

// 		if (geofenceGateways.length > 0) {
// 			for (let i = 0; i < geofenceGateways.length; i++) {
// 				shape.mastertags.push(geofenceGateways[i].mastertag);
// 				shape.gatewayIds.push(geofenceGateways[i].gatewayId);
// 			}
// 		}

// 		callback(shape);
// 	});
// }

// export function getGeoFenceGatewaysAll(
// 	shapesArr: GeofenceShape[],
// 	callback: Function
// ) {
// 	shapesArr.forEach((shape) => {
// 		getGeoFenceGateways(shape, callback);
// 	});
// }

function getMinMax(
	bounds: google.maps.LatLngBounds,
	minLat: number,
	minLng: number,
	maxLat: number,
	maxLng: number
) {
	if (!minLat || minLat > bounds.getSouthWest().lat()) {
		minLat = bounds.getSouthWest().lat();
	}

	if (!minLng || minLng > bounds.getSouthWest().lng()) {
		minLng = bounds.getSouthWest().lng();
	}

	if (!maxLat || maxLat < bounds.getNorthEast().lat()) {
		maxLat = bounds.getNorthEast().lat();
	}

	if (!maxLng || maxLng < bounds.getNorthEast().lng()) {
		maxLng = bounds.getNorthEast().lng();
	}

	return [minLat, minLng, maxLat, maxLng];
}

function getPolygonMinMax(
	polygon: google.maps.Polygon,
	minLat: number,
	minLng: number,
	maxLat: number,
	maxLng: number
) {
	var arr = polygon.getPath();

	for (let i = 0; i < arr.getLength(); i++) {
		let lat = arr.getAt(i).lat();
		let lng = arr.getAt(i).lng();

		minLat = minLat === undefined || minLat > lat ? lat : minLat;
		minLng = minLng === undefined || minLng > lng ? lng : minLng;
		maxLat = maxLat === undefined || maxLat < lat ? lat : maxLat;
		maxLng = maxLng === undefined || maxLng < lng ? lng : maxLng;
	}

	return [minLat, minLng, maxLat, maxLng];
}

export function getBoundForInitialZoom(
	shapesArr: GeofenceShape[]
): google.maps.LatLngBounds {
	let minLat: any, minLng: any, maxLat: any, maxLng: any;

	shapesArr.forEach((shape: GeofenceShape) => {
		if (shape.type === "rectangle") {
			let rect = shape.shape as google.maps.Rectangle;
			let minMax = getMinMax(rect.getBounds(), minLat, minLng, maxLat, maxLng);
			minLat = minMax[0];
			minLng = minMax[1];
			maxLat = minMax[2];
			maxLng = minMax[3];
		} else if (shape.type === "circle") {
			let circle = shape.shape as google.maps.Circle;
			let minMax = getMinMax(
				circle.getBounds(),
				minLat,
				minLng,
				maxLat,
				maxLng
			);
			minLat = minMax[0];
			minLng = minMax[1];
			maxLat = minMax[2];
			maxLng = minMax[3];
		} else {
			// polygon

			let polygon = shape.shape as google.maps.Polygon;
			let minMax = getPolygonMinMax(polygon, minLat, minLng, maxLat, maxLng);
			minLat = minMax[0];
			minLng = minMax[1];
			maxLat = minMax[2];
			maxLng = minMax[3];
		}
	});

	return new google.maps.LatLngBounds(
		{ lat: minLat, lng: minLng },
		{ lat: maxLat, lng: maxLng }
	);
}

export function updateGeoCoords(id: any, geoCoordinates: any) {
	Axios.put(
		appConfigs.server.URL + "/ui/api/geofences/changecoord",
		{
			id: id,
			geoCoordinates: geoCoordinates,
		},
		{
			responseType: "json",
			headers: {},
			params: {},
		}
	);
}

export function jsonCircle(circle: google.maps.Circle) {
	return JSON.stringify({
		center: {
			lat: circle.getCenter().lat(),
			lng: circle.getCenter().lng(),
		},
		radius: circle.getRadius(),
	});
}

export function jsonRectangle(rect: google.maps.Rectangle) {
	return JSON.stringify({
		north: rect.getBounds().getNorthEast().lat(),
		south: rect.getBounds().getSouthWest().lat(),
		east: rect.getBounds().getNorthEast().lng(),
		west: rect.getBounds().getSouthWest().lng(),
	});
}

export function jsonPolygon(polygon: google.maps.Polygon) {
	var arr = polygon.getPath();
	var points = [];

	for (let i = 0; i < arr.getLength(); i++) {
		points.push(arr.getAt(i));
	}

	return JSON.stringify({ points: points });
}

function getCircle(geoFencesData: any, circleData: any, map: google.maps.Map) {
	var lat = circleData.center.lat;
	var lng = circleData.center.lng;

	return new google.maps.Circle({
		...DEFAULT_POLYOPTIONS,
		fillColor: geoFencesData.fenceColor ? geoFencesData.fenceColor : "#f73e16",
		map: map,
		center: { lat: lat, lng: lng },
		radius: circleData.radius,
	});
}

function getRectangle(geoFencesData: any, rectData: any, map: google.maps.Map) {
	return new google.maps.Rectangle({
		...DEFAULT_POLYOPTIONS,
		fillColor: geoFencesData.fenceColor ? geoFencesData.fenceColor : "#f73e16",
		map: map,
		bounds: {
			north: rectData.north,
			south: rectData.south,
			east: rectData.east,
			west: rectData.west,
		},
	});
}

function getPolygon(geoFencesData: any, polygonData: any, map: google.maps.Map) {
	return new google.maps.Polygon({
		...DEFAULT_POLYOPTIONS,
		fillColor: geoFencesData.fenceColor ? geoFencesData.fenceColor : "#f73e16",
		map: map,
		paths: polygonData.points,
	});
}


