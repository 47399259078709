import React from "react";
import { Switch, Row, Col, Tooltip } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Filters, FilterCounts } from "../../models";
import { connect } from "react-redux";
import * as actions from "../../actions";

export interface Props {
    fleetOverviewState: any;
    filtersToggle: Function;
    filtersReset: Function;
    filters: Filters;
    filterCounts: FilterCounts;
    filterPreferences: Function;
    mapActions: any;
}

interface State {
    isMapTableFiltersSet: boolean
}

const FilterSwitch: React.FC<{
    active: boolean;
    toggle: () => void;
    label: string;
    color: string;
    count: number;
}> = (props) => {
    const style = (props.active && (props.count > 0)) ? { backgroundColor: props.color } : {};      
    let active = (props.active && (props.count > 0)) ? true : false;
    return (
        <Row align="middle" justify="space-between">
            <Col span={8}>
                <Switch
                    style={style}
                    checked={active}
                    onChange={props.toggle}
                    size="small"
                    disabled={(props.count || 0) === 0}
                />
            </Col>
            <Col span={12}>
                {props.label}
            </Col>
            <Col span={4}>
                {`[${props.count < 100 ? props.count : "99+"}]`}
            </Col>
        </Row>
    );
};

const FilterPanel = (props: Props) => {

    const filtersToggle = (filterName: string) => {
        let filter: any = props.filters;
        let value = filter[filterName] ? 'false' : 'true';
        props.filterPreferences('map', filterName, value,false, callbackFnSuccess);
       
    };
    const callbackFnSuccess = (filterData: any) => {
        props.filtersToggle(filterData.map, props.mapActions);
    }
    const filtersReset = (e: any) => {
        e.preventDefault();
        props.filterPreferences('map', 'null', 'null',true, callbackFnSuccess);
        props.filtersReset(props.mapActions);
    };

    return (
        <div data-id="map-filter-panel">
            <span>Filters</span>
            {
                 ((props.filters.critical && props.filterCounts.critical > 0) || (props.filters.warning && props.filterCounts.warning > 0) || (props.filters.info && props.filterCounts.info > 0) || (props.filters.dm1 && props.filterCounts.dm1 > 0)) &&
                <Tooltip title="Reset All Filters">
                    <a href="# " onClick={(e) => { filtersReset(e)}} className="pl-5">
                        <CloseCircleOutlined />
                    </a>
                </Tooltip>
            }
            <div className="mt-10">
                <FilterSwitch
                    active={props.filters.critical}
                    toggle={() => filtersToggle("critical")}
                    label="Critical"
                    color="#d11515"
                    count={props.filterCounts.critical}
                />
                <FilterSwitch
                    active={props.filters.warning}
                    toggle={() => filtersToggle("warning")}
                    label="Warning"
                    color="#ecc800"
                    count={props.filterCounts.warning}
                />
                <FilterSwitch
                    active={props.filters.info}
                    toggle={() => filtersToggle("info")}
                    label="Info"
                    color="#1e90ff"
                    count={props.filterCounts.info}
                />
                <FilterSwitch
                    active={props.filters.dm1}
                    toggle={() => filtersToggle("dm1")}
                    label="DM1s"
                    color="#ff9900"
                    count={props.filterCounts.dm1}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state:any) => {
    return {
        fleetOverviewState: state.fleetOverviewReducers.fleetOverviewState
    };
};

export default connect(
    mapStateToProps,
    actions
)(FilterPanel);
