import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function getQuotasApplied(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/templates/"+ templateId + "/quotas-applied", {
                responseType: "json",        
                headers: {}
            }
        )
        .then(response => {          
                let data = response.data.data.templates == null || (response.data.data.templates.length || 0) === 0 ? [] : response.data.data.templates;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "QUOTAS_APPLIED_GET_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}