import { appConfigs } from "../../../utils/configurations";

export const initialState = {
    assetsList: {
        assetMaint: {
            instance: 0,
            width: 720,
            visible: false,
            record: {},
            title: "",
            activeTab: "1",
            iqanConnectKey: {
                status: "",
                value: ""
            },
            wifipassword:"",
            tutorials: {
                tutorialsList: [],        
            },
            tabs: [
                {
                    title: "Asset Info",
                    instance: 0,
                    disabled: false,
                    assetsEligible: [],
                    orgTags: [],
                    orgs: [],
                    templates: []
                },
                {
                    title: "Subscriptions",
                    instance: 0,
                    disabled: true,
                    searchText: "",
                    tableData: [],
                    tableConfig : { 
                        pagination : {                  
                            showSizeChanger: appConfigs.tables.showSizeChanger,
                            showQuickJumper: appConfigs.tables.showQuickJumper,
                            pageSize: appConfigs.tables.pageSize,
                            defaultCurrent: appConfigs.tables.defaultCurrent,
                            current: appConfigs.tables.defaultCurrent,
                            total: appConfigs.tables.defaultTotal,
                            hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                            responsive: appConfigs.tables.responsive,
                            onChange: null,
                            showLessItems: appConfigs.tables.showLessItems,  
                            showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} user${total > 1 ? 's' : ''}`
                        }
                    }
                },
                {
                    title: "Gateway Settings",
                    instance: 0,
                    disabled: false,
                    enableWiFi: false,
                    enableHotspot: false,
                    enableEthernet: false,
                    ssidList: [],
                    connected_SSID: "",
                    ethernetIPAddress: appConfigs.app.defaultIP,
                    allowViewSleepMode: false, 
                    allowChangeSleepMode: false,
                    enableSleepMode: false,
                    sleepCycleInSeconds: "",
                    sleepCycleApplied: false,
                    nextWakeUpTime: ""
                },
                {
                    title: "Devices Discovery",
                    instance: 0,
                    disabled: false,
                    attachedControllersTableData: [
                        // {
                        //     "address": "0x5F",
                        //     "serialNumber": "52DER28465YD",
                        //     "controllerId": "369c3d80-2f33-11ed-91bd-3f471cd8a84f",
                        //     "type": "Controller",
                        //     "controllerName": "PHC"
                        // },
                        // {
                        //     "address": "0xE2",
                        //     "serialNumber": "SKS8367IKD25",
                        //     "controllerId": "0c6e9080-2f38-11ed-91bd-0bd9a3da5091",
                        //     "type": "Controller",
                        //     "controllerName": "PH Controller - Test"
                        // }
                    ],
                    controllersList: [
                        {
                            "controllerId": "369c3d80-2f33-11ed-91bd-3f471cd8a84f",
                            "controllerName": "PHC"
                        },
                        {
                            "controllerId": "0c6e9080-2f38-11ed-91bd-0bd9a3da5091",
                            "controllerName": "PH Controller - Test"
                        }
                    ],
                    tableConfig : { 
                        pagination : {                  
                            showSizeChanger: appConfigs.tables.showSizeChanger,
                            showQuickJumper: appConfigs.tables.showQuickJumper,
                            pageSize: appConfigs.tables.pageSize,
                            defaultCurrent: appConfigs.tables.defaultCurrent,
                            current: appConfigs.tables.defaultCurrent,
                            total: appConfigs.tables.defaultTotal,
                            hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                            responsive: appConfigs.tables.responsive,
                            onChange: null,
                            showLessItems: appConfigs.tables.showLessItems,  
                            showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} controllers${total > 1 ? 's' : ''}`
                        }
                    }
                },
                {
                    title: "Service Plan Details",
                    disabled: false,
                    billingNRenewalHistory: []
                },
            ]
        },
        status: "active",
        searchText: "",
        alertInterval: "1 day",
        gatewayId: "",
        refreshOnUpdate: false,
        filterTags: [],
        tableData: [],
        tableConfig : { 
            pagination : {                  
                showSizeChanger: appConfigs.tables.showSizeChanger,
                showQuickJumper: appConfigs.tables.showQuickJumper,
                pageSize: appConfigs.tables.pageSize,
                defaultCurrent: appConfigs.tables.defaultCurrent,
                current: appConfigs.tables.defaultCurrent,
                total: appConfigs.tables.defaultTotal,
                hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                responsive: appConfigs.tables.responsive,
                //onChange: null,
                showLessItems: appConfigs.tables.showLessItems,  
                showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} asset${total > 1 ? 's' : ''}`
            }
        },
        liteAssets:
        {   
            title:"",        
            instance: 0,
            disabled: false,
            searchText: "",
            refreshOnUpdate: false,
            tableData: [],
            tableConfig : {                
                pagination : {                  
                    showSizeChanger: appConfigs.tables.showSizeChanger,
                    showQuickJumper: appConfigs.tables.showQuickJumper,
                    pageSize: appConfigs.tables.pageSize,
                    defaultCurrent: appConfigs.tables.defaultCurrent,
                    current: appConfigs.tables.defaultCurrent,
                    total: appConfigs.tables.defaultTotal,
                    hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                    responsive: appConfigs.tables.responsive,
                    onChange: null,
                    showLessItems: appConfigs.tables.showLessItems,  
                    showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} collection${total > 1 ? 's' : ''}`
                }
            }
        }
    }
};
  