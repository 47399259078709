import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function oprState(state = initialState, action:any) {
    let data:any = null,
        payload:any;
        switch (action.type) {  
            case "OPRS_GET_SUCCESS":
                data = action.payload.data;
                return immerProduce(state, draft => {
                    draft.oprList.refreshOnUpdate = false;
                    draft.oprList.tableData = data;
                    draft.oprList.tableConfig.pagination.total = (data.length || 0) > 0 ? data[0].totalRecords : 0;
                    draft.oprList.tableConfig.pagination.current = action.payload.page;
                });
            case "OPRS_LIST_CLEAR":
                data = action.payload.data;
                return immerProduce(state, draft => {
                    draft.oprList.refreshOnUpdate = false;
                    draft.oprList.tableData = data;
                    draft.oprList.tableConfig.pagination.total = (data.length || 0) > 0 ? data[0].totalRecords : 0;
                    draft.oprList.tableConfig.pagination.current = action.payload.page;
                });
            case "OPRS_OPR_MAINT_OPEN":
                return immerProduce(state, draft => {
                    const 
                        payload = action.payload,
                        instance = Math.random();
                    draft.oprList.oprMaint.instance = instance;
                    draft.oprList.oprMaint.width = payload.width;
                    draft.oprList.oprMaint.visible = true;
                    draft.oprList.oprMaint.title = payload.title;
                    draft.oprList.oprMaint.record = payload.record;
    
                    draft.oprList.oprMaint.activeTab = "1";
                    draft.oprList.oprMaint.tabs[0].disabled = true;
                    draft.oprList.oprMaint.tabs[0].instance = instance;
                });   
            case "OPRS_OPR_MAINT_CLOSE":
                return immerProduce(state, draft => {
                    draft.oprList.oprMaint.visible = false;
                });
            case "OPR_HOME_ORGS_SUCCESS":
                    data = action.payload.data;
                    return immerProduce(state, draft => {
                        draft.oprList.oprMaint.operatorOrgs = data;
                    });    
            case "OPR_MAINT_ORG_USERS_GET_SUCCESS":
                    data = action.payload.data;
                    return immerProduce(state, draft => {
                        draft.oprList.oprMaint.tabs[0].orgUsers = data;
                    });  
            case "OPR_MAINT_ORG_ASSETS_GET_SUCCESS":
                data = action.payload.data;
                return immerProduce(state, draft => {
                    draft.oprList.oprMaint.tabs[0].orgAssets = data;
                });
            case "OPR_MAINT_ORG_ACCESS_TYPES_GET_SUCCESS":
                    data = action.payload.data;
                    return immerProduce(state, draft => {
                        draft.oprList.oprMaint.tabs[0].orgAccessTypes = data;
                    });
            case "OPR_RFID_SET_FIELD_VALUE":
                data = action.payload.data;
                return immerProduce(state, draft => {
                    draft.oprList.oprMaint.tabs[0].rfidValue = data;
                });
            case "OPR_SET_SEARCH":
                    return immerProduce(state, draft => {
                    draft.oprList.searchText = action.searchText;
            });
            case "OPRS_SET_PAGINATION":
                    return immerProduce(state, draft => {
                    draft.oprList.tableConfig.pagination.onChange = action.onPaginationChange;
            });
            case "OPR_MAINT_SUCCESS":
                data = action.payload.data;
                return immerProduce(state, draft => {
                    draft.oprList.oprMaint.record = data;
                    draft.oprList.oprMaint.title = "Edit Operator :: " + data.operatorIdValue;
                });  
            case "OPRS_LIST_REFRESH_ON_UPDATE":
                return immerProduce(state, draft => {
                    draft.oprList.refreshOnUpdate = true;
                });  
                case "OPR_MAINT_TABS_ENABLE":
                    return immerProduce(state, draft => {
                        if (draft.oprList.oprMaint.tabs[1].disabled) {
                            draft.oprList.oprMaint.tabs[1].disabled = false;
                        }
                        if (draft.oprList.oprMaint.tabs[2].disabled) {
                            draft.oprList.oprMaint.tabs[2].disabled = false;
                        }
                    });                         
            default:
                return state;
        }
}