import React from "react";
import { Table, Checkbox, Space, Tooltip, Input } from 'antd';
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import * as actions from "../actions";
import history from '../../../utils/history';
import { stringToUrlString } from "../../../utils/commonHelpers";

import "../assets.css";

const { Search } = Input;

interface Prop {
    assetSubscriptionsSetPagination: Function,
    assetSubscriptionsGet: Function,
    assetSubscriptionsMaint: Function,
    assetsListRefreshOnUpdate: Function,
    assetSubscriptionsSetSearch: Function,
    assetMaintClose: Function,    
    assetsList: any
}

interface State {
    partNumber: string;
    minCollectionFreq: number;
}

class AssetMaintSubscriptions extends React.Component<Prop, State> {  

    state: State = {
        partNumber: "",
        minCollectionFreq: -1,
    }
        
    componentDidMount() {
        this.props.assetSubscriptionsSetPagination(this.onPaginationChange);
        this.componentUpdate(1, "");
    }

    componentDidUpdate(prevProps: Prop) {
        if (this.props.assetsList.assetMaint.tabs[1].instance !== prevProps.assetsList.assetMaint.tabs[1].instance) {
            this.componentUpdate(1, "");
        }
    }

    componentUpdate(page: number, searchText: string) {
        if ((this.props.assetsList.assetMaint.record.gatewayId || "") !== "") {
            this.props.assetSubscriptionsGet(this, this.assetSubscriptionsGetSuccess, null, this.props.assetsList.assetMaint.record.gatewayId, searchText, page);
        }
    }

    onPaginationChange = (page: number) => {
        this.props.assetSubscriptionsGet(this, null, null, this.props.assetsList.assetMaint.record.gatewayId, this.props.assetsList.assetMaint.tabs[1].searchText, page);
    }
    
    onCheckChange = (e:any, record:any, notification: string, scope: string) => {
        
        let action = e.target.checked ? "plus" : "minus";

        this.props.assetSubscriptionsMaint(this, this.onCheckChangeSuccess, this.onCheckChangeFailure, this.props.assetsList.assetMaint.record.gatewayId, record, notification, action, scope);
    }

    assetSubscriptionsGetSuccess = (data: any) => {
        //possibly expand only row, where data.length === 1
        if ((data.length || 0) === 1) {
            setTimeout(function() {
                let targetButton:any = document.querySelectorAll('[data-id=assets-maint-subscriptions-data] tr[data-row-key="user.' + data[0].userId + '.*"] .ant-table-row-expand-icon-collapsed');
                if (targetButton.length) {
                    for (var i = 0; i < targetButton.length; i++) { 
                        targetButton[i].click();  
                    }
                }
            }, 250);      
        }

        const record=this.props.assetsList.assetMaint;
        if(record){
            this.setState({partNumber:record.partNumber,minCollectionFreq:record.minCollectionFreq});
        }
    }

    onCheckChangeSuccess = (userId:string, record:any, notification: string, action: string, scope:string) => {   
        if (scope === "user") {
            this.closeExpandedRows();
        }
        this.props.assetsListRefreshOnUpdate(this);
    };

    onCheckChangeFailure = (error:string, userId:string, record:any, notification: string, action: string, scope:string) => {   
    }        
    
    closeExpandedRows(rowKey?:string) {             
        let active:any;
        
        if((rowKey || "") !== "") {
            active = document.querySelectorAll('[data-id=assets-maint-subscriptions-data] tr[data-row-key="' + rowKey + '"] .ant-table-row-expand-icon-expanded');
        }
        else {
            active = document.querySelectorAll('[data-id=assets-maint-subscriptions-data] .ant-table-row-expand-icon-expanded');
        }
        if (active.length) {
            for (var i = 0; i < active.length; i++) { 
                active[i].click();  
                if ((rowKey || "") !== "") {
                    //if expanded click it twice to refresh updated state data
                    active[i].click();  
                }
            }
        }
    }
    
    onSearch = (searchText: string) => {   
        let me = this;
        setTimeout(function(){
            me.props.assetSubscriptionsGet(me, null, null, me.props.assetsList.assetMaint.record.gatewayId, me.props.assetsList.assetMaint.tabs[1].searchText, 1);
        }, 100);      
    }

    onSearchChange = (e: any) => {
        let value = e.target.value;
        this.props.assetSubscriptionsSetSearch((value || ""));
    }

    goToTemplate = (e: any, templateName: string, templateId: number) => {
        e.preventDefault();
        this.props.assetMaintClose(this);
        setTimeout(function(){
            history.push("/template/details/" + stringToUrlString(templateName) + "/" + templateId);
        }, 100);      
    };    

    isLiteAsset=()=>{
        return (this.state.partNumber || "") !== "" && this.state.minCollectionFreq > 0;
    }

    returnAlertSmsBySeverity=(record:any)=>{
        switch(record.severity){
           case "critical":
           case "warning":
           case "info":
               return record.alertSms;
           case "incremental":
             return record.incAlertSms;
           case "offline":
            return record.offlineAlertSms;
           default:
             return record.alertSms;
        }
     }
     returnAlertEmailBySeverity=(record:any)=>{
         switch(record.severity){
            case "critical":
            case "warning":
            case "info":
                return record.alertEmail;
            case "incremental":
              return record.incAlertEmail;
            case "offline":
             return record.offlineAlertEmail;
            default:
              return record.alertEmail;
         }
      }

      returnSeverity=(record:any)=>{
        switch(record.severity)
        {
            case "incremental":
            case "offline":
                return record.severity;
            default:
                return "alert";
        }        
      }

    actions = (record: any) => {

        const noOfSignals = this.props.assetsList.assetMaint.record.noOfSignals
       
        return <>
            {
                record.children &&
                    <Space size="large">
                        {/* Rollback OPS-4113: Disable SMS scubcription for lite assets */}
                        {/*<Tooltip title="Set/Unset SMS for all asset alerts">                             
                        <Checkbox disabled={noOfSignals > 0 ||   this.isLiteAsset() } data-id="assets-maint-subscriptions-sms-all" key={record.key} onChange={(e) => this.onCheckChange(e, record, "sms", "user")} indeterminate={record.userSmsIndeterminate} checked={(noOfSignals > 0 || this.isLiteAsset()?false:record.userSmsChecked)}><PhoneOutlined /></Checkbox> 
                        </Tooltip>*/}
                        <Tooltip title="Set/Unset SMS for all asset alerts">
                            <Checkbox data-id="assets-maint-subscriptions-sms-all" key={record.key} onChange={(e) => this.onCheckChange(e, record, "sms", "user")} indeterminate={record.userSmsIndeterminate} checked={record.userSmsChecked}><PhoneOutlined /></Checkbox>
                        </Tooltip>
                        <Tooltip title="Set/Unset email for all asset alerts">
                            <Checkbox data-id="assets-maint-subscriptions-email-all" key={record.key} onChange={(e) => this.onCheckChange(e, record, "email", "user")} indeterminate={record.userEmailIndeterminate} checked={record.userEmailChecked}><MailOutlined /></Checkbox>
                        </Tooltip>
                    </Space>
            }
            {
                !record.children && !record.name.includes('(Geofence)') &&
                <Space size="large">
                    {/* <Checkbox disabled={noOfSignals > 0 || this.isLiteAsset()} data-id="assets-maint-subscriptions-sms" key={record.key} onChange={(e) => this.onCheckChange(e, record, "sms", record.severity!=="incremental"?"alert":record.severity)} checked={(noOfSignals > 0 || this.isLiteAsset()?false:(record.severity!=="incremental"?record.alertSms:record.incAlertSms))}><PhoneOutlined /></Checkbox> */}
                    <Checkbox data-id="assets-maint-subscriptions-sms" key={record.key} onChange={(e) => this.onCheckChange(e, record, "sms", this.returnSeverity(record))} checked={this.returnAlertSmsBySeverity(record)}><PhoneOutlined /></Checkbox>
                    <Checkbox data-id="assets-maint-subscriptions-email" key={record.key} onChange={(e) => this.onCheckChange(e, record, "email", this.returnSeverity(record))} checked={this.returnAlertEmailBySeverity(record)}><MailOutlined /></Checkbox>
                </Space>
            }
            {
                !record.children && record.name.includes('(Geofence)') &&

                    <div>
                        <Space size="large" align="center">
                            <p style={{ marginTop: 15 }}>In</p>
                            {/* <Checkbox disabled={noOfSignals > 0 || this.isLiteAsset()} data-id="assets-maint-subscriptions-sms" key={record.key} onChange={(e) => this.onCheckChange(e, record, "smsIn", "geofence")} checked={(noOfSignals > 0 || this.isLiteAsset()? false:record.alertSmsIn)}><PhoneOutlined /></Checkbox> */}
                            <Checkbox data-id="assets-maint-subscriptions-sms" key={record.key} onChange={(e) => this.onCheckChange(e, record, "smsIn", "geofence")} checked={record.alertSmsIn}><PhoneOutlined /></Checkbox>
                            <Checkbox data-id="assets-maint-subscriptions-email" key={record.key} onChange={(e) => this.onCheckChange(e, record, "emailIn", "geofence")} checked={record.alertEmailIn}><MailOutlined /></Checkbox>
                        </Space>
                        
                        <Space size="large" align="center" style={{ marginRight: 10, marginTop: -30 }}>
                            <p style={{ marginTop: 15 }}>Out</p>
                            {/* <Checkbox disabled={noOfSignals > 0 || this.isLiteAsset()} data-id="assets-maint-subscriptions-sms" key={record.key} onChange={(e) => this.onCheckChange(e, record, "smsOut", "geofence")} checked={(noOfSignals > 0 || this.isLiteAsset()?false:record.alertSmsOut)}><PhoneOutlined /></Checkbox> */}
                            <Checkbox data-id="assets-maint-subscriptions-sms" key={record.key} onChange={(e) => this.onCheckChange(e, record, "smsOut", "geofence")} checked={record.alertSmsOut}><PhoneOutlined /></Checkbox>
                            <Checkbox data-id="assets-maint-subscriptions-email" key={record.key} onChange={(e) => this.onCheckChange(e, record, "emailOut", "geofence")} checked={record.alertEmailOut}><MailOutlined /></Checkbox>
                        </Space>
                    </div>
            }
        </>
    };

    render() {

        const { tableConfig, tableData, searchText } = this.props.assetsList.assetMaint.tabs[1];

        const columns = [
            {
                title: "User / Alarm Name",
                dataIndex: "name",
                key: "name",
                render: (text: any, record: any) => (     
                    <>               
                        {record.children &&
                            <strong>{record.name}</strong>
                        }
                        {!record.children && record.signalId === "" &&
                            <i>{record.name}</i>
                        }
                        {!record.children && record.signalId !== "" &&
                            <span>{record.name}</span>
                        }
                    </>
                )
            },
            {
                title: "Subscribed",
                dataIndex: "subscribed",
                width: "30%",
                key: "subscribed",
                align: "center" as "center",
                render: (text: any, record: any) => (                    
                    this.actions(record)
                )
            },
          ];
          
        return (     
            
            <div data-id="assets-maint-subscriptions">
                {
                    (
                        ((this.props.assetsList.assetMaint.tabs[1].searchText || "") === "" && tableData.length > 0) ||
                        ((this.props.assetsList.assetMaint.tabs[1].searchText || "") !== "")
                    ) &&
                    <Search
                        data-id="assets-maint-subscriptions-search-field"
                        placeholder="Search by user or alarm name"
                        enterButton="Search"
                        size="middle"
                        allowClear
                        onSearch={this.onSearch}
                        onChange={this.onSearchChange}
                        style={{marginBottom: 20, width: '100%'}}
                        value={searchText}
                    /> 
                }

                <Table 
                    data-id="assets-maint-subscriptions-data" 
                    rowKey={(record) => record.key}
                    {...tableConfig}
                    columns={columns} 
                    dataSource={tableData} 
                />
            </div>
        )
    }
}

const mapStateToProps = (state:any) => {
    return {
        assetsList: state.assetsReducer.assetsState.assetsList
    };
};

export default connect(
    mapStateToProps,
    actions
)(AssetMaintSubscriptions);