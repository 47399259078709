import {
  XYChart,
  CategoryAxis,
  ValueAxis,
  StepLineSeries,
  XYCursor,
} from "@amcharts/amcharts4/charts";
import * as amCore from "@amcharts/amcharts4/core";

import { AxisRange } from "../models";
import { NUMBER_FORMAT, DATE_FORMAT } from "../common";

const DISPLAY_COLORS = {
  critical: "#d11515",
  warning: "#ecc800",
  normal: "#ccc",
  info: "#1890ff"
};

export interface Signal {
  name: string;
  values: Array<{
    timestamp: Date;
    value: number;
  }>;
}
interface Rule {
  criticalHigh?: number;
  warningHigh?: number;
  infoHigh?: number;
  infoLow?: number;
  warningLow?: number;
  criticalLow?: number;
}

export interface Options {
  axisRange?: AxisRange;
}

export interface Props {
  signal: Signal;
  rule?: Rule;
  opts?: Options;
}

function createRange(axis: ValueAxis, from: number, to: number, color: string) {
  const range = axis.axisRanges.create();
  range.value = from;
  range.endValue = to;
  range.axisFill.fill = amCore.color(color);
  range.axisFill.fillOpacity = 0.8;
  range.grid.disabled = true;
  range.label.disabled = true;
}

function createRanges(axis: ValueAxis, rule?: Rule) {
  const maxValue = 100000;
  const minValue = -100000;

  if (!rule) {
    createRange(axis, minValue, maxValue, DISPLAY_COLORS.normal);
    return;
  }

  let { criticalHigh, warningHigh, criticalLow, warningLow, infoHigh, infoLow } = rule;

  if (criticalHigh) {
    createRange(axis, criticalHigh, maxValue, DISPLAY_COLORS.critical);
  } else {
    criticalHigh = maxValue;
  }

  if (warningHigh) {
    createRange(axis, warningHigh, criticalHigh, DISPLAY_COLORS.warning);
  } else {
    warningHigh = criticalHigh;
  }

  if(infoHigh) {
    createRange(axis, infoHigh, warningHigh, DISPLAY_COLORS.info);
  } else {
    infoHigh = warningHigh;
  }

  if (criticalLow) {
    createRange(axis, minValue, criticalLow, DISPLAY_COLORS.critical);
  } else {
    criticalLow = minValue;
  }

  if (warningLow) {
    createRange(axis, criticalLow, warningLow, DISPLAY_COLORS.warning);
  } else {
    warningLow = criticalLow;
  }

  if(infoLow) {
    createRange(axis, warningLow, infoLow, DISPLAY_COLORS.info);
  } else {
    infoLow = warningLow;
  }

  createRange(axis, infoLow, infoHigh, DISPLAY_COLORS.normal);
}

class Bullet {
  private _bullet: StepLineSeries;

  constructor(c: XYChart, toolTipText?: string) {
    const bullet = c.series.push(new StepLineSeries());
    bullet.dataFields = {
      categoryY: "name",
      valueX: "value",
      dateX: "timestamp",
    };
    bullet.stroke = amCore.color("#000");
    bullet.strokeWidth = 3;

    bullet.startLocation = 0.15;
    bullet.endLocation = 0.85;

    if (bullet.tooltip && toolTipText) {
      const tip = bullet.tooltip;
      tip.getFillFromObject = false;
      tip.background.fill = amCore.color("#FFF");
      tip.label.fill = amCore.color("#000");
      bullet.tooltipText = toolTipText;
    }

    this._bullet = bullet;
  }
}

class Cursor {
  private _cursor: XYCursor;
  constructor(c: XYChart) {
    const cur = new XYCursor();
    cur.lineX.disabled = true;
    cur.lineY.disabled = true;
    this._cursor = cur;
    c.cursor = cur;
  }
}

function firstNumber(
  inp: Array<undefined | null | number>
): number | undefined {
  for (const i of inp) {
    if (typeof i === "number") return i;
  }

  return undefined;
}

function lowerBound(opts?: Options, rule?: Rule) {
  return firstNumber([
    opts?.axisRange?.min,
    rule?.criticalLow,
    rule?.warningLow,
    rule?.infoLow,
  ]);
}

function upperBound(opts?: Options, rule?: Rule) {
  return firstNumber([
    opts?.axisRange?.max,
    rule?.criticalHigh,
    rule?.warningHigh,
    rule?.infoLow,
  ]);
}

class TrackerChart {
  private _chart: XYChart;
  private _bullet: Bullet;
  private _cursor: Cursor;

  constructor(
    ref: HTMLDivElement,
    signal: Signal,
    rule?: Rule,
    opts?: Options
  ) {
    const chart = (this._chart = amCore.create(ref, XYChart));
    chart.data = [{ ...signal.values[0], name: signal.name }];

    const yAxis = chart.yAxes.push(new CategoryAxis());
    yAxis.dataFields.category = "name";
    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.labels.template.disabled = true;
    yAxis.cursorTooltipEnabled = false;

    const xAxis = chart.xAxes.push(new ValueAxis());
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.baseGrid.disabled = true;
    xAxis.renderer.fill = amCore.color(DISPLAY_COLORS.normal);
    xAxis.cursorTooltipEnabled = false;
    xAxis.min = lowerBound(opts, rule);
    xAxis.max = upperBound(opts, rule);
    xAxis.paddingLeft = 3;
    createRanges(xAxis, rule);

    chart.numberFormatter.numberFormat = NUMBER_FORMAT;
    chart.dateFormatter.dateFormat = DATE_FORMAT;

    this._cursor = new Cursor(chart);
    this._bullet = new Bullet(chart, "{dateX}\n{name}: {valueX}");
  }

  public destroy() {
    this._chart?.dispose();
  }
}

export default TrackerChart;
