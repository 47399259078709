import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function orgSelectorListSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "ORG_SELECTOR_LIST_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}   

export function orgSelectorListSetCurentPage(
    page: number 
) {
    return (dispatch: any) => {
        dispatch({ type: "ORG_SELECTOR_LIST_SET_CURRENT_PAGE", page: page });
    }
}   

export function orgSelectorListSetOrgTree(
    me: any, 
    orgTree: any[]
) {
    return (dispatch: any) => {
        dispatch({ type: "ORG_SELECTOR_LIST_SET_ORG_TREE", orgTree: orgTree });
    }
}   

export function orgSelectorListSetSearch(
    me: any, 
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "ORG_SELECTOR_LIST_SET_SEARCH", searchText: searchText });
    }
}   

export function orgSelectorListSetDisplay(
    mode: string, 
    searchText: string,
    orgId: string,
    orgName: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "ORG_SELECTOR_LIST_SET_DISPLAY", mode: mode, searchText: searchText, orgId: orgId, orgName: orgName });
    }
}   

export function orgSelectorListOrgGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/orgs/" + orgId, {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.org);
                }
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error);
            }
            errorResponse(me, error);
        });
    };
}

export function orgSelectorListOrgsGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    mode: string = "all",
    page: number = 1,
    searchText?: string,
    orgId?: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/orgs", {
                responseType: "json",        
                headers: {},
                params: {
                    mode: mode,
                    scope: "all",
                    includeTree: true,
                    orgId: orgId,
                    searchText: searchText,
                    page: page,
                    pageSize: appConfigs.tables.pageSize
                }
            }
        )
        .then(response => {    
                let data = response.data.data.orgs == null || (response.data.data.orgs.length || 0) === 0 ? [] : response.data.data.orgs;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data, mode);
                }
                dispatch({ type: "ORG_SELECTOR_LIST_GET_ORGS_SUCCESS", payload: {data: data, page: page} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function orgSelectorListMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: string,
    selected: boolean,
    includeSubOrgs?: boolean
) {
    return async (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL +  "/ui/api/users/orgs/selection?orgId=" + orgId + "&selected=" + selected + "&includeSubOrgs=" + (includeSubOrgs || false),
            {},
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.orgSelection, orgId);
                }
                dispatch({ type: "ORG_SELECTOR_SET_ORG_SELECTION", data: response.data.data.orgSelection });
                dispatch({ type: "ORG_SELECTOR_LIST_SET_SELECTION", orgId: orgId, selected: selected });
                dispatch({ type: "ORG_SELECTOR_TREE_SET_SELECTION", orgSelection: response.data.data.orgSelection });
            }
        )
        .catch(function (error) {
            errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
    }
}
