/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal, Select, Col, Row, Form, Radio, message } from "antd";
import { connect } from "react-redux";
import { appConfigs } from "../../../utils/configurations";
import * as actions from "./actions";
import { resetFormErrors } from "../../../utils/apiHelpers/apiHelpers";
import { FormInstance } from "antd/lib/form/Form";
import "./uds.css";

const { Option } = Select;

interface Prop {
  getSoftwares: Function;
  csus: any;
  CsuMaint: Function;
  getAllSoftwaresList:Function;
}

export const SoftwareInstallation: React.FC<Prop>  = (props: any) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [operatorApproval, setOperatorApproval] = useState<string | null>(null);
    const [selectedSoftwareDevice, setSelectedSoftwareDevice] = useState<any>(null);
    const [selectedSoftwareType, setSelectedSoftwareType] = useState<any>(null);
    const [selectedSoftwarePackage, setSelectedSoftwarePackage] = useState<any>(null);

    useEffect(() => 
    {
      //props.getSoftwares();
      props.getAllSoftwaresList();
    }, []);
    
    const handleOk = () => 
    {
      setIsModalVisible(false);
      formRef.current?.setFieldsValue({
        userConfirmed: true,
      });
    };

    const handleCancel = () => 
    {
      setIsModalVisible(false);
    };

    const onFinishUpdateInstalltionSoftware= (values: any) => 
    {
        const formattedValues = 
        {
          ...values,
          operatorApproval: operatorApproval || null,
          version: getSelectedVersion(),
          hardwareId: getHardwareId(),
          orgId:getOrgId(),
          address:getAddress()
        };
          props.CsuMaint(null, onFinishSuccess, onFinishFailure,props.csus.csuMaintOptions.record.mastertag,formattedValues);
          resetFormErrors(null, formRef.current);
    };

    const onFinishSuccess = () => 
    {
      message.success("Software Queued Successfully");
    };

    const onFinishFailure = (error: any) =>
     {
      const errorMessage = error?.response?.data?.errors?.[0]?.message || 
      "Failed to install software with an error";
      message.error(errorMessage);
    };

    const formRef = React.createRef<FormInstance>();

    const getSelectedVersion = () => 
    {
       const selectedItem:any = Object.values(props.csus.csuList.csuMaint.record).find((item: any) =>
          item.deviceModel === selectedSoftwareDevice &&
          item.softwareType === selectedSoftwareType &&
          item.packageName === selectedSoftwarePackage);
        return selectedItem ? selectedItem.version : null;
    };
    const getOrgId = () => 
      {
         const selectedItem:any = Object.values(props.csus.csuList.csuMaint.record).find((item: any) =>
            item.deviceModel === selectedSoftwareDevice &&
            item.softwareType === selectedSoftwareType &&
            item.packageName === selectedSoftwarePackage);
          return selectedItem ? selectedItem.orgId : null;
      };
    const getHardwareId = () => 
    {
        const discovery = JSON.parse(props.csus.csuList.csuMaint.discovery?.discovery);
        return discovery ? discovery.map((device: any) => device.HardwareID) : null;
     };
     const getAddress = () => 
      {
          const address = JSON.parse(props.csus.csuList.csuMaint.discovery?.discovery);
          return address ? address.map((device: any) => device.address) : null;
       };
    const onSoftwareDeviceChange = (value: any) =>
    {
        setSelectedSoftwareDevice(value);
    };

    const onSoftwareTypeChange = (value: any) => 
    {
      setSelectedSoftwareType(value);
    };

    const onSoftwarePackageChange = (value: any) => 
    {
      setSelectedSoftwarePackage(value);
    };
      
    const onChangeOperatorApproval = (e: any) => 
    {
      setOperatorApproval(e.target.value);
    };
  
    return (
    <>
    <Modal
      title="Entry Warning"
      visible={isModalVisible}
      okText="Yes, Add"
      cancelText="No"
      okType="danger"
      onOk={handleOk}
      onCancel={handleCancel}
      >
      <span>Are you sure you still want to go to Installations?</span>
      </Modal>
      <Form id="softwareInstalltion" 
        ref={formRef}
        layout="vertical" 
        onFinish={onFinishUpdateInstalltionSoftware}
      >
    <Row gutter={8}>
    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      <Form.Item 
        name="DeviceName" 
        label="Select Device" 
        rules={[{ 
          required: true, 
          message: appConfigs.errors.fieldErrors.valueRequired 
      }]}>
        <Select
              placeholder="Select Device"
              showSearch
              style={{ width: "100%" }}
              optionFilterProp="children"
              onChange={onSoftwareDeviceChange}
              filterOption={(input, option) =>
                option?.children[0]?.props?.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
          {props.csus.csuList.csuMaint.record !== null &&
            [...new Set(Object.values(props.csus.csuList.csuMaint.record).map((item: any) => 
              item.deviceModel))].map((uniqueOption: any, index) => (
            <Option key={uniqueOption} value={uniqueOption}>
              {uniqueOption}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item 
        name="softwareType" 
        label="Select Software Type" 
        rules=
        {[{ 
          required: true,
            message: appConfigs.errors.fieldErrors.valueRequired 
        }]}>
        <Select
          placeholder="Select Software Type"
          showSearch
          style={{ width: "100%" }}
          optionFilterProp="children"
          onChange={onSoftwareTypeChange}
          filterOption={(input, option) =>
            option?.children[0]?.props?.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {props.csus.csuList.csuMaint.record !== null &&
          [...new Set(Object.values(props.csus.csuList.csuMaint.record).map((item: any) =>
              item.softwareType))].map((uniqueOption: any, index) => (
          <Option key={uniqueOption} value={uniqueOption}>
          {uniqueOption}
          </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item 
        name="packageName" 
        label="Select Package Name" 
        rules={[{ 
          required: true, 
          message: appConfigs.errors.fieldErrors.valueRequired 
        }]}>
          <Select
            placeholder="Select Package Name"
            showSearch
            style={{ width: "100%" }}
            optionFilterProp="children"
            onChange={onSoftwarePackageChange}
            filterOption={(input, option) =>
              option?.children[0]?.props?.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {props.csus.csuList.csuMaint.record !== null &&
              Object.keys(props.csus.csuList.csuMaint.record).map((item: any) =>
                props.csus.csuList.csuMaint.record[item].deviceModel === selectedSoftwareDevice &&
                props.csus.csuList.csuMaint.record[item].softwareType === selectedSoftwareType ? (
              <Option key={Math.random()} value={props.csus.csuList.csuMaint.record[item].packageName}>
                                    {props.csus.csuList.csuMaint.record[item].packageName}
              </Option>) :  "")}
          </Select>
        </Form.Item>
    </Col>
    <Col span={24}>
      <div>
      <Form.Item name="operatorapproval" label="Operator approval" rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}>
      <Radio.Group name="radiogroup" onChange={onChangeOperatorApproval} value={operatorApproval}>
      <Radio value="Yes">Yes</Radio>
      <Radio value="No">No</Radio>
      </Radio.Group>
      </Form.Item>
      </div>
    </Col>
    <Col span={24}>
      <div style={{ marginTop: "20px" }}>
        {operatorApproval &&
        props.csus.csuList.csuMaint.record !== null &&
        Object.keys(props.csus.csuList.csuMaint.record).map((item: any) =>
          props.csus.csuList.csuMaint.record[item].deviceModel === selectedSoftwareDevice &&
          props.csus.csuList.csuMaint.record[item].softwareType === selectedSoftwareType &&
          props.csus.csuList.csuMaint.record[item].packageName === selectedSoftwarePackage ? (
            <div key={item}>
              <h4 style={{ marginBottom: "20px" }}>Selected Software Details:</h4>
              <h4>Device Model: {props.csus.csuList.csuMaint.record[item].deviceModel}</h4>
              <h4>Software Type: {props.csus.csuList.csuMaint.record[item].softwareType}</h4>
              <h4>Version: {getSelectedVersion()}</h4>
              <h4>Software Package Name: {props.csus.csuList.csuMaint.record[item].packageName}</h4>
              <h4>Operator Approval: {operatorApproval}</h4>
              <h4>Hardware Id: {getHardwareId()}</h4>
              <h4>Address: {getAddress()}</h4>
            </div>
          ) :  "")}
        </div>
    </Col>
  </Row>
  </Form>
  </>
 );
};
    const mapStateToProps = (state: any) => ({
    context: state.contextReducer.context,
    csus: state.DeviceReducers.DeviceState.csus,
    selectedCSU: state.DeviceReducers.DeviceState.csus.selectedCSU,
    permissions: state.contextReducer.data,
    });
export default connect(mapStateToProps, actions)(SoftwareInstallation);