import {
  SignalId,
  Template,
  Signal,
  SignalDatapoint,
  EventData,
  WidGetSignalConfig,
  SignalCollectionId,
} from "../models";
import { DataStore, State, WidgetState } from "../state";
import { Layout } from "react-grid-layout";
import moment, { Moment } from "moment";
import { appConfigs } from "../../../utils/configurations";

export const DATE_FORMAT = "EEE MMM dd yyyy HH:mm:ss";

export function signalLabel(
  signal: { name: string; units: string },
  amCharts: boolean = true
): string {
  const openBracket = amCharts ? "[[" : "[";
  const closeBracket = amCharts ? "]]" : "]";

  let signalName = signal.name;
  if (signal.units) {
    signalName += ` ${openBracket}${signal.units || "-"}${closeBracket}`;
  }

  return signalName;
}

export const TOOLTIP_FORMAT = "{dateX}\n{name}: {valueY}";
export const TOOLTIP_FORMAT_H = "{dateX}\n{name}: {valueX}";
export const TOOLTIP_COLOR = "#333";

export const NUMBER_FORMAT = "#.######";

export function formatDate(date: Date): string {
  const dateString = date.toString();
  const idx = dateString.indexOf("GMT");
  return dateString.slice(0, idx);
}

export function inputSignalMapper<T>(
  mapFunc: (meta: Signal, data: SignalDatapoint[]) => T
) {
  return (signalIds: SignalId[], template: Template, data: DataStore) => {
    const result: T[] = [];

    for (let id of signalIds) {
      const signalData = data.get(id);
      const signalMeta = template.signals.find((s) => s.id === id);

      if (!!signalData && !!signalMeta) {
        result.push(mapFunc(signalMeta, signalData));
      }
    }

    return result;
  };
}

export function extendRange(
  limit: number,
  percent: number,
  direction: "min" | "max"
) {
  const sign = Math.sign(limit);
  const dir = direction === "max" ? 1 : -1;

  return limit * (1 + (dir * sign * percent) / 100);
}

export function addDateAndTime(dateVal: Moment, timeVal?: Moment) {
  //let newDate = moment(dateVal, "ddd MMM D YYYY HH:mm:ss ZZ");
  if (timeVal) {
    dateVal = dateVal.set({
      h: timeVal.toDate().getHours(),
      m: timeVal.toDate().getMinutes(),
      s: timeVal.toDate().getSeconds(),
      ms: 0
    });
  }  
  return dateVal;
}

export function getUnixDate(dateVal: Moment): number {
  return dateVal.valueOf();
}

export function validateForms(startDate: Moment, endDate: Moment, startTime: Moment, endTime: Moment) : Promise<any> {
  if (startDate) {
    let startDateTime = addDateAndTime(startDate, startTime);
    if( startDateTime > moment())  {
      return Promise.reject(
        "Start must come before current time"
      );
    }
    if(endDate) {
      const unixStartDate = getUnixDate(startDateTime);
      const unixEndDateTime = getUnixDate(addDateAndTime(endDate, endTime));
      if (endDate < startDate) {
        return Promise.reject(
          "Start must come before End proposed"
        );
      }
      if (
        moment.duration(unixEndDateTime - unixStartDate).asDays() > appConfigs.app.assetOverview.startdateenddatedaysdiff
      ) {
        return Promise.reject(
          "Start and End can be no more than 7 days apart"
        );
      }
    }
  }  
  return Promise.resolve();
}

export function exportValidateForms(startDate: Moment, endDate: Moment, startTime: Moment, endTime: Moment) : Promise<any> {
  if (startDate) {
    let startDateTime = addDateAndTime(startDate, startTime);
    if( startDateTime > moment())  {
      return Promise.reject(
        "Start must come before current time"
      );
    }
    if(endDate) {
      const unixStartDate = getUnixDate(startDateTime);
      const unixEndDateTime = getUnixDate(addDateAndTime(endDate, endTime));
      if (endDate < startDate) {
        return Promise.reject(
          "Start must come before End proposed"
        );
      }
      if (
        moment.duration(unixEndDateTime - unixStartDate).asDays() > appConfigs.app.assetOverview.exportstartdateenddatedaysdiff
      ) {
        return Promise.reject(
          `Start and End can be no more than ${appConfigs.app.assetOverview.exportstartdateenddatedaysdiff} days apart`
        );
      }
    }
  }  
  return Promise.resolve();
}

export function constructRows(
  response: any,
  signals: SignalId[]
): Map<SignalId, SignalDatapoint[]> {
  let dataSet = new Map<SignalId, SignalDatapoint[]>();
  //Get the response data from api and remove undefined entry
  const responseDataSet = response.map((res: any) => res.data).filter(Boolean);
  if (responseDataSet.length === 0) {
    return dataSet;
  }
  //if there are 3 diff datapoint request, there would be 3 array of array
  //Flatmap will make the collection to a single one dimensional array
  const eventsArrays: EventData[] = responseDataSet.flatMap((res: any) => res);
  //get the unique signal ids present in the event array.
  const ids = new Set(
    eventsArrays.flatMap((event) =>
      event.signals.flatMap((signal) => signal._id)
    )
  );
  const signalIds = Array.from(ids);
  //filtered the signals with the widget signals
  const filteredSignals = signalIds.filter((s) => signals.indexOf(s) > -1);

  function constrcuctSignalMap(id: SignalId): SignalDatapoint[] {
    const signalCollections = eventsArrays.filter(
      (event) => event.signals.findIndex((signal) => signal._id === id) > -1
    );
    const signalDataPointArray = signalCollections.reduce(
      (acc: SignalDatapoint[], current) => {
        acc.push({
          timestamp: new Date(current.createdAt),
          value: current.signals.filter((s) => s._id === id)[0].value,
        });
        return acc;
      },
      []
    );
    return signalDataPointArray.sort(
      (cur, next) => cur.timestamp.getTime() - next.timestamp.getTime()
    );
  }

  filteredSignals.forEach((id) => {
    dataSet.set(id, constrcuctSignalMap(id));
  });

  return dataSet;
}

