import { appConfigs } from "../configurations";
import { errorResponse } from "../../utils/apiHelpers/apiHelpers";
import Axios from "axios";

export async function getEmailTemplate(     
    emailCategory: string,  
    orgId:string,
    emailAddress: string
) 
{

        let url =  appConfigs.server.URL +  "/ui/api/genericEmail?orgId="+orgId+"&emailCategory="+emailCategory+"&emailAccount="+emailAddress;
            
     
        return await Axios.get(url)
        .then(function (response) {        
                return response.data;})
        .catch(function (error) {
            errorResponse(error,  false);
        }); 
    
}

export async function sendEmail(     
    
    orgId: string,  
	userEmailId: string,  
	emailCategory: string,  
	emailSubject: string,  
    emailBody: string   
) 
{
        var url = appConfigs.server.URL +  "/ui/api/genericEmail";
        
        return  await Axios.post(
            url,
            {
                orgId: orgId,  
                userEmailId: userEmailId,  
                emailCategory: emailCategory,  
                emailSubject: emailSubject,  
                emailBody: emailBody
            },
                {
                responseType: "json",       
                headers: {}               
            }
        ).then(response => {                 
              return response.data;                
            }
        ).catch(function (error) {
            errorResponse(error,  false);
       });
    
}

export async function getTemporaryPassword(  
    
    emailAddress: string
) 
{
     
    //https://msgiot-dev.parkerhannifin.com/ui/api/parker/okta/expiredusers/email/sateesh.mram@gmail.com
        let url =  appConfigs.server.URL +  "/ui/api/parker/okta/expiredusers/email/"+emailAddress;
            
     
        return await Axios.get(url)
        .then(function (response) {  
                  
                return response.data;})
        .catch(function (error) {
            errorResponse(error,  false);
        }); 
    
}

