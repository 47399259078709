import Widget from './GaugeWidget';
import EditForm from './Editform';
import { WidgetDetails  } from '../models';
import { CommonWidgetConfig } from '../../models';
import { Options } from './Gauge';

export type Config = CommonWidgetConfig & { type: "gauge"; settings?: Options };

export type GaugeOptions = Options;

const details: WidgetDetails = { type: "gauge", name: "Gauge Widget"}

export { Widget, details, EditForm };

