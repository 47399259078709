import React, { useCallback, useMemo } from "react";
import { Row, Col, Button, Form, Select, InputNumber } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";

import { SignalId } from "../../models";

export interface TrackerSignalField {
  id?: SignalId;
  max?: number;
  min?: number;
}

interface Option {
  value: SignalId;
  label: string;
}

interface TrackerSignalSelectorProps {
  options: Option[];
  onChange: (f: TrackerSignalField) => void;
  value: TrackerSignalField;
  showLabels?: boolean;
  onDelete: () => void;
}

const TrackerSignalSelect: React.FC<TrackerSignalSelectorProps> = (props) => {
  const label = (l: string) => (props.showLabels ? l : undefined);

  const { onChange } = props;

  const triggerChange = useCallback(
    (field: TrackerSignalField) => {
      if (onChange) onChange(field);
    },
    [onChange]
  );

  return (
    <Row gutter={6}>
      <Col span={16}>
        <Form.Item label={label("Signal")} required>
          <Select
            options={props.options}
            suffixIcon={<MinusCircleOutlined onClick={props.onDelete} />}
            onChange={(value: SignalId) =>
              triggerChange({ ...props.value, id: value })
            }
            value={props.value.id}
          />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label={label("Min")}>
          <InputNumber
            onChange={(value) =>
              triggerChange({ ...props.value, min: value as number })
            }
            value={props.value.min}
            style={{ width: "100%" }}
            placeholder="Min"
          />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label={label("Max")} rules={[{ type: "number" }]}>
          <InputNumber
            onChange={(value) =>
              triggerChange({
                ...props.value,
                max: value as number,
              })
            }
            value={props.value.max}
            style={{ width: "100%" }}
            placeholder="Max"
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

type Value = Array<TrackerSignalField>;

interface TrackerSignalSelectorListProps {
  options: Option[];
  value?: Value;
  onChange?: (value: Value) => void;
  max?: number;
}

const TrackerSignalSelectorList: React.FC<TrackerSignalSelectorListProps> = (
  props
) => {
  // Must be optional to play nice with Ant Design's forms but won't
  // actually work without them
  const assertDefined = (propName: keyof TrackerSignalSelectorListProps) => {
    if (props[propName] === undefined) {
      console.error(`Property '${propName} must be defined for ListSelector`);
    }
  };
  assertDefined("onChange");

  const { value = [], onChange } = props;
  const selectedSignalIds = value
    .filter((v) => !!v)
    .map((v) => (v as TrackerSignalField).id);

  const filteredOptions = (v?: TrackerSignalField) => {
    return props.options.filter(
      (opt) => !selectedSignalIds.includes(opt.value) || opt.value === v?.id
    );
  };

  const { add, remove, update } = useMemo(() => {
    const triggerChange = (newValue: Value) => {
      if (onChange) onChange(newValue);
    };

    return {
      add() {
        triggerChange(value.concat({}));
      },
      remove(i: number) {
        triggerChange([...value.slice(0, i), ...value.slice(i + 1)]);
      },
      update(index: number, updatedValue: TrackerSignalField) {
        triggerChange(value.map((v, i) => (i === index ? updatedValue : v)));
      },
    };
  }, [onChange, value]);

  const maxReached = !!props.max && value.length >= props.max;

  return (
    <div>
      {value.map((v, i) => (
        <TrackerSignalSelect
          key={i}
          options={filteredOptions(v)}
          onDelete={() => remove(i)}
          showLabels={i === 0}
          onChange={(v) => update(i, v)}
          value={v}
        />
      ))}
      <Button
        style={{ width: "100%" }}
        type="dashed"
        disabled={maxReached}
        onClick={add}
      >
        {maxReached ? "Max Signals Added" : "Add Signal"}
      </Button>
    </div>
  );
};

export default TrackerSignalSelectorList;
