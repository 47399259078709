import { appConfigs } from "../../../../utils/configurations";
export const initialState =
{
    csus: 
    {
        csuModification: 
        {
            csuList: null,
            loadingCsusList: true,
            selectedCsuList: [] as  any,
            submittingCsuList: false,
        },
        applications: [],
        appVersions: [],
        selectedCsu: null,
        csuMaintOptions: 
        {
            instance: 0,
            width: 720,
            visible: false,
            info_visible: false,
            title: "",
            record: null,
            activeTab: "1",
            tabs: [
                    {
                        title: "Discovery",
                        instance: 0,
                        disabled: false,
                    },
                    {
                        title: "Installation",
                        instance: 0,
                        disabled: false,
                    },
                    {
                        title: "Status",
                        instance: 0,
                        disabled: false,
                    },
                    {
                        title: "History",
                        instance: 0,
                        disabled: false,
                    },
                
            ],
            installationHistory: [],
            tableConfig: 
            {
                pagination : {                  
                    showSizeChanger: appConfigs.tables.showSizeChanger,
                    showQuickJumper: appConfigs.tables.showQuickJumper,
                    pageSize: appConfigs.tables.pageSize,
                    defaultCurrent: appConfigs.tables.defaultCurrent,
                    current: appConfigs.tables.defaultCurrent,
                    total: appConfigs.tables.defaultTotal,
                    hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                    responsive: appConfigs.tables.responsive,
                    onChange: null,
                    showLessItems: appConfigs.tables.showLessItems,  
                }
            }
        },
        activeTab: "1",
        csuList: 
        {
            csuMaint: 
            {
                instance: 0,
                width: 720,
                visible: false,
                record: {},
                discovery: {},
                systemstatus:{},
                systemhistory:{},
                title: "",
                activeTab: "1",
                tabs: [
                    {
                        title: "View Installations",
                        disabled: false,
                    }
                ]
            },
            searchText: "",
            refreshOnUpdate: false,
            columnsFilters: 
            {
                status: ['active', 'inactive']
            },
            tableData: [],
            tableConfig: 
            {
                pagination : {                  
                    showSizeChanger: appConfigs.tables.showSizeChanger,
                    showQuickJumper: appConfigs.tables.showQuickJumper,
                    pageSize: appConfigs.tables.pageSize,
                    defaultCurrent: appConfigs.tables.defaultCurrent,
                    current: appConfigs.tables.defaultCurrent,
                    total: appConfigs.tables.defaultTotal,
                    hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                    responsive: appConfigs.tables.responsive,
                    onChange: null,
                    showLessItems: appConfigs.tables.showLessItems,  
                    showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} controller${total > 1 ? 's' : ''}`
                }
            },
        },
        systemhistory:{
            searchText: "",
            refreshOnUpdate: false,
            tableData: [],
            tableConfig: 
            {
                pagination : {                  
                    showSizeChanger: appConfigs.tables.showSizeChanger,
                    showQuickJumper: appConfigs.tables.showQuickJumper,
                    pageSize: appConfigs.tables.pageSize,
                    defaultCurrent: appConfigs.tables.defaultCurrent,
                    current: appConfigs.tables.defaultCurrent,
                    total: appConfigs.tables.defaultTotal,
                    hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                    responsive: appConfigs.tables.responsive,
                    onChange: null,
                    showLessItems: appConfigs.tables.showLessItems,  
                    showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} controller${total > 1 ? 's' : ''}`
                }
            },
        },
        
    }
}