import { appConfigs } from "../../../utils/configurations";

export const initialState = {
    controllers: {
        controllersModification: {
            controllersList: null,
            loadingControllersList: true,
            selectedControllerList: [] as  any,
            submittingControllerList: false,
        },
        applications: [],
        appVersions: [],
        selectedController: null,
        controllerMaintOptions: {
            instance: 0,
            width: 720,
            visible: false,
            info_visible: false,
            title: "",
            record: null,
            activeTab: "1",
            tabs: [
                {
                    title: "Install Application",
                    instance: 0,
                    disabled: false,
                }
            ],
            installationHistory: [],
            tableConfig: {
                pagination : {                  
                    showSizeChanger: appConfigs.tables.showSizeChanger,
                    showQuickJumper: appConfigs.tables.showQuickJumper,
                    pageSize: appConfigs.tables.pageSize,
                    defaultCurrent: appConfigs.tables.defaultCurrent,
                    current: appConfigs.tables.defaultCurrent,
                    total: appConfigs.tables.defaultTotal,
                    hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                    responsive: appConfigs.tables.responsive,
                    onChange: null,
                    showLessItems: appConfigs.tables.showLessItems,  
                }
            }
        },
        activeTab: "1",
        controllersList: {
            controllerMaint: {
                instance: 0,
                width: 720,
                visible: false,
                record: {},
                title: "",
                activeTab: "1",
                tabs: [
                    {
                        title: "View Installations",
                        disabled: false,
                    }
                ]
            },
            searchText: "",
            refreshOnUpdate: false,
            columnsFilters: {
                status: ['active', 'inactive']
            },
            tableData: [],
            tableConfig: {
                pagination : {                  
                    showSizeChanger: appConfigs.tables.showSizeChanger,
                    showQuickJumper: appConfigs.tables.showQuickJumper,
                    pageSize: appConfigs.tables.pageSize,
                    defaultCurrent: appConfigs.tables.defaultCurrent,
                    current: appConfigs.tables.defaultCurrent,
                    total: appConfigs.tables.defaultTotal,
                    hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                    responsive: appConfigs.tables.responsive,
                    onChange: null,
                    showLessItems: appConfigs.tables.showLessItems,  
                    showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} controller${total > 1 ? 's' : ''}`
                }
            },
        }
    }
}