import React from "react";
import { Alert } from "antd";
import { appConfigs } from "../configurations";

interface SuccessProp {
    message?: string;
}

export const SuccessAlert = (props?:SuccessProp) => 
    <Alert
        message={appConfigs.settings.form.alerts.successTitle}
        description={
            (props != null && props.message) || 
            (props == null && appConfigs.settings.form.alerts.successDefaultMessage)
        }
        type="success"
        showIcon
        //closable
    />
interface WarningProp {
        message?: string;
}

export const WarningAlert = (props?:WarningProp) => 
    <Alert
        message={appConfigs.settings.form.alerts.warningTitle}
        description={
            (props != null && props.message) 
        }
        type="warning"
        showIcon
        //closable
    />


interface ErrorProp {
    message?: string[];
}

const listErrors = (props?:ErrorProp) => 
    props != null && props.message!.map((message:string, index:number) => 
        <li key={index}>
            {message}
        </li>
    )         

export const ErrorAlert = (props?:ErrorProp) => 
    <Alert
        message={appConfigs.settings.form.alerts.errorTitle}
        description={
            props != null && <ul>{listErrors(props)}</ul>
        }
        type="error"
        showIcon
        //closable
    />
