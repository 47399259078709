import { WidgetDetails  } from '../models';
import { CommonWidgetConfig } from '../../models';
import { Options } from './LineChart';
import Widget from './Widget';
import EditForm from './EditForm';

export const details: WidgetDetails = {
  type: "line",
  name: "Line Chart"
}

export type LineOptions= Options;

export type Config = CommonWidgetConfig & {
  type: "line";
  settings?: Options;
};

export { EditForm, Widget}