/* eslint-disable react-hooks/exhaustive-deps */
import { Button,  Drawer, Space,  Tabs } from "antd";
import React, { useEffect} from "react";
import { connect } from "react-redux";
import { appConfigs } from "../../../utils/configurations";
import * as actions from "./actions";
import {SoftwareInstallation} from "./SoftwareInstallation";
import {DeviceDiscovery} from "./DeviceDiscovery"
import {DeviceStatus} from "./DeviceStatus"
import {DeviceHistory} from "./DeviceHistory"
import "./uds.css";

const { TabPane } = Tabs;

interface Prop {
    context: any,
    csus: any,
    csuMaintOptions: any,
    closeInstallationProgressDrawer: Function,
    drawerTabChange: Function,
    getSoftwares:Function,
    getDeviceDiscovery: Function,
    getSystemStatus: Function,
    getSystemHistory: Function,
    getAllSoftwaresList:Function;
    CsuMaint:Function,
}

const SoftwareInstallationProgress: React.FC<Prop> = (props) => {

    const onDrawerClose = () => {
        props.closeInstallationProgressDrawer();
    };

    const onTabChange = (key: string) => {
        props.drawerTabChange(key);
    }
    useEffect(() => {

        if (props.csuMaintOptions.record != null) {
            props.getDeviceDiscovery(null,null,null,props.csuMaintOptions.record.mastertag);
            props.getSystemStatus(null,null,null,props.csuMaintOptions.record.mastertag);
            //props.getSystemHistory(null,null,null,props.csuMaintOptions.record.mastertag);
        }
    }, [props.csuMaintOptions.record])

    return (
        <Drawer
            title={props.csuMaintOptions.title}
            width={props.csuMaintOptions.width}
            onClose={onDrawerClose}
            visible={props.csuMaintOptions.info_visible}
            destroyOnClose={true}
            footer={
                <Space size="large" style={{ float: 'right' }}>
                     {props.csuMaintOptions.activeTab === "2" &&
                        <Button data-id="update-to-install-software" type="primary" form="softwareInstalltion" key="submit" htmlType="submit">
                            Update
                        </Button>
                        } 
                    <Button type="default" onClick={onDrawerClose}>
                        {appConfigs.settings.drawers.closeButtonText}
                    </Button>                  
                </Space>
                
            }
        >
            {
                <Tabs data-id="csu-maint-csu-info-tabs" activeKey={props.csuMaintOptions.activeTab} size="large" type="card" onChange={onTabChange}>                    
                  <TabPane tab={props.csuMaintOptions.tabs[0].title} key="1" disabled={props.csuMaintOptions.tabs[0].disabled}>
                     <DeviceDiscovery getDeviceDiscovery={props.getDeviceDiscovery} csus={props.csus} />
                    </TabPane>

                    {/* {(props.csuMaintOptions.record!==null 
                        && props.csuMaintOptions.record.connectionStatus==="ONLINE"
                        && props.csuMaintOptions.record.connectionStatus==="ONLINE")? */}
                        <TabPane tab={props.csuMaintOptions.tabs[1].title} key="2" disabled={props.csuMaintOptions.tabs[1].disabled}>
                            <SoftwareInstallation getAllSoftwaresList={props.getAllSoftwaresList} getSoftwares={props.getSoftwares}  csus={props.csus} CsuMaint={props.CsuMaint} />
                        </TabPane>
                        {/* :
                        <TabPane tab={props.csuMaintOptions.tabs[1].title} key="2" disabled></TabPane>}  */}

                    {/* {(props.csuMaintOptions.record!==null 
                        && props.csuMaintOptions.record.connectionStatus==="ONLINE")? */}
                        <TabPane tab={props.csuMaintOptions.tabs[2].title} key="3" disabled={props.csuMaintOptions.tabs[2].disabled}>
                            <DeviceStatus getSystemStatus={props.getSystemStatus} csus={props.csus} csuMaintOptions={props.csuMaintOptions} />
                        </TabPane>
                        {/* :
                        <TabPane tab={props.csuMaintOptions.tabs[2].title} key="3" disabled></TabPane>} */}

                        <TabPane tab={props.csuMaintOptions.tabs[3].title} key="4" disabled={props.csuMaintOptions.tabs[3].disabled}>
                        <DeviceHistory getSystemHistory={props.getSystemHistory} csus={props.csus} csuMaintOptions={props.csuMaintOptions}/>
                        </TabPane>
                </Tabs>   
            }         
        </Drawer>
    );
}

const mapStateToProps = (state: any) => {
    return {
        context: state.contextReducer.context,
        csus: state.DeviceReducers.DeviceState.csus,
        selectedCSU: state.DeviceReducers.DeviceState.csus.selectedCSU,
        csuMaintOptions: state.DeviceReducers.DeviceState.csus.csuMaintOptions
    };
};

export default connect(
    mapStateToProps,
    actions
)(SoftwareInstallationProgress);