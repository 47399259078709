import React, { useContext, useState, useEffect, useRef } from "react";
import { Modal, Row, Col, Tooltip, Tabs, Button, Space, Table, Input, Popconfirm, Form, Select, message, DatePicker, Card, Popover, Divider, Checkbox } from "antd";
import { FormInstance } from 'antd/lib/form';
import BackNavigation from "../../../utils/Components/BackNavigation";
import { successResponse, errorResponse, resetFormErrors } from "../../../utils/apiHelpers/apiHelpers";
import "./contracts.css";
import history from '../../../utils/history';
import { connect } from "react-redux";
import * as actions from "./actions";
import { ExclamationCircleFilled, InfoOutlined } from "@ant-design/icons";
import moment from 'moment';
import ContractMaint from "./ContractMaint";
import { appConfigs } from "../../../utils/configurations";
import { useDispatch } from 'react-redux';

const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;

const { TabPane } = Tabs;


interface Props{
    contractsSetSearch: Function,
    contractSetPagination: Function,
    getContracts: Function,
    openDrawer: Function,
    getAllAssetsList: Function,
    addToContractsList: Function,
    addToRenewalsToolList: Function,
    removeFromContractsList: Function,
    removeFromRenewalsToolList: Function,
    modifyContract: Function,
    addServicePlanToBucket: Function,
    updateField: Function,
    updateRenewalField: Function,
    getBillableCustomerList: Function,
    getServicePlansList: Function,
    getCustomerBucketDetails: Function,
    setTab: Function,
    context: any,
    contracts: any,
    permissions: any,
    contractsModification: any,
    renewalTool: any,
    billableCustomers: any,
    customerBucketDetails: any
    servicePlansList: any
}

const ServicePlanTab: React.FC<Props> = (props) => {

    const dispatch = useDispatch();

    const formRef = React.createRef<FormInstance>();
    const [showZeros, setShowZeros] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)

    useEffect(() => {
        props.getBillableCustomerList()
        props.getServicePlansList()
        return () => {
            dispatch({ 
                type: "GET_CUSTOMER_BUCKET_DETAILS", 
                payload: { customerBucketDetails: [] } // Or any default value you want
            });
        };

    }, [])

    const toggleZeros = () => {
        setShowZeros(!showZeros)
    }

    const showModal = () => {
        setIsModalVisible(true);
    };
    
    const handleOk = () => {
        setIsModalVisible(false);

        resetFormErrors(null, formRef.current); 

        formRef.current?.setFieldsValue({
            allowDuplicate: true,
        })

        console.log(formRef.current?.getFieldsValue())
        props.addServicePlanToBucket(formRef.current?.getFieldsValue(), submitNewServicePlanSuccess, submitNewServicePlanFailure)
    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const customerSelected = (organizationId:any) => {
        props.getCustomerBucketDetails(organizationId)
    }

    const onFinishServicePlanAddition = (values: any) => {
        resetFormErrors(null, formRef.current); 

        formRef.current?.setFieldsValue({
            allowDuplicate: false,
        })

        console.log(values)
        console.log(formRef.current?.getFieldsValue())

        props.addServicePlanToBucket(formRef.current?.getFieldsValue(), submitNewServicePlanSuccess, submitNewServicePlanFailure)
    }

    const submitNewServicePlanSuccess = (description: any, organizationId: any) => {
        message.success("Service Plan(s) Added To Bucket Successfully!");
        props.getCustomerBucketDetails(organizationId)
    }

    const submitNewServicePlanFailure = (defaultMessage: any, ServerMessage: any) => {
        if (ServerMessage.includes('Record Already Exists')) {
            showModal()
        } else {
            message.error(ServerMessage ? ServerMessage : defaultMessage);
        }
    }


    const customerBucketTableColumn = [
        {
            title: 'Service Plan Description',
            dataIndex: 'packageName',
        },
        {
            title: 'Service Plan Part Number',
            dataIndex: 'partNumber',
        },
        {
            title: 'Available Quantity',
            dataIndex: 'total_available_quantity',
            render: (text: string, record: any) => {
                // console.log('record :>> ', record);
                const content = (
                    <table className="styled-table" style={{width: '550px'}}>
                        <thead>
                            <tr>
                                <th style={{whiteSpace: 'nowrap' }}>Created At</th>
                                <th style={{whiteSpace: 'nowrap' }}>PO Number</th>
                                <th style={{whiteSpace: 'nowrap' }}>SO Number</th>
                                <th>Purchased Quantity</th>
                                <th>Used (Assigned) Quantity</th>
                                <th>Available Quantity</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            { record.partNumberBucketEntries?.map((bucketEntry:any, index: number) => 
                                <tr key={index}>
                                    <td style={{whiteSpace: 'nowrap' }}>{ moment(bucketEntry.createdAt).format('MM/DD/YYYY HH:mm:ss') }</td>
                                    <td style={{whiteSpace: 'nowrap' }}>{bucketEntry.poNumber}</td>
                                    <td style={{whiteSpace: 'nowrap' }}>{bucketEntry.soNumber}</td>
                                    <td>{bucketEntry.purchasedQuantity}</td>
                                    <td>{bucketEntry.purchasedQuantity - bucketEntry.availableQuantity}</td>
                                    <td>{bucketEntry.availableQuantity}</td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                );

                if(record.total_available_quantity > 0) {
                    return (
                        <Popover placement="topLeft" key={record.partNumber} content={content} title="PO History">
                            <span style={{ cursor: "pointer", color: "#119FFB", textDecoration: "underline" }}>{record.total_available_quantity}</span>
                        </Popover >
                    )
                } else {
                    return (
                        <span style={{ cursor: "pointer" }}>{record.total_available_quantity}</span>
                    )
                }

            }
        },
    ];

        
    return (

        <>
            
            <Modal title="Duplicate Entry Found!" visible={isModalVisible} okText="Yes, Add" cancelText="No" okType="danger" onOk={handleOk} onCancel={handleCancel}>
                We found a duplicate entry with the same PO, SO, and Part Number! Are you sure you still want to add this record?
            </Modal>
            
            <Form
                id="updateCustomerBucket"
                ref={formRef}
                layout="vertical"
                onFinish={onFinishServicePlanAddition}
            >   
                <Row style={{padding: '0px', margin: '0px'}} gutter={24}>

                    <Col style={{ marginTop: '10px' }} xs={6} sm={6} md={6} lg={6} xl={6}>

                        <Form.Item style={{display: "none"}} name="allowDuplicate" >
                            <Input value="false" type='hidden' />
                        </Form.Item>
                        
                        <Form.Item
                            label="Billable Customer"
                            name="orgId"                                 
                            rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                        >
                        
                            <Select
                                placeholder="Select A Customer"
                                showSearch
                                style={{ width: '100%' }}
                                onChange={(value, e) => customerSelected(value)}
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    return  option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                            >
                                {
                                    props.billableCustomers.map((item:any) => (
                                        <Option style={{fontSize: '12px'}} key={item.sapCustomerNumber} value={item.organizationId}>{item.customerName}</Option>
                                    ))
                                }
                            </Select>

                        </Form.Item>

                    </Col>

                    <Col style={{ marginTop: '10px' }} xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Form.Item 
                            label="Purchase Order"
                            name="PONumber"
                            rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                        >
                            <Input
                                style={{ width: '100%' }}
                                placeholder="Enter PO#"
                            />
                        </Form.Item>
                    </Col>

                    <Col style={{ marginTop: '10px' }} xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Form.Item 
                            label="Sales Order"
                            name="SONumber"
                            rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                        >
                            <Input
                                style={{ width: '100%' }}
                                placeholder="Enter SO #"
                            />
                        </Form.Item>
                    </Col>

                    <Col style={{ marginTop: '10px' }} xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Form.Item 
                            label="Quantity"
                            name="Quantity"
                            rules={[
                                { required: true, message: appConfigs.errors.fieldErrors.valueRequired },
                                { validator: (_, value) => {
                                    if (!Number.isInteger(Number(value))) {
                                         return Promise.reject('Whole Numbers Only'); 
                                    } 
                                    return Promise.resolve(); 
                                
                                }}
                            ]}
                        >
                            <Input
                                placeholder="Enter Quantity"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>


                    <Col style={{ marginTop: '10px' }} xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Form.Item
                            label="Service Plan"
                            name="partNumberId"                                 
                            rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                        >
                        
                            <Select
                                placeholder="Select Service Plan"
                                showSearch
                                style={{ width: '100%' }}
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    return  option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                            >
                                {
                                props.servicePlansList.map((item:any) => (
                                    item.packageActive===true&&
                                    <Option style={{fontSize: '12px'}} key={item.servicePlanId} value={item.servicePlanId}>{item.packageName}</Option>
                                ))

                                }
                            </Select>
                            
                        </Form.Item>
                    </Col>

                    <Col style={{ marginTop: '10px' }} xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item>
                            <Button 
                                style={{ width: '100%' }}
                                type="primary"
                                htmlType="submit"
                            >
                                Add Service Plan Details
                            </Button>
                        </Form.Item>
                    </Col>

                </Row>

            </Form>

            <Divider></Divider>

            {
                props.customerBucketDetails.length > 0 &&
                <Col style={{ marginTop: '10px' }} xs={24} sm={24} md={24} lg={24} xl={24}>
                    <h2>
                        <span style={{ marginRight: "5px"}}>Selected Customer Bucket Details</span>
                        {/* <span onClick={toggleZeros} style={{ marginLeft: "3px", fontSize: '12px', color: "#1890FF", textDecoration: "underline", cursor: "pointer", userSelect: "none" }}>{!showZeros ? 'Show' : 'Hide'} Zero Quantities</span> */}
                        {/* <Button 
                            onClick={toggleZeros} 
                            size='small'
                            type={!showZeros  ? "primary" : "default"}
                            style={{ float: 'right'}}
                        >{!showZeros ? 'Show' : 'Hide'} Zeros</Button> */}
                        {/* <Checkbox 
                            onClick={toggleZeros}
                            defaultChecked={showZeros}
                            style={{ float: 'right'}}
                        >
                            <span style={{marginLeft: "2px"}}>Show Zero Quantities</span>
                        </Checkbox>  */}
                    </h2>
                    <Table
                        pagination={false}
                        columns={customerBucketTableColumn}
                        dataSource={!showZeros ? props.customerBucketDetails.filter((details:any) => details['total_available_quantity'] > 0) : props.customerBucketDetails}
                        size="small"
                    />
                </Col>
            }
            
            

        </>

    );
}


const mapStateToProps = (state:any) => {
    return {
        permissions: state.contextReducer.data,
        context: state.contextReducer.context,
        contracts: state.contractsReducers.contractsState.contracts,
        contractsModification: state.contractsReducers.contractsState.contracts.contractsModification,
        renewalTool: state.contractsReducers.contractsState.contracts.renewalTool,
        billableCustomers: state.contractsReducers.contractsState.contracts.billableCustomers,
        servicePlansList: state.contractsReducers.contractsState.contracts.servicePlansList,
        customerBucketDetails: state.contractsReducers.contractsState.contracts.customerBucketDetails
    };
};

export default connect(
    mapStateToProps,
    actions
)(ServicePlanTab);