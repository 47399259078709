import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function orgsGlobalSubscriptionsSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "ORGS_GLOBAL_SUBSCRIPTIONS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}   

export function orgsGlobalSubscriptionsSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "ORGS_GLOBAL_SUBSCRIPTIONS_SET_SEARCH", searchText: searchText });
    }
}   

export function orgsGlobalSubscriptionsGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: string,
    userId: string,    
    searchText: string,
    page: number = 1
) {
    return async (dispatch: any) => {            
        Axios.get(
            appConfigs.server.URL + "/ui/api/orgs/" + ((orgId || "") !== "" ? orgId + "/": "") + "userId/" + ((userId || "") !== "" ? userId + "/": "" ) + "globalalertsubscription", {
                    responseType: "json",        
                    headers: {},
                    params: {
                        searchText: searchText,
                        page: page,
                        pageSize: appConfigs.tables.pageSize
                    }
                }
            )
            .then(response => {
                
                let globalalertSubscriptions = response.data.data.globalalertSubscriptions;
                    if (callbackFnSuccess != null) {
                        callbackFnSuccess(globalalertSubscriptions);
                    }
                    dispatch({ type: "ORGS_GLOBAL_SUBSCRIPTIONS_GET_SUCCESS", payload: {data: globalalertSubscriptions, page: page} });
                }
            )
            .catch(function (error) {
                
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
            
    };
}    

export function orgsGlobalSubscriptionsMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: string,
    record: any,
    notification: string,    
    action: string,
    scope: string
) {
    return async (dispatch: any) => {
        Axios.put(
            appConfigs.server.URL + "/ui/api/orgs/" + orgId + "/globalalertsubscription" +
                "?notification=" + notification +                
                "&action=" + action +
                "&scope=" + scope,
            {},
            {
                responseType: "json"
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(orgId, record, notification, action, scope);           
                }
                dispatch({ type: "ORGS_GLOBAL_SUBSCRIPTIONS_MAINT_SUCCESS", payload: {record: record, notification: notification, action: action, scope: scope} });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error, orgId, record, notification, action, scope);
                }
            }
        );
    }
}