import React, { useEffect, useState, useRef } from "react";
import { Row, Card, Col, Pagination, Empty, message, Grid, Tag, Divider, Button, Tooltip, Input } from "antd";
import Icon, { ZoomInOutlined } from "@ant-design/icons";
import { MapActions } from "../../../../sharedComponents/GoogleMap";

import history from '../../../../utils/history';
import { AssetOverview, ServiceTruck } from "../../models";
import { appConfigs } from "../../../../utils/configurations";
import { formatGps, formatSignals } from "../../../../utils/commonHelpers";

import onlineIcon from "../../../../assets/images/map-status/online.png";
import offlineIcon from "../../../../assets/images/map-status/offline.png";
import criticalIcon from "../../../../assets/images/map-status/critical.png";
import warningIcon from "../../../../assets/images/map-status/warning.png";
import infoIcon from "../../../../assets/images/map-status/info.png";
import dm1Icon from "../../../../assets/images/map-status/dm1.png";
import ReactDOMServer from "react-dom/server";
import { getTopThreeGeoTabAssets } from "../../../ServiceTrucks/serviceTrucksHelper";

import { ReactComponent as Tractor3 } from "../../../../assets/images/vehicle-icons/vehicles/tractor_3.svg";
import { width } from "@amcharts/amcharts4/.internal/core/utils/Utils";

const { TextArea } = Input;
const { useBreakpoint } = Grid;

interface AssetStatusProps {
    context: string;
    mastertag: string;
    alertType: string;
    status: string,
    icon: any;
    count: number;
    assetDrawerClose?: Function
}

function displayCount(count: number) {
    return count < 100 ? count : "99+";
}

const goToAsset = (
    e: any, 
    mastertag: string, 
    assetDrawerClose?: Function
) => {
    e.preventDefault();
    if (assetDrawerClose) {
        assetDrawerClose();
    }
    history.push("/assets/" + mastertag);
};    

const goToAlerts = (
    e: any, mastertag: 
    string, alertType: string, 
    status: string, 
    assetDrawerClose?: Function
) => {
    e.preventDefault();
    if (assetDrawerClose) {
        assetDrawerClose();
    }
    history.push({
        pathname: "/alerts",
        state: [{ 
            mode: alertType, 
            mastertag: mastertag,
            status: alertType === "signal-alarms" ? status : ""
        }]
    });    
};    

const AssetName = (props:any) => {
    return (
        <div className="assets-list-asset-name">
            {
                props.context !== "tooltip" &&
                <a href="# " onClick={(e) => {goToAsset(e, props.mastertag, props.assetDrawerClose)}} title="Asset Overview"> 
                    {props.name || props.mastertag}
                </a>
            }
            {
                props.context === "tooltip" &&
                <a href={"/assets/" + props.mastertag} title="Asset Overview"> 
                    {props.name || props.mastertag}
                </a>
            }
        </div>
    );
};

const AssetStatusBody = (props:any) => {
    return (
        <>
            <img src={props.icon} alt="" title={props.status === "Critical"? "Critical Alerts":props.status === "Warning"? "Warning Alerts": props.status==="Info"? "Info Messages": props.status==="dm1"? "DM1s": props.status} />
            <span>
                {displayCount(props.count)}
            </span>
        </>
    );
};

const AssetStatus: React.FC<AssetStatusProps> = (props) => {
    return (
        <Col span={6}>
            <div className="map-assets-list-element">
                {
                    props.context!== "tooltip" &&
                    <a href="# " onClick={(e) => {goToAlerts(e, props.mastertag, props.alertType, props.status, props.assetDrawerClose)}}> 
                        <AssetStatusBody
                            status={props.status}
                            icon={props.icon} 
                            count={props.count} 
                        />
                    </a>
                }
                {
                    props.context === "tooltip" &&
                    <a href={"/alerts/" + props.alertType + "/" + props.mastertag + "/" + props.status}>
                        <AssetStatusBody
                            status={props.status}
                            icon={props.icon} 
                            count={props.count} 
                        />
                    </a>
                }
            </div>
        </Col>
    );
};

const GeotabAssetInfo = (props: any) => {
    return (
        <>
            {/* <Col span={12}><span><b>VIN:</b> {props.GeotabAsset.VIN.substring(props.GeotabAsset.VIN.length-6)}</span></Col> */}
            <Col span={18}><span> {props.GeotabAsset.operatorName?.length > 0 ? props.GeotabAsset.operatorName : `${props.GeotabAsset.VIN.substring(props.GeotabAsset.VIN.length - 6)} - (No Name Assigned)`}</span></Col>
            <Col span={6}><span> {props.GeotabAsset.distance.toFixed(2)} <span style={{ fontSize: '10px' }}>mi.</span></span></Col>
        </>
    );
}



interface Props {
    context: string;
    assets: AssetOverview[];
    assetsAll?: AssetOverview[];
    searchText?: string;
    filters?: any;
    assetDrawerClose?: Function;
    mapActions?: MapActions;
    geotabAssets?: ServiceTruck[];

}

const AssetList: React.FC<Props> = (props) => {
    const deviceSize = useBreakpoint();   
    
    const [pagination] = useState<any>(
        {                  
            showSizeChanger: appConfigs.tables.showSizeChanger,
            showQuickJumper: appConfigs.tables.showQuickJumper,
            pageSize: appConfigs.tables.pageSize,
            defaultCurrent: appConfigs.tables.defaultCurrent,
            current: appConfigs.tables.defaultCurrent,
            total: appConfigs.tables.defaultTotal,
            hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
            responsive: appConfigs.tables.responsive,
            showLessItems: appConfigs.tables.showLessItems,  
            showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} asset${total > 1 ? 's' : ''}`,
            size: "small"
        }
    )
    
    const [pageCurrent, setPageCurrent] = useState<number>(1);
    const [searchText, setSearchText] = useState<string>("");
    const [filters, setFilters] = useState<any>({});

    const pageChange = (value:number) => {
        setPageCurrent(value);      
    }

    const componentMounted = useRef<boolean>(true);
    useEffect(
        () => {
            if (!componentMounted.current) {
                componentMounted.current = true;
            } 
            else {
                if (props.searchText !== searchText && pageCurrent !== 1) {
                    setPageCurrent(1);
                }
                if (JSON.stringify(props.filters) !== JSON.stringify(filters) && pageCurrent !== 1) {
                    setPageCurrent(1);
                }
                setSearchText(props.searchText || "");
                setFilters(props.filters);
            }           
        },
        [props.searchText, props.filters] //eslint-disable-line
    );  
    
    
    return (
        <div data-id="map-assets-list">
            {
                props.assets.slice((pageCurrent - 1) * pagination.pageSize, pageCurrent * pagination.pageSize).map((asset, index) => (
                <Row key={asset.id + "." + index}>
                    <Card>
                        <Row justify="space-between" align="middle">
                            <Col span={20}>
                                <AssetName 
                                    context={props.context}
                                    name={asset.name}
                                    mastertag={asset.mastertag}
                                    assetDrawerClose={props.assetDrawerClose}
                                />
                            </Col>
                            {props.context !== "tooltip" &&
                                <Col span={2} className="assets-list-connection-status">
                                    <ZoomInOutlined onClick = {() => {                                    
                                        if (props.mapActions && asset.position && asset.position.longitude && asset.position.latitude && asset.position.longitude !==0 && asset.position.latitude !== 0) {
                                            props.mapActions.focusAsset(asset.position.latitude, asset.position.longitude);
                                            if(!deviceSize.xl && !deviceSize.xxl) {
                                                if(props.assetDrawerClose) {
                                                    props.assetDrawerClose();
                                                }
                                            }
                                        } else {
                                            message.info('Asset not available on map');
                                        }
                                    }} />
                                </Col>
                            }
                            <Col span={2} className="assets-list-connection-status">
                                {
                                    asset.connectionStatus === "online" &&
                                    <img src={onlineIcon} title="Online" alt=""/>
                                }
                                {
                                    asset.connectionStatus !== "online" &&
                                    <img src={offlineIcon} title="Offline" alt=""/>                                    
                                }
                            </Col>
                        </Row>

                        <div className="assets-list-org-name">
                            {asset.orgName}
                        </div>

                        {/* {
                            props.context === "tooltip" &&
                            <div className="assets-list-position">
                                {formatGps(asset.position?.latitude || 0, asset.position?.longitude || 0)}
                            </div>
                            
                        } */}
                        {
                            props.context === "tooltip" &&
                            <div className="asset-list-position msg-wrapper">
                            {
                                <div data-id="pinned-signals-tooltip">      
                                </div>
                            }
                            </div>
                        }

                        <Row gutter={8} className="assets-list-status-line">
                            <AssetStatus
                                context={props.context}
                                mastertag={asset.mastertag}
                                alertType="signal-alarms"
                                status="Critical"
                                icon={criticalIcon}
                                count={asset.recentCriticalCount}
                                assetDrawerClose={props.assetDrawerClose}
                            />
                            <AssetStatus
                                context={props.context}
                                mastertag={asset.mastertag}
                                alertType="signal-alarms"
                                status="Warning"
                                icon={warningIcon}
                                count={asset.recentWarningCount}
                                assetDrawerClose={props.assetDrawerClose}
                            />
                            <AssetStatus 
                                context={props.context}
                                mastertag={asset.mastertag}
                                alertType="signal-alarms"
                                status="Info"
                                icon={infoIcon} 
                                count={asset.recentInfoCount} 
                                assetDrawerClose={props.assetDrawerClose}
                            />
                            <AssetStatus 
                                context={props.context}
                                mastertag={asset.mastertag}
                                alertType="dm1-faults"                                
                                status="dm1"
                                icon={dm1Icon} 
                                count={asset.recentDm1Count} 
                                assetDrawerClose={props.assetDrawerClose}
                            />

                            { asset.tagsSorted?.length > 0 &&
                                <Card>
                                    <Row justify="start" align="top">
                                        <Col>
                                            <span style={{ marginRight: "3px" }}><b>Tags: </b></span>
                                            {
                                            asset.tagsSorted
                                                .map((tag:any, index:number) => (
                                                    <Tag key={index}>
                                                        {tag}
                                                    </Tag>
                                                ))
                                            }
                                        </Col>
                                    </Row>
                                </Card>
                            } 
                                                
                        </Row>
                        
                    </Card>
                    {/* {   props.geotabAssets && (props.geotabAssets.length > 0) && 
                        <Card>
                            { 
                                props.context === "tooltip" && props.geotabAssets && (props.geotabAssets.length > 0) &&
                                <Row style={{paddingBottom: '5px'}}>
                                    <Col span={20}>
                                        <h4>Top 3 Nearby Service Trucks </h4>
                                    </Col>
                                </Row>
                            }

                            {
                                props.context === "tooltip" && props.geotabAssets &&
                                    <Row style={{lineHeight: 1}}>
                                        <Col span={18}><h4 style={{color: '#349beb'}}>Operator</h4></Col>
                                        <Col span={6}><h4 style={{color: '#349beb'}}>Distance</h4></Col>
                                        {
                                            getTopThreeGeoTabAssets(asset, props.geotabAssets).map((geotabAsset: any) => (
                                                <GeotabAssetInfo GeotabAsset={geotabAsset} mapActions={props.mapActions} key={geotabAsset.VIN} />
                                            ))
                                        }
                                    </Row>
                            }
                        </Card>
                    } */}
                </Row>
            ))}

            {
                props.assets.slice((pageCurrent - 1) * pagination.pageSize, pageCurrent * pagination.pageSize).length === 0 &&
                <Empty 
                    image={Empty.PRESENTED_IMAGE_SIMPLE} 
                    description={
                        <>
                            <span>
                                No Data
                            </span>
                            {
                                props.context === "list-asset-search-global" &&
                                (props.searchText || "").length < 2 &&
                                <>
                                    <br />
                                    <span>Need at least 2 letters for global search</span>
                                </>
                            }
                        </>
                    }
                />
            }

            <Pagination
                {...pagination}
                current={pageCurrent}
                onChange={pageChange}
                total={props.assets.length}
            />

        </div>
    );
};

export default AssetList;
