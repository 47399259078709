import { Grid, Tabs, Popconfirm, message, Empty, Alert, Typography } from "antd";
import React, {
  useReducer,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import * as Api from "./api";
import AssetDashboard from "./AssetDashboard";
import { DashboardClosing, DashboardTitleText } from "./DashboardHeader";
import { DashboardExtra } from "./DashboardExtra";
import { DBWidgetConfig } from "./models";
import { reducer, bindActions, initState, DashboardState } from "./state";
import "./Dashboard.css";
import * as DashboardApi from "./DashboardApi";
import {
  PermissionDrawer,
  formatPermissions,
  PermissionApi,
  DashboardPermission,
  constructPermissionToSave,
} from "./Permission";
import { appConfigs } from "../../utils/configurations";
import { connect, useDispatch } from "react-redux";
import * as actions from "../../actions";
import * as CommonHelper from "../../utils/commonHelpers";
import { errorResponse } from "../../utils/apiHelpers/apiHelpers";
import * as signalR from '@microsoft/signalr'
import axios from "axios";
import { spawn } from "child_process";
import moment from 'moment';
import AssetNotes from "./AssetNotes";
import { WidgetFilterConfig } from "./WidgetFilterForm";
//import { STATE_TOKEN_KEY_NAME } from "@okta/okta-auth-js";


const { TabPane } = Tabs;
const { useBreakpoint } = Grid;
let signalsList:any = []; // Loaded by initial loading on default dashboard or onTabClick for a specific dashboard
var defaultDashboardInitialized = false;
type Breakpoints = ReturnType<typeof useBreakpoint>;
const { Title } = Typography;

function columnNumber(breakpoints: Breakpoints) {
  if (breakpoints.xl) {
    return 4;
  } else if (breakpoints.lg) {
    return 3;
  } else if (breakpoints.md) {
    return 2;
  } else {
    return 1;
  }
}

interface Props {
  initialData: Api.AssetDashboardResponse;
  context: any;
  dashboard: any;
  permissions: any;
  signalRDataEnabled: boolean;
  onAssetNotesDrawerToggle: Function;
  updateSignalRData: Function;
  resetSignalRData: Function;
  disableSignalRData: Function;
  userPreferences: any;
  gpsCoordinates: any;
  SignalRInitialData: any;
  filterPreferences: Function;
}

interface PermissionState {
  dashboardId: number;
  roleId: number;
  permissionId: number;
  permission_level: number;
}

const InitDashboard: React.FC<Props> = ({
  initialData,
  context,
  dashboard,
  permissions,
  signalRDataEnabled,
  onAssetNotesDrawerToggle,
  updateSignalRData,
  resetSignalRData,
  disableSignalRData,
  gpsCoordinates,
  userPreferences
}) => {
  type TabAction = "add" | "remove";
  let defaultTab: number = 0;
  const columns = columnNumber(useBreakpoint());
  const [userAssetPreferences, setUserPreferences] = useState(userPreferences);
  const [state, dispatch] = useReducer(
    reducer,
    initState(initialData, columns, userAssetPreferences,gpsCoordinates)
  );
  const drawerWidth: number = Math.min(
    window.innerWidth,
    appConfigs.settings.drawers.defaultWidth
  );

  const { roleName } = context && context.appContext;

  const [connection, setConnection] = useState<any>();
  const [dashboardPermissions, setDashboardPermissions] = useState<any>();
  const [datasource, setDatasource] = useState<any>();
  const [toggleDrawer, setToggleDrawer] = useState<boolean>(false);
  const [orgRoles, setOrgRoles] = useState<any>([]);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  const [updatePreferences, setUpdatePreferences]= useState<boolean>(false);
  const [isTimezoneChanged, setTimezoneChanged]= useState<boolean>(false);
  const [gatewayId, setgatewayId] = useState<any>(null);
  const [bgImageUrlVar,setbgImageUrlVar]=useState<any>(null);
  const [isNewDashboard,setisNewDashboard]=useState<any>();
  const permissionSet = CommonHelper.getPermissions(
    permissions.asset_dashboard
  );

  const disptachMethods = useMemo(() => bindActions(dispatch), [dispatch]);
  const userPreferenceMethods ={
    updateAssetPreferences: (filterData: any,isTimezoneChanged:any) => updateAssetPreferences(filterData,isTimezoneChanged)
  };

  const updateAssetPreferences = (filterData: any,isTimezoneChanged:any) => {
    disptachMethods.assetPreferences(filterData.assets)
    setUserPreferences(filterData);
    if(isTimezoneChanged){
      setTimezoneChanged(true);
    }else{
      setUpdatePreferences(true);
    }     
    
  }
  const contextDispatch=useDispatch()

  const getGPSLatLong = (gatewayId: string, signalCollectionID: any, filterConfig?: WidgetFilterConfig) => {
    var coordinates: any = [];
    Api.loadSignalsData(gatewayId, signalCollectionID, filterConfig, "tracker")
      .then(res => {
        if (res[0].data) {
          res[0].data.signals.filter((ele: any) => {
            if (ele.name == "latitude") {
              coordinates.latitude = ele.value;
            }
            if (ele.name == "longitude") {
              coordinates.longitude = ele.value;
            }
          })
            contextDispatch(actions.contextUpdateGPSCoordinates(coordinates));
            disptachMethods.gpsCoordinates(coordinates)
        }
        else{
          let coordinates={
            latitude:"",
            longitude:""
          };
          contextDispatch(actions.contextUpdateGPSCoordinates(coordinates));
          disptachMethods.gpsCoordinates(coordinates)
        }
      })

  }

  if (!gatewayId && state.timezone.isGpsSignalAvailable && state.asset.gatewayId) {
    getGPSLatLong(state.asset.gatewayId, state.timezone.gpsSignalColId);
    setgatewayId(state.asset.gatewayId);
  }
  useEffect(() => {
    if (state) {
      disptachMethods.updateLayout(columns, +state.activeDashboard);
    }
     if (updatePreferences || isTimezoneChanged){
      contextDispatch(actions.contextGet())
     }
    }, [columns, +state.activeDashboard,updatePreferences,isTimezoneChanged]);

  const onTabClick = (
    activeKey: string,
    e: React.KeyboardEvent | React.MouseEvent
  ) => {
    let widgets: DBWidgetConfig[] = [];
    let title: string;
  
    Api.getDashboard(state.asset.mastertag, +activeKey).then((resp) => {
      const { tabId, tabs } =
        resp && resp.data && resp.data.data && resp.data.data.data;
      if (tabs) {
        const respTab = JSON.parse(tabs);
        setbgImageUrlVar(respTab[0].bgimageurl);
        setisNewDashboard(respTab[0].isNewDashboard);
        if (respTab.length > 0) {
          widgets =
            (respTab[0].widgets && respTab[0].widgets.length > 0 &&
              (respTab[0].widgets as DBWidgetConfig[])) ||
            [];
          title = respTab[0].title;
          // set the signalR signal list for the currently open tab
          // since during the initialization of the asset dashboard, only the default dashboard widgets is set
          // all other dashboards' widgets are set to null from /ui/api/assets?allData=true
    
            // console.log("reset signalRList for the current dashboard ", respTab[0].title)
            signalsList = [];
            respTab[0]?.widgets?.map((widget: any) => {
              if (widget.type === "command" ) {
                // map the command's response signal to the signalR
                // since only the response signal is the telmetry data
                widget?.signals?.map((signal: any) => {
                  Api.getCommandResponseSignalIds(state.asset.templateId, signal.signalId)
                .then((resp) => {
                  if (resp?.data?.data?.respSignals?.length > 0) {
                      signalsList.push({
                        signalId: resp.data.data.respSignals[0].responseSignalId,
                        name: resp.data.data.respSignals[0].responseSignalName,
                      })
                    }
                  })
                  .catch((error:any) => {
                    message.error(error?.response?.data?.errors?.length > 0 ?
                      error?.response?.data?.errors[0].message :
                      "Error in receiving the latest command response signal info")
                  });
                });
              } else {
                widget?.signals?.map((signal: any) => {
                  if (!signalsList.some((o:any) => o.signalId == signal.signalId)) {
                    signalsList.push({
                      signalId: signal.signalId,
                      name: signal.name,
                    })
                  }
                })
              }
              widget?.rightYAxisSignals?.map((signal: any) => {
                if (!signalsList.some((o:any) => o.signalId == signal.signalId)) {
                  signalsList.push({
                    signalId: signal.signalId,
                    name: signal.name,
                  })
                }
              })
            })
            // console.log("Dashboard " + respTab[0].title + " signalR list : ", signalsList);
        }
      }
      disptachMethods.loadWidgetsOnTabClick(tabId, columns, widgets);
    });
  };

  const onDrawerToggle = () => {
    setToggleDrawer((prevToggleDrawer) => !prevToggleDrawer);
  };

  const toggleAssetNotesDrawer = () => {
    onAssetNotesDrawerToggle(!dashboard.assetNotes.visible);
  };

  const onDashboardDelete = (dashboardId: number) => {
    setbgImageUrlVar(null);
    DashboardApi.deleteDashboard(state.asset.templateId, dashboardId)
      .then((resp: any) => {
        disptachMethods.deleteDashboard(dashboardId);
      })
      .catch((error) => errorResponse(null, error));
  };

  const nextDashboardName = () => {
    const arrayTitles = state.dashboards.reduce((acc: number[], d) => {
      const char = d.title.charAt(d.title.length - 1);
      if(parseInt(char) !== NaN) {
        acc = [...acc, +char];        
      }
      return acc;
    },[]);    
    const filteredArray = arrayTitles.filter(Boolean);
    if(filteredArray.length > 0) {
      const currentNo = Math.max(...filteredArray);
      return currentNo;
    } else {
      return 0;
    }    
  }

  const onTabAdd = (targetKey: any, action: TabAction) => {
    setbgImageUrlVar(null);
    if (action === "add") {
      nextDashboardName();
      if(state.dashboards.length === 6) {
        message.info('Maximum 6 dashboards are allowed');
        return;
      }
      DashboardApi.addDashboard(
        state.asset.templateId,
        `New Dashboard ${nextDashboardName() + 1}`,
        context.appContext.orgId
      )
        .then((resp: any) => {
          disptachMethods.addDashboard(
            resp.data.data.dashboard,
            `New Dashboard ${nextDashboardName() + 1}`,
            'null',
            false
          );
        })
        .catch((error) => errorResponse(null, error));
    } else {
      //setShowPopConfirm(true);
    }
  };

  const onTitleUpdate = (dashboardId: number, title: string) => {
    DashboardApi.updateDashboard(state.asset.templateId, dashboardId, title)
      .then((resp: any) => {
        disptachMethods.updateDashboardTitle(resp.data.data.dashboard, title);
      })
      .catch((error) => errorResponse(null, error));
  };

  const permissionCallback = useCallback(
    (dashboardpermissions: any) => {
      const permissionResult = formatPermissions(dashboardpermissions);
      return permissionResult;
    },
    [state.asset.templateId]
  );

  const getDashboardPermissions = (roleId: number) => {
    PermissionApi.getPermissions(
      state.asset.templateId,
      roleId,
      context.appContext.orgId
    )
      .then((resp) => {
        if (
          resp &&
          resp.data &&
          resp.data.data &&
          resp.data.data.dashboardpermissions &&
          resp.data.data.dashboardpermissions.length > 0
        ) {
          const { dashboardPermissions, datasource } = permissionCallback(
            resp.data.data.dashboardpermissions
          );
          if (datasource.length > 0) {
            setDatasource(datasource);
          }
          if (dashboardPermissions.length > 0) {
            setDashboardPermissions(dashboardPermissions);
          }          
        }
        setToggleDrawer(true);
      })
      .catch((error) => errorResponse(null, error));
  };

  const onPermissionClick = () => {
    PermissionApi.getOrgRoles(context.appContext.orgId)
      .then((resp: any) => {
        const userRoles =
          (resp.data &&
            resp.data.data &&
            resp.data.data.orgRoles[0] &&
            resp.data.data.orgRoles) ||
          [];
        if (userRoles.length > 0) {
          setOrgRoles(userRoles);
          getDashboardPermissions(userRoles[0].roleId);
        }
      })
      .catch((error) => errorResponse(null, error));
  };

  const onRoleChange = (value: any) => {
    getDashboardPermissions(+value);
    setSaveSuccess(false);
  };

  const formatPermissionCallback = useCallback(
    (permissions: DashboardPermission[]) => {
      const permissionResult = constructPermissionToSave(permissions);
      return permissionResult;
    },
    [state.asset.templateId]
  );

  const handleDelete = () => {};

  const onFinish = (
    roleId: number,
    dashboardPermissions: DashboardPermission[]
  ) => {
    const permissionToSave = formatPermissionCallback(dashboardPermissions);
    PermissionApi.savePermissions(
      state.asset.templateId,
      roleId,
      permissionToSave,
      context.appContext.orgId
    )
      .then((resp) => {
        if (
          resp &&
          resp.data &&
          resp.data.data &&
          resp.data.data.dashboardpermissions &&
          resp.data.data.dashboardpermissions.length > 0
        ) {
          const { dashboardPermissions, datasource } = permissionCallback(
            resp.data.data.dashboardpermissions
          );
          if (datasource.length > 0) {
            setDatasource(datasource);
          }
          if (dashboardPermissions.length > 0) {
            setDashboardPermissions(dashboardPermissions);
          }
          setSaveSuccess(true);
        }
      })
      .catch((error) => errorResponse(null, error));
  };

  // This only load the default dashboard, and set other dahsboard's widget to null from the backend
  // this will reduce the number of signals for auto-refreshing if it is not the current open dashboard
  // The other dashboard's signalR list is triggered when clicking dashboard tab

  if (!defaultDashboardInitialized) {
    defaultDashboardInitialized = true;
    
    initialData.asset?.dashboards?.map((dashboard: any) => {
      // console.log("dashboard widget " + dashboard.title + 
      //  " type: ",dashboard?.widgets?.type)
      dashboard?.widgets?.map((widget: any) => {
        // console.log(widget);
        if (widget.type === "command" ) {
          // map the command's response signal to the signalR
          // since only the response signal is the telmetry data
          widget?.signals?.map((signal: any) => {
            Api.getCommandResponseSignalIds(state.asset.templateId, signal.signalId)
          .then((resp) => {
            if (resp?.data?.data?.respSignals?.length > 0) {
                signalsList.push({
                  signalId: resp.data.data.respSignals[0].responseSignalId,
                  name: resp.data.data.respSignals[0].responseSignalName,
                })
              }
            })
            .catch((error:any) => {
              message.error(error?.response?.data?.errors?.length > 0 ?
                error?.response?.data?.errors[0].message :
                "Error in receiving the latest command response signal info")
            });
          });
        } else {
          widget?.signals?.map((signal: any) => {
            if (!signalsList.some((o:any) => o.signalId == signal.signalId)) {
              signalsList.push({
                signalId: signal.signalId,
                name: signal.name,
              })
            }
          })
        }
        widget?.rightYAxisSignals?.map((signal: any) => {
          if (!signalsList.some((o:any) => o.signalId == signal.signalId)) {
            signalsList.push({
              signalId: signal.signalId,
              name: signal.name,
            })
          }
        })
      })
    })
    // console.log("initialData signalR list for default tab: ", signalsList);
  }

    
  useEffect(() => {

    if (!connection && signalRDataEnabled) {
      signalRFunction(signalsList);
    } else if (connection && signalRDataEnabled) {
      connection.start()
    }

    if (connection && !signalRDataEnabled) {
      // console.log('Disabling SignalR Connection :>> ', connection);
      connection?.stop()
    }
    
  }, [signalRDataEnabled]);


  useEffect(() => {

    if (!connection && signalRDataEnabled) {
      signalRFunction(signalsList);
    }

    if (connection) {
      return () => {
        // console.log('Closeing SignalR Connection :>> ', connection);
        resetSignalRData()
        disableSignalRData()
        connection?.stop()
      }
    }
    
  }, [connection]);

  const signalRFunction = async (signalsList: any) => {

    const signalR_negotiate_url = `${appConfigs.signalR.url}${appConfigs.signalR.code}&userId=${initialData.asset.mastertag}`;

    const { url: connectionUrl, accessToken } = await axios
    .get(`${signalR_negotiate_url}`)
      .then(({ data }) => data)
      .catch(console.error)
    
    const connection: any = new signalR.HubConnectionBuilder()
      .withUrl(connectionUrl, { accessTokenFactory: () => accessToken })
      .withAutomaticReconnect()
      // .withAutomaticReconnect({
      //   nextRetryDelayInMilliseconds: retryContext => {
      //       if (retryContext.elapsedMilliseconds < 60000) {
      //           // If we've been reconnecting for less than 60 seconds so far,
      //           // wait between 0 and 10 seconds before the next reconnect attempt.
      //           return Math.random() * 10000;
      //       } else {
      //           // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
      //           return null;
      //       }
      //   }
      // })
      // .configureLogging(signalR.LogLevel.Trace)
      .build()
    
    connection.on('widgetUpdate', (data: any) => {
      updateSignalRData(data, signalsList)
    })
    connection.onclose(function () {
      // console.log('SignalR Disconnected')
    })
    connection.onreconnecting((err: any) =>
      console.log('Error Reconnecting...  ', err)
    )

    connection.start()
      .then((res: any) => {
        // code to run on connection success
        // console.log('SignalR Connected Successfully');
      })
      .catch(console.error)
    
    setConnection(connection)

  }

  const { user: contextUser,appContext } = context;
  let access= JSON.parse(appContext.disabledFeaturesOfContextOrg);
  let assetRestricted = (access==null || (access && access.length===0)) ? true : false
  return (
    ((access==null || (access && access.length===0))
    && (!state.asset.blockedassetOrgId ? true : (contextUser.homeOrg.orgId !== state.asset.blockedassetOrgId? true:false)))?
      <>      
        { (moment(state.asset.contractEndDate, "L").diff(moment(), 'days') >= 0 && moment(state.asset.contractEndDate, "L").diff(moment(), 'days') <= 30) && 
            <div style={{color: 'red', fontSize: '15px', padding: '10px 10px 0px 20px'}}>
              <Alert
                closable
                message={
                  <span>Dear Customer,<br/> Your subscription for this asset will expire {
                    moment(state.asset.contractEndDate, "L").diff(moment(), 'hours') <= 0 ? 'today' : moment(state.asset.contractEndDate, "L").diff(moment(), 'hours') <= 24 ? 'tomorrow' : 'in ' + (moment(state.asset.contractEndDate, "L").diff(moment(), 'days') + 1) + ' day(s)'}. The subscription may be deactivated at any time after the expiry. Please renew the subscription as soon as possible to continue the service. Thank you!</span>
                }
                type="error"
              />
            </div>
        }

        { moment(state.asset.contractEndDate, "L").diff(moment(), 'days') < 0  &&
            <div style={{color: 'red', fontSize: '15px', padding: '10px 10px 0px 20px'}}>
              <Alert
                closable
                message={
                  <span>Dear Customer,<br/> Your subscription for this asset has expired and may be deactivated at any time. Please renew the subscription as soon as possible to continue using the service. Thank you!</span>
                }
                type="error"
              />
            </div>
        }
      
        {
          <AssetNotes assetDetails={state.asset} />
        }

        { state.asset.templateId ? 
          (<div data-id="dashboard-container" style={{marginTop: '-10px'}} className="layout-content">
            {/* <PageHeader
              style={{ padding: "0px 0px 0px 0px" }}
              title=""
              //subTitle={<DashboardHeader {...headerData} />}
              extra={<DashboardHeaderExtra {...headerData} />}
            ></PageHeader> */}

            <Tabs
              type="editable-card"
              id="tab"
              hideAdd={state.dashboards.length === 6 ? true: !permissionSet[0] ? true: false}       
              
              activeKey={state.activeDashboard}
              tabBarExtraContent={
                //create, update and delete
                state.dashboards &&
                state.dashboards.length > 0 &&
                permissionSet[0] &&
                permissionSet[2] &&
                permissionSet[3] && (
                  <DashboardExtra onPermissionClick={onPermissionClick} />
                )
              }
              // tabBarExtraContent={
              //   //create, update and delete
              //   (roleName === 'admin') && (
              //     //<DashboardExtra onPermissionClick={onPermissionClick} /> ||
              //     <></>
              //   )
              // }
              onEdit={onTabAdd}
              onTabClick={(activeKey, e) => onTabClick(activeKey, e)}
            >
              {state.dashboards &&
                state.dashboards.map((t: DashboardState, i: number) => {
                  return (
                    <TabPane
                      id={t.title}
                      closable={!t.isdefaultDashboard && permissionSet[3]}
                      closeIcon={
                        !t.isdefaultDashboard?  permissionSet[3] ? (<DashboardClosing
                          dashboardId={t.dashboardId}
                          onDashboardDelete={onDashboardDelete}
                        />) : <></> : <></>
                      }
                      // closeIcon={
                      //   !t.isdefaultDashboard? (roleName === 'admin') ? (<DashboardClosing
                      //     dashboardId={t.dashboardId}
                      //     onDashboardDelete={onDashboardDelete}
                      //   />) : <></> : <></>
                      // }
                      tab={
                        (permissionSet[2]) ? (
                          <DashboardTitleText
                            dashboadId={t.dashboardId}
                            title={t.title}
                            onTitleUpdate={onTitleUpdate}
                            permissionSet={permissionSet}
                          />
                        ) : (
                          t.title
                        ) 
                      }
                      key={t.dashboardId}
                    >
                      <AssetDashboard
                        // updateAssetPreferences={function (filterPreferences: any): void {
                        //   throw new Error("Function not implemented.");
                        // } } 
                        state={state}
                        key={t.dashboardId}
                        dashboardId={t.dashboardId}
                        bgImageUrlProp={t.bgimageurl}
                        isNewDashboardProp={t.isNewDashboard}
                        {...disptachMethods} {...userPreferenceMethods}                      />
                    </TabPane>
                  );
                })}
            </Tabs>
            {toggleDrawer && (
              <PermissionDrawer
                visible={toggleDrawer}
                onDrawerClose={() => setToggleDrawer(false)}
                width={drawerWidth}
                orgRoles={orgRoles}
                dashboardPermissions={dashboardPermissions}
                datasource={datasource}
                onRoleChange={onRoleChange}
                onFinish={onFinish}
                saveSuccess={saveSuccess}
              />
            )}
          </div>) : <Empty description="No template assigned"> </Empty> 
        }
      </>
    :<Title level={2}><div>
      <p>Access Denied.</p>
      {assetRestricted?<p>Asset Dashboard Viewing Disabled in Asset Setting.</p>:<p>Asset Dashboard Viewing Disabled in Org Setting.</p>}
      <p>Please contact Org Administrator.</p>
      </div></Title>
  
  );
};

const mapStateToProps = (state: any) => {
  return {
    context: state.contextReducer.context,
    signalRDataEnabled: state.contextReducer.signalRData.enabled,
    SignalRInitialData: state.contextReducer.signalRData.SignalRInitialData,
    dashboard: state.dashboardReducer.context,
    permissions: state.contextReducer.data.permissions,
    assetDetails: state.asset,
    userPreferences: state.contextReducer.context.user.uiPreferences,
    gpsCoordinates: state.contextReducer.gpsCoordinates
  };
};
export default connect(
  mapStateToProps,actions
)(InitDashboard);
