import React, { useContext, useState, useEffect, useRef } from "react";
import { Row, Col, Tooltip, Tabs, Button, Space, Table, Input, Popconfirm, Form, Select, message, DatePicker, Card, Popover, Tag } from "antd";
import { FormInstance } from 'antd/lib/form';
import BackNavigation from "../../utils/Components/BackNavigation";
import "./usercontrollers.css";
import history from '../../utils/history';
import { connect } from "react-redux";
import * as actions from "./actions";
import { InfoOutlined, PlusOutlined } from "@ant-design/icons";
import moment from 'moment';
import AppMaintInstallation from "./AppMaintInstallation";
import AppInstallationProgress from "./AppInstallationProgress";
import { appConfigs } from "../../utils/configurations";

const { Search } = Input;


interface Props{
    controllersSetSearch: Function,
    controllerSetPagination: Function,
    getGatewaysControllers: Function,
    openDrawer: Function,
    openInstallationProgressDrawer: Function,
    getAllControllersList: Function,
    updateControllerSelection: Function,
    setTab: Function,
    context: any,
    controllers: any,
    permissions: any,
    controllersModification: any,
    selectedController: any
}

const UsersControllers: React.FC<Props> = (props) => {

    const drawerWidth: number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);
    const [selectedFilters, setSelectedFilters] = useState<any>({});

    const onTabChange = (key:string) => {
        props.setTab(key)
    }

    const { tableConfig, tableData } = props.controllers.controllersList;

    useEffect(() => {
        // props.getAllControllersList()
        props.getGatewaysControllers(null, null, null, 1, props.controllers.controllersList.searchText, [{"status": null}])
        props.controllerSetPagination(onPaginationChange(1));
    }, [])

    const renderControllersTable = (pagination: any, filters: any, sorter: any, extra: any, e:any) => {
        setSelectedFilters(filters)
        props.getGatewaysControllers(null, null, null, pagination.current, props.controllers.controllersList.searchText, filters)
    }

    const onSearch = (searchText: string) => {   
        props.controllersSetSearch((searchText || ""));

        if((searchText || "")==="") {
            history.push("/controllers");
        }
        
        setTimeout(function() {
            props.getGatewaysControllers(null, null, null, 1, searchText, selectedFilters);   
        }, 100);      
    }

    const onSearchChange = (e: any) => {
        let value = e.target.value;
        props.controllersSetSearch((value || ""));
    }

    const onPaginationChange = (page: number) => {
        props.getGatewaysControllers(null, null, null, page, props.controllers.controllersList.searchText, selectedFilters);
    };

    const tableColumns = [
        {
            title: 'Asset Name',
            dataIndex: 'gatewayName',
        },
        {
            title: 'Mastertag',
            dataIndex: 'mastertag',
        },
        {
            title: 'Controller Name',
            dataIndex: 'controllerName',
        },
        {
            title: 'Make',
            dataIndex: 'make',
        },
        {
            title: "Model",
            dataIndex: "model",
        },
        {
            title: "Serial Number",
            dataIndex: "serialNumber",
        },
        {
            title: "Address",
            dataIndex: "address",
        },
        // {
        //     title: "Installed Applications",
        //     dataIndex: "installedApps",
        //     width: '18%',
        //     render: (text: string, record: any) => (
        //         record.installedApps.map((app: any, index: number) => {
        //             return (<><Tag style={{marginTop: "3px"}}>{app.name}-{app.version}</Tag><br/></>)
        //         })
        //     )
        // },
        {
            title: "Status",
            dataIndex: "status",
            filterMultiple: true,
            filteredValue: selectedFilters.hasOwnProperty('status') ? selectedFilters.status : [],
            filters: props.controllers.controllersList.columnsFilters.status?.map((status: any) => {
                return { 'value': status, 'text': status }
            }),
        },
        {
            title: "Actions",
            align: "center" as "center",
            render: (text: string, record: any) => (
                <Space>
                    {
                        <Tooltip title="View Installations">
                            <Button
                                type="default" 
                                icon={<InfoOutlined />}
                                onClick={(e) => { props.openInstallationProgressDrawer("Installations History for: (" + record.gatewayName + ' - ' + record.controllerName + ')', drawerWidth, record); }}
                            />
                        </Tooltip>
                    }
                </Space>
            )
        }
    ]

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            // console.log(selectedRows[0]);
            props.updateControllerSelection(selectedRows[0])
        }
    };


    return (

        <div className="layout-content">
            <Row gutter={8}>
                
                <Col xs={24} lg={14}>
                    <div className="content-header page-title">
                        <div style={{ display: "flex" }}>
                            <Tooltip title="Go To Previous Page"> 
                                <BackNavigation /> 
                            </Tooltip>                            
                            {
                                <span>Controllers</span>
                            }
                        </div>
                    </div>
                </Col>

            </Row>
            
            <div className="mt-15">
                <Row justify="end" gutter={24}>
                    
                    <Col xs={24} lg={24} className="content-header-controls">
                        <Space>
                            <Search
                                data-id="controller-search-field"
                                key="search-field"
                                placeholder="Search By Name, Make, Model, Tags"
                                enterButton="Search"
                                size="middle"
                                allowClear
                                onSearch={onSearch}
                                onChange={onSearchChange}
                                className="search-field"
                                value={props.controllers.controllersList.searchText}
                            />

                            <Button data-id="assets-search-new-button" disabled={props.selectedController == null} type="primary" icon={<PlusOutlined />} onClick={(e) =>  props.openDrawer("Install Application", drawerWidth, {})}>
                                <span>App Installation</span>
                            </Button>
                        </Space>
                    </Col>

                    
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Table 
                            rowSelection={{
                                type: 'radio',
                                ...rowSelection,
                            }}
                            data-id="controllers-data" 
                            className="mt-15"
                            style={{ overflowX: 'auto' }} 
                            {...tableConfig}
                            rowKey={(record) => record.id}
                            columns={tableColumns}
                            dataSource={tableData}
                            onChange={renderControllersTable}
                            showSorterTooltip={false}
                        />
                    </Col>       
                </Row>
                    
            </div>

            <AppMaintInstallation/>
            <AppInstallationProgress/>
        </div>

    );

}

const mapStateToProps = (state:any) => {
    return {
        permissions: state.contextReducer.data,
        context: state.contextReducer.context,
        controllers: state.userControllersReducers.userControllersState.controllers,
        controllersModification: state.userControllersReducers.userControllersState.controllers.controllersModification,
        selectedController: state.userControllersReducers.userControllersState.controllers.selectedController
    };
};

export default connect(
    mapStateToProps,
    actions
)(UsersControllers);
