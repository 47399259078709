import React, { useState } from "react";
import { Drawer, Button } from "antd";
import { WidgetExportConfig } from ".";
import Event from "../../../lib/event";
import ExportFormContainer from "./ExportFormContainer";
import { appConfigs } from "../../../utils/configurations";

interface Props {
  config?: WidgetExportConfig;
  onFinish: (wfg: WidgetExportConfig) => void;
  title: string;
  visible: boolean;
  onClose: () => void;
}

const WidgetExportDrawer: React.FC<Props> = (props) => {
  const [submitter] = useState(new Event());
  const drawerWidth: number = Math.min(
    window.innerWidth,
    appConfigs.settings.drawers.defaultWidth
  );

  return (
    <Drawer
      width={drawerWidth}
      placement="right"
      {...props}
      footer={
        <Button
          type="primary"
          htmlType="button"
          onClick={() => submitter.trigger()}
        >
          Save
        </Button>
      }
      onClose={props.onClose}
      destroyOnClose
    >
      <ExportFormContainer
        {...props}
        onFinish={(cfg) => {
          props.onFinish(cfg);
          props.onClose();
        }}
        submitTrigger={submitter}
      />
    </Drawer>
  );
};

export default WidgetExportDrawer;
