import { CommonWidgetConfig } from '../../models';
import { WidgetDetails } from '../models';
import Widget from './Widget'
import EditForm from './EditForm';

export type Config = CommonWidgetConfig & { type: "command" };

export const details: WidgetDetails = {
    type: "command",
    name: "Command Widget"
  }
  
  export { EditForm, Widget }