import React from "react";
import { Drawer, Button, Space } from "antd";

import { connect } from "react-redux";
import * as actions from "./actions/reportSchedules";

import { appConfigs } from "../../utils/configurations";
import ScheduleMaintInfo from "./scheduleMaintInfo/ScheduleMaintInfo";

interface Prop {
    scheduledList: any,
    scheduleMaintClose: Function
}

class SchedulesMaint extends React.Component<Prop, {}> {

    onDrawerClose = () => {
        this.props.scheduleMaintClose(this);
    };

    render() {

        const { scheduledMaint } = this.props.scheduledList;

        return (
            <Drawer
                data-id="schedule-maint-drawer"
                title={scheduledMaint.title}
                width={scheduledMaint.width}
                onClose={this.onDrawerClose}
                visible={scheduledMaint.visible}
                destroyOnClose={true}
                footer={
                    <Space size="large" style={{ float: 'right' }}>
                        <Button data-id="schedule-maint-close" type="default" onClick={this.onDrawerClose}>
                            {appConfigs.settings.drawers.closeButtonText}
                        </Button>

                        <Button data-id="schedule-maint-submit" type="primary" form="scheduleMaintInfoForm" key="submit" htmlType="submit">
                            {appConfigs.settings.form.submitButtonText}
                        </Button>

                    </Space>
                }
            >
                {
                    <ScheduleMaintInfo />
                }

            </Drawer>
        );
    }

}

const mapStateToProps = (state: any) => {
    return {
        context: state.contextReducer.context,
        scheduledList: state.reportSchedulesReducers.reportScheduledState.scheduledList
    };
};

export default connect(
    mapStateToProps,
    actions
)(SchedulesMaint);