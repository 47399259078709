import CriticalPin from "../../assets/images/map-pins/critical-pin.svg";
import Dm1Pin from "../../assets/images/map-pins/dm1-pin.svg";
import WarningPin from "../../assets/images/map-pins/warning-pin.svg";
import InfoPin from "../../assets/images/map-pins/info-pin.svg";
import OnlinePin from "../../assets/images/map-pins/online-pin.svg";
import OfflinePin from "../../assets/images/map-pins/offline-pin.svg";
import AssetDownPin from "../../assets/images/map-pins/asset_down.svg";
import ServiceTruckPin from "../../assets/images/map-pins/servicetruck-pin.svg";
import { AssetOverview } from './models';

export { CriticalPin, Dm1Pin, WarningPin, InfoPin, OnlinePin, OfflinePin };

export function getPin(asset: AssetOverview): string {
    if (asset.recentCriticalCount > 0) return CriticalPin;
    else if (asset.recentDm1Count > 0) return Dm1Pin;
    else if (asset.recentWarningCount > 0) return WarningPin;
    else if (asset.recentInfoCount > 0) return InfoPin;
    else if (asset.connectionStatus === "online") return OnlinePin;
    else if (asset.assetCustomFields?.includes(`"additional_info":"active"`) > 0) return AssetDownPin;
    else return OfflinePin;
  }

export function getGeotabPin():string {
  return ServiceTruckPin;
}