import React from "react";
import { Form } from "antd";

import { Signal } from "../../models";
import { SelectorList } from "../../WidgetEditForm";

interface Props {
  max?: number;
  signals: Signal[];
}

const LocationSignalSelectorList: React.FC<Props> = (props) => {
  let signalArray: Signal[];
  signalArray =
    props.signals.filter(
      (s) =>
        s.name.toLowerCase().indexOf("longitude") !== -1  ||
        s.name.toLowerCase().indexOf("latitude") !== -1 
    ) || [];
  const selectable = signalArray.map((s) => ({
    value: s.id,
    label: s.name,
  }));

  return (
    <Form.Item
      label="Signals"
      name="signals"
      required
      rules={[
        {
          validator(rule, value) {
            return !value || value.filter((v: any) => !!v).length < 2
              ? Promise.reject("At least two valid signal must be specified")
              : Promise.resolve();
          },
        },
      ]}
    >
      <SelectorList
        options={selectable}
        maxSelected={props.max}
        valueLabel="Signal"
      />
    </Form.Item>
  );
};

export default LocationSignalSelectorList;
