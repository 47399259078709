import React, { Component } from "react";
import { Table, Button, Space, message, notification, Switch, InputNumber, Tooltip, Collapse, Row, Col, Radio } from "antd";
import { PlusOutlined, DeleteOutlined, ExportOutlined, EditOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { appConfigs } from "../../../utils/configurations";
import history from '../../../utils/history';
import { uuidv4, closePopover, getPermissions } from "../../../utils/commonHelpers";

import Dm1Messaging from "./Dm1Messaging";
import Dm1UploadFile from "./Dm1UploadFile";

import { connect } from "react-redux";
import * as actions from "../actions";
//import { CSVLink } from "react-csv";

import Dm1Lists from "./Dm1Lists";

import "./dm1Faults.css";
//import { getPermissions } from "../../../actions/permissions";
const { Panel } = Collapse;

interface Prop {
    templateDetails: any,
    iscreate: boolean, 
    isread: boolean,
    isupdate: boolean,
    isdelete: boolean,    
    context: any
}


interface State {
    editMode: boolean,
    csvData: any,
    csvFileName: string,
}

class Dm1Faults extends Component<Prop, State> {

   render() {    

        
        const { templateRecord } = this.props.templateDetails;
           
        return (
            <div data-id="template-dm1-faults">
                {
                    ((templateRecord.templateVisibleToSubOrg 
                        && (this.props.context.appContext.roleName.toLowerCase() === "admin" || 
                            this.props.iscreate || this.props.isread || this.props.isupdate))
                     || (!templateRecord.templateVisibleToSubOrg && (this.props.iscreate || this.props.isupdate || this.props.isread))) &&
                    <Dm1Messaging iscreate={this.props.iscreate} isread={this.props.isread} isupdate={this.props.isupdate}/>
                }               
                
                <Dm1Lists iscreate={this.props.iscreate} isread={this.props.isread} isupdate={this.props.isupdate} isdelete={this.props.isdelete} dm1ListType="Whitelist"/>
                <Dm1Lists iscreate={this.props.iscreate} isread={this.props.isread} isupdate={this.props.isupdate} isdelete={this.props.isdelete} dm1ListType="Blacklist"/>

            </div>
            
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        dm1Faults: state.templateDetailsReducers.templateDetailsState.tabs[1],
        templateDetails: state.templateDetailsReducers.templateDetailsState
    };
};

export default connect(
    mapStateToProps,
    actions
)(Dm1Faults);
  