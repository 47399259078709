import { ClusterIconStyle } from "@google/markerclustererplus";

export enum Status {
    normal = 0,
    info = 1,
    warning = 2,
    dm1 = 3,
    critical = 4,
    asset_down = 5
}

function encode(s: string): string {
    const encoded = window.btoa(s);

    return `data:image/svg+xml;base64, ${encoded}`;
}

const COLOR_PROPS = {
    [Status.info]: {
        stroke: "#105AE6",
        fill: "#0F62FE"
    },
    [Status.normal]: {
        stroke: "#209846",
        fill: "#24A148"
    },
    [Status.warning]: {
        stroke: "#E47E27",
        fill: "#ecc800"
    },
    [Status.critical]: {
        stroke: "#A01A21",
        fill: "#DA1E28"
    },
    [Status.dm1]: {
        stroke: "#BDA539",
        fill: "#ff9900"
    },
    [Status.asset_down]: {
        stroke: "#7725b9",
        fill: "#a240e3"
    },
};

const COLORS = Object.keys(COLOR_PROPS).map(Number);

const SIZE_PROPS = {
    small: {
        outer: 27,
        inner: 20
    },
    medium: {
        outer: 30,
        inner: 23
    },
    large: {
        outer: 33,
        inner: 26
    }
};

const SIZES = Object.keys(SIZE_PROPS);
const N_SIZES = SIZES.length;

export type Color = keyof typeof COLOR_PROPS;
export type Size = keyof typeof SIZE_PROPS;

function clusterIcon(color: Color, size: Size): ClusterIconStyle {

    const { outer, inner } = SIZE_PROPS[size];
    const imageSize = outer * 2;

    const { stroke, fill } = COLOR_PROPS[color];

    const url = encode(`<?xml version="1.0" encoding="UTF-8"?>
  <svg width="${imageSize}px" height="${imageSize}px" viewBox="0 0 ${imageSize} ${imageSize}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g id="Maps" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g fill="${fill}">
              <circle id="Oval" opacity="0.5" cx="${outer}" cy="${outer}" r="${outer}"></circle>
              <circle id="Oval" stroke="${stroke}" stroke-width="2" cx="${outer}" cy="${outer}" r="${inner}"></circle>
          </g>
      </g>
  </svg>`);

    return {
        url,
        height: imageSize,
        width: imageSize,
        fontWeight: "bold",
        textLineHeight: imageSize,
        textSize: 11,
        backgroundPosition: "0 0"
    };
}

export function computeStyleIndex(color: Color, size: Size): number {
    const index1 = COLORS.indexOf(color);
    const index2 = SIZES.indexOf(size);

    const result = index1 * N_SIZES + index2;
    return result;
}

function generateStyles() {
    let result: ClusterIconStyle[] = [];

    for (const color of COLORS) {
        for (const size of SIZES) {
            const index = computeStyleIndex(color as Color, size as Size);
            const icon = clusterIcon(color as Color, size as Size);
            result[index] = icon;
        }
    }
    return result;
}

const STYLES = generateStyles();

export default STYLES;
