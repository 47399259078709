import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function orgSelectorUserOrgSelectionGet(
    me: any, 
    callbackFnSuccess?: any,
    callbackFnFailure?: any
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/users/orgs/selection", {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.orgSelection.selections);
                }                
                dispatch({ type: "ORG_SELECTOR_SET_INCLUDE_SUBORGS", includeSuborgs: response.data.data.orgSelection.includeSuborgs, updateState: false});
                dispatch({ type: "ORG_SELECTOR_SET_ORG_SELECTION", data: response.data.data.orgSelection.selections });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error);
            }
            errorResponse(me, error);
        });
    };
}

export function orgSelectorHeaderTagRemove(
    me: any,
    orgId: string
) {
    return (dispatch: any) => {
        dispatch({ type: "ORG_SELECTOR_HEADER_TAG_REMOVE", orgId: orgId });
    };
}

export function orgSelectorHeaderSetDisplay(
    me: any,
    tab: string
) {
    return (dispatch: any) => {
        dispatch({ type: "ORG_SELECTOR_HEADER_SET_DISPLAY" });
    };
}

export function orgSelectorRemoveSelections(
    me: any, 
    callbackFnSuccess?: any,
    callbackFnFailure?: any
) {
    return async (dispatch: any) => {
        Axios.delete(
            appConfigs.server.URL + "/ui/api/users/orgs/selection",
            {}
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess();           
                }
                dispatch({ type: "ORG_SELECTOR_REMOVE_SELECTIONS_SUCCESS" });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

export function orgSelectorIncludeSuborgs(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    includeSuborgs: boolean
) {
    return async (dispatch: any) => {
        Axios.put(
            appConfigs.server.URL + 
                "/ui/api/users/orgs/selection" + 
                "?includeSuborgs=" + includeSuborgs.toString(),
            {},
            {
                responseType: "json"
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(includeSuborgs);           
                }
                dispatch({ type: "ORG_SELECTOR_SET_INCLUDE_SUBORGS", includeSuborgs: includeSuborgs, updateState: true });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error, includeSuborgs);
                }
            }
        );
    }
}
