import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function dm1FaultsSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "TEMPLATE_DETAILS_DM1_FAULTS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}   

export function dm1FaultsSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "TEMPLATE_DETAILS_DM1_FAULTS_SET_SEARCH", searchText: searchText });
    }
}   

export function getDm1Faults(
    me: any, 
    listType: string,
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string,
    page: number = 1,
    searchText?: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/templates/" + templateId + "/dm1-faults", {
                responseType: "json",        
                headers: {},
                params: {
                    listType: listType,
                    searchText: searchText,
                    page: page,
                    pageSize: 999
                }
            }
        )
        .then(response => {          
                let data = response.data.data.dm1Faults == null || (response.data.data.dm1Faults.length || 0) === 0 ? [] : response.data.data.dm1Faults;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "TEMPLATE_DETAILS_DM1_FAULTS_GET_SUCCESS", payload: {data: data, page: page, listIndex: (listType=='whitelist')?0:1}});
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function dm1MessagingMaint(
    me: any, 
    callbackSuccessFn: any,
    callbackErrorFn: any,
    record: any
) {
    return async (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL + "/ui/api/templates/" + record.templateId + "/dm1-messaging",
            {
                templateId: record.templateId,
                dm1MessagingCan0Enabled: record.dm1MessagingCan0Enabled,
                dm1MessagingCan0UpdateInterval: record.dm1MessagingCan0UpdateInterval,
                dm1MessagingCan1Enabled: record.dm1MessagingCan1Enabled,
                dm1MessagingCan1UpdateInterval: record.dm1MessagingCan1UpdateInterval,
                dm1WhitelistType: record.dm1WhitelistType,
                dm1NotificationDelaySeconds: record.dm1NotificationDelaySeconds,
                maxDayDm1: record.maxDayDm1
            },
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
                let record = response.data.data.templates == null || (response.data.data.templates.length || 0) === 0 ? {} : response.data.data.templates[0];
                if (callbackSuccessFn != null) {
                    callbackSuccessFn(record);
                }
                dispatch({ type: "TEMPLATE_DETAILS_DM1_MESSAGING_MAINT_SUCCESS", record });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackErrorFn != null) {
                    callbackErrorFn();
                }
            }
        );
        
    };
}

export function dm1FaultsDelete(
    me: any, 
    listType: string,
    index: number
) {
    return (dispatch: any) => {
        dispatch({ type: "DM1_FAULTS_DELETE", index: index , listIndex:  (listType=='whitelist')?0:1 });
    }
}

export function dm1FaultsDbDelete(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string,
    listType: string,
    dm1Id: string
) {
    return async (dispatch: any) => {
        Axios.delete(
            appConfigs.server.URL + "/ui/api/templates/" + templateId + "/dm1-faults/" + dm1Id+"/list-type/"+listType,
            {}
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(dm1Id);           
                }
                dispatch({ type: "DM1_FAULTS_DB_DELETE_SUCCESS", dm1Id: dm1Id , listIndex: (listType=='whitelist')?0:1 });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

export function dm1FaultsAddRecord(
    me: any,
    listType: string
) {
    return async (dispatch: any) => {
        dispatch({ type: "DM1_FAULTS_ADD_RECORD",  payload: {listIndex: (listType=='whitelist')?0:1} });           
    }   
}

export function dm1FaultsAddRecords(
    me: any,
    listType: string,
    data: any
) {
    return async (dispatch: any) => {
        dispatch({ type: "DM1_FAULTS_ADD_RECORDS", data: data, listIndex: (listType=='whitelist')?0:1});           
    }   
}

export function dm1FaultsSetFieldValue(
    me: any,
    listType: string,
    index: number,
    fieldName: string,
    fieldValue: any
) {
    return async (dispatch: any) => {
        dispatch({ type: "DM1_FAULTS_SET_FIELD_VALUE", listIndex: (listType=='whitelist')?0:1,index: index, fieldName: fieldName, fieldValue: fieldValue});           
    }   
}

export function dm1FaultsMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackErrorFn: any,
    templateId: string,
    listType: string,
    data: any
) {
    return async (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL + "/ui/api/templates/" + templateId + "/dm1-faults",
            {
                templateId: templateId,
                dm1Faults: data
            },
            {
                responseType: "json",       
                headers: {},
                params: {
                    listType: listType
                }
            }
        )
        .then(response => {
                let data = response.data.data.dm1Faults == null || (response.data.data.dm1Faults.length || 0) === 0 ? [] : response.data.data.dm1Faults;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "TEMPLATE_DETAILS_DM1_FAULTS_GET_SUCCESS", payload: {data: data, page: 1, listIndex: (listType=='whitelist')?0:1} });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackErrorFn != null) {
                    callbackErrorFn();
                }
            }
        );
        
    };
}

//feature can track when csv file is imported
// export function dm1FaultsSetImportFlag(
//     me: any,
//     listType: string,
//     val: boolean 
// ) {
//     return (dispatch: any) => {
//         dispatch({ type: "TEMPLATE_DETAILS_DM1_FAULTS_SET_IMPORT_FLAG", payload: {val: val, listIndex: (listType=='whitelist')?0:1 } });
//     }
// }  
