import Axios from "axios";
import { appConfigs } from "../utils/configurations";
import { errorResponse } from "../utils/apiHelpers/apiHelpers";

export function contextGet(
    me?: any,
    callbackSuccessFn?: any,
    callbackErrorFn?: any
) {
    return async (dispatch: any) => {
        await Axios.get(
            appConfigs.server.URL + "/ui/api/context", {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {                            
                if (callbackSuccessFn != null) {                    
                    callbackSuccessFn(response.data.data.context);                    
                }
                dispatch({ type: "CONTEXT_SUCCESS", payload: {data: response.data.data.context} });
                dispatch({ type: "ORG_SELECTOR_SET_INCLUDE_SUBORGS", includeSuborgs: response.data.data.context.orgSelection.includeSuborgs, updateState: false});
                dispatch({ type: "ORG_SELECTOR_SET_ORG_SELECTION", data: response.data.data.context.orgSelection.selections });
            }
        )
        .catch(function (error) {                
                errorResponse(me, error, false);
                if (callbackErrorFn != null) {
                    callbackErrorFn(error);
                }
                dispatch({ type: "CONTEXT_FAILURE", error: error });
            }
        );
    };
}

export function contextUpdateGPSCoordinates(
    coordinates: any 
) { 
    return (dispatch: any) => {
        dispatch({ type: "CONTEXT_UPDATE_GPS_COORDINATES", coordinates: coordinates });
    }
}

export function contextUpdateMapDefaultView(
    mapDefaultView: string 
) {    
    return (dispatch: any) => {
        dispatch({ type: "CONTEXT_UPDATE_MAP_DEFAULT_VIEW", mapDefaultView: mapDefaultView });
    }
}

export function permissionsGet(
    me?: any,
    callbackSuccessFn?: any,       
    callbackErrorFn?: any
) {  
    
    return async (dispatch: any) => {
        await Axios.get(           
            appConfigs.server.URL + "/ui/api/context/permissions", {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
           
            if (callbackSuccessFn != null) {                    
                callbackSuccessFn(response.data.data);                    
            }           
            dispatch({ type: "PERMISSIONS_SUCCESS", payload: {data: response.data.data} });
        }
        )
        .catch(function (error) {                
                errorResponse(me, error, false);
                if (callbackErrorFn != null) {
                    callbackErrorFn(error);
                }
                dispatch({ type: "PERMISSIONS_FAILURE", error: error });
            }
        );
    };
}


export function maxCommandPermissionValueGet(
    me?: any,
    callbackSuccessFn?: any,       
    callbackErrorFn?: any
) {  
    
    return async (dispatch: any) => {
        await Axios.get(           
            appConfigs.server.URL + "/ui/api/context/max-command-permission-value", {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
            if (callbackSuccessFn != null) {                    
                callbackSuccessFn(response.data.data);                    
            }           
            dispatch({ type: "MAX_COMMAND_PERMISSION_SUCCESS", payload: {data: response.data.data} });
        }
        )
        .catch(function (error) {                
                errorResponse(me, error, false);
                if (callbackErrorFn != null) {
                    callbackErrorFn(error);
                }
                dispatch({ type: "PERMISSIONS_FAILURE", error: error });
            }
        );
    };
}


export function getQuickStats(
    me?: any,
    callbackSuccessFn?: any,       
    callbackErrorFn?: any
) {  
    
    return async (dispatch: any) => {
        await Axios.get(           
            appConfigs.server.URL + "/ui/api/get_quick_stats", {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
            if (callbackSuccessFn != null) {                    
                callbackSuccessFn(response.data.data.quickStats);                    
            }           
            dispatch({ type: "STATS_SUCCESS", payload: {data: response.data.data.quickStats} });
        }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackErrorFn != null) {
                    callbackErrorFn(error);
                }
                dispatch({ type: "STATS_FAILURE", error: error });
            }
        );
    };
}

export function fleetStatsVisibility(
    visible: boolean,
) {
    return (dispatch: any) => {
        dispatch({ type: "SET_FLEET_STATS_VISIBILITY", visible: visible });
    }
}

export function notificationVisibility(
    visible: boolean,
) {
    return (dispatch: any) => {
        dispatch({ type: "SET_NOTIFICATION_VISIBILITY", visible: visible });
    }
}


export function updateDm1DailyCount(
    mastertag: string,
    dailyCountGetFailure: any
) {
    return async (dispatch: any) => {
        await Axios.get(           
            appConfigs.server.URL + "/ui/api/get_daily_dm1_count/"+mastertag, {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
            if (response.data.data.assetDailyDm1Count != null) {
                dispatch({ type: "SET_DM1_DAILY_COUNT", payload: { dailyDm1Count: response.data.data.assetDailyDm1Count } });
            } else {
                dispatch({ type: "RESET_DM1_DAILY_COUNT" });
            }
        })
        .catch(function (error) {
            dailyCountGetFailure(error);
            dispatch({ type: "RESET_DM1_DAILY_COUNT" });
        }
    );
    };
}


export function updateDm1HeatMap(
    mastertag: string,
    getFailure: any
) {
    return async (dispatch: any) => {
        await Axios.get(           
            appConfigs.server.URL + "/ui/api/get_dm1_heatmap/"+mastertag, {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
            if (response.data.data.dm1HeatMapData != null) {
                dispatch({ type: "SET_DM1_HEATMAP", payload: { dm1HeatMapData: response.data.data.dm1HeatMapData } });
            } else {
                dispatch({ type: "RESET_DM1_HEATMAP" });
            }
        })
        .catch(function (error) {
            getFailure(error);
            dispatch({ type: "RESET_DM1_HEATMAP" });
        }
    );
    };
}


export function getAssetsWithDMs(
    getFailureMessage: any
) {
    return async (dispatch: any) => {
        await Axios.get(
            appConfigs.server.URL + "/ui/api/get_assets_with_dm1", {
                responseType: "json",
                headers: {},
                params: {}
            }
        )
        .then(response => {
            if (response.data.data.assetsWithDm1 != null) {
                dispatch({ type: "SET_STATS_DM1_ASSETS_LIST", payload: { assetsWithDm1: response.data.data.assetsWithDm1 } });
            } else {
                dispatch({ type: "RESET_STATS_DM1_ASSETS_LIST" });
            }
        })
        .catch(function (error) {
            getFailureMessage(error);
            dispatch({ type: "RESET_STATS_DM1_ASSETS_LIST" });
        }
    );
    };
}


export function fleetStatsInitialization(
    initialized: boolean,
) {
    return (dispatch: any) => {
        dispatch({ type: "SET_FLEET_STATS_VISIBILITY", initialized: initialized });
    }
}

export function notificationInitialization(
    initialized: boolean,
) {
    return (dispatch: any) => {
        dispatch({ type: "SET_NOTIFICATION_VISIBILITY", initialized: initialized });
    }
}

export function notificationsLoading(
    initialized: boolean,
) {
    return (dispatch: any) => {
        dispatch({ type: "SET_NOTIFICATION_LOADING", initialized: initialized });
    }
}

export function fleetStatsLoading(
    loading: boolean,
) {
    return (dispatch: any) => {
        dispatch({ type: "SET_FLEET_STATS_LOADING", loading: loading });
    }
}

export function enableSignalRData() {
    return (dispatch: any) => {
        dispatch({ type: "ENABLE_SIGNAL_R_DATA" });
    }
}

export function disableSignalRData() {
    return (dispatch: any) => {
        dispatch({ type: "DISABLE_SIGNAL_R_DATA" });
    }
}

export function updateSignalRData(
    liveData: any,
    dashboardSignals: any
) {
    return (dispatch: any) => {
        dispatch({
            type: "UPDATE_SIGNAL_R_DATA",
            payload: {
                liveData: liveData,
                dashboardSignals: dashboardSignals,
            }
        });
    }
}

export function resetSignalRData() {
    return (dispatch: any) => {
        dispatch({ type: "RESET_SIGNAL_R_DATA" });
    }
}
