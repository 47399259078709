import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function templatesSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "TEMPLATES_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}   

export function templatesSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "TEMPLATES_SET_SEARCH", searchText: searchText });
    }
}   

export function getTemplate(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string
) {
    return async (dispatch: any) => {
        if(templateId){
        Axios.get(
            appConfigs.server.URL + "/ui/api/templates/" + templateId, {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
            let data = response.data.data.templates == null || (response.data.data.templates.length || 0) === 0 ? {} : response.data.data.templates[0];
            dispatch({ type: "TEMPLATE_DETAILS_SUCCESS", payload: {data: data}});
                if (callbackFnSuccess != null) {                    
                    callbackFnSuccess(me, data);
                }
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error);
            }
            errorResponse(me, error);
        });
    }
    else
    {
        dispatch({ type: "TEMPLATE_DETAILS_SUCCESS", payload: {data: null}});
    }
    }
}

export function getTemplates(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    page: number = 1,
    mode: string,
    searchText?: string,
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/templates", {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText,
                    mode: mode,
                    page: page,
                    pageSize: appConfigs.tables.pageSize
                }
            }
        )
        .then(response => {     
                let data = response.data.data.templates == null || (response.data.data.templates.length || 0) === 0 ? [] : response.data.data.templates;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "TEMPLATES_GET_TEMPLATES_SUCCESS", payload: {data: data, page: page} }); 
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function templatesListRefreshOnUpdate(
    me: any
) {
    return (dispatch: any) => {
        dispatch({ type: "TEMPLATES_LIST_REFRESH_ON_UPDATE" });
    };
}

export function templateMaintOpen(
    me: any, 
    mode: string,
    title: string,
    width: number,
    record: any
) {
    return (dispatch: any) => {
        dispatch({ type: "TEMPLATES_TEMPLATE_MAINT_OPEN", payload: {mode: mode, title: title, width: width, record: record} });
    };
}

export function templateMaintClose(
    me: any
) {   
    return (dispatch: any) => {
        dispatch({ type: "TEMPLATES_TEMPLATE_MAINT_CLOSE" });
    };
}

export function templateSetGlobalMode(
    mode: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "TEMPLATE_SET_GLOBAL_MODE", mode: mode });
    }
}

export function getTemplatesForCopyCollection(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,  
    orgId: string,
    searchText?: string   
) {
    return async (dispatch: any) => {
        if(orgId){
        Axios.get(
            appConfigs.server.URL + "/ui/api/getTemplatesbyOrgId", {
                responseType: "json",        
                headers: {},
                params: {
                    orgId: orgId,
                    searchText: searchText,
                    page: 1,
                    pageSize: 9999
                }
            }
        )
        .then(response => {          
                let data = response.data.data.templates == null || (response.data.data.templates.length || 0) === 0 ? [] : response.data.data.templates;
                dispatch({ type: "GET_TEMPLATES_SUCCESS", payload: {data: data} });
                dispatch({ type: "TEMPLATE_DETAILS_SUCCESS", payload: {data: null}});
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(me,data);
                }               
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    }
    else
    {
        dispatch({ type: "GET_TEMPLATES_SUCCESS", payload: {data: null} });
        dispatch({ type: "TEMPLATE_DETAILS_SUCCESS", payload: {data: null}});
    }
}
}
