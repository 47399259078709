import React, { useRef, useLayoutEffect } from "react";
import LineChart, { Options } from "./LineChart";
import useDomNode from "../../../../lib/use-dom-node";
import { CommonWidgetProps, LineWidgetProps, WidgetFilterSetting, WidgetSignalId } from "../../models";
import { inputSignalMapper } from "../common";
import { Empty } from "antd";
import { connect } from "react-redux";
import * as actions from "../../../../actions";

const createSignalSeries = inputSignalMapper((meta, data, userPreference) => ({
  ...meta,
  values: data,
  userPreference,
}));

interface LineChartProps extends CommonWidgetProps,LineWidgetProps{
  signalRData?: any;
  settings?: Options;
  errorCode?: string;
  timescaleAPIZooming?: Function;
  widgetFilterSetting?: WidgetFilterSetting;
}

const Widget: React.FC<LineChartProps> = (props) => {
  const chartRef = useRef<LineChart>();
  const [node, setNode] = useDomNode<HTMLDivElement>();

  const { template, data, signals, settings, rightYAxisSignals, rightYAxisData, signalRData, state } = props;

  // console.log("Line Char data")
  // console.log(props)
  // console.log(template)
  // console.log(signals)
  // console.log(data)
  
  let leftAxis = createSignalSeries(signals, template, data,state.temperature.value)
  let rightAxis = createSignalSeries((rightYAxisSignals || []), template, rightYAxisData,state.temperature.value)
    
  /* updating leftAxis with live value if present (SignalR) */
  if (Object.keys(props.signalRData.data).length > 0) {
    leftAxis = leftAxis.map((signal: any) => {
      if (props.signalRData.data.hasOwnProperty(signal.id)) {
        props.signalRData.data[signal.id].slice().reverse().forEach((signalData: any, index: number) => {
          signal.values.push({
            timestamp: new Date(signalData.bt - signalData.time),
            value: signalData.value
          })
        });
      }
      return signal
    })
  }

  // /* updating rightAxis with live value if present (SignalR) */
  if (Object.keys(props.signalRData.data).length > 0) {
    rightAxis = rightAxis.map((signal: any) => {
      if (props.signalRData.data.hasOwnProperty(signal.id)) {
        props.signalRData.data[signal.id].slice().reverse().forEach((signalData: any, index: number) => {
          signal.values.push({
            timestamp: new Date(signalData.bt - signalData.time),
            value: signalData.value
          })
        });
      }
      return signal
    })
  }

  useLayoutEffect(() => {
    if (node) {
      chartRef.current = new LineChart(
        node,
        leftAxis,
        rightAxis,
        settings,
        props.timescaleAPIZooming,
        state,
        props.widgetFilterSetting
      );
      return () => chartRef.current?.destroy();
    }
  }, [node, signals, template, settings, data, rightYAxisSignals, rightYAxisData, leftAxis, rightAxis, props.signalRData.data]);

  if (props.errorCode === "404") {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else if (props.errorCode === "500") {
    return <Empty description="signal Id is null" />;
  } else {
    return <div style={{ height: "100%" }} ref={setNode} />;
  }
};

// export default Widget;
const mapStateToProps = (state: any) => {
  return {
    signalRData: state.contextReducer.signalRData,
  };
};
export default connect(
  mapStateToProps,
  // actions
)(Widget);
