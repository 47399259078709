import React from "react";
import { Form, Select, Row, Col, InputNumber, Card } from "antd";

import {
  EditFormProps,
  Container,
  WidgetTitleFormField,
} from "../../WidgetEditForm";
import { Config } from ".";
import { SignalId } from "../../models";

type Fields = Omit<Config, "signals"> & { signal: SignalId };

function sanitizeInputs(f: Fields): Config {
  return {
    title: f.title,
    settings: f.settings,
    type: "gauge",
    signals: [f.signal],
  };
}

function populateForm(c?: Config): Fields | undefined {
  if (!c) return c;

  return {
    title: c.title,
    type: "gauge",
    settings: c.settings,
    signal: c.signals[0],
  };
}

const EditForm: React.FC<EditFormProps> = (props) => {
  const selectable = props.template.signals.map((s) => ({
    value: s.id,
    label: s.name,
  }));

  return (
    <Form
      onFinish={(values) => props.onFinish(sanitizeInputs(values as Fields))}
      layout="vertical"
      form={props.form}
      initialValues={populateForm(props.config as Config)}
    >
      <Card title="Gauge Widget Settings">
        <WidgetTitleFormField />
        <Row justify="space-between">
          <Col span={10}>
            <Form.Item name={["settings", "min"]} label="Min Value">
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name={["settings", "max"]} label="Max Value">
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Signal" name="signal">
          <Select options={selectable} />
        </Form.Item>
      </Card>
    </Form>
  );
};

export default EditForm;
