import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function countriesReducer(state = initialState, action:any) {
    let data:any = null;

    switch (action.type) {    
        case "COUNTRIES_SET_PAGINATION":
            return immerProduce(state, draft => {
                draft.tableConfig.pagination.onChange = action.onPaginationChange;
            });
        case "COUNTRIES_SET_SEARCH":
            return immerProduce(state, draft => {
                draft.searchText = action.searchText;
            });
        case "COUNTRIES_GET_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.tableData = data;
                draft.tableConfig.pagination.total = data !== null && (data.length || 0) > 0 ? data[0].totalRecords : 0;
                draft.tableConfig.pagination.current = action.payload.page;
            });       
        default:
            return state;
    }
}