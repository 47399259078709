import * as amCore from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import moment from "moment";
import { findTimezone } from "../common";

export interface Signal {
  signalId: string;
  name: string;
  values: Array<{ timestamp: Date; value: number }>;
  color: string;
}

export interface Options {
  max: number;
  min: number;
  criticalMin: number;
  criticalMax: number;
  warnMin: number;
  warnMax: number;
}

export interface Rules {
  criticalMin: number;
  criticalMax: number;
  warnMin: number;
  warnMax: number;
}

export default class Gauge {
  private _chart: am4charts.GaugeChart;

  constructor(ref: HTMLDivElement, signals: Signal[], rules: Rules, opts?: Options,timezone?:any) {
      var onColor = "#008000";
      var offColor = "#FF0000";
      let isRulesAvailable = true;
      if (rules.criticalMax === -1 || rules.criticalMin === -1 || rules.warnMax === -1 || rules.warnMin === -1) {
        // no rules found
        isRulesAvailable = false;
      }
      if (!rules.criticalMax || !rules.criticalMin|| !rules.warnMax || !rules.warnMin ) {
        // no rules found
        isRulesAvailable = false;
      }
      var criticalMin = rules.criticalMin || 2000;
      var warnMin = rules.warnMin || 4000;
      var warnMax =  rules.warnMax ||26000;
      var criticalMax = rules.criticalMax || 28000;
      
      if (signals && signals.length > 0) {
        if (signals[0].color) {
          let arr = signals[0].color.split("$");
          onColor = arr[0];
          offColor = arr[1];
        }
      }
      var name = signals[0].name;
      var unit = "";
      if ( signals[0].name.includes('[') && signals[0].name.includes(']')) {
        unit=  signals[0].name.substring(
          signals[0].name.lastIndexOf("[") + 1, 
          signals[0].name.lastIndexOf("]"));
        name = signals[0].name.split('[')[0];
      }

      // var tooltipFormat= "";
      // if (signals[0].values.length !== 0) {
      //   tooltipFormat = moment(signals[0].values[0].timestamp).format('ddd, MMM Do YYYY, h:mm:ss A') + " \n " + signals[0].name + " : " + signals[0].values[0].value;
      // } 

      var tooltipFormat= "";
      var details:any;
      if (signals[0].values.length !== 0) {
        
        details= findTimezone(timezone);
        let tooltipText = moment(signals[0].values[0].timestamp).format('ddd, MMM Do YYYY, HH:mm:ss ').concat(details?.timezone);
         tooltipFormat= tooltipText+" \n " + signals[0].name + " : " + signals[0].values[0].value;
      } 
      amCore.options.autoSetClassName = true;
      // Create Chart
      const chart = (this._chart = amCore.create(ref, am4charts.GaugeChart));
      chart.hiddenState.properties.opacity = 0;
      chart.fontSize = 11;
      chart.innerRadius = amCore.percent(85);
      chart.resizable = true;
      chart.tooltipText = tooltipFormat;
      chart.tooltip!.getFillFromObject = false;
      chart.tooltip!.background.fill = amCore.color("#6794dc");
      /**
       * Normal axis
       */
      var axis = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>())

      axis.min = opts!.min;// opts!.min;// this.channels.meta.input.range.min;
      axis.max = opts!.max;//this.channels.meta.input.range.max;

      axis.strictMinMax = true;
      axis.renderer.radius = amCore.percent(85);
      // axis.renderer.inside = true;
      axis.renderer.line.strokeOpacity = 0.1;
      axis.renderer.ticks.template.disabled = false;
      axis.renderer.ticks.template.strokeOpacity = 1;
      axis.renderer.ticks.template.strokeWidth = 0.5;
      axis.renderer.ticks.template.length = 0;
      axis.renderer.grid.template.disabled = true;
      axis.renderer.labels.template.radius = amCore.percent(20);
      axis.renderer.labels.template.fontSize = "0.9em";
      chart.numberFormatter.numberFormat = "#a";
      // axis.renderer.minGridDistance = 100;
      axis.renderer.minLabelPosition = 0;
      /**
       * Axis for ranges
       */
      var axis2 = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>())
      axis2.min = opts!.min;// this.channels.meta.input.range.min;
      axis2.max = opts!.max;//this.channels.meta.input.range.max;
      axis2.strictMinMax = true;
      axis2.renderer.labels.template.disabled = true;
      axis2.renderer.ticks.template.disabled = true;
      axis2.renderer.grid.template.disabled = true;
      // axis2.renderer.minGridDistance = 100;

      if (isRulesAvailable) {
        // Min Critical
        var range1 = axis2.axisRanges.create();
        range1.value = opts!.min; //signals[0].values[0].value; // start value of range 1
        range1.endValue = criticalMin;// opts!.max;//signals[0].values[0].max; // end value of range 1
        range1.axisFill.fillOpacity = 1;
        range1.axisFill.fill =  amCore.color("#FF0000"); //amCore.color(offColor);//
        range1.axisFill.zIndex =-1;

        // Min Warning
        var range2 = axis2.axisRanges.create();
        range2.value = criticalMin;// signals[0].values[0].value; // start value of range 1

        range2.endValue = warnMin;//signals[0].values[0].max; // end value of range 1
        range2.axisFill.fillOpacity = 1;
        range2.axisFill.fill = amCore.color('#FFCC00');// amCore.color("#ffd740");
        range2.axisFill.zIndex =-1;

        // Normal
        var range3 = axis2.axisRanges.create();
        range3.value = warnMin;// opts!.max -1000; // start value of range 1

        range3.endValue = warnMax;//signals[0].values[0].max; // end value of range 1
        range3.axisFill.fillOpacity = 1;
        range3.axisFill.fill = amCore.color(onColor);// amCore.color("#ffd740");
        range3.axisFill.zIndex =-1;

        // Max Warning
        var range4 = axis2.axisRanges.create();
        range4.value =  warnMax; //opts!.max -1000; // start value of range 1

        range4.endValue = criticalMax;// opts!.max;//signals[0].values[0].max; // end value of range 1
        range4.axisFill.fillOpacity = 1;
        range4.axisFill.fill = amCore.color('#FFCC00');// amCore.color("#ffd740");
        range4.axisFill.zIndex =-1;

        // Max Critical
        var range5 = axis2.axisRanges.create();
        range5.value = criticalMax;// opts!.max -1000; // start value of range 1

        range5.endValue = opts!.max;//signals[0].values[0].max; // end value of range 1
        range5.axisFill.fillOpacity = 1;
        range5.axisFill.fill = amCore.color('#FF0000');// amCore.color("#ffd740");
        range5.axisFill.zIndex =-1;
      } else {
        // Fill
        var range01 = axis2.axisRanges.create();
        range01.value = opts!.min; //signals[0].values[0].value; // start value of range 1

        range01.endValue = signals[0].values[0].value;//signals[0].values[0].max; // end value of range 1
        range01.axisFill.fillOpacity = 1;
        range01.axisFill.fill =  amCore.color(onColor);
        range01.axisFill.zIndex =-1;

        // Remain
        var range02 = axis2.axisRanges.create();
        range02.value =  signals[0].values[0].value;// start value of range 2

        range02.endValue = opts!.max; // end value of range 2
        range02.axisFill.fillOpacity = 1;
        range02.axisFill.fill = amCore.color(offColor);
        range02.axisFill.zIndex =-1;
      }

      /**
       * Label
       */
      let label = chart.radarContainer.createChild(amCore.Label);
      label.isMeasured = false;
      label.fontSize = 12;
      label.x = amCore.percent(50);
      label.y = amCore.percent(100);
      label.horizontalCenter = "middle";
      label.verticalCenter = "bottom";
      let val = signals[0].values[0].value;
      let count = 3
      var re = /[A-Za-z]/g;

      if(re.test(val.toString())) {
        val = 0
      } else {
        count = this.countDecimals(val);
      }
      

      if (count > 2){
        label.text = val.toFixed(2);
      } else {
        label.text = val.toFixed(count);
      }
      var label1 = chart.chartContainer.createChild(amCore.Label);
      label1.text = unit;
      label1.align = "center";
      label1.verticalCenter = "bottom";

      /**
       * Hand
       */

      let hand = chart.hands.push(new am4charts.ClockHand());
      hand.axis = axis2;
      hand.innerRadius = amCore.percent(40);  
      hand.startWidth = 3;
      hand.pin.disabled = true;
      hand.fill = amCore.color("#000000");
      hand.stroke = amCore.color("#000000");

      if (signals[0].values[0].value >= opts!.max ) {
        hand.value = opts!.max;
      } else if (signals[0].values[0].value <= opts!.min) {
        hand.value = opts!.min;
      } else {
        hand.value = signals[0].values[0].value;
      }

      let title = chart.titles.create();
      title.text = name;
      title.fontSize = 10;
      title.marginBottom = 5;
      title.tooltipText = tooltipFormat;

  } 

  private countDecimals(value: any) {
    if(Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0; 
  }

  public destroy() {
    this._chart.dispose();
  }
}
