import {produce as immerProduce} from "immer";
import { initialState } from "./state";

export function DeviceState(
    state = initialState, 
    action:any
) 
{
    let data : any = null
    switch (action.type) 
    {  
        case "CSUS_GET_SUCCESS":data = action.payload;
            return immerProduce(state, draft => 
            {
                draft.csus.csuList.refreshOnUpdate = false;
                draft.csus.csuList.tableData = data;
                draft.csus.csuList.tableConfig.pagination.total = data ? data[0].totalRecords : 0;
                draft.csus.csuList.tableConfig.pagination.current = action.payload.page;
            });
        case "CSU_SET_SEARCH":
            return immerProduce(state, draft => 
            {
                draft.csus.csuList.searchText = action.searchText;
            });
        case "CSU_SET_PAGINATION":
            return immerProduce(state, draft => 
            {
                draft.csus.csuList.tableConfig.pagination.onChange = action.onPaginationChange;
            });
        case "SET_TAB":
            return immerProduce(state, draft => 
            {
                draft.csus.csuList.csuMaint.activeTab = action.tabNumber;
            });
        case "OPEN_INSTALL_PROGRESS_DRAWER":
            return immerProduce(state, (draft) => 
            {
                const payload = action.payload,
                instance = Math.random();
                    draft.csus.csuMaintOptions.instance = instance;
                    draft.csus.csuMaintOptions.width = payload.width;
                    draft.csus.csuMaintOptions.info_visible = true;
                    draft.csus.csuMaintOptions.title = payload.title;
                    draft.csus.csuMaintOptions.activeTab = "1";
                    draft.csus.csuMaintOptions.record = payload.record;
            }); 
        case "CLOSE_DRAWER":
            return immerProduce(state, (draft) => 
            {
                draft.csus.csuMaintOptions.visible = false;
                draft.csus.csuMaintOptions.activeTab = "1";
                draft.csus.csuMaintOptions.record = null;
            });
        case "CLOSE_INSTALL_PROGRESS_DRAWER":
            return immerProduce(state, (draft) => 
            {
                draft.csus.csuMaintOptions.info_visible = false;
                draft.csus.csuMaintOptions.activeTab = "1";
                draft.csus.csuMaintOptions.record = null;
            });
        case "DRAWER_TAB_CHANGE":
            return immerProduce(state, (draft) => 
            {
                draft.csus.csuMaintOptions.activeTab = action.activeTab;
            }); 
        case "SOFTWARES_GET_SUCCESS": data = action.payload.data;
            return immerProduce(state, (draft) => 
            {
                draft.csus.csuList.csuMaint.record = data;
            });
        case "DISCOVERY_GET_SUCCESS": data = action.payload.data;
            return immerProduce(state, (draft) => 
            {
                draft.csus.csuList.csuMaint.discovery = data;
            });  
        case "SYSTEMSTATUS_GET_SUCCESS":data = action.payload.data;
            return immerProduce(state, (draft) => 
            {
                draft.csus.csuList.csuMaint.systemstatus = data;
                
            }); 
        case "SYSTEMHISTORY_GET_SUCCESS":data = action.payload.data;
            return immerProduce(state, (draft) =>
            {
                //draft.csus.csuList.csuMaint.systemhistory = data;
                draft.csus.systemhistory.refreshOnUpdate = false;
                draft.csus.systemhistory.tableData = data;
                draft.csus.systemhistory.tableConfig.pagination.total = data ? data[0].totalRecords : 0;
                draft.csus.systemhistory.tableConfig.pagination.current = action.payload.page;
            }); 
        case "DEVICE_OTA_GET_SUCCESS": data = action.payload;
            return immerProduce(state, draft => 
            {
                draft.csus.csuList.refreshOnUpdate = false;
                draft.csus.csuList.tableData = data;
                draft.csus.csuList.tableConfig.pagination.total = data ? data[0].totalRecords : 0;
                draft.csus.csuList.tableConfig.pagination.current = action.payload.page;
            });
        case "DISCOVERY_GET_FAILED":
            return immerProduce(state, draft => {
                draft.csus.csuList.csuMaint.discovery = data;
            });  
        case "SYSTEMSTATUS_GET_FAILED":
            return immerProduce(state, draft => {
                draft.csus.csuList.csuMaint.systemstatus = data;
            }); 
        case "CSU_MAINT_FAILED":
            return immerProduce(state, draft => {
                draft.csus.csuList.tableData = data;
            }); 
        case "SYSTEMHISTORY_FAILED":
                return immerProduce(state, draft => {
                    draft.csus.systemhistory = data;
            });             
        default: return state;
        case "SOFTWARES_LIST_SUCCESS": data = action.payload.data;
            return immerProduce(state, (draft) => 
            {
                draft.csus.csuList.csuMaint.record = data;
            });
    }
}