import React from "react";
import { Drawer, Button, Space, message, Tooltip } from "antd";
import { PlusOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import * as actions from "../actions";

import CollectionMaintInfo from "./CollectionMaintInfo";

import { appConfigs } from "../../../utils/configurations";
import history from '../../../utils/history';

import "./collectionMaint.css";

interface Prop {
    signalCollectionMaintClose: Function,  
    signalCollectionSetRecord: Function,    
    getSignalCollection: Function,
    getSignalCollections: Function,
    signalCollections: any,
    iscreate: boolean,
    iscreateOrUpdateTemplate: boolean,
    isread: boolean,
    isdelete: boolean,
    isGlobalTemplate: boolean,
    context: any
}

class CollectionMaint extends React.Component<Prop, {}> {  
 
    getTemplateId() {
        const pathnameArr = history.location.pathname.split("/");
        return pathnameArr[pathnameArr.length - 1];
    }

    onDrawerClose = () => {
        if (this.props.signalCollections.refreshOnUpdate) {
            this.props.getSignalCollections(this, null, null, this.getTemplateId(), this.props.signalCollections.tableConfig.pagination.current, this.props.signalCollections.searchText);
        }
        this.props.signalCollectionMaintClose(this);
    };

    gotoCollection = (e:any, direction:string) => {
        e.preventDefault();

        const 
            tableData = this.props.signalCollections.tableData;
        let 
            currentId = this.props.signalCollections.signalCollectionMaint.signalCollectionId,
            currentRecordIndex = -1;

        tableData.forEach((record: any, index: number) => {
            if (record.signalCollectionId === currentId) {
                currentRecordIndex = index;
            }
        });

        if (currentRecordIndex !== -1) {
            if (direction === "next" ) {
                currentRecordIndex = (currentRecordIndex === tableData.length - 1 ? 0 : currentRecordIndex + 1);
            }
            if (direction === "previous") {
                currentRecordIndex = (currentRecordIndex === 0 ? tableData.length - 1 : currentRecordIndex - 1);
            }
            this.props.getSignalCollection(this, null, null, this.getTemplateId(), tableData[currentRecordIndex].signalCollectionId);
        }
        else {
            message.error("Unable navigate to " + direction + " collection");
        }
    };
    
    addCollection = (e:any) => {
        e.preventDefault();
        this.props.signalCollectionSetRecord(this, {});
    };

    render() {
        
        const { title, width, visible } = this.props.signalCollections.signalCollectionMaint;

        return (
            <Drawer
                data-id="signal-collection-maint-drawer" 
                title={title}
                width={width}
                onClose={this.onDrawerClose}
                visible={visible}
                zIndex={1}
                destroyOnClose={true}
                footer={
                    <>
                        <Space size="middle" style={{ float: 'left' }}>
                            {
                                (this.props.signalCollections.tableData.length || 0) > 1 &&
                                    this.props.signalCollections.signalCollectionMaint.signalCollectionId &&
                                <>
                                    <Button data-id="collection-maint-previous-collection-button" type="default" onClick={(e) => {this.gotoCollection(e, "previous")}}>
                                        <LeftOutlined /><span className="pl-5">Collection</span>
                                    </Button>
                                    <Button data-id="collection-maint-next-collection-button" type="default" onClick={(e) => {this.gotoCollection(e, "next")}}>
                                        <span className="prl-5">Collection</span><RightOutlined /> 
                                    </Button>
                                </>
                            }
                            {
                                (Object.keys(this.props.signalCollections.signalCollectionMaint.record).length !== 0  || this.props.iscreate) &&
                                <Tooltip title="Create new signal collection">
                                    <Button data-id="collection-maint-new-collection-button" type="default" icon={<PlusOutlined />} onClick={(e) => this.addCollection(e)}>
                                        <span className="pl-5">Collection</span>
                                    </Button>              
                                </Tooltip>     
                            }
                        </Space>
                        <Space size="middle" style={{ float: 'right' }}>
                            <Button type="default" onClick={this.onDrawerClose}>
                                {appConfigs.settings.drawers.closeButtonText}
                            </Button>
                            {
                                (this.props.isGlobalTemplate && this.props.context.appContext.roleName.toLowerCase() === "admin"|| !this.props.isGlobalTemplate && this.props.iscreateOrUpdateTemplate) &&
                                <Button type="primary" form="signalCollectionMaintInfoForm" key="submit" htmlType="submit">
                                    {appConfigs.settings.form.submitButtonText}
                                </Button>
                            }
                        </Space>
                    </>
                }
            >
               <CollectionMaintInfo />               
            </Drawer>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        signalCollections: state.templateDetailsReducers.templateDetailsState.tabs[0]
    };
};

export default connect(
    mapStateToProps,
    actions
)(CollectionMaint);