import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";
import { formatTagsForPosting } from "../../../utils/commonHelpers";
import { jsonCircle } from "../../GeoFences/geofenceHelper";

export function eligibleAssets(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    status: string    
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/assets", {
                responseType: "json",        
                headers: {},
                params: {
                    status: status,
                    page: 1,
                    pageSize: 9999
                }
            }
        )
        .then(response => {          
                let data = response.data.data.assets == null || (response.data.data.assets.length || 0) === 0 ? [] : response.data.data.assets;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "ASSETS_ELIGIBLE_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}


export function eligibleAssetsLov(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    status: string, 
    searchText: string    
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/assets-lov", {
                responseType: "json",        
                headers: {},
                params: {
                    status: status,
                    searchText: searchText,
                    page: 1,
                    pageSize: 9999
                }
            }
        )
        .then(response => {          
                let data = response.data.data.assets == null || (response.data.data.assets.length || 0) === 0 ? [] : response.data.data.assets;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "ASSETS_ELIGIBLE_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function getOrgTags(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    status: string,
    orgId: string,
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/tags", {
                responseType: "json",        
                headers: {},
                params: {
                    orgId: orgId
                }
            }
        )
        .then(response => {  
                let data = response.data.data.orgTags == null || (response.data.data.orgTags.length || 0) === 0 ? [] : response.data.data.orgTags;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
            
                data = data.map((tag: any) => tag['tagName'].toUpperCase()).filter((item: any, i: any, ar: any) => ar.indexOf(item) === i).sort();
            
                dispatch({ type: "ORG_TAGS_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function assetMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    record: any
) {
    return async (dispatch: any) => {
        record.tags = formatTagsForPosting(record.tags);
        Axios.post(
            appConfigs.server.URL + "/ui/api/assets",
            record,
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
                let data = response.data.data.assets == null || (response.data.data.assets.length || 0) === 0 ? {} : response.data.data.assets[0];
                
                data['tags'] = JSON.parse(data['tags']);
                
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "ASSETS_ASSET_MAINT_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

export function assetOrgGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/orgs-lov" + ((orgId || "") !== "" ? "/" + orgId : ""), {
                responseType: "json",        
                headers: {},
                params: {
                    mode: "all",
                    scope: "restricted",
                    page: 1,
                    pageSize: 9999
                }
            }
        )
        .then(response => {
                let data = response.data.data.orgs == null || (response.data.data.orgs.length || 0) === 0 ? [] : response.data.data.orgs;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(orgId, data);
                }
                dispatch({ type: "ASSETS_GET_ORGS_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error);
            }
            errorResponse(me, error);
        });
    };
}

export function assetTemplatesGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string,
    orgByMastertag: string,    
    orgId: string,
    searchText?: string   
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/templates-lov", {
                responseType: "json",        
                headers: {},
                params: {
                    orgId: orgId,
                    orgByMastertag: orgByMastertag,
                    searchText: searchText,
                    page: 1,
                    pageSize: 9999
                }
            }
        )
        .then(response => {          
                let data = response.data.data.templates == null || (response.data.data.templates.length || 0) === 0 ? [] : response.data.data.templates;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "ASSETS_GET_TEMPLATES_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function assetStatusUpdate(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    gatewayId: string,
    status: string
) {
    return async (dispatch: any) => {
        Axios.put(
            appConfigs.server.URL + 
                "/ui/api/assets/" + gatewayId +  
                "?status=" + status,
            {},
            {
                responseType: "json"
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(gatewayId, status);           
                }
                dispatch({ type: "ASSETS_ASSET_STATUS_SUCCESS", gatewayId: gatewayId, status: status });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

export function assetIqanConnectKey(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    gatewayId: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/assets/" + gatewayId + "/iqan-connect-key", {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {                    
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.iqanConnectKey);
                }
                dispatch({ type: "ASSETS_IQAN_CONNECT_KEY_SUCCESS", iqanConnectKey: response.data.data.iqanConnectKey });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function IqanConnectKeyDeviceTwin(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    mastertag: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/assets/" + mastertag + "/iqan-connect-key-devicetwin", {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {                     
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.iqanConnectKey);
                }
                dispatch({ type: "ASSETS_IQAN_CONNECT_KEY_SUCCESS", iqanConnectKey: response.data.data.iqanConnectKey });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function setAssetSettings (
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    mastertag:string,
    values: any
) {
    return async (dispatch: any) => {
        Axios.post(  
            appConfigs.server.URL + `/ui/api/assets/${mastertag}/settings`, values,{
                responseType: "json",        
                headers: {},
                params: {
            }
            }
        )
        .then(response => {                   
                if (callbackFnSuccess != null) {
                    callbackFnSuccess();
                }
                dispatch({ type: "ASSET_SETTINGS", payload: values});
            })
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(me, error)
                }
        })
    }
}
export function assetwifipassword(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    mastertag: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/assets/" + mastertag + "/asset-wifi-password", {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {    
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.wifiPassword);
                }
                dispatch({ type: "ASSETS_WIFI_PASSWORD", wifipassword: response.data.data.wifiPassword });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });

    };
}

export function getAssetSettings (
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    mastertag:string,
    
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/assets/" + mastertag + "/settings", {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
                let deviceSettings={enableWiFi:false,
                     enableHotspot:false,
                    enableEthernet:false,
                    enableSleepMode: false,
                    sleepCycleInSeconds: "",
                    sleepCycleApplied: false,
                    nextWakeUpTime: "",
                    ssidList:[]}

                let data=response.data.data.deviceSettings===null?deviceSettings:response.data.data.deviceSettings;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "ASSET_SETTINGS", payload: data});
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            //errorResponse(me, error);
        });

    };
}    





 
