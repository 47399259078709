import { AssetOverview, ServiceTruck } from "../FleetOverview2/models";

export function getTopThreeGeoTabAssets(asset: AssetOverview, geotabAssets:ServiceTruck[]){

    if(asset.position && geotabAssets.length > 0){
    
        let closeGeoTabAssets:any = [];
        let assetLatLng = new google.maps.LatLng(asset.position?.latitude, asset.position?.longitude);

        geotabAssets.forEach((geotabAsset:ServiceTruck) => {

            let geotabAssetLatLng = new google.maps.LatLng(geotabAsset.latitude, geotabAsset.longitude);
            let distance:number = google.maps.geometry.spherical.computeDistanceBetween(assetLatLng, geotabAssetLatLng) * (0.000621371);
            let newEntry = {...geotabAsset, distance: distance};

            closeGeoTabAssets.push(newEntry);
        });

        closeGeoTabAssets = closeGeoTabAssets.sort((a:any,b:any) => a.distance - b.distance);

        let topThreeGeoTabAssets = [closeGeoTabAssets[0], closeGeoTabAssets[1], closeGeoTabAssets[2]];

        // console.log(topThreeGeoTabAssets);
        return topThreeGeoTabAssets;
    }

    return [];
}