import React, { useContext, useState, useEffect, useRef } from "react";
import { Row, Col, Tooltip, Tabs, Button, Space, Table, Input, Popconfirm, Form, Select, message, DatePicker, Card, Popover, Modal, Divider } from "antd";
import { FormInstance } from 'antd/lib/form';
import BackNavigation from "../../../utils/Components/BackNavigation";
import { successResponse, errorResponse, resetFormErrors } from "../../../utils/apiHelpers/apiHelpers";
import "./contracts.css";
import history from '../../../utils/history';
import { connect } from "react-redux";
import * as actions from "./actions";
import { InfoOutlined } from "@ant-design/icons";
import moment from 'moment';
import ContractMaint from "./ContractMaint";
import { appConfigs } from "../../../utils/configurations";
import ServicePlanTab from "./ServicePlanTab";
import { checkObjectKeyValuesNotEmpty } from "../../../utils/commonHelpers";

const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;

const { TabPane } = Tabs;


interface Props{
    contractsSetSearch: Function,
    contractSetPagination: Function,
    getContracts: Function,
    openDrawer: Function,
    getAllAssetsList: Function,
    addToContractsList: Function,
    addToRenewalsToolList: Function,
    removeFromContractsList: Function,
    removeFromRenewalsToolList: Function,
    modifyContract: Function,
    submitNewRenewal: Function,
    assignServicePlanToGateway: Function,
    updateField: Function,
    updateRenewalField: Function,
    setTab: Function,
    context: any,
    contracts: any,
    permissions: any,
    contractsModification: any,
    renewalTool: any
}

const Contracts: React.FC<Props> = (props) => {

    const drawerWidth: number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);
    const [selectedFilters, setSelectedFilters] = useState<any>({});
    const [selectedPartNumber, setSelectedPartNumber] = useState<any>('');
    const [renewalSelectedMastertag, setRenewalSelectedMastertag] = useState<any>(null);
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalMessage, setModalMessage] = useState('')

    const onTabChange = (key:string) => {
        props.setTab(key)
    }

    const { tableConfig, tableData } = props.contracts.contractsList;

    useEffect(() => {
        props.getAllAssetsList()
        props.contractSetPagination(onPaginationChange(1));
    }, [])

    const showModal = () => {
        setIsModalVisible(true);
    };
    
    const handleOk = () => {

        setIsModalVisible(false);

        let InputValidated = true

        // Future Validations
        props.renewalTool.selectedRenewalList.map((newRenewal: any) => {
            // console.log(newRenewal)
            if ( newRenewal.orderNumber == '' || newRenewal.customerPO == '' || newRenewal.partNumber == '' ) {
                InputValidated = false
                message.error("All fields are required")
            }
        })

        if(InputValidated){

            const selectedServicePlan = props.renewalTool.selectedRenewalList[0]['renewal_packages'].filter((servicePlan:any) => servicePlan.partNumber === selectedPartNumber)
            
            const postValue = {
                orgId: props.renewalTool.selectedRenewalList[0]['orgID'],
                gatewayId: props.renewalTool.selectedRenewalList[0]['gatewayID'],
                masterTag: props.renewalTool.selectedRenewalList[0]['mastertag'],
                partNumberId: selectedServicePlan[0]['id'],
                customerNumber: props.renewalTool.selectedRenewalList[0]['customerNumber'],
                billToName: props.renewalTool.selectedRenewalList[0]['billToName'],
                customerPO: props.renewalTool.selectedRenewalList[0]['customerPO'],
                orderNumber: props.renewalTool.selectedRenewalList[0]['orderNumber'],
                partNumber: props.renewalTool.selectedRenewalList[0]['partNumber'],
                userConfirmed: true,
                addLog: true
            }
            
            if(checkObjectKeyValuesNotEmpty(postValue)){
                props.assignServicePlanToGateway(postValue, submitNewRenewalSuccess, submitNewRenewalFailure)
            } else {
                message.warning("Unable to process request. Please contact your system administrator");
                console.log(postValue, props.renewalTool.selectedRenewalList[0])
            }

            // props.submitNewRenewal(props.renewalTool.selectedRenewalList, submitNewRenewalSuccess, submitNewRenewalFailure)
        }

    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const renderContractsTable = (pagination: any, filters: any, sorter: any, extra: any, e:any) => {
        setSelectedFilters(filters)
        props.getContracts(null, null, null, pagination.current, props.contracts.contractsList.searchText, filters)
    }

    const onSearch = (searchText: string) => {   
        props.contractsSetSearch((searchText || ""));

        if((searchText || "")==="") {
            history.push("/admin/contracts");
        }
        
        setTimeout(function() {
            props.getContracts(null, null, null, 1, searchText, selectedFilters);   
        }, 100);      
    }

    const onSearchChange = (e: any) => {
        let value = e.target.value;
        props.contractsSetSearch((value || ""));
    }

    const onPaginationChange = (page: number) => {
        props.getContracts(null, null, null, page, props.contracts.contractsList.searchText, selectedFilters);
    };

    const tableColumns = [
        {
            title: 'Mastertag',
            dataIndex: 'masterTag',
            render: (text: string, record: any) => {
                
                const content = (
                    <table className="styled-table" style={{width: '405px'}}>
                        <thead>
                            <tr>
                                <th>Mastertag</th>
                                <th>Created On</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Part Number</th>
                                <th>PO#</th>
                                <th>SO#</th>
                                <th>Created By</th>
                            </tr>
                        </thead>
                        <tbody>
                            { JSON.parse(record.billingContractHistory)?.map((billing:any) => 
                                <tr key={billing.id}>
                                    <td>{billing.mastertag}</td>
                                    <td>{billing.built_on}</td>
                                    <td>{billing.contract_start_date}</td>
                                    <td>{billing.contract_end_date}</td>
                                    <td>{billing.partNumber}</td>
                                    <td></td>
                                    <td></td>
                                    <td>{billing.full_name}</td>
                                </tr>
                            )}
                            { JSON.parse(record.renewalContractHistory)?.map((renewal:any) => 
                                <tr key={renewal.pts_id}>
                                    <td>{renewal.mastertag}</td>
                                    <td>{renewal.created_date}</td>
                                    <td>{renewal.contract_start_date}</td>
                                    <td>{renewal.contract_end_date}</td>
                                    <td>{renewal.partNumber}</td>
                                    <td>{renewal.po_number}</td>
                                    <td>{renewal.so_number}</td>
                                    <td>{renewal.full_name}</td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                );

                if(record.billingContractHistory || record.renewalContractHistory) {
                    return (
                        <Popover placement="topLeft" key={record.masterTag} content={content} title="Asset Detail View">
                            <span style={{ cursor: "pointer", color: "#119FFB", textDecoration: "underline" }}>{record.masterTag}</span>
                        </Popover >
                    )
                } else {
                    return (
                        <Popover placement="topLeft" key={record.masterTag} content={<p>No Contract History</p>} title="Asset Detail View">
                            <span style={{ cursor: "pointer" }}>{record.masterTag}</span>
                        </Popover >
                    )
                }

            }
        },
        {
            title: 'Asset Name',
            dataIndex: 'assetName',
        },
        {
            title: "Org Name",
            dataIndex: "orgName",
        },
        {
            title: 'Billable Customer',
            dataIndex: 'billing_customer',
            render: (text: any, record: any) => (
                <span className="no-word-wrap">
                    {
                        record.billingContractHistory != null ?
                            <Tooltip title={
                                    <>
                                        <span>PTS Customer #: {JSON.parse(record.billingContractHistory)[0].pts_customer_number}</span>
                                        <br/>
                                        <span>SAP Customer #: {JSON.parse(record.billingContractHistory)[0].sap_customer_number}</span>
                                    </>
                                }>
                                <span style={{ cursor: "pointer", color: "#119FFB", textDecoration: "underline" }}>{JSON.parse(record.billingContractHistory)[0].customer_name}</span>
                            </Tooltip>
                        :
                        <span >No Customer</span>
                    }
                </span>  
            ),
        },
        {
            title: "Part Number",
            dataIndex: "partNumber",
        },
        {
            title: "Contract Start Date",
            dataIndex: "startDate",
            render: (text: any, record: any) => (
                <span className="no-word-wrap">
                    {
                        moment(record.endDate, "L").diff(moment(), 'days') <= 30 ?
                            <>
                                <span style={{ color: 'red' }}><b>{record.startDate}</b></span>
                            </>
                            :
                            <span >{record.startDate}</span>
                    }
                </span>  
            ),
        },
        {
            title: "Contract End Date",
            dataIndex: "endDate",
            render: (text: any, record: any) => (
                <span className="no-word-wrap">
                    {
                        moment(record.endDate, "L").diff(moment(), 'days') <= 30 ?
                            <>
                                <span style={{ color: 'red' }}><b>{record.endDate}</b></span>
                            </>
                            :
                            <span >{record.endDate}</span>
                    }
                </span>  
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            filterMultiple: true,
            filteredValue: selectedFilters.hasOwnProperty('status') ? selectedFilters.status : [],
            filters: props.contracts.contractsList.columnsFilters.status?.map((status: any) => {
                return { 'value': status, 'text': status }
            }),
        },
        
        {
            title: "Actions",
            align: "center" as "center",
            render: (text: string, record: any) => (
                <Space>
                    {
                        <Tooltip title="View Contract">
                            <Button
                                data-id="assets-edit-asset-button"
                                type="default" 
                                style={ record.changesHistory ? { backgroundColor:'#e6d9ad', color: "white", border: "solid #8a8a8a 1px"} : {} }
                                icon={<InfoOutlined />}
                                onClick={(e) => { props.openDrawer("Mastertag (" + record.masterTag + ') Contract Details', drawerWidth, record); }}
                            />
                        </Tooltip>
                    }                        
                </Space>
            )
        }
    ]



    //Modify Contracts 
    const onChangeModify = (value: any) => {
        console.log('value :>> ', value);
        let selectedAssetDetails = props.contractsModification.assetsList.filter((record:any) => (
            record.masterTag === value
        ))[0]

        // console.log('selectedAssetDetails', selectedAssetDetails)
        props.addToContractsList(selectedAssetDetails, getContractDetailsFailed)

    }

    //Renewal Tool 
    const onRenewalToolChangeModify = (value: any) => {
        // console.log('value :>> ', value);
        let selectedAssetDetails = props.renewalTool.assetsList.filter((record:any) => (
            record.masterTag === value
        ))[0]

        // console.log('selectedAssetDetails', selectedAssetDetails)
        props.addToRenewalsToolList(selectedAssetDetails, getRenewalDetailsFailed)
        setRenewalSelectedMastertag(null)

    }

    const getContractDetailsFailed = (error: any) => {
        console.log(error)
        message.error("Error While Retrieving Asset Data (Gateway Does Not Have Contract Or Whitelisted!!)");
    }

    const getRenewalDetailsFailed = (error: any, respMessage: string) => {
        console.log(error)
        message.error(respMessage);
    }

    const removeFromContractsList = (mastertag: any) => {
        props.removeFromContractsList(mastertag)
    }

    const removeFromRenewalsToolList = (mastertag: any) => {
        props.removeFromRenewalsToolList(mastertag)
    }

    const contractsModificationColumns = [
        {
            title: 'Mastertag',
            dataIndex: 'mastertag',
            render: (text: string, record: any) => {
                console.log('record :>> ', record);
                const content = (
                    <table className="styled-table" style={{width: '405px'}}>
                        <thead>
                            <tr>
                                <th>Mastertag</th>
                                <th>Created On</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Part Number</th>
                                <th>PO#</th>
                                <th>SO#</th>
                                <th>Created By</th>
                            </tr>
                        </thead>
                        <tbody>
                            { record.billing_contract_history?.map((billing:any) => 
                                <tr key={billing.id}>
                                    <td>{billing.mastertag}</td>
                                    <td>{billing.built_on}</td>
                                    <td>{billing.contract_start_date}</td>
                                    <td>{billing.contract_end_date}</td>
                                    <td>{billing.partNumber}</td>
                                    <td></td>
                                    <td></td>
                                    <td>{billing.full_name}</td>
                                </tr>
                            )}
                            { record.renewal_contract_history?.map((renewal:any) => 
                                <tr key={renewal.id}>
                                    <td>{renewal.mastertag}</td>
                                    <td>{renewal.created_date}</td>
                                    <td>{renewal.contract_start_date}</td>
                                    <td>{renewal.contract_end_date}</td>
                                    <td>{renewal.partNumber}</td>
                                    <td>{renewal.po_number}</td>
                                    <td>{renewal.so_number}</td>
                                    <td>{renewal.full_name}</td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                );

                if(record.billing_contract_history || record.renewal_contract_history) {
                    return (
                        <Popover placement="topLeft" key={record.mastertag} content={content} title="Asset Detail View">
                            <span style={{ cursor: "pointer", color: "#119FFB", textDecoration: "underline" }}>{record.mastertag}</span>
                        </Popover >
                    )
                } else {
                    return (
                        <Popover placement="topLeft" key={record.mastertag} content={<p>No Contract History</p>} title="Asset Detail View">
                            <span style={{ cursor: "pointer" }}>{record.mastertag}</span>
                        </Popover >
                    )
                }

            }
        },
        {
            title: 'Contract Start Date',
            dataIndex: 'contract_start_date',
            render: (text: string, record: any) => (
                <Tooltip title="Select New Contract Start Date">
                    <DatePicker 
                        defaultValue={moment(record.contract_start_date, 'MM/DD/YYYY')}
                        format={'MM/DD/YYYY'}
                        style={{ width: '100%' }}
                        onChange={(value, dateString) => updateField(dateString, 'contract_start_date', record)}
                    />
                </Tooltip>
            )
            
        },
        {
            title: 'Contract End Date',
            dataIndex: 'contract_end_date',
            render: (text: string, record: any) => (
                <Tooltip title="Select New Contract End Date">
                    <DatePicker 
                        defaultValue={moment(record.contract_end_date, 'MM/DD/YYYY')}
                        format={'MM/DD/YYYY'}
                        style={{ width: '100%' }}
                        onChange={(value, dateString) => updateField(dateString, 'contract_end_date', record)}
                    />
                </Tooltip>
            )
        },
        {
            title: 'Asset Status',
            dataIndex: 'old_gateway_status',
            render: (text: string, record: any) => (
                <Tooltip title="Select New Portal Status">
                    <Select 
                        defaultValue={record.old_gateway_status} 
                        style={{ width: '100%' }}
                        onChange={(value, e) => updateField(value, 'new_gateway_status', record)}
                    >
                        <Option value="active">Active</Option>
                        <Option value="inactive">Inactive</Option>
                    </Select>
                </Tooltip>
            )
        },
        {
            title: 'SIM Status',
            dataIndex: 'old_sim_status',
            render: (text: string, record: any) => (
                <Tooltip title="Select New Portal Status">
                    <Select
                        defaultValue={record.old_sim_status}
                        style={{ width: '100%' }}
                        onChange={(value, e) => updateField(value, 'new_sim_status', record)}
                    >
                        <Option value="ACTIVATED">Active</Option>
                        <Option value="DEACTIVATED">Inactive</Option>
                    </Select>
                </Tooltip>
            )
        },
        {
            title: 'IoTHub Status',
            dataIndex: 'old_iothub_status',
            render: (text: string, record: any) => (
                <Tooltip title="Select New Portal Status">
                    <Select
                        defaultValue={record.old_iothub_status}
                        style={{ width: '100%' }}
                        onChange={(value, e) => updateField(value, 'new_iothub_status', record)}
                    >
                        <Option value="enabled">Active</Option>
                        <Option value="disabled">Inactive</Option>
                    </Select>
                </Tooltip>
            )
        },
        {
            title: 'Change Justification',
            dataIndex: 'justification',
            render: (text: string, record: any) => (
                <Tooltip title="Enter Justification (Required) Min 10 Characters">
                    <TextArea
                        placeholder="Enter Justification"
                        minLength={10}
                        defaultValue={record.justification}
                        autoSize={{ minRows: 2, maxRows: 10 }}
                        onChange={(e) => updateJustification(e, 'justification', record)}
                        onBlur={(e) => updateJustification(e, 'justification', record)}
                        style={{ width: '100%', border: record.justification?.trim().length < 10 ? '#C70039 2px solid' : '#097969 2px solid'}}
                    />
                </Tooltip>
            )
        },
        {
            title: 'Action',
            render: (text: string, record: any) => (
                <Tooltip title="Remove Row">
                    <Button 
                        style={{ width: '100%' }}
                        type="link"
                        onClick={() => removeFromContractsList(record.mastertag)}
                    >
                        Remove
                    </Button>
                </Tooltip>
            )
        },
    ];


    const renewalToolColumns = [
        {
            title: 'Mastertag',
            dataIndex: 'mastertag',
        },
        {
            title: 'Customer Name',
            dataIndex: 'customer_name',
            render: (text: string, record: any) => (
                <span>{record.billing_contract_history[0].customer_name}</span>
            )
        },
        // {
        //     title: 'PTS Customer #',
        //     dataIndex: 'pts_customer_number',
        //     render: (text: string, record: any) => (
        //         <span>{record.billing_contract_history[0].pts_customer_number}</span>
        //     )
        // },
        {
            title: 'Order Number',
            dataIndex: 'order_number',
            render: (text: string, record: any) => (
                <Tooltip title="Enter Service Order Number">
                    <Input
                        placeholder="Order Number"
                        minLength={10}
                        onChange={(e) => updateRenewalField(e.target.value, 'orderNumber', record)}
                        onBlur={(e) => updateRenewalField(e.target.value, 'orderNumber', record)}
                    />
                </Tooltip>
            )
        },
        {
            title: 'PO Number',
            dataIndex: 'po_number',
            render: (text: string, record: any) => (
                <Tooltip title="Enter PO Number">
                    <Input
                        placeholder="PO Number"
                        minLength={10}
                        onChange={(e) => updateRenewalField(e.target.value, 'customerPO', record)}
                        onBlur={(e) => updateRenewalField(e.target.value, 'customerPO', record)}
                    />
                </Tooltip>
            )
        },
        {
            title: 'Renewal Package',
            dataIndex: 'renewal_package',
            width: 350,
            render: (text: string, record: any) => (
                <Tooltip title="Select Renewal Package">
                    <Select
                        placeholder="Select Renewal Package"
                        showSearch
                        style={{ width: '100%' }}
                        onChange={(value, e) => updateRenewalField(value, 'partNumber', record)}
                        optionFilterProp="children"
                        filterOption={(input, option) => {
                            return  option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }}
                    >
                        {
                            record.renewal_packages && record.renewal_packages.map((item:any) => (
                                <Option style={{fontSize: '12px'}} key={item.partNumber} value={item.partNumber}>{item.package_name}</Option> /* {item.nickname} */
                            ))
                        }
                    </Select>
                </Tooltip>
            )
        },
        {
            title: 'Action',
            render: (text: string, record: any) => (
                <Tooltip title="Remove Row">
                    <Button 
                        style={{ width: '100%' }}
                        type="link"
                        onClick={() => removeFromRenewalsToolList(record.mastertag)}
                    >
                        Remove
                    </Button>
                </Tooltip>
            )
        },
    ];


    const updateJustification = (e:any, fieldName:string, record:any) => {
        if(e.target.value){
            props.updateField(e.target.value, fieldName, record.mastertag)
        }
    }

    const updateField = (value:any, fieldName:string, record:any) => {
        props.updateField(value, fieldName, record.mastertag)
    }

    
    const updateRenewalField = (value:any, fieldName:string, record:any) => {
        if(fieldName === 'partNumber'){
            setSelectedPartNumber(value)
        }
        props.updateRenewalField(value, fieldName, record.mastertag)
    }

    const contractUpdateSuccess = (description: any) => {
        console.log('message', description.description)
        message.success("Contract Data Updated Successfully!");
    }

    const enterLoading = (val:any) => {
        
        let InputValidated = true

        props.contractsModification.selectedContractList.map((contract:any) => {
            if(contract.justification.trim().length < 10){
                message.error("Mastertag: (" + contract.mastertag + ") is Missing Justification (Minimum 10 Characters)");
                InputValidated = false
            }
        })

        if(InputValidated){
            props.modifyContract(props.contractsModification.selectedContractList, contractUpdateSuccess)
        }
    }


    const submitNewRenewalSuccess = (data: any, resultMessage: any) => {

        if(resultMessage === 'Renewal Contract Assigned, Activated and Downgraded Successfully'){
            message.success("Service Plan Assigned and Activated Successfully!, Template Resetted! Please assign Lite template");
        } else {
            message.success("Renewal Entered Successfully!");
        }
        
        removeFromRenewalsToolList(props.renewalTool.selectedRenewalList[0].mastertag)

    }

    const submitNewRenewalFailure = (defaultMessage: any, ServerMessage: any) => {
        if(ServerMessage?.includes('There is an active contract expiring on') || ServerMessage?.includes('There is a pending service plan')){
            setModalMessage(ServerMessage)
            showModal()
        } else {
            message.error(ServerMessage ? ServerMessage : defaultMessage, 10);
        }
    }


    const submitNewRenewal = (val:any) => {
        
        let InputValidated = true

        // Future Validations
        props.renewalTool.selectedRenewalList.map((newRenewal: any) => {
            // console.log(newRenewal)
            if ( newRenewal.orderNumber == '' || newRenewal.customerPO == '' || newRenewal.partNumber == '' ) {
                InputValidated = false
                message.error("All fields are required")
            }
        })

        if(InputValidated){

            const selectedServicePlan = props.renewalTool.selectedRenewalList[0]['renewal_packages'].filter((servicePlan:any) => servicePlan.partNumber === selectedPartNumber)

            console.log(props.renewalTool.selectedRenewalList[0])
            const postValue = {
                orgId: props.renewalTool.selectedRenewalList[0]['orgID'],
                gatewayId: props.renewalTool.selectedRenewalList[0]['gatewayID'],
                masterTag: props.renewalTool.selectedRenewalList[0]['mastertag'],
                partNumberId: selectedServicePlan[0]['id'],
                customerNumber: props.renewalTool.selectedRenewalList[0]['customerNumber'],
                billToName: props.renewalTool.selectedRenewalList[0]['billToName'],
                customerPO: props.renewalTool.selectedRenewalList[0]['customerPO'],
                orderNumber: props.renewalTool.selectedRenewalList[0]['orderNumber'],
                partNumber: props.renewalTool.selectedRenewalList[0]['partNumber'],
                userConfirmed: false,
                addLog: true
            }
            
            console.log(postValue)

            if(checkObjectKeyValuesNotEmpty(postValue)){
                props.assignServicePlanToGateway(postValue, submitNewRenewalSuccess, submitNewRenewalFailure)
            } else {
                message.warning("Unable to process request. Please contact your system administrator");
                console.log(postValue, props.renewalTool.selectedRenewalList[0])
            }

            // props.submitNewRenewal(props.renewalTool.selectedRenewalList, submitNewRenewalSuccess, submitNewRenewalFailure)
        }
    }

    // console.log('props.contractsModification.selectedContractList', props.contractsModification.selectedContractList)

    const contractsHistoryColumns = [
        {
            title: 'Mastertag',
            dataIndex: 'mastertag',
        },
    ]

    return (
        <>
            <Modal title="Entry Warning" visible={isModalVisible} okText="Yes, Add" cancelText="No" okType="danger" onOk={handleOk} onCancel={handleCancel}>
                <span>{ modalMessage }</span><br />
                <span>Are you sure you still want to add this service plan?</span> 
            </Modal>
       
            <div className="layout-content">
                <Row gutter={8}>
                    
                    <Col xs={24} lg={14}>
                        <div className="content-header page-title">
                            <div style={{ display: "flex" }}>
                                <Tooltip title="Go To Previous Page"> 
                                    <BackNavigation /> 
                                </Tooltip>                            
                                {
                                    <span>Contracts Management</span>
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
                
                <div className="mt-15">
                    <Tabs activeKey={props.contracts.activeTab} size="large" type="card" onChange={onTabChange}>
                        <TabPane tab="Contracts List" key="1">                    
                            <Row justify="end" gutter={24}>
                                
                                <Col xs={12} lg={8} className="content-header-controls">
                                    <Search
                                        data-id="contract-search-field"
                                        key="search-field"
                                        placeholder="Search By Master Tag, Name, Org Name, End Date"
                                        enterButton="Search"
                                        size="middle"
                                        allowClear
                                        onSearch={onSearch}
                                        onChange={onSearchChange}
                                        className="search-field"
                                        value={props.contracts.contractsList.searchText}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Table 
                                        data-id="contracts-data" 
                                        className="mt-15"
                                        style={{ overflowX: 'auto' }} 
                                        {...tableConfig}
                                        rowKey={(record) => record.masterTag+record.assetName}
                                        columns={tableColumns}
                                        dataSource={tableData}
                                        onChange={renderContractsTable}
                                        showSorterTooltip={false}
                                    />
                                </Col>       
                            </Row>
                        </TabPane> 

                        

                        
                        <TabPane tab="Modify Existing Contracts" key="2">
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Select
                                        style={{width: '100%'}}
                                        showSearch
                                        disabled={props.contractsModification.selectedContractList.length > 0}
                                        placeholder="Select a Gateway to Edit"
                                        onChange={onChangeModify}
                                        loading={props.contractsModification.loadingAssetsList}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => {
                                            return  option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }}
                                    >
                                        {
                                            props.contractsModification.assetsList && props.contractsModification.assetsList.map((item:any) => (
                                                <Option key={item.gatewayID} value={item.masterTag}>{item.masterTag}</Option> /* {item.nickname} */
                                            ))
                                        }
                                    </Select>
                                </Col>

                                <Col style={{marginTop: '10px'}} xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Table 
                                        dataSource={props.contractsModification.selectedContractList}
                                        columns={contractsModificationColumns}
                                        rowKey={(record) => record.mastertag}
                                        showSorterTooltip={false}
                                        pagination={false}
                                    />
                                </Col>

                                <Col style={{marginTop: '10px'}} xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Button 
                                        style={{ width: '100%' }}
                                        type="primary"
                                        loading={props.contractsModification.submittingContractList}
                                        onClick={(e) => enterLoading(e)}
                                        disabled={props.contractsModification.selectedContractList.length === 0}
                                    >
                                        Update Contract(s) and Asset(s) Details
                                    </Button>
                                </Col>

                            </Row>
                        </TabPane>


                        
                        <TabPane tab="Add Renewal To Gateway" key="3">
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Select
                                        style={{width: '100%'}}
                                        showSearch
                                        disabled={props.renewalTool.selectedRenewalList.length > 0}
                                        placeholder="Select a gateway to add renewal"
                                        value={renewalSelectedMastertag}
                                        onChange={onRenewalToolChangeModify}
                                        loading={props.renewalTool.loadingAssetsList}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => {
                                            return  option?.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }}
                                    >
                                        {
                                            props.renewalTool.assetsList && props.renewalTool.assetsList.map((item:any) => (
                                                <Option key={item.gatewayID} value={item.masterTag}>{item.masterTag}</Option> /* {item.nickname} */
                                            ))
                                        }
                                    </Select>
                                </Col>

                                <Col style={{marginTop: '10px'}} xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Table 
                                        dataSource={props.renewalTool.selectedRenewalList}
                                        columns={renewalToolColumns}
                                        rowKey={(record) => record.mastertag}
                                        showSorterTooltip={false}
                                        pagination={false}
                                    />
                                </Col>

                                <Col style={{marginTop: '10px'}} xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Button 
                                        style={{ width: '100%' }}
                                        type="primary"
                                        loading={props.renewalTool.submittingRenewalList}
                                        onClick={(e) => submitNewRenewal(e)}
                                        disabled={props.renewalTool.selectedRenewalList.length === 0}
                                    >
                                        Add Renewal Details
                                    </Button>
                                </Col>

                                {
                                    props.renewalTool.selectedRenewalList.length > 0 &&
                                    <>
                                    
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <Divider></Divider>
                                        </Col>

                                        
                                        <Col style={{marginTop: '10px'}} xs={24} sm={24} md={24} lg={24} xl={24}>
                                            <h2>Asset Detailed View</h2>
                                            <table className="styled-table" style={{width: '100%'}}>
                                                <thead>
                                                    <tr>
                                                        <th>Mastertag</th>
                                                        <th>Created On</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>
                                                        <th>Part Number</th>
                                                        <th>PO#</th>
                                                        <th>SO#</th>
                                                        <th>Created By</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { props.renewalTool?.selectedRenewalList[0]?.billing_contract_history?.map((billing:any) => 
                                                        <tr key={billing.id}>
                                                            <td>{billing.mastertag}</td>
                                                            <td>{billing.built_on}</td>
                                                            <td>{billing.contract_start_date}</td>
                                                            <td>{billing.contract_end_date}</td>
                                                            <td>{billing.partNumber}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>{billing.full_name}</td>
                                                        </tr>
                                                    )}
                                                    { props.renewalTool?.selectedRenewalList[0]?.renewal_contract_history?.map((renewal:any) => 
                                                        <tr key={renewal.id}>
                                                            <td>{renewal.mastertag}</td>
                                                            <td>{renewal.created_date}</td>
                                                            <td>{renewal.contract_start_date}</td>
                                                            <td>{renewal.contract_end_date}</td>
                                                            <td>{renewal.partNumber}</td>
                                                            <td>{renewal.po_number}</td>
                                                            <td>{renewal.so_number}</td>
                                                            <td>{renewal.full_name}</td>
                                                        </tr>
                                                    )}
                                                </tbody>

                                            </table>
                                        </Col>
                                    </>
                                }
                                

                            </Row>
                        </TabPane>


                        <TabPane tab="Add Service Plan To Buckets" key="4">
                            <ServicePlanTab />
                        </TabPane>

                        
                    </Tabs>           
                </div>

                <ContractMaint/>
            </div>
        </>

    );

}

const mapStateToProps = (state:any) => {
    return {
        permissions: state.contextReducer.data,
        context: state.contextReducer.context,
        contracts: state.contractsReducers.contractsState.contracts,
        contractsModification: state.contractsReducers.contractsState.contracts.contractsModification,
        renewalTool: state.contractsReducers.contractsState.contracts.renewalTool,
    };
};

export default connect(
    mapStateToProps,
    actions
)(Contracts);
