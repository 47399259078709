import React, { Component, useEffect } from "react";
import { Row, Col, Tooltip, Tabs, Button, Space, Avatar, Card } from "antd";
import BackNavigation from "../../../utils/Components/BackNavigation";
import "./tutorials.css";
import { connect } from "react-redux";
import * as actions from "./actions";
import { PauseOutlined, CaretRightOutlined, FullscreenOutlined } from "@ant-design/icons";
import Meta from "antd/lib/card/Meta";
import ReactPlayer from 'react-player'

const { TabPane } = Tabs;

interface Props{
    getTutorials: Function,
    setTab: Function,
    context: any,
    tutorials: any,
    permissions: any
}

const Tutorials: React.FC<Props> = (props) => {


    const onTabChange = (key:string) => {
        props.setTab(key)
    }


    useEffect(() => {
        props.getTutorials()
    }, [])

    return (

        <div className="layout-content">
            <Row gutter={8}>
                <Col xs={24} lg={14}>
                    <div className="content-header page-title">
                        <div style={{ display: "flex" }}>
                            <Tooltip title="Go To Previous Page"> 
                                <BackNavigation /> 
                            </Tooltip>                            
                            {
                                <span>Video Tutorials</span>
                            }
                        </div>
                    </div>
                </Col>
            </Row>
            
            <div className="mt-15">
                <Tabs activeKey={props.tutorials.activeTab} size="large" type="card" onChange={onTabChange}>

                    <TabPane tab="All Tutorials" key="1">                    
                        <Row gutter={8}>
                            {
                                props.tutorials.tutorialsList.map((tutorial:any, index:number) => (
                                    <Col xs={12} sm={12} md={8} lg={8} xl={8} key={tutorial.id}>
                                        <Card
                                            style={{ height: '%50', padding: '3px' }}
                                            cover={
                                                <ReactPlayer 
                                                    url={tutorial.link}
                                                    controls={true}
                                                    width='260'
                                                    height='160'
                                                    playsinline={true}
                                                    stopOnUnmount={false}
                                                />
                                            }
                                            // actions={[
                                            //     <CaretRightOutlined key="play" />,
                                            //     <PauseOutlined key="pause" />,
                                            //     <FullscreenOutlined key="fullscreen" />,
                                            // ]}
                                        >
                                            <Meta
                                                title={tutorial.title}
                                                description={tutorial.description}
                                            />
                                        </Card>
                                    </Col>
                                    
                                ))
                            }
                            
                        </Row>
                    </TabPane> 

                    <TabPane tab="Map Tutorials" key="2">
                        <Row gutter={8}>
                            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                <Card
                                    style={{ height: '%50', padding: '3px' }}
                                    cover={
                                        <ReactPlayer 
                                            url=''
                                            controls={true}
                                            width='260'
                                            height='160'
                                            playsinline={true}
                                        />
                                    }
                                    // actions={[
                                    //     <CaretRightOutlined key="play" />,
                                    //     <PauseOutlined key="pause" />,
                                    //     <FullscreenOutlined key="fullscreen" />,
                                    // ]}
                                >
                                    <Meta
                                        title="Coming Soon..."
                                        description="More tutorials are coming soon"
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane tab="Alerts Tutorials" key="3">
                        <Row gutter={8}>
                        {
                                props.tutorials.tutorialsList.filter((record:any, index:number) => (
                                    record.category === 'Alerts'
                                ))
                                .map((tutorial:any, index:number) => (
                                    <Col xs={12} sm={12} md={8} lg={8} xl={8} key={tutorial.id}>
                                        <Card
                                            style={{ height: '%50', padding: '3px' }}
                                            cover={
                                                <div className='player-wrapper'>
                                                    <ReactPlayer
                                                        url={tutorial.link}
                                                        controls
                                                        width='260'
                                                        height='160'
                                                        className='react-player'
                                                        stopOnUnmount={false}
                                                    />
                                                </div>
                                            }
                                            // actions={[
                                            //     <CaretRightOutlined key="play" />,
                                            //     <PauseOutlined key="pause" />,
                                            //     <FullscreenOutlined key="fullscreen" />,
                                            // ]}
                                        >
                                            <Meta
                                                title={tutorial.title}
                                                description={tutorial.description}
                                            />
                                        </Card>
                                    </Col>
                                    
                                ))
                            }
                        </Row>
                    </TabPane>

                    

                    <TabPane tab="Assets Tutorials" key="4">                    
                        <Row gutter={8}>
                            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                <Card
                                    style={{ height: '%50', padding: '3px' }}
                                    cover={
                                        <ReactPlayer 
                                            url=''
                                            controls={true}
                                            width='260'
                                            height='160'
                                            playsinline={true}
                                        />
                                    }
                                    // actions={[
                                    //     <CaretRightOutlined key="play" />,
                                    //     <PauseOutlined key="pause" />,
                                    //     <FullscreenOutlined key="fullscreen" />,
                                    // ]}
                                >
                                    <Meta
                                        title="Coming Soon..."
                                        description="More tutorials are coming soon"
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </TabPane> 

                    <TabPane tab="More..." key="5">
                        <Row gutter={8}>
                            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                <Card
                                    style={{ height: '%50', padding: '3px' }}
                                    cover={
                                        <ReactPlayer 
                                            url=''
                                            controls={true}
                                            width='260'
                                            height='160'
                                            playsinline={true}
                                        />
                                    }
                                    // actions={[
                                    //     <CaretRightOutlined key="play" />,
                                    //     <PauseOutlined key="pause" />,
                                    //     <FullscreenOutlined key="fullscreen" />,
                                    // ]}
                                >
                                    <Meta
                                        title="Coming Soon..."
                                        description="More tutorials are coming soon"
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>           
            </div>
        </div>

    );

}

const mapStateToProps = (state:any) => {
    return {
        permissions: state.contextReducer.data,
        context: state.contextReducer.context,
        tutorials: state.tutorialsReducers.tutorialsState.tutorials,
    };
};

export default connect(
    mapStateToProps,
    actions
)(Tutorials);
