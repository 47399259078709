import { useEffect } from "react";

export type PollFunc = () => void;
export type AsyncPollFunc = () => Promise<void>;

export default function usePolling(
    pollFunc: PollFunc | AsyncPollFunc,
    msInterval: number
) {
    useEffect(() => {
        let cancelPolling = false;

        const innerPoll = () => {
            // Prevent loop from setting another poll if the cancel happened while
            // the previous poll was already in progress
            if (cancelPolling) return;

            setTimeout(() => {
                if (cancelPolling) return;
                // Promise.resolve will unwrap any inner promises allowing this to work with both sync
                // and async functions.
                Promise.resolve(pollFunc()).then(innerPoll);
            }, msInterval);
        };

        innerPoll();
        return () => {
            cancelPolling = true;
        };
    }, [pollFunc, msInterval]);
}
