import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";
import * as actions from "../actions";

export function getOperatorSignals(
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string,
    preference: string
){
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/templates/"+ templateId +"/signalpreferences", {
                responseType: "json",        
                headers: {},
                params: {
                    preference: preference
                }
            }
        ).then(
            response => {
                let record = response.data.data.signals == null ?  { id: "", preferedSignals: []} : response.data.data.signals;                
                dispatch({ type: "OPR_PREFERED_SIGNALS_SUCCESS", payload: record});
            }
        )
        .catch(function (error) {
            errorResponse(null, error);
        });
    }
}

export function saveOperatorSignals(
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string,
    preference: string,
    preferedSignals: any
){
    return async (dispatch: any) => {
        Axios.put(
            appConfigs.server.URL + "/ui/api/templates/"+ templateId +"/signalpreferences", 
            preferedSignals,            
            {
                responseType: "json",
                headers: {},
                params: {
                    preference: preference
                }
            }
        ).then(
            response=> {
              callbackFnSuccess(response);
            }            
        ).catch(function (error) {            
            errorResponse(null, error);
        });
    }

}



