import React, { useRef, useLayoutEffect } from "react";

import GaugeChart, { Options, Signal } from "./Gauge";
import { CommonWidgetProps } from "../../models";
import { inputSignalMapper, signalLabel } from "../common";
import useDomNode from "../../../../lib/use-dom-node";

interface Props extends CommonWidgetProps {
  settings?: Options;
}

const createSignals = inputSignalMapper<Signal>((meta, data) => ({
  name: signalLabel(meta),
  values: [data[0]],
}));

const GaugeWidget: React.FC<Props> = (props) => {
  const [node, setNode] = useDomNode<HTMLDivElement>();
  const gaugeRef = useRef<GaugeChart>();

  const { signals, template, data, settings } = props;
  const signalList = createSignals(signals, template, data);

  useLayoutEffect(() => {
    if (node && data.size > 0 && signalList.length > 0) {
      const gauge = new GaugeChart(
        node,
        signalList,
        settings
      );
      gaugeRef.current = gauge;
      return () => gauge.destroy();
    }
  }, [node, signals, template, data, settings]);

  return <div style={{ height: "100%" }} ref={setNode} />;
};

export default GaugeWidget;
