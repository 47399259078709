import { appConfigs } from "./configurations";
import { number } from "@amcharts/amcharts4/core";
import { Moment } from "moment";
import moment from "moment";

export interface assetTableFilter {
    page: number,
	status: string,
    searchText: string,
    sortColumn: string,
    sortDirection: string,
    alertInterval: string,
    listMode: boolean,
    filters: any[any],
}


export const getDrawerWidth = (): number => {
	return Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);
};

export const setLogin = (
	headerColor: string,
	logoUrl: string,
	loginLogoUrl: string,
	backgroundUrl: string,
	termsAndConditions: string
): void => {
	let items: any;

	if ((headerColor || "") !== "") {
		items = document.querySelectorAll(
			"#okta-sign-in.auth-container .okta-sign-in-header"
		);
		if (items.length) {
			items[0].style.borderBottom = "5px solid " + headerColor;
		}
	}

	if ((loginLogoUrl || "") === "") {
		items = document.querySelectorAll(
			"#okta-sign-in.auth-container .okta-sign-in-header"
		);
		if (items.length) {
			items[0].style.display = "none";
		}
	} else {
		items = document.querySelectorAll(
			"#okta-sign-in.auth-container .okta-sign-in-header img"
		);
		if (items.length) {
			items[0].src = appConfigs.server.URL + loginLogoUrl;
		}
	}
	/*
        items = document.querySelectorAll('.okta-sign-in-header.auth-header');    
        if (items.length) {
            items[0].style.display = ((logoUrl || "") !== "" ? "" : "none");
        }
        if ((logoUrl || "") !== "") {
            items = document.querySelectorAll('.okta-sign-in-header.auth-header img');    
            if (items.length) {
                items[0].src = logoUrl;
            }
        }
    */
	items = document.querySelectorAll("body");
	if (items.length) {
		if ((backgroundUrl || "") !== "") {
			items[0].style.background =
				"url(" +
				appConfigs.server.URL +
				backgroundUrl +
				") center center / cover no-repeat fixed";
		}
		const widget = document.querySelectorAll("#sign-in-widget");
		if (widget.length) {
			items[0].style.overflow = "inherit";
		}
	}

	items = document.querySelectorAll(".login-footer a, .login-footer div");
	for (let counter = 0; counter < items.length; counter++) {
		items[counter].style.color =
			(backgroundUrl || "") === "" ? "#999" : "#FFFFFF";
	}

	items = document.querySelectorAll(".loginTermsAndConditions");
	if (items.length) {
		items[0].innerHTML = termsAndConditions;
	}
};

export const setLoginTermsAndConditions = (): void => {
	let source: any = document.querySelectorAll(".loginTermsAndConditions"),
		items: any = document.querySelectorAll(".loginTermsAndConditionsDisplay");

	if (source.length && items.length && (source[0].innerHTML || "") !== "") {
		items[0].innerHTML = source[0].innerHTML;
	}
};

export const getLoginUrl = (): string => {
	let loginUrl = window.localStorage.getItem("loginUrl") || "";

	if (loginUrl !== "") {
		loginUrl = loginUrl.trim();
	}
	if ((loginUrl || "") === "/") {
		loginUrl = "";
	}

	return loginUrl || "/login";
};

export const setHeaderAvatar = (src: string): void => {
	let items: any = document.querySelectorAll("[data-id=header-avatar]");
	if (items.length) {
		items[0].style.display = "";
		items[0].getElementsByTagName("img")[0].src = src;
	}
};

export const setHeaderUserName = (name: string): void => {
	let items: any = document.querySelectorAll(".header-user-full-name");
	for (let i = items.length; i--; ) {
		items[i].innerHTML = name;
	}
};

export const setHeaderColor = (headerColor: string): void => {
	let items: any = document.querySelectorAll(
		".ant-layout.ant-layout-has-sider"
	);
	if (items.length) {
		items[0].style.borderLeft = headerColor.startsWith("#000")
			? "none"
			: "5px solid " + headerColor;
	}
};

export const setLogo = (logoUrl: string): void => {
	let items: any = document.querySelectorAll(".div-organization-logo");
	if (items.length) {
		if (logoUrl !== items[0].src) {
			items[0].src = logoUrl;
		}
	}
};

export const getContext = (keyPath: string): any => {
	let contextString: string = window.localStorage.getItem("context") || "{}",
		context: any;

	try {
		context = JSON.parse(contextString);
	} catch (err) {
		window.localStorage.setItem("context", "{}");
		context = {};
	}

	if (!keyPath) {
		return JSON.parse(context);
	} else {
		return getObjectKey(context, keyPath);
	}
};

export const reloadPage = (): void => {
	window.location.href = window.location.href + "";
};

export const loadPage = (url?: string): void => {
	window.location.href = url || getLoginUrl();
};

export const refreshPage = (history: any) => {
	//
	//Refresh page / route without reloading the page completely
	//
	const refreshUri = window.location.pathname;
	history.replace("/refresh" + refreshUri);

	setTimeout(() => {
		history.replace(refreshUri);
	}, 100);
};

export const loadingIndicatorStart = (): void => {
	document.body.classList.add("loading-indicator");
};

export const loadingIndicatorEnd = (): void => {
	document.body.classList.remove("loading-indicator");
};

export const uuidv4 = (faviconUrl?: string): string => {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		//eslint-disable-next-line
		var r = (Math.random() * 16) | 0,
			v = c === "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

export const getObjectKey = (obj: any, keyPath: string): any => {
	let s: any = keyPath.replace(/\[(\w+)\]/g, ".$1");
	s = s.replace(/^\./, "");
	let a = s.split(".");

	for (let i = 0, n = a.length; i < n; ++i) {
		let k = a[i];
		if (k in obj) {
			obj = obj[k];
		} else {
			return;
		}
	}

	return obj;
};

export const stringToUrlString = (aString: string): string => {
	return aString
		.replace(/ +/g, "-")
		.replace(/[^a-zA-Z0-9-_]/g, "")
		.toLowerCase();
};

export const scrollToElement = (elementSelector: string) => {
	let items: any = document.querySelectorAll(elementSelector);
	if (items.length) {
		items[0].scrollIntoView({
			behavior: "smooth",
			block: "nearest",
		});
	}
};

export const closePopover = () => {
	//mousedown only works on desktops, touchstart on all
	if ('ontouchstart' in document.documentElement) {
		// console.log('0')
		setTimeout(function () {
			document.dispatchEvent(
				new MouseEvent("touchstart", {
					bubbles: true,
					view: window,
				})
			);
		}, 150);
	} else {
		// console.log('1')
		setTimeout(function () {
			document.dispatchEvent(
				new MouseEvent("mousedown", {
					bubbles: true,
					view: window,
				})
			);
		}, 150);
	}

	
};

export const clientStorageClear = () => {
	let assetTableFiltersString: any = window.localStorage.getItem("asset-table-filters")

	localStorage.clear();
	sessionStorage.clear();

	window.localStorage.setItem("asset-table-filters", assetTableFiltersString);
};

export const contentExists = (): boolean => {
	let items: any = document.querySelectorAll(".ant-layout-content");
	for (let i = items.length; i--; ) {
		if ((items[i].innerHTML || "") !== "") {
			return true;
		}
	}
	return false;
};

export const messageExists = (messageText: string): boolean => {
	let items: any = document.querySelectorAll(
		".ant-message .ant-message-custom-content span"
	);
	for (let i = items.length; i--; ) {
		if (items[i].innerHTML === messageText) {
			return true;
		}
	}
	return false;
};

export const getParamValueFromHistory = (
	historyState: any,
	param: string
): string => {
	if (historyState !== undefined) {
		for (let i = 0; i < historyState.length; i++) {
			return historyState[i][param];
		}
	}

	return "";
};

export const getSegmentFromUrl = (position: number): string => {
	return window.location.pathname.split("/")[position] || "";
};

export const localizeUtcDate = (
	date: string,
	dateFormatOptions: any
): string => {
	let datum = new Date(date);
	return datum.toLocaleString(undefined, dateFormatOptions).replace(/\,/g, ""); //eslint-disable-line no-useless-escape
};

export const sleep = (milliseconds: number): void => {
	const date = Date.now();
	let currentDate = null;
	do {
		currentDate = Date.now();
	} while (currentDate - date < milliseconds);
};

export const setElementDisplay = (
	selectorQuery: string,
	displayValue: string
): void => {
	let items: any = document.querySelectorAll(selectorQuery);
	for (let i = items.length; i--; ) {
		items[i].style.display = displayValue;
	}
};

export const isMobile = (): boolean => {
	const userAgent =
		typeof window.navigator === "undefined" ? "" : navigator.userAgent;
	const mobile = Boolean(
		userAgent.match(
			/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
		)
	);

	return mobile;
};

export const isSilentRequest = (url: string): boolean => {
	const pathname = url.replace(appConfigs.server.URL, "").toLowerCase();
	return (
		pathname.startsWith("/api/users/") ||
		pathname.startsWith("/ui/api/alerts/dm1/filters") ||
		pathname.startsWith("/ui/api/alerts/signals/filters") ||
		(pathname.startsWith("/ui/api/assets/") &&
			pathname.startsWith("/iqan-connect-key")) ||
		pathname.startsWith("/ui/api/orgreports/") ||
		pathname.startsWith("/api/roles/")
	);
};

export const isContextUrl = (url: string): boolean => {
	const pathname = url.replace(appConfigs.server.URL, "").toLowerCase();
	return pathname == "/ui/api/context";
};

export const initCap = (value: string): string => {
	const words = (value || "").toLowerCase().split(" ");

	const capitalWords = words.map((word) => {
		if (word.length < 1) {
			return "";
		}
		return word.slice(0, 1).toUpperCase() + word.slice(1);
	});

	return capitalWords.join(" ");
};

export const formatGps = (latitude: number, longitude: number): string => {
	
	const latitudeString = latitude.toFixed(5).toString().replace(".00000", "");
	const longitudeString = longitude.toFixed(5).toString().replace(".00000", "");

	return `${latitudeString} °N, ${longitudeString} °W`;
};

const formatLatLngSignal = (ltlngArray: any): string => {
	let formattedSignals: string = "";
	let longitude: number = 0;
	let latitude: number = 0;
	if (ltlngArray && ltlngArray.length > 0) {
		ltlngArray.forEach((ltlng: any, index: number) => {
			if (ltlng.name === "latitude") {
				latitude =
					ltlng.value !== null
						? ltlng.value.toFixed(5).toString().replace(".00000", "")
						: 0;
			} else {
				longitude =
					ltlng.value !== null
						? ltlng.value.toFixed(5).toString().replace(".00000", "")
						: 0;
				formattedSignals = `${longitude} °N`;
			}
		});
	}
	if (latitude) {
		formattedSignals = `${latitude} °W ` + formattedSignals;
	}
	return formattedSignals;
};

const formatOtherSignals = (formattedSignals: string, signalArray: any) => {
	let signalValues = signalArray.reduce(
		(acc: any, curSignal: any, currentIndex: number) => {
			const { name, value = "", units = "" } = curSignal;
			const newunits = units !== "" ? `[${units}]` : "";
			const newvalue =
				value !== null
					? value.toString().indexOf(".") === -1
						? value.toString().includes('0x')
							? hexToString(value)
						: value
					: value.toFixed(2)
				: "NA";
			if (currentIndex === 0) {
				if (formattedSignals.length > 1) {
					acc = formattedSignals + "\n";
				}
			}
			acc = acc + `${name} ${newunits}: ${newvalue}` + "\n";
			return acc;
		},
		""
	);

	return signalValues;
};

export const formatSignals = (signals: any): string => {
	let formattedSignals: string = "";
	let ltlngArray = signals.filter(
		(s: any) =>
			s.name.toLowerCase() === "longitude" ||
			s.name.toLowerCase() === "latitude"
	);

	if (ltlngArray.length > 0) {
		formattedSignals = formatLatLngSignal(ltlngArray);
	}


	let signalArray = signals.filter(
		(s: any) =>
			s.name.toLowerCase() !== "longitude" &&
			s.name.toLowerCase() !== "latitude"
	);
	if (signalArray.length > 0) {
		const signalValues = formatOtherSignals(formattedSignals, signalArray);
		return signalValues;
	} else {
		return formattedSignals;
	}
};

export const numberZeroPositive = (value: Number): boolean => {
	return !!value || ("".concat(value) || "x") === "0";
};

export const getPermissions = (permission: number): any => {
	var result = [];
	var binaryResult = [];

	//convert number to binary format(1,1,1,1)
	while (permission >= 1) {
		binaryResult.unshift(Math.floor(permission % 2));
		permission = permission / 2;
		result.push(binaryResult);
	}

	//Based on the binary format , will generate user "CRUD" permissions
	for (let i = 0; i < binaryResult.length; i++) {
		if (binaryResult.length == 4) {
			if (i === 0) {
				result[3] = binaryResult[i] === 1 ? true : false;
			}
			if (i === 1) {
				result[2] = binaryResult[i] === 1 ? true : false;
			}
			if (i === 2) {
				result[1] = binaryResult[i] === 1 ? true : false;
			}
			if (i === 3) {
				result[0] = binaryResult[i] === 1 ? true : false;
			}
		}
		if (binaryResult.length == 3) {
			if (i === 0) {
				result[2] = binaryResult[i] === 1 ? true : false;
				result[3] = false;
			}
			if (i === 1) {
				result[1] = binaryResult[i] === 1 ? true : false;
			}
			if (i === 2) {
				result[0] = binaryResult[i] === 1 ? true : false;
			}
		}
		if (result.length == 2) {
			result[0] = binaryResult[1] === 1 ? true : false;
			result[1] = binaryResult[0] === 1 ? true : false;
			result[2] = false;
			result[3] = false;
		}
	}

	return result;
};

export const getPermissionsList = (permission: number): any => {
	var result = [];
	var binaryResult = [];

	//convert number to binary format(1,1,1,1)
	while (permission >= 1) {
		binaryResult.unshift(Math.floor(permission % 2));
		permission = permission / 2;
		result.push(binaryResult);
	}

	//Based on the binary format , will generate user "CRUD" permissions
	for (let i = 0; i < binaryResult.length; i++) {
		if (binaryResult.length == 4) {
			if (i === 0) {
				result[3] = binaryResult[i] === 1 ? 1 : 0;
			}
			if (i === 1) {
				result[2] = binaryResult[i] === 1 ? 1 : 0;
			}
			if (i === 2) {
				result[1] = binaryResult[i] === 1 ? 1 : 0;
			}
			if (i === 3) {
				result[0] = binaryResult[i] === 1 ? 1 : 0;
			}
		}
		if (binaryResult.length == 3) {
			if (i === 0) {
				result[2] = binaryResult[i] === 1 ? 1 : 0;
				result[3] = 0;
			}
			if (i === 1) {
				result[1] = binaryResult[i] === 1 ? 1 : 0;
			}
			if (i === 2) {
				result[0] = binaryResult[i] === 1 ? 1 : 0;
			}
		}
		if (result.length == 2) {
			result[0] = binaryResult[1] === 1 ? 1 : 0;
			result[1] = binaryResult[0] === 1 ? 1 : 0;
			result[2] = 0;
			result[3] = 0;
		}
	}

	return result;
};

export const permissionsList = (tableData: any): any => {
	var permissionsList = [];
	var binaryResult = [];

	//convert number to binary format(1,1,1,1)
	//Based on the binary format , will generate user "CRUD" permissions

	for (let i = 0; i < tableData.permissions.length; i++) {
		let permission = tableData.permissions[i].permissionLevel;
		let permissionId = tableData.permissions[i].permissionId;
		let bynaryData = [];
		let result = [];

		while (permission >= 1) {
			binaryResult.unshift(Math.floor(permission % 2));
			permission = permission / 2;
			result.push(binaryResult);
		}
		permission = 0;

		for (let j = 0; j < binaryResult.length; j++) {
			if (binaryResult.length == 4) {
				if (j === 0) {
					bynaryData[3] = binaryResult[j] === 1 ? 1 : 0;
				}
				if (j === 1) {
					bynaryData[2] = binaryResult[j] === 1 ? 1 : 0;
				}
				if (j === 2) {
					bynaryData[1] = binaryResult[j] === 1 ? 1 : 0;
				}
				if (j === 3) {
					bynaryData[0] = binaryResult[j] === 1 ? 1 : 0;
				}
			}
			if (binaryResult.length == 3) {
				if (j === 0) {
					bynaryData[2] = binaryResult[j] === 1 ? 1 : 0;
					bynaryData[3] = 0;
				}
				if (j === 1) {
					bynaryData[1] = binaryResult[j] === 1 ? 1 : 0;
				}
				if (j === 2) {
					bynaryData[0] = binaryResult[j] === 1 ? 1 : 0;
				}
			}
			if (binaryResult.length == 2) {
				bynaryData[0] = binaryResult[1] === 1 ? 1 : 0;
				bynaryData[1] = binaryResult[0] === 1 ? 1 : 0;
				bynaryData[2] = 0;
				bynaryData[3] = 0;
			}
		}

		let permissionList = {
			permissionId: permissionId,
			permissions: bynaryData,
		};
		permissionsList.push(permissionList);
		binaryResult = [];
	}

	return permissionsList;
};

export const convertBinaryToValue = (permissionsList: any) => {
	let value;
	var permissions = [];

	for (let i = 0; i < permissionsList.length; i++) {
		if (permissionsList[i].permissions.length > 0) {
			permissionsList[i].permissions[0] =
				permissionsList[i].permissions[0] === 1 ? 1 : 0;
			permissionsList[i].permissions[1] =
				permissionsList[i].permissions[1] === 1 ? 1 : 0;
			permissionsList[i].permissions[2] =
				permissionsList[i].permissions[2] === 1 ? 1 : 0;
			permissionsList[i].permissions[3] =
				permissionsList[i].permissions[3] === 1 ? 1 : 0;
		}

		let permissionReverse = permissionsList[i].permissions.reverse();
		let binaryString = permissionReverse.join("");
		value = parseInt(binaryString, 2);
		value = value > 1 ? value : 0;

		let permission = { id: permissionsList[i].permissionId, value: value };
		permissions.push(permission);
		permissionsList[i].permissions.reverse();
	}

	return permissions;
};

export const getOrgPermissionByName = (orgPermissionsList: any, category: string, permissionName: string) => {
	if (orgPermissionsList) {
		if ((category in orgPermissionsList)) {
			if ((permissionName in orgPermissionsList[category])) {
				return orgPermissionsList[category][permissionName]
			}
		}
	}
	return false
}

export const getOrgPermissionsByCategory = (orgPermissionsList: any, category: string) => {
	if (orgPermissionsList) {
		if ((category in orgPermissionsList)) {
			return orgPermissionsList[category]
		}
	}
	return null
}

export const formatTagsForPosting = (tagArray: any[]): string => {
	let newTags: string[] = [],
		tagArrayNew: any[string] = [];

	tagArray?.forEach((tag: any) => {
		newTags.push(tag.value);
	});

	tagArrayNew["new"] = newTags;

	return JSON.stringify(Object.assign({}, tagArrayNew));
};

export const sortAlphabetically = (a: any, b: any) => {
	let fa = a.tagName.toUpperCase(),
		fb = b.tagName.toUpperCase();

	if (fa < fb) {
		return -1;
	}
	if (fa > fb) {
		return 1;
	}
	return 0;
};

export const rolespermissionsList = (tableData: any): any => {
	var permissionsList = [];
	var binaryResult = [];

	//convert number to binary format(1,1,1,1)
	//Based on the binary format , will generate user "CRUD" permissions

	for (let i = 0; i < tableData.data.permissions.length; i++) {
		let permission = tableData.data.permissions[i].permission_level;
		let permissionId = tableData.data.permissions[i].permissionId;
		let bynaryData = [];
		let result = [];

		while (permission >= 1) {
			binaryResult.unshift(Math.floor(permission % 2));
			permission = permission / 2;
			result.push(binaryResult);
		}
		permission = 0;

		for (let j = 0; j < binaryResult.length; j++) {
			bynaryData[0] = 0;
			bynaryData[1] = 0;
			bynaryData[2] = 0;
			bynaryData[3] = 0;
		}

		let permissionList = {
			permissionId: permissionId,
			permissions: bynaryData,
		};
		permissionsList.push(permissionList);
		binaryResult = [];
	}

	return permissionsList;
};

export const matchWords = (stringToSearch: any, words: any): any => {
	if (words) {
		var regexMetachars = /[(){[*+?.\\^$|]/g;

		words = words.split(" ");

		for (var i = 0; i < words.length; i++) {
			words[i] = words[i].replace(regexMetachars, "\\$&");
		}

		var regex = new RegExp("\\b(?:" + words.join("|") + ")\\b", "gi");

		return stringToSearch.match(regex) || [];
	} else {
		return [];
	}
};

export const getGPSEventsWidgetIconDetails = (
	point1: google.maps.LatLng,
	point2: google.maps.LatLng,
	severity: string | undefined,
	color: string = "#2b7cab"
): any => {
	let icon;
	const angleDegrees = google.maps.geometry.spherical.computeHeading(
		point1,
		point2
	);

	if (severity == "GPS") {
		icon = {
			path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
			fillColor: "#3377ff",
			fillOpacity: 1.0,
			rotation: angleDegrees,
			strokeColor: "#1b4db3",
			strokeWeight: 2,
			scale: 2,
		};
	} else {
		icon = {
			path: google.maps.SymbolPath.CIRCLE,
			fillColor: color,
			fillOpacity: 1.0,
			rotation: angleDegrees,
			strokeColor: color,
			strokeWeight: 3,
			scale: 8,
		};
	}

	// if (severity == "critical") {
	//   icon = {
	//     path: google.maps.SymbolPath.CIRCLE,
	//     fillColor: "#de3131",
	//     fillOpacity: 1.0,
	//     rotation: angleDegrees,
	//     strokeColor: "#ad2b2b",
	//     strokeWeight: 3,
	//     scale: 12,
	//   }
	// } else if (severity == "warning") {
	//   icon = {
	//     path: google.maps.SymbolPath.CIRCLE,
	//     fillColor: "#edb228",
	//     fillOpacity: 1.0,
	//     rotation: angleDegrees,
	//     strokeColor: "#bd8f26",
	//     strokeWeight: 3,
	//     scale: 12,
	//   }
	// } else if (severity == "info") {
	//   icon = {
	//     path: google.maps.SymbolPath.CIRCLE,
	//     fillColor: "#2c9bdb",
	//     fillOpacity: 1.0,
	//     rotation: angleDegrees,
	//     strokeColor: "#2b7cab",
	//     strokeWeight: 3,
	//     scale: 12,
	//   }
	// } else {
	//   icon = {
	//     path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
	//     fillColor: "#3377ff",
	//     fillOpacity: 1.0,
	//     rotation: angleDegrees,
	//     strokeColor: "#1b4db3",
	//     strokeWeight: 2,
	//     scale: 4,
	//   }
	// }

	return icon;
};

export const rad = (x: number) => {
	return (x * Math.PI) / 180;
};

export const getDistance = (p1: google.maps.LatLng, p2: google.maps.LatLng) => {
	let R = 6378137; // Earth’s mean radius in meter
	let dLat = rad(p2.lat() - p1.lat());
	let dLong = rad(p2.lng() - p1.lng());
	let a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(rad(p1.lat())) *
			Math.cos(rad(p2.lat())) *
			Math.sin(dLong / 2) *
			Math.sin(dLong / 2);
	let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	let d = R * c;
	return d; // returns the distance in meter
};

export const numberWithCommas = (x: number | string) => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getPercentageChange = (oldNumber: number, newNumber: number) => {
	var decreaseValue = newNumber - oldNumber;
	if (oldNumber == 0) { return (decreaseValue).toFixed(2); }

    return ((decreaseValue / oldNumber) * 100).toFixed(2);
}

export const groupObjsByValue = (objectArray: any[], property: string) => {
	return objectArray.reduce(function (acc, obj) {
		var key = obj[property];
		if (!acc[key]) {
			acc[key] = [];
		}
		acc[key].push(obj);
		return acc;
	}, {});
};

/*
    export const getObjectKeys = (
        obj: any
    ):any => {
        let keys:any = [];

        for (var key in obj) {
            keys.push(key);
            if (typeof obj[key] === "object") {
                let subkeys:any = getObjectKeys(obj[key]);
                keys = keys.concat(subkeys.map(function(subkey:any) {
                    return key + "." + subkey;
                }));
            }
        }

        return keys;
    }
*/

export const getToken=()=>{
	let oktaStorage:string = window.localStorage.getItem("okta-token-storage") || "{}",
    oktaStorageJson:any = JSON.parse(oktaStorage);
    let token=null;
	if(oktaStorageJson.idToken !== undefined)
	{
        token= (oktaStorageJson.idToken || oktaStorageJson.idToken.idToken);
	}
	return token;
}

export const checkOktaTokenExpired=()=>{
	let isTokenExpired: boolean =false;
	let oktaStorage:string = window.localStorage.getItem("okta-token-storage") || "{}",
    oktaStorageJson:any = JSON.parse(oktaStorage);
    if(oktaStorageJson.idToken === undefined)
	{
         isTokenExpired =true;
	}
	else
	{
		const expirationTime = new Date(oktaStorageJson.idToken.expiresAt * 1000);
		const now = new Date();
		if(expirationTime.getTime() < now.getTime()){
			isTokenExpired=true;
		}
	}
   
	return isTokenExpired;
}


export const setAssetTableFilters = (newAssetTableFilters: assetTableFilter) => {

	window.localStorage.setItem("asset-table-filters", JSON.stringify(newAssetTableFilters));
	let assetTableFilters = window.localStorage.getItem("asset-table-filters");

	return assetTableFilters
}


export const getAssetTableFilters = () => {

	let assetTableExistingFilters: any = window.localStorage.getItem("asset-table-filters")
	
	if (JSON.parse(assetTableExistingFilters)) {

		let assetTableFiltersString: any = window.localStorage.getItem("asset-table-filters")
		let assetTableFilters: assetTableFilter = JSON.parse(assetTableFiltersString);
		
		return assetTableFilters

	} else {

		setAssetTableFilters(
			{
				page: 1,
				status: 'active',
				searchText: '',
				sortColumn: 'asset',
				sortDirection: 'ascend',
				alertInterval: '1 day',
				listMode: true,
				filters: { tags: null },
			}
		)

		let assetTableFiltersString: any = window.localStorage.getItem("asset-table-filters")
		let assetTableFilters: assetTableFilter = JSON.parse(assetTableFiltersString);
		
		return assetTableFilters

	}
	
}


export const resetAssetTableFilters = ():assetTableFilter => {

	setAssetTableFilters(
		{
			page: 1,
			status: 'active',
			searchText: '',
			sortColumn: 'asset',
			sortDirection: 'ascend',
			alertInterval: '1 day',
			listMode: true,
			filters: {tags: null},
		}
	)

	let assetTableFiltersString: any = window.localStorage.getItem("asset-table-filters")
	let assetTableFilters:assetTableFilter = JSON.parse(assetTableFiltersString);
	
	return assetTableFilters
}


export const isAssetTableFiltersSet = ():boolean => {

	let assetTableFilters = window.localStorage.getItem("asset-table-filters");

	let initialFilterState = {
		page: 1,
		status: 'active',
		searchText: '',
		sortColumn: 'asset',
		sortDirection: 'ascend',
		alertInterval: '1 day',
		listMode: true,
		filters: {tags: null},
	};

	if (JSON.stringify(initialFilterState) === assetTableFilters) {
		return false
	}

	return true
}

export const formatNumber = (input: any): any => {
	const value = Number(input).toFixed(2).replace(".00", "");
		
	return value;
};

export const hexToString = (hexString:any): string => {
	// Remove the '0x' prefix and initial 'ff' bytes
    let cleanHexString = hexString.toString().replace(/^0x/, '').replace(/^ff+/, '');

    // Split the cleaned hex string into an array of byte pairs
    let bytePairs = cleanHexString.match(/.{1,2}/g) || [];

    // Reverse the order of the byte pairs
    let reversedBytePairs = bytePairs.reverse();

    // Convert the reversed byte pairs to a byte array
    let bytesArray = reversedBytePairs.map((bytePair:any) => parseInt(bytePair, 16));

    // Decode bytes using TextDecoder, assuming UTF-8 encoding
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(new Uint8Array(bytesArray));
}

export const templateContainsMSIntervals = (datapoints:string): boolean => {
    var millSecExists = false;
	let dataPointsList;

	try {

		dataPointsList = JSON.parse(datapoints);

		dataPointsList?.map((datapoint: any) => {
			if(datapoint.update_interval.includes("ms")){
				if(!datapoint.update_interval.includes("000")){
					millSecExists = true
				}
			}
		})

		return millSecExists;

	} catch (error) {

		return millSecExists;

	}
    
}

export const childNodes = (signals: any) => {
	return {
	  key: signals._id,
	  title: signals.label,
	};
  };
  
  export const createTreeStructure = (collection: any) => {
	return {
	  key: collection._id,
	  title: collection.name,
	  children: collection.values && collection.values.map(childNodes),
	};
  };


export const getTimescaleFilterText = (
	DataSearchRange: [Moment, Moment],
	timeRangeValueLocal: any,
	aggregation: any,
	granularity: any,
	isNotMapWidget: any,
	GranularityInit?: any
) => {

	let mapGranularity = "Raw Data"

	let timeRange = timeRangeValueLocal == 'Custom' ? `${DataSearchRange[0].format('MM/DD/YY HH:mma')} - ${DataSearchRange[1].format('MM/DD/YY HH:mma')}` : timeRangeValueLocal
	let text = ''
	let granularityTmp = '1 Minute'
	let customGranularity = granularity
	let shouldQuery = true

	switch (timeRangeValueLocal) {
		case 'Latest 30 minutes':
			text = `${granularity == 'Automatic' ? `Raw Data | ${aggregation}` : `${granularity} | ${aggregation}`}`
			DataSearchRange = [moment().subtract(30, "minute"), moment()]
			granularityTmp = isNotMapWidget ? granularity != 'Automatic' ? granularity : 'Raw Data' : mapGranularity
			break
		case 'Latest hour':
			text = `${granularity == 'Automatic' ? `Raw Data | ${aggregation}` : `${granularity} | ${aggregation}`}`
			DataSearchRange = [moment().subtract(1, "hours"), moment()]
			granularityTmp = isNotMapWidget ? granularity != 'Automatic' ? granularity : 'Raw Data' : mapGranularity
			break
		case 'Latest 4 hours':
			text = `${granularity == 'Automatic' ? `1 Minute | ${aggregation}` : `${granularity} | ${aggregation}`}`
			DataSearchRange = [moment().subtract(4, "hours"), moment()]
			granularityTmp = isNotMapWidget ? granularity != 'Automatic' ? granularity : '1 Minute' : mapGranularity
			break
		case 'Latest 12 hours':
			text = `${granularity == 'Automatic' ? `1 Minute | ${aggregation}` : `${granularity} | ${aggregation}`}`
			DataSearchRange = [moment().subtract(12, "hours"), moment()]
			granularityTmp = isNotMapWidget ? granularity != 'Automatic' ? granularity : '1 Minute' : mapGranularity
			break
		case 'Latest 24 hours':
			text = `${granularity == 'Automatic' ? `1 Minute | ${aggregation}` : `${granularity} | ${aggregation}`}`
			DataSearchRange = [moment().subtract(24, "hours"), moment()]
			granularityTmp = isNotMapWidget ? granularity != 'Automatic' ? granularity : '1 Minute' : mapGranularity
			break
		case 'Latest 48 hours':
			text = `${granularity == 'Automatic' ? `1 Minutes | ${aggregation}` : `${granularity} | ${aggregation}`}`
			DataSearchRange = [moment().subtract(48, "hours"), moment()]
			granularityTmp = isNotMapWidget ? granularity != 'Automatic' ? granularity : '1 Minutes' : mapGranularity
			break
		case 'Latest 3 days':
			text = `${granularity == 'Automatic' ? `1 Minutes | ${aggregation}` : `${granularity} | ${aggregation}`}`
			DataSearchRange = [moment().subtract(3, "days"), moment()]
			granularityTmp = isNotMapWidget ? granularity != 'Automatic' ? granularity : '1 Minutes' : mapGranularity
			break
		case 'Latest 7 days':
			text = `${granularity == 'Automatic' ? `1 Hour | ${aggregation}` : `${granularity} | ${aggregation}`}`
			DataSearchRange = [moment().subtract(8, "days"), moment()]
			granularityTmp = isNotMapWidget ? granularity != 'Automatic' ? granularity : '1 Hour' : mapGranularity
			break
		case 'Latest 30 days':
			text = `${granularity == 'Automatic' ? `1 Day | ${aggregation}` : `${granularity} | ${aggregation}`}`
			DataSearchRange = [moment().subtract(31, "days"), moment()]
			granularityTmp = isNotMapWidget ? granularity != 'Automatic' ? granularity : '1 Day' : mapGranularity
			break
		case 'Custom':

			let granularityBaseOnDate = '';
			let differenceInMinutes = moment.duration(DataSearchRange[1].diff(DataSearchRange[0])).asMinutes();
			
			if (GranularityInit == 'Automatic') {
				if (differenceInMinutes <= 120) { // 2 Hours or less
					granularityBaseOnDate = 'Raw Data'
				} else if (differenceInMinutes <= 2880) { // 2 Day or less
					granularityBaseOnDate = '1 Minute'
				} else if (differenceInMinutes <= 10080) { // 4 Days or less
					granularityBaseOnDate = '5 Minutes'
				} else if (differenceInMinutes < 43200) { // 30 Days or less
					granularityBaseOnDate = '1 Hour'
				} else {
					granularityBaseOnDate = '1 Day'
				}
			} else {
				granularityBaseOnDate = GranularityInit
			}

			customGranularity = granularityBaseOnDate

			text = `${granularity == 'Automatic' ? `${granularityBaseOnDate} | ${aggregation}` : `${granularity} | ${aggregation}`}`
			
			if (GranularityInit == 'Automatic') {
				text = `${granularityBaseOnDate} | ${aggregation}`
			}

			granularityTmp = granularityBaseOnDate

			break
		case '':
			granularityTmp = ``
			text = `Click to Filter`
			DataSearchRange = [moment().subtract(48, "hours"), moment()]
			granularityTmp = isNotMapWidget ? granularity != 'Automatic' ? granularity : 'Raw Data' : mapGranularity
			break
		default:
			shouldQuery = false
	}

	return {
		shouldQuery: shouldQuery,
		granularityDisplay: text,
		granularityTmp: granularityTmp,
		DataSearchRange: DataSearchRange,
		customGranularity: customGranularity,
		timeRange: timeRange
	};
};




export const getAvailableGranularity = (
	DataSearchRange: [Moment, Moment],
	timeRangeValueLocal: any,
	applyLimits: boolean
) => {

	let AvailableGranularity = 0

	if (applyLimits) {

		switch (timeRangeValueLocal) {
			case 'Latest 30 minutes':
				AvailableGranularity = 0
				break
			case 'Latest hour':
				AvailableGranularity = 0
				break
			case 'Latest 4 hours':
				AvailableGranularity = 1
				break
			case 'Latest 12 hours':
				AvailableGranularity = 2
				break
			case 'Latest 24 hours':
				AvailableGranularity = 2
				break
			case 'Latest 48 hours':
				AvailableGranularity = 2
				break
			case 'Latest 3 days':
				AvailableGranularity = 3
				break
			case 'Latest 7 days':
				AvailableGranularity = 4
				break
			case 'Latest 30 days':
				AvailableGranularity = 5
				break
			case 'Custom':
	
				let differenceInMinutes = moment.duration(DataSearchRange[1].diff(DataSearchRange[0])).asMinutes();
				
				if (differenceInMinutes <= 60) { // below 1 hour show raw data
					AvailableGranularity = 0
				} else if (differenceInMinutes > 60 && differenceInMinutes < 240) { // up to 4 Hours
					AvailableGranularity = 1
				} else if (differenceInMinutes >= 240 && differenceInMinutes < 2880) { // up to 48 Hours
					AvailableGranularity = 2
				} else if (differenceInMinutes >= 2880 && differenceInMinutes < 10080) { // up to 7 Days 
					AvailableGranularity = 3
				} else if (differenceInMinutes >= 10080 && differenceInMinutes < 43200) { // up to 30 Days 
					AvailableGranularity = 5
				} else if (differenceInMinutes >= 43200 && differenceInMinutes < 86400 ) { // up to 60 Days 
					AvailableGranularity = 5
				} else if (differenceInMinutes >= 86400 && differenceInMinutes < 129600 ) { // up to 90 Days 
					AvailableGranularity = 6
				} else { // Greater than 90 days 
					AvailableGranularity = 7
				}
				break
			
			default:
				AvailableGranularity = 0
		}
	}
	
	return AvailableGranularity;
};

export const checkObjectKeyValuesNotEmpty = (obj:any) => {
	for (let key in obj) {
	  if (obj[key] === undefined || obj[key] === '' || obj[key] === null) {
		return false;
	  }
	}
	return true;
}