import { IssuesCloseOutlined, LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import { Drawer, List, Table, Tag } from "antd";
import React from "react";
import { connect } from "react-redux";
import * as actions from "./actions";


interface Prop{
    visible:boolean,
    closeFenceAssetsDrawer:Function,
    mastertags:any,
    fenceName:any,
    gatewaysData:any
}

const FenceAssets = (props: Prop) => {

    const columns = [
        {
            title:'Asset Name',
            dataIndex:'gatewayName',
            key: 'mastertag',
            sorter: (a:any, b:any) => { return a.fenceName.localeCompare(b.fenceName)},
            render:(text:any, record:any) => (
                <a href={'/assets/' + record.mastertag}> {record.gatewayName}</a>
            )
        },  
        {
            title:'Last Reported',
            dataIndex:'latest_position_status',
            key: 'latest_position_status',
            render: (text: any, record: any) => (
                <>
                    {
                        record?.latest_position_status?.status ?
                            <span>
                            {record.latest_position_status.status.includes('In') &&
                                <Tag icon={<LoginOutlined style={{ fontSize: 15 }} />} style={{fontSize: 15, padding: 3, marginBottom: 8}} color="#009e1d"> {record.latest_position_status.status} - {new Date(record.latest_position_status.updated_at).toLocaleString()}</Tag>}
                            {record.latest_position_status.status.includes('Out') &&
                                <Tag icon={<LogoutOutlined style={{ fontSize: 15 }} />} style={{fontSize: 15, padding: 3, marginBottom: 8}} color="#d60012"> {record.latest_position_status.status} - {new Date(record.latest_position_status.updated_at).toLocaleString()}</Tag>}
                            </span> :
                                <Tag icon={<IssuesCloseOutlined style={{ fontSize: 15 }} />} style={{fontSize: 15, padding: 3, marginBottom: 8}}> No Info</Tag>
                    }
                </>
            )
        }  
    ]

    return (

        <Drawer data-id="user-fence-assets-drawer"
            visible={props.visible}
            title="Geofence Assets Status"
            width={"400px"}
            zIndex={3}
            destroyOnClose={true}
            onClose={() => props.closeFenceAssetsDrawer()}
        >

            <Table dataSource={props.gatewaysData} columns={columns} rowKey={(record) => record.mastertag} />
            
            {/* <List 
                bordered 
                header={<h3>{"Geofence: " + this.props.fenceName}</h3>}
                dataSource={this.props.gatewaysData}
                renderItem={
                    (item: any) => (<List.Item><a href={'/assets/' + item.mastertag} >{item.gatewayName}</a></List.Item>)
                }
            /> */}

        </Drawer>

    );
}

const mapStateToProps = (state:any) => {

    return {
        visible: state.geofencesReducer.geofencesState.geofencesList.fenceAssets.visible,
        mastertags: state.geofencesReducer.geofencesState.geofencesList.fenceAssets.mastertags,
        fenceName: state.geofencesReducer.geofencesState.geofencesList.fenceAssets.fenceName,
        gatewaysData: state.geofencesReducer.geofencesState.geofencesList.fenceAssets.gatewaysData
    };

}


export default connect(
    mapStateToProps,actions
)(FenceAssets);


