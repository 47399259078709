import { produce as immerProduce } from "immer";
import { initialState } from "./state";
import { appConfigs } from "../../../utils/configurations";

export function templateDetailsState(state = initialState, action:any) {
    let data:any = null;

    switch (action.type) {    
        case "TEMPLATE_DETAILS_TAB_CHANGE":
            return immerProduce(state, draft => {
                draft.activeTab = action.tab;
            });                
        case "SIGNAL_COLLECTIONS_SET_PAGINATION":
            return immerProduce(state, draft => {
                draft.tabs[0].tableConfig!.pagination.onChange = action.onPaginationChange;
            });
        case "SIGNAL_COLLECTIONS_SET_SEARCH":
            return immerProduce(state, draft => {
                draft.tabs[0].searchText = action.searchText;
            });
        case "SIGNAL_COLLECTIONS_GET_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.tabs[0].refreshOnUpdate = false;
                draft.tabs[0].tableData = data;
                draft.tabs[0].tableConfig!.pagination.total = (data.length || 0) > 0 ? data[0].totalRecords : 0;
                draft.tabs[0].tableConfig!.pagination.current = action.payload.page;
            });        
        case "SIGNAL_COLLECTION_MAINT_OPEN":
            return immerProduce(state, draft => {
                const 
                    payload = action.payload,
                    instance = Math.random();
                draft.tabs[0].signalCollectionMaint!.instance = instance;
                draft.tabs[0].signalCollectionMaint!.width = payload.width;
                draft.tabs[0].signalCollectionMaint!.visible = true;
                draft.tabs[0].signalCollectionMaint!.title = (Object.keys(payload.record).length !== 0 ? "Signal Collection :: " + payload.record.name : "Signal Collection :: New");
                draft.tabs[0].signalCollectionMaint!.record = payload.record;                
                draft.tabs[0].signalCollectionMaint!.signalCollectionId = (Object.keys(payload.record).length !== 0 ? payload.record.signalCollectionId : null);                
                draft.tabs[0].signalCollectionMaint!.signalCollectionName =(Object.keys(payload.record).length !== 0 ?  payload.record.name : null);                
            });     
        case "SIGNAL_COLLECTION_MAINT_CLOSE":
            return immerProduce(state, draft => {
                draft.tabs[0].signalCollectionMaint!.visible = false;
            });     
        case "SIGNAL_COLLECTION_MAINT_SET_RECORD":
            return immerProduce(state, draft => {  
                const instance = Math.random();
                draft.tabs[0].signalCollectionMaint!.instance = instance;
                draft.tabs[0].signalCollectionMaint!.title = (Object.keys(action.record).length !== 0 ? "Signal Collection :: " + draft.tabs[0].signalCollectionMaint!.signalCollectionName : "Signal Collection :: New");
                draft.tabs[0].signalCollectionMaint!.record = action.record;                
                draft.tabs[0].signalCollectionMaint!.signalCollectionId = (Object.keys(action.record).length !== 0 ? action.record.signalCollectionId : null);                
                draft.tabs[0].signalCollectionMaint!.signalCollectionName = (Object.keys(action.record).length !== 0 ? action.record.name : null)               
            });                                 
        case "SIGNAL_COLLECTION_MAINT_SUCCESS":
            return immerProduce(state, draft => {
                draft.tabs[0].refreshOnUpdate = true;   
                draft.tabs[0].signalCollectionMaint!.record = action.record;   
                draft.tabs[0].signalCollectionMaint!.title = "Signal Collection :: " + action.record.name;
                draft.tabs[0].signalCollectionMaint!.signalCollectionId = action.record.signalCollectionId;
                draft.tabs[0].signalCollectionMaint!.signalCollectionName = action.record.name;  
                draft.signals.signalCollectionId = action.record.signalCollectionId;          
                draft.signals.signalCollectionName = action.record.name;
            });     
        case "TEMPLATE_DETAILS_GET_TEMPLATE_SUCCESS":
            return immerProduce(state, draft => {
                draft.templateRecord = action.record;
            });    
        case "TEMPLATE_DETAILS_MAINT_TEMPLATE_SUCCESS":
            return immerProduce(state, draft => {
                draft.templateRecord = action.record;
            });    
        case "TEMPLATE_DETAILS_DM1_FAULTS_SET_PAGINATION":
            return immerProduce(state, draft => {
                draft.tabs[1].tableConfig!.pagination.onChange = action.onPaginationChange;
            });
        case "TEMPLATE_DETAILS_DM1_FAULTS_SET_SEARCH":
            return immerProduce(state, draft => {
                draft.tabs[1].searchText = action.searchText;
            });
        case "TEMPLATE_DETAILS_DM1_FAULTS_GET_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.tabs[1].refreshOnUpdate = false;
                draft.tabs[1].tableData![action.payload.listIndex] = data;
                draft.tabs[1].tableConfig!.pagination.total = (data.length || 0) > 0 ? data[0].totalRecords : 0;
                draft.tabs[1].tableConfig!.pagination.current = action.payload.page;
            });                    
        case "TEMPLATE_DETAILS_DM1_MESSAGING_MAINT_SUCCESS":
            return immerProduce(state, draft => {
                draft.templateRecord = action.record;
            });    
        case "DM1_FAULTS_DELETE":
            return immerProduce(state, draft => {
                let data = draft.tabs[1].tableData![action.listIndex];
                draft.tabs[1].tableData![action.listIndex] = data.filter((record:any, index:number) => index !== action.index);
            });    
        case "DM1_FAULTS_DB_DELETE_SUCCESS":
            return immerProduce(state, draft => {
                let data = draft.tabs[1].tableData![action.listIndex];
                draft.tabs[1].tableData![action.listIndex] = data.filter((record:any) => action.dm1Id !== record.dm1Id);
            });    
        case "DM1_FAULTS_ADD_RECORD":
            return immerProduce(state, draft => {
                dm1FaultsAdd(
                    draft, 
                    state.tabs[1].tableData![action.payload.listIndex], 
                    action.payload.listIndex, 
                    {
                        dm1Id: "",
                        enabled: true,
                        sa: "",
                        spn: "",
                        fmi: "" 
                    }
                );
            });    
        case "DM1_FAULTS_ADD_RECORDS":
            return immerProduce(state, draft => {
                draft.tabs[1].tableData![action.listIndex]=action.data;
                dm1FaultsUploadProcessing(draft,action.listIndex);
                
                // action.data.forEach((record: any, index: number) => {
                //     dm1FaultsAdd(
                //         draft, 
                //         draft.tabs[1].tableData, 
                //         {
                //             dm1Id: "", 
                //             enabled: record.enabled,
                //             sa: record.sa,
                //             spn: record.spn,
                //             fmi: record.fmi
                //         }
                //     );
                // });
            });    
        case "DM1_FAULTS_SET_FIELD_VALUE":
            return immerProduce(state, draft => {
                dm1FaultsSetFieldValue(
                    draft, 
                    action.listIndex,
                    action.index, 
                    action.fieldName,
                    action.fieldValue
                )
            });
        case "SIGNAL_LIST_SET_SEARCH":
            return immerProduce(state, draft => {
                draft.signals.signalList.searchText = action.searchText;                
            });                    
        case "SIGNALS_OPEN":
            return immerProduce(state, draft => {
                const 
                    payload = action.payload,
                    instance = Math.random();
                draft.signals.instance = instance;
                draft.signals.width = payload.width;
                draft.signals.visible = true;
                draft.signals.title = payload.title;
                draft.signals.signalCollectionId = payload.signalCollectionId;                
                draft.signals.signalCollectionName = payload.signalCollectionName;   
                draft.signals.signalCollectionType = payload.signalCollectionType;     
                draft.signals.signalList.searchText = "";            
            });                
        case "SIGNALS_CLOSE":
            return immerProduce(state, draft => {
                draft.signals.visible = false;
                draft.signals.signalList.tableData = [];
                draft.signals.signalList.tableConfig!.pagination.total = appConfigs.tables.defaultTotal;
                draft.signals.signalList.tableConfig!.pagination.current = appConfigs.tables.defaultCurrent
            });      
        case "SIGNALS_GET_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.signals.title = "Signal Collection :: " + action.payload.signalCollectionName + ((data.length || 0) > 0 ? " (" + data[0].totalRecords + ")" : "");
                draft.signals.signalCollectionId = action.payload.signalCollectionId;
                draft.signals.signalCollectionName = action.payload.signalCollectionName; 
                draft.signals.signalCollectionType = action.payload.signalCollectionType;    
                draft.signals.signalList.refreshOnUpdate = false;
                draft.signals.signalList.tableData = data;
                draft.signals.signalList.tableConfig!.pagination.total = (data.length || 0) > 0 ? data[0].totalRecords : 0;
                draft.signals.signalList.tableConfig!.pagination.current = action.payload.page;
            });      
        case "SIGNAL_MAINT_OPEN":
            return immerProduce(state, draft => {
                const 
                    payload = action.payload,
                    instance = Math.random();
                draft.signals.signalMaint.instance = instance;
                draft.signals.signalMaint.width = payload.width;
                draft.signals.signalMaint.visible = true;
                draft.signals.signalMaint.title = (Object.keys(payload.record).length === 0 ? "Signal Collection :: " + draft.signals.signalCollectionName + " :: Add Signal" : "Signal :: Edit :: " + payload.record.label);
                draft.signals.signalMaint.record = payload.record;
                draft.signals.signalMaint.collectionType = payload.collectionType;              
            });     
        case "SIGNAL_MAINT_CLOSE":
            return immerProduce(state, draft => {
                draft.signals.signalMaint.visible = false;
                draft.signals.signalMaint.j1939s!.data = [];
                draft.signals.signalMaint.commandPgns!.data = [];
                draft.signals.signalMaint.commandControllers!.data = [];
            });     
        case "SIGNAL_MAINT_SUCCESS":
            return immerProduce(state, draft => {
                if (action.editMode === "insert") {
                    draft.tabs[0].refreshOnUpdate = true;
                }
                draft.signals.signalList.refreshOnUpdate = true;   
                draft.signals.signalMaint.record = action.record;   
                draft.signals.signalMaint.title = "Signal :: Edit :: " + action.record.label;
            });     
        case "SIGNAL_DELETE_SUCCESS":
            return immerProduce(state, draft => {
                draft.tabs[0].refreshOnUpdate = true;
            });                 
        case "SIGNAL_GET_J1939S_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                const payload = action.payload;
                draft.signals.signalMaint.j1939s!.data = payload.data;
            });    
        case "SIGNAL_GET_COMMAND_PGN_SUCCESS":
                data = action.payload.data;
                return immerProduce(state, draft => {
                    const payload = action.payload;
                    draft.signals.signalMaint.commandPgns!.data = payload.data;
                });  
        case "SIGNAL_GET_COMMAND_CONTROLLER_SUCCESS":
                data = action.payload.data;
                return immerProduce(state, draft => {
                    const payload = action.payload;
                    draft.signals.signalMaint.commandControllers!.data = payload.data;
                });                
        case "SIGNAL_MAINT_SET_RECORD":
            return immerProduce(state, draft => {
                const instance = Math.random();
                draft.signals.signalMaint.instance = instance;
                draft.signals.signalMaint.title = (Object.keys(action.record).length === 0 ? "Signal Collection :: Add Signal :: " + draft.signals.signalCollectionName : "Signal :: Edit :: " + action.record.label);
                draft.signals.signalMaint.record = action.record;    
                draft.signals.signalMaint.j1939s!.data = [];     
                draft.signals.signalMaint.commandPgns!.data = [];       
                draft.signals.signalMaint.commandControllers!.data=[];
            });
        case "PREFERED_SIGNALS_SUCCESS":
            return immerProduce(state, draft => {                
                draft.tabs[2].pinnedSignals!.id = action.payload.id;
                draft.tabs[2].pinnedSignals!.preferedSignals = action.payload.preferedSignals;                
            });          
        case "OPR_PREFERED_SIGNALS_SUCCESS":               
            return immerProduce(state, draft => {                
                draft.tabs[3].operatorSignals!.id = action.payload.id;
                draft.tabs[3].operatorSignals!.preferedSignals = action.payload.preferedSignals;                
            });                       
        case "QUOTAS_APPLIED_GET_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.tabs[4].refreshOnUpdate = false;
                draft.tabs[4].tableData = data;
            });
        case "Units_GET_SUCCESS":
            data = action.data;
                return immerProduce(state, draft => {
                  draft.signals.signalMaint.units!.data=data;
            });
        /*case "TEMPLATE_DETAILS_DM1_FAULTS_SET_IMPORT_FLAG":
            return immerProduce(state, draft => {
                draft.tabs[1].importFlag![action.payload.listIndex] = action.payload.val;
          }); */  //feature can track when csv file is imported        
        default:
            return state;
    }
}

const dm1FaultsAdd = (draft:any, data:any, listIndex: any,newRecord:any) => {   
    
    let recordFound:boolean = false;

    data.forEach(function (record:any) {
        if ((newRecord.enabled||newRecord.sa || newRecord.spn || newRecord.fmi) !== "" && record.enabled === newRecord.enabled && record.sa === newRecord.sa && record.spn === newRecord.spn && record.fmi === newRecord.fmi) {
            recordFound = true;
        }
    });  

    if (!recordFound) {
        draft.tabs[1].tableData[listIndex] = [newRecord, ...data];
    }
}

const dm1FaultsUploadProcessing = (draft:any, listIndex: any) => {   
    
    let recordFound:boolean = false;

    draft.tabs[1].tableData[listIndex].forEach(function (record:any) {
        record.dm1Id = "";
    });  

}

const dm1FaultsSetFieldValue = (draft:any, listIndex:number, index:number, fieldName:string, fieldValue:any) => {   
    draft.tabs[1].tableData[listIndex][index][fieldName] = fieldValue;
}