import React from "react";
import { Drawer, Button, Tabs, Space ,Popconfirm} from "antd";

import { connect } from "react-redux";
import * as actions from "./actions";

import { appConfigs } from "../../utils/configurations";

import AssetMaintInfo from "./assetMaintInfo/AssetMaintInfo";
import AssetMaintSubscriptions from "./assetMaintSubscriptions/AssetMaintSubscriptions";
import AssetMaintControllerInfo from "./assetMaintControllerInfo/AssetMaintControllerInfo";
import AssetGatewaySettings from "./assetGatewaySettings/AssetGatewaySettings";
import AssetContractsDetails from "./AssetContractsDetails/AssetContractsDetails";
import { FormInstance } from 'antd/lib/form';


const { TabPane } = Tabs;

interface Prop {
    getAssets: Function,
    assetMaintClose: Function,    
    assetMaintTabChange: Function,
    assetsList: any,
    context: any,
    iscreate: boolean,
    isupdate: boolean,
    isread: boolean
}

// //Rollback gateway replacement changes
interface State {
    enableMtag: boolean
}

class AssetMaint extends React.Component<Prop, State> {  

    formRef=React.createRef<FormInstance>();
    state: State = {
        enableMtag: false
    }
//class AssetMaint extends React.Component<Prop, {}> {
    onDrawerClose = () => {
        if (this.props.assetsList.refreshOnUpdate) {
            this.props.getAssets(
                this, null, null, 
                this.props.assetsList.tableConfig.pagination.current, 
                this.props.assetsList.status, this.props.assetsList.searchText, 
                this.props.assetsList.alertInterval, this.props.assetsList.gatewayId,
                true
            );
        }
        this.props.assetMaintClose(this);
    };

    onTabChange = (key:string) => {
        this.props.assetMaintTabChange(this, key);
    }

    //Rollback gateway replacement changes
    confirmMtagReplacement = () => (
        <Popconfirm
            title={"Do you really want to replace the asset?"}
            onConfirm={(e) => {this.submitReplacement()}}
            okText="Yes"
            cancelText="No"
        >
            <Button data-id="assets-maint-submit-button" type="primary">
                                {appConfigs.settings.form.submitButtonText}
                            </Button>
        </Popconfirm>
    );

    onMtagReplacement=(isEnable:boolean)=>{
       this.setState({enableMtag:isEnable})
    }

    assetMaintFormRef=(form:any)=>{
        this.formRef=form;
    }

     submitReplacement=()=>{
         this.formRef.current!.submit();
     }
    


    render() {
        
        const { assetMaint } = this.props.assetsList;
        const { user:contextUser, appContext} = this.props.context;
        let isBarko = false;

        if(appContext.roleName.toLowerCase() === "barko_guest"){
            const barkoId = '17ad9f98-33df-48a0-8c4f-99468e22e129';           
            for(let i=0; i<this.props.context.user.homeOrg.orgTree.length; i++){                           
                if(this.props.context.user.homeOrg.orgTree[i].orgId === barkoId){
                    isBarko = true;
                }
            }
        }
          
        return (
            <Drawer
                data-id="user-maint-drawer" 
                title={assetMaint.title}
                width={assetMaint.width}
                onClose={this.onDrawerClose}
                visible={assetMaint.visible}
                destroyOnClose={true}
                footer={
                    <Space size="large" style={{ float: 'right' }}>
                        <Button data-id="assets-maint-close-button" type="default" onClick={this.onDrawerClose}>
                            {appConfigs.settings.drawers.closeButtonText}
                        </Button>
                        {
                            assetMaint.activeTab === "3" &&
                            ( /* 
                                 A) any admin-like or create asset permission
                                 */
                                // B.Object.keys(assetMaint.record).length === 0 &&
                                ((assetMaint.record.status || "") !== "whitelisted") && ((assetMaint.record.status || "") !== "inactive") && 
                                (assetMaint.record.objectAdmin || this.props.iscreate || this.props.isupdate)
                            ) &&
                            <Button data-id="assets-settings-submit-button" type="primary" form="assetMaintSettingsForm" key="submit" htmlType="submit">
                                {appConfigs.settings.form.submitButtonText}
                            </Button>
                        }
                        {
                            assetMaint.activeTab === "1" &&
                            ( /* A) Whitelisted
                                 B) any admin-like or create asset permission
                                 */
                                // Object.keys(assetMaint.record).length === 0 &&
                                (assetMaint.record.status || "") === "whitelisted" && 
                                (assetMaint.record.objectAdmin || this.props.iscreate || this.props.isupdate)
                            ) &&
                            <Button data-id="assets-maint-submit-button" type="primary" form="assetMaintInfoForm" key="submit" htmlType="submit">
                                {appConfigs.settings.form.submitButtonText}
                            </Button>
                        }
                        {
                            assetMaint.activeTab === "1" &&

                            ( /* A) A user role can view but not edit (submit); therefore user will not see submit button. 
                                 B) Barko guest (isBarko=true) can submit
                                 C) ignore record_length ?
                                 D) any admin-like or create/update asset permission for non-whitelisted asset
                                 E) Asset is not inactive
                                 */
                                isBarko  || 
                                 ((assetMaint.record.status || "") !== "whitelisted") && ((assetMaint.record.status || "") !== "inactive") && 
                                    (assetMaint.record.objectAdmin || this.props.iscreate || this.props.isupdate)
                            ) && !this.state.enableMtag &&
                            <Button data-id="assets-maint-submit-button" type="primary" form="assetMaintInfoForm" key="submit" htmlType="submit">
                                {appConfigs.settings.form.submitButtonText}
                            </Button>
                        }
                        {/* Rollback gateway replacement changes */}
                        {
                            assetMaint.activeTab === "1" &&

                            ( /* A) A user role can view but not edit (submit); therefore user will not see submit button. 
                                 B) Barko guest (isBarko=true) can submit
                                 C) ignore record_length ?
                                 D) any admin-like or create/update asset permission for non-whitelisted asset
                                 E) Asset is not inactive
                        */ 
                                  isBarko  || 
                                 ((assetMaint.record.status || "") !== "whitelisted") && ((assetMaint.record.status || "") !== "inactive") && 
                                    (assetMaint.record.objectAdmin || this.props.iscreate || this.props.isupdate)
                            ) && this.state.enableMtag &&
                            this.confirmMtagReplacement()
                          
                        } 
                        {
                            assetMaint.activeTab === "5" &&

                            <Button data-id="assign-service-plan-to-gateway" type="primary" form="assignSerivePlanToGateway" key="submit" htmlType="submit">
                                Assign
                            </Button>
                        } 
                    </Space>
                }
            >
                {
                //     (
                //         Object.keys(assetMaint.record).length === 0
                //     ) &&
                //     <Tabs data-id="assets-maint-asset-info-tabs" activeKey={assetMaint.activeTab} size="large" type="card" onChange={this.onTabChange}>                    
                //         <TabPane data-id="assets-maint-asset-info-tab-button" tab={assetMaint.tabs[0].title} key="1" disabled={assetMaint.tabs[0].disabled}>                    
                //             <AssetMaintInfo guestRole={isBarko}/>
                //         </TabPane>  
                //     </Tabs>   
                }         
                {
                    (
                        isBarko || appContext.roleName === "user" ||
                        assetMaint.record.objectAdmin || this.props.iscreate || this.props.isupdate 
                    ) &&
                    <Tabs data-id="assets-maint-asset-info-tabs" activeKey={assetMaint.activeTab} size="large" type="card" onChange={this.onTabChange}>                    
                        <TabPane data-id="assets-maint-asset-info-tab-button" tab={assetMaint.tabs[0].title} key="1" disabled={assetMaint.tabs[0].disabled}>                    
                            {/* <AssetMaintInfo guestRole={isBarko}/> */}
                            <AssetMaintInfo guestRole={isBarko} assetMaintFormRef={this.assetMaintFormRef} onMtagReplacement={this.onMtagReplacement}/>
                        </TabPane> 
                        {
                            /* Note: DB function controls the content of the subscription by the alert_settings read/edit permission
                             therefore, UI only needs to check assets update permission to determine whether to show the subscription tab */
                            /* alert subscription is only applicable to active asset */
                            /* whitelisted assets (or create asset) permission does not apply to subscription*/
                            (assetMaint.record.status || "") !== "whitelisted" && 
                            Object.keys(assetMaint.record).length !== 0 && /* this line controls whether subscription tab shown up in whitelist new asset drawer */
                            (
                              this.props.iscreate || this.props.isupdate
                                ) && 
                                <>
                                    
                                    <TabPane data-id="assets-maint-subscriptions-tab-button" tab={assetMaint.tabs[1].title} key="2" disabled={assetMaint.tabs[1].disabled || assetMaint.record.status == 'inactive'}>
                                        <AssetMaintSubscriptions />
                                    </TabPane>                          
                                </>
                        }
                        {
                            (appConfigs.app.enableGatewaySettings)&&((assetMaint.record.status || "") !== "whitelisted") &&
                            // ( this.props.context.appContext.orgId == 'c6adcb40-be92-11e6-9ed6-a5bc9cb5279b' && 
                                (assetMaint.record.allowWifiClient || assetMaint.record.allowViewSleepMode)
                            //)
                             &&
                            (assetMaint.record.objectAdmin || this.props.iscreate || this.props.isupdate)
                            &&
                            <TabPane data-id="assets-wifi_client-tab-button" tab={assetMaint.tabs[2].title} key="3" disabled={assetMaint.tabs[2].disabled || assetMaint.record.status == 'inactive'}>                    
                                <AssetGatewaySettings />
                            </TabPane>
                        } 
                        {
                            /* whitelisted assets (or create asset) permission does not apply to controllers*/
                            (assetMaint.record.status || "") !== "whitelisted" && 
                            Object.keys(assetMaint.record).length !== 0 && /* this line controls whether subscription tab shown up in whitelist new asset drawer */
                            (this.props.iscreate || this.props.isupdate) &&                                  
                            (this.props.context.appContext.orgId == 'c6adcb40-be92-11e6-9ed6-a5bc9cb5279b') &&
                            <TabPane data-id="assets-iqanopen-tab-button" tab={assetMaint.tabs[3].title} key="4" disabled={assetMaint.tabs[3].disabled || assetMaint.record.status == 'inactive'}>
                                <AssetMaintControllerInfo />
                            </TabPane> 
                        }  

                        {
                            /* whitelisted assets (or create asset) permission does not apply to controllers*/
                            Object.keys(assetMaint.record).length !== 0 && /* this line controls whether subscription tab shown up in whitelist new asset drawer */
                            (this.props.iscreate || this.props.isupdate) &&                                  
                            (this.props.context.user.contextUserOrgIsBillableCustomer || this.props.context.user.contextOrgHasTransferredContracts) &&
                            <TabPane data-id="assets-iqanopen-tab-button" tab={assetMaint.tabs[4].title} key="5">
                                <AssetContractsDetails />
                            </TabPane> 
                        }      
                    </Tabs>   
                }         
                {/* {                    
                    // Object.keys(assetMaint.record).length !== 0 && 
                    // !assetMaint.record.objectAdmin && !isBarko && appContext.roleName !== "user" &&
                    (Object.keys(assetMaint.record).length !== 0 || this.props.iscreate || this.props.isupdate ) &&
                    
                    <Tabs data-id="assets-maint-asset-info-tabs" activeKey="2" size="large" type="card">                    
                        <TabPane data-id="assets-maint-subscriptions-tab-button" tab={assetMaint.tabs[1].title} key="2">
                            <AssetMaintSubscriptions />
                        </TabPane>  
                    </Tabs>   
                }      */}
            </Drawer>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        assetsList: state.assetsReducer.assetsState.assetsList
    };
};

export default connect(
    mapStateToProps,
    actions
)(AssetMaint);