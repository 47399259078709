import React, { useEffect, useState} from 'react';
import { Modal, Row, Col,Button, message, Alert } from 'antd';
import { appConfigs } from '../utils/configurations';
import { withOktaAuth } from '@okta/okta-react';
import {OktaAuth} from '@okta/okta-auth-js'
import { clientStorageClear, getToken, loadingIndicatorStart, getPermissions } from '../utils/commonHelpers';
import moment, { min } from 'moment';
import history from '../utils/history';
import { connect } from "react-redux";
import {setOktaLastLogin} from "../pages/Users/actions";

interface Props {
    authService?: any,
    setOktaLastLogin:Function,
    context: any,
    permissions:any,
}
export const authConfig = {
    pkce: true,
    issuer: appConfigs.okta.oidc.issuer,
    clientId: appConfigs.okta.oidc.clientId,
    redirectUri: window.location.origin
}

const TimeOutHandler: React.FC<Props> = (props)=>{
    const authClient = new OktaAuth(authConfig);
    const[showExpiredModal,setExipredModal]=useState(false);
    const[showNotExpiredModal,setNotExipredModal]=useState(false);
    const[seconds,setSecs]=useState('');
    const[isLastMinute,setLastMinute]=useState(false);
    const[minutes,setMin]=useState(0)
    const[loading,setLoad]=useState(false);
    const [events, setEvents] = useState(['click', 'load', 'scroll']);
    let timer:any=undefined;
    const SECOND_MS = 1000; //execution time
    const pad = (n:number) => ('0' + n).slice(-2);
    const timeoutsettings = getPermissions(props.permissions.permissions.timeout_settings);
    let cantimeoutRead = timeoutsettings[1];
    const startTimer=()=>{
        if(timer){
            clearInterval(timer)
        }
        timer=setInterval(()=>{
            let token=getToken();
            if(token==null || isLastMinute)
            {
                clearInterval(timer);
                setNotExipredModal(false);
            }
            else
            {
                if (cantimeoutRead) {
                   return;
                 }
                const expirationTime = new Date(token.expiresAt * 1000);
                const now = new Date();
               // const minute = 1000 * 60 * 4;
                const diff=moment.duration(expirationTime.getTime()-now.getTime());
                const diffMin = diff.minutes();
                const diffSecs = diff.seconds();
                 if( diffMin < 15){
                        setMin(diffMin);
                        setSecs(pad(Math.sign(diffSecs)==-1?0:diffSecs));
                        if(diffMin==0 && Math.sign(diffSecs)==-1){
                            setLastMinute(true);
                            setExipredModal(true);
                        }
                        else
                        {
                            setNotExipredModal(true);
                        }
                    }else{
                        setNotExipredModal(false);
                        startTimer();
                    }
                
            }
          
        },SECOND_MS)
    }
  
      
    const renewToken=()=>{
        authClient.token.getWithoutPrompt()
        .then(function(res) {
        var tokens = res.tokens;
        //authClient.tokenManager.add('accessToken', token);
        authClient.tokenManager.setTokens(tokens);
        props.setOktaLastLogin(null,setOktaLastLoginSuccess,setOktaLastLoginFailure,props.context.user.oktaUserId);
        });
    }

    const setOktaLastLoginSuccess=()=>{
        resetSessionProps();
    }

    const setOktaLastLoginFailure=()=>{
        startTimer();
        resetSessionProps();
    }

    const resetSessionProps=()=>{
        deleteRefreshFlags();
        setNotExipredModal(false)
        setExipredModal(false);
        setLoad(false);  
    }

    const handleReset = ()=>{
        setLoad(true);
        renewToken();
    }
    useEffect(()=>{
        let isPageRefreshed=localStorage.getItem("isPageRefreshed") || "";

        if(cantimeoutRead){
     
            isPageRefreshed = "NOT-EXPIRED";
     
        //dev/stage msgiotdevuser18@outlook.com, prod: adminObject16@gmail.com, ssittp@taylorbigred.com
        if(isPageRefreshed!=="")
        {
            setNotExipredModal(false);
            renewToken();
        }
        subscribeEvents();
        startTimer();
        return (()=>{
            unsubscribeEvents();
            clearInterval(timer);
        })
        }
    });
    
    const handleRelogin=()=>{
        clearInterval(timer);
        setExipredModal(false);
        clientStorageClear();
        loadingIndicatorStart();
        localStorage.setItem("pathname",history.location.pathname)
        props.authService.logout(window.location.origin + "/login");
    }

    const subscribeEvents=()=>{
        window.addEventListener("beforeunload", setRefreshFlags);
        events.forEach((event) => {
            window.addEventListener(event, resetTimer);
         });
    }

    const unsubscribeEvents=()=>{
        window.removeEventListener("beforeunload", deleteRefreshFlags);
        events.forEach(eventName=>{
            window.removeEventListener(eventName,resetTimer)
        })
    };

    const resetTimer=(eventType:any)=>{
        if(showNotExpiredModal)
        {
          renewToken();
        }
        else
        {
            startTimer();
        }
    }

    const setRefreshFlags= () => {
        localStorage.setItem("pathname",history.location.pathname)
        localStorage.setItem("isPageRefreshed","true");
    };

    const deleteRefreshFlags=()=>{
        localStorage.removeItem("pathname");
        localStorage.removeItem("isPageRefreshed");
    }

    return (
        <>
        { 
                !cantimeoutRead ?<Modal
                data-id="session-timeout-error"
                title="IQAN Connect"
                visible={showExpiredModal}
                closable={false}
                footer={
                    <>
                      
                            <div className="mt-15">
                            <Button key="Logout" type="primary" onClick={handleRelogin}>
                            Ok
                            </Button>
                        </div>
                    
                       
                    </>
                }
                >
                       
                    <Row gutter={16}>
                    <Col xs={21}>
                        <div><h4>Session expired. Please login again.</h4></div>
                        
                    </Col>
                    </Row>

                </Modal>:""
        }
        {
            showNotExpiredModal && !cantimeoutRead ?
            <div className="session-info">
             <span>Your session expires in  <strong>{minutes+1}:{seconds}</strong> minutes</span>
           
               <Button type="primary" loading={loading} size="small" onClick={handleReset}>
                    Reset
               </Button>
            
            </div>:""
           
        }
        </>
       

    );
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        permissions: state.contextReducer.data,
    };
};

export default connect(
    mapStateToProps,
    { 
        setOktaLastLogin
      
    })(withOktaAuth(TimeOutHandler));
