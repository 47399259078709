import React from "react";
import { Form, Select, Spin } from 'antd';

import { appConfigs } from "../../../utils/configurations";

import { connect } from "react-redux";
import * as actions from "../actions";

const { Option } = Select;

interface Prop {
    scheduleMaintInfoUsersGet: Function,
    scheduledList: any,
    context: any,
    formRef: any,
    subscribers: string[]
}

interface State {
    loading: boolean;
}

class ScheduleMaintInfoUsers extends React.Component<Prop, State> {

    state: State = {
        loading: true
    };

    componentDidMount() {
        this.componentUpdate();
    }

    componentDidUpdate(prevProps: Prop) {

        let usersList = this.props.scheduledList.scheduledMaint.users;

        if (this.props.scheduledList.scheduledMaint.instance !== prevProps.scheduledList.scheduledMaint.instance) {
            this.componentUpdate();
        }
        else if (usersList && usersList.length > 0 &&
            this.props.subscribers.length > 0 &&
            this.props.scheduledList.scheduledMaint.mode === "edit") {

            this.props.formRef.setFieldsValue({
                subscribe: this.props.subscribers
            })
        }

        if (!usersList && this.state.loading){
            this.setState({ loading: false });
        }
        else if (usersList && usersList.length > 0 && this.state.loading) {
            this.setState({ loading: false });
        }
        
    }

    componentUpdate() {
        this.props.scheduleMaintInfoUsersGet(this, null, null, null, null);
    }

    filterUsersArray(element: any, index: number, array: any) {
        return element.activeInScope == true;
    }


    render() {

        const { users } = this.props.scheduledList.scheduledMaint;

        let finalUsers = [];
        if (users && users.length > 0) {
            finalUsers = users.filter(this.filterUsersArray);
        }

        return (
            <Spin spinning={this.state.loading}>
                <Form.Item
                    name="subscribe"
                    label="Subscribe"
                    rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                >
                    <Select
                        data-id="schedule-maint-schedule-info-user-id"
                        mode="multiple"
                        showSearch
                        showArrow={true}
                        allowClear
                        placeholder="Select user"
                        optionFilterProp="children"
                        className="select-before"
                    >
                        {finalUsers && finalUsers.length > 0 && finalUsers.map((record: any, index: number) => (
                            <Option key={record.userId} value={record.userId}>
                                {record.fullName} ({record.email})
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Spin>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        context: state.contextReducer.context,
        scheduledList: state.reportSchedulesReducers.reportScheduledState.scheduledList
    };
};

export default connect(
    mapStateToProps,
    actions
)(ScheduleMaintInfoUsers);