import { SignalCollection, Template } from "../models";
import { State, WidgetState } from "../state";

interface CsvHeader {
  label: string;
  key: string;
}

function fetchSignalDetails(
  event: any,
  datapointName: string,
  mastertag: string,
  name: string,
  org: string
) {
  const { timestamp, signals = [] } = event;
  let eventsArray = signals.reduce((cum: object, elem: any) => {
    let name = elem.name;
    if (elem.uom !== "") {
      name += `(${elem.uom})`;
    }
    return { ...cum, [name]: elem.value };
  }, {});
  return {
    ...eventsArray,
    timestamp: timestamp,
    mastertag: mastertag,
    assetName: name,
    orgName: org,
    signalCollectionName: datapointName,
  };
}

function createHeader(arrayOfObj: any, headerArray: any) {
  let notPresentKeys = Object.keys(arrayOfObj).filter((k: string) => {
    return headerArray.findIndex((s: any) => s.key == k) === -1;
  });
  const headerKeys = notPresentKeys.reduce((cum1: any, elem1: any) => {
    return [...cum1, { label: elem1, key: elem1 }];
  }, []);
  headerArray = [...headerArray, ...headerKeys];
  return headerArray;
}
//sort the export response data by signal collection order
function sortResponse(response: any, template: Template) {
  let sortedExportEvents: any = [];
  const { signalCollections } = template;
  const newSignalCollections = [...signalCollections];
  if (signalCollections.length > 0) {
    const sortedSignalCollections = sortSignalCollection(newSignalCollections);
    sortedSignalCollections.forEach((current: SignalCollection) => {
      const newResponse = response.filter(
        (elem: any) => elem.datapointId === current.id
      );
      if (newResponse.length > 0) {
        sortedExportEvents = [...sortedExportEvents, newResponse[0]];
      }
    });
  }
  return sortedExportEvents;
}
//sort the signal collection by alphabet.
function sortSignalCollection(
  signalCollections: SignalCollection[]
): SignalCollection[] {
  const sortedSignalCollections = signalCollections.sort(
    (elem1: SignalCollection, elem2: SignalCollection) => {
      return elem2.name.toLocaleLowerCase() < elem1.name.toLocaleLowerCase()
        ? 1
        : elem2.name.toLocaleLowerCase() > elem1.name.toLocaleLowerCase()
        ? -1
        : 0;
    }
  );
  return sortedSignalCollections;
}

export function prepareWidgetEvents(
  response: any,
  state: State,
  widgetState: WidgetState
) {
  const { template } = state;
  const { mastertag, name, orgName } = state.asset;
  if (template.signalCollections.length > 1) {
    response = sortResponse(response, template);
  }
  let headerArray: CsvHeader[] = [
    { label: "Time Stamp (UTC)", key: "timestamp" },
    { label: "Organization Name", key: "orgName" },
    { label: "Master Tag", key: "mastertag" },
    { label: "Asset Name", key: "assetName" },
    { label: "Signal Collection Name", key: "signalCollectionName" },
  ];

  const eventsArrays = response.reduce((eventArray: any, curElemenet: any) => {
    let isHeaderKeysAdded = false;
    const singleEventArray = curElemenet.datapointRecords.reduce(
      (eventDataArray: any, current: any) => {
        const arrayOfObj = fetchSignalDetails(
          current,
          curElemenet.datapointName,
          mastertag,
          name,
          orgName
        );
        
        headerArray = createHeader(arrayOfObj, headerArray);
        eventDataArray = [...eventDataArray, arrayOfObj];
        return eventDataArray;
      },
      []
    );
    eventArray = [...eventArray, ...singleEventArray];
    return eventArray;
  }, []);

  return {
    eventHeader: headerArray,
    eventArray: eventsArrays,
  };
}
