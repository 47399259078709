import { useState, useEffect } from "react";
import scriptCache from "../../lib/script-cache";

export type GoogleMapsLib = typeof google.maps;

declare global {
    interface Window {
        google: typeof google;
    }
}

const GOOGLE_MAP_KEY = "AIzaSyChUP2Cm0aMo89e0mE4LBZSbaXOW-Al9dI";
const GOOGLE_URL = `https://maps.googleapis.com/maps/api/js?libraries=drawing,geometry&key=${GOOGLE_MAP_KEY}`;

function loadLibs() {
    return scriptCache.loadScript(GOOGLE_URL);
}

export default function useGoogleMapsLib(): GoogleMapsLib | undefined {
    const [lib, setLib] = useState<GoogleMapsLib>();

    useEffect(() => {
            loadLibs().then(() => setLib(google.maps));
        }, 
        []
    );

    return lib;
}
