import React, { useState } from "react";
import { inputSignalMapper, signalLabel } from "../common";
import { CommonWidgetProps } from "../../models";
import { ReactComponent as maximizeIcon } from "../../../../assets/images/maximize.svg";
import {
  Map,
  Marker,
  MapActions,
} from "../../../../sharedComponents/GoogleMap";
import { toolTipForAsset } from "./Tooltip";
import MapPin from "../../../../assets/images/map-pin.png";
import { Tooltip, Button } from "antd";
import Icon, { BorderOuterOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import * as actions from "../../../../actions";

interface Signal {
  signalId: string;
  name: string;
  values: Array<{
    timestamp: Date;
    value: number;
  }>;
}

function assetToMarker(signals: Signal[], timezone?: any): Marker[] {

    if (!signals || signals.length === 0) {
      return [];
    }
    const latitude = signals.find((s) => s.name.toLowerCase().includes('latitude'));
    const longitude = signals.find((s) => s.name.toLowerCase().includes('longitude'));
    if (!latitude || !longitude) {
      return [];
    }
    const latitudeValues = latitude.values;
    const longitudeValues = longitude.values;
    const markers = latitudeValues
      .map((s, i) => {
        const lat = s?.value ?? 0;
        const timestamp = s?.timestamp ?? new Date();
        const lngData = longitudeValues[i];
        const lng = lngData?.value ?? 0;
        
     //console.log(`Processing marker ${i}: lat=${lat}, lng=${lng}`);
     // Skip markers where lat or lng is zero
     if (lat === 0 || lng === 0) {
       //console.log(`Skipping marker ${i}: lat=${lat}, lng=${lng}`);
       return null;
     }
     return {
       id: `${i}`,
       position: {
         lat: lat,
         lng: lng,
       },
       tooltip: toolTipForAsset({ lat, lng }, timestamp, undefined, timezone),
       icon: MapPin,
     } as Marker;
   })
   .filter((marker): marker is Marker => marker !== null); // Filter out null values
    //console.log(`Markers created: ${markers.length}`);
    return markers;
}
const createSignals = inputSignalMapper<Signal>((meta, data) => ({
  signalId: meta.id,
  name: signalLabel(meta, false),
  values: data,
}));

const Widget: React.FC<CommonWidgetProps> = (props) => {
  const { template, data, state } = props;
  let signals = createSignals(props.signals, template, data);
  const [mapActions, setMapActions] = useState<MapActions>();
  /* updating with live value if present (SignalR) */
  if (Object.keys(props.signalRData.data).length > 0) {
    signals = signals.map((signal: Signal) => {
      if (props.signalRData.data.hasOwnProperty(signal.signalId)) {
        props.signalRData.data[signal.signalId].forEach((signalData:any, index: number) => {
          signal.values.push({
            value: signalData.value,
            timestamp: new Date(signalData.bt - signalData.time)
          })
        });
      }
      return signal
    })
  }

  return (
    <div style={{ height: "100%", position: "relative" }}>
      {signals.length > 1 ? (
        <Map
          data-id="map-container"
          markers={assetToMarker(signals,state.timezone)}
          clusterMarkers={false}
          mapActionsRef={setMapActions}
          mapOptions={{ mapTypeId: "satellite" }}
          polyline={true}
          drawOverlays={false}
        ></Map>
      ) : (
        <></>
      )}
      {signals.length > 1 ? (
         <Tooltip 
         title="Zoom to Fit" 
         placement="left"
     >
         <Button
             data-id="map-zoom-to-fit"
             size="large"
             shape="circle"
             icon={<Icon component={maximizeIcon} style={{fontSize: 22, marginRight: 1, marginTop: 2 }} />}
             onClick={() => {
                 //props.mapsAssetDrawerClose();
                 if (mapActions) {
                     mapActions.zoomToFit();
                 }
             }}
         />
     </Tooltip>  
 

) : (
  <></>
)}
    </div>
  );
};

// export default Widget;
const mapStateToProps = (state: any) => {
  return {
    signalRData: state.contextReducer.signalRData,
  };
};
export default connect(
  mapStateToProps,
  // actions
)(Widget);