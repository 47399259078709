import { appConfigs } from "../../../../utils/configurations";

export const initialState = 
{
  softwares: 
  {
    controllersList: [],
    softwaresModification: 
    {
      softwaresList: null,
      loadingSoftwaresList: true,
      selectedSoftwareList: [] as any,
      submittingSoftwareList: false,
    },
    softwareMaintOptions: 
    {
      instance: 0,
      width: 720,
      visible: false,
      title: "",
      record: {},
      orgs: [],
      devicemodel:"",
      softwaretype:"",
      activeTab: "1",
      tabs: [
        {
          title: "Software Details",
          instance: 0,
          disabled: false,
        },
      ],
    },
    activeTab: "1",
    softwaresList: 
    {
      softwareMaint: 
      {
        instance: 0,
        width: 720,
        visible: false,
        record: {},
        title: "",
        activeTab: "1",
        tabs: [
          {
            title: "Software Details",
            disabled: false,
          },
        ],
      },
      searchText: "",
      refreshOnUpdate: false,
      columnsFilters: 
      {
        status: ["active", "inactive"],
      },
      tableData: [],
      tableConfig: 
      {
        pagination: 
        {
          showSizeChanger: appConfigs.tables.showSizeChanger,
          showQuickJumper: appConfigs.tables.showQuickJumper,
          pageSize: appConfigs.tables.pageSize,
          defaultCurrent: appConfigs.tables.defaultCurrent,
          current: appConfigs.tables.defaultCurrent,
          total: appConfigs.tables.defaultTotal,
          hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
          responsive: appConfigs.tables.responsive,
          onChange: null,
          showLessItems: appConfigs.tables.showLessItems,
          showTotal: (total: number, range: Array<number>) =>
            `${range[0]} - ${range[1]} of ${total} software${
              total > 1 ? "s" : ""
            }`,
        },
      },
    },
  },
};
