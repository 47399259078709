import React, { useEffect, useRef, useState } from 'react'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import '@amcharts/amcharts4/charts';
import am4themes_material from "@amcharts/amcharts4/themes/material";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import './fleetOverview.css'

import { connect, useDispatch } from "react-redux";
import * as rootActions from "../../actions";
import { Button, Card, Col, message, Row, Select, Statistic, Table, Tooltip } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { getPercentageChange, numberWithCommas } from '../../utils/commonHelpers';
import moment from 'moment';

am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);


export function useClickOutsideStats(ref: any) {

    const dispatch = useDispatch()
    
    useEffect(() => {

        function handleClickOutside(event: any) {
            /* prevent close on tooltip and select or on click outside */
            let doesCloseOn = [
                'ant-tooltip-inner',
                'ant-select-item-option-content',
                'btn-flOv',
                'ant-btn header-stats-big ant-btn-ghost',
                'ant-select-item ant-select-item-option ant-select-item-option-active ant-select-item-option-selected',
                'ant-select-item-empty',
                'ant-empty-description',
                'ant-empty-image',
                'ant-empty-img-simple-path',
                'ant-empty ant-empty-normal ant-empty-small',
            ]

            setTimeout(() => {
                if (ref.current && (!ref.current.contains(event.target) && !doesCloseOn.includes(event.target.className)) && ref.current.className == 'statsLayoutOpen') {
                    dispatch({ type: "SET_FLEET_STATS_VISIBILITY", visible: false });
                    dispatch({ type: "SET_NOTIFICATION_VISIBILITY", visible: false });
                }
            }, 20);
            
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

interface Props {
    fleetStatsVisibility: Function,
    updateDm1DailyCount: Function,
    updateDm1HeatMap: Function,
    stats: any
}


const FleetStatsOverview = (props: Props) => {

    const wrapperRef = useRef(null);
    useClickOutsideStats(wrapperRef)

    const { data } = props.stats
    const { dm1DailyCount, dm1HeatMapData } = props.stats

    const [selectedMasterTag7Days, setSelectedMasterTag7Days] = useState("")
    const [selectedMasterTagHeatMap, setSelectedMasterTagHeatMap] = useState("")
    const [DM1Percent, setDM1Percent] = useState(getPercentageChange(data.dm1CountYesterday , data.dm1Count))
    const [alertsPercent, setAlertsPercent] = useState(getPercentageChange(data.criticalAlertsCountYesterday, data.criticalAlertsCount))

    const getDm1DailyCountByAsset = (mastertag: string) => {
        setSelectedMasterTag7Days(mastertag)
        props.updateDm1DailyCount(mastertag, dailyCountGetFailure)
    }

    const getDm1DailyCountHeatMap = (mastertag: string) => {
        setSelectedMasterTagHeatMap(mastertag)
        props.updateDm1HeatMap(mastertag, dailyCountGetFailure)
    }

    const dailyCountGetFailure = (error:any) => {
        const errorCode:number = !error.response ? 999 : (error.response.status || 999);

        if (errorCode === 401) {
            message.error("Unauthorized data access.");
        }
        
        if (errorCode > 400) {
            message.error("Error while retrieving data please try again");
        } else {
            message.error("Unable to retrieve requested data");
        }
    }

    const pad = (number:number) => {
        return (number < 10) ? ("0" + number) : number;
    }

    let DayNameArray = [moment().format('ddd').toUpperCase()]
    for (let index = 1; index < 7; index++) {
        DayNameArray.push(moment().subtract(index, 'day').format('ddd').toUpperCase())
    }

    /* update heatmap */
    useEffect(() => {

        let weeklyHeatMapData: any = [];
       
        if (dm1HeatMapData.length > 0) {

            if (dm1HeatMapData) {

                let AMPM = 'AM';
                let Hours = 0;
                let tmp = []
                for (let day = 0; day < 7; day++) { //0=Today's date
                    for (let hour = 0; hour <= 23; hour++) { //00=12am
                        if (hour >= 12) {
                            AMPM = 'PM'; Hours = hour - 12;
                            if (hour == 12) {
                                Hours = hour;
                            }
                        } else {
                            AMPM = 'AM';
                            if (hour == 0) {
                                Hours = 12
                            } else {
                                Hours = hour
                            }
                        }

                        tmp = dm1HeatMapData.filter((item: any) => item['dayHour'] == (DayNameArray[day] + '-' + pad(hour)));

                        weeklyHeatMapData.push({
                            "hour": Hours + AMPM,
                            "weekday": DayNameArray[day],
                            "value": tmp.length > 0 ? tmp[0].count : 0
                        })
                        
                    }
                }
                weeklyHeatMapData.reverse();
            }

        } else {
            weeklyHeatMapData = [];
        }


        /* HeatMap Chart */
        let chartHeatMap = am4core.create("chartHeatMap", am4charts.XYChart);
        chartHeatMap.maskBullets = false;

        let xAxis = chartHeatMap.xAxes.push(new am4charts.CategoryAxis());
        let yAxis = chartHeatMap.yAxes.push(new am4charts.CategoryAxis());

        xAxis.dataFields.category = "weekday";
        yAxis.dataFields.category = "hour";

        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.minGridDistance = 40;

        yAxis.renderer.grid.template.disabled = true;
        yAxis.renderer.inversed = true;
        yAxis.renderer.minGridDistance = 30;

        let seriesHeatMap = chartHeatMap.series.push(new am4charts.ColumnSeries());
        seriesHeatMap.dataFields.categoryX = "weekday";
        seriesHeatMap.dataFields.categoryY = "hour";
        seriesHeatMap.dataFields.value = "value";
        seriesHeatMap.sequencedInterpolation = true;
        seriesHeatMap.defaultState.transitionDuration = 3000;

        let bgColor = new am4core.InterfaceColorSet().getFor("background");

        let columnTemplate = seriesHeatMap.columns.template;
        columnTemplate.strokeWidth = 1;
        columnTemplate.strokeOpacity = 0.2;
        // columnTemplate.stroke = bgColor;
        columnTemplate.tooltipText = "{weekday}, {hour}: {value.workingValue.formatNumber('#.')}";
        columnTemplate.width = am4core.percent(100);
        columnTemplate.height = am4core.percent(100);

        seriesHeatMap.heatRules.push({
            target: columnTemplate,
            property: "fill",
            min: am4core.color(bgColor),
            max: chartHeatMap.colors.getIndex(0)
        });

        // heat legend
        let heatLegend = chartHeatMap.bottomAxesContainer.createChild(am4charts.HeatLegend);
        heatLegend.width = am4core.percent(100);
        heatLegend.series = seriesHeatMap;
        heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
        heatLegend.valueAxis.renderer.minGridDistance = 30;

        // heat legend behavior
        seriesHeatMap.columns.template.events.on("over", function(event) {
            handleHover(event.target);
        })

        seriesHeatMap.columns.template.events.on("hit", function(event) {
            handleHover(event.target);
        })

        function handleHover(column: any) {
            if (!isNaN(column.dataItem.value)) {
                heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
            }
            else {
                heatLegend.valueAxis.hideTooltip();
            }
        }

        seriesHeatMap.columns.template.events.on("out", function(event) {
            heatLegend.valueAxis.hideTooltip();
        })
        
        chartHeatMap.data = weeklyHeatMapData

        
    }, [dm1HeatMapData])


    /* Update Bar Chart */
    useEffect(() => {

        let chartBar = am4core.create("chartdivBar", am4charts.XYChart);
        chartBar.colors.saturation = 0.4;

        let dailyData: any = []
        if (dm1DailyCount) {

            let dmDailyCountArr = JSON.parse(dm1DailyCount)
            let tmp = []

            for (let day = 0; day < 7; day++) { //0=Today's date

                tmp = dmDailyCountArr.filter((item: any) => item['day_name'] == (DayNameArray[day]))

                dailyData.push({
                    day: DayNameArray[day],
                    dm1Count: tmp.length > 0 ? tmp[0]['dm1count'] : 0
                })
            }
            dailyData.reverse();
        }
        

        if (dailyData.length == 0) {
            const noDataMessagecontainer = chartBar.chartContainer.createChild(am4core.Container);
            noDataMessagecontainer.align = 'center';
            noDataMessagecontainer.isMeasured = false;
            noDataMessagecontainer.x = am4core.percent(50);
            noDataMessagecontainer.horizontalCenter = 'middle';
            noDataMessagecontainer.y = am4core.percent(50);
            noDataMessagecontainer.verticalCenter = 'middle';
            noDataMessagecontainer.layout = 'vertical';

            const messageLabel = noDataMessagecontainer.createChild(am4core.Label);
            messageLabel.text = 'There is no data to show';
            messageLabel.textAlign = 'middle';
            messageLabel.maxWidth = 300;
            messageLabel.wrap = true;
        } else {
            chartBar.data = dailyData;
        }
    
        
        // Create axes
        let categoryAxis = chartBar.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "day";
        categoryAxis.title.text = "Day";
        categoryAxis.renderer.minGridDistance = 30;

        let valueAxis = chartBar.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "DM1 Count";

        // Create series
        let series = chartBar.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "dm1Count";
        series.dataFields.categoryX = "day";
        series.columns.template.tooltipText = "Day: {categoryX}\nTotal DM1: {valueY}";
        series.sequencedInterpolation = true;
        series.defaultState.transitionDuration = 1000;
        series.sequencedInterpolationDelay = 100;
        series.columns.template.strokeOpacity = 0;

        series.columns.template.adapter.add("fill", (fill, target) => {
            return am4core.color("#0092eb")
        });
        
        var fillModifier = new am4core.LinearGradientModifier();
        fillModifier.brightnesses = [0, 1, 1, 0];
        fillModifier.offsets = [0, 0.45, 0.55, 1];
        fillModifier.gradient.rotation = 180;
        series.columns.template.fillModifier = fillModifier;
        
        var valueLabel = series.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = "{valueY}";
        valueLabel.label.fontSize = 12;
        valueLabel.label.verticalCenter = "bottom"
        // valueLabel.label.dy = -10;

        
    }, [dm1DailyCount])


    /* on data change */
    useEffect(() => {

        /* Bar Chart */
        let chartBar = am4core.create("chartdivBar", am4charts.XYChart);
        chartBar.colors.saturation = 0.4;

        let dailyData: any = []
        if (data.dmDailyCount) {

            let dmDailyCount = JSON.parse(data.dmDailyCount)
            let tmp = []

            for (let day = 0; day < 7; day++) { //0=Today's date

                tmp = dmDailyCount.filter((item: any) => item['day_name'] == (DayNameArray[day]))

                dailyData.push({
                    day: DayNameArray[day],
                    dm1Count: tmp.length > 0 ? tmp[0]['dm1count'] : 0
                })
            }
            dailyData.reverse();
        }

        // console.log(`dmDailyCount`, JSON.parse(data.dmDailyCount))

        if (dailyData.length == 0) {
            const noDataMessagecontainer = chartBar.chartContainer.createChild(am4core.Container);
            noDataMessagecontainer.align = 'center';
            noDataMessagecontainer.isMeasured = false;
            noDataMessagecontainer.x = am4core.percent(50);
            noDataMessagecontainer.horizontalCenter = 'middle';
            noDataMessagecontainer.y = am4core.percent(50);
            noDataMessagecontainer.verticalCenter = 'middle';
            noDataMessagecontainer.layout = 'vertical';

            const messageLabel = noDataMessagecontainer.createChild(am4core.Label);
            messageLabel.text = 'There is no data to show';
            messageLabel.textAlign = 'middle';
            messageLabel.maxWidth = 300;
            messageLabel.wrap = true;
        } else {
            chartBar.data = dailyData;
        }
    
        
        // Create axes
        let categoryAxis = chartBar.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "day";
        categoryAxis.title.text = "Day";
        categoryAxis.renderer.minGridDistance = 30;

        let valueAxis = chartBar.yAxes.push(new am4charts.ValueAxis());
        valueAxis.title.text = "DM1 Count";

        // Create series
        let series = chartBar.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "dm1Count";
        series.dataFields.categoryX = "day";
        series.columns.template.tooltipText = "Day: {categoryX}\nTotal DM1: {valueY}";
        series.sequencedInterpolation = true;
        series.defaultState.transitionDuration = 1000;
        series.sequencedInterpolationDelay = 100;
        series.columns.template.strokeOpacity = 0;

        series.columns.template.adapter.add("fill", (fill, target) => {
            return am4core.color("#0092eb")
        });
        
        var fillModifier = new am4core.LinearGradientModifier();
        fillModifier.brightnesses = [0, 1, 1, 0];
        fillModifier.offsets = [0, 0.45, 0.55, 1];
        fillModifier.gradient.rotation = 180;
        series.columns.template.fillModifier = fillModifier;
        
        
        var valueLabel = series.bullets.push(new am4charts.LabelBullet());
        valueLabel.label.text = "{valueY}";
        valueLabel.label.fontSize = 12;
        valueLabel.label.verticalCenter = "bottom"
        // valueLabel.label.dy = -10;



        /* Pie Chart */
        let chartPie = am4core.create("chartdivPie", am4charts.PieChart);

        chartPie.data = [{
            "status": "Active Assets",
            "count": data.gatewaysCount - data.expiredGateways - data.whitelistedGateways,
            "color": am4core.color("#30b08e")
        }, {
            "status": "Inactive Assets",
            "count": data.expiredGateways,
            "color": am4core.color("#055cb0")
        }, {
            "status": "Whitelisted Assets",
            "count": data.whitelistedGateways,
            "color": am4core.color("#71abff")
        }];
        
        chartPie.innerRadius = am4core.percent(40);
        chartPie.hiddenState.properties.innerRadius = am4core.percent(0);
        chartPie.hiddenState.properties.radius = am4core.percent(100);

        chartPie.legend = new am4charts.Legend();
        chartPie.legend.position = "right";
        chartPie.legend.labels.template.text = "[bold {color}]{name}[/]";
        
        let pieSeries = chartPie.series.push(new am4charts.PieSeries());
        

        pieSeries.dataFields.value = "count";
        pieSeries.dataFields.category = "status";

        pieSeries.slices.template.stroke = am4core.color("#2a4858");
        pieSeries.slices.template.strokeWidth = 1;
        pieSeries.slices.template.strokeOpacity = 0.8;
        pieSeries.slices.template.showOnInit = true;
        pieSeries.slices.template.hiddenState.properties.shiftRadius = 1;
        pieSeries.slices.template.propertyFields.fill = "color";

        pieSeries.legendSettings.valueText = "[bold {color}]{value}[/]";

        // Disable ticks and labels
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;

        // let rgm = new am4core.RadialGradientModifier();
        // rgm.brightnesses.push(-0.8, -0.8, -0.5, 0, - 0.5);
        // pieSeries.slices.template.fillModifier = rgm;
        // pieSeries.slices.template.strokeModifier = rgm;
        // pieSeries.slices.template.strokeOpacity = 0.4;
        // pieSeries.slices.template.strokeWidth = 0;
                

        setDM1Percent(getPercentageChange(data.dm1CountYesterday , data.dm1Count))
        setAlertsPercent(getPercentageChange(data.criticalAlertsCountYesterday, data.criticalAlertsCount))



        /* HeatMap Chart */
        let weeklyHeatMapData: any = [];
        let dmHeatMap = JSON.parse(data.dmHeatMap)

        if (dmHeatMap) {

            if (dmHeatMap.length > 0) {

                let AMPM = 'AM';
                let Hours = 0;
                let tmp = []
                for (let day = 0; day < 7; day++) { //0=Today's date
                    for (let hour = 0; hour <= 23; hour++) { //00=12am
                        if (hour >= 12) {
                            AMPM = 'PM'; Hours = hour - 12;
                            if (hour == 12) {
                                Hours = hour;
                            }
                        } else {
                            AMPM = 'AM';
                            if (hour == 0) {
                                Hours = 12
                            } else {
                                Hours = hour
                            }
                        }

                        tmp = dmHeatMap.filter((item: any) => item['day_hour'] == (DayNameArray[day] + '-' + pad(hour)));
                        
                        weeklyHeatMapData.push({
                            "hour": Hours + AMPM,
                            "weekday": DayNameArray[day],
                            "value": tmp.length > 0 ? tmp[0].count : 0
                        })
                        
                    }
                }
                weeklyHeatMapData.reverse();
            }

        } else {
            weeklyHeatMapData = [];
        }

        // console.log('moment().utcOffset :>> ', moment().utcOffset()/60);
        // console.log(`dmHeatMap`, dmHeatMap)
        // console.log(`weeklyHeatMapData`, weeklyHeatMapData)

        /* HeatMap Chart */
        let chartHeatMap = am4core.create("chartHeatMap", am4charts.XYChart);
        chartHeatMap.maskBullets = false;

        let xAxis = chartHeatMap.xAxes.push(new am4charts.CategoryAxis());
        let yAxis = chartHeatMap.yAxes.push(new am4charts.CategoryAxis());

        xAxis.dataFields.category = "weekday";
        yAxis.dataFields.category = "hour";

        xAxis.renderer.grid.template.disabled = true;
        xAxis.renderer.minGridDistance = 40;

        yAxis.renderer.grid.template.disabled = true;
        yAxis.renderer.inversed = true;
        yAxis.renderer.minGridDistance = 30;

        let seriesHeatMap = chartHeatMap.series.push(new am4charts.ColumnSeries());
        seriesHeatMap.dataFields.categoryX = "weekday";
        seriesHeatMap.dataFields.categoryY = "hour";
        seriesHeatMap.dataFields.value = "value";
        seriesHeatMap.sequencedInterpolation = true;
        seriesHeatMap.defaultState.transitionDuration = 3000;

        let bgColor = new am4core.InterfaceColorSet().getFor("background");

        let columnTemplate = seriesHeatMap.columns.template;
        columnTemplate.strokeWidth = 1;
        columnTemplate.strokeOpacity = 0.2;
        // columnTemplate.stroke = bgColor;
        columnTemplate.tooltipText = "{weekday}, {hour}: {value.workingValue.formatNumber('#.')}";
        columnTemplate.width = am4core.percent(100);
        columnTemplate.height = am4core.percent(100);

        seriesHeatMap.heatRules.push({
            target: columnTemplate,
            property: "fill",
            min: am4core.color(bgColor),
            max: chartHeatMap.colors.getIndex(0)
        });

        // heat legend
        let heatLegend = chartHeatMap.bottomAxesContainer.createChild(am4charts.HeatLegend);
        heatLegend.width = am4core.percent(100);
        heatLegend.series = seriesHeatMap;
        heatLegend.valueAxis.renderer.labels.template.fontSize = 9;
        heatLegend.valueAxis.renderer.minGridDistance = 30;

        // heat legend behavior
        seriesHeatMap.columns.template.events.on("over", function(event) {
            handleHover(event.target);
        })

        seriesHeatMap.columns.template.events.on("hit", function(event) {
            handleHover(event.target);
        })

        function handleHover(column: any) {
            if (!isNaN(column.dataItem.value)) {
                heatLegend.valueAxis.showTooltipAt(column.dataItem.value)
            }
            else {
                heatLegend.valueAxis.hideTooltip();
            }
        }

        seriesHeatMap.columns.template.events.on("out", function(event) {
            heatLegend.valueAxis.hideTooltip();
        })
        
        chartHeatMap.data = weeklyHeatMapData
        

    }, [data])


    /* Top 5 Assets by DM1 Count */
    let dataSource: any = []
    if (data.assetsDmCountJSON) {
        JSON.parse(data.assetsDmCountJSON).map((asset:any, index:number) => {
            dataSource.push({key: index, name: asset.nickname, count: asset.dmcount})
        })
    }
      
    const columns = [
        {
            title: 'Asset Name',
            dataIndex: 'name',
            key: 'key',
        },
        {
            title: 'DM 1 Count',
            dataIndex: 'count',
            key: 'key',
        },
    ];

    return (
        <div ref={wrapperRef} className={`${props.stats.visible ? "statsLayoutOpen" : "statsLayoutClose"}` }>
            <Row gutter={[8*1, 8*1]} >
                <Col className={`${props.stats.visible ? "slideTop1" : "slideTop1Reversed"} `} xs={24} sm={24} md={12} lg={12} xl={8}>
                    <div style={{ padding: '8px 8px', borderRadius: '3px' }}>
                        <Card size="small" style={{ backgroundColor: "#ff9c4a", backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))", borderRadius: "5px", padding: "1px", margin: "1px" }} >
                            <Row style={{ height: 50 }} gutter={[0,0]}>
                                <Col style={{ color: "white" }} span={24}>
                                    <span style={{ position: 'absolute', marginTop: "-8px", color: "white", fontSize: 26, marginRight: "6px" }}>{ numberWithCommas(data.dm1AssetsCount) } Assets</span><br />
                                    <span style={{ position: 'absolute', marginTop: "5px" }}>(Assets with DM1 alerts past 24 Hours)</span>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>

                <Col className={`${props.stats.visible ? "slideTop1" : "slideTop1Reversed"}`} xs={24} sm={24} md={12} lg={12} xl={8}>
                    <div style={{ padding: '8px 8px', borderRadius: '3px' }}>
                        <Card size="small" style={{ backgroundColor: "#fc2d42", backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))", borderRadius: "5px", padding: "1px", margin: "1px" }} >
                            <Row style={{ height: 50 }} gutter={[0,0]}>
                                <Col style={{ color: "white" }} span={24}>
                                    <span style={{ position: 'absolute', marginTop: "-8px", color: "white", fontSize: 26, marginRight: "6px" }}>{ numberWithCommas(data.criticalAlertsAssetsCount) } Assets</span><br />
                                    <span style={{ position: 'absolute', marginTop: "5px" }}>(Assets with critical alerts past 24 Hours)</span>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>


                <Col className={`${props.stats.visible ? "slideTop1" : "slideTop1Reversed"}`} xs={24} sm={24} md={12} lg={12} xl={8}>
                    <div style={{ padding: '8px 8px', borderRadius: '3px' }}>
                        <Card size="small" style={{ backgroundColor: "#20c997" , backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))" , borderRadius: "5px", padding: "1px", margin: "1px" }} >
                            <Row style={{ height: 50 }} gutter={[0,0]}>
                                <Col style={{ color: "white" }} span={24}>
                                    <span style={{ position: 'absolute', marginTop: "-8px", color: "white", fontSize: 26, marginRight: "6px" }}>{ numberWithCommas(data.activeUsers) } Users</span><br />
                                    <span style={{ position: 'absolute', marginTop: "5px" }}>(Active sessions within last hour)</span>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>





                
                <Col className={`${props.stats.visible ? "slideTop1" : "slideTop1Reversed"} `} xs={24} sm={24} md={12} lg={12} xl={8}>
                    <div style={{ padding: '8px 8px', borderRadius: '3px' }}>
                        <Card size="small" style={{ backgroundColor: "#fd7e14", backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))", borderRadius: "5px", padding: "1px", margin: "1px" }} >
                            <Row style={{ height: 80 }} gutter={[0,0]}>
                                <Col style={{ color: "white" }} span={24}>
                                    <span style={{ color: "white", fontSize: 26, marginRight: "6px" }}>{ numberWithCommas(data.dm1Count) } DM1 Alerts</span>
                                    <span>(Last 24 Hours)</span>
                                </Col>
                                <Col span={24} >
                                    { parseFloat(DM1Percent) >= 0 ?
                                        <ArrowUpOutlined style={{ fontSize: 24, fontWeight: "bolder", marginRight: '3px', color: "white" }} />
                                        :
                                        <ArrowDownOutlined style={{ fontSize: 24, fontWeight: "bolder", marginRight: '3px', color: "white" }} />
                                    }
                                    <span style={{ color: "white" }}><span style={{ fontSize: 22 }}>{numberWithCommas(Math.abs(parseInt(DM1Percent.split(".")[0])))}</span><span>.{DM1Percent.split(".")[1]} % (From prior 48h's { numberWithCommas(data.dm1CountYesterday) } alerts)</span></span>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>

                <Col className={`${props.stats.visible ? "slideTop1" : "slideTop1Reversed"}`} xs={24} sm={24} md={12} lg={12} xl={8}>
                    <div style={{ padding: '8px 8px', borderRadius: '3px' }}>
                        <Card size="small" style={{ backgroundColor: "#f41127", backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))", borderRadius: "5px", padding: "1px", margin: "1px" }} >
                            <Row style={{ height: 80 }} gutter={[0,0]}>
                                <Col style={{ color: "white" }} span={24}>
                                    <span style={{ color: "white", fontSize: 26, marginRight: "6px" }}>{ numberWithCommas(data.criticalAlertsCount) } Critical Alerts</span>
                                    <span>(Last 24 Hours)</span>
                                </Col>
                                <Col span={24} >
                                    { parseFloat(alertsPercent) >= 0 ?
                                        <ArrowUpOutlined style={{ fontSize: 24, fontWeight: "bolder", marginRight: '3px', color: "white" }} />
                                        :
                                        <ArrowDownOutlined style={{ fontSize: 24, fontWeight: "bolder", marginRight: '3px', color: "white" }} />
                                    }
                                    <span style={{ color: "white" }}><span style={{ fontSize: 22 }}>{numberWithCommas(Math.abs(parseInt(alertsPercent.split(".")[0])))}</span><span>.{alertsPercent.split(".")[1]} % (From prior 48h's { numberWithCommas(data.criticalAlertsCountYesterday) } alerts)</span></span>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>

                <Col className={`${props.stats.visible ? "slideTop1" : "slideTop1Reversed"}`} xs={24} sm={24} md={12} lg={12} xl={8}>
                    <div style={{ padding: '8px 8px', borderRadius: '3px' }}>
                        <Card size="small" style={{ backgroundColor: "#20c997", backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))", borderRadius: "5px", padding: "1px", margin: "1px" }} >
                            <Row style={{ height: 80 }} gutter={[0,0]}>
                                <Col style={{ color: "white" }} span={24}>
                                    <span style={{ color: "white", fontSize: 26, marginRight: "6px" }}>{ numberWithCommas(data.onlineCount) } ONLINE</span>
                                    <span style={{ color: "white" }}>(As of today {moment(props.stats.timestamp).format('h:mmA')})</span>
                                </Col>
                                <Col style={{ color: "white" }} span={24}>
                                    <span style={{ color: "#4a4a4a", fontSize: 26, marginRight: "6px" }}>{ numberWithCommas(data.offlineCount) } OFFLINE</span>
                                    <span style={{ color: "#4a4a4a" }}>(Last seen 7 days ago)</span>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>


                

                {/* <Col className={`${props.stats.visible ? "slideTop2" : "slideTop2Reversed"}`} xs={24} sm={24} md={8} lg={8} xl={8}>
                    <div style={{ padding: '8px 8px', borderRadius: '3px' }}>
                        <Card className={`${data.expiringSoon > 0 ? 'changeColor' : ''}`} size="small" style={{ backgroundColor: "#d1d1d1", backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))", borderRadius: "5px", padding: "1px", margin: "1px" }} >
                            <Row style={{ height: 80 }} gutter={[0,0]}>
                                <Col style={{ color: "#4a4a4a" }} span={24}>
                                    <span style={{ color: "#4a4a4a", fontSize: 26, marginRight: "6px" }}>{ numberWithCommas(data.expiringSoon) } Assets</span>
                                    <span>(Expiring within 60 days)</span>
                                    <Button className='downloadStatsButton' style={{ backgroundColor: 'white' }} type="ghost">
                                        <DownloadOutlined style={{ fontSize: "19px", color: 'black' }} />
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>

                <Col className={`${props.stats.visible ? "slideTop2" : "slideTop2Reversed"}`} xs={24} sm={24} md={8} lg={8} xl={8}>
                    <div style={{ padding: '8px 8px', borderRadius: '3px' }}>
                        <Card className={`${data.expiredGateways > 0 ? 'changeColor' : ''}`} size="small" style={{ backgroundColor: "#d1d1d1", backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))", borderRadius: "5px", padding: "1px", margin: "1px" }} >
                            <Row style={{ height: 80 }} gutter={[0,0]}>
                                <Col style={{ color: "#4a4a4a" }} span={24}>
                                    <span style={{ color: "#4a4a4a", fontSize: 26, marginRight: "6px" }}>{ numberWithCommas(data.expiredGateways) } Assets</span>
                                    <span>(Already expired)</span>
                                    <Button className='downloadStatsButton' style={{ backgroundColor: 'white' }} type="ghost">
                                        <DownloadOutlined style={{ fontSize: "19px", color: 'black' }} />
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>

                <Col className={`${props.stats.visible ? "slideTop2" : "slideTop2Reversed"}`} xs={24} sm={24} md={8} lg={8} xl={8}>
                    <div style={{ padding: '8px 8px', borderRadius: '3px' }}>
                        <Card className={`${data.expiredGateways > 0 ? 'changeColor' : ''}`} size="small" style={{ backgroundColor: "#d1d1d1", backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))", borderRadius: "5px", padding: "1px", margin: "1px" }} >
                            <Row style={{ height: 80 }} gutter={[0,0]}>
                                <Col style={{ color: "#4a4a4a" }} span={24}>
                                    <span style={{ color: "#4a4a4a", fontSize: 26, marginRight: "6px" }}>{ numberWithCommas(data.expiredGateways) } Assets</span>
                                    <span>(Already expired)</span>
                                    <Button className='downloadStatsButton' style={{ backgroundColor: 'white' }} type="ghost">
                                        <DownloadOutlined style={{ fontSize: "19px", color: 'black' }} />
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col> */}

                <Col className={`${props.stats.visible ? "slideTop2" : "slideTop2Reversed"}`} xs={24} sm={24} md={24} lg={24} xl={24}>
                    <div style={{ padding: '8px 8px', borderRadius: '3px' }}>
                        <Card size="small" style={{ backgroundColor: "#6f42c1", backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))", borderRadius: "5px", padding: "1px", margin: "1px" }} >
                            <Row style={{ height: 80 }} gutter={[0,0]}>
                                <Col style={{ color: "white" }} span={24}>
                                    <span style={{ color: "white", fontSize: 26, marginRight: "6px" }}>{ numberWithCommas(data.outsideGeofenceCount) } Assets</span>
                                    <span>(Reported Outside Geofence(s))</span>
                                </Col>
                                <Col style={{ color: "white" }} span={24}>
                                    <span style={{ color: "white", fontSize: 26, marginRight: "6px" }}>{ numberWithCommas(data.insideGeofenceCount) } Assets</span>
                                    <span>(Reported Inside Geofence(s))</span>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>

                <Col className={`${props.stats.visible ? "slideTop2" : "slideTop2Reversed"}`} xs={24} sm={24} md={12} lg={12} xl={8}></Col>
                <Col className={`${props.stats.visible ? "slideTop2" : "slideTop2Reversed"}`} xs={24} sm={24} md={12} lg={12} xl={8}></Col>

                <Col className={`${props.stats.visible ? "slideTop3" : "slideTop3Reversed"}`} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{ padding: '8px 8px', borderRadius: '3px' }}>
                        <Card size="small" style={{ backgroundColor: "#f0f0f0", backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))", borderRadius: "5px", minHeight: 305 }} title={<><span style={{ fontSize: 18 }}>Top 5 Assets by DM1 Count</span> <span style={{ fontSize: 14 }}>(Last 7 Days)</span> <Tooltip title="Shows top five assets by overall DM1 count regardless of DM1 type"><InfoCircleOutlined style={{ color: "#0d6efd", marginLeft: "2px"}} /></Tooltip></>   } >
                            <Table size="small" dataSource={dataSource} columns={columns} pagination={false} />
                        </Card>
                    </div>
                </Col>



                <Col className={`${props.stats.visible ? "slideTop3" : "slideTop3Reversed"}`} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{ padding: '8px 8px', borderRadius: '3px' }}>
                        <Card size="small" style={{ backgroundColor: "#f0f0f0", backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))", borderRadius: "5px" }} title={<><span style={{ fontSize: 18 }}>Assets Status</span> <span style={{ fontSize: 14 }}>(Total of { data.gatewaysCount } Assets)</span> <Tooltip title="Shows assets count based on current status (Active, Inactive or Whitelisted)"><InfoCircleOutlined style={{ color: "#0d6efd", marginLeft: "2px"}} /></Tooltip></> } >
                            <Row style={{ height: 235 }} gutter={[0, 0]}>
                                <Col span={24}>
                                    <div id="chartdivPie" style={{ width: '100%', height: '100%' }}></div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>


                <Col className={`${props.stats.visible ? "slideTop4" : "slideTop4Reversed"}`} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{ padding: '8px 8px', borderRadius: '3px' }}>
                        <Card size="small" style={{ backgroundColor: "#f0f0f0", backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))", borderRadius: "5px" }} title={
                            <Row gutter={[0, 0]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <span style={{ fontSize: 18 }}>DM1 Count By Day </span>
                                    <span style={{ fontSize: 14 }}>{selectedMasterTag7Days == '' ? "(Overall Fleet Health)" : `(${selectedMasterTag7Days})`}</span>
                                    <Tooltip title="Shows DM1 count per day for all assets for the last 7 days (You can also select specific asset from drop down list to get asset's details)."><InfoCircleOutlined style={{ color: "#0d6efd", marginLeft: "2px"}} /></Tooltip>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                                    <Select
                                        placeholder="Select Asset"
                                        style={{ width: '100%' }}
                                        showSearch
                                        onChange={getDm1DailyCountByAsset}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => {
                                            return  (option?.children[0]?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                            option?.children[2]?.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                                        }}
                                    >
                                        {
                                            props.stats.assetsWithDm1.map((asset:any) => (
                                                <Select.Option key={asset.mastertag} value={asset.mastertag}>{asset.nickname} / {asset.mastertag}</Select.Option>
                                            ))
                                            
                                        }
                                    </Select>
                                        
                                </Col>
                                
                                
                                
                                
                            </Row>
                        }>
                            <Row gutter={[0, 0]}>
                                <Col style={{ height: 350 }} xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div id="chartdivBar" style={{ width: '100%', height: '100%', margin: "10px auto" }}></div>
                                </Col>
                                
                                
                            </Row>
                        </Card>
                    </div>
                </Col>


                <Col className={`${props.stats.visible ? "slideTop4" : "slideTop4Reversed"}`} xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{ padding: '8px 8px', borderRadius: '3px' }}>
                        <Card size="small" style={{ backgroundColor: "#f0f0f0", backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))", borderRadius: "5px" }} title={
                            <Row gutter={[0, 0]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <span style={{ fontSize: 18 }}>DM1 Count By Day </span>
                                    <span style={{ fontSize: 14 }}>{selectedMasterTagHeatMap == '' ? "(Overall Fleet Health)" : `(${selectedMasterTagHeatMap})`}</span>
                                    <Tooltip title="Shows DM1 count per hour for all assets for the last 7 days (You can select specific asset from drop down list to get asset's details)"><InfoCircleOutlined style={{ color: "#0d6efd", marginLeft: "2px"}} /></Tooltip>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                                    <Select
                                        placeholder="Select Asset"
                                        style={{ width: '100%' }}
                                        showSearch
                                        onChange={getDm1DailyCountHeatMap}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => {
                                            return  (option?.children[0]?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                            option?.children[2]?.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                                        }}
                                    >
                                        {
                                            props.stats.assetsWithDm1.map((asset:any) => (
                                                <Select.Option key={asset.mastertag} value={asset.mastertag}>{asset.nickname} / {asset.mastertag}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                        
                                </Col>
                                
                                
                                
                                
                            </Row>
                        }>
                            <Row gutter={[0, 0]}>
                                <Col style={{ height: 350 }} xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <div id="chartHeatMap" style={{ width: '100%', height: '100%', margin: "10px auto" }}></div>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>



                {/* <Col className={`${props.stats.visible ? "slideTop2" : "slideTop2Reversed"}`} xs={24} sm={24} md={12} lg={12} xl={8}>
                    <div style={{ padding: '8px 8px', borderRadius: '3px' }}>
                        <Card style={{ borderRadius: "5px", padding: "1px", margin: "1px" }}>
                            <Row style={{ height: 55 }} gutter={[0,0]}>
                                <Col style={{ color: "#4a4a4a" }} span={24}>
                                    <Statistic
                                        title="Active"
                                        value={11.28}
                                        precision={2}
                                        valueStyle={{ color: '#3f8600' }}
                                        prefix={<ArrowUpOutlined />}
                                        suffix="%"
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col> */}

                <Col className={`${props.stats.visible ? "slideTop2" : "slideTop2Reversed"}`} xs={24} sm={24} md={24} lg={24} xl={24}>
                    <span>* All times are in UTC.</span>
                </Col>

            </Row>
        </div>
    )

}




const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        stats: state.contextReducer.stats,
        fleetOverviewState: state.fleetOverviewReducers.fleetOverviewState,
        permissions: state.contextReducer.data,
    };
};

export default connect(
    mapStateToProps,
    { 
        ...rootActions,
    }
)(React.memo(FleetStatsOverview));