/* eslint-disable @typescript-eslint/no-unused-vars */
import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function releasenotesState(state = initialState, action:any) {
    let data:any = null,
        payload:any;
        switch (action.type) {  
            case "RELEASENOTES_GET_SUCCESS":
                return immerProduce(state, draft => {
                    draft.releasenotes.releasenotesList = action.data;
                });
            case "RELEASENOTES_SET_SEARCH":
                    return immerProduce(state, draft => {
                    draft.releasenotes.searchText = action.searchText;
            });
            case "SET_TAB":
                    return immerProduce(state, draft => {
                    draft.releasenotes.activeTab = action.tabNumber;
            });
            default:
                return state;
        }
}