import React from "react";
import { Form ,Row,Col, InputNumber} from "antd";

import { Rule, Signal } from "../../models";
import BooleanSelectorList from "../../WidgetEditForm/BooleanSelectorList";
import ColorSelectorList from "../../WidgetEditForm/ColorSelectorList";
import SelectorList from "../../../Dashboard/WidgetEditForm/SelectorList";


interface Props {
  max?: number;
  signals: Signal[];
  rules: Rule[];
  isColor: string;
}
const rightYAxisRange = (end: string) => ["settings", "rightYAxisRange", end];
const RightAxisSignals: React.FC<Props> = (props) => {
  const gaugelabels = ["Fill","Remain"];
  const boolLabels = ["ON","OFF"];
  const colorSelectable = props.signals.map((s) => ({
    id: s.id,
    label: s.name,
    color: s.color,
    value: s.id,
  }));
  const selectable = props.signals.map((s) => ({
    value: s.id,
    label: s.name,
  }));
  let isColor = props.isColor;
 
  return (
    <>
     <Row justify="space-between" style={{ width: "95%" }}>
            <Col span={10}>
              <Form.Item
                name={rightYAxisRange("min")}
                label="Right Axis Min"
                rules={[{ type: "number" }]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col span={10} push="right">
              <Form.Item
                name={rightYAxisRange("max")}
                label="Right Axis Max"
                rules={[{ type: "number" }]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>
             
                  <Form.Item
                      label="Signals on right axis"
                      name="rightYAxisSignals"
                      required
                      rules={[
                          {
                              validator(rule, value) {
                                  if (value) {
                                      if (value.length === 0) {
                                          return Promise.reject(
                                              "At least one valid signal must be specified"
                                          );
                                      }
                                      let res = value.filter((v: any) => {
                                          if (v) {
                                              if (v?.id !== "") {
                                                  return true;
                                              } else {
                                                  return false;
                                              }
                                          } else {
                                              return false;
                                          }
                                      });
                                      if (res.length < 1) {
                                          return Promise.reject(
                                              "At least one valid signal must be specified"
                                          );
                                      } else {
                                          return Promise.resolve();
                                      }
                                  } else {
                                      return Promise.reject(
                                          "At least one valid signal must be specified"
                                      );
                                  }
                              },
                          },
                      ]}
                  >
                      {isColor === "boolean" ? (
                          <BooleanSelectorList
                              options={colorSelectable}
                              maxSelected={props.max}
                              valueLabel="Signal"
                              rules={props.rules}
                              labels={boolLabels} />
                      ) : isColor === "gauge" ? (
                          <BooleanSelectorList
                              options={colorSelectable}
                              maxSelected={props.max}
                              rules={props.rules}
                              valueLabel="Signal"
                              labels={gaugelabels} />
                      ) : isColor === "color" ? (
                          <ColorSelectorList
                              options={colorSelectable}
                              maxSelected={props.max}
                              valueLabel="Signal" />
                      ) : (
                          <SelectorList
                              options={selectable}
                              maxSelected={props.max}
                              valueLabel="Signal" />
                      )}
                  </Form.Item>
             </>
  );
};

export default RightAxisSignals;
