import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function loginReducer(state = initialState, action:any) {
    switch (action.type) {    
        case "LOGIN_GET_SUCCESS":
            return immerProduce(state, draft => {
                draft.backgroundUrl = action.data.backgroundUrl;
                draft.languageIsoCode2 = action.data.languageIsoCode2;
                draft.languageIsoCode2Default = action.data.languageIsoCode2Default;
                draft.headerColor = action.data.headerColor;
                draft.languages = action.data.languages;
                draft.termsAndConditions = action.data.termsAndConditions;
            });
        default:
            return state;
    }
}