import { appConfigs } from "../../../utils/configurations";

export const initialState = {
    templatesList: {
        templates:[],
        selectedTemplateDetails:{},
        templateMaint: {
            instance: 0,
            width: 720,
            visible: false,
            record: {},
            title: "",
            mode: "",
            modes: [
                {
                    name: "template",
                    instance: 0,
                    visible: false,
                    orgs: []
                },
                {
                    name: "duplicate",
                    instance: 0,
                    visible: false,
                    orgs: []
                }
            ]
        },
        mode: "all",
        searchText: "",
        refreshOnUpdate: false,
        tableData: [],
        tableConfig : { 
            pagination : {       
                showSizeChanger: appConfigs.tables.showSizeChanger,
                showQuickJumper: appConfigs.tables.showQuickJumper,
                pageSize: appConfigs.tables.pageSize,
                defaultCurrent: appConfigs.tables.defaultCurrent,
                current: appConfigs.tables.defaultCurrent,
                total: appConfigs.tables.defaultTotal,
                hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                responsive: appConfigs.tables.responsive,
                onChange: null,
                showLessItems: appConfigs.tables.showLessItems,        
                showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} record${total > 1 ? 's' : ''}`
            }
        }
    }
};
  