import React from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet"

import * as serviceWorker from "./serviceWorker";
import * as amCore from '@amcharts/amcharts4/core';

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "./reducers";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';

import App from "./App";
import Login from "./pages/Login/Login";
import config from './pages/Login/config';
import { appConfigs } from "./utils/configurations";
import { getLoginUrl, loadPage } from "./utils/commonHelpers";
import VersionNumber from "./VersionNumber";

import "./index.css";

amCore.options.commercialLicense = true;

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);

function onAuthRequired({ history }: any) {
   loadPage(getLoginUrl());
}
/*
    Okta custom Sing-In widget workaround workflow requires existance of "/", "/login" and "/implicit/callback" routes.
    The workflow will be broken without it.
*/

ReactDOM.render(
    <>        
        <VersionNumber />
        
        <Provider store={createStoreWithMiddleware(reducers)}>  
            <Router>
                <Security
                    {...config.oidc}
                    onAuthRequired={onAuthRequired}
                >
                    <Switch>
                        <Route path="/" exact component={Login} />
                        <Route path="/login" component={Login} />
                        <Route path="/implicit/callback" component={LoginCallback} />
                        <SecureRoute 
                            render={() => {
                                return <App />;
                            }}
                        />
                    </Switch>
                </Security>
            </Router>,
        </Provider>
    </>,
    document.getElementById("root")
);

serviceWorker.unregister();