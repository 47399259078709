import React from "react";
import { Table, Checkbox, Space, Tooltip, Input } from 'antd';
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import * as actions from "../actions";
import { array } from "@amcharts/amcharts4/core";

interface Prop{
    usersList: any,
    activitySubscriptionsGet:Function,
    activitySubscriptionsMaint:Function
}

interface State{
    expandedKey:any;
}

class ActivityMaintSubscription extends React.Component<Prop,State> {

    state: State = {
        expandedKey:[]
    }

    componentDidMount() {
        this.props.activitySubscriptionsGet(this, this.onActivitySuccess, null, this.props.usersList.userMaint.record.userId);
    }

    onActivitySuccess=(data:any)=>{
        let expandKeys:Array<any>=[];
        expandKeys.push(data[0].expandedKey);
        this.setState({expandedKey:expandKeys});
    }

    onCheckChange = (e:any, record:any, notification: string, scope: string) => {
        let action = e.target.checked ? "plus" : "minus";
        this.props.activitySubscriptionsMaint(this, this.onCheckChangeSuccess, this.onCheckChangeFailure, this.props.usersList.userMaint.record.userId, record, notification, action, scope);
    }

    onCheckChangeSuccess = (userId:string, record:any, notification: string, action: string, scope:string) => {   
       
    };

    onCheckChangeFailure = (error:string, userId:string, record:any, notification: string, action: string, scope:string) => {   
    }        

    actions = (record:any) => (
        <>
        {record.children &&
                <Space size="large">
                    <Tooltip title="Set/Unset SMS for all asset movement">
                        <Checkbox data-id="activity-maint-subscriptions-sms-all" key={record.key} onChange={(e) => this.onCheckChange(e, record, "sms", "asset")} indeterminate={record.smsIndeterminate} checked={record.smsChecked}><PhoneOutlined /></Checkbox>
                    </Tooltip>
                    <Tooltip title="Set/Unset email for all asset movement">
                        <Checkbox data-id="activity-maint-subscriptions-email-all" key={record.key} onChange={(e) => this.onCheckChange(e, record, "email", "asset")} indeterminate={record.emailIndeterminate} checked={record.emailChecked}><MailOutlined /></Checkbox>
                    </Tooltip>
                </Space>
            }
              {!record.children  && record.name.includes('In') &&
               <div>
               <Space size="large">
                  
                   <Checkbox data-id="alert-maint-subscriptions-smsin" key={record.key} onChange={(e) => this.onCheckChange(e, record, "smsIn", "assetIn")} checked={record.alertSmsIn}><PhoneOutlined /></Checkbox>
                   <Checkbox data-id="alert-maint-subscriptions-emailin" key={record.key} onChange={(e) => this.onCheckChange(e, record, "emailIn", "assetIn")} checked={record.alertEmailIn}><MailOutlined /></Checkbox>
               </Space>
           
              
              </div>
            }
            {!record.children  && record.name.includes('Out') &&
               <div>
               <Space size="large" >
                  
               <Checkbox data-id="alert-maint-subscriptions-smsout" key={record.key} onChange={(e) => this.onCheckChange(e, record, "smsOut", "assetOut")} checked={record.alertSmsOut}><PhoneOutlined /></Checkbox>
                <Checkbox data-id="alert-maint-subscriptions-emailout" key={record.key} onChange={(e) => this.onCheckChange(e, record, "emailOut", "assetOut")} checked={record.alertEmailOut}><MailOutlined /></Checkbox>
               </Space>
           
              
              </div>
            }
            
        </>
    )

    handleExpand(record:any) {
        // if a row is expanded, collapses it, otherwise expands it
        this.setState(prevState =>
          prevState.expandedKey.includes(record.key)
            ? {
                expandedKey: prevState.expandedKey.filter((key:any)=>
                    key !== record.key
                )
              }
            : { expandedKey: [...prevState.expandedKey, record.key] }
        );
      }
    render(){
        const { tableData } = this.props.usersList.userMaint.tabs[3];
        // console.log(tableData);
        const columns = [
            {
                title: "Activity",
                dataIndex: "name",
                key: "name"            
            },
            {
                title: "Subscribed",
                dataIndex: "subscribed", 
                width: "30%",
                key: "subscribed",
                align: "center" as "center",
                render: (text: any, record: any) => (                    
                    this.actions(record) 
                )

            }
        ];
        
        return(
            <>  
               <Table 
               data-id="activity-maint-subscriptions" 
               columns = { columns }
               dataSource={tableData}
               onExpand={(expanded, record) => this.handleExpand(record)}
               expandable={{
                expandedRowKeys: this.state.expandedKey,
               }}
               />         
            </>
        )       
        
    }

}

const mapStateToProps = (state:any) => {
    return {
        usersList: state.usersReducers.usersState.usersList
    };
};

export default connect(
    mapStateToProps,
    actions
)(ActivityMaintSubscription);