import { ServiceTruck } from "../../FleetOverview2/models";
import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";


export function openServiceTruckDrawer() {
	return (dispatch: any) => {
		dispatch({
			type: "SET_SERVICE_TRUCK_DRAWER_OPEN",
		});
	};
}

export function closeServiceTruckDrawer() {
	return (dispatch: any) => {
		dispatch({ type: "SET_SERVICE_TRUCK_DRAWER_CLOSE" });
	};
}

export function openServiceTruckMaintDrawer(ServiceTruck: ServiceTruck) {
	return (dispatch: any) => {
		dispatch({
			type: "SET_SERVICE_TRUCK_MAINT_DRAWER_OPEN",
			payload: { ServiceTruck: ServiceTruck },
		});
	};
}

export function closeServiceTruckMaintDrawer() {
	return (dispatch: any) => {
		dispatch({
			type: "SET_SERVICE_TRUCK_MAINT_DRAWER_CLOSE",
		});
	};
}


export function setServiceTruckMap(map: any) {
	return (dispatch: any) => {
		dispatch({ type: "SET_SERVICE_TRUCK_MAP", payload: { map: map } });
	};
}


export function getServiceTrucks(
    callbackFnSuccess: any,
    callbackFnFailure: any,
){
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/fleet-overview/service_trucks", {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
            
            if (callbackFnSuccess != null) {
                callbackFnSuccess(response.data.data.serviceTrucks);
            }
            
            dispatch({type: "GET_SERVICE_TRUCKS_SUCCESS", assets: response.data.data.serviceTrucks})
        })
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(null, error);
            }
            errorResponse(null, error);
        });
    };
}


export function updateServiceTrucksDetails(
    serviceTrucks: ServiceTruck,
    callbackFnSuccess: any,
    callbackFnFailure: any,
){
    return async (dispatch: any, getState: any) => {
        
        const { assets } = getState().serviceTruckReducer.serviceTruckState.serviceTruckList;

        Axios.get(
            appConfigs.server.URL + "/ui/api/fleet-overview/service_truck_maint",
            {
                responseType: "json",        
                headers: {},
                params: {
                    vin: serviceTrucks.VIN,
                    orgId: serviceTrucks.orgId,
                    operatorName: serviceTrucks.operatorName
                }
            }
        )
        .then(response => {
            
            if (callbackFnSuccess != null) {
                callbackFnSuccess("Details Updated Successfully");
            }

            dispatch({
                type: "UPDATE_SERVICE_TRUCK_SUCCESS",
                params: {
                    vin: response.data.data.vin,
                    serviceTrucks: serviceTrucks,
                    assets: assets
                }
            })
        })
        .catch(function (error) {
            
            if (callbackFnFailure != null) {
                callbackFnFailure(["Error while updating details"]);
            }
            // errorResponse(null, error);
        });
    };
}