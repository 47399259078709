export function openFenceAssetsDrawer(){
    return (dispatch: any) => {
        dispatch({type: "OPEN_FENCE_ASSETS_DRAWER"});
    }
}

export function closeFenceAssetsDrawer(){
    return (dispatch: any) => {
        dispatch({type: "CLOSE_FENCE_ASSETS_DRAWER"});
    }
}

export function setFenceMastertags(mastertags:any, fenceName:any, latestPositionStatuses: any){
    return (dispatch: any) => {
        dispatch({type: "SET_FENCE_MASTERTAGS", payload: {mastertags: mastertags, fenceName: fenceName, latestPositionStatuses: latestPositionStatuses}});
    }
}