import React, {useState } from "react";
import {Modal, Col, Row, Form, Card } from "antd";
import { FormInstance } from "antd/lib/form";
import { connect } from "react-redux";
import * as actions from "./actions";

interface Prop {
 getDeviceDiscovery: Function;
 csus: any;
}
export const DeviceDiscovery: React.FC<Prop> = (props) => {
  const formRef = React.createRef<FormInstance>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  const handleOk = () => 
  {
    setIsModalVisible(false);
    formRef.current?.setFieldsValue({
      userConfirmed: true,
    });
  };
  const handleCancel = () => 
  {
      setIsModalVisible(false);
  };
  return (
    <>
      <Modal
          title="Entry Warning"
          visible={isModalVisible}
          okText="Yes, Add"
          cancelText="No"
          okType="danger"
          onOk={handleOk}
          onCancel={handleCancel}
      >
      </Modal>
      <Form id="DeviceDiscovery" ref={formRef} layout="vertical">
        <Row gutter={8}>
          <Col span={24}>
            <Card style={{ borderColor: "#dbdbdb", width: "100%", overflowX: "auto" }}>
              <div style={{ maxWidth: "100%", overflowX: "auto" }}>
                {(props.csus.csuList.csuMaint.discovery!==null)?<table className="styled-table" style={{ width: "100%" }}>
                    <thead>
                      <tr key={Math.random()}>
                      <th style={{ width: "10%" }}>Mode</th>
                      <th style={{ width: "10%" }}>Model</th>
                      <th style={{ width: "10%" }}>Serial</th>
                      <th style={{ width: "10%" }}>Hardware Id</th>
                      <th style={{ width: "10%" }}>PcbaVariantID</th>
                      <th style={{ width: "10%" }}>Runtime Version</th>
                      <th style={{ width: "10%" }}>Platform Version</th>
                      <th style={{ width: "10%" }}>Application Version</th>
                      </tr>
                    </thead>
                    <tbody>
                    {props.csus.csuList.csuMaint.discovery?.discovery &&
                    JSON.parse(props.csus.csuList.csuMaint.discovery?.discovery).map((device: any) => (
                        <tr key={Math.random()}>
                        <td>{device.Mode}</td>
                        <td>{device.Controller}</td>
                        <td>{device.SerialNumber}</td>
                        <td>{device.HardwareID}</td>
                        <td>{device.PcbaVariantID}</td>
                        <td>{device.SoftwareDetails.RuntimeVersion}</td>
                        <td>{device.SoftwareDetails.PlatformVersion}</td>
                        <td>{device.SoftwareDetails.ApplicationVersion}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>:<div><p>There is no Discovery available for this Device at this moment.</p></div>}
              </div>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};
const mapStateToProps = (state: any) => ({
 context: state.contextReducer.context,
 csus: state.DeviceReducers.DeviceState.csus,
 selectedCSU: state.DeviceReducers.DeviceState.csus.selectedCSU,
 permissions: state.contextReducer.data,
});
export default connect(mapStateToProps, actions)(DeviceDiscovery);