import React from "react";
import { Form, Input, message, Switch, Alert, Row, Col, Tooltip, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';

import { appConfigs } from "../../../utils/configurations";
import { errorResponse, resetFormErrors } from "../../../utils/apiHelpers/apiHelpers";
import { stringToUrlString } from "../../../utils/commonHelpers";
import { FormState, formInit } from "../../../utils/apiHelpers/formHelpers";
import { SuccessAlert, ErrorAlert } from "../../../utils/Components/formAlerts";

import { connect } from "react-redux";
import * as actions from "../actions";
import history from '../../../utils/history';

import TemplateMaintInfoOrg from "./TemplateMaintInfoOrg";

const { TextArea } = Input;

interface Prop {
    templateMaint: Function,
    templatesListRefreshOnUpdate: Function,
    getTemplates: Function,
    templateMaintClose: Function,
    context: any,
    templatesList: any
}

class TemplateMaintInfo extends React.Component<Prop, FormState> {  
        
    state: FormState = {};

    formRef = React.createRef<FormInstance>();
    
    componentDidMount() {
        this.componentUpdate(this.props.templatesList.templateMaint.record);
    }

    componentDidUpdate(prevProps: Prop, prevState: FormState) {
        if (this.props.templatesList.templateMaint.instance !== prevProps.templatesList.templateMaint.instance) {
            this.componentUpdate(this.props.templatesList.templateMaint.record);
        }
    }

    componentUpdate(record:any) {
        formInit(this);     
        if (this.formRef.current != null) {
            this.formRef.current!.resetFields();    
            this.formRef.current!.setFieldsValue({
                templateId: record.templateId,
                name: record.name,
                orgId: this.props.context.appContext.orgId,
                description: record.description,
                templateVisibleToSubOrg: record.templateVisibleToSubOrg
            });
        }
    };

    onFinish = (values: any) => {    
        resetFormErrors(this, this.formRef.current);
        this.props.templateMaint(this, this.onFinishSuccess, this.onFinishFailure, {...values, templateVisibleToSubOrg: values && values.templateVisibleToSubOrg === undefined ? false: values.templateVisibleToSubOrg });
    };

    onFinishSuccess = (record: any, mode: string) => { 
        this.props.templateMaintClose(this);

        const me = this;
        setTimeout(function () {
            if (mode === "insert") {
                history.push("/template/details/" + stringToUrlString(record.name) + "/" + record.templateId);
            }   
            else {
                me.props.getTemplates(me, me.getTemplatesSuccess(mode), null, me.props.templatesList.tableConfig.pagination.current, me.props.templatesList.mode, me.props.templatesList.searchText);            
            }     
        }, 250);     
    }

    onFinishFailure = (error: any) => { 
        errorResponse(this, error);
    }

    getTemplatesSuccess = (mode: string) => { 
        message.success("Template has been successfully " + (mode === "insert" ? "created" : "updated"));
        this.props.templateMaintClose(this);
    }

    render() {
        
        return (

            <>
                {this.state.formSuccess && !this.state.formError && <SuccessAlert data-id="template-maint-info-success-message" message={this.state.formSuccessMessage}/>}
                {this.state.formError && !this.state.formSuccess && <ErrorAlert data-id="template-maint-info-error-message" message={this.state.formErrorMessage}/>}

                <Form
                    style={{marginTop: 15}}                   
                    {...appConfigs.settings.formLayout.mainLayout}
                    id="templateMaintInfoForm"
                    ref={this.formRef}                    
                    onFinish={this.onFinish}
                >       
                    <Form.Item 
                        name="templateId"
                        style={{ display: 'none' }}
                    >
                        <Input type="hidden" />
                    </Form.Item>
                    
                    <Form.Item
                        label="Name"
                        name="name"                                 
                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                    >
                        <Input maxLength={255} placeholder="Template unique name" />
                    </Form.Item>
                    
                    {
                        this.formRef.current !== null &&
                        (this.formRef.current!.getFieldValue("templateId") || "") === "" &&                        
                        <TemplateMaintInfoOrg />
                    }
                    {
                        this.props.context.appContext.roleName.toLowerCase() === "admin" &&
                        <Form.Item
                        label="Allow sub-orgs to use this master template"                        
                        labelCol={{ span: 10 }}
                        >  
                        <Row>
                            <Col span={3}>
                                <Form.Item noStyle name="templateVisibleToSubOrg" valuePropName="checked">
                                    <Switch />
                                </Form.Item>                                
                            </Col>
                            <Col span={1}>
                                <Tooltip title="Any change done to this master template will automatically apply to all the assets using this template in all sub-orgs">
                                    <InfoCircleOutlined style={{ fontSize: '120%', color: '#1890ff'}} />
                                </Tooltip>
                            </Col>
                        </Row>                        
                        </Form.Item> 

                    }
                    
                    <Form.Item
                        label="Description"
                        name="description"  
                    >
                        <TextArea maxLength={255} rows={6} placeholder="A brief template description" />
                    </Form.Item>

                </Form>
                   
            </>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        templatesList: state.templatesReducers.templatesState.templatesList
    };
};

export default connect(
    mapStateToProps,
    actions
)(TemplateMaintInfo);