import React, { useEffect} from "react";
import { Col, Row,  Form, Button} from "antd";
import { FormInstance } from "antd/lib/form";
import { connect } from "react-redux";
import * as actions from "./actions";
import { ReloadOutlined } from '@ant-design/icons';

interface Prop {
  getSystemStatus: Function;
  csus: any;
  csuMaintOptions: any,
}

interface SystemStatus {
  [key: string]: string;
 }


export const DeviceStatus: React.FC<Prop> = (props) => {
  const formRef = React.createRef<FormInstance>();

  useEffect(() => {
    props.getSystemStatus(null,null,null,props.csuMaintOptions.record.mastertag);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const onRefresh=()=>
  {
    props.getSystemStatus(null,null,null,props.csuMaintOptions.record.mastertag);
  }
const StatusFields : string[] = ["hardwareId","softwareType", "version", "status", "action","updatedAt"]
//console.log(props.csus.csuList.csuMaint.systemstatus);
  return (
    
    <>
      <Form
        id="SoftwareStatus"
        ref={formRef}
        layout="vertical"
      >
       {props.csus.csuList.csuMaint.systemstatus!=null ? <Row gutter={24}>  
            <Col xs={24} lg={10}>
                <Button 
                data-id=".csu-status-refresh-button" 
                className="mt-10" 
                onClick={onRefresh}
                type="default" 
                icon={<ReloadOutlined />}
                >       
                Refresh
                </Button>
            </Col>              
              <Col span={24}>
              <table className="styled-table" style={{ width: "100%" }}>
                <thead>
                  <tr key={Math.random()}>
                    <th>HardwareId</th>
                    <th>File Type</th>
                    <th>Version</th>
                    <th>Status</th>
                    <th>Action</th>
                    <th>Updated At</th>
                  </tr>
                </thead>
                <tbody>
                  <tr> 
                    {
                      props.csus.csuList.csuMaint.systemstatus && 
                      Object.entries(StatusFields).map(([key, field]) => (                                  
                        <td key={field}>
                          {props.csus.csuList.csuMaint.systemstatus[field]}
                        </td>                
                      ))
                    }
                </tr>
                </tbody>
              </table>
              </Col>
        </Row>:<div><p>There is no Status available for this Device at this moment.</p></div>}
      </Form>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    context: state.contextReducer.context,
        csus: state.DeviceReducers.DeviceState.csus,
        selectedCSU: state.DeviceReducers.DeviceState.csus.selectedCSU,
        permissions: state.contextReducer.data,
        csuMaintOptions: state.DeviceReducers.DeviceState.csus.csuMaintOptions
  };
};

export default connect(mapStateToProps, {
  ...actions,
})(DeviceStatus);