import React, {useState} from 'react';
import {Drawer, Button } from 'antd';

import { Template } from '../models';
import { WidgetConfig } from '../widgets';
import Event from '../../../lib/event';
import EditFormContainer from './EditFormContainer';
import { appConfigs } from '../../../utils/configurations';

interface Props {
  template: Template;
  onFinish: (wcfg: WidgetConfig) => void;
  title: string;
  visible: boolean;
  onClose: () => void;
  onDualChange:(checked:boolean)=>void;
  enabledualaxis:boolean;
}

const WidgetEditDrawer: React.FC<Props> = (props) => {
  const [submitter] = useState(new Event());
  const drawerWidth:number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);

  return (
    <Drawer
      width={drawerWidth}
      placement="right"
      {...props}
      footer={
        <Button
          type="primary"
          htmlType="button"
          onClick={() => submitter.trigger()}
        >
          Save
        </Button>
      }
      onClose={props.onClose}
      destroyOnClose
    >
      <EditFormContainer
        {...props} 
        onFinish={(cfg) => {
          props.onFinish(cfg);
          props.onClose();
        }}
        submitTrigger={submitter}
      />
    </Drawer>
  );
};

export default WidgetEditDrawer;