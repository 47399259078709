import React from "react";
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { appConfigs } from "../../../utils/configurations";

import { connect } from "react-redux";
import * as actions from "../actions";

interface Prop {
    formRef: any;
    
    orgLoginBackgroundMaint: Function
    orgsList: any;
}

interface State {
    imageSrc?: string;
    fileList: any[];
    loading: boolean;
}

class OrgLoginBackground extends React.Component<Prop, State> {  

    state:State = {
        fileList: [],
        loading: false
    }

    componentDidMount() {
        this.componentUpdate();
    }

    componentDidUpdate(prevProps: Prop) {
        if (this.props.orgsList.orgMaint.instance !== prevProps.orgsList.orgMaint.instance) {
            this.componentUpdate();
        }
    }

    componentUpdate() {
        const imageSrc = this.props.orgsList.orgMaint.record.loginBackgroundUploadSrc;
        this.setState({ loading: false, imageSrc: (imageSrc ? appConfigs.server.URL + imageSrc : "") });
    }

    beforeUpload = (file:any) => {
        const fileType = file.type === 'image/jpeg' || file.type === 'image/png';
        const fileSize = file.size / 1024 < 500;

        if (!fileType) {
            message.error('JPG/PNG file types are only allowed');
        }
        if (!fileSize) {
            message.error('Image must be smaller than 500kB');
        }
        
        return fileType && fileSize;
    }
    
    upload = async (options:any) => {                
        const { onSuccess, onError, file, action } = options;

        this.setState({ 
            fileList: [{
                uid: "1",
                name: file.name,
                status: "done"
            }]
        });

        const formData = new FormData();
        formData.append("loginBackground", file);

        this.props.orgLoginBackgroundMaint(this, onSuccess, onError, this.onUploadSuccess, null, action, formData, options.file);
    }        

    onUploadSuccess = (uploadId: string, url: string) => { 
        const finalUrl = appConfigs.server.URL + url;

        this.setState({ imageSrc: finalUrl });
        this.props.formRef.setFieldsValue({
            
            loginBackgroundUploadId: uploadId,
            loginBackgroundUploadSrc: finalUrl,
            fileList: []
        });
        message.warning({ content: "Click Submit button to save the login splash image", duration: 2});
    }

    onRemove = (file:any) => { 
        this.componentUpdate();
        
        this.setState(state => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
                fileList: newFileList,
            };
        });
    }

    handleChange = (info:any) => {        
        if (info.file.status === "uploading") {
            this.setState({ loading: true });
        }
        else if (info.file.status === "error") {
            this.setState({ loading: false });
        }
        else if (info.file.status === "done") {
            this.setState({ loading: false });
        }
    };    

    render() {

        const { imageSrc } = this.state;

        return (
            <>
                {
                    imageSrc && 
                    <div>
                        <img src={imageSrc} className="org-image-preview" alt="Splash preview"/>
                    </div>
                }
                <div>                
                    <Upload 
                        data-id="orgs-maint-info-login-background-upload"
                        multiple={false}
                        action= {appConfigs.server.URL + "/ui/api/orgs/login-background"}
                        beforeUpload={this.beforeUpload}
                        onChange={this.handleChange}
                        customRequest={this.upload}
                        onRemove={this.onRemove}
                        fileList={this.state.fileList}
                    >
                        <Button style={{ width: 140}}>
                            <UploadOutlined /> Select File
                        </Button>
                    </Upload>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        orgsList: state.orgsReducers.orgsState.orgsList
    };
};

export default connect(
    mapStateToProps,
    actions
)(OrgLoginBackground);