import { CommonWidgetConfig } from '../../models';
import { WidgetDetails } from '../models';
import Widget from './Widget'
import EditForm from './EditForm';

export type Config = CommonWidgetConfig & { type: "text" };

export const details: WidgetDetails = {
  type: "text",
  name: "Text Widget"
}

export { EditForm, Widget }