import React from "react";
import { Form, Select } from 'antd';

import { appConfigs } from "../../../utils/configurations";

import { connect } from "react-redux";
import * as actions from "../actions";

const { Option } = Select;

interface Prop {
    scheduleMaintInfoOrgsGet: Function,
    scheduledList: any,
    context: any,
    scheduleMaintInfoReportsGet: Function,
    formRef: any,
}

class ScheduleMaintInfoOrg extends React.Component<Prop, {}> {

    componentDidMount() {
        this.componentUpdate();
    }

    componentDidUpdate(prevProps: Prop) {
        if (this.props.scheduledList.scheduledMaint.instance !== prevProps.scheduledList.scheduledMaint.instance) {
            this.componentUpdate();
        }
    }

    componentUpdate() {
        this.props.scheduleMaintInfoOrgsGet(this, null, null, null, null);
    }

    onOrgChange = (value: string, option: any) => {
        if ((value || "") !== "") {
            this.props.scheduleMaintInfoReportsGet(this, null, null, value);
        }
    }

    render() {

        const { orgs } = this.props.scheduledList.scheduledMaint;
        const { mode } = this.props.scheduledList.scheduledMaint;

        return (
            <Form.Item
                name="orgId"
                label="Org Name"
                rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
            >
                <Select
                    data-id="schedule-maint-schedule-info-org-id"
                    showSearch
                    showArrow={true}
                    allowClear
                    placeholder="Select..."
                    optionFilterProp="children"
                    className="select-before"
                    onChange={this.onOrgChange}
                    disabled={mode === "edit"}
                >
                    {orgs.map((record: any, index: number) => (
                        <Option key={record.orgId} value={record.orgId}>
                            {record.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        context: state.contextReducer.context,
        scheduledList: state.reportSchedulesReducers.reportScheduledState.scheduledList
    };
};

export default connect(
    mapStateToProps,
    actions
)(ScheduleMaintInfoOrg);