import React, { useEffect, useState } from "react";
import { Modal, Table, Checkbox, Space, Tooltip, Input, InputNumber, Popconfirm,Typography, Select, Col, Row, Button, message, Divider, Popover, Form, Radio } from 'antd';
import { connect } from "react-redux";
import * as actions from "../actions";
import * as contractActions from "../../Admin/Contracts/actions";

import "../orgs.css";
import { EditOutlined, DeleteOutlined  } from "@ant-design/icons";
const { TextArea } = Input;


interface Prop {
    getCustomerBucketDetails: Function,   
    getOrgServicePlanBucketByOrdId: Function,   
    getServicePlansList: Function,   
    transferServicePlanToOrg: Function,
    returnServicePlanToTopParentCustomerOrg: Function, 
    getOrgGlobalNotesList:Function,
    orgGlobalNotesMaint:Function,
    context: any,
    assetsList: any,
    servicePlansList: any,
    customerBucketDetails: any,
    currentOrgDetails: any,
    orgGlobalNotesList: any
}
interface Item {
    id: string;
    orgId: string;
    noteBody: string;
    noteType: string;
    createdAt: string;
    createdBy: string;
  }  
  
  
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text';
    record: Item;
    index: string;
    children: React.ReactNode;
  }

  const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        assetsList: state.assetsReducer.assetsState.assetsList,
        servicePlansList: state.contractsReducers.contractsState.contracts.servicePlansList,
        customerBucketDetails: state.contractsReducers.contractsState.contracts.customerBucketDetails,
        orgGlobalNotesList: state.orgsReducers.orgsState.orgsList.orgMaint.tabs[5].tableData
    };
};

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    let inputNode = inputType === 'number' ? <TextArea rows={2} /> : <TextArea rows={2} />;
    //let inputNode2 = inputType === 'number' ? <Input /> : <Input />;
    if(dataIndex==='noteBody'){
        inputNode = <TextArea rows={3} /> ;
    }else if(dataIndex==='noteType'){
        inputNode = <Select defaultValue={record.noteType} options={[{ value: 'Internal', label: 'Internal' },{ value: 'External', label: 'External' }]} />;
    } else if(dataIndex==='id'){
      inputNode=<Input value={record.id} disabled={true} hidden={true}/>
    }else if(dataIndex==='orgId'){
      inputNode=<Input value={record.orgId} disabled={true} hidden={true}/>
    }else{
        inputNode=<span/>
    }
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };
  
  const OrgGlobalNotes: React.FC<Prop> = (props) => {
    
   const [form] = Form.useForm();
    useEffect(() => {
    props.getOrgGlobalNotesList(null, null, null,"both",props.currentOrgDetails.orgId);
  }, [props.currentOrgDetails.orgId]);
    const [editingKey, setEditingKey] = useState('');
    
    const globalNotesData=props.orgGlobalNotesList;
    const isEditing = (record: Item) => record.id === editingKey;
  
    const edit = (record: Partial<Item> & { id: React.Key }) => {
      form.setFieldsValue({ noteBody: '', noteType: '', address: '', ...record });
      setEditingKey(record.id);
    };
  
    const cancel = () => {
      setEditingKey('');
    };
    const updateNote = async (key: React.Key,orgId:any,mode:any) => {
      try {
        const row = (await form.validateFields()) as Item;
        row.id=key.toString();
        row.orgId=orgId;
        setEditingKey('');
        await props.orgGlobalNotesMaint(null,onUpdateSuccess,onFailure,mode,JSON.stringify(row));
      } catch (errInfo) {
        message.error('Validation Failed:'+ errInfo);
        console.log('Validation Failed:', errInfo);
      }
    };
    
    const onUpdateSuccess = async (record: any,mode:any) => {
      if(mode==='update'){
        message.success("Global Note Updated Successfully");
      }else{
        message.success("Global Note Deleted Successfully");
      }
    }
    const onFailure = (error: any,mode:any) => { 
      message.error(error);
    }
    
    const columns = [
      {
        title: 'Id',
        dataIndex: 'id',
        width: -1,
        hidden: true,
        editable: true,
        show:false,
      },
      {
        title: 'orgId',
        dataIndex: 'orgId',
        width: -1,
        hidden: true,
        editable: true,
        show:false,
      },
      {
        title: 'Note Type',
        dataIndex: 'noteType',
        width: '15%',
        editable: true,
      },
      {
        title: 'Note Description',
        dataIndex: 'noteBody',
        width: '70%',
        editable: true,
      },
      {
        title: 'Actions',
        dataIndex: 'operation',
        render: (_: any, record: Item) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Typography.Link onClick={() => updateNote(record.id,record.orgId,'update')} style={{ marginRight: 5 }}>
              <span>Save</span>
              </Typography.Link>
              <a style={{color:'red'}} onClick={cancel}>Cancel</a>
            </span>
          ) : (
            <span>
            <Button style={{marginRight:5}} title="Edit Global Note" data-id="global-note-edit-button" disabled={editingKey !== ''} type="default" icon={<EditOutlined />} onClick={() => edit(record)}/>
            <Popconfirm title="Confirm to delete?" okText="Yes" cancelText="No" onConfirm={() => updateNote(record.id,record.orgId,'delete')}>
              <Button title="Delete Global Note" style={{marginLeft:5}} data-id="global-note-delte-button" type="default" icon={<DeleteOutlined />} />
            </Popconfirm>
            </span>
          );
        },
      },
    ].filter(item => !item.hidden);
  
    const mergedColumns = columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: Item) => ({
          record,
          inputType: col.dataIndex === 'age' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });
  
    return (
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={globalNotesData}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
        
      </Form>
    );
  };
    
export default connect(
    mapStateToProps,
    {
        ...actions,
        ...contractActions
    },
    
)(OrgGlobalNotes);
