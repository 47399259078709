import React from "react";
import { List, Tooltip } from "antd";
import { formatDate, inputSignalMapper, signalLabel } from "../common";
import { CommonWidgetProps } from "../../models";

interface Signal {
  name: string;
  values: Array<{
    timestamp: Date;
    value: number | string | boolean;
  }>;
}

const createSignals = inputSignalMapper<Signal>((meta, data) => ({
  name: signalLabel(meta, false),
  values: [{ ...data[0] }],
}));

function formatNumber(n: number | string | boolean): string | boolean {
  return typeof n === "number" ? n.toFixed(2) : n;
}

const Widget: React.FC<CommonWidgetProps> = (props) => {
  const { template, data } = props;
  const signals = createSignals(props.signals, template, data);

  return (
    <List
      dataSource={signals}
      renderItem={(signal) => (
        <List.Item>
          <List.Item.Meta title={signal.name} />
          <Tooltip
            title={formatDate(signal.values[0].timestamp)}
            placement="topRight"
            arrowPointAtCenter={true}
          >
            <div>{formatNumber(signal.values[0].value)}</div>
          </Tooltip>
        </List.Item>
      )}
    />
  );
};

export default Widget;
