import React from "react";
import { connect } from "react-redux";
import { Form, Input, Typography, Select, Table, Checkbox, Row, Col, Tooltip, Button, message } from 'antd';
import { PlusOutlined, EllipsisOutlined, UserDeleteOutlined, EditOutlined, DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { FormInstance } from 'antd/lib/form';
import { appConfigs } from "../../../utils/configurations";
import { getPermissions, getPermissionsList, permissionsList, convertBinaryToValue } from "../../../utils/commonHelpers";
import { successResponse, errorResponse, resetFormErrors } from "../../../utils/apiHelpers/apiHelpers";
import { FormState, formInit } from "../../../utils/apiHelpers/formHelpers";
import { SuccessAlert, ErrorAlert } from "../../../utils/Components/formAlerts";
import { closePopover } from "../../../utils/commonHelpers";
import * as actions from "../actions";
import "./roleMaintInfo.css";

const { Option } = Select;

interface permissionsList {
    permissionId: number;
    permissions: number[];
}

interface Prop { 
    roleMaint: Function,
    getRolePermissions: Function,   
    getOrgRolesMaint: Function,
    rolePermissionMaint: Function,
    context: any,
    roleList: any
}

interface State {
    selectedRowKeys?: any[];
    formError?: boolean;
    formErrorMessage?: string[];
    formSuccess?: boolean;
    formSuccessMessage?: string;
    formCommit?: boolean;
    createchecked?: boolean;
    readchecked?:boolean;
    updatechecked?:boolean;
    deletechecked?:boolean;
    orgId: string;
    roleId: number;
    roleName: string;
    permissionsList: permissionsList[];
}

class RoleViewMaintInfo extends React.PureComponent<Prop, State> {    

    state:State = {
        orgId: "", 
        roleId: 0,
        roleName: "",
        permissionsList: []
    }
    
    drawerWidth:number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);
    formRef = React.createRef<FormInstance>();
    
    componentDidMount() {
       
        const { appContext } = this.props.context;
        this.setState({roleName: appContext.roleName, orgId: appContext.orgId});
        
       // appContext.roleName === "admin" ? this.props.getRolePermissions(this,null,null,"2") : this.props.getRolePermissions(this,null,null,"1");
        let permissionList = [];
        permissionList = permissionsList(this.props.roleList.roleMaint.record);
        this.setState({permissionsList: permissionList});
        this.componentUpdate(this.props.roleList.roleMaint.record);
        this.state.roleId = this.props.roleList.roleMaint.record.roleId;
    }
    
    componentDidUpdate(prevProps: Prop, prevState: any) {
          if(prevState.roleName !== this.state.roleName) {
            let permissionList = [];
            permissionList = permissionsList(this.props.roleList.roleMaint.record);
            this.setState({permissionsList: permissionList});
        }
        
        if (this.props.roleList.roleMaint.instance !== prevProps.roleList.roleMaint.instance) {
            //this.componentUpdate();
            this.componentUpdate(this.props.roleList.roleMaint.record);
        }       
    }

    componentUpdate(record: any) {        
        formInit(this);
        if (this.formRef.current != null) {
            this.formRef.current!.resetFields();
            this.formRef.current!.setFieldsValue({
                orgName:record.orgName,
                roleName: record.roleName,
                roleId: record.roleId,
                description: record.description
            })
        }
    }

    onFinish = (values:any) => {     
        if(values.roleName.toLowerCase() !== "admin" && values.roleName.toLowerCase() !== "guest" && values.roleName.toLowerCase() !== "users"){
            let permissions = convertBinaryToValue(this.state.permissionsList);
            let permissionObject = { mode: "update", roleId: this.state.roleId, roleName: this.state.roleName, description: values.description,
                                   isRoleAvailable: true, isActive: true, permissions: permissions
                                 };
             this.props.rolePermissionMaint(this, this.onFinishSuccess, this.onFinishFailure, permissionObject, this.state.orgId);

        }
        else{
           // errorResponse(this, "Edit will not be allowed to" + values.roleName);            
            message.error("Edit will not be allowed to " + values.roleName + " role.");
        }
      
    };

    onFinishSuccess = (recordOld:any) => {
       this.props.getRolePermissions(this, null, null, this.state.roleId);
       //this.setState({roleName: "barko_guest"});
        //this.props.getRolePermissions(this, null, null, "5");

        let permissionList = [];
        permissionList = permissionsList(this.props.roleList.tableData);
        this.setState({permissionsList: permissionList});
        successResponse(this, "Role and Permissions has been successfully updated");
    }

    onFinishFailure = (error: any) => { 
        errorResponse(this, error);
    }

    onCheckChange = (value: any) => {
        this.setState({ createchecked: value.target.checked});
    }

    onAction = (e:any, record:any, action:string) => {
        e.preventDefault();
        const { appContext } = this.props.context;
        const { roles } = this.props.roleList.roleMaint.orgRoles.data;               
        this.props.getOrgRolesMaint(this,null,null,appContext.orgId);      
        this.props.getRolePermissions(this, null, null, roles[0].roleId);
        
        closePopover();       
    }

    onCheckedChange = (e: any, permissionId: number) => {        
        const permissionType = +e.target.id.split(':')[1];
        this.setState({
            permissionsList: this.state.permissionsList.map((p: any) => {
            if (p.permissionId === permissionId) {
              p.permissions[permissionType] = +e.target.checked;
              return p;
            } else {
              return p;
            }
          }),
        });
    };

    render() {        
        const { appContext } = this.props.context; 
        let roleName = appContext.roleName;
        const { roles } = this.props.roleList.roleMaint.orgRoles.data;
        const { record } = this.props.roleList.roleMaint;
        let permissionList = [];
        
        // if(tableOrgData.status === "success"){
        //     for (let i=0; i<tableOrgData.data.orgRolePermissions.permissions.length; i++){           
        //         const result = getPermissionsList(tableOrgData.data.orgRolePermissions.permissions[i].permissionLevel);
        //         let permission = { permissionId:tableOrgData.data.orgRolePermissions.permissions[i].permissionId, 
        //             entityName:tableOrgData.data.orgRolePermissions.permissions[i].entity, 
        //             create:result[0], read:result[1], update:result[2], remove: result[3]               
        //         };
        //         permissionList.push(permission);
        //     }  
        // }

       
            for (let i=0; i<record.permissions.length; i++){           
                const result = getPermissionsList(record.permissions[i].permissionLevel);
                let permission = { permissionId:record.permissions[i].permissionId, 
                    entityName:record.permissions[i].entity, 
                    create:result[0], read:result[1], update:result[2], remove: result[3]               
                };
                permissionList.push(permission);
            }  
       
          
        
        
        const { permissionsList } = this.state;
       
        const tableColumns = [
            {
            title: "Permissions",
            dataIndex: "entityName",
            width: "50%",
            },
            {
            title: "View",
            dataIndex: "read",
            key: "read",
            width: "12%",
            render: (read: any, record: any) => (
                <>
               
                    <Checkbox 
                        checked={record.read === 1 ? true : false}
                    > 
                    </Checkbox>
               
                </>
            ),
            },
            {
            title: "Add",
            dataIndex: "create",
            key: "create",
            width: "12%",
            render: (create: any, record: any) => (
                <>
                 
                    <Checkbox 
                        checked={record.create === 1 ? true : false}
                    > 
                    </Checkbox>
                
                </>
            ),
            },
            {
            title: "Edit",
            dataIndex: "update",
            key: "update",
            width: "12%",
            render: (update: any, record: any) => (
                <>
               
                    <Checkbox 
                        checked={record.update === 1 ? true : false}
                    > 
                    </Checkbox>
               
                </>
            ),
            },
            {
                title: "Delete",
                dataIndex: "remove",
                key: "remove",
                width: "12%",
                render: (remove: any, record: any) => (
                    <>
                   
                        <Checkbox 
                            checked={record.remove === 1 ? true : false}
                        > 
                        </Checkbox>
                   
                    </>
                ),
            },
        ];

        return (
            <div data-id="role-maint-info">
                {this.state.formSuccess && !this.state.formError && <SuccessAlert data-id="role-maint-info-success-message"  message={this.state.formSuccessMessage}/>}
                {this.state.formError && !this.state.formSuccess && <ErrorAlert data-id="role-maint-info-error-message" message={this.state.formErrorMessage}/>}
               
                    <Form
                        {...appConfigs.settings.formLayout}
                        id="roleViewMaintInfoForm"
                        ref={this.formRef}
                        onFinish={this.onFinish}
                        className="mt-15"
                    > 
                        <Form.Item                                 
                            label="Org Name"
                            name="orgName"
                        >                           
                            <Input maxLength={200} readOnly/>
                        </Form.Item>
                       
                        <Form.Item
                            label="Role Name"
                            name="roleName"
                            shouldUpdate={true}
                        >
                            <Input maxLength={200} readOnly/>
                        </Form.Item>
                    
                        <Form.Item
                            label="Description"
                            name="description"
                        >
                            <Input maxLength={200} readOnly/>
                        </Form.Item>
                        
                    </Form>
               
               <div className="mt-15">
                <Table
                  data-id="dashboard-roles"
                  rowKey={(record) => record.permissionId}
                  columns={tableColumns}
                  dataSource={permissionList}
                />
              </div> 
        </div>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        roleList: state.rolesReducer.rolesState.roleList
        
    };
};

export default connect(
    mapStateToProps,
    actions
)(RoleViewMaintInfo);