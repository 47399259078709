import {  
  DBWidgetConfig,  
  SignalCollection,
  Rule,  
  Dashboard,
  TextMessage,
  C2DWlist
} from "./models";

import { AssetDashboardResponse, Datapoint } from "./api";
import { debug } from "console";

export function constructAssetData(
  apiResponse: any,
  mastertag: string
): AssetDashboardResponse {
  function constructSignalCollection(datapoint: Datapoint): SignalCollection {
    const { _id, name, type, values = [] } = datapoint;
    let orgCmdPgnSpns =  apiResponse.data.assets[0].orgCmdPgnSpns;
    return {
      id: _id,
      name,
      type,
      signals:
        values?.map((v) => {
          if (type==="Command") {
            let pgnSpns= v.pgn === null ? "0-0" : (v.spn === null ? v.pgn + '-' + '0':  v.pgn + '-' + v.spn);
            if ( orgCmdPgnSpns !== null && orgCmdPgnSpns != '-'  && orgCmdPgnSpns.includes(pgnSpns)) {
              return {
                id: v._id,
                name: v.name || v.label,
                format: v.format,
                color: "#000000", // TODO 
                units: v.unit, 
                pgn: v.pgn
              };
            } else { // The org allowed comand PgnSpns does not incude this cmd signal
              return {
                id: v._id,
                name: "NA-CMD",
                format: v.format, 
                color: "#000000",
                units: v.unit, 
                pgn: ""
              };
            }
          } else { // Not command collection
            return {
              id: v._id,
              name: v.name || v.label,
              format: v.format, // response signal might be text format need to convert to ascii
              color: "#000000", // TODO 
              units: v.unit, 
              pgn: ""
            };
          }
        }).filter(v => v.name != "NA-CMD") || [],
    };
  }

  function constructDashboardCollecton(dashboard: Dashboard) {
    if (dashboard.widgets) {
      dashboard.widgets.map(w => w.signals.map(s => s.color ? s.color : "#0D62F2" ));
    }
    return dashboard;
  }

  const assets = apiResponse.data.assets[0];
  const datapoints = assets["datapoints"] !== undefined ? ((JSON.parse(assets["datapoints"]) || []) as Datapoint[]) : null;
  const widgets = assets["widgets"] !== undefined ? ((JSON.parse(assets["widgets"]) || []) as DBWidgetConfig[]) : null;
  const rawRules = assets["signalRules"] !== undefined ? (JSON.parse(assets["signalRules"]) || []) : null;
  const rulesList = rawRules?.flatMap((s: any) => s.conditions) || [];
  const Dashboards = assets["tabs"] !== undefined ? ((JSON.parse(assets["tabs"]) || []) as Dashboard[]) : null;
  const c2dMsgs = assets["c2dMessages"] !== undefined ? ((JSON.parse(assets["c2dMessages"]) || []) as TextMessage[]) : null;

  //added c2dWhitelist here
  // console.log("assets from dashboard API response", assets)
  const c2dWhitelist = assets["c2dWhitelist"] !== undefined ?((JSON.parse(assets["c2dWhitelist"])|| []) as C2DWlist[]): null;

  function formatRules(rule: any): Rule {
    const {
      CriticalHigh,
      WarningHigh,
      InfoHigh,
      InfoLow,
      WarningLow,
      CriticalLow,
    } = rule.Value;
    return {
      signalId: rule.SignalId,
      criticalHigh: CriticalHigh,
      warningHigh: WarningHigh,
      infoHigh: InfoHigh,
      infoLow: InfoLow,
      warningLow: WarningLow,
      criticalLow: CriticalLow,
    };
  }

  const rules: Rule[] = rulesList?.map(formatRules) || [];

  const { nickname } = assets;
  const { assetDownDesc } = assets;
  const asset: AssetDashboardResponse = {
    asset: {
      ...assets,
      id: assets.gatewayId,
      name: nickname,
      template: {
        signalCollections: datapoints?.map(constructSignalCollection) || [],
        rules: rules,
      },
      widgets: widgets || [],
      dashboards: Dashboards?.map(constructDashboardCollecton) || [], 
      c2dMessages: c2dMsgs || [],
      c2dWhitelist: c2dWhitelist || [],
      assetDownDesc:assetDownDesc
    }
  };
  return asset;
}

