import React from "react";
import { Form, Row, Col, InputNumber, Card } from "antd";

import { Config } from "./index";

import {
  EditFormProps,
  WidgetTitleFormField,
  SignalSelectorList,
} from "../../WidgetEditForm";
import Container from "../../WidgetEditForm/Container";
import "./BarChartEditForm.css";

function sanitizeInputs(values: Config): Config {
  return {
    title: values.title,
    settings: values.settings,
    type: "bar",
    signals: values.signals.filter((v) => !!v),
  };
}

const axisRangePath = (end: string) => ["settings", "axisRange", end];

export const EditForm: React.FC<EditFormProps> = (props) => (
  <div className="bar-chart-edit-form">
    <Form
      onFinish={(values) => props.onFinish(sanitizeInputs(values as Config))}
      layout="vertical"
      form={props.form}
      initialValues={props.config}
    >
      <Card title="Bar Chart Settings">
        <Row>
          <WidgetTitleFormField />
        </Row>
        <Row justify="space-between" style={{ width: "95%" }}>
          <Col span={10}>
            <Form.Item
              name={axisRangePath("min")}
              label="Axis Min"
              rules={[{ type: "number" }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={10} push="right">
            <Form.Item
              name={axisRangePath("max")}
              label="Axis Max"
              rules={[{ type: "number" }]}
            >
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <SignalSelectorList max={6} signals={props.template.signals} />
      </Card>
    </Form>
  </div>
);
