import React, { Component } from "react";
import { Table, Descriptions, Button, Modal, message, Radio, Tooltip, Drawer } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { 
    CheckCircleOutlined, CloseCircleOutlined
} from "@ant-design/icons";
import onlineIcon from "../../assets/images/map-status/online.png";
import offlineIcon from "../../assets/images/map-status/offline.png";

import history from '../../utils/history';
import { appConfigs } from "../../utils/configurations";
import { localizeUtcDate, getPermissions } from "../../utils/commonHelpers";

import { connect } from "react-redux";
import * as actions from "./actions";

import AlertsCmdHistoryFilters from "./AlertsCmdHistoryFilters";
import "./alerts.css";

interface Prop {    
    alertsCmdHistorySetPagination: Function,
    alertsCmdHistory: Function,
    alertsCmdHistoryFilters: Function,
    getAsset: Function,    
    context: any,
    alertsState: any,
    permissions: any,
    mastertag: string,
    isread: boolean,
    selectedType: Function,
}

interface State {
    nickname: string[],
    selectedRowKeys: string[], 
    loading: boolean,
    isModalVisible: boolean,
    viewHistoryType: string,
    selectedAlert: any
}

class AlertsCmdHistory extends Component<Prop, State> {
    
    state:State = {
        nickname: [],
        selectedRowKeys: [], 
        loading: false,
        isModalVisible: false,
        viewHistoryType:"latest",
        selectedAlert:{}
    }

    componentDidMount () { 
        if ((this.props.mastertag || "") !== "") {
            this.props.getAsset(this, this.getAssetSuccess, null, this.props.mastertag);                         
        }
        else {
            this.componentUpdate();     
        }       
    }    
    
    getAssetSuccess = (mastertag: string, asset:any) => {  
        this.setState({ nickname: [asset.nickname] });     
        this.componentUpdate();        
    };

    componentUpdate = () => {   
        this.props.alertsCmdHistorySetPagination(this.onPaginationChange);
        this.props.alertsCmdHistory(this, null, null, 1, null, null, this.state.viewHistoryType, null, null, this.state.nickname);  
        this.props.alertsCmdHistoryFilters(this, null, null);    
    };

    onPaginationChange = (page: number) => {      
        const appliedFilters = this.props.alertsState.tabs[2].appliedFilters;     
        this.props.alertsCmdHistory(
            this, 
            null, 
            null, 
            page, 
            null,
            null,
            this.state.viewHistoryType,
            appliedFilters.timespanFrom,
            appliedFilters.timespanTo,
            appliedFilters.nickname,
            appliedFilters.command,
            appliedFilters.operator,
            appliedFilters.user,
            "", //appliedFilters.type,
            appliedFilters.status, 
            appliedFilters.tags
        ); 
    };
 
    goToAsset = (e: any, mastertag: string) => {
        e.preventDefault();
        history.push("/assets/" + mastertag);
    };

    onSelectChange = (selectedRowKeys:any) => {
        this.setState({ selectedRowKeys });
      };  

    showModal = () => {
        this.setState({isModalVisible: true});
      };
    
    handleOk = () => {
        this.setState({isModalVisible: false});
      };
    
    handleCancel = () => {
        this.setState({isModalVisible: false});
        this.setState({loading: false, selectedRowKeys:[]});
      };
 
      onChangeViewHistory = (e:any) => {
        this.setState({
            viewHistoryType: e.target.value,
            selectedRowKeys: [] 
        });
        this.props.selectedType(e.target.value);
         this.props.alertsCmdHistory(this, null, null, 1, null, null, e.target.value, null, null, this.state.nickname);
    };
    render() {  
        const result = getPermissions(this.props.permissions.permissions.CmdHistory_settings);      
        const { tableConfig, tableData } = this.props.alertsState.tabs[2];       
        const { loading, selectedRowKeys, viewHistoryType} = this.state;
        const rowSelection = {getCheckboxProps: (record:any)=>({disabled: true})};
        const hasSelected = selectedRowKeys.length > 0;
        
        const tableColumns = [
            {
                title: "Status",
                dataIndex: "status",
                render: (text: any, record: any) => (
                    <span className="no-word-wrap">
                        {
                            record.status === "Fail" &&
                            <><span className="dot alert-status-red "/>Fail</>
                        }
                        {
                            record.status === "Pending" &&
                            <><span className="dot alert-status-blue "/>Pending</>
                        }
                        {
                            record.status === "Success" &&
                            <><span className="dot alert-status-green "/>Success</>
                        }
                        {
                            record.status === "Deactivated" &&
                            <><span className="dot alert-status-orange "/>Inactive</>
                        }
                    </span>
                )
            },
            {
                title: "Updated",
                dataIndex: "reportedTime",
                responsive: ["lg"] as Breakpoint[],
                render: (reportedTime: string, record: any) => (
                    <>{localizeUtcDate(reportedTime, this.props.alertsState.dateFormatOptions)}</>                    
                ) 
            },
            {
                title: "Created",
                dataIndex: "requestTime",
                responsive: ["lg"] as Breakpoint[],
                render: (requestTime: string, record: any) => (
                    <>{localizeUtcDate(requestTime, this.props.alertsState.dateFormatOptions)}</>                    
                ) 
            },
            {
                title: "Command",
                dataIndex: "command",
                responsive: ["md"] as Breakpoint[],
                align: "center" as "center"                                       
            },
            {
                title: "Value",
                dataIndex: "desiredPayload",
                responsive: ["md"] as Breakpoint[],
                align: "center" as "center"                                       
            },
            {
                title: "Operator",
                dataIndex: "operator",
                responsive: ["md"] as Breakpoint[],
                align: "center" as "center"                                       
            },
            {
                title: "User",
                dataIndex: "user",
                responsive: ["md"] as Breakpoint[],
                align: "center" as "center"                                         
            },
            {
                title: "Connection",
                dataIndex: "connectionState",
                render: (text: any, record: any) => (
                    <span className="no-word-wrap">
                        {
                            record.connectionState === "DISCONNECTED" &&
                            <><span/><img src={offlineIcon} alt="" /></>
                        }
                        {
                            record.connectionState === "CONNECTED" &&
                            <><span/><img src={onlineIcon} alt="" /></>
                        }
                    </span>
                )
            },
            {
                title: "Org Name",
                dataIndex: "orgName",
                responsive: ["lg"] as Breakpoint[]
            },
            {
                title: "Asset",
                dataIndex: "nickname",
                render: (text: any, record: any) => (
                    <a href="# " onClick={(e) => {this.goToAsset(e, record.mastertag)}}> 
                        {record.nickname || record.mastertag} 
                    </a>
                )                
            }
        ];

        return (
            <>    
                <AlertsCmdHistoryFilters nickname={this.state.nickname} isread={this.props.isread} viewHistoryType={this.state.viewHistoryType} />
                {
                     this.props.isread &&
                     <div>
                     <br/>
                     <>
                     <span style={{ margin: 8 }}> 
                     <Radio.Group value={viewHistoryType}  buttonStyle="solid" onChange={this.onChangeViewHistory}  >
                         <Tooltip mouseEnterDelay={1} placement="bottom" title="Last command for each asset" arrowPointAtCenter>
                             <div style={{ display: 'inline-block'}}>
                                     <Radio.Button value="latest">
                                         <CheckCircleOutlined className="pr-5" />Latest
                                     </Radio.Button>
                             </div>
                         </Tooltip>
                         <Tooltip mouseEnterDelay={1} placement="bottom" title="All command history" arrowPointAtCenter>
                             <div style={{ display: 'inline-block'}}>

                                     <Radio.Button value="all">
                                         <CloseCircleOutlined className="pr-5" />All
                                     </Radio.Button>
                             </div>
                         </Tooltip>
                     </Radio.Group>
                     </span>
                     </>
                     <br/>
                    

                {this.props.isread &&     
                    <Table rowSelection={rowSelection}
                        data-id="alerts-cmd-history-data"
                        {...tableConfig}
                        rowKey={(record) => record.cmdHistoryId}
                        columns={tableColumns}
                        dataSource={tableData}
                        expandable={{
                            expandedRowRender: record => (
                                <Descriptions bordered column={1}>
                                    <Descriptions.Item label="Reason">{record.reason ? record.reason : ''}</Descriptions.Item>
                                    <Descriptions.Item label="Connection State">{record.connectionState ? record.connectionState : ''}</Descriptions.Item>
                                    <Descriptions.Item label="Connection State Last Updated At">{record.connectionStateLastUpdatedTime ? record.connectionStateLastUpdatedTime : ''}</Descriptions.Item>
                                    <Descriptions.Item label="Estimated Command Time">{record.estimatedCommandTime ? record.estimatedCommandTime : 'NA '}</Descriptions.Item>
                                </Descriptions>
                            ),
                            rowExpandable: record => (record.Reason || record.connectionState || record.connectionStateLastUpdatedTime || record.estimatedCommandTime),
                        }}
                        className="mt-20"
                    />
                    }
                </div>
                } 
            </>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        permissions: state.contextReducer.data,
        alertsState: state.alertsReducer.alertsState
    };
};

export default connect(
    mapStateToProps,
    actions
)(AlertsCmdHistory);
  