import React, { useState, useEffect } from "react";

import { Input, Affix } from "antd";
import { SyncOutlined } from "@ant-design/icons";

import FiltersBar from "../filtersBar/FiltersBar";
import { Filters } from "../filtersBar/models";
import filterAssets from "../filtersBar/filter-assets";
import AssetList from "../assetList/AssetList";
import { appConfigs } from "../../../../utils/configurations";
import { MapActions } from "../../../../sharedComponents/GoogleMap";

import { connect } from "react-redux";
import * as actions from "../../actions";

import "../../fleetOverview.css";

const { Search } = Input;

interface Props {
    fleetOverviewState: any,
    clusterInfoSearch: Function,
    mapsAssetDrawerClose: Function,
    context: any,
    mapActions?: MapActions
}

const ClusterInfo = (props:Props) => {

    const [container, setContainer] = useState<any>(null);
    const [searchText, setSearchText] = useState<string>("");
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
    const [loading, setLoading] = useState<boolean>(true);
    const [filters, setFilters] = useState<Filters>({
        critical: false,
        warning: false,
        info: false,
        dm1: false,
        online: false,
        offline: false,
        asset_down: false
    });
    
    useEffect(() => {
        let isMounted = true; 
                
        setTimeout(function () {
            if (isMounted) {
                setLoading(false);
            }
        }, 250);   

        return () => { isMounted = false };                 
    }, [])

    const toggleFilters = (f: keyof Filters) => {
        setFilters({
            ...filters,
            [f]: !filters[f],
        });
    };   

    const onSearchChange = (e: any) => {
        const searchText = e.target.value;
        
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setTypingTimeout(
            setTimeout(function () {
                setSearchText(searchText);
                props.clusterInfoSearch(searchText);
            }, appConfigs.app.fleetOverview.assetSearchGlobalKeystrokeDelay)
        )        
    }
    
    const { assetDrawer } = props.fleetOverviewState;

    return (
        <>
            <div data-id="map-cluster-info" ref={setContainer}>           

                <Affix data-id="map-cluster-info-affix" offsetTop={1} target={() => container}>
                    <div> 
                        <FiltersBar
                            assetCount={assetDrawer.cluster.assets.length}
                            filters={filters}
                            toggleFilters={toggleFilters}
                        />

                        <Search
                            data-id="map-cluster-info-list-search-field"
                            key="tree-search-field"
                            placeholder="Filter assets"
                            size="middle"
                            allowClear
                            onChange={onSearchChange}
                            className="search-field"
                        /> 
                    </div>   
                </Affix>

                {
                    !loading &&
                    <AssetList 
                        context="list-cluster"
                        assets={filterAssets(assetDrawer.cluster.filteredAssets, filters)} 
                        assetsAll={assetDrawer.cluster.assets} 
                        searchText={searchText}
                        filters={filters}
                        assetDrawerClose={props.mapsAssetDrawerClose}
                        mapActions={props.mapActions}
                    />
                }
                            
            </div>

            {
                loading &&
                <div data-id="map-asset-list-loading">
                    <SyncOutlined spin className="pr-5"/>
                </div>
            }          
        </>
    );
};

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,        
        fleetOverviewState: state.fleetOverviewReducers.fleetOverviewState
    };
};

export default connect(
    mapStateToProps,
    actions
)(ClusterInfo);
  