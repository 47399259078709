import React from "react";
import { Tag, Card, Button, Badge, Switch } from "antd";

import { connect } from "react-redux";
import * as actions from "./actions";

import "./orgSelector.css";
import { getPermissions } from "../../utils/commonHelpers";
//import { getPermissions } from "../../actions/permissions";

interface Prop {
    orgSelectorHeaderSetDisplay: Function,
    orgSelectorHeaderTagRemove: Function,
    orgSelectorUserOrgSelectionGet: Function,
    orgSelectorListMaint: Function,
    orgSelectorTreeMaint: Function,
    orgSelectorIncludeSuborgs: Function,
    orgSelector: any,
    permissions: any
}

class OrgSelectorHeader extends React.Component<Prop, {}> {  
    
    componentDidMount() {
        this.componentUpdate();        
    }

    componentDidUpdate(prevProps: Prop) {
        if (this.props.orgSelector.instance !== prevProps.orgSelector.instance) {
            this.componentUpdate();
        }
    }

    componentUpdate() {
        this.props.orgSelectorUserOrgSelectionGet(this, null, null);
    }

    onTagCloseAll = (e:any) => {
        e.preventDefault();

        let tags:any = document.querySelectorAll('[data-id=org-selector-selection-container] .ant-tag span');

        if (tags.length) {
            for (var i = 0; i < tags.length; i++) { 
                tags[i].click();  
            }
        }
    }

    setDisplay = (e:any) => {
        e.preventDefault();
        this.props.orgSelectorHeaderSetDisplay();
    }

    onTagClose = (e:any, orgId:string) => {
        this.props.orgSelectorListMaint(this, this.onTagCloseSuccess, null, orgId, false);
        this.props.orgSelectorTreeMaint(this, this.onTagCloseSuccess, null, orgId, false);
    }

    onTagCloseSuccess = (data:any, orgId:string) => {
        this.props.orgSelectorHeaderTagRemove(this, orgId);
    }

    onSuborgsChange = (checked:boolean, e:any) => {
        this.props.orgSelectorIncludeSuborgs(this, null, null, checked)
    }

    render() {
        
        const { orgSelector } = this.props;

        return (
            <>
                { 
                    orgSelector.orgSelection.length > 0  &&
                    <Card 
                        data-id="org-selector-selection-container"
                        size="small" 
                        title={
                            <>                            
                                <span>Selection</span>
                                <Badge data-id="org-selector-selection-badge" className={"pl-5 site-badge-count-4" + (orgSelector.includeSuborgs ? " org-selector-has-sub-orgs" : "")} count={orgSelector.orgSelection.length} style={{ backgroundColor: '#108ee9', marginBottom: 5 }} />
                                {                                    
                                    <div data-id="org-selector-header-include-suborgs">
                                        <Switch checkedChildren="SUB-ORGS" unCheckedChildren="SUB-ORGS" onChange={(checked:boolean, e:any) => this.onSuborgsChange(checked, e)} checked={orgSelector.includeSuborgs}/>
                                    </div>
                                }
                                
                            </>
                        } 
                        extra={                            
                            <a data-id="org-selector-selection-show-hide" href="# " onClick={(e) => {this.setDisplay(e)}}>{orgSelector.selectonHeader ? "Hide" : "Show"}</a>
                        } 
                    >
                        {
                            orgSelector.selectonHeader &&
                            <div>
                                {
                                    orgSelector.orgSelection
                                        .map((record:any, index:number) => (
                                            <Tag key={"selection-" + record.orgId} closable onClose={(e:any) => this.onTagClose(e, record.orgId)}>
                                                {record.name}
                                            </Tag>
                                        ))
                                }
                                { 
                                    orgSelector.orgSelection &&
                                    <Button key={"remove-all"} data-id="org-selector-button-remove-all" type="dashed" onClick={(e) => {this.onTagCloseAll(e)}}>Remove All</Button>
                                }
                            </div>
                        }
                    </Card>
                }
            </>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        orgSelector: state.orgSelectorReducers.orgSelectorState.orgSelector,
        permissions: state.contextReducer.data
    };
};

export default connect(
    mapStateToProps,
    actions
)(OrgSelectorHeader);
