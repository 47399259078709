import React from "react";
import { Form } from "antd";

import { Rule, Signal } from "../models";
import ColorSelectorList from "./ColorSelectorList";
import BooleanSelectorList from "./BooleanSelectorList";
import SelectorList from "./SelectorList";

interface Props {
  max?: number;
  signals: Signal[];
  rules: Rule[];
  isColor?: string;
  orgCmdPgnSpns? : string;
}

const SignalSelectorList: React.FC<Props> = (props) => {
  //console.log("SignalSelectorList props:",props);
  const gaugelabels = ["Fill","Remain"];
  const boolLabels = ["ON","OFF"];
  const colorSelectable = props.signals.map((s) => ({
    id: s.id,
    label: s.name,
    color: s.color,
    value: s.id,
  }));
  const selectable = props.signals.map((s) => ({
    value: s.id,
    label: s.name,
  }));
  let isColor = props.isColor;
 //console.log("SignalSelectorList props",props)
  return (
    <Form.Item
      label="Signals"
      name="signals"
      required
      rules={[
        {
          validator(rule, value) {
            if (value) {
              if (value.length === 0) {
                return Promise.reject(
                  "At least one valid signal must be specified"
                );
              }
              let res = value.filter((v: any) => {
                if (v) {
                  if (v?.id !== "") {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              });
              if (res.length < 1) {
                return Promise.reject(
                  "At least one valid signal must be specified"
                );
              } else {
                return Promise.resolve();
              }
            } else {
              return Promise.reject(
                "At least one valid signal must be specified"
              );
            }
          },
        },
      ]}
    >
      {isColor === "boolean" ? (
        <BooleanSelectorList
          options={colorSelectable}
          maxSelected={props.max}
          valueLabel="Signal"
          rules={props.rules}
          labels= {boolLabels} />
      ) : isColor === "gauge" ? (
        <BooleanSelectorList
          options={colorSelectable}
          maxSelected={props.max}
          rules={props.rules}
          valueLabel="Signal"
          labels= {gaugelabels} />
      ) : isColor === "color" ? (
        <ColorSelectorList
          options={colorSelectable}
          maxSelected={props.max}
          valueLabel="Signal"
        />
      ) : (
        <SelectorList
          options={selectable}
          maxSelected={props.max}
          valueLabel="Signal"
          orgCmdPgnSpns = {props.orgCmdPgnSpns}
        />
      )}
    </Form.Item>
  );
};

export default SignalSelectorList;
