import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function geofencesState(state = initialState, action: any) {
	let data: any = null,
		payload: any;

	switch (action.type) {
		case "SET_DRAWER_OPEN":
			return immerProduce(state, (draft) => {
				
				let arr:any[] = draft.geofencesList.fenceList;
                let index:any = arr.findIndex((item) => (item.shape === action.payload.geofenceShape.shape));

				draft.geofencesList.geoFenceMaint.record.fenceName = arr[index].fenceName;
                draft.geofencesList.geoFenceMaint.record.fenceColor = arr[index].fenceColor;
                draft.geofencesList.geoFenceMaint.record.description = arr[index].description;
                draft.geofencesList.geoFenceMaint.record.type = arr[index].type;
                draft.geofencesList.geoFenceMaint.record.geoCoordinates = arr[index].geoCoordinates;
                draft.geofencesList.geoFenceMaint.record.id = arr[index].id;
                draft.geofencesList.geoFenceMaint.mastertags = arr[index].mastertags;
				draft.geofencesList.geoFenceMaint.visible = true;

				draft.geofencesList.geoFenceMaint.changeInGateways = []; // initially no change
			});
		case "SET_DRAWER_CLOSE":
			return immerProduce(state, (draft) => {
				draft.geofencesList.geoFenceMaint.visible = false;
			});
		case "SET_NAME":
			return immerProduce(state, (draft) => {
				draft.geofencesList.geoFenceMaint.record.fenceName = action.payload.fenceName;
			});
		case "SET_COLOR":
			return immerProduce(state, (draft) => {
				draft.geofencesList.geoFenceMaint.record.fenceColor = action.payload.fenceColor;
			});
		case "SET_DESCRIPTION":
			return immerProduce(state, (draft) => {
				draft.geofencesList.geoFenceMaint.record.description = action.payload.description;
			});
		case "SET_MAP":
			return immerProduce(state, (draft) => {
				draft.geofencesList.map = action.payload.map;
			});
		case "SET_DRAWING_MANAGER":
			return immerProduce(state, (draft) => {
				draft.geofencesList.drawingManager = action.payload.drawingManager;
			});
		case "SET_MARKER_MANAGER":
			return immerProduce(state, (draft) => {
				draft.geofencesList.markerManager = action.payload.markerManager;
			});
		case "SET_FENCELIST":
			return immerProduce(state, (draft) => {			
				draft.geofencesList.fenceList = action.payload.fenceList;
				draft.geofencesList.fenceListLoaded = true
			});
		case "SET_FENCELIST_LOADED":
			return immerProduce(state, (draft) => {			
				draft.geofencesList.fenceListLoaded = action.payload.fenceListLoaded
			});
		case "OPEN_FENCELIST_DRAWER":
			return immerProduce(state, (draft) => {
				draft.geofencesList.fenceListDrawerVisible = true;
			});
		case "CLOSE_FENCELIST_DRAWER":
			return immerProduce(state, (draft) => {
				draft.geofencesList.fenceListDrawerVisible = false;
			});
		case "SET_ASSETSLIST":
			return immerProduce(state, (draft) => {
				draft.geofencesList.geoFenceMaint.assetsList = action.payload.assetsList;
			});
		case "SET_CHANGE_IN_GATEWAYS":
			return immerProduce(state, (draft) => {
				let item = action.payload.changeInGateways;
				let arr: any = draft.geofencesList.geoFenceMaint.changeInGateways;

				const filterFunc = (element: any) => {
					if (element.key !== item.key) {
						return true;
					}
					return false;
				};

				for (let i = 0; i < arr.length; i++) {
					if (arr[i].key === item.key) {
						draft.geofencesList.geoFenceMaint.changeInGateways = arr.filter(
							filterFunc
						);
						return;
					}
				}

				arr.push({ key: item.key, value: item.value, selected: item.selected });

				// draft.geofencesList.geoFenceMaint.changeInGateways = action.payload.changeInGateways;
			});
		case "RESET_CHANGE_IN_GATEWAYS":
			return immerProduce(state, (draft) => {
				draft.geofencesList.geoFenceMaint.changeInGateways = [];
			});
		case "SET_SHAPE_GATEWAYS":
			return immerProduce(state, (draft) => {
				let arr: any[] = draft.geofencesList.fenceList;

				let mastertags: any = [];
				let gatewayIds: any = [];

				let gateways: any[] = action.payload.gateways;

				gateways.forEach((item) => {
					gatewayIds.push(item.key);
					mastertags.push(item.value);
				});

				arr.forEach((item) => {
					if (item.id === action.payload.id) {
						item.gatewayIds = gatewayIds;
						item.mastertags = mastertags;
						item.latestPositionStatuses = {'status': null, 'updated_at': null};
					}
				});

				draft.geofencesList.geoFenceMaint.gatewayIds = gatewayIds;
				draft.geofencesList.geoFenceMaint.mastertags = mastertags;
			});
		case "ADD_SHAPE":
			return immerProduce(state, (draft) => {

				if(action.payload.geofenceShape.id !== ''){
					draft.geofencesList.fenceList = draft.geofencesList.fenceList.filter((geofence:any) =>
						geofence.id != action.payload.geofenceShape.id
					);
				}

				let arr: any[] = draft.geofencesList.fenceList;
				arr.push(action.payload.geofenceShape);
			});
		case "OPEN_FENCE_ASSETS_DRAWER":
			return immerProduce(state, (draft) => {
				draft.geofencesList.fenceAssets.visible = true;
			});
		case "CLOSE_FENCE_ASSETS_DRAWER":
			return immerProduce(state, (draft) => {
				draft.geofencesList.fenceAssets.visible = false;
			});
		case "SET_FENCE_MASTERTAGS":
			return immerProduce(state, (draft) => {
				draft.geofencesList.fenceAssets.mastertags = action.payload.mastertags;

				let mastertags:String[] = action.payload.mastertags;
				let assets:any[] = draft.geofencesList.geoFenceMaint.assetsList;
				let gatewaysData: any = [];

				mastertags?.forEach((item, key) => {
					let index = assets.findIndex((asset:any) => asset.mastertag === item);
					if (index !== -1) {
						gatewaysData.push({ mastertag : item, gatewayName : assets[index].nickname, latest_position_status : action.payload.latestPositionStatuses[key]});
					}
				})

				draft.geofencesList.fenceAssets.gatewaysData = gatewaysData;
				draft.geofencesList.fenceAssets.fenceName = action.payload.fenceName;
			});
		case "REMOVE_SHAPE_FROM_MAP":
			return immerProduce(state, (draft) => {
				let arr: any[] = draft.geofencesList.fenceList;
				arr.forEach((item) => {
					if (item.id === action.payload.id) {
						if (item.type === "circle") {
							(item.shape as google.maps.Circle).setMap(null);
						} else if (item.type === "rectangle") {
							(item.shape as google.maps.Rectangle).setMap(null);
						} else if (item.type === "polygon") {
							(item.shape as google.maps.Polygon).setMap(null);
						}
					}
				});
			});
		case "UPDATE_SHAPE":
			return immerProduce(state, (draft) => {
				let arr: any[] = draft.geofencesList.fenceList;
				arr.forEach((item) => {
					if (item.id === action.payload.id) {
						item.fenceName = action.payload.record.fenceName;
						item.fenceColor = action.payload.record.fenceColor;
						item.description = action.payload.record.description;
					}
				});
			});
		case "REMOVE_SHAPE":
            return immerProduce(state, (draft) => {

				let arr: any[] = draft.geofencesList.fenceList;
				arr.forEach((item) => {
					if (item.shape === action.payload.geofenceShape.shape) {
						action.payload.geofenceShape.shape.setMap(null);
					}
				});

				draft.geofencesList.fenceList = draft.geofencesList.fenceList.filter(
					(item: any) => item.shape !== action.payload.geofenceShape.shape
				);
			});
		case "SET_CUR_SHAPE":
			return immerProduce(state, (draft) => {
				draft.geofencesList.geoFenceMaint.curShape = action.payload.geofenceShape;
			});
		case "ADD_SHAPE_SUBMIT":
			return immerProduce(state, (draft) => {
				let arr: any[] = draft.geofencesList.fenceList;
				arr.forEach((item) => {
					if (item.shape === action.payload.geofenceShape) {
						item.id = action.payload.id;
						item.fenceName = action.payload.record.fenceName;
						item.fenceColor = action.payload.record.fenceColor;
						item.description = action.payload.record.description;
					}
				});

				/* fix clicking on newly added shap */
			});
		case "FILTER_FENCELIST_DRAWER":
			return immerProduce(state, (draft) => {
				let searchValue: String = action.payload.searchVal;
				if (searchValue.length > 0) {
					draft.geofencesList.fenceTableList = draft.geofencesList.fenceList.filter(
						(item: any) =>
							item.fenceName.toLowerCase().includes(searchValue.toLowerCase()) && item.fenceName !== ""
					);
				} else {
					draft.geofencesList.fenceTableList = draft.geofencesList.fenceList.filter(
						(item: any) => item.fenceName !== ""
					);
				}
			});
		case "REMOVE_SHAPES_FROM_MAP":
			return immerProduce(state, (draft) => {
				draft.geofencesList.fenceList.forEach((item:any) => {
					if(item.shape){
						if (item.type === "circle") {
							(item.shape as google.maps.Circle).setMap(null);
						} else if (item.type === "rectangle") {
							(item.shape as google.maps.Rectangle).setMap(null);
						} else if (item.type === "polygon") {
							(item.shape as google.maps.Polygon).setMap(null);
						}
					}
				})
			})
		case "ADD_SHAPES_TO_MAP":
			return immerProduce(state, (draft) => {
				let map:google.maps.Map = draft.geofencesList.map as unknown as google.maps.Map; 
				draft.geofencesList.fenceList.forEach((item:any) => {
					if(item.shape){
						if (item.type === "circle") {
							(item.shape as google.maps.Circle).setMap(map);
							//add tooltip here to testing
						} else if (item.type === "rectangle") {
							(item.shape as google.maps.Rectangle).setMap(map);
						} else if (item.type === "polygon") {
							(item.shape as google.maps.Polygon).setMap(map);
						}
					}
				})
			})
		case "UPDATE_SHAPE_ON_MAP":
			return immerProduce(state, (draft) => {
				action.payload.geofenceShape.shape.setOptions({
					fillColor: action.payload.newOptions.fenceColor
				});
			});
		case "CLEAR_GEOFENCES_STATE":
			return immerProduce(state, (draft) => {
				draft.geofencesList.fenceList = [];
				draft.geofencesList.map = null;
			});
			
			

		default:
			return state;
	}
}
