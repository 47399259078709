import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "antd";
import * as actions from "./actions";
import "./alerts.css";
import moment from "moment";
import tzlookup from "tz-lookup";
interface Prop {
    context: any,
    alertsState: any,
    permissions: any,
    recommendation: any,
    assetOrgId:any   
}
interface State {

}

class CumminsResponseView extends Component<Prop, State> {   



    render() {
        let message:any,staticParams:any;
        var relatedFaultCode:any;
        // console.log("Cmns recm from API ", this.props.recommendation);
        if(this.props.recommendation!="" && this.props.recommendation !== null){
            message=this.props.recommendation;            
            staticParams = JSON.parse(message.staticParams);
            relatedFaultCode=JSON.parse(JSON.stringify(JSON.parse(message?.responseParams)?.Related));
        }
        
        function displayRelatedFaultCodeRows(){
            var rfc= relatedFaultCode.map((related:any)=>
            {
                return(
                    <div className="divTableBody" >
                        <div className="divTableRow">
                        <div className="divTableCell">{JSON.parse(JSON.stringify(related)).Rel_Fault_Code}</div>
                        <div className="divTableCell">{JSON.parse(JSON.stringify(related)).Rel_SPN}</div>
                        <div className="divTableCell">{JSON.parse(JSON.stringify(related)).Rel_FMI}</div>
                        <div className="divTableCell">{findAssetTimeZone(staticParams,related.Rel_Occurrence_Date_Time)}</div>
                        <div className="divTableCell">{JSON.parse(JSON.stringify(related)).Rel_Fault_Code_Description}</div>
                        </div>
                    </div>
                )
            }
            )
            return rfc;
        }

        function findAssetTimeZone(coordinates: any, detected_at: any) {
            //detected_at(occurence time) is in Utc in database-but code is take it as IST and converting to asset timezone,
            // So displaying it in utc format, from utc we are converting to asset time zone.
            let assettime=moment(detected_at).toISOString(true).slice(0,23).concat('Z')
            var locale_date_str = new Date(assettime).toLocaleString("en-US",
                {
                    timeZone: tzlookup(coordinates.Latitude, coordinates.Longitude)
                });
            var geo_local_date_str = new Date(locale_date_str);
            return moment(geo_local_date_str).format('DD-MMM-YYYY, hh:mm:ss A');
        }
        return (
            // <div> {JSON.stringify(this.props.alertsState.dm1CumminsRecommendations)} </div>
            <div>
            {
                this.props.recommendation != "" && this.props.recommendation !== null &&
            <div>
                <div><b>Vehicle Information:</b></div>
                <div style={{width:400}} className="divTable">
                    <div className="divTableBody">
                        <div className="divTableRow">
                            <div className="divTableCell">Customer Equipment ID</div>
                            <div className="divTableCell">{message.mac !==""?message.mac:message.nickname}</div>
                        </div>
                        <div className="divTableRow">
                            <div className="divTableCell">Engine Serial Number</div>
                            <div className="divTableCell">{JSON.parse(message.staticParams).Engine_Serial_Number}</div>
                        </div>
                        {
                            JSON.parse(message.staticParams).VIN &&
                            <div className="divTableRow">
                            <div className="divTableCell">VIN/OEM Equipment ID</div>
                            <div className="divTableCell">{JSON.parse(message.staticParams).VIN}</div>
                            </div>
                        }
                        
                        <div className="divTableRow">
                            <div className="divTableCell">Engine Model</div>
                            <div className="divTableCell">{JSON.parse(message.responseParams).Service_Model_Name}</div>
                        </div>
                        <div className="divTableRow">
                            <div className="divTableCell">Mastertag</div>
                            <div className="divTableCell">{message.mastertag}</div>
                        </div>
                    </div>
                </div>
                <br/>

                 <div><b>Recommended Action:</b></div>
                <div>
                        {JSON.parse(message.responseParams).Instruction_To_Fleet_Mgr}
                </div>
                <br/>
                <div><b>Fault Code (FC) Information:</b></div>
                <div>{//JSON.stringify(message.responseParams)
                }
                </div>
               {
               JSON.parse(JSON.stringify(JSON.parse(message.responseParams).Related)) &&
               JSON.parse(JSON.stringify(JSON.parse(message.responseParams).Related)).length > 0  &&
                    
                        <div>
                            <div className="divTable">
                            <div className="divTableHeading">
                                    <div className="divTableHead">Related Fault Code</div>
                                    <div className="divTableHead">SPN</div>
                                    <div className="divTableHead">FMI</div>
                                    <div className="divTableHead">Timestamp ({moment.tz(tzlookup(staticParams.Latitude, staticParams.Longitude)).format('z')})</div>
                                    <div className="divTableHead">Description</div>
                                </div>
                                {displayRelatedFaultCodeRows()}
                                </div>
                                </div>
                     }
                    {
                    JSON.parse(JSON.stringify(JSON.parse(message.responseParams).Related)) &&
                    JSON.parse(JSON.stringify(JSON.parse(message.responseParams).Related)).length > 0 &&
                    <div className="divTableRecomendation">
                        <div className="divTableBody">
                        <div className="divTableRow">
                    <div className="divTableCellRecomendation">
                        Fault code  {JSON.parse(JSON.stringify(JSON.parse(message.responseParams).Related)).map((related:any)=>
                        { return JSON.parse(JSON.stringify(related)).Rel_Fault_Code }).join( ",")} is likely related to active primary fault code {JSON.parse(message.responseParams).Primary_Fault_Code}. Recommended action, suggested root cause,  and derate information are based on the primary fault code.
                    </div>
                    </div>
                    </div>
                    </div>
                }
               
                <br/>
                {
                    JSON.parse(message.responseParams).Primary_Fault_Code ===""?<div>
                    <div className="divTable">
                        <div className="divTableHeading">
                            <div className="divTableHead">Fault Code</div>
                            <div className="divTableHead">SPN</div>
                            <div className="divTableHead">FMI</div>
                            <div className="divTableHead">Timestamp ({moment.tz(tzlookup(staticParams.Latitude, staticParams.Longitude)).format('z')})</div>
                            <div className="divTableHead">Description</div>
                        </div>
                        <div className="divTableBody">
                            <div className="divTableRow">
                                <div className="divTableCell">{JSON.parse(message.responseParams).Fault_Code}</div>
                                <div className="divTableCell">{message.spn}</div>
                                <div className="divTableCell">{message.fmi}</div>
                                <div className="divTableCell">{findAssetTimeZone(staticParams, message.occurrence_date_time)}</div> 
                                <div className="divTableCell">{JSON.parse(message.responseParams).Fault_Code_Description}</div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="divTableRecomendation">
                        <div className="divTableBody">
                        <div className="divTableRow">
                            <div className="divTableCellRecomendation">
                            Recommended action and derate information are based on the <i>overall equipment status</i>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>:<div>
                    <div className="divTable">

                        <div className="divTableHeading">
                            <div className="divTableHead">Primary Fault Code</div>
                            <div className="divTableHead">SPN</div>
                            <div className="divTableHead">FMI</div>
                            <div className="divTableHead">Timestamp ({moment.tz(tzlookup(staticParams.Latitude, staticParams.Longitude)).format('z')})</div>
                            <div className="divTableHead">Description</div>
                        </div>

                        <div className="divTableBody">
                            <div className="divTableRow">
                                <div className="divTableCell">{JSON.parse(message.responseParams).Primary_Fault_Code}</div>
                                <div className="divTableCell">{JSON.parse(message.responseParams).Primary_SPN}</div>
                                <div className="divTableCell">{JSON.parse(message.responseParams).Primary_FMI}</div>
                                 <div className="divTableCell">{findAssetTimeZone(staticParams, JSON.parse(message.responseParams).Primary_Occurrence_Date_Time)}</div> 
                                <div className="divTableCell">{JSON.parse(message.responseParams).Primary_Fault_Code_Description}</div>
                            </div>
                        </div>
                        </div>
                        <div className="divTableRecomendation">
                        <div className="divTableBody">
                        <div className="divTableRow">
                            <div className="divTableCellRecomendation">
                            Recommended action and derate information are based on the <i>overall equipment status</i>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                }
                <br/>
                {
                    JSON.parse(message.responseParams).Fault_Root_Cause1 && 
                    <div><b>Suggested Root Causes:</b></div> 
                }
                <div className="divTable">
                    <div className="divTableHeading">
                        {
                            JSON.parse(message.responseParams).Fault_Likelihood1 &&
                            <div className="divTableHead"><u>Component or System</u></div>
                        }
                        {
                            JSON.parse(message.responseParams).Fault_Likelihood1 &&
                            <div className="divTableHead"><u>Probability</u></div>
                        }
                    </div>
                    <div className="divTableBody">
                        {
                        JSON.parse(message.responseParams).Fault_Root_Cause1 &&
                        JSON.parse(message.responseParams).Fault_Root_Cause1!=""&&
                        <div className="divTableRow">
                            <div className="divTableCell">{JSON.parse(message.responseParams).Fault_Root_Cause1}</div>
                            {JSON.parse(message.responseParams).Fault_Likelihood1 &&
                            <div className="divTableCell">{JSON.parse(message.responseParams).Fault_Likelihood1}</div>}
                        </div>
                        }
                        {
                        JSON.parse(message.responseParams).Fault_Root_Cause2 &&
                        JSON.parse(message.responseParams).Fault_Root_Cause2!=""&&
                        <div className="divTableRow">
                            <div className="divTableCell">{JSON.parse(message.responseParams).Fault_Root_Cause2}</div>
                            {JSON.parse(message.responseParams).Fault_Likelihood2 &&
                            <div className="divTableCell">{JSON.parse(message.responseParams).Fault_Likelihood2}</div>}
                        </div>
                        }
                        {
                        JSON.parse(message.responseParams).Fault_Root_Cause3 &&
                        JSON.parse(message.responseParams).Fault_Root_Cause3!=""&&
                        <div className="divTableRow">
                            <div className="divTableCell">{JSON.parse(message.responseParams).Fault_Root_Cause3}</div>
                            {JSON.parse(message.responseParams).Fault_Likelihood3 &&
                            <div className="divTableCell">{JSON.parse(message.responseParams).Fault_Likelihood3}</div>}
                        </div>
                        }
                        {
                        JSON.parse(message.responseParams).Fault_Root_Cause4 &&
                        JSON.parse(message.responseParams).Fault_Root_Cause4!=""&&
                        <div className="divTableRow">
                            <div className="divTableCell">{JSON.parse(message.responseParams).Fault_Root_Cause4}</div>
                            {JSON.parse(message.responseParams).Fault_Likelihood4 &&
                            <div className="divTableCell">{JSON.parse(message.responseParams).Fault_Likelihood4}</div>}
                        </div>
                        }
                    </div>
                </div>
                <br/>
                {
                 JSON.parse(message.responseParams).Derate_Flag &&
                 JSON.parse(message.responseParams).Derate_Flag !="" &&
                 JSON.parse(message.responseParams).Derate_Flag ==="Yes" &&
                <div>
                    Fault code indicates that your equipment has entered a situation where performance will be limited, and you may experience the following:
                    <ul>
                        {
                        JSON.parse(message.responseParams).Derate_Value1 &&
                        JSON.parse(message.responseParams).Derate_Value1 != "" &&
                            <li>{JSON.parse(message.responseParams).Derate_Value1}</li>
                        }
                        {
                        JSON.parse(message.responseParams).Derate_Value2 &&
                        JSON.parse(message.responseParams).Derate_Value2 != "" &&
                            <li>{JSON.parse(message.responseParams).Derate_Value2}</li>
                        }
                        {
                        JSON.parse(message.responseParams).Derate_Value3 &&
                        JSON.parse(message.responseParams).Derate_Value3 != "" &&
                            <li>{JSON.parse(message.responseParams).Derate_Value3}</li>
                        }
                    </ul>
                </div>
                
                }
                {
                    JSON.parse(message.responseParams).Shutdown_Flag &&
                    JSON.parse(message.responseParams).Shutdown_Flag !="" &&
                    JSON.parse(message.responseParams).Shutdown_Flag ==="Yes" &&
                    <div>
                        {JSON.parse(message.responseParams).Shutdown_Description}
                    </div>
                }
                <br/>
                
                <div dangerouslySetInnerHTML={{__html:message.cmns_recomendation_footer_html}} ></div> 
                {
                /*
                message.cmns_recomendation_footer_html
                this.props.assetOrgId != "4d5b9621-2b2b-11ee-8a6d-739e920ccfbe" &&
                                <div>

                <p>If you need further assistance contact your local Taylor service representative or dealer.</p>
                <p>If you do not know who to contact, then please call our main service line and we will assist you in reaching the correct person.</p>
                <p>Taylor Sudden Service</p>
                <p>1-800-222-6218</p>
                <p>You may also find the nearest Taylor Service Center using the locator map linked below.</p>
                <p><a target="_blank" rel="noreferrer" href="https://www.taylorsuddenservice.com/locations.html">https://www.taylorsuddenservice.com/locations.html</a></p>
                </div>*/
                }
                {/* Footer for Toyota */}
                {/*this.props.assetOrgId == "4d5b9621-2b2b-11ee-8a6d-739e920ccfbe" && 
                <div>

                <p>If you need further assistance contact your local Toyota Material Handling Heavy Duty service representative or dealer.</p>
                <p>If you do not know who to contact, then please call our main service line and we will assist you in reaching the correct person.</p>
                <p>Toyota Material Handling Heavy Duty</p>
                <p>1-800-381-5879</p>
                <p>You may also find the nearest Toyota Material Handling Heavy Duty Service Center using the locator map linked below.</p>
                <p><a target="_blank" rel="noreferrer" href="https://www.toyotaforklift.com/parts-and-service">https://www.toyotaforklift.com/parts-and-service</a></p>
                </div>*/
                }

            </div> 

            }
            {
                (this.props.recommendation === "" || this.props.recommendation === null) &&
                <div>
                <p style={{ color: 'Red' }}>Failed to fetch Cummins recommendation.</p>
                </div>
            }
            </div>
    
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        context: state.contextReducer.context,
        permissions: state.contextReducer.data,
        alertsState: state.alertsReducer.alertsState
    };
};

export default connect(
    mapStateToProps,
    actions
)(CumminsResponseView); 