import { appConfigs } from "../../../utils/configurations";

export const initialState = {
    usersList: {
        userMaint: {
            instance: 0,
            width: 720,
            visible: false,
            record: {},           
            title: "",
            pageName: "",
            activeTab: "1",
            administeredOrgs: [],
            tabs: [
                {
                    title: "User Info",
                    instance: 0,
                    disabled: false,
                    countries: [],
                    userCopy: {
                        data: []
                    }
                },
                {
                    title: "Organizations",
                    instance: 0,
                    disabled: true,
                    tableData: [],
                    tableConfig : { 
                        pagination : {                  
                            showSizeChanger: appConfigs.tables.showSizeChanger,
                            showQuickJumper: appConfigs.tables.showQuickJumper,
                            pageSize: appConfigs.tables.pageSize,
                            defaultCurrent: appConfigs.tables.defaultCurrent,
                            current: appConfigs.tables.defaultCurrent,
                            total: appConfigs.tables.defaultTotal,
                            hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                            responsive: appConfigs.tables.responsive,
                            onChange: null,
                            showLessItems: appConfigs.tables.showLessItems,  
                            showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} org${total > 1 ? 's' : ''}`
                        }
                    }
                },
                {
                    title: "Subscriptions",
                    instance: 0,
                    disabled: true,
                    searchText: "",
                    tableData: [],
                    tableConfig : { 
                        pagination : {                  
                            showSizeChanger: appConfigs.tables.showSizeChanger,
                            showQuickJumper: appConfigs.tables.showQuickJumper,
                            pageSize: appConfigs.tables.pageSize,
                            defaultCurrent: appConfigs.tables.defaultCurrent,
                            current: appConfigs.tables.defaultCurrent,
                            total: appConfigs.tables.defaultTotal,
                            hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                            responsive: appConfigs.tables.responsive,
                            onChange: null,
                            showLessItems: appConfigs.tables.showLessItems,  
                            showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} asset${total > 1 ? 's' : ''}`
                        }
                    }
                },
                {
                    title: "Activity Subscription",
                    instance: 0,
                    disabled: true,
                    searchText: "",
                    tableData: [],
                    tableConfig : { 
                        pagination : {                  
                            showSizeChanger: appConfigs.tables.showSizeChanger,
                            showQuickJumper: appConfigs.tables.showQuickJumper,
                            pageSize: appConfigs.tables.pageSize,
                            defaultCurrent: appConfigs.tables.defaultCurrent,
                            current: appConfigs.tables.defaultCurrent,
                            total: appConfigs.tables.defaultTotal,
                            hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                            responsive: appConfigs.tables.responsive,
                            onChange: null,
                            showLessItems: appConfigs.tables.showLessItems,  
                            showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} asset${total > 1 ? 's' : ''}`
                        }
                    }
                }
            ]
        },
        searchText: "",
        refreshOnUpdate: false,
        tableData: [],
        tableConfig : { 
            pagination : {                  
                showSizeChanger: appConfigs.tables.showSizeChanger,
                showQuickJumper: appConfigs.tables.showQuickJumper,
                pageSize: appConfigs.tables.pageSize,
                defaultCurrent: appConfigs.tables.defaultCurrent,
                current: appConfigs.tables.defaultCurrent,
                total: appConfigs.tables.defaultTotal,
                hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                responsive: appConfigs.tables.responsive,
                onChange: null,
                showLessItems: appConfigs.tables.showLessItems,  
                showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} record${total > 1 ? 's' : ''}`
            }
        }
    },
    roleList: {
        roleMaint: {
            instance: 0,
            title: "Role and Permission",
            width: 520,
            visible: false,
            selectonHeader: true,
            record: {},
            currentView: "list-view",
            activeTab: "1",
            orgRoles:{
                data:{
                  roles:[]  
                }
            },
            tabs: [
                {
                    title: "Role and Permission Info",
                    instance: 0,
                    disabled: false,
                    roleCopy: {
                        data: []
                    }
                }
            ]
        },
       
        refreshOnUpdate: false,
        tableData: [],
        tableConfig : { 
            pagination : {                  
                showSizeChanger: appConfigs.tables.showSizeChanger,
                showQuickJumper: appConfigs.tables.showQuickJumper,
                pageSize: 30,
                defaultCurrent: appConfigs.tables.defaultCurrent,
                current: appConfigs.tables.defaultCurrent,
                total: appConfigs.tables.defaultTotal,
                hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                responsive: appConfigs.tables.responsive,
                onChange: null,
                showLessItems: appConfigs.tables.showLessItems,  
                showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} record${total > 1 ? 's' : ''}`
            }
        }
    },    
    data:{
        initialized: false, 
        "status": "",
        "timestamp": "",    
        permissions:[]
    }
};
  