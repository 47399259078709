import { Button, Card, Col, Divider, Drawer, Row, Space, Tabs } from "antd";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { appConfigs } from "../../../utils/configurations";
import * as actions from "./actions";

const { TabPane } = Tabs;

interface Prop {
    context: any,
    contractMaintOptions: any,
    closeDrawer: Function,
    drawerTabChange: Function,
}

const ContractMaint: React.FC<Prop> = (props) => {
   
    const onDrawerClose = () => {
        props.closeDrawer();
    };

    const onTabChange = (key: string) => {
        props.drawerTabChange(key);
    }
        
    const contractMaintOptions = props.contractMaintOptions;
    let changesHistoryDetails = null

    if(contractMaintOptions.activeTab === "2"){
        changesHistoryDetails = JSON.parse(contractMaintOptions?.record?.changesHistory)
    }

    const checkEquality = (oldValue: any, newValue: any) => {
        if (oldValue == newValue)
            return true
        return false
    }
    
    return (
        <Drawer
            title={contractMaintOptions.title}
            width={contractMaintOptions.width}
            onClose={onDrawerClose}
            visible={contractMaintOptions.visible}
            destroyOnClose={true}
            footer={
                <Space size="large" style={{ float: 'right' }}>
                    <Button type="default" onClick={onDrawerClose}>
                        {appConfigs.settings.drawers.closeButtonText}
                    </Button>

                    {/* {
                        contractMaintOptions.activeTab === "1" &&
                        <Button data-id="assets-maint-submit-button" type="primary" form="contractMaintOptionsInfoForm" key="submit" htmlType="submit">
                            {appConfigs.settings.form.submitButtonText}
                        </Button>
                    } */}
                    
                </Space>
            }
        >
            {
                <Tabs activeKey={contractMaintOptions.activeTab} size="large" type="card" onChange={onTabChange}>                    
                    <TabPane tab={contractMaintOptions.tabs[0].title} key="1" disabled={contractMaintOptions.tabs[0].disabled}>
                        <Card title="Current Contract Details:" style={{ borderColor: '#dbdbdb', width: '100%' }}>
                            <table style={{ fontSize: "15px", width: '100%'}}>
                            <thead></thead>
                            <tbody>
                                <tr style={{borderBottom: '1px #dbdbdb solid'}}>
                                    <th>Master Tag:</th>
                                    <td>{ contractMaintOptions.record.masterTag }</td>
                                </tr>
                                <tr style={{borderBottom: '1px #dbdbdb solid'}}>
                                    <th>Org Name:</th>
                                    <td>{ contractMaintOptions.record.orgName }</td>
                                </tr>
                                <tr style={{borderBottom: '1px #dbdbdb solid'}}>
                                    <th>Part Number:</th>
                                    <td>{ contractMaintOptions.record.partNumber }</td>
                                </tr>
                                <tr style={{borderBottom: '1px #dbdbdb solid'}}>
                                    <th>Status:</th>
                                    <td>{ contractMaintOptions.record.status }</td>
                                </tr>
                                <tr style={{borderBottom: '1px #dbdbdb solid'}}>
                                    <th>Contract Period</th>
                                    <td>{ contractMaintOptions.record.startDate } - { contractMaintOptions.record.endDate }</td>
                                </tr>
                                </tbody>
                            </table>
                        </Card>
                    </TabPane> 
                        
        
                    <TabPane tab={contractMaintOptions.tabs[1].title} key="2" disabled={contractMaintOptions.tabs[1].disabled}>
                        { changesHistoryDetails !== null && changesHistoryDetails.map((record:any, index:number) => (
                            <Row justify="space-between" style={{ border: '1px #dbdbdb solid', paddingLeft: '20px', paddingRight: '20px', marginBottom: '10px' }}>
                                <Col span={24}>
                                    <h3 style={{color: '#37806d', paddingTop: '10px',}}>{'A change logged on ' + moment(record.created_at).format('ddd, MMM Do YYYY, h:mm:ss A') + (index === 0 ? ' - (Most Recent)' : '') }</h3>
                                </Col>
                                <Col span={11}>
                                    <Card size="small" title="Old Values" style={{ border: '1px #dbdbdb dashed', width: '100%' }}>
                                        <table style={{ fontSize: "15px", width: '100%'}}>
                                            <tr 
                                                style={{borderBottom: '1px #dbdbdb solid'}}
                                                className={checkEquality(record.old_record_value.contract_start_date, record.new_record_value.contract_start_date) ? '' : 'entryChanged'}>
                                                <th>Contract Start Date</th>
                                                <td>{ record.old_record_value.contract_start_date }</td>
                                            </tr>
                                            <tr 
                                                style={{borderBottom: '1px #dbdbdb solid'}}
                                                className={checkEquality(record.old_record_value.contract_end_date, record.new_record_value.contract_end_date) ? '' : 'entryChanged'}>
                                                <th>Contract End Date</th>
                                                <td>{ record.old_record_value.contract_end_date }</td>
                                            </tr>
                                            <tr 
                                                style={{borderBottom: '1px #dbdbdb solid'}}
                                                className={checkEquality(record.old_record_value.additionalDetails?.gatewayStatus,record.new_record_value.additionalDetails?.gatewayStatus) ? '' : 'entryChanged'}>
                                                <th>Gateway Status:</th>
                                                <td>{ record.old_record_value.additionalDetails?.gatewayStatus }</td>
                                            </tr>
                                            <tr 
                                                style={{borderBottom: '1px #dbdbdb solid'}}
                                                className={checkEquality(record.old_record_value.additionalDetails?.SIMStatus,record.new_record_value.additionalDetails?.SIMStatus) ? '' : 'entryChanged'}>
                                                <th>SIM Status:</th>
                                                <td>{ record.old_record_value.additionalDetails?.SIMStatus }</td>
                                            </tr>
                                            <tr 
                                                style={{borderBottom: '1px #dbdbdb solid'}}
                                                className={checkEquality(record.old_record_value.additionalDetails?.IoTHubStatus,record.new_record_value.additionalDetails?.IoTHubStatus) ? '' : 'entryChanged'}>
                                                <th>IoTHub Status:</th>
                                                <td>{ record.old_record_value.additionalDetails?.IoTHubStatus }</td>
                                            </tr>
                                        </table>
                                    </Card>
                                </Col>
                                <Col span={11}>
                                    <Card size="small" title="New Values" style={{ border: '1px #dbdbdb dashed', width: '100%' }}>
                                        <table style={{ fontSize: "15px", width: '100%'}}>
                                            <tr 
                                                style={{borderBottom: '1px #dbdbdb solid'}}
                                                className={checkEquality(record.old_record_value.contract_start_date, record.new_record_value.contract_start_date ) ? '' : 'entryChanged'}>
                                                <th>Contract Start Date</th>
                                                <td>{ record.new_record_value.contract_start_date }</td>
                                            </tr>
                                            <tr 
                                                style={{borderBottom: '1px #dbdbdb solid'}}
                                                className={checkEquality(record.old_record_value.contract_end_date, record.new_record_value.contract_end_date ) ? '' : 'entryChanged'}>
                                                <th>Contract End Date</th>
                                                <td>{ record.new_record_value.contract_end_date }</td>
                                            </tr>
                                            <tr 
                                                style={{borderBottom: '1px #dbdbdb solid'}}
                                                className={checkEquality(record.old_record_value.additionalDetails?.gatewayStatus, record.new_record_value.additionalDetails?.gatewayStatus) ? '' : 'entryChanged'}>
                                                <th>Gateway Status:</th>
                                                <td>{ record.new_record_value.additionalDetails?.gatewayStatus }</td>
                                            </tr>
                                            <tr 
                                                style={{borderBottom: '1px #dbdbdb solid'}}
                                                className={checkEquality(record.old_record_value.additionalDetails?.SIMStatus, record.new_record_value.additionalDetails?.SIMStatus) ? '' : 'entryChanged'}>
                                                <th>SIM Status:</th>
                                                <td>{ record.new_record_value.additionalDetails?.SIMStatus }</td>
                                            </tr>
                                            <tr 
                                                style={{borderBottom: '1px #dbdbdb solid'}}
                                                className={checkEquality(record.old_record_value.additionalDetails?.IoTHubStatus, record.new_record_value.additionalDetails?.IoTHubStatus) ? '' : 'entryChanged'}>
                                                <th>IoTHub Status:</th>
                                                <td>{ record.new_record_value.additionalDetails?.IoTHubStatus }</td>
                                            </tr>
                                        </table>
                                    </Card>
                                </Col>
                                <p style={{ marginTop: '15px' }}>
                                    <h4 style={{ color: '#37806d' }}>{'Changed By: ' + record.created_by_name}</h4>
                                    <h4 style={{ color: '#37806d', marginTop: '-10px' }}>{'User Notes: ' + record.notes}</h4>
                                </p>
                                
                            </Row>
                            
                        ))}

                        { changesHistoryDetails === null &&
                            <h3 style={{ color: 'red', textAlign: 'center' }}>No Audit Log Available</h3>
                        }
                    </TabPane>  
                </Tabs>   
            }         
        </Drawer>
    );
}

const mapStateToProps = (state: any) => {
    return {
        context: state.contextReducer.context,
        contractMaintOptions: state.contractsReducers.contractsState.contracts.contractMaintOptions
    };
};

export default connect(
    mapStateToProps,
    actions
)(ContractMaint);