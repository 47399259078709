import { WidgetDetails } from "../models";
import { CommonWidgetConfig } from "../../models";
import Widget, { SignalOpts } from "./Widget";
import EditForm from "./EditForm";

export const details: WidgetDetails = {
  type: "tracker",
  name: "Tracker Widget",
};
export type TrackerOptions = SignalOpts;

export type Config = CommonWidgetConfig & {
  type: "tracker";
  settings?: SignalOpts[];
};

export { EditForm, Widget };
