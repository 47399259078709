import React from "react";
import { List, Tooltip } from "antd";
import Dot from "../../../../sharedComponents/Dot";
import { formatDate, inputSignalMapper, signalLabel } from "../common";
import { CommonWidgetProps, CommonWidgetConfig } from "../../models";
import { WidgetDetails } from "../models";

interface Signal {
  name: string;
  values: { timestamp: Date; value: boolean }[];
}

export const details: WidgetDetails = {
  type: "boolean",
  name: "Boolean Widget"
}

export type Config = CommonWidgetConfig & { type: "boolean" };

const createSignals = inputSignalMapper<Signal>((meta, data) => ({
  name: signalLabel(meta, false),
  values: [
    {
      timestamp: data[0].timestamp,
      value: !!data[0].value,
    },
  ],
}));

const BooleanWidget: React.FC<CommonWidgetProps> = (props) => {
  const { template, data } = props;
  const signals = createSignals(props.signals, template, data);

  return (
    <List
      dataSource={signals}
      renderItem={(signal) => (
        <List.Item>
          <List.Item.Meta title={signal.name} />
          <Tooltip
            title={formatDate(signal.values[0].timestamp)}
            placement="topRight"
            arrowPointAtCenter={true}
          >
            <div>
              <Dot
                color={signal.values[0].value ? "green" : "red"}
                size="20px"
              />
            </div>
          </Tooltip>
        </List.Item>
      )}
    />
  );
};

export default BooleanWidget;
