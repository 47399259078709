import Axios from "axios";
import { errorResponse } from "../../../../utils/apiHelpers/apiHelpers";
import { appConfigs } from "../../../../utils/configurations";

export function getApplications(
    me: any, 
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
    page: number = 1,
    searchText?: string,
    filters?: any[any],
    applicationId?:string
) {
    
    return async (dispatch: any) => {

        let filtersObj:any = [];
        filters && filters['status'] != null ? filtersObj = {'statusFilter': filters['status']} : filtersObj = {}

        Axios.get(
            appConfigs.server.URL + "/ui/api/applications", {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText,
                    page: page,
                    pageSize: appConfigs.tables.pageSize,
                    applicationId: (applicationId||""),
                    filters: (filters['status'] ? "" : encodeURIComponent(JSON.stringify(filtersObj)) )
                }
            }
        )
        .then(response => { 
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.applications);
                }
                dispatch({ type: "APPLICATIONS_GET_SUCCESS", payload: {data: response.data.data.applications, page: page}  });
            }
        )
        .catch(function (error) {
            console.log('error', error)
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function applicationsSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "APPLICATION_SET_SEARCH", searchText: searchText });
    }
}  

export function applicationSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "APPLICATIONS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
} 

export function setTab(
    tabNumber: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "SET_TAB", tabNumber: tabNumber });
    }
}

export function openDrawer(title: string, width: number, record: any) {
	return (dispatch: any) => {
		dispatch({ type: "OPEN_DRAWER", payload: { title: title, width: width, record: record } });
	};
}

export function closeDrawer() {
	return (dispatch: any) => {
		dispatch({ type: "CLOSE_DRAWER" });
	};
}

export function drawerTabChange(activeTab: string) {
	return (dispatch: any) => {
		dispatch({ type: "DRAWER_TAB_CHANGE", activeTab: activeTab });
	};
}



export function getAllApplicationsList() {
	return (dispatch: any) => {
		Axios.get(appConfigs.server.URL + "/ui/api/applications", {
			responseType: "json",
			headers: {},
			params: {
				page: 1,
				pageSize: 9999,
			},
		}).then((response) => {
            let applications = response.data.data.applications;
			dispatch({ type: "SET_APPLICATIONS_LIST", payload: { applicationsList: applications } });
		}).catch(function (error) {
            dispatch({ type: "SET_LOADING_APPLICATIONS", payload: { state: false } });
            errorResponse(null, error);
        });
	};
}



export function getAllControllersList(
    me: any, 
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
    page: number = 1,
    pageSize?: number
) {
    
    return async (dispatch: any) => {

        Axios.get(
            appConfigs.server.URL + "/ui/api/controllers", {
                responseType: "json",        
                headers: {},
                params: {
                    page: page,
                    pageSize: pageSize,
                }
            }
        )
        .then(response => { 
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.controllers);
                }
                dispatch({ type: "GET_CONTROLLERS_LIST", controllersList: response.data.data.controllers  });
            }
        )
        .catch(function (error) {
            console.log('error', error)
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}


export function ApplicationMaint(
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
    applicationInfo?: any,
) {
    
    return async (dispatch: any) => {

        console.log("applicationInfo")
        console.log(applicationInfo)
        var formData = new FormData();

        formData.append("controllerId", applicationInfo.compatibleControllers);
        formData.append("name", applicationInfo.applicationName);
        formData.append("type", applicationInfo.type);
        formData.append("version", applicationInfo.version);
        formData.append("description", applicationInfo.description);
        formData.append("requiresApproval", applicationInfo.requiredApproval);
        formData.append("mode", "add");
        formData.append("file", applicationInfo.fileUpload[0].originFileObj);

        Axios({
            method: "POST",
            url: appConfigs.server.URL + "/ui/api/modify_application",
            data: formData,
            headers: { "Content-Type": 'multipart/form-data;' }
        })
        .then(response => { 
            
                console.log(response.data.status)
            
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.status);
                }

                dispatch({ type: "APPLICATION_MAINT_SUCCESS", payload: response.data.application  });
            }
        )
        .catch(function (error) {
            console.log('error', error)
            if (callbackFnFailure != null) {
                callbackFnFailure(error)
            }

            dispatch({ type: "APPLICATION_MAINT_FAILED", payload: 'Failed'  });
        });
    };
}



export function modifyController(
    selectedApplicationsToModify: any,
    callbackFnSuccess?: any
) {
    return (dispatch: any) => {
        dispatch({ type: "ADD_APPLICATION", payload: { state: true } });
		Axios.get(appConfigs.server.URL + "/ui/api/modify_applications", {
			responseType: "json",
			headers: {},
            params: {
                applications: encodeURIComponent(JSON.stringify(selectedApplicationsToModify))
			},
		}).then((response) => {
            if (callbackFnSuccess != null) {
                callbackFnSuccess(response.data.data);
            }
			dispatch({ type: "ADD_APPLICATION_SUCCESSFULLY" });
		}).catch(function (error) {
            dispatch({ type: "ADD_APPLICATION", payload: { state: false } });
            console.log('error', error)
            errorResponse(null, error);
        });
	};
}