import React from "react";
import { Form, Row, Col, Input, Select, InputNumber, Card, Tooltip, Button, Space, Collapse } from 'antd';
import { DeleteOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import * as actions from "../actions";

const { Option } = Select;

interface Prop {
    formRef: any;

    signalMaint: any
    iscreateRules: boolean,
    isupdateRules: boolean,
    isdeleteRules: boolean,
    isreadRules: boolean,
    isGlobalTemplate: boolean,
    isadmin: boolean,
    leftMergeRules:any,
    rightMergeRules:any,
    setLeftMergeRules:Function,
    setRightMergeRules:Function

}

interface State {
    ruleType: string,
    openMergePanel:any
}

const { Panel } = Collapse;

class SignalRule extends React.Component<Prop, State> {
        
    state: State = {
        ruleType: "",
        openMergePanel:"0"
    };

    componentDidMount() {
        this.componentUpdate();       
    }

    componentDidUpdate(prevProps: Prop) {
        if (this.props.signalMaint.instance !== prevProps.signalMaint.instance) {
            this.componentUpdate();
        }
    }

    componentUpdate() {
        const me = this;
        if (me.props.formRef == null) {
            setTimeout(function() {
                me.setComponentState(me);
            }, 250);    
        }
        else {
            me.setComponentState(me);
        }
    }

    setComponentState = (me: any) => { 
        if (me.props.formRef != null) {
            me.setState({
               ruleType: me.props.formRef.getFieldValue("ruleType"),
               incrementalRuleType:me.props.formRef.getFieldValue("incrementalRuleType"),
               openMergePanel:(me.props.formRef.getFieldValue("mergeRuleId")||"")!==""?"1":"0"          
            });           
        }
    }

    onRuleTypeChange = (value: string) => { 
        this.setState({ruleType: value });
    }

    deleteRule = (e: any) => { 
        e.preventDefault();
        e.stopPropagation();

        this.props.formRef.setFieldsValue({
           // ruleId: "",
            ruleName: "",
            ruleType: "alarm",
            criticalLowValue: "",
            warningLowValue: "",
            warningHighValue: "",
            criticalHighValue: "",
            infoLowValue: "",
            infoHighValue: ""
        });
        this.setComponentState(this);        
    }

    deleteIncrementalRule=(e:any)=>{
        e.preventDefault();
        e.stopPropagation();

        this.props.formRef.setFieldsValue({
            incrementalRuleName:"",
            incrementRuleType:"incremental",
            incrementalRuleValue:""
        });
        this.setComponentState(this);        
    }

    deleteOfflineRule=(e:any)=>{
        e.preventDefault();
        e.stopPropagation();

        this.props.formRef.setFieldsValue({
            offlineSince:"",
            offlineRuleName:"",
            offlineDuration:""
        });
        this.setComponentState(this);        
    }

    onChangeLeftMergeRule=(value:any)=>{
        this.setLeftMergeRules(value);
    } 

    onChangeRightMergeRule=(value:any)=>{
        this.setRightMergeRules(value);
    } 

    setLeftMergeRules=(value:any)=>{
        this.props.setLeftMergeRules(value);
    }

    setRightMergeRules=(value:any)=>{
        this.props.setRightMergeRules(value);
    }

    deleteMergeRule=(e:any)=>{
        e.preventDefault();
        e.stopPropagation();

        this.props.formRef.setFieldsValue({
            leftMergeRule:"",
            rightMergeRule:"",
            mergeRuleOperator:""
        });
        this.setComponentState(this);        
    }

    setOpenMergePanel=(e:any)=>{
        this.setState(prevState => ({
            ...prevState,
         openMergePanel:prevState.openMergePanel==="1"?"0":"1"
          }))
    }
   
    render() {
        return (
            <div>
            {
                <>
                            <Card 
                key="1"
                size="small" 
                title={"Rule (1)" }                 
                extra={
                    <>
                        {
                            this.props.formRef.getFieldValue("ruleId") &&
                            <Space size="middle" style={{ float: 'left' }}>
                                {(this.props.isGlobalTemplate && this.props.isadmin || !this.props.isGlobalTemplate && this.props.isdeleteRules) &&
                                <Tooltip title="Delete Rule">
                                    <Button type="default" icon={<DeleteOutlined />} onClick={(e) => {this.deleteRule(e)}}/>
                                </Tooltip>}
                            </Space>
                        }
                    </>
                }
            >
        
                <Form.Item 
                    name="ruleId"
                    style={{ display: 'none' }}
                >
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item 
                    name="mergeRules"
                    style={{ display: 'none' }}
                >
                    <Input type="hidden" />
                </Form.Item>
                <Row gutter={8}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Rule Name"
                            name="ruleName"    
                        >
                            <Input maxLength={255} 
                                disabled={this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateRules && !this.props.isupdateRules} />
                        </Form.Item>
                    </Col> 
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Rule Type"
                            name="ruleType" 
                        >
                            <Select 
                                onChange={this.onRuleTypeChange}
                                disabled={this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateRules && !this.props.isupdateRules}
                            >
                                <Option key="alarm" value="alarm">Alarm</Option>
                                <Option key="info" value="info">Info</Option>
                            </Select>     
                        </Form.Item>
                    </Col> 
                </Row>             
                {
                    this.state.ruleType === "alarm" && 
                    <>                 
                        <Row gutter={8}>
                            <Col xs={8} md={5}>
                                <Form.Item
                                    label="Critical Min."
                                    className ="alert-critical"
                                    name="criticalLowValue"  
                                >
                                    <InputNumber 
                                        disabled={this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateRules && !this.props.isupdateRules}
                                        min={-999000000} 
                                        max={999000000} 
                                        style={{ width: '100%' }} 
                                        //precision={12} 
                                        placeholder="Less than"
                                    />
                                </Form.Item>
                            </Col>                        
                            <Col xs={8} md={5}>
                                <Form.Item
                                    label="Warning Min."
                                    className ="alert-warning"
                                    name="warningLowValue"  
                                >
                                    <InputNumber 
                                        disabled={this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateRules && !this.props.isupdateRules}
                                        min={-999000000} 
                                        max={999000000} 
                                        style={{ width: '100%' }} 
                                        //precision={12} 
                                        placeholder="Less than"
                                    />
                                </Form.Item>
                            </Col> 
                        {/* </Row>   */}
                        <Col xs={8} md={4}>
                            <div className="center-div" style={{ padding: "10px 0 15px", color: "rgba(0, 0, 0, 0.85)" }}>Normal Threshold</div>
                        </Col>
                        {/* <Row gutter={8}> */}
                        <Col xs={8} md={5}>
                            <Form.Item
                                label="Warning Max."
                                name="warningHighValue"  
                                className ="alert-warning"
                            >
                                <InputNumber 
                                    disabled={this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateRules && !this.props.isupdateRules}
                                    min={-999000000} 
                                    max={999000000} 
                                    style={{ width: '100%' }} 
                                    //precision={12}  
                                    placeholder="Greater than"
                                />
                            </Form.Item>
                        </Col>                        
                        <Col xs={8} md={5}>
                            <Form.Item
                                label="Critical Max."
                                name="criticalHighValue"  
                                className ="alert-critical"
                            >
                                <InputNumber 
                                    disabled={this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateRules && !this.props.isupdateRules}
                                    min={-999000000} 
                                    max={999000000} 
                                    style={{ width: '100%' }} 
                                    //precision={12}   
                                    placeholder="Greater than"
                                />
                            </Form.Item>
                        </Col> 
                        </Row>   
                    </>
                }      
                {
                    this.state.ruleType === "info" &&
                    <Row gutter={8}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Info Minimum"
                                name="infoLowValue"  
                            >
                                <InputNumber 
                                    disabled={this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateRules && !this.props.isupdateRules}
                                    min={-999000000} 
                                    max={999000000} 
                                    style={{ width: '100%' }} 
                                    //precision={12}   
                                    placeholder="Less than"
                                />
                            </Form.Item>
                        </Col>                        
                        <Col xs={24} md={12}>
                            <Form.Item
                                label="Info Maximum"
                                name="infoHighValue"  
                            >
                                <InputNumber 
                                    disabled={this.props.isGlobalTemplate && this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateRules && !this.props.isupdateRules}
                                    min={-999000000} 
                                    max={999000000} 
                                    style={{ width: '100%' }} 
                                    //precision={12}   
                                    placeholder="Greater than"
                                />
                            </Form.Item>
                        </Col> 
                    </Row>    
                }
                
            </Card>  
            <div style={{marginTop:'10px'}}>
            <Card 
                key="2"
                size="small" 
                
                title={"Rule (2)" }                 
                extra={
                    <>
                        {
                            this.props.formRef.getFieldValue("incrementalRuleId") &&
                            <Space size="middle" style={{ float: 'left' }}>
                                {(this.props.isGlobalTemplate && this.props.isadmin || !this.props.isGlobalTemplate && this.props.isdeleteRules) &&
                                <Tooltip title="Delete Rule">
                                    <Button type="default" icon={<DeleteOutlined />} onClick={(e) => {this.deleteIncrementalRule(e)}}/>
                                </Tooltip>}
                            </Space>
                        }
                    </>
                }
            >
                <Form.Item 
                     name="incrementalRuleId"
                     style={{ display: 'none' }}
                 >
                     <Input type="hidden" />
                     </Form.Item>

                <Form.Item 
                     name="mergeRuleId"
                     style={{ display: 'none' }}
                 >
                     <Input type="hidden" />
                     </Form.Item>
                <Row gutter={8}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Rule Name"
                            name="incrementalRuleName"    
                        >
                            <Input maxLength={255} 
                                disabled={this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateRules && !this.props.isupdateRules} />
                        </Form.Item>
                    </Col> 
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Rule Type"
                            name="incrementalRuleType" 
                        >
                            <Select 
                                onChange={this.onRuleTypeChange}
                                disabled={this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateRules && !this.props.isupdateRules}
                            >
                                <Option key="incremental" value="incremental">Incremental</Option>
                                
                            </Select>     
                        </Form.Item>
                    </Col> 
                </Row>   
                <Row gutter={8}>
                        <Col xs={24} md={12}>
                        <Form.Item
                                name="incrementalRuleValue"
                                label="Incremental Value"
                                style={{width:'100%'}}
                            >
                                <InputNumber 
                                   
                                    min={-999000000} 
                                    max={999000000} 
                                    style={{ width: '100%' }} 
                                  />
                        </Form.Item>
                        </Col>                        
                       
                    </Row>    
                    
                </Card>
            </div>
            <div style={{marginTop:'10px'}}>
            <Card 
                key="3"
                size="small" 
                
                title={"Rule (3)" }                 
                extra={
                    <>
                        {
                            this.props.formRef.getFieldValue("offlineRuleId") &&
                            <Space size="middle" style={{ float: 'left' }}>
                                {(this.props.isGlobalTemplate && this.props.isadmin || !this.props.isGlobalTemplate && this.props.isdeleteRules) &&
                                <Tooltip title="Delete Rule">
                                    <Button type="default" icon={<DeleteOutlined />} onClick={(e) => {this.deleteOfflineRule(e)}}/>
                                </Tooltip>}
                            </Space>
                        }
                    </>
                }
            >
                <Form.Item 
                     name="offlineRuleId"
                     style={{ display: 'none' }}
                 >
                     <Input type="hidden" />
                     </Form.Item>
                     <Row gutter={16}>
      <Col className="gutter-row" span={12}>
      <Form.Item
                            label="Rule Name"
                            name="offlineRuleName"    
                        >
                            <Input maxLength={255} 
                               />
                        </Form.Item>
      </Col>
      <Col className="gutter-row" span={6}>
      <Form.Item
       name="offlineSince" label="Offline Since">
                              <Select>
                                {/* <Option key="hours" value="hours">Hours</Option> */}
                                <Option key="days" value="days">Days</Option>
                                <Option key="weeks" value="weeks">Weeks</Option>
                                <Option key="months" value="months">Months</Option>
                             </Select>
                            </Form.Item>
                </Col>
                <Col className="gutter-row" span={6}>
                <Form.Item
                                label=""
                                name="offlineDuration"
                                style={{ display: 'inline-block', width: '50%',marginTop:'30px'}}
                            >
                            
                            <Input
                                    disabled={this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateRules && !this.props.isupdateRules} />
                            </Form.Item>
                </Col>
        
            </Row>
                </Card>
                </div>
                {
           
            this.props.leftMergeRules && this.props.leftMergeRules.length >0 &&
             <Row gutter={8}>
             <Col span={24}>
            <Collapse defaultActiveKey={["0"]} activeKey={[this.state.openMergePanel]} onChange={this.setOpenMergePanel} style={{marginTop:'25px'}}>
               <Panel 
               header="Merge Rules"
               key="1"
               extra={
                <>
                    {
                        this.props.formRef.getFieldValue("mergeRuleId") &&
                        <Space size="middle" style={{ float: 'left' }}>
                            {
                            <Tooltip title="Delete Merge Rule">
                                <Button type="default" icon={<DeleteOutlined />} onClick={(e) => {this.deleteMergeRule(e)}}/>
                            </Tooltip>}
                        </Space>
                    }
                </>
            }
              
               >
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label="Merge Rule Name"
                            name="mergeRuleName"    
                        >
                        <Input maxLength={255} 
                                disabled={this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateRules && !this.props.isupdateRules} />
                        </Form.Item>
                    </Col>
                </Row>
              
                <Row gutter={16}>
                <Col className="gutter-row" span={10}>
                    <Form.Item
                        label="Select Rule"
                        name="leftMergeRule"
                    >            
                    <Select 
                   
                           showArrow={true}
                           optionFilterProp="children"
                           className="select-before" 
                           allowClear
                           onChange={this.onChangeLeftMergeRule}
                       >      
                          {this.props.leftMergeRules && this.props.leftMergeRules.map((mergeRule:any, index:number) => (
                           <Option key={mergeRule.ruleId} value={mergeRule.ruleId}>
                               {mergeRule.ruleName}
                           </Option>
                          ))}                                  
                          
                       </Select>  

                       
                      
                    </Form.Item>   
                  
                </Col>
                <Col className="gutter-row" span={4}>
                    <Form.Item
                        label="Operator"
                        name="mergeRuleOperator"    
                        
                        // checked by DB function, so that we allow the customer to change mind to stop configuring the merge ruile
                        // otherwise it will hang there once left or right rule is entered and then customer decide to not configure the merge rule
                        // rules={[
                        //     ({ getFieldValue }) => ({
                        //     // validator(_, value) {
                        //     //  if((getFieldValue('leftMergeRule')||"")!=="" && (getFieldValue('rightMergeRule')||"")!=="" && (value||"")==="")
                        //     //   {
                                  
                        //     //       return Promise.reject(new Error('Please select the operator'));
                        //     //   }
                        //      return Promise.resolve();
                        //     },
                        //   }),
                        // ]}                                             
                    > 
                    <Select allowClear>
                    <Option key="and" value="and">AND</Option>      
                    {/* <Option key="or" value="or">OR</Option>  */}
                       </Select> 
                     
                    </Form.Item>
                </Col>
                    <Col className="gutter-row" span={10}>
                    <Form.Item
                        label="Select Rule"
                        name="rightMergeRule"                        
                        rules={[
                             ({ getFieldValue }) => ({
                             validator(_, value) {
                              if((getFieldValue('leftMergeRule')||"")!=="" && (getFieldValue('mergeRuleOperator')||"")!=="" && (value||"")===""  && getFieldValue('mergeRules').length>1)
                               {
                                  return Promise.reject(new Error('Please select the rule to merge'));
                               }
                              return Promise.resolve();
                             },
                           }),
                         ]}                                     
                    >  
                         
                        <Select 
                           showArrow={true}
                           optionFilterProp="children"
                           className="select-before" 
                           allowClear
                           onChange={this.onChangeRightMergeRule} 
                           // onChange={this.onChangeMergeRule}
                       >      
                          {this.props.rightMergeRules && this.props.rightMergeRules.map((rule2:any, index:number) => (
                             <Option key={rule2.ruleId} value={rule2.ruleId}>
                             {rule2.ruleName}
                         </Option>
                          ))}                                  
                          
                       </Select>   
                    </Form.Item>
                    </Col>
              
            </Row>
       
               </Panel>
               </Collapse>
               </Col>
               </Row>

            }
                </>
            }

              </div>
           
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        signalMaint: state.templateDetailsReducers.templateDetailsState.signals.signalMaint
    };
};

export default connect(
    mapStateToProps,
    actions
)(SignalRule);