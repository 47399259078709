import React from "react";

import { formatDate } from '../common';
import { formatGps } from "../../../../utils/commonHelpers";

const formatOptions = {
  month: "short",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

function formatTimestamp(timestamp: Date): string {
  //return timestamp.toLocaleDateString("en-US", formatOptions);
  return formatDate(timestamp);
}

interface TootipProps {
  position: Position;
  signalName: string;
  gpsTimestamp: Date;
  eventTimestamp: Date;
  signalValue: number;
  ruleName: string;
  timezone?:any;
}

const Tooltip: React.FC<TootipProps> = ({ position, signalName, gpsTimestamp, eventTimestamp, signalValue, ruleName, timezone }) => {
  return (
    <div>
      {/* <span style={{ color: "black " }}><b style={{ fontWeight: 700 }}>Signal Name:</b> {signalName} </span>
      <br /> */}
      <span style={{ color: "black " }}><b style={{ fontWeight: 700 }}>Event Name:</b> {ruleName} </span>
      <br />
      <span><b style={{ fontWeight: 700 }}>Event Value:</b> { !!signalValue ? "ON" : "OFF" } </span>
      <br />
      <span><b style={{ fontWeight: 700 }}>Event Time:</b> {formatTimestamp(eventTimestamp)}</span>   
      <hr />
      <span style={{ color: "black " }}><span><b style={{ fontWeight: 700 }}>Location:</b> {formatGps(position.lat, position.lng)}</span></span>
      <br />
      <span><b style={{ fontWeight: 700 }}>GPS Time:</b> {formatTimestamp(gpsTimestamp)}</span>      
    </div>
  );
};

export default Tooltip;

interface Position {
  lat: number;
  lng: number;
}

export function toolTipForAssetEvents(
    position: Position,
    signalName: string,
    gpsTimestamp: Date,
    eventTimestamp: Date,
    signalValue: number,
    ruleName: string,
    timezone?:any
) {
    return (
      <Tooltip position={position} signalName={signalName} gpsTimestamp={gpsTimestamp} eventTimestamp={eventTimestamp} signalValue={signalValue} ruleName={ruleName} timezone={timezone} />
    );
}