import { SignalCollection, Template } from "../models";
import { State, WidgetState } from "../state";
import moment, {Moment} from "moment";
import "moment-timezone";
import {findTimezone } from "../widgets/common";


interface CsvHeader {
  label: string;
  key: string;
}

function fetchSignalDetails(
  event: any,
  datapointName: string,
  mastertag: string,
  name: string,
  org: string,
  userPreference: string
) {
  const { timestamp, signals = [] } = event;
  let eventsArray = signals.reduce((cum: object, elem: any) => {
    let name = elem.name;
    if (elem.uom.includes("Â")) {
      elem.uom = (elem.uom).slice(1)
    }
    if (elem.uom == "°C" || elem.uom == "°F") {
      // Convert to °C
      if (elem.uom == "°F" && userPreference == "°C" && userPreference != null) {
        elem.value = (elem.value - 32) * 5 / 9;
        elem.value=Math.round(elem.value*100)/100;
      }
      // Convert to °F
      if (elem.uom == "°C" && (userPreference == "°F" || userPreference == null)) {
        elem.value = (elem.value * 9 / 5) + 32;
        elem.value=Math.round(elem.value*100)/100;
      }
      elem.uom = userPreference ? userPreference : "°F";
    }
    if (elem.uom !== "") {
      var slice = elem.uom.slice(1);
      const tempUnits = ["C", "F"];
      let checkStr = tempUnits.some(temp => slice.includes(temp));
      elem.uom = checkStr ? slice == "C" ? "°C" : "°F" : elem.uom;
      name += `(${elem.uom})`;
    }
    return { ...cum, [name]: elem.value };
  }, {});
  return {
    ...eventsArray,
    timestamp: timestamp,
    mastertag: mastertag,
    assetName: name,
    orgName: org,
    signalCollectionName: datapointName,
  };
}

function createHeader(arrayOfObj: any, headerArray: any) {
  let notPresentKeys = Object.keys(arrayOfObj).filter((k: string) => {
    return headerArray.findIndex((s: any) => s.key == k) === -1;
  });
  const headerKeys = notPresentKeys.reduce((cum1: any, elem1: any) => {
    return [...cum1, { label: elem1, key: elem1 }];
  }, []);
  headerArray = [...headerArray, ...headerKeys];
  return headerArray;
}
//sort the export response data by signal collection order
function sortResponse(response: any, template: Template) {
  let sortedExportEvents: any = [];
  const { signalCollections } = template;
  const newSignalCollections = [...signalCollections];
  if (signalCollections.length > 0) {
    const sortedSignalCollections = sortSignalCollection(newSignalCollections);
    sortedSignalCollections.forEach((current: SignalCollection) => {
      const newResponse = response.filter(
        (elem: any) => elem.datapointId === current.id
      );
      if (newResponse.length > 0) {
        sortedExportEvents = [...sortedExportEvents, newResponse[0]];
      }
    });
  }
  return sortedExportEvents;
}
//sort the signal collection by alphabet.
function sortSignalCollection(
  signalCollections: SignalCollection[]
): SignalCollection[] {
  const sortedSignalCollections = signalCollections.sort(
    (elem1: SignalCollection, elem2: SignalCollection) => {
      return elem2.name.toLocaleLowerCase() < elem1.name.toLocaleLowerCase()
        ? 1
        : elem2.name.toLocaleLowerCase() > elem1.name.toLocaleLowerCase()
        ? -1
        : 0;
    }
  );
  return sortedSignalCollections;
}

export function prepareWidgetEvents(
  response: any,
  state: State,
  widgetState: WidgetState
) {
  const { template, temperature } = state;
  let details=findTimezone(state.timezone)
  const { mastertag, name, orgName } = state.asset;
  if (template.signalCollections.length > 1) {
    response = sortResponse(response, template);
  }
  let headerArray: CsvHeader[] = [
    { label: ("Time Stamp"+" (").concat(details?.timezone)+(")"),key: "timestamp"},
    //{ label: ("Time Stamp"+" (").concat(moment().tz(moment.tz.guess()).format('z'))+(")"), key: "timestamp" },//OPS-3712- To Know Timezone string
    { label: "Organization Name", key: "orgName" },
    { label: "Master Tag", key: "mastertag" },
    { label: "Asset Name", key: "assetName" },
    { label: "Signal Collection Name", key: "signalCollectionName" },
  ];

  const eventsArrays = response.reduce((eventArray: any, curElemenet: any) => {
    let isHeaderKeysAdded = false;
    const singleEventArray = curElemenet.datapointRecords.reduce(
      (eventDataArray: any, current: any) => {
        const arrayOfObj = fetchSignalDetails(
          current,
          curElemenet.datapointName,
          mastertag,
          name,
          orgName,
          temperature.value
        );
        
        headerArray = createHeader(arrayOfObj, headerArray);
        eventDataArray = [...eventDataArray, arrayOfObj];
        return eventDataArray;
      },
      []
    );
    eventArray = [...eventArray, ...singleEventArray];
    return eventArray;
  }, []);

  return {
    eventHeader: headerArray,
    eventArray: eventsArrays,
  };
}
