import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function contractsState(state = initialState, action:any) {
    let data:any = null,
        payload:any;
        switch (action.type) {  
            case "CONTRACTS_GET_SUCCESS":
                data = action.payload.data;
                return immerProduce(state, draft => {
                    draft.contracts.contractsList.refreshOnUpdate = false;
                    draft.contracts.contractsList.tableData = data;
                    draft.contracts.contractsList.tableConfig.pagination.total = data ? data[0].totalRecords : 0;
                    draft.contracts.contractsList.tableConfig.pagination.current = action.payload.page;
                });
            case "SET_BILLABLE_CUSTOMER":
                return immerProduce(state, draft => {
                    draft.contracts.billableCustomers = action.payload.billableCustomers;
                });
            case "SET_SERVICE_PLANS_LIST":
                return immerProduce(state, draft => {
                    draft.contracts.servicePlansList = action.payload.servicePlansList;
                });
            case "GET_CUSTOMER_BUCKET_DETAILS":
                return immerProduce(state, draft => {

                    const servicePlanTmp = JSON.parse(JSON.stringify(draft.contracts.servicePlansList))
                    const servicePlanBalance = servicePlanTmp?.map((servicePlan: any) => {
                        let total_available_quantity = 0
                        let partNumberBucketEntries: any = []
                        action.payload.customerBucketDetails?.map((bucketEntry:any) => {
                            if (bucketEntry.partNumber == servicePlan.partNumber) {
                                total_available_quantity = total_available_quantity + parseInt(bucketEntry.availableQuantity)
                                partNumberBucketEntries.push(bucketEntry)
                            }
                        })

                        servicePlan['total_available_quantity'] = total_available_quantity
                        servicePlan['partNumberBucketEntries'] = partNumberBucketEntries

                        return servicePlan
                    })
                    
                    draft.contracts.customerBucketDetails = servicePlanBalance;
                   
                    
                });
            case "CONTRACT_SET_SEARCH":
                    return immerProduce(state, draft => {
                    draft.contracts.contractsList.searchText = action.searchText;
            });
            case "CONTRACTS_SET_PAGINATION":
                    return immerProduce(state, draft => {
                    draft.contracts.contractsList.tableConfig.pagination.onChange = action.onPaginationChange;
            });
            case "SET_TAB":
                    return immerProduce(state, draft => {
                    draft.contracts.activeTab = action.tabNumber;
            });
            case "OPEN_DRAWER":
                    return immerProduce(state, (draft) => {
                        const payload = action.payload,
                            instance = Math.random();
                        draft.contracts.contractMaintOptions.instance = instance;
                        draft.contracts.contractMaintOptions.width = payload.width;
                        draft.contracts.contractMaintOptions.visible = true;
                        draft.contracts.contractMaintOptions.title = payload.title;
                        draft.contracts.contractMaintOptions.activeTab = "1";
                        draft.contracts.contractMaintOptions.record = payload.record;
                    });
            case "CLOSE_DRAWER":
                    return immerProduce(state, (draft) => {
                        draft.contracts.contractMaintOptions.visible = false;
                        draft.contracts.contractMaintOptions.activeTab = "1";
                        draft.contracts.contractMaintOptions.record = {};
                    });
            case "DRAWER_TAB_CHANGE":
                    return immerProduce(state, (draft) => {
                        draft.contracts.contractMaintOptions.activeTab = action.activeTab;
                    });
            case "SET_ASSETS_LIST":
                    return immerProduce(state, (draft) => {
                        draft.contracts.contractsModification.assetsList = action.payload.assetsList;
                        draft.contracts.renewalTool.assetsList = action.payload.assetsList;
                        draft.contracts.contractsModification.loadingAssetsList = false;
                    });
            case "SET_LOADING_ASSETS":
                return immerProduce(state, (draft) => {
                    draft.contracts.contractsModification.loadingAssetsList = action.payload.state;
                });
            case "SET_CONTRACT_SUBMITTING":
                return immerProduce(state, (draft) => {
                    draft.contracts.contractsModification.submittingContractList = action.payload.state;
                });
            case "SET_CONTRACT_MODIFIED_SUCCESSFULLY":
                return immerProduce(state, (draft) => {
                    draft.contracts.contractsModification.submittingContractList = false;
                    draft.contracts.contractsModification.selectedContractList = []
                });
            case "UPDATE_FIELD":
                return immerProduce(state, (draft) => {
                    if(draft.contracts.contractsModification.selectedContractList){
                        draft.contracts.contractsModification.selectedContractList = draft.contracts.contractsModification.selectedContractList.map((contract: any) => {
                            if(contract.mastertag === action.payload.mastertag){
                                contract[action.payload.filedName] = action.payload.value
                            }
                            return contract
                        });
                    }
                });
            case "UPDATE_RENEWAL_FIELD":
                return immerProduce(state, (draft) => {
                    if(draft.contracts.renewalTool.selectedRenewalList){
                        draft.contracts.renewalTool.selectedRenewalList = draft.contracts.renewalTool.selectedRenewalList.map((renewal: any) => {
                            if(renewal.mastertag === action.payload.mastertag){
                                renewal[action.payload.filedName] = action.payload.value
                            }
                            return renewal
                        });
                    }
                });
            case "APPEND_TO_SELECTED_CONTRACTS_LIST":
                return immerProduce(state, (draft) => {
                    // console.log('payload.contract', action.payload.contract)

                    const exists = draft.contracts.contractsModification.selectedContractList.some((contract:any) => contract.mastertag === action.payload.contract.masterTag)
                    
                    if(!exists){
                        const tmp = {
                            contract_id: action.payload.contract?.contractId,
                            gateway_id: action.payload.contract?.gatewayID,
                            mastertag: action.payload.contract?.masterTag,
                            contract_start_date: action.payload.contract?.contractStartDate,
                            contract_end_date: action.payload.contract?.contractEndDate,
                            old_gateway_status: action.payload.contract?.gatewayStatus,
                            old_sim_status: action.payload.contract?.simStatus,
                            old_iothub_status: action.payload.contract?.iothubStatus,
                            new_gateway_status: action.payload.contract?.gatewayStatus,
                            new_sim_status: action.payload.contract?.simStatus,
                            new_iothub_status: action.payload.contract?.iothubStatus,
                            iccid: action.payload.contract?.iccid,
                            justification: '',
                            billing_contract_history: JSON.parse(action.payload.contract?.billingContractHistory),
                            renewal_contract_history: JSON.parse(action.payload.contract?.renewalContractHistory),
                        };
                        draft.contracts.contractsModification.selectedContractList = [tmp, ...draft.contracts.contractsModification.selectedContractList];
                    }
                    
                });
            case "APPEND_TO_SELECTED_RENEWAL_TOOL_LIST":
                return immerProduce(state, (draft) => {
                    // console.log('payload.contract', action.payload.contract)

                    const exists = draft.contracts.renewalTool.selectedRenewalList.some((contract:any) => contract.mastertag === action.payload.contract.masterTag)
                    
                    if(!exists){
                        const tmp = {
                            customerNumber: JSON.parse(action.payload.contract.billingContractHistory)[0].pts_customer_number,
                            billToName: JSON.parse(action.payload.contract.billingContractHistory)[0].customer_name,
                            partNumber: "",
                            orderNumber: "",
                            customerPO: "",
                            gatewayID: action.payload.contract.gatewayID,
                            orgID: action.payload.contract.orgID,
                            mastertag: action.payload.contract.masterTag,
                            billing_contract_history: JSON.parse(action.payload.contract.billingContractHistory),
                            renewal_contract_history: JSON.parse(action.payload.contract.renewalContractHistory),
                            renewal_packages: JSON.parse(action.payload.contract.renewalPackages),
                        };
                        draft.contracts.renewalTool.selectedRenewalList = [tmp, ...draft.contracts.renewalTool.selectedRenewalList];
                    }
                    
                });
            case "REMOVE_FROM_SELECTED_CONTRACTS_LIST":
                return immerProduce(state, (draft) => {
                    // console.log('action.payload.mastertag', action.payload.mastertag)
                    draft.contracts.contractsModification.selectedContractList = draft.contracts.contractsModification.selectedContractList.filter((contract: any) => {
                        if(contract.mastertag !== action.payload.mastertag){
                            return contract
                        }
                    });
                });
            case "REMOVE_FROM_SELECTED_RENEWALS_TOOL_LIST":
                return immerProduce(state, (draft) => {
                    // console.log('action.payload.mastertag', action.payload.mastertag)
                    draft.contracts.renewalTool.selectedRenewalList = draft.contracts.renewalTool.selectedRenewalList.filter((renewal: any) => {
                        if(renewal.mastertag !== action.payload.mastertag){
                            return renewal
                        }
                    });
                });
            default:
                return state;
        }
}