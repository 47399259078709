import React, { useEffect, useReducer, useState } from "react";
import { List, Tooltip } from "antd";
import { findTimezone, formatDate, inputSignalMapper, signalLabel } from "../common";
import { CommonWidgetProps } from "../../models";
import { connect } from "react-redux";
import * as actions from "../../../../actions";
import { sign } from "crypto";
import { hexToString } from "../../../../utils/commonHelpers";

interface Props {
  signalRData: any;
}

interface Signal {
  signalId: string;
  name: string;
  format: string;
  color: string;
  values: Array<{
    timestamp: Date;
    value: number | string | boolean;
  }>;
}

const createSignals = inputSignalMapper<Signal>((meta, data, userPreference) => ({
  signalId: meta.id,
  name: signalLabel(meta, userPreference, false),
  format:meta.format,
  color: meta.color,
  values: [{ ...data[0] }],
}));

function formatNumber(n: number | string | boolean, type: string = ''): string | boolean {
  if (type.includes("latitude") || type.includes("longitude")) {
    return typeof n === "number" ? n.toFixed(10) : n;
  } else if (typeof n === "string" && (type.includes("IQAN Connect Key") ||
    type.includes("text"))) {
      // n = "0x2a6d616554206948"; 
      // console.log("raw hex", n);// 
    //n= "0x006d6165542069486d616554206948"; // 
    var msg = hexToString(n);

    msg = msg.substring(0, msg.length);
    // console.log("raw msg", msg)
    // console.log("msg.length", msg.length)
  
    // remove the deliminator from the singlast text frame
    if (type.includes("text")) {
    
      if (msg.length <= 9) {
         const lastFrameStartPos = msg.length -9;
          // console.log("lastFrameStartPos", lastFrameStartPos)
          const delimiterPos = msg.indexOf("*", lastFrameStartPos);
          // console.log("delimiterPos", delimiterPos)
          if (delimiterPos >=0) { // only applicable to singleframe, since multi-frame does not have delimiter,
              // therefore, multi-frame delimiterPos = -1
              msg = msg.substring(0, delimiterPos);
          } 
        } 
      }
      // console.log("msg before return", msg)
    return msg;
  } else {
    return typeof n === "number" ? n.toFixed(2) : n;
  }
}

const Widget: React.FC<CommonWidgetProps & Props> = (props) => {
  const { template, data, state } = props;
  let signals = createSignals(props.signals, template, data, state.temperature.value);

  /* updating with live value if present (SignalR) */
  if (Object.keys(props.signalRData.data).length > 0) {
    signals = signals.map((signal: Signal) => {
      if (props.signalRData.data.hasOwnProperty(signal.signalId)){
        signal.values[0].value = props.signalRData.data[signal.signalId][0].value
        signal.values[0].timestamp = new Date(props.signalRData.data[signal.signalId][0].bt - props.signalRData.data[signal.signalId][0].time)
      }
      return signal
    })
  }

  var details:any;
  if(signals.length>0){
    details=findTimezone(state.timezone);
  }
  return (
    <List
      dataSource={signals}
      renderItem={(signal) => (
        <List.Item>
          <List.Item.Meta title={signal.name} />
          <Tooltip
            //title={formatDate(signal.values[0].timestamp)}
            title={formatDate(signal.values[0].timestamp)+" ".concat(details?.timezone)}
            placement="topRight"
            arrowPointAtCenter={true}
          >
            <div style={{color: signal.color }}>{formatNumber(signal.values[0].value, signal.name?.includes("IQAN Connect Key")?signal.name:signal.format)}</div>
          </Tooltip>
        </List.Item>
      )}
    />
  );
};

// export default Widget;
const mapStateToProps = (state: any) => {
  return {
    signalRData: state.contextReducer.signalRData,
  };
};
export default connect(
  mapStateToProps,
  // actions
)(Widget);
