import { AssetOverview } from "../../models";
import { Filters } from "./models";
import { statusFilter, acceptAll } from './asset-filters';

function onlineFilter(asset: AssetOverview) {
    return asset.connectionStatus === "online";
}

function offlineFilter(asset: AssetOverview) {
    return asset.connectionStatus === "offline";
}

function connectionFilter(filters: Filters) {
    if (filters.online === filters.offline) {
        return acceptAll;
    }

    if (filters.online) return onlineFilter;

    return offlineFilter;
}

export default function filterAssets(
    assets: AssetOverview[],
    filters: Filters
): AssetOverview[] {
    const connFilter = connectionFilter(filters);
    const statFilter = statusFilter(filters);

    return assets.filter((a) => connFilter(a) && statFilter(a));
}
