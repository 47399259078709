import React from "react";
import { connect } from "react-redux";
import { Drawer, Tabs, Button, Space } from "antd";
import * as actions from "./actions";
import { appConfigs } from "../../utils/configurations";
import RolePermissionMaintInfo from "./roleMaintInfo/RolePermissionMaintInfo";


const { TabPane } = Tabs;

interface Prop {
    rolePermissionMaintClose: Function,
    getOrgRoles: Function,
    getRolePermissions:Function,
    context: any,
    rolePermissionList: any,
    roleList: any    
}

class RolePermissionMaint extends React.Component<Prop, {}> {
    
    componentDidUpdate(prevProps: Prop) {
       
        if (this.props.roleList.roleMaint.instance !== prevProps.roleList.roleMaint.instance) {
            this.closeCollapsed();
        }
    }
    
    closeCollapsed() {       
        let active:any = document.querySelectorAll("[data-id=role-permission-maint-drawer] .ant-collapse-item-active");    
        if (active.length) {
            for (var i = 0; i < active.length; i++) { 
                active[i]!.children[0].click();  
            }
        }
    }

    onDrawerClose = () => {
        const { appContext } = this.props.context;
        this.props.getOrgRoles(this,null,null,appContext.orgId,null);  
        appContext.roleName === "admin" ? this.props.getRolePermissions(this,null,null,"2") : this.props.getRolePermissions(this,null,null,"1");
         this.props.rolePermissionMaintClose(this);
    };

    onTabChange = (key:string) => {
        //this.props.roleMaintTabChange(this, key);
    }

    render() {

        const { rolePermissionMaint } = this.props.rolePermissionList;
               
        return(
            <Drawer
                data-id="role-permission-maint-drawer" 
                title={rolePermissionMaint.title}
                width={rolePermissionMaint.width}
                onClose={this.onDrawerClose}
                visible={rolePermissionMaint.visible}
                destroyOnClose={true}
                footer={
                    <Space size="large" style={{ float: 'right' }}>
                        <Button data-id="role-maint-close-button" type="default" onClick={this.onDrawerClose}>
                            {appConfigs.settings.drawers.closeButtonText}
                        </Button>
                         
                        <Button data-id="role-maint-submit-button" type="primary" form="rolePermissionMaintInfoForm" key="submit" htmlType="submit">
                            {appConfigs.settings.form.submitButtonText}
                        </Button>
                        
                    </Space>
                }
            >
                <Tabs data-id="role-maint-info-tabs" activeKey={rolePermissionMaint.activeTab} size="large" type="card" onChange={this.onTabChange}>
                    <TabPane data-id="role-maint-info-tab-button" tab="Roles Info" key="1">                    
                            {/* <RolePermissionMaintInfo /> */}
                    </TabPane>
                </Tabs>
            </Drawer>
        );
    }

}
const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        rolePermissionList: state.rolesReducer.rolesState.rolePermissionList,
        roleList: state.rolesReducer.rolesState.roleList               
    };
};

export default connect(
    mapStateToProps,
    actions
)(RolePermissionMaint);