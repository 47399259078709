import { getPermissions } from "../../utils/commonHelpers";

const PermissionOperations: any = { "Create" : 0, "View": 1, "Update": 2, "Delete" : 3};
export const checkMaxPermissions = (record: any, operation: string, customerOrgPermissions: any, orgId: string) => {
    //ops-2689
    // const permissionEntity = customerOrgPermissions.find((cp: any) => 
    // cp.orgId === orgId && cp.permissionId === record.permissionId);    
    const permissionEntity = customerOrgPermissions.find((cp: any) =>    
    cp.permissionId === record.permissionId);

    if(permissionEntity) {
        const permissionBinary = getPermissions(permissionEntity.permissionLevel); 
        if(permissionBinary){
            const indexValue = PermissionOperations[operation];
            const value = permissionBinary[indexValue];                
            return value;
        }
    }
    return false;  
}