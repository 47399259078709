export function orgSelectorOpen(
    me: any, 
    title: string,
    width: number
) {
    return (dispatch: any) => {
        dispatch({ type: "ORG_SELECTOR_OPEN", title: title, width: width });
    };
}

export function orgSelectorClose(
    me: any
) {   
    return (dispatch: any) => {
        dispatch({ type: "ORG_SELECTOR_CLOSE" });
    };
}

export function orgSelectorSetView(
    me: any, 
    viewName: string
) {
    return (dispatch: any) => {
        dispatch({ type: "ORG_SELECTOR_SET_VIEW", viewName: viewName });
    };
}