import { ReactComponent as aeroplane } from '../../assets/images/vehicle-icons/vehicles/aeroplane.svg';
import { ReactComponent as Aircraft } from '../../assets/images/vehicle-icons/vehicles/Aircraft.svg';
import { ReactComponent as ambulance } from '../../assets/images/vehicle-icons/vehicles/ambulance.svg';
import { ReactComponent as Ambulance1 } from '../../assets/images/vehicle-icons/vehicles/Ambulance1.svg';
import { ReactComponent as AmbulanceCar } from '../../assets/images/vehicle-icons/vehicles/AmbulanceCar.svg';
import { ReactComponent as ATV } from '../../assets/images/vehicle-icons/vehicles/ATV.svg';
import { ReactComponent as bicycle } from '../../assets/images/vehicle-icons/vehicles/bicycle.svg';
import { ReactComponent as Boat } from '../../assets/images/vehicle-icons/vehicles/Boat.svg';
import { ReactComponent as bulldozerWheels } from '../../assets/images/vehicle-icons/vehicles/bulldozerWheels.svg';
import { ReactComponent as bus } from '../../assets/images/vehicle-icons/vehicles/bus.svg';
import { ReactComponent as bus1 } from '../../assets/images/vehicle-icons/vehicles/bus1.svg';
import { ReactComponent as Bus2 } from '../../assets/images/vehicle-icons/vehicles/Bus2.svg';
import { ReactComponent as Bus3 } from '../../assets/images/vehicle-icons/vehicles/Bus3.svg';
import { ReactComponent as CableCar } from '../../assets/images/vehicle-icons/vehicles/CableCar.svg';
import { ReactComponent as CabrioletCar } from '../../assets/images/vehicle-icons/vehicles/CabrioletCar.svg';
import { ReactComponent as camper } from '../../assets/images/vehicle-icons/vehicles/camper.svg';
import { ReactComponent as car_1 } from '../../assets/images/vehicle-icons/vehicles/car_1.svg';
import { ReactComponent as car_2 } from '../../assets/images/vehicle-icons/vehicles/car_2.svg';
import { ReactComponent as car_3 } from '../../assets/images/vehicle-icons/vehicles/car_3.svg';
import { ReactComponent as car_4 } from '../../assets/images/vehicle-icons/vehicles/car_4.svg';
import { ReactComponent as car } from '../../assets/images/vehicle-icons/vehicles/car.svg';
import { ReactComponent as CargoShip } from '../../assets/images/vehicle-icons/vehicles/CargoShip.svg';
import { ReactComponent as CargoShip1 } from '../../assets/images/vehicle-icons/vehicles/CargoShip1.svg';
import { ReactComponent as CoupeCar } from '../../assets/images/vehicle-icons/vehicles/CoupeCar.svg';
import { ReactComponent as crane } from '../../assets/images/vehicle-icons/vehicles/crane.svg';
import { ReactComponent as Crane1 } from '../../assets/images/vehicle-icons/vehicles/Crane1.svg';
import { ReactComponent as CrossoverCar } from '../../assets/images/vehicle-icons/vehicles/CrossoverCar.svg';
import { ReactComponent as deliveryTruck } from '../../assets/images/vehicle-icons/vehicles/deliveryTruck.svg';
import { ReactComponent as DoubleBus } from '../../assets/images/vehicle-icons/vehicles/DoubleBus.svg';
// import { ReactComponent as ElectricTrain } from '../../assets/images/vehicle-icons/vehicles/ElectricTrain.svg';
import { ReactComponent as Escavator } from '../../assets/images/vehicle-icons/vehicles/Escavator.svg';
// import { ReactComponent as FireBrigade } from '../../assets/images/vehicle-icons/vehicles/FireBrigade.svg';
// import { ReactComponent as FireEngine } from '../../assets/images/vehicle-icons/vehicles/FireEngine.svg';
// import { ReactComponent as FlyingAirplane } from '../../assets/images/vehicle-icons/vehicles/FlyingAirplane.svg';
// import { ReactComponent as forklift } from '../../assets/images/vehicle-icons/vehicles/forklift.svg';
// import { ReactComponent as Forklifter } from '../../assets/images/vehicle-icons/vehicles/Forklifter.svg';
// import { ReactComponent as GolfCar } from '../../assets/images/vehicle-icons/vehicles/GolfCar.svg';
// import { ReactComponent as HatchbackCar } from '../../assets/images/vehicle-icons/vehicles/HatchbackCar.svg';
// import { ReactComponent as Helicopter } from '../../assets/images/vehicle-icons/vehicles/Helicopter.svg';
// import { ReactComponent as Helicopter1 } from '../../assets/images/vehicle-icons/vehicles/Helicopter1.svg';
// import { ReactComponent as hellicopter } from '../../assets/images/vehicle-icons/vehicles/hellicopter.svg';
// import { ReactComponent as IcecreamVan } from '../../assets/images/vehicle-icons/vehicles/IcecreamVan.svg';
// import { ReactComponent as IndustrialTractor } from '../../assets/images/vehicle-icons/vehicles/IndustrialTractor.svg';
// import { ReactComponent as JetSki } from '../../assets/images/vehicle-icons/vehicles/JetSki.svg';
// import { ReactComponent as LandingAirplane } from '../../assets/images/vehicle-icons/vehicles/LandingAirplane.svg';
// import { ReactComponent as lift } from '../../assets/images/vehicle-icons/vehicles/lift.svg';
// import { ReactComponent as LiftbackCar } from '../../assets/images/vehicle-icons/vehicles/LiftbackCar.svg';
// import { ReactComponent as lifter } from '../../assets/images/vehicle-icons/vehicles/lifter.svg';
// import { ReactComponent as Lifter1 } from '../../assets/images/vehicle-icons/vehicles/Lifter1.svg';
// import { ReactComponent as LimousineCar } from '../../assets/images/vehicle-icons/vehicles/LimousineCar.svg';
// import { ReactComponent as loader } from '../../assets/images/vehicle-icons/vehicles/loader.svg';
// import { ReactComponent as LogisticsShip } from '../../assets/images/vehicle-icons/vehicles/LogisticsShip.svg';
// import { ReactComponent as metro } from '../../assets/images/vehicle-icons/vehicles/metro.svg';
// import { ReactComponent as MinibusCar } from '../../assets/images/vehicle-icons/vehicles/MinibusCar.svg';
// import { ReactComponent as MinivanCar } from '../../assets/images/vehicle-icons/vehicles/MinivanCar.svg';
// import { ReactComponent as MonsterTruck } from '../../assets/images/vehicle-icons/vehicles/MonsterTruck.svg';
// import { ReactComponent as motorcicle } from '../../assets/images/vehicle-icons/vehicles/motorcicle.svg';
// import { ReactComponent as MotorSport } from '../../assets/images/vehicle-icons/vehicles/MotorSport.svg';
// import { ReactComponent as NewsVan } from '../../assets/images/vehicle-icons/vehicles/NewsVan.svg';
// import { ReactComponent as Pickup } from '../../assets/images/vehicle-icons/vehicles/Pickup.svg';
// import { ReactComponent as PickupCar } from '../../assets/images/vehicle-icons/vehicles/PickupCar.svg';
// import { ReactComponent as pickupTruck } from '../../assets/images/vehicle-icons/vehicles/pickupTruck.svg';
// import { ReactComponent as Plane } from '../../assets/images/vehicle-icons/vehicles/Plane.svg';
// import { ReactComponent as PoliceCar } from '../../assets/images/vehicle-icons/vehicles/PoliceCar.svg';
// import { ReactComponent as RoadsterCar } from '../../assets/images/vehicle-icons/vehicles/RoadsterCar.svg';
// import { ReactComponent as RVCar } from '../../assets/images/vehicle-icons/vehicles/RVCar.svg';
// import { ReactComponent as SafariJeep } from '../../assets/images/vehicle-icons/vehicles/SafariJeep.svg';
// import { ReactComponent as Scooter } from '../../assets/images/vehicle-icons/vehicles/Scooter.svg';
// import { ReactComponent as Sedan } from '../../assets/images/vehicle-icons/vehicles/Sedan.svg';
// import { ReactComponent as SedanCar } from '../../assets/images/vehicle-icons/vehicles/SedanCar.svg';
// import { ReactComponent as Segway } from '../../assets/images/vehicle-icons/vehicles/Segway.svg';
// import { ReactComponent as ship } from '../../assets/images/vehicle-icons/vehicles/ship.svg';
// import { ReactComponent as SmallYacht } from '../../assets/images/vehicle-icons/vehicles/SmallYacht.svg';
// import { ReactComponent as smartCar } from '../../assets/images/vehicle-icons/vehicles/smartCar.svg';
// import { ReactComponent as SpeedBoat } from '../../assets/images/vehicle-icons/vehicles/SpeedBoat.svg';
// import { ReactComponent as SportsCar } from '../../assets/images/vehicle-icons/vehicles/SportsCar.svg';
// import { ReactComponent as Steamboat } from '../../assets/images/vehicle-icons/vehicles/Steamboat.svg';
// import { ReactComponent as StretchCar } from '../../assets/images/vehicle-icons/vehicles/StretchCar.svg';
// import { ReactComponent as Submarine } from '../../assets/images/vehicle-icons/vehicles/Submarine.svg';
// import { ReactComponent as SUVCar } from '../../assets/images/vehicle-icons/vehicles/SUVCar.svg';
// import { ReactComponent as tank } from '../../assets/images/vehicle-icons/vehicles/tank.svg';
// import { ReactComponent as TargaCar } from '../../assets/images/vehicle-icons/vehicles/TargaCar.svg';
// import { ReactComponent as tractor_1 } from '../../assets/images/vehicle-icons/vehicles/tractor_1.svg';
// import { ReactComponent as tractor_2 } from '../../assets/images/vehicle-icons/vehicles/tractor_2.svg';
// import { ReactComponent as tractor_3 } from '../../assets/images/vehicle-icons/vehicles/tractor_3.svg';
// import { ReactComponent as Tractor } from '../../assets/images/vehicle-icons/vehicles/Tractor.svg';
// import { ReactComponent as Tractor1 } from '../../assets/images/vehicle-icons/vehicles/Tractor1.svg';
// import { ReactComponent as trailer } from '../../assets/images/vehicle-icons/vehicles/trailer.svg';
// import { ReactComponent as Trailer1 } from '../../assets/images/vehicle-icons/vehicles/Trailer1.svg';
// import { ReactComponent as Trailer2 } from '../../assets/images/vehicle-icons/vehicles/Trailer2.svg';
// import { ReactComponent as train } from '../../assets/images/vehicle-icons/vehicles/train.svg';
// import { ReactComponent as Train2 } from '../../assets/images/vehicle-icons/vehicles/Train2.svg';
// import { ReactComponent as TrainTracks } from '../../assets/images/vehicle-icons/vehicles/TrainTracks.svg';
// import { ReactComponent as tram } from '../../assets/images/vehicle-icons/vehicles/tram.svg';
// import { ReactComponent as Tram1 } from '../../assets/images/vehicle-icons/vehicles/Tram1.svg';
// import { ReactComponent as trolley_bus } from '../../assets/images/vehicle-icons/vehicles/trolley_bus.svg';
// import { ReactComponent as TrolleyBus } from '../../assets/images/vehicle-icons/vehicles/TrolleyBus.svg';
// import { ReactComponent as truck_1 } from '../../assets/images/vehicle-icons/vehicles/truck_1.svg';
// import { ReactComponent as truck_2 } from '../../assets/images/vehicle-icons/vehicles/truck_2.svg';
// import { ReactComponent as truck_3 } from '../../assets/images/vehicle-icons/vehicles/truck_3.svg';
// import { ReactComponent as truck_4 } from '../../assets/images/vehicle-icons/vehicles/truck_4.svg';
// import { ReactComponent as truck_5 } from '../../assets/images/vehicle-icons/vehicles/truck_5.svg';
// import { ReactComponent as truck_6 } from '../../assets/images/vehicle-icons/vehicles/truck_6.svg';
// import { ReactComponent as truck_7 } from '../../assets/images/vehicle-icons/vehicles/truck_7.svg';
// import { ReactComponent as Truck } from '../../assets/images/vehicle-icons/vehicles/Truck.svg';
// import { ReactComponent as truck2 } from '../../assets/images/vehicle-icons/vehicles/truck2.svg';
// import { ReactComponent as van } from '../../assets/images/vehicle-icons/vehicles/van.svg';
// import { ReactComponent as VanCar } from '../../assets/images/vehicle-icons/vehicles/VanCar.svg';
// import { ReactComponent as WagonCar } from '../../assets/images/vehicle-icons/vehicles/WagonCar.svg';
// import { ReactComponent as yacht } from '../../assets/images/vehicle-icons/vehicles/yacht.svg';
// import { ReactComponent as Yacht1 } from '../../assets/images/vehicle-icons/vehicles/Yacht1.svg';
import { ReactComponent as cloud } from '../../assets/images/map-status/cloud.svg';

export const assetsIcons:any = {
    aeroplane: {name: 'aeroplane', icon: aeroplane},
    Aircraft: {name: 'Aircraft', icon: Aircraft},
    ambulance: {name: 'ambulance', icon: ambulance},
    Ambulance1: {name: 'Ambulance1', icon: Ambulance1},
    AmbulanceCar: {name: 'AmbulanceCar', icon: AmbulanceCar},
    ATV: {name: 'ATV', icon: ATV},
    bicycle: {name: 'bicycle', icon: bicycle},
    Boat: {name: 'Boat', icon: Boat},
    bulldozerWheels: {name: 'bulldozerWheels', icon: bulldozerWheels},
    bus: {name: 'bus', icon: bus},
    bus1: {name: 'bus1', icon: bus1},
    Bus2: {name: 'Bus2', icon: Bus2},
    Bus3: {name: 'Bus3', icon: Bus3},
    CableCar: {name: 'CableCar', icon: CableCar},
    CabrioletCar: {name: 'CabrioletCar', icon: CabrioletCar},
    camper: {name: 'camper', icon: camper},
    car_1: {name: 'car_1', icon: car_1},
    car_2: {name: 'car_2', icon: car_2},
    car_3: {name: 'car_3', icon: car_3},
    car_4: {name: 'car_4', icon: car_4},
    car: {name: 'car', icon: car},
    CargoShip: {name: 'CargoShip', icon: CargoShip},
    CargoShip1: {name: 'CargoShip1', icon: CargoShip1},
    CoupeCar: {name: 'CoupeCar', icon: CoupeCar},
    crane: {name: 'crane', icon: crane},
    Crane1: {name: 'Crane1', icon: Crane1},
    CrossoverCar: {name: 'CrossoverCar', icon: CrossoverCar},
    deliveryTruck: {name: 'deliveryTruck', icon: deliveryTruck},
    DoubleBus: {name: 'DoubleBus', icon: DoubleBus},
    // ElectricTrain: {name: 'ElectricTrain', icon: ElectricTrain},
    Escavator: {name: 'Escavator', icon: Escavator},
    // FireBrigade: {name: 'FireBrigade', icon: FireBrigade},
    // FireEngine: {name: 'FireEngine', icon: FireEngine},
    // FlyingAirplane: {name: 'FlyingAirplane', icon: FlyingAirplane},
    // forklift: {name: 'forklift', icon: forklift},
    // Forklifter: {name: 'Forklifter', icon: Forklifter},
    // GolfCar: {name: 'GolfCar', icon: GolfCar},
    // HatchbackCar: {name: 'HatchbackCar', icon: HatchbackCar},
    // Helicopter: {name: 'Helicopter', icon: Helicopter},
    // Helicopter1: {name: 'Helicopter1', icon: Helicopter1},
    // hellicopter: {name: 'hellicopter', icon: hellicopter},
    // IcecreamVan: {name: 'IcecreamVan', icon: IcecreamVan},
    // IndustrialTractor: {name: 'IndustrialTractor', icon: IndustrialTractor},
    // JetSki: {name: 'JetSki', icon: JetSki},
    // LandingAirplane: {name: 'LandingAirplane', icon: LandingAirplane},
    // lift: {name: 'lift', icon: lift},
    // LiftbackCar: {name: 'LiftbackCar', icon: LiftbackCar},
    // lifter: {name: 'lifter', icon: lifter},
    // Lifter1: {name: 'Lifter1', icon: Lifter1},
    // LimousineCar: {name: 'LimousineCar', icon: LimousineCar},
    // loader: {name: 'loader', icon: loader},
    // LogisticsShip: {name: 'LogisticsShip', icon: LogisticsShip},
    // metro: {name: 'metro', icon: metro},
    // MinibusCar: {name: 'MinibusCar', icon: MinibusCar},
    // MinivanCar: {name: 'MinivanCar', icon: MinivanCar},
    // MonsterTruck: {name: 'MonsterTruck', icon: MonsterTruck},
    // motorcicle: {name: 'motorcicle', icon: motorcicle},
    // MotorSport: {name: 'MotorSport', icon: MotorSport},
    // NewsVan: {name: 'NewsVan', icon: NewsVan},
    // Pickup: {name: 'Pickup', icon: Pickup},
    // PickupCar: {name: 'PickupCar', icon: PickupCar},
    // pickupTruck: {name: 'pickupTruck', icon: pickupTruck},
    // Plane: {name: 'Plane', icon: Plane},
    // PoliceCar: {name: 'PoliceCar', icon: PoliceCar},
    // RoadsterCar: {name: 'RoadsterCar', icon: RoadsterCar},
    // RVCar: {name: 'RVCar', icon: RVCar},
    // SafariJeep: {name: 'SafariJeep', icon: SafariJeep},
    // Scooter: {name: 'Scooter', icon: Scooter},
    // Sedan: {name: 'Sedan', icon: Sedan},
    // SedanCar: {name: 'SedanCar', icon: SedanCar},
    // Segway: {name: 'Segway', icon: Segway},
    // ship: {name: 'ship', icon: ship},
    // SmallYacht: {name: 'SmallYacht', icon: SmallYacht},
    // smartCar: {name: 'smartCar', icon: smartCar},
    // SpeedBoat: {name: 'SpeedBoat', icon: SpeedBoat},
    // SportsCar: {name: 'SportsCar', icon: SportsCar},
    // Steamboat: {name: 'Steamboat', icon: Steamboat},
    // StretchCar: {name: 'StretchCar', icon: StretchCar},
    // Submarine: {name: 'Submarine', icon: Submarine},
    // SUVCar: {name: 'SUVCar', icon: SUVCar},
    // tank: {name: 'tank', icon: tank},
    // TargaCar: {name: 'TargaCar', icon: TargaCar},
    // tractor_1: {name: 'tractor_1', icon: tractor_1},
    // tractor_2: {name: 'tractor_2', icon: tractor_2},
    // tractor_3: {name: 'tractor_3', icon: tractor_3},
    // Tractor: {name: 'Tractor', icon: Tractor},
    // Tractor1: {name: 'Tractor1', icon: Tractor1},
    // trailer: {name: 'trailer', icon: trailer},
    // Trailer1: {name: 'Trailer1', icon: Trailer1},
    // Trailer2: {name: 'Trailer2', icon: Trailer2},
    // train: {name: 'train', icon: train},
    // Train2: {name: 'Train2', icon: Train2},
    // TrainTracks: {name: 'TrainTracks', icon: TrainTracks},
    // tram: {name: 'tram', icon: tram},
    // Tram1: {name: 'Tram1', icon: Tram1},
    // trolley_bus: {name: 'trolley_bus', icon: trolley_bus},
    // TrolleyBus: {name: 'TrolleyBus', icon: TrolleyBus},
    // truck_1: {name: 'truck_1', icon: truck_1},
    // truck_2: {name: 'truck_2', icon: truck_2},
    // truck_3: {name: 'truck_3', icon: truck_3},
    // truck_4: {name: 'truck_4', icon: truck_4},
    // truck_5: {name: 'truck_5', icon: truck_5},
    // truck_6: {name: 'truck_6', icon: truck_6},
    // truck_7: {name: 'truck_7', icon: truck_7},
    // Truck: {name: 'Truck', icon: Truck},
    // truck2: {name: 'truck2', icon: truck2},
    // van: {name: 'van', icon: van},
    // VanCar: {name: 'VanCar', icon: VanCar},
    // WagonCar: {name: 'WagonCar', icon: WagonCar},
    // yacht: {name: 'yacht', icon: yacht},
    // Yacht1: {name: 'Yacht1', icon: Yacht1}
    cloud: {name: 'cloud', icon: cloud}
}

export default function getAssetIcon(iconName: string) {
    if(iconName){
        return assetsIcons[iconName].icon
    } else {
        return assetsIcons.cloud.icon
    }
}


