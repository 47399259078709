import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Api from "./api";
import AssetDashboard from "./AssetDashboard";
import { Result } from "antd";
import { errorResponse } from "../../utils/apiHelpers/apiHelpers";

const ErrorPage: React.FC = () => (
  <Result
    status="error"
    title="Failed to Load Dashboard"
    subTitle="Unfortunately we couldn't fetch the data to make this page work. Try refreshing the page or contact support"
  />
);

const DashboardPage: React.FC = () => {
  const { assetId } = useParams();
  const [initialData, setInitialData] = useState<Api.AssetDashboardResponse>();
  const [errorOccurred, setErrorOccurred] = useState(false);

  useEffect(() => {
    if (assetId) {
      Api.loadAssetDashboard(assetId)
        .then(setInitialData)
        .catch((error) => {
          errorResponse(null, error);
          setErrorOccurred(true)
        });
    }
  }, [assetId]);

  if (initialData) {
    return <AssetDashboard initialData={initialData} />;
  } else if (errorOccurred) {
    return <ErrorPage />;
  } else {
    return <></>;
  }
};

export default DashboardPage;
