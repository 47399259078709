import React from "react";
import { Drawer, Button, Form, Input, Popconfirm, message, Select, Row, Col, } from "antd";
import TextArea from "antd/lib/input/TextArea";

import Axios from "axios";

import { connect } from "react-redux";
import * as actions from "./actions";

import { appConfigs } from "../../utils/configurations";

import { ErrorAlert, SuccessAlert } from "../../utils/Components/formAlerts";
import { addListenerToUpdateGeoCoords, jsonCircle, jsonPolygon, jsonRectangle, updateGeoCoords } from "./geofenceHelper";
import { getPermissions } from "../../utils/commonHelpers";
import { SketchPicker } from "react-color";

const colorPickerCover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
} as React.CSSProperties;

const { Option } = Select;

interface Prop{
    visible: boolean,
    openDrawer: Function,
    closeDrawer: Function,
    setName: Function,
    setColor: Function,
    setDescription: Function,
    record: any,
    assetsList: any,
    setAssetsList:Function,
    mastertags: any,
    gatewayIds: any,
    changeInGateways:any,
    setChangeInGateways:Function,
    setShapeGateways:Function,
    resetChangeGateways:Function,
    updateShape:Function,
    updateShapeOnMap: Function,
    curShape:any,
    addShapeSubmit:Function
    removeShape: Function,
    permissions: any,
    stats: any,
    geofencesList: any,
}

interface State {
    formError?: boolean;
    formErrorMessage?: string[];
    formSuccess?: boolean;
    formSuccessMessage?: string;
    isOpen: boolean;
    geoFenceColor: string;
}


class ShapeInfo extends React.Component<Prop>{  
    
    state:State = {
        isOpen: false,
        geoFenceColor: this.props.record.fenceColor ? this.props.record.fenceColor : "#f73e16",
    }

    gateways: any = [];

    close = () => {
        this.props.closeDrawer();
        this.props.resetChangeGateways();
        this.setState({formError:false})
        this.setState({formSuccess:false})
    }


    onChange = (value:any, option:any) => {
        this.gateways = option;
    }

    onSelect = (value:any, option:any) => {
       this.props.setChangeInGateways({key: option.key, value: option.value, selected: true})
    }

    onDeSelect = (key:any, option:any) => {
        this.props.setChangeInGateways({key: option.key, value: option.value, selected: false})
    }
   
    sendGeofenceGateway = () => {

        this.props.changeInGateways.forEach( (item:any) => {

            if(item.selected){

                Axios.post(appConfigs.server.URL + "/ui/api/geofences/gateway/add",
                {
                    gatewayId: item.key,
                    geofenceId: this.props.record.id,
                    mastertag: item.value
                }, 
                {
                    responseType: "json",        
                    headers: {},
                    params: {}
                })

            }else{

                Axios.put(appConfigs.server.URL + "/ui/api/geofences/gateway/delete",
                {
                    gatewayId: item.key,
                    geofenceId: this.props.record.id               
                }, 
                {
                    responseType: "json",        
                    headers: {},
                    params: {}
                })
                
            }

        })

        this.props.resetChangeGateways();
        this.props.setShapeGateways(this.props.record.id, this.gateways);
        this.gateways = [];
    } 
   

    onSubmitClick = (canGeofencingCreate: boolean, canGeofencingEdit: boolean) => {

        let record:any = this.props.record;
        let curShape:any = this.props.curShape;

        let changeInGateways:any[] = this.props.changeInGateways;
        let gateways = this.gateways;

        //check if we are creating or editing
        if (record.id === '') { //check if creation mode

            /* Prevent org to create more that 10 geofences if all of its gateways are lite gateways */
            if ((this.props.stats.data.gatewaysCount - this.props.stats.data.liteGatewaysCount) == 0 && this.props.geofencesList.fenceList.length > 10) {

                this.setState({ formError: true, formErrorMessage: ["A maximum of 10 geofences limit reached, contact system administrator to upgrade"] })
                
            } else {
                
                if (canGeofencingCreate) {

                    Axios.post(appConfigs.server.URL + "/ui/api/geofences" , 
                    this.props.record,
                    {
                        responseType: "json",        
                        headers: {},
                        params: {}
                    }).then(response => {

                        if( response.data.status === "success" ) {
                            this.setState({formSuccess:true , formSuccessMessage: "Sucess"})

                            if (record.id === '') {
                                
                                // Update to lastest coordinates for the newly added shape in the database
                                if(record.type === "circle"){
                                    updateGeoCoords(response.data.data.geofenceId, jsonCircle(curShape.shape));
                                }else if(record.type === "rectangle"){
                                    updateGeoCoords(response.data.data.geofenceId, jsonRectangle(curShape.shape));
                                }else if(record.type === "polygon"){
                                    updateGeoCoords(response.data.data.geofenceId, jsonPolygon(curShape.shape));
                                }

                                this.props.addShapeSubmit(curShape.shape, record, response.data.data.geofenceId);
                                addListenerToUpdateGeoCoords({
                                    id: response.data.data.geofenceId,
                                    shape: curShape.shape,
                                    type: record.type
                                })

                                this.props.setShapeGateways(response.data.data.geofenceId, gateways)
                                
                                changeInGateways.forEach((item:any) => {
                                    Axios.post(appConfigs.server.URL + "/ui/api/geofences/gateway/add",
                                    {
                                        gatewayId: item.key,
                                        geofenceId: response.data.data.geofenceId,
                                        mastertag: item.value
                                    }, 
                                    {
                                        responseType: "json",        
                                        headers: {},
                                        params: {}
                                    })
                                })


                            }

                        }else {
                            this.setState({formError:true , formErrorMessage: ["Error couldn't submit"]})
                            return false
                        }

                    })

                    if(this.props.record.id !== ''){
                        this.props.updateShape(this.props.record.id, this.props.record);
                        this.props.updateShapeOnMap(curShape, {fenceColor: this.state.geoFenceColor });
                        if (changeInGateways.length > 0) {
                            this.sendGeofenceGateway()
                        }
                    } else {
                        this.props.updateShapeOnMap(curShape, {fenceColor: this.state.geoFenceColor });
                    }

                } else {
                    this.setState({formError:true , formErrorMessage: ["You don't have creation permisison"]})
                    return false
                }

            }

        } else { // else is editing mode

            if (canGeofencingEdit) {

                Axios.post(appConfigs.server.URL + "/ui/api/geofences" , 
                this.props.record,
                {
                    responseType: "json",        
                    headers: {},
                    params: {}
                }).then(response => {

                    if( response.data.status === "success" ) {
                        this.setState({formSuccess:true , formSuccessMessage: "Sucess"})

                        if (record.id === '') {
                            
                            // Update to lastest coordinates for the newly added shape in the database
                            if(record.type === "circle"){
                                updateGeoCoords(response.data.data.geofenceId, jsonCircle(curShape.shape));
                            }else if(record.type === "rectangle"){
                                updateGeoCoords(response.data.data.geofenceId, jsonRectangle(curShape.shape));
                            }else if(record.type === "polygon"){
                                updateGeoCoords(response.data.data.geofenceId, jsonPolygon(curShape.shape));
                            }

                            this.props.addShapeSubmit(curShape.shape, record, response.data.data.geofenceId);
                            addListenerToUpdateGeoCoords({
                                id: response.data.data.geofenceId,
                                shape: curShape.shape,
                                type: record.type
                            })

                            this.props.setShapeGateways(response.data.data.geofenceId, gateways)
                            
                            changeInGateways.forEach((item:any) => {
                                Axios.post(appConfigs.server.URL + "/ui/api/geofences/gateway/add",
                                {
                                    gatewayId: item.key,
                                    geofenceId: response.data.data.geofenceId,
                                    mastertag: item.value
                                }, 
                                {
                                    responseType: "json",        
                                    headers: {},
                                    params: {}
                                })
                            })


                        }

                    }else {
                        this.setState({formError:true , formErrorMessage: ["Error couldn't submit"]})
                        return false
                    }

                })

                if(this.props.record.id !== ''){
                    this.props.updateShape(this.props.record.id, this.props.record);
                    this.props.updateShapeOnMap(this.props.curShape, {fenceColor: this.state.geoFenceColor });
                    if (changeInGateways.length > 0) {
                        this.sendGeofenceGateway()
                    }
                }

            } else {
                this.setState({formError:true , formErrorMessage: ["You don't have editing permisison"]})
            }

        }
        

    }


    onDelete = () => {
        let id:string = this.props.record.id;
        let shape:any = this.props.curShape;

        if(id === ''){
            message.success('Geofence has been deleted successfully');
            this.props.removeShape(shape);
            this.props.closeDrawer();
            return;
        }

        if(this.props.mastertags.length === 0){
            Axios.put( appConfigs.server.URL + `/ui/api/geofences/${id}/delete` ,
                {
                    responseType: "json",        
                    headers: {},
                    params: {
                        geofenceId: id
                    }
                }
            ).then (response => {
                if(response.data.status === "success"){
                    
                    message.success('Geofence has been deleted successfully');
                    this.props.removeShape(shape);
                    this.props.closeDrawer();
                }
            })
        }else{
            message.error('Remove all the assets attached to this geofence first')
        }
        
    }

    onColorSelect = (color:any) => {
        this.props.setColor(color.hex)        
        this.setState({ geoFenceColor: color.hex });
    };

    render() {

        const geofencePermissions = getPermissions(this.props.permissions.permissions.geofencing);
        let canGeofencingCreate = geofencePermissions[0];
        // let canGeofencingRead = geofencePermissions[1];
        let canGeofencingEdit = geofencePermissions[2];
        let canGeofencingDelete = geofencePermissions[3];

        return (
            <Drawer
                zIndex={3}
                data-id="user-maint-drawer" 
                title={`Geofence: ${this.props.record.fenceName}`}
                width={"400px"}
                onClose={this.close}
                visible={this.props.visible}
                destroyOnClose={true}
                footer={
                    <Row justify="space-between">
                        {canGeofencingDelete &&
                            <Col span={12}>
                                <Popconfirm title="Are you sure to delete this geofence?" okText="Yes" cancelText="No" onConfirm={this.onDelete}>
                                    <Button type="primary" size="middle" danger>
                                        Delete
                                    </Button>
                                </Popconfirm>
                            </Col>
                        } {!canGeofencingDelete && <Col span={12}></Col> }
                        {(canGeofencingEdit || canGeofencingCreate) &&
                            <Col span={12}>
                                <Button style={{ float: 'right' }} data-id="assets-maint-submit-button" type="primary" form="shapeInfoForm" key="submit" onClick={() => this.onSubmitClick(canGeofencingCreate, canGeofencingEdit)} htmlType="submit">
                                    {appConfigs.settings.form.submitButtonText}
                                </Button>
                            </Col>
                        }
                    </Row>
                }
            >                       
    
                {this.state.formSuccess && !this.state.formError && <SuccessAlert data-id="assets-maint-info-success-message" message={this.state.formSuccessMessage}/>}
                {this.state.formError && !this.state.formSuccess && <ErrorAlert data-id="assets-maint-info-error-message" message={this.state.formErrorMessage}/>}
                    
                {
                    <Form layout="vertical" >  

                        <Form.Item style={{marginTop:10}} label="Name">
                            <Input 
                                disabled={ (!canGeofencingEdit && this.props.record.id !== '' ) || (!canGeofencingCreate && this.props.record.id === '' ) } 
                                placeholder="Name" 
                                defaultValue={this.props.record.fenceName}
                                onChange={(e) => {this.props.setName(e.target.value)}}
                            />
                        </Form.Item>

                        <Form.Item style={{marginTop:10}} label="Color">
                               
                            <Input 
                                disabled={ (!canGeofencingEdit && this.props.record.id !== '' ) || (!canGeofencingCreate && this.props.record.id === '' ) }
                                value={this.props.record.fenceColor ? this.props.record.fenceColor : "#f73e16"}
                                readOnly={true}
                                onFocus={() => {
                                    this.setState({isOpen: !this.state.isOpen});
                                }}
                                style={{ width: "85%" }}
                            />
                            
                            <Button
                                style={{
                                    marginLeft: "8px",
                                    width: "5%",
                                    background: this.props.record.fenceColor ? this.props.record.fenceColor : "#f73e16",
                                }}
                                shape= "circle"
                                onClick={() => {
                                    this.setState({isOpen: !this.state.isOpen});
                                }}
                            >
                                &nbsp;
                            </Button>
                            <div>
                                {this.state.isOpen ? (
                                    <>
                                    <div
                                        style={{
                                            position: "absolute",
                                            zIndex: 5,
                                            right: 0,
                                            marginTop: '8px',
                                            marginRight: '15px',
                                        }}
                                    >
                                        <div
                                            style={colorPickerCover}
                                            onClick={() => {
                                                this.setState({isOpen: !this.state.isOpen});
                                            }}
                                        />
                                        <SketchPicker
                                            color={ this.props.record.fenceColor ? this.props.record.fenceColor : "#f73e16" }
                                            onChange={ this.onColorSelect }
                                        />
                                    </div>
                                    </>
                                ) : null}
                            </div>
                        </Form.Item>
                       
                        <Form.Item style={{marginTop: -20}} label="Description">
                            <TextArea disabled={(!canGeofencingEdit && this.props.record.id !== '' ) || (!canGeofencingCreate && this.props.record.id === '' )} autoSize={{ minRows: 3, maxRows: 5 }} defaultValue={this.props.record.description} onChange={(e) => {this.props.setDescription(e.target.value)}} placeholder="Description" />
                        </Form.Item>
                       
                        <Form.Item  style={{marginTop:10, display: 'none'}}  label="Shape">
                            <Input  value={this.props.record.type} disabled={true} />
                        </Form.Item>

                        <Form.Item style={{marginTop:10}} label="Asset Master Tag">
                            <Select 
                                mode="multiple"
                                defaultValue={this.props.mastertags}
                                showSearch
                                onChange={this.onChange}
                                onSelect={this.onSelect}
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    return  (option?.children[0]?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option?.children[2]?.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                                }}
                                onDeselect={this.onDeSelect}
                                disabled={ (!canGeofencingEdit && this.props.record.id !== '' ) || (!canGeofencingCreate && this.props.record.id === '' ) }
                            >
                                {
                                    this.props.assetsList.map((item:any) => (
                                        (this.props.record.gatewayId === item.key) ? 
                                            <Option selected key={item.gatewayId} value={item.mastertag}>{item.nickname} / {item.mastertag}</Option>
                                            : 
                                            <Option key={item.gatewayId} value={item.mastertag}>{item.nickname} / {item.mastertag}</Option>
                                    ))
                                    
                                }
                            </Select>
                        </Form.Item>
                                                       
                    </Form>
                    
                }
                
            </Drawer>
        );
    }
}


const mapStateToProps = (state:any) => {

    return {
        visible: state.geofencesReducer.geofencesState.geofencesList.geoFenceMaint.visible,
        record: state.geofencesReducer.geofencesState.geofencesList.geoFenceMaint.record,
        assetsList: state.geofencesReducer.geofencesState.geofencesList.geoFenceMaint.assetsList,
        mastertags: state.geofencesReducer.geofencesState.geofencesList.geoFenceMaint.mastertags,
        gatewayIds:  state.geofencesReducer.geofencesState.geofencesList.geoFenceMaint.gatewayIds,
        changeInGateways:  state.geofencesReducer.geofencesState.geofencesList.geoFenceMaint.changeInGateways,
        curShape: state.geofencesReducer.geofencesState.geofencesList.geoFenceMaint.curShape,
        permissions: state.contextReducer.data,
        stats: state.contextReducer.stats,
        geofencesList: state.geofencesReducer.geofencesState.geofencesList,
    }
}

export default connect(
    mapStateToProps,
    actions
)(ShapeInfo);



