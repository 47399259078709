import Axios from "axios";
import { errorResponse } from "../../../../utils/apiHelpers/apiHelpers";
import { appConfigs } from "../../../../utils/configurations";

export function getControllerOtaManagement(
    me: any, 
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
    page: number = 1,
    searchText?: string,
    filters?: any[any],
    controllerId?:string) 
{  
  return async (dispatch: any) => 
  {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let filtersObj:any = [];
    filters && filters['status'] != null ? filtersObj = {'statusFilter': filters['status']} : filtersObj = {}
    Axios.get(appConfigs.server.URL + "/ui/api/ota_controllers",
    {
      responseType: "json",        
      headers: {},
      params: {
          searchText: searchText,
          page: page,
          pageSize: appConfigs.tables.pageSize
      }
    }).then(response => 
    { 
      if (callbackFnSuccess != null) {
          callbackFnSuccess(response.data.data.controllers);
      }
      dispatch({ type: "CSUS_GET_SUCCESS", payload: response.data.data.gateways_controllers  });
    }).catch(function (error) 
    {
      if (callbackFnFailure != null) {
          callbackFnFailure(me, error)
      }
      dispatch({ type: "CSUS_GET_FAILED", payload: {data: null, page: page}  });
      errorResponse(me, error);
    });
  };
}
export function controllerOtaManagementSetSearch(searchText: string ) 
{
    return (dispatch: any) => 
    {
        dispatch({ type: "CSU_SET_SEARCH", searchText: searchText });
    }
}  

export function updateControllerOtaManagementSelection(selectedController: string )
{
    return (dispatch: any) => 
    {
        dispatch({ type: "UPDATE_CSU_SELECTION", selectedController: selectedController });
    }
}  

export function ControllerOtaManagementSetPagination(onPaginationChange: any)
{
    return (dispatch: any) => 
    {
        dispatch({ type: "CSU_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
} 

export function setTab(tabNumber: string )
{
    return (dispatch: any) => 
    {
        dispatch({ type: "SET_TAB", tabNumber: tabNumber });
    }
}
export function openDrawer(title: string, width: number, record: any) 
{
	return (dispatch: any) => 
  {
		dispatch({ type: "OPEN_DRAWER", payload: { title: title, width: width, record: record } });
	};
}

export function openInstallationProgressDrawer(title: string, width: number, record: any) 
{
	return (dispatch: any) => 
  {
		dispatch({ type: "OPEN_INSTALL_PROGRESS_DRAWER", payload: { title: title, width: width, record: record } });
	};
}

export function closeDrawer() 
{
	return (dispatch: any) => 
  {
		dispatch({ type: "CLOSE_DRAWER" });
	};
}

export function closeInstallationProgressDrawer() 
{
	return (dispatch: any) => 
  {
		dispatch({ type: "CLOSE_INSTALL_PROGRESS_DRAWER" });
	};
}

export function drawerTabChange(activeTab: string) 
{
	return (dispatch: any) => 
  {
		dispatch({ type: "DRAWER_TAB_CHANGE", activeTab: activeTab });
	};
}
export function getSoftwares(
  me: any,
  callbackFnSuccess?: any,
  callbackFnFailure?: any,
  page: number=1,
  searchText?: string,
  filters?: any[any],
  systemSwId?: string ) 
{
  return async (dispatch: any) => 
  {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let filtersObj: any = [];
    filters && filters["status"] != null
      ? (filtersObj = { statusFilter: filters["status"] })
      : (filtersObj = {});

    Axios.get(appConfigs.server.URL + "/ui/api/software-listing",
      {
          params: {
            orgId: null,
            systemSwId: systemSwId,
            searchText: searchText,
            scope: "all",
            page: page,
            pageSize: appConfigs.tables.pageSize
          },
      }
    ).then((response) => 
    {
        if (callbackFnSuccess != null) 
        {
          callbackFnSuccess(response.data.data.softwareList);
        }
        dispatch({
          type: "SOFTWARES_GET_SUCCESS",
          payload: { data: response.data.data.softwareList, page: page },
        });
    }).catch(function (error) 
    {
        if (callbackFnFailure != null) 
        {
          callbackFnFailure(me, error);
        }
        errorResponse(me, error);
    });
  };
}
  export function getDeviceDiscovery(
    me: any,
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
    mastertag?:string,)
{
  return async (dispatch: any) => 
  {
      Axios.get(appConfigs.server.URL + `/api/iqanopen/GATEWAY/${mastertag}/system-discovery`,).then((response) => 
      {
          if (callbackFnSuccess != null) 
          {
            callbackFnSuccess(response.data.data.systemDiscovery);
          }
          dispatch({ type: "DISCOVERY_GET_SUCCESS",payload: { data: response.data.data.systemDiscovery},});
        }).catch(function (error) 
        {
          if (callbackFnFailure != null) 
          {
            callbackFnFailure(me, error);
          }
          dispatch({ type: "DISCOVERY_GET_FAILED", payload: {data: null}});
          errorResponse(me, error);
      });
    };
  }
  export function getSystemStatus(
    me: any,
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
    mastertag?:string,) 
  {
    return async (dispatch: any) => 
    {
      Axios.get(appConfigs.server.URL + `/api/iqanopen/GATEWAY/${mastertag}/status`,).then((response) => 
      {
          if (callbackFnSuccess != null) 
          {
            callbackFnSuccess(response.data.data.systemStatus);
          }
          dispatch({type: "SYSTEMSTATUS_GET_SUCCESS", payload: { data: response.data.data.systemStatus},});
      }).catch(function (error) 
      {
          if (callbackFnFailure != null) 
          {
            callbackFnFailure(me, error);
          }
          dispatch({ type: "SYSTEMSTATUS_GET_FAILED", payload: {data: null}});
          errorResponse(me, error);
        });
    };
  }
  export function CsuMaint(
    me: any,
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
    mastertag?:any,
    csuInfo?: any,
  ) {
  
    return async (dispatch: any) => {
      var formData = new FormData();

      if(csuInfo!==null)
      {
      formData.append("devicemodel", csuInfo.DeviceName ||'');
      formData.append("softwaretype", csuInfo.softwareType||'');
      formData.append("version", csuInfo.version||'');
      formData.append("hardwareId", csuInfo.hardwareId||'');
      formData.append("operatorApproval", csuInfo.operatorapproval||'');
      formData.append("packageName", csuInfo.packageName||'');
      formData.append("orgId", csuInfo.orgId||'');
      formData.append("address", csuInfo.address||'');
      }
      //console.log("post install-csu-software formData", Object.fromEntries(formData));
      Axios(  
          appConfigs.server.URL + `/api/iqanopen/GATEWAY/${mastertag}/install-software-package`,
        {
          method: "POST",
          data: Object.fromEntries(formData),
          headers: { "Content-Type": "application/json;" },
        }
      ).then((response) => {
         //console.log("install-csu-software response.data", response.data);
          if (callbackFnSuccess != null) {
            callbackFnSuccess(me, response.data.status);
          }
  
          dispatch({
            type: "CSU_MAINT_SUCCESS",
            payload: response.data.data.softwareUploads,
          });
        })
        .catch(function (error) {
          //console.log("error", error);
          if (callbackFnFailure != null) {
            callbackFnFailure(error);
          }
          errorResponse(me, error);
        });
    };
  
  }
export function getSystemHistory(
    me: any,
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
    mastertag?:string,) 
{
  return async (dispatch: any) => 
  {
      Axios.get(appConfigs.server.URL + `/api/iqanopen/GATEWAY/${mastertag}/history`,)
        .then((response) => 
        {
          if (callbackFnSuccess != null) 
          {
            callbackFnSuccess(response.data.data.systemHistory);
          }
          dispatch({type: "SYSTEMHISTORY_GET_SUCCESS",payload: { data: response.data.data.systemHistory}});
        }).catch(function (error) 
        {
          if (callbackFnFailure != null)
          {
            callbackFnFailure(me, error);
          }
          dispatch({ type: "SYSTEMHISTORY_FAILED", payload: {data: null}});
          errorResponse(me, error);
        });
  };
}
export function ControllerOtaManagement(
    me: any, 
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
    page: number = 1,
    searchText?: string,
    filters?: any[any],
    controllerId?:string) 
{
  return async (dispatch: any) => 
  {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let filtersObj:any = [];
    filters && filters['status'] != null ? filtersObj = {'statusFilter': filters['status']} : filtersObj = {}
    Axios.get(appConfigs.server.URL + "/ui/api/device_ota_management", 
    {
      responseType: "json",        
      headers: {},
      params: {
          searchText: searchText,
          page: page,
          pageSize: appConfigs.tables.pageSize
      }
    }).then(response => 
    { 
      if (callbackFnSuccess != null) 
      {
          callbackFnSuccess(response.data.data.controllers);
      }
          dispatch({ type: "DEVICE_OTA_GET_SUCCESS", payload: response.data.data.controllers_ota});
      }).catch(function (error) 
      {
            if (callbackFnFailure != null) 
            {
                callbackFnFailure(me, error)
            }
            dispatch({ type: "DEVICE_OTA_GET_FAILED", payload: {data: null, page: page}  });
            errorResponse(me, error);
        });
    };
}

export function getAllSoftwaresList() 
{
  return (dispatch: any) => 
  {
    Axios.get(appConfigs.server.URL + "/ui/api/software-listing", {
      responseType: "json",
      headers: {},
    }).then((response) => 
    {
      dispatch({
        type: "SOFTWARES_LIST_SUCCESS",
        payload: { data: response.data.data.softwareList},
        
      });
    }).catch(function (error) 
    {
        dispatch({type: "SET_LOADING_SOFTWARES", payload: { state: false },});
        errorResponse(null, error);
    });
  };
}