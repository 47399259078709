import { EventsMapMarkerOptions } from "../../pages/Dashboard2/widgets/EventsLocationWidget/Widget";
import { Marker } from "./MarkerManager";

export interface PolyLineOptions {
	polyline: boolean;
}

export interface EventsOnMap {
	eventsOnMap?: boolean;
}

function compare(array1: Marker[], array2: Marker[]) {
	const newArray1 = [...array1];
	const newArray2 = [...array2];
	return (
		newArray1.length === newArray2.length &&
		newArray1.every(function (value, index) {
			return (
				value.position.lat === newArray2[index].position.lat &&
				value.position.lng === newArray2[index].position.lng
			);
		})
	);
}

function zoomToFit(markers: Marker[]) {
	let bounds = new google.maps.LatLngBounds();
	for (let i = 0; i < markers.length; i++) {
		bounds.extend(markers[i].position);
	}
	return bounds;
}

export function constructPoyline(
	markers: Marker[],
	map: google.maps.Map,
	polyLines?: google.maps.Polyline[],
	oldmarkers?: Marker[]
) {
	//compare old markers are same as new markers, if not then zoom to fit, if no change
	//means user has zoomed in and it should not come to previous state.
	if (!oldmarkers) {
		const bounds = zoomToFit(markers);
		map.fitBounds(bounds);
	} else {
		const isFitBoundRequired = compare(markers, oldmarkers);
		if (!isFitBoundRequired) {
			const bounds = zoomToFit(markers);
			map.fitBounds(bounds);
		}
	}

	//map.setZoom(map.getZoom() + 1);
	let polyLinePaths: google.maps.Polyline[] = [];
	polyLinePaths.push(
		new google.maps.Polyline({
			path: markers.map((s) => s.position),
			strokeColor: "#6060FB",
			strokeWeight: 3,
			strokeOpacity: 1.0,
			editable: false,
			draggable: false,
			geodesic: true,
			visible: true,
			icons: [
				{
					icon: {
						path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
					},
					offset: "25px",
					repeat: "50px",
				},
			],
		})
	);

	//clear the previous polyline from widget
	if (polyLines) {
		polyLines?.map((p) => {
			p.setMap(null);
		});
	}

	polyLinePaths.map((p) => {
		p.setMap(map);
	});

	let oldMarkers = [...markers];
	return { polyLinePaths, oldMarkers };
}

export function constructPoylineWithEvents(
	markers: Marker[],
	map: google.maps.Map,
	polyLines?: google.maps.Polyline[],
	oldmarkers?: Marker[],
	mapMarkerOptions?: EventsMapMarkerOptions
) {
	//compare old markers are same as new markers, if not then zoom to fit, if no change
	//means user has zoomed in and it should not come to previous state.
	if (!oldmarkers) {
		const bounds = zoomToFit(markers);
		map.fitBounds(bounds);
	} else {
		const isFitBoundRequired = compare(markers, oldmarkers);
		if (!isFitBoundRequired) {
			const bounds = zoomToFit(markers);
			map.fitBounds(bounds);
		}
	}

	if (polyLines) {
		polyLines?.map((p) => {
			p.setMap(null);
		});
	}

	let markerGroup: any = [];
	let lastGrouping = 1;
	let lastValue: boolean;
	let skip = false;

	const enabledSignalId = mapMarkerOptions?.SignalMapOptions.filter(
		(s) => s.EnableSignal == true
	)[0]?.SignalId;

	/* Filter through markers to create groups of markers that represents polylines based on markers values */
	markers.map((m, i) => {
		skip = false;

		/* Handles First Point */
		if (i == 0) {
			markerGroup[lastGrouping] = [];
			m.color = m.color == undefined ? "#e0e0e0" : m.color;
			lastValue = m.value == undefined ? false : m.value;
			markerGroup[lastGrouping].push(m);
			skip = true;
		}

		if (!skip) {
			if (
				m.value != undefined &&
				enabledSignalId !== undefined &&
				m.signalId !== undefined &&
				enabledSignalId == m.signalId
			) {
				if (lastValue == m.value && markers[i - 1].color == undefined) {
					lastGrouping++;
					markerGroup[lastGrouping] = [];
					markerGroup[lastGrouping].push(m);
					lastValue = m.value == undefined ? false : m.value;
				} else if (lastValue == m.value) {
					markerGroup[lastGrouping].push(m);
					lastValue = m.value == undefined ? false : m.value;
				} else {
					lastGrouping++;
					markerGroup[lastGrouping] = [];
					markerGroup[lastGrouping].push(m);
					lastValue = m.value == undefined ? false : m.value;
				}
			} else {
				if (mapMarkerOptions?.ShowGPSPath) {
					markerGroup[lastGrouping].push(m);
				}
			}
		}
	});

	let polyLinePaths: google.maps.Polyline[] = [];
	let polyLinePath = new google.maps.Polyline({});
	markerGroup.map((m: any) => {
		polyLinePath = new google.maps.Polyline({
			path: m.map((s: any) => s.position),
			strokeColor: m[0].color,
			strokeWeight: 3,
			strokeOpacity: 1.0,
			editable: false,
			draggable: false,
			geodesic: true,
			visible: true,
			icons: !mapMarkerOptions?.ShowGPSMarker
				? [
						{
							icon: {
								path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
								scale: 3,
							},
							offset: "15px",
							repeat: "100px",
						},
				  ]
				: [],
		});

		polyLinePaths.push(polyLinePath);
		polyLinePath.setMap(map);
	});

	let oldMarkers = [...markers];
	return { polyLinePaths, oldMarkers };
}
