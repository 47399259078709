import Axios from "axios";
import { errorResponse } from "../../../../utils/apiHelpers/apiHelpers";
import { appConfigs } from "../../../../utils/configurations";

export function getReleaseNotes() {
    
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/get_tutorials"
        )
        .then(response => { 
                dispatch({ type: "RELEASENOTES_GET_SUCCESS", data: response.data.data.tutorials });
                console.log(response.data.data.tutorials);
            }
        )
        .catch(function (error) {
            console.log(`error`, error)
        });
    };
}


export function releasenotesSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "RELEASENOTES_SET_SEARCH", searchText: searchText });
    }
}  

export function setTab(
    tabNumber: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "SET_TAB", tabNumber: tabNumber });
    }
}   
