import { appConfigs } from "../../../utils/configurations";

interface cmnsData{
    id: string,
    value: any
}
let cmns: Array<cmnsData> = []
export const initialState = {
    dateFormatOptions: {
        year: 'numeric', 
        month: '2-digit', 
        day: 'numeric', 
        hour: "2-digit", 
        minute: "2-digit", 
        second: "2-digit" , 
        hour12: true
    },
    tabs: [
        {
            filters: null,
            appliedFilters: {
                timespanFrom: "",
                timespanTo: "",
                nickname: [],
                sa: [],
                spn: [],
                fmi: [],
                status: [],
                tags: []
            },
            tableData: [],
            tableConfig : { 
                pagination : {                  
                    showSizeChanger: appConfigs.tables.showSizeChanger,
                    showQuickJumper: appConfigs.tables.showQuickJumper,
                    pageSize: appConfigs.tables.pageSize,
                    defaultCurrent: appConfigs.tables.defaultCurrent,
                    current: appConfigs.tables.defaultCurrent,
                    total: appConfigs.tables.defaultTotal,
                    hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                    responsive: appConfigs.tables.responsive,
                    onChange: null,
                    showLessItems: appConfigs.tables.showLessItems,  
                    showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} alert${total > 1 ? 's' : ''}`
                }
            }
        },            
        {
            filters: null,
            appliedFilters: {
                timespanFrom: "",
                timespanTo: "",
                nickname: [],
                status: [],
                tags: []
            },
            tableData: [],
            tableConfig : { 
                pagination : {                  
                    showSizeChanger: appConfigs.tables.showSizeChanger,
                    showQuickJumper: appConfigs.tables.showQuickJumper,
                    pageSize: appConfigs.tables.pageSize,
                    defaultCurrent: appConfigs.tables.defaultCurrent,
                    current: appConfigs.tables.defaultCurrent,
                    total: appConfigs.tables.defaultTotal,
                    hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                    responsive: appConfigs.tables.responsive,
                    onChange: null,
                    showLessItems: appConfigs.tables.showLessItems,  
                    showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[2]} of ${total} alert${total > 2 ? 's' : ''}`
                }
            }
        }, 
        {
            filters: null,
            appliedFilters: {
                timespanFrom: "",
                timespanTo: "",
                nickname: [],
                command: [],
                operator: [],
                user: [],
                type: [],
                status: [],
                tags: []
            },
            tableData: [],
            tableConfig : { 
                pagination : {                  
                    showSizeChanger: appConfigs.tables.showSizeChanger,
                    showQuickJumper: appConfigs.tables.showQuickJumper,
                    pageSize: appConfigs.tables.pageSize,
                    defaultCurrent: appConfigs.tables.defaultCurrent,
                    current: appConfigs.tables.defaultCurrent,
                    total: appConfigs.tables.defaultTotal,
                    hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                    responsive: appConfigs.tables.responsive,
                    onChange: null,
                    showLessItems: appConfigs.tables.showLessItems,  
                    showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} alert${total > 1 ? 's' : ''}`
                }
            }
        }, 
        {
            tableData: [],
            tableConfig : { 
                pagination : {                  
                    showSizeChanger: appConfigs.tables.showSizeChanger,
                    showQuickJumper: appConfigs.tables.showQuickJumper,
                    pageSize: appConfigs.tables.pageSize,
                    defaultCurrent: appConfigs.tables.defaultCurrent,
                    current: appConfigs.tables.defaultCurrent,
                    total: appConfigs.tables.defaultTotal,
                    hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                    responsive: appConfigs.tables.responsive,
                    onChange: null,
                    showLessItems: appConfigs.tables.showLessItems,  
                    showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} alert${total > 1 ? 's' : ''}`
                }
            }
        } 
    ],
    dm1CumminsRecommendations: cmns
};
  