import React, { Component, useState, useEffect, useReducer, useMemo } from "react";
import { connect } from "react-redux";
import "./CommandWidget.css";
import { Form, Input, InputNumber, Switch, Button, Spin, message, Radio, Tooltip, Select } from 'antd';
import { FormInstance } from "antd/lib/form";
import { appConfigs } from "../../../../utils/configurations";
import * as Api from "../../api";
import { localizeUtcDate, hexToString} from "../../../../utils/commonHelpers";

import {
    reducer,
    bindActions,
    State,
    RemoteControl
  } from "../../state"; 

import { min } from "@amcharts/amcharts4/.internal/core/utils/Math";
import { options } from "@amcharts/amcharts4/core";
import { height } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import FormItem from "antd/lib/form/FormItem";

interface Prop {
    gatewayId: any,
    signaldata: any,
    state: State,
    signalRData: any, 
    context: any
}



function capitalizeFirstLetter(str: string) {
    if (!str) {
        return str;
    } else {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
       
  }

function formatNumber(n: number | string | boolean, type: string = ''): string | boolean {
    if (type.includes("latitude") || type.includes("longitude")) {
      return typeof n === "number" ? n.toFixed(10) : n;
    } else if (typeof n === "string" && (type.includes("IQAN Connect Key") ||
      type.includes("text"))) {
        var msg = hexToString(n);
        msg = msg.substring(1, msg.length-1);
        if (type.includes("text")) {
          if (msg.length <=9) {
            const lastFrameStartPos = msg.length -9;
            const delimiterPos = msg.indexOf("*", lastFrameStartPos);
            if (delimiterPos >=0) { // only applicable to singleframe, since multi-frame does not have delimiter,
                // therefore, multi-frame delimiterPos = -1
                msg = msg.substring(0, delimiterPos);
            } 
          } 
        }
        return msg;
    } else {
      return typeof n === "number" ? n.toFixed(2) : n;
    }
  }
  
let intialReportedData:RemoteControl={commandId:"", gatewayId:"", responseInfo:{}}
const { TextArea } = Input;
const CommandControl: React.FC<Prop> = (props) => {
    const [reportedValue, setReportedValue] = useState(intialReportedData);
    const [loading, isLoading] = useState(false);
    const [form]=Form.useForm();
    const [state,dispatch] = useReducer(
        reducer,
        props.state
      );
    const disptachMethods = useMemo(() => bindActions(dispatch), [dispatch]);
    const [fakeTest, setFakeTest] = React.useState(0);
    const onChangeFakeTest = (e:any) => {
        setFakeTest(e.target.value);
    };
    const orgCmdPgnSpns = state.asset.orgCmdPgnSpns;

    const msgsList = props.state.asset.c2dWhitelist;
   
    const [textAreaValue, setTextAreaValue] = useState('');

    const [selectedOption, setSelectedOption] = useState('');

    function handleTextAreaChange(event:any) {
        setTextAreaValue(event.target.value);
    }

    function handleOptionChange(value:any) {
        setSelectedOption(value.slice(1,-1));
        setTextAreaValue(value.slice(1,-1));
      }

    function handleButtonClick() {
        form.setFieldsValue({
            [props.signaldata.label]:  selectedOption
        });
        setTextAreaValue('');
      }

    useEffect(() => {
         if (Object.keys(props.signalRData.data).length > 0) {       
             Api.getCommandResponseSignalIds(state.asset.templateId, props.signaldata._id)
            .then((resp: any) => {
              if ( resp?.data?.data?.respSignals?.length > 0) {
                    reportedValue.responseInfo.id = resp.data.data.respSignals[0].responseSignalId;
                    reportedValue.responseInfo.name = resp.data.data.respSignals[0].responseSignalName;
                   
                    if (props.signalRData.data.hasOwnProperty(props.signaldata._id)){
                        reportedValue.responseInfo.reportedPayload = props.signalRData.data[reportedValue.responseInfo.id][0].value
                        reportedValue.responseInfo.reportedTime = new Date(props.signalRData.data[reportedValue.responseInfo.id][0].bt - 
                            props.signalRData.data[reportedValue.responseInfo.id][0].time)
                    } else {
                        Api.getCommandStatus(props.gatewayId,props.signaldata._id)
                        .then((data:any) => {
                           
                            disptachMethods.commandStatus(props.gatewayId,props.signaldata._id,data.data);
                            setReportedValue(state.asset.remoteControl.filter((data:any)=> data.gatewayId===props.gatewayId && data.commandId===props.signaldata._id)[0])
                        })
                        .catch((error:any) => {
                            message.error(error?.response?.data?.errors?.length > 0 ?
                                error.response.data.errors[0].message :
                                "Error in receiving the latest command response signal info")
                        });
                    }
                } 
              })
              .catch((error:any) => {
                message.error(error?.response?.data?.errors?.length > 0 ?
                    error?.response?.data?.errors[0].message :
                    "Error in receiving the latest command response signal info")
              });
     
          } else { 
          
            Api.getCommandStatus(props.gatewayId,props.signaldata._id)
            .then((data:any) => {
                disptachMethods.commandStatus(props.gatewayId,props.signaldata._id,data.data);
                setReportedValue(state.asset.remoteControl.filter((data:any)=> data.gatewayId===props.gatewayId && data.commandId===props.signaldata._id)[0])
            })
            .catch((error:any) => {
                message.error(error?.response?.data?.errors?.length > 0 ?
                    error?.response?.data?.errors[0].message :
                    "Error in receiving the latest command response signal info")
            });
       
          }
        }, [props,reportedValue]);

    const onFinish = (values: any) => {
        isLoading(true);
        if(props.signaldata.format === 'boolean' && values[props.signaldata.label] === undefined ){
            values[props.signaldata.label]=false;
        }
        if(values[props.signaldata.label] === false && props.signaldata.format === 'boolean' )
        {
            values[props.signaldata.label] = "0";
        }
        if(values[props.signaldata.label] === true && props.signaldata.format === 'boolean' )
        {
            values[props.signaldata.label] = "1";
        }
        const testMode = appConfigs.app.enableCommandSignalFakeTesting?(fakeTest || 0):0
        Api.invokeCommand(props.gatewayId,props.signaldata._id,values[props.signaldata.label],testMode)
        .then((data:any) => {
            message.success("Request message sent to gateway successfully");
          })
          .catch((error:any) => {
            message.error(error?.response?.data?.errors?.length > 0 ?
               error?.response?.data?.errors[0].message :
                "Gateway failed to receive the request message")
          });
        isLoading(false);
    };

    let respValue = reportedValue?.responseInfo?.requestId === null ? null: ( (props.signaldata.format === 'boolean') ?
         reportedValue.responseInfo.reportedPayload === 0 ?'OFF' :'ON'
         : formatNumber(reportedValue.responseInfo.reportedPayload , props.signaldata.format));

    let respTimeUtc = reportedValue?.responseInfo?.reportedTime;
    let dateFormatOptions =  {
        year: 'numeric', 
        month: '2-digit', 
        day: 'numeric', 
        hour: "2-digit", 
        minute: "2-digit", 
        second: "2-digit" , 
        hour12: true
    };
    let respTimeLocal = respTimeUtc === null? null: localizeUtcDate(respTimeUtc, dateFormatOptions);
    let allowedSingleFrameUsersDev = [
        '6eb1fbc0-0ffe-11eb-b4af-c7f0a7c1b411','70c429b0-91a7-11e8-af90-51d20134150b',
        '152bd120-309b-11ec-bc2d-bb7291a0ceba','e704a550-4e6b-11ea-8fc9-5f6f1264b2c7',
        'cad29b40-1d5f-11ea-abaa-a34583f0c422','96d03480-0431-11ee-9567-dfd4928f58e8',
        '7cb0a1a0-542f-11ed-b7bb-f305eb77c106','13131860-537d-11ee-a2ea-233ac79fb151',
        'ce3bb3a0-9619-11eb-87b2-239c76a096d7','054dda40-59ab-11eb-8ee0-bfaf65ef3261',
        '521a6300-67b3-11eb-a256-dba62bf2eb52' 
    ];
    let allowedSingleFrameUsersStag = [
        'a5c3cb80-2b37-11eb-ada0-d3b3466952a6','70c429b0-91a7-11e8-af90-51d20134150b',
        '8279a1a0-62fd-11ec-8f8f-53c130199102','e95b17a0-07ce-11ea-8c1e-29af9f5c9993',
        '9bb0b810-1d5f-11ea-b10c-611371d47cc6','ef77ae80-3819-11ee-b6e7-875b48ebb37d',
        '94a456c0-3819-11ee-a7c5-8b26a0381d3f','dba0aa40-5e6c-11ea-8269-67263d3b9573',
        'fee680c0-9619-11eb-9d03-1f19554fcd07','3bf339c0-599f-11eb-b6ef-0754ed297eb6'
    ];
    let allowedSingleFrameUsersProd = [
        '25c1ec40-2bab-11eb-9f2e-2fc8ff0a0080','70c429b0-91a7-11e8-af90-51d20134150b',
        '8a209140-d985-11eb-87da-f7bca1133f68','e95b17a0-07ce-11ea-8c1e-29af9f5c9993',
        '9bb0b810-1d5f-11ea-b10c-611371d47cc6','910a8d40-08fd-11ee-9e3e-ab5787211252',
        '69bfdee0-6af4-11ed-b1c9-df04de49673c', 'dba0aa40-5e6c-11ea-8269-67263d3b9573',
        'f0e97000-2f9f-11ee-a86c-135fbbccefd5', '1e9aa720-961a-11eb-bc08-ff40daac93b5',
        'fe97d7c0-2bae-11eb-b7a8-aba558bbe8c6'
    ];
   
    return(
        <>
    
        {orgCmdPgnSpns.includes((props.signaldata.pgn === null? '0' : props.signaldata.pgn) + '-' + 
                                 (props.signaldata.spn === null? '0' : props.signaldata.spn)) 
        &&
        <div className="rowdiv">
        <Form  form = {form}  name={ props.signaldata.label } layout="inline" onFinish={ onFinish }>
            <Form.Item name="commandId" style={{ display: 'none' }} >
                <Input type="hidden" />
            </Form.Item>
            {
            (props.signaldata.format === 'integer' || props.signaldata.format === 'float') &&
            <Form.Item label={ props.signaldata.label }   name = { props.signaldata.label } 
            rules={[
                { required: true, message: appConfigs.errors.fieldErrors.valueRequired },
                { min: parseInt(props.signaldata.min), type:"number", message: 'Minimum value allowed '+props.signaldata.min+'.' },
                { max: parseInt(props.signaldata.max), type:"number", message: 'Maximum value allowed '+props.signaldata.max+'.' },
            ]}>
                 <InputNumber style={{width:100}} />  
            </Form.Item>
            }
            {
            (props.signaldata.format === 'hex') &&
            <Form.Item label={ props.signaldata.label }   name = { props.signaldata.label } 
            rules={[
                { required: true, message: appConfigs.errors.fieldErrors.valueRequired },
                { min: parseInt(props.signaldata.min), type:"hex", message: 'Minimum value allowed '+parseInt(props.signaldata.min).toString(16)+'.' },
                { max: parseInt(props.signaldata.max), type:"hex", message: 'Maximum value allowed '+parseInt(props.signaldata.max).toString(16)+'.' },
            ]}>
                 <InputNumber style={{width:100}} />  
            </Form.Item>
            }
            {
             (props.signaldata.format === 'integer' || props.signaldata.format === 'float' || props.signaldata.format === 'hex') &&
             <Form.Item>
                  <div> { props.signaldata.unit } </div> 
             </Form.Item>
            }
            {
            (props.signaldata.format === 'boolean') &&
            <Form.Item name={ props.signaldata.label } label={ props.signaldata.label } valuePropName="checked">
                <Switch />
            </Form.Item>
            }

            {
            (props.signaldata.format === 'text') &&
            <Form.Item label={ props.signaldata.label}   name = { props.signaldata.label }
            rules={[
                { required: true, message: appConfigs.errors.fieldErrors.valueRequired },
                { min: parseInt(props.signaldata.min), message: 'Minimum length of the message is '+props.signaldata.min+'.' },
                { max: parseInt(props.signaldata.max), message: 'Maximum length of the message is '+props.signaldata.max+'.' },
            ]} >
               
               <Input.TextArea style={{ width: 300 }} rows={5} value={textAreaValue} onChange={handleTextAreaChange} />     
             </Form.Item>
            }
            {
            (props.signaldata.format === 'text') &&
            //  appConfigs.app.enableCommandSignalFakeTesting && // temporarily enable single-frame for dev and stage for testing
            // props.context.appContext.orgId === 'c6adcb40-be92-11e6-9ed6-a5bc9cb5279b' &&
            // (allowedSingleFrameUsersDev.includes(props.context.appContext.userId) ||
            //  allowedSingleFrameUsersStag.includes(props.context.appContext.userId) ||
            //  allowedSingleFrameUsersProd.includes(props.context.appContext.userId) ) &&
            <Form.Item label="Message list">
                <Select style={{ width: 300 }} onChange={handleOptionChange}>
                    {msgsList.map((option, index) => (
                    <Select.Option key={index} value={JSON.stringify(option)}>
                        {option}
                    </Select.Option>
                    ))}
                </Select>
                <Button onClick={handleButtonClick}>Add message</Button>
            </Form.Item>
            }

            { appConfigs.app.enableCommandSignalFakeTesting &&
            props.context.appContext.orgId == "c6adcb40-be92-11e6-9ed6-a5bc9cb5279b" && // enable faketesting only for Parker org
            <Form.Item label='Test Mode:'>
                <div className="TestMode">
                    <Radio.Group onChange={onChangeFakeTest} value={setFakeTest}>
                        <Radio value={1}>Success Test</Radio>
                        <Radio value={2}>Fail Test</Radio>
                        <Radio value={0}>Real Test</Radio>
                    </Radio.Group>
                </div>
            </Form.Item>
            }
            <Form.Item>
                <Spin size="small" spinning={loading} />
                {props.state.asset.connectionStatus !== "ONLINE" ?
                <Tooltip placement="bottomRight" title="Gateway Offline">
                <Button type="primary" htmlType="submit" disabled={true}>Send</Button>
                </Tooltip>
                :
                <Button type="primary" htmlType="submit" >Send</Button>
                }
            </Form.Item>
        </Form>
        </div>
        }

            { reportedValue && reportedValue.responseInfo && reportedValue.commandId!==null && reportedValue.commandId!==""&&
              orgCmdPgnSpns.includes(props.signaldata.pgn + '-' + props.signaldata.spn) &&
            <div className="responserowdiv">
            <Form layout="inline">
            { reportedValue && reportedValue.responseInfo && reportedValue.commandId!==null && reportedValue.commandId!==""&&
            <Form.Item label= "Reported Value:"  >
                <Tooltip placement="bottomRight" title={respTimeLocal}>
                    <div>  
                        <strong>{(respValue === null || respTimeLocal === null) ? "" : respValue} </strong>
                        <em style= {{color: "blue", fontSize: "70%" }} >
                            { (respValue === null || respTimeLocal === null ) ? "" : "           as of " + respTimeLocal
                            }
                        </em>
                    </div>
                </Tooltip>
                  
            </Form.Item>
            }

        </Form>
        </div>
            }
        </>
        
    ) 
}

const mapStateToProps = (state: any) => {
    return {
      context: state.contextReducer.context,
      signalRData: state.contextReducer.signalRData     
    };
  };

export default connect(
    mapStateToProps,
    // actions
  )(CommandControl);