import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Api from "./api";
import { Result, Tabs } from "antd";
import { errorResponse } from "../../utils/apiHelpers/apiHelpers";

import { Dashboard } from "./models";
import InitDashboard from "./InitDashboard";
import { connect } from "react-redux";

const { TabPane } = Tabs;


const ErrorPage: React.FC = () => (
  <Result
    status="error"
    title="Failed to Load Dashboard"
    subTitle="Unfortunately we couldn't fetch the data to make this page work. Try refreshing the page or contact support"
  />
);

interface Props {
  context: any
}




const DashboardPage: React.FC<Props> = ({context}) => {  
  const { assetId } = useParams();
  const mode = context.appContext.mode;
  const [initialData, setInitialData] = useState<Api.AssetDashboardResponse>();
  const [errorOccurred, setErrorOccurred] = useState(false);
  //const [tabs, setTabs] = useState<Dashboard[]>([]); 

  useEffect(() => {
    if (assetId && context.appContext) {
      Api.loadAssetDashboard(assetId, mode)
        .then((resp: Api.AssetDashboardResponse) => {
          setInitialData(resp);
          //setTabs(resp.asset.Dashboard)
        })
        .catch((error) => {
          errorResponse(null, error);
          setErrorOccurred(true)
        });
    }
  }, [assetId]);
 

  if (initialData) {    
    return (
      <InitDashboard initialData={initialData} />
    )    
  } else if (errorOccurred) {
    return <ErrorPage />;
  } else {
    return <></>;
  }
};

const mapStateToProps = (state: any) => {
  return {
    context: state.contextReducer.context    
  };
};

export default connect(mapStateToProps)(DashboardPage);
