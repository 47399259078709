import React from "react";
import { Form, Upload, Button, Modal, Tooltip,  message, notification, Select, Input } from 'antd';
import { ImportOutlined, UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';

import { connect } from "react-redux";
import * as actions from "../actions";

import { appConfigs } from "../../../utils/configurations";
import history from '../../../utils/history';
import { getToken } from "../../../utils/commonHelpers";

const { Option } = Select;

interface Prop {
    iqanFileUploadMaint: Function,
    getSignalCollections: Function,
    signalCollections: any,
    signalCollectionMaint: any
}

interface State {
    visible: boolean;
}

class IqanFileUpload extends React.Component<Prop, State> {  

    state:State = {
        visible: false
    }
    
    formRef = React.createRef<FormInstance>();

    getTemplateId() {
        const pathnameArr = history.location.pathname.split("/");
        return pathnameArr[pathnameArr.length - 1];
    }

    openModal = (e:any) => {   
        e.preventDefault();     
        
        this.setState({
            visible: true
        });

        const me = this;
        setTimeout(function() {
            if (me.formRef.current! !== null) {
                me.formRef.current!.setFieldsValue({
                    sourceName: "can0",
                    importMode: "append"
                });
            }
        }, 250);  
    };    

    cancelModal = (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        
        this.setState({
            visible: false
        });

        this.formRef.current!.resetFields();  

        let items:any = document.querySelectorAll(".ant-upload-list");    
        for (let i = items.length; i--;) {
            items[i].innerHTML = "";
        }
    };

    beforeUpload = (file:any) => {
        const fileType = file.type === "application/json";
        const fileSize = file.size / 1024 < 500;

        if (!fileType) {
            notification.error({
                message: "File Processing",
                description: "JSON file type is only allowed"
            });
        }
        if (!fileSize) {
            notification.error({
                message: "File Processing",
                description: "File must be smaller than 500kB"
            });
        }
        
        return fileType && fileSize;
    }

    onFinish = (values: any) => {    
        if (!this.formRef.current?.getFieldValue("uploadId")) {
            notification.error({
                message: "File Processing",
                description: "Please select a JSON file"
            });
        }
        else {
            this.props.iqanFileUploadMaint(this, this.onFinishSuccess, this.onFinishFailure, this.getTemplateId(), values);
        }
    };

    onFinishSuccess = (record: any) => {        
        this.props.getSignalCollections(this, null, null, this.getTemplateId(), 1, this.props.signalCollections.searchText);
        this.cancelModal();        
        message.success("IQAN Telematic Signal File has been successfully processed");        
    }

    onFinishFailure = (error: any) => { 
        let errorStatus = !error.response ? 999 : (error.response.status || 999);

        if (errorStatus === 404) {
            notification.error({
                message: "File Processing",
                description: "Uploaded file not found"
            });
        }
        else {
            let formErrors: any = [];

            if ((error.response.data.errors.length || 0) > 0) {
                error.response.data.errors.forEach(function (record: any) {
                    if (record.type === "form") {
                        formErrors.push(record.message);
                    }
            });
            }
            if (formErrors.length > 0) {
                formErrors.forEach((error: string) => {
                    notification.error({
                        message: "File Processing",
                        description: error
                    });
                });
            }
            else {
                notification.error({
                    message: "File Processing",
                    description: "File processing failed with a generic error message - Please try later"
                });
            }
        }
    }

    render() {
        let token=getToken();
        const me = this;
        const uploadProps = {
            name: "file",
            action: appConfigs.server.URL + "/ui/api/templates/" + this.getTemplateId() + "/iqan-telematic-upload",
            headers: {
                //authorization: `Bearer ${JSON.parse(window.localStorage.getItem("okta-token-storage") || "{}").idToken.value}`,
                authorization: `Bearer ${token.value||token.idToken}`,
            },
            onChange(info: any) {    
                if (info.file.status === "done") {
                    me.formRef.current!.setFieldsValue({
                        uploadId: info.file.response.data.iqanTelematicFile.uploadId
                    });
                } 
                else if (info.file.status === "error") {
                    message.error(`${info.file.name} file upload failed.`);
                }
            }
        };

        return (
            
            <div>
                <Tooltip title="Upload IQAN Telematic Signal File">            
                    <Button data-id="signal-collections-iqan-upload-button" href="# " onClick={(e) => {this.openModal(e)}} target="_new" type="default" icon={<ImportOutlined />}>
                        <span>Upload</span>
                    </Button>
                </Tooltip>

                <Modal
                    title="Upload IQAN Telematic Signal File"
                    visible={this.state.visible}
                    onCancel={this.cancelModal}
                    okButtonProps={{ hidden: true }}
                    footer={[
                        <Button key="cancel" onClick={this.cancelModal}>
                            {appConfigs.settings.drawers.cancelButtonText}
                        </Button>,
                        <Button type="primary" form="iqanFileUploadForm" key="submit" htmlType="submit">
                            {appConfigs.settings.form.submitButtonText}
                        </Button>
                    ]}
                >
                    <Upload 
                        {...uploadProps}                 
                        beforeUpload={this.beforeUpload}
                    >
                        <Button>
                            <UploadOutlined /> Select File to Upload
                        </Button>
                        <Tooltip placement="topLeft" title={appConfigs.messages.iqanTelematicSignalFileTooltip}>
                            <QuestionCircleOutlined style={{ marginLeft: '10px'}}/>
                        </Tooltip>
                    </Upload>

                    <Form
                        id="iqanFileUploadForm"
                        ref={this.formRef}
                        layout="vertical"
                        onFinish={this.onFinish}
                        className="mt-10"
                    >   
                        <Form.Item 
                            name="uploadId"
                            style={{ display: 'none' }}
                        >   
                            <Input type="hidden" />
                        </Form.Item>
                        <Form.Item
                            label="Source" 
                            name="sourceName"      
                            rules={[
                                { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                            ]}             
                        >
                            <Select
                                style={{ minWidth: 180}}
                            >
                                <Option key="can0" value="can0">can0</Option>
                                <Option key="can1" value="can1">can1</Option>
                            </Select> 
                        </Form.Item>
                    </Form>
                </Modal>
            </div>

        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        signalCollections: state.templateDetailsReducers.templateDetailsState.tabs[0],
        signalCollectionMaint: state.templateDetailsReducers.templateDetailsState.tabs[0].signalCollectionMaint
    };
};

export default connect(
    mapStateToProps,
    actions
)(IqanFileUpload);