import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Form, Button, Empty, message } from "antd";
import * as actions from "../actions";
import SignalTree from "./signalTree";
// import { useForm } from "antd/lib/form/util";
import history from "../../../utils/history";
import {
  createTreeStructure,
  createJsonForSignalMaint,
} from "./formatPinnedSignals";
import { appConfigs } from '../../../utils/configurations';
import "./pinnedSignals.css";


interface Props {
  context: any;
  activeTab: any;
  getTemplate: Function;
  pinnedSignals: any;
  getPinnedSignals: Function;
  savePinnedSignals: Function;
  iscreate: boolean;
  isread: boolean;
  isupdate: boolean;
  // isdelete: boolean;
  isGlobalTemplate: boolean;
  isadmin: boolean;
}

const PinnedSignals: React.FC<Props> = (props) => {
  //state variables
  const [form] = Form.useForm();
  const [maxSelected, setMaxSelected] = useState<number>(0);
  const {    
    getPinnedSignals,
    savePinnedSignals,
    pinnedSignals,
  } = props;
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [isSubmitVisible, setSubmitVisibility] = useState<boolean>(false);
  const [treeSignals, setTreeSignals] = useState([]);
  const [datapoints, setDatapoints] = useState<any>(); 


  const loadPreferedSignals = () => {    
    getPinnedSignals(null, null, getTemplateId(), "pinnedsignals");
  };

  const loadPreferedSignalsCallback = useCallback(loadPreferedSignals, []);

  const { activeTab, getTemplate } = props;

  const {isadmin, iscreate, isread, isupdate, isGlobalTemplate} = props;

  const getTemplateId = () => {
    const pathnameArr = history.location.pathname.split("/");
    return pathnameArr[pathnameArr.length - 1];
  }  
  
  //Load the updated signal collections.
  useEffect(() => {
    if(activeTab === "3") {
      getTemplate(null, getTemplateSuccess, null, getTemplateId());
    }    
  }, [activeTab]);

  const formatTreeData = (datapoints: any) => {
    let submitVisible = false;
    let signalCollection = JSON.parse(datapoints || []);
    //remove the Command collection.
    signalCollection = signalCollection.filter((s: any) => s.type !== "Command");
    const treeData = signalCollection.map(createTreeStructure);      
    return treeData;
  };


  const getTemplateSuccess = (response: any) => {    
    if(response.datapoints) {
      setDatapoints(response.datapoints);
      const treeData = formatTreeData(response.datapoints);
      setTreeSignals(treeData);
      if(treeData && treeData.length > 0) {
        const submitVisible = treeData.some((elem: any) => elem.children.length > 0);
        setSubmitVisibility(submitVisible);
      }
      loadPreferedSignalsCallback();
    }
  }

  const setTreePinnedSignals = (preferedSignals: any): string[] => {
    const treeSignals = pinnedSignals.preferedSignals.reduce((acc: any, curr: any) => {
      acc = acc.concat(curr.signalId);
      return acc;
    }, []);
    return treeSignals;
  }

  const setExpanededKey = (preferedSignals: any) => {
    let uniqueExpandKeys;
      const expanedKeys = pinnedSignals.preferedSignals.reduce(
        (acc: any, curr: any) => {
          acc = acc.concat(curr.signalCollectionId);
          return acc;
        },
        []
      );
      if (expanedKeys) {
        uniqueExpandKeys = expanedKeys.filter((elem: any, pos: any) => {
          return expanedKeys.indexOf(elem) === pos;
        });
      }
      return uniqueExpandKeys;
  }



  //Once the API response received, update the tree with new data and selection 
  useEffect(() => {
    let signals: string[] = [];
    if (pinnedSignals.preferedSignals) {
      signals = setTreePinnedSignals(pinnedSignals.preferedSignals);
      //Assign the expanded keys
      let uniqueExpandKeys = setExpanededKey(pinnedSignals.preferedSignals);     
      setExpandedKeys(uniqueExpandKeys);
    }
    form.setFieldsValue({ signals: signals });
  }, [pinnedSignals.preferedSignals]);


  const preferedSignalsMaint = (preferedSignalJson: any) => {
    savePinnedSignals(
      preferedSignalsMaintSuccess,
      null,
      getTemplateId(),
      "pinnedsignals",
      preferedSignalJson      
    );
  };

  const preferedSignalsMaintSuccess = (response: any) => {
    message.success('Signal selection saved successfully');
  }

  const onExpand = (expandedKeys: any) => {    
    setExpandedKeys(expandedKeys);
  };

  const saveSignalMaint = useCallback(preferedSignalsMaint, []);

  //save the signals into DB.
  const onFinish = (values: any) => {
    const preferedSignalJson = createJsonForSignalMaint(
      JSON.parse(datapoints),
      values
    );
    saveSignalMaint(preferedSignalJson);
  };


  //Maximum 6 signals can be selected, validate the same
  const checkValidations = (rule: any, value: any) => {
    const newValue = value.filter((v: any) =>
      JSON.parse(datapoints).every((d: any) => d._id !== v)
    );
    setMaxSelected(newValue.length);    
    if (!newValue || newValue.length > appConfigs.app.pinnedSignals.maxSignalsSelection) {
      return Promise.reject(`Maximum ${appConfigs.app.pinnedSignals.maxSignalsSelection} signals must be selected`);
    }
    return Promise.resolve();
  };

  const fomInitialValues = (): string[] => {
    let signals: string[] = [];
    if (pinnedSignals.preferedSignals) {
      signals = pinnedSignals.preferedSignals.reduce((acc: any, curr: any) => {
        acc = acc.concat(curr.signalId);
        return acc;
      }, []);
    }
    return signals;
  };


  if (treeSignals && treeSignals?.length > 0) {
    return (
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}        
        initialValues={{ signals: fomInitialValues() }}
      >
        <Form.Item
          label=""
          name="signals"
          rules={[{ validator: checkValidations }]}
        >
          <SignalTree
            treeData={treeSignals}
            expandedKeys={expandedKeys}
            onExpand={onExpand}
            isupdate={isupdate} iscreate={iscreate} isread={isread} isGlobalTemplate={isGlobalTemplate} isadmin={isadmin}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" 
          disabled={isGlobalTemplate && !isadmin || 
            !isGlobalTemplate && (!iscreate && !isupdate) ||
            maxSelected  >  appConfigs.app.pinnedSignals.maxSignalsSelection}  htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  } else {
    return <Empty />;
  }
};



const mapStateToProps = (state: any) => {
  return {
    context: state.contextReducer.context,
    pinnedSignals:
      state.templateDetailsReducers.templateDetailsState.tabs[2].pinnedSignals    
  };
};


export default connect(mapStateToProps, actions)(PinnedSignals);
