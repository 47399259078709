import React, { memo, useEffect, useState } from 'react'
import { Marker, MapActions } from "../../../../sharedComponents/GoogleMap";
import { CaretDownFilled, CaretLeftFilled, CaretRightFilled, CaretUpFilled, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Row, Switch, Tag } from 'antd';
import { EventsMapMarkerOptions } from './Widget';


interface Props {
    markers?: Marker[],
    filterMapOption: Function,
    mapActions: MapActions | undefined,
    mapMarkerOptions: EventsMapMarkerOptions
}

const mapOptionsBar = {
    backgroundColor: 'rgba(6, 27, 74, 0.85)',
    position: 'absolute',
    height: 'auto',
    top: '0',
    left: '0',
    zIndex: 2,
    width: '100%',
    color: '#fff',
    cursor: 'pointer',
    textAlign: 'center',
    MozUserSelect: 'none',
    khtmlUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
} as React.CSSProperties;

const mapOptionsOpen = {
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 2,
    color: '#000',
    textAlign: 'left',
    height: 'auto',
    width: '100%',
    marginTop: '20px',
    boxShadow: '0px 10px 10px #404040',
    WebkitTransition: 'max-height, 0.2s linear',
    MozTransition:  'max-height, 0.2s linear',
    msTransition:  'max-height, 0.2s linear',
    OTransition:  'max-height, 0.2s linear',
    transition: 'max-height, 0.2s linear',
} as React.CSSProperties;
  
const mapOptionsClose = {
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: 2,
    color: '#000',
    textAlign: 'left',
    maxHeight: '0',
    overflow: 'hidden',
    width: '100%',
    marginTop: '20px',
    boxShadow: '0px 10px 10px #404040',
    WebkitTransition: 'max-height, 0.2s linear',
    MozTransition:  'max-height, 0.2s linear',
    msTransition:  'max-height, 0.2s linear',
    OTransition:  'max-height, 0.2s linear',
    transition: 'max-height, 0.2s linear',
} as React.CSSProperties;




const mapStatsBar = {
    backgroundColor: 'rgba(130, 64, 123, 0.85)',
    position: 'absolute',
    bottom: '0',
    left: '0',
    zIndex: 1,
    width: 'auto',
    height: '85%',
    writingMode: 'vertical-lr',
    color: '#fff',
    cursor: 'pointer',
    textAlign: 'center',
    MozUserSelect: 'none',
    khtmlUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
} as React.CSSProperties;

const mapStatsOpen = {
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    position: 'absolute',
    bottom: '0',
    left: '0',
    zIndex: 1,
    color: '#000',
    textAlign: 'center',
    marginLeft: '20px',
    height: '85%',
    width: '350px',
    borderTopRightRadius: '10px',
    boxShadow: '10px 0px 10px #404040',
    WebkitTransition: 'width, 0.2s linear',
    MozTransition:  'width, 0.2s linear',
    msTransition:  'width, 0.2s linear',
    OTransition:  'width, 0.2s linear',
    transition: 'width, 0.2s linear',
} as React.CSSProperties;
  
const mapStatsClose = {
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    position: 'absolute',
    bottom: '0',
    left: '0',
    zIndex: 1,
    color: '#000',
    textAlign: 'center',
    marginLeft: '20px',
    overflow: 'hidden',
    height: '85%',
    width: '0px',
    borderTopRightRadius: '10px',
    boxShadow: '10px 0px 10px #404040',
    WebkitTransition: 'width, 0.2s linear',
    MozTransition:  'width, 0.2s linear',
    msTransition:  'width, 0.2s linear',
    OTransition:  'width, 0.2s linear',
    transition: 'width, 0.2s linear',
} as React.CSSProperties;
  
  
const MapMenuOptions: React.FC<Props> = (props) => {

    const { mapMarkerOptions } = props;

    const [markerOptions, setMarkerOptions] = useState<EventsMapMarkerOptions>(mapMarkerOptions);
    const [showMapOptions, setShowMapOptions] = useState<Boolean>(false)
    const [showMapStats, setShowMapStats] = useState<Boolean>(false)

    useEffect(() => {
        setMarkerOptions(mapMarkerOptions);
    }, [mapMarkerOptions])

    const toggleMapOptions = (mapShowState: Boolean) => {
        setShowMapOptions(mapShowState)
    }

    const toggleMapStats = (showMapStats: Boolean) => {
        setShowMapStats(showMapStats)
    }

    const filterMapOption = (status: boolean, field: any, signalId: any) => {
        if (signalId) {

            if (field == 'EnableSignal') {
                mapMarkerOptions.SignalMapOptions.map((signal, index) => {
                    mapMarkerOptions.SignalMapOptions[index].EnableSignal = false
                    mapMarkerOptions.SignalMapOptions[index].ShowSignalMarker = false
                })

                let foundIndex = mapMarkerOptions.SignalMapOptions.findIndex(x => x.SignalId == signalId);
                mapMarkerOptions.SignalMapOptions[foundIndex].EnableSignal = status;
                mapMarkerOptions.SignalMapOptions[foundIndex].ShowSignalMarker = status;
            }

            let foundIndex = mapMarkerOptions.SignalMapOptions.findIndex(x => x.SignalId == signalId);
            mapMarkerOptions.SignalMapOptions[foundIndex][field] = status;
            
        } else {
            mapMarkerOptions[field] = status
        }
        setMarkerOptions(mapMarkerOptions)        
        props.filterMapOption(mapMarkerOptions)
    }

    const showEventsStats = (signalId: string) => {

        mapMarkerOptions.SignalMapOptions.map((signal, index) => {
            mapMarkerOptions.SignalMapOptions[index].EnableSignal = false
            // mapMarkerOptions.SignalMapOptions[index].ShowSignalMarker = false
        })

        let foundIndex = mapMarkerOptions.SignalMapOptions.findIndex(x => x.SignalId == signalId);
        mapMarkerOptions.ShowGPSPath = true;
        mapMarkerOptions.SignalMapOptions[foundIndex].EnableSignal = true;
        mapMarkerOptions.SignalMapOptions[foundIndex].ShowSignalMarker = true;

        setMarkerOptions(mapMarkerOptions)        
        props.filterMapOption(mapMarkerOptions)

    }

    return (
        <>
            <div onClick={() => toggleMapOptions(!showMapOptions)} style={mapOptionsBar} >
                {
                    (showMapOptions) &&
                    <>
                        <CaretUpFilled />
                        <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>Map Options</span>
                        <CaretUpFilled />
                    </>
                }
                {
                    (!showMapOptions) &&
                    <>
                        <CaretDownFilled />
                        <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>Map Options</span>
                        <CaretDownFilled />
                    </>
                }
            </div>

            <div
                style={showMapOptions ? mapOptionsOpen : mapOptionsClose}
            >
                
                <Row style={{ padding: '10px' }}  justify="start" align="top" gutter={[4, 4]}>

                    <Col span={24}>
                        <Card title="General Options" bordered={true}>
                            <Card.Grid hoverable={false} style={{ width: '25%', textAlign: 'center' }}>
                                <Switch checked={markerOptions.ShowGPSPath} onChange={(checked) => { filterMapOption(checked, 'ShowGPSPath', false) }} />
                                <span style={{ marginLeft: '5px' }} >Show Tracking Path</span>
                            </Card.Grid>
                            <Card.Grid hoverable={false} style={{ width: '25%', textAlign: 'center' }}>
                                <Switch checked={  markerOptions.ShowGPSMarker } onChange={(checked) => { filterMapOption(checked, 'ShowGPSMarker', false) }} />
                                <span style={{ marginLeft: '5px' }} >Show GPS markers</span>
                            </Card.Grid>
                            <Card.Grid hoverable={false} style={{ width: '25%', textAlign: 'center' }}>
                                <Switch checked={  markerOptions.ShowStartEndPoint } onChange={(checked) => { filterMapOption(checked, 'ShowStartEndPoint', false) }} />
                                <span style={{ marginLeft: '5px' }} >Show Start/End Points</span>
                            </Card.Grid>
                            <Card.Grid hoverable={false} style={{ width: '25%', textAlign: 'center' }}>
                                <Switch checked={  markerOptions.HideClusteredPoints } onChange={(checked) => { filterMapOption(checked, 'HideClusteredPoints', false) }} />
                                <span style={{ marginLeft: '5px' }} >Hide Clustered Points</span>
                            </Card.Grid>
                        </Card>
                    </Col>

                    { markerOptions.SignalMapOptions.map((s) => {
                            
                        return (

                            <Col key={s.SignalId} /* flex='auto' */ span={ 24/markerOptions.SignalMapOptions.length }>
                                <Card title={ s.name } bordered={true}>
                                    <Switch checked={  s.EnableSignal } onChange={(checked) => { filterMapOption(checked, 'EnableSignal', s.SignalId)  }} />
                                    <span style={{ marginLeft: '5px' }} >View Path</span>
                                    <br />
                                    <Switch checked={ s.ShowSignalMarker } onChange={(checked) => { filterMapOption(checked, 'ShowSignalMarker', s.SignalId)  }} />
                                    <span style={{ marginLeft: '5px' }} >View Events</span>
                                    <hr />
                                    <span style={{ marginLeft: '5px' }} >Legends: [</span>
                                    <span style={{ marginLeft: '5px' }} >ON: </span>
                                    <span style={{ border: '1px solid black', minWidth: '10px', height: "10px", backgroundColor: s.color.split("$")[0] }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <span style={{ marginLeft: '5px' }} > | OFF: </span>
                                    <span style={{ border: '1px solid black', minWidth: '10px', height: "10px", backgroundColor: s.color.split("$")[1] }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <span style={{ marginLeft: '5px' }} >]</span>
                                </Card>
                            </Col>

                            )
                        })
                    
                    }

                </Row>
                
            </div>

            

            <div  onClick={() => toggleMapStats(!showMapStats)} style={mapStatsBar} >
                {
                    (showMapStats) &&
                    <div style={{ transform: 'rotate(180deg)' }}>
                        <CaretRightFilled />
                        <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>Hide Summary</span>
                        <CaretRightFilled />
                    </div>
                }
                {
                    (!showMapStats) &&
                    <div style={{ transform: 'rotate(180deg)' }}>
                        <CaretLeftFilled />
                        <span style={{ paddingRight: '5px', paddingLeft: '5px' }}>Show Summary</span>
                        <CaretLeftFilled />
                    </div>
                }
            </div>

            <div
                style={showMapStats ? mapStatsOpen : mapStatsClose}
            >
                <Row style={{ padding: '10px', textAlign: 'left' }} justify="space-around" align="top" gutter={[4, 4]}>

                    <Col span={24}>
                        <Card title="Events Summary" bordered={true}>
                            <Card.Grid hoverable={false} style={{ width: '100%', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}>
                                <span style={{ marginLeft: '5px' }} >Total Signals:</span>
                                <Tag style={{ float: 'right' }} color="blue">{ markerOptions.SignalMapOptions.length } Signals</Tag>
                            </Card.Grid>
                            <Card.Grid hoverable={false} style={{ width: '100%', textAlign: 'left', paddingTop: '15px', paddingBottom: '15px' }}>
                                <span style={{ marginLeft: '5px' }} >Total Data Points:</span>
                                <Tag style={{ float: 'right' }} color="blue">{props.markers?.length} Data Points</Tag>
                            </Card.Grid>
                        </Card>
                    </Col>

                </Row>

                <Row style={{ padding: '10px', textAlign: 'left', paddingTop: '0px' }} justify="space-around" align="top" gutter={[4, 4]}>

                    <Col span={24}>
                        <Card title="Legends" style={{ paddingTop: '0px' }} bordered={true}>

                            { markerOptions.SignalMapOptions.map((s, i) => {
                                
                                return (
                                    <Card.Grid key={i} hoverable={false} style={{ width: '100%', textAlign: 'left', paddingTop: '0px', paddingBottom: '10px' }}>
                                        <span style={{ marginLeft: '5px', fontSize: "12px" }} ><b>{ s.name }</b> </span><br />
                                        <span style={{ marginLeft: '5px' }} >[ON: </span>
                                        <span style={{ border: '1px solid black', minWidth: '10px', height: "10px", backgroundColor: s.color.split("$")[0] }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <span style={{ marginLeft: '5px' }} > | OFF: </span>
                                        <span style={{ border: '1px solid black', minWidth: '10px', height: "10px", backgroundColor: s.color.split("$")[1]}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <span style={{ marginLeft: '5px' }} >]</span>
                                        <span style={{ marginLeft: '5px', float: 'right' }} ><Tag style={{ cursor: 'pointer' }} onClick={() => { showEventsStats(s.SignalId) }} color="blue">{props.markers?.filter((m) => m.signalId == s.SignalId).length} Event(s)</Tag></span>
                                    </Card.Grid>
                                    )
                                })
                            
                            }
                            
                        </Card>
                    </Col>

                </Row>

            </div>
        </>
    )
}


export default memo(MapMenuOptions)