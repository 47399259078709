import React, { useState, useEffect } from "react";
import { Row, Col, Tooltip, Button, Space, Table, Input, Badge } from "antd";
import BackNavigation from "../../../utils/Components/BackNavigation";
import "./softwares.css";
import history from "../../../utils/history";
import { connect } from "react-redux";
import * as actions from "./actions";
import { PlusOutlined, DownloadOutlined } from "@ant-design/icons";
import SoftwareMaint from "./SoftwareMaint";
import { appConfigs } from "../../../utils/configurations";
import {getPermissions} from "../../../utils/commonHelpers";
const { Search } = Input;

interface Props {    
  softwaresSetSearch: Function;
  softwareSetPagination: Function;
  getSoftwares: Function;
  openDrawer: Function;
  getAllSoftwaresList: Function;
  getAllControllersList: Function;
  setTab: Function;
  context: any;
  softwares: any;
  permissions: any;
  softwaresModification: any;
  downloadPackage:Function;
  }
const Softwares: React.FC<Props> = (props) => 
  {  
    
    const [page, setPage] = useState(1);
    const [selectedFilters, setSelectedFilters] = useState<any>({});

    useEffect(() => {
           props.softwareSetPagination(onPaginationChange);
           props.getSoftwares(null,null,null, 1, '',null);
        }
    , []);

    const drawerWidth: number = Math.min(
      window.innerWidth,
      appConfigs.settings.drawers.defaultWidth
    );

    const { tableConfig, tableData } = props.softwares.softwaresList;

    const onPaginationChange = (page: number) => {

        props.getSoftwares(null,null,null, page, '',null);
    };

    const handleDownloadClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => 
    {
      const fileName = event.currentTarget.getAttribute('data-filename');
      const versionNumber = event.currentTarget.getAttribute('data-version');
      const orgid = event.currentTarget.getAttribute('data-orgid');
      const deviceModel = event.currentTarget.getAttribute('data-devicemodel');
          // eslint-disable-next-line no-template-curly-in-string
          const url = appConfigs.server.URL +`/ui/api/download-software-package?pathName=${fileName}&orgId=${orgid}&version=${versionNumber}&isCmnsota=false&devicemodel=${deviceModel}`;
          const fileType= fileName?.split('.').pop();
          props.downloadPackage(null, null, null, url, fileType , fileName);
  };

  const tableColumns = [
      {
        title: "Software Name",
        dataIndex: "name",
        render: (text: any, record: any) => (
          record.availableToSubOrgs ?
          <Tooltip title="Visible to suborg">                        
            <Badge dot offset={[5, 5]}> 
                {record.name} 
            </Badge>                        
          </Tooltip> : 
          <Tooltip title="Not visible to suborg">  
            {record.name}   
          </Tooltip>           
        )              
      },
     
      {
        title: "Version",
        dataIndex: "version",
      },
      {title: "Organization Name",
       dataIndex: "orgName",
       render: (text: any, record: any) => {
        let parents = record?.orgPathName?.includes("~") ? record?.orgPathName.split('~') : [record?.orgPathName];
        if (parents.length > 1) {
            // eslint-disable-next-line array-callback-return
            parents = parents.filter((parent: any) => {
                if (!parent.toLowerCase().includes("parker")) {
                    return parent
                }
            })
        }
        return (
            parents.length > 2 ?
            <>
                <span className="no-word-wrap">
                    <Tooltip overlayInnerStyle={{ width: '350px' }} title={
                        parents.map((parent: any, index: number) => {
                            return parents.length - 1 === index ? parent : parent + " \\ " 
                        })
                    }>
                        <span>{record.orgName}</span>
                    </Tooltip>
                </span>  
            </>
            : <span>{record.orgName}</span>
        )
    }
      },
       {
        title: "Device Model",
        dataIndex: "deviceModel",
      },
      {
        title: "Software Type",
        dataIndex: "softwareType",
      },
      {
        title: "System Package",
        dataIndex: "packageName",
      },
      {
        title: "Status",
        dataIndex: "status",
      },
      {
        title: "Description",
        dataIndex: "description",
      },   
    {
      title: "Download",
      align: "center" as "center",
      render: (text: string, record: any) => (
          <Space>
              {
                  <Tooltip title="Download Software">
                      <Button
                          type="default" 
                          icon={<DownloadOutlined />}
                          onClick={handleDownloadClick}
                          data-filename={record.packageName}
                          data-version={record.version}
                          data-orgid={record.orgId}
                          data-devicemodel={record.deviceModel}
                      />
                  </Tooltip>
              }                        
          </Space>
      )
  }
  
    ];

    const onSearch = (searchText: string) => {   
      props.softwaresSetSearch((searchText || ""));

      if((searchText || "")==="") {
          history.push("/admin/softwares");
      }
      
      setTimeout(function() {
          props.getSoftwares(null, null, null, 1, searchText, selectedFilters);   
      }, 100);      
  }

  const onSearchChange = (e: any) => {
      let value = e.target.value;
      props.softwaresSetSearch((value || ""));
  }
  
   const renderSoftwareTable = (pagination: any, filters: any, sorter: any) => {
      setSelectedFilters(filters);
        setPage(pagination.current);
  }

    return(
        <>
        <div className="layout-content">
            <Row gutter={8}>
              <Col xs={24} lg={14}>
                <div className="content-header page-title">
                  <div style={{ display: "flex" }}>
                    <Tooltip title="Go To Previous Page">
                      <BackNavigation />
                    </Tooltip>
                    {<span>Softwares Management</span>}
                  </div>
                </div>
              </Col>
            </Row>
          <Row justify="end" gutter={24}>
          <Col xs={24} lg={24} className="content-header-controls">
            <Space>
              <Search
                data-id="softwares-search-field"
                key="search-field"
                placeholder="Search By Name, version, Organization Name, Software Type, Device Model, System Package,	Status,	Description"

                enterButton="Search"
                size="middle"
                allowClear
                onSearch={onSearch}
                onChange={onSearchChange}
                className="search-field"
                value={props.softwares.softwaresList.searchText}
              />

              <Button
                data-id="softwares-search-new-button"
                type="primary"
                icon={<PlusOutlined />}
                onClick={(e) =>
                  props.openDrawer("New Software", drawerWidth, {})
                }
              >
                <span>Software</span>
              </Button>
            </Space>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Table 
                        data-id="softwares-data" 
                        className="mt-15"
                        style={{ overflowX: 'auto' }} 
                        {...tableConfig}
                        rowKey={(record:any) => record.gatewayId}
                        columns={tableColumns}
                        dataSource={tableData}
                        onChange={renderSoftwareTable}
                        showSorterTooltip={false}
                    />
            </Col>
            </Row>
            <SoftwareMaint />
        </div>
        </>
    );
};

const mapStateToProps = (state: any) => {
  return {
    permissions: state.contextReducer.data,
    context: state.contextReducer.context,
    softwares: state.softwaresReducers.softwaresState.softwares,
    softwaresModification:
      state.softwaresReducers.softwaresState.softwares.softwaresModification,
  };
};

export default connect(mapStateToProps, actions)(Softwares);
