import React, { CSSProperties, Component } from "react";
import { Space, Typography, Tooltip, Row, Col, Badge } from "antd";
import history from "../../../utils/history";
import { stringToUrlString } from "../../../utils/commonHelpers";
import { useAssetStatus } from "../useAssetStatus";
import AssetStatus from "../AssetStatus";
import Icon from "@ant-design/icons";
import { BarcodeOutlined, FileTextOutlined } from "@ant-design/icons";
import { ReactComponent as TractorIcon } from "../../../assets/images/tractor-black.svg";
import BackNavigation from "../../../utils/Components/BackNavigation";
import { PermissionProps } from "../models";
import { ReactComponent as OperatorIcon } from "../../../assets/images/operator.svg";
import { ReactComponent as OfflinePin } from "../../../assets/images/map-pins/offline-pin.svg";
import { ReactComponent as OnlinePin } from "../../../assets/images/map-pins/online-pin.svg";
import { ReactComponent as AssetDownPin } from "../../../assets/images/map-pins/asset_down.svg";
import { ReactComponent as Komatsu } from "../../../assets/images/komatsu-black.svg";
import Reset from "../../../assets/images/reset.png";


import { connect, useDispatch } from "react-redux";
import * as rootActions from "../../../actions";
import moment from "moment";
import tzlookup  from "tz-lookup";


const { Title } = Typography;

export interface HeaderProps {
  name: string;
  id: string;
  mastertag: string;
  templateId: string;
  templateName: string;
  alertDm1Count: number;
  alertInfoCount: number;
  alertWarningCount: number;
  alertCriticalCount: number;
  mac: string;
  operatorId: string;
  operatorIdValue: string;
  accessCardValue: string;
  operatorsHistoryId: string;
  connectionStatus: string;
  resetTimestamp: string;
  resetlocationLatitude: number;
  resetlocationLongitude: number;
  assetDownDesc: string;
}

type Props = HeaderProps & PermissionProps;

const DashboardHeader: React.FC<Props & {context: any}> = ({
  name,
  id,
  mastertag,
  templateName,
  templateId,
  mac,
  isRead,
  isUpdate,
  isAssetUpdate,
  isTemplatesRead,
  operatorId,
  operatorIdValue,
  accessCardValue,
  operatorsHistoryId,
  isOprRead,
  isOprUpdate,
  connectionStatus,
  resetTimestamp,
  resetlocationLatitude,
  resetlocationLongitude,
  assetDownDesc,
  context
}) => {
  const styles: CSSProperties = {
    fontSize: "14px",
    fontWeight: 400,
    verticalAlign: "text-bottom",
    marginRight: "10px",
  };

  const goToDetails = (e: any, templateName: string, templateId: string) => {
    e.preventDefault();
    history.push(
      "/template/details/" + stringToUrlString(templateName) + "/" + templateId
    );
  };

  const getTimezone=(lat:number, long:number) => {
      let location = tzlookup(lat, long);
      let zone=moment.tz(location).format('z');
    return zone;
  }

  const goToAsset = (e: any, mac?: string) => {
    e.preventDefault();
    history.push(
      "/assets" + ((mac || "") === "" ? "" : "/" + mac + "/details")
    );
  };
   {/* Commenting below code to get status from rediscache*/}
  // const assetStatus = useAssetStatus(id);
  const { Text } = Typography;
  const goToOperators = (e: any, id: string, operatorsHistoryId?: string,isOperator?:boolean) => {
    e.preventDefault();
    history.push({pathname:"/operators/" + stringToUrlString(id) + ((operatorsHistoryId || "") === "" ? "" : "/" + operatorsHistoryId),state:{isOperator:isOperator}});
  };

  return (
    <Row>
      <Col>        
        <div className="column">
          <Title style={styles} level={4}>
            <Tooltip title="Go To Previous Page">
              <div style={{ position: "relative", top: "6px", left: "5px" }}>
                <BackNavigation />
              </div>
            </Tooltip>
          </Title>
          {/* Commenting below code to get status from rediscache*/}
          {/* <Title style={styles} level={4}>
            <AssetStatus assetStatus={assetStatus} />
          </Title> */}
          <Title style={styles} level={4}>
          {
            (connectionStatus  === "ONLINE")  ? 
            <Tooltip title="Online">
            <OnlinePin style={{ verticalAlign: "middle" }} />
          </Tooltip> 
            :
            ((assetDownDesc === null || assetDownDesc === '')  ?
            <Tooltip title="Offline">
            <OfflinePin style={{ verticalAlign: "middle" }} />
          </Tooltip>
            :
            <Tooltip title= {"Asset Down : " + assetDownDesc} >
            <AssetDownPin style={{ verticalAlign: "middle" }} />
          </Tooltip>
            )
          }
          </Title> 
          <Title style={styles} level={4}>
            <Tooltip title="AssetName">
              <>
                { (context.appContext.orgId == '616c4c61-865d-11eb-871a-13cc80610287') ? (
                    <Icon
                      type="setting"
                      component={Komatsu}
                      style={{ verticalAlign: "middle", fontSize: "40px" }}
                    />
                  ): (
                    <Icon
                      type="setting"
                      component={TractorIcon}
                      style={{ verticalAlign: "middle", fontSize: "22px" }}
                    />
                  )
                }
              </>
            </Tooltip>{" "}
            {name}
          </Title>
          {isTemplatesRead ? (
            <Title style={styles} level={4}>
              <Tooltip title="Template">
                <FileTextOutlined
                  style={{ verticalAlign: "middle", fontSize: "22px" }}
                />
              </Tooltip>
              <a
                href="# "
                onClick={(e) => goToDetails(e, templateName, templateId)}
              >
                {templateName}
              </a>
            </Title>
          ) : (
            <Title style={styles} level={4}>
              <Tooltip title="Template">
                <FileTextOutlined
                  style={{ verticalAlign: "middle", fontSize: "22px" }}
                />
              </Tooltip>
              {templateName}
            </Title>
          )}
          {isAssetUpdate ? (
            <Title style={styles} level={4}>
              <Tooltip title="SerialNo">
                <BarcodeOutlined
                  style={{ verticalAlign: "middle", fontSize: "22px" }}
                />
              </Tooltip>
              <a href="# " onClick={(e) => goToAsset(e, mac)}>
                {" "}
                {mac}{" "}
              </a>
            </Title>
          ) : (
            <Title style={styles} level={4}>
              <Tooltip title="SerialNo">
                <BarcodeOutlined
                  style={{ verticalAlign: "middle", fontSize: "22px" }}
                />
              </Tooltip>
              {mac}
            </Title>
          )}
         {
                  (isOprRead && isOprUpdate) && (operatorIdValue || accessCardValue) ?
                          <Title style={styles} level={4}>
                              <Tooltip title={(operatorIdValue || "") === "" ? "RFID" : "OperatorID"}>
                                  <Icon type="setting" component={OperatorIcon} style={{ verticalAlign: "middle", fontSize: '22px' }} />
                              </Tooltip> {
                                  (operatorIdValue || "") === "" ?
                                      <a href="# " onClick={(e) => goToOperators(e, accessCardValue, operatorsHistoryId,false)}> {accessCardValue} </a>
                                      : <a href="# " onClick={(e) => goToOperators(e, operatorIdValue, operatorId,true)}> {operatorIdValue} </a>
                              }
                          </Title> : ""
          }
          {
            resetTimestamp && resetTimestamp !== null &&
            <Title style={styles} level={4}>
                <Tooltip title="PM Reset Date">
                <img src={Reset} alt="" style={{paddingRight: '5px', cursor: 'default'}}/>
                </Tooltip>
                    <Text>{moment.utc(resetTimestamp).local().format("MM/DD/YYYY")} ({getTimezone(resetlocationLatitude,resetlocationLongitude)})</Text>
            </Title>
          }
        </div>
        <div className="asset-header asset-header1">
          <Title style={styles} level={4}>
            <div style={{ position: "relative", top: "6px", left: "5px" }}>
              <Tooltip title="Go To Previous Page">
                <BackNavigation />
              </Tooltip>
            </div>
          </Title>
         {/* Commenting below code to get status from rediscache*/}
          {/* <Title style={styles} level={4}>
            <AssetStatus assetStatus={assetStatus} />
          </Title> */}
          <Title style={styles} level={4}>
          {(connectionStatus || "") === "ONLINE" ?
          <Tooltip title="Online">
          <OnlinePin style={{ verticalAlign: "middle" }} />
        </Tooltip> 
          : 
          <Tooltip title="Offline">
          <OfflinePin style={{ verticalAlign: "middle" }} />
        </Tooltip>
          }
          </Title> 
          <Title style={styles} level={4}>
            <Tooltip title="AssetName">
              <Icon
                type="setting"
                component={TractorIcon}
                style={{ verticalAlign: "middle", fontSize: "22px" }}
              />
            </Tooltip>{" "}
            {name}
          </Title>          
        </div>        
        <div className="asset-header">
          {isRead ? (
            <Title style={styles} level={4}>
              <Tooltip title="Template">
                <FileTextOutlined
                  style={{ verticalAlign: "middle", fontSize: "22px" }}
                />
              </Tooltip>
              <a
                href="# "
                onClick={(e) => goToDetails(e, templateName, templateId)}
              >
                {templateName}
              </a>
            </Title>
          ) : (
            <Title style={styles} level={4}>
              <Tooltip title="Template">
                <FileTextOutlined
                  style={{ verticalAlign: "middle", fontSize: "22px" }}
                />
              </Tooltip>
              {templateName}
            </Title>
          )}
          {isRead && isUpdate ? (
            <Title style={styles} level={4}>
              <Tooltip title="SerialNo">
                <BarcodeOutlined
                  style={{ verticalAlign: "middle", fontSize: "22px" }}
                />
              </Tooltip>
              <a href="# " onClick={(e) => goToAsset(e, mac)}>
                {" "}
                {mac}{" "}
              </a>
            </Title>
          ) : (
            <Title style={styles} level={4}>
              <Tooltip title="SerialNo">
                <BarcodeOutlined
                  style={{ verticalAlign: "middle", fontSize: "22px" }}
                />
              </Tooltip>
              {mac}
            </Title>
          )}
          {
                  (isOprRead && isOprUpdate) && (operatorIdValue || accessCardValue) ?
                          <Title style={styles} level={4}>
                              <Tooltip title={(operatorIdValue || "") === "" ? "RFID" : "OperatorID"}>
                                  <Icon type="setting" component={OperatorIcon} style={{ verticalAlign: "middle", fontSize: '22px' }} />
                              </Tooltip> {
                                  (operatorIdValue || "") === "" ?
                                      <a href="# " onClick={(e) => goToOperators(e, accessCardValue, operatorsHistoryId,false)}> {accessCardValue} </a>
                                      : <a href="# " onClick={(e) => goToOperators(e, operatorIdValue, operatorId,true)}> {operatorIdValue} </a>
                              }
                          </Title> : ""
          }
         {
            resetTimestamp && resetTimestamp !== null &&
            <Title style={styles} level={4}>
              <Tooltip title="PM Reset Date">
              <img src={Reset} alt="" style={{paddingRight: '5px', cursor: 'default'}}/>
              </Tooltip>
                  <Text>{moment.utc(resetTimestamp).local().format("MM/DD/YYYY")} ({getTimezone(resetlocationLatitude,resetlocationLongitude)})</Text>
            </Title>
          }
        </div>        
      </Col>
    </Row>
  );
};

const mapStateToProps = (state:any) => {
  return {
      context: state.contextReducer.context,
  };
};

export default connect(
  mapStateToProps,
  { 
      ...rootActions,
  }
)(DashboardHeader);


