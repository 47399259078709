import React from "react";
import "./leftSider.css";
import { Layout, Menu } from "antd";
import {
    SettingOutlined,
    CloudServerOutlined,
    FileOutlined,
    GlobalOutlined,
    BarChartOutlined,
    PlaySquareOutlined,
    FundProjectionScreenOutlined,
    CommentOutlined,
    FieldTimeOutlined,
    SnippetsOutlined,
    FormOutlined,
    PlayCircleOutlined,
    IdcardOutlined
} from "@ant-design/icons";
import Icon from "@ant-design/icons";
import { ReactComponent as MapIcon } from "../assets/images/map-pin.svg";
import { ReactComponent as BellIcon } from "../assets/images/bell.svg";
import { ReactComponent as UsersIcon } from "../assets/images/users.svg";
import { ReactComponent as TractorIcon } from "../assets/images/tractor.svg";
import { ReactComponent as OrganizationIcon } from "../assets/images/organization.svg";
import { ReactComponent as OperatorIcon } from "../assets/images/operator.svg";
import { ReactComponent as SupportIcon } from "../assets/images/support.svg";
import { ReactComponent as VideoTutorialIcon } from "../assets/images/video-tutorial.svg";
import { ReactComponent as AdminSettingsIcon } from "../assets/images/settings-admin.svg";
import { ReactComponent as ContractsIcon } from "../assets/images/contracts.svg";
import { ReactComponent as ControllerIcon } from "../assets/images/vehicle-icons/maintenance/035-motor.svg";
import { ReactComponent as AppsIcon } from "../assets/images/vehicle-icons/maintenance/tools-measures-service-mechanic-repair.svg";
import { ReactComponent as AIOTIcon } from "../assets/images/aiot.svg";
import { ReactComponent as Komatsu } from "../assets/images/komatsu.svg";
import { ReactComponent as AddDeviceIcon} from "../assets/images/add-device.svg";
import { ReactComponent as AddSoftwareIcon} from "../assets/images/add-software.svg";
import { ReactComponent as UpdateSoftwareIcon} from "../assets/images/update-software.svg";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import * as rootActions from "../actions";

import history from '../utils/history';
import { appConfigs } from "../utils/configurations";
import { getPermissions, getToken } from "../utils/commonHelpers";

const { Sider } = Layout;
const { SubMenu } = Menu;

interface Prop {
    context: any,
    orgReportAccess: any,
    supportMode: any,
    userOrgAccess: any,
    permissions: any
}

interface State {
    collapsed: boolean,
    selection: string
}

class LeftSider extends React.Component<Prop, State> {

    state = {
        collapsed: false,
        selection: ""
    };
        
    componentDidMount() {
        this.setState({
            selection: "/" + window.location.pathname.split("/")[1]
        });

        history.listen((event: any) => {
            if ((event.pathname || "") !== "") {
                const pathname = event.pathname.split("/");
                let finalPathname = pathname[1].toLowerCase();

                switch (finalPathname) {
                    case "template":
                        finalPathname = "templates";
                        break;
                    default:
                }

                if ("countries#templates#users#organizations#assets#alerts#map#refresh#not-found#operators#geo#roles#reportschedules#tutorials#controllers#admin#ota#videotutorials#iqanopen#releasenotes#userguide".indexOf(finalPathname) === -1) {
                    console.log("Not found 404:" + finalPathname)
                    history.push("/not-found")
                }

                this.setState({
                    selection: "/" + finalPathname
                });
            }
        });

    }

    onCollapse = (collapsed: boolean) => {
        this.setState({ collapsed });
    };

    goToReport=(selectedOrgId:any,supportmode:any)=>{
        let token = getToken();
        let reportURL = `${appConfigs.server.reportServerURL}?OrgId=${selectedOrgId}&supportmode=${supportmode}&token=${token.value||token.idToken}`;
        const win = window.open(reportURL, "_blank");
        win?.focus();
    }

    render() {

        const { user: contextUser, orgSettings, appContext, initialized: contextInitialized } = this.props.context;
        //Getting Users list for Left side bar menu access
        let contractsUsersList = this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'contracts')[0]?.usersList;
        let controllersUsersList = this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'controllers')[0]?.usersList;
        let aiotUsersList = this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'aiot')[0]?.usersList;
        let applicationsUsersList = this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'applications')[0]?.usersList;
        let contractsOrgsList= this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'contracts')[0]?.orgsList;
        let applicationsOrgsList= this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'applications')[0]?.orgsList;
        let aiotOrgsList= this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'aiot')[0]?.orgsList;
        let controllersOrgsList= this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'controllers')[0]?.orgsList;
        let iqanopenOrgsList= this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'iqanopen')[0]?.orgsList;
        let iqanopenUsersList = this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'iqanopen')[0]?.usersList;

        let orgReportAccess = this.props.orgReportAccess ? true : false;
        let isAdmin = this.props.context.user.contextUserIsAdmin;
        let isUserAdminToOrg = this.props.userOrgAccess ? true : false;
        //getting permissions for reports to enable reports menu for users.
        const reportPermissions = getPermissions(this.props.permissions.permissions.reports);
        const assetPermissions = getPermissions(this.props.permissions.permissions.assets);
        const userPermissions = getPermissions(this.props.permissions.permissions.users);
        const rolePermissions = getPermissions(this.props.permissions.permissions.role_settings);
        const templatePermissions = getPermissions(this.props.permissions.permissions.templates);
        const orgPermissions = getPermissions(this.props.permissions.permissions.org_options);
        const dm1Permissions = getPermissions(this.props.permissions.permissions.dm1_settings);
        const alertPermissions = getPermissions(this.props.permissions.permissions.alert_settings);
        const oprPermission = getPermissions(this.props.permissions.permissions.operator_support);
      
        //report access logic for barko_guest
        // 

        // let isBarko = false;
        // if(this.props.context.appContext.roleName.toLowerCase() === "barko_guest"){
        //     const barkoId = '17ad9f98-33df-48a0-8c4f-99468e22e129';
        //     for(let i=0; i<this.props.context.user.homeOrg.orgTree.length; i++){            
        //         if(this.props.context.user.homeOrg.orgTree[i].orgId === barkoId){
        //             isBarko = true;
        //         }
        //     }
        // }   

        //let reportAccess = ((isAdmin || reportPermissions[1]) && orgReportAccess && (isUserAdminToOrg || isBarko));
        
        // Reports can access by admins / users with report permission AND org should have access to reports
        let reportAccess = ((isAdmin || reportPermissions[1]) && orgReportAccess);
        let selectedOrgId  = this.props.context.appContext.orgId;

        //let oktaStorage = window.localStorage.getItem("okta-token-storage") || "";
        let supportModeString: string = window.sessionStorage.getItem("support-mode") || "{}";
        let supportmode = false;
        if (supportModeString !== "{}") {
            supportmode = true;
        }

        const href = window.location.href.toLowerCase();
        let showHelp = true;
        if (href.includes(appConfigs.server.claytonServerURL.toLowerCase()) ||
            href.includes(appConfigs.server.hoistServerURL.toLowerCase()) ||
            href.includes(appConfigs.server.toyotaServerURL.toLowerCase())) 
        {
            showHelp = false;
        } 

        // let token = getToken();
        // let reportURL = `${appConfigs.server.reportServerURL}?OrgId=${selectedOrgId}&supportmode=${supportmode}&token=${token.value||token.idToken}`;
        return (
            <div>
                <Sider
                    style={{ 
                        overflow: 'auto',
                        height: '100vh',
                        left: 0,
                    }}
                    breakpoint="md"
                    collapsedWidth="0"
                    collapsible
                    collapsed={this.state.collapsed}
                    onCollapse={this.onCollapse}
                >
                    {
                        this.props.context.orgSettings.logo &&
                        <Link to="/map">
                            <img src={appConfigs.server.URL + orgSettings.logo} className="div-organization-logo" alt="" />
                        </Link>
                    }
                    <div className="sider-content">
                        {
                            (contextInitialized || false) &&
                            <Menu
                                theme="dark"
                                selectedKeys={[
                                    this.state.selection
                                ]}
                                mode="inline"
                                id="topMenu"
                            >
                                <Menu.Item data-id="left-side-menu-map" key="/map" className="menuItem">
                                    <Link to="/map">
                                        <Icon type="setting" component={MapIcon} />
                                        Map
                                    </Link>
                                </Menu.Item>
                                {
                                    (isAdmin || alertPermissions[1]) &&
                                    <Menu.Item data-id="left-side-menu-alerts" key="/alerts" className="menuItem">
                                        <Link to={{ pathname: "/alerts" }} >
                                            <Icon type="setting" component={BellIcon} />
                                            Alerts
                                        </Link>
                                    </Menu.Item>
                                }
                                {
                                    (isAdmin || assetPermissions[1]) &&
                                    <Menu.Item data-id="left-side-menu-assets" key="/assets" className="menuItem">
                                        <Link to="/assets">
                                            {
                                                (this.props.context.appContext.orgId == '616c4c61-865d-11eb-871a-13cc80610287') ? (
                                                    <>
                                                        <Icon type="setting" component={Komatsu} style={{marginLeft: '-5px', verticalAlign: "middle", fontSize: "25px" }} />
                                                        <span style={{ marginLeft: '-9px' }}> Assets </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Icon type="setting" component={TractorIcon} />
                                                        Assets
                                                    </>
                                                )
                                            }
                                        </Link>
                                    </Menu.Item>
                                }
                                {
                                    (isAdmin) && (appConfigs.app.enableCumminsUI) &&
                                    (
                                        this.props.context.appContext.userId === 'ce3bb3a0-9619-11eb-87b2-239c76a096d7' || // muaz - dev
                                        this.props.context.appContext.userId === 'a9d07dc0-1257-11eb-949d-3f27d8f5ffc0' || // Will - dev
                                        this.props.context.appContext.userId === '150a5ea0-bf0b-11ed-b524-5f3c8839e6fa' || // Polash - dev
                                        this.props.context.appContext.userId === '6eb1fbc0-0ffe-11eb-b4af-c7f0a7c1b411' || // ying - dev
                                        this.props.context.appContext.userId === 'cad29b40-1d5f-11ea-abaa-a34583f0c422' || // Ravi Teja - Dev
                                        this.props.context.appContext.userId === '83cc2900-0b6d-11ee-b4fd-3306c58aa61b' || // Vanaja - dev
                                        this.props.context.appContext.userId === '152bd120-309b-11ec-bc2d-bb7291a0ceba' || // Raja - dev
                                        this.props.context.appContext.userId === 'ba085340-afa0-11ee-b3af-9f9a6f95957b' || // Rupa dev
                                        this.props.context.appContext.userId === '52f11900-5f65-11eb-9e05-ef71659b2f26' || // Sanath dev
                                        this.props.context.appContext.userId === '36a33e20-d65b-11ee-8c16-df16b4074be9' || // Satya - dev Cummins
                                        this.props.context.appContext.userId === '6554fa60-d65b-11ee-bd59-df284d80dc48' || // Abishek - dev Cummins
                                        this.props.context.appContext.userId === '054dda40-59ab-11eb-8ee0-bfaf65ef3261' ||  // msgiotdevuser18 - dev
                                        this.props.context.appContext.userId === '83c69fa0-5f65-11eb-8d03-678698c2e948' ||	// sanath stage
                                        this.props.context.appContext.userId === 'a676c7c0-f69e-11eb-9313-f316c04c8311' ||	// Ravi stage Parker 
                                        this.props.context.appContext.userId === '8279a1a0-62fd-11ec-8f8f-53c130199102' || 	// Raja hekhar tage 
                                        this.props.context.appContext.userId === 'fee680c0-9619-11eb-9d03-1f19554fcd07' || 	// Muaz stage
                                        this.props.context.appContext.userId === 'a5c3cb80-2b37-11eb-ada0-d3b3466952a6' || 	// Ying stage 
                                        this.props.context.appContext.userId === 'a1e98d00-00f5-11ef-a551-2ba2814d1b76' || // Will stage
                                        this.props.context.appContext.userId === '163372c0-00f6-11ef-a2d9-23ab13c6deef' || // Polash stage Polash
                                        this.props.context.appContext.userId === '5868cc00-dbc4-11ee-9edf-7b6889274e51' ||   // Rupa stage
                                        this.props.context.appContext.userId === '3bf339c0-599f-11eb-b6ef-0754ed297eb6' ||// msgiotdevuser18 stage
                                        this.props.context.appContext.userId === 'f1a89780-070a-11ef-9b78-6b2fd5b0ce17' || // Satya stage Cummins
                                        this.props.context.appContext.userId === 'dc197ce0-070a-11ef-b25e-43b0aa52711a' || // Abishek stage Cummins
                                        this.props.context.appContext.userId === 'fd45a120-111f-11ec-8780-b750def983cc' || // msgiotdevuser15 Satya stage Cummins temp
                                        this.props.context.appContext.userId === '6c76fee0-3bfb-11eb-b5b5-ab15846f8785' // msgiotdevuser18 Abishek stage Cummins temp
                                    ) && 
                                    <Menu.Item data-id="left-side-menu-csu" key="/ota" className="menuItem">
                                        <Link to="/ota">
                                            <Icon type="setting" component={TractorIcon} />
                                             CSU                                                
                                        </Link>
                                    </Menu.Item>
                                }
                                {
                                    (this.props.context.appContext.userId === '21ced2c0-3db9-11ed-9280-db679e967b72' ||
                                     this.props.context.appContext.userId === '5664e840-6694-11ed-b5d8-976680d8c775' || 
                                     this.props.context.appContext.userId === 'ce3bb3a0-9619-11eb-87b2-239c76a096d7' || //muaz
                                     this.props.context.appContext.userId === '1e9aa720-961a-11eb-bc08-ff40daac93b5' || 
                                     this.props.context.appContext.userId === '25c1ec40-2bab-11eb-9f2e-2fc8ff0a0080' || 
                                     this.props.context.appContext.userId === '6eb1fbc0-0ffe-11eb-b4af-c7f0a7c1b411' || // ying
                                     this.props.context.appContext.userId === '70c429b0-91a7-11e8-af90-51d20134150b' ||
                                     this.props.context.appContext.userId === '70c429b0-91a7-11e8-af90-51d20134150b' ||
                                     this.props.context.appContext.userId === '8d6bb5c0-1a34-11eb-a02f-2f84f79acdc7' ||
                                     this.props.context.appContext.userId === 'cad29b40-1d5f-11ea-abaa-a34583f0c422' || // Ravi Teja - Dev
                                     this.props.context.appContext.userId === 'afa49c60-e70b-11ec-bc58-63ef25306e1e' || // Ravi Teja                                     
                                     this.props.context.appContext.userId === 'dba0aa40-5e6c-11ea-8269-67263d3b9573' || // Marcel                                     
                                     this.props.context.appContext.userId === '83cc2900-0b6d-11ee-b4fd-3306c58aa61b' || // Vanaja - dev
                                     this.props.context.appContext.userId === '152bd120-309b-11ec-bc2d-bb7291a0ceba' || // Raja - dev
                                     this.props.context.appContext.userId === '70c429b0-91a7-11e8-af90-51d20134150b' || // Syed
                                     this.props.context.appContext.userId === '2fa250e0-931f-11ee-b300-63d17ef479fc' || // Lavanya K
                                     this.props.context.appContext.userId === 'da915c00-d3c9-11ed-92c5-b79e53de7502' || // Rupa prod
                                     this.props.context.appContext.userId === 'ba085340-afa0-11ee-b3af-9f9a6f95957b' || // Rupa dev
                                     this.props.context.appContext.userId === 'a44d5840-5f65-11eb-818b-7f8b88f38ec0' || // Sanath prod
                                     this.props.context.appContext.userId === '52f11900-5f65-11eb-9e05-ef71659b2f26'    // Sanath dev
                                     ) &&
                                    (
                                        controllersUsersList == null
                                ? false
                                : controllersUsersList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.userId)
                                    ) &&
                                    <Menu.Item data-id="left-side-menu-user-controllers" key="/controllers">
                                        <Link to="/controllers">
                                            <Icon type="controllers" component={ControllerIcon} />
                                            Controllers
                                        </Link>
                                    </Menu.Item>
                                }
                                {
                                    (isAdmin || orgPermissions[1]) &&
                                    <Menu.Item data-id="left-side-menu-organizations" key="/organizations" className="menuItem">
                                        <Link to="/organizations">
                                            <Icon type="setting" component={OrganizationIcon} />
                                            Organizations
                                        </Link>
                                    </Menu.Item>
                                }
                                {
                                    (isAdmin || userPermissions[1]) &&
                                    <Menu.Item data-id="left-side-menu-users" key="/users" className="menuItem">
                                        <Link to="/users">
                                            <Icon type="setting" component={UsersIcon} />
                                            Users
                                        </Link>
                                    </Menu.Item>
                                }
                                {

                                    (isAdmin || rolePermissions[1]) &&
                                    <Menu.Item data-id="left-side-menu-roles" key="/roles" className="menuItem">
                                        <Link to="/roles">
                                            <SettingOutlined />
                                            Roles
                                        </Link>
                                    </Menu.Item>
                                }
                                {
                                    (isAdmin || templatePermissions[1]) &&
                                    <Menu.Item data-id="left-side-menu-templates" key="/templates" className="menuItem">
                                        <Link to="/templates">
                                            <CloudServerOutlined />
                                            Templates
                                        </Link>
                                    </Menu.Item>
                                }
{/*
                                    
                                    OPS 1149

                                    contextUser.contextUserIsSuperUser &&
                                    (this.props.supportMode.orgId || "") === "" &&
                                    <SubMenu data-id="left-side-menu-admin" key="sub-admin" title="Admin" icon={<ToolOutlined />}>
                                        <Menu.Item data-id="left-side-menu-countries" key="/countries">
                                            <Link to="/countries">
                                                <GlobalOutlined />
                                                Countries
                                            </Link>
                                        </Menu.Item>
                                    </SubMenu>
                                */}                      
                                
                                                                {reportAccess && appConfigs.app.enableSchedule &&
                                    <SubMenu title="Reports" key="/reports" icon={<BarChartOutlined />} className="menuItem">
                                        <Menu.Item data-id="left-side-menu-reports" key="/report" className="menuItem">
                                        <Link to={{pathname:history.location.pathname}} onClick={(e)=>this.goToReport(selectedOrgId,supportmode)}>
                                                <FundProjectionScreenOutlined />
                                                Portal
                                        </Link>
                                        </Menu.Item>
                                        <Menu.Item data-id="left-side-menu-report-schedules" key="/reportschedules" className="menuItem">
                                            <Link to="/reportschedules">
                                                <FieldTimeOutlined />
                                            Schedules
                                        </Link>
                                        </Menu.Item>
                                    </SubMenu >
                                }

                                {(reportAccess && !appConfigs.app.enableSchedule)?
                                    <Menu.Item data-id="left-side-menu-reports" key="/reports" className="menuItem">
                                    <Link to={{pathname:history.location.pathname}} onClick={(e)=>this.goToReport(selectedOrgId,supportmode)}>                                 
                                        <BarChartOutlined />
                                        Reports
                                    </Link>
                                </Menu.Item> : <> </>
                                }
                                
                                {

                                    (isAdmin || oprPermission[1]) &&
                                    <Menu.Item data-id="left-side-menu-operators" key="/operators" className="menuItem">
                                        <Link to="/operators">
                                            <Icon type="setting" component={OperatorIcon} />
                                            
                                        Operators
                                    </Link>
                                    </Menu.Item>
                                }
                                {
                                    (iqanopenOrgsList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.orgId))
                                                &&
                                                (
                                                    iqanopenUsersList == null
                                            ? false
                                            : iqanopenUsersList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.userId)
                                                ) &&
                                        
                                        <SubMenu title="Software Update Manager" key="/iqanopen" icon={<Icon type="support" component={AdminSettingsIcon} />} className="subMenuItem">
                                             {(
                                                this.props.context.appContext.userId === 'cad29b40-1d5f-11ea-abaa-a34583f0c422' || // Ravi Teja - Dev
                                                this.props.context.appContext.userId === 'afa49c60-e70b-11ec-bc58-63ef25306e1e' || // Ravi Teja                                                                         
                                                this.props.context.appContext.userId === '83cc2900-0b6d-11ee-b4fd-3306c58aa61b' || // Vanaja - dev
                                                this.props.context.appContext.userId === 'a44d5840-5f65-11eb-818b-7f8b88f38ec0' || // Sanath prod
                                                this.props.context.appContext.userId === '52f11900-5f65-11eb-9e05-ef71659b2f26' ||   // Sanath dev
                                                this.props.context.appContext.userId === '65975860-5276-11ee-8357-cfdf235dea49' ||   // Josh dev
                                                this.props.context.appContext.userId === '946ffc80-645c-11ee-a54d-3f1b9e8fb7b0'    // Josh prod
                                                ) && 
                                                <Menu.Item data-id="left-side-menu-admin-controllers" key="/Iqanopen/controllers">
                                                    <Link to="/Iqanopen/controllers">
                                                        <Icon type="add-devices" component={AddDeviceIcon} />
                                                        Add Device
                                                    </Link>
                                                </Menu.Item>}
                                        
                                            {

                                            (iqanopenOrgsList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.orgId))
                                            &&
                                            (
                                                iqanopenUsersList == null
                                            ? false
                                            : iqanopenUsersList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.userId)
                                            ) &&
                                                <Menu.Item data-id="left-side-menu-admin-controllers" key="/Iqanopen/Softwares">
                                                    <Link to="/Iqanopen/Softwares">
                                                        <Icon type="add-software" component={AddSoftwareIcon} />
                                                        Add Software
                                                    </Link>
                                                </Menu.Item>
                                            }
                                            {
                                            (iqanopenOrgsList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.orgId))
                                            &&
                                            (
                                                iqanopenUsersList == null
                                                ? false
                                                : iqanopenUsersList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.userId)
                                                    ) &&
                                                    <Menu.Item data-id="left-side-menu-admin-applications" key="/Iqanopen/UpdateDeviceSoftware">
                                                        <Link to="/Iqanopen/UpdateDeviceSoftware">
                                                            <Icon type="updatesoftware" component={UpdateSoftwareIcon} />
                                                            Update Device Software
                                                        </Link>
                                                    </Menu.Item>
                                            }          
                                        </SubMenu >
                                    } 
                                {
                                    (contractsOrgsList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.orgId))
                                    &&
                                        (contractsUsersList == null
                                            ? false
                                            : contractsUsersList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.userId)) &&
                                        
                                        <SubMenu title="Admin" key="/admin" icon={<IdcardOutlined />} className="subMenuItem">
                                            
                                            <Menu.Item data-id="left-side-menu-admin" key="/contracts" className="menuItem">
                                                <Link to="/admin/contracts">
                                                    <Icon type="contracts" component={ContractsIcon} />
                                                    Contracts
                                                </Link>
                                            </Menu.Item>
                                        
                                            {
                                                (controllersOrgsList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.orgId))
                                                &&
                                                (
                                                    controllersUsersList == null
                                            ? false
                                            : controllersUsersList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.userId)
                                                ) &&
                                                <Menu.Item data-id="left-side-menu-admin-controllers" key="/admin/controllers">
                                                    <Link to="/admin/controllers">
                                                        <Icon type="admin-controllers" component={ControllerIcon} />
                                                        Controllers
                                                    </Link>
                                                </Menu.Item>
                                            }
                                            {
                                                (applicationsOrgsList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.orgId))
                                                &&
                                                (
                                                    applicationsUsersList == null
                                            ? false
                                            : applicationsUsersList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.userId)
                                                ) &&
                                                    <Menu.Item data-id="left-side-menu-admin-applications" key="/applications">
                                                        <Link to="/admin/applications">
                                                            <Icon type="Applications" component={AppsIcon} />
                                                            Applications
                                                        </Link>
                                                    </Menu.Item>
                                            }
                                            {
                                                (
                                                    this.props.context.user.contextUserIsAdmin && 
                                                    (aiotOrgsList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.orgId)) 
                                                    && (
                                                        aiotUsersList == null
                                                ? false
                                                : aiotUsersList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.userId)
                                                    )
                                                ) &&
                                                    <Menu.Item data-id="left-side-menu-admin-aiot" key="/aiot">
                                                        <Link to="/admin/aiot">
                                                            <Icon type="AIOTIcon" component={AIOTIcon} />
                                                            AIOT
                                                        </Link>
                                                    </Menu.Item>
                                            }
                                                        
                                        </SubMenu >
                                }

                                {
                                showHelp &&
                                <SubMenu title="Help" key="/help" icon={<Icon type="support" component={SupportIcon} />} className="subMenuItem">
                                            {/* <Menu.Item data-id="left-side-menu-tutorials" key="/tutorials">
                                                <Link to="/tutorials">
                                                    <Icon type="tutorial" component={VideoTutorialIcon} />
                                                    Tutorials
                                                </Link>
                                            </Menu.Item> */}
                                                <Menu.Item data-id="left-side-menu-tutorials" key="/releasenotes">
                                                <Link to="/releasenotes"> 
                                                <SnippetsOutlined/> 
                                                    Release Notes
                                                </Link>
                                                </Menu.Item>

                                                <Menu.Item data-id="left-side-menu-tutorials" key="/videotutorials">
                                                <Link to="/videotutorials">
                                                <PlayCircleOutlined />
                                                    Tutorial Videos
                                                </Link>
                                                </Menu.Item>

                                                <Menu.Item data-id="left-side-menu-tutorials" key="/userguide">
                                                <Link to="/userguide">
                                                <FormOutlined />
                                                    User Guide
                                                </Link>
                                                </Menu.Item>

                                            {/* <Menu.Item data-id="left-side-menu-reports" key="/report">
                                            <Link to={{pathname:history.location.pathname}} >
                                                <CommentOutlined />
                                                Support
                                            </Link>
                                            </Menu.Item> */}

                                    {/* <Menu.Item data-id="left-side-menu-reports" key="/report">
                                    <Link to={{pathname:history.location.pathname}} >
                                        <CommentOutlined />
                                        Support
                                    </Link>
                                    </Menu.Item> */}
                                </SubMenu >
                            }

                            </Menu>
                        } 
                    </div>
                </Sider>
            </div>
        );
    }
}


const mapStateToProps = (state: any) => {
    return {
        context: state.contextReducer.context,
        permissions: state.contextReducer.data,
        supportMode: state.supportModeReducer,
        orgReportAccess: state.reportReducer.orgAccess,
        userOrgAccess: state.userOrgAccessReducer.data.userOrgAccess
    };
};

export default connect(
    mapStateToProps,
    {
        ...rootActions
    }
)(LeftSider);
