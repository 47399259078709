import {
  XYChart,
  ColumnSeries,
  ValueAxis,
  CategoryAxis,
  XYCursor,
} from "@amcharts/amcharts4/charts";
import * as amCore from "@amcharts/amcharts4/core";

import { AxisRange } from "../models";
import {
  DATE_FORMAT,
  TOOLTIP_FORMAT,
  NUMBER_FORMAT,
} from "../common";

export interface Signal {
  name: string
  timestamp: Date
  value: number
}

export interface Options {
  axisRange?: AxisRange;
}

export default class BarChart {
  private _chart: XYChart;

  constructor(ref: HTMLDivElement, signals: Signal[], opts?: Options) {
    const chart = (this._chart = amCore.create(ref, XYChart));
    const cursor = (this._chart.cursor = new XYCursor());
    cursor.lineX.disabled = true;
    cursor.lineY.disabled = true;

    const xAxis = new CategoryAxis();
    xAxis.dataFields.category = "name";
    xAxis.cursorTooltipEnabled = false;
    chart.xAxes.push(xAxis);

    const yAxis = new ValueAxis();
    yAxis.cursorTooltipEnabled = false;
    yAxis.min = opts?.axisRange?.min;
    yAxis.max = opts?.axisRange?.max;
    chart.yAxes.push(yAxis);

    const series = new ColumnSeries();
    series.dataFields = {
      categoryX: "name",
      valueY: "value",
      dateX: "timestamp",
    };
    series.tooltipText = TOOLTIP_FORMAT;
    if(series.tooltip) {
      series.tooltip.pointerOrientation = "vertical";
    }  
    

    chart.series.push(series);    
    chart.dateFormatter.dateFormat = DATE_FORMAT;
    chart.numberFormatter.numberFormat = NUMBER_FORMAT;
    chart.data = signals
  }

  public destroy() {
    this?._chart.dispose();
  }
}
