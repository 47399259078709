import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function alertsState(state = initialState, action:any) {
    let data:any = null;

    switch (action.type) {    
        
        case "ALERTS_DM1_SET_PAGINATION":
            return immerProduce(state, draft => {
                draft.tabs[0].tableConfig.pagination.onChange = action.onPaginationChange;
            });    
        case "ALERTS_DM1_GET_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.tabs[0].tableData = data;
                draft.tabs[0].tableConfig.pagination.total = (data.length || 0) > 0 ? data[0].totalRecords : 0;
                draft.tabs[0].tableConfig.pagination.current = action.payload.page;
                draft.tabs[0].appliedFilters = action.payload.appliedFilters;
            });
        case "ALERTS_DM1_FILTERS_GET_SUCCESS":
            return immerProduce(state, draft => {
                draft.tabs[0].filters = action.data;
            });    
        case "ALERTS_SIGNALS_FILTERS_GET_SUCCESS":
            return immerProduce(state, draft => {
                draft.tabs[1].filters = action.data;
            });        
        case "ALERTS_SIGNALS_SET_PAGINATION":
            return immerProduce(state, draft => {
                draft.tabs[1].tableConfig.pagination.onChange = action.onPaginationChange;
            });        
        case "ALERTS_SIGNALS_GET_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.tabs[1].tableData = data;
                draft.tabs[1].tableConfig.pagination.total = (data.length || 0) > 0 ? data[0].totalRecords : 0;
                draft.tabs[1].tableConfig.pagination.current = action.payload.page;
                draft.tabs[1].appliedFilters = action.payload.appliedFilters;
            });
        case "ALERTS_CMD_HISTORY_SET_PAGINATION":
            return immerProduce(state, draft => {
                draft.tabs[2].tableConfig.pagination.onChange = action.onPaginationChange;
            });  
        case "ALERTS_CMD_HISTORY_GET_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.tabs[2].tableData = data;
                draft.tabs[2].tableConfig.pagination.total = (data.length || 0) > 0 ? data[0].totalRecords : 0;
                draft.tabs[2].tableConfig.pagination.current = action.payload.page;
                draft.tabs[2].appliedFilters = action.payload.appliedFilters;
            });
        case "ALERTS_CMD_HISTORY_FILTERS_GET_SUCCESS":
            return immerProduce(state, draft => {
                draft.tabs[2].filters = action.data;
            });
        case "ACTIVITY_GET_ALERTS_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.tabs[3].tableData = data;
                draft.tabs[3].tableConfig.pagination.total = (data.length || 0) > 0 ? data[0].totalRecords : 0;
                draft.tabs[3].tableConfig.pagination.current = action.payload.page;
            });
        case "ACTIVITY_ALERTS_SET_PAGINATION":
            return immerProduce(state, draft => {
                draft.tabs[3].tableConfig.pagination.onChange = action.onPaginationChange;
            })
        default:
            return state;
    }
    
}