import React, { useContext, useState, useEffect, useRef } from "react";
import { Row, Col, Tooltip, Tabs, Button, Space, Table, Input, Popconfirm, Form, Select, message, DatePicker, Card, Popover, Modal, Divider } from "antd";
import { FormInstance } from 'antd/lib/form';
import BackNavigation from "../../utils/Components/BackNavigation";
import { successResponse, errorResponse, resetFormErrors } from "../../utils/apiHelpers/apiHelpers";
import history from '../../utils/history';
import { connect } from "react-redux";
import * as actions from "./Contracts/actions";
import { InfoOutlined } from "@ant-design/icons";
import moment from 'moment';
import { appConfigs } from "../../utils/configurations";
import { checkObjectKeyValuesNotEmpty } from "../../utils/commonHelpers";
import { height } from "@amcharts/amcharts4/.internal/core/utils/Utils";

const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;

const { TabPane } = Tabs;


interface Props{
    getAllAssetsList: Function,
    context: any,
    permissions: any,
}

const AIOT: React.FC<Props> = (props) => {

    useEffect(() => {
        // console.log("");
    }, [])

    return (
        <div style={{height: "100%"}}>
            <Row style={{height: "100%"}} gutter={8}>
                <Col style={{height: "100%"}} xs={24} lg={24}>
                    <iframe style={{height: "100%"}} height="100%" width="100%" src="https://cognition-demo.camgian.com/" />
                </Col>
            </Row>           
        </div>
    );

}

const mapStateToProps = (state:any) => {
    return {
        permissions: state.contextReducer.data,
        context: state.contextReducer.context,
    };
};

export default connect(
    mapStateToProps,
    actions
)(AIOT);
