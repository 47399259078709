import { DashboardPermission, PermissionApiReq } from ".";

const formatDatasource = (
  binaryResult: number[],
  dashboardId: number,
  dashboardTitle: string,
  permissionId: number
) => {
  const permissionArray: string[] = ["Create", "View", "Update", "Delete"];
  let datasource = binaryResult.reduce((acc: any, elem: number, i: number) => {
    acc = { ...acc, dashboardId, dashboardTitle, [permissionArray[i]]: elem };
    return acc;
  }, {});
  datasource = { ...datasource, permissionId: permissionId };
  return datasource;
};

export const constructPermissions = (
  givenPermission: number,
  allowedPermission: number,
  dashboardId: number,
  dashboardTitle: string,
  permissionId: number
): any => {
  let maxBinaryResult: number[] = [];
  const binaryResult = Array.from(givenPermission.toString(2).padStart(4, '0'), Number).reverse();
  // while (allowedPermission >= 1) {
  //   maxBinaryResult.unshift(Math.floor(allowedPermission % 2));
  //   allowedPermission = allowedPermission / 2;
  // }

  // let binaryResult = new Array<number>(maxBinaryResult.length).fill(0);

  // while (givenPermission >= 1) {
  //   binaryResult.unshift(Math.floor(givenPermission % 2));
  //   binaryResult.pop();
  //   givenPermission = givenPermission / 2;
  // }

  return {
    dashboardPermissions: { permissionId, permissions: binaryResult },
    datasource: formatDatasource(
      binaryResult,
      dashboardId,
      dashboardTitle,
      permissionId
    ),
  };
};

export const formatPermissions = (resp: any) => {
  const permissionResult = resp.reduce(
    (acc: any, elem: any, i: any) => {
      const { dashboardPermissions, datasource } = constructPermissions(
        elem.permission_level,
        15,
        elem.dashboardId,
        elem.dashboardTitle,
        elem.permissionId
      );
      acc = {
        ...acc,
        dashboardPermissions: [
          ...acc.dashboardPermissions,
          dashboardPermissions,
        ],
        datasource: [...acc.datasource, datasource],
      };
      return acc;
    },
    { dashboardPermissions: [], datasource: [] }
  );

  return permissionResult;
};

export function constructPermissionToSave(
  newPermissionSet: DashboardPermission[]
): PermissionApiReq[] {
  const apiReq = newPermissionSet.reduce(
    (acc: PermissionApiReq[], elem: DashboardPermission) => {
      const binaryString = elem.permissions.reduceRight(
        (ac: string, el: number) => {
          return (ac = `${ac}${el}`);
        },
        ""
      );
      const allowedPermission = parseInt(binaryString, 2);
      const permissionApiReq: PermissionApiReq = {
        permissionId: elem.permissionId,
        permission_level: allowedPermission,
      };
      acc = [...acc, permissionApiReq];
      return acc;
    },
    []
  );
  return apiReq;
}
