import React, { Component } from "react";
import { Typography, Breadcrumb, Skeleton, Tabs, Tooltip, Button} from "antd";
import { CaretLeftOutlined } from '@ant-design/icons';

import { appConfigs } from "../../utils/configurations";
import history from '../../utils/history';

import SignalCollections from "./signalCollections/SignalCollections";
import Dm1Faults from "./dm1Faults/Dm1Faults";
import PinnedSignals from "./pinnedSignals/pinnedSignals";

import CollectionMaint from "./collectionMaint/CollectionMaint";

import { connect } from "react-redux";
import * as actions from "./actions";

import "./templateDetails.css";
import BackNavigation from "../../utils/Components/BackNavigation";
import OperatorSignals from "./operatorSignals/OperatorSignals";
import QuotasApplied from "./quotasApplied/quotasApplied";
import { getPermissions } from "../../utils/commonHelpers";

const { Paragraph } = Typography;
const { TabPane } = Tabs;

interface Prop {
    getTemplate: Function,
    templateMaint: Function,
    templateDetailsTabChange: Function,
    getQuotasApplied: Function, 
    templateDetails: any,
    context: any,
    permissions: any,
    quotasApplied: any
}

class TemplateDetails extends Component<Prop, {}> {

    drawerWidth:number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);

    getTemplateId() {
        const pathnameArr = history.location.pathname.split("/");
        return pathnameArr[pathnameArr.length - 1];
    }

    componentDidMount () {
        if (this.props.context.appContext.roleName.toLowerCase() === "guest" || this.props.context.appContext.roleName.toLowerCase() === "barko_guest"){
            history.push("/not-found")    
        }
        else {
            this.props.getTemplate(this, this.getTemplateSuccess, null, this.getTemplateId());
        }
    }

    componentWillUnmount() {
        this.props.templateDetailsTabChange(this, "1");
    }
     
    getTemplateSuccess = (record:any) => {          
    }

    onTemplateNameChange = (value:string) => {
        this.props.templateMaint(this, null, null, this.getTemplateId(), "name", value);
    }

    onTemplateDescriptionChange = (value:string) => {
        this.props.templateMaint(this, null, null, this.getTemplateId(), "description", value);
    }

    goToTemplates = (e:any) => {
        e.preventDefault();
        history.push("/templates");
    }

    onTabChange = (key:string) => {
        this.props.templateDetailsTabChange(this, key);
    }

    render() {    
        
        const { templateRecord, activeTab, tabs} = this.props.templateDetails;
        const result = getPermissions(this.props.permissions.permissions.templates);
        const dm1Faults = getPermissions(this.props.permissions.permissions.dm1_settings);
        const rules = getPermissions(this.props.permissions.permissions.alert_settings);

        let iscreate = result[0]; 
        let isread = result[1]; 
        let isupdate =  result[2]; 
        let isdelete = result[3];
        let iscreateDm1Faults = false;        
        let isreadDm1Faults = false
        let isupdateDm1Faults = false;
        let isdeleteDm1Faults = false;
        let iscreateRules = false;
        let isreadRules = false
        let isupdateRules = false;
        let isdeleteRules = false;
        let iscreateOrUpdateTemplate = false;        
        let noEditDisplay = false;     
        let orgCmdPgnSpns = templateRecord.orgCmdPgnSpns; 

        let isadmin = false;   

        //ops-2453, it should be enabled based on template permission
        // const oprPermissionResult = getPermissions(this.props.permissions.permissions.operator_support);
        // let isSignalCreate = oprPermissionResult[0];
        // let isSignalUpdate = oprPermissionResult[2];
        // let isSignalDelete = oprPermissionResult[3]; 

        if(templateRecord.objectAdmin || isupdate) {
            noEditDisplay = true
        }

        if(iscreate || dm1Faults[0]) { iscreateDm1Faults = true; }
        if(isread ||  dm1Faults[1]) { isreadDm1Faults = true; }
        if(isupdate || dm1Faults[2]) { isupdateDm1Faults = true; }
        if(isdelete || dm1Faults[3]) { isdeleteDm1Faults = true; }

        if(iscreate || rules[0]) {iscreateRules = true; }
        if(isread || rules[1]) { isreadRules = true; }
        if(isupdate || rules[2]) { isupdateRules = true; }
        if(isdelete || rules[3]) { isdeleteRules = true; }
        if(iscreate || isupdate) { iscreateOrUpdateTemplate = true; }
        

        // The user who has view template subcomponent permission shall be able to view the tab
        // these type of user who does not have update permission will not see submit button but can view tab detail at least for dm1 definition
        // It is unclear for pinned signal/operator, if they cannot update, there is no reason for them to see the pinned signal/operator tab detail

        return (
            <div data-id="template-details-container" className="layout-content">
                <div data-id="template-details-breadcrumbs-container">
                    <Breadcrumb data-id="template-details-breadcrumbs" separator="/">
                        <Breadcrumb.Item data-id="template-details-breadcrumb-element" key="breadcrumb-0" href="# " onClick={(e) => {this.goToTemplates(e)}}>Templates</Breadcrumb.Item>
                        <Breadcrumb.Item data-id="template-details-breadcrumb-element" key="breadcrumb-1">Template Details</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
               
                {
                    ((templateRecord.templateVisibleToSubOrg && (this.props.context.appContext.roleName.toLowerCase() === "admin" || isread))
                     || (!templateRecord.templateVisibleToSubOrg && (templateRecord.objectAdmin || isread || isupdate))) &&
                    <>
                        <div className="content-header page-title">
                            {
                                templateRecord.templateId &&
                                <div style={{ display: "flex" }}>
                                    <Tooltip title="Go To Previous Page"> 
                                        <BackNavigation />
                                    </Tooltip> 
                                    <Paragraph editable={(this.props.context.appContext.roleName.toLowerCase() === "admin" || isupdate) &&
                                        {  onChange: this.onTemplateNameChange }}>{templateRecord.name}</Paragraph>   
                                    { (templateRecord.quotasApplied) &&
                                        <Tooltip title={templateRecord.quotasApplied} >
                                            <Button style={{backgroundColor:'rgba(228, 241, 254, 1)', borderColor: 'rgba(228, 241, 254, 1)', color: 'rgba(1, 124, 132, 1)', fontSize: "50%"}}>Lite</Button>
                                        </Tooltip>                    
                                    }   
                                </div>
                            }
                            {
                                !templateRecord.templateId &&
                                <Skeleton.Input active={true} size="default" />
                            }
                            
                        </div>
                        {
                            templateRecord.templateId && ((templateRecord.templateVisibleToSubOrg && (this.props.context.appContext.roleName.toLowerCase() === "admin"))
                             || (!templateRecord.templateVisibleToSubOrg && (templateRecord.objectAdmin || isupdate))) &&
                            <Paragraph editable={{ onChange: this.onTemplateDescriptionChange }}>{templateRecord.description || ""}</Paragraph>                            
                        }
                        {
                            !templateRecord.templateId &&
                            <Skeleton.Input active={true} size="small" />
                        }
                    </>                   
                }
                {
                    ((templateRecord.templateVisibleToSubOrg && this.props.context.appContext.roleName.toLowerCase() !== "admin" && !isread)
                    || (!templateRecord.templateVisibleToSubOrg && (!templateRecord.objectAdmin && !isread && !isupdate))) &&
                    <>
                        <div className="content-header page-title">
                            <div style={{ display: "flex" }}>
                                <Tooltip title="Go To Previous Page"> 
                                    <BackNavigation />
                                </Tooltip>
                                <span>{templateRecord.name}</span>
                            </div>
                        </div>
                        {
                            templateRecord.description &&
                            <div>{templateRecord.description || ""}</div>
                        }  
                        <div className="mt-15"></div>                      
                    </>
                }
                
                <Tabs activeKey={activeTab} size="large" type="card" onChange={this.onTabChange}>
                    {/* ops-2453 */}  
                    {
                        (isreadRules || isread) &&
                        <TabPane data-id="template-details-collections-tab-button" tab={tabs[0].title} key="1">                    
                         <SignalCollections iscreate={iscreate} iscreateRules={iscreateRules} isread={isread} isreadRules={isreadRules} 
                         isupdateRules={isupdateRules} isdelete={isdelete} isdeleteRules={isdeleteRules} iscreateOrUpdateTemplate={iscreateOrUpdateTemplate} 
                         isGlobalTemplate = {templateRecord.templateVisibleToSubOrg} orgCmdPgnSpns={orgCmdPgnSpns} />
                        </TabPane>
                    }
                    {/* ops-2453 */}                   
                    {
                        (isreadDm1Faults) &&
                        <TabPane data-id="template-details-dm1-faults-tab-button" tab={tabs[1].title} key="2">                    
                            <Dm1Faults iscreate={iscreateDm1Faults} isread={isreadDm1Faults} isupdate={isupdateDm1Faults} isdelete={isdeleteDm1Faults}/>
                        </TabPane>
                    }
                    
                    {/* ops-1224 */}
                    { /* A user with read permision shall be able to read but shall not be able to update for both global or local template */
                        ((templateRecord.templateVisibleToSubOrg && (this.props.context.appContext.roleName.toLowerCase() === "admin" || isread)) 
                        || (!templateRecord.templateVisibleToSubOrg && (isread || iscreateOrUpdateTemplate))) &&
                            <TabPane data-id="template-details-pinned-signals-tab-button" tab={tabs[2].title} key="3">                    
                                <PinnedSignals activeTab={activeTab} isadmin={this.props.context.appContext.roleName.toLowerCase() === "admin"} iscreate={iscreate} isread={isread} isupdate={isupdate}  isGlobalTemplate={templateRecord.templateVisibleToSubOrg}/>
                            </TabPane>                        
                    }
                    {/* ops-2453 , it should be enabled based on template permission */}
                    { /* A user with read permisison can read, but shall not be update */
                        ((templateRecord.templateVisibleToSubOrg && (this.props.context.appContext.roleName.toLowerCase() === "admin" || isread))
                         || (!templateRecord.templateVisibleToSubOrg && (isread || iscreateOrUpdateTemplate))) &&
                            
                            <TabPane 
                                data-id="template-details-opr-signals-tab-button"
                                tab={(<Tooltip title="User may define signal from template collections, to be used as operator identification. Typical operator ID signal would come from a keypad entry, RFID reader, fob reader, etc..." >{tabs[3].title}</Tooltip>)} 
                                key="4"
                            >
                                <OperatorSignals activeTab={activeTab} isadmin={this.props.context.appContext.roleName.toLowerCase() === "admin"} iscreate={iscreate} isread={isread} isupdate={isupdate} isGlobalTemplate={templateRecord.templateVisibleToSubOrg}/>
                            </TabPane>   
                    }
                    {
                        ((templateRecord.templateVisibleToSubOrg && (this.props.context.appContext.roleName.toLowerCase() === "admin" || isread)) 
                         || (!templateRecord.templateVisibleToSubOrg && (isread || iscreateOrUpdateTemplate))) &&
                         (templateRecord.quotasApplied) &&
                            <TabPane data-id="template-details-quotas-applied-tab-button" tab={tabs[4].title} key="5">                    
                                <QuotasApplied iscreate={iscreate} isread={isread} isupdate={isupdate} isdelete={isdelete} activeTab={activeTab}/>
                            </TabPane>                        
                    }
                </Tabs>                        
                
                {((templateRecord.templateVisibleToSubOrg && (this.props.context.appContext.roleName.toLowerCase() === "admin" || isread)) 
                         || (!templateRecord.templateVisibleToSubOrg && (isread || iscreateOrUpdateTemplate))) &&
                <CollectionMaint  iscreate={iscreate} isread={isread} iscreateOrUpdateTemplate={iscreate || isupdate} isdelete={isdelete} isGlobalTemplate = {templateRecord.templateVisibleToSubOrg} context={this.props.context}/>    
                }           
            </div>
                
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        templateDetails: state.templateDetailsReducers.templateDetailsState,
        permissions: state.contextReducer.data,
        quotasApplied: state.templateDetailsReducers.templateDetailsState.tabs[4]
    };
};

export default connect(
    mapStateToProps,
    actions
)(TemplateDetails);
  