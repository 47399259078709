import React, { useState, useRef,useEffect } from "react";
import { Grid, Button, PageHeader, Empty, message,Tooltip,Upload } from "antd";
import { SizeMe } from "react-sizeme";
import GridLayout, { ReactGridLayoutProps } from "react-grid-layout";
import { CSVLink } from "react-csv";
import "react-grid-layout/css/styles.css";
import { WidgetState, State, DashboardState } from "./state";
import { WidgetConfig } from "./widgets";
import WidgetContainer from "./WidgetContainer";
import Icon, { PlusOutlined, UploadOutlined, LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import { appConfigs } from "../../utils/configurations";
import Axios from "axios";
import WidgetEditDrawer from "./WidgetEditForm";
import * as Api from "./api";
import {
  loadingIndicatorEnd,
  loadingIndicatorStart,
  uuidv4,
  getPermissions,
} from "../../utils/commonHelpers";
import {
  editWidgetStructureForDb,
  addWidgetStructureForDb,
  saveLayoutDb,
} from "./widgets/widgetapi";
import { DBWidgetConfig, PermissionProps } from "./models";
import { errorResponse } from "../../utils/apiHelpers/apiHelpers";
import "./AssetDashboard.css";
import { useAssetStatus } from "./useAssetStatus";
import AssetStatus from "./AssetStatus";
import { WidgetFilterConfig, WidgetFilterDrawer } from "./WidgetFilterForm";
import {
  WidgetExportConfig,
  WidgetExportDrawer,
  exportCsv,
  prepareWidgetEvents,
} from "./WidgetExportForm";
import { useAssetAlerts } from "./useAssetAlerts";
import { useExport } from "./WidgetExportForm/useExport";
import { connect } from "react-redux";
import ReactGridLayout from "react-grid-layout";
import {
  DashboardHeader,
  DashboardHeaderExtra,
  DashboardHeaderExtraProps,
  DashboardHeaderProps,
} from "./DashboardHeader";

const { useBreakpoint } = Grid;

type Breakpoints = ReturnType<typeof useBreakpoint>;

function columnNumber(breakpoints: Breakpoints) {
  if (breakpoints.xl) {
    return 4;
  } else if (breakpoints.lg) {
    return 3;
  } else if (breakpoints.md) {
    return 2;
  } else {
    return 1;
  }
}

interface EditState {
  visible: boolean;
  title: string;
  onFinish: (cfg: WidgetConfig) => void;
  config?: WidgetConfig;
  assetDetail?: any;
  onDualChange: (checked: boolean) => void;
  enabledualaxis:boolean;
}

interface FilterState {
  visible: boolean;
  title: string;
  onFinish: (cfg: WidgetFilterConfig) => void;
  config?: WidgetFilterConfig;
}

interface ExportState {
  visible: boolean;
  title: string;
  filterConfig?: WidgetFilterConfig
  onFinish: (cfg: WidgetExportConfig) => void;
}

const DEFAULT_FILTER_STATE: FilterState = {
  visible: false,
  title: "",
  onFinish: (cfg) => {},
};

const DEFAULT_EXPORT_STATE: ExportState = {
  visible: false,
  title: "",
  onFinish: (cfg) => {},
};

const DEFAULT_EDIT_STATE: EditState = {
  visible: false,
  title: "",
  onFinish: (cfg) => {},
  onDualChange:(checked)=>{},
  enabledualaxis:false
};
//rowHeight: 220,
const GRID_SETTINGS: ReactGridLayoutProps = {
  compactType: "vertical",
  containerPadding: [0, 0],
  isDraggable: false,
  isResizable: false,
};

interface DataProps {
  state: State;
  context: any;
  permissions: any;
  SignalRInitialData: any;
  dashboardId: number;
  bgImageUrlProp:any;
  isNewDashboardProp:any;
}

interface dispatchProps {
  addWidget: (dashboardId: number, id: string, widget: DBWidgetConfig) => void;
  deleteWidget: (dashboardId: number, id: string) => void;
  updateLayout: (column: number, dashboardId: number) => void;
  saveLayout: (layout: ReactGridLayout.Layout[]) => void;
assetPreferences: (filterPreferences: any) => void;
updateAssetPreferences: (filterPreferences: any,isTimezoneChanged:any) => void;
  updateWidget: (
    dashboardId: number,
    id: string,
    widget: DBWidgetConfig
  ) => void;
  saveLayoutSuccess: (
    dashboardId: number,
    layout: ReactGridLayout.Layout[]
  ) => void;
  clearWidgetFilter: (dashboardId: number, id: string) => void;
  updateWidgetFilter: (
    dashboardId: number,
    id: string,
    widgetFilterConfig: WidgetFilterConfig
  ) => void;
}

type Props = DataProps & dispatchProps;

const AssetDashboard: React.FC<Props> = (props) => { 
  const {
    state,
    dashboardId,
    updateLayout,
    updateWidget,
    addWidget,
    deleteWidget,
    updateWidgetFilter,
    updateAssetPreferences,
    saveLayoutSuccess,
    saveLayout,
  } = props;
  const { contextUser, appContext } = props.context;
  const result = getPermissions(props.permissions.permissions.asset_widgets);   const assets = getPermissions(props.permissions.permissions.assets);
  const assetsPermissionResult = getPermissions(props.permissions.permissions.assets);
  const templatesPermissionResult = getPermissions(props.permissions.permissions.templates);
  const oprPermissionResult = getPermissions(props.permissions.permissions.operator_support);  
  //ops-2839: Remove the iqanconnect key permission check  
  //const iqanKey = getPermissions(props.permissions.permissions.iqan_key);

  const [editState, setEditState] = useState<EditState>(DEFAULT_EDIT_STATE);
  const [autoRefresh, setAutoRefresh] = useState<number>(0);
  const [isResizable, setIsResizable] = useState<boolean>(false);
  const [dashboardbgImageUrl, setdashboardbgImageUrl] = useState<any>();
  const [isNewDashboard, setIsNewDashboard] = useState<any>();
  useEffect(() => {
    setdashboardbgImageUrl(props.bgImageUrlProp)
    }, [props.bgImageUrlProp]);
  useEffect(() => {
    setIsNewDashboard(props.isNewDashboardProp)
      }, [props.isNewDashboardProp]);
  //enable and disable the layout resizing option
  const [gridResize, setGridResize] = useState<ReactGridLayoutProps>(
    GRID_SETTINGS
  );
  const [filterState, setFilterState] = useState<FilterState>(
    DEFAULT_FILTER_STATE
  );
  const [exportState, setExportState] = useState<ExportState>(
    DEFAULT_EXPORT_STATE
  );
  const csvLink = useRef<any>(null);
  const assetStatus = useAssetStatus(state.asset.id);
  const [
    dataForDownload,
    setDataForDownload,
    bDownloadReady,
    setDownloadReady,
    exportHeader,
    setexportHeader,
    exportFileName,
    setExportFileName,
  ] = useExport(csvLink);
  const refreshWidgets = () => {
    setAutoRefresh((prevState:any) => {
      return prevState + 1
    });
  };
  const parentOrgPath = state?.asset?.orgPathId?.includes("~") ? state?.asset?.orgPathId.split('~') : [state?.asset?.orgPathId];
  let parentOrg:any;
  if (parentOrgPath.length > 1) {
    parentOrg=parentOrgPath[1]
  }else{
    parentOrg=parentOrgPath[0]
  }
  let enableNewDashboardStyles:boolean;
  if(parentOrg==='7ff34c90-36b2-11e8-8e2e-6f1fbe0d469d' && isNewDashboard){
    enableNewDashboardStyles=true;
  }else{
    enableNewDashboardStyles=false;
  }
  
  const createNewWidget = () => {
    let widgetColumns:number;
    if(enableNewDashboardStyles){
      widgetColumns=8;
    }else{
      widgetColumns=4;
    }
    const uuid = uuidv4();
    setEditState({
      visible: true,
      title: "Create New Widget",
      onFinish: (cfg) => saveWidgets(uuid, cfg, "Add",widgetColumns),
      onDualChange:(checked)=>onDualChange(checked),
      enabledualaxis:false
    });
  };

  const saveWidgets = (widgetId: string, cfg: WidgetConfig, action: string,widgetColumns:number) => {
    let signalStructure: DBWidgetConfig;
    const cnfg: any = cfg;
    const dashboard = state.dashboards.find(
      (d: DashboardState) => d.dashboardId === dashboardId
    );
    //let widgetColumns=6;
    if (dashboard) {
      if (action === "Edit") {
        const widgetState =
          dashboard.widgets.filter((w) => w.id === widgetId)[0] || [];
          //console.log("widgetState:",widgetState);
        signalStructure = editWidgetStructureForDb(
          widgetId,
          cfg,
          state.template,
          widgetState
        );
      } else {
        signalStructure = addWidgetStructureForDb(
          widgetId,
          cfg,
          state.template,
          dashboard.widgets,
          widgetColumns
        );
      }

      Api.saveWidget(state.asset.templateId, signalStructure, dashboardId)
        .then((data) => {
          if (action === "Edit") {
            updateWidget(dashboardId, widgetId, signalStructure);
          } else {
            addWidget(dashboardId, widgetId, signalStructure);
          }
        })
        .catch((error) => {
          errorResponse(null, error);
          message.error("Errror in saving the widget");
        });
    }

    
  };

  const deleteWidgt = (widgetId: string) => {
    Api.deleteWidget(state.asset.templateId, widgetId, dashboardId)
      .then((data) => {
        deleteWidget(dashboardId, widgetId);
        })
      .catch((error) => {
        errorResponse(null, error);
        message.error("Errror in deleting the selected widget");
      });
    
  };
  const assetCallBackFnSuccess = (filterData: any,isTimezoneChanged: any) => {
    updateAssetPreferences(filterData,isTimezoneChanged);
  }

  const editWidget = (w: WidgetState) => {
    let widgetColumns:number;
    if(enableNewDashboardStyles){
      widgetColumns=8;
    }else{
      widgetColumns=4;
    }
    setEditState({
      visible: true,
      title: "Edit Widget",
      onFinish: (cfg) => saveWidgets(w.id, cfg, "Edit",widgetColumns),
      config: w,
      onDualChange:(checked)=>onDualChange(checked),
      enabledualaxis:w.rightYAxisSignals && w.rightYAxisSignals?.length>0?true:false
    });
  };

  const filerWidget = (wid: string, cfg: WidgetFilterConfig) => {
    updateWidgetFilter(dashboardId, wid, cfg);
  };

  const exprtWidget = (widgetState: WidgetState, cfg: WidgetExportConfig) => {
    exportCsv(state, widgetState, cfg)
      .then((data) => {
        loadingIndicatorStart();
        const csvExportDetails = prepareWidgetEvents(data, state, widgetState);

        setexportHeader(csvExportDetails.eventHeader);
        setExportFileName(
          `${state.asset.name}_${widgetState.title}_${cfg.startDate.format(
            "YYYY-MM-DD"
          )}_${cfg.endDate.format("YYYY-MM-DD")}.csv`
        );
        setDataForDownload(csvExportDetails.eventArray);
        setDownloadReady(true);
        loadingIndicatorEnd();
      })
      .catch((error) => {
        errorResponse(null, error);
        setDownloadReady(false);
        loadingIndicatorEnd();
      });
  };

  const filterWidget = (w: WidgetState) => {
    const config: WidgetFilterConfig = {
      startDate: w.startDate,
      startTime: w.startTime,
      endDate: w.endDate,
      endTime: w.endTime,
      datapoint: w.datapoint || 50,
    };
    setFilterState({
      visible: true,
      title: "Widget Filter",
      onFinish: (cfg) => filerWidget(w.id, cfg),
      config: config,
    });
  };

  const exportWidget = (w: WidgetState) => {
    const filterConfig: WidgetFilterConfig = {
      startDate: w.startDate,
      startTime: w.startTime,
      endDate: w.endDate,
      endTime: w.endTime
    };
    setExportState({
      visible: true,
      title: "Widget Export",
      filterConfig: filterConfig,
      onFinish: (cfg) => exprtWidget(w, cfg),
    });
  };

  const hideEditForm = () => {
    setEditState((s) => ({
      ...s,
      visible: false,
    }));
  };

  const hideFilterForm = () => {
    setFilterState((s) => ({
      ...s,
      visible: false,
    }));
  };

  const hideExportForm = () => {
    setExportState((s) => ({
      ...s,
      visible: false,
    }));
  };

  const onLayoutViewChange = (checked: boolean) => {
    setIsResizable(checked)
    setGridResize({
      ...GRID_SETTINGS,
      isDraggable: checked,
      isResizable: checked,
    });
    if (!checked) {
      const newLayout = saveLayoutDb(state.layout);
      Api.saveLayoutChange(state.asset.templateId, newLayout, dashboardId)
        .then((data) => saveLayoutSuccess(dashboardId, state.layout))
        .catch((err) => {
          errorResponse(null, err);
          message.error("Error occured while saving the new layout");
        });
    }
  };

  const onDualChange=(checked: boolean)=>{
   
    // setEditState({
    //   ...DEFAULT_EDIT_STATE,
    //   visible:true,
    //   isDualAxisSelected:checked
    // });  

    setEditState((prevState) => (
      { 
      ...prevState, 
      enabledualaxis:checked 
      }));
  }

  const NewWidgetButton = ({ label }: { label: string }) => (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      onClick={createNewWidget}
      className="asset-buttonwidth"
    >
      {label}
    </Button>
  );
  
  const headerData: DashboardHeaderProps = {
    ...state.asset,
  };

  let temperature = {
    checked:state.temperature.checked,
    value:state.temperature.value
  }
  let timezone = {
    value: state.timezone.value,
    isGpsSignalAvailable: state.timezone.isGpsSignalAvailable,
    gpsSignalColId: state.timezone.gpsSignalColId,
    gpsCoordinates:state.timezone.coordinates
  }

  const HeaderExtra: DashboardHeaderExtraProps = {
    ...state.asset,
    temperature,
    timezone,
    onLayoutViewChange: (checked: boolean) => onLayoutViewChange(checked),
    assetCallBackFnSuccess: (filterData: any,isTimezoneChanged: any) => assetCallBackFnSuccess(filterData,isTimezoneChanged),


  };

   const permissions: PermissionProps = {
    userRole: appContext.roleName,
    isCreate: result[0],
    isRead: result[1],
    isUpdate: result[2],
    isDelete: result[3],
  };
  let widgetColumns:number;
  let rowHeight:number;
  if(enableNewDashboardStyles){
      widgetColumns=8;
      rowHeight=120;
    }else{
      widgetColumns=4;
      rowHeight=400;
    }
  const { dashboards } = state;
  const dashboard =  dashboards.length > 0 && dashboards.find((d: DashboardState) => d.dashboardId === dashboardId);
  let dashboardBgStyles:any;
  //console.log("dashboard object",dashboards);
  if(dashboards.length > 0 ){
    //const bgImageArray= dashboards.map((d: DashboardState) => d.bgimageurl !== null );
    //dashboards.find((d: DashboardState) => d.dashboardId === dashboardId
    //const bgImageArray= dashboards.find((d: DashboardState) => d.dashboardId === dashboardId);
    if(dashboardbgImageUrl!==null && isNewDashboard){
      dashboardBgStyles={backgroundImage: `url(${dashboardbgImageUrl})`,  backgroundPosition: 'top center', backgroundRepeat:'no-repeat', minHeight:'100%', backgroundSize: 'contain'};
    }else{
      dashboardBgStyles={};
    }
  }else{
    dashboardBgStyles={};
  }
  
const uploadProps = {
  name: 'file',
  action: appConfigs.server.URL + '/ui/api/dashboard-bg-image',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info:any) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};
function beforeUpload(file:any) {
  const isJPG = file.type === 'image/jpeg' || 'image/png';
  if (!isJPG) {
    message.error('You can only upload JPG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJPG && isLt2M;
}
  const handleCustomFileUpload = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    //formData.append('dashboardId', dashboardId);
    formData.append('file', file);
    formData.append('fileName', file.name);
    try {
      const response = await Axios.post(appConfigs.server.URL + '/ui/api/dashboard-bg-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        params:{
          'dashboardId':dashboardId
        },
      });
      setdashboardbgImageUrl(response?.data?.data?.uploadDashboardBgImage);
      message.success('File uploaded successfully.');
    } catch (error) {
      console.error('Error uploading file:', error);
      message.error('File upload failed.');
    }
  }
  const handleDeleteFileUpload = async (options: any) => {
    const formData = new FormData();
    formData.append('fileName', '');
    try {
      const response = await Axios.post(appConfigs.server.URL + '/ui/api/dashboard-bg-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        params:{
          'dashboardId':dashboardId
        },
      });
      setdashboardbgImageUrl(null);
      message.success('Background Image deleted successfully.');
    } catch (error) {
      console.error('Error deleting file:', error);
      message.error('Background Image delete failed.');
    }
    //return
  }
//const dashboardBgImage = state.dashboards.find((d: DashboardState) => d.dashboardId === dashboardId)?.bgimageurl;
  return (
    <>
      <PageHeader
        title=""
        subTitle={
          state.loaded ? (
            <DashboardHeader
              userRole={appContext.roleName}
              isRead={result[1]}
              isUpdate={result[2]}
              isAssetUpdate={assetsPermissionResult[2]}
              isTemplatesRead={templatesPermissionResult[1]}
              isOprRead={oprPermissionResult[1]}
              isOprUpdate={oprPermissionResult[2]}
              {...headerData}
            />
          ) : null
        }
        //added a key
        extra={
          state.columns === 8 || 6 || 4
            ? [
                <DashboardHeaderExtra 
                  key="pageHeaderExtra"
                  refreshWidgets={() => refreshWidgets()}
                  {...HeaderExtra} {...permissions} />,                
                result[0] === true ? (
                  <NewWidgetButton key="widgetAdd" label="Add Widget" />
                ) : (
                  ""
                ),
              ]
            : result[0] === true
            ? [<NewWidgetButton key="widgetAdd" label="Add Widget" />]
            : ""
        }
      >
        <PageContent>
          <div style={dashboardBgStyles}>
          {dashboard && dashboard.widgets.length > 0 ? (
            
            
            <SizeMe>
              {({ size }) => (
                <>
                  <GridLayout
                    style={{marginTop: '5px', marginLeft: '7px',marginRight: '15px', marginBottom: '10px'}}
                    {...gridResize}
                    rowHeight={rowHeight}
                    cols={widgetColumns}
                    layout={state.layout}
                    onLayoutChange={saveLayout}
                    width={size.width || 200}
                    preventCollision={true}
                    transformScale={1}
                  >
                    {dashboard.widgets.map((w) => {
                      return (
                        <div key={w.id} className="widget-index">
                          <WidgetContainer
                            widgetState={w}
                            assetId={state.asset.id}
                            template={state.template}
                            state={state}
                            isResizable={isResizable}
                            onEdit={() => editWidget(w)}
                            onDelete={() => deleteWidgt(w.id)}
                            onFilter={() => filterWidget(w)}
                            onExport={() => exportWidget(w)}
                            userRole={appContext.roleName}
                            isCreate={result[0]}
                            isRead={result[1]}
                            isUpdate={result[2]}
                            isDelete={result[3]}
                            autoRefresh={autoRefresh}
                            assetDetails={props.state.asset}
                            context={props.context}
                            SignalRInitialData={props.SignalRInitialData}
                            //isIqanKey={iqanKey[1]} //ops-2839: Remove the iqanconnect key permission check 
                            dashboardId={dashboardId}
                            bgimageurl={dashboardbgImageUrl}
                            isNewDashboard={isNewDashboard}
                          ></WidgetContainer>
                        </div>
                      );
                    })}
                  </GridLayout>
                </>
              )}
            </SizeMe>
          ) : appContext.roleName === "admin" ||
            appContext.roleName === "user" ? (
                  <Empty description="This dashboard has no widgets">
                    <NewWidgetButton label="Create a Widget" />
                    {
                    enableNewDashboardStyles && 
                    (dashboardbgImageUrl ===null || dashboardbgImageUrl ===undefined || dashboardbgImageUrl ==='null'?
                    <Upload
                      accept=".png,.jpg,.jpeg"
                      showUploadList={false}
                      name="file"
                      beforeUpload={beforeUpload}
                      customRequest={handleCustomFileUpload}
                    >
                      <Tooltip title="Click Here to upload Background Image for Dashboard">
                        <Button icon={<UploadOutlined />} type="primary" className="asset-buttonwidth" style={{ margin: 10 }}>Click to Upload Image</Button>
                      </Tooltip>
                    </Upload> 
                    :
                    <Tooltip title="Click Here to Delete Background Image for Dashboard">
                        <Button icon={<DeleteOutlined />} type="primary" onClick={(e:any) => handleDeleteFileUpload(dashboardId)} className="asset-buttonwidth" style={{ margin: 10 }}>Click to Delete Image</Button>
                      </Tooltip>
                    )}
                  </Empty>
          ) : (
            ""
          )}

          <WidgetEditDrawer
            {...editState}
            template={state.template}
            assetDetails={props.state.asset}
            onClose={hideEditForm}
          />
          <WidgetFilterDrawer {...filterState} onClose={hideFilterForm} />
          <WidgetExportDrawer {...exportState} onClose={hideExportForm} />
          </div>
        </PageContent>
      </PageHeader>
      <CSVLink
        data={dataForDownload}
        headers={exportHeader}
        filename={exportFileName}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </>
  );
};

const PageContent: React.FC = ({ children }) => {
  return <div className="pagecontent">{children}</div>;
};

const mapStateToProps = (state: any) => {
  return {
    context: state.contextReducer.context,
    permissions: state.contextReducer.data,
    SignalRInitialData: state.contextReducer.signalRData.SignalRInitialData,
    assetDetails: state.asset
  };
};

export default connect(mapStateToProps)(AssetDashboard);
