
import Widget from './Widget';
import EditForm  from "./BarChartEditForm";
import { WidgetDetails } from "../models";
import { CommonWidgetConfig } from "../../models";
import { Options } from './BarChartClass';

export type Config = CommonWidgetConfig & { type: "bar"; settings?: Options };

export type BarOptions = Options;

const details: WidgetDetails = { type: "bar", name: "Bar Chart" };

export { Widget, details, EditForm };