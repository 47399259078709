import Axios from "axios";
import { appConfigs } from "../utils/configurations";

export function onAssetNotesDrawerToggle(
    state: boolean
) {
    return (dispatch: any) => {
        dispatch({ type: "TOGGLE_MACHINE_NOTE_DRAWER", payload: {state: state} });
    }
}  


export function getAssetNotes(
    gatewayId: string,
    callbackErrorFn?: any
) {

    return async (dispatch: any) => {

        await Axios.get(appConfigs.server.URL + "/ui/api/assets/"+gatewayId+"/asset_notes", {
			responseType: "json",
		})
        .then(response => {    
            let notesList = response.data.data.assetNotes
            dispatch({ type: "GET_NOTES_SUCCESS", payload: { notesList } });
        })
        .catch(function (error) {                
                if (callbackErrorFn != null) {
                    callbackErrorFn("Failed get asset notes!");
                }
            }
        );
    }
}  
export function getAssetGlobalNotes(
    orgId: string,
    callbackErrorFn?: any
) {

    return async (dispatch: any) => {

        Axios.get(appConfigs.server.URL + "/ui/api/orgs/" + orgId + "/getAssetGlobalNotes", {
			responseType: "json",
		})
        .then(response => {    
            let globalNotes = response.data.data.globalNotes
            dispatch({ type: "GET_ASSET_GLOBAL_NOTES_SUCCESS", payload: { globalNotes } });
        })
        .catch(function (error) {                
                if (callbackErrorFn != null) {
                    callbackErrorFn("Failed get asset notes!");
                }
            }
        );
    }
} 

export function addNewNote(
    noteBody: string,
    gatewayId: string,
    callbackSuccessFn?: any,
    callbackErrorFn?: any
) {

    return async (dispatch: any) => {

        await Axios.post(appConfigs.server.URL + "/ui/api/assets/add_asset_note", null, {
                params: {
                    noteBody: noteBody,
                    gatewayId: gatewayId,
                    Mode: 'add'
                },
            })
            .then(response => {                            
                if (callbackSuccessFn != null) {                    
                    callbackSuccessFn("Note Added Successfully");                    
                }
            })
            .catch(function (error) {                
                if (callbackErrorFn != null) {
                    callbackErrorFn("Adding new note failed!");
                }
            });
        
    }
}  


export function pinMessage(
    noteId: string,
    pinnedStatus: string,
    callbackSuccessFn?: any,
    callbackErrorFn?: any
) {

    return async (dispatch: any) => {

        await Axios.post(appConfigs.server.URL + "/ui/api/assets/add_asset_note", null, {
            params: {
                noteId: noteId,
                pinnedStatus: pinnedStatus,
                Mode: 'update'
            }
        })
        .then(response => {                            
            if (callbackSuccessFn != null) {  
                if (pinnedStatus == 'false') {
                    callbackSuccessFn("Message Unpinned Successfully");                    
                } else {
                    callbackSuccessFn("Message Pinned Successfully");                    
                }
            }
        })
        .catch(function (error) {                
            if (callbackErrorFn != null) {
                callbackErrorFn("Failed to Pin the message!");
            }
        });
    }

}  