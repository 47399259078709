import React, { useEffect, useRef, useState } from 'react'

import './fleetOverview.css'

import { connect, useDispatch } from "react-redux";
import * as rootActions from "../../actions";
import { Button, Card, Col, message, Row, Select, Statistic, Table, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { getPercentageChange, numberWithCommas } from '../../utils/commonHelpers';
import moment from 'moment';


export function useClickOutsideNotifications(ref: any) {

    const dispatch = useDispatch()
    
    useEffect(() => {

        function handleClickOutside(event: any) {
            /* prevent close on tooltip and select or on click outside */
            let doesCloseOn = [
                'ant-tooltip-inner',
                'ant-select-item-option-content',
                'btn-notification',
                'ant-btn header-notifications-big ant-btn-ghost',
                'ant-select-item ant-select-item-option ant-select-item-option-active ant-select-item-option-selected',
                'ant-select-item-empty',
                'ant-empty-description',
                'ant-empty-image',
                'ant-empty-img-simple-path',
                'ant-empty ant-empty-normal ant-empty-small',
            ]

            setTimeout(() => {
                if (ref.current && (!ref.current.contains(event.target) && !doesCloseOn.includes(event.target.className)) && ref.current.className == 'notificationsLayoutOpen') {
                    dispatch({ type: "SET_NOTIFICATION_VISIBILITY", visible: false });
                    dispatch({ type: "SET_FLEET_STATS_VISIBILITY", visible: false });
                }
            }, 20);

            
            
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

interface Props {
    notificationVisibility: Function,
    notifications: any
    stats: any
}


const NotificationsOverview = (props: Props) => {

    const wrapperRef = useRef(null);
    useClickOutsideNotifications(wrapperRef)

    const { data } = props.stats


    const pad = (number:number) => {
        return (number < 10) ? ("0" + number) : number;
    }

    let DayNameArray = [moment().format('ddd').toUpperCase()]
    for (let index = 1; index < 7; index++) {
        DayNameArray.push(moment().subtract(index, 'day').format('ddd').toUpperCase())
    }


    return (
        <div ref={wrapperRef} className={`${props.notifications.visible ? "notificationsLayoutOpen" : "notificationsLayoutClose"}` }>
            <Row gutter={[8*1, 8*1]} >
                
                <Col className={`${props.notifications.visible ? "slideTop2" : "slideTop2Reversed"}`} xs={24} sm={24} md={8} lg={12} xl={12}>
                    <div style={{ padding: '8px 8px', borderRadius: '3px' }}>
                        <Card className={`${data.expiringSoon > 0 ? '' : ''}`} size="small" style={{ backgroundColor: "#d1d1d1", backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))", borderRadius: "5px", padding: "1px", margin: "1px" }} >
                            <Row style={{ height: 80 }} gutter={[0,0]}>
                                <Col style={{ color: "#4a4a4a" }} span={24}>
                                    <span style={{ color: "#4a4a4a", fontSize: 26, marginRight: "6px" }}>{ numberWithCommas(data.expiringSoon) } Assets</span>
                                    <span>(Contracts Expiring Within 60 Days)</span>
                                    {/* <Button className='downloadStatsButton' style={{ backgroundColor: 'white' }} type="ghost">
                                        <DownloadOutlined style={{ fontSize: "19px", color: 'black' }} />
                                    </Button> */}
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>

                <Col className={`${props.notifications.visible ? "slideTop2" : "slideTop2Reversed"}`} xs={24} sm={24} md={8} lg={12} xl={12}>
                    <div style={{ padding: '8px 8px', borderRadius: '3px' }}>
                        <Card className={`${data.expiredGateways > 0 ? 'changeColor' : ''}`} size="small" style={{ backgroundColor: "#d1d1d1", backgroundImage: "linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))", borderRadius: "5px", padding: "1px", margin: "1px" }} >
                            <Row style={{ height: 80 }} gutter={[0,0]}>
                                <Col style={{ color: "#4a4a4a" }} span={24}>
                                    <span style={{ color: "#4a4a4a", fontSize: 26, marginRight: "6px" }}>{ numberWithCommas(data.expiredGateways) } Assets</span>
                                    <span>(Expired Contracts)</span>
                                    {/* <Button className='downloadStatsButton' style={{ backgroundColor: 'white' }} type="ghost">
                                        <DownloadOutlined style={{ fontSize: "19px", color: 'black' }} />
                                    </Button> */}
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Col>

                

            </Row>
        </div>
    )

}




const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        notifications: state.contextReducer.notifications,
        stats: state.contextReducer.stats,
        fleetOverviewState: state.fleetOverviewReducers.fleetOverviewState,
        permissions: state.contextReducer.data,
    };
};

export default connect(
    mapStateToProps,
    { 
        ...rootActions,
    }
)(React.memo(NotificationsOverview));