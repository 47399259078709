import React, { useEffect } from "react";
import { Table, Checkbox, Space, Tooltip, Input, Select, Col, Row, Button, Divider } from 'antd';
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import * as actions from "../actions";
import history from '../../../utils/history';
import { stringToUrlString } from "../../../utils/commonHelpers";

import "../assets.css";

const { Search } = Input;
const { Option } = Select;

interface Prop {
    assetMaintClose: Function,    
    assetsList: any
}


const AssetMaintControllerInfo: React.FC<Prop> = (props) => {
        
    const { tableConfig, attachedControllersTableData, controllersList } = props.assetsList.assetMaint.tabs[3];

    const columns = [
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
            render: (text: any, record: any) => (     
                <span>{record.address}</span>
            )
        },
        {
            title: "Serial Number",
            dataIndex: "serialNumber",
            key: "serialNumber",
            render: (text: any, record: any) => (     
                <span>{record.serialNumber}</span>
            )
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (text: any, record: any) => (     
                <span>{record.type}</span>
            )
        },
        {
            title: "Device Info",
            dataIndex: "controllerName",
            key: "controllerName",
            render: (text: any, record: any) => (     
                <span>{record.controllerName}</span>
            )
        },
        // {
        //     title: "Controller Assignment",
        //     dataIndex: "controllerId",
        //     key: "controllerId",
        //     render: (text: any, record: any) => (   
                
        //         <Select placeholder="Select Controller" style={{ width: "100%" }}>
        //             {controllersList !== null && controllersList.map((controller:any, index:number) => (
        //                 <Option key={controller.controllerId} value={controller.controllerId} >
        //                     {controller.controllerName}
        //                 </Option>
        //             ))}
        //         </Select >
        //     )
        // }
    ];
          
        return (     
            
            <Row gutter={8}>
                <Col span={18}>
                </Col>
                <Col span={6}>
                    <Button block type="primary">
                        Discover Devices
                    </Button>
                </Col>

                <Divider></Divider>

                <Col span={24}>
                    <h3>Connected Devices (Controllers) List:</h3>
                    <Table 
                        data-id="assets-maint-controller-data" 
                        rowKey={(record) => record.key}
                        {...tableConfig}
                        columns={columns} 
                        dataSource={attachedControllersTableData} 
                    />
                </Col>
            </Row>
        )
}

const mapStateToProps = (state:any) => {
    return {
        assetsList: state.assetsReducer.assetsState.assetsList
    };
};

export default connect(
    mapStateToProps,
    actions
)(AssetMaintControllerInfo);