import React,{CSSProperties} from "react";
import { Link } from "react-router-dom";
import { Space, Badge, Switch,Tooltip,Row,Col } from "antd";
import { ReactComponent as BellSvg } from "../../assets/images/bell.svg";
import { ReactComponent as Dm1Pin } from "../../assets/images/map-pins/dm1-pin.svg";
import { InputNumberProps } from "antd/lib/input-number";
import history from '../../utils/history';
import { stringToUrlString } from "../../utils/commonHelpers";

interface Props {  
  onLayoutViewChange: (checked: boolean) => void;
  alertInfoCount: number;
  alertWarningCount: number;
  alertCriticalCount: number;
  alertDm1Count: number;
  name: string;
  mastertag?: string;
  userRole: string;
  isCreate: boolean;
  isRead: boolean;
  isUpdate: boolean;
  isDelete: boolean;
}

const PageHeaderExtra: React.FC<Props> = (props) => {
  const {    
    onLayoutViewChange,
    alertInfoCount,
    alertWarningCount,
    alertCriticalCount,
    alertDm1Count,
    name,
    mastertag,
    userRole,
    isCreate,
    isRead,
    isUpdate,
    isDelete,
  } = props;

  const styles: CSSProperties = {
    fontSize: "14px",
    fontWeight: 400,
    verticalAlign: "text-bottom",
    marginRight: "10px",
  };

  return (  
    <>
 {/* <Space key="page-header-space" direction="horizontal" size="small" className="asset-header"> */}
  <Space key="page-header-space" direction="horizontal" size="small" >
  
        <Link to={{
           pathname: "/alerts",
           state: [{ mode: "signal-alarms", mastertag: mastertag }]
        }}>
            {/*<Badge count={alertInfoCount + alertWarningCount + alertCriticalCount}>*/}
                <Tooltip title="Signal Alarms"  >
                    <BellSvg style={{ verticalAlign: "middle" }} />
                </Tooltip>
            {/*</Badge>*/}
        </Link>
        
        <Link to={{
            pathname: "/alerts",
            state: [{ mode: "dm1-faults", mastertag: mastertag }]
        }}>
            {/*<Badge count={alertInfoCount + alertWarningCount + alertCriticalCount}>*/}
                <Tooltip title="DM1 Faults" >
                    <Dm1Pin style={{ verticalAlign: "middle" }} />
                </Tooltip>
            {/*</Badge>*/}
        </Link> 

        {isUpdate ?
          <Switch
            key="layoutView"
            checkedChildren="Off"
            unCheckedChildren="On"
            onChange={onLayoutViewChange}
          /> : ''
        } 
      </Space>  
      {/* <Space key="page-header-space" direction="horizontal" size="small" className="asset-header">
        <Link to={{
          pathname:"/alerts/signal-alarms/" + mastertag
          //state:[{mastertag:mastertag, mode:"2"}]
          }}>
          <Badge count={alertInfoCount + alertWarningCount + alertCriticalCount}>
            <Tooltip title="Signal Alarms"  >
              <BellSvg style={{ verticalAlign: "middle" }} /> 
            </Tooltip>        
          </Badge>
        </Link>
         <Link to={{
          pathname:"/alerts/dm1-faults/" + mastertag
          //state:[{mastertag:mastertag, mode:"1"}]
          }}>
          <Badge count={alertInfoCount + alertWarningCount + alertCriticalCount}>
            <Tooltip title="DM1 Faults" >
              <Dm1Pin style={{ verticalAlign: "middle" }} /> 
            </Tooltip>        
          </Badge>
        </Link>
      </Space>        */}
    </>
  );
};


export default PageHeaderExtra;