import React, { useRef, useLayoutEffect } from "react";
import LineChart, { Options } from "./LineChart";
import useDomNode from "../../../../lib/use-dom-node";
import { CommonWidgetProps, LineWidgetProps } from "../../models";
import { inputSignalMapper } from "../common";
import { Empty } from "antd";

const createSignalSeries = inputSignalMapper((meta, data) => ({
  ...meta,
  values: data,
}));

interface LineChartProps extends CommonWidgetProps,LineWidgetProps {
  settings?: Options;
  errorCode?: string;
}

const Widget: React.FC<LineChartProps> = (props) => {
  const chartRef = useRef<LineChart>();
  const [node, setNode] = useDomNode<HTMLDivElement>();

  const { template, data, signals, settings,rightYAxisSignals,rightYAxisData } = props;

  useLayoutEffect(() => {
    if (node) {
      chartRef.current = new LineChart(
        node,
        createSignalSeries(signals, template, data),
        createSignalSeries((rightYAxisSignals ||[]), template, rightYAxisData),
        settings
      );
      return () => chartRef.current?.destroy();
    }
  }, [node, signals, template, settings, data,rightYAxisSignals,rightYAxisData]);

  if (props.errorCode === "404") {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  } else if (props.errorCode === "500") {
    return <Empty description="signal Id is null" />;
  } else {
    return <div style={{ height: "100%" }} ref={setNode} />;
  }
};

export default Widget;
