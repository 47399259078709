import React from "react";
import { inputSignalMapper, signalLabel } from "../common";
import { CommonWidgetProps } from "../../models";
import {
  Map,
  Marker,
  MapActions,
} from "../../../../sharedComponents/GoogleMap";
import { toolTipForAsset } from "./Tooltip";
import MapPin from "../../../../assets/images/map-pin.png";

interface Signal {
  name: string;
  values: Array<{
    timestamp: Date;
    value: number;
  }>;
}

function assetToMarker(signals: Signal[]): Marker[] {
  const latitude = signals.filter((s) => s.name.toLowerCase().indexOf('latitude') !== -1)[0] || [];
  const longitude = signals.filter((s) => s.name.toLowerCase().indexOf('longitude') !== -1)[0] || [];

  const markers = latitude.values.map((s, i) => {
    const lat = s.value;
    const timestamp = s.timestamp;
    const lng = longitude.values[i].value;
    return {
      id: `${i}`,
      position: {
        lat: lat,
        lng: lng,
      },
      tooltip: toolTipForAsset({ lat, lng }, timestamp),
      icon: MapPin,
    } as Marker;
  });

  return markers;
}

const createSignals = inputSignalMapper<Signal>((meta, data) => ({
  name: signalLabel(meta, false),
  values: data,
}));

const Widget: React.FC<CommonWidgetProps> = (props) => {
  const { template, data } = props;
  const signals = createSignals(props.signals, template, data);

  return (
    <div style={{ height: "100%", position: "relative" }}>
      {signals.length > 1 ? (
        <Map
          data-id="map-container"
          markers={assetToMarker(signals)}
          clusterMarkers={false}
          mapOptions={{ mapTypeId: "satellite" }}
          polyline={true}
          drawOverlays={false}
        ></Map>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Widget;
