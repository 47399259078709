import {
  XYChart,
  ColumnSeries,
  ValueAxis,
  Legend,
  DateAxis,
  XYCursor,
} from "@amcharts/amcharts4/charts";
import * as amCore from "@amcharts/amcharts4/core";

import { AxisRange } from "../models";
import {
  DATE_FORMAT,
  TOOLTIP_FORMAT,
  NUMBER_FORMAT,
  signalLabel,
  findTimezone,
} from "../common";
import { appConfigs } from "../../../../utils/configurations";

export interface Options {
  axisRange?: AxisRange;
}

export type TimeSeriesSignal = {
  name: string;
  color: string;
  units: string;
  userPreferences:any;
  values: Array<{ timestamp: Date; value: number }>;
};

export default class ClusterBarChart {
  private _chart: XYChart;
  private  count = 0 ;
  private timezone:any;
  constructor(ref: HTMLDivElement, series: TimeSeriesSignal[], opts?: Options,state?:any) {
    const chart = amCore.create(ref, XYChart); 
    chart.colors.step = 3;
    
    
  
    const xAxis = chart.xAxes.push(new DateAxis());
    xAxis.renderer.cellStartLocation = 0.1
    xAxis.renderer.cellEndLocation = 0.9
    xAxis.renderer.grid.template.location = 0;
    xAxis.cursorTooltipEnabled = false;
    this.timezone=state.timezone;
    const yAxis = chart.yAxes.push(new ValueAxis());
    yAxis.cursorTooltipEnabled = false;
    yAxis.min = opts?.axisRange?.min;
    yAxis.max = opts?.axisRange?.max;

    const cursor = (chart.cursor = new XYCursor());
    cursor.lineY.disabled = true;
    cursor.lineX.disabled = true;
    // Show only one tooltip
    // https://www.amcharts.com/docs/v4/concepts/chart-cursor/#Relation_to_series
    cursor.maxTooltipDistance = -1;

    chart.legend = new Legend();
    chart.legend.maxWidth = 1000000;
    chart.legend.useDefaultMarker = true;
    // chart.legend.labels.template.truncate = false;
    chart.legend.labels.template.maxWidth = 1000000;
    chart.legend.valueLabels.template.align = "left";
    chart.legend.valueLabels.template.textAlign = "start";
    chart.legend.contentAlign = "left";
    chart.legend.itemContainers.template.paddingTop = 1;
    chart.legend.itemContainers.template.paddingBottom = 1;
    chart.legend.itemContainers.template.tooltipText = "{name}";

    chart.legend.markers.template.width = 15;
    chart.legend.markers.template.height = 15;

    chart.paddingLeft = 0;
    chart.marginLeft = 0;
    chart.marginTop = 12;

    chart.dateFormatter.dateFormat = DATE_FORMAT;
    chart.numberFormatter.numberFormat = NUMBER_FORMAT;
  
    this._chart = chart;
   
    series.forEach(this.addSeries);
  }
   
  addSeries = (dataSeries: TimeSeriesSignal) => {

    const series = new ColumnSeries();
   
    series.dataFields = {
      valueY: "value",
      dateX: "timestamp",
    };
    series.fill = dataSeries.color ? amCore.color(dataSeries.color) : amCore.color(appConfigs.colorCodes.colorCodesLineChart[this.count]);

    series.data = dataSeries.values;     
    series.strokeWidth = 4;  
    series.paddingRight = 2;
    series.name = signalLabel(dataSeries,dataSeries.userPreferences);
    var tooltipDetails:any;
    if(dataSeries && dataSeries.values.length>0){
     tooltipDetails=findTimezone(this.timezone);
    }
    series.tooltipText = "{dateX}"+" "+tooltipDetails?.timezone+"\n{name}: {valueY}";

    this._chart.series.push(series);

    this.count++;
  };

  destroy() {
    this._chart?.dispose();
  }
}
