import React, { useReducer, useEffect, useMemo, useState, useRef } from "react";
import { Grid, Button, PageHeader, Empty, message } from "antd";
import { SizeMe } from "react-sizeme";
import GridLayout, { ReactGridLayoutProps, Layout } from "react-grid-layout";
import { CSVLink } from "react-csv";
import "react-grid-layout/css/styles.css";
import {
  reducer,
  bindActions,
  initState,
  WidgetState,
  computeLayout,
} from "./state";
import { WidgetConfig } from "./widgets";
import WidgetContainer from "./WidgetContainer";
import { PlusOutlined, CalculatorFilled } from "@ant-design/icons";
import WidgetEditDrawer from "./WidgetEditForm";
import * as Api from "./api";
import { loadingIndicatorEnd, loadingIndicatorStart, uuidv4, getPermissions } from "../../utils/commonHelpers";
import {
  editWidgetStructureForDb,
  addWidgetStructureForDb,
  saveLayoutDb,
} from "./widgets/widgetapi";
import { DBWidgetConfig } from "./models";
import Header from "./Header";
import { errorResponse } from "../../utils/apiHelpers/apiHelpers";
import "./AssetDashboard.css";
import { useAssetStatus } from "./useAssetStatus";
import AssetStatus from "./AssetStatus";
import { WidgetFilterConfig, WidgetFilterDrawer } from "./WidgetFilterForm";
import {
  WidgetExportConfig,
  WidgetExportDrawer,
  exportCsv,
  prepareWidgetEvents,
} from "./WidgetExportForm";
import { useAssetAlerts } from "./useAssetAlerts";
import PageHeaderExtra from "./PageHeaderExtra";
import { useExport } from "./WidgetExportForm/useExport";
import { connect } from "react-redux";
//import { getPermissions } from "../../actions/permissions";

const { useBreakpoint } = Grid;

type Breakpoints = ReturnType<typeof useBreakpoint>;

function columnNumber(breakpoints: Breakpoints) {
  if (breakpoints.xl) {
    return 4;
  } else if (breakpoints.lg) {
    return 3;
  } else if (breakpoints.md) {
    return 2;
  } else {
    return 1;
  }
}

interface EditState {
  visible: boolean;
  title: string;
  onFinish: (cfg: WidgetConfig) => void;
  config?: WidgetConfig;
  onDualChange: (checked: boolean) => void;
  enabledualaxis:boolean;
}

interface PageHeaderExtraProps {
  onLayoutViewChange: (checked: boolean) => void;
  alertInfoCount: number;
  alertWarningCount: number;
  alertCriticalCount: number;
  alertDm1Count: number;
  name: string;
  mastertag: string;
  userRole: string; 
  isCreate: boolean;
  isRead: boolean;
  isUpdate: boolean;
  isDelete: boolean;
}

interface FilterState {
  visible: boolean;
  title: string;
  onFinish: (cfg: WidgetFilterConfig) => void;
  config?: WidgetFilterConfig;
}

interface ExportState {
  visible: boolean;
  title: string;
  onFinish: (cfg: WidgetExportConfig) => void;
}

interface Props{
  context: any,
  permissions: any
}

const DEFAULT_FILTER_STATE: FilterState = {
  visible: false,
  title: "",
  onFinish: (cfg) => {},
};

const DEFAULT_EXPORT_STATE: ExportState = {
  visible: false,
  title: "",
  onFinish: (cfg) => {},
};

const DEFAULT_EDIT_STATE: EditState = {
  visible: false,
  title: "",
  onFinish: (cfg) => {},
  onDualChange:(checked)=>{},
  enabledualaxis:false
};

const GRID_SETTINGS: ReactGridLayoutProps = {
  rowHeight: 400,
  compactType: "vertical",
  containerPadding: [0, 0],
  isDraggable: false,
  isResizable: false,
};

const AssetDashboard: React.FC<{ initialData: Api.AssetDashboardResponse, context: any, permissions: any}> = (
  props
) => {
  const columns = columnNumber(useBreakpoint());
  
  const [state, dispatch] = useReducer(
    reducer,
    initState(props.initialData, columns, props.context)
  );

  const { contextUser, appContext} = props.context;  

  const result = getPermissions(props.permissions.permissions.asset_widgets);
  const assets = getPermissions(props.permissions.permissions.assets);
  const templates = getPermissions(props.permissions.permissions.templates);
  //ops-2839: Remove the iqanconnect key permission check  
  //const iqanKey = getPermissions(props.permissions.permissions.iqan_key);
  
  // let isBarko = false;
  // if(appContext.roleName.toLowerCase() === 'guest'){
  //   const barkoId = '17ad9f98-33df-48a0-8c4f-99468e22e129'; 
  //   for(let i=0; i< props.context.user.homeOrg.orgTree.length; i++){      
  //     if(props.context.user.homeOrg.orgTree[i].orgId === barkoId){
  //         isBarko = true;
  //     }
  //   } 
  // }
    
    const permissionResult = getPermissions(props.permissions.permissions.operator_support);
    let isOprRead = permissionResult[1];
    let isOprUpdate = permissionResult[2];    

  const [editState, setEditState] = useState<EditState>(DEFAULT_EDIT_STATE);
  //enable and disable the layout resizing option
  const [gridResize, setGridResize] = useState<ReactGridLayoutProps>(
    GRID_SETTINGS
  );
  const [filterState, setFilterState] = useState<FilterState>(
    DEFAULT_FILTER_STATE
  );
  const [exportState, setExportState] = useState<ExportState>(
    DEFAULT_EXPORT_STATE
  );
  const csvLink = useRef<any>(null);
  const assetStatus = useAssetStatus(state.asset.id);
  const [
    dataForDownload,
    setDataForDownload,
    bDownloadReady,
    setDownloadReady,
    exportHeader,
    setexportHeader,
    exportFileName,
    setExportFileName,
  ] = useExport(csvLink);  

  const {
    addWidget,
    deleteWidget,
    updateLayout,
    saveLayout,
    updateWidget,
    saveLayoutSuccess,
    clearWidgetFilter,
    updateWidgetFilter,
  } = useMemo(() => bindActions(dispatch), [dispatch]);

  useEffect(() => {
    updateLayout(columns);
  }, [columns, updateLayout]);

  

  const createNewWidget = () => {
    let widgetColumns:number;
    if(appContext.orgId==='4d5b9621-2b2b-11ee-8a6d-739e920ccfbe'){
      widgetColumns=6;
    }else{
      widgetColumns=4;
    }
    const uuid = uuidv4();
    setEditState({
      visible: true,
      title: "Create New Widget",
      onFinish: (cfg) => saveWidgets(uuid, cfg, "Add",widgetColumns),
      onDualChange:(checked)=>onDualChange(checked),
      enabledualaxis:false
    });
  };

  const saveWidgets = (widgetId: string, cfg: WidgetConfig, action: string,widgetColumns:number) => {
    let signalStructure: DBWidgetConfig;
    const cnfg: any = cfg;
    if (action === "Edit") {
      const widgetState =
        state.widgets.filter((w) => w.id === widgetId)[0] || [];
      signalStructure = editWidgetStructureForDb(
        widgetId,
        cfg,
        state.template,
        widgetState
      );
    } else {
      signalStructure = addWidgetStructureForDb(
        widgetId,
        cfg,
        state.template,
        state.widgets,
        widgetColumns
      );
    }

    Api.saveWidget(state.asset.templateId, signalStructure)
      .then((data) => {
        if (action === "Edit") {
          updateWidget(widgetId, signalStructure);
        } else {
          addWidget(widgetId, signalStructure);
        }
      })
      .catch((error) => {
        errorResponse(null, error);
        message.error("Errror in saving the widget");
      });
    // hideEditForm();
  };

  const deleteWidgt = (widgetId: string) => {
    Api.deleteWidget(state.asset.templateId, widgetId)
      .then((data) => {
        deleteWidget(widgetId);
      })
      .catch((error) => {
        errorResponse(null, error);
        message.error("Errror in deleting the selected widget");
      });
    // hideEditForm();
  };

  const editWidget = (w: WidgetState) => {
    let widgetColumns:number;
    if(appContext.orgId==='4d5b9621-2b2b-11ee-8a6d-739e920ccfbe'){
      widgetColumns=6;
    }else{
      widgetColumns=4;
    }
    setEditState({
      visible: true,
      title: "Edit Widget",
      onFinish: (cfg) => saveWidgets(w.id, cfg, "Edit",widgetColumns),
      config: w,
      onDualChange:(checked)=>onDualChange(checked),
      enabledualaxis:w.rightYAxisSignals && w.rightYAxisSignals?.length>0?true:false
    });
  };

  const filerWidget = (wid: string, cfg: WidgetFilterConfig) => {
    updateWidgetFilter(wid, cfg);
  };

  const exprtWidget = (widgetState: WidgetState, cfg: WidgetExportConfig) => {
    exportCsv(state, widgetState, cfg)
      .then((data) => {
        loadingIndicatorStart();
        const csvExportDetails = prepareWidgetEvents(data, state, widgetState);
        
        setexportHeader(csvExportDetails.eventHeader);
        setExportFileName(`${state.asset.name}_${widgetState.title}_${cfg.startDate.format('YYYY-MM-DD')}_${cfg.endDate.format('YYYY-MM-DD')}.csv`);
        setDataForDownload(csvExportDetails.eventArray);
        setDownloadReady(true);
        loadingIndicatorEnd();
      })
      .catch((error) => {
        errorResponse(null, error);
        setDownloadReady(false);
        loadingIndicatorEnd();
      });
  };

  const filterWidget = (w: WidgetState) => {
    const config: WidgetFilterConfig = {
      startDate: w.startDate,
      startTime: w.startTime,
      endDate: w.endDate,
      endTime: w.endTime,
      datapoint: w.datapoint || 50,
    };
    setFilterState({
      visible: true,
      title: "Widget Filter",
      onFinish: (cfg) => filerWidget(w.id, cfg),
      config: config,
    });
  };

  const exportWidget = (w: WidgetState) => {
    setExportState({
      visible: true,
      title: "Widget Export",
      onFinish: (cfg) => exprtWidget(w, cfg),
    });
  };

  const hideEditForm = () => {
    setEditState((s) => ({
      ...s,
      visible: false,
    }));
  };

  const hideFilterForm = () => {
    setFilterState((s) => ({
      ...s,
      visible: false,
    }));
  };

  const hideExportForm = () => {
    setExportState((s) => ({
      ...s,
      visible: false,
    }));
  };

  const onLayoutViewChange = (checked: boolean) => {
    setGridResize({
      ...GRID_SETTINGS,
      isDraggable: checked,
      isResizable: checked,
    });
    if (!checked) {
      const newLayout = saveLayoutDb(state.layout);
      Api.saveLayoutChange(state.asset.templateId, newLayout)
        .then((data) => saveLayoutSuccess(state.layout))
        .catch((err) => {
          errorResponse(null, err);
          message.error("Error occured while saving the new layout");
        });
    }
  };

  const NewWidgetButton = ({ label }: { label: string }) => (
      <Button type="primary" icon={<PlusOutlined />} onClick={createNewWidget} className="asset-buttonwidth">
      {label}
    </Button>           
  );

  const {
    alertDm1Count = 0,
    alertInfoCount = 0,
    alertWarningCount = 0,
    alertCriticalCount = 0,    
    name,
    mastertag,
    isRead = result[1],
    isUpdate = result[2],
    isassetUpdate = assets[2],
    istemplatesRead = templates[1]
  } = state.asset;

  const pageHeaderExtraProps: PageHeaderExtraProps = {
    onLayoutViewChange: (checked: boolean) => onLayoutViewChange(checked),
    alertDm1Count,
    alertInfoCount,
    alertWarningCount,
    alertCriticalCount,
    name,
    mastertag,
    userRole: appContext.roleName, 
    isCreate: result[0],
    isRead: result[1],
    isUpdate: result[2],
    isDelete: result[3]  
  };
  
  const onDualChange=(checked: boolean)=>{
    setEditState((prevState) => (
      { 
      ...prevState, 
      enabledualaxis:checked 
      }));
  }

  return (
    <>
      <PageHeader
        title=""
        subTitle={state.loaded ? <Header isOprRead={isOprRead} isOprUpdate={isOprUpdate} {...state.asset } /> : null}
        //added a key
        extra={
          state.columns === 4
            ? [
                <PageHeaderExtra
                  key="pageHeaderExtra"
                  {...pageHeaderExtraProps}
                />,
                result[0] === true ?
                <NewWidgetButton key="widgetAdd" label="Add Widget" /> : '',
              ]
            : 
            result[0] === true ? [<NewWidgetButton key="widgetAdd" label="Add Widget"  />] : ''             
        }
      >
        <PageContent>
          {          
          state.widgets.length > 0 ? (
            <SizeMe>
              {({ size }) => (
                <>
                  <GridLayout
                    {...gridResize}
                    cols={state.columns}
                    width={size.width || 200}
                    layout={state.layout}
                    onLayoutChange={saveLayout}
                  >
                    
                    { 
                    state.widgets.map((w) => {
                      return (
                        <div key={w.id}>
                          <WidgetContainer
                            widgetState={w}
                            assetId={state.asset.id}
                            template={state.template}
                            onEdit={() => editWidget(w)}
                            onDelete={() => deleteWidgt(w.id)}
                            onFilter={() => filterWidget(w)}
                            onExport={() => exportWidget(w)}
                            userRole={appContext.roleName}
                            isCreate={result[0]}
                            isRead={result[1]}
                            isUpdate={result[2]}
                            isDelete={result[3]} 
                            //isIqanKey={iqanKey[1]} //ops-2839: Remove the iqanconnect key permission check 
                          ></WidgetContainer>
                        </div>
                      );
                    })}
                  </GridLayout>
                </>
              )}
            </SizeMe>
          ) : ( appContext.roleName === "admin" || appContext.roleName === "user" ?
            <Empty description="This dashboard has no widgets">
              <NewWidgetButton label="Create a Widget" />
            </Empty> : ''
          )
          }
          
          <WidgetEditDrawer
            {...editState}
            template={state.template}
            onClose={hideEditForm}
          />
          <WidgetFilterDrawer {...filterState} onClose={hideFilterForm} />
          <WidgetExportDrawer {...exportState} onClose={hideExportForm} />
        </PageContent>
      </PageHeader>
      <CSVLink
        data={dataForDownload}
        headers={exportHeader}
        filename={exportFileName}
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </>
  );
};

const PageContent: React.FC = ({ children }) => {
  return <div className="pagecontent">{children}</div>;
};

const mapStateToProps = (state:any) => {
  return {
      context: state.contextReducer.context,
      permissions: state.contextReducer.data,      
  };
};

export default connect(
  mapStateToProps
)(AssetDashboard);
