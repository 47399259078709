import { appConfigs } from "../../../utils/configurations";

export const initialState = {
    searchText: "",
    tableData: [],
    tableConfig : { 
        pagination : {                  
            showSizeChanger: appConfigs.tables.showSizeChanger,
            showQuickJumper: appConfigs.tables.showQuickJumper,
            pageSize: appConfigs.tables.pageSize,
            defaultCurrent: appConfigs.tables.defaultCurrent,
            current: appConfigs.tables.defaultCurrent,
            total: appConfigs.tables.defaultTotal,
            hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
            responsive: appConfigs.tables.responsive,
            onChange: null,
            showLessItems: appConfigs.tables.showLessItems,  
            showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} countr${total > 1 ? 'ies' : 'y'}`
        }
    }
};
  