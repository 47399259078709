import React, { Component } from "react";
import { Table, Button, Tooltip, message, Modal, Radio} from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { 
    CheckCircleOutlined, CloseCircleOutlined
} from "@ant-design/icons";

import history from '../../utils/history';
import { localizeUtcDate, formatGps, getPermissions, formatNumber } from "../../utils/commonHelpers";

import { connect } from "react-redux";
import * as actions from "./actions";

import AlertsSignalsFilters from "./AlertsSignalsFilters";
import "./alerts.css";
//import { getPermissions } from "../../actions/permissions";

interface Prop {
    alertsSignalsSetPagination: Function,
    alertsSignals: Function,
    alertsSignalsFilters: Function,
    markAlerts: Function,
    getAsset: Function,    
    context: any,
    alertsState: any,
    permissions: any,
    mastertag: string,
    status: string,
    isread: boolean,
    selectedType: Function
}

interface State {
    nickname: string[],
    selectedRowKeys: string[], 
    loading: boolean,
    isModalVisible: boolean,
    viewAlertType: string,
    deleteAlerts: boolean   
}

class AssetsSignals extends Component<Prop, State> {
    
    state:State = {
        nickname: [],
        selectedRowKeys: [], 
        loading: false,
        isModalVisible: false,
        viewAlertType: "unseen",
        deleteAlerts: false
    }

    componentDidMount () {   
        if ((this.props.mastertag || "") !== "") {
            this.props.getAsset(this, this.getAssetSuccess, null, this.props.mastertag);                         
        }
        else {
            this.componentUpdate();    
        }
    }  

    getAssetSuccess = (mastertag: string, asset:any) => {   
        this.setState({ nickname: [asset.nickname] });
        this.componentUpdate(); 
    };

    componentUpdate = () => {
        const appliedFilters = this.props.alertsState.tabs[1].appliedFilters;
        this.props.alertsSignalsSetPagination(this.onPaginationChange);
        if(!this.filtersApplied()){
            this.props.alertsSignals(this, null, null, 1, null, null, this.state.viewAlertType, null, null, this.state.nickname, this.props.status ? [this.props.status] : null);   
            this.props.alertsSignalsFilters(this, null, null);
        }
        else
        {
            this.props.alertsSignals(this, null, null, 1, null, null, this.state.viewAlertType,
                appliedFilters.timespanFrom, appliedFilters.timespanTo, appliedFilters.nickname,
                appliedFilters.status);
        }
    };

    onPaginationChange = (page: number) => {        
        const appliedFilters = this.props.alertsState.tabs[1].appliedFilters;     
        this.props.alertsSignals(
            this, 
            null, 
            null, 
            page,
            null,
            null,
            this.state.viewAlertType,
            appliedFilters.timespanFrom,
            appliedFilters.timespanTo,
            appliedFilters.nickname,
            appliedFilters.status
        );
    };

    goToAsset = (e: any, mastertag: string) => {
        e.preventDefault();
        history.push("/assets/" + mastertag);
    };

    onSelectChange = (selectedRowKeys:any) => {
        this.setState({ selectedRowKeys });
      };

      filtersApplied = () => {
        const appliedFilters = this.props.alertsState.tabs[1].appliedFilters;
        let filtersApplied = false;
        return filtersApplied = 
            (appliedFilters.nickname && appliedFilters.nickname.length || 0) || //eslint-disable-line 
            (appliedFilters.status && appliedFilters.status.length || 0) || //eslint-disable-line 
            (appliedFilters.tags && appliedFilters.tags.length || 0) || //eslint-disable-line 
            ((appliedFilters.timespanFrom || "") !== "") || //eslint-disable-line  
            ((appliedFilters.timespanTo || "") !== ""); //eslint-disable-line         
      }

    markAlertsSuccess = (data:any) => {        
        let status = (this.state.deleteAlerts) ?
        (this.state.selectedRowKeys.length>0? `Selected  ${(this.state.viewAlertType === 'seen')? 'Seen' : 'Unseen'} alerts got deleted.`
        : (this.filtersApplied()? `All the filtered ${(this.state.viewAlertType === 'seen')? 'Seen' : 'Unseen'} alerts got deleted.` 
        :`All the ${(this.state.viewAlertType === 'seen')? 'Seen' : 'Unseen'} alerts got deleted.`))
        :
        `Selected alerts are marked as ${(this.state.viewAlertType === 'unseen')? 'Seen' : 'Unseen'}`
        message.success(status);
        this.setState({loading: false, selectedRowKeys:[]});
        this.componentUpdate();
    }
     markAlertsFail = (error:any) => {
         this.setState({loading: false, selectedRowKeys:[]});
    //     message.error("Selected alerts failed to delete "+error.Error);        
     }
    deleteSignalAlerts = () => {
        const appliedFilters = this.props.alertsState.tabs[1].appliedFilters; 
        this.props.markAlerts(this,this.markAlertsSuccess,this.markAlertsFail,this.state.selectedRowKeys, 
            this .state.viewAlertType,
            "signals",
            this.state.deleteAlerts, 
            appliedFilters.timespanFrom,
            appliedFilters.timespanTo,
            null,
            null,
            appliedFilters.nickname,
            null,
            null,
            null,
            appliedFilters.status,
            appliedFilters.tags
    );
        this.setState({loading: true});
    }
    showModal = (e:any, isDeleted: boolean) => {
        if(isDeleted)
        {
            this.setState({deleteAlerts: true}); 
        }
        else
        {
            this.setState({deleteAlerts: false});
        }
        this.setState({isModalVisible: true});
      };
    
    handleOk = () => {
        this.setState({isModalVisible: false});
        this.deleteSignalAlerts();
      };
    
    handleCancel = () => {
        this.setState({isModalVisible: false});
        this.setState({loading: false, selectedRowKeys:[]});
      };

    onChangeViewAlert = (e:any) => {
        const appliedFilters = this.props.alertsState.tabs[1].appliedFilters;
        this.setState({
            viewAlertType: e.target.value,
            selectedRowKeys: [] 
        });
        this.props.selectedType(e.target.value);
        //this.props.alertsSignals(this, null, null, 1, null, null, e.target.value, null, null, this.state.nickname, this.props.status ? [this.props.status] : null);
        this.props.alertsSignals(
            this, 
            null, 
            null, 
            1,
            null,
            null,
            e.target.value,
            appliedFilters.timespanFrom,
            appliedFilters.timespanTo,
            appliedFilters.nickname,
            appliedFilters.status
        );
    };

    render() {    
        const result = getPermissions(this.props.permissions.permissions.alert_settings);      
        let isDelete = result[3];
        const { tableConfig, tableData } = this.props.alertsState.tabs[1];       
        const { loading, selectedRowKeys, viewAlertType } = this.state;
        
        const rowSelection = (isDelete)? {
            selectedRowKeys,
            onChange: this.onSelectChange,
        }:{getCheckboxProps: (record:any)=>({disabled: true})};
        const hasSelected = selectedRowKeys.length > 0;

        const tableColumns = [
            {
                title: "Status",
                dataIndex: "severity",
                render: (text: any, record: any) => (
                    <span className="no-word-wrap">                        
                        {
                            record.transition === "positive" &&
                            record.severity === "Info" &&
                            <><span className="dot alert-status-blue "/>{record.severity}</>
                        }
                        {
                            record.transition === "positive" &&
                            record.severity === "Critical" &&
                            <><span className="dot alert-status-red "/>{record.severity}</>
                        }
                        {
                            record.transition === "positive" &&
                            record.severity === "Warning" &&
                            <><span className="dot alert-status-orange"/>{record.severity}</>
                        }
                        {
                            record.transition === "negative" &&
                            <><span className="dot alert-status-green"/>Normal</>
                        }
                    </span>
                )
            },
            {
                title: "Signal",
                dataIndex: "signalName"
            },
            {
                title: "Value",
                dataIndex: "signalDescription",
                render: (text: string, record: any) => (
                    <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
                         {formatNumber(text.split(/[:(]/)[1])}
                    </div>
                )
            },
            {
                title: "Detected",
                dataIndex: "triggerTime",
                responsive: ["lg"] as Breakpoint[],
                render: (triggerTime: string, record: any) => (
                    <>{localizeUtcDate(triggerTime, this.props.alertsState.dateFormatOptions)}</>                    
                ) 
            },
            {
                title: "Threshold",
                dataIndex: "signalThreshold",
                responsive: ["lg"] as Breakpoint[]
            },
            {
                title: "Location",
                responsive: ["lg"] as Breakpoint[],
                render: (text: any, record: any) => (
                    <>
                        {
                            record.locationLatitude &&
                            record.locationLongitude &&
                            <span>{formatGps(record.locationLatitude, record.locationLongitude)}</span>
                        }
                    </>
                    
                )              
            },
            {
                title: "Org Name",
                dataIndex: "orgName",
                responsive: ["lg"] as Breakpoint[],
                render: (text: any, record: any) => {
                    let parents = record?.orgPathName?.includes("~") ? record?.orgPathName.split('~') : [record?.orgPathName];
                    if (parents.length > 1) {
                        parents = parents.filter((parent: any) => {
                            if (!parent.toLowerCase().includes("parker")) {
                                return parent
                            }
                        })
                    }
                    return (
                        parents.length > 2 ?
                        <>
                            <span className="no-word-wrap">
                                <Tooltip overlayInnerStyle={{ width: '350px' }} title={
                                    parents.map((parent: any, index: number) => {
                                        return parents.length - 1 == index ? parent : parent + " \\ " 
                                    })
                                }>
                                    <span>{record.orgName}</span>
                                </Tooltip>
                            </span>  
                        </>
                        : <span>{record.orgName}</span>
                    )
                }
            },
            {
                title: "Asset",
                dataIndex: "nickname",
                responsive: ["lg"] as Breakpoint[],
                render: (text: any, record: any) => (
                    <a href="# " onClick={(e) => {this.goToAsset(e, record.mastertag)}}> 
                        {record.nickname || record.mastertag} 
                    </a>
                )              
            }
        ];

        return (
            <>               
                <AlertsSignalsFilters 
                    nickname={this.state.nickname} 
                    status={(this.props.status || "") === "" ? [] : [this.props.status]}                   
                    isread ={this.props.isread}
                    viewAlertType={this.state.viewAlertType}                   
                />
                {
                    this.props.isread &&
                    <div>
                        <br/>
                        <>
                        <div style = {{width:'85%' , display:'inline-block'}}>
                            <span style={{ margin: 8 }}> 
                                <Radio.Group value={viewAlertType}  buttonStyle="solid" onChange={this.onChangeViewAlert}  >
                                    <Tooltip mouseEnterDelay={1} placement="bottom" title="Unseen/New Alerts" arrowPointAtCenter>
                                        <div style={{ display: 'inline-block'}}>
                                            <Radio.Button value="unseen">
                                                <CheckCircleOutlined className="pr-5" />Unseen
                                            </Radio.Button>
                                        </div>
                                    </Tooltip>
                                    <Tooltip mouseEnterDelay={1} placement="bottom" title="Seen Alerts" arrowPointAtCenter>
                                        <div style={{ display: 'inline-block'}}>
                                            <Radio.Button value="seen">
                                                <CloseCircleOutlined className="pr-5" />Seen
                                            </Radio.Button>
                                        </div>
                                    </Tooltip>
                                </Radio.Group>
                            </span>
                        </div>
                        <div style = {{display:'inline-block'}}>
                          <Button  type="primary" 
                           disabled={(tableData || []).length === 0 || tableData === null || 
                            (isDelete || "") === "" || isDelete === null ||  !isDelete } 
                          onClick={(e:any)=>this.showModal(e,true)} danger>Clear {(viewAlertType === 'unseen')? 'Unseen' : 'Seen'} Alerts</Button>
                        </div>
                        </>
                        <br/>
                        <div style={{ margin: 16 }}>
                        
                        <Button type = "primary" onClick = {(e:any)=>this.showModal(e,false)} disabled={!hasSelected} loading={loading}>
                            {(viewAlertType === 'unseen')? 'Mark as Seen' : 'Mark as Unseen'}
                        </Button>
                        <Modal title="Confirm" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
                            { (this.state.deleteAlerts) ?
                                (selectedRowKeys.length>0? `Are you sure you want to delete the selected ${(viewAlertType === 'seen')? 'Seen' : 'Unseen'} alerts?`
                                :this.filtersApplied()?`Are you sure you want to delete the filtered ${(viewAlertType === 'seen')? 'Seen' : 'Unseen'} alerts?`
                                :`Are you sure you want to delete all the ${(viewAlertType === 'seen')? 'Seen' : 'Unseen'} alerts?`)
                                :
                                `Mark selected alerts as ${(viewAlertType === 'unseen')? 'Seen' : 'Unseen'}?`
                            }
                        </Modal>
                        <span style={{ marginLeft: 8 }}>
                            {hasSelected ? `Selected ${selectedRowKeys.length} alerts to mark as ${(viewAlertType === 'unseen')? 'Seen' : 'Unseen'}` : ''}
                        </span>
                        </div>
                    
                    <Table rowSelection={rowSelection}
                        data-id="alerts-signal-alarms-data"                
                        {...tableConfig}
                        rowKey={(record) => record.alertId}
                        columns={tableColumns}
                        dataSource={tableData}
                        className="mt-20"
                    />
                  </div>
                }
            </>
           
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        permissions: state.contextReducer.data,
        alertsState: state.alertsReducer.alertsState
    };
};

export default connect(
    mapStateToProps,
    actions
)(AssetsSignals);