import { WidgetFilterConfig } from ".";
import { appConfigs } from "../../../utils/configurations";
import { WidgetConfig } from "../widgets";
import { addDateAndTime, getUnixDate } from "../widgets/common";

function addUrlParams(url: string, filterConfig?: WidgetFilterConfig): string {
    if (filterConfig?.datapoint) {
      url += `?limit=${filterConfig.datapoint}`;
    }
    if(filterConfig?.startDate) {
      let unixDate = getUnixDate(addDateAndTime(filterConfig?.startDate, filterConfig?.startTime));
      if (url.indexOf("?") > 0) url += `&startDate=${unixDate}`;
          else url += `?startDate=${unixDate}`;
    }
    if(filterConfig?.endDate) {
      let unixDate = getUnixDate(addDateAndTime(filterConfig?.endDate, filterConfig?.endTime));
      if (url.indexOf("?") > 0) url += `&endDate=${unixDate}`;
          else url += `?endDate=${unixDate}`;
    }
    return url;
  }
  
  export function constructUrl(
    gatewayId: string,
    datapointId: string,
    filterConfig?: WidgetFilterConfig,
    type?: WidgetConfig["type"]
  ): string {
    let url: string = `${appConfigs.server.URL}/ui/api/assets/gateway/${gatewayId}/datapoint/${datapointId}/events`;
    switch (type) {
      case "text":
      case "boolean":
      case "bar":
      case "gauge":
      case "tracker":
        url += "/last";
        return url;
      case "line":
      case "map":
      case "clusterbar":
        return addUrlParams(url, filterConfig);
      default:
        url += "/last";
        return url;
    }
  }