import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";

export function signalCollectionMaintOpen(
    me: any, 
    width: number,
    record: any
) {
    return (dispatch: any) => {
        dispatch({ type: "SIGNAL_COLLECTION_MAINT_OPEN", payload: {width: width, record: record} });
    };
}

export function signalCollectionMaintClose(
    me: any
) {   
    return (dispatch: any) => {
        dispatch({ type: "SIGNAL_COLLECTION_MAINT_CLOSE" });
    };
}

export function signalCollectionSetRecord(
    me: any, 
    record: any
) {
    return (dispatch: any) => {
        dispatch({ type: "SIGNAL_COLLECTION_MAINT_SET_RECORD", record: record });
    };
}

export function signalCollectionMaintSubmit(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    record: any
) {
    const editMode = (record.signalCollectionId || "") !== "" ? "edit" : "insert";
    return async (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL + "/ui/api/templates/" + record.templateId + "/signal-collections",
            record,
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
                let record = response.data.data.signalCollections == null || (response.data.data.signalCollections.length || 0) === 0 ? {} : response.data.data.signalCollections[0];
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(editMode, record);
                }
                dispatch({ type: "SIGNAL_COLLECTION_MAINT_SUCCESS", record: record });
            }
        )
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

