export function supportModeInit(
    me: any,
    callbackFn: any
) {
    return (dispatch: any) => {
        dispatch({ type: "SUPPORT_MODE_INIT" });
        if (callbackFn != null) {
            callbackFn();
        }
    }
}   

export function supportModeSet(
    me: any,
    callbackFn: any,
    orgId: string,
    orgName: string
) {
    return (dispatch: any) => {
        dispatch({ type: "SUPPORT_MODE_SET", orgId: orgId, orgName: orgName });
        if (callbackFn != null) {
            callbackFn(orgId, orgName);
        }
    }
}   

export function supportModeUnSet(
    me: any,
    callbackFn: any
) {
    return (dispatch: any) => {
        dispatch({ type: "SUPPORT_MODE_UNSET" });
        if (callbackFn != null) {
            callbackFn();
        }
    }
}   