import React from "react";
import { Form } from "antd";

type FormProps = React.ComponentProps<typeof Form>;

/* eslint-disable no-template-curly-in-string */
const validationMessages: FormProps["validateMessages"] = {
  required: "${label} is required",
  types: {
    number: "${label} must be a number",
  },
};

export default validationMessages