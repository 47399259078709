import { appConfigs } from "../../../utils/configurations";

export const initialState = {
    csu: {
    csuList:{
        calibrationMaint: {
            instance: 0,
            width: 720,
            visible: false,
            record: {},
            title: "",
            activeTab: "1",               
            tabs: [
                {
                    title: "Calibration Info",
                    instance: 0,
                    disabled: false
                }
            ]
         },

        all:{
            tableData: [],
            tableConfig : { 
                pagination : {                  
                    showSizeChanger: appConfigs.tables.showSizeChanger,
                    showQuickJumper: appConfigs.tables.showQuickJumper,
                    pageSize: appConfigs.tables.pageSize,
                    defaultCurrent: appConfigs.tables.defaultCurrent,
                    current: appConfigs.tables.defaultCurrent,
                    total: appConfigs.tables.defaultTotal,
                    hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                    responsive: appConfigs.tables.responsive,
                    onChange: null,
                    showLessItems: appConfigs.tables.showLessItems,  
                    showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} calibration${total > 1 ? 's' : ''}`
                }
            },
            search: ""
        },
        history: {
            tableData: [],
            tableConfig : { 
                pagination : {                  
                    showSizeChanger: appConfigs.tables.showSizeChanger,
                    showQuickJumper: appConfigs.tables.showQuickJumper,
                    pageSize: appConfigs.tables.pageSize,
                    defaultCurrent: appConfigs.tables.defaultCurrent,
                    current: appConfigs.tables.defaultCurrent,
                    total: appConfigs.tables.defaultTotal,
                    hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                    responsive: appConfigs.tables.responsive,
                    onChange: null,
                    showLessItems: appConfigs.tables.showLessItems,  
                    showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} calibration${total > 1 ? 's' : ''}`
                  }
            }
        },
    }
    }
}

