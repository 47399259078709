import { produce as immerProduce } from "immer";
import { initialState } from "./reportState";

export function reportReducer(state = initialState, action:any) {
    switch (action.type) {    
        case "REPORT_ACCESS_SUCCESS":
            return immerProduce(state, draft => {                
                let reportAccessData:string = JSON.stringify(action.payload.data) || "{}";                               
                if (reportAccessData !== "{}") {                           
                    let reportAccessJson = JSON.parse(reportAccessData); 
                    draft.orgId = reportAccessJson.orgId;
                    draft.orgName = reportAccessJson.orgName;
                    draft.orgAccess = reportAccessJson.orgAccess;
                } 
            });      
        case "REPORT_ACCESS_FAILURE":
            return immerProduce(state, draft => {
                draft.orgAccess = null;
            });             
        default:
            return state;
    }
}