import React, { useState } from "react";
import { Popconfirm } from "antd";
import { CloseOutlined } from "@ant-design/icons";

interface Props {
  dashboardId: number;
  onDashboardDelete: (dashboardId: number) => void;
}

const DashboardClosing: React.FC<Props> = ({ dashboardId, onDashboardDelete }) => {
  const [showPopCinfirm, setShowPopConfirm] = useState<boolean>(false);

  const handleDelete = () => {
    setShowPopConfirm(false);
    onDashboardDelete(dashboardId);
  };  
  return (
    <Popconfirm
      visible={showPopCinfirm}
      onConfirm={handleDelete}
      onCancel={() => setShowPopConfirm(false)}
      title="Are you sure to delete this dashboard?"
    >
      <CloseOutlined onClick={() => setShowPopConfirm(true)} />
    </Popconfirm>
  );
};

export default DashboardClosing;
