export const initialState = {

    geofencesList:{
        fenceList: [],
        fenceListLoaded: false,
        rawDataList: [],
        geoFenceMaint:{
            visible: false,
            record: {
                fenceName: "",
                fenceColor: "",
                description: "",
                type: "",
                geoCoordinates: {},
                id: ""           
             },
            assetsList: [],
            mastertags: [],
            gatewayIds: [],
            changeInGateways: [],
            curShape: {}
        },
        fenceAssets:{
            visible: false,
            mastertags: [],
            gatewaysData: [],
            fenceName: "",
            fenceColor: ""
        },
        fenceListDrawerVisible: false,
        fenceTableList: [],
        map: null,
        drawingManager: null,
        markerManager: null,
        showingGeofences: true
    }

}



