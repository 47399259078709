import React from "react";
import { Form, Card } from "antd";
import { Signal } from "../../models";

import {
    EditFormProps,
    WidgetTitleFormField,
    SignalSelectorList,
  } from "../../WidgetEditForm";
  import { Config } from ".";

  function sanitizeInputs(c: Config): Config {
    return {
      title: c.title,
      signals: c.signals.filter((s) => !!s),
      type: "command",
    };
  }

  const EditForm: React.FC<EditFormProps> = (props) => {
    const commandSignals: Signal[] = [];
    const orgCmdPgnSpns: string =  props.assetDetails.orgCmdPgnSpns; 
     props.template.signalCollections.map((collection)=>{
      if((collection.type) && (collection.type === "Command"))
      {
          collection.signals.map((signal)=>{
            commandSignals.push(signal)
          });
      }
    });

    return (
      <div className="text-widget-edit-form">
        <Form
          onFinish={(values) => props.onFinish(sanitizeInputs(values as Config))}
          layout="vertical"
          form={props.form}
          initialValues={props.config}
        >
          <Card title="Command Widget Settings">
            <WidgetTitleFormField />
            <SignalSelectorList max={10} signals={commandSignals} orgCmdPgnSpns={orgCmdPgnSpns} rules= {[]} />
          </Card>
        </Form>
      </div>
    );
  };
  
  export default EditForm;
  