export const initialState = {

    serviceTruckList:{
        assets: []
    },
    serviceTruckMaint: {},
    serviceTruckListDrawerVisible: false,
    serviceTruckMintDrawerVisible: false,
    map: null
}



