import Axios from "axios";
import { errorResponse } from "../apiHelpers/apiHelpers";

export function download(
    me: any,
    callbackSuccessFn: any,
    callbackErrorFn: any,
    url: string,
    fileName: string
) {

    Axios(
        {
            url: url,
            method: "GET",
            responseType: "blob", 
        }
    )
    .then(response => 
        {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            
            if (callbackSuccessFn != null) {
                callbackSuccessFn(response.data.data.context);
            }
        }
    )
    .catch(function (error) 
        {                
            errorResponse(me, error, false);
            if (callbackErrorFn != null) {
                callbackErrorFn(error);
            }
        }
    )
}
