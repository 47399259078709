import React from "react";
import { Table, Checkbox, Space, Tooltip, Input } from 'antd';
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import * as actions from "../actions";

const { Search } = Input;

interface Prop {
    userSubscriptionsSetPagination: Function,
    userSubscriptionsGet: Function,
    userSubscriptionsMaint: Function,
    usersListRefreshOnUpdate: Function,
    userSubscriptionsSetSearch: Function,
    usersList: any
}

class UserMaintSubscriptions extends React.Component<Prop, {}> {  
       
    componentDidMount() {
        this.props.userSubscriptionsSetPagination(this.onPaginationChange);
        this.componentUpdate(1);
    }

    componentDidUpdate(prevProps: Prop) {
        if (this.props.usersList.userMaint.tabs[2].instance !== prevProps.usersList.userMaint.tabs[2].instance) {
            this.componentUpdate(1);
        }
    }

    componentUpdate(page: number) {
        this.props.userSubscriptionsGet(this, null, null, this.props.usersList.userMaint.record.userId, this.props.usersList.userMaint.tabs[2].searchText, page);
    }

    onPaginationChange = (page: number) => {
        this.props.userSubscriptionsGet(this, null, null, this.props.usersList.userMaint.record.userId, this.props.usersList.userMaint.tabs[2].searchText, page);
    }
    
    onCheckChange = (e:any, record:any, notification: string, scope: string) => {
        let action = e.target.checked ? "plus" : "minus";
        this.props.userSubscriptionsMaint(this, this.onCheckChangeSuccess, this.onCheckChangeFailure, this.props.usersList.userMaint.record.userId, record, notification, action, scope);
    }

    onCheckChangeSuccess = (userId:string, record:any, notification: string, action: string, scope:string) => {   
        if (scope === "asset") {
            this.closeExpandedRows(record.key);
        }
        this.props.usersListRefreshOnUpdate(this);
    };

    onCheckChangeFailure = (error:string, userId:string, record:any, notification: string, action: string, scope:string) => {   
    }        
    
    closeExpandedRows(rowKey?:string) {             
        let active:any;
        
        if((rowKey || "") !== "") {
            active = document.querySelectorAll('[data-id=user-maint-subscriptions] tr[data-row-key="' + rowKey + '"] .ant-table-row-expand-icon-expanded');
        }
        else {
            active = document.querySelectorAll('[data-id=user-maint-subscriptions] .ant-table-row-expand-icon-expanded');
        }
        if (active.length) {
            for (var i = 0; i < active.length; i++) { 
                active[i].click();  
                if ((rowKey || "") !== "") {
                    //if expanded click it twice to refresh updated state data
                    active[i].click();  
                }
            }
        }
    }
    
    onSearch = (searchText: string) => {   
        let me = this;
        setTimeout(function(){
            me.props.userSubscriptionsGet(me, null, null, me.props.usersList.userMaint.record.userId, me.props.usersList.userMaint.tabs[2].searchText, 1);
        }, 100);      
    }
    
    onSearchChange = (e: any) => {
        let value = e.target.value;
        this.props.userSubscriptionsSetSearch((value || ""));
    }

    isLiteAsset=(record:any)=>{
        return (record.partNumber || "") !== "" && record.minCollectionFreq > 0;
    }

    returnAlertSmsBySeverity=(record:any)=>{
        
        switch(record.severity){
           case "critical" || "warning" ||"info":
               return record.alertSms;
           case "incremental":
             return record.incAlertSms;
           case "offline":
            return record.offlineAlertSms;
           default:
             return record.alertSms;
        }
     }
     returnAlertEmailBySeverity=(record:any)=>{
        
         switch(record.severity){
            case "critical" || "warning" ||"info":
                return record.alertEmail;
            case "incremental":
              return record.incAlertEmail;
            case "offline":
             return record.offlineAlertEmail;
            default:
              return record.alertEmail;
         }
      }

      returnSeverity=(record:any)=>{
        
        switch(record.severity)
        {
            case "incremental" || "offline":
                return record.severity;
            default:
                return "alert";
        }        
      }

    actions = (record:any) => (
        <>
            {record.children &&
                <Space size="large">
                    {/* Rollback OPS-4113: Disable SMS scubcription for lite assets */}
                    {/*<Tooltip title="Set/Unset SMS for all asset alerts">                        
                         <Checkbox disabled={this.isLiteAsset(record)}  data-id="user-maint-subscriptions-sms-all" key={record.key} onChange={(e) => this.onCheckChange(e, record, "sms", "asset")} indeterminate={record.assetSmsIndeterminate} checked={(this.isLiteAsset(record)?false:record.assetSmsChecked)}><PhoneOutlined /></Checkbox> 
                    </Tooltip>*/}
                    <Tooltip title="Set/Unset SMS for all asset alerts">
                        <Checkbox data-id="user-maint-subscriptions-sms-all" key={record.key} onChange={(e) => this.onCheckChange(e, record, "sms", "asset")} indeterminate={record.assetSmsIndeterminate} checked={record.assetSmsChecked}><PhoneOutlined /></Checkbox>
                    </Tooltip>
                    <Tooltip title="Set/Unset email for all asset alerts">
                        <Checkbox data-id="user-maint-subscriptions-email-all" key={record.key} onChange={(e) => this.onCheckChange(e, record, "email", "asset")} indeterminate={record.assetEmailIndeterminate} checked={record.assetEmailChecked}><MailOutlined /></Checkbox>
                    </Tooltip>
                </Space>
            }
            {!record.children && !record.name.includes('(Geofence)') &&
                <Space size="large">
                    {/* <Checkbox disabled={this.isLiteAsset(record)}  data-id="user-maint-subscriptions-sms" key={record.key} onChange={(e) => this.onCheckChange(e, record, "sms", record.severity!=="incremental"?"alert":record.severity)} checked={(this.isLiteAsset(record)?false:(record.severity!=="incremental"?record.alertSms:record.incAlertSms))}><PhoneOutlined /></Checkbox> */}
                    <Checkbox data-id="user-maint-subscriptions-sms" key={record.key} onChange={(e) => this.onCheckChange(e, record, "sms", this.returnSeverity(record))} checked={this.returnAlertSmsBySeverity(record)}><PhoneOutlined /></Checkbox>
                    <Checkbox data-id="user-maint-subscriptions-email" key={record.key} onChange={(e) => this.onCheckChange(e, record, "email", this.returnSeverity(record))} checked={this.returnAlertEmailBySeverity(record)}><MailOutlined /></Checkbox>
                </Space>
            }
            {
                !record.children  && record.name.includes('(Geofence)') &&

                <div>
                    <Space size="large" align="center">
                        <p style={{marginTop: 15}}>In</p>
                        {/* <Checkbox disabled={this.isLiteAsset(record)}  data-id="assets-maint-subscriptions-sms" key={record.key} onChange={(e) => this.onCheckChange(e, record, "smsIn", "geofence")} checked={(this.isLiteAsset(record)?false:record.alertSmsIn)}><PhoneOutlined /></Checkbox> */}
                        <Checkbox data-id="assets-maint-subscriptions-sms" key={record.key} onChange={(e) => this.onCheckChange(e, record, "smsIn", "geofence")} checked={record.alertSmsIn}><PhoneOutlined /></Checkbox>
                        <Checkbox data-id="assets-maint-subscriptions-email" key={record.key} onChange={(e) => this.onCheckChange(e, record, "emailIn", "geofence")} checked={record.alertEmailIn}><MailOutlined /></Checkbox>
                    </Space>
                
                    <Space size="large" align="center" style={{marginRight: 10, marginTop: -30}}>
                        <p style={{marginTop: 15}}>Out</p>
                        {/* <Checkbox disabled={this.isLiteAsset(record)}  data-id="assets-maint-subscriptions-sms" key={record.key} onChange={(e) => this.onCheckChange(e, record, "smsOut", "geofence")} checked={(this.isLiteAsset(record)?false:record.alertSmsOut)}><PhoneOutlined /></Checkbox> */}
                        <Checkbox data-id="assets-maint-subscriptions-sms" key={record.key} onChange={(e) => this.onCheckChange(e, record, "smsOut", "geofence")} checked={record.alertSmsOut}><PhoneOutlined /></Checkbox>
                        <Checkbox data-id="assets-maint-subscriptions-email" key={record.key} onChange={(e) => this.onCheckChange(e, record, "emailOut", "geofence")} checked={record.alertEmailOut}><MailOutlined /></Checkbox>
                    </Space>
                </div>
            }
        </>
    );
    
    render() {

        const { tableConfig, tableData } = this.props.usersList.userMaint.tabs[2];
        const columns = [
            {
                title: "Asset / Alarm Name",
                dataIndex: "name",
                key: "name",
                render: (text: any, record: any) => (     
                    <>               
                        {record.children &&
                            <strong>{record.name}</strong>
                        }
                        {!record.children && record.signalId === "" &&
                            <i>{record.name}</i>
                        }
                        {!record.children && record.signalId !== "" &&
                            <span>{record.name}</span>
                        }
                    </>
                )
            },
            {
                title: "Subscribed",
                dataIndex: "subscribed",
                width: "30%",
                key: "subscribed",
                align: "center" as "center",
                render: (text: any, record: any) => (                    
                    this.actions(record)
                )
            },
          ];
          
        return (     
            <>
                {
                    (
                        ((this.props.usersList.userMaint.tabs[2].searchText || "") === "" && tableData.length > 0) ||
                        ((this.props.usersList.userMaint.tabs[2].searchText || "") !== "")
                    ) &&
                    <Search
                        data-id="user-maint-subscriptions-search-field"
                        placeholder="Search by asset, tag, or alarm name"
                        enterButton="Search"
                        size="middle"
                        allowClear
                        onSearch={this.onSearch}
                        onChange={this.onSearchChange}
                        style={{marginBottom: 20, width: '100%'}}
                        value={this.props.usersList.userMaint.tabs[2].searchText}
                    /> 
                }
                
                <Table 
                    data-id="user-maint-subscriptions" 
                    rowKey={(record) => record.key}
                    {...tableConfig}
                    columns={columns} 
                    dataSource={tableData} 
                />
            </>
        )
    }
}

const mapStateToProps = (state:any) => {
    return {
        usersList: state.usersReducers.usersState.usersList
    };
};

export default connect(
    mapStateToProps,
    actions
)(UserMaintSubscriptions);