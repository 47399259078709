import React from "react";
import { Form, Row, Col, InputNumber, Card } from "antd";

import {
  EditFormProps,
  WidgetTitleFormField,
  SignalSelectorList,
} from "../../WidgetEditForm";
import { Config } from ".";
import { WidgetSignalId } from "../../models";

type Fields = Omit<Config, "signals"> & { signals: WidgetSignalId[] };

function sanitizeInputs(f: Fields) : Config {
  return {
    title: f.title,
    settings: f.settings,
    type: "gauge",
    signals: f.signals
  };
}

const EditForm: React.FC<EditFormProps> = (props) => {
  return (
    <Form
      onFinish={(values) => props.onFinish(sanitizeInputs(values as Fields))}
      layout="vertical"
      form={props.form}
      initialValues={ props.config }
    >
      <Card title="Gauge Widget Settings">
        <WidgetTitleFormField />
        <Row justify="space-between">
          <Col span={10}>
            <Form.Item name={["settings", "min"]} label="Min Value">
              <InputNumber />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name={["settings", "max"]} label="Max Value">
              <InputNumber />
            </Form.Item>
          </Col>
        </Row>
        <SignalSelectorList max={1} signals={props.template.signals} rules={props.template.rules} isColor={"gauge"} />
      </Card>
    </Form>
  );
};

export default EditForm;
