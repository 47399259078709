import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { FormInstance } from 'antd/lib/form';

import { appConfigs } from "../../utils/configurations";
import { loadingIndicatorStart, loadingIndicatorEnd} from "../../utils/commonHelpers";

import { connect } from "react-redux";
import * as actions from "./actions";

interface Props {
    forgotPassword: Function,
    login: any,
    context: any,

    visible: boolean,
    forgotPasswordClose: Function
}

const ForgotPassword: React.FC<Props> = (props) => {
    
    const formRef = React.createRef<FormInstance>();
    const [successVisible, setSuccessVisible] = useState(false);
    const [isParker, setIsParker] = useState(false);

    const forgotPasswordClose = (e:any) => {   
        e.preventDefault();

        setSuccessVisible(false);
        props.forgotPasswordClose();
    }

    const onFinish = (values: any) => {
        loadingIndicatorStart();
        props.forgotPassword(null, onFinishSuccess, onFinishFailure, values.emailAddress);
    }

    const onFinishSuccess = (emailAddress: string) => {
        loadingIndicatorEnd();
        setSuccessVisible(true);
        setIsParker(emailAddress.toLowerCase().indexOf("@parker.com") !== -1);
    }
    
    const onFinishFailure = () => {
        loadingIndicatorEnd();
    }

    return (
        <>
            { 
                props.visible &&
                <div id="forgot-password">
                    {
                        !successVisible &&
                        <Form
                            layout="vertical"
                            id="forgot-password-form"
                            ref={formRef}
                            onFinish={onFinish}
                            className="mt-15"
                        >     
                            <h2>{appConfigs.app.login.en["password.reset.title"]}</h2>
                            
                            <Form.Item
                                label={appConfigs.app.login.en["primaryauth.username.placeholder"]}
                                name="emailAddress"                            
                                rules={[
                                    { required: true, message: appConfigs.errors.fieldErrors.valueRequired},
                                    { type: "email", message: appConfigs.errors.fieldErrors.invalidEmail},
                                    () => ({
                                        validator(rule, value) {
                                            if (value && value.toLowerCase().indexOf("@parker.com") !== -1) {
                                                return Promise.reject(appConfigs.app.login.en["password.forgot.emailSent.parker.desc"]);
                                            }
                                            else {
                                                return Promise.resolve();
                                            }
                                        }
                                    })
                                ]}
                            >
                                <Input maxLength={255} />
                            </Form.Item> 

                            <div className="mt-20">
                                <Button data-id="forgot-password-form-submit-button" type="primary" key="submit" htmlType="submit">
                                    {appConfigs.app.login.en["password.forgot.sendText"]}
                                </Button> 
                            </div>
                            
                            <div className="mt-20">
                                <a href="# " onClick={forgotPasswordClose}>{appConfigs.app.login.en.goback}</a>
                            </div>
                        </Form>
                    }
                    {
                        successVisible &&
                        <div id="forgot-password-success">
                            <h2>
                                {
                                    isParker &&
                                    appConfigs.app.login.en["password.forgot.emailSent.parker.title"]
                                }
                                {
                                    !isParker &&
                                    appConfigs.app.login.en["password.forgot.emailSent.title"]
                                }
                            </h2>
                            {
                                isParker &&
                                <div dangerouslySetInnerHTML={{__html: appConfigs.app.login.en["password.forgot.emailSent.parker.desc"]}}></div>
                            }
                            {
                                !isParker &&
                                <div dangerouslySetInnerHTML={{__html: appConfigs.app.login.en["password.forgot.emailSent.desc"]}}></div>
                            }
                            <div className="mt-30">
                                <Button data-id="forgot-password-back-buttonn" type="primary" key="back" htmlType="submit" onClick={forgotPasswordClose}>
                                    {appConfigs.app.login.en.goback}
                                </Button> 
                            </div>
                        </div>
                    }
                </div>
            }
        </>

    );
};

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        login: state.loginReducer
    };
};

export default connect(
    mapStateToProps,
    actions
)(ForgotPassword);