import React from 'react';
import { Drawer, Button } from 'antd';

import { appConfigs } from "../../utils/configurations";
import { setLoginTermsAndConditions } from "../../utils/commonHelpers";
import { setCookie } from "../../utils/cookieHelpers";

import { connect } from "react-redux";
import * as actions from "./actions";

import "./login.css";

interface Prop {
    login: any,
    context: any
}

interface State {
    termsVisible: boolean;
    defaultLanguage?: string
}

class LoginFooter extends React.Component<Prop, State> {  

    drawerWidth:number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);

    state:State = {
        termsVisible: false
    }    

    componentDidUpdate() {
        setTimeout(function() {
            setLoginTermsAndConditions();
        }, 250);   
    }

    termsOpen = (e:any) => { 
        e.preventDefault();
        this.setState({
            termsVisible: true
        });
    };

    termsClose = () => { 
        this.setState({
            termsVisible: false
        });
    };

    onLanguageChange = (value: string) => { 
        this.setState({
            defaultLanguage: value
        });
        setCookie("language", value, 365);
    };

    render() {
        
        const { /*languages, languageIsoCode2, languageIsoCode2Default,*/ backgroundUrl, termsAndConditions } = this.props.login;
         
        const href = window.location.href.toLowerCase();
        let showHelp = true;
        if (href.includes(appConfigs.server.claytonServerURL.toLowerCase()) ||
            href.includes(appConfigs.server.hoistServerURL.toLowerCase()) ||
            href.includes(appConfigs.server.toyotaServerURL.toLowerCase())) 
        {
            showHelp = false;
        } 

        return (
            <div className="login-footer">
                {/*
                    languages.length > 0 &&
                    <Select 
                        value={this.state.defaultLanguage || getCookie("language") || languageIsoCode2 || languageIsoCode2Default} 
                        onChange={this.onLanguageChange} 
                        style={{ marginBottom: '20px'}}
                    >
                        {languages.map((record:any, index:number) => (
                            <Option key={record.isoCode2} value={record.isoCode2}>                                                
                                {"Language: " + record.name}
                            </Option>
                        ))}
                    </Select>
                */}

                {showHelp ? (
                <>
                <div>
                <a href="# " className={backgroundUrl ? "login-footer-link" : ""} onClick={(e) => {this.termsOpen(e)}}>
                        {appConfigs.app.login.en.termsAndConditionsLinkText}
                </a>
                </div>
                <div className={backgroundUrl ? "login-footer-text" : ""}>
                    {appConfigs.app.login.en.copyrightText}
                </div>
                </>
                ) : (
                <div className={backgroundUrl ? "login-footer-text" : ""}>
                <a href="https://www.parker.com/us/en/privacy-policies/end-user-license-agreement.html" target="_blank">
                    © Privacy Policy.
                </a>
                </div>
                )}

                <Drawer
                    data-id="login-terms-and-conditions-drawer" 
                    title={
                        <>
                            <div className="ant-modal-title">{appConfigs.app.termsAndConditions.title}</div>
                            <div className="mt-10"><h5>{appConfigs.app.termsAndConditions.subTitle}</h5></div>
                        </>
                    }
                    width={this.drawerWidth}
                    onClose={this.termsClose}
                    visible={this.state.termsVisible}
                    destroyOnClose={true}
                    footer={        
                        <Button data-id="login-terms-and-conditions-close-button" type="default" onClick={this.termsClose}>
                            {appConfigs.settings.drawers.closeButtonText}
                        </Button>
                    }
                >                    
                    <div className="loginTermsAndConditionsDisplay"></div>
                </Drawer>
            </div>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        login: state.loginReducer
    };
};

export default connect(
    mapStateToProps,
    actions
)(LoginFooter);
  
