import React, { useState,useEffect } from "react";
import { Row, Col, Tooltip, Tabs, Card } from "antd";
import BackNavigation from "../../../utils/Components/BackNavigation";
import { connect } from "react-redux";
import * as actions from "./actions";
import ReactPlayer from 'react-player';
import { PlayCircleOutlined } from "@ant-design/icons";
import Meta from "antd/lib/card/Meta";
const { TabPane } = Tabs;
interface Props 
{
   getVideoTutorials: Function,
   setTab: Function,
   context: any,
   tutorials: any,
   permissions: any,
}
const VideoTutorials: React.FC<Props> = (props) => 
{
   const [playingUrl, setPlayingUrl] = useState<string | null>(null);

   useEffect(() => {
    props.getVideoTutorials();
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const onTabChange = (key: string) => {
       props.setTab(key)
   }
   return (<div className="layout-content">
            <Row gutter={8}>
                <Col xs={24} lg={14}>
                    <div className="content-header page-title">
                        <div style={{ display: "flex" }}>
                            <Tooltip title="Go To Previous Page">
                                <BackNavigation />
                             </Tooltip>
                                <span>Video Tutorials</span>
                         </div>
                    </div>
                </Col>
            </Row>
            <div className="mt-15">
                <Tabs activeKey={props.tutorials.activeTab} size="large" type="card" onChange={onTabChange}>
                    <TabPane tab="All Tutorials" key="1">
                        <Row gutter={12}>
                            {props.tutorials.tutorialsList.map((tutorial: any) => 
                                    (tutorial.category === "All videos" &&
                                <Col xs={12} sm={12} md={8} lg={8} xl={8} key={tutorial.id}>
                                    <Card
                                        style={{ padding:'3px',marginBottom: '10px' }}
                                        onClick={() => setPlayingUrl(tutorial.link)}
                                        cover={
                                            playingUrl === tutorial.link ? (
                                            <ReactPlayer
                                                url={tutorial.link}
                                                controls
                                                width="100%"
                                                height="160"
                                                playsinline={true}
                                                stopOnUnmount={false}
                                                //onPause={() => setPlayingUrl(null)}
                                            />) : (<><img
                                                    src={tutorial.thumbnail}
                                                    alt="Thumbnail"
                                                    style={{
                                                        width: "100%",
                                                        height: "160",
                                                        objectFit: "cover"
                                                     }}
                                                />
                                                <div style={{
                                                    position: "absolute",
                                                    top:"42%",
                                                    left: "45%",
                                                    transform:"transalte(-50%,-50%)",

                                                }}>
                                                <PlayCircleOutlined style={{fontSize:30,color:'grey'}}/>
                                                </div>
                                                </>
                                            )
                                    }>
                                    <Meta
                                        title={tutorial.title}
                                        description={tutorial.description}
                                    />
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
}
const mapStateToProps = (state: any) => {
   return {
       permissions: state.contextReducer.data,
       context: state.contextReducer.context,
       tutorials: state.tutorialsReducers.tutorialsState.tutorials,
   };
};
export default connect(
   mapStateToProps,
   actions
)(VideoTutorials);