import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";
import { any } from "@amcharts/amcharts4/.internal/core/utils/Array";

export function mapsAssetDrawerClusterOpen(
    ids: any,
    zoomTo: any
) {
    return (dispatch: any) => {
        dispatch({ type: "FLEET_OVERVIEW_CLUSTER_SELECTED", ids: ids, zoomTo: zoomTo });
        dispatch({ type: "FLEET_OVERVIEW_ASSET_DRAWER_STATE", title: "Cluster Assets", mode: "cluster", state: true });
    }
}   

export function mapsAssetDrawerAssetSearchOpen() {
    return (dispatch: any) => {
        dispatch({ type: "FLEET_OVERVIEW_ASSET_SEARCH_SELECTED" });
        dispatch({ type: "FLEET_OVERVIEW_ASSET_DRAWER_STATE", title: "Assets", mode: "asset-search", state: true });
    }
}   

export function mapsAssetDrawerClose() {
    return (dispatch: any) => {
        dispatch({ type: "FLEET_OVERVIEW_ASSET_DRAWER_STATE", state: false });
    }
}   

export function clusterInfoSearch(
    searchText: string
) {
    return (dispatch: any) => {
        dispatch({ type: "FLEET_OVERVIEW_CLUSTER_INFO_SEARCH", searchText: searchText });               
    }
}   

export function assetSearchSearch(
    searchText: string
) {
    return (dispatch: any) => {
        dispatch({ type: "FLEET_OVERVIEW_ASSET_SEARCH_SEARCH", searchText: searchText });               
    }
}   

export function assetSearchReset() {
    return (dispatch: any) => {
        dispatch({ type: "FLEET_OVERVIEW_GLOBAL_SEARCH_SUCCESS",  assets: [] });
    }
}   

export function filtersToggle(
    filterName: any,
    mapActions: any
) {
    return (dispatch: any) => {
        dispatch({ type: "FLEET_OVERVIEW_FILTERS_TOGGLE", filterName: filterName, mapActions: mapActions });
    }
}   

export function filtersReset(
    mapActions: any
) {
    return (dispatch: any) => {
        dispatch({ type: "FLEET_OVERVIEW_FILTERS_RESET", mapActions: mapActions });
    }
}   

export function toggleMarkerToolTip(
    visibility: boolean
) {
    return (dispatch: any) => {
        dispatch({ type: "TOGGLE_MARKER_TOOLTIP", visibility: visibility });
    }
}   

export function updateToolTipMarkerDetails(
    marker: any,
    gmarker: any
) {
    return (dispatch: any) => {
        dispatch({ type: "UPDATE_TOOLTIP_MARKER", marker: marker, gmarker: gmarker });
    }
}


export function updateToolTipAssetDetails(
    asset: any,
) {
    return (dispatch: any) => {
        dispatch({ type: "UPDATE_TOOLTIP_ASSET", asset: asset });
    }
}

export function updateToolTipOverlay(
    overlay: any,
) {
    return (dispatch: any) => {
        dispatch({ type: "UPDATE_TOOLTIP_OVERLAY", overlay: overlay });
    }
}


export function submitAssetDown(
    callbackFnSuccess: any,
    callbackFnFailure: any,
    record: any,
) {
    return async (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL + "/ui/api/update-assets-down",
            record,
            {
                responseType: "json",       
                headers: {},
                params: {
                    assetId: record.assetId,
                    customFieldId: record.customFieldId,
                    description: record.description,
                    clearDownStatus: record.clearDownStatus,
                    assetDownCategory:record.assetDownCategory,
                    mastertag:record.mastertag,
                    orgId:record.orgId,
                    orgName:record.orgName,
                    assetName:record.assetName
                }
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.description);
                }
                
                // dispatch({ type: "ASSET_DOWN_SUBMITTED_SUCCESS", record: record });
            }
        )
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}


export function getFleetOverview(
    callbackFnSuccess: any,
    callbackFnFailure: any,
    mode: string,
    searchText?: string,
    originator?: string,
    preferences?: any
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/fleet-overview", {
                responseType: "json",        
                headers: {},
                params: {
                    mode: mode,
                    searchText: searchText
                }
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.assets);
                }
                if (mode === "map") {
                    dispatch({ type: "FLEET_OVERVIEW_MAP_SUCCESS", assets: response.data.data.assets, originator: originator, preferences: preferences});
                }
                else {
                    dispatch({ type: "FLEET_OVERVIEW_GLOBAL_SEARCH_SUCCESS", assets: response.data.data.assets });
                }
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(null, error);
            }
            errorResponse(null, error);
        });
    };
}

export function getPreferedSignals(
    callbackFnSuccess: Function,    
    assetId: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + `/ui/api/assets/${assetId}/signalpreferences`, {
                responseType: "json",        
                headers: {},
                params: {
                    preference:'pinnedsignals'
                }
            }
        )
        .then(response => {
                if(response.data && response.data.data.signals){
                    callbackFnSuccess({signals: response.data.data.signals.preferedSignals, assetId: assetId});                   
                } else {
                    const asset = { signals : [], assetId: null};
                    callbackFnSuccess({signals: [], assetId: assetId});                   
                }               
            }
        )
        .catch(function (error) {            
            errorResponse(null, error);
        });
    };
}
export function filterPreferences(
    pageName: string,
    key: string,
    value: string,
    isResetEnabled: boolean,
    callbackFnSuccess?: any,
    isTimezoneChanged?:any
) {
    return async (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL + `/ui/api/filterPreferences/${pageName}`,
            {
                key: key,
                value: value,
                isResetEnabled: isResetEnabled
            },
            {
                responseType: "json",
                headers: {},
                params: {}
            }
        )
            .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(JSON.parse(response.data.data.preferences)[0],isTimezoneChanged);
                }
            }
            )
            .catch(function (error) {
                errorResponse(null, error);
            }
            );
    }
}

export function assetZoomToFitFilter(
    filterZoomTofit: any,
    mapActions: any
) {
	return (dispatch: any) => {
		dispatch({ type: "SET_SEARCH_ZOOM_TO_FIT_FITLER_MAP", payload: { filterZoomTofit: filterZoomTofit, mapActions: mapActions } });
	};
}

export function setAssetSearchValue(
    assetSearchValue: any,
) {
	return (dispatch: any) => {
		dispatch({ type: "SET_SEARCH_ZOOM_TO_FIT_SEARCH_VALUE", payload: { assetSearchValue: assetSearchValue } });
	};
}

export function setAssetSearchFilters(
    assetSearchFilters: any,
) {
	return (dispatch: any) => {
		dispatch({ type: "SET_SEARCH_ZOOM_TO_FIT_SEARCH_FILTER", payload: { assetSearchFilters: assetSearchFilters } });
	};
}

export function setPreSearchAssetsList(
    preSearchAssetList: any,
) {
	return (dispatch: any) => {
		dispatch({ type: "SET_PRE_SEARCH_ZOOM_TO_FIT_ASSET_LIST", payload: { preSearchAssetList: preSearchAssetList } });
	};
}

export function setFleetOverviewMap(map: any) {
	return (dispatch: any) => {
		dispatch({ type: "SET_FLEET_OVERVIEW_MAP", payload: { map: map } });
	};
}