import React, { Component, useState } from "react";
import { Table,  Input, Row, Col, Space, Button, Tooltip, Radio, message, Popconfirm, Breadcrumb, Tag, Badge, Menu, Checkbox, Dropdown, Modal, Divider  } from "antd";
import Icon, { 
    PlusOutlined, EditOutlined, 
    ClockCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, 
    CaretLeftOutlined, CloudServerOutlined, SearchOutlined, InsertRowRightOutlined, CommentOutlined, FileExclamationOutlined, FileTextOutlined, LockOutlined, UnlockOutlined, CheckOutlined, CloseOutlined, DeleteOutlined
} from "@ant-design/icons";

import onlineIcon from "../../assets/images/map-status/online.png";
import offlineIcon from "../../assets/images/map-status/offline.png";
import criticalIcon from "../../assets/images/map-status/critical.png";
import warningIcon from "../../assets/images/map-status/warning.png";
import infoIcon from "../../assets/images/map-status/info.png";
import assetdownIcon from "../../assets/images/map-pins/asset_down.svg";
import { ReactComponent as filterRemove } from "../../assets/images/filter-remove.svg";

import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import "./assets.css";

import { connect } from "react-redux";
import * as actions from "./actions";

import AssetMaint from "./AssetMaint";
import { appConfigs } from "../../utils/configurations";
import { stringToUrlString, closePopover, getPermissions, getAssetTableFilters, setAssetTableFilters, assetTableFilter, isAssetTableFiltersSet, resetAssetTableFilters } from "../../utils/commonHelpers";
import history from '../../utils/history';
import BackNavigation from "../../utils/Components/BackNavigation";
// import { getPermissions } from "../../utils/commonHelpers";
import LiteAssets from "./liteAssets/LiteAssets";
import { array } from "@amcharts/amcharts4/core";
import moment from 'moment';
import { FilterDropdownProps } from "antd/lib/table/interface";

const { Search } = Input;
var permissionsList;

interface Prop {
    assetsSetPagination: Function,
    assetsSetStatus: Function,
    assetsSetSearch: Function,
    getAssets: Function,
    getFilterTags: Function,
    editTagSubmit: Function,
    getOrgTags: Function,
    getAsset: Function,
    assetMaintOpen: Function,
    assetStatusUpdate: Function,       
    context: any,
    assetsList: any,
    permissions: any,
    permissionsset: any,
    getLiteAssets: Function,
    liteAssetsSetSearch: Function,
    blockAsset: Function,
}

interface State {
    mode: string,
    mastertag?: string,   
    showLiteAssets:boolean
    assetTableFilters:assetTableFilter
    isAssetTableFiltersSet:boolean
    columnSelectionVisible:boolean
    tableColumnsHidden: string[]
    columnSelectionFilter: string
    tagSearchText: string
    currentTagName: string | null
    newTagName: string
}
class Assets extends Component<Prop, State, {}> {
    
    state: State = {
        mode: "default",
        showLiteAssets: false,
        assetTableFilters: getAssetTableFilters(),
        isAssetTableFiltersSet: isAssetTableFiltersSet(),
        columnSelectionVisible: false,
        tableColumnsHidden: ['Contract Start Date', 'Contract End Date'],
        columnSelectionFilter: '',
        tagSearchText: '',
        currentTagName: null,
        newTagName: '',
    };

    

    drawerWidth: number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);
    
    getMastertagDetailsMode() {
        const pathnameArr = history.location.pathname.split("/");
        
        if (pathnameArr[pathnameArr.length - 1].toLowerCase() === "details") {
            return pathnameArr[pathnameArr.length - 2];
        }
        
        return "";
    }

    componentDidMount() {
        this.props.assetsSetPagination(this.onPaginationChange);
        this.getAssets();
        this.props.getFilterTags(this, null, null, 'all');
    }

    getTableFilterParams() {
        
        if (!this.state.assetTableFilters || !this.state.assetTableFilters.hasOwnProperty('status')) {            
            this.state.assetTableFilters = {
                page: 1,
                status: 'active',
                searchText: '',
                sortColumn: 'asset',
                sortDirection: 'ascend',
                alertInterval: '1 day',
                listMode: true,
                filters: {tags: null},
            }
        }

        return [this, null, null, this.state.assetTableFilters.page, this.state.assetTableFilters.status, this.state.assetTableFilters.searchText, this.state.assetTableFilters.alertInterval, "", true, this.state.assetTableFilters.sortColumn, this.state.assetTableFilters.sortDirection, this.state.assetTableFilters.filters]
        
    }
    
    getAssets() {
        const mastertag = this.getMastertagDetailsMode();

        if (mastertag === "") {
            // User can switch between asset page and other page while the previous asset tab is on "Lite"
            if (this.state.assetTableFilters.status !== 'LiteAssets') {
                this.props.getAssets(...this.getTableFilterParams());
            }
            else{
                this.setState({ showLiteAssets: true });
            }
         
        }
        else {
            this.setState({ mode: "details", mastertag: mastertag });
            this.props.assetsSetStatus("active,inactive,whitelisted,replaced");
            this.props.assetsSetSearch(mastertag);
            this.state.assetTableFilters.status = "active,inactive,whitelisted,replaced"
            this.state.assetTableFilters.searchText = mastertag
            let tableFilterParams = this.getTableFilterParams();
            tableFilterParams[1] = this.getAssetsDetailsSuccess
            this.props.getAssets(...tableFilterParams);
        }
    }

    blockAsset= (e: any, record: any) => {
        e.preventDefault();
        this.props.blockAsset(this, this.blockAssetSuccess, this.blockAssetFail, record.gatewayId, record.blockedassetOrgId ? false : true, record.orgId);
    }

    blockAssetSuccess=(blocked:any)=>
    {
        if(blocked){
            message.success("Asset has been blocked successfully");
        }
        else{
            message.success("Asset has been unblocked successfully");
        }
        this.props.getAssets(...this.getTableFilterParams());
    }

    blockAssetFail=()=>
    {

    }
    
    getAssetsDetailsSuccess = (data: any) => {
        this.props.getOrgTags(this, null, null, 'all', data[0].orgId);
        if ((data.length || 0) !== 0) {
            this.assetMaintOpenDo(data[0]);
        }
    }

    onPaginationChange = (page: number) => {

        this.state.assetTableFilters.page = page
        setAssetTableFilters(this.state.assetTableFilters)
        this.setState({ isAssetTableFiltersSet: isAssetTableFiltersSet() });

        this.props.getAssets(...this.getTableFilterParams());
    };

    assetMaintOpen = (e: any, record: any) => {
        e.preventDefault();
        this.assetMaintOpenDo(record);
    }

    assetMaintOpenDo = (record: any) => { //filterTags
        const title = Object.keys(record).length === 0 ? "New Asset" : "Asset :: " + (record.nickname || record.mastertag) + " :: " + record.status.charAt(0).toUpperCase() + record.status.slice(1);
        this.props.assetMaintOpen(this, title, this.drawerWidth, record);
        this.props.getOrgTags(this, null, null, 'all', record.orgId);
    }

    onSearch = (searchText: string) => {
        let me = this;
        this.props.assetsSetSearch((searchText.trim() || ""));

        // Trimming Empty Spaces on search
        let updatedFilters = { ...this.state.assetTableFilters };
        updatedFilters.searchText = searchText.trim();
        this.setState({ assetTableFilters: updatedFilters });

        this.setState({ isAssetTableFiltersSet: isAssetTableFiltersSet() });
        setTimeout(function () {
            me.props.getAssets(...me.getTableFilterParams());
        }, 100);
    }
    
    onSearchChange = (e: any) => {
        let value = e.target.value.trim();
        this.state.assetTableFilters.searchText = value || ""
        setAssetTableFilters(this.state.assetTableFilters)
        this.props.assetsSetSearch((value || ""));
    }

    goToAsset = (e: any, mastertag: number) => {
        e.preventDefault();
        history.push("/assets/" + mastertag);
    };

    goToTemplate = (e: any, templateName: string, templateId: string) => {
        e.preventDefault();

        let templateNameUrl = stringToUrlString(templateName);
        templateNameUrl = (templateNameUrl || "") === "" ? "details-" + templateId : templateNameUrl;

        history.push("/template/details/" + templateNameUrl + "/" + templateId);
    };
    
    assetInactivate = (e: any, record: any) => {
        e.preventDefault();
        this.props.assetStatusUpdate(this, this.assetInactivateSuccess, null, record.gatewayId, "inactive");
    };

    assetInactivateSuccess = (gatewayId: string, status: string) => {
        message.warning("Asset has been successfully inactivated");
        this.props.getAssets(...this.getTableFilterParams());
    }

    assetActivate = (e: any, record: any) => {
        e.preventDefault();
        this.props.assetStatusUpdate(this, this.assetActivateSuccess, null, record.gatewayId, "active");
    };

    assetActivateSuccess = (gatewayId: string, status: string) => {
        message.warning("Asset has been successfully activated");
        this.props.getAssets(...this.getTableFilterParams());
    }

    onStatusChange = (e: any) => {
        e.preventDefault();

        this.state.assetTableFilters.status = e.target.value || 'active'
        setAssetTableFilters(this.state.assetTableFilters)
        this.props.assetsSetStatus(e.target.value);
        if(e.target.value!=="LiteAssets")
        {
            this.setState({ showLiteAssets: false, isAssetTableFiltersSet: isAssetTableFiltersSet() });
            this.props.getAssets(...this.getTableFilterParams());
        }
        else{
            this.setState({ showLiteAssets: true });
        }
    };
    
    cancelDetailsMode = (e: any) => {
        e.preventDefault();

        this.props.assetsSetStatus("active");
        this.props.assetsSetSearch("");

        history.push("/assets");
    };

    confirmInactivation = (record: any, accessAllowed: any) => (
        <Popconfirm
            title={"Do you really want to inactivate this asset?"}
            onCancel={closePopover}
            onConfirm={(e) => { this.assetInactivate(e, record) }}
            okText="Yes"
            cancelText="No"
        > 
        {
            (accessAllowed)?
            <Tooltip title="Inactivate Asset">
                <Button data-id="users-delete-button" type="default" icon={<CloseCircleOutlined />} />
            </Tooltip>            
            :
            <Tooltip title="Denied Inactivate Asset">
                <Button data-id="users-delete-button" style={{pointerEvents: 'none', cursor: 'default', opacity: '0.5', color:'#808080'}}
                 type="default" icon={<CloseCircleOutlined />} />
            </Tooltip>
          }
        </Popconfirm>
    );

    confirmActivation = (record: any) => (
        <Popconfirm
            title={"Do you really want to activate this asset?"}
            onCancel={closePopover}
            onConfirm={(e) => { this.assetActivate(e, record) }}
            okText="Yes"
            cancelText="No"
        >
            <Tooltip title="Activate Asset">
                <Button data-id="users-delete-button" type="default" icon={<CheckCircleOutlined />} />
            </Tooltip>
        </Popconfirm>
    );

    sortAssetsTable = (pagination: any, filters: any, sorter: any) => {
        let sortColumn: string | undefined = undefined;
        let sortDirection: string | undefined = undefined;
        
        if (sorter && Object.keys(sorter).length > 0) {
            const { column, order } = sorter;
            sortDirection = order;
            if (column.title.hasOwnProperty('props')) {
                sortColumn = column.title.props['data-title'].toLowerCase().replace(/\s/g, '')
            } else {
                sortColumn = column.title.toLowerCase().replace(/\s/g, '');
            }
        }

        this.state.assetTableFilters.page = pagination.current
        this.state.assetTableFilters.sortColumn = sortColumn || ''
        this.state.assetTableFilters.sortDirection = sortDirection || ''
        this.state.assetTableFilters.filters = filters?.tags?.length > 0 ? filters : {tags: null}
        setAssetTableFilters(this.state.assetTableFilters)

        this.setState({ isAssetTableFiltersSet: isAssetTableFiltersSet() });
        this.props.getAssets(...this.getTableFilterParams());
    }

    goToOperators = (e: any, id: string, operatorsHistoryId?: string, isOperator?: boolean) => {
        e.preventDefault();
        history.push({ pathname: "/operators/" + stringToUrlString(id) + ((operatorsHistoryId || "") === "" ? "" : "/" + operatorsHistoryId), state: { isOperator: isOperator } });
    };

   onLiteAssetSearch = (searchText: string) => {
        let me = this;
        me.props.liteAssetsSetSearch((searchText || ""));
        setTimeout(function () {
            me.props.getLiteAssets(me, null, null,1, searchText,"","",""); 
        }, 100);
    }
    
    onLiteAssetSearchChange = (e: any) => {
        let value = e.target.value;
        this.props.liteAssetsSetSearch((value || ""));
    }

    resetAssetTableFilters = () => {
        this.setState({
            assetTableFilters: resetAssetTableFilters(),
            isAssetTableFiltersSet: isAssetTableFiltersSet()
        });
        this.state.assetTableFilters = resetAssetTableFilters()
        this.props.getAssets(...this.getTableFilterParams());
    }

    
    handleTagSearch = (e:any) => {
        this.setState({ tagSearchText: e.target.value });
    };

    handleTagEditClick = (tag:any) => {
        this.setState({ currentTagName: tag, newTagName: tag });
    };

    handleTagSaveClick = (confirm:any, clearFilters:any, actionType: string) => {

        // console.log(this.state.newTagName, this.state.currentTagName);

        if (actionType == 'edit') {
            
            if (this.state.newTagName.trim() !== "") {

                if (this.state.newTagName.toUpperCase() != this.state.currentTagName) {
                    confirm();
        
                    Modal.confirm({
                        title: 'Are you sure you want to edit this tag?',
                        content: 'This change will be applied to all assets attached to this tag within your current organization and all sub-organizations',
                        okText: 'Yes, edit and apply',
                        okType: 'danger',
                        cancelText: 'No',
                        onOk: () => {
                            clearFilters()
                            this.props.editTagSubmit(this, null, null, this.props.context.appContext.orgId, this.state.currentTagName, this.state.newTagName.trim().toUpperCase(), 'edit');
                            this.getAssets();
                            this.props.getFilterTags(this, null, null, 'all');
                            
                            message.success('Success!');
                        },
                        onCancel: () => {
                            this.setState({ currentTagName: null, newTagName: '' }); // Hide the input field
                        },
                    });
    
                } else {
                    this.setState({ currentTagName: null, newTagName: '' }); // Hide the input field
                }

            } else {
                this.setState({ currentTagName: null, newTagName: '' });
                message.warning('Value cannot be empty');
            }
            

        } else if (actionType == 'delete') {

            confirm();
    
            Modal.confirm({
                title: 'Are you sure you want to delete this tag?',
                content: 'This will delete this tag from all attached assets within your current organization and all sub-organizations',
                okText: 'Yes, delete',
                okType: 'danger',
                cancelText: 'No',
                onOk: () => {
                    clearFilters()
                    this.props.editTagSubmit(this, null, null, this.props.context.appContext.orgId, this.state.currentTagName, '', 'delete');
                    this.setState({ currentTagName: null, newTagName: '', tagSearchText: '' });
                    this.getAssets();
                    this.props.getFilterTags(this, null, null, 'all');
                    message.success('Success!');
                },
                onCancel: () => {
                    this.setState({ currentTagName: null, newTagName: '' }); // Hide the input field
                },
            });

        }
       
        
       
    };
    
    handleTagEditChange = (e:any) => {
        this.setState({ newTagName: e.target.value });
    };

    render() {

        const { user: contextUser, appContext } = this.props.context;
        const { tableConfig, tableData } = this.props.assetsList;

        const result = getPermissions(this.props.permissions.permissions.assets);
        const templatePermissions = getPermissions(this.props.permissions.permissions.templates);
        
        let iscreate = result[0];
        let isread = result[1];
        let isupdate = result[2];


        const tagPermissions = getPermissions(this.props.permissions.permissions.asset_tagging);
        let canTagRead = tagPermissions[1];
        let canTagDelete = tagPermissions[3];

        let isBarko = false;
       
        if (appContext.roleName.toLowerCase() === "barko_guest") {
            const barkoId = '17ad9f98-33df-48a0-8c4f-99468e22e129';
            for (let i = 0; i < this.props.context.user.homeOrg.orgTree.length; i++) {
                if (this.props.context.user.homeOrg.orgTree[i].orgId === barkoId) {
                    isBarko = true;
                }
            }
        }

        const oprPermission = getPermissions(this.props.permissions.permissions.operator_support);
        let isOprRead = oprPermission[1];
        let isOprUpdate = oprPermission[2];        
        let access = JSON.parse(appContext.disabledFeaturesOfContextOrg);
        let assetRestricted = (access==null || (access && access.length===0)) ? 
                             "Access Denied. Asset Dashboard Viewing Disabled in Asset Setting. Please contact Org Administrator."
                            :"Access Denied. Asset Dashboard Viewing Disabled in Org Setting. Please contact Org Administrator." ; 
        let tableColumns = [
            {
                title: "Asset",
                column_name: "Asset",
                dataIndex: "nickname",
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                sortOrder: this.state.assetTableFilters.sortColumn == 'asset' ? this.state.assetTableFilters.sortDirection : false,
                render: (text: any, record: any) => (
                    ((access==null || (access && access.length===0))
                    && (!record.blockedassetOrgId ? true : (contextUser.homeOrg.orgId !== record.blockedassetOrgId? true:false)))?
                        (record.status != 'replaced' ?
                        (<a href={`/assets/${record.mastertag}`} onClick={(e) => { this.goToAsset(e, record.mastertag) }}>
                            {
                                record.notesCount > 0 &&
                                <span className="asset-status-yellow pr-3" ><FileTextOutlined /></span>
                            }
                            {
                                record.status === "active" &&
                                <span className="asset-status-green pr-5" ><CheckCircleOutlined /></span>
                            }
                            {
                                record.status === "inactive" &&
                                <span className="asset-status-red pr-5" ><CloseCircleOutlined /></span>
                            }
                            {
                                record.status === "whitelisted" &&
                                <span className="asset-status-gray pr-5" ><ClockCircleOutlined /></span>
                            }
                            {record.nickname || record.mastertag}
                        </a>)
                        : (<a style={{pointerEvents: 'none', cursor: 'default', opacity: '0.5', color:'#808080'}} ><span className="asset-status-gray pr-5" ><CloseCircleOutlined /> {record.nickname || record.mastertag}  </span>
                       </a>)
                        )
                        :
                        
                        <Tooltip placement="rightTop" title= {assetRestricted} >
                            <Space>
                           {
                                <a style={{pointerEvents: 'none', cursor: 'default', opacity: '0.5', color:'#808080'}} >    
                                    {
                                        record.notesCount > 0 &&
                                        <span className="asset-status-yellow pr-3" ><FileTextOutlined /></span>
                                    }
                                    {
                                        record.status === "active" &&
                                        <span className="asset-status-green pr-5" ><CheckCircleOutlined /></span>
                                    }
                                    {
                                        record.status === "inactive" &&
                                        <span className="asset-status-red pr-5" ><CloseCircleOutlined /></span>
                                    }
                                    {
                                        record.status === "whitelisted" &&
                                        <span className="asset-status-gray pr-5" ><ClockCircleOutlined /></span>
                                    }
                                    {
                                        record.status === "replaced" &&
                                        <span className="asset-status-gray pr-5" ><ClockCircleOutlined /></span>
                                    }
                                    {record.nickname || record.mastertag}
                                </a>
                               
                            }
                            </Space>
                         </Tooltip>
                         
                )
            },
            {
                title: "Status",
                column_name: "Status",
                responsive: ["md"] as Breakpoint[],
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                sortOrder: this.state.assetTableFilters.sortColumn == 'status' ? this.state.assetTableFilters.sortDirection : false,
                align: "center" as "center",
                render: (text: any, record: any) => (
                    <>
                         {
                            record.connectionStatus === "ONLINE" && record.status !== "inactive" && record.status !== "whitelisted" && record.assetDownDesc == null &&
                            <Tooltip title="Online">
                                <span className="asset-status-green alarm-info-icon pr-5" ><img src={onlineIcon} alt="" /></span>
                            </Tooltip>
                        }
                        {
                            record.connectionStatus === "ONLINE" && record.status !== "active" &&
                            <Tooltip title="Offline">
                                <span className="asset-status-red alarm-info-icon pr-5" ><img src={offlineIcon} alt="" /></span>
                            </Tooltip>
                        }
                        {
                            record.assetDownDesc !== null && record.status !== "inactive" && record.status !== "whitelisted" && record.connectionStatus !== "ONLINE" &&
                            <Tooltip title= {"Asset Down : " + record.assetDownDesc}  >
                                <span className="asset-status-red alarm-info-icon pr-5" ><img src={assetdownIcon} alt="" /></span>
                            </Tooltip>
                        }
                        {
                            record.connectionStatus === "ONLINE" && record.assetDownDesc !== null && 
                            <Tooltip title="Online">
                                <span className="asset-status-red alarm-info-icon pr-5" ><img src={onlineIcon} alt="" /></span>
                            </Tooltip>
                        }
                        {
                            record.connectionStatus === "OFFLINE" && record.assetDownDesc == null &&
                            <Tooltip title="Offline">
                                <span className="asset-status-red alarm-info-icon pr-5" ><img src={offlineIcon} alt="" /></span>
                            </Tooltip>
                        }
                    </>
                )
            },
            {
                //Displaying the last status change time even for online assets instead of showing "NA".
                //So, changing the title

                //title: "Offline Since",
                title: <Tooltip mouseEnterDelay={0.5} data-title="Status Since" title="Represents most recent device's connect/disconnect timestamp"><span>Status Since</span></Tooltip>,
                column_name: "Status Since",
                responsive: ["xl"] as Breakpoint[],
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                sortOrder: this.state.assetTableFilters.sortColumn == 'statussince' ? this.state.assetTableFilters.sortDirection : false,
                align: "center" as "center",
                render: (text: any, record: any) => (
                    <>                    
                    {
                            //As part of fix for OPS-2627, inserting status as whitelisted for newly provisioned assets.
                            //So, if status received as whitelisted from DB, show it is offline and time as "Not Available" in UI
                            //Also, display status time for online devices

                            //record.connectionStatus === "OFFLINE" ?
                            record.connection_status === 'whitelisted' ?
                            <>{appConfigs.app.assets.offlineHavingNoDateText}</>
                            :record.disconnectedSince ?                          
                            <span>{new Date(record.disconnectedSince).toLocaleString()}</span> 
                            : <>{appConfigs.app.assets.offlineHavingNoDateText}</> 
                            //: <>{appConfigs.app.assets.onlineText}</>
                    }
                    </>
                )
            },
            {
                title: "Tags",
                column_name: "Tags",
                sortDirections: ['ascend', 'descend', 'ascend'],
                filterMultiple: true,
                width: '12%',
                sortOrder: this.state.assetTableFilters.sortColumn == 'tags' ? this.state.assetTableFilters.sortDirection : false,
                filterDropdown: ( { setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps ) => (
                    <div style={{ padding: 8 }}>

                        <Input
                            placeholder={`Search Tags`}
                            value={this.state.tagSearchText}
                            onChange={this.handleTagSearch}
                            style={{ marginBottom: 8, marginTop: 2, display: 'block' }}
                        />

                        <div style={{ maxHeight: '200px', overflowY: 'auto', paddingRight:"15px" }}>
                            {this.props.assetsList.filterTags
                                .filter((tag:any) => tag.toLowerCase().includes(this.state.tagSearchText.toLowerCase()))
                                .map((tag: any, index: any) => (
                                    
                                    <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        {this.state.currentTagName === tag ? (
                                            <Input
                                                value={this.state.newTagName}
                                                onChange={this.handleTagEditChange}
                                                // onBlur={() => this.setState({ currentTagName: null })}
                                            />
                                        ) : (
                                            <div style={{marginBottom: '7px'}}>
                                                <Checkbox
                                                    key={index}
                                                    checked={selectedKeys.includes(tag)}
                                                    onChange={e => {
                                                        const value = e.target.checked ? [tag, ...selectedKeys] : selectedKeys.filter(key => key !== tag);
                                                        setSelectedKeys(value);
                                                    }}
                                                >
                                                    {tag}
                                                </Checkbox>
                                            </div>
                                        )}

                                        {(this.state.currentTagName === tag) ? (
                                            <>
                                                <Tooltip placement="top" title="Cancel">
                                                    <Button size="small" shape="circle-outline" type="default" icon={<CloseOutlined />} style={{ color: 'red', marginLeft: '2px' }} onClick={() => this.setState({ currentTagName: null })} />
                                                </Tooltip>
                                                {/* {(this.state.newTagName !== this.state.currentTagName) && ( */}
                                                    <Tooltip placement="top" title="Edit Tag">
                                                        <Button size="small" shape="circle-outline" type="default" icon={<CheckOutlined />} style={{ color: 'green', marginLeft: '2px' }} onClick={() => this.handleTagSaveClick(confirm, clearFilters, 'edit')} />
                                                    </Tooltip>
                                                {/* )} */}
                                                <Tooltip placement="top" title="Delete Tag">
                                                    <Button size="small" shape="circle-outline" type="default" icon={<DeleteOutlined />} style={{ color: 'orange', marginLeft: '2px' }} onClick={() => this.handleTagSaveClick(confirm, clearFilters, 'delete')} />
                                                </Tooltip>
                                            </>
                                        ) : (
                                            <>
                                                {(canTagDelete) && (
                                                    <Tooltip placement="top" title="Tag Actions">
                                                        <Button size="small" shape="circle-outline" type="default" icon={<EditOutlined />} style={{ marginLeft: 'auto', color: '#1890ff' }} onClick={() => this.handleTagEditClick(tag)} />
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                        
                                        
                                        <br />
                                    </div>
                                )
                            )}
                        </div>

                        <Divider style={{padding: '0px', margin: '5px'}} />
                        
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                onClick={clearFilters}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Reset
                            </Button>

                            <Button
                                type="primary"
                                onClick={() => { confirm() }}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Filter
                            </Button>
                        </div>
                        
                    </div>
                ),
                onFilter: (value: any, record: any) => record.tags?.filter((tag: any) => tag.tagId === value),
                sorter: (a: any, b: any) => (!a.tags && !b.tags) ? 0 : (a.tags && b.tags) ? a.tags.length - b.tags.length : (a.tags && !b.tags) ? a.tags.length ? (!a.tags && b.tags) : b.tags.length : 0,
                dataIndex: "tags",
                responsive: ["lg"] as Breakpoint[],
                // className: (isOprRead && isOprUpdate) ? "" : "hidden",
                render: (record: any) => (
                    <>
                      {record?.map((tag: any, key: number) => {
                          return (
                            <Tag key={key} style={{ margin: '2px' }}>
                                { tag.tagName.toUpperCase()}
                            </Tag>
                        );
                        
                      })}
                    </>
                  ),
            },
            {
                title: "Operator ID/RFID",
                column_name: "Operator ID/RFID",
                responsive: ["md"] as Breakpoint[],
                align: "center" as "center",
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                sortOrder: this.state.assetTableFilters.sortColumn == 'operatorid/rfid' ? this.state.assetTableFilters.sortDirection : false,
                className: (isOprRead && isOprUpdate) ? "" : "hidden",
                render: (text: any, record: any) => (
                    <>
                        {
                            (record.operatorIdValue || "") === "" ?
                                <a href="# " style={{ color: 'red' }} onClick={(e) => this.goToOperators(e, record.accessCardValue, record.operatorsHistoryId,false)}> {record.accessCardValue} </a>
                                : <a href="# " onClick={(e) => this.goToOperators(e, record.operatorIdValue, record.operatorId,true)}> {record.operatorIdValue} </a>
                        }
                    </>
                )
            },
            {
                title: "Signal Alarms",
                column_name: "Signal Alarms",
                responsive: ["md"] as Breakpoint[],
                align: "center" as "center",
                render: (text: any, record: any) => (
                    <span className="no-word-wrap">
                        {
                            record.alertInfoCount > 0 &&
                            <span className="pr-5">
                                <span className="pr-5"><img src={infoIcon} alt="" /></span>
                                <span data-id="assets-data-info">{record.alertInfoCount}</span>
                            </span>
                        }
                        {
                            record.alertWarningCount > 0 &&
                            <span className="pr-5">
                                <span className="pr-5"><img src={warningIcon} alt=""/></span>
                                <span data-id="assets-data-warning">{record.alertWarningCount}</span>
                            </span>
                        }
                        {
                            record.alertCriticalCount > 0 &&
                            <span className="pr-5">
                                <span className="pr-5"><img src={criticalIcon} alt="" /></span>
                                <span data-id="assets-data-critical">{record.alertCriticalCount}</span>
                            </span>
                        }
                    </span>  
                )                                           
            },
            {
                title: "Asset Model",
                column_name: "Asset Model",
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                sortOrder: this.state.assetTableFilters.sortColumn == 'assetmodel' ? this.state.assetTableFilters.sortDirection : false,
                dataIndex: "model",
                responsive: ["lg"] as Breakpoint[]
            },
            {
                title: "Serial Number",
                column_name: "Serial Number",
                dataIndex: "mac",
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                sortOrder: this.state.assetTableFilters.sortColumn == 'serialnumber' ? this.state.assetTableFilters.sortDirection : false,
                responsive: ["lg"] as Breakpoint[]
            },
            {
                title: "Mastertag",
                column_name: "Mastertag",
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                dataIndex: "mastertag",
                sortOrder: this.state.assetTableFilters.sortColumn == 'mastertag' ? this.state.assetTableFilters.sortDirection : false,
                responsive: ["lg"] as Breakpoint[]
            },
            {
                title: "Org Name",
                column_name: "Org Name",
                dataIndex: "orgName",
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                sortOrder: this.state.assetTableFilters.sortColumn == 'orgname' ? this.state.assetTableFilters.sortDirection : false,
                responsive: ["lg"] as Breakpoint[],
                render: (text: any, record: any) => {
                    let parents = record?.orgPathName?.includes("~") ? record?.orgPathName.split('~') : [record?.orgPathName];
                    if (parents.length > 1) {
                        parents = parents.filter((parent: any) => {
                            if (!parent.toLowerCase().includes("parker")) {
                                return parent
                            }
                        })
                    }
                    return (
                        parents.length > 2 && record.objectAdmin ?
                        <>
                            <span className="no-word-wrap">
                                <Tooltip overlayInnerStyle={{ width: '350px' }} title={
                                    parents.map((parent: any, index: number) => {
                                        return parents.length - 1 == index ? parent : parent + " \\ " 
                                    })
                                }>
                                    <span>{record.orgName}</span>
                                </Tooltip>
                            </span>  
                        </>
                        : <span>{record.orgName}</span>
                    )
                }
            },
            {
                title: "Template",
                column_name: "Template",
                dataIndex: "templateName",
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                sortOrder: this.state.assetTableFilters.sortColumn == 'template' ? this.state.assetTableFilters.sortDirection : false,
                responsive: ["lg"] as Breakpoint[],
                className: appContext.roleName === "guest" ? "hidden" : "",
                render: (text: any, record: any) => (
                    record.status != 'inactive' ?
                    <>
                        {
                            (!record.templateIsDeleted && templatePermissions[1]) ? record.templateVisibleToSubOrg ?
                            <Badge dot offset={[5, 5]}>
                                <a href={`/template/details/${encodeURIComponent(record.templateName)}/${record.templateId}`} onClick={(e) => {this.goToTemplate(e, record.templateName, record.templateId)}}> 
                                    {record.templateName} 
                                </a>
                            </Badge> :
                            <a href={`/template/details/${encodeURIComponent(record.templateName)}/${record.templateId}`} onClick={(e) => {this.goToTemplate(e, record.templateName, record.templateId)}}> 
                                {record.templateName} 
                            </a> : <></>

                        }
                        {
                            (!record.templateIsDeleted && !templatePermissions[1]) ? record.templateVisibleToSubOrg ?
                            <Badge dot offset={[5, 5]}>
                                <span>{record.templateName}</span> 
                            </Badge>
                            : <span>{record.templateName}</span> 
                            : <></>
                        }
                        {
                            record.templateIsDeleted &&
                            <>
                                <Tooltip title="Template is deleted">
                                    <span className="asset-status-red pr-5" ><CloudServerOutlined /></span>
                                </Tooltip>
                                <span>{record.templateName}</span>
                            </>
                        }
                    </>
                    : <span>{record.templateName}</span>
                )              
            },
            /*
                {
                    title: "Users Subscribed",
                    dataIndex: "userSubscriptionCount",
                    align: "center" as "center",
                    render: (text: number, record: any) => (
                        <div className="notifications-tag">
                            {
                                text > 0 && 
                                <Tag color="geekblue">{text}</Tag>
                            }
                        </div>
                    ),
                    responsive: ["lg"] as Breakpoint[]
                },
            */
            {
                title: "Contract Start Date",
                column_name: "Contract Start Date",
                dataIndex: "contractStartDate",
                // sortDirections: ['ascend', 'descend', 'ascend'],
                // sorter: true,
                // sortOrder: this.state.assetTableFilters.sortColumn == 'contractStartDate' ? this.state.assetTableFilters.sortDirection : false,
                align: "center" as "center",
                render: (text: any, record: any) => (
                    <span className="no-word-wrap">
                        {
                            //Hide contract date for no plan part number
                            (record.contractStartDate != record.contractEndDate) &&
                            (moment(record.contractEndDate, "L").diff(moment(), 'days') <= 30 ?
                                <>
                                    <span style={{ color: 'red' }}><b>{record.contractStartDate}</b></span>
                                </>
                                :
                                <span >{record.contractStartDate}</span>
                            )
                        }
                    </span>  
                ),
                responsive: ["lg"] as Breakpoint[]
            },
            {
                title: "Contract End Date",
                column_name: "Contract End Date",
                dataIndex: "contractEndDate",
                // sortDirections: ['ascend', 'descend', 'ascend'],
                // sorter: true,
                // sortOrder: this.state.assetTableFilters.sortColumn == 'contractEndDate' ? this.state.assetTableFilters.sortDirection : false,
                align: "center" as "center",
                render: (text: any, record: any) => (
                    <span className="no-word-wrap">
                        {
                            //Hide contract date for no plan part number
                            (record.contractStartDate != record.contractEndDate) &&
                            (moment(record.contractEndDate, "L").diff(moment(), 'days') <= 30 ?
                                <>
                                    <span style={{ color: 'red' }}><b>{record.contractEndDate}</b></span>
                                </>
                                :
                                <span >{record.contractEndDate}</span>
                            )
                        }
                    </span>  
                ),
                responsive: ["lg"] as Breakpoint[]
            },
            {
                title: "Actions",
                column_name: "Actions",
                align: "center" as "center",
                render: (text: string, record: any) => (
                    ((access==null || (access && access.length===0))
                    && (!record.blockedassetOrgId ? true : (contextUser.homeOrg.orgId !== record.blockedassetOrgId ? true:false)))?
                    (record.status != 'replaced' ?
                    (
                    <Space>
                        
                        {
                            // Roles and permissions
                            // (
                            //     record.objectAdmin ||
                            //     (
                            //         !record.objectAdmin &&         
                            //         record.status !== "whitelisted"
                            //     )
                            // ) && 

                            (isupdate || isBarko) &&
                            <Tooltip title="Edit Asset">
                                <Button data-id="assets-edit-asset-button" type="default" icon={<EditOutlined />} onClick={(e) => {this.assetMaintOpen(e, record)}}/>
                            </Tooltip>
                        }
                        {
                            contextUser.contextUserIsAdmin &&
                            record.blockedassetOrgId && contextUser.homeOrg.orgTree.length < 3 &&
                            <Popconfirm
                            title={"Do you really want to unblock the asset?"}
                            onConfirm={(e) => {this.blockAsset(e, record)}}
                            okText="Yes"
                            cancelText="No"
                            >
                                <Tooltip title="Unblock Asset">
                                    <Button shape="circle" icon={<LockOutlined />} />
                                </Tooltip>
                            </Popconfirm>
                        }
                        {
                            contextUser.contextUserIsAdmin && 
                            !record.blockedassetOrgId && contextUser.homeOrg.orgTree.length < 3 &&
                            <Popconfirm
                            title={"Do you really want to block the asset?"}
                            onConfirm={(e) => {this.blockAsset(e, record)}}
                            okText="Yes"
                            cancelText="No"
                            >
                                <Tooltip title="Block Asset">
                                    <Button shape="circle" icon={<UnlockOutlined />} />
                                </Tooltip>
                            </Popconfirm>
                        }                      
                        {
                            (contextUser.contextUserIsSuperUser) &&
                            record.status === "active" &&
                            this.confirmInactivation(record, true)
                        }
                        
                        {/*
                            (contextUser.contextUserIsSuperUser) &&
                            (record.status === "inactive" || record.status === "whitelisted") &&
                            this.confirmActivation(record)
                        */}
                    </Space>)
                     :
                     <Tooltip title={`Asset replaced with ${record.activeMastertag}`}>

                    <Space>
                        
                    {
                        // Roles and permissions
                        // (
                        //     record.objectAdmin ||
                        //     (
                        //         !record.objectAdmin &&         
                        //         record.status !== "whitelisted"
                        //     )
                        // ) && 

                        (isupdate || isBarko) &&
                        <Tooltip title="Edit Asset">
                            <Button style={{pointerEvents: 'none', cursor: 'default', opacity: '0.5', color:'#808080'}}
                            data-id="assets-edit-asset-button" type="default" icon={<EditOutlined />} onClick={(e) => {this.assetMaintOpen(e, record)}}/>
                        </Tooltip>
                    }                    
                     {/*
                         (contextUser.contextUserIsSuperUser) &&
                        (record.status === "inactive" || record.status === "whitelisted") &&
                        this.confirmActivation(record)
                    */}
                 </Space>
                 </Tooltip>)
                    :
                    <Tooltip placement="leftTop" title = { assetRestricted } >
                    <Space>
                        {
                            // Roles and permissions
                            // (
                            //     record.objectAdmin ||
                            //     (
                            //         !record.objectAdmin &&         
                            //         record.status !== "whitelisted"
                            //     )
                            // ) && 

                            (isupdate || isBarko) &&
                            <Tooltip title="Edit Asset Denied">
                                <Button style={{pointerEvents: 'none', cursor: 'default', opacity: '0.5', color:'#808080'}}
                                 data-id="assets-edit-asset-button" type="default" icon={<EditOutlined />}/>
                            </Tooltip>
                        }
                         {
                            contextUser.contextUserIsAdmin &&
                            record.blockedassetOrgId && contextUser.homeOrg.orgTree.length < 3 &&
                            <Popconfirm
                            title={"Do you really want to unblock the asset?"}
                            onConfirm={(e) => {this.blockAsset(e, record)}}
                            okText="Yes"
                            cancelText="No"
                            >
                                <Tooltip title="Unblock Asset">
                                    <Button style={{pointerEvents: 'none', cursor: 'default', opacity: '0.5', color:'#808080'}} shape="circle" icon={<LockOutlined />} />
                                </Tooltip>
                            </Popconfirm>
                        }
                        {
                            contextUser.contextUserIsAdmin && 
                            !record.blockedassetOrgId && contextUser.homeOrg.orgTree.length < 3 &&
                            <Popconfirm
                            title={"Do you really want to block the asset?"}
                            onConfirm={(e) => {this.blockAsset(e, record)}}
                            okText="Yes"
                            cancelText="No"
                            >
                                <Tooltip title="Block Asset">
                                    <Button style={{pointerEvents: 'none', cursor: 'default', opacity: '0.5', color:'#808080'}} shape="circle" icon={<UnlockOutlined />} />
                                </Tooltip>
                            </Popconfirm>
                        }      
                        {
                            (contextUser.contextUserIsSuperUser) &&
                            record.status === "active" &&
                            this.confirmInactivation(record, false)
                        }
                        
                        {/*
                            (contextUser.contextUserIsSuperUser) &&
                            (record.status === "inactive" || record.status === "whitelisted") &&
                            this.confirmActivation(record)
                        */}                  
                    </Space>
                    </Tooltip>
                )
            }
        ];


        /* remove unselected columns by user */
        if(!canTagRead) tableColumns.splice(tableColumns.map((item) => item.title).indexOf('Tags'), 1);

        let allColumns = tableColumns.map(a => {return {...a}})
        
        const handleMenuClick = async(columnName: string) => {

            await this.setState((prevState) => ({
                ...prevState,
                tableColumnsHidden: this.state.tableColumnsHidden.indexOf(columnName) === -1 ? [...prevState.tableColumnsHidden, columnName] : [ ...prevState.tableColumnsHidden.filter(ex => ex !== columnName) ]
            }));

        }
    
        const handleVisibleChange = (visibility: boolean) => {
            this.setState({ columnSelectionVisible: visibility });
        }

        const onSearchColumnSelection = async(e: any) => {
            await this.setState({ columnSelectionFilter: e.target.value });
        }

        const menu = (
            <Menu>
                <div>
                    <Input placeholder="Filter Columns" allowClear onChange={onSearchColumnSelection} />
                </div>
                {
                    // eslint-disable-next-line array-callback-return
                    tableColumns.map((column:any) => {
                        if(column.column_name !== 'Actions' && column.column_name.toLowerCase().includes(this.state.columnSelectionFilter.toLowerCase())){
                            return ( 
                                <Menu.Item key={column.column_name}> 
                                    <Checkbox 
                                        onClick={() => handleMenuClick(column.column_name)}
                                        defaultChecked={!this.state.tableColumnsHidden.includes(column.column_name)}
                                    >
                                        {column.column_name}
                                    </Checkbox> 
                                </Menu.Item>
                            )
                        }
                    })
                }
            </Menu>
        );


        tableColumns = allColumns.filter((column:any) => !this.state.tableColumnsHidden.includes(column.column_name))

        return (
            
            <div data-id="assets-container" className="layout-content">
                 
                 {
                    this.state.mode === "details" &&
                    <div data-id="assets-breadcrumbs-container">
                        <Breadcrumb data-id="assets-breadcrumbs" separator="/">
                            <Breadcrumb.Item data-id="assets-breadcrumb-0" key="assets-breadcrumb-0" href="# " onClick={(e) => {this.cancelDetailsMode(e)}}>All Assets</Breadcrumb.Item>
                            <Breadcrumb.Item data-id="assets-breadcrumb-1" key="assets-breadcrumb-1">
                                <Tooltip title="Exit Asset Details View"> 
                                    <>
                                        <a href="# " className="pr-5" onClick={(e) => {this.cancelDetailsMode(e)}}><CloseCircleOutlined /></a>
                                        Asset {this.state.mastertag} Details
                                    </>
                                </Tooltip>
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                }

                <Row gutter={8}>
                    <Col xs={24} lg={this.state.mode === "default" ? 10 : 24}>
                        <div className="content-header page-title">
                            <div style={{ display: "flex" }}>
                                <Tooltip title="Go To Previous Page"> 
                                    <BackNavigation /> 
                                </Tooltip>
                                {
                                    this.state.mode === "default" &&
                                    <span>Assets</span>
                                }
                                {
                                    this.state.mode !== "default" &&
                                    <span>Asset {this.state.mastertag} Details</span>
                                }
                            </div>
                        </div>
                    </Col>                    
                </Row>
                {
                    this.state.mode === "default" && isread &&
                    <Row gutter={8}>
                        <Col xs={24} lg={12} className="mt-10">
                            <span className="asset-status-green pr-5" ></span>
                            <Radio.Group value={this.state.assetTableFilters.status} buttonStyle="solid" onChange={this.onStatusChange}>

                                <Tooltip mouseEnterDelay={0.5} placement="bottom" title="Active assets list" arrowPointAtCenter>
                                    <div style={{ display: 'inline-block', margin: '0 0 0 0', padding: '0 0 0 0', borderRadius: '0', border: '0'}}>
                                        <Radio.Button value="active">
                                            <CheckCircleOutlined className="pr-5" />Active
                                        </Radio.Button>
                                    </div>
                                </Tooltip>

                                <Tooltip mouseEnterDelay={0.5} placement="bottom" title={
                                    <div>
                                        <span>Assets with expired contract having:</span><br />
                                        <span>1. Deactivated SIM</span><br />
                                        <span>2. Disabled on system</span><hr />
                                        <span><b>Note:</b> Contact your system admin for reactivation of assets</span>
                                    </div>
                                } arrowPointAtCenter>
                                    <div style={{ display: 'inline-block'}}>
                                        <Radio.Button value="inactive">
                                            <CloseCircleOutlined className="pr-5" />Inactive
                                        </Radio.Button>
                                    </div>
                                </Tooltip>

                                <Tooltip mouseEnterDelay={0.5} placement="bottom" title="Assets not yet deployed. Assets with no assigned template" arrowPointAtCenter>
                                    <div style={{ display: 'inline-block'}}>
                                        <Radio.Button value="whitelisted">
                                            <ClockCircleOutlined className="pr-5" />Whitelisted
                                        </Radio.Button>
                                    </div>
                                </Tooltip>

                                <Tooltip mouseEnterDelay={0.5} placement="bottom" title="Show all assets regardless of type" arrowPointAtCenter>
                                    <div style={{ display: 'inline-block'}}>
                                        <Radio.Button value="active,inactive,whitelisted,replaced"
                                        >All</Radio.Button>
                                    </div>
                                </Tooltip>
                        
                                <Tooltip mouseEnterDelay={0.5} arrowPointAtCenter title={`Assets with lite capabilities only`} placement="bottom">
                                    <div style={{ display: 'inline-block'}}>
                                        <Radio.Button value="LiteAssets">Lite</Radio.Button>
                                    </div>
                                </Tooltip>

                            </Radio.Group>
                        </Col>
                        <Col xs={24} lg={12} className="content-header-controls mt-10">
                            <Space>
                                { this.state.isAssetTableFiltersSet &&
                                    <Tooltip title={<><span>- Reset all filters</span><br /><span>- Restore table to original state</span></>}>
                                        <Button
                                            style={{ backgroundColor: '#eba134', color: 'white' }}
                                            icon={<Icon component={filterRemove} style={{fontSize: 22 }} />}
                                            onClick={() => this.resetAssetTableFilters()}
                                        />
                                    </Tooltip>
                                }

                                {
                                    (isread) && !this.state.showLiteAssets &&   
                                    <Tooltip mouseEnterDelay={0.5} title="Search by asset name, mastertag, template name, serial, organization, asset model, tag">
                                        <Search
                                            data-id="assets-search-field" 
                                            placeholder="Search by asset name, mastertag, template name, serial, organization, asset model, tag"
                                            enterButton="Search"
                                            size="middle"
                                            allowClear
                                            onSearch={this.onSearch}
                                            onChange={this.onSearchChange}
                                            className="search-field"
                                            value={this.state.assetTableFilters.searchText}
                                        />
                                    </Tooltip>
                                } 
                                {
                                    this.state.showLiteAssets &&    
                                    <Tooltip mouseEnterDelay={0.5} title="Search by asset name, mastertag, template name, serial, organization, asset model, tag">
                                        <Search
                                            data-id="assets-search-field" 
                                            placeholder="Search by asset name, mastertag, partnumber, planquota, organization, template"
                                            enterButton="Search"
                                            size="middle"
                                            allowClear
                                            onSearch={this.onLiteAssetSearch}
                                            onChange={this.onLiteAssetSearchChange}
                                            className="search-field"
                                            value={this.props.assetsList.liteAssets.searchText}
                                        />
                                    </Tooltip>
                                }
                                {
                                    (contextUser.contextUserIsAdmin || iscreate) && !this.state.showLiteAssets &&                   
                                    <div className="add-user-button">
                                        <Button data-id="assets-search-new-button" type="primary" icon={<PlusOutlined />} onClick={(e) => this.assetMaintOpen(e, {})}>
                                            <span>Asset</span>
                                        </Button>
                                    </div>
                                }
                                {

                                    !this.state.showLiteAssets && 
                                    <div className="add-user-button">
                                        <Dropdown 
                                            overlay={menu}
                                            onVisibleChange={handleVisibleChange}
                                            visible={this.state.columnSelectionVisible}
                                        >
                                            <Button data-id="assets-search-new-button" type="primary">
                                                <span>Columns</span>
                                                <PlusOutlined />
                                            </Button>
                                        </Dropdown>
                                    </div>
                                }
                            </Space>
                        </Col>
                    </Row>
                }
                {
                    (isread) && !this.state.showLiteAssets &&
                    <Table 
                        data-id="assets-data" 
                        className="mt-15"
                        //style={{ overflowX: 'auto' }} 
                        {...tableConfig}
                        rowKey={(record) => record.gatewayId}
                        columns={tableColumns}
                        dataSource={tableData}
                        onChange={this.sortAssetsTable}
                        showSorterTooltip={false}
                    />
                }
                { 
                    this.state.showLiteAssets &&
                    <LiteAssets/>
                }
                <AssetMaint iscreate={iscreate} isupdate={isupdate} isread={isread}/>
            </div>
        );
    }
}

const mapStateToProps = (state:any) => {    
    return {        
        context: state.contextReducer.context,
        permissions: state.contextReducer.data,
        assetsList: state.assetsReducer.assetsState.assetsList,        
        permissionsset: state.permissionsReducer,
    };
};

export default connect(
    mapStateToProps,
    actions
  )(Assets);
  
