import React from "react";
import { Drawer, Button, Space, Tooltip, message } from "antd";
import { PlusOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import * as actions from "../actions";
import history from '../../../utils/history';

import { appConfigs } from "../../../utils/configurations";

import SignalList from "../signals/SignalList";

import "./signals.css";

interface Prop {
    signalsClose: Function,  
    getSignals: Function, 
    signalMaintOpen: Function,
    signalListSetSearch: Function,
    getSignalCollections: Function,
    signals: any,
    signalCollections: any,
    templateDetails: any,
    iscreateOrUpdateTemplate: boolean,
    iscreate: boolean,
    isread: boolean,
    isdelete: boolean,
    iscreateRules: boolean,
    isreadRules: boolean,
    isupdateRules: boolean,
    isdeleteRules: boolean,
    isGlobalTemplate: boolean,
    context: any,
}

class Signals extends React.Component<Prop, {}> {  
   
    drawerWidth:number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth - (window.innerWidth > 575 ? 100 : 0));

    getTemplateId() {
        const pathnameArr = history.location.pathname.split("/");
        return pathnameArr[pathnameArr.length - 1];
    }

    onDrawerClose = () => {
        if (this.props.signalCollections.refreshOnUpdate) {
            this.props.getSignalCollections(this, null, null, this.getTemplateId(), this.props.signalCollections.tableConfig.pagination.current, this.props.signalCollections.searchText);
        } 
        this.props.signalsClose(this);
    };
    
    gotoCollection = (e:any, direction:string) => {
        e.preventDefault();

        const 
            tableData = this.props.signalCollections.tableData;
        let 
            currentId = this.props.signals.signalCollectionId,
            currentName = "",
            currentType = "",
            currentRecordIndex = -1;

        tableData.forEach((record: any, index: number) => {
            if (record.signalCollectionId === currentId) {
                currentRecordIndex = index;
            }
        });

        if (currentRecordIndex !== -1) {
            if (direction === "next" ) {
                currentRecordIndex = (currentRecordIndex === tableData.length - 1 ? 0 : currentRecordIndex + 1);
            }
            if (direction === "previous") {
                currentRecordIndex = (currentRecordIndex === 0 ? tableData.length - 1 : currentRecordIndex - 1);
            }
            currentId = tableData[currentRecordIndex].signalCollectionId;
            currentName = tableData[currentRecordIndex].name;
            currentType = tableData[currentRecordIndex].type;
            
            this.props.signalListSetSearch(""); 
            this.props.getSignals(this, null, null, this.getTemplateId(), currentId, currentName, currentType, 1, "");
        }
        else {
            message.error("Unable navigate to " + direction + " collection");
        }
    };

    addSignal = (e:any, type:any) => {
        e.preventDefault();
       
        this.props.signalMaintOpen(this, this.drawerWidth, {}, type);
    };

    render() {
        
        const { title, width, visible } = this.props.signals;
        const { templateRecord } = this.props.templateDetails;       
        let disableAddSignal = (
            (templateRecord.allowedMaxNoOfSignals && templateRecord.noOfSignals &&
            templateRecord.allowedMaxNoOfSignals <= templateRecord.noOfSignals)
         || ( this.props.signals.signalCollectionType === "Command" && templateRecord.orgCmdPgnSpns === '-')
        );
        let disableTooltip = this.props.signals.signalCollectionType === "Command" && templateRecord.orgCmdPgnSpns === '-' ?
            "Local org does not support remote control" :
            "Exceeds the max no. of signals defined in the quota of assets applied to the template";
        let addSignalTooltip = disableAddSignal ?  disableTooltip : "Add Signal";
 
        return (
            <Drawer
                data-id="signals-drawer" 
                title={title}
                width={width}
                onClose={this.onDrawerClose}
                visible={visible}
                zIndex={2}
                destroyOnClose={true}
                footer={
                    <>
                        <Space size="middle" style={{ float: 'left' }}>
                            {
                                (this.props.signalCollections.tableData.length || 0) > 1 && this.props.isread &&
                                <>
                                    <Tooltip title="Previous Collection">
                                        <Button data-id="signals-previous-collection-button" type="default" onClick={(e) => {this.gotoCollection(e, "previous")}}>
                                            <LeftOutlined /><span className="pl-5">Collection</span>
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Next Collection">
                                        <Button data-id="signals-next-collection-button" type="default" onClick={(e) => {this.gotoCollection(e, "next")}}>
                                            <span className="pr-5">Collection</span><RightOutlined /> 
                                        </Button>
                                    </Tooltip>
                                </>
                            }
                            {
                                ((templateRecord.templateVisibleToSubOrg && this.props.context.appContext.roleName.toLowerCase() === "admin") 
                                || (!templateRecord.templateVisibleToSubOrg && this.props.iscreate &&
                                    (this.props.signals.signalCollectionType !== "GPS" &&
                                     this.props.signals.signalCollectionType !== "IQAN Diagnostics"
                                    )
                                )) &&
                                <Tooltip title={addSignalTooltip}>
                                    <Button disabled={disableAddSignal}data-id="signals-new-signal-button" type="default" icon={<PlusOutlined />} onClick={(e) => {this.addSignal(e,this.props.signals.signalCollectionType)}}>
                                        <span className="pl-5">Signal</span>
                                    </Button>              
                                </Tooltip>                                
                            }
                        </Space>
                        <Button data-id="signals-previous-close-button" type="default" style={{ float: 'right' }} onClick={this.onDrawerClose}>
                            {appConfigs.settings.drawers.closeButtonText}
                        </Button>
                    </>
                }
            >
                <SignalList iscreateOrUpdateTemplate={this.props.iscreateOrUpdateTemplate} isread={this.props.isread} isdelete={this.props.isdelete}
                    iscreateRules={this.props.iscreateRules} isreadRules={this.props.isreadRules} isupdateRules={this.props.isupdateRules} isdeleteRules={this.props.isdeleteRules}
                    isGlobalTemplate={this.props.isGlobalTemplate}/>
            </Drawer>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        signals: state.templateDetailsReducers.templateDetailsState.signals,
        signalCollections: state.templateDetailsReducers.templateDetailsState.tabs[0],
        templateDetails: state.templateDetailsReducers.templateDetailsState
    };
};

export default connect(
    mapStateToProps,
    actions
)(Signals);