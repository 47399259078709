import React from "react";
import { Form, Button, Modal, Tooltip,  message, notification, Select, InputNumber, Alert } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';

import { connect } from "react-redux";
import * as actions from "./actions";

import { appConfigs } from "../../utils/configurations";
import { uuidv4 } from "../../utils/commonHelpers";
import { download } from "../../utils/api/download";

const { Option } = Select;

interface State {
    visible: boolean;
}

interface Props {
    alertsState: any;
    mastertag: string;
    mode: string;
    pageSize: number;
    totalRecords: number;
    currentPage: number;
    selectedViewType: string
}

class Export extends React.Component<Props, State> {  

    state:State = {
        visible: false
    }
    
    formRef = React.createRef<FormInstance>();

    openModal = (e:any) => {   
        e.preventDefault();     
        
        this.setState({
            visible: true
        });

        const me = this;
        setTimeout(function() {
            if (me.formRef.current! !== null) {
                me.formRef.current!.setFieldsValue({
                    scope: "current-page"
                });
            }
        }, 250);  
    };    

    cancelModal = (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        
        this.setState({
            visible: false
        });

        this.formRef.current!.resetFields();  
    };

    totalPages = ():number => {
        return Math.floor(this.props.totalRecords / this.props.pageSize) + 1;  
    };    

    downloadFm1Faults = (mode: string, scope: string, fromPage: number, toPage: number) => { 
        const appliedFilters = this.props.alertsState.tabs[0].appliedFilters;   
        const url = appConfigs.server.URL + "/ui/api/alerts/" + mode + ".csv" +
            "?scope=" + scope + 
            "&fromPage=" + fromPage + 
            "&toPage=" + toPage +
            "&pageSize=" + this.props.alertsState.tabs[0].tableConfig.pagination.pageSize +
            "&maxPages=" + appConfigs.app.alerts.exportMaxPages +
            "&mastertag=" + encodeURIComponent((this.props.mastertag || "")) +
            "&isSeen=" + (this.props.selectedViewType || "") +
            "&timespanFromFilter=" + encodeURIComponent((appliedFilters.timespanFrom || "")) +
            "&timespanToFilter=" + encodeURIComponent((appliedFilters.timespanTo || "")) +
            "&nicknameFilter=" + encodeURIComponent((appliedFilters.nickname || []).join("~")) +
            "&saFilter=" + encodeURIComponent((appliedFilters.sa || []).join("~")) +
            "&spnFilter=" + encodeURIComponent((appliedFilters.spn || []).join("~")) +
            "&fmiFilter=" + encodeURIComponent((appliedFilters.fmi || []).join("~")) +
            "&statusFilter=" + encodeURIComponent((appliedFilters.status || []).join("~")) +
            "&tagsFilter=" + encodeURIComponent((appliedFilters.tags || []).join("~")) +
            "&timeZone=" + encodeURIComponent((Intl.DateTimeFormat().resolvedOptions().timeZone || "")) +
            "&languageTag=" + encodeURIComponent((navigator.language || ""));

        download(this, null, null, url, mode + "-" + uuidv4() + ".csv");
    }

    downloadSignalAlerts = (mode: string, scope: string, fromPage: number, toPage: number) => {   
        const appliedFilters = this.props.alertsState.tabs[1].appliedFilters;   
        const url = appConfigs.server.URL + "/ui/api/alerts/" + mode + ".csv" +
            "?scope=" + scope + 
            "&fromPage=" + fromPage + 
            "&toPage=" + toPage +
            "&pageSize=" + this.props.alertsState.tabs[1].tableConfig.pagination.pageSize +
            "&maxPages=" + appConfigs.app.alerts.exportMaxPages +
            "&mastertag=" + encodeURIComponent((this.props.mastertag || "")) +
            "&isSeen=" + (this.props.selectedViewType || "") +
            "&timespanFromFilter=" + encodeURIComponent((appliedFilters.timespanFrom || "")) +
            "&timespanToFilter=" + encodeURIComponent((appliedFilters.timespanTo || "")) +
            "&nicknameFilter=" + encodeURIComponent((appliedFilters.nickname || []).join("~")) +
            "&statusFilter=" + encodeURIComponent((appliedFilters.status || []).join("~")) +
            "&tagsFilter=" + encodeURIComponent((appliedFilters.tags || []).join("~")) +
            "&timeZone=" + encodeURIComponent((Intl.DateTimeFormat().resolvedOptions().timeZone || "")) +
            "&languageTag=" + encodeURIComponent((navigator.language || ""));
            
        download(this, null, null, url, mode + "-" + uuidv4() + ".csv");
    }
    
    onFinish = (values: any) => {    

        let fromPage:number = 1;
        let toPage:number = 1;
        
        if (values.scope === "all-pages") {
            fromPage = 1;
            toPage = this.totalPages();
        }
        if (values.scope === "current-page") {
            fromPage = this.props.currentPage;
            toPage = this.props.currentPage;
        }
        if (values.scope === "custom-pages") {
            fromPage = values.fromPage ? values.fromPage : this.totalPages();
            toPage = values.toPage ? values.toPage : this.totalPages();
        }
        //ops-2059 fix
        if (this.props.mode === "dm1-faults") {
            this.downloadFm1Faults(this.props.mode, values.scope, fromPage, toPage);        
        }
        else {
            this.downloadSignalAlerts(this.props.mode, values.scope, fromPage, toPage);        
        }
    };

    scopeChange = (value: string) => { 
        if (value === "custom-pages") {
            if (!this.formRef.current?.getFieldValue("fromPage")) {
                this.formRef.current!.setFieldsValue({
                    fromPage: 1
                });
            }
            if (!this.formRef.current?.getFieldValue("toPage")) {
                const me = this;
                this.formRef.current!.setFieldsValue({
                    toPage: Math.min(me.totalPages(), 5)
                });
            }
        }
    }

    validateCustomPages = (fromPage: number, toPage: number) : Promise<any>  => { 
        if (fromPage && toPage) {
            if (fromPage > toPage) {
                return Promise.reject(
                    "Value must be less than, or equal to, To Page"
                );
            }
        }
        if (!(fromPage || toPage)) {
            return Promise.reject(
                "Enter at least the starting page"
            );
        }
         
        return Promise.resolve();
    }

    render() {

        const me = this;

        return (

            <div>
                <Tooltip title="Export data into .csv file">            
                    <Button data-id="alerts-export-button" href="# " className="mt-10" onClick={(e) => {this.openModal(e)}} target="_new" type="default" icon={<ExportOutlined />}>
                        <span>Export</span>
                    </Button>
                </Tooltip>

                <Modal
                    title={"Export "  + (this.props.mode === "dm1-faults" ? "DM1 Alerts" : "Signal Alerts")}
                    visible={this.state.visible}
                    onCancel={this.cancelModal}
                    okButtonProps={{ hidden: true }}
                    cancelButtonProps={{ hidden: true }}
                    footer={[
                        <Button type="primary" form="alertsExportForm" key="submit" htmlType="submit">
                            {appConfigs.settings.form.submitButtonText}
                        </Button>
                    ]}
                >                   
                    <Form
                        id="alertsExportForm"
                        ref={this.formRef}
                        layout="vertical"
                        onFinish={this.onFinish}
                        className="mt-10"
                    >   
                        <Form.Item
                            label="Scope" 
                            name="scope"      
                            rules={[
                                { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                            ]}             
                        >
                            <Select
                                style={{ minWidth: 180}}
                                onChange={this.scopeChange}
                            >
                                <Option key="current-page" value="current-page">Current Page</Option>
                                <Option key="custom-pages" value="custom-pages">Custom Pages</Option>
                                <Option key="all-pages" value="all-pages">All Pages</Option>
                            </Select> 
                        </Form.Item>

                        <Form.Item 
                            noStyle
                            shouldUpdate={true}
                        >                        
                            {({ getFieldValue }) =>
                                getFieldValue("scope") === "custom-pages" ? (
                                    <>
                                        <Form.Item
                                            label="From Page"
                                            name="fromPage" 
                                            style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}                                                             
                                            dependencies={["toPage"]}
                                            rules={[
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        return me.validateCustomPages(
                                                            value,
                                                            getFieldValue("toPage")
                                                        );
                                                    },
                                                }),
                                            ]}
                                        >
                                            <InputNumber 
                                                min={1} 
                                                precision={0}
                                                pattern="[0-9]*"
                                                max={this.totalPages()} 
                                                style={{ width: '100%' }} 
                                            />
                                        </Form.Item>
                                        <span
                                            style={{ display: 'inline-block', width: '24px'}}
                                        />
                                        <Form.Item
                                            label="To Page"
                                            name="toPage"
                                            style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                                        >                                            
                                            <InputNumber 
                                                min={1} 
                                                precision={0}
                                                pattern="[0-9]*"    
                                                max={this.totalPages()} 
                                                style={{ width: '100%' }} 
                                            />
                                        </Form.Item>
                                    </>
                                ) : null
                            }
                        </Form.Item>

                        <Form.Item 
                            noStyle
                            shouldUpdate={true}
                        >                        
                            {({ getFieldValue }) =>
                                (getFieldValue("scope") === "custom-pages" && (getFieldValue("toPage") || 1) - (getFieldValue("fromPage") || 1) + 1 > appConfigs.app.alerts.exportMaxPages)|| 
                                (getFieldValue("scope") === "all-pages" && this.totalPages() > appConfigs.app.alerts.exportMaxPages) ? (
                                    <Alert
                                        className="mt-20"
                                        message="Info"
                                        description={`Due to performance considerations, up to ${appConfigs.app.alerts.exportMaxPages} pages will be exported in a single file.`}
                                        type="info"
                                        showIcon
                                    />
                                ) : null
                            }
                        </Form.Item>
                        
                    </Form>
                </Modal>
            </div>

        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        signalCollections: state.templateDetailsReducers.templateDetailsState.tabs[0],
        signalCollectionMaint: state.templateDetailsReducers.templateDetailsState.tabs[0].signalCollectionMaint,
        alertsState: state.alertsReducer.alertsState
    };
};

export default connect(
    mapStateToProps,
    actions
)(Export);