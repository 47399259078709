import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function applicationsState(state = initialState, action:any) {
    let data:any = null,
        payload:any;
        switch (action.type) {  
            case "APPLICATIONS_GET_SUCCESS":
                data = action.payload.data;
                return immerProduce(state, draft => {
                    draft.applications.applicationsList.refreshOnUpdate = false;
                    draft.applications.applicationsList.tableData = data;
                    draft.applications.applicationsList.tableConfig.pagination.total = data ? data[0].totalRecords : 0;
                    draft.applications.applicationsList.tableConfig.pagination.current = action.payload.page;
                });
            case "APPLICATION_SET_SEARCH":
                    return immerProduce(state, draft => {
                    draft.applications.applicationsList.searchText = action.searchText;
            });
            case "APPLICATIONS_SET_PAGINATION":
                    return immerProduce(state, draft => {
                    draft.applications.applicationsList.tableConfig.pagination.onChange = action.onPaginationChange;
            });
            case "SET_TAB":
                    return immerProduce(state, draft => {
                    draft.applications.activeTab = action.tabNumber;
            });
            case "OPEN_DRAWER":
                    return immerProduce(state, (draft) => {
                        const payload = action.payload,
                            instance = Math.random();
                        draft.applications.applicationMaintOptions.instance = instance;
                        draft.applications.applicationMaintOptions.width = payload.width;
                        draft.applications.applicationMaintOptions.visible = true;
                        draft.applications.applicationMaintOptions.title = payload.title;
                        draft.applications.applicationMaintOptions.activeTab = "1";
                        draft.applications.applicationMaintOptions.record = payload.record;
                    });
            case "CLOSE_DRAWER":
                    return immerProduce(state, (draft) => {
                        draft.applications.applicationMaintOptions.visible = false;
                        draft.applications.applicationMaintOptions.activeTab = "1";
                        draft.applications.applicationMaintOptions.record = {};
                    });
            case "DRAWER_TAB_CHANGE":
                    return immerProduce(state, (draft) => {
                        draft.applications.applicationMaintOptions.activeTab = action.activeTab;
                    });
            case "SET_APPLICATIONS_LIST":
                    return immerProduce(state, (draft) => {
                        draft.applications.applicationsModification.applicationsList = action.payload.applicationsList;
                        draft.applications.applicationsModification.loadingApplicationsList = false;
                    });
            case "SET_LOADING_APPLICATIONS":
                return immerProduce(state, (draft) => {
                    draft.applications.applicationsModification.loadingApplicationsList = action.payload.state;
                });
            case "ADD_APPLICATION":
                return immerProduce(state, (draft) => {
                    draft.applications.applicationsModification.submittingApplicationList = action.payload.state;
                });
            case "ADD_APPLICATION_SUCCESSFULLY":
                return immerProduce(state, (draft) => {
                    draft.applications.applicationsModification.submittingApplicationList = false;
                    draft.applications.applicationsModification.selectedApplicationList = []
                });
            case "UPDATE_FIELD":
                return immerProduce(state, (draft) => {
                    if(draft.applications.applicationsModification.selectedApplicationList){
                        draft.applications.applicationsModification.selectedApplicationList = draft.applications.applicationsModification.selectedApplicationList.map((application: any) => {
                            if(application.mastertag === action.payload.mastertag){
                                application[action.payload.filedName] = action.payload.value
                            }
                            return application
                        });
                    }
                });
            case "GET_CONTROLLERS_LIST":
                return immerProduce(state, (draft) => {
                    draft.applications.controllersList= action.controllersList;
                })
            default:
                return state;
        }
}