import React, { Component } from "react";
import { Table, Button,Popover, message, Typography, Popconfirm, Input, Row, Col, Space, Tooltip } from "antd";
import { PlusOutlined, EllipsisOutlined, UserDeleteOutlined, EditOutlined, DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import "./users.css";

import { appConfigs } from "../../utils/configurations";
import { closePopover, getPermissions } from "../../utils/commonHelpers";
import history from '../../utils/history';

import { connect } from "react-redux";
import * as actions from "./actions";
import BackNavigation from "../../utils/Components/BackNavigation";
import RoleMaint from './RoleMaint';
import UserMaint from './UserMaint';
//import { getPermissions } from "../../actions/permissions";

const { Search } = Input;
const { Text } = Typography;

interface Prop {
    usersSetPagination: Function,
    usersSetSearch: Function,
    getUsers: Function,
    getUser: Function,
    userStatus: Function,
    userMaintOpen: Function,
    roleMaintOpen: Function, 
    getRolePermissions:Function,  
    getOrgRoles:Function,
    getOrgUserRoles: Function,
    context: any,
    usersList: any,
    permissions: any,
    roleList: any,
    rolePermissionList: any
}

class Users extends Component<Prop, {}> {

    drawerWidth:number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);
    
    componentDidMount () {
        const { appContext } = this.props.context;
        let sortDetails = { sortColumn: 'name', sortDirection:  'ascend' }
        
        // this.props.usersSetPagination(this.onPaginationChange);
        this.props.getUsers(this, null, null, 1, this.props.usersList.searchText, true, sortDetails);
        //this.props.getOrgRoles(this,null,null,appContext.orgId);            
        //this.props.getRolePermissions(this,null,null,"1");
                
    }
    
    // onPaginationChange = (page: number) => {
    //     this.props.getUsers(this, null, null, page, this.props.usersList.searchText, true);
    // };

    onAction = (e:any, record:any, action:string) => {
        e.preventDefault();

        if ( action === "add-user") {
            this.props.userMaintOpen(this, "New User", this.drawerWidth, {},"NewUser");
        }
        else if ( action === "edit-user") {
            this.props.getUser(this, this.onUserEdit, null, record.userId);

            if((this.props.roleList.roleMaint.orgRoles || 0) === 0 && (this.props.context.user.contextUserIsAdmin || false) === true){
                const { appContext } = this.props.context;
                this.props.getOrgRoles(this,null,null,record.orgIdHome);
            }
    
            if(!this.props.context.user.contextUserIsAdmin){
                this.props.getOrgUserRoles(null, null, null, record.orgIdHome, this.props.context.appContext.roleName, null);
            }
            //this.props.getOrgRoles(this,null,null,record.orgIdHome);            
        }
        else if ( (action === "activate" || action === "deactivate" || action === "delete")) {
            this.props.userStatus(this, this.onStatusSuccess, null, record.userId, action);
        }  

        closePopover();       
    }

    onSearch = (searchText: string) => {   
        let me = this;

        this.props.usersSetSearch((searchText || ""));
        setTimeout(function() {
            me.props.getUsers(me, null, null, 1, searchText, true);
        }, 100);      
    }

    onSearchChange = (e: any) => {
        let value = e.target.value;
        this.props.usersSetSearch((value || ""));
    }

    sortUsersTable = (pagination: any, filters: any, sorter: any) => {
        let sortColumn: string | undefined = undefined;
        let sortDirection: string | undefined = undefined;

        if (sorter && Object.keys(sorter).length > 0) {
            const { column, order } = sorter;
            sortDirection = order;
            if (column.title.hasOwnProperty('props')) {
                sortColumn = column.title.props['data-title'].toLowerCase().replace(/\s/g, '')
            } else {
                sortColumn = column.title.toLowerCase().replace(/\s/g, '');
            }
        }

        let sortDetails = {sortColumn: sortColumn, sortDirection: sortDirection}

        // console.log(this.props.usersList.searchText)
        // console.log(pagination)
        // console.log(filters)
        // console.log(sorter)
        // console.log(sortColumn)
        // console.log(sortDirection)
        
        this.props.getUsers(this, null, null, pagination.current, this.props.usersList.searchText, true, sortDetails);

    }

    onStatusSuccess = (record:any, userId:string) => {       
        message.success("User has been successfully updated");    
        this.props.getUsers(this, null, null, this.props.usersList.tableConfig.pagination.current, this.props.usersList.searchText, true);
    };

    onUserEdit = (record:any) => { 
        this.props.userMaintOpen(this, "Edit User :: " + record.fullName, this.drawerWidth, record,"EditUser");
    }

    confirmStatus = (record:any, appContext:any, isupdate:any, isdelete:any, action:string) => (
        <Popconfirm
            title={"Are you sure to " + (action === "activate" ? "activate" : "deactivate") + " this user?"}
            onCancel={closePopover}
            onConfirm={(e) => {this.onAction(e, record, action)}}
            okText="Yes"
            cancelText="No"
        >
            <div>
                {
                    (action === "activate" && isupdate) &&
                    <Tooltip title="Activate User"> 
                        <Button data-id="users-activate-button" type="default" icon={<UserAddOutlined />} />
                    </Tooltip>
                }
                {
                    ((action !== "activate" && appContext.roleName.toLowerCase() === "admin") 
                    || (action !== "activate" && isdelete)) 
                    &&
                    <Tooltip title="Deactivate User"> 
                        <Button data-id="users-deactivate-button" type="default" icon={<UserDeleteOutlined />} />
                    </Tooltip>
                }
            </div>
        </Popconfirm>
    );
    
    confirmDeletion = (record:any, isdelete:any) => (
        <Popconfirm
            title={"Are you absolutely sure to delete this user?"}
            onCancel={closePopover}
            onConfirm={(e) => {this.onAction(e, record, "delete")}}
            okText="Yes"
            cancelText="No"
        >
            <div>
                {
                    (isdelete) &&
                    <Tooltip title="Delete User">            
                    <Button data-id="users-delete-button" type="default" icon={<DeleteOutlined />} />
                   </Tooltip>
                }
            </div>
        </Popconfirm>
    );

    actions = (record:any, appContext:any, contextUser:any, isupdate:any, isdelete:any) => (
        <Popover
            content={
                <div className="center-div">
                    <Space size="large">
                        {
                            (isupdate) &&
                            <Tooltip title="Edit User">
                                <Button data-id="users-edit-button" type="default" icon={<EditOutlined />} onClick={(e) => {this.onAction(e, record, "edit-user")}}/>               
                            </Tooltip>
                        }
                        
                        {!record.activeInScope && record.userId !== contextUser.userId && this.confirmStatus(record, appContext, isupdate, isdelete, "activate")}
                        {record.activeInScope && record.userId !== contextUser.userId && this.confirmStatus(record, appContext, isupdate, isdelete, "deactivate")}
                        {
                            (((contextUser.contextUserIsSuperUser && (record.userId !== contextUser.userId && isdelete)))
                            || (record.userId !== contextUser.userId && isdelete)) &&
                            this.confirmDeletion(record, isdelete)
                        }
                    </Space>
                </div>
            }
            title={
                <div className="center-div">Actions</div>
            }
            trigger="click"
        >  
            <Button data-id="users-actions-button" type="default" icon={<EllipsisOutlined />} />
                       
        </Popover>
    );

    onRoleAction = (e:any, record:any, action:string) => {
        e.preventDefault();
        const { appContext } = this.props.context;
       // const { roles } = this.props.roleList.roleMaint.orgRoles.data;               
       // this.props.getOrgRoles(this,null,null,appContext.orgId);      
       // this.props.getRolePermissions(this, null, null, roles[0].roleId);
        if ( action === "add-role") {
            this.props.roleMaintOpen(this, "Roles and Permissions (Soon)", this.drawerWidth, {});
        }
        closePopover();       
    }

    render() {   

        const { user:contextUser, appContext } = this.props.context;
        const { tableConfig, tableData } = this.props.usersList;
        const result = getPermissions(this.props.permissions.permissions.users);
        const rolepermission = getPermissions(this.props.permissions.permissions.role_settings);
        if (!this.props.context.user.contextUserIsAdmin && !result[1]){
            history.push("/not-found")    
        }
       

        let iscreate = result[0];
        let isread = result[1];
        let isupdate =  result[2];
        let isdelete = result[3];
        let isrolecreate = rolepermission[0];


        
        const tableColumns = [
            {
                title: "Name",
                dataIndex: "fullName",
                sortDirections: ['descend', 'ascend', 'descend'],
                defaultSortOrder: 'ascend',
                sorter: true,
                responsive: ["md"] as Breakpoint[],
                render: (fullName: string, record: any) => (
                    <>
                        {
                            record.activeInScope.toString() !== "true" &&
                            <>
                                <UserDeleteOutlined className="inactive-user"/>
                                &nbsp;
                                <Text type="danger">{fullName}</Text>
                            </>
                        }
                        {
                            record.activeInScope.toString() === "true" &&
                            <span>{fullName}</span>
                        }
                    </>
                )
            },
            {
                title: "Email",
                dataIndex: "email",
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                render: (email: string, record: any) => (
                    <a href={"mailto:" + email}>{email}</a>
                )
            },
            {
                title: "Phone",
                dataIndex: "phoneNumber",
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                responsive: ["sm"] as Breakpoint[]
            },
            {
                title: "Home Org Name",
                dataIndex: "orgHomeName",
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                responsive: ["lg"] as Breakpoint[]
            },
            {
                title: "Role",
                dataIndex: "roleList",
                responsive: ["lg"] as Breakpoint[],
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                render: (roleList: string, record: any) => (
                    <>
                        {
                            contextUser.contextUserIsSuperUser &&
                            record.superUser &&
                            <>Super{roleList ? ", " : ""}</>
                        }
                        {roleList}
                    </>
                )
            },
            /*
                {
                    title: "Subscriptions",
                    dataIndex: "notificationCount",
                    align: "center" as "center",
                    render: (text: number, record: any) => (
                        <div className="notifications-tag">
                            {
                                text > 0 && 
                                <Tag color="geekblue">{text}</Tag>
                            }
                        </div>
                    ),
                    responsive: ["lg"] as Breakpoint[]
                },
            */
            {
                title: "Actions",
                key: "action",
                align: "center" as "center",
                render: (text: any, record: any) => (
                    <>
                   {
                        (isupdate === true || isdelete === true) &&                    
                        this.actions(record, appContext, contextUser, isupdate, isdelete)
                   } 
                   </>
                )
            }
        ];

        return (

            <div data-id="users-container" className="layout-content">
                <Row gutter={8}>
                    <Col sm={24} lg={6}>
                        <div className="content-header page-title">
                            <div style={{ display: "flex" }}>
                                <Tooltip title="Go To Previous Page"> 
                                <BackNavigation /> 
                                </Tooltip>
                                Users
                            </div>
                        </div>
                    </Col>
                    <Col sm={24} lg={18} className="content-header-controls">
                        <Space size="middle">
                            {
                                (isread) &&
                                <Search
                                    data-id="users-search-field"
                                    placeholder="Search by name, phone and email"
                                    enterButton="Search"
                                    size="middle"
                                    allowClear
                                    onSearch={this.onSearch}
                                    onChange={this.onSearchChange}
                                    className="search-field"
                                    value={this.props.usersList.searchText}
                                />
                            }
                            {
                                (iscreate) &&
                                <Tooltip title="Create New User">     
                                    <div className="add-user-button">
                                        <Button data-id="users-search-new-user-button" type="primary" icon={<PlusOutlined />} onClick={(e) => this.onAction(e, {}, "add-user")}>
                                            <span>User</span>
                                        </Button>
                                    </div>
                                </Tooltip>
                            } 
                            {/* it is moved to Roles Menu   */}
                            {/* {
                                (isrolecreate) &&
                                <Tooltip title="Roles and Permissions">     
                                    <div className="add-role-button">
                                        <Button data-id="role-search-new-user-button" type="primary" onClick={(e) => this.onRoleAction(e, {}, "add-role")}>
                                            <span>Role</span>
                                        </Button>
                                    </div>
                                </Tooltip>
                            }            */}
                        </Space>
                    </Col>
                </Row>

                <div className="mt-15">
                    {
                        isread &&
                        <Table  
                            data-id="users-data"
                            {...tableConfig}
                            rowKey={(record) => record.userId}
                            columns={tableColumns}
                            dataSource={tableData}
                            onChange={this.sortUsersTable}
                            showSorterTooltip={false}
                        />
                    }                
                           
                </div>
                
                <RoleMaint />
            </div>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        permissions: state.contextReducer.data,
        usersList: state.usersReducers.usersState.usersList,
        roleList: state.usersReducers.usersState.roleList,
        rolePermissionList: state.usersReducers.usersState.data
    };
};

export default connect(
    mapStateToProps,
    actions
)(Users);
  