import moment, { Moment } from "moment";
import { WidgetExportConfig } from ".";
import { appConfigs } from "../../../utils/configurations";
import { State, WidgetState } from "../state";
import * as Api from "../api";
import { addDateAndTime } from "../widgets/common";


function lastTimeStamp(apiResponse: any) {
  const lastDatapoint = apiResponse[apiResponse.length - 1];
  const utc = moment.utc(lastDatapoint["timestamp"]);
  return moment.utc(lastDatapoint["timestamp"]);
}

function nextDayTimeStamp(startDate: Moment) {
  let tomorrow = startDate.add(1, "days");
  return tomorrow.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
}

export function exportCsv(
  state: State,
  widgetState: WidgetState,
  cnfg: WidgetExportConfig
): Promise<any> {
  const datapointArray: any = [];
  widgetState.signals.forEach((si) => {
    const datapoint = state.template.signalCollections.filter((s) =>
      s.signals.find((sg) => sg.id === si)
    )[0];

    if (datapoint && datapoint["id"]) {
      datapointArray.push({ datapointId: datapoint.id, name: datapoint.name });
    }
  });
  //stop the repeataion of datapoints
  const uniqsignalCollectionId = datapointArray
    .filter((elem: any, pos1: any, arr: any) => {
      return arr.findIndex((t:any)=>(t.datapointId === elem.datapointId))===pos1
    })
    .filter((x: any) => !!x);

  let startDate = addDateAndTime(cnfg.startDate, cnfg.startTime);
  let endDate = addDateAndTime(cnfg.endDate, cnfg.endTime);

  const promises = uniqsignalCollectionId.map((elem: any) =>  fetchDataSlice(startDate, endDate, 3000, elem.datapointId, elem.name, state.asset.id));
  return Promise.all(promises);
}

async function fetchDataSlice(
  startDate: Moment,
  stopDate: Moment,
  limit: number,
  datapointId: string,
  datapointName: string,
  gatewayId: string
) {
  const MAX_DATAPOINT_LIMIT = appConfigs.app.assetOverview.maxdatapointlimit;
  const MAX_API_LIMIT = appConfigs.app.assetOverview.maxapilimit;
  let eventData: any = [];

  let startDate1  = startDate.clone();
  let stopDate1 = stopDate.clone();

  const formatResponse = (responseData: any) => ({
    datapointId: datapointId,
    datapointName: datapointName,
    datapointRecords: responseData,
  });

   
  while (
    stopDate1.valueOf() > startDate1.valueOf() &&
    eventData.length < MAX_DATAPOINT_LIMIT
  ) {
    try {      
      let { data } = await getDataFromApi(
        startDate1,
        stopDate1,
        limit,
        gatewayId,
        datapointId
      );
      //sort the from asc to desc
      data = data.sort((a: any, b: any) => {
        const timeStamp1 = moment(a["timestamp"]).valueOf();
        const timeStamp2 = moment(b["timestamp"]).valueOf();
        return timeStamp1 - timeStamp2
      });
      if (data.length < MAX_API_LIMIT || data.length === 0) {
        startDate1 = nextDayTimeStamp(startDate1);
      }
      if (data.length >= MAX_API_LIMIT) {
        startDate1 = lastTimeStamp(data).add(10, "milliseconds");
      }
      let remainingRecordToFetch =
        MAX_DATAPOINT_LIMIT - (eventData.length + data.length);
      if (remainingRecordToFetch < MAX_API_LIMIT) {
        limit = remainingRecordToFetch;
      }
      eventData = [...eventData, ...data];      
    } catch (error) {
      throw new Error(`Error in fetching data: ${error}`);      
    }
  }
  return formatResponse(eventData);
}

async function getDataFromApi(
  startDate: Moment,
  stopDate: Moment,
  limit: number,
  gatewayId: string,
  datapointId: string
): Promise<any> {
  let conString = `${appConfigs.server.URL}/ui/api/assets/gateway/${gatewayId}/datapoint/${datapointId}/events`;

  conString += `?limit=${limit}`;

  conString += `&startDate=${startDate.valueOf()}`;
  let currDayEndDateTime = moment(startDate);
  currDayEndDateTime.set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 999,
  });
  let currDayEndEpochTime = currDayEndDateTime.valueOf();

  if (currDayEndDateTime.valueOf() > stopDate.valueOf()) {
    conString += `&endDate=${stopDate.valueOf()}`;
  } else {
    conString += `&endDate=${currDayEndEpochTime}`;
  }
  return Api.csvExportApi(conString);
}
