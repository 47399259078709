import { appConfigs } from "../../../utils/configurations";

export const initialState = {
    orgSelector: {
        instance: 0,
        title: "Org Selector",
        width: 720,
        visible: false,
        selectonHeader: true,
        orgSelection: [],
        currentView: "list-view",
        updated: false,
        includeSuborgs: false,
        list: {
            mode: "all",
            searchText: "",
            refreshOnEntry: false,
            orgId: "",
            orgName: "",
            orgTree: [],
            tableData: [],
            tableConfig : { 
                showHeader: false,      
                pagination : {                                
                    showSizeChanger: appConfigs.tables.showSizeChanger,
                    showQuickJumper: appConfigs.tables.showQuickJumper,
                    pageSize: appConfigs.tables.pageSize,
                    defaultCurrent: appConfigs.tables.defaultCurrent,
                    current: appConfigs.tables.defaultCurrent,
                    total: appConfigs.tables.defaultTotal,
                    hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                    responsive: appConfigs.tables.responsive,
                    onChange: null,
                    showLessItems: appConfigs.tables.showLessItems,  
                    showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} org${total > 1 ? 's' : ''}`
                }
            },
        },
        tree: {
            mode: "all",
            searchText: "",
            refreshOnEntry: false,
            selectedOrgId: "",
            treeData: [],
            treeDataFlat: [],
            treeDataChecked: [],
            treeDataExpanded: [],
            restrictedOrgs: [],
            restrictedOrgsExpansion: [],
            restrictedFeatures:[],
            isExpanded: false
        }
    }
};
  