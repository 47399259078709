/* eslint-disable no-self-compare */
import React from "react";
import {Button,Col,Drawer,Form,Input,Row,Select,Space,Tabs,Upload,message,Tooltip, Switch} from "antd";
import { FormInstance } from "antd/lib/form";
import { connect } from "react-redux";
import { formInit } from "../../../utils/apiHelpers/formHelpers";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";
import { localizeUtcDate} from "../../../utils/commonHelpers";
import { appConfigs } from "../../../utils/configurations";
import * as actions from "./actions";
import { InboxOutlined,  InfoCircleOutlined } from "@ant-design/icons";
import { UploadProps } from "antd/lib/upload";
const { TabPane } = Tabs;
const { Option } = Select;
const { TextArea } = Input;
const { Dragger } = Upload;

const dragProps: UploadProps = {
  name: "file",
  multiple: false,
  beforeUpload(fileInfo: any) {
    return false;
  },
};

interface State {
  formError?: boolean;
  formErrorMessage?: string[];
  formSuccess?: boolean;
  formSuccessMessage?: string;
  formCommit?: boolean;
  mastertag?: string;
  enableSoftwarePreview: boolean;
  summary: any; 
  zipFiles: any;
  csu: any; 
  enableSubmit: boolean;
  profile: any; 
  orgs: any;
  orgId: any;
  availableToSubOrgs: boolean;
  mode: string;
  name: string,
  version: string,
  packageName: string,
  description: string,
  devicemodel:string;
  softwaretype:any;
}

interface Prop {
  context: any;
  controllersList: any;
  softwareMaintOptions: any;
  enableSoftwarePreview: boolean;
  closeDrawer: Function;
  drawerTabChange: Function;
  SoftwareMaint: Function;
  getSoftwares: Function;
  SoftwarePreview: Function; 
  softwareCsuOrgGet: Function;
  softwares: any;
  getControllers:Function;
}

class SoftwareMaint extends React.Component<Prop, State> {  
   formRef = React.createRef<FormInstance>();
   formRefNewVersion = React.createRef<FormInstance>();

  state: State = {
    name: "",
    version: "",
    packageName: "",
    enableSoftwarePreview: false, 
    enableSubmit: false,
    summary: {}, 
    zipFiles: null,
    csu: {},
    profile: {}, 
    orgs: [], 
    orgId: null,
    mode: "",
    availableToSubOrgs: false, 
    description: "",
    devicemodel:"",
    softwaretype:""
  };

  onDrawerClose = () => {
    this.props.closeDrawer();
    this.setState({enableSoftwarePreview: false,
      enableSubmit: false, 
      zipFiles: null,
      summary: {},
      csu: [],
      profile: {}, 
      orgId: null,
      availableToSubOrgs: false,
      mode: "",
      name: "",
      version: "", 
      packageName:"",
      description: ""
    });
  };

  componentDidMount() { 
    formInit(this);   
    this.softwareMaintFormRef(this.formRef);   
     if  (this.props.softwareMaintOptions?.visible ) {
      this.componentUpdate(this.props.softwareMaintOptions?.record);
    }
  }

  componentDidUpdate(prevProps: Prop, prevState: any) {
    if (this.props.softwareMaintOptions?.instance !== prevProps.softwareMaintOptions?.instance){
    this.componentUpdate(this.props.softwareMaintOptions?.record);
    }
    if (this.props.softwareMaintOptions?.systemSoftwareId  &&  !prevProps.softwareMaintOptions?.systemSoftwareId){
    this.componentUpdate(this.props.softwareMaintOptions?.record);
    }

  }

  componentUpdate(record: any) {
      formInit(this);
      if ((this.state.orgs || []).length === 0) {
        this.props.softwareCsuOrgGet(this, this.softwareCsuOrgGetSuccess, null, null/*record.orgId*/);
      }
      if (this.formRef.current != null) {
          if (record?.systemSoftwareId) {
              this.formRef.current!.resetFields();    
              this.formRef.current!.setFieldsValue({
                systemSoftwareId : record?.systemSoftwareId,
                enableSoftwarePreview: true,
                name: record?.name,
                version: record?.version,
                orgId: record?.orgId, // for update mode, this is read-only
                availableToSubOrgs: record?.availableToSubOrgs,
                description: record?.description,
                status: record.status,
                packageName: record?.packageName,
                devicemodel:record.devicemodel,
                softwaretype:record.softwaretype,
                orgs: record.orgs,
                csu: JSON.parse(record.csu),
                mode: "Update",
                isFromAncestor: record.isFromAncestor,
            });
            this.setState({
              enableSoftwarePreview: true,
              orgId: record?.orgId,
              availableToSubOrgs: record?.availableToSubOrgs,
              summary: {
                packageName: record?.packagename,
              },
              orgs: record?.orgs,
              csu: JSON.parse(record.csu),
              mode: "Update"
          });
          }

      }

  };

  softwareMaintFormRef=(form:any)=>{
    this.formRef=form;
}


  utcToLocal = ((timeUtc:string) => timeUtc === null? null: localizeUtcDate(timeUtc, {
    year: 'numeric', 
    month: '2-digit', 
    day: 'numeric', 
    hour: "2-digit", 
    minute: "2-digit", 
    second: "2-digit" , 
    hour12: true
  }));

  softwareCsuOrgGetSuccess = (
    data: any) => { 
    if ((data.length || 0) === 0) {
    } else {
      this.setState({
        orgs: data
      })
    }
  };

  onOrgIdChange = (value: string) => {
    this.props.getControllers(this, null, null, value);  
    let shallBeAvailableToSubOrgs = (value==='c6adcb40-be92-11e6-9ed6-a5bc9cb5279b' ? false: true);
    this.formRef.current!.setFieldsValue({
      orgId: value,
      availableToSubOrgs: shallBeAvailableToSubOrgs
    });
    this.setState({
      orgId: value,
      availableToSubOrgs: shallBeAvailableToSubOrgs
    })
  }
  OnDeviceModelChange = (value: string) => {
    this.formRef.current!.setFieldsValue({
      devicemodel: value,
    });
    this.setState({
      devicemodel: value,
    })

  }
  OnSoftwareTypeChange= (value: string) => {
    this.formRef.current!.setFieldsValue({
      softwaretype: value,
    });
    this.setState({
      softwaretype: value,
    })

  }

   onFinish = (values: any) => {
    this.props.SoftwareMaint(this, this.onFinishSuccess, this.onFinishFailure, values);
    this.setState({zipFiles: values, 
      enableSoftwarePreview: false,
      enableSubmit: false, 
      summary: {},
      csu: [], 
      profile: {}
    });
  };

   onFinishSuccess = (record: any) => {
    message.success("Software Added Successfully");
    this.props.getSoftwares(null, null, null, 1, "", {});
    this.props.closeDrawer();
  };

   onFinishFailure = (error: any) => {
    if (error?.response?.data?.errors && error?.response?.data?.errors.length >0) {
      message.error(error?.response?.data?.errors[0]?.message);
    } else {
      message.error("Failed to update software with a validation error");
    }
  };


   getUploadFile = (e: any) => {
    // if (Array.isArray(e)) {
    //     return e;
    // }
    if (e) {
      this.setState({zipFiles: e.fileList, 
        enableSoftwarePreview: true}); 
         this.props.SoftwarePreview(this, this.onSuccessGetSoftwarePreview, this.onFailureGetSoftwarePreviewError, e.fileList);
    } else {
      return this.state.zipFiles;
    }

    return e && e.fileList;
  };


  getSoftwarePreview= (file: any) => {  
      this.props.SoftwarePreview(this, this.onSuccessGetSoftwarePreview, this.onFailureGetSoftwarePreviewError, file);
  }

  onSuccessGetSoftwarePreview = (profile: any, zipFiles: any) => {   
    let summary = {
      packageName: profile.packageName,
      version: profile.version,
      formatRevision: profile.formatRevision,
      createdOn: this.utcToLocal(profile.createdOn),
      numDevice: profile.numDevice,
      numCsu: profile.numCsu
    };
    let sortedCsuList = JSON.parse(profile.csu); 
    this.setState({            
        enableSoftwarePreview: true,
        enableSubmit: true,
        summary: summary,
        csu: sortedCsuList,
        zipFiles: this.state.zipFiles,
        mode: "Add"
    }) 

    this.formRef.current!.setFieldsValue({
      enableSoftwarePreview: true,
      enableSubmit: true, 
      packageName: profile.packageName,
      formatReview: profile.formatRevision,
      numDevice: profile.numDevice,
      numCsu: profile.numCsu, 
      createdOn: this.utcToLocal(profile.createdOn),
      csu: sortedCsuList,
      zipFiles: zipFiles, 
      mode: "Add"
    });

  }


  onFailureGetSoftwarePreviewError = (error: any) => { 
    errorResponse(this, error);
  }


  render() {  

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let orgsLen = (this.props.softwareMaintOptions?.orgs || []).length;
    return (
      <Drawer
        title={this.props.softwareMaintOptions.title}
        width={this.props.softwareMaintOptions.width}
        onClose={() => {this.props.closeDrawer()}}
        visible={this.props.softwareMaintOptions.visible}
        destroyOnClose={true}

        footer={
          <Space size="large" style={{ float: "right" }}>
            {(this.state.enableSoftwarePreview || 
              (this.props.softwareMaintOptions?.record?.systemSoftwareId || "") !== "" )  &&
            <Tooltip title="Enabled after previewing the uploaded file and preview">
              <Button
                data-id="softwares-maint-submit-button"
                type="primary"
                form="softwareMaintOptionsInfoForm"
                key="submit"
                htmlType="submit"
              >
                {appConfigs.settings.form.submitButtonText}
              </Button>
            </Tooltip>
          }
          </Space>
        } 
        >
          <Tabs
            activeKey={this.props.softwareMaintOptions.activeTab}
            size="large"
            type="card"
            onChange={(key: string)=>this.props.drawerTabChange(key)}
          >
            <TabPane
              tab={this.props.softwareMaintOptions.tabs[0].title}
              key="1"
              disabled={this.props.softwareMaintOptions.tabs[0].disabled}
            >
              <Form
                id="softwareMaintOptionsInfoForm"
                ref={this.formRef}
                layout="vertical"
                onFinish={this.onFinish}
              >      
                <Form.Item 
                  name="systemSoftwareId"
                  style={{ display: 'none' }}
              >
                  <Input type="hidden" />
              </Form.Item>
              <Form.Item 
                  name="mode"
                  style={{ display: 'none' }}
              >
                  <Input type="hidden" />
              </Form.Item>
              <Form.Item 
                  name="csu"
                  style={{ display: 'none' }}
              >
                  <Input type="hidden" />
              </Form.Item>
              <Form.Item 
                  name="isFromAncestor"
                  style={{ display: 'none' }}
              >
                  <Input type="hidden" />
              </Form.Item>
              <Row gutter={8}>
                <Col span={12}>
                    <Form.Item
                        label="Software Name"
                        name="name"    
                        shouldUpdate={true}                                 
                        rules={[
                            { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                        ]}
                    >
                        <Input maxLength={255} disabled={this.props.softwareMaintOptions?.record?.status === 'inactive' || 
                           this.props.softwareMaintOptions?.record?.isFromAncestor} />
                    </Form.Item>
                </Col>   
                <Col span={12}>
                    <Form.Item
                        label="Version"
                        name="version"    
                        shouldUpdate={true}                                 
                        rules={[
                            { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                        ]}
                    >
                        <Input maxLength={255} disabled={this.props.softwareMaintOptions?.record?.status === 'inactive' || 
                           this.props.softwareMaintOptions?.record?.isFromAncestor} />
                    </Form.Item>
                </Col>                        
            </Row>                          
              <Row gutter={8}>
                  <Col span={12}>                                
                  <Form.Item 
                      label="Org Name"
                      name="orgId"                                                
                  >
                        <Select 
                          data-id="software-maint-org-id"
                          showSearch
                          showArrow={true}
                          optionFilterProp="children"
                          className="select-before" 
                          placeholder="Select..."
                          allowClear
                          onChange={this.onOrgIdChange}
                          disabled={this.props.softwareMaintOptions?.record?.systemSoftwareId || "" !== "" || 
                             this.props.softwareMaintOptions?.record?.isFromAncestor}       
                      >
                          {(this.props.softwareMaintOptions?.orgs || []).length !== 0 && 
                            this.props.softwareMaintOptions?.orgs?.map((record:any, index:number) => (
                              <Option key={index} value={record.orgId}>                                                    
                                  {record.name}
                              </Option>
                          )) }
                      </Select>                                          
                  </Form.Item>  
              </Col> 

              <Col span={12}>
                <Form.Item
                  label="Allow sub-orgs to use"                        
                  labelCol={{ span: 10 }}
                  >  
                  <Row>
                      <Col span={3}>
                          <Form.Item noStyle name="availableToSubOrgs" valuePropName="checked">
                              <Switch disabled={  this.props.softwareMaintOptions?.record?.isFromAncestor}/>
                          </Form.Item>                                
                      </Col>
                      <Col span={1}>
                          <Tooltip title="This software package will be available to sub-orgs">
                              <InfoCircleOutlined style={{ fontSize: '120%', color: '#1890ff'}} />
                          </Tooltip>
                      </Col>
                  </Row>                        
                </Form.Item> 
              </Col>
            </Row>      
            <Row gutter={8}>
                  <Col span={12}>                                
                  <Form.Item 
                      label="Device Model"
                      name="devicemodel"                                                
                  >
                      <Select 
                          data-id="software-maint-device model"
                          showSearch
                          showArrow={true}
                          optionFilterProp="children"
                          className="select-before" 
                          placeholder="Select..."
                          allowClear
                          onChange={this.OnDeviceModelChange}
                          disabled={this.props.softwareMaintOptions?.record?.systemSoftwareId || "" !== "" || 
                             this.props.softwareMaintOptions?.devicemodel?.isFromAncestor}       
                      >
                          {(this.props.softwareMaintOptions?.devicemodel || []).length !== 0 && 
                            this.props.softwareMaintOptions?.devicemodel?.map((record:any, index:number) => (
                              <Option key={index} value={record.model}>                                                    
                                  {record.model}
                              </Option>
                          )) }
                      </Select>  
                  </Form.Item>  
              </Col>     
              <Col span={12}>
                  <Form.Item
                      label="Software Type"
                      name="softwaretype"
                  >
                      <Select
                      data-id="software-maint-software-type"
                      showSearch
                      showArrow={true}
                      optionFilterProp="children"
                      className="select-before" 
                      placeholder="Select..."
                      allowClear
                      disabled={this.props.softwareMaintOptions?.record?.systemSoftwareId || "" !== "" || 
                             this.props.softwareMaintOptions?.softwaretype?.isFromAncestor}       
                      onChange={this.OnSoftwareTypeChange}>
                          <Option value="System">System</Option>
                          <Option value="Application">Application</Option>
                          <Option value="Runtime">Runtime</Option>
                          <Option value="Platform">Platform</Option>
                          <Option value="Others">Others</Option>
                      </Select>
                  </Form.Item>

              </Col>
            </Row>    
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item
                        label="Description"
                        name="description"                                 
                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                    >
                        <TextArea rows={2} placeholder="A brief desccription the purpose of this package"
                          disabled={this.props.softwareMaintOptions?.record?.isFromAncestor}/>
                    </Form.Item>
                  </Col> 
                  </Row>
                  {!this.props?.softwareMaintOptions?.record?.systemSoftwareId &&
                  <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item
                      label="Upload File"
                      name="fileUpload"
                      getValueFromEvent={this.getUploadFile}
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: appConfigs.errors.fieldErrors.valueRequired,
                      //   },
                      // ]}
                    >
                      <Dragger {...dragProps} 
                        disabled={this.props.softwareMaintOptions?.record?.systemSoftwareId || "" !== ""}>

                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag a file to this area to upload
                        </p>
                      </Dragger>
                    </Form.Item>
                  </Col>
                </Row>}
              </Form>
            </TabPane>
          </Tabs>   
      </Drawer>
    );
  };
}

const mapStateToProps = (state: any) => {
  return {
    context: state.contextReducer.context,
    softwareMaintOptions:
      state.softwaresReducers.softwaresState.softwares.softwareMaintOptions,
    controllersList:
      state.softwaresReducers.softwaresState.softwares.controllersList,
    enableSoftwarePreview: state.enableSoftwarePreview,
    softwares: state.softwaresReducers.softwaresState.softwares
  };
};

export default connect(mapStateToProps, actions)(SoftwareMaint);