import Axios from "axios";
import { appConfigs } from "../../utils/configurations";

export function addDashboard(
    templateId: string,
    title: string,
    orgId: string    
  ): Promise<any> {
    return Axios.post(
      `${appConfigs.server.URL}/ui/api/assets/templates/${templateId}/dashboards`, {'title': title},
      {
        params: {
          orgId
        },
        responseType: "json",
      }
    );    
  }
  
  export function deleteDashboard(
    templateId: string,    
    dashboardId: number
  ): Promise<any> {
    return Axios.delete(
      `${appConfigs.server.URL}/ui/api/assets/templates/${templateId}/dashboards/${dashboardId}`
    );    
  }


  export function updateDashboard(
    templateId: string,    
    dashboardId: number,
    title: string,
  ): Promise<any> {
    return Axios.post(
      `${appConfigs.server.URL}/ui/api/assets/templates/${templateId}/dashboards/${dashboardId}`,
      {'title': title},
      {
        responseType: "json",
      }
    );
  }