import { DataStore, DbLayoutConfig } from "./state";
import { AxisRange } from "./widgets/models";
import { LineOptions } from "./widgets/LineChart";
import { TrackerOptions } from "./widgets/TrackerWidget";
import { GaugeOptions } from "./widgets/GaugeWidget";
import { BarOptions } from "./widgets/BarChart";
import { ClusterBarOptions } from "./widgets/ClusterBar";

export type SignalId = string;
type widgetId = string;
export type SignalCollectionId = string;
type SignalValue = number;

export interface Rule {
  signalId: SignalId;
  criticalHigh?: number;
  warningHigh?: number;
  infoHigh?: number;
  infoLow?: number;
  warningLow?: number;
  criticalLow?: number;
}

export interface SignalCollection {
  id: SignalCollectionId;
  name: string;
  type: string | null;
  signals: Signal[];
}

export interface Signal {
  id: SignalId;
  name: string;
  units: string;
}

export interface CommonWidgetConfig extends LineWidgetConfig{
  title: string;
  signals: SignalId[];
}

export interface LineWidgetConfig {
  rightYAxisSignals?:SignalId[];
}

export interface CommonWidgetProps {
  signals: SignalId[];
  data: DataStore;
  template: Template; 
}

export interface LineWidgetProps{
  rightYAxisSignals?:SignalId[];
  rightYAxisData:DataStore;
}

export interface SignalDatapoint {
  timestamp: Date;
  value: SignalValue;
}

export interface Asset {
  id: string;
  name: string;
  mastertag: string;
  orgId: string;
  template: {
    signalCollections: SignalCollection[];
    rules: Rule[];
  };
  templateId: string;
  templateName: string;
  orgName: string;
  widgets: DBWidgetConfig[];
  alertDm1Count: number;
  alertInfoCount: number;
  alertWarningCount: number;
  alertCriticalCount: number;
  mac: string;
  userRole: string; 
  isRead: boolean;
  isUpdate: boolean; 
  operatorId: string;
  operatorIdValue: string;
  accessCardValue: string;
  operatorsHistoryId: string;
  isassetUpdate: boolean;
  istemplatesRead: boolean;
}

export interface EventSignals {
  _id: string;
  name: string;
  value: number;
  uom: string;
}

export interface EventData {
  signalCollectionID: SignalCollectionId;
  orgID: string;
  createdAt: string;
  timestamp: string;
  signals: EventSignals[];
}

export interface WidGetSignalConfig {
  name: string;
  datapointId: SignalCollectionId;
  signalId: SignalId;
}

export interface DBWidgetConfig {
  _id: widgetId;
  type: string;
  signals: WidGetSignalConfig[];
  sizeX?: number;
  sizeY?: number;
  row?: number;
  col?: number;
  title: string;
  settings?: LineOptions | BarOptions | GaugeOptions | TrackerOptions | ClusterBarOptions;
  rightYAxisSignals?: any[];
}

export class Template {
  constructor(
    public readonly signalCollections: SignalCollection[],
    public readonly rules: Rule[]
  ) {}

  get signals(): Signal[] {
    const signals = this.signalCollections.map((sc) => sc.signals);
    return signals.reduce((result, signals) => result.concat(signals), []);
  }
}
