import Axios from "axios";
import { PermissionApiReq } from ".";
import { appConfigs } from "../../../utils/configurations";

export function getPermissions(
  templateId: string,
  roleId: number,
  orgId: string
): Promise<any> {
  return Axios.get(
    `${appConfigs.server.URL}/ui/api/templates/${templateId}/roles/${roleId}`,
    {
      params: {
        orgId
      },
      responseType: "json"
    }    
  );  
}

export function getOrgRoles(orgId: string): Promise<any> {
  return Axios.get(appConfigs.server.URL + "/ui/api/org-roles/" + orgId, {
    responseType: "json",
    headers: {},
    params: {},
  });
}

export function savePermissions(
  templateId: string,
  roleId: number,
  permissions: PermissionApiReq[],
  orgId: string
): Promise<any> {
  return Axios.put(
    `${appConfigs.server.URL}/ui/api/templates/${templateId}/roles/${roleId}`,
    permissions,
    {
      params: {
        orgId
      },
      responseType: "json"
    }
  );
}
