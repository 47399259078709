import React, { useEffect, useState } from "react";
export const useExport = (csvLink: any) => {
	const [dataForDownload, setDataForDownload] = useState<any>([]);
	const [bDownloadReady, setDownloadReady] = useState(false);
	const [exportHeader, setexportHeader] = useState<any>([]);
	const [exportFileName, setExportFileName] = useState<string>();

	useEffect(() => {
		if (csvLink && csvLink.current && bDownloadReady) {
			setTimeout(() => {
				csvLink.current.link.click();
			}, 1);
			setDownloadReady(false);
		}
	}, [bDownloadReady]);

	return [
		dataForDownload,
		setDataForDownload,
		bDownloadReady,
		setDownloadReady,
		exportHeader,
		setexportHeader,
		exportFileName,
		setExportFileName,
	];
};
