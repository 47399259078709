import React, { useMemo, useState } from "react";

import { Form, Button, Select, Input } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { SketchPicker } from "react-color";
import { appConfigs } from "../../../utils/configurations";

type Value = string;
type ValueArray = Array<any | undefined>;

interface SignalValue {
  id: Value;
  label: string;
  color: string;
  value: string;
}

interface Selectable {
  value: SignalValue;
  label: string;
}
interface Props {
  options: SignalValue[];
  maxSelected?: number;
  value?: ValueArray;
  onChange?: (v: ValueArray) => void;
  valueLabel?: string;
}

const ColorSelectorList: React.FC<Props> = (props) => {
  // Must be optional to play nice with Ant Design's forms but won't
  // actually work without them
  const assertDefined = (propName: keyof Props) => {
    if (props[propName] === undefined) {
      console.error(`Property '${propName} must be defined for ListSelector`);
    }
  };
  assertDefined("onChange");

  const { value = [], onChange } = props;

  let temp = [];
  let tempColor = [];
  for (let index = 0; index < value.length; index++) {
    temp.push(false);
    let c = ((value[index].color === "#000000" || value[index].color  == null) ? appConfigs.colorCodes.colorCodesLineChart[index] : value[index].color);
    tempColor.push(c);
  }
  const [isOpen, setIsOpen] = useState(temp);
  const [dcolor, setDcolor] = useState(tempColor);

  const { add, remove, changeValue } = useMemo(() => {
    const triggerChange = (newValues: ValueArray) => {
      if (onChange) {
        onChange(newValues);
      }
    };

    const add = () => {
      triggerChange(value.concat({ id: '', color: "#000000" }));
    };

    const remove = (i: number) => {
      triggerChange([...value.slice(0, i), ...value.slice(i + 1)]);
    };

    const changeValue = (index: number, updatedValue: Value) => {
      const newVals = [...value];
      if (newVals.length > 0) {
        let obj = { id: updatedValue, color: appConfigs.colorCodes.colorCodesLineChart[index] };
        newVals[index] = obj;
        triggerChange(newVals);
      }
    };

    return { add, remove, changeValue };
  }, [onChange, value]);

  const filteredOptions = (v?: SignalValue) => {
    return props.options.filter(
      (opt) => opt.id === v?.id || !value.some(e=>e.id === opt.id) //!value.includes({ id: opt.id, color: opt.color })
    );
  };

  const onSearch = (val: string) => {
    return props.options.filter(
      (opt) => opt.label === val || !value.includes(opt.label)
    );
  }

  const maxReached = value.length >= (props.maxSelected || Infinity);
  const valueLabel = props.valueLabel ? `${props.valueLabel} ` : "";

  const colorPickerCover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  } as React.CSSProperties;

  return (
    <div>
      {value.map((v, i) => (
        <Form.Item key={i}>
          <Select
            showSearch
            style={{ width: "70%" }}
            value={v?.id}
            onChange={(v) => changeValue(i, v)}
            options={filteredOptions(v)}
            optionFilterProp={'label'}
            onSearch={onSearch}
          />
          <Input
            value={v.color || dcolor[i] }
            style={{ marginLeft: "8px", width: "15%" }}
          />
          <Button
            style={{
              marginLeft: "8px",
              width: "5%",
              background: v.color || dcolor[i],
            }}
            shape= "circle"
            onClick={() => {
              isOpen[i] = true;
              setIsOpen([...isOpen]);
            }}
          >
            .
          </Button>
          <MinusCircleOutlined
            style={{ marginLeft: "8px" }}
            onClick={() => remove(i)}
          />
          <div>
            {isOpen[i] ? (
              v ? (
                <>
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 5,
                      right: 0,
                    }}
                  >
                    <div
                      style={colorPickerCover}
                      onClick={() => {
                        isOpen[i] = false;
                        setIsOpen([...isOpen]);
                      }}
                    />
                    <SketchPicker
                      color={v.color || dcolor[i]}
                      onChange={(c) => {
                        v.color = c.hex;
                        dcolor[i] = c.hex;
                        setDcolor([...dcolor]);
                      }}
                    />
                  </div>
                </>
              ) : null
            ) : null}
          </div>
        </Form.Item>
      ))}
      <Button disabled={maxReached} onClick={add} type="dashed">
        {maxReached ? `Max ${valueLabel}Added` : `Add ${valueLabel}`}
      </Button>
    </div>
  );
};

export default ColorSelectorList;
