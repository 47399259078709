import React from "react";
import { SketchPicker } from 'react-color';
import { Typography, Form, Input, Button, Select, Row, Col, Tooltip, Card } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';

//OPS 1149 import OrgPhone from "./OrgPhone";
import OrgLogo from "./OrgLogo";
import OrgLoginLogo from "./OrgLoginLogo";
import OrgLoginBackground from "./OrgLoginBackground";

import { appConfigs } from "../../../utils/configurations";
import { successResponse, errorResponse, resetFormErrors } from "../../../utils/apiHelpers/apiHelpers";
import { formInit } from "../../../utils/apiHelpers/formHelpers";
import { SuccessAlert, ErrorAlert } from "../../../utils/Components/formAlerts";
import { setHeaderColor, setLogo, scrollToElement, getPermissions } from "../../../utils/commonHelpers";

import { connect } from "react-redux";
import * as rootActions from "../actions";
import * as contextActions from "../../../actions/context";
import * as orgActions from "../../OrgSelector/actions/";
import "./orgMaintInfo.css";

const { Option } = Select;
const { Paragraph } = Typography;
const { TextArea } = Input;

interface Prop {
    orgMaint: Function,
    orgsListRefreshOnUpdate: Function,
    orgMaintTabsDisabled: Function,
    countriesGet: Function,
    countryStatesGet: Function,
    languagesGet: Function,
    orgMaintClose: Function,
    orgsGet: Function,
    contextUpdateMapDefaultView: Function,
    context: any,
    orgsList: any,
    permissions:any,
    orgSelectorTreeOrgsGet: Function,
    orgLevelTags:Function
}

interface State {
    enableMtag: boolean;
    states: any
    orgIdParentName?: string;
    loginUrlDisplay?: string;
    displayColorPicker: boolean;
    color?: any;
    opa?: any;

    formError?: boolean;
    formErrorMessage?: string[];
    formSuccess?: boolean;
    formSuccessMessage?: string;
    formCommit?: boolean;
}

class OrgMaintInfo extends React.Component<Prop, State> {  
    
    state: State = {
        states: [],
        displayColorPicker: false,
        color: "",
        enableMtag: false
    };

    formRef = React.createRef<FormInstance>();
    
    componentDidMount() {
        this.props.countriesGet(this); 
        this.props.countryStatesGet(this, this.onCountryStatesGetSuccess);
        this.props.languagesGet(this, this.onLanguageGetSuccess, null);
        this.componentUpdate(this.props.orgsList.orgMaint.record);
        this.props.orgSelectorTreeOrgsGet(this, null, null, "list"); 
        this.props.orgLevelTags(this, null, null, "all", this.props.orgsList.orgMaint.record.orgId);
    }
    componentDidUpdate(prevProps: Prop, prevState: State) {
        if (this.props.orgsList.orgMaint.instance !== prevProps.orgsList.orgMaint.instance) {
            this.componentUpdate(this.props.orgsList.orgMaint.record);
            this.setDefaultLanguage(this.props.orgsList.orgMaint.tabs[0].languages);
            this.countryChange(this.props.orgsList.orgMaint.countryId);            
        }
    }

    componentUpdate(record:any) {
        formInit(this);     
        if (this.formRef.current != null) {
            this.formRef.current!.resetFields();    
            this.formRef.current!.setFieldsValue({
                orgId: record.orgId,
                orgIdParent: record.orgIdParent,
                orgIdParentName: record.orgIdParentName,
                name: record.name,
                orgTags: record.orgTags?.map((tag: any) => { return { value: tag.tagName.toUpperCase(), label: tag.tagName.toUpperCase(), key: tag.tagName.toUpperCase() } }),
                street1: record.street1,
                street2: record.street2,
                street3: record.street3,                
                city: record.city,              
                zipCode: record.zipCode,        
                stateList: record.state,    
                state: record.state,      
                countryId: record.countryId,
                phoneNumber: record.phoneNumber,
                phoneNumberIsoCode2: record.phoneNumberIsoCode2 || "",
                logoUploadId: record.logoUploadId || "",
                logoUploadSrc: record.logoUploadSrc || "",
                loginBackgroundUploadId: record.loginBackgroundUploadId || "",
                loginBackgroundUploadSrc: record.loginBackgroundUploadSrc || "",
                loginLogoUploadId: record.logoUploadId || "",
                loginLogoUploadSrc: record.logoUploadSrc || "",
                loginUrl: record.loginUrl || "",
                loginUrlDisplay: record.loginUrlDisplay || "",
                headerColor: record.headerColor,
                fleetMapDefaultView: record.fleetMapDefaultView || "satellite",
                termsAndConditions: record.termsAndConditions,
                languageId: record.languageId
            });
        
            this.setState({ 
                orgIdParentName: record.orgIdParentName, 
                color: record.headerColor || "", 
                loginUrlDisplay: record.orgId ? (window.location.origin + (record.loginUrlDisplay ? record.loginUrlDisplay : "/login")).replace(/\/\/+/g, '/') : "" 
            });
        }
    };

    onFinish = (values: any) => {        
        let existingTags = this.props.orgsList.orgMaint.record.orgTags?.map((tag: any) => { return { value: tag.tagName.toUpperCase(), label: tag.tagName.toUpperCase(), key: tag.tagName.toUpperCase() } });
        const removedTags = existingTags?.filter((oldTag:any) =>
            !values.orgTags.some((tag:any) => tag.value === oldTag.value && tag.key === oldTag.key));
        const newTags = values.orgTags?.filter((oldTag:any) =>
        !existingTags?.some((tag:any) => tag.value === oldTag.value && tag.key === oldTag.key));
        resetFormErrors(this, this.formRef.current);
        values.newTags = newTags;
        values.removedTags =  removedTags;
        this.props.orgMaint(this, this.onFinishSuccess, this.onFinishFailure, values);
    };

    onFinishSuccess = (record: any) => { 
        this.props.orgsListRefreshOnUpdate(this);
        
        if (record != null && Object.keys(record.orgId).length !== 0) {
            this.componentUpdate(record);
            this.props.orgMaintTabsDisabled(this, (record.orgId || "") === "");
        }
        if (record.orgId === this.props.context.appContext.orgId) {
            setHeaderColor(record.headerColor);
            setLogo(appConfigs.server.URL + record.logoUploadSrc);
        }
        if (record.fleetMapDefaultView !== this.props.context.orgSettings.fleetMapDefaultView) {
            this.props.contextUpdateMapDefaultView(record.fleetMapDefaultView);
        }

        scrollToElement("[data-id=orgs-maint-form-messages]");
        successResponse(this, "Organization has been successfully processed");   

        const me = this;
        const orgsList = this.props.orgsList;
        this.props.orgsGet(this, null, null, orgsList.mode, orgsList.scope, orgsList.tableConfig.pagination.current, orgsList.searchText, orgsList.orgId);  
        setTimeout(function () {
            me.props.orgMaintClose(me);
        }, 750);     
    }

    onFinishFailure = (error: any) => { 
        scrollToElement("[data-id=orgs-maint-form-messages]");
        errorResponse(this, error);
    }

    onCountryStatesGetSuccess = () => { 
        this.countryChange(this.formRef.current!.getFieldValue("countryId"));
    }

    onLanguageGetSuccess = (data:any) => { 
        this.setDefaultLanguage(data);
    }

    setDefaultLanguage = (data:any) => { 
        if ((this.props.orgsList.orgMaint.record.languageId || "") === "" ) {
            let me = this;
            data.forEach((record: any) => {
                if (record.defaultLanguage) {
                    me.formRef.current!.setFieldsValue({"languageId": record.languageId });
                }
            });       
        }
    }
    
    countryChange = (value: string) => { 
        let me = this;

        setTimeout(function() {
            let states = me.props.orgsList.orgMaint.tabs[0].states.filter((state:any) => {
                    return state.countryId === value;
                });

            me.setState({ states: states });
            if (states.length > 0) {
                me.formRef.current!.setFieldsValue({"state": "" });
            }
            else {
                me.formRef.current!.setFieldsValue({"stateList": "" });
            }
        }, 150);        
    }

    colorPickerOpen = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };
    
    colorPickerClose = () => {
        this.setState({ displayColorPicker: false });
    };

    colorPickerChange = (color:any) => {
        let me = this;

        setTimeout(function(){
            me.setState({ color: color.hex });
            me.formRef.current!.setFieldsValue({"headerColor": color.hex });
        }, 150);        
    }

    render() {
        
        const { orgMaint } = this.props.orgsList;        
        const { user:contextUser } = this.props.context;
        const orgsettings = getPermissions(this.props.permissions.permissions.org_settings);
        let iscreate = orgsettings[0];
        let isupdate = orgsettings[2];

        const tagPermissions = getPermissions(this.props.permissions.permissions.asset_tagging);
        let canTagCreate = tagPermissions[0];
        let canTagRead = tagPermissions[1];
        let canTagDelete = tagPermissions[3];

        const checkTagsValidations = async (rule: any, options: any) => {
            let newValues:any = [];
            let existingValues = [];
            newValues = options?.map((o:any) => o.value);
            existingValues = orgMaint.record.orgTags?.map((o: any) => o.tagName);
            if (!canTagDelete && existingValues !== undefined) {
                if (!existingValues.every((tag: any) => newValues.includes(tag))) {
                    return Promise.reject(`you do not have enough permission privilege to delete tags`);
                }
            }
            if (options?.length) {
                if (canTagCreate) {
                    if (options?.length > appConfigs.app.orgTags.maxOrgTags) {
                        options.pop();
                        return Promise.reject(`Maximum of ${appConfigs.app.orgTags.maxOrgTags} tags allowed`);
                    }
                    if (options[options.length - 1]?.value.length > appConfigs.app.orgTags.maxAllowCharactersPerTags) {
                        options.pop();
                        return Promise.reject(`Maximum of ${appConfigs.app.orgTags.maxAllowCharactersPerTags} characters allowed per tags`);
                    }
                } else {
                    //if we are deleting don't remove additional tag
                    if (existingValues === undefined) {
                        existingValues = []
                    }
                    if (options?.length > orgMaint.record.orgTags?.length || existingValues?.every((tag: any) => !newValues.includes(tag))) {
                        options.pop();
                        return Promise.reject(`you do not have permission to add tags`);
                    }
                }
            }
            return Promise.resolve();
        };
        const colorPickerCover = {
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px"
        } as React.CSSProperties;

        const mainLayout = {
            labelCol: { span: 7 },
            wrapperCol: { span: 17 }
        };

        const logoLayout = {
            labelCol: { span: 7 },
            wrapperCol: { span: 17 }
        };

        const loginLogoLayout = {
            labelCol: { span: 7 },
            wrapperCol: { span: 17 }
        };

        const settingsLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 }
        };
        
        const settingsLayoutShort = {
            labelCol: { span: 4 },
            wrapperCol: { span: 11 }
        };

        const headerColorLabel = (
            <>
                <span>Color</span>
                <Tooltip placement="topLeft" title={appConfigs.messages.headerColorTooltip}>
                    <QuestionCircleOutlined />
                </Tooltip>
            </>
        );

        const logoLabel = (
            <>
                <span>Logo</span>
                <Tooltip placement="topLeft" title={appConfigs.messages.companyLogoTooltip}>
                    <QuestionCircleOutlined />
                </Tooltip>
            </>
        );

        const loginLogoLabel = (
            <>
                <span>Login<br />Logo</span>
                <Tooltip placement="topLeft" title={appConfigs.messages.companyLoginLogoTooltip}>
                    <QuestionCircleOutlined />
                </Tooltip>
            </>
        );

        const loginUrlLabel = (
            <>
                <span>URL</span>
                <Tooltip placement="topLeft" title={appConfigs.messages.companyLoginUrlTooltip}>
                    <QuestionCircleOutlined />
                </Tooltip>
            </>
        );

        const loginBackgroundLabel = (
            <>
                <span>Splash</span>
                <Tooltip placement="topLeft" title={appConfigs.messages.companyLoginBackgroundTooltip}>
                    <QuestionCircleOutlined />
                </Tooltip>
            </>
        );

        return (            

            <div data-id="orgs-maint-info">
                <div data-id="orgs-maint-form-messages">
                    {this.state.formSuccess && !this.state.formError && <SuccessAlert data-id="orgs-maint-info-success-message" message={this.state.formSuccessMessage}/>}
                    {this.state.formError && !this.state.formSuccess && <ErrorAlert data-id="orgs-maint-info-error-message" message={this.state.formErrorMessage}/>}
                </div>

                <Form
                    {...mainLayout}
                    id="orgMaintInfoForm"
                    ref={this.formRef}
                    onFinish={this.onFinish}
                >                                   
                    <Row gutter={16}>
                        <Col xs={24} md={15}>
                            <Form.Item 
                                name="orgIdParent"
                                style={{ display: 'none' }}
                            >
                                <Input type="hidden" />
                            </Form.Item>

                            <Form.Item 
                                name="orgId"
                                style={{ display: 'none' }}
                            >
                                <Input type="hidden" />
                            </Form.Item>
                            
                            <Form.Item 
                                name="logoUploadId"
                                style={{ display: 'none' }}
                            >
                                <Input type="hidden" />
                            </Form.Item>
                            
                            <Form.Item 
                                name="loginLogoUploadId"
                                style={{ display: 'none' }}
                            >
                                <Input type="hidden" />
                            </Form.Item>

                            <Form.Item 
                                name="loginBackgroundUploadId"
                                style={{ display: 'none' }}
                            >
                                <Input type="hidden" />
                            </Form.Item>

                            <Form.Item 
                                name="orgIdParent"
                                label="Parent Org"
                            >
                                <strong>{this.state.orgIdParentName}</strong>
                            </Form.Item>

                            <Form.Item
                                label="Name"
                                name="name"                                 
                                rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                            >
                                <Input maxLength={255}/>
                            </Form.Item>

                            {

// (canTagRead) && 
<Row gutter={8}>
    <Col span={24}>
        <Form.Item
            label="Tags"
            name="orgTags"
            initialValue={orgMaint.record.orgTags?.map((tag: any) => { return { value: tag.tagName.toUpperCase(), label: tag.tagName.toUpperCase(), key: tag.tagName.toUpperCase() } })} 
            rules={[{ validator: checkTagsValidations }]}
        >            
        <Select 

                mode="tags"
                style={{ width: '100%', textTransform: "uppercase" }}
                placeholder="Select Tags"
                disabled={ (!canTagCreate && !canTagDelete) || orgMaint.record.status == 'inactive' ||this.state.enableMtag
                }
                labelInValue
        >
            {orgMaint.selectedTag?.map((tagName:any, index:number) => (
                <Option key={index} value={tagName.toUpperCase()}>
                    {tagName.toUpperCase()}
                </Option>
            ))}
        </Select>  
        </Form.Item>   
    </Col>
    <Col span={12} >
    
   
</Col> 
</Row>
}

                            {/* OPS 1149 */}                                                            
                            <Form.Item
                                label="Phone"
                                name="phoneNumber"
                            >
                                <Input maxLength={32}/>
                            </Form.Item>

                            {/*
                                OPS 1149
                                <OrgPhone />
                            */}             
                            
                            <Form.Item
                                label="Street"
                                name="street1"                           
                            >
                                <Input maxLength={255}/>
                            </Form.Item>

                            <Form.Item
                                label=" "
                                name="street2"   
                                colon={false}                              
                            >
                                <Input maxLength={255}/>
                            </Form.Item>

                            <Form.Item
                                label="City"
                                name="city"                                 
                            >
                                <Input maxLength={255}/>
                            </Form.Item>

                            <Form.Item
                                label="Zip Code"
                                name="zipCode"                                 
                            >
                                <Input maxLength={255}/>
                            </Form.Item>

                            <Form.Item
                                label="Country"
                                name="countryId"  
                            >
                                <Select 
                                    data-id="orgs-maint-info-country-id"
                                    showSearch
                                    showArrow={true}
                                    optionFilterProp="children"
                                    className="select-before" 
                                    placeholder="Select..."
                                    allowClear
                                    onChange={this.countryChange}
                                >
                                    {orgMaint.tabs[0].countries.map((record:any, index:number) => (
                                        <Option key={record.countryId} value={record.countryId}>
                                            {record.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            {
                                this.state.states.length !== 0 &&
                                <Form.Item
                                    label="State"
                                    name="stateList"  
                                >
                                    <Select 
                                        data-id="orgs-maint-info-state-list"
                                        showSearch
                                        showArrow={true}
                                        optionFilterProp="children"
                                        className="select-before" 
                                        placeholder="Select..."
                                        allowClear
                                    >
                                        {this.state.states.map((record:any, index:number) => (
                                            <Option key={record.isoCode2} value={record.isoCode2}>
                                                {record.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            }
                            {
                                this.state.states.length === 0 &&
                                <Form.Item
                                    label="State"
                                    name="state" 
                                >
                                    <Input maxLength={255}/>
                                </Form.Item>
                            }
                        </Col>
                        <Col xs={24} md={9}>

                            <Form.Item
                                {...logoLayout}
                                label={logoLabel}
                                colon={false}
                            >
                                <OrgLogo formRef={this.formRef.current}/>       
                            </Form.Item>

                            <Form.Item
                                {...loginLogoLayout}
                                label={loginLogoLabel}
                                colon={false}
                            >
                                <OrgLoginLogo formRef={this.formRef.current}/>       
                            </Form.Item>

                        </Col>
                    </Row> 

                    <Card size="small" title="Settings">
                        {/*
                            <Form.Item
                                {...settingsLayoutShort}
                                label="Language"
                                name="languageId" 
                            >
                                <Select 
                                    data-id="orgs-maint-info-language-id"
                                    showSearch
                                    showArrow={true}
                                    optionFilterProp="children"
                                    className="select-before" 
                                    placeholder="Select..."
                                    allowClear
                                >
                                    {orgMaint.tabs[0].languages.map((record:any, index:number) => (
                                        <Option key={record.languageId} value={record.languageId}>
                                            {record.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        */}

                        <Form.Item
                            {...settingsLayoutShort}
                            label="Fleet View"
                            name="fleetMapDefaultView" 
                        >
                            <Select 
                                data-id="orgs-maint-info-fleet-map-default-view"
                                showSearch
                                showArrow={true}
                                optionFilterProp="children"
                                placeholder="Select..."
                            >
                                <Option value="satellite">Satellite</Option>
                                <Option value="roadmap">RoadMap</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            {...settingsLayout}
                            label={headerColorLabel}
                            colon={false}
                        >
                            <Row gutter={8}>
                                <Col xs={12} md={6}>
                                    <Form.Item
                                        name="headerColor"
                                        noStyle
                                    >
                                        <Input maxLength={16} placeholder="Hex color value"/>
                                    </Form.Item>
                                </Col>
                                <Col xs={12} md={4}>
                                    <Button data-id="header-color-pick-button" onClick={this.colorPickerOpen}>
                                        Pick
                                    </Button>
                                    <div>
                                        {this.state.displayColorPicker ? (
                                            <>
                                                <div style={{position: 'absolute', zIndex: 2, left: 5, top: -330 }}>
                                                    <div style={colorPickerCover} onClick={this.colorPickerClose} />
                                                    <SketchPicker
                                                        color={this.state.color}
                                                        onChange={this.colorPickerChange}
                                                    />
                                                </div>
                                            </>
                                        ) : null}
                                    </div>           
                                </Col>
                            </Row>
                        </Form.Item>
                        {
                            (contextUser.contextUserIsSuperUser) &&
                            <Form.Item
                                {...settingsLayout}
                                label="Terms"
                                name="termsAndConditions"
                            >
                                <TextArea rows={4} placeholder="Enter your Terms and Conditions here" maxLength={32000}/>
                            </Form.Item>
                        }
                    </Card>   

                    {
                        (iscreate  && isupdate) &&
                        <Card size="small" title="Login">
                            <Form.Item 
                                {...settingsLayout}
                                label="Current URL"
                            >
                                {
                                    this.state.loginUrlDisplay &&
                                    <Paragraph copyable={{ text: this.state.loginUrlDisplay }}>{this.state.loginUrlDisplay}</Paragraph>
                                }
                            </Form.Item>

                            <Form.Item
                                {...settingsLayout}
                                label={loginUrlLabel}
                                colon={false} 
                                name="loginUrl"                                 
                            >
                                <Input maxLength={64}/>
                            </Form.Item>

                            <Form.Item
                                {...settingsLayout}
                                label={loginBackgroundLabel}
                                colon={false}
                            >
                                <OrgLoginBackground formRef={this.formRef.current}/>       
                            </Form.Item>

                        </Card>
    }
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        orgsList: state.orgsReducers.orgsState.orgsList,
        permissions: state.contextReducer.data
    };
};

export default connect(
    mapStateToProps,
    { 
        ...rootActions,
        ...contextActions,
        ...orgActions
    }
)(OrgMaintInfo);