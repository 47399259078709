import React, { useState } from "react";
import {
  EditFormProps,
  Container,
  WidgetTitleFormField,
  SignalSelectorList,
} from "../../WidgetEditForm";
import { Config } from ".";
import { Form, Row, Col, InputNumber, Card,Switch,Select } from "antd";
import RightAxisSignals from "./RightAxisSignals";
import { truncate } from "fs";

function sanitizeInputs(values: Config,enableRightYAxis:boolean): Config {
  return {
    title: values.title,
    settings: {...values.settings,...(!enableRightYAxis?{}:{enableRightYAxis:enableRightYAxis})},
    type: "line",
    signals: values.signals.filter((v) => !!v),
    rightYAxisSignals:values.rightYAxisSignals?.filter((v) => !!v)
  };
}

const axisRangePath = (end: string) => ["settings", "axisRange", end];
const { Option } = Select;
export const EditForm: React.FC<EditFormProps> = (props) => {
  return (
    <div className="bar-chart-edit-form">
      <Form
        onFinish={(values) => props.onFinish(sanitizeInputs(values as Config,props.enabledualaxis))}
        layout="vertical"
        form={props.form}
        initialValues={props.config}
       
      >
        <Card title="Line Chart Settings">
          <Row>
            <WidgetTitleFormField />
          </Row>
          <Row>
          <div style={{display:'flex',flexWrap:'nowrap',marginBottom:'10px'}}>
            <div style={{color:'#000000',fontSize:'14px'}}>Dual Axis?</div>
            <div style={{marginLeft:'10px'}}><Switch checked={props.enabledualaxis} onChange={props.onDualChange}/></div> 
          </div>
          </Row>

       
                                        {/* 
                                            */}
                                            {/* <Row> */}
                                                    {/* <Form.Item
                                                        label="Dual Axis?"
                                                        name="enableRightYAxis"
                                                        valuePropName="checked"
                                                        style={{height:'25px',width:'100px'}}
                                                        >
                                                        <div style={{width:'50px',display:'flex',marginTop:'-11px'}}>
                                                        <Switch   
                                                            //  checked={props.enabledualaxis}
                                                            onChange={props.onDualChange}
                                                          />
                                                          </div>
                                                         
                                                       
                                                    </Form.Item>        */}
                                                                         
                                                {/* </Row> */}
                                                {/* </Col> */}
                                              
        
          <Row justify="space-between" style={{ width: "95%" }}>
            <Col span={10}>
              <Form.Item
                name={axisRangePath("min")}
                label="Axis Min"
                rules={[{ type: "number" }]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col span={10} push="right">
              <Form.Item
                name={axisRangePath("max")}
                label="Axis Max"
                rules={[{ type: "number" }]}
              >
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>
        
          <SignalSelectorList max={6} signals={props.template.signals} rules= {[]} isColor={"color"}/>
           {
              props.enabledualaxis &&
              <RightAxisSignals max={6} signals={props.template.signals} rules= {[]} isColor={"color"}/>
           }    
         
                     
        </Card>
      </Form>
    </div>
  );
};

export default EditForm;