import { appConfigs } from "../../../utils/configurations";

export const initialState = {
    roleList: {
        roleMaint: {
            instance: 0,
            title: "Role and Permission",
            width: 520,
            visible: false,
            selectonHeader: true,
            mode: "",
            record: [],
            successData: [],
            currentView: "list-view",
            activeTab: "1",
            onFinishTab:"2",
            orgs: [],
            orgRoles:{
                data:{
                  roles:[]  
                }
            },
            roles:{
                data:{
                  roles:[]  
                }
            },
            tabs: [
                {
                    title: "Role Info",
                    instance: 0,
                    disabled: false,
                    roleCopy: {
                        data: []
                    }
                },
                {
                    title: "Add Role and Permissions",
                    instance: 0,
                    disabled: false,
                    roleCopy: {
                        data: []
                    }
                }
            ],
            isSubmitButton : false
        },
        searchText: "",
        refreshOnUpdate: false,
        tableData: [],
        tableConfig : { 
            pagination : {                  
                showSizeChanger: appConfigs.tables.showSizeChanger,
                showQuickJumper: appConfigs.tables.showQuickJumper,
                pageSize: 30,
                defaultCurrent: appConfigs.tables.defaultCurrent,
                current: appConfigs.tables.defaultCurrent,
                total: appConfigs.tables.defaultTotal,
                hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                responsive: appConfigs.tables.responsive,
                onChange: null,
                showLessItems: appConfigs.tables.showLessItems,  
                showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} record${total > 1 ? 's' : ''}`
            }
        },
        tableRolesData: [],
        tableRolesConfig : { 
            pagination : {                  
                showSizeChanger: appConfigs.tables.showSizeChanger,
                showQuickJumper: appConfigs.tables.showQuickJumper,
                pageSize: appConfigs.tables.pageSize,
                defaultCurrent: appConfigs.tables.defaultCurrent,
                current: appConfigs.tables.defaultCurrent,
                total: appConfigs.tables.defaultTotal,
                hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                responsive: appConfigs.tables.responsive,
                onChange: null,
                showLessItems: appConfigs.tables.showLessItems,  
                showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} record${total > 1 ? 's' : ''}`
            }
        },
        tableOrgData: [],
        tableOrgConfig : { 
            pagination : {                  
                showSizeChanger: appConfigs.tables.showSizeChanger,
                showQuickJumper: appConfigs.tables.showQuickJumper,
                pageSize: 30,
                defaultCurrent: appConfigs.tables.defaultCurrent,
                current: appConfigs.tables.defaultCurrent,
                total: appConfigs.tables.defaultTotal,
                hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                responsive: appConfigs.tables.responsive,
                onChange: null,
                showLessItems: appConfigs.tables.showLessItems,  
                showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} record${total > 1 ? 's' : ''}`
            }
        },
       
    },
    rolePermissionList: {
        rolePermissionMaint: {
            instance: 0,
            title: "Role and Permission",
            width: 520,
            visible: false,
            selectonHeader: true,
            record: [],
            successData:[],
            currentView: "list-view",
            activeTab: "1",
            onFinishTab:"1",
            orgRoles:{
                data:{
                  roles:[]  
                }
            },            
            tabs: [
                {
                    title: "Add Role and Permissions",
                    instance: 0,
                    disabled: false,
                    roleCopy: {
                        data: []
                    }
                }
            ]
        },
       
        refreshOnUpdate: false,
        tableData: [],
        tableConfig : { 
            pagination : {                  
                showSizeChanger: appConfigs.tables.showSizeChanger,
                showQuickJumper: appConfigs.tables.showQuickJumper,
                pageSize: 30,
                defaultCurrent: appConfigs.tables.defaultCurrent,
                current: appConfigs.tables.defaultCurrent,
                total: appConfigs.tables.defaultTotal,
                hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                responsive: appConfigs.tables.responsive,
                onChange: null,
                showLessItems: appConfigs.tables.showLessItems,  
                showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} record${total > 1 ? 's' : ''}`
            }
        }
    },
    customerOrgPermissions: [],
    data:{
        initialized: false, 
        "status": "",
        "timestamp": "",    
        permissions:[]
    }

};