import React, { useEffect, useState } from "react";
import { Drawer, Button, Space, Form, Input, Popconfirm, message, Select, Row, Col, } from "antd";
import TextArea from "antd/lib/input/TextArea";

import Axios from "axios";

import { connect } from "react-redux";
import * as actions from "./actions";

import { appConfigs } from "../../utils/configurations";

import { ErrorAlert, SuccessAlert } from "../../utils/Components/formAlerts";
import { getPermissions } from "../../utils/commonHelpers";
import { ServiceTruck } from "../FleetOverview2/models";


const { Option } = Select;

interface Prop{
    closeServiceTruckMaintDrawer: Function,
    updateServiceTrucksDetails: Function,
    serviceTruckMaint: ServiceTruck,
    serviceTruckList: any,
    serviceTruckMintDrawerVisible: any,
    map: any,
    permissions: any,
}

interface State {
    formError?: boolean;
    formErrorMessage?: string[];
    formSuccess?: boolean;
    formSuccessMessage?: string;
    ServiceTruckNew?: ServiceTruck;
}


const ServiceTrucksMaint = (props: Prop) => {

    const [formError, setFormError] = useState<boolean>(false);
    const [formErrorMessage, setFormErrorMessage] = useState<string[]>([]);
    const [formSuccess, setFormSuccess] = useState<boolean>(false);
    const [formSuccessMessage, setFormSuccessMessage] = useState<string>("");
    const [serviceTrucks, setServiceTrucks] = useState<ServiceTruck>(props.serviceTruckMaint);
    
    const serviceTruckMaint = props.serviceTruckMaint

    useEffect(() => {
        setServiceTrucks(serviceTruckMaint)
    }, [serviceTruckMaint]);


    const close = () => {
        props.closeServiceTruckMaintDrawer();
        setFormError(false)
        setFormErrorMessage([])
        setFormSuccess(false)
        setFormSuccessMessage("")
    }


    const setOperatorName = (value: any) => {
        if (value) {
            setServiceTrucks({ ...serviceTruckMaint, operatorName: value.trim(), orgId: props.serviceTruckMaint.orgId ? props.serviceTruckMaint.orgId : '' })
        } else {
            setServiceTrucks({ ...serviceTruckMaint, operatorName: '' })
        }
    }
   
    const onSubmitClick = () => {        
        if (serviceTrucks.operatorName) {
            setFormError(false)
            setFormErrorMessage([])
            props.updateServiceTrucksDetails(serviceTrucks, callbackFnSuccess ,callbackFnFailure)

        } else {
            setFormError(true)
            setFormErrorMessage(["Operator name cannot be empty"])
        }

    }

    const callbackFnSuccess = (message: string) => {
        setFormError(false)
        setFormErrorMessage([""])

        setFormSuccess(true)
        setFormSuccessMessage(message)
    }


    const callbackFnFailure = (messages: string[]) => {
        setFormSuccess(false)
        setFormSuccessMessage("")

        setFormError(true)
        setFormErrorMessage(messages)
    }


    return (
        <Drawer
            zIndex={3}
            data-id="user-maint-drawer" 
            title={`Vehicle ID: ${props.serviceTruckMaint?.vehicleName}`}
            width={"400px"}
            onClose={close}
            visible={props.serviceTruckMintDrawerVisible}
            destroyOnClose={true}
            footer={
                <Row justify="space-between">
                    {
                        <Col span={24}>
                            <Button style={{ float: 'right' }} data-id="assets-maint-submit-button" type="primary" form="serviceTruckForm" key="submit" onClick={() => onSubmitClick() } htmlType="submit">
                                {appConfigs.settings.form.submitButtonText}
                            </Button>
                        </Col>
                    }
                </Row>
            }
        >                       

            {formSuccess && !formError && <SuccessAlert data-id="assets-maint-info-success-message" message={formSuccessMessage}/>}
            {formError && !formSuccess && <ErrorAlert data-id="assets-maint-info-error-message" message={formErrorMessage}/>}
                
            {
                <Form layout="vertical" >  

                    <Form.Item style={{marginTop:10}} label="Operator Name">
                        <Input placeholder="Operator Name" defaultValue={props.serviceTruckMaint.operatorName} onChange={ (e) => setOperatorName(e.target.value) }/>
                    </Form.Item>
                    
                </Form>
            }
            
        </Drawer>
    );

}


const mapStateToProps = (state:any) => {

    return {
        serviceTruckMaint: state.serviceTruckReducer.serviceTruckState.serviceTruckMaint,
        serviceTruckList: state.serviceTruckReducer.serviceTruckState.serviceTruckList,
        serviceTruckMintDrawerVisible: state.serviceTruckReducer.serviceTruckState.serviceTruckMintDrawerVisible,
        map: state.serviceTruckReducer.serviceTruckState.map,
        permissions: state.contextReducer.data,
    }
}

export default connect(
    mapStateToProps,actions
)(ServiceTrucksMaint);



