import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function assetsState(state = initialState, action:any) {
    let data:any = null,
        payload:any;

    switch (action.type) {    
        case "ASSETS_SET_PAGINATION":
            return immerProduce(state, draft => {
                //draft.assetsList.tableConfig.pagination.onChange = action.onPaginationChange;
            });
        case "ASSETS_SET_STATUS":
            return immerProduce(state, draft => {
                draft.assetsList.status = action.status;
            });
        case "ASSETS_SET_SEARCH":
            return immerProduce(state, draft => {
                draft.assetsList.searchText = action.searchText;
            });
        case "ASSETS_SET_GATEWAY_ID":
            return immerProduce(state, draft => {
                draft.assetsList.gatewayId = action.gatewayId;
            });    
        case "ASSETS_GET_ASSETS_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.assetsList.refreshOnUpdate = false;
                draft.assetsList.tableData = data;
                draft.assetsList.tableConfig.pagination.total = (data.length || 0) > 0 ? data[0].totalRecords : 0;
                draft.assetsList.tableConfig.pagination.current = action.payload.page;        
                draft.assetsList.assetMaint.tabs[2].allowViewSleepMode = (data.length || 0) > 0 ? data[0].allowViewSleepMode : false;
                draft.assetsList.assetMaint.tabs[2].allowChangeSleepMode = (data.length || 0) > 0 ? data[0].allowChangeSleepMode : false; 
            });
        case "ASSET_SETTINGS": 
        
            return immerProduce(state, draft =>{
                draft.assetsList.assetMaint.tabs[2].enableWiFi = action.payload.enableWiFi;
                draft.assetsList.assetMaint.tabs[2].enableHotspot = action.payload.enableHotspot;
                draft.assetsList.assetMaint.tabs[2].enableEthernet = action.payload.enableEthernet;
                draft.assetsList.assetMaint.tabs[2].ssidList = action.payload.ssidList;
                draft.assetsList.assetMaint.tabs[2].connected_SSID = action.payload.connected_SSID;
                draft.assetsList.assetMaint.tabs[2].ethernetIPAddress = action.payload.ethernetIPAddress;
                draft.assetsList.assetMaint.tabs[2].enableSleepMode = action.payload.enableSleepMode;
                draft.assetsList.assetMaint.tabs[2].sleepCycleInSeconds = action.payload.sleepCycleInSeconds;   
                draft.assetsList.assetMaint.tabs[2].sleepCycleApplied = action.payload.sleepCycleApplied;
                draft.assetsList.assetMaint.tabs[2].nextWakeUpTime = action.payload.nextWakeUpTime;
                
        }
        
        );
        case "ASSETS_ASSET_MAINT_OPEN":
            return immerProduce(state, draft => {
                const 
                    payload = action.payload,
                    instance = Math.random();
                draft.assetsList.assetMaint.instance = instance;
                draft.assetsList.assetMaint.width = payload.width;
                draft.assetsList.assetMaint.visible = true;
                draft.assetsList.assetMaint.title = payload.title;
                draft.assetsList.assetMaint.record = payload.record;

                draft.assetsList.assetMaint.activeTab = "1";
                draft.assetsList.assetMaint.tabs[0].disabled = false;
                draft.assetsList.assetMaint.tabs[0].instance = instance;
                draft.assetsList.assetMaint.tabs[1].disabled = Object.keys(payload.record).length === 0;
                draft.assetsList.assetMaint.tabs[1].instance = instance;
                draft.assetsList.assetMaint.tabs[2].instance = instance;
            });                
        case "ASSETS_ASSET_MAINT_CLOSE":
            return immerProduce(state, draft => {
                draft.assetsList.assetMaint.visible = false;
                draft.assetsList.assetMaint.record = {};
                draft.assetsList.assetMaint.iqanConnectKey.status = "";
                draft.assetsList.assetMaint.iqanConnectKey.value = "";
                draft.assetsList.assetMaint.tabs[0].orgs = [];
                draft.assetsList.assetMaint.tabs[0].templates = [];
                draft.assetsList.assetMaint.tabs[1].searchText = "";
                draft.assetsList.assetMaint.tabs[1].tableData = [];
            });   
        case "ASSETS_MAINT_SET_TITLE":
            return immerProduce(state, draft => {
                draft.assetsList.assetMaint.title = action.title;
            });                   
        case "ASSETS_ASSET_MAINT_TABS_ENABLE":
            return immerProduce(state, draft => {
                if (draft.assetsList.assetMaint.tabs[0].disabled) {
                    draft.assetsList.assetMaint.tabs[0].disabled = false;
                }
                if (draft.assetsList.assetMaint.tabs[1].disabled) {
                    draft.assetsList.assetMaint.tabs[1].disabled = false;
                }
            });                                
        case "ASSETS_ASSET_MAINT_TAB_CHANGE":
            return immerProduce(state, draft => {
                draft.assetsList.assetMaint.activeTab = action.activeTab;
            });                   
        case "ASSETS_ASSET_MAINT_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.assetsList.refreshOnUpdate = true;
                draft.assetsList.assetMaint.record = data;
            });                         
        case "ASSETS_ASSET_STATUS_SUCCESS":
            return immerProduce(state, draft => {
            });                                                            
        case "ASSETS_LIST_REFRESH_ON_UPDATE":
            return immerProduce(state, draft => {
                draft.assetsList.refreshOnUpdate = true;
            });            
        case "ASSETS_ELIGIBLE_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.assetsList.assetMaint.tabs[0].assetsEligible = data;
            });                    
        case "ORG_TAGS_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.assetsList.assetMaint.tabs[0].orgTags = data;
            });                    
        case "ASSETS_FILTER_TAGS_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.assetsList.filterTags = data;
            });                    
        case "ASSETS_GET_TEMPLATES_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.assetsList.assetMaint.tabs[0].templates = data;
            });                        
        case "ASSETS_GET_ORGS_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.assetsList.assetMaint.tabs[0].orgs = data;
            });    
        case "ASSET_SUBSCRIPTIONS_SET_PAGINATION":
            return immerProduce(state, draft => {
                draft.assetsList.assetMaint.tabs[1].tableConfig!.pagination.onChange = action.onPaginationChange;
            });
        case "ASSET_SUBSCRIPTIONS_SET_SEARCH":
            return immerProduce(state, draft => {
                draft.assetsList.assetMaint.tabs[1].searchText = action.searchText;
            });
        case "ASSET_SUBSCRIPTIONS_GET_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.assetsList.assetMaint.tabs[1].tableData! = data.filter( (ele: any, ind: any) => ind === data.findIndex( (elem: { userId: any; }) => elem.userId === ele.userId));
                draft.assetsList.assetMaint.tabs[1].tableConfig!.pagination.total = (data.length || 0) > 0 ? data[0].groupsTotal : 0;
                draft.assetsList.assetMaint.tabs[1].tableConfig!.pagination.current = action.payload.page;

            });
        case "ASSET_SUBSCRIPTIONS_MAINT_SUCCESS":
            return immerProduce(state, draft => {
                payload = action.payload;
                if (payload.scope === "user") {
                    updateUser(draft, payload.record, payload.notification, payload.action);                
                }
                else {               
                    updateUserAlerts(draft, payload.record, payload.notification, payload.action);                
                }
            });           
        case "ASSETS_IQAN_CONNECT_KEY_SUCCESS":
            return immerProduce(state, draft => {
                draft.assetsList.assetMaint.iqanConnectKey = action.iqanConnectKey;
            });    
        case "LITE_ASSETS_GET_SUCCESS":
                data = action.payload.data;
            return immerProduce(state, draft => {
                draft.assetsList.liteAssets.refreshOnUpdate = false;
                draft.assetsList.liteAssets.tableData = data;
                draft.assetsList.liteAssets.tableConfig.pagination.total = (data.length || 0) > 0 ? data[0].totalRecords : 0;
                draft.assetsList.liteAssets.tableConfig.pagination.current = action.payload.page;
              
            });   
         case "LITEASSETS_SET_SEARCH":
                return immerProduce(state, draft => {
                    draft.assetsList.liteAssets.searchText = action.searchText;
          });
          case "LITEASSETS_SET_PAGINATION":
                 return immerProduce(state, draft => {
                    draft.assetsList.liteAssets.tableConfig.pagination.onChange = action.onPaginationChange;
          }); 
          case "ASSETS_WIFI_PASSWORD":
          return immerProduce(state, draft => {
             draft.assetsList.assetMaint.wifipassword = action.wifipassword;
          }); 
          case "TUTORIALS_GET_SUCCESS":
            return immerProduce(state, draft => {
                draft.assetsList.assetMaint.tutorials.tutorialsList = action.data;
            });  

        default:
            return state;
    }
    
}

const updateUser = (draft:any, record:any, notification: string, action: string) => {   
    draft.assetsList.assetMaint.tabs[1].tableData.forEach(function (user:any) {
        if (user.key === record.key) {
            user.children.forEach(function (alert:any) {
                if (notification === "sms") {
                    alert.alertSms = (action === "plus");
                    alert.incAlertSms = (action === "plus");
                    alert.offlineAlertSms = (action === "plus");
                }
                if (notification === "email") {
                    alert.alertEmail = (action === "plus");
                    alert.incAlertEmail = (action === "plus");
                    alert.offlineAlertEmail = (action === "plus");
                }
            });
            if (notification === "sms") {
                user.userSmsIndeterminate = false;
                user.userSmsChecked = (action === "plus");
            }
            else {
                user.userEmailIndeterminate = false;
                user.userEmailChecked = (action === "plus");
            }
        }
    });
}

const updateUserAlerts = (draft:any, record:any, notification: string, action: string) => {   
    draft.assetsList.assetMaint.tabs[1].tableData.forEach(function (user:any) { 
        user.children.forEach(function (alert:any) {
            if (alert.key === record.key) {
                let checkedCount = 0;
                if (notification === "sms") {
                    alert.alertSms = (action === "plus");
                    alert.incAlertSms = (action === "plus");
                    alert.offlineAlertSms = (action === "plus");
                }
                if (notification === "email") {
                    alert.alertEmail = (action === "plus");
                    alert.incAlertEmail = (action === "plus");
                    alert.offlineAlertEmail = (action === "plus");
                }
                if(notification === "smsIn"){
                    alert.alertSmsIn = (action === "plus");
                }
                if(notification === "smsOut"){
                    alert.alertSmsOut = (action === "plus");

                }
                if(notification === "emailIn"){
                    alert.alertEmailIn = (action === "plus");
                }
                if(notification === "emailOut"){
                    alert.alertEmailOut = (action === "plus");
                }


                user.children.forEach(function (alertCount:any) {
                    checkedCount = checkedCount + (notification === "sms" && alertCount.alertSms ? 1 : 0) + (notification === "email" && alertCount.alertEmail ? 1 : 0);
                });
                if (notification === "sms") {
                    user.userSmsIndeterminate = (checkedCount > 0 && checkedCount < user.children.length);
                    user.userSmsChecked = (checkedCount === user.children.length);
                }
                else {
                    user.userEmailIndeterminate = (checkedCount > 0 && checkedCount < user.children.length);
                    user.userEmailChecked = (checkedCount === user.children.length);
                } 
            }
        });
    });
}