import React from "react";
import { Upload, Button, Modal, Tooltip,  message, notification } from 'antd';
import { FileAddOutlined , UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { connect } from "react-redux";
import * as actions from "../actions";

import { appConfigs } from "../../../utils/configurations";
import { getToken, uuidv4 } from "../../../utils/commonHelpers";
import history from '../../../utils/history';
import { CSVLink } from "react-csv";

interface Prop {
    dm1FaultsAddRecords: Function,
    // dm1FaultsSetImportFlag : Function, //feature can track when csv file is imported
    editModeSet: Function,
    listType: string,
    dm1Faults: any
}

interface State {
    visible: boolean;
    csvData: any,
    csvFileName: string
}

class Dm1UploadFile extends React.Component<Prop, State> {  

    listIndex = (this.props.listType=='whitelist')?0:1;
    csvLink:any = React.createRef<CSVLink>();    

    state:State = {
        visible: false,
        csvData: [],
        csvFileName: ""
    }

    getTemplateId() {
        const pathnameArr = history.location.pathname.split("/");
        return pathnameArr[pathnameArr.length - 1];
    }

    openModal = (e:any) => {   
        e.preventDefault();     
        this.setState({
            visible: true
        });
    };    

    closeModal = (e: any) => {
        e.preventDefault();
        this.setState({
            visible: false
        });

        let items:any = document.querySelectorAll(".ant-upload-list");    
        for (let i = items.length; i--;) {
            items[i].innerHTML = "";
        }
    };
    
    closeModalwhenSuccessful = () => {
        this.setState({
            visible: false
        });

        let items:any = document.querySelectorAll(".ant-upload-list");    
        for (let i = items.length; i--;) {
            items[i].innerHTML = "";
        }
    };  

    whiteListCsvData = () => {  
        let csvData:any = [];
        let csvRecord:string[] = [];    

        csvRecord.push("DM1Enable");
        csvRecord.push("SA");
        csvRecord.push("SPN");
        csvRecord.push("FMI");
        csvData.push(csvRecord);
        csvRecord = [];   
        csvRecord.push("Enabled");
        csvRecord.push("1");
        csvRecord.push("200");
        csvRecord.push("1");
        csvData.push(csvRecord);
        csvRecord = [];   
        csvRecord.push("Enabled");
        csvRecord.push("1");
        csvRecord.push("300");
        csvRecord.push("2");
        csvData.push(csvRecord);

        this.setState({ csvData: csvData, csvFileName: "sample-" + uuidv4() + ".csv" });
    }

    whiteListSample = (e: any) => {   
        e.preventDefault();     

        this.whiteListCsvData();

        const me = this;
        setTimeout(function () {
            me.csvLink.link.click();
        }, 250);   
    }

    beforeUpload = (file:any) => {
        const fileType = file.type === "text/csv" || file.type === "text/tsv" || file.type === "text/plain" || file.type === "application/vnd.ms-excel";
        const fileSize = file.size / 1024 < 500;

        if (!fileType) {
            notification.error({
                message: "File Processing",
                description: "CSV (comma separated values) and text file types are only allowed"
            });
        }
        if (!fileSize) {
            notification.error({
                message: "File Processing",
                description: "File must be smaller than 500kB"
            });
        }
        
        return fileType && fileSize;
    }

    render() {
        let token=getToken();
        const me = this;
        const uploadProps = {
            name: "file",
            action: appConfigs.server.URL + "/ui/api/templates/" + this.getTemplateId() + "/dm1-faults/upload",
            headers: {
                //authorization: `Bearer ${JSON.parse(window.localStorage.getItem("okta-token-storage") || "{}").idToken.value}`,
                authorization: `Bearer ${token.value||token.idToken}`,
            },
            onChange(info: any) { 
                if (info.file.status === "done") {
                    //const totalRecordsPre = (me.props.dm1Faults.tableData[me.listIndex].length || 0);
                    me.props.dm1FaultsAddRecords(me, me.props.listType, info.file.response.data.dm1Faults);
                    //const totalRecordsPost = (me.props.dm1Faults.tableData[me.listIndex].length || 0);
                    message.success(`File has been successfully processed. Click Save to confirm changes!`);
                    //me.props.dm1FaultsSetImportFlag(me, me.props.listType, true);   //feature can track when csv file is imported
                    me.closeModalwhenSuccessful();
                    
                    // if (totalRecordsPre > 0 && totalRecordsPre === totalRecordsPost) {
                    //     notification.warning({
                    //         message: "File Processing",
                    //         description: "File has been successfully processed - No unique records were found"
                    //     });
                    // }
                    // else {
                    //     if (totalRecordsPre > 0) {
                    //         message.success(`File has been successfully processed - ${totalRecordsPost-totalRecordsPre} record${totalRecordsPost-totalRecordsPre > 1 ? "s" : ""} added. Click Submit to save your changes!`);
                    //     }
                    //     else {
                    //         message.success(`File has been successfully processed. Click button Submit to save your changes!`);
                    //     }
                    // }
                    
                } 
                else if (info.file.status === "error") {
                    let formErrors: any = [];
                    if (info.file.response.errors && (info.file.response.errors.length || 0) !== 0) {
                        info.file.response.errors.forEach(function (record: any) {
                            if (record.type === "form") {
                                formErrors.push(record.message);
                            }
                        });
                    }
                    if (formErrors.length > 0) {
                        formErrors.forEach((error: string) => {
                            notification.error({
                                message: "File Processing",
                                description: error
                            });
                        });
                    }
                    else {
                        message.error(`${info.file.name} file import failed.`);
                    }
                }
            }
        };

        return (
            
            <div>
                <Tooltip title="Import Data from File">
                    <Button data-id="dm1-faults-upload-button" href="# " onClick={(e) => {this.openModal(e)}} target="_new" type="default" icon={<FileAddOutlined  />}>
                        <span>Import</span>  
                    </Button>
                </Tooltip>
                <Modal
                    title="Add From Whitelist CSV File"
                    visible={this.state.visible}
                    onCancel={this.closeModal}
                    okButtonProps={{ hidden: true }}
                >
                    <Upload 
                        {...uploadProps}                 
                        beforeUpload={this.beforeUpload}
                    >
                        <Button>
                            <UploadOutlined /> Select File to Import
                        </Button>
                        <Tooltip placement="topLeft" title={appConfigs.messages.dm1FaultsUploadTooltip}>
                            <QuestionCircleOutlined style={{ marginLeft: '10px'}}/>
                        </Tooltip>
                        <p>Note : Importing will overwrite the current list</p>
                    </Upload>
                    <div className="mt-20">
                        <a href="# " onClick={(e) => {this.whiteListSample(e)}}>Download sample here</a>
                        <CSVLink
                            data={this.state.csvData}
                            filename={this.state.csvFileName}
                            className="hidden"
                            ref={(r:any) => this.csvLink = r}
                            target="_blank"
                        />
                    </div>
                </Modal>
            </div>

        );
    }
}


const mapStateToProps = (state:any) => {
    return {
        dm1Faults: state.templateDetailsReducers.templateDetailsState.tabs[1]
    };
};

export default connect(
    mapStateToProps,
    actions
)(Dm1UploadFile);