import React from "react";

import { findTimezone, formatDate } from '../common';
import { formatGps } from "../../../../utils/commonHelpers";

const formatOptions = {
  month: "short",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

function formatTimestamp(timestamp: Date): string {
  //return timestamp.toLocaleDateString("en-US", formatOptions);
 // console.log("timestamp")
  //console.log(timestamp)
  return formatDate(timestamp);
}

interface TootipProps {
  position: Position;
  timestamp: Date;
  title?: string;
  timezone?:any;
}

const Tooltip: React.FC<TootipProps> = ({ position, timestamp, title,timezone }) => {
  var details=findTimezone(timezone);
  
  return (
    <div>
      {title !== undefined && <><span style={{ color: 'black' }}><b>{title}</b></span> <hr/></>}
      <span>Location: {formatGps(position.lat, position.lng)}</span>
      <br />
      <span>{formatTimestamp(timestamp)+" ".concat(details?.timezone)}</span>       
    </div>
  );
};

export default Tooltip;

interface Position {
  lat: number;
  lng: number;
}

export function toolTipForAsset(
    position: Position,
    timestamp: Date,
    title?: string,
    timezone?:any
) {
    return (
        <Tooltip position={position} timestamp={timestamp} title={title} timezone={timezone} />
    );
}