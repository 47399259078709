import { produce as immerProduce } from "immer";
import { initialState } from "./contextState";

export function contextReducer(state = initialState, action: any) {
    switch (action.type) {
        case "CONTEXT_SUCCESS":
            return immerProduce(state, draft => {
                localStorage.setItem("context", JSON.stringify(action.payload.data));
                action.payload.data.user.uiPreferences = (action.payload.data.user.uiPreferences != undefined || action.payload.data.user.uiPreferences != null) ?
                    JSON.parse(action.payload.data.user.uiPreferences) : null;
                draft.context = action.payload.data;
                draft.context.initialized = true;
            });
        case "CONTEXT_UPDATE_GPS_COORDINATES":
            return immerProduce(state, draft => {
                draft.gpsCoordinates.latitude = action.coordinates.latitude;
                draft.gpsCoordinates.longitude = action.coordinates.longitude;
            });
        case "CONTEXT_FAILURE":
            return immerProduce(state, draft => {
                window.localStorage.setItem("context", "{}");
            });
        case "CONTEXT_UPDATE_MAP_DEFAULT_VIEW":
            return immerProduce(state, draft => {
                draft.context.orgSettings.fleetMapDefaultView = action.mapDefaultView;
            });
        case "PERMISSIONS_SUCCESS":
            return immerProduce(state, draft => {
                localStorage.setItem("permissions", JSON.stringify(action.payload.data));
                draft.data = action.payload.data;
                draft.data.initialized = true;
            });
        case "MAX_COMMAND_PERMISSION_SUCCESS":
            return immerProduce(state, draft => {
                localStorage.setItem("maxCommandPermissionValue", JSON.stringify(action.payload.data));
                draft.maxCommandPermissionValue = action.payload.data;
            });
        case "PERMISSIONS_FAILURE":
            return immerProduce(state, draft => {
                window.localStorage.setItem("permissions", "{}");
            });
        case "STATS_SUCCESS":
            return immerProduce(state, draft => {
                draft.stats.data = action.payload.data;
                draft.stats.timestamp = new Date();
                draft.stats.initialized = true;
                draft.stats.loading = false;
            });
        case "SET_STATS_DM1_ASSETS_LIST":
            return immerProduce(state, draft => {
                draft.stats.assetsWithDm1 = action.payload.assetsWithDm1;
            });
        case "SET_FLEET_STATS_INITIALIZATION":
            return immerProduce(state, draft => {
                draft.stats.initialized = action.initialized;
            });
        case "SET_FLEET_STATS_VISIBILITY":
            return immerProduce(state, draft => {
                draft.stats.visible = action.visible;
            });
        case "SET_NOTIFICATION_VISIBILITY":
            return immerProduce(state, draft => {
                draft.notifications.visible = action.visible;
            });
        case "SET_DM1_DAILY_COUNT":
            return immerProduce(state, draft => {
                draft.stats.dm1DailyCount = action.payload.dailyDm1Count.assetDmDailyCount;
            });
        case "RESET_DM1_DAILY_COUNT":
            return immerProduce(state, draft => {
                draft.stats.dm1DailyCount = null;
            });
        case "SET_DM1_HEATMAP":
            return immerProduce(state, draft => {
                draft.stats.dm1HeatMapData = action.payload.dm1HeatMapData;
            });
        case "RESET_DM1_HEATMAP":
            return immerProduce(state, draft => {
                draft.stats.dm1HeatMapData = [];
            });
        case "SET_FLEET_STATS_LOADING":
            return immerProduce(state, draft => {
                draft.stats.loading = action.loading;
            });
        case "ENABLE_SIGNAL_R_DATA":
            return immerProduce(state, draft => {
                draft.signalRData.enabled = true
            });
        case "DISABLE_SIGNAL_R_DATA":
            return immerProduce(state, draft => {
                draft.signalRData.enabled = false
            });
        case "RESET_SIGNAL_R_DATA":
            return immerProduce(state, draft => {
                draft.signalRData.data = []
            });
        case "UPDATE_SIGNAL_R_DATA":
            return immerProduce(state, draft => {
                let liveData = JSON.parse(action.payload.liveData)
                // console.log("liveData", liveData);
                
                let dashboardUpdatedSignals:any = [];
                // console.log('state.signalRData.data', state.signalRData.data)
                // console.log('liveData', liveData)

                // dashboardUpdatedSignals = [...state.signalRData.data]
                Object.keys(state.signalRData.data).map((SignalID:any) => {
                    if(!dashboardUpdatedSignals.hasOwnProperty(SignalID)){
                        dashboardUpdatedSignals[SignalID] = []
                    }
                    Object.keys(state.signalRData.data[SignalID]).map((index:any) => {
                        dashboardUpdatedSignals[SignalID][index] = state.signalRData.data[SignalID][index]
                    })
                })

                // console.log('dashboardUpdatedSignals', dashboardUpdatedSignals)


                let baseTime: any = liveData[0].bt;

                /* Reformat SenML data to get only latest (most recent) values for signals on dashboard */
                /* Assuming that the format is all t is in ascending sequence */
                liveData.forEach((dataPoint: any, index: number) => {
                    for (let key = 0; key < action.payload.dashboardSignals.length; key++) {

                        if (dataPoint.hasOwnProperty('bn')) {
                            if (dataPoint.bn.includes(action.payload.dashboardSignals[key].signalId)) {

                                if (!dashboardUpdatedSignals.hasOwnProperty(action.payload.dashboardSignals[key].signalId)) {
                                    dashboardUpdatedSignals[action.payload.dashboardSignals[key].signalId] = [{
                                        bt: baseTime,
                                        name: action.payload.dashboardSignals[key].name,
                                        signalId: action.payload.dashboardSignals[key].signalId,
                                        value: dataPoint.v,
                                        time: dataPoint.hasOwnProperty('t') ? dataPoint.t : 0
                                    }]
                                } else {
                                    dashboardUpdatedSignals[action.payload.dashboardSignals[key].signalId].unshift({
                                        bt: baseTime,
                                        name: action.payload.dashboardSignals[key].name,
                                        signalId: action.payload.dashboardSignals[key].signalId,
                                        value: dataPoint.v,
                                        time: dataPoint.hasOwnProperty('t') ? dataPoint.t : 0
                                    })
                                }

                                
                            }

                        }

                        if (dataPoint.hasOwnProperty('n')) {
                            if (dataPoint.n.includes(action.payload.dashboardSignals[key].signalId)) {

                                if (!dashboardUpdatedSignals.hasOwnProperty(action.payload.dashboardSignals[key].signalId)) {
                                    dashboardUpdatedSignals[action.payload.dashboardSignals[key].signalId] = [{
                                        bt: baseTime,
                                        name: action.payload.dashboardSignals[key].name,
                                        signalId: action.payload.dashboardSignals[key].signalId,
                                        value: dataPoint.v,
                                        time: dataPoint.t
                                    }]
                                } else {
                                    dashboardUpdatedSignals[action.payload.dashboardSignals[key].signalId].unshift({
                                        bt: baseTime,
                                        name: action.payload.dashboardSignals[key].name,
                                        signalId: action.payload.dashboardSignals[key].signalId,
                                        value: dataPoint.v,
                                        time: dataPoint.t
                                    })
                                }

                                
                            }
                        }

                        /* handle no .n or .bn */
                        if (!dataPoint.hasOwnProperty('bn') && !dataPoint.hasOwnProperty('n')) {
                            if (dashboardUpdatedSignals[dashboardUpdatedSignals.length - 1]) {
                                if (dashboardUpdatedSignals[dashboardUpdatedSignals.length - 1].hasOwnProperty('bn')) {
                                    if (dashboardUpdatedSignals[dashboardUpdatedSignals.length - 1].bn.includes(action.payload.dashboardSignals[key].signalId)) {
                                        dashboardUpdatedSignals[action.payload.dashboardSignals[key].signalId].unshift({
                                            bt: baseTime,
                                            name: action.payload.dashboardSignals[key].name,
                                            signalId: action.payload.dashboardSignals[key].signalId,
                                            value: dataPoint.v,
                                            time: dataPoint.hasOwnProperty('t') ? dataPoint.t : 0
                                        })
                                    }

                                }

                                if (dashboardUpdatedSignals[dashboardUpdatedSignals.length - 1].hasOwnProperty('n')) {
                                    if (dashboardUpdatedSignals[dashboardUpdatedSignals.length - 1].n.includes(action.payload.dashboardSignals[key].signalId)) {
                                        dashboardUpdatedSignals[action.payload.dashboardSignals[key].signalId].unshift({
                                            bt: baseTime,
                                            name: action.payload.dashboardSignals[key].name,
                                            signalId: action.payload.dashboardSignals[key].signalId,
                                            value: dataPoint.v,
                                            time: dataPoint.t
                                        })
                                    }
                                }
                            }


                        }

                    }
                });

                console.log(`dashboardUpdatedSignals`, dashboardUpdatedSignals)
                draft.signalRData.data = dashboardUpdatedSignals;
                // draft.signalRData.SignalRInitialData = dashboardUpdatedSignals;

            });
        case "STATS_FAILURE":
            return immerProduce(state, draft => {
                draft.stats.data = {};
                draft.stats.initialized = false;
                draft.stats.loading = false;
            });
        default:
            return state;
    }
}