import Axios from "axios";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";
import { appConfigs } from "../../../utils/configurations";


export function getGatewaysControllers(
    me: any, 
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
    page: number = 1,
    searchText?: string,
    filters?: any[any],
    controllerId?:string
) {
    
    return async (dispatch: any) => {

        let filtersObj:any = [];
        filters && filters['status'] != null ? filtersObj = {'statusFilter': filters['status']} : filtersObj = {}

        Axios.get(
            appConfigs.server.URL + "/ui/api/gateways_controllers", {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText,
                    page: page,
                    pageSize: appConfigs.tables.pageSize
                }
            }
        )
        .then(response => { 
            
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.controllers);
                }
                dispatch({ type: "CONTROLLERS_GET_SUCCESS", payload: response.data.data.gateways_controllers  });
            }
        )
        .catch(function (error) {
            console.log('error', error)
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            dispatch({ type: "CONTROLLERS_GET_FAILED", payload: {data: null, page: page}  });
            errorResponse(me, error);
        });
    };
}


export function initiateControllerOTA(
    controllerInfo: any,
    applicationInfo: any,
    callbackFnSuccess?: any,
    callbackFnFailure?: any
) {

    return async (dispatch: any) => {

        let iqanOpenOtaRequestForm = {
            orgId: applicationInfo.orgId,
            gatewayId: controllerInfo.gatewayId,
            controllerId: controllerInfo.controllerId,
            applicationId: applicationInfo.applicationId,
            controllerAddress: controllerInfo.address,
            pathName: applicationInfo.storageLocation.slice(37),
            // serialNumber: controllerInfo.serialNumber,
        }

        Axios.post(
            appConfigs.server.URL + "/ui/api/ota_controller/" + controllerInfo.mastertag,
            iqanOpenOtaRequestForm,
            {
                responseType: "json",       
                headers: {},
            }
        )
        .then(response => { 
            
                if (callbackFnSuccess != null) {
                    callbackFnSuccess("OTA Successfully Initiated");
                }
                // dispatch({ type: "INITIATE_CONTROLLER_OTA_SUCCESS", payload: response.data.data.installation_id  });
            }
        )
        .catch(function (error) {
            
            if (callbackFnFailure != null) {
                callbackFnFailure(error)
            }
            // dispatch({ type: "INITIATE_CONTROLLER_OTA_FAILED", payload: null });
        });
    };
}




export function updateOTAStatusFromDeviceTwin(
    installationDetails: any,
    controllerDetails: any,
    callbackFnSuccess?: any,
    callbackFnFailure?: any
) {

    return async (dispatch: any) => {

        Axios.post(
            appConfigs.server.URL + "/ui/api/update_ota_status/",
            installationDetails,
            {
                responseType: "json",       
                headers: {},
                params: {
                    mastertag: controllerDetails.mastertag,
                    controllerAddress: controllerDetails.address,
                }
            }
        )
        .then(response => { 

                console.log(response.data)
            
                if (callbackFnSuccess != null) {
                    callbackFnSuccess("OTA Successfully Initiated");
                }
            }
        )
        .catch(function (error) {
            
                console.log("error");
                console.log(error);
                
            if (callbackFnFailure != null) {
                callbackFnFailure(error)
            }
        });
    };
}



// export function getApplications(
//     me: any, 
//     callbackFnSuccess?: any,
//     callbackFnFailure?: any,
//     page: number = 1,
//     searchText?: string,
//     filters?: any[any],
//     applicationId?:string
// ) {
    
//     return async (dispatch: any) => {

//         let filtersObj:any = [];
//         filters && filters['status'] != null ? filtersObj = {'statusFilter': filters['status']} : filtersObj = {}

//         Axios.get(
//             appConfigs.server.URL + "/ui/api/applications", {
//                 responseType: "json",        
//                 headers: {},
//                 params: {
//                     searchText: searchText,
//                     page: page,
//                     pageSize: appConfigs.tables.pageSize,
//                     applicationId: (applicationId||""),
//                     filters: (filters['status'] ? "" : encodeURIComponent(JSON.stringify(filtersObj)) )
//                 }
//             }
//         )
//         .then(response => { 
//                 if (callbackFnSuccess != null) {
//                     callbackFnSuccess(response.data.data.applications);
//                 }
//                 dispatch({ type: "APPLICATIONS_GET_SUCCESS", payload: {data: response.data.data.applications, page: page}  });
//             }
//         )
//         .catch(function (error) {
//             console.log('error', error)
//             if (callbackFnFailure != null) {
//                 callbackFnFailure(me, error)
//             }
//             errorResponse(me, error);
//         });
//     };
// }


export function getApplications(
    controllerId: string,
    callbackFnFailure: Function,
    callbackFnSuccess?: Function,
) {
    
    return async (dispatch: any) => {

        Axios.get(
            appConfigs.server.URL + "/ui/api/applications", {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: "",
                    page: 1,
                    pageSize: 99999,
                }
            }
        )
        .then(response => { 
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.controllers);
                }
                dispatch({ type: "APPLICATIONS_LIST_GET_SUCCESS", applications: response.data.data.applications  });
            }
        )
        .catch(function (error) {
            console.log('error', error)
            callbackFnFailure(error)
            dispatch({ type: "APPLICATIONS_LIST_GET_SUCCESS", applications: null  });
        });
    };
}


export function getApplicationVersions(
    applicationId:string,
    callbackFnFailure: Function,
    callbackFnSuccess?: Function
) {
    
    return async (dispatch: any) => {

        Axios.get(
            appConfigs.server.URL + "/ui/api/get_application_versions", {
                responseType: "json",        
                headers: {},
                params: {
                    applicationId: applicationId,
                }
            }
        )
        .then(response => { 
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.controllers);
                }
                dispatch({ type: "APP_VERSIONS_GET_SUCCESS", versions: response.data.data.versions  });
            }
        )
        .catch(function (error) {
            console.log('error', error)
            callbackFnFailure(error)
        });
    };
}


export function getInstallationsHistory(
    controllerId:string,
    gatewaysId:string,
    callbackFnFailure: Function,
    callbackFnSuccess?: Function
) {
    
    return async (dispatch: any) => {

        Axios.get(
            appConfigs.server.URL + "/ui/api/controller_installations", {
                responseType: "json",        
                headers: {},
                params: {
                    gatewayId: gatewaysId,
                    controllerId: controllerId,
                }
            }
        )
        .then(response => { 
            console.log(response);
            
                if (callbackFnSuccess != null) {
                    callbackFnSuccess("Installation History Loaded Successfully");
                }
                dispatch({ type: "GET_CONTROLLER_INSTALLATIONS_SUCCESS", installationHistory: response.data.data.controller_installations  });
            }
        )
        .catch(function (error) {
            console.log('error', error)
            callbackFnFailure(error)
        });
    };
}

export function controllersSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "CONTROLLER_SET_SEARCH", searchText: searchText });
    }
}  

export function updateControllerSelection(
    selectedController: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "UPDATE_CONTROLLER_SELECTION", selectedController: selectedController });
    }
}  

export function controllerSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "CONTROLLERS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
} 

export function setTab(
    tabNumber: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "SET_TAB", tabNumber: tabNumber });
    }
}

export function openDrawer(title: string, width: number, record: any) {
	return (dispatch: any) => {
		dispatch({ type: "OPEN_DRAWER", payload: { title: title, width: width, record: record } });
	};
}

export function openInstallationProgressDrawer(title: string, width: number, record: any) {
	return (dispatch: any) => {
		dispatch({ type: "OPEN_INSTALL_PROGRESS_DRAWER", payload: { title: title, width: width, record: record } });
	};
}

export function closeDrawer() {
	return (dispatch: any) => {
		dispatch({ type: "CLOSE_DRAWER" });
	};
}


export function closeInstallationProgressDrawer() {
	return (dispatch: any) => {
		dispatch({ type: "CLOSE_INSTALL_PROGRESS_DRAWER" });
	};
}

export function drawerTabChange(activeTab: string) {
	return (dispatch: any) => {
		dispatch({ type: "DRAWER_TAB_CHANGE", activeTab: activeTab });
	};
}



export function getAllControllersList() {
	return (dispatch: any) => {
		Axios.get(appConfigs.server.URL + "/ui/api/controllers", {
			responseType: "json",
			headers: {},
			params: {
				page: 1,
				pageSize: 9999,
			},
		}).then((response) => {
            let controllers = response.data.data.controllers;
			dispatch({ type: "SET_CONTROLLERS_LIST", payload: { assetsList: controllers } });
		}).catch(function (error) {
            dispatch({ type: "SET_LOADING_CONTROLLERS", payload: { state: false } });
            errorResponse(null, error);
        });
	};
}



export function modifyController(
    selectedControllersToModify: any,
    callbackFnSuccess?: any
) {
    return (dispatch: any) => {
        dispatch({ type: "ADD_CONTROLLER", payload: { state: true } });
		Axios.get(appConfigs.server.URL + "/ui/api/modify_controllers", {
			responseType: "json",
			headers: {},
            params: {
                controllers: encodeURIComponent(JSON.stringify(selectedControllersToModify))
			},
		}).then((response) => {
            if (callbackFnSuccess != null) {
                callbackFnSuccess(response.data.data);
            }
			dispatch({ type: "ADD_CONTROLLER_SUCCESSFULLY" });
		}).catch(function (error) {
            dispatch({ type: "ADD_CONTROLLER", payload: { state: false } });
            console.log('error', error)
            errorResponse(null, error);
        });
	};
}