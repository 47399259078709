import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function acceptTermsAndConditions(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string,
    templateOrgId: string
) {
    
    return async (dispatch: any) => {        
        Axios.put(
            appConfigs.server.URL +  "/ui/api/users/terms-and-conditions?templateId=" + templateId + "&templateOrgId=" + templateOrgId,
            {},
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess();
                }
            }
        )
        .catch(function (error) {
            errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
    }
}