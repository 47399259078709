import React from "react";
import { Drawer, Button, Tabs, Space, Form, Radio, Input, Table } from "antd";
import * as actions from "./actions";
import { connect } from "react-redux";
import { UnorderedListOutlined } from "@ant-design/icons";

const { Search } = Input;

interface Props{
    fenceList: any[],
    fenceListDrawerVisible: boolean,
    fenceTableList: any[],
    map: google.maps.Map,
    closeFenceListDrawer: Function,
    openDrawer:Function,
    openFenceAssetsDrawer:Function,
    setFenceMastertags:Function,
    changeOnSearch:Function
}


const FenceListDrawer = (props: Props) => {

    const close = () => {
        props.closeFenceListDrawer();
    }

    const onClickListIcon = (record:any) => {

        //props.closeFenceListDrawer();
        props.openFenceAssetsDrawer();
        props.setFenceMastertags(record.mastertags, record.fenceName, record.latestPositionStatuses);
       
    }

    const onFenceNameClicked = (record: any) => {
        
        let shape = null

        if(record.type === "circle"){
            shape = record.shape as google.maps.Circle;
            props.map.setZoom(props.map.getZoom() + 1)
            props.map.fitBounds(shape.getBounds());
        }else if(record.type === "rectangle"){
            shape = record.shape as google.maps.Rectangle;
            props.map.setZoom(props.map.getZoom() + 1)
            props.map.fitBounds(shape.getBounds());
        } else if (record.type === "polygon") {
            shape = record.shape as google.maps.Polygon;
            let bounds = getBoundsForPolygon(shape as google.maps.Polygon);
            if (bounds !== null) {
                props.map.setZoom(props.map.getZoom() + 1)
                props.map.fitBounds(bounds);
            }
        }

        props.openDrawer(record);
        props.closeFenceListDrawer();
    }

    const getBoundsForPolygon = (polygon:google.maps.Polygon) => {
       
        var arr = polygon.getPath();
        var minLat, minLng, maxLat, maxLng = undefined;

        for(let i = 0; i < arr.getLength(); i++){

            let lat = arr.getAt(i).lat();
            let lng = arr.getAt(i).lng();

            minLat = (minLat === undefined || minLat > lat) ? lat : minLat;
            minLng = (minLng === undefined || minLng > lng) ? lng : minLng;
            maxLat = (maxLat === undefined || maxLat < lat) ? lat : maxLat;
            maxLng = (maxLng === undefined || maxLng < lng) ? lng : maxLng;
        }

        if(minLat && minLng && maxLat && maxLng){
            let southWest = new google.maps.LatLng(minLat, minLng);
            let northEast = new google.maps.LatLng(maxLat, maxLng);
            return new google.maps.LatLngBounds(southWest, northEast);
        }

        return null;
    }



    const onSearch = (value:string) => {
        props.changeOnSearch(value);
    }

    const onChange = (event:any) => {
        let target = event.target;
        if(target){
            props.changeOnSearch(target.value);
        }
    }


    const columns = [
        {
            title:'Name',
            dataIndex:'fenceName',
            key: 'name',
            sorter: (a:any, b:any) => { return a.fenceName.localeCompare(b.fenceName)},
            render:(text:any, record:any) => (
                <a onClick={() => {onFenceNameClicked(record)}}> {record.fenceName}</a>
            )
        },  
        {
            title: 'Assets Status',
            dataIndex: 'fenceName',
            key: 'assets',
            render:(text:any, record:any) => (
                <Button shape="circle-outline" onClick={() => onClickListIcon(record)} icon={ <UnorderedListOutlined />}></Button>
            )
        } 
    ];

    
    return (
        
            <Drawer  data-id="user-fencelist-drawer" 
                visible={props.fenceListDrawerVisible}
                title="Geofences List"
                width={"450px"}
                destroyOnClose={true}
                onClose={close}
                zIndex={3}
            >
                {
                    <Search
                        data-id="assets-maint-subscriptions-search-field"
                        placeholder="Search by Name"
                        enterButton="Search"
                        size="middle"
                        allowClear
                        onSearch={onSearch}
                        onChange={onChange}
                        style={{marginBottom: 20, width: '100%'}}
                    /> 
                }
                {
                    <Table dataSource={props.fenceTableList} columns={columns} rowKey={(record) => record.id} />
                }


            </Drawer>
    
    );

     
}

const mapStateToProps = (state:any) => {

    return {
        fenceList:  state.geofencesReducer.geofencesState.geofencesList.fenceList,
        fenceListDrawerVisible: state.geofencesReducer.geofencesState.geofencesList.fenceListDrawerVisible,
        map: state.geofencesReducer.geofencesState.geofencesList.map,
        fenceTableList: state.geofencesReducer.geofencesState.geofencesList.fenceTableList
    }

}


export default connect(
    mapStateToProps,
    actions
)(FenceListDrawer);
