import { appConfigs } from "../../../../utils/configurations";

export const initialState = {
    contracts: {
        contractsModification: {
            assetsList: null,
            loadingAssetsList: true,
            selectedContractList: [] as  any,
            submittingContractList: false,
        },
        renewalTool: {
            assetsList: null,
            loadingAssetsList: true,
            selectedRenewalList: [] as  any,
            submittingRenewalList: false,
        },
        billableCustomers: [],
        servicePlansList: [],
        customerBucketDetails: {},
        contractMaintOptions: {
            instance: 0,
            width: 720,
            visible: false,
            title: "",
            record: {},
            activeTab: "1",
            tabs: [
                {
                    title: "Contract Details",
                    instance: 0,
                    disabled: false,
                },
                {
                    title: "Change Log",
                    instance: 0,
                    disabled: false,
                },
            ]
        },
        activeTab: "1",
        contractsList: {
            contactMaint: {
                instance: 0,
                width: 720,
                visible: false,
                record: {},
                title: "",
                activeTab: "1",
                tabs: [
                    {
                        title: "Contract Details",
                        disabled: false,
                    },
                    {
                        title: "Contract Log",
                        disabled: false,
                    }
                ]
            },
            searchText: "",
            refreshOnUpdate: false,
            columnsFilters: {
                status: ['active', 'inactive', 'whitelisted']
            },
            tableData: [],
            tableConfig: {
                pagination : {                  
                    showSizeChanger: appConfigs.tables.showSizeChanger,
                    showQuickJumper: appConfigs.tables.showQuickJumper,
                    pageSize: appConfigs.tables.pageSize,
                    defaultCurrent: appConfigs.tables.defaultCurrent,
                    current: appConfigs.tables.defaultCurrent,
                    total: appConfigs.tables.defaultTotal,
                    hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
                    responsive: appConfigs.tables.responsive,
                    onChange: null,
                    showLessItems: appConfigs.tables.showLessItems,  
                    showTotal: (total:number, range:Array<number>) => `${range[0]} - ${range[1]} of ${total} contract${total > 1 ? 's' : ''}`
                }
            },
        }
    }
}