import Axios from "axios";
import { Asset, C2DWlist, DBWidgetConfig, TextMessage} from "./models";
import { DbLayoutConfig } from "./state";
import { constructAssetData } from "./AssetApiResp";
import { WidgetConfig } from "./widgets";
import { WidgetFilterConfig } from "./WidgetFilterForm";
import { appConfigs } from "../../utils/configurations";
import { constructUrl } from "./WidgetFilterForm/FilterApi";
import { Moment } from "moment";
import { hexToString, loadingIndicatorStart } from "../../utils/commonHelpers";
import { addUrlParams } from "./WidgetFilterForm/FilterApi";

export interface AssetDashboardResponse {
  asset: Asset;
}
interface DbSignal {
  _id: string;
  unit: string;
  name: string;
  label: string;
  pgn: string;
  spn: string;
  format: string;
}

export interface Datapoint {
  _id: string;
  type: string;
  name: string;
  values: DbSignal[];
}

export async function loadAssetDashboard(
  mastertag: string,
  mode: string
): Promise<AssetDashboardResponse> {
  const { data } = await Axios.get(
    `${appConfigs.server.URL}/ui/api/assets?mastertag=${mastertag}&allData=true`,
    {
      params: {
        alertInterval: appConfigs.app.assetOverview.alertsInterval,
        mode: mode,
      },
    }
  );
  return constructAssetData(data, mastertag);
}

export async function loadSignalsData(
  gatewayId: string,
  signalCollectionId: string[],
  filterConfig?: WidgetFilterConfig,
  type?: WidgetConfig["type"]
  //filterTransients?: boolean
): Promise<any> {
  let promiseArray = signalCollectionId
    .map((datapoint) =>
      Axios.get(constructUrl(gatewayId, datapoint, filterConfig, type))
    )
    .map((p) => p.catch((e) => e));
  return Promise.all(promiseArray);
}

function formatD2CText(v: string): string {
  var msg = hexToString(v);
  // remove the deliminator from the single/multi text frame
  const lastFrameStartPos = msg.length - 9;
  const delimiterPos = msg.indexOf("*", lastFrameStartPos);
  msg = msg.substring(1, delimiterPos);
  return msg;
}

export async function loadC2DMessageData(
  gatewayId: string,
  filterConfig?: WidgetFilterConfig
): Promise<TextMessage[]> {
  let c2dMsg: TextMessage[] = [];
  try {
    let msgData = await Axios.get(
      addUrlParams(
        `${appConfigs.server.URL}/ui/api/assets/${gatewayId}/c2d-messages`,
        filterConfig
      )
    );
    msgData?.data?.data?.messages.forEach((m: any, index: number) => {
      c2dMsg.push({
        signalId: m.signalId,
        firstName: m.firstName,
        lastName: m.lastName,
        type: m.messageType,
        timestamp: new Date(m.messageTime),
        text: m.messageText,
      });
    });
    return c2dMsg;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export async function loadC2DWhitelistData(
	gatewayId: string,
	filterConfig?: WidgetFilterConfig
): Promise<C2DWlist[]> {
	let c2dwhtlst:C2DWlist[] = [];	
	try {
		 let msgData = await Axios.get(addUrlParams(`${appConfigs.server.URL}/ui/api/assets/${gatewayId}/c2d-messages`, filterConfig));
		 msgData?.data?.data?.messages.forEach( (m: any, index: number) => {
			c2dwhtlst.push({
				whitelistMessages:m.whitelistMessages
			});
		})
		return  c2dwhtlst;
	} catch (e) {
		console.log(e)
		throw e;
	}

}

export async function loadSignalsDataTimescale(
  interval: string,
  reading: string,
  signalsNCollectionId: string[],
  startDate: Moment,
  endDate: Moment,
  type: string,
  customFilters: string[],
  requiredSignals: string[]
): Promise<any> {
  let promiseArray = signalsNCollectionId
    .map(async (dataPoint) => {
      let signalDetails = dataPoint.split("~");
      return await Axios.get(
        `${appConfigs.server.URL}/ui/api/timescale/${signalDetails[0]}/${
          signalDetails[1]
        }/${signalDetails[2]}/${type}?startDate=${startDate.valueOf()}&endDate=${endDate.valueOf()}&interval=${interval}&reading=${reading}&customFilters=${customFilters}&requiredSignals=${requiredSignals}`
      );
    })
    .map((p) =>
      p
        .then((resp: any) => {
          loadingIndicatorStart();
          return resp;
        })
        .catch((e) => e)
    );
  return Promise.all(promiseArray);
}

export function saveWidget(
  templateId: string,
  widgetDetails: DBWidgetConfig,
  dashboardId: number
): Promise<any> {
  return Axios.put(
    `${appConfigs.server.URL}/ui/api/assets/templates/${templateId}/dashboards/${dashboardId}/widget`,
    widgetDetails,
    {
      responseType: "json",
    }
  );
}

export function deleteWidget(
  templateId: string,
  widgetId: string,
  dashboardId: number
): Promise<any> {
  return Axios.delete(
    `${appConfigs.server.URL}/ui/api/assets/templates/${templateId}/dashboards/${dashboardId}/widget/${widgetId}`
  );
}

export function saveLayoutChange(
  templateId: string,
  layout: (DbLayoutConfig & { _id: string })[],
  dashboardId: number
): Promise<any> {
  return Axios.put(
    `${appConfigs.server.URL}/ui/api/assets/templates/${templateId}/dashboards/${dashboardId}/widget/layout`,
    layout,
    {
      responseType: "json",
    }
  );
}

export function getAlerts(mastertag: string): Promise<any> {
  const dmAlertsUrl = Axios.get(
    `${appConfigs.server.URL}/ui/api/alerts/dm1?mastertag=${mastertag}`
  );
  const restAlertsUrl = Axios.get(
    `${appConfigs.server.URL}/ui/api/alerts/signals?mastertag=${mastertag}`
  );
  return Promise.all([dmAlertsUrl, restAlertsUrl]);
}

export function csvExportApi(url: string): Promise<any> {
  return Axios.get(url);
}

export function getDashboard(mastertag: string, dashboardId: number) {
  return Axios.get(
    `${appConfigs.server.URL}/ui/api/assets/${mastertag}/dashboards/${dashboardId}`
  );
}

export function invokeCommand(
  gatewayId: string,
  commandId: string,
  commandInfo: string,
  testMode: number
): Promise<any> {
  const data = { desiredPayload: commandInfo };
  return Axios.put(
    `${appConfigs.server.URL}/ui/api/controllers/${gatewayId}/commands/${commandId}?directMethodTestMode=${testMode}`,
    data, {
      headers: {
          'Content-Type': 'application/json; charset=utf-8'
      }
    }
  );
}

export function getCommandStatus(
  gatewayId: string,
  commandId: string
): Promise<any> {
  return Axios.get(
    `${appConfigs.server.URL}/ui/api/controllers/${gatewayId}/command-status/${commandId}`
  );
}

export function getCommandResponseSignalIds(
  templateId: string,
  commandId: string
): Promise<any> {
  return Axios.get(
    `${appConfigs.server.URL}/ui/api/signals/template/${templateId}/command-response-signals/${commandId}`
  );
}
