import React from "react";
import { Table, Checkbox, Space, Tooltip, Input, Tree } from 'antd';
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import * as actions from "../actions";
import { array } from "@amcharts/amcharts4/core";

interface Prop{    
    context: any,
    orgsList: any,
    orgsGlobalSubscriptionsGet: Function,
    orgsGlobalSubscriptionsMaint: Function,
    orgsListRefreshOnUpdate: Function,
    iscreate: boolean,
    isread: boolean,
    isupdate: boolean
   
}

interface State{
    expandedKey:any;
}

class OrgMaintGlobalSubscription extends React.Component<Prop,State> {

    state: State = {
        expandedKey:[]
    }

    componentDidMount() {
        const { appContext } = this.props.context;
        this.props.orgsGlobalSubscriptionsGet(this, this.onGlobalSubscriptionSuccess, null, this.props.orgsList.orgMaint.record.orgId, appContext.userId, this.props.orgsList.searchText);

        //this.props.orgsGlobalSubscriptionsGet(this,this.onGlobalSubscriptionSuccess, null,this.props.orgsList.orgMaint.record.orgId, appContext.userId, parentOrgId, this.props.orgsList.searchText);
        
    }

    onGlobalSubscriptionSuccess=(data:any)=>{
        
        let expandKeys:Array<any>=[];
        expandKeys.push(data[0].expandedKey);
        this.setState({expandedKey:expandKeys});
    }

    
    onCheckChange = (e:any, record:any, notification: string, scope: string) => {       
        let action = e.target.checked ? "plus" : "minus";
        let trigger = record.name;        
        this.props.orgsGlobalSubscriptionsMaint(this, this.onCheckChangeSuccess, this.onCheckChangeFailure, this.props.orgsList.orgMaint.record.orgId, record, notification, action, scope);
    }
    onCheckChangeSuccess = (userId:string, record:any, notification: string, action: string, scope:string) => {   

        //this.props.orgsListRefreshOnUpdate(this);
        //const { appContext } = this.props.context; 
        //let parentOrgId = this.props.orgsList.orgMaint.record.orgIdParent == null ? this.props.orgsList.orgMaint.record.orgId : this.props.orgsList.orgMaint.record.orgIdParent;
        //this.props.orgsGlobalSubscriptionsGet(this,this.onGlobalSubscriptionSuccess, null,this.props.orgsList.orgMaint.record.orgId, appContext.userId, parentOrgId, this.props.orgsList.searchText);
       
    };

    onCheckChangeFailure = (error:string, userId:string, record:any, notification: string, action: string, scope:string) => {   
        
    }  
    
     onSelect = (selectedKeys: React.Key[], info: any) => {
        // console.log('selected', selectedKeys, info);
      };
    
      onCheck = (checkedKeys: React.Key[], info: any) => {
        // console.log('onCheck', checkedKeys, info);
      };

   

    actions = (record:any) => (
        <>
        <Space size="large">
            <Tooltip title="Set/Unset SMS for all global alerts">
                <Checkbox data-id="org-maint-global-subscriptions-sms-all" key={record.key} onChange={(e) => this.onCheckChange(e, record, "sms", "alert")}  checked={record.smsChecked}><PhoneOutlined /></Checkbox>
            </Tooltip>
            <Tooltip title="Set/Unset email for all global alerts">
                <Checkbox data-id="org-maint-global-subscriptions-email-all" key={record.key} onChange={(e) => this.onCheckChange(e, record, "email", "alert")} checked={record.emailChecked}><MailOutlined /></Checkbox>
            </Tooltip>
        </Space>
      
      {/* Below lines are commented for not to show parent/child records. */}
        {/* {record.children &&
                <Space size="large">
                    <Tooltip title="Set/Unset SMS for all global alerts">
                        <Checkbox data-id="org-maint-global-subscriptions-sms-all" key={record.key} onChange={(e) => this.onCheckChange(e, record, "sms", "alert")} indeterminate={record.smsIndeterminate} checked={record.smsChecked}><PhoneOutlined /></Checkbox>
                    </Tooltip>
                    <Tooltip title="Set/Unset email for all global alerts">
                        <Checkbox data-id="org-maint-global-subscriptions-email-all" key={record.key} onChange={(e) => this.onCheckChange(e, record, "email", "alert")} indeterminate={record.emailIndeterminate} checked={record.emailChecked}><MailOutlined /></Checkbox>
                    </Tooltip>
                </Space>
        } */}
        {/* {!record.children  &&
            <div>
            <Space size="large">
                <Checkbox data-id="org-maint-subscriptions-sms" key={record.key} onChange={(e) => this.onCheckChange(e, record, "sms", "alert")} checked={record.alertSms}><PhoneOutlined /></Checkbox>

                <Checkbox data-id="org-maint-subscriptions-email" key={record.key} onChange={(e) => this.onCheckChange(e, record, "email", "alert")} checked={record.alertEmail}><MailOutlined /></Checkbox>
            </Space>
            </div>
        }
             */}
            
        </>
    )

    handleExpand(record:any) {
        // if a row is expanded, collapses it, otherwise expands it
        this.setState(prevState =>
          prevState.expandedKey.includes(record.key)
            ? {
                expandedKey: prevState.expandedKey.filter((key:any)=>
                    key !== record.key
                )
              }
            : { expandedKey: [...prevState.expandedKey, record.key] }
        );
    }
    render(){
        
        const { tableData } = this.props.orgsList.orgMaint.tabs[3];
 
        const treeData =[
            {
                title: "alertName",
                dataIndex: "alertName",
                key: "alertName",
                render: (text: any, record: any) => (     
                    <>               
                        {record.children &&
                            <strong>{record.name}</strong>
                        }
                        {!record.children && record.signalId === "" &&
                            <i>{record.name}</i>
                        }
                        {!record.children && record.signalId !== "" &&
                            <span>{record.name}</span>
                        }
                    </>
                )
            },
            {
                title: "Subscribed",
                dataIndex: "subscribed",
                width: "30%",
                key: "subscribed",
                align: "center" as "center",
                render: (text: any, record: any) => (                    
                    this.actions(record)
                )
            },
            
        ]
        const columns = [
            {
                title: "Subscription",
                dataIndex: "name",
                key: "name"            ,
                render: (text: any, record: any) => (     
                    <>
                    <strong>{record.name}</strong>               
                        {/* {record.children && 
                            <strong>{record.name}</strong>
                        }
                        {!record.children &&
                            <i>{record.name}</i>
                        } */}
                    </>
                )
            },
            {
                title: "Subscribed",
                dataIndex: "subscribed", 
                width: "30%",
                key: "subscribed",
                align: "center" as "center",
                render: (text: any, record: any) => (                    
                   this.actions(record) 
                )
            }
        ];
        
        return(
            <>  
               {/* <Table 
               data-id="org-maint-global-subscriptions" 
               columns = { columns }
               dataSource = { tableData }
               onExpand={(expanded, record) => this.handleExpand(record)}
               expandable={{
                expandedRowKeys: this.state.expandedKey,
               }}
               />  */}

            <Table 
               data-id="org-maint-global-subscriptions" 
               columns = { columns }
               dataSource = { tableData }            
               />
            </>
        )       
        
    }

}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        orgsList: state.orgsReducers.orgsState.orgsList
    };
};

export default connect(
    mapStateToProps,
    actions
)(OrgMaintGlobalSubscription);