import React from "react";
import { Card, Descriptions, Switch, Tag, Empty, Tooltip, Button, Space, Pagination, Input, Popconfirm, message } from "antd";
import { EditOutlined, DeleteOutlined, EyeInvisibleFilled, YahooFilled  } from "@ant-design/icons";

import { connect } from "react-redux";
import * as actions from "../actions";
import history from '../../../utils/history';
import { closePopover, numberZeroPositive } from "../../../utils/commonHelpers";
import { appConfigs } from "../../../utils/configurations";

import "./signals.css";

const { Search } = Input;

interface Prop {
    signalListSetPagination: Function,
    signalListSetSearch: Function,
    getSignal: Function,
    getSignals: Function,   
    signalDelete: Function,
    signalMaintOpen: Function,
    getTemplate: Function,
    signals: any,
    signalList: any,
    templateDetails: any,
    iscreateOrUpdateTemplate: boolean,
    isread: boolean,
    isdelete: boolean,
    iscreateRules: boolean,
    isreadRules: boolean,
    isupdateRules: boolean,
    isdeleteRules: boolean,
    isGlobalTemplate: boolean
    context: any
}

class SignalList extends React.Component<Prop, {}> {  
   
    drawerWidth:number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth) - (window.innerWidth > 575 ? 100 : 0);
    descriptionSpan:number = window.innerWidth > 700 ? 0 : 1;
    
    getTemplateId() {
        const pathnameArr = history.location.pathname.split("/");
        return pathnameArr[pathnameArr.length - 1];
    }

    capitalizeFirstLetter(str: string) {
        if (!str) {
            return str;
        } else {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
           
      }

    componentDidMount() {
        this.props.signalListSetPagination(this.onPaginationChange);
        this.componentUpdate(1);
    }

    componentDidUpdate(prevProps: Prop) {
        if (this.props.signals.instance !== prevProps.signals.instance) {
            this.componentUpdate(1);
        }
    }

    componentUpdate(pageNumber: number) {
        this.props.getSignals(this, this.componentUpdateSuccess, null, this.getTemplateId(), this.props.signals.signalCollectionId, this.props.signals.signalCollectionName, this.props.signals.signalCollectionType, pageNumber, this.props.signalList.searchText);
    };

    componentUpdateSuccess = (signals: any) => {  
        if ((signals.length || 0) === 0 && (this.props.signalList.searchText || "") === "") {
            this.props.signalMaintOpen(this, this.drawerWidth, {}, this.props.signals.signalCollectionType);
        }
    }

    onPaginationChange = (pageNumber: number) => {
        this.componentUpdate(pageNumber);
    };

    onSearch = (searchText: string) => {   
        let me = this;

        this.props.signalListSetSearch(searchText || "");    

        setTimeout(function(){
            me.componentUpdate(1);
        }, 100);      
    }

    onSearchChange = (e: any) => {
        let value = e.target.value;
        this.props.signalListSetSearch((value || ""));
    }

    onAction = (e:any, record:any, action:string) => {
        e.preventDefault();

        if ( action === "delete" ) {
            this.props.signalDelete(this, this.onSignalDeleteSuccess, null, this.getTemplateId(), record.signalId);
        }
        else if ( action === "edit" ) {
            this.props.getSignal(this, this.onGetSignalSuccess, null, this.getTemplateId(), record.signalId);
        }
    }

    onGetSignalSuccess = (record:any) => {
        this.props.signalMaintOpen(this, this.drawerWidth, record, this.props.signals.signalCollectionType);
    }

    onSignalDeleteSuccess = (signalId:string) => {
        message.success("Signal has been successfully deleted");        
        this.props.getSignals(this, this.onSignalDeleteSuccess2, null, this.getTemplateId(), this.props.signals.signalCollectionId, this.props.signals.signalCollectionName, this.props.signals.signalCollectionType, this.props.signalList.tableConfig.pagination.current, this.props.signalList.searchText);
        this.props.getTemplate(this, null, null, this.getTemplateId());
    }

    onSignalDeleteSuccess2 = (signalId:string) => {
        if ((this.props.signalList.tableData.length || 0) === 0) { 
            if (this.props.signalList.tableConfig.pagination.current !== 1) {
                this.componentUpdate(this.props.signalList.tableConfig.pagination.current - 1);
            }
        }
    }
    
    confirmDeletion = (record:any) => (
        <Popconfirm
            title={"Are you sure to delete this signal?"}
            onCancel={closePopover}
            onConfirm={(e) => {this.onAction(e, record, "delete")}}
            okText="Yes"
            cancelText="No"
        >
            <Tooltip title="Delete Signal">            
                <Button data-id="signal-list-signal-delete-button" type="default" icon={<DeleteOutlined />} />
            </Tooltip>
        </Popconfirm>
    );

    closePopover = () => (
        document.dispatchEvent(
            new MouseEvent("mousedown", {
                bubbles: true,
                view: window
            })
        )
    );

    getSignalName(signalid:string){
        let signalName = "";
        const { templateRecord } = this.props.templateDetails;

        if(this.props.templateDetails.templateRecord.datapoints.length > 0){      
            let commands = JSON.parse(this.props.templateDetails.templateRecord.datapoints)
                                .filter((t:any) => t.type && t.type === "Command");  
        commands.map((collection:any) => {            
            let signals=collection.values;
            if(!signalName || signalName === ""){
            signals.map((signal:any) => {            
                if(signalid === signal._id && templateRecord.orgCmdPgnSpns.includes(signal.pgn || '- || signal.spn')){
                    signalName = signal.label;
                }
                });
            }
        });
        }
        return signalName ;
    }

    
    doesOrgSupportCommandSignal(signalid:string){
        var supported = false; 
        let contextOrgCmdPgnSpns = this.props.templateDetails.templateRecord.orgCmdPgnSpns === null? '' :
            this.props.templateDetails.templateRecord.orgCmdPgnSpns;
        if (contextOrgCmdPgnSpns === '-') {
            supported= false;
            return supported;
        }
        if(this.props.templateDetails.templateRecord.datapoints.length > 0){      
            let commands = JSON.parse(this.props.templateDetails.templateRecord.datapoints)
                                .filter((t:any) => t.type && t.type === "Command");  
        commands.map((collection:any) => {            
            let signals=collection.values;
            signals.map((signal:any) => {            
                if(signalid === signal._id){
                    if (contextOrgCmdPgnSpns.includes((signal.pgn === null? '0':signal.pgn) + "-" + (signal.spn===null? '0' : signal.spn))) {
                        supported = true; 
                    }
                }
                });
            }
        
        );
        }
        return supported ;
    }
    
    getLeftMergeRuleName=( (record:any) => {
        let leftMergeRuleName = "";

        if (record.mergeRule && record.signalRule && record.offlineRule ) {
             leftMergeRuleName = (record.mergeRule.leftMergeRule === record.signalRule.ruleId )?
             record.signalRule.ruleName : record.offlineRule.ruleName
          
        }
       return leftMergeRuleName; 
    });

    getRightMergeRuleName =( (record:any) => {
        let rightMergeRuleName = "";

        if (record.mergeRule && record.signalRule && record.offlineRule ) {
            rightMergeRuleName = (record.mergeRule.rightMergeRule === record.signalRule.ruleId )?
             record.signalRule.ruleName : record.offlineRule.ruleName; 
        }
       return rightMergeRuleName; 
    });

    isLeftOffline =( (record:any) => {
        let isLeftOffline = false; 

        if (record.mergeRule && record.signalRule && record.offlineRule ) {
            isLeftOffline = record.mergeRule.leftMergeRule === record.offlineRule.ruleId;
        }
       return isLeftOffline; 
    });

    render() {
        
        const { templateRecord } = this.props.templateDetails;
        const contextOrgCmdPgnSpns = templateRecord.orgCmdPgnSpns === null? '' : templateRecord.orgCmdPgnSpns;
        return (
            <>
                {
                    ((this.props.signalList.tableData.length || 0) > 0 || ((this.props.signalList.tableData.length || 0) === 0 && this.props.signals.signalList.searchText) && this.props.isread) &&
                    <Search
                        data-id="signal-list-search-field"
                        placeholder="Search by signal name"
                        enterButton="Search"
                        size="middle"
                        allowClear
                        onSearch={this.onSearch}
                        onChange={this.onSearchChange}
                        style={{marginBottom: 20, width: '100%'}}
                        value={this.props.signalList.searchText}
                    /> 
                } 
                {
                    (this.props.signalList.tableData.length || 0) > 0 &&
                    <Card style={{ marginBottom: 15, border:"0px"}}
                            data-id="signal-signal-card"
                            size="small"     
                    >
                    <Pagination 
                        {...this.props.signalList.tableConfig.pagination}
                        style={{ float: 'right'}}
                        onChange={this.onPaginationChange}
                    />
                    </Card>
                } 
                {
                    (this.props.signalList.tableData.length || 0) === 0 &&
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
                {
                    this.props.signalList.tableData.map((record:any, index:number) => (
                        <Card 
                            data-id="signal-list-signal-card"
                            key={"signal-" + record.signalId}
                            size="small" 
                            title={
                                <>
                                    { /* read or modify rules or signal */
                                        
                                       (   (    (templateRecord.templateVisibleToSubOrg && this.props.context.appContext.roleName.toLowerCase() === "admin") ||
                                                (!templateRecord.templateVisibleToSubOrg && (this.props.iscreateOrUpdateTemplate || this.props.isupdateRules || this.props.isread ||  this.props.isreadRules 
                                                    || this.props.iscreateRules || this.props.isdeleteRules) )
                                            ) &&
                                            (   record.signalCollectionType !== "Command" ||
                                                (record.signalCollectionType === "Command" && 
                                                   contextOrgCmdPgnSpns.includes((record.pgn===null ? '0': record.pgn) + '-' + (record.spn===null? '1':record.spn))
                                                )
                                            ) 
                                        ) && 
                                             //A user with an admin role can delete a collection for a global template defined in the current org, but is visible to suborg
                                             // however, an admin role in suborg only, who is viewing the global template defined in the prevous org yet the local suborg does not grant the permission for command
                                             // cannot delete the global template's command collection
                                             // TBD OPS-3092 it seems there is a defect, that an admin role in the suborg only can delete the generic collection of global template defined in the parent org 
                                             // i.e, if Parker make a global template availble to the suborg, the suborg's admin can delete the collection of the Parker's global template
                                             // UI and db function may need a new flag isContextOrgSameAsTemplateOrg
                                             // note; contextOrgCmdPgnSpns means support the command in the local suborg even though the template is defined at parent org
                                 
                                           // a user with delete template permission can delete generic or command collection if the org has permission for command.
                                           // TBD OPS-3092 it seems there is a defect that a user cannot delete a GPS or IAQN diagnostic collection
                                           // UI and db function need to check a new flag isContextOrgSameAsTemplateOrg
                                                         
                                       <Tooltip title="Edit Signal">
                                            <>
                                                <span className="pr-5">Signal Name:</span>
                                                <Tooltip title="Edit Signal">
                                                    <a href="# " onClick={(e) => {this.onAction(e, record, "edit")}}>
                                                        {record.label} 
                                                    </a>
                                                </Tooltip>                                                                    
                                            </>
                                        </Tooltip>
                                    }

                                    { // global templates supports more command pgns/features than the suborg
                                      // or rare case disable the org permission on command control after previously enable it
                                        (   (   (templateRecord.templateVisibleToSubOrg && this.props.context.appContext.roleName.toLowerCase() === "admin") ||
                                                (!templateRecord.templateVisibleToSubOrg && (this.props.iscreateOrUpdateTemplate || this.props.isupdateRules || this.props.isread ||  this.props.isreadRules 
                                                    || this.props.iscreateRules || this.props.isdeleteRules) 
                                                )
                                            ) &&
                                            (  (record.signalCollectionType === "Command" && 
                                                !contextOrgCmdPgnSpns.includes((record.pgn === null ? '0' : record.pgn )+ '-' + (record.spn===null? '1': record.spn))
                                               )
                                            ) 
                                        ) && 
                                        <>
                                            <EyeInvisibleFilled className="invisible-signal"/>
                                            &nbsp;
                                            <Tooltip title="Command Not Applicable to the Org">
                                            <a href = "#" style={{ backgroundColor:'#EBEBEB',  color: '#A6A6A6', textDecorationLine: 'line-through' }}>Signal Name: {record.label}  </a>
                                            </Tooltip>  
                                        </>

                                    }
                                    { 
                                       ( (templateRecord.templateVisibleToSubOrg && this.props.context.appContext.roleName.toLowerCase() !== "admin") ||
                                         (!templateRecord.templateVisibleToSubOrg && !this.props.iscreateOrUpdateTemplate && 
                                            !this.props.isupdateRules && !this.props.iscreateRules && !this.props.isreadRules && !this.props.isdeleteRules) 
                                        ) && 
                                            <>
                                            <span className="pr-5">Signal Name:</span>
                                            <span>{record.label} </span>
                                            </>
                                    }
                                </>

                            } 
                            style={{ marginBottom: 15}}
                            className={record.signalCollectionType === "GPS" && !(record.signalRule && (numberZeroPositive(record.signalRule.criticalLowValue) || numberZeroPositive(record.signalRule.criticalHighValue) || numberZeroPositive(record.signalRule.warningLowValue) || numberZeroPositive(record.signalRule.warningHighValue))) ? "gps-card-body" : "" }
                            extra={
                                <>
                                    
                                    <Space size="middle" style={{ float: 'left' }}>
                                        {                                    
                                            <>
                                                {
                                                    (  (templateRecord.templateVisibleToSubOrg && this.props.context.appContext.roleName.toLowerCase() === "admin" &&
                                                          (record.signalCollectionType !== "Command" ||
                                                            (record.signalCollectionType === "Command" && 
                                                                  contextOrgCmdPgnSpns.includes((record.pgn===null? '0':record.pgn) + '-' + (record.spn===null? '1':record.spn))
                                                            )
                                                          ) 
                                                       ) 
                                                       || 
                                                       (!templateRecord.templateVisibleToSubOrg && record.signalCollectionType !== "GPS" && record.signalCollectionType !== "IQAN Diagnostics" &&
                                                         (record.signalCollectionType !== "Command" || 
                                                          (record.signalCollectionType === "Command" && (contextOrgCmdPgnSpns === null || record.pgn === null || contextOrgCmdPgnSpns.includes(record.pgn)))
                                                         ) &&
                                                          this.props.isdelete
                                                       )
                                                    ) &&
                                                    this.confirmDeletion(record)
                                                } 
                                                {
                                                    ( ( (templateRecord.templateVisibleToSubOrg && this.props.context.appContext.roleName.toLowerCase() === "admin")  ||
                                                        (!templateRecord.templateVisibleToSubOrg &&
                                                          (this.props.iscreateOrUpdateTemplate || this.props.isupdateRules || this.props.isread ||  this.props.isreadRules 
                                                           || this.props.iscreateRules || this.props.isdeleteRules)
                                                        )
                                                      ) &&
                                                      (  record.signalCollectionType !== "Command" ||
                                                         (record.signalCollectionType === "Command" && 
                                                              contextOrgCmdPgnSpns.includes((record.pgn===null? '0': record.pgn) + '-' + (record.spn===null? '1': record.spn))
                                                         )
                                                      )  
                                                    ) &&
                                                    <Tooltip title="Edit Signal">
                                                        <Button data-id="signal-list-signal-edit-button" type="default" icon={<EditOutlined />} onClick={(e) => {this.onAction(e, record, "edit")}}/>
                                                    </Tooltip>
                                                }                                       
                                                
                                            </>
                                        }
                                    </Space>
                                    
                                </>
                            }
                        >
                            {
                                (record.signalCollectionType === "none" || record.signalCollectionType === "Command") &&
                                <Descriptions bordered column={2}>
                                    <Descriptions.Item label="PGN" span={1 + this.descriptionSpan}>{record.pgn}</Descriptions.Item>
                                    <Descriptions.Item label="PGN Name" span={1 + this.descriptionSpan}>{record.pgnName}</Descriptions.Item>
                                    
                                    {
                                        (record.signalCollectionType === "none" && record.requestPgn && record.requestInterval) &&                                                                          
                                        <Descriptions.Item label="PGN Interval" span={1 + this.descriptionSpan}>{record.requestInterval}</Descriptions.Item>
                                    }
                                    { 
                                        (record.signalCollectionType === "none" && record.requestPgn && record.pgnDestinationAddress) &&
                                        <Descriptions.Item label="Source Address" span={1 + this.descriptionSpan}>{record.pgnDestinationAddress}</Descriptions.Item>                                        
                                    }
                                    { 
                                        (record.signalCollectionType === "none" && record.requestPgn && record.pgnSourceAddress) &&
                                        <Descriptions.Item label="Destination Address" span={1 + this.descriptionSpan}>{record.pgnSourceAddress}</Descriptions.Item>                                        
                                    }

                                    <Descriptions.Item label="SPN" span={1 + this.descriptionSpan}>{record.spn}</Descriptions.Item>
                                    <Descriptions.Item label="SPN Name" span={1 + this.descriptionSpan}>{record.spnName}</Descriptions.Item>

                                    <Descriptions.Item label="Bit Length" span={1 + this.descriptionSpan}>{record.bitLength}</Descriptions.Item>
                                    <Descriptions.Item label="First Bit" span={1 + this.descriptionSpan}>{record.firstBit}</Descriptions.Item>

                                    <Descriptions.Item label="Offset" span={1 + this.descriptionSpan}>{record.offset}</Descriptions.Item>
                                    <Descriptions.Item label="Scaling" span={1 + this.descriptionSpan}>{record.scaling}</Descriptions.Item>

                                    <Descriptions.Item label="Unit" span={1 + this.descriptionSpan}>{record.unit}</Descriptions.Item>
                                    <Descriptions.Item label="Source" span={1 + this.descriptionSpan}><span className="break-long-string">{record.source}</span></Descriptions.Item>

                                    {
                                    record.signalCollectionType !== "Command"  &&                                      
                                    <Descriptions.Item label="Source Address" span={1 + this.descriptionSpan}>{record.sourceAddress}</Descriptions.Item>
                                    }

                                    {
                                    record.signalCollectionType !== "Command" &&  
                                    <Descriptions.Item label="J1939 Error Checking" span={1 + this.descriptionSpan}>
                                        <Switch checked={record.j1939Error} />
                                    </Descriptions.Item>
                                    }
                                    
                                    {
                                        record.canHeader &&                
                                        this.doesOrgSupportCommandSignal(record.commandId) &&                     
                                        <Descriptions.Item label="CAN Header" span={2}><span className="break-long-string">{record.canHeader}</span></Descriptions.Item>
                                    }
                                    {
                                        record.commandId &&                                   
                                        <Descriptions.Item label="Corresponding Command Name" span={2}><span className="break-long-string">{this.getSignalName(record.commandId)}</span></Descriptions.Item>                               
                                        
                                    }
                                    {
                                        record.signalCollectionType === "Command" && record.canAddress &&                                      
                                      <Descriptions.Item label={record.pgn===65305 ? "Gateway CAN Address" : "Destination Controller CAN Address"}
                                       span={1 + this.descriptionSpan}>{record.canAddress}</Descriptions.Item>
                                    }
                                    {
                                       record.format && (record.format || "") !== "" &&                        
                                      <Descriptions.Item label="Format" span={1 + this.descriptionSpan}>{this.capitalizeFirstLetter(record.format)}</Descriptions.Item>
                                    }
                                     {
                                      (record.signalCollectionType === "Command" && record.min &&
                                       (record.format === "integer" || record.format === "float")) &&
                                      <Descriptions.Item label="Min" span={1 + this.descriptionSpan}>{record.min}</Descriptions.Item>
                                    }
                                    {
                                      (record.signalCollectionType === "Command" && record.format === "text" ) &&
                                      <Descriptions.Item label="Multi-package" span={1 + this.descriptionSpan}>{(record.max || "") === "140" ? "Yes" : "No"}</Descriptions.Item>
                                    }
                                    {
                                      record.signalCollectionType === "Command" && record.max &&
                                      <Descriptions.Item label="Max" span={1 + this.descriptionSpan}>{record.max}</Descriptions.Item>                                      
                                    }
                                   
                                </Descriptions>
                            }
                            {
                                record.signalCollectionType === "IQAN Diagnostics" &&
                                <Descriptions bordered column={2}>
                                    <Descriptions.Item label="Source">{record.source}</Descriptions.Item>
                                </Descriptions>
                            }
                            {
                                record.signalRule &&
                                (
                                    record.signalRule.criticalLowValue !== null || record.signalRule.criticalHighValue !== null || 
                                    record.signalRule.warningLowValue !== null || record.signalRule.warningHighValue !== null ||
                                    record.signalRule.infoLowValue !== null || record.signalRule.infoHighValue !== null
                                ) &&
                                <Card size="small" key={"rule-" + record.signalId} className="mt-20">
                                    <div className="rule-blocks">
                                        <div>
                                            <span className="pr-5">Alarm</span>
                                            <strong>{record.signalRule.ruleName}</strong>
                                        </div>
                                        {
                                            (record.signalRule.criticalLowValue !== null || record.signalRule.criticalHighValue !== null || record.signalRule.warningLowValue !== null || record.signalRule.warningHighValue !== null) &&
                                            <div>
                                                <span className="rule-critical">{record.signalRule.criticalLowValue !== null || record.signalRule.criticalHighValue !== null ? "Critical" : ""}</span>
                                                { 
                                                    (record.signalRule.criticalLowValue !== null || record.signalRule.criticalHighValue !== null) && (record.signalRule.warningLowValue !== null || record.signalRule.warningHighValue !== null) &&
                                                    <span className="pl-5 pr-5">+</span>
                                                }
                                                <span className="rule-warning">{record.signalRule.warningLowValue !== null || record.signalRule.warningHighValue !== null ? "Warning" : ""}</span>
                                                <span className="pl-5">when {record.label} is</span>
                                            </div>
                                        }
                                        {
                                            (record.signalRule.infoLowValue !== null || record.signalRule.infoHighValue !== null) &&
                                            <div>
                                                <span className="rule-info">Info</span>
                                            </div>
                                        }
                                        <div className="mt-10">
                                            { 
                                                numberZeroPositive(record.signalRule.infoLowValue) &&
                                                <Tag color="#1e90ff">
                                                    <div>{record.signalRule.infoLowValue}</div>
                                                    <div>{record.signalRule.infoLowLabel}</div>
                                                </Tag>
                                            }
                                            { 
                                                numberZeroPositive(record.signalRule.criticalLowValue) &&
                                                <Tag color="#9b0000">
                                                    <div>{record.signalRule.criticalLowValue}</div>
                                                    <div>{record.signalRule.criticalLowLabel}</div>
                                                </Tag>
                                            }
                                            { 
                                                numberZeroPositive(record.signalRule.warningLowValue) &&
                                                <Tag color="#efb20e">
                                                    <div>{record.signalRule.warningLowValue}</div>
                                                    <div>{record.signalRule.warningLowLabel}</div>
                                                </Tag>
                                            }
                                            {
                                                ((record.signalRule.criticalLowValue !== null || record.signalRule.warningLowValue !== null) &&( record.signalRule.criticalHighValue !== null || record.signalRule.warningHighValue !== null)) &&
                                                <Tag color="#e6e6e6">
                                                    <div>&nbsp;</div>
                                                    <div>&nbsp;</div>
                                                </Tag>
                                            }                                           
                                            { 
                                                numberZeroPositive(record.signalRule.warningHighValue) &&
                                                <Tag color="#efb20e">
                                                    <div>{record.signalRule.warningHighValue}</div>
                                                    <div>{record.signalRule.warningHighLabel}</div>
                                                </Tag>
                                            }
                                            { 
                                                numberZeroPositive(record.signalRule.criticalHighValue) &&
                                                <Tag color="#9b0000">
                                                    <div>{record.signalRule.criticalHighValue}</div>
                                                    <div>{record.signalRule.criticalHighLabel}</div>
                                                </Tag>
                                            }
                                            { 
                                                numberZeroPositive(record.signalRule.infoHighValue) &&
                                                <Tag color="#1e90ff">
                                                    <div>{record.signalRule.infoHighValue}</div>
                                                    <div>{record.signalRule.infoHighLabel}</div>
                                                </Tag>
                                            }
                                      
                                        
                                    </div>
                                      </div>
                                       
                                </Card>
                                
                            }   
                            
                             {
                                 
                                    record.signalIncrementalRule &&
                                    <Card size="small" key="1" className="mt-20">
                                    <div style={{margin:'10px',textAlign:'center'}}>
                                    <div>
                                    <span className="pr-5">Incremental Value</span>
                                    
                                   </div>
                                <div><strong>{record.signalIncrementalRule.ruleValue}</strong></div>
                                 </div>
                                 </Card>
                                }
                                 {
                                 
                                 record.offlineRule &&
                                 <Card size="small" key="2" className="mt-20">
                                 <div style={{margin:'10px',textAlign:'center'}}>
                                     <div><span className="pr-5"> Offline <strong>{record.offlineRule.ruleName}</strong></span></div>
                                    <div> <Tag color="blue">
                                            <div><strong>{record.offlineRule.offlineDuration +" " + (record.offlineRule.offlineDuration > 1 ? record.offlineRule.offlineSince:record.offlineRule.offlineSince.substring(0,record.offlineRule.offlineSince.length-1))}</strong>
                                            </div>
                                            <div>over</div>
                                        </Tag></div>
                                    </div>
                              </Card>
                             }
                              {

                                 record.mergeRule &&
                                 <Card size="small" key="3" className="mt-20">
                                 <div style={{margin:'10px',textAlign:'center'}}>
                                    <div><span className="pr-5">Merge <strong>{record.mergeRule.mergeRuleName}</strong></span></div>
                                    <Tag color= {this.isLeftOffline(record)?"blue":"orange"}><strong>{this.getLeftMergeRuleName(record)}</strong></Tag>
                                    {" " + record.mergeRule.mergeRuleOperator + "  "}
                                    <Tag color={this.isLeftOffline(record)?"orange":"blue"}><strong>{this.getRightMergeRuleName(record)}</strong></Tag>
                                 </div>
                              </Card>
                             }
                            </Card>                  
                                            
                    )
                )}
                {
                    (this.props.signalList.tableData.length || 0) > 0 &&
                    <Pagination 
                        {...this.props.signalList.tableConfig.pagination}
                        style={{ float: 'right'}}
                        onChange={this.onPaginationChange}
                    />
                }
            </>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        signals: state.templateDetailsReducers.templateDetailsState.signals,
        signalList: state.templateDetailsReducers.templateDetailsState.signals.signalList,
        templateDetails: state.templateDetailsReducers.templateDetailsState
    };
};

export default connect(
    mapStateToProps,
    actions
)(SignalList);