import { useState, useCallback } from "react";

type Node<T> = T | null;

export default function useDomNode<T>(): [Node<T>, (n: Node<T>) => void] {
  const [node, setNode] = useState<Node<T>>(null);

  const onChange = useCallback((n: Node<T>) => setNode(n), []);

  return [node, onChange];
}
