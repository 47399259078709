import { WidgetDetails } from '../models';
import { CommonWidgetConfig } from '../../models';
import Widget from './Widget';
import EditForm from './EditForm';

export const details: WidgetDetails = {
  type: "chat",
  name: "Chat History"
}

export type Config = CommonWidgetConfig  & {
  type: "chat";
};


export { EditForm, Widget}