import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function usersState(state = initialState, action:any) {
    let data:any = null,
        payload:any;

    switch (action.type) {
        case "USERS_SET_PAGINATION":
            return immerProduce(state, draft => {
                draft.usersList.tableConfig.pagination.onChange = action.onPaginationChange;
            });
        case "USERS_SET_SEARCH":
            return immerProduce(state, draft => {
                draft.usersList.searchText = action.searchText;
            });
        case "USERS_GET_USERS_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.usersList.refreshOnUpdate = false;
                draft.usersList.tableData = data;
                draft.usersList.tableConfig.pagination.total = (data.length || 0) > 0 ? data[0].totalRecords : 0;
                draft.usersList.tableConfig.pagination.current = action.payload.page;
            });
        case "USERS_USER_MAINT_OPEN":
            return immerProduce(state, draft => {
                const
                    payload = action.payload,
                    instance = Math.random();
                draft.usersList.userMaint.instance = instance;
                draft.usersList.userMaint.width = payload.width;
                draft.usersList.userMaint.visible = true;
                draft.usersList.userMaint.title = payload.title;
                draft.usersList.userMaint.record = payload.record;
                draft.usersList.userMaint.pageName = payload.pageName;

                draft.usersList.userMaint.activeTab = "1";
                draft.usersList.userMaint.tabs[0].disabled = false;
                draft.usersList.userMaint.tabs[0].instance = instance;
                draft.usersList.userMaint.tabs[0].userCopy!.data = [];
                draft.usersList.userMaint.tabs[1].disabled = Object.keys(payload.record).length === 0;
                draft.usersList.userMaint.tabs[1].instance = instance;
                draft.usersList.userMaint.tabs[2].disabled = Object.keys(payload.record).length === 0;
                draft.usersList.userMaint.tabs[2].instance = instance;
                draft.usersList.userMaint.tabs[3].disabled = Object.keys(payload.record).length === 0;
                draft.usersList.userMaint.tabs[3].instance = instance;
            });
        case "USERS_USER_MAINT_CLOSE":
            return immerProduce(state, draft => {
                draft.usersList.userMaint.visible = false;
                draft.usersList.userMaint.tabs[1].tableData = [];
                draft.usersList.userMaint.tabs[2].tableData = [];
                draft.usersList.userMaint.tabs[2].searchText = "";
                draft.usersList.userMaint.tabs[3].tableData = [];
            });
        case "USERS_LIST_REFRESH_ON_UPDATE":
            return immerProduce(state, draft => {
                draft.usersList.refreshOnUpdate = true;
            });
        case "USERS_MAINT_REFRESH_INSTANCE":
            return immerProduce(state, draft => {
                const instance = Math.random();
                action.instances.forEach((element:number) => {
                    draft.usersList.userMaint.tabs[element].instance = instance;
                });
            });
        case "USERS_MAINT_SUCCESS":
            data = action.payload.data;            
            return immerProduce(state, draft => {
                draft.usersList.userMaint.record = data;
                draft.usersList.userMaint.title = "Edit User :: " + data.fullName;
            });
        case "USERS_USER_MAINT_TAB_CHANGE":
            return immerProduce(state, draft => {
                draft.usersList.userMaint.activeTab = action.tab;
            });
        case "USERS_USER_MAINT_TABS_ENABLE":
            return immerProduce(state, draft => {
                if (draft.usersList.userMaint.tabs[1].disabled) {
                    draft.usersList.userMaint.tabs[1].disabled = false;
                }
                if (draft.usersList.userMaint.tabs[2].disabled) {
                    draft.usersList.userMaint.tabs[2].disabled = false;
                }
                if (draft.usersList.userMaint.tabs[3].disabled) {
                    draft.usersList.userMaint.tabs[3].disabled = false;
                }
            });
        case "USERS_GET_USERS_COPY_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.usersList.userMaint.tabs[0].userCopy!.data = data;
            });
        case "USERS_COUNTRIES_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.usersList.userMaint.tabs[0].countries! = data;
            });
        case "USERS_ADMINISTERED_ORGS_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.usersList.userMaint.administeredOrgs = data;
            });
        case "USERS_SUBSCRIPTIONS_SET_PAGINATION":
            return immerProduce(state, draft => {
                draft.usersList.userMaint.tabs[2].tableConfig!.pagination.onChange = action.onPaginationChange;
            });
        case "USERS_SUBSCRIPTIONS_SET_SEARCH":
            return immerProduce(state, draft => {
                draft.usersList.userMaint.tabs[2].searchText = action.searchText;
            });
        case "USERS_SUBSCRIPTIONS_GET_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.usersList.userMaint.tabs[2].tableData! = data.filter( (ele: any, ind: any) => ind === data.findIndex( (elem: { gatewayId: any; }) => elem.gatewayId === ele.gatewayId));
                draft.usersList.userMaint.tabs[2].tableConfig!.pagination.total = (data.length || 0) > 0 ? data[0].groupsTotal : 0;
                draft.usersList.userMaint.tabs[2].tableConfig!.pagination.current = action.payload.page;

            });
        case "USERS_SUBSCRIPTIONS_MAINT_SUCCESS":
            return immerProduce(state, draft => {
                payload = action.payload;
                if (payload.scope === "asset") {
                    updateAsset(draft, payload.record, payload.notification, payload.action);
                }
                else {
                    updateAssetAlerts(draft, payload.record, payload.notification, payload.action);
                }
            });
        case "USERS_ORGS_SET_PAGINATION":
            return immerProduce(state, draft => {
                draft.usersList.userMaint.tabs[1].tableConfig!.pagination.onChange = action.onPaginationChange;
            });
        case "USERS_ORGS_ROLE_UPDATE_SUCCESS":
            return immerProduce(state, draft => {
                updateOrgUser(state, draft, action.data[0]);
            });
        case "USERS_ORGS_GET_SUCCESS":
            data = action.payload.data || [];
            return immerProduce(state, draft => {
                draft.usersList.userMaint.tabs[1].tableData! = data;
                draft.usersList.userMaint.tabs[1].tableConfig!.pagination.total = (data.length || 0) > 0 ? data[0].totalRecords : 0;
                draft.usersList.userMaint.tabs[1].tableConfig!.pagination.current = action.payload.page;
            });
        case "USERS_ORGS_REMOVE_SUCCESS":
            return immerProduce(state, draft => {
                let data = draft.usersList.userMaint.tabs[1].tableData!;
                draft.usersList.userMaint.tabs[1].tableData = data.filter((record:any) => action.payload.orgUserId !== record.orgUserId);
            });
        case "ROLE_MAINT_OPEN":
            return immerProduce(state, draft => {
            const
                payload = action.payload,
                instance = Math.random();
                
            draft.roleList.roleMaint.instance = instance;
            draft.roleList.roleMaint.width = payload.width;
            draft.roleList.roleMaint.visible = true;
            draft.roleList.roleMaint.title = payload.title;
            draft.roleList.roleMaint.record = payload.record;
            draft.roleList.roleMaint.activeTab = "1";
            draft.roleList.roleMaint.tabs[0].disabled = false;
            draft.roleList.roleMaint.tabs[0].instance = instance;
            draft.roleList.roleMaint.tabs[0].roleCopy!.data = [];
        });
        case "ROLE_MAINT_CLOSE":
            return immerProduce(state, draft => {
                // draft.roleList.roleMaint.tabs[0].searchText = "";
                draft.roleList.roleMaint.visible = false;
            });
        case "ROLE_PERMISSION_MAINT_SUCCESS":
            data = action.payload.data;
            return immerProduce(state, draft => {
                draft.roleList.roleMaint.record = data;
                draft.roleList.roleMaint.title = "Edit Role :: " + data.name;
            });
        case "ROLE_PERMISSION_GET_SUCCESS":
            data = action.payload.data;            
            return immerProduce(state, draft => {
                draft.data.permissions = data;
            });
        case "USERS_ORG_ROLE_GET_SUCCESS":            
            data = action.payload.data;            
            return immerProduce(state, draft => {
                draft.roleList.roleMaint.orgRoles.data.roles = data.data.orgRoles;
            });
        case "USERS_ORG_USER_ROLE_GET_SUCCESS": 
            data = action.payload.data;            
            return immerProduce(state, draft => {
                draft.roleList.roleMaint.orgRoles.data.roles = data; //data.data.orgRolePermissions.filter((res: { roleId: number; }) => res.roleId !== 1);            
        });
        case "USER_ACTIVITY_SUBSCRIPTIONS_GET": 
           data = action.payload.data;            
           return immerProduce(state, draft => {
            draft.usersList.userMaint.tabs[3].tableData! = data;
        });
        case "USERS_ACTIVITY_SUBSCRIPTIONS_MAINT_SUCCESS":
            return immerProduce(state, draft => {
                payload = action.payload;
                updateActivitySubscriptions(draft, payload.record, payload.notification, payload.action);
        });
        default:
            return state;
    }
}
const updateOrgUser = (state: any, draft:any, updatedRecord:any) => {
    state.usersList.userMaint.tabs[1].tableData.forEach((record: any, index: number) => {
        if (record.orgUserId === updatedRecord.orgUserId) {
            draft.usersList.userMaint.tabs[1].tableData[index] = updatedRecord;
        }
    });
}

const updateAsset = (draft:any, record:any, notification: string, action: string) => {
    draft.usersList.userMaint.tabs[2].tableData.forEach(function (asset:any) {
        if (asset.key === record.key) {
            asset.children.forEach(function (alert:any) {
                if (notification === "sms") {
                    alert.alertSms = (action === "plus");
                    alert.incAlertSms = (action === "plus");
                    alert.offlineAlertSms = (action === "plus");
                }
                if (notification === "email") {
                    alert.alertEmail = (action === "plus");
                    alert.incAlertEmail = (action === "plus");
                    alert.offlineAlertEmail = (action === "plus");
                }
            });
            if (notification === "sms") {
                asset.assetSmsIndeterminate = false;
                asset.assetSmsChecked = (action === "plus");
            }
            else {
                asset.assetEmailIndeterminate = false;
                asset.assetEmailChecked = (action === "plus");
            }
        }
    });
}

const updateAssetAlerts = (draft:any, record:any, notification: string, action: string) => {
    draft.usersList.userMaint.tabs[2].tableData.forEach(function (asset:any) {
        asset.children.forEach(function (alert:any) {
            if (alert.key === record.key) {
                let checkedCount = 0;
                if (notification === "sms") {
                    alert.alertSms = (action === "plus");
                    alert.incAlertSms = (action === "plus");
                    alert.offlineAlertSms = (action === "plus");
                }
                if (notification === "email") {
                    alert.alertEmail = (action === "plus");
                    alert.incAlertEmail = (action === "plus");
                    alert.offlineAlertEmail = (action === "plus");
                }

                if(notification === "smsIn"){
                    alert.alertSmsIn = (action === "plus");
                }
                if(notification === "smsOut"){
                    alert.alertSmsOut = (action === "plus");

                }
                if(notification === "emailIn"){
                    alert.alertEmailIn = (action === "plus");
                }
                if(notification === "emailOut"){
                    alert.alertEmailOut = (action === "plus");
                }

                asset.children.forEach(function (alertCount:any) {
                    checkedCount = checkedCount + (notification === "sms" && alertCount.alertSms ? 1 : 0) + (notification === "email" && alertCount.alertEmail ? 1 : 0);
                });
                if (notification === "sms") {
                    asset.assetSmsIndeterminate = (checkedCount > 0 && checkedCount < asset.children.length);
                    asset.assetSmsChecked = (checkedCount === asset.children.length);
                }
                else {
                    asset.assetEmailIndeterminate = (checkedCount > 0 && checkedCount < asset.children.length);
                    asset.assetEmailChecked = (checkedCount === asset.children.length);
                }
            }
        });
    });
}

const updateActivitySubscriptions = (draft:any, record:any, notification: string, action: string) => {
    draft.usersList.userMaint.tabs[3].tableData.forEach(function (activity:any) {
        activity.children.forEach(function (alert:any) {
                if (notification === "sms") {
                    alert.alertSmsIn = (action === "plus"); 
                    alert.alertSmsOut = (action === "plus");
                    activity.smsChecked = (action === "plus");
                    activity.smsIndeterminate = false;

                }
                if (notification === "email") {
                    alert.alertEmailIn = (action === "plus");
                    alert.alertEmailOut = (action === "plus");
                    activity.emailChecked = (action === "plus");
                    activity.emailIndeterminate = false;
                }
                if(notification === "smsIn"){
                    alert.alertSmsIn = (action === "plus");
                    //activity.smsIndeterminate = alert.alertSmsOut?true:false;
                    if(alert.alertSmsIn && alert.alertSmsOut)
                    {
                        activity.smsIndeterminate=false;
                        activity.smsChecked = true;
                    }
                    else
                    {
                        if(!alert.alertSmsIn && !alert.alertSmsOut)
                        {
                            activity.smsIndeterminate=false;
                        }
                        else
                        {
                            activity.smsIndeterminate=true;
                        }
                        activity.smsChecked = false;
                    }
                    
                }
                if(notification === "smsOut"){
                    alert.alertSmsOut = (action === "plus");
                    if(alert.alertSmsIn && alert.alertSmsOut)
                    {
                        activity.smsIndeterminate=false;
                        activity.smsChecked = true;
                    }
                    else
                    {
                        if(!alert.alertSmsIn && !alert.alertSmsOut)
                        {
                            activity.smsIndeterminate=false;
                        }
                        else
                        {
                            activity.smsIndeterminate=true;
                        }
                        activity.smsChecked = false;
                    }

                }
                if(notification === "emailIn"){
                    alert.alertEmailIn = (action === "plus");
                    if(alert.alertEmailIn && alert.alertEmailOut)
                    {
                        activity.emailIndeterminate=false;
                        activity.emailChecked = true;
                    }
                    else
                    {
                        if(!alert.alertEmailIn && !alert.alertEmailOut)
                        {
                            activity.emailIndeterminate=false;
                        }
                        else
                        {
                            activity.emailIndeterminate=true;
                        }
                        
                        activity.emailChecked = false;
                    }
                }
                if(notification === "emailOut"){
                    alert.alertEmailOut = (action === "plus");
                    if(alert.alertEmailIn && alert.alertEmailOut)
                    {
                        activity.emailIndeterminate=false;
                        activity.emailChecked = true;
                    }
                    else
                    {
                        if(!alert.alertEmailIn && !alert.alertEmailOut)
                        {
                            activity.emailIndeterminate=false;
                        }
                        else
                        {
                            activity.emailIndeterminate=true;
                        }
                        activity.emailChecked = false;
                    }
                }
        });
    });
}