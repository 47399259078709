/* eslint-disable @typescript-eslint/no-unused-vars */
import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function tutorialsState(state = initialState, action:any) {
    let data:any = null,
        payload:any;
        switch (action.type) {  
            case "VIDEOTUTORIALS_GET_SUCCESS":
                return immerProduce(state, draft => {
                    draft.tutorials.tutorialsList = action.data;
                });
            case "TUTORIAL_SET_SEARCH":
                    return immerProduce(state, draft => {
                    draft.tutorials.searchText = action.searchText;
            });
            case "SET_TAB":
                    return immerProduce(state, draft => {
                    draft.tutorials.activeTab = action.tabNumber;
            });
            default:
                return state;
        }
}