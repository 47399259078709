import React from "react";

export interface Props {
  size: number | string;
  color: string;
}

const Dot: React.FC<Props> = (props) => (
  <div
    {...props}
    style={{
      borderRadius: "50%",
      backgroundColor: props.color,
      height: props.size,
      width: props.size,
    }}
  />
);

export default Dot;
