import React, { Component } from "react";
import { Table, Descriptions, Button, Modal, message, Radio, Tooltip, Drawer, Spin, Row, Col, Divider, Tag } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { 
    CheckCircleOutlined, CloseCircleOutlined
} from "@ant-design/icons";

import history from '../../utils/history';
import { appConfigs } from "../../utils/configurations";
import { localizeUtcDate, getPermissions } from "../../utils/commonHelpers";

import { connect } from "react-redux";
import * as actions from "./actions";

import AlertsDm1Filters from "./AlertsDm1Filters";
import "./alerts.css";

import cumminsLogo from "../../assets/images/cummins_logo.png";
import stopNow from "../../assets/images/stopnow.png";
import serviceNow from "../../assets/images/servicenow.png";
import serviceSoon from "../../assets/images/servicesoon.png";
import information from "../../assets/images/information.png";
import inactive from "../../assets/images/inactive.png";
import CumminsResponseView from "./CumminsResponseView";
import { ColumnsType } from "antd/lib/table";

interface Prop {    
    alertsDm1SetPagination: Function,
    alertsDm1: Function,
    alertsDm1Filters: Function,
    markAlerts: Function,
    getAsset: Function,    
    context: any,
    alertsState: any,
    permissions: any,
    mastertag: string,
    isread: boolean,
    selectedType: Function,
    getCumminsRecommendations: Function,
    getParkerRecommendations: Function,
}

interface State {
    nickname: string[],
    selectedRowKeys: string[], 
    loading: boolean,
    isModalVisible: boolean,
    viewAlertType: string,
    isCumminsDrawerVisible: boolean,
    selectedAlert: any,
    deleteAlerts: boolean,
    recommendationOfAlert: string,
    recommendation: any,
    parkerRecommendation: any,
    parkerActions: any,
    showDiagnostics: boolean,
    assetOrgId:any,
}

class AlertsDm1 extends Component<Prop, State> {
    
    state:State = {
        nickname: [],
        selectedRowKeys: [], 
        loading: false,
        isModalVisible: false,
        viewAlertType: "unseen",
        isCumminsDrawerVisible: false,
        selectedAlert:{},
        deleteAlerts: false,
        recommendationOfAlert: "",
        recommendation: "",
        parkerRecommendation: "", 
        parkerActions: [],
        showDiagnostics: false,
        assetOrgId: "",
    }

    componentDidMount () { 
        if ((this.props.mastertag || "") !== "") {
            this.props.getAsset(this, this.getAssetSuccess, null, this.props.mastertag);                         
        }
        else {
            this.componentUpdate();     
        }
    }    
    
    getAssetSuccess = (mastertag: string, asset:any) => {  
        this.setState({ nickname: [asset.nickname] }); 
        this.componentUpdate();        
    };

    componentUpdate = () => {
        const appliedFilters = this.props.alertsState.tabs[0].appliedFilters;  
        this.props.alertsDm1SetPagination(this.onPaginationChange);
        if(!this.filtersApplied()){
            this.props.alertsDm1(this, null, null, 1, null, null, this.state.viewAlertType, null, null, this.state.nickname);
            this.props.alertsDm1Filters(this, null, null);
        }
        else{
            let nicknameToBeFiltered = [];
            if (appliedFilters.nickname && appliedFilters.nickname.length != 0 && this.state.nickname && 
                appliedFilters.nickname.indexOf(this.state.nickname) == -1) {
                    nicknameToBeFiltered.push(this.state.nickname);
            } else {
                nicknameToBeFiltered = appliedFilters.nickname;
            }

            this.props.alertsDm1(this, null, null, 1, null, null, this.state.viewAlertType, appliedFilters.timespanFrom,
                appliedFilters.timespanTo, nicknameToBeFiltered, appliedFilters.sa, appliedFilters.spn,
                appliedFilters.fmi, appliedFilters.status);                
        }            
    };

    onPaginationChange = (page: number) => {      
        const appliedFilters = this.props.alertsState.tabs[0].appliedFilters;     
        this.props.alertsDm1(
            this, 
            null, 
            null, 
            page, 
            null,
            null,
            this.state.viewAlertType,
            appliedFilters.timespanFrom,
            appliedFilters.timespanTo,
            appliedFilters.nickname,
            appliedFilters.sa,
            appliedFilters.spn,
            appliedFilters.fmi,
            appliedFilters.status
        ); 
    };

    goToAsset = (e: any, mastertag: string) => {
        e.preventDefault();
        history.push("/assets/" + mastertag);
    };

    onSelectChange = (selectedRowKeys:any) => {
        this.setState({ selectedRowKeys });
      };

      filtersApplied = () => {
        const appliedFilters = this.props.alertsState.tabs[0].appliedFilters;
        let filtersApplied = false;
        return filtersApplied =             
            (appliedFilters.nickname && appliedFilters.nickname.length || 0) || //eslint-disable-line 
            (appliedFilters.sa && appliedFilters.sa.length || 0) || //eslint-disable-line 
            (appliedFilters.spn && appliedFilters.spn.length || 0) || //eslint-disable-line 
            (appliedFilters.fmi && appliedFilters.fmi.length || 0) || //eslint-disable-line 
            (appliedFilters.status && appliedFilters.status.length || 0) || //eslint-disable-line 
            (appliedFilters.tags && appliedFilters.tags.length || 0) || //eslint-disable-line 
            ((appliedFilters.timespanFrom || "") !== "") || //eslint-disable-line  
            ((appliedFilters.timespanTo || "") !== ""); //eslint-disable-line 
      }
    
    markAlertsSuccess = (data:any) => {
        let status = (this.state.deleteAlerts) ?
        (this.state.selectedRowKeys.length>0? `Selected  ${(this.state.viewAlertType === 'seen')? 'Seen' : 'Unseen'} alerts got deleted.`
        : (this.filtersApplied()? `All the filtered ${(this.state.viewAlertType === 'seen')? 'Seen' : 'Unseen'} alerts got deleted.` 
        :`All the ${(this.state.viewAlertType === 'seen')? 'Seen' : 'Unseen'} alerts got deleted.`))
        :
        `Selected alerts are marked as ${(this.state.viewAlertType === 'unseen')? 'Seen' : 'Unseen'}`
        message.success(status);
        this.setState({loading: false, selectedRowKeys:[]});
        this.componentUpdate();
    }  
    markAlertsFail = (error:any) => {
        this.setState({loading: false, selectedRowKeys:[]});
   //     message.error("Selected alerts failed to delete "+error.Error);        
    }
    deleteDm1Alerts = () => {
        const appliedFilters = this.props.alertsState.tabs[0].appliedFilters;
        this.props.markAlerts(this,this.markAlertsSuccess,this.markAlertsFail,this.state.selectedRowKeys,
            this.state.viewAlertType,"dm1",this.state.deleteAlerts,
            appliedFilters.timespanFrom,
            appliedFilters.timespanTo,
            null,
            null,
            appliedFilters.nickname,
            appliedFilters.sa,
            appliedFilters.spn,
            appliedFilters.fmi,
            appliedFilters.status,
            appliedFilters.tags
        );
        this.setState({loading: true});
    }

    showModal = (e:any, isDeleted: boolean) => {
        if(isDeleted)
        {
            this.setState({deleteAlerts: true}); 
        }
        else
        {
            this.setState({deleteAlerts: false});
        }
        this.setState({isModalVisible: true});
      };
    
    handleOk = () => {
        this.setState({isModalVisible: false});
        this.deleteDm1Alerts();
      };
    
    handleCancel = () => {
        this.setState({isModalVisible: false});
        this.setState({loading: false, selectedRowKeys:[]});
      };
    
    showDiagnosticsModal = (showDiagnostics: boolean) => {
		this.setState({ showDiagnostics: showDiagnostics });
    };
    
    handleDiagnosticsOk = () => {
		this.setState({ showDiagnostics: false });
	};

	handleDiagnosticsCancel = () => {
		this.setState({ showDiagnostics: false });
	};

    showCumminsRecommendations= (e:any,alert:any) => {
        this.setState({
            loading: true,
        });
        this.props.getCumminsRecommendations(this, this.getRecommendation, null,
            alert.alertId,
            alert.gatewayId,
            alert.sa,
            alert.spn,
            alert.fmi,
            alert.oc,
            alert.detectionTime);
        this.setState({recommendationOfAlert: alert.alertId});
        this.setState({isCumminsDrawerVisible: true});
        this.setState({assetOrgId: alert.orgId}); 
        e.preventDefault();
    }
    showParkerRecommendations= (e:any,alert:any) => {
        this.setState({
            loading: true,
        });
        this.props.getParkerRecommendations(this, this.getParkerRecommendation, null,
            alert.orgId,
            alert.sa,
            alert.spn,
            alert.fmi);
        // this.setState({recommendationOfAlert: alert.alertId});
        this.setState({showDiagnostics: true});
        e.preventDefault();
    }
    getRecommendation = (data:any) => {
        this.setState({
            loading: false,
            recommendation: data
        });

    }

    getParkerRecommendation = (data:any) => {
        this.setState({
            loading: false,
        parkerRecommendation: data
        });

        if (this.state.parkerRecommendation?.recommendations) {
            let actions = JSON.parse(this.state.parkerRecommendation?.recommendations);
            actions.sort(function (a: any, b: any) {
                return a?.order < b?.order;
            })
            actions.sort(); 
            this.setState({
                parkerActions: actions
            });
        }
    }  

    onCloseCumminsDiagnostics = () => {
        this.setState({isCumminsDrawerVisible: false,
                        recommendation: ""});
    }

    onCloseParkerDiagnostics = () => {
        this.setState({showDiagnostics: false,
                        parkerRecommendation: ""});
    }


    onChangeViewAlert = (e:any) => {
        this.setState({
            viewAlertType: e.target.value,
            selectedRowKeys: [] 
        });
        this.props.selectedType(e.target.value);
        //this.props.alertsDm1(this, null, null, 1, null, null, e.target.value, null, null, this.state.nickname);
        const appliedFilters = this.props.alertsState.tabs[0].appliedFilters;     
        this.props.alertsDm1(
            this, 
            null, 
            null, 
            1, 
            null,
            null,
            e.target.value,
            appliedFilters.timespanFrom,
            appliedFilters.timespanTo,
            appliedFilters.nickname,
            appliedFilters.sa,
            appliedFilters.spn,
            appliedFilters.fmi,
            appliedFilters.status,
            appliedFilters.tags
        );
    };
  

    
    render() {  
        const result = getPermissions(this.props.permissions.permissions.dm1_settings);      
        let isDelete = result[3];
        const { tableConfig, tableData } = this.props.alertsState.tabs[0];       
        const { loading, selectedRowKeys, viewAlertType } = this.state;
        const rowSelection = (isDelete) ? {
            selectedRowKeys,
            onChange: this.onSelectChange,
        }:{getCheckboxProps: (record:any)=>({disabled: true})};
        const hasSelected = selectedRowKeys.length > 0;

        const openInNewTab = (url: any) => {
            window.open(url, '_blank', 'noopener,noreferrer');
        };

        
        let tableColumns = [
            {
                title: "Status",
                dataIndex: "status",
                render: (text: any, record: any) => (
                    <span className="no-word-wrap">
                        {
                            record.status === "inactive" &&
                            <><span className="dot alert-status-red "/>Active</>
                        }
                        {
                            record.status === "active" &&
                            <><span className="dot alert-status-orange "/>Inactive</>
                        }
                    </span>
                )
            },
            {
                title: "Updated",
                dataIndex: "triggerTime",
                responsive: ["lg"] as Breakpoint[],
                render: (triggerTime: string, record: any) => (
                    <>{localizeUtcDate(triggerTime, this.props.alertsState.dateFormatOptions)}</>                    
                ) 
            },
            {
                title: "Detected",
                dataIndex: "detectionTime",
                responsive: ["lg"] as Breakpoint[],
                render: (detectionTime: string, record: any) => (
                    <>{localizeUtcDate(detectionTime, this.props.alertsState.dateFormatOptions)}</>                    
                ) 
            },
            {
                title: "SA",
                dataIndex: "sa",
                responsive: ["md"] as Breakpoint[],
                align: "center" as "center"                                       
            },
            {
                title: "SPN",
                dataIndex: "spn",
                responsive: ["md"] as Breakpoint[],
                align: "center" as "center"                                       
            },
            {
                title: "FMI",
                dataIndex: "fmi",
                responsive: ["md"] as Breakpoint[],
                align: "center" as "center"                                         
            },
            {
                title: "Counts",
                dataIndex: "oc",
                responsive: ["lg"] as Breakpoint[],
                align: "center" as "center",
                render: (oc: number, record: any) => (
                    <>{oc > 126 ? "N/A" : oc + ""}</>                    
                ) 
            },
            {
                title: "Org Name",
                dataIndex: "orgName",
                responsive: ["lg"] as Breakpoint[],
                render: (text: any, record: any) => {
                    let parents = record?.orgPathName?.includes("~") ? record?.orgPathName.split('~') : [record?.orgPathName];
                    if (parents.length > 1) {
                        parents = parents.filter((parent: any) => {
                            if (!parent.toLowerCase().includes("parker")) {
                                return parent
                            }
                        })
                    }
                    return (
                        parents.length > 2 ?
                        <>
                            <span className="no-word-wrap">
                                <Tooltip overlayInnerStyle={{ width: '350px' }} title={
                                    parents.map((parent: any, index: number) => {
                                        return parents.length - 1 == index ? parent : parent + " \\ " 
                                    })
                                }>
                                    <span>{record.orgName}</span>
                                </Tooltip>
                            </span>  
                        </>
                        : <span>{record.orgName}</span>
                    )
                }
            },
            {
                title: "Asset",
                dataIndex: "nickname",
                render: (text: any, record: any) => (
                    <a href="# " onClick={(e) => {this.goToAsset(e, record.mastertag)}}> 
                        {record.nickname || record.mastertag} 
                    </a>
                )                
            },
            {
                title: "Parker Recommendations",
                dataIndex: "parker_recommendations",
                render: (text: any, record: any) => (
                    <a href="#" onClick={() => this.showDiagnosticsModal(!this.state.showDiagnostics)} > 
                        Recommendation
                    </a>
                )                
            },
        ];
        
        let cumminsDiagnosticsTable = [
            {
                title: "Status",
                dataIndex: "status",
                render: (text: any, record: any) => (
                    <span className="no-word-wrap">
                        {
                            record.status === "inactive" &&
                            <><span className="dot alert-status-red "/>Active</>
                        }
                        {
                            record.status === "active" &&
                            <><span className="dot alert-status-orange "/>Inactive</>
                        }
                    </span>
                )
            },
            {
                title: "Updated",
                dataIndex: "triggerTime",
                responsive: ["lg"] as Breakpoint[],
                render: (triggerTime: string, record: any) => (
                    <>{localizeUtcDate(triggerTime, this.props.alertsState.dateFormatOptions)}</>                    
                ) 
            },
            {
                title: "Detected",
                dataIndex: "detectionTime",
                responsive: ["lg"] as Breakpoint[],
                render: (detectionTime: string, record: any) => (
                    <>{localizeUtcDate(detectionTime, this.props.alertsState.dateFormatOptions)}</>                    
                ) 
            },
            {
                title: 'SA',
                key: 'action',
                render: (record:any) => (
                <Tooltip mouseEnterDelay={1} placement="bottom" title={ record.saDescription ? record.sa +' - ' + record.saDescription : record.sa } arrowPointAtCenter>
                  <div>
                   { record.sa }
                 </div>
                </Tooltip>
                ),
            },
            {
                title: "SPN",
                key: 'action',
                render: (record:any) => (
                <Tooltip mouseEnterDelay={1} placement="bottom" title={ record.spnDescription ? record.spn +' - ' + record.spnDescription : record.spn } arrowPointAtCenter>
                  <div>
                   { record.spn }
                 </div>
                </Tooltip>
                ),                                      
            },
            {
                title: "FMI",
                key: 'action',
                render: (record:any) => (
                <Tooltip mouseEnterDelay={1} placement="bottom" title={ record.fmiDescription ? record.fmi + ' - ' + record.fmiDescription : record.fmi } arrowPointAtCenter>
                  <div>
                   { record.fmi } 
                 </div>
                </Tooltip>
                ),            
            },
            {
                title: "Counts",
                key: 'action',
                responsive: ["lg"] as Breakpoint[],
                align: "center" as "center",
                render: (oc: number, record: any) => (
                    <div>
                      {record.oc > 126 ? "N/A" : record.oc + ""}
                    </div>                    
                ) 
            },
            {
                title: "Org Name",
                dataIndex: "orgName",
                responsive: ["lg"] as Breakpoint[],
                render: (text: any, record: any) => {
                    let parents = record?.orgPathName?.includes("~") ? record?.orgPathName.split('~') : [record?.orgPathName];
                    if (parents.length > 1) {
                        parents = parents.filter((parent: any) => {
                            if (!parent.toLowerCase().includes("parker")) {
                                return parent
                            }
                        })
                    }
                    return (
                        parents.length > 2 ?
                        <>
                            <span className="no-word-wrap">
                                <Tooltip overlayInnerStyle={{ width: '350px' }} title={
                                    parents.map((parent: any, index: number) => {
                                        return parents.length - 1 == index ? parent : parent + " \\ " 
                                    })
                                }>
                                    <span>{record.orgName}</span>
                                </Tooltip>
                            </span>  
                        </>
                        : <span>{record.orgName}</span>
                    )
                }
            },
            {
                title: "Asset",
                dataIndex: "nickname",
                render: (text: any, record: any) => (
                    <a href="# " onClick={(e) => {this.goToAsset(e, record.mastertag)}}> 
                        {record.nickname || record.mastertag} 
                    </a>
                )
            },
            {  
                title: "Recommendations",        
                dataIndex: "priority",    
                render: (text: any, record: any) => (
                    <div>
            {
                (record.priority === null|| "")?
                <div>
                     
                    {/* <Button style={{  width: 100 }} loading={loading} disabled >NA</Button> */}
                    <a href="#"className="disabledlink"> Not Available </a>
                </div>
               
                :
                (record.priority.toLowerCase() === "stop now")?
                <div >
                    
                  <img src={stopNow} alt="stop now" width="50" height="50" onClick= { (e)=>this.showCumminsRecommendations(e,record) } />
                 <a href="# " onClick={(e)=>this.showCumminsRecommendations(e,record)}> {record.priority} </a>
                </div>
                :(record.priority.toLowerCase() === "service now")?
                <div>
                   
                  <img src={serviceNow} alt="service now" width="50" height="50" onClick= { (e)=>this.showCumminsRecommendations(e,record) } />
                  <a href="# " onClick={(e)=>this.showCumminsRecommendations(e,record)}> {record.priority} </a>
                </div>
                : (record.priority.toLowerCase() === "service soon")?
                <div>
                   
                <img src={serviceSoon} alt="service soon" width="50" height="50" onClick= { (e)=>this.showCumminsRecommendations(e,record) } />
                <a href="# " onClick={(e)=>this.showCumminsRecommendations(e,record)}> {record.priority} </a>
                </div>
                : (record.priority.toLowerCase() === "information")?
                <div>
                    
                <img src={information} alt="information" width="50" height="50" onClick= { (e)=>this.showCumminsRecommendations(e,record) } />
                <a href="# " onClick={(e)=>this.showCumminsRecommendations(e,record)}> {record.priority} </a>
                </div>
                : 
                <div>
                    
                <img src={inactive} alt="inactive" width="50" height="50" onClick= { (e)=>this.showCumminsRecommendations(e,record) } />
                <a href="# " onClick={(e)=>this.showCumminsRecommendations(e,record)}> {record.priority} </a>
                </div>
            }
           
        </div>
                )
            },
            {
                title: "Parker Recommendations",
                dataIndex: "hasParkerRecommendation",
                render: (text: any, record: any) => (
                    <div>
                    {
                        ((record.hasParkerRecommendation || false) === false) ?
                        <div>
                             
                            {/* <Button style={{  width: 100 }} loading={loading} disabled >NA</Button> */}
                            <a href="#"className="disabledlink"> NA </a>
                        </div>
                        :
                        <div>
                            <a href="#" onClick= { (e)=>this.showParkerRecommendations(e,record) } > 
                                Recommendation
                            </a>
                        </div>
                    }
                    </div>
                )                
            },
        ];

        if (this.props.context.appContext.orgId != 'c6adcb40-be92-11e6-9ed6-a5bc9cb5279b') {
            tableColumns = tableColumns.filter((column:any) => column.title != 'Parker Recommendations')
            cumminsDiagnosticsTable = cumminsDiagnosticsTable.filter((column:any) => column.title != 'Parker Recommendations')
        }

        return (
            <>    
                <AlertsDm1Filters nickname={this.state.nickname} isread={this.props.isread} viewAlertType={this.state.viewAlertType} />
                {
                    this.props.isread &&

                    <div>
                        <br/>
                        <>
                        <div style = {{width:'85%' , display:'inline-block'}}>
                            <span style={{ margin: 8 }}> 
                                <Radio.Group value={viewAlertType}  buttonStyle="solid" onChange={this.onChangeViewAlert}  >
                                    <Tooltip mouseEnterDelay={1} placement="bottom" title="Unseen/New Alerts" arrowPointAtCenter>
                                        <div style={{ display: 'inline-block'}}>
                                            <Radio.Button value="unseen">
                                                <CheckCircleOutlined className="pr-5" />Unseen
                                            </Radio.Button>
                                        </div>
                                    </Tooltip>
                                    <Tooltip mouseEnterDelay={1} placement="bottom" title="Seen Alerts" arrowPointAtCenter>
                                        <div style={{ display: 'inline-block'}}>

                                            <Radio.Button value="seen">
                                                <CloseCircleOutlined className="pr-5" />Seen
                                            </Radio.Button>
                                        </div>
                                    </Tooltip>
                                </Radio.Group>
                            </span>
                        </div>
                        <div style = {{display:'inline-block'}}>
                          <Button  type = "primary" 
                          disabled={(tableData || []).length === 0 || tableData === null || 
                                     (isDelete || "") === "" || isDelete === null ||  !isDelete } 
                        onClick = {(e:any)=>this.showModal(e,true)} danger>Clear {(viewAlertType === 'unseen')? 'Unseen' : 'Seen'} Alerts</Button>
                        </div>
                        </>
                        <br/>

                        <div style={{ margin: 16 }}>
                        <Button type="primary" onClick={(e:any)=>this.showModal(e,false)} disabled={!hasSelected} loading={loading}>
                            {(viewAlertType === 'unseen')? 'Mark as Seen' : 'Mark as Unseen'}
                        </Button>
                        <Modal title="Confirm" visible={this.state.isModalVisible} onOk={this.handleOk} onCancel={this.handleCancel}>
                         { (this.state.deleteAlerts) ?
                            (selectedRowKeys.length>0? `Are you sure you want to delete the selected ${(viewAlertType === 'seen')? 'Seen' : 'Unseen'} alerts?`
                            :this.filtersApplied()?`Are you sure you want to delete the filtered ${(viewAlertType === 'seen')? 'Seen' : 'Unseen'} alerts?`
                            :`Are you sure you want to delete all the ${(viewAlertType === 'seen')? 'Seen' : 'Unseen'} alerts?`)
                            :
                            `Mark selected alerts as ${(viewAlertType === 'unseen')? 'Seen' : 'Unseen'}?`
                         }
                        </Modal>
                        <span style={{ marginLeft: 8 }}>
                            {hasSelected ? `Selected ${selectedRowKeys.length} alerts to mark as ${(viewAlertType === 'unseen')? 'Seen' : 'Unseen'}` : ''}
                                </span>
                                
                        <Modal
                            width={720}
                            title={
                                <>
                                    <img height='30' width='30' style={{marginRight: "3px"}} src='parker-logo-48x48.png' className="org-image-preview" alt="Logo preview"/>
                                    <span style={{fontSize: "24px"}}>Parker Diagnostics</span>
                                </>
                            }
                            visible={this.state.showDiagnostics}
                            onCancel={this.handleDiagnosticsCancel}
                            onOk={this.handleDiagnosticsOk}
                            footer={<Button type="primary" onClick={this.handleDiagnosticsOk} >OK</Button>}
                        >
                            {<div>
                                <Row>
                                    <Col span={16}>
                                    {/*   
                                        <h3 style={{textDecoration: 'underline'}}>Part Details:</h3>
                                        <span><b>Machine Number:</b> 123456</span><br />
                                        <span><b>PTS ID:</b> Q5T08SFY</span><br />
                                        <span><b>Faulty Part:</b> VA 300</span><br />
                                        <span><b>Model Number:</b> VA-300-069111-01</span><br />

                                        <Divider />
                                        */}  

                                    <h3 style={{textDecoration: 'underline'}}>DM1 Fault Details:</h3>


                                    <div style={{width:400}} className="divTable">
                                        <div className="divTableBody">
                                            <div className="divTableRow">
                                                <div className="divTableCell">DM1 Message</div>
                                                <div className="divTableCell">{this.state.parkerRecommendation.name}</div>
                                            </div>
                                            <div className="divTableRow">
                                                <div className="divTableCell">SPN</div>
                                                <div className="divTableCell">{this.state.parkerRecommendation.spn}</div>
                                            </div>
                                            <div className="divTableRow">
                                            <div className="divTableCell">FMI</div>
                                            <div className="divTableCell">{this.state.parkerRecommendation.fmi}</div>
                                            </div>
                                            <div className="divTableRow">
                                                <div className="divTableCell">Description</div>
                                                <div className="divTableCell">{this.state.parkerRecommendation.description}</div>
                                            </div>
                                            <div className="divTableRow">
                                                <div className="divTableCell">Severity</div>
                                                <div className="divTableCell">
                                                   <Tag color={(this.state.parkerRecommendation.severity || "LOW")==='HIGH'?"red" :
                                                        ((this.state.parkerRecommendation.severity || "LOW") ==="MEDIUM"? "orange": "green")} 
                                                       key={this.state.parkerRecommendation.severity}>
                                                       {this.state.parkerRecommendation.severity}
                                                    </Tag>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col span={8}>
                                        <img style={{ marginLeft: '5%', marginTop: '10%' }} height="100" width="200" src='thumbnail_VA300_product-778x553.png' className="org-image-preview" />
                                        
                                        <Divider />

                                        <Button
                                            type="primary"
                                            style={{ width: '100%', height: '40px', backgroundColor: '#d7970d', color: "black", fontSize: '15px' }}
                                            onClick={() => openInNewTab('https://ph.parker.com/us/en/mobile-directional-contol-valve-va-series/va300-mobile-valve')}
                                        >
                                            Products detail and catalog
                                        </Button>
                                        
                                    </Col> 
                                </Row>
                                <Row>
                                    <Col>
                                    <br/>
                                  
                                    <div style={{width:640}}>
                                        <table className="divTable">  
                                        <colgroup>
                                            {/* <col width="8%"/> */}
                                            <col width="48%"/>
                                            <col width="18%"/>
                                            <col width="28%"/>
                                        </colgroup>
    
                                                {/* <th className="divTableCell">Priority</th> */}
                                                <th className="divTableCell">Possible Cause</th>
                                                <th className="divTableCell">Related SPN</th>
                                                <th className="divTableCell">Suggested Fix</th>
                                        <tbody>
                                            {this.state.parkerActions.map((record:any) => (
                                                
                                                <tr key={record.order}>
                                                    {/* <td className="divTableCell">{record.order}</td> */}
                                                    <td className="divTableCell">{record.cause}</td>
                                                    <td className="divTableCell">{record.relatedSpn}</td>
                                                    <td className="divTableCell">{record.fix}</td>
                                                </tr>
                                            ))} 
                                        </tbody>
                                    </table>
                                    </div>
                                    {/* {this.state.parkerActions.map((record:any) => (
                                    
                                     <div>           
                                        <Divider />
                                        <h3 style={{textDecoration: 'underline'}}>Possible Cause  + {record.order} :</h3>
                                        <span>{record.cause}</span><br />
                                        {record.relatedSpn !== "" && 
                                        <div>
                                            <h3 style={{textDecoration: 'underline'}}>Related DM1 SPN for cause {record.order}</h3>
                                            <span>{record.relatedSpn}</span><br />
                                        </div>
                                        }
                                        <h3 style={{textDecoration: 'underline'}}>Possible fix {record.order} :</h3>
                                        <span>{record.fix}</span><br />
                                        </div>
                                    
                                    ))*/} 
                                    </Col> 
                                </Row>
                            </div>
                            }
                        </Modal>
                    </div>    
                    <Drawer width="700"  title={
            
            <div style={{float:"left"}}><img src={cumminsLogo} alt="Cummins" />Connected Diagnostics&#8482;</div>}
           
         placement="right" onClose={this.onCloseCumminsDiagnostics} visible={this.state.isCumminsDrawerVisible}>
                       { !this.state.loading && this.state.recommendation!=""&&
                        <CumminsResponseView recommendation={this.state.recommendation} assetOrgId={this.state.assetOrgId}></CumminsResponseView>
                       }
            </Drawer>
                    {!(appConfigs.app.enableCumminsDiagnostics) &&                                        
                    <Table rowSelection={rowSelection}
                        data-id="alerts-dm1-faults-data"
                        {...tableConfig}
                        rowKey={(record) => record.alertId}
                        columns={tableColumns}
                        dataSource={tableData}
                        expandable={{
                            expandedRowRender: record => (
                                <Descriptions bordered column={1}>
                                    <Descriptions.Item label="SA">{record.sa}{record.saDescription ? ' - ' + record.saDescription : ''}</Descriptions.Item>
                                    <Descriptions.Item label="SPN">{record.spn}{record.spnDescription ? ' - ' + record.spnDescription : ''}</Descriptions.Item>
                                    <Descriptions.Item label="FMI">{record.fmi}{record.fmiDescription ? ' - ' + record.fmiDescription : ''}</Descriptions.Item>
                                </Descriptions>
                            ),
                            rowExpandable: record => (record.saDescription || record.fmiDescription || record.ocDescription || record.spnDescription),
                        }}
                        className="mt-20"
                    />
                    }

                    {(appConfigs.app.enableCumminsDiagnostics) &&
                    <Table rowSelection={rowSelection}
                        data-id="alerts-dm1-faults-data"
                        {...tableConfig}
                        rowKey={(record) => record.alertId}
                        columns={cumminsDiagnosticsTable}
                        dataSource={tableData}
                        className="mt-20"
                    />
                    }
                    </div>
                } 
            </>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        permissions: state.contextReducer.data,
        alertsState: state.alertsReducer.alertsState
    };
};

export default connect(
    mapStateToProps,
    actions
)(AlertsDm1);
  