import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function getOrgRoles(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,    
    orgId: string,
    searchText?: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/org-role-permissions/" + orgId , {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText
                }
            }
        )
        .then(response => {      
                   
            let data = response.data;
           
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data);
                }
                dispatch({ type: "ORG_ROLE_GET_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

export function getRolePermissions(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,   
    roleId: string
) {
  
    return async (dispatch: any) => {
        Axios.get(

            appConfigs.server.URL + "/ui/api/role-permissions/role/" + roleId , {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {            
            let data = response.data;            
            if (callbackFnSuccess != null) {
                callbackFnSuccess(response.data);
            }            
            dispatch({ type: "ROLE_PERMISSIONS_GET_SUCCESS", payload: {data: data} });                
            }
        )        
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

export function getOrgRolePermissions(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: string,
    roleId: string
) {
  
    return async (dispatch: any) => {
        Axios.get(
            
            appConfigs.server.URL + "/ui/api/org-role-permissions/" + orgId + "/role/" + roleId , {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {            
            let data = response.data;             
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data);
                }
                dispatch({ type: "ORG_ROLE_PERMISSIONS_GET_SUCCESS", payload: {data: data} });                
            }
        )        
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}


export function getCustomerOrgPermissions(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: string    
) {
  
    return async (dispatch: any) => {
        Axios.get(
            
            appConfigs.server.URL + "/ui/api/customer-org-permissions", {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {            
                let data = response.data;             
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data);
                }
                dispatch({ type: "CUSTONER_ROLE_PERMISSIONS_GET_SUCCESS", payload: {data: data} });                
            }
        )        
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

export function rolePermissionMaintOpen(
    me: any, 
    title: string,
    width: number,
    record: any
) {
   
    return (dispatch: any) => {
        dispatch({ type: "ROLE_PERMISSION_MAINT_OPEN", payload: {title: title, width: width, record: record} });
    };
}

export function rolePermissionMaintClose(
    me: any
) {   
    return (dispatch: any) => {
        dispatch({ type: "ROLE_PERMISSION_MAINT_CLOSE" });
    };
}
    
export function roleMaintOpen(
    me: any,
    mode: string,   
    title: string,
    width: number,
    record: any
) {
   
    return (dispatch: any) => {
        dispatch({ type: "ROLE_MAINT_OPEN", payload: {title: title, mode: mode, width: width, record: record} });
    };
}

export function roleMaintClose(
    me: any
) {   
    return (dispatch: any) => {
        dispatch({ type: "ROLE_MAINT_CLOSE" });
    };
}

export function rolesSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "ROLES_SET_SEARCH", searchText: searchText });
    }
}  

export function roleCopyMaintOpen(
    me: any,   
    title: string,
    width: number,
    record: any
) {
   
    return (dispatch: any) => {
        dispatch({ type: "ROLE_COPY_MAINT_OPEN", payload: {title: title, width: width, record: record} });
    };
}

export function roleCopyMaintClose(
    me: any
) {   
    return (dispatch: any) => {
        dispatch({ type: "ROLE_COPY_MAINT_CLOSE" });
    };
}

export function roleStatusMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    record: any,
    orgId:any
) {   
    return async (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL + "/ui/api/org-roles/" + orgId,  
            record,
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {                   
                    callbackFnSuccess(record, response.data);
                }
                
                dispatch({ type: "ROLE_STATUS_MAINT_SUCCESS", payload: {data: response.data} });
            }
        )
        .catch(function (error) {          
            if (callbackFnFailure != null) {
                callbackFnFailure(error);
            }
        });
    }
}

export function getSubmitButton(
    isSubmitButton: boolean 
) {    
    return (dispatch: any) => {
        dispatch({ type: "ROLES_GET_SUBMIT_BUTTON", isSubmitButton: isSubmitButton });
    }
}