import React from "react";
import { Col, Form, Input, message, Row, Select, Tree } from 'antd';
import { connect } from "react-redux";
import * as actions from "../Orgs/actions";
import * as rootActions from "../../actions";
import * as orgActions from "../OrgSelector/actions/";
import { appConfigs } from "../../utils/configurations";
import { FormInstance } from "antd/lib/form";
import { errorResponse, resetFormErrors, successResponse } from "../../utils/apiHelpers/apiHelpers";
import { formInit } from "../../utils/apiHelpers/formHelpers";
import { ErrorAlert, SuccessAlert } from "../../utils/Components/formAlerts";
import { loadingIndicatorEnd, loadingIndicatorStart } from "../../utils/commonHelpers";
import "../OrgSelector/orgSelector.css";


const { Option } = Select;
const { Search } = Input;
const { DirectoryTree } = Tree;

interface Prop {
    context: any,
    orgsList: any,
    getAllCapabilities: Function,
    capabilityAccess: Function,
    orgSelectorTreeExpand: Function,
    orgSelectorTreeCheck: Function,
    orgSelectorTreeSelection: Function,
    orgSelectorTreeOrgsGet: Function,
    orgSelectorTreeMaint: Function,
    orgSelectorSetView: Function,
    orgSelector: any,
    orgSelectorTree: any,
}
interface State {
    allFeatures: any[],
    //selectedOrgs: any[],
    searchValue: string,
    childOrgs: any[],
    clearedOrgs: any[],

    formError?: boolean;
    formErrorMessage?: string[];
    formSuccess?: boolean;
    formSuccessMessage?: string;
    formCommit?: boolean;
}

class OrgMaintSettings extends React.Component<Prop, State> {    
    state:State = {      
        allFeatures: [],
      //  selectedOrgs: [],
        searchValue: "",
        childOrgs: [],
        clearedOrgs: []
    }
    _isMounted = false;
    formRef = React.createRef<FormInstance>();
    onFinish = (values: any)  =>{
        let disabledFeatures:any[]=[];
        if(values.disableFeatures.length > 0){
            values.disableFeatures.map((item:any)=>{
                if(item === "Asset Dashboard")
                {
                    disabledFeatures.push(25);
                }
                else
                {
                    disabledFeatures.push(item);
                }
            });
        }
        resetFormErrors(this, this.formRef.current);

        let clearedOrgs:any[]=[];
        if(JSON.stringify(this.state.childOrgs)!==JSON.stringify(this.props.orgSelectorTree.restrictedOrgs)){
            
            this.props.orgSelectorTree.restrictedOrgs.map((id:any)=>{
                if(this.state.childOrgs.indexOf(id)===-1){
                    clearedOrgs.push(id);
                }
            });
        }
        
        this.props.capabilityAccess(this,this.capabilityAccessSuccess,this.capabilityAccessFail,disabledFeatures,this.state.childOrgs,this.state.clearedOrgs);
    }

    capabilityAccessSuccess = () => {
        resetFormErrors(this, this.formRef.current);
        this.props.orgSelectorTreeOrgsGet(this, null, null, this.props.orgSelectorTree.mode);
        successResponse(this,"Successfully Updated");
    }    
    capabilityAccessFail = (error:any) => {
        errorResponse(this, error);
    }
    componentDidMount(): void {
        this._isMounted = true;
        //this.setState({childOrgs:[]});
        if(this._isMounted){
           
            this.componentUpdate();
        }
       
        
       
    }

    componentWillUnmount(): void {
        this._isMounted = false;
    }

    getAllCapabilitiesSuccess = (data: any) => {
        let capabilities: any[] = [];
        data.map((capabality:any)=>{
            let capabilityItem = {"featureId": capabality.id, "name": capabality.capabilityName};
            capabilities.push(capabilityItem);
        })
        if(this._isMounted){
            this.setState({ allFeatures: capabilities});
        }
    }

   

    componentDidUpdate(prevProps: Prop) {
        if (this.props.orgSelector.instance !== prevProps.orgSelector.instance) {
            this.componentUpdate();
        }
     }

    componentUpdate() {
        if(this.props.orgsList.orgMaint.tabs[1].allCapabilities.length === 0) {
            this.props.getAllCapabilities(this, this.getAllCapabilitiesSuccess, null);
        }
        else {
            this.getAllCapabilitiesSuccess(this.props.orgsList.orgMaint.tabs[1].allCapabilities);
        }
        if ((this.props.orgSelectorTree.treeData.length || 0) === 0 || this.props.orgSelectorTree.refreshOnEntry) {
            if(this._isMounted){
                this.setState({ searchValue: "" });
            }
            this.props.orgSelectorTreeSelection(this, "", "");
            this.props.orgSelectorTreeOrgsGet(this, this.onGetOrgsSuccess, null, this.props.orgSelectorTree.mode);            
        }
        else {
        formInit(this);
        if(this.props.orgSelectorTree && this.props.orgSelectorTree.restrictedOrgs.length>0 ){
           this.setState({childOrgs: this.props.orgSelectorTree.restrictedOrgs});
            if (this.formRef.current != null) {
                this.formRef.current!.resetFields();
                this.formRef.current!.setFieldsValue({
                    disableFeatures : ["Asset Dashboard"],
                    ///disableFeatures : {"featureId": 25, "name": "Asset Dashboard"},
                    
                    
                    //)//this.props.orgSelectorTree.restrictedFeatures
                });
            }
            
         }
        }
    }
  
    onGetOrgsSuccess = (data: any,mode:any)=>{
        let restrictedOrgIds:any[]=[];
        let restrictedFeatures:any[]=[];   
        let restrictedOrgs = data.filter((item:any)=>item.properties&&JSON.parse(item.properties).disableFeatures&&JSON.parse(item.properties).disableFeatures.length>0);
        if(restrictedOrgs.length>0){
            restrictedOrgs.map((item:any)=>restrictedOrgIds.push(item.orgId));
            if(this._isMounted){
                this.setState({childOrgs: restrictedOrgIds})
            };
        restrictedFeatures.push(JSON.parse(restrictedOrgs[0].properties).disableFeatures)
         if (this.formRef.current != null) {
             this.formRef.current!.resetFields();
             this.formRef.current!.setFieldsValue({
        //         disableFeatures: restrictedFeatures.map((item:any)=>{return item.name})//this.props.orgSelectorTree.restrictedFeatures
                    disableFeatures : ["Asset Dashboard"],
             });
         }
        }
    }
        
    arrayDiff = (arr1:any, arr2:any) => {
        let arr = [], 
            diff = [];
    
        for (var i = 0; i < arr1.length; i++) {
            arr[arr1[i]] = true;
        }
    
        for (i = 0; i < arr2.length; i++) {
            if (arr[arr2[i]]) {
                delete arr[arr2[i]];
            } else {
                arr[arr2[i]] = true;
            }
        }
    
        for (var k in arr) {
            diff.push(k);
        }
    
        return diff;
    }

   

    

    onSelect = (selectedKeys:any[], info:any) => {
        this.props.orgSelectorTreeSelection(this, selectedKeys[0]);
    };

    onExpand = (expandedKeys:any) => {
        this.props.orgSelectorTreeExpand(this, expandedKeys);
    };

    onCheckChange = (checkedKeys:any, info:any) => {
        let key:any = info.node.key;
        let checkedStatus = info.checked;
             let clearedOrgs: any[] = [...this.state.clearedOrgs];
             let selectedOrgs: any[] = [...this.state.childOrgs];
             let selectedOrgs1 = this.getChildOrgs(key,selectedOrgs,clearedOrgs,info.checked);
            if(this._isMounted)
            {
                this.setState({childOrgs: selectedOrgs1.childOrgs,
                               clearedOrgs: selectedOrgs1.clearedOrgs });
            }
    };

    getChildOrgs = (orgId: any,childOrgs: any[],clearedOrgs:any[],checked:boolean) =>{
        if(checked){
            if(childOrgs.indexOf(orgId)===-1) {
                childOrgs.push(orgId);
                //this.setState({childOrgs: [...this.state.childOrgs,orgId]});
            }
            if(clearedOrgs.indexOf(orgId)>-1)
            {
                let index = clearedOrgs.indexOf(orgId);
                clearedOrgs.splice(index,1);
            }
        }
        else
        {
            const index = childOrgs.indexOf(orgId);
            if (index > -1) {
                childOrgs.splice(index,1);
                //this.setState({childOrgs: childOrgs});
                }
            if(clearedOrgs.indexOf(orgId)=== -1){
                clearedOrgs.push(orgId);
            }
        }
        
        let selectedOrg:any = {};
            selectedOrg = this.props.orgSelectorTree.treeDataFlat.filter((org:any)=> org.orgId===orgId)[0];
            for (let i= selectedOrg.children.length; i>0; i-- ) {
                this.getChildOrgs(selectedOrg.children[i-1].orgId, childOrgs,clearedOrgs,checked);
            }
        return {"childOrgs":childOrgs, "clearedOrgs": clearedOrgs}
    }
      
    onCheckChangeSuccess = (data:any, orgId:string, checkedKeys:any) => {  
        this.props.orgSelectorTreeCheck(this, checkedKeys);
    };

    onCheckChangeFailure = () => {   
    }      

    getParentKey = (orgId:string, tree:any):string => {
        let parentOrgId;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some((item: any) => item.orgId === orgId)) {
                    parentOrgId = node.orgId;
                } 
                else if (this.getParentKey(orgId, node.children)) {
                    parentOrgId = this.getParentKey(orgId, node.children);
                }
            }
        }
        return parentOrgId;
    };

    onSearchChange = (e: any) => {
        let value = e.target.value;
        const loadingIndicator = (this.props.orgSelectorTree.treeDataFlat.length || 0 ) > 50;
        const me = this;
        if(this._isMounted){
            this.setState({ searchValue: value });
        }
        if (loadingIndicator) {
            loadingIndicatorStart();
        }

        let expandedKeys = 
        
            this.props.orgSelectorTree.treeDataFlat
                .map((record:any) => {
                    if (record.name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                        return this.getParentKey(record.orgId, this.props.orgSelectorTree.treeData);
                    }
                    return null;
                })
                .filter((item:any, i:number, self:any) => 
                    item && self.indexOf(item) === i
                );
        
        setTimeout(function() {
            me.props.orgSelectorTreeExpand(me, expandedKeys);

            if (loadingIndicator) {
                loadingIndicatorEnd();
            }
        }, 100);  
    }

    getExpandedKeys = (treeDataFlat: any, treeDataChecked: any) => {
        const selection = treeDataChecked.reduce((acc: any, elem: any) => {
            const orgFound = treeDataFlat.find((t: any) => t.orgId === elem);
            if(orgFound) {
                const pathId = orgFound.pathId;
                let pathArray = pathId.split('~');
                if(pathArray.length > 1) {
                    const pathArrayNew = pathArray.pop();
                }
                if(pathArray.length > 1) {
                    acc = [...acc, ...pathArray];
                    return acc;
                }
                return acc;                
            }
            return acc;
        },[]);
        
        return selection.filter((elem: any, index: any) => selection.indexOf(elem) === index);
    }


    render() {
        
        let defaultSelection=[];
        const { treeData, treeDataExpanded,restrictedOrgs, restrictedOrgsExpansion,restrictedFeatures } = this.props.orgSelectorTree;
        defaultSelection = restrictedOrgsExpansion;
        let checkedOrgs = this.state.childOrgs;
        defaultSelection = defaultSelection.concat(treeDataExpanded);
        const getTreeData = (data: any) =>
            data.map((item: any) => {
                const index = item.name.toLowerCase().indexOf(this.state.searchValue.toLowerCase());
                const beforeStr = index > - 1 ? item.name.substr(0, index) : "";
                const afterStr = index > - 1 ? item.name.substr(index + this.state.searchValue.length) : "";
                const string = index > - 1 ? item.name.substr(index, this.state.searchValue.length) : "";
                let title =
                    index > -1 ? (
                        <span>
                            {beforeStr}
                            <span className="tree-search-value">{string}</span>
                            {/* {afterStr} +{item.orgId} */} {afterStr}
                        </span>
                    ) : 
                    (
                        // <span>{item.name+item.orgId}</span>
                        <span>{item.name}</span>

                    );
                //title = title +item.orgId + item.name;
                if (item.children) {
                    return { title, key: item.orgId, children: getTreeData(item.children) };
                }

                return {
                    title,
                    key: item.orgId,
                };
            });

        return (
                <>
                <div data-id="orgs-maint-form-messages">
                    {this.state.formSuccess && !this.state.formError && <SuccessAlert data-id="orgs-maint-info-success-message" message={this.state.formSuccessMessage}/>}
                    {this.state.formError && !this.state.formSuccess && <ErrorAlert data-id="orgs-maint-info-error-message" message={this.state.formErrorMessage}/>}
                </div>
                <Form
                    layout="vertical"
                    id="orgMaintSettingsForm"
                    ref={this.formRef}
                    onFinish={this.onFinish}
                > 
                <Row gutter={8}>
                    <Col xs={24} md={24}>
                    <Form.Item
                        name="disableFeatures"
                        label="Disable Features"
                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                    >
                        
                        <Select
                            data-id="orgs-maint-settings-features"
                            mode="multiple"
                            showSearch
                            showArrow={true}
                            allowClear
                            placeholder="Select feature"
                            optionFilterProp="children"
                            className="select-before"
                        >
                            {this.state.allFeatures.map((record: any, index: number) => (
                                <Option key={record.featureId} value={record.featureId}>
                                    {record.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    </Col>
                    </Row>
                
                {/* <Row gutter={8}>
                    <Col xs={24} md={24}>
                    <Form.Item name= "searchOrg" label= "Search Org"> 
                        <Search
                            data-id="orgs-maint-tree-search-field"
                            key="tree-search-field"
                            placeholder="Search by typing org name"
                            size = "middle"
                            allowClear
                            onChange={this.onSearchChange}
                            className="search-field"
                            value={this.state.searchValue}
                        />
                    </Form.Item>   
                    </Col>
                </Row> */}
                <Row gutter={8}>
                    <Col xs={24} md={24}>
                <Form.Item name="orgSelector"
                label="Select Orgs"
               // rules={[{ required: this.state.childOrgs.length == 0, message: "Select atleast one org" }]}
                >  
                    <DirectoryTree
                        data-id="orgs-maint-settings-tree"
                        className="mt-25"
                        checkable
                        showIcon={false}
                        onExpand={this.onExpand}
                        onCheck={this.onCheckChange}
                        onSelect={this.onSelect}
                        checkStrictly={true}                        
                        //checkedKeys={treeDataChecked}
                        checkedKeys={checkedOrgs} //{this.state.childOrgs}
                        expandedKeys={defaultSelection}             
                        treeData={getTreeData(treeData)}     
                               
                />
             </Form.Item>
             </Col>
             </Row>
            
                    </Form>
            </>
        )
    }
}

const mapStateToProps = (state: any) => {
    return {
        context: state.contextReducer.context,
        orgsList: state.orgsReducers.orgsState.orgsList,
        orgSelector: state.orgSelectorReducers.orgSelectorState.orgSelector,
        orgSelectorTree: state.orgSelectorReducers.orgSelectorState.orgSelector.tree
    };
};

export default connect(
    mapStateToProps,
    {...actions,
    ...rootActions,
    ...orgActions}
)(OrgMaintSettings);