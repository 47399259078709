import React from "react";
import { Tooltip, Button } from "antd";

interface Props {
  onPermissionClick: () => void;
}

const DashboardExtra: React.FC<Props> = ({onPermissionClick}) => {
  return (
    <Tooltip title="Set Tab Access">
      <div>
        <Button
          data-id="set-tab-access"
          type="primary"
          onClick={(e) => onPermissionClick()}
        >
          <span>Set Tab Access</span>
        </Button>
      </div>
    </Tooltip>
  );
};

export default DashboardExtra;
