import React, { Component } from "react";
import { connect } from "react-redux";
import { Table,Button,Popover, message, Typography, Popconfirm, Input, Row, Col, Space, Tooltip, Checkbox, Select, Switch } from "antd";
import { PlusOutlined, EllipsisOutlined, EditOutlined, DeleteOutlined, CopyOutlined, TabletOutlined, PauseOutlined, RollbackOutlined } from "@ant-design/icons";
import { appConfigs } from "../../utils/configurations";
import { closePopover, getPermissions } from "../../utils/commonHelpers";
import history from '../../utils/history';
import { errorResponse } from "../../utils/apiHelpers/apiHelpers";
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import BackNavigation from "../../utils/Components/BackNavigation";
import * as actions from "./actions";
import RoleMaint from './RoleMaint';
import RolePermissionMaint from './RolePermissionMaint';
import "./roles.css";
import { string } from "@amcharts/amcharts4/core";

const { Option } = Select;
const { Search } = Input;
const { Text } = Typography;

interface Prop {
    getRolePermissions: Function,   
    getOrgRoles: Function,
    getOrgRolePermissions: Function,
    rolePermissionMaintOpen: Function,
    roleMaintOpen: Function,
    rolesSetSearch: Function,
    rolePermissionMaint: Function,
    roleStatusMaint: Function,
    getSubmitButton: Function,
    context: any,
    permissions: any,
    roleList: any,
    getCustomerOrgPermissions: any    
    //rolePermissionList: any
}

class Roles extends Component<Prop, {}> {

    drawerWidth:number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);
    
    componentDidMount () {
        const { appContext } = this.props.context; 
        this.props.getCustomerOrgPermissions(this, null, null, appContext.orgId);       
        this.props.getOrgRoles(this, null, null, appContext.orgId, this.props.roleList.searchText);                
        //ops-2689        
        //appContext.roleName === "admin" ? this.props.getRolePermissions(this,null,null,"2") : this.props.getRolePermissions(this,null,null,"1");              
        this.props.getRolePermissions(this,null,null,appContext.roleId);
        this.props.getSubmitButton(false);
    }

    onRoleIdChange = (value: string) => {        
        this.props.getRolePermissions(this,null,null,value);
    }

    onSearch = (searchText: string) => {   
        let me = this;
        const { appContext } = this.props.context;
        this.props.rolesSetSearch((searchText || ""));
        setTimeout(function() {
            me.props.getOrgRoles(me, null, null, appContext.orgId, me.props.roleList.searchText);                
        }, 100);      
    }

    onSearchChange = (e: any) => {
        let value = e.target.value;
        this.props.rolesSetSearch((value || ""));
    }

    onRoleEdit = (record:any) => {  
        this.props.roleMaintOpen(this, "Edit Role :: " + record.roleName, this.drawerWidth, record);
    }

    onRoleCopy = (record:any) => {  
        this.props.roleMaintOpen(this, "Copy Role :: " + record.roleName, this.drawerWidth, record);
    }

    confirmStatus = (record:any, appContext:any, isupdate:any, isdelete:any, action:string) => (
        <Popconfirm
            title={"Are you sure to " + (action === "active" ? "active" : "deactive") + " this role?"}
            onCancel={closePopover}
            onConfirm={(e) => {this.onAction(e, record, action)}}
            okText="Yes"
            cancelText="No"
        >
            <div>
                {
                    (isupdate && record.isActive) &&
                    <Tooltip title="Deactivate Role"> 
                        <Button data-id="roles-deactivate-button" type="default" icon={<PauseOutlined />} />
                    </Tooltip>
                }
                {
                    (isupdate && !record.isActive) 
                    &&
                    <Tooltip title="Activate Role"> 
                        <Button data-id="roles-activate-button" type="default" icon={<RollbackOutlined />} />
                    </Tooltip>
                }
               
            </div>
        </Popconfirm>
    );
    
    confirmDeletion = (record:any, isdelete:any) => (
        <Popconfirm
            title={"Are you absolutely sure to delete this role?"}
            onCancel={closePopover}
            onConfirm={(e) => {this.onAction(e, record, "delete-role")}}
            okText="Yes"
            cancelText="No"
        >
            <div>
                {
                    (isdelete) &&
                    <Tooltip title="Delete Role">            
                        <Button data-id="roles-delete-button" type="default" icon={<DeleteOutlined />} />
                   </Tooltip>
                }
            </div>
        </Popconfirm>
    );

    actions = (record:any, appContext:any, contextUser:any, isupdate:any, iscreate:any, isdelete:any) => (
        <Popover
            content={
                <div className="center-div">
                    <Space size="large">
                        {
                            (record.roleName.toLowerCase() === "admin" || record.roleName.toLowerCase() === "user" || record.roleName.toLowerCase() === "guest" || record.roleName.toLowerCase() === "super admin") &&
                            <Tooltip title="View Role">
                                <Button data-id="roles-view-button" type="default" icon={<TabletOutlined />} onClick={(e) => {this.onAction(e, record, "view-role")}}/>               
                            </Tooltip>
                        }
                        {
                            (isupdate && (record.roleName.toLowerCase() !== "admin" && record.roleName.toLowerCase() !== "user" && record.roleName.toLowerCase() !== "guest" && record.roleName.toLowerCase() !== "super admin")) &&
                            <Tooltip title="Edit Role">
                                <Button data-id="roles-edit-button" type="default" icon={<EditOutlined />} onClick={(e) => {this.onAction(e, record, "edit-role")}}/>               
                            </Tooltip>
                        }
                        {
                             (iscreate && isupdate) &&
                             <Tooltip title="Clone Role">
                                 <Button data-id="roles-actions-copy-button" type="default" icon={<CopyOutlined />} onClick={(e) => {this.onAction(e, record, "copy-role")}} />               
                             </Tooltip>
                        }                        
                        {
                            (isupdate && record.isActive 
                                && (record.roleName.toLowerCase() !== "admin" && record.roleName.toLowerCase() !== "user" && record.roleName.toLowerCase() !== "guest" && record.roleName.toLowerCase() !== "super admin")
                            ) &&
                            this.confirmStatus(record, appContext, isupdate, isdelete, "deactive")
                        }
                        { 
                            (isupdate && !record.isActive 
                                && (record.roleName.toLowerCase() !== "admin" && record.roleName.toLowerCase() !== "user" && record.roleName.toLowerCase() !== "guest" && record.roleName.toLowerCase() !== "super admin")
                            ) &&
                            this.confirmStatus(record, appContext, isupdate, isdelete, "active")
                        }

                        {
                            (isdelete === true 
                                && (record.roleName.toLowerCase() !== "admin" && record.roleName.toLowerCase() !== "user" && record.roleName.toLowerCase() !== "guest" && record.roleName.toLowerCase() !== "super admin")
                            ) &&
                            this.confirmDeletion(record, isdelete)
                        }
                    </Space>
                </div>
            }
            title={
                <div className="center-div">Actions</div>
            }
            trigger="click"
        >  
            <Button data-id="users-actions-button" type="default" icon={<EllipsisOutlined />} />
                       
        </Popover>
    );

    onAction = (e:any, record:any, action:string) => {
        e.preventDefault();
       
        if ( action === "add-role") {
            this.props.roleMaintOpen(this, "add-role", "New Role" , this.drawerWidth, {});
            //this.props.rolePermissionMaintOpen(this, "New Role", this.drawerWidth, {});
        }
        if ( action === "edit-role") {
            this.props.roleMaintOpen(this, "edit-role", "Edit Role :: " + record.roleName, this.drawerWidth, record);
            //this.props.getOrgRolePermissions(this,this.onRoleEdit, null, this.props.context.appContext.orgId, record.roleId);
        }
        if ( action === "copy-role") {
            this.props.roleMaintOpen(this, "copy-role", "Clone Role :: " + record.roleName, this.drawerWidth, record);
        }
        if ( action === "view-role") {
            this.props.roleMaintOpen(this, "view-role", "View Role :: " + record.roleName, this.drawerWidth, record);
        }
        if(action === "active"){
            
            let permissionObject = { mode: "update", roleName: record.roleName, 
                                     description:record.description, isActive: true
                                    // isRoleAvailable: true, 
                                   };

            this.props.roleStatusMaint(this, this.onActivateSuccess, this.onStatusActiveFailure, permissionObject, record.orgId);
        }
        if(action === "deactive"){
           
            let permissionObject = { mode: "update", roleName: record.roleName, 
                                     description:record.description, isActive: false
                                     //isRoleAvailable: false, 
                                   };

            this.props.roleStatusMaint(this, this.onDeactivateSuccess, this.onStatusDeactiveFailure, permissionObject, record.orgId);
        }
        else if ( action === "delete-role") {

            let permissionObject = { mode: "delete",
                                     roleName: record.roleName
                                   };
            this.props.roleStatusMaint(this, this.onDeleteSuccess, this.onStatusDeleteFailure, permissionObject, record.orgId);
            //this.props.rolePermissionMaint(this, this.onDeleteSuccess, this.onStatusFailure, permissionObject, record.orgId);
        }
        closePopover();       
    }

    onDeleteSuccess = (record:any) => {

        message.success("Role has been deleted successfully.");          
        this.props.getOrgRoles(this, null, null, this.props.context.appContext.orgId, this.props.roleList.searchText);                         
    };

    onActivateSuccess = (record:any) => {

        message.success("Role has been activated successfully.");
        this.props.getOrgRoles(this, null, null, this.props.context.appContext.orgId, this.props.roleList.searchText);                         
     };

    onDeactivateSuccess = (record:any) => {
        
       message.success("Role has been deactivated successfully.");
       this.props.getOrgRoles(this, null, null, this.props.context.appContext.orgId, this.props.roleList.searchText);                         
    };

    onStatusDeleteFailure = (error: any) => {
         errorResponse(this, error, false); 
    }

    onStatusActiveFailure = (error: any) => {
         errorResponse(this, error, false);
     }

    onStatusDeactiveFailure = (error: any) => {
        errorResponse(this, error, false);
     }

    render() {      
        const { appContext } = this.props.context;        
        const rolespermissions = getPermissions(this.props.permissions.permissions.role_settings);

        if(!this.props.context.user.contextUserIsAdmin && rolespermissions.length > 0 && !rolespermissions[1]) {        
            history.push("/not-found");  
        }

        const { roles } = this.props.roleList.roleMaint.orgRoles.data;
        const { tableRolesConfig, tableRolesData } = this.props.roleList;
       
        let iscreate = rolespermissions[0];
        let isread = rolespermissions[1];
        let isupdate = rolespermissions[2];
        let isdelete = rolespermissions[3];

        const tableColumns = [
            {
                title: "Role Name",
                dataIndex: "roleName",
                width: "20%",
                render: (roleName: string, record: any) => (
                    <>
                        {
                            !(record.isActive) &&
                            <Text type="danger">{roleName}</Text>
                           
                        }
                        {
                            record.isActive &&
                            <span>{roleName}</span>
                        }
                    </>
                )
            },
            {
                title:"Description", 
                dataIndex: "description",                
                width: "30%"
            },
            {
                title:"Org Name", 
                dataIndex: "sourceOrgName",                
                width: "20%"
            },
            {
                title:"Status", 
                dataIndex: "isActive",                
                width: "12%",
                render: (text: any, record: any) => (
                    <>
                   {
                       record.isActive ? "Active" : "Inactive"
                   } 
                   </>
                )                
            },
            {
                title: "Actions",
                key: "action",
                align: "center" as "center",
                render: (text: any, record: any) => (
                    <>
                   {
                        (isupdate === true || isdelete === true) &&                    
                        this.actions(record, appContext, appContext, isupdate, iscreate, isdelete)
                   } 
                   </>
                )
            }
           
        ];

        return(

            <div data-id="roles-container" className="layout-content">
                <Row gutter={8}>
                    <Col sm={24} lg={6}>
                        <div className="content-header page-title">
                            <div style={{ display: "flex" }}>
                                <Tooltip title="Go To Previous Page"> 
                                    <BackNavigation /> 
                                </Tooltip>
                                    Roles
                            </div>
                        </div>
                    </Col>
                    <Col sm={24} lg={18} className="content-header-controls">
                        <Space size="middle">
                            {
                                (isread) &&                            
                                <Search
                                    data-id="users-search-field"
                                    placeholder="Search by role name, org name and active"
                                    enterButton="Search"
                                    size="middle"
                                    allowClear
                                    onSearch={this.onSearch}
                                    onChange={this.onSearchChange}
                                    className="search-field"
                                    value={this.props.roleList.searchText}
                                />
                            }
                            {
                                (iscreate) &&
                                <div className="add-role-button">
                                    <Button data-id="roles-search-new-role-button" type="primary" icon={<PlusOutlined />} onClick={(e) => this.onAction(e, {}, "add-role")}>
                                        <span>Role</span>
                                    </Button>
                                </div>
                             } 
                           
                            {/* <div className="add-role-button">
                                    <Tooltip title="Edit role">  
                                <Button data-id="roles-search-new-role-button" type="primary" icon={<EllipsisOutlined />} onClick={(e) => this.onAction(e, {}, "edit-role")}>
                                    
                                </Button>
                                </Tooltip>
                            </div> */}
                           
                        </Space>
                    </Col>
                </Row>
                <div className="mt-15">
                {
                     (isread) &&                   
                     <Table  
                         data-id="role-permissions-data"
                         {...tableRolesConfig}                            
                         rowKey={(record) => record.roleName}
                         columns={tableColumns}
                         dataSource={tableRolesData}
                     />
                }
                       
                </div>
                <RoleMaint />
                <RolePermissionMaint />
            </div>

        );
    }

}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        permissions: state.contextReducer.data,       
        roleList: state.rolesReducer.rolesState.roleList        
    };
};

export default connect(
    mapStateToProps,
    actions    
)(Roles);