import React, { useEffect, useState } from "react";
import { Drawer, Button, Tabs, Space, Form, Radio, Input, Table } from "antd";
import * as actions from "./actions";
import { connect } from "react-redux";
import { ServiceTruck } from "../FleetOverview2/models";

const { Search } = Input;

interface Props{
    serviceTruckList: any,
    serviceTruckListDrawerVisible: boolean,
    map: any,
    openServiceTruckMaintDrawer: Function,
    closeServiceTruckDrawer: Function,
    openServiceTruckDrawer: Function,
}


const ServiceTrucksListDrawer = (props: Props) => {

    const [serviceTrucks, setServiceTrucks] = useState<ServiceTruck[]>(props.serviceTruckList.assets);
    
    const serviceTrucksProp = props.serviceTruckList.assets

    useEffect(() => {
        setServiceTrucks(serviceTrucksProp)
    }, [serviceTrucksProp]);
    

    const close = () => {
        props.closeServiceTruckDrawer();
    }

    const focusOnServiceTruck = (record: ServiceTruck) => {
        

        if (props.map && record.longitude && record.latitude && record.longitude !== 0 && record.latitude !== 0) {
			const bounds = new google.maps.LatLngBounds();
			const loc = new google.maps.LatLng(record.latitude, record.longitude);
            bounds.extend(loc);
            
            props.map.setZoom(props.map.getZoom() + 1);

            props.map.fitBounds(bounds);
            
            props.map.panToBounds(bounds);
            
            props.closeServiceTruckDrawer();
        }
        
        props.openServiceTruckMaintDrawer(record);
        
    }

    const onSearch = (searchValue: string) => {
        if (searchValue.length > 0) {

            let filteredServiceTrucksList = props.serviceTruckList.assets.filter((item: ServiceTruck) =>
                {
                    return (
                        item.vehicleName.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
                        item.operatorName?.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
                    )
                }
            );
            setServiceTrucks(filteredServiceTrucksList)

        } else {
             setServiceTrucks(props.serviceTruckList.assets)
        }
        
    }

    const onChange = (event:any) => {
        let target = event.target;

        if (target) {
            if (target.value.length > 0) {

                let filteredServiceTrucksList = props.serviceTruckList.assets.filter((item: ServiceTruck) =>
                    {
                        return (
                            item.vehicleName.toLowerCase().indexOf(target.value.toLowerCase()) >= 0 ||
                            item.operatorName?.toLowerCase().indexOf(target.value.toLowerCase()) >= 0
                        )
                    }
                );
                setServiceTrucks(filteredServiceTrucksList)
    
            } else {
                 setServiceTrucks(props.serviceTruckList.assets)
            }
        }
    }


    const columns = [
        {
            title:'Vehicle ID',
            dataIndex:'vehicleName',
            key: 'name',
            sorter: (a:any, b:any) => { return a.VIN.localeCompare(b.VIN)},
            render:(text:any, record:ServiceTruck) => (
                <a onClick={() => {focusOnServiceTruck(record)}}>{ record.vehicleName }</a>
            )
        },  
        {
            title: 'Operator Name',
            dataIndex: 'operatorName',
            key: 'assets',
            sorter: (a: any, b: any) => { if (a.operatorName && b.operatorName) { return a.operatorName.localeCompare(b.operatorName) } },
            render:(text:any, record:ServiceTruck) => (    
                <span>{ record.operatorName==='None' ? '' : record.operatorName }</span>  
            )
        } 
    ];

    
    return (
        
            <Drawer
                visible={props.serviceTruckListDrawerVisible}
                title="Service Trucks List"
                width={"450px"}
                destroyOnClose={true}
                onClose={close}
                zIndex={3}
            >
                {
                    <Search
                        placeholder="Search by Vehicle ID or Operator Name"
                        enterButton="Search"
                        size="middle"
                        allowClear
                        onSearch={onSearch}
                        onChange={onChange}
                        style={{marginBottom: 20, width: '100%'}}
                    /> 
                }
                {
                    <Table pagination={{ showSizeChanger: false, defaultPageSize: 20, simple: true }} dataSource={serviceTrucks} columns={columns} rowKey={(record) => record.VIN} showSorterTooltip={false}/>
                }

            </Drawer>
    
    );

     
}

const mapStateToProps = (state:any) => {

    return {
        serviceTruckList: state.serviceTruckReducer.serviceTruckState.serviceTruckList,
        serviceTruckListDrawerVisible: state.serviceTruckReducer.serviceTruckState.serviceTruckListDrawerVisible,
        map: state.serviceTruckReducer.serviceTruckState.map,
    }

}


export default connect(
    mapStateToProps,
    actions
)(ServiceTrucksListDrawer);
