import React from "react";
import { Drawer, Button, Space, Tooltip, message, Modal } from "antd";
import { PlusOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";

import { connect } from "react-redux";
import * as actions from "../actions";
import history from '../../../utils/history';

import SignalMaintInfo from "./SignalMaintInfo";

import { appConfigs } from "../../../utils/configurations";
// import { T } from "antd/lib/upload/utils";

interface Prop {
    signalMaintClose: Function,  
    signalMaintSetRecord: Function,
    getSignal: Function,
    signalMaint: any,
    signals: any,
    signalList: any,
    templateDetails: any,
    iscreate: boolean,
    isread: boolean,
    isdelete: boolean,
    iscreateRules: boolean,
    isreadRules: boolean,
    isupdateRules: boolean,
    isdeleteRules: boolean,
    iscreateOrUpdateTemplate: boolean,
    isGlobalTemplate: boolean,
    context: any
}
interface State {
    isModalVisible: boolean,
    canAddress: Number,
}
class SignalMaint extends React.Component<Prop, State> {

    state: State = {
        isModalVisible: false,
        canAddress: 39 //initial value set to 39. If any change to that will be handled by this state variable
    };
   
    getTemplateId() {
        const pathnameArr = history.location.pathname.split("/");
        return pathnameArr[pathnameArr.length - 1];
    }

    onDrawerClose = () => {
        this.props.signalMaintClose(this);
    };

    gotoSignal = (e:any, direction:string) => {
        e.preventDefault();

        const 
            tableData = this.props.signalList.tableData;
        let 
            currentId = this.props.signalMaint.record.signalId,
            currentRecordIndex = -1;

        tableData.forEach((record: any, index: number) => {
            if (record.signalId === currentId) {
                currentRecordIndex = index;
            }
        });

        if (currentRecordIndex !== -1) {
            if (direction === "next" ) {
                currentRecordIndex = (currentRecordIndex === tableData.length - 1 ? 0 : currentRecordIndex + 1);
            }
            if (direction === "previous") {
                currentRecordIndex = (currentRecordIndex === 0 ? tableData.length - 1 : currentRecordIndex - 1);
            }
            
            this.props.getSignal(this, null, null, this.getTemplateId(), tableData[currentRecordIndex].signalId);
        }
        else {
            message.error("Unable navigate to " + direction + " signal");
        }
    };

    addSignal = (e:any) => {
        e.preventDefault();
        this.props.signalMaintSetRecord(this, {});
    }

 showModal = () => {
    this.setState({
        isModalVisible: true,
    });
  };

  handleOk = () => {
    this.setState({
        
        isModalVisible: false,
    });
  };

  handleCancel = () => {
    this.setState({
        isModalVisible: false,
    });
  };    
    render() {
        const { title, width, visible } = this.props.signalMaint;
        const { templateRecord, activeTab, tabs} = this.props.templateDetails;

        let disableAddSignal = (
                (templateRecord.allowedMaxNoOfSignals && templateRecord.noOfSignals &&
                templateRecord.allowedMaxNoOfSignals <= templateRecord.noOfSignals)
             || ( this.props.signals.signalCollectionType === "Command" && templateRecord.orgCmdPgnSpns === '-')
            );
        let disableTooltip = this.props.signals.signalCollectionType === "Command" && templateRecord.orgCmdPgnSpns==='-'?
            "Local org does not support remote control" :
             "Exceeds the max no. of signals defined in the quota of assets applied to the template";
        let addSignalTooltip = disableAddSignal ?  disableTooltip : "Add Signal";
        let firstControllerSa = 39;
        if (this.props.signalMaint.commandcontrollers !== null) {
            if (this.props.signalMaint.commandControllers.data !== null && this.props.signalMaint.commandControllers.data.length !== 0 ) {
                let firstController = this.props.signalMaint.commandControllers.data[0];
                if (firstController !== null) {
                    firstControllerSa = firstController.cmdControllerSaId;
                } 
            }
        }

        return (
            <Drawer
                data-id="signal-maint-drawer" 
                title={title}
                width={width}
                onClose={this.onDrawerClose}
                visible={visible}
                zIndex={3}
                destroyOnClose={true}
                footer={        
                    <>     
                        <Space size="middle" style={{ float: 'left' }}>
                            {
                                Object.keys(this.props.signalMaint.record).length !== 0 &&
                                (this.props.signalList.tableData.length || 0) > 1 && this.props.isread &&
                                <>
                                    <Tooltip title="Previous Signal">
                                        <Button data-id="signal-maint-previous-signal-button" type="default" onClick={(e) => {this.gotoSignal(e, "previous")}}>
                                            <LeftOutlined /><span className="pl-5">Signal</span>
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title="Next Signal">
                                        <Button data-id="signal-maint-next-signal-button" type="default" onClick={(e) => {this.gotoSignal(e, "next")}}>
                                            <span className="pr-5">Signal</span><RightOutlined /> 
                                        </Button>
                                    </Tooltip> 
                                </>                              
                            }
                            {
                                this.props.iscreate && this.props.signals.signalCollectionType !== "GPS" &&
                                this.props.signals.signalCollectionType !== "IQAN Diagnostics" &&
                                <Tooltip title={addSignalTooltip}>
                                    <Button disabled={disableAddSignal} data-id="signal-maint-new-signal-button" type="default" icon={<PlusOutlined />} onClick={(e) => {this.addSignal(e)}}>
                                        <span className="pl-5">Signal</span>
                                    </Button>              
                                </Tooltip>   
                            }                        
                        </Space>      
                        <Space size="middle" style={{ float: 'right' }}>
                            <Button data-id="signal-maint-close-button" type="default" onClick={this.onDrawerClose}>
                                {appConfigs.settings.drawers.closeButtonText}
                            </Button>
                            {   
                                this.props.signals.signalCollectionType !== "Command" && 
                                (this.props.isGlobalTemplate && this.props.context.appContext.roleName.toLowerCase() === "admin" ||
                                    !this.props.isGlobalTemplate && (this.props.iscreateOrUpdateTemplate || 
                                     this.props.iscreateRules || this.props.isupdateRules || this.props.isdeleteRules)) &&
                                <Button data-id="signal-maint-submit-button" type="primary" form="signalMaintInfoForm" key="submit" htmlType="submit">
                                    {appConfigs.settings.form.submitButtonText}
                                </Button>
                            }
                            {   
                                this.props.signals.signalCollectionType === "Command" && 
                                firstControllerSa != 37 &&
                                (this.props.isGlobalTemplate && this.props.context.appContext.roleName.toLowerCase() === "admin" ||
                                    !this.props.isGlobalTemplate && (this.props.iscreateOrUpdateTemplate || 
                                     this.props.iscreateRules || this.props.isupdateRules || this.props.isdeleteRules)) &&
                                <>
                                <Button type="primary" onClick={this.showModal}>
                                   {appConfigs.settings.form.submitButtonText}
                                </Button>
                                <Modal title="Confirm CAN Address" visible={this.state.isModalVisible} 
                                //okButtonProps= { type:'primary', form:'signalMaintInfoForm', key: 'submit', htmlType: 'submit'}
                                onOk={this.handleOk}
                                onCancel={this.handleCancel} 
                                footer={[
                                    <Button onClick={this.handleCancel}>No</Button>,
                                    <Button key="submit" type="primary" onClick={this.handleOk} data-id="signal-maint-submit-button" form="signalMaintInfoForm" htmlType="submit">
                                      Yes
                                    </Button>,
                                  ]}
                                > 
                                    <p>Are you sure the CAN address is the destination address on the controller?</p>                            
                                </Modal>
                                </>
                            }
                            {
                                this.props.signals.signalCollectionType === "Command" && 
                                firstControllerSa === 37 &&   
                                (this.props.isGlobalTemplate && this.props.context.appContext.roleName.toLowerCase() === "admin"|| !this.props.isGlobalTemplate && this.props.iscreateOrUpdateTemplate) &&
                                <Button type="primary" form="signalMaintInfoForm" key="submit" htmlType="submit">
                                    {appConfigs.settings.form.submitButtonText}
                                </Button>
                            }
                        </Space>
                    </>
                }
            >
                {
                    (this.props.iscreateOrUpdateTemplate|| this.props.isread || this.props.iscreateRules || this.props.isupdateRules || this.props.isdeleteRules || this.props.isreadRules) &&
                    <SignalMaintInfo accept={this.handleOk} cancel={this.handleCancel} iscreateOrUpdateTemplate ={this.props.iscreateOrUpdateTemplate} iscreate={this.props.iscreate} isread={this.props.isread}
                    iscreateRules={this.props.iscreateRules} isupdateRules={this.props.isupdateRules} isreadRules={this.props.isreadRules} isdeleteRules = {this.props.isdeleteRules}
                    isGlobalTemplate = {this.props.isGlobalTemplate} isadmin={this.props.context.appContext.roleName.toLowerCase() === "admin"}/>
                }
               
            </Drawer>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        templateDetails: state.templateDetailsReducers.templateDetailsState,
        signals: state.templateDetailsReducers.templateDetailsState.signals,
        signalMaint: state.templateDetailsReducers.templateDetailsState.signals.signalMaint,
        signalList: state.templateDetailsReducers.templateDetailsState.signals.signalList
    };
};

export default connect(
    mapStateToProps,
    actions
)(SignalMaint);