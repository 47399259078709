import { produce as immerProduce } from "immer";
import { matchWords } from "../../../utils/commonHelpers";
import { initialState } from "./state";

export function fleetOverviewState(state = initialState, action: any) {

    switch (action.type) {
        case "FLEET_OVERVIEW_ASSET_DRAWER_STATE":
            return immerProduce(state, draft => {
                draft.assetDrawer.show = action.state;
                if (action.title) {
                    draft.assetDrawer.title = action.title;
                }
                if (action.title) {
                    draft.assetDrawer.mode = action.mode;
                }
                if (action.state) {
                    draft.assetDrawer.width = Math.min(window.innerWidth, 440);
                }
            });
        case "FLEET_OVERVIEW_CLUSTER_STATE":
            return immerProduce(state, draft => {
                draft.assetDrawer.cluster.show = action.state;
            });
        case "FLEET_OVERVIEW_CLUSTER_INFO_SEARCH":
            return immerProduce(state, draft => {
                const searchText = action.searchText.toLowerCase().trim();
                draft.assetDrawer.cluster.filteredAssets =
                    state.assetDrawer.cluster.assets
                        .filter((asset: any, index: number) => (
                            (asset.name || asset.mastertag).toLowerCase().includes(searchText) ||
                            asset.orgName.toLowerCase().includes(searchText)
                        ));
            });
        case "FLEET_OVERVIEW_CLUSTER_SELECTED":
            return immerProduce(state, draft => {
                draft.assetDrawer.cluster.assets =
                    state.fleet.filteredAssets
                        .filter((asset: any, index: number) => (
                            action.ids.includes(asset.id)
                        ));
                draft.assetDrawer.cluster.filteredAssets = [...draft.assetDrawer.cluster.assets];
                draft.assetDrawer.cluster.zoomTo = action.zoomTo;
            });
        case "FLEET_OVERVIEW_ASSET_SEARCH_STATE":
            return immerProduce(state, draft => {
                draft.assetDrawer.assetSearch.show = action.state;
            });
        case "FLEET_OVERVIEW_ASSET_SEARCH_SELECTED":
            return immerProduce(state, draft => {
                draft.assetDrawer.assetSearch.assets = state.fleet.filteredAssets;
            });
        case "FLEET_OVERVIEW_ASSET_SEARCH_SEARCH":
            return immerProduce(state, draft => {
                const searchText = action.searchText.toLowerCase().trim();
                draft.assetDrawer.assetSearch.assets =
                    state.fleet.filteredAssets
                        .filter((asset: any, index: number) => {
                            if (asset.name.toLowerCase().includes(searchText.toLowerCase()) || asset.mastertag.toLowerCase().includes(searchText.toLowerCase()) || (asset.tagsSorted != null ? matchWords(asset.tagsSorted?.join().toLowerCase(), searchText).length > 0 : false)) return asset
                        });

            });
        case "FLEET_OVERVIEW_GLOBAL_SEARCH_SUCCESS":
            return immerProduce(state, draft => {
                draft.loading = false;
                draft.assetDrawer.assetSearch.assets = action.assets;
            });
        case "FLEET_OVERVIEW_FILTERS_TOGGLE":
            return immerProduce(state, draft => {
                draft.assetDrawer.cluster.show = false;
                draft.assetDrawer.assetSearch.show = false;

                updateFilter(state, draft, action.filterName);
                //
                //OPS-1143 
                //Going around the known Google Maps bug
                if (action.mapActions && (draft.fleet.filteredAssets.length || 0) !== 0) {
                    setTimeout(function () {
                        action.mapActions.zoomToFit();
                    }, 500);
                }
                //
            });
        case "FLEET_OVERVIEW_FILTERS_RESET":
            return immerProduce(state, draft => {
                resetFilters(state, draft);
                //
                //OPS-1143 
                //Going around the known Google Maps bug
                if (action.mapActions && (draft.fleet.filteredAssets.length || 0) !== 0) {
                    setTimeout(function () {
                        action.mapActions.zoomToFit();
                    }, 500);
                }
                //
            });
        case "TOGGLE_MARKER_TOOLTIP":
            return immerProduce(state, draft => {
                draft.tooltip.visibility = action.visibility;
            });
        case "UPDATE_TOOLTIP_MARKER":
            return immerProduce(state, draft => {
                draft.tooltip.marker = action.marker;
                draft.tooltip.gmarker = action.gmarker;
            });
        case "UPDATE_TOOLTIP_ASSET":
            return immerProduce(state, draft => {
                draft.tooltip.asset = action.asset;
            });
        case "UPDATE_TOOLTIP_OVERLAY":
            return immerProduce(state, draft => {
                draft.tooltip.overlay = action.overlay;
            });
        case "ASSET_DOWN_SUBMITTED_SUCCESS":
            return immerProduce(state, draft => {
                // draft.tooltip.asset['reportDescription'] = action.record.description;
            });
            case "FLEET_OVERVIEW_MAP_SUCCESS":
                return immerProduce(state, draft => {
                    draft.loading = false;
                    draft.fleet.assets = action.assets;
                    draft.preSearchAssetList = action.assets
                    if (action.originator === "useEffect") {
                        let mapFilters = null
                        if (action.preferences != null) {
                            mapFilters = state.filterEnabled ? action.preferences.map : state.filters;
                        }
                       //let mapFilters = state.filterEnabled ? action.preferences.map: state.filters;
                       if (mapFilters != null &&
                        (mapFilters.critical || mapFilters.warning
                            || mapFilters.info || mapFilters.dm1)) {
                        draft.filters = {
                            critical: mapFilters.critical,
                            warning: mapFilters.warning,
                            info: mapFilters.info,
                            dm1: mapFilters.dm1
                        };
                            draft.fleet.filteredAssets = 
                            draft.fleet.assets
                                .filter((asset:any, index:number) => (
                                    asset.position &&
                                    (
                                        (!draft.filters.critical && !draft.filters.warning && !draft.filters.info && !draft.filters.dm1) ||
                                        (
                                            !(!draft.filters.critical && !draft.filters.warning && !draft.filters.info && !draft.filters.dm1) &&
                                            (
                                                (draft.filters.critical && asset.recentCriticalCount > 0) ||
                                                (draft.filters.warning && asset.recentWarningCount > 0) ||
                                                (draft.filters.info && asset.recentInfoCount > 0) ||
                                                (draft.filters.dm1 && asset.recentDm1Count > 0)
                                            )
                                        )
                                    )
                                ));          
                            
                        }
                        else
                        {
                        draft.filters = {
                            critical: false,
                            warning: false,
                            info: false,
                            dm1: false
                        };
                    draft.fleet.filteredAssets = action.assets.filter((asset:any) => asset.position);
                    }
                    updateFilterCounts(draft);
                    }              
                });
        case "FLEET_PREFERED_SIGNALS_SUCCESS":
            return immerProduce(state, draft => {
                draft.preferedSignals.assetId = action.asset.assetId;
                draft.preferedSignals.signals = action.asset.preferedSignals;
            });
        case "SET_SEARCH_ZOOM_TO_FIT_FITLER_MAP":
            return immerProduce(state, (draft) => {
                draft.assetDrawer.cluster.show = false;
                draft.fleet.assets = action.payload.filterZoomTofit;
                draft.fleet.filteredAssets = action.payload.filterZoomTofit;
                
                if ((draft.fleet.filteredAssets.length || 0) !== 0) {
                    setTimeout(function () {
                        action.payload.mapActions.zoomToFit();
                    }, 500);
                }

            });
        case "SET_SEARCH_ZOOM_TO_FIT_SEARCH_VALUE":
            return immerProduce(state, (draft) => {
                draft.assetSearchValue = action.payload.assetSearchValue;
            });
        case "SET_SEARCH_ZOOM_TO_FIT_SEARCH_FILTER":
            return immerProduce(state, (draft) => {
                draft.assetSearchFilters = action.payload.assetSearchFilters;
            });
        case "SET_PRE_SEARCH_ZOOM_TO_FIT_ASSET_LIST":
            return immerProduce(state, (draft) => {
                draft.preSearchAssetList = action.payload.preSearchAssetList;
            });
        case "SET_FLEET_OVERVIEW_MAP":
            return immerProduce(state, (draft) => {
                draft.map = action.payload.map;
            });
        default:
            return state;
    }
}

const resetFilters = (state: any, draft: any) => {
    draft.filters.critical = false;
    draft.filters.warning = false;
    draft.filters.info = false;
    draft.filters.dm1 = false;

    draft.fleet.filteredAssets = state.fleet.assets;
}

const updateFilter = (state: any, draft: any, filterName: string) => {
    draft.filters = filterName;
    draft.filterEnabled = false;
    draft.fleet.filteredAssets =
        state.fleet.assets
            .filter((asset: any, index: number) => (
                asset.position &&
                (
                    (!draft.filters.critical && !draft.filters.warning && !draft.filters.info && !draft.filters.dm1) ||
                    (
                        !(!draft.filters.critical && !draft.filters.warning && !draft.filters.info && !draft.filters.dm1) &&
                        (
                            (draft.filters.critical && asset.recentCriticalCount > 0) ||
                            (draft.filters.warning && asset.recentWarningCount > 0) ||
                            (draft.filters.info && asset.recentInfoCount > 0) ||
                            (draft.filters.dm1 && asset.recentDm1Count > 0)
                        )
                    )
                )
            ));
}

const updateFilterCounts = (draft: any) => {

    draft.filterCounts.critical = 0;
    draft.filterCounts.dm1 = 0;
    draft.filterCounts.info = 0;
    draft.filterCounts.warning = 0;

    draft.fleet.assets.forEach((asset: any) => {
        if (asset.recentCriticalCount > 0) {
            draft.filterCounts.critical += 1;
        }
        if (asset.recentDm1Count > 0) {
            draft.filterCounts.dm1 += 1;
        }
        if (asset.recentInfoCount > 0) {
            draft.filterCounts.info += 1;
        }
        if (asset.recentWarningCount > 0) {
            draft.filterCounts.warning += 1;
        }
    });
}