import { Space, Input, message, Divider } from "antd";
import { EditOutlined, SaveOutlined, UndoOutlined } from "@ant-design/icons";
import React, { useState } from "react";

interface Props {
  title: string;
  dashboadId: number;
  permissionSet: any;
  onTitleUpdate: (dashboardId: number, title: string) => void
}

const DashboardTitleText: React.FC<Props> = (props) => {
  const { title, dashboadId, onTitleUpdate, permissionSet } = props;
  const [editTitle, setEditTitle] = useState<boolean>(false);
  const [newtitle, setNewTitle] = useState<string>(title);

  const onEditClick = (e: any) => {
    // console.log(dashboadId);
    e.stopPropagation();
    setEditTitle(true);
  };

  const saveTitle = (e: any) => { 
    e.stopPropagation();  
    if(newtitle.length === 0) {
      message.info("Dashboard title can not be empty");
      return;
    } 
    onTitleUpdate(dashboadId, newtitle);
    setEditTitle(false);
  }

  const undoTitle = (e: any) => {
    e.stopPropagation();
    setNewTitle(title);
    setEditTitle(false); 
  }

  if (!editTitle) {
    return (
      <Space size="small">
        {title}
        {
          permissionSet[2] && <><div style={{ backgroundColor: '#dedede', minWidth: '1px', minHeight: '20px', marginRight: '0px', marginLeft: '0px' }} /> <EditOutlined style={{ marginLeft: '0px', paddingLeft: '0px' }} onClick={(e) => onEditClick(e)} /></>
        }        
      </Space>
    );
  }

  return (
    <Space size="small">
      <Input
        size="small"
        value={newtitle}
        maxLength={16}
        onClick={(event) => event.stopPropagation()}
        onChange={(event) => setNewTitle(event.target.value)}
      />
      <div style={{ backgroundColor: '#dedede', minWidth: '1px', minHeight: '20px', marginRight: '0px', marginLeft: '0px' }} />
      <SaveOutlined onClick={(e) => saveTitle(e)} />
      <UndoOutlined onClick={(e) => undoTitle(e)} />
    </Space>
  );
};

export default DashboardTitleText;
