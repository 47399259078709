import ReactDOMServer from "react-dom/server";
import MarkerClusterer, {
	MarkerClustererOptions,
} from "@google/markerclustererplus";
import { Cluster } from "@google/markerclustererplus/dist/cluster";
import CLUSTER_STYLES from "./cluster-styles";
export interface Marker {
	tooltip?: any;
	tooltipHandler?: string;
	label?: google.maps.MarkerLabel;
	timestamp?: any;
	color?: string;
	value?: any;
	position: google.maps.LatLngLiteral;
	id: string;
	icon?: ReturnType<google.maps.Marker["getIcon"]>;
	optimized?: Boolean;
	show?: Boolean;
	enabled?: Boolean;
	signalId?: string;
}

interface ClusterActions {
	zoomTo: () => void;
}

export type ServiceTruckMarker = Marker & { VIN: string};

export interface ClusterClickHandler {
	(ids: ClusterIds, actions: ClusterActions): void;
}

const DEFAULT_CLUSTER_OPTIONS: MarkerClustererOptions = {
	imagePath:
		"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
	styles: CLUSTER_STYLES,
	minimumClusterSize: 2,
	maxZoom: 20,
	zoomOnClick: false,
	averageCenter: true,
};

type ClusterIds = Array<Marker["id"]>;

interface OptionsWithClustering {
	clusterMarkers: boolean;
	onClusterClick?: ClusterClickHandler;
	eventsOnMap?: boolean
}

interface OptionsNoClustering {
	clusterMarkers: boolean;
	calculator?: undefined;
	eventsOnMap?: boolean
	// onClusterClick?: ClusterClickHandler;
}

export type ClusteringOptions = OptionsWithClustering | OptionsNoClustering;

interface MarkerStore {
	marker: google.maps.Marker;
	tooltip?: any;
	label?: google.maps.MarkerLabel;
}

type MarkerArray = Array<MarkerStore & { id: Marker["id"] }>;

function setIcon(gmarker: google.maps.Marker, marker: Marker) {
	if (marker.icon) gmarker.setIcon(marker.icon);
}

export default class MarkerManager {
	private _assets: any;
	public _infowindow: any;
	private _markers: Map<string, MarkerStore> = new Map();
	private _clusterer?: MarkerClusterer;
	private _clusterListener?: google.maps.MapsEventListener;
	private _bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
	private _options: any;
	private _serviceTruckMarkers: Map<string,MarkerStore> = new Map(); 
	private _serviceTrucks: any;
	private _toolTipOptions: any;


	constructor(map: google.maps.Map, options?: any, geofenceOptions?: any, toolTipOptions?: any) {
		this._options = options
		this._toolTipOptions = toolTipOptions
		if (options?.clusterMarkers) {
			this.setupClusterer(map, options);
		}
	}

	getMarkers(): MarkerArray {
		const result: MarkerArray = [];

		this._markers.forEach(({ marker, tooltip, label }, id) => {
			result.push({
				id,
				marker,
				tooltip,
				label,
			});
		});

		return result;
	}

	private tooltipManager(
		map: google.maps.Map,
		gmarker: google.maps.Marker,
		marker: Marker
	) {

		if(this._toolTipOptions){

			this._toolTipOptions.toggleMarkerToolTip(!this._toolTipOptions.tooltipVisibility)
			this._toolTipOptions.updateToolTipMarkerDetails(marker, gmarker)
			
		} else {

			let tooltip: any;
			let tooltipHandler: any;

			if ((marker.tooltipHandler || "") !== "") {
				tooltipHandler = document.querySelectorAll(marker.tooltipHandler || "");
				if (tooltipHandler) {
					window.sessionStorage.setItem("mapTooltipGenerator", marker.id);
					tooltipHandler[0].click();
					tooltip = window.sessionStorage.getItem("mapTooltipGenerator");
					window.sessionStorage.removeItem("mapTooltipGenerator");
				}
			}

			if ((marker.tooltip || "") !== "") {
				for (const asset of this._assets) {
					if (asset.id === marker.id) {
						try {
							tooltip = ReactDOMServer.renderToString(asset.tooltip);
						} catch (error) {}
					}
				}
			}

			if (this._infowindow) {
				this._infowindow.close();
			}

			this._infowindow = new google.maps.InfoWindow({});
			this._infowindow.setContent(tooltip);
			this._infowindow.open(map, gmarker);

			if (tooltipHandler) {
				window.sessionStorage.removeItem("mapTooltipLink");
				setTimeout(function () {
					const tooltipAnchors: any = document.querySelectorAll(
						"[data-id=map-marker-tooltip] a"
					);
					for (let i = tooltipAnchors.length; i--; ) {
						let href = tooltipAnchors[i].href.replace(window.location.origin, "");
						tooltipAnchors[i].onclick = function (e: any) {
							e.preventDefault();
							window.sessionStorage.setItem("mapTooltipLink", href);
							let button: HTMLElement = document.querySelectorAll(
								marker.tooltipHandler || ""
							)[0] as HTMLElement;
							button.click();
						};
						tooltipAnchors[i].setAttribute("href", "#");
					}
				}, 250);
			}

		}
		

		
	}

	public updateMarkers(
		markers: Marker[],
		map: google.maps.Map,
		options?: ClusteringOptions,
	) {
		const newMap: Map<string, MarkerStore> = new Map();
		this._bounds = new google.maps.LatLngBounds();
		this._assets = markers;

		if (!options?.clusterMarkers) {
			this.clearClusterer()
		} else if (!this._clusterer && options?.clusterMarkers) {
			this.setupClusterer(map, this._options);
		}

		const updateMarker = (existing: MarkerStore, val: Marker) => {
			existing.marker.setPosition(val.position);
			setIcon(existing.marker, val);
			existing.tooltip = val.tooltip;

			newMap.set(val.id, existing);
			// Delete from old map, this will leave only unneeded markers in the old map
			this._markers.delete(val.id);

			if (!options?.clusterMarkers) {
				if (this._infowindow) {
					this._infowindow.close();
				}
			}
		};

		const addMarker = (marker: Marker, map: google.maps.Map) => {
			const me = this;
			const gmarker = new google.maps.Marker({
				position: marker.position,
				label: marker.label,
			});
			setIcon(gmarker, marker);

			newMap.set(marker.id, { marker: gmarker, tooltip: marker.tooltip });
			if (!options?.clusterMarkers) {
			    gmarker.setMap(map);
			}

			if (!options?.clusterMarkers) {

				if (options?.eventsOnMap) {
					google.maps.event.addListener(gmarker, "mouseover", function () {
						me.tooltipManager(map, gmarker, marker);
					});
				} else {
					google.maps.event.addListener(gmarker, "click", function () {
						me.tooltipManager(map, gmarker, marker);
					});
				}

				// google.maps.event.addListener(gmarker, "mouseout", function () {
				// 	me._infowindow.close();
				// });
			} else {
				gmarker.addListener("click", function () {
					me.tooltipManager(map, gmarker, marker);
				});
			}

			this._clusterer?.addMarker(gmarker);
		};

		const deleteMarkers = (markers: IterableIterator<MarkerStore>) => {
			const markerArray = Array.from(markers);
			markerArray.forEach(({ marker }) => marker.setMap(null));
			this._clusterer?.removeMarkers(markerArray.map(({ marker }) => marker));
		};

		for (const marker of markers) {
			if (marker) {
				const existing = this._markers.get(marker.id);
				this._bounds.extend(marker.position);
				if (marker.show === undefined || marker.show) {
					if (existing) {
						updateMarker(existing, marker);
					} else {
						addMarker(marker, map);
					}
				}
			}
		}

		deleteMarkers(this._markers.values());
		this._markers = newMap;
	}

	public tooltipServiceTruck(map: google.maps.Map, gmarker: google.maps.Marker, marker: ServiceTruckMarker){
		let tooltip: any;
		let tooltipHandler: any;

		if ((marker.tooltipHandler || "") !== "") {
			tooltipHandler = document.querySelectorAll(marker.tooltipHandler || "");
			if (tooltipHandler) {
				window.sessionStorage.setItem("mapTooltipGenerator", marker.VIN);
				tooltipHandler[0].click();
				tooltip = window.sessionStorage.getItem("mapTooltipGenerator");
				window.sessionStorage.removeItem("mapTooltipGenerator");
			}
		}

		if ((marker.tooltip || "") !== "") {
			for (const asset of this._serviceTrucks) {
				if (asset.VIN === marker.VIN) {
					try {
						tooltip = ReactDOMServer.renderToString(asset.tooltip);
					} catch (error) {}
				}
			}
		}

		if (this._infowindow) {
			this._infowindow.close();
		}

		this._infowindow = new google.maps.InfoWindow({});
		this._infowindow.setContent(tooltip);
		this._infowindow.open(map, gmarker);

		if (tooltipHandler) {
			window.sessionStorage.removeItem("mapTooltipLink");
			setTimeout(function () {
				const tooltipAnchors: any = document.querySelectorAll(
					"[data-id=map-marker-tooltip] a"
				);
				for (let i = tooltipAnchors.length; i--; ) {
					let href = tooltipAnchors[i].href.replace(window.location.origin, "");
					tooltipAnchors[i].onclick = function (e: any) {
						e.preventDefault();
						window.sessionStorage.setItem("mapTooltipLink", href);
						let button: HTMLElement = document.querySelectorAll(
							marker.tooltipHandler || ""
						)[0] as HTMLElement;
						button.click();
					};
					tooltipAnchors[i].setAttribute("href", "#");
				}
			}, 250);
		}



	}

	public updateServiceTruckMarkers(markers: ServiceTruckMarker[], map: google.maps.Map){

		const newMap: Map<string, MarkerStore> = new Map();
		this._serviceTrucks = markers;

		const updateMarker = (existing: MarkerStore, val: Marker) => {
			existing.marker.setPosition(val.position);
			setIcon(existing.marker, val);
			existing.tooltip = val.tooltip;

			newMap.set(val.id, existing);
			// Delete from old map, this will leave only unneeded markers in the old map
			this._serviceTruckMarkers.delete(val.id);

		}

		const addMarker =  (marker: ServiceTruckMarker, map: google.maps.Map) => {

			const me = this;
			const gmarker = new google.maps.Marker({
				position: marker.position,
				label: marker.label,
			});
			setIcon(gmarker, marker);
			newMap.set(marker.VIN, { marker: gmarker, tooltip: marker.tooltip });
			gmarker.setMap(map);

			gmarker.addListener("click", function () {
				me.tooltipServiceTruck(map, gmarker, marker);
			});

		}

		const deleteMarkers = (markers: IterableIterator<MarkerStore>) => {
			const markerArray = Array.from(markers);
			markerArray.forEach(({ marker }) => marker.setMap(null));
		};

		for (const marker of markers) {
			if (marker) {
				const existing = this._serviceTruckMarkers.get(marker.id);
				if (marker.show === undefined || marker.show) {
					if (existing) {
						updateMarker(existing, marker);
					} else {
						addMarker(marker, map);
					}
				}
			}
		}

		deleteMarkers(this._serviceTruckMarkers.values());
		this._serviceTruckMarkers = newMap;
	}

	public destroy() {
		this._clusterListener?.remove();
	}

	public clearClusterer() {
		this.destroy()
		this._clusterer = undefined
	}

	private setupClusterer(map: google.maps.Map, opts: OptionsWithClustering) {
		this._clusterer = new MarkerClusterer(map, [], DEFAULT_CLUSTER_OPTIONS);
		if (opts.onClusterClick) {
			const onClick = opts.onClusterClick;
			this._clusterListener = this._clusterer.addListener(
				"click",
				(cluster: Cluster) => {
					const bounds = cluster.getBounds();
					const center = cluster.getCenter();
					const map = cluster.getMap();
					const maxZoom = (DEFAULT_CLUSTER_OPTIONS.maxZoom || 20) + 1;
					const zoomTo = () => {
						map.setZoom(map.getZoom() + 1);
						map.setCenter(center);
						map.fitBounds(bounds);
						map.setZoom(Math.min(map.getZoom(), maxZoom));
					};

					if (this._infowindow) {
						this._infowindow.close();
					}

					onClick(this.getMarkerIds(cluster.getMarkers()), { zoomTo });
				}
			);
		}

		if (opts.clusterMarkers) {
			this.setClusterCalculator();
		}
	}

	public setClusterCalculator() {
		let title = "";
		
		this._clusterer?.setCalculator((markers, count) => {
			title = this.getTitle(markers);
			return { text: title, title: title, index: this.getStyleIndex(markers) };
		});
	}

	private getTitle(markers: google.maps.Marker[]): string {
		if (markers.length > 99) {
			return "99+";
		}

		return markers.length.toString();
	}

	private getSizeIndex(markersLength: number): number {
		if (markersLength > 99) {
			return 2; //large
		}
		if (markersLength > 50) {
			return 1; //medium
		}
		return 0; //small
	}

	private getStyleIndex(markers: google.maps.Marker[]): number {
		let pins = "";

		markers.forEach((marker) => {
			if (
				(marker.getIcon()?.toString() || "").indexOf("critical") !== -1 &&
				pins.indexOf("critical") === -1
			) {
				pins += "critical~";
			}
			if (
				(marker.getIcon()?.toString() || "").indexOf("dm1") !== -1 &&
				pins.indexOf("dm1") === -1
			) {
				pins += "dm1~";
			}
			if (
				(marker.getIcon()?.toString() || "").indexOf("warning") !== -1 &&
				pins.indexOf("warning") === -1
			) {
				pins += "warning~";
			}
			if (
				(marker.getIcon()?.toString() || "").indexOf("info") !== -1 &&
				pins.indexOf("info") === -1
			) {
				pins += "info~";
			}
			if (
				(marker.getIcon()?.toString() || "").indexOf("asset_down") !== -1 &&
				pins.indexOf("asset_down") === -1
			) {
				pins += "asset_down~";
			}
		});

		pins = pins !== "" ? "~" + pins : "";

		if (pins.indexOf("asset_down") !== -1) {
			return 16 + this.getSizeIndex(markers.length);
		}
		if (pins.indexOf("critical") !== -1) {
			return 13 + this.getSizeIndex(markers.length);
		}
		if (pins.indexOf("dm1") !== -1) {
			return 10 + this.getSizeIndex(markers.length);
		}
		if (pins.indexOf("warning") !== -1) {
			return 7 + this.getSizeIndex(markers.length);
		}
		if (pins.indexOf("info") !== -1) {
			return 4 + this.getSizeIndex(markers.length);
		}
		
		
		return 1 + this.getSizeIndex(markers.length);
	}

	public zoomToFit(map: google.maps.Map) {
		//zoom when markers are present
		if (this._markers.size > 0) {
			//OPS-1143
			//Going around the know Google Maps bug
			map.setZoom(map.getZoom() + 1);
			//
			map.fitBounds(this._bounds);
		}
	}
	//focus the asset when user clicks on any asset from drawer.
	public focusAsset(map: google.maps.Map, latitude: number, longitude: number) {
		if (longitude && latitude && longitude !== 0 && latitude !== 0) {
			const bounds = new google.maps.LatLngBounds();
			const loc = new google.maps.LatLng(latitude, longitude);
			bounds.extend(loc);
			//focus on the asset
			map.fitBounds(bounds);
			//centre the asset
			map.panToBounds(bounds);
			//map.setZoom(DEFAULT_CLUSTER_OPTIONS.maxZoom || 20);
			//map.panTo(new google.maps.LatLng(latitude, longitude));
		}
	}

	public closeInfoWindow(map: google.maps.Map) {}

	private getMarkerIds(markers: google.maps.Marker[]): ClusterIds {
		const idLookup = new Map<google.maps.Marker, Marker["id"]>();
		Array.from(this._markers.entries()).forEach(([id, store]) =>
			idLookup.set(store.marker, id)
		);

		return markers.map((m) => idLookup.get(m) as Marker["id"]);
	}
}
