import React, { useRef, useLayoutEffect } from "react";
import ClusterBarChart, { Options } from "./ClusterBarChart";
import useDomNode from "../../../../lib/use-dom-node";
import { CommonWidgetProps } from "../../models";
import { inputSignalMapper } from "../common";
import { connect } from "react-redux";
import * as actions from "../../../../actions";


const createSignalSeries = inputSignalMapper((meta, data, userPreferences) => ({
  ...meta,
  values: data,
  userPreferences
}));

interface Props extends CommonWidgetProps {
  signalRData?: any;
  settings?: Options;
  errorCode?: string;
}

export const Widget: React.FC<Props> = (props) => {
  const [node, setNode] = useDomNode<HTMLDivElement>();
  const chartRef = useRef<ClusterBarChart>();

  const { template, data, settings, signals,state } = props;

  let clusterData = createSignalSeries(signals, template, data,state.temperature.value);

  /* updating clusterData with live value if present (SignalR) */
  if (Object.keys(props.signalRData.data).length > 0) {
    clusterData = clusterData.map((signal: any) => {
      if (props.signalRData.data.hasOwnProperty(signal.id)) {
        props.signalRData.data[signal.id].forEach((signalData:any, index: number) => {
          signal.values.push({
            value: signalData.value,
            timestamp: new Date(signalData.bt - signalData.time)
          })
        });
      }
      return signal
    })
  }

  useLayoutEffect(() => {
    if (node) {
      chartRef.current = new ClusterBarChart(
        node,
        clusterData,
        settings,
        state
      );
      return () => chartRef.current?.destroy();
    }
  }, [node, template, settings, signals, data, clusterData]);

  return <div style={{ height: "100%" }} ref={setNode} />;
};


// export default Widget;
const mapStateToProps = (state: any) => {
  return {
    signalRData: state.contextReducer.signalRData,
  };
};
export default connect(
  mapStateToProps,
  // actions
)(Widget);
