import React, { Component } from "react";
import { Table } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { 
    CheckCircleOutlined, CloseCircleOutlined
} from "@ant-design/icons";

import history from '../../utils/history';
import { appConfigs } from "../../utils/configurations";
import { localizeUtcDate, getPermissions } from "../../utils/commonHelpers";

import { connect } from "react-redux";
import * as actions from "./actions";


interface Prop {
    context: any,
    alertsState: any,
    permissions: any,
    getActivityAlerts:Function,
    activityAlertsSetPagination:Function
}

class ActivityAlerts extends Component<Prop, {}> {

    componentDidMount(){
        this.props.activityAlertsSetPagination(this.onPaginationChange);
        this.props.getActivityAlerts(this, this.getActivityAlertsSuccess, null,1, null);    
    }
    
    onPaginationChange = (page: number) => {
        this.props.getActivityAlerts(this, this.getActivityAlertsSuccess, null, page, null);
    };

    getActivityAlertsSuccess=()=>{
        //todo implement after getting alerts
    }

    render(){
        const { tableConfig, tableData } = this.props.alertsState.tabs[3];       
         const tableColumns = [
            {
                title: "Asset",
                dataIndex: "assetName"                            
            },
            {
                title: "Created At",
                dataIndex: "createdAt",
                responsive: ["lg"] as Breakpoint[],
                render: (createdAt: string, record: any) => (
                    <>{localizeUtcDate(createdAt, this.props.alertsState.dateFormatOptions)}</>                    
                ) 
            },
            {
                title: "Alert Type",
                dataIndex: "alertType",
                responsive: ["lg"] as Breakpoint[]
            },
            {
                title: "Description",
                dataIndex: "description",
                responsive: ["lg"] as Breakpoint[]                              
            },
        ];
        
        return(
            <>
            <Table 
                data-id="alerts-activity-tab-button"   
                {...tableConfig}
                rowKey={(record) => record.id}
                columns={tableColumns}       
                dataSource={tableData}           
                className="mt-20"      
            />
            </>
        )
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        permissions: state.contextReducer.data,
        alertsState: state.alertsReducer.alertsState
    };
};

export default connect(
    mapStateToProps,
    actions
)(ActivityAlerts);