import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function templateDuplicateOrgsGet(
    me: any, 
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
    orgId?: string,
    searchText?: string
) {
    return async (dispatch: any) => {            
        Axios.get(
            appConfigs.server.URL + "/ui/api/orgs-lov" + ((orgId || "") !== "" ? "/" + orgId : ""), {
                responseType: "json",        
                headers: {},
                params: {
                    mode: (orgId || "") === "" ? "all" : "single",
                    scope: "all",
                    includeTree: false,
                    orgId: orgId,
                    searchText: searchText,
                    page: 1,
                    pageSize: 9999
                }
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.orgs);
                }
                dispatch({ type: "TEMPLATES_DUPLICATE_ORGS_SUCCESS", payload: {data: response.data.data.orgs} });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
    };
}    

export function templateDuplicate(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    templateId: string,
    newName: string,
    newDescription: string,
    newOrgId: string,
    templateVisibleToSubOrg: boolean,
    clonedSignals:any
) {
    return async (dispatch: any) => {
        Axios.put(
            appConfigs.server.URL + "/ui/api/templates/" + templateId + "/duplicate",
            {    
                name: newName,
                description: newDescription,
                orgId: newOrgId,
                templateVisibleToSubOrg: templateVisibleToSubOrg,
                clonedSignals
            },
            {
                responseType: "json"
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.templates[0]);           
                }
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}
