import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";
import { sortAlphabetically } from "../../../utils/commonHelpers";
import { orgsListSetDisplay } from "../../Orgs/actions";

export function getCalibrations(
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: any,
    sortColumn: any,
    sortDirection: any,
    searchText: any,
    category: any,
    testMode:number,
    page: number = 1
) {
    return (dispatch: any) => {
        Axios.get(
           // appConfigs.server.URL + "/ui/api/cmns-csu/calibrations?testMode="+testMode, {
            appConfigs.server.URL + "/ui/api/cmns-csu/calibrations", {
                responseType: "json",        
                headers: {},
                params: {
                    orgId: orgId,
                    sortColumn: sortColumn,
                    sortDirection: sortDirection,
                    category: category,
                    searchText: searchText,
                    testMode : testMode,
                }
        })
        .then(response => {
            if (callbackFnSuccess != null) {                    
                let record = response.data == null || (response.data|| 0) === 0 ? {} : response.data;
                callbackFnSuccess(record);
            }
            dispatch({
                type: "CSU_ALL_DATA",
                payload: {
                    data: response.data,
                    page: page
                }
            });
        })
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(error);
            }
            //errorResponse(error);
        });
        //dispatch({ type: "ASSETS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}

export function getOTAHistory(
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: any,
    sortColumn: any,
    sortDirection: any,
    searchText: any,
    typeText: string,
    page: number = 1, 
    pageSize: number
) {
    // console.log("getOTAHistory typeText=", typeText)
    return (dispatch: any) => {
        Axios.get(
           // appConfigs.server.URL + "/ui/api/cmns/getCalibrations?", {
            appConfigs.server.URL + "/ui/api/cmns-csu/status-history", {
                responseType: "json",        
                headers: {},
                params: {
                    orgId: orgId,
                    sortColumn: sortColumn,
                    sortDirection: sortDirection,
                    searchText: searchText,               
                    type: typeText,
                    page: page,
                    pageSize: pageSize
                }
        })
        .then(response => {
            dispatch({
                type: "CSU_HISTORY_DATA",
                payload: {
                    data: response.data,
                    page: page
                }
            });
            if (callbackFnSuccess != null) {                    
                let record = response.data == null || (response.data|| 0) === 0 ? {} : response.data;
                //callbackFnSuccess(record);
            }
           
        })
        .catch(function (error) {
            console.log("history API returns error", error)
            if (callbackFnFailure != null) {
                callbackFnFailure(error);
            }
            //errorResponse(error);
        });
        //dispatch({ type: "ASSETS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}

export function csuMaintClose() {   
    return (dispatch: any) => {
        dispatch({ type: "CSU_MAINT_CLOSE" });
    };
}

export function assetMaintTabChange(
    activeTab: string
) {
    return (dispatch: any) => {
        dispatch({ type: "CSU_MAINT_TAB_CHANGE", activeTab: activeTab });
    };
}


export function csuMaintOpen(
    title: string,
    width: number,
    record: any,
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
) {
    return (dispatch: any) => {
        dispatch({ type: "CALIBRATION_MAINT_OPEN", payload: {title: title, width: width, record: record} });
    };
}
//calibrationMaintOpen(title, drawerWidth, record){




export function processAction(
    data:any,
    actionType: any,
    callbackFnSuccess: any,
    callbackFnFailure: any
) {
        return(dispatch:any) => {
           // if(actionType === 'approve update' || actionType === 'cancel update') {
            Axios.post(
                appConfigs.server.URL + "/ui/api/cmns-csu/processAction", data, {
                    responseType: "json",        
                    headers: {},
                    params: {
                        actionType: actionType
                    }
            })
            .then(response => {
                if (callbackFnSuccess != null) {    
                     let record = (response.data.data.OTA == null || (response.data.data.OTA.length || 0) === 0) ? {} : response.data.data.OTA;
                     callbackFnSuccess(record, actionType);
                }
            })
            .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
                //errorResponse(error);
            });
        //}
        // else if(actionType === 'deny update') {
        //     debugger;
        //     let data1 = {
        //         "systemJobId": "e45db8f4-bab5-4fd0-990a-ae1ab0a06789",
        //         "csuJobId":  "e45db8f4-bab5-4fd0-990a-ae1ab0a06596",
        //         "systemSoftwareName": "POLASH",
        //         "systemSoftwareVersion": "2023-09-15T16:06:08Z",
        //         "address": 0,
        //         "fileType": "pendingCal",
        //         "version": "2023-09-26T16:06:08Z",
        //         "operation": "update",
        //         "priority": 4,
        //         "order": 1,
        //         "status": "sendFailed",
        //         "code": "500",
        //         "message": "NRC error etc",
        //         "retries": 0
        //     }
        //     Axios.post(
        //         appConfigs.server.URL + "/api/csu/GATEWAY/QW7J2OY8/status", data1, {
        //             responseType: "json",        
        //             headers: {},
        //             params: {
        //                 boxId: '20094029865',
        //                 esn: '78000001'
        //             }
        //     })
        //     .then(response => {
        //         if (callbackFnSuccess != null) {                    
        //             // let record = response.data.data.OTA == null || (response.data.data.OTA.length || 0) === 0 ? {} : response.data.data.OTA;
        //             // callbackFnSuccess(record);
        //             console.log("RESPONSE: "+ JSON.stringify(response));
        //         }
        //     })
        //     .catch(function (error) {
        //         if (callbackFnFailure != null) {
        //             callbackFnFailure(error);
        //         }
        //         //errorResponse(error);
        //     });
        // }

        }
}

export function csuSetPaginationForAll(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "CSU_ALL_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}

export function setSearch(search:any) {
    return (dispatch: any) => {
        dispatch({ type: "SET_SEARCH", search: search });
    }
}

export function csuSetPaginationForHistory(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "CSU_HISTORY_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
} 