import { connect } from "react-redux";
import * as actions from "./actions/csu";
import React from "react";
import { Descriptions } from "antd";
interface Props {    
    context: any;
    permissions: any;
    tabData: any;
    csuList: any;
    csus: any;
    getCalibrations: Function;
    processAction: Function;
  }

const CSUInfo: React.FC<Props> = (props) => {
    const { csuList } = props;
    let descriptionSpan:number = window.innerWidth > 700 ? 0 : 1;
    console.log("RECORD DATA: "+ JSON.stringify(csuList));
    console.log("RECORD DATA INFO: "+ JSON.stringify(csuList.record.info));
    return(
        
      <>
       <Descriptions bordered column={2}>

          <Descriptions.Item label="Esn" span={1 + descriptionSpan}>{(csuList?.record)?(csuList.record.esn? csuList.record.esn:null):null}</Descriptions.Item>
          <Descriptions.Item label="Box Id" span={1 + descriptionSpan}>{(csuList?.record)?(csuList.record.boxId? csuList.record.boxId:null):null}</Descriptions.Item>

          <Descriptions.Item label="Address" span={1 + descriptionSpan}>{(csuList?.record)?(csuList.record.address? csuList.record.address:null):null}</Descriptions.Item>
          <Descriptions.Item label="VIN" span={1 + descriptionSpan}>{(csuList?.record?.info)? (csuList.record.vin) :null}</Descriptions.Item>
          
          <Descriptions.Item label="Current Version" span={1 + descriptionSpan}>{(csuList?.record)?(csuList.record.currentVersion? csuList.record.currentVersion:null):null}</Descriptions.Item>
          <Descriptions.Item label="New Version" span={1 + descriptionSpan}>{(csuList?.record)?(csuList.record.newVersion? csuList.record.newVersion:null):null}</Descriptions.Item>
          
          <Descriptions.Item label="Telematics Version" span={1 + descriptionSpan}>{(csuList?.record?.info)?(JSON.parse(csuList.record.info).telematicsVesion? JSON.parse(csuList.record.info).telematicsVesion:null):null}</Descriptions.Item>
          <Descriptions.Item label="Telematics Supplier Id" span={1 + descriptionSpan}>{(csuList?.record?.info)?(JSON.parse(csuList.record.info).telematicsSupplierId?JSON.parse(csuList.record.info).telematicsSupplierId:null):null}</Descriptions.Item>

          <Descriptions.Item label="OTA Status" span={1 + descriptionSpan}>{(csuList?.record)?(csuList.record.otaStatus? csuList.record.otaStatus:null):null}</Descriptions.Item>
          <Descriptions.Item label="OTA code" span={1 + descriptionSpan}>{(csuList?.record?.info)?(JSON.parse(csuList.record.info).otaCode? JSON.parse(csuList.record.info).otaCode:null):null}</Descriptions.Item>

          <Descriptions.Item label="OTA Message" span={1 + descriptionSpan}>{(csuList?.record?.info)?(JSON.parse(csuList.record.info).otaMessage? JSON.parse(csuList.record.info).otaMessage:null):null}</Descriptions.Item>
          <Descriptions.Item label="Calibration from" span={1 + descriptionSpan}>{(csuList?.record?.persistMode)? ((csuList.record.persistMode===2)?"Simulation": "Cummins"):null}</Descriptions.Item>
          

      </Descriptions>
      <div>
          <div></div>
          <div></div>
      </div>
      <div>
          <div></div>
          <div></div>
      </div>
      <div>
          <div></div>
          <div></div>
      </div>
       </>)
};

const mapStateToProps = (state: any) => {
    return {
      context: state.contextReducer.context,
      permissions: state.contextReducer.data,
      csus: state.csuReducer.csuState.csu.csuList.all,
      csuList: state.csuReducer.csuState.csu.csuList.calibrationMaint,
    };
  };

export default connect(mapStateToProps, actions)(CSUInfo);
