/// <reference path="../../../node_modules/@types/googlemaps/index.d.ts" />
import React, { useState, useEffect } from "react";
import MarkerManager, { Marker, ClusteringOptions, ServiceTruckMarker } from "./MarkerManager";
import useGoogleMapsLib, { GoogleMapsLib } from "./use-google-maps-lib";
import useDomNode from "../../lib/use-dom-node";
import { PolyLineOptions, constructPoyline, constructPoylineWithEvents, EventsOnMap } from "./PolyLine";
import DrawingManager, { DrawingOptions, Geofence } from "./DrawingManager";
import { EventsMapMarkerOptions } from "../../pages/Dashboard2/widgets/EventsLocationWidget/Widget";
import MapContext, { MapContextInterface } from "../../pages/FleetOverview2/MapContext";

const DEFAULT_CENTER = {
    lat: 41.996052,
    lng: -87.974322
};

const DEFAULT_ZOOM = 4;

// Wrap in a function so that we can delay until the
// google library has actually been loaded
export const DEFAULT_MAP_OPTIONS = (): google.maps.MapOptions => ({
  zoom: DEFAULT_ZOOM,
  center: DEFAULT_CENTER,
  mapTypeControl: true,
  mapTypeControlOptions: {
    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
    mapTypeIds: ["roadmap", "satellite"],
    position: google.maps.ControlPosition.BOTTOM_LEFT,
  },
  streetViewControl: false,
  zoomControl: true,
  zoomControlOptions: {
    position: google.maps.ControlPosition.RIGHT_BOTTOM,
  },
  fullscreenControl: false,
  fullscreenControlOptions: {
    position: google.maps.ControlPosition.RIGHT_BOTTOM,
  },
  restriction: {
    latLngBounds: {
      east: 180,
      west: -180,
      north: 85,
      south: -85,
    },
  },
  minZoom: 2,
  rotateControl: false
});

export const DEFAULT_MAP_OPTIONS_FOR_GEOFENCING = (): google.maps.MapOptions => ({
    zoom: DEFAULT_ZOOM,
    center: DEFAULT_CENTER,
    mapTypeControl: true,
    mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        mapTypeIds: ["roadmap", "satellite"],
        position: google.maps.ControlPosition.BOTTOM_LEFT,
    },
    streetViewControl: false,
    zoomControl: true,
    zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
    },
    fullscreenControl: false,
    fullscreenControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
    },
    restriction: {
        latLngBounds: {
            east: 180,
            west: -180,
            north: 85,
            south: -85,
        },
    },
    minZoom: 2,
    rotateControl: false
});

export interface MapActions {
    zoomToFit: () => void;
    focusAsset: (latitude: number, longitude: number) => void;
}

interface PolyLineState {
    polyLines?: google.maps.Polyline[],
    markers?: Marker[]
}

interface MapProps {
    markers: Marker[];
    mapOptions?: google.maps.MapOptions;
    eventsMapMarkerOptions?: EventsMapMarkerOptions;
    mapActionsRef?: (map: MapActions) => void;
}

export interface GeofenceOptions {
    geofences?: Geofence[];
    geofenceOptions? : any
}

export interface ServiceTrucksOptions {
    serviceTruckMarkers?: Marker[];
    serviceTruckOptions? : any
}

export interface FleetOverviewOptions {
    fleetOverviewOptions?: any
}

export interface ToolTipOptions {
    toolTipOptions? : any
}

type Props = MapProps & ClusteringOptions & PolyLineOptions & EventsOnMap & DrawingOptions & GeofenceOptions & ServiceTrucksOptions & FleetOverviewOptions & ToolTipOptions;


export const GoogleMap: React.FC<Props> = (props ) => {
    const glib = useGoogleMapsLib();
    return glib ? <MapInternals lib={glib} {...props} /> : <></>;
};


interface State {
    map: google.maps.Map;
    markerManager: MarkerManager;    
    drawingManager: DrawingManager;    
}

const MapInternals: React.FC<Props & { lib: GoogleMapsLib }> = (props) => {
    const [mapNode, setMapNode] = useDomNode<HTMLDivElement>();
    const [state, setState] = useState<State>();
    const [hasRenderedMarkers, setMarkersRendered] = useState(false);
    const [, setDisplayMarkers] = useState<
        ReturnType<MarkerManager["getMarkers"]>
    >([]);
    const [plylineState, setPlyLineState] = useState<PolyLineState>();    

    const { lib, mapOptions, markers, drawOverlays, geofences, geofenceOptions, toolTipOptions, serviceTruckMarkers, serviceTruckOptions, fleetOverviewOptions } = props;

    let map: google.maps.Map<HTMLDivElement>;

    const initializeMap = () => {
        if (mapNode) {
            if (drawOverlays) {
                map = new lib.Map(mapNode, {
                    ...DEFAULT_MAP_OPTIONS_FOR_GEOFENCING(),
                    ...mapOptions,
                });
            } else {
                map = new lib.Map(mapNode, {
                    ...DEFAULT_MAP_OPTIONS(),
                    ...mapOptions,
                });
            }
            const markerManager = new MarkerManager(map, props, geofenceOptions, toolTipOptions);
            let drawingManager = new DrawingManager(map, props, geofences, geofenceOptions);
            props.geofenceOptions?.setMap(map);
            props.geofenceOptions?.setDrawingManager(drawingManager);
            props.geofenceOptions?.setMarkerManager(markerManager);

            serviceTruckOptions?.setServiceTruckMap(map)
            fleetOverviewOptions?.setFleetOverviewMap(map)

            setState({
                markerManager,
                drawingManager,
                map
            });

        
            if (props.mapActionsRef) {
                props.mapActionsRef({
                    zoomToFit: () => {
                        markerManager.zoomToFit(map);
                    },
                    focusAsset: (latitude: number, longitude: number) => {
                        markerManager.focusAsset(map, latitude, longitude);
                    }
                });
            }

            map.addListener("click", function() {
                if (markerManager._infowindow) {
                    markerManager._infowindow.close();
                }
            });

            return () => {
                markerManager.destroy();
            };
        }
    };

    useEffect(initializeMap, [mapNode]);

    const updateGeofences = () => {
        if (state && geofences) {
            state.drawingManager.updateGeofences(state.map, props, geofences, geofenceOptions)
        }
    }

    const updateMarkers = () => {

        if (state && markers) {
            if (!props.clusterMarkers && !props.eventsOnMap && !props.geofenceOptions) {
                //need to display only recent marker and rest all should be connected by polyline
                const length = markers.length-1;
                const marker = [markers[length]];
                state.markerManager.updateMarkers(marker, state.map, props);
            } else {
                state.markerManager.updateMarkers(markers, state.map, props);
            }
            

            if (props.polyline) {
                //const a = constructPoyline(markers, state.map, polylineState);
                const { polyLinePaths,  oldMarkers }  = constructPoyline(markers, state.map, plylineState?.polyLines, plylineState?.markers);                
                setPlyLineState({...plylineState, polyLines: polyLinePaths, markers: oldMarkers});
            }


            if (props.eventsOnMap) {

                const { polyLinePaths,  oldMarkers }  = constructPoylineWithEvents(markers, state.map, plylineState?.polyLines, plylineState?.markers, props.eventsMapMarkerOptions );

                setPlyLineState({...plylineState, polyLines: polyLinePaths, markers: oldMarkers});
            }
            
            setDisplayMarkers(state.markerManager.getMarkers());
        }
    }

    useEffect(updateMarkers, [state, markers]);
    useEffect(updateGeofences, [state, geofences, drawOverlays, geofenceOptions]);

    const firstMarkerRender = () => {
        if (markers.length > 0 && state?.markerManager && !hasRenderedMarkers) {
            setMarkersRendered(true);
            setTimeout(function () {
                if (props.clusterMarkers) {
                    state.markerManager.zoomToFit(state.map);
                }                
            }, 250);            
        }
    };

    useEffect(firstMarkerRender, [state, markers, hasRenderedMarkers]);

    const updateServiceTruckMarkers = () => {
        if(state && serviceTruckMarkers){
            state?.markerManager.updateServiceTruckMarkers(serviceTruckMarkers as ServiceTruckMarker[], state.map);
        }
    };

    useEffect(updateServiceTruckMarkers, [serviceTruckMarkers, state]);

    const MapContextInterface: MapContextInterface = {
        map: state?.map!,
    };

    return (
        
        <>
            <div
                data-id="map-container"
                ref={setMapNode} 
                style={{ height: "100%" }} 
            >
                <MapContext.Provider value={MapContextInterface}>{props.children}</MapContext.Provider>
            </div>
        </>
    );
};