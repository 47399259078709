import React from "react";
import { Form, Input, message, Tooltip, Switch, Tree, Select, Modal, Button } from 'antd';
import { FormInstance } from 'antd/lib/form';

import { appConfigs } from "../../../utils/configurations";
import { errorResponse, resetFormErrors } from "../../../utils/apiHelpers/apiHelpers";
import { FormState, formInit } from "../../../utils/apiHelpers/formHelpers";
import { SuccessAlert, ErrorAlert } from "../../../utils/Components/formAlerts";
import { connect } from "react-redux";
import * as actions from "../actions";
import { createTreeStructure, uuidv4 } from "../../../utils/commonHelpers";
import { WarningOutlined } from "@ant-design/icons";
const { Option } = Select;
const lodash = require('lodash');
interface Prop {
    templateCopyCollection: Function,
    templatesListRefreshOnUpdate: Function,
    getTemplate: Function,
    getTemplatesForCopyCollection: Function,
    context: any,
    templateDuplicateOrgsGet: Function;
    templatesList: any,
    assetsList: any,
    closePopUp: Function,
    signalValidation: Function,
    onDrawerClose: Function
}

interface State extends FormState {
    signalData: any,
    expandedKeys: Array<string>,
    selectedkeys: Array<string>,
    enableSignalCollectons: boolean,
    autoExpandParent: boolean,
    checkedKeys: Array<string>,
    checkable: boolean,
    matchingSignals: boolean,
    destinationCollectionExists: boolean,
    isModalVisible: boolean,
    isModalVisibleForDuplicateName: boolean,
    signalOverride: boolean,
    flattenedSignals: any,
    destinationCollection: any,
    signalCount: any,
    templateId: any,
    isSignalWarningEnabled: boolean,
    destinationTemplateCollectionData: any,
    duplicationCollectionSignals: any,
    newCollectionSignals: any,
    templateOrgId: any,
    sourceDataPoints: any,
    sourceTemplateData: any,
    receivedsignalCollections: any,
    receivedorgTemplates: any
}

class TemplateCopyCollection extends React.Component<Prop, State> {

    constructor(props: any) {
        super(props);
        this.onTemplateIdChange = this.onTemplateIdChange.bind(this)
    }
    state: State = {
        signalData: [],
        expandedKeys: [],
        selectedkeys: [],
        enableSignalCollectons: false,
        autoExpandParent: true,
        matchingSignals: false,
        isModalVisible: false,
        isModalVisibleForDuplicateName: false,
        signalOverride: false,
        checkedKeys: [],
        checkable: false,
        destinationCollectionExists: false,
        destinationCollection: undefined,
        isSignalWarningEnabled: false,
        signalCount: 0,
        flattenedSignals: [],
        duplicationCollectionSignals: [],
        newCollectionSignals: [],
        destinationTemplateCollectionData: [],
        templateId: null,
        templateOrgId:null,
        sourceDataPoints: null,
        sourceTemplateData: null,
        receivedsignalCollections: false,
        receivedorgTemplates: false
    };

    formRef = React.createRef<FormInstance>();
    onOrgIdChange = (value: string) => {        
        this.formRef.current!.resetFields(["templateId","collectionId"]);
        this.setState({receivedorgTemplates: false, destinationCollection: undefined, templateId: undefined, duplicationCollectionSignals:[]});
        if (value) {                        
            this.props.getTemplatesForCopyCollection(this,this.getOrgTemplatesSuccess, null,value,null);
        }
        else{
            this.props.getTemplatesForCopyCollection(this,null, null,value,null);
        }
    }
    getOrgTemplatesSuccess(me:any, data:any){
        me.setState({receivedorgTemplates: true});
    }
    onCollectionChange(value: string, options: any) {
        this.setState({ destinationCollection: value[value.length - 1] });
        this.formRef.current!.setFieldsValue({ collectionId: value[value.length - 1] });
        this.onTemplateIdChange(this.formRef.current?.getFieldsValue().templateId, false);
    }
        
    templateDetailsSuccess(me:any, destData:any){
        
        let sourceData = me.props.templatesList.templateMaint.record.datapoints; 
        let templateId=me.formRef.current?.getFieldsValue().templateId; 
        let orgId = me.formRef.current?.getFieldsValue().orgId; 
        let collectionId = me.formRef.current?.getFieldsValue().collectionId; 
        let enableSignalCollections = me.state.enableSignalCollectons;
        let destTemplateData = destData? destData?.datapoints? JSON.parse(destData?.datapoints):null: null;//details ? JSON.parse(details?.datapoints) : null;
        let sourceTemplateData = JSON.parse(sourceData);
       if(destTemplateData) {
        if (enableSignalCollections) {
            me.validateSignalCollection(sourceTemplateData, destTemplateData, templateId, orgId, collectionId);
        } else {
            if (me.state.checkedKeys.length > 0) {
                sourceTemplateData.map((st: any) => {
                    for (let i = st.values?.length - 1; i >= 0; i--) {
                        if (!me.state.checkedKeys.includes(st.values[i]._id)) {
                            st.values.splice(i, 1);
                        }
                    }
                });
                for (let i = sourceTemplateData.length - 1; i >= 0; i--) {
                    if ((!me.state.checkedKeys.includes(sourceTemplateData[i]._id)
                        && sourceTemplateData[i].values.length === 0)
                    ) {
                        sourceTemplateData.splice(i, 1);
                    }
                }
                me.setState({sourceTemplateData: sourceTemplateData});  
                me.validateSignalCollection(sourceTemplateData, destTemplateData, templateId, orgId, collectionId);
            }
        }
     }
     me.setState({receivedsignalCollections: true});
    }

    onTemplateIdChange = (value: any, isTemplateChange: boolean=true) => {
        this.setState({
            templateId: value,
            duplicationCollectionSignals: []
        });
        this.formRef.current!.setFieldsValue({
            templateId: value
        });
        if(isTemplateChange){
            this.formRef.current!.setFieldsValue({
                collectionId: undefined
            });
            this.setState({receivedsignalCollections: false,
            destinationCollection: undefined});
            if(value) {
                this.props.getTemplate(this,this.templateDetailsSuccess,null,value);
            }
            else
            {
                this.props.getTemplate(this,null,null,value);
            }
        }
        else {
            let tempdata = this.props.templatesList.selectedTemplateDetails;
            let sourceTemplateData = JSON.parse(this.props.templatesList.templateMaint.record.datapoints);
            let destTemplateData = tempdata? tempdata?.datapoints?JSON.parse(tempdata?.datapoints) : null : null;
            if (this.state.enableSignalCollectons) {
                this.validateSignalCollection(sourceTemplateData, destTemplateData, this.formRef.current?.getFieldsValue().templateId, this.formRef.current?.getFieldsValue().orgId, this.formRef.current?.getFieldsValue().collectionId);
             } else {
            if (this.state.checkedKeys.length > 0) {
                    sourceTemplateData.map((st: any) => {
                        for (let i = st.values?.length - 1; i >= 0; i--) {
                            if (!this.state.checkedKeys.includes(st.values[i]._id)) {
                                st.values.splice(i, 1);
                            }
                        }
                    });
                    for (let i = sourceTemplateData.length - 1; i >= 0; i--) {
                        if ((!this.state.checkedKeys.includes(sourceTemplateData[i]._id)
                            && sourceTemplateData[i].values.length === 0)
                        ) {
                            sourceTemplateData.splice(i, 1);
                        }
                    }
                    this.setState({sourceTemplateData: sourceTemplateData});                
                this.validateSignalCollection(sourceTemplateData, destTemplateData, this.formRef.current?.getFieldsValue().templateId, this.formRef.current?.getFieldsValue().orgId, this.formRef.current?.getFieldsValue().collectionId);
            }
            }
        }
    }
    componentDidMount() {
        this.componentUpdate(this.props.templatesList.templateMaint.record);
    }


    componentUpdate(record: any) {
        this.props.templateDuplicateOrgsGet(this, null, null, null, null);
        this.props.getTemplatesForCopyCollection(this,this.getOrgTemplatesSuccess, null,this.props.context.appContext.orgId, null);
        formInit(this);
        if (this.formRef.current != null) {
            this.formRef.current!.resetFields();
            this.formRef.current!.setFieldsValue({
                orgId: this.props.context.appContext.orgId,
                templateVisibleToSubOrg: record.templateVisibleToSubOrg,
                clonedSignals: null
            });
            this.setSignalTree(record);
        }
    };

    validateSignalCollection = (sourceTemplateData: any, destTemplateData: any, templateId: any, orgId: any, destinationCollection: any) => {
        var copyCollectionData: any = [];
        let matchingCollectionSignals: any = [];
        let newSignals: any = [];
        let isDuplicateSignalAvailable: boolean = false;
        let destinationCollectionFound: any;
        this.setState({
            duplicationCollectionSignals: [],
            matchingSignals: false
        });
        if (destinationCollection) {
            matchingCollectionSignals=[];
            destinationCollectionFound = destTemplateData?.find((ele: any) => (ele?._id === destinationCollection));
            if (destinationCollectionFound) {
                this.setState({
                    destinationCollectionExists: true
                })
                copyCollectionData.push(destinationCollectionFound);
                sourceTemplateData?.filter((sourceCollectionData: any) => {
                    if (!sourceCollectionData?.type || sourceCollectionData.type.toLowerCase() === "none") {
                        for (let i = sourceCollectionData.values?.length - 1; i >= 0; i--) {
                            let signalFound = destinationCollectionFound.values.find((ele: any) => (ele.label.toLowerCase() === sourceCollectionData.values[i].label?.toLowerCase()));
                            if (signalFound) {
                                isDuplicateSignalAvailable = true;
                                matchingCollectionSignals.push({ "signalId": sourceCollectionData.values[i]._id, "signalName": sourceCollectionData.values[i].label, "signalCollectionId": sourceCollectionData._id, "collectionName": sourceCollectionData.name, "signalData": sourceCollectionData.values[i] });
                                for (let j = copyCollectionData[0].values?.length - 1; j >= 0; j--) {
                                    let removedDuplicate : boolean = false
                                    if(copyCollectionData[0].values[j]?.label.toLowerCase() === signalFound.label.toLowerCase())
                                    {
                                        copyCollectionData[0].values.splice(j, 1);
                                        removedDuplicate = true;
                                    }
                                    if(removedDuplicate) {
                                        break;
                                    }
                                }
                            } else {
                                const uuid = uuidv4();
                                sourceCollectionData.values[i]._id = uuid;
                                newSignals.push(sourceCollectionData.values[i]);
                            }
                        }
                    }
                })
                copyCollectionData[0].values = newSignals;
                if (isDuplicateSignalAvailable) {
                    this.setState({
                        matchingSignals: true
                    });
                } 
                else {
                    this.setState({
                        matchingSignals: false
                    });
                }
                if (matchingCollectionSignals.length > 0) {

                    this.setState({
                        duplicationCollectionSignals: matchingCollectionSignals
                    });
                }
                if (newSignals.length > 0) {

                    this.setState({
                        newCollectionSignals: copyCollectionData
                    });

                }
            }
            else {
                this.setState({
                    destinationCollectionExists: false
                })
                let newCollection = {
                    "all_signals": true,
                    "name": destinationCollection,
                    "type": "none",
                    "update_interval": "60s",
                    "values": [],
                    "_id": uuidv4()
                }

                sourceTemplateData?.filter((sourceCollectionData: any) => {
                    if (!sourceCollectionData?.type || sourceCollectionData.type.toLowerCase() === "none") {
                        sourceCollectionData.values?.filter((sourceSignal: any) => {
                            newSignals.push(sourceSignal);
                        }
                        );
                    }
                    if (newSignals.length > 0) {
                        newSignals?.filter((signals: any) => {
                            const uuid = uuidv4();
                            signals._id = uuid;
                        })
                        newCollection.values = newSignals;
                        copyCollectionData.push(newCollection);
                    }

                    this.setState({
                        matchingSignals: false,
                        newCollectionSignals: newCollection
                    });

                })
            }
        }
        else {
            matchingCollectionSignals = [];
            sourceTemplateData?.filter((sourceCollectionData: any) => {
                let collectionFound = destTemplateData?.find((ele: any) => (ele?.name?.toLowerCase() === sourceCollectionData?.name.toLowerCase()));
                if (!sourceCollectionData?.type || sourceCollectionData?.type.toLowerCase() === "none") {
                    if (collectionFound) {
                        newSignals = [];
                        sourceCollectionData?.values?.filter((sourceSignal: any) => {
                            let signalFound = collectionFound?.values?.find((ele: any) => (ele?.label.toLowerCase() === sourceSignal?.label.toLowerCase()));
                            if (signalFound) {
                                isDuplicateSignalAvailable = true;
                                matchingCollectionSignals.push({ "signalId": sourceSignal._id, "signalName": sourceSignal.label, "signalCollectionId": sourceCollectionData._id, "collectionName": collectionFound.name, "signalData": sourceSignal });
                            } else {
                                newSignals.push(sourceSignal);
                            }
                        })
                        if (newSignals.length > 0) {
                            const uuid = uuidv4();
                            sourceCollectionData._id = uuid;
                            newSignals?.filter((signals: any) => {
                                const uuid = uuidv4();
                                signals._id = uuid;
                            })
                            sourceCollectionData.values = newSignals;
                            copyCollectionData.push(sourceCollectionData);
                        }
                        if (isDuplicateSignalAvailable) {
                            this.setState({
                                matchingSignals: true
                            });
                        }
                    }
                    else {
                        const uuid = uuidv4();
                        sourceCollectionData._id = uuid;
                        sourceCollectionData.values?.filter((signals: any) => {
                            const uuid = uuidv4();
                            signals._id = uuid;
                        })
                        copyCollectionData.push(sourceCollectionData);
                    }
                }
            });
            if (matchingCollectionSignals.length > 0) {
                this.setState({
                    duplicationCollectionSignals: matchingCollectionSignals
                });
            }
            if (copyCollectionData.length > 0) {
                this.setState({
                    newCollectionSignals: copyCollectionData
                });
            }
        }
    }

    hasDuplicateSignalName = (collection:any, signalName:any) => {
        var hasDuplicate = Object.create(null);
        return collection.some((coll:any) => {
           return coll[signalName] && (hasDuplicate[coll[signalName]] || !(hasDuplicate[coll[signalName]] = true));
        });
     };

    onFinish = (values: any) => {
        const { templateId, collectionId } = values;
        let tempOrgId= this.props.templatesList.templates.find((template:any)=>template.templateId===templateId).templateOrgId;
        let destTemplateData = JSON.parse(this.props.templatesList?.selectedTemplateDetails?.datapoints);
        if (!collectionId) {
            if (this.state.matchingSignals && !this.state.signalOverride) {
                this.setState({
                    templateOrgId: tempOrgId,
                    isModalVisible: true
                });
            }
            else if (!this.state.matchingSignals) {
                this.state.newCollectionSignals?.filter((col: any) => {
                    let sameCollectionExists: boolean = false;
                    for(let i = destTemplateData.length-1;i>=0;i--) {
                        if(col.name === destTemplateData[i]?.name){
                            sameCollectionExists = true;
                            let signalsmerged=[...destTemplateData[i].values,...col.values]
                            destTemplateData[i].values = signalsmerged;
                        }
                        if (sameCollectionExists) {
                           break;
                        }
                         }                    
                    if (!sameCollectionExists) {
                        destTemplateData.push(col);
                    }
                });
                let isDuplicateNameavailable :any = false;
                //checking for duplicate collection name
                if(!isDuplicateNameavailable){
                    isDuplicateNameavailable = this.hasDuplicateSignalName(destTemplateData,"label");
                }                
                for(let i = destTemplateData?.length-1;i>=0;i--) {
                    if(!isDuplicateNameavailable){
                        //checking for duplicate signal name in a collection
                        isDuplicateNameavailable = this.hasDuplicateSignalName(destTemplateData[i].values,"label");
                    }
                }
                if(!isDuplicateNameavailable){
                    this.props.templateCopyCollection(this, this.onFinishSuccess, this.onFinishFailure, templateId, tempOrgId, destTemplateData);
                    this.props.closePopUp();
                }
                else
                {
                    this.setState({isModalVisibleForDuplicateName: true});
                }
            }

        }
        else {
            if (this.state.destinationCollectionExists) {
                if (this.state.matchingSignals && !this.state.signalOverride) {
                    this.setState({
                        templateOrgId: tempOrgId,
                        isModalVisible: true
                    });
                }
                else {
                    let isDuplicateNameavailable :any = false;
                    this.state.newCollectionSignals?.filter((col: any) => {
                        for(let i = destTemplateData?.length-1;i>=0;i--) {
                            if(col.name === destTemplateData[i].name){
                                let mergedsignals = [... destTemplateData[i].values,...col.values];
                                destTemplateData[i].values = mergedsignals;
                            }
                            if(!isDuplicateNameavailable){
                                isDuplicateNameavailable = this.hasDuplicateSignalName(destTemplateData[i].values,"label");
                            }
                        }
                    });
                    //checking for duplicate collection name
                    if(!isDuplicateNameavailable){
                        isDuplicateNameavailable = this.hasDuplicateSignalName(destTemplateData,"label");
                    }  
                    if(!isDuplicateNameavailable){
                        this.props.templateCopyCollection(this, this.onFinishSuccess, this.onFinishFailure, templateId, tempOrgId, destTemplateData);
                        this.props.closePopUp();
                    }
                    else
                    {
                        this.setState({isModalVisibleForDuplicateName: true});
                    }
                }
            }
            else {
                let isDuplicateNameavailable :any = false;
                //checking for duplicate collection name
                if(!isDuplicateNameavailable){
                    isDuplicateNameavailable = this.hasDuplicateSignalName(destTemplateData,"label");
                }            
                destTemplateData.push(this.state.newCollectionSignals);
                for(let i = destTemplateData?.length-1;i>=0;i--) {
                    if(!isDuplicateNameavailable){
                        //checking for duplicate signal name in a collection
                        isDuplicateNameavailable = this.hasDuplicateSignalName(destTemplateData[i].values,"label");
                    }
                }
                if(!isDuplicateNameavailable){
                    this.props.templateCopyCollection(this, this.onFinishSuccess, this.onFinishFailure, templateId, tempOrgId, destTemplateData);
                    this.props.closePopUp();
                }
                else
                {
                    this.setState({isModalVisibleForDuplicateName: true});
                }
            }
        }
    };
    onFinishSuccess = (record: any, orgId:any) => {
        this.setState({
            isModalVisible: false,
            signalOverride: false
        });
        this.props.getTemplatesForCopyCollection(this,this.getOrgTemplatesSuccess, null,orgId, null);
        message.success("Template collections has been successfully copied");
        this.props.onDrawerClose();
    }

    onFinishFailure = (error: any) => {
        errorResponse(this, error);
    }

    setSignalTree = (record: any) => {
        if (record.datapoints) {
            const treeData = this.formatTreeData(record.datapoints);
            let uniqueExpandKeys = this.setExpanededKey(treeData);
            this.setState({
                sourceDataPoints: record.datapoints,
                signalData: treeData,
                expandedKeys: uniqueExpandKeys,
                enableSignalCollectons: true,
                checkedKeys: uniqueExpandKeys
            });
            this.props.signalValidation(uniqueExpandKeys.length, true);
        }
    }

    formatTreeData = (datapoints: any) => {
        let signalCollection = JSON.parse(datapoints || []);
        let count = 0;
        let genericCollections: any = [];
        signalCollection.map((ele: any) => {
            if (!ele?.type || ele?.type.toLowerCase() === "none") {
                genericCollections.push(ele);
                count++;
                ele.values?.filter((signal: any) => {
                    count++
                })
            }
        })
        this.setState({ signalCount: count });
        const treeData = genericCollections.map(createTreeStructure);
        this.getFlattenedSignals(signalCollection);
        return treeData;
    };

    getFlattenedSignals = (datapoints: any) => {
        let signals: any = [];
        datapoints.forEach((sc: any) => {
            if (sc.values && sc.values.length > 0) {

                let signalIds: any = [];
                sc.values.forEach((sig: any) => {
                    signalIds.push(sig._id)
                });
                signals.push({ 'signalCollectionId': sc._id, 'signalId': signalIds })

            }
            else {
                signals.push({ 'signalCollectionId': sc._id, 'signalId': null })
            }
        })
        this.setState({ flattenedSignals: signals });
    }

    setExpanededKey = (data: any) => {
        let uniqueExpandKeys;
        const expanedKeys = data.reduce(
            (acc: any, curr: any) => {
                acc = acc.concat(curr.key);
                return acc;
            },
            []
        );
        if (expanedKeys) {
            uniqueExpandKeys = expanedKeys.filter((elem: any, pos: any) => {
                return expanedKeys.indexOf(elem) === pos;
            });
        }
        return uniqueExpandKeys;
    }

    onExpand = (expandedKeysValue: any) => {
        this.setState({ expandedKeys: expandedKeysValue, autoExpandParent: false });
    };



    onCheck = (checkedKeysValue: any) => {
        const selectedSignals: any = [];
        let sourceTemplateData = JSON.parse(this.props.templatesList?.templateMaint?.record?.datapoints);
        let destTemplateData = (this.props.templatesList?.selectedTemplateDetails?.datapoints)?JSON.parse(this.props.templatesList.selectedTemplateDetails.datapoints):null;//.find((ele: any) => (ele.templateId === this.formRef.current?.getFieldsValue().templateId)).datapoints);
        if (checkedKeysValue.length > 0) {
            sourceTemplateData.map((st: any) => {
                for (let i = st.values?.length - 1; i >= 0; i--) {
                    if (!checkedKeysValue.includes(st.values[i]._id)) {
                        st.values.splice(i, 1);
                    }
                }
            });
            for (let i = sourceTemplateData.length - 1; i >= 0; i--) {
                if ((!checkedKeysValue.includes(sourceTemplateData[i]._id)
                    && sourceTemplateData[i].values.length === 0)
                ) {
                    sourceTemplateData.splice(i, 1);
                }
            }
            this.setState({sourceTemplateData: sourceTemplateData});
            if(destTemplateData){
                this.validateSignalCollection(sourceTemplateData, destTemplateData, this.formRef.current?.getFieldsValue().templateId, this.formRef.current?.getFieldsValue().orgId, this.formRef.current?.getFieldsValue().collectionId);
            }
        }

        let isEnableSignalCollection = this.state.signalCount - checkedKeysValue.length == 0;
        this.formRef.current!.setFieldsValue({
            clonedSignals: isEnableSignalCollection ? null : selectedSignals
        });
        this.setState({
            checkedKeys: checkedKeysValue,
            enableSignalCollectons: isEnableSignalCollection
        });

        this.props.signalValidation(checkedKeysValue.length, isEnableSignalCollection);
    };

    onEnableSignalCollection = (checked: any) => {
        let keyLength: number;
        if (!checked) {
            keyLength = 0;
            this.setState({ checkedKeys: [],
            duplicationCollectionSignals:[] });

        }
        else {
            keyLength = this.state.expandedKeys.length;
            this.setState({ checkedKeys: this.state.expandedKeys })
            this.formRef.current!.setFieldsValue({
                clonedSignals: null
            });
        this.validateSignalCollection(this.props.templatesList.templateMaint.record?.datapoints?JSON.parse(this.props.templatesList.templateMaint.record?.datapoints):null, 
        this.props.templatesList.selectedTemplateDetails?.datapoints?JSON.parse(this.props.templatesList.selectedTemplateDetails.datapoints):null,
                    this.formRef.current?.getFieldsValue().templateId, this.formRef.current?.getFieldsValue().orgId,
                    this.formRef.current?.getFieldsValue().collectionId);  
        }
        this.setState({ enableSignalCollectons: checked });
        this.props.signalValidation(keyLength, checked);
        
    };
    handleOkDuplicateName = () => {
        this.setState({
            isModalVisibleForDuplicateName: false
        });
    }
    handleCancelDuplicateName = () => {
        this.setState({
            isModalVisibleForDuplicateName: false
        });
    }
    handleCancel = () => {
        this.setState({
            isModalVisible: false
        });
        this.props.closePopUp();
    };

    handleOk = () => {
        this.setState({
            isModalVisible: false,
            signalOverride: true
        });         
        let destTemplateData = JSON.parse(this.props.templatesList?.selectedTemplateDetails?.datapoints);
        if (!this.formRef.current?.getFieldsValue().collectionId) {
            destTemplateData?.filter((ele: any) => {
                this.state.duplicationCollectionSignals?.filter((duplicate: any) => {
                    if (duplicate.collectionName.toLowerCase() === ele.name.toLowerCase()) {
                        for(let j=ele.values.length-1;j>=0;j--) {
                            if(ele.values[j].label.toLowerCase()===duplicate.signalName.toLowerCase()) {
                                ele.values.splice(j,1);
                                ele.values.push(duplicate.signalData);
                            }
                        } 
                    }
                });
            });

            this.state.newCollectionSignals?.filter((col: any) => {
                let sameCollectionExists: boolean = false;
                for (let i = destTemplateData?.length - 1; i >= 0; i--) {
                    if (destTemplateData[i]?.name.toLowerCase() === col.name?.toLowerCase()) {
                        sameCollectionExists = true;
                            let signalsmerged=[...destTemplateData[i].values,...col.values]
                            destTemplateData[i].values = signalsmerged;
                    }
                    if (sameCollectionExists) {
                        break;
                     }
                }
                if (!sameCollectionExists) {
                    destTemplateData.push(col);
                }
            });
        }
        else {
            if (this.state.destinationCollectionExists) {
                
                destTemplateData?.filter((ele: any) => {                    
                        if (ele._id === this.formRef.current?.getFieldsValue().collectionId) {
                            this.state.duplicationCollectionSignals?.filter((duplicate: any) => {
                            let replaced:boolean = false;
                            for(let j=ele.values.length-1;j>=0;j--) {
                                if(ele.values[j].label.toLowerCase()===duplicate.signalName.toLowerCase()) {
                                    ele.values.splice(j,1);
                                    ele.values.push(duplicate.signalData);
                                    replaced = true;
                                }
                                if(replaced)
                                {
                                    break;
                                }
                            }
                        });
                        }
                   
                });
                this.state.newCollectionSignals.map((ele: any) => {
                    if (ele._id === this.formRef.current?.getFieldsValue().collectionId) {
                        destTemplateData?.filter((destele: any) => {
                            if(destele.name?.toLowerCase()===ele.name?.toLowerCase()){
                                let signalsmerged = [...ele?.values,...destele?.values];
                                destele.values = signalsmerged;
                            }

                        });
                    }
                });
            }
        }
        let isDuplicateNameavailable :any = false;
        //checking for duplicate collection name
        if(!isDuplicateNameavailable){
            isDuplicateNameavailable = this.hasDuplicateSignalName(destTemplateData,"label");
        }
        for(let i = destTemplateData?.length-1;i>=0;i--) {
            if(!isDuplicateNameavailable){
                //checking for duplicate signal name in a collection
                isDuplicateNameavailable = this.hasDuplicateSignalName(destTemplateData[i].values,"label");
            }
        }
        if(!isDuplicateNameavailable){
            this.props.templateCopyCollection(this, this.onFinishSuccess, this.onFinishFailure, this.formRef.current?.getFieldsValue().templateId, this.state.templateOrgId, destTemplateData);
            this.props.closePopUp();
        }
        else
        {
            this.setState({isModalVisibleForDuplicateName: true});
        }
    };

    render() {
        let { templates } = this.props.templatesList;
        templates = templates?.filter((template:any)=> template.templateId!==this.props.templatesList.templateMaint.record?.templateId);
        const  destTemp = this.props.templatesList?.selectedTemplateDetails?.datapoints;
        let destgenericCollections:[] = [];
        if(destTemp !== null && destTemp !== undefined) {
            destgenericCollections = JSON.parse(destTemp).filter((data:any)=> (!data.type || data.type==="none"));
        }
        const { orgs } = this.props.templatesList.templateMaint.modes[1];
        this.state.signalData.filter((ele: any) => {
            ele.children?.filter((child: any) => {
                child.icon = null;
            })
        });
        if (this.state.duplicationCollectionSignals.length > 0) {
            this.state.signalData.filter((ele: any) => {
                ele.children?.filter((child: any) => {
                    let collection = this.state.duplicationCollectionSignals.find((col: any) => (col.signalId === child.key));
                    if (collection) {
                        child.icon = 
                        <WarningOutlined title="Indicates matching signal in source and destination template collections" style={{ color: "red" }}  />
                    }
                    else {
                        child.icon = null;
                    }
                })
            })
        }
        else
        {
            this.state.signalData.filter((ele: any) => {
                ele.children?.filter((child: any) => {
                        child.icon = null;
                })
            })
        }
        return (

            <>
                {this.state.formSuccess && !this.state.formError && <SuccessAlert data-id="template-copy-success-message" message={this.state.formSuccessMessage} />}
                {this.state.formError && !this.state.formSuccess && <ErrorAlert data-id="template-copy-error-message" message={this.state.formErrorMessage} />}

                <Form
                    style={{ marginTop: 15 }}
                    // {...appConfigs.settings.formLayout.mainLayout}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 24 }}
                    id="templateCopyCollectionForm"
                    ref={this.formRef}
                    onFinish={this.onFinish}
                >
                    <Form.Item
                        name="clonedSignals"
                        style={{ display: 'none' }}
                    >
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item
                        name="templateId"
                        style={{ display: 'none' }}
                    >
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item
                        name="orgId"
                        label="Destination Org"
                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                    >
                        <Select
                            data-id="assets-maint-asset-info-org-id"
                            showSearch
                            showArrow={true}
                            optionFilterProp="children"
                            className="select-before"
                            placeholder="Select..."
                            allowClear
                            onChange={this.onOrgIdChange}
                        >
                            {orgs !== null && orgs.map((record: any, index: number) => (
                                <Option key={index} value={record.orgId}>
                                    {record.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Destination Template"
                        name="templateId"
                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                    >
                        <Select
                            data-id="template-copy-collection-template-id"
                            showSearch
                            showArrow={true}
                            optionFilterProp="children"
                            className="select-before"
                            placeholder="Select..."
                            loading = {templates?.length === 0  && this.formRef.current?.getFieldsValue().orgId
                                && !this.state.receivedorgTemplates}
                            //disabled = {templates.length === 0 }
                            allowClear
                            onChange={(value:any)=>this.onTemplateIdChange(value,true)}
                        >
                            {templates !== null && templates?.map((record: any, index: number) => (
                                record.templateVisibleToSubOrg ?
                                    <Option key={index} value={record.templateId} style={{ color: "blue" }}>
                                        {record.name}
                                    </Option>
                                    :
                                    <Option key={index} value={record.templateId}>
                                        {record.name}
                                    </Option>
                            ))}
                        </Select>
                        {
                            templates?.length === 0 
                            && this.formRef.current?.getFieldsValue().orgId
                            && this.state.receivedorgTemplates &&
                            <div style={{ color: 'blue' }}>
                            <span>No templates available for the selected Organization</span>
                            </div>
                        }
                    </Form.Item>

                    <Form.Item
                        name="collectionId"
                        label="Destination Collection"
                    // rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                    >
                        <Select
                            mode="tags"
                            data-id="template-copy-collection-destination-collection-id"
                            showSearch
                            showArrow={true}
                            optionFilterProp="children"
                            className="select-before"
                            placeholder="Select..."
                            allowClear
                            maxTagCount={1}
                            loading = {destgenericCollections.length === 0 && templates?.length > 0 
                                && this.state.templateId
                            && !this.state.receivedsignalCollections}
                            //disabled = {destgenericCollections.length === 0}
                            value={this.state.destinationCollection}
                            onChange={(value: any, options: any) => this.onCollectionChange(value, options)}
                        >
                            { destgenericCollections.length > 0 && destgenericCollections.map((record: any, index: number) => (
                                <Option key={index} value={record._id}>
                                    {record.name}
                                </Option>
                            ))}
                        </Select>
                        {
                            destgenericCollections?.length === 0 && templates?.length > 0
                            && this.state.templateId
                            && this.state.receivedsignalCollections &&
                            <div style={{ color: 'blue' }}>
                            <span>No collections in the selected template</span>
                            </div>
                        }
                    </Form.Item>
                    {
                        this.state.duplicationCollectionSignals?.length > 0 &&
                        <div style={{ color: 'red', paddingLeft: '50px', paddingBottom:'10px' }}>
                            <WarningOutlined style={{ color: "red" }} /> <span>Indicates matching signals in source and destination template collections</span>
                        </div> 
                    }

                    {
                        (JSON.parse(this.props.templatesList?.templateMaint?.record?.datapoints).filter((collection:any) => 
                        (!collection.type || collection.type==="none")).length === 0) &&
                        <div style={{ color: 'red', paddingLeft: '50px', paddingBottom:'10px' }}>
                        <span>No signals available to copy. Please select a source template with atleast one generic collection.</span>
                        </div>
                    }

                    {
                        (JSON.parse(this.props.templatesList?.templateMaint?.record?.datapoints).filter((collection:any) => 
                        (!collection.type || collection.type==="none")).length > 0) &&
                        <Form.Item
                        label="Signal Collections"
                        name="enableSignalCollectons"
                        valuePropName="checked"
                        >
                        <Switch
                            checked={this.state.enableSignalCollectons}
                            onChange={this.onEnableSignalCollection}
                        />
                        <span style={{ marginLeft: '3px' }}>Select all</span>
                        {this.state.checkedKeys.length === 0 &&
                            <div style={{ color: 'red', marginLeft: '2px' }}>Select atleast one signal</div>
                        }
                    </Form.Item> 
                    }              
                    <Form.Item
                        label=""
                        style={{ marginLeft: '140px' }}
                        name="signals"
                    >
                        <Tree
                            checkable
                            onExpand={this.onExpand}
                            expandedKeys={this.state.expandedKeys}
                            autoExpandParent={this.state.autoExpandParent}
                            onCheck={this.onCheck}
                            checkedKeys={this.state.checkedKeys}
                            selectedKeys={this.state.selectedkeys}
                            showIcon
                            treeData={this.state.signalData}
                        //treeData={sourceTree}
                        />
                    </Form.Item>
                    {<Modal title="Duplicate Signal found" visible={this.state.isModalVisible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[
                            <Button onClick={this.handleCancel}>No</Button>,
                            <Button type="primary"  onClick={this.handleOk}>
                                Yes
                            </Button>,
                        ]}
                    >
                        <ul>
                            {this.state.duplicationCollectionSignals.length>0?this.state.duplicationCollectionSignals?.map((record: any) => (
                                <li>{record.signalName}</li>
                            )):null}
                        </ul>
                        <p>Same collection signals found in the destination template. Do you want to override destination template collection signals data?</p>
                        <p><b><h3>Note: Once those signals are Overridden, previously collected signals data will be lost.</h3></b></p>
                    </Modal>
                    }
                    {<Modal title="Duplicate Signal Name" visible={this.state.isModalVisibleForDuplicateName}
                        onOk={this.handleOkDuplicateName}
                        onCancel={this.handleCancelDuplicateName}
                        footer={[
                            <Button type="primary"  onClick={this.handleOkDuplicateName}>
                                OK
                            </Button>,
                        ]}
                    >
                        <ul>
                            <li>Same collection name not allowed multiple times within a template</li>
                            <li>Same signal name not allowed multiple times within a collection.</li>
                        </ul>
                        <p>Please remove the duplicate names to proceed further.</p>
                    </Modal>
                    }
                </Form>

            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        context: state.contextReducer.context,
        assetsList: state.assetsReducer.assetsState.assetsList,
        templatesList: state.templatesReducers.templatesState.templatesList
    };
};

export default connect(
    mapStateToProps,
    actions
)(TemplateCopyCollection);