import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function assetSubscriptionsSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "ASSET_SUBSCRIPTIONS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}   

export function assetSubscriptionsSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "ASSET_SUBSCRIPTIONS_SET_SEARCH", searchText: searchText });
    }
}   

export function assetSubscriptionsGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    gatewayId: string,
    searchText: string,
    page: number = 1
) {
    return async (dispatch: any) => {            
        Axios.get(
            appConfigs.server.URL + "/ui/api/assets/" + gatewayId + "/subscriptions", {
                    responseType: "json",        
                    headers: {},
                    params: {
                        searchText: searchText,
                        page: page,
                        pageSize: appConfigs.tables.pageSize
                    }
                }
            )
            .then(response => {
                    if (callbackFnSuccess != null) {
                        callbackFnSuccess(response.data.data.assetSubscriptions);
                    }
                    dispatch({ type: "ASSET_SUBSCRIPTIONS_GET_SUCCESS", payload: {data: response.data.data.assetSubscriptions, page: page} });
                }
            )
            .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
            
    };
}    

export function assetSubscriptionsMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    gatewayId: string,
    record: any,
    notification: string,
    action: string,
    scope: string
) {
    return async (dispatch: any) => {
        Axios.put(
            appConfigs.server.URL + "/ui/api/users/" + record.userId + "/subscriptions" + 
                "?gatewayId=" + gatewayId +
                "&signalId=" + record.signalId + 
                "&notification=" + notification + 
                "&action=" + action +
                "&scope=" + scope,
            {},
            {
                responseType: "json"
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(gatewayId, record, notification, action, scope);           
                }
                dispatch({ type: "ASSET_SUBSCRIPTIONS_MAINT_SUCCESS", payload: {record: record, notification: notification, action: action, scope: scope} });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error, gatewayId, record, notification, action, scope);
                }
            }
        );
    }
}
