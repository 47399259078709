import React, { useState } from "react";
import { Tree } from "antd";
import { transpileModule } from "typescript";

type Value = string[];

interface Props {
  value?: Value;
  onChange?: (value: Value) => void;
  treeData: any;  
  expandedKeys: string[],
  onExpand: any,
  iscreate: boolean,
  isupdate: boolean,
  isread: boolean,
  isGlobalTemplate: boolean,
  isadmin: boolean
}

const SignalTree: React.FC<Props> = (props) => {
  const assertDefined = (propName: keyof Props) => {
    if (props[propName] === undefined) {
      console.error(`Property '${propName} must be defined for ListSelector`);
    }
  };
  assertDefined("onChange");
  const { value = [], onChange, treeData } = props;

  const triggerCheck = (newValues: Value) => {
     if (onChange) {
       onChange(newValues);
     }
  };

  const onCheckNode = (selectedKeys: any) => {
    // console.log(selectedKeys);    
     triggerCheck(selectedKeys);
  };  

  const { isadmin, iscreate, isupdate, isread, isGlobalTemplate } = props;

  return (
    <>
    {
    isread &&
    <span>
      <Tree
        checkable
        disabled={isGlobalTemplate && !isadmin || !isGlobalTemplate && !isupdate && !iscreate}
        checkStrictly={true}
        onExpand={(keys)=>props.onExpand(keys)}
        expandedKeys={props.expandedKeys}                
        onCheck={onCheckNode}
        checkedKeys={value}
        treeData={treeData}
      />
    </span>
  }
  {!isread &&
  <span><Tree/></span>}
  </>
  );
};

export default SignalTree;
