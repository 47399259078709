import React from "react";
import { EditFormProps } from "../../WidgetEditForm/EditFormContainer";
import { WidgetTitleFormField, Container } from "../../WidgetEditForm";
import { SignalId } from "../../models";
import { Config } from ".";
import { Form, Card, Row, Col } from "antd";
import TrackerSignalSelectorList, {
  TrackerSignalField,
} from "./TrackerSignalSelectorList";
import { SignalOpts } from "./Widget";

interface Fields {
  signals: { id: SignalId; min?: number; max?: number }[];
  title: string;
}

function parseOptions(signalFields: Fields["signals"]): Config["settings"] {
  return signalFields
    .filter((s) => s.min !== undefined || s.max !== undefined)
    .map((s) => ({
      signalId: s.id,
      opts: {
        axisRange: {
          min: s.min,
          max: s.max,
        },
      },
    }));
}

function sanitizeInputs(f: Fields): Config {
  const validSignals = f.signals.filter((s) => !!s.id);

  return {
    title: f.title,
    signals: validSignals.map((s) => { return { id: s.id, color: "#000000"}}),
    type: "tracker",
    settings: parseOptions(validSignals),
  };
}

function populateSignals(
  signals: Config["signals"],
  opts: Config["settings"]
): Fields["signals"] {
  return signals.map((id) => {
    let currentOpt: SignalOpts | undefined;
    if(Array.isArray(opts)) {
      currentOpt = opts?.find((opt) => opt.signalId === id.id);
    }    
    
    const result = {
      id: id.id,
      min: currentOpt?.opts.axisRange?.min,
      max: currentOpt?.opts.axisRange?.max,
    };
    return result;
  });
}

function populateForm(c?: Config): Fields | undefined {
  if (!c) return undefined;

  return {
    title: c.title,
    signals: populateSignals(c.signals, c.settings),
  };
}

const EditForm: React.FC<EditFormProps> = (props) => {
  const initialVals = populateForm(props.config as Config);

  return (
    <div className="tracker-widget-edit-form">
      <Form
        layout="vertical"
        onFinish={(fields) => props.onFinish(sanitizeInputs(fields as Fields))}
        form={props.form}
        initialValues={initialVals}
      >
        {/* <Container title="Tracker Widget Settings">
          <WidgetTitleFormField />
        </Container> */}
        <Card title="Tracker Widget Settings">
          <Row style={{ width: "95%" }}>
            <Col span={20}>
            <WidgetTitleFormField />
            </Col>
          </Row>        
          <Form.Item
            name="signals"
            rules={[
              {
                validator(rule, value) {
                  return !value ||
                    value.filter((v: TrackerSignalField) => !!v.id).length < 1
                        ? Promise.reject("At least on valid signal must be selected")
                        : (value.filter((v: TrackerSignalField) => (v.min && v.max && v.min > v.max)).length > 0
                            ? Promise.reject("Min value must be less than, or equal to, Max value")
                            : Promise.resolve());
                },
              },
            ]}
          >
            <TrackerSignalSelectorList
              options={props.template.signals.map((s) => ({
                value: s.id,
                label: s.name,
              }))}
              max={7}              
            />
          </Form.Item>
        </Card>
      </Form>
    </div>
  );
};

export default EditForm;
