import moment, { Moment } from "moment";
import { WidgetExportConfig } from ".";
import { appConfigs } from "../../../utils/configurations";
import { State, WidgetState } from "../state";
import * as Api from "../api";
import { FindAssetTimeZone, addDateAndTime, findTimezone } from "../widgets/common";
import { WidgetSignalId } from "../models";



function lastTimeStamp(apiResponse: any) {
  const lastDatapoint = apiResponse[apiResponse.length - 1];
  const utc = moment.utc(lastDatapoint["timestamp"]);
  return moment.utc(lastDatapoint["timestamp"]);
}

function nextDayTimeStamp(startDate: Moment) {
  let tomorrow = startDate.add(1, "days");
  return tomorrow.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
}

export function exportCsv(
  state: State,
  widgetState: WidgetState,
  cnfg: WidgetExportConfig
): Promise<any> {
  const datapointArray: any = [];
  widgetState.signals.forEach((si) => {
    const datapoint = state.template.signalCollections.filter((s) =>
      s.signals.find((sg) => sg.id === si.id)
    )[0];

    if (datapoint && datapoint["id"]) {
      datapointArray.push({ datapointId: datapoint.id, name: datapoint.name });
    }
  });
  //stop the repeataion of datapoints
  const uniqsignalCollectionId = datapointArray
    .filter((elem: any, pos1: any, arr: any) => {
      return arr.findIndex((t:any)=>(t.datapointId === elem.datapointId))===pos1
    })
    .filter((x: any) => !!x);

  //ops-2758
  //if right Y-axis signals are present then including collection ids into actual signal collections
  if((widgetState.rightYAxisSignals||[]).length > 0)
  {
    const rightYAxisSigCollectionId=getRightYAxisSignals(widgetState.rightYAxisSignals!,state);
    if(rightYAxisSigCollectionId !== null && rightYAxisSigCollectionId.length > 0)
    {
      uniqsignalCollectionId.push(...rightYAxisSigCollectionId);
    }
  }  
  let startDate = addDateAndTime(cnfg.startDate, cnfg.startTime);
  let endDate = addDateAndTime(cnfg.endDate, cnfg.endTime);

  const promises = uniqsignalCollectionId.map((elem: any) =>  fetchDataSlice(startDate, endDate, 3000, elem.datapointId, elem.name, state.asset.id, state.asset.orgId,state.timezone));
  return Promise.all(promises);
}

async function fetchDataSlice(
  startDate: Moment,
  stopDate: Moment,
  limit: number,
  datapointId: string,
  datapointName: string,
  gatewayId: string,
  orgId?: string,
  timezone?:any
) {
  let MAX_DATAPOINT_LIMIT = appConfigs.app.assetOverview.maxdatapointlimit;

  let allowed30DaysExport = [
    'c6adcb40-be92-11e6-9ed6-a5bc9cb5279b', // Parker
    '616c4c61-865d-11eb-871a-13cc80610287', // Komatsu Germany GmbH
    'a09ad270-3b21-11e7-893e-f94b4bc7246b', // Taylor
    "231cb420-f381-4b5a-ad42-ae13f347075a",	// "City of Calgary"
    "eb785adc-16a9-40db-a331-dc92fde719e4",	// "NTM Global"
    "e13ad975-82b1-4f3a-a6b2-a3368b011087",	// "Ohlssons AB"
    "12ca9505-a866-4d4d-b05e-1f02edd87ff4",	// "NTM Canada"
    "f2f3ced8-154b-4e0e-90b4-907b98e02a94",	// "NTM Finland"
    "f9c815bb-3fe4-49a3-b6dc-46a5396e006b",	// "NTM Sweden"
    "586986cc-99c2-418b-8383-23f9d07a0c27",	// "Customer1 Finland"
  ]
  
  if (orgId && allowed30DaysExport.includes(orgId)) {
    MAX_DATAPOINT_LIMIT = 50000
  }
  const MAX_API_LIMIT = appConfigs.app.assetOverview.maxapilimit;
  let eventData: any = [];

  let startDate1  = startDate.clone();
  let stopDate1 = stopDate.clone();

  const formatResponse = (responseData: any) => ({
    datapointId: datapointId,
    datapointName: datapointName,
    datapointRecords: responseData,
  });

   
  while (
    stopDate1.valueOf() > startDate1.valueOf() &&
    eventData.length < MAX_DATAPOINT_LIMIT
  ) {
    try {      
      let { data } = await getDataFromApi(
        startDate1,
        stopDate1,
        limit,
        gatewayId,
        datapointId
      );
    
      //sort the from asc to desc
      data = data.sort((a: any, b: any) => {
        const timeStamp1 = moment(a["timestamp"]).valueOf();
        const timeStamp2 = moment(b["timestamp"]).valueOf();
        return timeStamp1 - timeStamp2
      });
      if (data.length < MAX_API_LIMIT || data.length === 0) {
        startDate1 = nextDayTimeStamp(startDate1);
      }
      if (data.length >= MAX_API_LIMIT) {
        startDate1 = lastTimeStamp(data).add(10, "milliseconds");
      }
      let remainingRecordToFetch =
        MAX_DATAPOINT_LIMIT - (eventData.length + data.length);
      if (remainingRecordToFetch < MAX_API_LIMIT) {
        limit = remainingRecordToFetch;
      }
    //OPS-3634 To convert UTC to Local time for export csv file
      data.forEach((item: any) =>{
        let details:any;
        if(timezone.coordinates.latitude && timezone.coordinates.longitude){
          //details =findTimezone(state.timestamp);
          details =FindAssetTimeZone(timezone.coordinates,item.timestamp);
          if (timezone.isGpsSignalAvailable && timezone.value=="asset" && details){
            item.timestamp=moment(details.timezoneDetails.geo_local_date_str).format().slice(0,-6)
          }
          else{
            item.timestamp=moment(item.timestamp).local().format().slice(0,-6)
          }

          //item.timestamp=moment(details.timezoneDetails.geo_local_date_str).format().slice(0,-6)
        }
        else{
          item.timestamp=moment(item.timestamp).local().format().slice(0,-6)
        }
        //item.timestamp=moment(item.timestamp).local().format().slice(0,-6)
        //console.log("timestamp,",item.timestamp)
      })
      eventData = [...eventData, ...data];      
    } catch (error) {
      throw new Error(`Error in fetching data: ${error}`);      
    }
  }
  return formatResponse(eventData);
}

async function getDataFromApi(
  startDate: Moment,
  stopDate: Moment,
  limit: number,
  gatewayId: string,
  datapointId: string
): Promise<any> {
  let conString = `${appConfigs.server.URL}/ui/api/assets/gateway/${gatewayId}/datapoint/${datapointId}/events`;

  conString += `?limit=${limit}`;

  conString += `&startDate=${startDate.valueOf()}`;
  let currDayEndDateTime = moment(startDate);
  currDayEndDateTime.set({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 999,
  });
  let currDayEndEpochTime = currDayEndDateTime.valueOf();

  if (currDayEndDateTime.valueOf() > stopDate.valueOf()) {
    conString += `&endDate=${stopDate.valueOf()}`;
  } else {
    conString += `&endDate=${currDayEndEpochTime}`;
  }
  return Api.csvExportApi(conString);
}

function getRightYAxisSignals(rightYAxisSignalIds:WidgetSignalId[],state:State){
  const datapointArray: any = [];
  let rightYAxisSigCollectionId:any=[]

  rightYAxisSignalIds.forEach((si) => {
   const datapoint = state.template.signalCollections.filter((s) =>
     s.signals.find((sg) => sg.id === si.id)
   )[0];

   if (datapoint && datapoint["id"]) {
     datapointArray.push({ datapointId: datapoint.id, name: datapoint.name,isRightYAxis:true });
   }
 });
 //stop the repeataion of datapoints
 rightYAxisSigCollectionId = datapointArray
   .filter((elem: any, pos1: any, arr: any) => {
     return arr.findIndex((t:any)=>(t.datapointId === elem.datapointId))===pos1
   })
   .filter((x: any) => !!x);
   return rightYAxisSigCollectionId;
}