/* eslint-disable @typescript-eslint/no-unused-vars */
import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function userguideState(state = initialState, action:any) {
    let data:any = null,
        payload:any;
        switch (action.type) {  
            case "USERGUIDE_GET_SUCCESS":
                return immerProduce(state, draft => {
                    draft.userguide.userguideList = action.data;
                });
            case "USERGUIDE_SET_SEARCH":
                    return immerProduce(state, draft => {
                    draft.userguide.searchText = action.searchText;
            });
            case "SET_TAB":
                    return immerProduce(state, draft => {
                    draft.userguide.activeTab = action.tabNumber;
            });
            default:
                return state;
        }
}