import React from "react";
import { Form, Card } from "antd";
import { Signal } from "../../models"
import {
    EditFormProps,
    Container,
    WidgetTitleFormField,
    SignalSelectorList,
  } from "../../WidgetEditForm";
  import { Config } from ".";

  function sanitizeInputs(c: Config): Config {
    return {
      title: c.title,
      signals: c.signals.filter((s) => !!s),
      type: "chat"
    };
  }

  const EditForm: React.FC<EditFormProps> = (props) => {
    const chatSignals: Signal[] = [];
    const orgCmdPgnSpns: string =  props.assetDetails.orgCmdPgnSpns; 
 
     props.template.signalCollections.map((collection)=>{
      if(collection.type && collection.type === "none" || collection.type && collection.type === "Command" ){
          collection.signals.filter((signal)=>(signal.format==="text" && 
          (collection.type != "Command" || 
           collection.type === "Command" && orgCmdPgnSpns.indexOf("65305")!==-1))) // this org is supporting command
            .map((signal)=> {
              chatSignals.push(signal)
            })
      }
    });

    return (
      <div className="chat-widget-edit-form">
        <Form
          onFinish={(values) => props.onFinish(sanitizeInputs(values as Config))}
          layout="vertical"
          form={props.form}
          initialValues={props.config}
        >
          <Card title="Chat History Widget Settings">
            <WidgetTitleFormField />
            <SignalSelectorList max={10} signals={chatSignals} orgCmdPgnSpns={orgCmdPgnSpns} rules= {[]} />
          </Card>
        </Form>
      </div>
    );
  };
  
  export default EditForm;
  