import React, { useEffect, useState, useCallback, CSSProperties } from "react";
import ReactDOMServer from 'react-dom/server'
import { Popover, Tooltip, Button } from "antd";

import { BorderOuterOutlined, FilterOutlined, RadarChartOutlined, UnorderedListOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons";
import { ReactComponent as TractorIconBlack } from "../../assets/images/tractor-black.svg";
import { ReactComponent as TractorIconWhite } from "../../assets/images/tractor.svg";
import { ReactComponent as geofenceIcon } from "../../assets/images/geofenceIcon.svg";
import { ReactComponent as moveGeofence } from "../../assets/images/move-geofence.svg";
import { ReactComponent as moveGeofenceActive } from "../../assets/images/move-geofence-active.svg";
import { ReactComponent as markerIcon } from "../../assets/images/marker.svg";
import { ReactComponent as markerActive } from "../../assets/images/markerActive.svg";
import { ReactComponent as zoomInIcon } from "../../assets/images/zoomin.svg";
import { ReactComponent as maximizeIcon } from "../../assets/images/maximize.svg";
import { ReactComponent as serviceTruck } from "../../assets/images/service-truck.svg";
import { ReactComponent as serviceTruckActive } from "../../assets/images/service-truck-active.svg";
import { ReactComponent as expandIcon } from "../../assets/images/expand.svg";
import { ReactComponent as KomatsuIconBlack } from "../../assets/images/komatsu-black.svg";
import { ReactComponent as KomatsuIconWhite } from "../../assets/images/komatsu.svg";


import AssetDrawer from "./overlays/AssetDrawer";
import FilterPanel from "./overlays/filterPanel/FilterPanel";
import { markerTooltipFromAsset, markerTooltipFromGeotabAsset } from "./overlays/MarkerTooltip";

import { getGeotabPin, getPin } from "./map-pins";
import { appConfigs } from "../../utils/configurations";

import usePolling from "../../lib/use-polling";
import { Map, Marker, MapActions } from "../../sharedComponents/GoogleMap";

import { connect } from "react-redux";
import * as actions from "./actions";
import history from '../../utils/history';
import { formatSignals, getOrgPermissionByName, getPermissions } from "../../utils/commonHelpers";
import FenceAssets from "../GeoFences/FenceAssets";
import GeofenceMaint from "../GeoFences/GeofenceMaint";
import FenceListDrawer from "../GeoFences/FenceListDrawer";
import { GeofenceTheme } from "../../sharedComponents/GoogleMap/geofenceStyle";
import { DEFAULT_MAP_OPTIONS, DEFAULT_MAP_OPTIONS_FOR_GEOFENCING } from "../../sharedComponents/GoogleMap/Map";
import { getBoundForInitialZoom } from "../GeoFences/geofenceHelper";
import { ServiceTruckMarker } from "../../sharedComponents/GoogleMap/MarkerManager";
import ServiceTrucksListDrawer from "../ServiceTrucks/ServiceTrucksListDrawer";
import ServiceTrucksMaint from "../ServiceTrucks/ServiceTrucksMaint";
import MapToolTip from "./MapToolTip";
import OverlayViewContainer from "./OverlayViewContainer";
interface Prop {  
    getFleetOverview: Function,  
    mapsAssetDrawerClusterOpen: Function,
    mapsAssetDrawerAssetSearchOpen: Function,
    mapsAssetDrawerClose: Function,
    fleetOverviewState: any,
    serviceTruckState: any,
    context: any,
    permissions: any,
    mapMode?: "satellite" | "roadmap",
    getPreferedSignals: Function,
    getServiceTrucks: Function      
    toggleMarkerToolTip: Function,
    updateToolTipMarkerDetails: Function  
}


interface Props {
    getGeoFences: Function,
    getAllAssetsList: Function,
    openDrawer:Function,
    openFenceListDrawer:Function,
    setCurShape:Function,
    addShape:Function,
    setMap:Function,
    setServiceTruckMap:Function,
    setFleetOverviewMap:Function,
    setDrawingManager:Function,
    setMarkerManager:Function,
    geofencesList: any,
    removeShapesFromMap:Function,
    addShapesToMap:Function
    updateShapeOnMap:Function
    clearGeoFencesState: Function,
    openServiceTruckDrawer: Function,
    uiPreferences: any
}


const FleetOverview = (props:(Prop & Props)) => {    

    const [mapActions, setMapActions] = useState<MapActions>();
    const [pollingEnabled, setPollingEnabled] = useState<boolean>(false);
    const [showServiceTrucks, setShowServiceTrucks] = useState <boolean>(false);
    const [serviceTruckMarkers, setserviceTruckMarkers] = useState < ServiceTruckMarker []>([]);
    const [isShowingGeofences, setShowingGeofenes] = useState(false);
    const [isGeofencesArrangeEnabled, setGeofenceArrangement] = useState(false);
    const [isShowingGeofencesMarkers, setShowingGeofenesMarkers] = useState(false);
    const result = getPermissions(props.permissions.permissions.assets);
    let isread = result[1];
    

    const geofencePermissions = getPermissions(props.permissions.permissions.geofencing);
    let canGeofencingCreate = geofencePermissions[0];
    let canGeofencingRead = geofencePermissions[1];
    let canGeofencingEdit = geofencePermissions[2];

    const { fenceList, fenceListLoaded, map, drawingManager, markerManager } = props.geofencesList;
    const { orgLevelPermissions } = props.permissions.permissions

    const geofenceOptions = {
        openDrawer: props.openDrawer,
        setCurShape: props.setCurShape,
        addShape: props.addShape,
        setMap: props.setMap,
        setDrawingManager: props.setDrawingManager,
        setMarkerManager: props.setMarkerManager,
        canGeofencingEdit: canGeofencingEdit,
        geofencesArrangeEnabled: isGeofencesArrangeEnabled
    }

    const toolTipOptions = {
        tooltipVisibility: props.fleetOverviewState.tooltip.visibility,
        toggleMarkerToolTip: props.toggleMarkerToolTip,
        updateToolTipMarkerDetails: props.updateToolTipMarkerDetails  
    }

    const serviceTruckOptions = {
        setServiceTruckMap: props.setServiceTruckMap,
    }

    const fleetOverviewOptions = {
        setFleetOverviewMap: props.setFleetOverviewMap,
    }

    const hasGeoTabAccess = getOrgPermissionByName(orgLevelPermissions, 'GeotabFeature', 'AllowGeoTabFeatureAccess')

    const loadFleetOverview = (originator: string) => {
        if (originator === "usePolling" && !pollingEnabled) {
            //
            //Prevent usePolling and useEffect fireing the API in the same time on component load event
            //
            return;
        }
        props.mapsAssetDrawerClose();
        props.getFleetOverview(loadFleetOverviewSuccess(originator), null, "map", null, originator,props.uiPreferences);
    }
    
    const loadFleetOverviewSuccess = (originator: string) => {        
        if (originator === "useEffect") {
            setPollingEnabled(true);
        }
    }

    const getFleetMarkers = (assets:any): Marker[] => {
        return assets.map(assetToMarker);
    }

    const assetToMarker = (asset:any, index:number): any => {
        return {
            id: asset.id,
            position: {
                lat: asset.position.latitude,
                lng: asset.position.longitude,
            },            
            tooltipHandler: "[data-id=map-tooltip-handler]",
            icon: getPin(asset)
        };
    }    

    const tooltipGenerator = () => {
        const assetId:string = window.sessionStorage.getItem("mapTooltipGenerator") || "";
        //
        // is assetId uuid?
        //
        if (assetId.length === 36) {
            props.fleetOverviewState.fleet.filteredAssets.forEach(function(asset:any, index:number) {
                if (asset.id === assetId) {                                     
                    window.sessionStorage.setItem("mapTooltipGenerator", ReactDOMServer.renderToString(markerTooltipFromAsset(asset, props.serviceTruckState.serviceTruckList.assets)));                 
                    props.getPreferedSignals(getPrefereSignalsSuccess, assetId);
                }
            });
        }        
        else
        {
            props.serviceTruckState.serviceTruckList.assets.forEach((geotabAsset:any) => {
                if(geotabAsset.VIN === assetId){
                    window.sessionStorage.setItem("mapTooltipGenerator", ReactDOMServer.renderToString(markerTooltipFromGeotabAsset(geotabAsset)));                 
                }
            })
        }


    }    

    const getPrefereSignalsSuccess = (response: any) => {
        const { assetId, signals = []} = response; 
        let assets: any;        
        if(signals.length > 0) {        
            if(document.querySelectorAll('[data-id=pinned-signals-tooltip]').length > 0) {
                const value = formatSignals(signals);
                document.querySelectorAll('[data-id=pinned-signals-tooltip]')[0].innerHTML = value;            
            }
        }
    }

    const tooltipLink = () => {
        const link:string = window.sessionStorage.getItem("mapTooltipLink") || "";

        if ((link || "") !== "") { 
            window.sessionStorage.removeItem("mapTooltipLink");    
            if (link.startsWith("/alerts")) {
                const linkArr = link.split("/");
                history.push({
                    pathname: linkArr[1],
                    state: [{ 
                        mode: linkArr[2], 
                        mastertag: linkArr[3], 
                        status: linkArr.length === 5 ? linkArr[4] : ""
                    }]
                });                
            }
            else {
                history.push(link);
            }
        }        
    }
    
    usePolling(
        () => {
            loadFleetOverview("usePolling");
        }, 
        appConfigs.app.fleetOverview.mapDataPoolingRate
    );

    const loadFleetOverviewCallback = useCallback(loadFleetOverview, []) 

    useEffect(() => {
        props.getGeoFences();
        props.getAllAssetsList();
        if(!props.serviceTruckState.serviceTruckList.assets.length && hasGeoTabAccess){
            props.getServiceTrucks();
        }
        return () => {
            props.clearGeoFencesState();
        }
    }, [])    

    useEffect(() => {
            loadFleetOverviewCallback("useEffect");
        },
        [loadFleetOverviewCallback]
    );

    const decideMapWidth = (): CSSProperties => {       
        return {
            height: "100%",
            width: "100%",
            position: "absolute"
        }      
    } 

    const serviceTruckToMarker = (asset:any, index:number): any => {
        return {
            id: asset.id,
            position: {
                lat: asset.latitude,
                lng: asset.longitude,
            },            
            tooltipHandler: "[data-id=map-tooltip-handler]",
            icon: getGeotabPin(),
            VIN: asset.VIN
        };
    }   
    
    const getserviceTruckMarkers = (assets:any): ServiceTruckMarker[] => {
        if(!assets){
            return [];
        }
        
        return assets.map(serviceTruckToMarker);
    }

    useEffect(() => {
        //Check permission
        if (hasGeoTabAccess) {
            const intervalId = setInterval(() => {
                props.getServiceTrucks();
            }, appConfigs.app.fleetOverview.mapGeoTabDataPoolingRate);

            return () => clearInterval(intervalId);
        }
    }, [props.serviceTruckState.serviceTruckList.assets])


    const toggleserviceTruckMarkers = () => {
        setShowServiceTrucks(!showServiceTrucks);
    }

    useEffect(() => {

        if (hasGeoTabAccess) {
            if (!showServiceTrucks) {
                setserviceTruckMarkers([])
            } else {
                setserviceTruckMarkers(getserviceTruckMarkers(props.serviceTruckState.serviceTruckList.assets));
            }
        }

    }, [showServiceTrucks]);

    

    const toggleGeofences = () => {
        if (isShowingGeofences) {
            props.removeShapesFromMap();
            map.setMapTypeId(props.mapMode || "roadmap")
        } else {
            map.setMapTypeId('roadmap')
            props.addShapesToMap();
        }
        setShowingGeofenesMarkers(false);
        setTimeout(() => {
            setShowingGeofenes(!isShowingGeofences);
        }, 10)
        
    }
    

    const toggleGeofencesMarkers = () => {
        setShowingGeofenesMarkers(!isShowingGeofencesMarkers);
    }


    const toggleGeofencesArrange = () => {
        setGeofenceArrangement(!isGeofencesArrangeEnabled);
    }

    const zoomToFixGeofences = () => {
        map.setZoom(map.getZoom() + 1)
        map.fitBounds(getBoundForInitialZoom(fenceList))
    }

    const zoomToFixServiceTrucks = () => {

        if (serviceTruckMarkers.length > 0) {

            map.setZoom(map.getZoom() + 1)

            let bounds = new google.maps.LatLngBounds();
            
            serviceTruckMarkers.map(marker => {
                bounds.extend(new google.maps.LatLng(marker.position.lat, marker.position.lng));
            });
        
            map.fitBounds(bounds)
        }
    }
    
    useEffect(() => {

        if (map&& isShowingGeofences) {
            map.setOptions({ ...DEFAULT_MAP_OPTIONS_FOR_GEOFENCING, styles: GeofenceTheme });
        } else if (map && !isShowingGeofences) {
            map.setOptions({ ...DEFAULT_MAP_OPTIONS, styles: [] });
        }

        if (drawingManager) {
            if (canGeofencingCreate) {
                drawingManager.showHideContols(map, isShowingGeofences)
            } else {
                drawingManager.showHideContols(map, canGeofencingCreate)
            }
        }

    }, [isShowingGeofences, isShowingGeofencesMarkers]);


    return (        
        <div style={decideMapWidth()}>

            <Map
                markers={!isShowingGeofences || isShowingGeofencesMarkers ? getFleetMarkers(props.fleetOverviewState.fleet.filteredAssets) : []}
                clusterMarkers={!isShowingGeofencesMarkers}
                onClusterClick={(ids: any, actions: any) => {
                    props.mapsAssetDrawerClusterOpen(ids, actions.zoomTo,);
                }}
                mapActionsRef={setMapActions}
                mapOptions={{ mapTypeId: props.mapMode || "roadmap" }}
                polyline={false}
                drawOverlays={isShowingGeofences}
                geofences={fenceList}
                geofenceOptions={geofenceOptions}
                toolTipOptions={toolTipOptions}
                serviceTruckOptions={serviceTruckOptions}
                fleetOverviewOptions={fleetOverviewOptions}
                serviceTruckMarkers={serviceTruckMarkers}
            >
                <OverlayViewContainer>
                    <MapToolTip />
                </OverlayViewContainer> 
            </Map>

            
            

            {canGeofencingRead &&
                <>
                    <FenceListDrawer></FenceListDrawer>
                    <GeofenceMaint></GeofenceMaint>
                    <FenceAssets></FenceAssets>


                    <Tooltip title={`${!isShowingGeofences ? 'Show' : 'Hide'} geofences`} placement="topLeft">
                        <Button
                            style={{
                                backgroundColor: isShowingGeofences ? '#00a628' : 'white',
                                color: isShowingGeofences ? 'white' : 'grey',
                            }}
                            className='geofenceButton'
                            size="large"
                            shape="circle"
                            icon={<Icon type="setting" component={geofenceIcon} style={{fontSize: 24}} />}
                            onClick={() => {
                                toggleGeofences();
                            }}
                        />
                    </Tooltip>

                    <Tooltip title="Enable Geofence Rearrangement" placement="bottom">
                        <Button
                            style={{
                                backgroundColor: isGeofencesArrangeEnabled ? '#00a628' : 'white',
                            }}
                            className={`${isShowingGeofences ? 'rearrangeGeofenceEnabled': 'rearrangeGeofenceDisabled'}`}
                            type="default"
                            onClick={() => { toggleGeofencesArrange() }}
                            size="large"
                            shape="circle"
                            icon={<Icon component={isGeofencesArrangeEnabled ? moveGeofenceActive : moveGeofence} style={{fontSize: 24, marginTop: 1 }} />}
                        ></Button>
                    </Tooltip>

                    <Tooltip title="Geofences List" placement="bottom">
                        <Button
                            className={`${isShowingGeofences ? 'geofenceEnabled': 'geofenceDisabled'}`}
                            type="default"
                            onClick={() => { props.openFenceListDrawer() }}
                            size="large"
                            shape="circle"
                            icon={ <UnorderedListOutlined style={{ fontSize: '20px', textAlign: 'center' }} /> }
                        ></Button>
                    </Tooltip>

                    <Tooltip title={`${!isShowingGeofencesMarkers ? 'Show' : 'Hide'} assets`} placement="bottom">
                        <Button
                            style={{
                                backgroundColor: isShowingGeofencesMarkers ? '#00a628' : 'white',
                            }}
                            className={`${isShowingGeofences ? 'showMarkerEnabled': 'showMarkerDisabled'}`}
                            type="default"
                            onClick={() => { toggleGeofencesMarkers() }}
                            size="large"
                            shape="circle"
                            icon={<Icon component={isShowingGeofencesMarkers ? markerActive : markerIcon} style={{fontSize: 24 }} />}
                        ></Button>
                    </Tooltip>


                    <Tooltip title={`Zoom to fit geofences`} placement="bottom">
                        <Button
                            className={`${isShowingGeofences ? 'zoomToFitGeofencesEnabled': 'zoomToFitGeofencesDisabled'}`}
                            type="default"
                            onClick={() => { zoomToFixGeofences() }}
                            size="large"
                            shape="circle"
                            disabled={!fenceList.length}
                            icon={<Icon component={maximizeIcon} style={{fontSize: 22, marginRight: 1, marginTop: 2 }} />}
                        ></Button>
                    </Tooltip>
                </>
            }


            {hasGeoTabAccess &&
                
                <>
                    <ServiceTrucksListDrawer></ServiceTrucksListDrawer>
                    <ServiceTrucksMaint></ServiceTrucksMaint>
                    
                    <Tooltip
                        title={`${!showServiceTrucks ? 'Show' : 'Hide'} Service Trucks`}
                        placement="bottomLeft"
                    >
                    <Button
                            style={{
                                backgroundColor: showServiceTrucks ? '#00a628' : 'white',
                            }}
                            size="large"
                            shape="circle"
                            className="serviceTruckBtn"
                            icon={<Icon component={showServiceTrucks ? serviceTruckActive  : serviceTruck} style={{fontSize: 22 }} />}
                            onClick={() => {
                                toggleserviceTruckMarkers();
                            }}
                        />
                    </Tooltip>
                    
                    <Tooltip title={`Zoom to fit service trucks`} placement="bottom">
                        <Button
                            className={`${showServiceTrucks ? 'zoomToFitServiceTrucksEnabled': 'zoomToFitServiceTrucksDisabled'}`}
                            type="default"
                            onClick={() => { zoomToFixServiceTrucks() }}
                            size="large"
                            shape="circle"
                            disabled={!props.serviceTruckState.serviceTruckList?.assets?.length}
                            icon={<Icon component={maximizeIcon} style={{fontSize: 22, marginRight: 1, marginTop: 2  }} />}
                        ></Button>
                    </Tooltip>
                    
                
                    <Tooltip title="Service trucks list" placement="bottom">
                        <Button
                            className={`${showServiceTrucks ? 'serviceTrucksEnabled': 'serviceTrucksDisabled'}`}
                            type="default"
                            onClick={() => { props.openServiceTruckDrawer() }}
                            size="large"
                            shape="circle"
                            icon={ <UnorderedListOutlined style={{ fontSize: '20px', textAlign: 'center' }} /> }
                        ></Button>
                    </Tooltip>
                
                </>
                
            }
            <Popover
                content={
                    <FilterPanel    
                        filters={props.fleetOverviewState.filters}
                        filterCounts={props.fleetOverviewState.filterCounts}
                        mapActions={mapActions}
                    />
                }
                placement="left"
            >
                <Button
                    data-id="map-filter"
                    size="large"
                    icon={<FilterOutlined />}
                    shape="circle"
                    ghost={ 
                        (props.fleetOverviewState.filters.critical && (props.fleetOverviewState.filterCounts.critical > 0)) || (props.fleetOverviewState.filters.warning && (props.fleetOverviewState.filterCounts.warning > 0)) ||
                        (props.fleetOverviewState.filters.info && (props.fleetOverviewState.filterCounts.info > 0)) || (props.fleetOverviewState.filters.dm1 && (props.fleetOverviewState.filterCounts.dm1 > 0))
                    }                      
                />
            </Popover>
            
            <Tooltip 
                title="Asset List" 
                placement="left"
            >
                <Button
                    data-id="map-asset-search"
                    size="large"
                    shape="circle"
                    icon={
                        (props.context.appContext.orgId == '616c4c61-865d-11eb-871a-13cc80610287') ? (
                            <Icon type="setting" component={props.fleetOverviewState.assetDrawer.assetSearch.show ? KomatsuIconWhite : KomatsuIconBlack} style={{ fontSize: "30px", marginTop: '-3px' }} />
                            ) : (
                            <Icon type="setting" component={props.fleetOverviewState.assetDrawer.assetSearch.show ? TractorIconWhite : TractorIconBlack} />
                        )
                        
                    }
                    onClick={() => {
                        props.mapsAssetDrawerAssetSearchOpen();
                    }}
                    ghost={ 
                        (props.fleetOverviewState.assetSearchValue !== '' || Object.values(props.fleetOverviewState.assetSearchFilters).some(value => value === true))
                    }
                />
            </Tooltip>

            <Tooltip 
                title="Zoom to Fit" 
                placement="left"
            >
                <Button
                    data-id="map-zoom-to-fit"
                    size="large"
                    shape="circle"
                    icon={<Icon component={maximizeIcon} style={{fontSize: 22, marginRight: 1, marginTop: 2 }} />}
                    onClick={() => {
                        props.mapsAssetDrawerClose();
                        if (mapActions) {
                            mapActions.zoomToFit();
                        }
                    }}
                />
            </Tooltip>

            <Button
                data-id="map-tooltip-handler"
                onClick={() => {     
                    tooltipLink();
                    tooltipGenerator();                                                          
                }}
            />
            {
                isread &&
                <AssetDrawer map={props.fleetOverviewState.map} mapActions={mapActions} />
            }            
            
            
        </div>         
    );

}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        fleetOverviewState: state.fleetOverviewReducers.fleetOverviewState,
        serviceTruckState: state.serviceTruckReducer.serviceTruckState,
        uiPreferences: state.contextReducer.context.user.uiPreferences,
        permissions: state.contextReducer.data,
        geofencesList: state.geofencesReducer.geofencesState.geofencesList,
        permissionsset: state.permissionsReducer      
    };
};

export default connect(
    mapStateToProps,
    actions
)(React.memo(FleetOverview));


  