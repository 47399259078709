import { CommonWidgetConfig, CommonWidgetProps } from "../../models";
import { WidgetDetails } from "../models";
import { Options } from './ClusterBarChart';
import Widget from './Widget';
import { EditForm } from "./ClusterBarChartEditForm";

export type ClusterBarOptions = Options;

export type Config = CommonWidgetConfig & { type: "clusterbar"; settings?: Options };

export type Props = CommonWidgetProps & { settings?: Options };

export const details: WidgetDetails = {
  type: "clusterbar",
  name: "Cluster Bar Widget",
};

export { EditForm, Widget}




