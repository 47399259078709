import { produce as immerProduce } from "immer";
import { initialState } from "./userOrgAccessState";

export function userOrgAccessReducer(state = initialState, action:any) {
    switch (action.type) {    
        case "USER_ORG_ACCESS_SUCCESS":
            return immerProduce(state, draft => {                
                let userOrgAccessData:string = JSON.stringify(action.payload.data) || "{}";                               
                if (userOrgAccessData !== "{}") {    
                    let  userOrgAccess = JSON.parse(userOrgAccessData);
                    draft.status = userOrgAccess.status;
                    draft.timestamp = userOrgAccess.timestamp;
                    draft.data.userOrgAccess = userOrgAccess.data.userOrgAccess;
                } 
            });      
        case "USER_ORG_ACCESS_FAILURE":
            return immerProduce(state, draft => {
                draft.data.userOrgAccess = null;    
            });             
        default:
            return state;
    }
}