import React from 'react';
import { Form, Input } from 'antd'

const WidgetTitleFormField: React.FC = (props) => (
  <Form.Item
    name="title"
    label="Widget Title"
    required
    rules={[{ required: true }, { whitespace: true }]}
  >
    <Input />
  </Form.Item>
);

export default WidgetTitleFormField;