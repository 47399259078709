import React, { Component } from "react";
import { Table, Button,Popover, Tag, Popconfirm, Input, Row, Col, Space, Tooltip, message, Badge, Radio, Typography } from "antd";
import { PlusOutlined, EllipsisOutlined, CopyOutlined, EditOutlined, DeleteOutlined, UnorderedListOutlined, GlobalOutlined, BlockOutlined } from "@ant-design/icons";
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';

import { appConfigs } from "../../utils/configurations";
import { stringToUrlString, closePopover, getPermissions, assetTableFilter, setAssetTableFilters } from "../../utils/commonHelpers";
import history from '../../utils/history';

import TemplateMaint from "./TemplateMaint";

import { connect } from "react-redux";
import * as actions from "./actions";

import "./templ.css";
import BackNavigation from "../../utils/Components/BackNavigation";
//import { getPermissions } from "../../actions/permissions";

const { Search } = Input;

interface Prop {
    templatesSetPagination: Function,
    templatesSetSearch: Function,
    getTemplates: Function,
    getTemplate: Function,
    templateMaintOpen: Function,
    getTemplatesForCopyCollection: Function,
    templateDelete: Function,
    templateDuplicate: Function,
    context: any,
    templatesList: any,
    permissions: any,
    templateSetGlobalMode: any
}

class Templates extends Component<Prop, {}> {
    drawerWidth:number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);
   
    componentDidMount () {
        this.props.templatesSetPagination(this.onPaginationChange);
        this.props.getTemplates(this, null, null, 1, this.props.templatesList.mode, this.props.templatesList.searchText);          
    }

    onPaginationChange = (page: number) => {
        this.props.getTemplates(this, null, null, page, this.props.templatesList.mode, this.props.templatesList.searchText);
    };

    onAction = (e:any, record:any, action:string) => {
        e.preventDefault();

        if ( action === "add") {
            this.props.templateMaintOpen(this, "template", "New Template", this.drawerWidth, {});
        }
        else if ( action === "edit") {
            this.props.templateMaintOpen(this, "template", "Edit Template :: " + record.name, this.drawerWidth, record);
        }
        else if ( action === "copy") {
            this.props.templateMaintOpen(this, "copy", "Copy Collection :: " + record.name, this.drawerWidth, record);
        }
        else if ( action === "duplicate") {
            this.props.templateMaintOpen(this, "duplicate", "Duplicate Template :: " + record.name, this.drawerWidth, record);
        }
        else if ( action === "delete") {
            this.props.templateDelete(this, this.templateDeleteSucess, null, record.templateId);            
        }
        else if ( action === "details") {
            this.goToDetails(e, record.name, record.templateId);     
        }
       
        closePopover();       
    }

    templateDeleteSucess = (templateId:string) => {
        message.success("Template has been successfully deleted");
        this.onPaginationChange(this.props.templatesList.tableConfig.pagination.current);
    }

    onSearch = (searchText: string) => {   
        let me = this;

        this.props.templatesSetSearch((searchText || ""));
        setTimeout(function() {
            me.props.getTemplates(me, null, null, 1, me.props.templatesList.mode, searchText);
        }, 100);      
    }

    onSearchChange = (e: any) => {
        let value = e.target.value;
        this.props.templatesSetSearch((value || ""));
    }

    goToDetails = (e: any, templateName: string, templateId: number) => {
        e.preventDefault();
        history.push("/template/details/" + stringToUrlString(templateName) + "/" + templateId);
    };

    onStatusChange = (e: any) => {
        e.preventDefault();
        this.props.templateSetGlobalMode(e.target.value);
        this.props.getTemplates(this, null, null, 1, e.target.value, this.props.templatesList.searchText);  
    };

    goToAssets = (e:any, filter: assetTableFilter, ) => {
        e.preventDefault();
        setAssetTableFilters(filter);
        history.push("/assets")
    }

    confirmDeletion = (record:any) => (
        <Popconfirm
            title={"Are you sure to delete this template?"}
            onCancel={closePopover}
            onConfirm={(e) => {this.onAction(e, record, "delete")}}
            okText="Yes"
            cancelText="No"
        >
            <Tooltip title="Delete Template">            
                <Button data-id="templates-actions-delete-button" type="default" icon={<DeleteOutlined />} className="delete-template" />
            </Tooltip>
        </Popconfirm>
    );

    actions = (record:any, isCreate:any, isRead:any, isUpdate:any, isDelete:any) => (
        <Popover
            content={
                <div className="center-div">
                    <Space size="large">
                        {
                            (record.templateVisibleToSubOrg && (this.props.context.appContext.roleName.toLowerCase() === "admin")) ?
                                (<Tooltip title="Edit Template">
                                    <Button data-id="templates-actions-edit-button" type="default" icon={<EditOutlined />} onClick={(e) => {this.onAction(e, record, "edit")}} />               
                                </Tooltip>) : 
                            (!record.templateVisibleToSubOrg && isUpdate) ?
                                (<Tooltip title="Edit Template">
                                    <Button data-id="templates-actions-edit-button" type="default" icon={<EditOutlined />} onClick={(e) => {this.onAction(e, record, "edit")}} />               
                                 </Tooltip>) : <></>                            
                        }
                        {
                            (record.templateVisibleToSubOrg && (this.props.context.appContext.roleName.toLowerCase() === "admin")) ?
                                this.confirmDeletion(record) :
                            (!record.templateVisibleToSubOrg && isDelete)?
                            this.confirmDeletion(record) : <></>
                        }
                        {
                            (record.templateVisibleToSubOrg && (this.props.context.appContext.roleName.toLowerCase() === "admin" && isCreate && isUpdate)) ?
                                <Tooltip title="Copy Collection">
                                    <Button data-id="templates-actions-copy-button" type="default" icon={<BlockOutlined />} onClick={(e) => {this.onAction(e, record, "copy")}} />               
                                </Tooltip> :
                            (!record.templateVisibleToSubOrg && isCreate && isUpdate) ?
                                <Tooltip title="Copy Collection">
                                    <Button data-id="templates-actions-copy-button" type="default" icon={<BlockOutlined />} onClick={(e) => {this.onAction(e, record, "copy")}} />               
                                </Tooltip> : <></>
                        }
                        {
                            (record.templateVisibleToSubOrg && (this.props.context.appContext.roleName.toLowerCase() === "admin" && isCreate && isUpdate)) ?
                                <Tooltip title="Duplicate Template">
                                    <Button data-id="templates-actions-duplicate-button" type="default" icon={<CopyOutlined />} onClick={(e) => {this.onAction(e, record, "duplicate")}} />               
                                </Tooltip> :
                            (!record.templateVisibleToSubOrg && isCreate && isUpdate) ?
                                <Tooltip title="Duplicate Template">
                                    <Button data-id="templates-actions-duplicate-button" type="default" icon={<CopyOutlined />} onClick={(e) => {this.onAction(e, record, "duplicate")}} />               
                                </Tooltip> : <></>
                        }
                        
                        {
                             (isRead) &&
                            <Tooltip title="List Template Details">
                                <Button data-id="templates-actions-details-button" type="default" icon={<UnorderedListOutlined />} onClick={(e) => {this.onAction(e, record, "details")}} />               
                            </Tooltip>
                        }
                        
                    </Space>
                </div>
            }
            title={
                <div className="center-div">Actions</div>
            }
            trigger="click"
        >
            <Button data-id="templates-actions-button" type="default" icon={<EllipsisOutlined />} />  
        </Popover>
    );
    
    render() {    

        const { user:contextUser } = this.props.context;
        const { tableConfig, tableData } = this.props.templatesList;
        const result = getPermissions(this.props.permissions.permissions.templates);
        if (!this.props.context.user.contextUserIsAdmin && !result[1]){
            history.push("/not-found")    
        }
        let isCreate = result[0];
        let isRead = result[1];
        let isUpdate =  result[2];
        let isDelete = result[3];
        const tableColumns = [
            {
                title: "Name",
                dataIndex: "name",
                key: "name",
                //width: 50,
                render: (text: any, record: any) => (
                    record.templateVisibleToSubOrg ?
                    <Tooltip title="Global Template Details">                        
                        <Badge dot offset={[5, 5]}>
                            <a href="# " onClick={(e) => {this.goToDetails(e, record.name, record.templateId)}}> 
                                {record.name} 
                            </a>
                        </Badge>                        
                    </Tooltip> : <Tooltip title="List Template Details">
                        <a href="# " onClick={(e) => {this.goToDetails(e, record.name, record.templateId)}}> 
                            {record.name} 
                        </a>             
                    </Tooltip>
                )              
            },
            {
                title: "Description",
                dataIndex: "description",
                key: "description",
                responsive: ["md"] as Breakpoint[]
            },
            {
                title: "Org Name",
                dataIndex: "orgName",
                key: "orgName",
                responsive: ["md"] as Breakpoint[]
            },
            {
                title: "Collections",
                dataIndex: "collectionCount",
                key: "collectionCount",
                align: "center" as "center",
                responsive: ["md"] as Breakpoint[],
                render: (collectionCount: number, record: any) => (
                    <div className="notifications-tag">
                        {
                            collectionCount > 0 && 
                            <Tag color="geekblue">{collectionCount}</Tag>
                        }
                    </div>
                )
            },
            {
                title: "Assets",
                dataIndex: "assetCount",
                key: "assetCount",
                align: "center" as "center",
                responsive: ["md"] as Breakpoint[],
                render: (assetCount: number, record:any) => (
                    <div 
                        className="notifications-tag" 
                        onClick={(e) => this.goToAssets(e,
                            {
                                page: 1,
                                status: 'active',
                                searchText: record.name,
                                sortColumn: 'asset',
                                sortDirection: 'ascend',
                                alertInterval: '1 day',
                                listMode: true,
                                filters: { tags: null },
                            }
                        )}
                    >
                        {
                            <Tooltip title = {"View Assigned Assets"}>
                                    <Tag style={{cursor:'pointer'}}
>
                                        <Typography.Text underline style={{color:"#1890FF"}}>
                                            {assetCount}
                                        </Typography.Text>
                                    </Tag>
                            </Tooltip>
                        }
                    </div>
                )
            },
            {
                title: "Actions",
                key: "action",
                align: "center" as "center",
                render: (text: any, record: any) => (   
                    <>  
                        {
                            (isCreate || isRead || isUpdate || isDelete) &&               
                            this.actions(record, isCreate, isRead, isUpdate, isDelete)
                        }
                        {/* {
                            !record.objectAdmin &&
                            <Tooltip title="List Template Details">
                                <Button data-id="templates-actions-details-button" type="default" icon={<UnorderedListOutlined />} onClick={(e) => {this.onAction(e, record, "details")}} />               
                            </Tooltip>
                        } */}
                    </>
                )
            }
        ];

        return (
            <div data-id="templates-container" className="layout-content">
                <Row gutter={8}>
                    <Col sm={24} lg={6}>
                        <div className="content-header page-title">
                            <div style={{ display: "flex" }}>
                                <Tooltip title="Go To Previous Page"> 
                                    <BackNavigation />
                                </Tooltip>
                                Templates
                            </div>
                        </div>                        
                    </Col>
                </Row>
                <Row gutter={8}>                    
                    <Col xs={24} lg={12} className="mt-10">
                        <span className="asset-status-green pr-5" ></span>
                        <Radio.Group value={this.props.templatesList.mode} buttonStyle="solid" onChange={this.onStatusChange}>
                            <Radio.Button value="all">All</Radio.Button>
                            <Radio.Button value="global"><GlobalOutlined className="pr-5"/>Global Template</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col xs={24} lg={12} className="content-header-controls">
                        <Space size="middle">
                            {                                
                                <Search
                                    data-id="templates-search-field"
                                    placeholder="Search by name, description and organization"
                                    enterButton="Search"
                                    size="middle"
                                    allowClear
                                    onSearch={this.onSearch}
                                    onChange={this.onSearchChange}
                                    className="search-field"
                                    value={this.props.templatesList.searchText}
                                /> 
                            }
                            
                            {
                                (isCreate) &&
                                <Tooltip title="Create New Template">            
                                    <Button data-id="templates-new-template-button" type="primary" icon={<PlusOutlined />} onClick={(e) => this.onAction(e, {}, "add")}>
                                        <span>Template</span>
                                    </Button>
                                </Tooltip>    
                            }
                            </Space>
                    </Col>
                </Row>
                <div className="mt-15">
                    <TemplateMaint /> 
                    {                        
                        <Table  
                            data-id="templates-data" 
                            {...tableConfig}
                            rowKey={(record) => record.templateId}
                            columns={tableColumns}
                            dataSource={tableData}
                        />
                    }   
                    
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        permissions: state.contextReducer.data,
        templatesList: state.templatesReducers.templatesState.templatesList
    };
};

export default connect(
    mapStateToProps,
    actions
)(Templates);
  