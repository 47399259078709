import {
  XYChart,
  ColumnSeries,
  ValueAxis,
  CategoryAxis,
  XYCursor,
  Legend
} from "@amcharts/amcharts4/charts";
import * as amCore from "@amcharts/amcharts4/core";

import { AxisRange } from "../models";
import {
  DATE_FORMAT,
  TOOLTIP_FORMAT,
  NUMBER_FORMAT,
  findTimezone,
} from "../common";
import { appConfigs } from "../../../../utils/configurations";

export interface Signal {
  name: string
  timestamp: Date
  value: number
  color: string;
}

export interface Options {
  axisRange?: AxisRange;
}

export default class BarChartClass {
  private _chart: XYChart;

  constructor(ref: HTMLDivElement, signals: Signal[], opts?: Options, timezone?:any) {
    const chart = (this._chart = amCore.create(ref, XYChart));
    const cursor = (this._chart.cursor = new XYCursor());
    cursor.lineX.disabled = true;
    cursor.lineY.disabled = true;

    chart.paddingLeft = 0;
    chart.marginLeft = 0;
    chart.marginTop = 12;

    const xAxis = new CategoryAxis();
    xAxis.dataFields.category = "name";
    xAxis.cursorTooltipEnabled = false;
    chart.xAxes.push(xAxis);

    const yAxis = new ValueAxis();
    yAxis.cursorTooltipEnabled = false;
    yAxis.min = opts?.axisRange?.min;
    yAxis.max = opts?.axisRange?.max;
    chart.yAxes.push(yAxis);

    let legend = new Legend();
    legend.parent = chart.chartContainer;
    legend.itemContainers.template.togglable = false;
    legend.marginTop = 20;
    const series = new ColumnSeries();
    series.dataFields = {
      categoryX: "name",
      valueY: "value",
      dateX: "timestamp",
    };
    var tooltipDetails:any;
    if (signals && signals.length > 0) {
      tooltipDetails= findTimezone(timezone);
    }
    
    if(tooltipDetails){
      series.tooltipText =  "{dateX}"+" "+tooltipDetails?.timezone+"\n{name}: {valueY}";
    }
    if(series.tooltip) {
      series.tooltip.pointerOrientation = "vertical";
    } 
    if (signals && signals.length > 0) {
      series.columns.template.adapter.add("fill", function (fill, target) {
        let index = target.dataItem!.index ? target.dataItem!.index : 0;
        return signals[index].color ? amCore.color(signals[index].color) : amCore.color(appConfigs.colorCodes.colorCodesLineChart[index]);
       });
    }
    //Below line to remove labels on xAxis
    xAxis.renderer.labels.template.disabled = true;
    chart.series.push(series);    
    chart.dateFormatter.dateFormat = DATE_FORMAT;
    chart.numberFormatter.numberFormat = NUMBER_FORMAT;
    chart.data = signals;
    series.events.on("ready", function(ev) {
      var legenddata: any = [];
      series.columns.each(function(column) {
        legenddata.push({
          name:  column.dataItem?.categories.categoryX,
          fill: column.fill
        });
      });
      legend.data = legenddata;
    });    
  }

  public destroy() {
    this?._chart.dispose();
  }
}
