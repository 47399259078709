import { appConfigs } from '../../utils/configurations';

export default {
    oidc: {
        issuer: appConfigs.okta.oidc.issuer,
        redirectUri: `${appConfigs.server.deploymentServerURL}`,
        clientId: appConfigs.okta.oidc.clientId,
        scopes: appConfigs.okta.oidc.scopes,
        pkce: appConfigs.okta.oidc.pkce,
        disableHttpsCheck: appConfigs.okta.oidc.disableHttpsCheck
    },
    resourceServer: {
        messagesUrl: `${appConfigs.server.deploymentServerURL}/api/messages`
    }
};