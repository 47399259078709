import React from "react";
import { Form, Row, Col, Input,Select, InputNumber, Switch,AutoComplete, Collapse, Button, message} from 'antd';
import { FormInstance } from 'antd/lib/form';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import { appConfigs } from "../../../utils/configurations";
import { scrollToElement, numberZeroPositive } from "../../../utils/commonHelpers";
import { successResponse, errorResponse, resetFormErrors } from "../../../utils/apiHelpers/apiHelpers";
import { FormState, formInit } from "../../../utils/apiHelpers/formHelpers";
import { SuccessAlert, ErrorAlert } from "../../../utils/Components/formAlerts";

import { connect } from "react-redux";
import * as actions from "../actions";
import history from '../../../utils/history';

import SignalRule from "./SignalRule";

import "./signalMaint.css";
import { truncateWithEllipsis, width } from "@amcharts/amcharts4/.internal/core/utils/Utils";

import {Button as AntdButton} from "antd";
import {PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {useState} from "react";
import { array, object, options } from "@amcharts/amcharts4/core";
import { stat } from "fs";


const { TextArea } = Input;

const { Option } = Select;
const { Panel } = Collapse;
interface commnadSignalType{

    signalId: string, 
    name: string
}

interface Prop {
    getJ1939s: Function,
    getCommandPgns: Function,
    getCommandControllers: Function,
    signalMaintSubmit: Function,
    getSignals: Function,
    signals: any,
    signalMaint: any,
    signalList: any,
    iscreateOrUpdateTemplate: boolean,
    iscreate: boolean,
    isread: boolean,
    iscreateRules: boolean,
    isupdateRules: boolean,
    isreadRules: boolean,
    isdeleteRules: boolean,
    isGlobalTemplate: boolean, 
    isadmin: boolean,
    templateDetails: any,
    getTemplate: Function,
    getUnits: Function,
    accept: Function,
    cancel: Function,
    context: any
}

interface State {
    signalId: string,
    name: string,
    mode: string,
    pgn: number,
    spn: number,
    sourceName: string,
    matchSourceAddress: boolean,
    sourceAddress: number,
    //signalCollectionType: string,
    ruleType: string

    formError?: boolean;
    formErrorMessage?: string[];
    formSuccess?: boolean;
    formSuccessMessage?: string;
    formCommit?: boolean;

    format?: string;
    firstBit?: number;
    bitLength?: number;
    offset?: number,
    scaling?: number,
    min?: number;
    max?: number;
    multiPackage: string;
    commandSignals : Array<commnadSignalType>;
    commandId?: string;
    canAddress?: number;
    uom:string;
    unit:string;
    unitSymbols:Array<String>;
    viewRequestPGN: boolean;
    activeKey: string;
    rules:any;
    leftMergeRules:any;
    rightMergeRules:any,
    messageArray:object[],
    inputValue:string,
    c2dWhitelist: any,
    newMessageText: any,
    multiPackageError: Boolean
}





const returnParams: string = "{}"
const ParamsObjectarray = JSON.parse(returnParams);


class SignalMaintInfo extends React.Component<Prop, State> {  

    state: State = {
        signalId: "",
        name: "",
        mode: "",
        pgn: 0,
        spn: 1,
        sourceName: "",
        matchSourceAddress: false,
        sourceAddress: 0,
        //signalCollectionType: "none",
        ruleType: "",
        commandSignals: [],
        commandId: "",
        canAddress: 39,
        uom:"",
        unit:"",
        multiPackage: "Yes",
        unitSymbols:[],
        viewRequestPGN: false,
        activeKey: "0",
        rules:[],
        leftMergeRules:[],
        rightMergeRules:[],
        messageArray:ParamsObjectarray,
        inputValue:"",
        c2dWhitelist: [], // assigning default messages
        newMessageText: "",
        multiPackageError: false
    };

    formRef = React.createRef<FormInstance>();




      handleAddMessage = () => {
        const newMessage = this.state.newMessageText;
        this.setState(c2dWhitelist => ({
        c2dWhitelist: [...c2dWhitelist.c2dWhitelist, newMessage],
          newMessageText: ""
        }), () => {});
      }



      handleRemoveMessage = (index:any) => {
        this.setState(c2dWhitelist => ({
            c2dWhitelist: c2dWhitelist.c2dWhitelist.filter((message:any, i:any) => i !== index)
        }), 
        () =>{});
      }

      handleNewMessageTextChange = (event:any) => {
        const newMessageText = event.target.value;
        this.setState({ newMessageText });
      }


    handleMessageTextChange = (event:any, index:any) => {
        const updatedMessage = event.target.value;
        let c2dWlist = [...this.state.c2dWhitelist];
      
        c2dWlist[index] = updatedMessage;
        this.setState({
          c2dWhitelist: c2dWlist
        });
      }


    setMulti = (value:any) =>{
        const stringValue = String(value);
        this.setState({multiPackage: stringValue});
        if(value==="Yes"){
            this.setState({
                bitLength:0, 
                max: 140});
            this.formRef.current!.setFieldsValue({
                bitLength:0,
                max: 140
            });
        }
        else{
            this.setState({
                bitLength:64,
                max: 8});
            this.formRef.current!.setFieldsValue({
                bitLength:64,
                max: 8
            });
        }
    }
    
    getTemplateId() {
        const pathnameArr = history.location.pathname.split("/");
        return pathnameArr[pathnameArr.length - 1];
    }
    
    componentDidMount() {
        this.componentUpdate(this.props.signalMaint.record);       
        
    }
    
    componentDidUpdate(prevProps: Prop, prevState: FormState) {
        if (this.props.signalMaint.instance !== prevProps.signalMaint.instance) {
            this.componentUpdate(this.props.signalMaint.record);
        }
    } 

    componentUpdate(record:any) {
        if ((record.mode || "") === "J1939 Database") {
            this.props.getJ1939s(this, null, null, null, record.modeId);
        }
        if (this.props.signalMaint.collectionType === "Command") {
            this.props.getCommandPgns(this, null, null, null, record.pgn);
            this.props.getCommandControllers(this, null, null, null, record.canAddress, record.pgn);
        }
        this.props.getUnits(this, this.getUnitsSuccess, null);
        this.props.getTemplate(this, this.getTemplateSuccess, null, this.getTemplateId());
        formInit(this);     
        if (this.formRef.current != null) {
            this.formRef.current!.resetFields();    
            this.setSignalRecord(record);
            this.setRuleRecord(record);
           
            this.setIncrementalRuleRecord(record);
            this.setOfflineRuleRecord(record);
            this.setMergeRuleRecord(record);
            this.setMergeRules(record);
            this.setRequestPGNRecord(record);  
            this.setState({ viewRequestPGN: record.requestPgn,
                activeKey: "0" });     
            if(record.requestPgn) {
                this.setState({ viewRequestPGN: record.requestPgn,
                activeKey: "1" }); }
            else
                { this.setState({ viewRequestPGN: record.requestPgn,
                activeKey: "0" }); } 
    
            this.setState({
                signalId: this.formRef.current!.getFieldValue("signalId"),
                name: this.formRef.current!.getFieldValue("name"),
                mode: this.formRef.current!.getFieldValue("mode"),
                sourceName: this.formRef.current!.getFieldValue("sourceName"),
                matchSourceAddress: this.formRef.current!.getFieldValue("matchSourceAddress"),
                sourceAddress: this.formRef.current!.getFieldValue("sourceAddress"),
                //signalCollectionType: record.signalCollectionType,
                ruleType: this.formRef.current!.getFieldValue("ruleType"),
                format: this.formRef.current!.getFieldValue("format"),
                firstBit: this.formRef.current!.getFieldValue("firstBit"),
                bitLength: this.formRef.current!.getFieldValue("bitLength"),
                offset: this.formRef.current!.getFieldValue("offset"), 
                scaling: this.formRef.current!.getFieldValue("scaling"), 
                min: this.formRef.current!.getFieldValue("min"),
                max: this.formRef.current!.getFieldValue("max"),
                multiPackage:this.formRef.current!.getFieldValue("multiPackage"),
                canAddress: this.formRef.current!.getFieldValue("canAddress"), 
                commandId: this.formRef.current!.getFieldValue("commandId"),
                commandSignals: this.getCommandSignals(),
                pgn: this.formRef.current!.getFieldValue("pgn")
            });          
        }
    };

    setRequestPGNRecord = (record: any) => {
        if (record.requestPgn != null && record.requestPgn) {
            this.formRef.current!.setFieldsValue({
                requestIntervalValue: record.requestIntervalValue,
                requestIntervalUom: record.requestIntervalUom,
                pgnDestinationAddress: record.pgnSourceAddress,
                pgnSourceAddress: record.pgnDestinationAddress,
            });
        }
    }

    setSignalRecord = (record: any) => {
        this.formRef.current!.setFieldsValue({
            templateId: this.getTemplateId(),
            signalCollectionId: (Object.keys(record).length === 0 ? this.props.signals.signalCollectionId : record.signalCollectionId),
            signalId: record.signalId,
            name: record.name,
            mode: record.mode,
            modeId: record.modeId,
            label: record.label,
            canHeader: record.canHeader,
            pgn: record.pgn,
            pgnName: record.pgnName,
            spn: record.spn,
            spnName: record.spnName,
            bitLength: record.bitLength,
            canAddress: record.canAddress,
            firstBit: record.firstBit,
            offset: record.offset,
            scaling: record.scaling,
            //unit: record.unit,
            sourceName: record.sourceName,
            source: record.source,
            matchSourceAddress: record.matchSourceAddress,
            sourceAddress: record.sourceAddress,
            j1939Error: record.j1939Error,
            format: record.format,
            min: record.min,
            max: record.max,
            multiPackage: (record.format || "float") === "text" && (record.max || 8) > 8 ? "Yes": "No",
            c2dWhitelist: JSON.parse(record.c2dWhitelist || "[]"),
            commandId: record.commandId || ""
        });
        this.setState({
            format: record.format,
            pgn: record.pgn, 
            c2dWhitelist: JSON.parse(record.c2dWhitelist || "[]"),
        });
    }

    setRuleRecord = (record: any) => {
        if (record.signalRule != null) {
            this.formRef.current!.setFieldsValue({
                ruleId: record.signalRule.ruleId,
                ruleName: record.signalRule.ruleName,
                ruleType: (numberZeroPositive(record.signalRule.infoLowValue) || numberZeroPositive(record.signalRule.infoHighValue) ? "info" : "alarm"),
                criticalLowValue: record.signalRule.criticalLowValue,
                warningLowValue: record.signalRule.warningLowValue,
                warningHighValue: record.signalRule.warningHighValue,
                criticalHighValue: record.signalRule.criticalHighValue,
                infoLowValue: record.signalRule.infoLowValue,
                infoHighValue: record.signalRule.infoHighValue
            });          
        }
        else {
            this.formRef.current!.setFieldsValue({
                ruleType: "alarm",
                ruleId: "",
            });            
        }
    }

    setIncrementalRuleRecord=(record:any)=>{
        if(record.signalIncrementalRule!=null)
        {
            this.formRef.current!.setFieldsValue({
            incrementalRuleId:record.signalIncrementalRule.ruleId,
            incrementalRuleName:record.signalIncrementalRule.ruleName,
            incrementalRuleType:record.signalIncrementalRule.ruleType,
            incrementalRuleValue:record.signalIncrementalRule.ruleValue
            });
        }
        else
        {
            this.formRef.current!.setFieldsValue({
                incrementalRuleId:"",
                incrementalRuleType:"incremental"
            });
        }
    }

    setOfflineRuleRecord=(record:any)=>{
        if(record.offlineRule!=null)
        {
            this.formRef.current!.setFieldsValue({
            offlineRuleId:record.offlineRule.ruleId,
            offlineRuleName:record.offlineRule.ruleName,
            offlineSince:record.offlineRule.offlineSince,
            offlineDuration:record.offlineRule.offlineDuration
            });           
        }
        else
        {
            this.formRef.current!.setFieldsValue({
                offlineRuleId:""
            });
        }
    }

    setMergeRules=(record: any)=>{
       let rules=[];
       if(record.signalRule!=null){ rules.push({ruleId:record.signalRule.ruleId,ruleName:record.signalRule.ruleName});}
    //    if(record.signalIncrementalRule!=null){rules.push({ruleId:record.signalIncrementalRule.ruleId,ruleName:record.signalIncrementalRule.ruleName});}
       if(record.offlineRule!=null){rules.push({ruleId:record.offlineRule.ruleId,ruleName:record.offlineRule.ruleName});}
       this.formRef.current!.setFieldsValue({
                mergeRules:rules
        });

       this.setState({leftMergeRules:rules,rightMergeRules:rules});
        
    }

    setMergeRuleRecord=(record:any)=>{
        if(record.mergeRule!=null)
        {
            this.formRef.current!.setFieldsValue({
            mergeRuleId:record.mergeRule.mergeRuleId,
            mergeRuleName: record.mergeRule.mergeRuleName,
            leftMergeRule:record.mergeRule.leftMergeRule,
            rightMergeRule:record.mergeRule.rightMergeRule,
            mergeRuleOperator:record.mergeRule.mergeRuleOperator
            });           
        }
        else
        {
            this.formRef.current!.setFieldsValue({
                mergeRuleId:""
            });
        }
    }

    setRecordNull = (record: any) => { 
        Object.keys(record).forEach(function(index) {
            record[index] = null;
        });
    }
    
    onFinish = (values: any) => {
        const maxLength = this.state.multiPackage === 'Yes' ? 144 : 8;
        const invalidMessages = this.state.c2dWhitelist.filter((message:any) => message.length > maxLength);
        if (invalidMessages.length > 0) {
        message.error(`Error: Message length cannot exceed ${maxLength} characters`);
        this.setState({ multiPackageError: true }); // Set the error state to true
        } 
        else {
            this.setState({ multiPackageError: false }); // Set the error state to false                                       
        }
        resetFormErrors(this, this.formRef.current); 
        if (!this.state.multiPackageError) { // Check the value of the multiPackageError state variable
          this.props.signalMaintSubmit(this, this.onFinishSuccess, this.onFinishFailure, this.getTemplateId(), 
          { ...values, requestPgn:this.state.viewRequestPGN,c2dWhitelist:JSON.stringify(this.state.c2dWhitelist)});
          this.props.accept();
        } else {
          message.error('Error: Please fix the message length error before submitting'); // Display an error message
        }
      };

    onFinishSuccess = (editMode: string, record: any) => {
        this.componentUpdate(record);
        successResponse(this, "Signal has been successfully " + (editMode === "edit" ? "updated" : " created and added to the collection"));        
        scrollToElement("[data-id=signals-form-messages]");
        this.props.getSignals(this, null, null, this.getTemplateId(), this.props.signals.signalCollectionId, this.props.signals.signalCollectionName, this.props.signals.signalCollectionType, this.props.signalList.tableConfig.pagination.current, this.props.signalList.searchText);
    }

    onFinishFailure = (error: any) => { 
        scrollToElement("[data-id=signals-form-messages]");
        errorResponse(this, error);
    }
    getTemplateSuccess = (record:any) => {
        this.setState({
        commandSignals: this.getCommandSignals()
        });
    }

    onModeChange = (mode: string) => { 
        this.setState({ mode: mode });

        let record = {...this.props.signalMaint.record};
        
        this.setRecordNull(record);

        record.templateId = this.props.signalMaint.record.templateId || this.getTemplateId();     
        record.signalCollectionId = this.props.signalMaint.record.signalCollectionId || this.props.signals.signalCollectionId;     
        record.signalId = this.props.signalMaint.record.signalId;     
        record.mode = mode;     

        if ((this.props.signalMaint.record.signalId || "") === "" && mode === "J1939 Database") {
            record.sourceName = "can0"; 
        }

        this.setSignalRecord(record);
        if(this.props.signalMaint.collectionType === "Command" &&
            this.state.format === "text"){
            record.sourceName = "both";
            record.max = 140;
            record.canAddress = 37; 
            this.formRef.current!.setFieldsValue({bitLength: 0,
            firstBit: 0, multiPackage: "Yes", sourceName: "both", max: 140});
        }
        if(this.props.signalMaint.collectionType === "Command" &&
            this.state.format === "hex"){
            record.sourceName = "both";
              this.formRef.current!.setFieldsValue({bitLength:64,
              firstBit: 0, multiPackage: "No"});
        }
        if(this.props.signalMaint.collectionType === "Command" &&
            this.state.format === "boolean"){
            record.sourceName = "both";
            this.formRef.current!.setFieldsValue({bitLength: 2, multiPackage: "No"});
        }
        if(this.props.signalMaint.collectionType === "Command" &&
        this.state.format === "integer" || this.state.format === "float"){
            record.sourceName = "both";
        this.formRef.current!.setFieldsValue({bitLength: 32, multiPackage: "No"});
    }
    }

    
    onCanAddressChange = (address:any) => {
        this.setState({ canAddress: address });  
              
        this.formRef.current!.setFieldsValue({
            canAddress: address
        });
        
    };     

    onRuleTypeChange = (value: string) => { 
        this.setState({ ruleType: value });
    }

    onJ1939TemplateSearch = (value:string) => {
        this.props.getJ1939s(this, null, null, value);
    };

    onJ1939TemplateChange = (value:string) => {
        this.props.signalMaint.j1939s.data.forEach((record: any) => {
            if (record.j1939Id === value) {
                this.setSignalRecord(
                    {
                        templateId: this.getTemplateId(),
                        signalCollectionId: this.props.signals.signalCollectionId,
                        signalId: this.props.signalMaint.record.signalId,
                        mode: "J1939 Database",
                        modeId: record.j1939Id,
                        pgn: record.pgn,
                        pgnName: record.pgnName,
                        spn: record.spn,
                        spnName: record.spnName,
                        bitLength: record.bitLength,
                        firstBit: record.firstBit,
                        offset: record.offset,
                        scaling: record.scaling,
                        unit: record.unit,
                        source: record.source,
                        sourceName: "can0" 
                    }
                );
            }
        });       
    };

    onCommandPgnSearch = (value:string) => {
        this.props.getCommandPgns(this, null, null, value);
    };

    onCommandPgnChange = (value:string) => {
        this.props.signalMaint.commandPgns.data.forEach((record: any) => {
          
            if (record.id === value) { // commandPgnUUID

                if (record.pgn === 65305) {
                    this.setSignalRecord(
                        {
                            templateId: this.getTemplateId(),
                            signalCollectionId: this.props.signals.signalCollectionId,
                            signalId: this.props.signalMaint.record.signalId,
                            mode: "Add New Signal",
                            modeId: record.id, 
                            pgn: 65305,
                            pgnName: record.pgnName,
                            spn: record.spn,
                            spnName: record.spnName,
                            bitLength: 0,
                            firstBit: 0,
                            format: "text",
                            canAddress: 37,
                            max: 140, 
                            sourceName: "both",
                            multiPackage: "Yes"
                        }
                    );
                    this.formRef.current!.setFieldsValue({
                        format: "text",
                        max: 140,
                        pgn: 65305,
                        spn: record.spn,
                        bitLength: 0,
                        firstBit: 0, 
                        canAddress: 37, 
                        sourceName: "both",
                        unitName: "", 
                        unit: "",
                        multiPackage: "Yes"
                    });
                    let unitSymbols=this.props.signalMaint.units!.data.filter((f:any)=>f.name=='Other');
                    this.setState({
                        format: "text", 
                        max: 140, 
                        pgn: 65305,
                        spn: record.spn, 
                        bitLength: 0, 
                        firstBit: 0,
                        canAddress: 37, 
                        sourceName: "both",
                        unitSymbols: unitSymbols,
                        unit: "",
                        multiPackage: "Yes"
                    });
                    this.props.getCommandControllers(this, null, null, "", null, 65305);

                } else if (record.pgn === 65310) {
                    this.setSignalRecord(
                        {
                            templateId: this.getTemplateId(),
                            signalCollectionId: this.props.signals.signalCollectionId,
                            signalId: this.props.signalMaint.record.signalId,
                            mode: "Add New Signal",
                            modeId: record.id, 
                            pgn: record.pgn,
                            pgnName: record.pgnName, 
                            spn: record.spn,
                            spnName: record.spnName,
                            sourceName: "both",
                            bitLength: 2,
                            format: "boolean", 
                            unitName: "", 
                            unit: "",
                        }
                    );
                    this.formRef.current!.setFieldsValue({
                        format: "boolean",
                        pgn: record.pgn,
                        spn: record.spn,
                        sourceName: "both",
                        bitLength: 2, 
                        min: null, 
                        max: null, 
                        multiPackage: "No"
                    });
                    this.setState({format: "boolean",
                                    pgn: record.pgn,
                                    spn: record.spn,
                                    sourceName: "both",
                                    bitLength: 2,
                                    multiPackage: "No"
                                });
               /* } else if (record.pgn >= 65351 && record.pgn <=65370) {
                    // reserve about 20, even though initially allocated to customer might be subset of 
                    this.setSignalRecord(
                        {
                            templateId: this.getTemplateId(),
                            signalCollectionId: this.props.signals.signalCollectionId,
                            signalId: this.props.signalMaint.record.signalId,
                            mode: "Add New Signal",
                            modeId: record.id, 
                            pgn: record.pgn,
                            pgnName: record.pgnName, 
                            spn: record.spn,
                            spnName: record.spnName,
                            sourceName: "both",
                            bitLength: 64,
                            firstBit: 0,
                            format: "hex",
                            unitName:"", 
                            unit: ""
                        }
                    );
                    this.formRef.current!.setFieldsValue({
                        format: "hex",
                        pgn: record.pgn,
                        spn: record.spn,
                        sourceName: "both",
                        bitLength: 64, 
                        min: null, 
                        max: null,
                        unitName: "",
                        unit: "",
                    });
                    let unitSymbols=this.props.signalMaint.units!.data.filter((f:any)=>f.name=='Other');
                    this.setState({format: "hex",
                                    pgn: record.pgn,
                                    spn: record.spn,
                                    southName: "both",
                                    bitLength:64,
                                    firstBit: 0, 
                                    unitSymbols: unitSymbols, 
                                    unit: "",
                                }); */
                }else {
                    this.setSignalRecord(
                        {
                            templateId: this.getTemplateId(),
                            signalCollectionId: this.props.signals.signalCollectionId,
                            signalId: this.props.signalMaint.record.signalId,
                            mode: "Add New Signal",
                            modeId: record.id, 
                            pgn: record.pgn,
                            pgnName: record.pgnName,
                            spn: record.spn,
                            spnName: record.spnName,
                            format: record.format, // support text telematic signal
                            multiPackage: "No"
                        }
                    );
                }

                this.setState({pgn: record.pgn, 
                               spn: record.spn,
                              format: record.format,
                              multiPackage: "No"
                        }
                    );
            }
        });       
    };

    onCommandControllersSearch = (value:string) => {
        this.props.getCommandControllers(this, null, null, value, null, this.state.pgn);
    };

    onCommandControllersChange = (value:string) => {
        this.props.signalMaint.commandControllers.data.forEach((record: any) => {
            if (record.cmdControllerSaId === value) { // commandController's saId
                        this.formRef.current!.setFieldsValue({
                            canAddress:  record.cmdControllerSaId
                        });
                   this.setState({canAddress: record.cmdControllerSaId});
                }
            });       
    };

    onSourceAddressChange = (value:any) => {
        this.setState({ sourceAddress: value });
    };                                                                

    onMatchSourceAddressChange = (checked:any) => {
        this.setState({ matchSourceAddress: checked });  
        /*      
            this.formRef.current!.setFieldsValue({
                sourceAddress: null
            });
        */
    };                                                                

    onSourceNameChange = (sourceName:any) => {
        this.setState({ sourceName: sourceName });
    };    
    onFormatChange = (format:any) => {
        this.setState({ format: format });
        if(format === 'text'){
            if (this.state.pgn === 65305) { /* C2D */
                this.formRef.current!.setFieldsValue({
                    format: "text",  
                    max: 140,
                    firstBit: 0,
                    BitLength: 0,
                    canAddress:37,
                    multiPackage: "Yes"}
                );
                this.setState({
                    format: "text",
                    max: 140, 
                    firstBit: 0,
                    bitLength: 0,
                    canAddress: 37,
                    multiPackage: "Yes"
                });
                this.props.getCommandControllers(this, null, null, "", null, 65305);
            }  else { /* D2C */
                this.formRef.current!.setFieldsValue({ 
                    format: "text",
                    firstBit: 0,
                    BitLength: 64
                });
                this.setState({
                    format: "text",
                    firstBit: 0,
                    bitLength: 64
                });
            }
        
        }
        else if(format === 'boolean'){
            this.formRef.current!.setFieldsValue({ max:"" });
            this.formRef.current!.setFieldsValue({ bitLength:2 });
        } else if(format === 'hex'){
            this.formRef.current!.setFieldsValue({ min:"" });
            this.formRef.current!.setFieldsValue({ max:"" });
            this.formRef.current!.setFieldsValue({ firstBit:0 });
            this.formRef.current!.setFieldsValue({ bitLength:64 });
        }  if(format === 'integer' || format === 'float'){
            this.formRef.current!.setFieldsValue({ min:"" });
            this.formRef.current!.setFieldsValue({ max:"" });
            this.formRef.current!.setFieldsValue({ firstBit:0 });
            this.formRef.current!.setFieldsValue({ bitLength:32 });
            this.formRef.current!.setFieldsValue({ offset:0 });
            this.formRef.current!.setFieldsValue({ scaling:1 });
        } else
        {
            this.formRef.current!.setFieldsValue({ min:"" });
            this.formRef.current!.setFieldsValue({ max:"" });
            this.formRef.current!.setFieldsValue({ firstBit:"" });
            this.formRef.current!.setFieldsValue({ bitLength:"" });
            this.formRef.current!.setFieldsValue({ offset:"" });
            this.formRef.current!.setFieldsValue({ scaling:"" });
        }
    };

    onCommandNameChange = (commandId:any) => {
        this.setState({ commandId: commandId });
    };
   
    getCommandSignals = () => {
        let templateCommandSignals : Array<commnadSignalType>=[];
        let defaultSignal: commnadSignalType = {signalId: "", name: "none"};
        let orgCmdPgnSpns = this.props.templateDetails.templateRecord.orgCmdPgnSpns;
        if((this.props.signalMaint.collectionType || "none") === "none"){
            templateCommandSignals.push(defaultSignal);
            if(this.props.templateDetails.templateRecord.datapoints.length > 0){      
                let commands = JSON.parse(this.props.templateDetails.templateRecord.datapoints)
                                    .filter((t:any) => t.type && t.type === "Command");
            let commandSignal: commnadSignalType = {signalId: "", name: ""};
            commands.map((collection:any) => {            
                let signals=JSON.stringify(collection.values);
                JSON.parse(signals).map((signal:any) => {            
                commandSignal.signalId = signal._id;
                if (orgCmdPgnSpns.includes(signal.pgn + "-" + signal.spn)) {      
                    commandSignal.name = signal.label;
                } else {
                    commandSignal.name = signal.label + " (NA to org)";
                }
                templateCommandSignals.push(commandSignal);
                commandSignal = {signalId: "", name: ""};
                });
            });
            }
        }
        return templateCommandSignals;
    }

    onUnitNameChange=(value:any)=>{ 
        let units:any=[];
        units=this.props.signalMaint.units!.data.filter((f:any)=>{
            return f.name.toLowerCase()==value.toLowerCase();
        })
        if(units.length)
        {
            this.formRef.current!.setFieldsValue({ unit:units[0].symbol});
            this.setState({unitSymbols:units});
        }
    }

    onUnitSearch=(value:string)=>{
        if((value||"")!=="")
        {
            //pulling unit symbols and names if mapping finds.
            let units=this.props.signalMaint.units!.data.filter((f:any)=>{
                return f.symbol.toLowerCase()==value.toLowerCase();
            })
            if(units.length)
            {
                let unitSymbols=this.props.signalMaint.units!.data.filter((f:any)=>{
                    return f.name.toLowerCase()==units[0].name.toLowerCase();
                })
                this.setState({unitSymbols:unitSymbols})
                this.formRef.current!.setFieldsValue({ unitName:unitSymbols.length?units[0].name:''});
            }
            else
            {
                //if there are no mappings present then populating with default other.
                let unitSymbols=this.props.signalMaint.units!.data.filter((f:any)=>f.name=='Other');
                this.setState({unitSymbols:unitSymbols})
                this.formRef.current!.setFieldsValue({ unitName:'Other'});
            }
        }
    }
  
   getUniqueUnits=()=>{
      //pulling distinct unit names
      const units=Array.from(new Map(this.props.signalMaint.units!.data.map((item: any) => [item["name"], item])).values())
      return units;
    }

    getUnitsSuccess=(record:any)=>{
      let value=this.props.signalMaint.record.unit;
      if((value||"")=="")
      {
          //showing default units when new signal creates.
          if(!this.state.signalId)
          {
            let unitSymbols=record.filter((f:any)=>f.name==record[0].name);
            this.setState({unitSymbols:unitSymbols})
            this.formRef.current!.setFieldsValue({ unit:record[0].symbol});
            this.formRef.current!.setFieldsValue({ unitName:record[0].name});
          }
      }
      else
      {
          //if unit presents repopulating respective name along with unit value.
          let units=record.filter((f:any)=> f.symbol==value);
          if(units.length)
          {
            this.formRef.current!.setFieldsValue({ unit:value});
            let unitSymbols=record.filter((f:any)=>f.name==units[0].name);
            this.formRef.current!.setFieldsValue({ unitName:units[0].name});
            this.setState({unitSymbols:unitSymbols})
          }
          else
          {
            this.formRef.current!.setFieldsValue({ unit:value});
            this.formRef.current!.setFieldsValue({ unitName:'Other'});
            let unitSymbols=record.filter((f:any)=>f.name=='Other');
            this.setState({unitSymbols:unitSymbols})
          }
      }
    }

    showRequestPGNPanel= (enabled: any) =>
    {
        if (enabled) {
            this.setState({
                viewRequestPGN: true,
                activeKey: "1",
            });
            this.formRef.current!.setFieldsValue({
                requestIntervalValue:"1",
                requestIntervalUom: "m",
                pgnDestinationAddress: "0",
                pgnSourceAddress: "37",
                sourceName: "both"                
            });            
          } 
          else {
            this.setState({
                viewRequestPGN: false,
                activeKey: "0",
            });
            this.formRef.current!.setFieldsValue({
                requestIntervalValue:"",
                requestIntervalUom: "",
                pgnDestinationAddress: "",
                pgnSourceAddress: ""
            });
            if(this.state.mode === "Add New Signal"){
                this.formRef.current!.setFieldsValue({
                    sourceName: ""
                });
            }
            else{
                this.formRef.current!.setFieldsValue({
                    sourceName: "can0"
                });
            }

          }
    }

    validateTimeUnit= (rule: any, value: any, callback: any) => {
        if(value){
          const interval = this.formRef.current!.getFieldValue('requestIntervalValue');
          if ((value === 'h'&& interval > 24) ||
          (value === 'm'&& interval > 24*60) ||
          (value === 's'&& interval > 24*60*60)) {
           callback("Please enter a valid time interval");
          } 
       else {
            callback();
           } 
        }
        else {
          callback();
        }
      };

      validateTimeValue= (rule: any, value: any, callback: any) => {
        if(value){
          const unit = this.formRef.current!.getFieldValue('requestIntervalUom');
          if ((unit === 'h'&& value > 24) ||
          (unit === 'm'&& value > 24*60) ||
          (unit === 's'&& value > 24*60*60)) {
           callback("Please enter a valid time interval");
          } 
       else {
            callback();
           } 
        }
        else {
          callback();          
        }
      };

      validateTime = () => {          
        this.formRef.current!.validateFields(['requestIntervalValue','requestIntervalUom']);
      }

      setLeftMergeRules=(value:any)=>{
        let rules= this.formRef.current!.getFieldValue("mergeRules");
        let filteredRules=rules.filter((v:any)=>v.ruleId!==value);
        this.setState({rightMergeRules:filteredRules});
     }

     setRightMergeRules=(value:any)=>{
        let rules= this.formRef.current!.getFieldValue("mergeRules");
        let filteredRules=rules.filter((v:any)=>v.ruleId!==value);
        this.setState({leftMergeRules:filteredRules});
    }

     allowedSingleFrameUsersDev = [
        '6eb1fbc0-0ffe-11eb-b4af-c7f0a7c1b411','70c429b0-91a7-11e8-af90-51d20134150b',
        '152bd120-309b-11ec-bc2d-bb7291a0ceba','e704a550-4e6b-11ea-8fc9-5f6f1264b2c7',
        'cad29b40-1d5f-11ea-abaa-a34583f0c422','96d03480-0431-11ee-9567-dfd4928f58e8',
        '7cb0a1a0-542f-11ed-b7bb-f305eb77c106','13131860-537d-11ee-a2ea-233ac79fb151',
        'ce3bb3a0-9619-11eb-87b2-239c76a096d7','054dda40-59ab-11eb-8ee0-bfaf65ef3261',
        '521a6300-67b3-11eb-a256-dba62bf2eb52'
    ];
     allowedSingleFrameUsersStag = [
        'a5c3cb80-2b37-11eb-ada0-d3b3466952a6','70c429b0-91a7-11e8-af90-51d20134150b',
        '8279a1a0-62fd-11ec-8f8f-53c130199102','e95b17a0-07ce-11ea-8c1e-29af9f5c9993',
        '9bb0b810-1d5f-11ea-b10c-611371d47cc6','ef77ae80-3819-11ee-b6e7-875b48ebb37d',
        '94a456c0-3819-11ee-a7c5-8b26a0381d3f','dba0aa40-5e6c-11ea-8269-67263d3b9573',
        'fee680c0-9619-11eb-9d03-1f19554fcd07','3bf339c0-599f-11eb-b6ef-0754ed297eb6'
    ];
     allowedSingleFrameUsersProd = [
        '25c1ec40-2bab-11eb-9f2e-2fc8ff0a0080','70c429b0-91a7-11e8-af90-51d20134150b',
        '8a209140-d985-11eb-87da-f7bca1133f68','e95b17a0-07ce-11ea-8c1e-29af9f5c9993',
        '9bb0b810-1d5f-11ea-b10c-611371d47cc6','910a8d40-08fd-11ee-9e3e-ab5787211252',
        '69bfdee0-6af4-11ed-b1c9-df04de49673c', 'dba0aa40-5e6c-11ea-8269-67263d3b9573',
        'f0e97000-2f9f-11ee-a86c-135fbbccefd5', 
        '1e9aa720-961a-11eb-bc08-ff40daac93b5','fe97d7c0-2bae-11eb-b7a8-aba558bbe8c6'
    ];

    render() {

        return (
        
            <>
                <div data-id="signals-form-messages">
                    {this.state.formSuccess && !this.state.formError && <SuccessAlert data-id="signals-success-message" message={this.state.formSuccessMessage}/>}
                    {this.state.formError && !this.state.formSuccess && <ErrorAlert data-id="signals-error-message"message={this.state.formErrorMessage}/>}
                </div>

                <Row gutter={8} style={{marginTop: 15}}>
                    <Col offset={1} span={23}>
                        <Form
                            id="signalMaintInfoForm"
                            ref={this.formRef}
                            layout="vertical"
                            onFinish={this.onFinish}
                        >       
                            <Form.Item 
                                name="templateId"
                                style={{ display: 'none' }}
                            >
                                <Input type="hidden" />
                            </Form.Item>

                            <Form.Item 
                                name="signalCollectionId"
                                style={{ display: 'none' }}
                            >
                                <Input type="hidden" />
                            </Form.Item>

                            <Form.Item 
                                name="signalId"
                                style={{ display: 'none' }}
                            >
                                <Input type="hidden" />
                            </Form.Item>

                            {
                                // a user shall be able to view the signal content if users has view template permission but cannot update if the user does not have create/update permission
                                (this.props.signalMaint.collectionType === "IQAN Diagnostics" && (this.props.isread || this.props.iscreateOrUpdateTemplate)) &&     
                                <>                                                               
                                    <Row gutter={8}>
                                        <Col span={24}>
                                            <Form.Item
                                                label="Signal Name"
                                                name="label"    
                                                rules={[
                                                    { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                                                ]}
                                            >
                                                <Input 
                                                    maxLength={255}
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={8}>
                                        <Col span={24}>
                                            <Form.Item
                                                label="Source"
                                                name="sourceName"      
                                                rules={[
                                                    { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                                                ]}             
                                            >
                                                <Select 
                                                    onChange={this.onSourceNameChange}
                                                >
                                                    <Option key="can0" value="can0">can0</Option>
                                                    <Option key="can1" value="can1">can1</Option>
                                                </Select>     
                                            </Form.Item> 
                                        </Col>
                                    </Row>  
                                    <div className="mt-20"></div>
                                </>           
                            }
                            {
                                (((this.props.signalMaint.collectionType || "none") === "none" || this.props.signalMaint.collectionType === "Command")   && (this.props.isread || this.props.iscreateOrUpdateTemplate)) &&                                                                    
                                <Row gutter={8}>
                                    <Col span={24}>
                                
                                        <Form.Item
                                            label="Signal Source"
                                            name="mode"  
                                        >
                                            <Select 
                                                data-id="mode" 
                                                onChange={this.onModeChange}
                                                placeholder="Select..."
                                                disabled={this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateOrUpdateTemplate}
                                            >
                                                {(this.props.signalMaint.collectionType !== "Command") &&
                                                <Option key="J1939 Database" value="J1939 Database">J1939 Database</Option>
                                                }
                                                <Option key="Add New Signal" value="Add New Signal">Custom Signal</Option>
                                            </Select>     
                                        </Form.Item>
                                        {
                                            this.state.mode === "J1939 Database" &&
                                            <>
                                                <Form.Item
                                                    label="J1939 Template"
                                                    name="modeId"     
                                                    rules={[
                                                        { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                                                    ]}             
                                                >
                                                    <Select 
                                                        showSearch
                                                        placeholder="Search by typing PGN Name, SPN Name, PGN or SPN"
                                                        defaultActiveFirstOption={false}
                                                        showArrow={false}
                                                        filterOption={false}
                                                        onSearch={this.onJ1939TemplateSearch}
                                                        onChange={this.onJ1939TemplateChange}
                                                        notFoundContent={null}
                                                        disabled={this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateOrUpdateTemplate}
                                                        allowClear
                                                    >
                                                        {this.props.signalMaint.j1939s!.data.map((record:any, index:number) => (
                                                            <Option key={record.j1939Id} value={record.j1939Id}>                                                
                                                                <div className="demo-option-label-item">
                                                                    <div><strong>PGN&nbsp;</strong>{record.pgnName} ({record.pgn})</div>
                                                                    <div><strong>SPN&nbsp;</strong>{record.spnName} ({record.spn})</div>
                                                                </div>
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>                                  
                                            </>
                                        }
                                        {
                                           ((this.props.signalMaint.collectionType || "none") === "Command") &&
                                            (this.state.mode === "Add New Signal") &&
                                            <>
                                                <Form.Item
                                                    label="Command PGNs"
                                                    name="pgn"     
                                                    rules={[
                                                        { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                                                    ]}             
                                                >
                                                    <Select 
                                                        showSearch
                                                        placeholder="Search by typing Command PGN Name or PGN"
                                                        defaultActiveFirstOption={false}
                                                        showArrow={false}
                                                        filterOption={false}
                                                        onSearch={this.onCommandPgnSearch}
                                                        onChange={this.onCommandPgnChange}
                                                        notFoundContent={null}
                                                        disabled={this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateOrUpdateTemplate}
                                                        allowClear
                                                    >
                                                        {this.props.signalMaint.commandPgns!.data.map((record:any, index:number) => ( 
                                                            <Option key={record.id} value={record.id}>                                                
                                                                <div className="demo-option-label-item">
                                                                    <div><strong>PGN&nbsp;</strong>{record.pgnName} ({record.pgn})</div>
                                                                    <div><strong>SPN&nbsp;</strong>{record.spnName} ({record.spn})</div>
                                                                </div>
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>                                  
                                            </>
                                        }
                                        {
                                            (
                                                this.state.mode === "Add New Signal" 
                                            ) &&
                                            <Form.Item
                                                label="Signal Name"
                                                name="label"    
                                                rules={[
                                                    { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                                                ]}
                                            >
                                                <Input 
                                                    maxLength={255}
                                                    disabled={this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateOrUpdateTemplate}
                                                />
                                            </Form.Item>
                                        }                                       
                                    </Col> 
                                </Row>   
                            }

                            { /* request pgn for D2C telemetry but not D2C text */
                                ((
                                    (this.props.signalMaint.collectionType || "none") === "none" &&
                                    (this.state.format || "float") === "float" 
                                ) && (this.props.isread || this.props.iscreateOrUpdateTemplate)) &&
                                (
                                    this.state.signalId ||                
                                    (!this.state.signalId && this.state.mode) 
                                ) && 
                                <>
                                                
                                 <Collapse activeKey = {this.state.activeKey}>
                                <Panel disabled={!this.state.viewRequestPGN}
                                header="Request PGN"
                                key="1"
                                extra={
                                    <div>
                                        
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}                                           
                                            onChange = {this.showRequestPGNPanel} 
                                            checked = {this.state.viewRequestPGN} />
                                    </div>
                                }
                                >
                                 <div style={{ marginTop:'-15px'}}>
                                            <span style={{ color:'#0052CC',fontSize:'12px'}}>This feature requires version 1.2.5 running on Gateway</span>
                                            </div>
                    <Row gutter={8} style={{marginTop: 15}}>
                    <Col offset={1} span={23}>
                                <Row gutter={8}>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        label="Request Interval"
                                        name="requestIntervalValue"
                                        rules={[
                                            { required: this.props.signalMaint.collectionType === "none" && this.state.viewRequestPGN, message: appConfigs.errors.fieldErrors.valueRequired },
                                            {  validator: this.validateTimeValue, }
                                        ]}
                                    >
                                        <InputNumber onChange={this.validateTime}
                                            min={1} 
                                            max={86400} //Maximum time per day in seconds is 86400.
                                            style={{ width: '100%' }}  
                                            precision={0} 
                                            
                                        />
                                    </Form.Item>
                                </Col>                        
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        label="Units"
                                        name="requestIntervalUom"
                                        rules={[
                                            { required: this.props.signalMaint.collectionType === "none" && this.state.viewRequestPGN, message: appConfigs.errors.fieldErrors.valueRequired },
                                            {  validator: this.validateTimeUnit, }
                                        ]} 
                                    >
                                         <Select onChange={this.validateTime}>
                                            <Option key="s" value="s">Seconds</Option>
                                            <Option key="m" value="m">Minutes</Option>
                                            <Option key="h" value="h">Hours</Option>
                                        </Select>     
                                    </Form.Item>
                                </Col> 
                            </Row>                                   
                            <Row gutter={8}>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        label="Source Address"
                                        name="pgnSourceAddress"
                                        rules={[
                                            { required: this.props.signalMaint.collectionType === "none" && this.state.viewRequestPGN, message: appConfigs.errors.fieldErrors.valueRequired }
                                        ]}
                                    >
                                        <InputNumber
                                          min={0} 
                                          max={255}
                                          style={{ width: '100%' }}  
                                          precision={0} 
                                          disabled={true}
                                        />
                                    </Form.Item>
                                    
                                            {/* <div style={{ marginTop:'-15px'}}>
                                            <span style={{ color:'#0052CC',fontSize:'12px'}}>Allowed Values 0 - 255</span>
                                            </div> */}
                                            
                                </Col>                        
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        label="Destination Address"
                                        name="pgnDestinationAddress"
                                        rules={[
                                            { required: this.props.signalMaint.collectionType === "none" && this.state.viewRequestPGN, message: appConfigs.errors.fieldErrors.valueRequired }
                                        ]}
                                    >
                                        <InputNumber
                                             min={0} 
                                             max={255}
                                             style={{ width: '100%' }}  
                                             precision={0} 
                                           
                                        />
                                    </Form.Item>
                                    <div style={{ marginTop:'-15px'}}>
                                            <span style={{ color:'#0052CC',fontSize:'12px'}}>Allowed Values 0 - 255</span>
                                            </div>
                                </Col> 
                            </Row>
                            </Col>
                            </Row>
                        </Panel>
                        </Collapse> 
                                </>
                            }

                            {
                                (((this.props.signalMaint.collectionType || "none") === "none" || this.props.signalMaint.collectionType === "Command")  && (this.props.isread || this.props.iscreateOrUpdateTemplate)) &&
                                (
                                    this.state.signalId ||                
                                    (!this.state.signalId && this.state.mode) 
                                ) &&                
                                <>
                                    <Row gutter={8}>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label="PGN"
                                                name="pgn"
                                                rules={[

                                                    { required: ( ((this.props.signalMaint.collectionType || "none") === "none" && this.state.viewRequestPGN)), message: appConfigs.errors.fieldErrors.valueRequired }

                                                ]}
                                            >
                                                <InputNumber 
                                                    min={0} 
                                                    max={1000000} 
                                                    style={{ width: '100%' }}  
                                                    precision={0} 
                                                    disabled={this.state.mode === "J1939 Database" || (this.props.signalMaint.collectionType || "none") === "Command" ||
                                                    (this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateOrUpdateTemplate)}  
                                                />
                                            </Form.Item>
                                        </Col>                        
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label="PGN Name"
                                                name="pgnName" 
                                            >
                                                <Input 
                                                    maxLength={255} 
                                                    disabled  
                                                />
                                            </Form.Item>
                                        </Col> 
                                    </Row>  
                                    { /* do not show pgn for D2C and C2D text */
                                    (((this.props.signalMaint.collectionType || "none") === "none" &&
                                      (this.state.format || "float") === "float"
                                     ) ||  
                                     (this.props.signalMaint.collectionType === "Command" && 
                                        this.state.pgn > 65306 && this.state.pgn <= 65350 
                                     ) 
                                    ) &&                                   
                                    <Row gutter={8}>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label="SPN"
                                                name="spn" 
                                            >
                                                <InputNumber 
                                                    min={0} 
                                                    max={1000000} 
                                                    style={{ width: '100%' }}  
                                                    precision={0} 
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col>                        
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label="SPN Name"
                                                name="spnName"  
                                            >
                                                <Input 
                                                    maxLength={255} 
                                                    disabled
                                                />
                                            </Form.Item>
                                        </Col> 
                                    </Row>            
                                    }  
                                    {
                                        this.props.signalMaint.collectionType !== "GPS" &&
                                        this.props.signalMaint.collectionType !== "IQAN Diagnostics" &&
                                        this.props.signalMaint.collectionType !== "Command" &&
                                        // this.state.mode === "Add New Signal" &&
                                        <Row gutter={8}>
                                            <Col span={24}>
                                                <Form.Item
                                                        label="Format"
                                                        name="format" 
                                                    >
                                                    <Select 
                                                        onChange={this.onFormatChange}>
                                                           <Option key="none" value=""> </Option>
                                                           <Option key="text" value="text">Text</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    } 
                                    {this.props.signalMaint.collectionType === "Command" &&
                                        <Row gutter={8}>
                                            <Col xs={24} md={12}>
                                                <Form.Item
                                                        label="Format"
                                                        name="format" 
                                                        rules={[

                                                            { required: ( (this.props.signalMaint.collectionType || "none") === "Command"), 
                                                                message: appConfigs.errors.fieldErrors.valueRequired }
                                                        ]}
                                                    >
                                                    <Select 
                                                        onChange={this.onFormatChange}
                                                        disabled={this.state.pgn === 65305 || this.state.pgn === 65310 ||
                                                            (this.state.pgn >= 65351 && this.state.pgn <= 65370) }>
                                                        {this.state.pgn === 65311 && <Option key="hex" value="hex">Hex</Option>}
                                                        {this.state.pgn === 65311 && <Option key="integer" value="integer">Integer</Option>}
                                                        {this.state.pgn === 65311 && <Option key="float" value="float">Float</Option>}
                                                        {this.state.pgn === 65310 && <Option key="boolean" value="boolean">Boolean</Option>}
                                                        {(this.state.pgn === 65305 &&  (this.props.signalMaint.collectionType || "none") === "Command") &&
                                                           <Option key="text" value="text">Text</Option>}
                                                        {(this.state.pgn >= 65351 &&  this.state.pgn <= 65370 && 
                                                            (this.props.signalMaint.collectionType || "none") === "Command") &&
                                                           <Option key="hex" value="hex">Hex</Option>}
                                                        {(this.props.signalMaint.collectionType || "none") !== "Command" &&
                                                           <Option key="float" value="float">Float</Option>}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item
                                                    label={this.state.pgn === 65305 ? "Gateway CAN Address" : "Destination Controller CAN Address"}
                                                    name="canAddress"     
                                                    rules={[
                                                        { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                                                    ]}             
                                                >
                                                    <Select 
                                                        showSearch
                                                        placeholder="Search by typing Command Controller Source address ID or name"
                                                        defaultActiveFirstOption={false}
                                                        showArrow={false}
                                                        filterOption={false}
                                                        onSearch={this.onCommandControllersSearch}
                                                        onChange={this.onCommandControllersChange}
                                                        notFoundContent={null}
                                                        disabled={
                                                            (this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateOrUpdateTemplate) ||
                                                            this.state.pgn === 65305
                                                        }
                                                        allowClear
                                                    >
                                                        {this.props.signalMaint.commandControllers!.data.map((record:any, index:number) => ( 
                                                            <Option key={record.cmdControllerSaId} value={record.cmdControllerSaId}>                                                
                                                                <div className="demo-option-label-item">
                                                                    <div>({record.cmdControllerSaId}) {record.cmdControllerSaName}</div>
                                                                </div>
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    }
                                    {/* This is for integer or float, not text (c2d or d2c), boolean or hex*/
                                    (((this.props.signalMaint.collectionType || "none") === "none"  &&
                                       ((this.state.format || "float") === "float")) ||
                                      ((this.props.signalMaint.collectionType || "none") === "Command" &&
                                         (this.state.format === "integer" || this.state.format === "float" ||
                                         ((this.state.format || "none") === "none" && this.state.pgn != 65305 && this.state.pgn != 65310)))) &&
                                    <Row gutter={8}>
                                        <Col xs={24} md={12}>
                                    
                                            <Form.Item
                                                label="Unit"
                                                name="unitName"
                                                style={{ display: 'inline-block'}}
                                            >
                                                <Select
                                                showSearch
                                                disabled={this.state.mode === "J1939 Database" ||
                                                    (this.props.isGlobalTemplate && !this.props.isadmin 
                                                        || !this.props.isGlobalTemplate && !this.props.iscreateOrUpdateTemplate) ||
                                                     (this.props.signalMaint.collectionType === "Command" && 
                                                     ((this.state.format || "none") === "hex"))
                                                    }  
                                                style={{ width: 165 }}
                                                onChange={this.onUnitNameChange}
                                                optionFilterProp="children"
                                                // filterOption={(input, option) =>
                                                //     option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                //   }
                                                >
                                                {
                                                this.getUniqueUnits().map((record:any, index:number) => (
                                                                <Option key={record.id} value={record.name}>                                                
                                                                    {record.name}
                                                                </Option>
                                                ))

                                                }
                                                </Select>

                                            </Form.Item>
                                            <Form.Item
                                                
                                                name="unit"
                                                style={{ display: 'inline-block', width: 100,marginTop:'30px'}}                                
                                            >
                                                {/* <Input maxLength={32}
                                                    onChange={ (e) => this.setUnit(e.target.value) }
                                                    placeholder="" 
                                                    disabled={this.state.mode === "J1939 Database" ||
                                                    (this.props.isGlobalTemplate && !this.props.isadmin 
                                                        || !this.props.isGlobalTemplate && !this.props.iscreateOrUpdateTemplate) ||
                                                    this.state.format === "text" || this.state.format === "boolean"
                                                    }  
                                                /> */}

                                                <AutoComplete
                                                  showArrow={false}
                                                  allowClear={true}
                                                  disabled={this.state.mode === "J1939 Database" ||
                                                  (this.props.isGlobalTemplate && !this.props.isadmin 
                                                      || !this.props.isGlobalTemplate && !this.props.iscreateOrUpdateTemplate) ||
                                                      (this.props.signalMaint.collectionType === "Command" && 
                                                          (this.state.format || "none")=== "hex")
                                                  }  
                                                style={{ width: 117, margin: '0 5px' }}
                                                showSearch
                                                optionFilterProp="children"
                                            
                                                onSearch={this.onUnitSearch}
                                                >
                                                    {
                                                        this.state.unitSymbols.map((record:any, index:number) => (
                                                            <Option key={record.id} value={record.symbol}>                                                
                                                                {record.symbol}
                                                            </Option>
                                                        ))
                                                    }
                                               
                                                </AutoComplete>

                                            </Form.Item>
                                        </Col>                                                                                     
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label="Source"
                                                name="sourceName"      
                                                rules={[
                                                    { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                                                ]}             
                                            >
                                                <Select 
                                                    onChange={this.onSourceNameChange}
                                                >
                                                    <Option key="can0" value="can0">can0</Option>
                                                    <Option key="can1" value="can1">can1</Option>
                                                    {
                                                        ((this.state.mode === "Add New Signal" && ( this.props.signalMaint.collectionType === "Command" || this.state.viewRequestPGN ))
                                                        || (this.state.mode === "J1939 Database" && this.state.viewRequestPGN)) 
                                                        &&
                                                        <Option key="both" value="both">both</Option>
                                                    }
                                                    {
                                                        this.state.mode === "Add New Signal" && this.props.signalMaint.collectionType !== "Command" && !this.state.viewRequestPGN &&
                                                        <Option key="custom" value="custom">custom</Option>
                                                    }
                                                </Select>     
                                            </Form.Item> 
                                        </Col> 
                                    </Row>   
                                    }
                                    {/* this is for c2d or d2c text, and boolean, hex */
                                      ( ((this.props.signalMaint.collectionType || "none") === "none" && 
                                        (this.state.format || "float") === "text") ||
                                       (this.props.signalMaint.collectionType || "none") === "Command" &&
                                      (this.state.format === "text" || this.state.format === "boolean"|| this.state.format === "hex"
                                      || (this.state.format || "none" === "none") && (this.state.pgn === 65305 || this.state.pgn === 65310 || this.state.pgn > 65350 ))) &&
                                    <Row gutter={8}>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label="Bit Length"
                                                name="bitLength"
                                                style={{ display: 'inline-block', width: 140}}
                                            >
                                                <InputNumber 
                                                    min={0}
                                                    max={64}
                    
                                                    // precision={0} 
                                                    disabled={this.state.pgn === 65305 || this.state.pgn === 65310 || this.state.pgn > 65350
                                                     || this.state.format === "hex" || this.state.format === "text"} 
                                                    // placeholder="1 - 64"
                                                    style={{ display: 'inline-block', width: 140}}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label="First Bit"
                                                name="firstBit"
                                                style={{ display: 'inline-block', width: 140, margin: '0 2px'}}  
                                            >
                                                <InputNumber 
                                                    min={0} 
                                                    max={63}
                                                    // precision={0} 
                                                    disabled={this.state.format === "hex" || this.state.format === "text" || this.state.pgn === 65305 || this.state.pgn > 65350
                                                     }
                                                    style={{ display: 'inline-block', width: 140, margin: '0 2px'}}
                                                />
                                            </Form.Item>
                                        </Col>                                                                            
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label="Source"
                                                name="sourceName"      
                                                rules={[
                                                    { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                                                ]}             
                                            >
                                                <Select 
                                                    onChange={this.onSourceNameChange}
                                                >
                                                    <Option key="can0" value="can0">can0</Option>
                                                    <Option key="can1" value="can1">can1</Option>
                                                    {
                                                        ((this.state.mode === "Add New Signal" && ( this.props.signalMaint.collectionType === "Command" || this.state.viewRequestPGN ))
                                                        || (this.state.mode === "J1939 Database" && this.state.viewRequestPGN)) 
                                                        &&
                                                        <Option key="both" value="both">both</Option>
                                                    }
                                                    {
                                                        this.state.mode === "Add New Signal" && this.props.signalMaint.collectionType !== "Command" && !this.state.viewRequestPGN &&
                                                        <Option key="custom" value="custom">custom</Option>
                                                    }
                                                </Select>     
                                            </Form.Item> 
                                        </Col> 
                                    </Row>   
                                    }
                                    {
                                        this.state.mode === "Add New Signal" &&
                                        this.state.sourceName === "custom" &&                           
                                        <Row gutter={8}>
                                            <Col span={24}>
                                                <Form.Item
                                                    label="Custom Source"
                                                    name="source"       
                                                    rules={[
                                                        { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                                                    ]}                            
                                                >
                                                    <Input 
                                                        maxLength={255}
                                                        disabled={this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateOrUpdateTemplate}
                                                    />
                                                </Form.Item>       
                                            </Col>      
                                        </Row>
                                    }
                                    {/* This is for command or signals as integer or float  */
                                     ((this.props.signalMaint.collectionType || "none") === "none" && 
                                        ((this.state.format || "float") === "float" ) || 
                                      ((this.props.signalMaint.collectionType || "none") === "Command" &&
                                        (this.state.format === "integer" || this.state.format === "float"))) &&
                                    <Row gutter={8}>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label="Bit Length"
                                                name="bitLength"
                                            >
                                                <InputNumber 
                                                    min={1}
                                                    max={64}
                                                    style={{ width: '100%' }}  
                                                    precision={0} 
                                                    disabled={this.state.mode === "J1939 Database" ||
                                                        (this.props.isGlobalTemplate && !this.props.isadmin || 
                                                            !this.props.isGlobalTemplate && !this.props.iscreateOrUpdateTemplate)  
                                                    }  
                                                    placeholder="Values 1 - 64"
                                                />
                                            </Form.Item>
                                            {this.props.signalMaint.collectionType === "Command" &&
                                            <div style={{ marginTop:'-15px'}}>
                                            <span style={{ color:'#0052CC',fontSize:'12px'}}>Allowed Values 1 - 64</span>
                                            </div>
                                            }
                                        </Col>                        
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label="First Bit"
                                                name="firstBit"
                                            >
                                                <InputNumber 
                                                    min={0} 
                                                    max={63}
                                                    style={{ width: '100%' }}  
                                                    precision={0} 
                                                    disabled={this.state.mode === "J1939 Database" ||
                                                        (this.props.isGlobalTemplate && !this.props.isadmin || 
                                                            !this.props.isGlobalTemplate && !this.props.iscreateOrUpdateTemplate)
                                                    } 
                                                    placeholder="Values 0 - 63" 
                                                />
                                            </Form.Item>
                                            {this.props.signalMaint.collectionType === "Command" &&
                                            <div style={{ marginTop:'-15px'}}>
                                            <span style={{ color:'#0052CC',fontSize:'12px'}}>Allowed Values 0 - 63</span>
                                            </div>
                                            }
                                        </Col> 
                                    </Row>    
                                  }
                                    {(((this.props.signalMaint.collectionType || "none") === "none"  &&
                                       ((this.state.format || "float") === "float")) ||  
                                      ((this.props.signalMaint.collectionType || "none") === "Command" &&
                                          (this.state.format === "integer" || this.state.format === "float" || 
                                          ((this.state.format || "none") === "none" && this.state.pgn !== 65305 
                                             && this.state.pgn !== 65310 && this.state.pgn <= 65350)) )
                                    )  && 
                                    <Row gutter={8}>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label="Offset"
                                                name="offset"                                 
                                            >
                                                <InputNumber 
                                                    style={{ width: '100%' }}  
                                                    //precision={12} 
                                                    disabled={this.state.mode === "J1939 Database" || 
                                                    (this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate 
                                                        && !this.props.iscreateOrUpdateTemplate)}  
                                                />
                                            </Form.Item>
                                        </Col>                        
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label="Scaling"
                                                name="scaling"                              
                                            >
                                                <InputNumber 
                                                    style={{ width: '100%' }}  
                                                    //precision={12} 
                                                    disabled={this.state.mode === "J1939 Database" || 
                                                    (this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate 
                                                        && !this.props.iscreateOrUpdateTemplate)}  
                                                />
                                            </Form.Item>
                                        </Col> 
                                    </Row>   
                                    }
                                    {/* only for generic telemetry that is not text */
                                     ((this.props.signalMaint.collectionType || "none") === "none"  &&
                                        (this.state.format || "float") === "float")  &&    
                                    <Row gutter={8}>
                                        <Col xs={24} md={12}>
                                            <div className="matchSourceAddressLabel">Match Source Address</div>
                                            <Row gutter={8}>
                                                <Col xs={6}>
                                                    <Form.Item
                                                        name="matchSourceAddress"
                                                        valuePropName="checked"
                                                        >
                                                        <Switch  
                                                            disabled={this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateOrUpdateTemplate || this.props.signalMaint.collectionType === "Command"}
                                                            onChange={this.onMatchSourceAddressChange}
                                                        />
                                                    </Form.Item>       
                                                </Col>                                                   
                                                <Col xs={18}>
                                                    {
                                                        this.state.matchSourceAddress &&
                                                        <Form.Item
                                                            name="sourceAddress"                                 
                                                        >
                                                            <InputNumber 
                                                                min={0} 
                                                                max={255} 
                                                                style={{ width: '100%' }}  
                                                                precision={0} 
                                                                onChange={this.onSourceAddressChange}
                                                                placeholder="Values 0 - 255" 
                                                                disabled={this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateOrUpdateTemplate || this.props.signalMaint.collectionType === "Command"}
                                                            />
                                                        </Form.Item>   
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>   
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label="J1939 Error Checking"
                                                name="j1939Error"
                                                valuePropName="checked"
                                            >
                                                <Switch disabled= {this.props.isGlobalTemplate && !this.props.isadmin || !this.props.isGlobalTemplate && !this.props.iscreateOrUpdateTemplate || this.props.signalMaint.collectionType === "Command"}/>
                                            </Form.Item>    
                                        </Col>                        
                                    </Row>  
                                    }
                                    {
                                        (this.props.signalMaint.collectionType !== "Command" 
                                        ) &&
                                        <Row gutter={8}>
                                            {this.state.mode === "Add New Signal" && 
                                            <Col xs={24} md={12}>
                                                <Form.Item
                                                    label="CAN Header"
                                                    name="canHeader"  
                                                >
                                                    <Input maxLength={255} 
                                                     disabled={this.props.isGlobalTemplate && !this.props.isadmin 
                                                        || !this.props.isGlobalTemplate && !this.props.iscreateOrUpdateTemplate
                                                        || ((this.props.signalMaint.collectionType || "none") === "none"  &&
                                                            (this.state.format || "float") === "text") 
                                                      }  
                                                    />
                                                </Form.Item>
                                            </Col>
                                             }
                                             { (this.props.signalMaint.collectionType  || "none") !== "IQAN Diagnostics" &&
                                             // Support response signal for J1939 or Generic signal for non-command, none-IQAN diagnostics
                                            <Col xs={24} md={12}>
                                            {
                                                this.state.commandSignals.length > 0 &&
                                                <Form.Item
                                                    label="Command Name"
                                                    name="commandId"  
                                                >   
                                                   <Select 
                                                    onChange={this.onCommandNameChange}>
                                                       { this.state.commandSignals.map(signal =>
                                                         <Option key={signal.signalId } value={signal.signalId}
                                                                 style={signal.name.includes("(NA to org)")? {color: '#A6A6A6', textDecorationLine: 'line-through'}
                                                                 :{color: "blue"}}
                                                         >{signal.name}</Option>)};
                                                    </Select>  
                                                    
                                                </Form.Item>
                                            }
                                            </Col>
                                            }
                                        </Row>
                                    }
                                    {
                                        this.props.signalMaint.collectionType === "Command" &&
                                        (this.state.pgn <= 65350 && this.state.pgn >= 65305 &&
                                             (this.state.format === "integer" || this.state.format === "float" || this.state.format === "text" || this.state.pgn == 65305
                                             || this.state.pgn > 65310 && this.state.pgn <= 65350 )) &&
                                        <Row gutter={8}>
                                            <Col xs={24} md={12}>
                                            {(this.state.format === "integer" || this.state.format === "float" || this.state.pgn !== 65305 && this.state.pgn !== 65310) &&
                                                <Form.Item
                                                    label="Min"
                                                    name="min" 
                                                    rules={[
                                                        { required: this.state.format === "integer" || this.state.format === "float", 
                                                    message: appConfigs.errors.fieldErrors.valueRequired }
                                                    ]}  
                                                >
                                                    <Input maxLength={255} 
                                                    disabled={ this.state.format === "hex"}
                                                     />                                
                                                </Form.Item>
                                            }  
                                             

                                            {(this.state.format === "text" || this.state.pgn === 65305 ) &&
                                            <Form.Item
                                                label="Multi-package"
                                                name="multiPackage" 
                                                rules={[
                                                { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                                                ]}
                                            >

                                                <Select disabled={false} onChange={(value) => {
                                                    // temporarily disabled for prod and enable dev/stage testing
                                                this.setMulti(value);
                                                const maxLength = value === 'Yes' ? 144 : 8;
                                                const invalidMessages = this.state.c2dWhitelist.filter((message:any) => message.length > maxLength);
                                                if (invalidMessages.length > 0) {
                                                    message.error(`Error: Message length cannot exceed ${maxLength} characters when MultiPackage is No`);
                                                    this.setState({ multiPackageError: true }); 
                                                } else {
                                                    this.setState({ multiPackageError: false}); 
                                                }
                                                }}>
                                                <Option value="Yes">Yes</Option>
                                                <Option value="No">No</Option>
                                                </Select>  
                                            </Form.Item>
                                            }
                                                        
                                            {(this.state.pgn===65305) &&
                                           // appConfigs.app.enableCommandSignalFakeTesting &&
                                            // this.props.context.appContext.orgId === 'c6adcb40-be92-11e6-9ed6-a5bc9cb5279b' &&
                                            // (this.allowedSingleFrameUsersDev.includes(this.props.context.appContext.userId) ||
                                            //  this.allowedSingleFrameUsersStag.includes(this.props.context.appContext.userId) ||
                                            //  this.allowedSingleFrameUsersProd.includes(this.props.context.appContext.userId) ) && 
                                            // temporarily disable for prod and enable dev/stage testing
                                            <Collapse style={{width:400}}>
                                                <Panel header="Cloud-to-Device Message Whitelist" key="1" style={{ width: 398 }}>
                                                   
                                                {this.state.c2dWhitelist.sort().map((message:any, index:any) => (
                                                    <Row key={index} gutter={8} style={{ marginBottom: 8}}>
           
                                                    {/* // <Row key={index} gutter={8} style={{ marginBottom: 8, border: message.length > (this.state.multiPackage === 'Yes' ? 140 : 8) ? '0.5px solid red' : 'none' }}> */}
                                                    <Col span={60}>
                                                        <TextArea 
                                                        value={message} 
                                                        onChange={(event) => this.handleMessageTextChange(event, index)}  
                                                        style={{width:300, border: message.length > (this.state.multiPackage === 'Yes' ? 140 : 8) ? '0.5px solid red' : '' }}    
                                                        autoSize={{ minRows: 1, maxRows: this.state.multiPackage === 'Yes' ? 6 : 1 }}
                                                        maxLength={this.state.multiPackage === 'Yes' ? 140 : 8}
                                                        />
                                                    </Col>
                                                    <Col span={4}>
                                                        <Button type="primary" onClick={() => this.handleRemoveMessage(index)} style={{ zIndex: 1 }}>-</Button>
                                                    </Col>
                                                    </Row>
                                                ))}
                                                <Row gutter={8} style={{ marginBottom: 8, width:300}}>
                                                    <Col span={60}>
                                                    <Input
                                                        value={this.state.newMessageText}
                                                        onChange={this.handleNewMessageTextChange}
                                                        style={{ width: 300 }}
                                                        maxLength={this.state.multiPackage === 'Yes' ? 140 : 8}
                                                        placeholder="Enter Text Here"
                                                    />
                                                    </Col>
                                                    <Col span={60} >
                                                    <Button type="primary" onClick={this.handleAddMessage} style={{ zIndex: 1 }}>Add New Message</Button>
                                                    </Col>
                                                </Row>
                                                </Panel>
                                            </Collapse>
                                            }

                                        
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <Form.Item
                                                    label="Max"
                                                    name="max" 
                                                    rules={[
                                                        { required: (this.props.signalMaint.collectionType === "Command" &&
                                                          this.state.pgn <= 65350 && 
                                                         (this.state.format === "integer"|| this.state.format === "float" || this.state.format === "text" ||
                                                         this.state.pgn === 65305 )),  
                                                    message: appConfigs.errors.fieldErrors.valueRequired }
                                                    ]}>

                                                      <Input
                                                      maxLength={(this.state.multiPackage === "Yes")? 140:80
                                                      } 
                                                     disabled={this.state.format === "hex" || this.state.pgn == 65305}/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    }
                                    
                                    <div className="mt-20"></div>                                                                                                                      
                                </>                         
                            }
                            {
                                (
                                    (this.state.signalId || (!this.state.signalId && this.state.mode)) && 
                                    (this.props.iscreateRules || this.props.isupdateRules || this.props.isreadRules || this.props.isdeleteRules) &&
                                    (this.props.signalMaint.collectionType !== "Command") &&
                                    ((this.props.signalMaint.collectionType || "none") === "none" &&
                                     (this.state.format || "float") === "float" /* not for D2C text nor command */
                                     )
                                ) &&      
                                <SignalRule setLeftMergeRules={this.setLeftMergeRules} setRightMergeRules={this.setRightMergeRules}  leftMergeRules={this.state.leftMergeRules} rightMergeRules={this.state.rightMergeRules}  iscreateRules={this.props.iscreateRules} isreadRules={this.props.isreadRules} isupdateRules={this.props.isupdateRules}
                                    isdeleteRules={this.props.isdeleteRules} formRef={this.formRef.current}  isGlobalTemplate = {this.props.isGlobalTemplate} isadmin={this.props.isadmin} />     
                            }
                        </Form>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        templateDetails: state.templateDetailsReducers.templateDetailsState,
        signals: state.templateDetailsReducers.templateDetailsState.signals,
        signalMaint: state.templateDetailsReducers.templateDetailsState.signals.signalMaint,
        signalList: state.templateDetailsReducers.templateDetailsState.signals.signalList,
        context: state.contextReducer.context
    };
};

export default connect(
    mapStateToProps,
    actions
)(SignalMaintInfo);