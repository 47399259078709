import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function activitySubscriptionsGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    userId: string,
  ) {
    return async (dispatch: any) => {            
        Axios.get(
            appConfigs.server.URL + "/ui/api/users/" + ((userId || "") !== "" ? userId + "/": "") + "activitysubscription", {
                    responseType: "json",        
                    headers: {},
                    params: {}           
                }
            )
            .then(response => {
                    let activitySubscriptions=response.data.data.activitySubscriptions;
                    if (callbackFnSuccess != null) {
                        callbackFnSuccess(activitySubscriptions);
                    }
                    dispatch({ type: "USER_ACTIVITY_SUBSCRIPTIONS_GET", payload: {data: activitySubscriptions} });
                }
            )
            .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
            
    };
}    

export function activitySubscriptionsMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    userId: string,
    record: any,
    notification: string,
    action: string,
    scope: string
) {
    return async (dispatch: any) => {
        Axios.put(
            appConfigs.server.URL + "/ui/api/users/" + userId + "/activitysubscription" + 
                "?notification=" + notification + 
                "&action=" + action +
                "&scope=" + scope,
            {},
            {
                responseType: "json"
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(userId, record, notification, action, scope);           
                }
                dispatch({ type: "USERS_ACTIVITY_SUBSCRIPTIONS_MAINT_SUCCESS", payload: {record: record, notification: notification, action: action, scope: scope} });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error, userId, record, notification, action, scope);
                }
            }
        );
    }
}