import React, { useState } from "react";
import moment, { Moment } from "moment";
import {
  Form,
  Card,
  Row,
  Col,
  DatePicker,
  TimePicker,
  Select,
  Input,
} from "antd";
import { FilterFormProps } from "./FilterFormContainer";
import { WidgetFilterConfig } from ".";
import { exportValidateForms, validateForms } from "../widgets/common";
import { appConfigs } from "../../../utils/configurations";

import { connect} from "react-redux";
import * as rootActions from "../../../actions";


const datapointValues = appConfigs.app.assetOverview.datapointlimits.map(
  (d) => ({
    value: d,
    label: d,
  })
);

function sanitizeInputs(c: WidgetFilterConfig): WidgetFilterConfig {
  return {
    startDate: c.startDate,
    endDate: c.endDate,
    startTime: c.startTime,
    endTime: c.endTime,
    datapoint: c.datapoint,
  };
}

const FilterForm: React.FC<FilterFormProps & {context: any}> = (props) => {
  const [datapoint, setDatapoint] = useState(props.config?.datapoint || 50);

  const initialValues: WidgetFilterConfig = {
    startDate: props.config?.startDate ? props.config?.startDate : moment().subtract(1, "days"),
    startTime: props.config?.startTime ? props.config?.startTime : moment().subtract(1, "days"),
    endDate: props.config?.endDate ? props.config?.endDate : moment(),
    endTime: props.config?.endTime ? props.config?.endTime : moment(),
    datapoint: props.config?.datapoint
  };

  const onStartTimeChange = (time: Moment | null) => {
    if (!props.form.getFieldValue("startDate"))
      props.form.setFieldsValue({ startDate: moment() });
  };

  const onEndTimeChange = (time: Moment | null) => {
    if (!props.form.getFieldValue("endDate"))
      props.form.setFieldsValue({ endDate: moment() });
  };

    const onDateChange = (date: Moment | null, timeFieldName: string) => {
        props.form.setFieldsValue({ [timeFieldName]: (date ? moment(date) : null) });
    };
    
  return (
    <div className="text-widget-edit-form">
      <Form
        onFinish={(values) =>
          props.onFinish(sanitizeInputs(values as WidgetFilterConfig))
        }
        layout="vertical"
        form={props.form}
        initialValues={initialValues}
      >
        <Card title="Filter Widget">
          <Row></Row>
          <div style={{ color: 'blue' }}>
              <span>Allowed to select dates within last 90 days</span>
          </div>
          <Row justify="space-between" style={{ width: "95%" }}>
            <Col span={24}>
              <Form.Item label="Start Date">
                <Input.Group compact>
                  <Form.Item
                    noStyle
                    name="startDate"                    
                    dependencies={["startTime", "endDate", "endTime"]}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(rule, value) {

                          let allowed30DaysExport = [
                            'c6adcb40-be92-11e6-9ed6-a5bc9cb5279b', // Parker
                            '616c4c61-865d-11eb-871a-13cc80610287', // Komatsu Germany GmbH
                            'a09ad270-3b21-11e7-893e-f94b4bc7246b', // Taylor
                            "231cb420-f381-4b5a-ad42-ae13f347075a",	// "City of Calgary"
                            "eb785adc-16a9-40db-a331-dc92fde719e4",	// "NTM Global"
                            "e13ad975-82b1-4f3a-a6b2-a3368b011087",	// "Ohlssons AB"
                            "12ca9505-a866-4d4d-b05e-1f02edd87ff4",	// "NTM Canada"
                            "f2f3ced8-154b-4e0e-90b4-907b98e02a94",	// "NTM Finland"
                            "f9c815bb-3fe4-49a3-b6dc-46a5396e006b",	// "NTM Sweden"
                            "586986cc-99c2-418b-8383-23f9d07a0c27",	// "Customer1 Finland"
                            "925a4395-11c8-4497-9174-66ba6cf10bfc", // VIATEC   
                          ]
                          
                          if (allowed30DaysExport.includes(props.context.appContext.orgId)) {
                            return exportValidateForms(
                              value,
                              getFieldValue("endDate"),
                              getFieldValue("startTime"),
                              getFieldValue("endTime")
                            );
                          } else {
                            return validateForms(
                              value,
                              getFieldValue("endDate"),
                              getFieldValue("startTime"),
                              getFieldValue("endTime")
                            );
                          }

                          
                        },
                      }),
                    ]}
                  >
                    <DatePicker inputReadOnly 
                    disabledDate={(current) => {
                      return (!(moment().subtract(0, 'days')  >= current &&
                           moment().subtract(90, 'days')  <= current));
                      }}
                    onChange={(date) => onDateChange(date, "startTime")} />
                  </Form.Item>
                  <Form.Item noStyle name="startTime">
                    <TimePicker inputReadOnly onChange={onStartTimeChange} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="End Date">
                <Input.Group compact>
                  <Form.Item noStyle name="endDate">
                    <DatePicker inputReadOnly
                    disabledDate={(current) => {
                      return (!(moment().subtract(0, 'days')  >= current &&
                           moment().subtract(90, 'days')  <= current));
                      }}
                     onChange={(date) => onDateChange(date, "endTime")} />
                  </Form.Item>
                  <Form.Item noStyle name="endTime">
                    <TimePicker inputReadOnly onChange={onEndTimeChange} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" style={{ width: "95%" }}>
            <Col span={24}>
              <Form.Item name="datapoint" label="Datapoint Limit">
                <Select
                  style={{ width: "95%" }}
                  onChange={(v) => setDatapoint(v)}
                  value={datapoint}
                  options={datapointValues}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};


const mapStateToProps = (state:any) => {
  return {
      context: state.contextReducer.context,
  };
};

export default connect(
  mapStateToProps,
  { 
      ...rootActions,
  }
)(FilterForm);