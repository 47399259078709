import Axios from "axios";
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";

export function orgsSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "ORGS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}   

export function orgsListSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "ORGS_LIST_SET_SEARCH", searchText: searchText });
    }
}   

export function orgsListSetDisplay(
    mode: string, 
    searchText: string,
    orgId: string,
    orgName: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "ORGS_LIST_SET_DISPLAY", mode: mode, searchText: searchText, orgId: orgId, orgName: orgName });
    }
}   

export function orgsMaintRefreshInstance(
    me: any,
    instances: number[] 
) {
    return (dispatch: any) => {
        dispatch({ type: "ORGS_MAINT_REFRESH_INSTANCE", instances: instances });
    }
}   

export function orgGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/orgs/" + orgId, {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.org);
                }
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error);
            }
            errorResponse(me, error);
        });
    };
}

export function getAllCapabilities(me: any, callbackFnSuccess: any, callbackFnFailure: any) {
    const capabilityCatgegoryId = 6
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/capabilities/capabilityByCategory/"+ capabilityCatgegoryId, {
                responseType: "json",        
                headers: {},
                params: {}
            }
        ).then(

            response => {
                let data = response.data == null || (response.data.length || 0) === 0 ? [] : response.data;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "ORGS_ALL_CAPABILITIES", payload: {data: data} });
            }
        ).catch(function (error){
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);

        }
        );
    }
}

export function capabilityAccess(me: any, callbackFnSuccess: any, callbackFnFailure: any, capabilitiesSelected:any[], OrgsSelected:any[], orgsCleared:any[]) {
    return async (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL + "/ui/api/capabilities/restrictCapabilities/" , {
                orgs: OrgsSelected && OrgsSelected.length>0?(OrgsSelected|| []).join("~"):"",
                capabilities: capabilitiesSelected && capabilitiesSelected.length>0?(capabilitiesSelected|| []).join("~"):"",
                clearedOrgs: orgsCleared && orgsCleared.length>0?(orgsCleared|| []).join("~"):""          
            },
            {
                responseType: "json",
                headers: {},
                params: {}
            }
        ).then(
            response => {
                if(callbackFnSuccess != null) {
                callbackFnSuccess();
            }
        }
            ).catch(function (error){
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            
        }
        );

    }
}

export function orgsGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    mode: string = "all",
    scope: string = "all",
    page: number = 1,
    searchText?: string,
    orgId?: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/orgs", {
                responseType: "json",        
                headers: {},
                params: {
                    mode: mode,
                    scope: scope,
                    includeTree: true,
                    orgId: orgId,
                    searchText: searchText,
                    page: page,
                    pageSize: appConfigs.tables.pageSize
                }
            }
        )
        .then(response => { 
                let data = response.data.data.orgs == null || (response.data.data.orgs.length || 0) === 0 ? [] : response.data.data.orgs;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data, mode);
                }
                dispatch({ type: "ORGS_GET_ORGS_SUCCESS", payload: {data: data, page: page} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function orgDelete(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: string
) {
    return async (dispatch: any) => {
        Axios.delete(
            appConfigs.server.URL + "/ui/api/orgs/" + orgId,
            {}
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(orgId);           
                }
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}


export function orgsListRefreshOnUpdate(
    me: any
) {
    return (dispatch: any) => {
        dispatch({ type: "ORGS_LIST_REFRESH_ON_UPDATE" });
    };
}

export function orgMaintOpen(
    me: any, 
    title: string,
    width: number,
    record: any
) {
    return (dispatch: any) => {
        let data = record;
        data.orgTags = record.orgTags?JSON.parse(record.orgTags): undefined;
        dispatch({ type: "ORGS_MAINT_OPEN", payload: {title: title, width: width, record: data} });
    };
}

export function orgMaintClose(
    me: any
) {   
    return (dispatch: any) => {
        dispatch({ type: "ORGS_MAINT_CLOSE" });
    };
}

export function orgMaintTabChange(
    me: any,
    tab: string
) {
    return (dispatch: any) => {
        dispatch({ type: "ORGS_MAINT_TAB_CHANGE", tab: tab });
    };
}

export function orgMaintTabsDisabled(
    me: any,
    state: string
) {
    return (dispatch: any) => {
        dispatch({ type: "ORGS_MAINT_TABS_DISABLED", state: state });
    };
}