import React from "react";
import { Drawer, Button, Space, Modal } from "antd";

import { connect } from "react-redux";
import * as actions from "./actions/templates";

import { appConfigs } from "../../utils/configurations";

import TemplateMaintInfo from "./templateMaintInfo/TemplateMaintInfo";
import TemplateDuplicate from "./templateDuplicate/TemplateDuplicate";
import TemplateCopyCollection from "./templateCopyCollection/TemplateCopyCollection";

interface Prop {
    templatesListRefreshOnUpdate: Function,
    getTemplates: Function,
    templateMaintClose: Function,    
    templatesList: any
}
interface State {
    isModalVisible: boolean,
    signalLength:number,
    isEnabledAllSignals:boolean,
    showSignalDuplicateModel:boolean
    showSignalCopyModel:boolean
}

class TemplateMaint extends React.Component<Prop, State> {  
   
    state: State = {
        isModalVisible: false,
        signalLength:1,
        isEnabledAllSignals:false,
        showSignalDuplicateModel:false,
        showSignalCopyModel:false
    };

    componentDidUpdate(prevProps: Prop) {
        if (this.props.templatesList.templateMaint.instance !== prevProps.templatesList.templateMaint.instance) {
        }
    }    

    onDrawerClose = () => {
        if (this.props.templatesList.refreshOnUpdate) {
            this.props.getTemplates(this, null, null, this.props.templatesList.tableConfig.pagination.current, this.props.templatesList.mode, this.props.templatesList.searchText);
        }
        this.props.templateMaintClose(this);
    };

    showModal = () => {
        this.setState((prevState) => (
            { 
            ...prevState, 
            isModalVisible: true,
            showSignalDuplicateModel:!prevState.isEnabledAllSignals,
            showSignalCopyModel:!prevState.showSignalCopyModel 
            }));
    };

    handleOk = () => {
        this.setState({ 
           isModalVisible: false,
        });
    };


    handleCancel = () => {
        this.setState({
            isModalVisible: false,
            showSignalDuplicateModel:false,
            showSignalCopyModel:false
        });
    }; 

    signalValidation=(signalLength:number,isEnabledAllSignals:boolean)=>{
        this.setState({signalLength:signalLength,isEnabledAllSignals:isEnabledAllSignals});
     }

    render() {
        
        const { templateMaint } = this.props.templatesList;
        return (
            <Drawer
                data-id="template-maint-drawer" 
                title={templateMaint.title}
                width={templateMaint.width}
                onClose={this.onDrawerClose}
                visible={templateMaint.visible}
                destroyOnClose={true}
                footer={
                    <Space size="large" style={{ float: 'right' }}>
                        <Button data-id="template-maint-close" type="default" onClick={this.onDrawerClose}>
                            {appConfigs.settings.drawers.closeButtonText}
                        </Button>
                        {
                            templateMaint.mode === "template" &&    
                            <Button data-id="template-maint-submit" type="primary" form="templateMaintInfoForm" key="submit" htmlType="submit">
                                {appConfigs.settings.form.submitButtonText}
                            </Button>
                        }
                        {
                            templateMaint.mode === "duplicate" && templateMaint.record != null 
                             && this.state.isEnabledAllSignals &&
                            <Button data-id="template-duplicate-submit" type="primary"
                             form="templateDuplicateForm" key="submit" htmlType="submit">
                                {appConfigs.settings.form.submitButtonText}
                            </Button>
                        }
                        {
                              templateMaint.mode === "duplicate" && templateMaint.record != null 
                              && !this.state.isEnabledAllSignals &&
                            <>
                            <Button data-id="template-duplicate-submit" type="primary"
                            key="submit" htmlType="submit" onClick={this.showModal} disabled={this.state.signalLength===0}>
                            {appConfigs.settings.form.submitButtonText}
                            </Button>

                            <Modal title="Confirm cloning subset of signals" visible={this.state.showSignalDuplicateModel} 
                            onOk={this.handleOk}
                            onCancel={this.handleCancel} 
                            footer={[
                            <Button onClick={this.handleCancel}>No</Button>,
                            <Button data-id="template-duplicate-submit" type="primary" form="templateDuplicateForm" key="submit" onClick={this.handleOk} htmlType="submit">
                                Yes
                            </Button>,
                            ]}
                            >
                            <p>Only selected signals will be cloned to the new template. Do you want to proceed?</p>                                   
                            </Modal>
                            </>
                        }

                        {
                              templateMaint.mode === "copy" && templateMaint.record != null 
                               && templateMaint.record.orgId != null && templateMaint.record.templateId != null &&
                            <>
                            <Button data-id="template-copy-submit" type="primary"
                            // key="submit" htmlType="submit" onClick={this.showModal} disabled={this.state.signalLength===0}>
                            key="submit" htmlType="submit" form="templateCopyCollectionForm" disabled={this.state.signalLength===0}>
                            {appConfigs.settings.form.submitButtonText}
                            </Button>

                            <Modal title="Confirm copy collection subset of signals" visible={this.state.showSignalCopyModel} 
                            onOk={this.handleOk}
                            onCancel={this.handleCancel} 
                            footer={[
                            <Button onClick={this.handleCancel}>No</Button>,
                            <Button data-id="template-copy-submit" type="primary" form="templateCopyCollectionForm" key="submit" onClick={this.handleOk} htmlType="submit">
                                Yes
                            </Button>,
                            ]}
                            >
                            <p>Only selected signals will be copied to the destination template. Do you want to proceed?</p>                                   
                            </Modal>
                            </>
                        }
                        {/* {   
                         templateMaint.mode === "duplicate" && templateMaint.record != null && templateMaint.record.remoteControl === 1 && 
                        <>
                        <Button type="primary" disabled={this.state.signalLength===0} onClick={this.showModal}>
                            {appConfigs.settings.form.submitButtonText}
                        </Button>
                        <Modal title="Confirm to duplicate a subset of command" visible={this.state.isModalVisible} 
                        onOk={this.handleOk}
                        onCancel={this.handleCancel} 
                        footer={[
                            <Button onClick={this.handleCancel}>No</Button>,
                            <Button data-id="template-duplicate-submit" type="primary" form="templateDuplicateForm" key="submit" onClick={this.handleOk} htmlType="submit">
                                Yes
                            </Button>,
                            ]}
                        >
                            <p>Only a subset of the commands allowed by the target org will be duplicated. Are you sure to duplicate the template?</p>                                   
                        </Modal>
                        </>
                        } */}
                    </Space>
                }
            >
                {
                    templateMaint.mode === "template" &&                 
                    <TemplateMaintInfo />   
                }
                {
                    templateMaint.mode === "duplicate" &&                 
                    <TemplateDuplicate onDrawerClose={this.onDrawerClose} signalValidation={this.signalValidation}/>   
                }
                {
                    templateMaint.mode === "copy" &&                 
                    <TemplateCopyCollection onDrawerClose={this.onDrawerClose} signalValidation={this.signalValidation} closePopUp={this.handleCancel}/>   
                }
            </Drawer>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        templatesList: state.templatesReducers.templatesState.templatesList
    };
};

export default connect(
    mapStateToProps,
    actions
)(TemplateMaint);