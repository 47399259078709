import { appConfigs } from "../../../utils/configurations";

export const initialState = {
  scheduledList: {
    dateFormatOptions: {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    },
    scheduledMaint: {
      instance: 0,
      width: 720,
      visible: false,
      record: {},
      title: "",
      orgs: [],
      reports: [],
      users: [],
      mode: "",
      selectedTag: [],
      selectedOrgTag: [],
      selectedOrgId: "",
      scheduleAddEdit: {
        mode: "",
        scheduleId: -1,
        name: "",
        frequency: "",
        orgId: "",
        subscribers: [],
        dayOfMonth: "",
        dayOfWeek: "",
        reports: [],
        timeZone: "",
        timeZoneOffset: 0,
        clientScheduledTime: "",
        scheduledTime: "",
      },
    },
    searchText: "",
    refreshOnUpdate: false,
    tableData: [],
    tableConfig: {
      pagination: {
        showSizeChanger: appConfigs.tables.showSizeChanger,
        showQuickJumper: appConfigs.tables.showQuickJumper,
        pageSize: appConfigs.tables.pageSize,
        defaultCurrent: appConfigs.tables.defaultCurrent,
        current: appConfigs.tables.defaultCurrent,
        total: appConfigs.tables.defaultTotal,
        hideOnSinglePage: appConfigs.tables.hideOnSinglePage,
        responsive: appConfigs.tables.responsive,
        onChange: null,
        showLessItems: appConfigs.tables.showLessItems,
        showTotal: (total: number, range: Array<number>) =>
          `${range[0]} - ${range[1]} of ${total} record${total > 1 ? "s" : ""}`,
      },
    },
  },
};
