import React from "react";
import { Form, Card,Input,Typography } from "antd";

import {
  EditFormProps,
  Container,
  WidgetTitleFormField,
  SignalSelectorList,
} from "../../WidgetEditForm";
import { Config } from ".";
import TextArea from "antd/lib/input/TextArea";
const { Text } = Typography;
function sanitizeInputs(c: Config): Config {
  //console.log("sanitizeInputs c",c)
  return {
    title: c.title,
    signals: c.signals?.filter((s) => !!s),
    type: c.type?c.type:"information",
    informationValue:c.informationValue
  };
}

const EditForm: React.FC<EditFormProps> = (props) => {
  return (
    <div className="text-widget-edit-form">
      <Form
        onFinish={(values) => props.onFinish(sanitizeInputs(values as Config))}
        layout="vertical"
        form={props.form}
        initialValues={props.config}
      >
        <Card title="Information Widget Settings">
          <WidgetTitleFormField />
         
          <Form.Item
            name="informationValue"
            label="Enter Text here"
            rules={[{ whitespace: true }]}
          >
            <TextArea maxLength={255} style={{ resize: 'none' }} rows={3}/>
          </Form.Item>
          <Form.Item
            name="warnintText"
            rules={[{ whitespace: true }]}
          >
            <Text type="warning">Maximum allowed text only 255 characters.</Text>
          </Form.Item>
        </Card>
      </Form>
    </div>
  );
};

export default EditForm;
