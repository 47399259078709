import { ServiceTruck } from "../../FleetOverview2/models";
import { produce as immerProduce } from "immer";
import { initialState } from "./state";
import moment from "moment";

export function serviceTruckState(state = initialState, action: any) {
	let data: any = null,
		payload: any;

	switch (action.type) {
		case "GET_SERVICE_TRUCKS_SUCCESS":
			return immerProduce(state, draft => {
				let geoTabData = JSON.parse(action.assets.geoTabData)
				let serviceTrucks = action.assets.serviceTrucks

				/* adding meta data to geotab (operatorName and OrgId) */
				geoTabData = geoTabData.map((geoTab: any) => {
					serviceTrucks.map((serviceTruck: any) => {
						if (serviceTruck['vin'] == geoTab['VIN']) {
							geoTab['orgId'] = serviceTruck['orgId'];
							geoTab['operatorName'] = moment(serviceTruck['updatedAt']).isAfter(geoTab['dateTime']) ? serviceTruck['operatorName'] : geoTab['driver'];
						}
						else
            			{
              				geoTab['operatorName'] = geoTab['driver'];
            			}
					})

					return geoTab as ServiceTruck;
				})
				

				draft.serviceTruckList.assets = geoTabData;
			})
		case "SET_SERVICE_TRUCK_DRAWER_OPEN":
			return immerProduce(state, (draft) => {
				draft.serviceTruckListDrawerVisible = true;
			});
		case "SET_SERVICE_TRUCK_DRAWER_CLOSE":
			return immerProduce(state, (draft) => {
				draft.serviceTruckListDrawerVisible = false;
			});
		case "SET_SERVICE_TRUCK_MAINT_DRAWER_OPEN":
			return immerProduce(state, (draft) => {
				draft.serviceTruckMintDrawerVisible = true;
				draft.serviceTruckMaint = action.payload.ServiceTruck
			});
		case "SET_SERVICE_TRUCK_MAINT_DRAWER_CLOSE":
			return immerProduce(state, (draft) => {
				draft.serviceTruckMintDrawerVisible = false;
				draft.serviceTruckMaint = {}
			});
		case "UPDATE_SERVICE_TRUCK_SUCCESS":
			return immerProduce(state, (draft) => {

				let refreshedList = action.params.assets.map((ServiceTruck: ServiceTruck) => {
					if (ServiceTruck.VIN == action.params.vin) {
						return action.params.serviceTrucks
					}
					return ServiceTruck
				});

				draft.serviceTruckList.assets = refreshedList
				draft.serviceTruckMaint = action.params.serviceTrucks

			});
		case "SET_SERVICE_TRUCK_MAP":
			return immerProduce(state, (draft) => {
				draft.map = action.payload.map;
			});
		default:
			return state;
	}
}
