import React, { CSSProperties } from "react";
import { Row, Col, Tooltip } from "antd";
import {
    CriticalIcon,
    WarningIcon,
    DM1Icon,
    InfoIcon,
    OnlineIcon,
    OfflineIcon,
    Icon,
    AssetDown
} from "../../icons";

import { Filters } from "./models";

import "../../fleetOverview.css";

function filterIconStyle(active?: boolean): CSSProperties {
    return active ? 
        {
            backgroundColor: "white",
            opacity: 1
        } : 
        {};
}

interface TitleBarProps {
    filters: Filters;
    toggleFilters: (k: keyof Filters) => void;
    assetCount: number;
}

interface FilterButtonProps {
    onClick: () => void;
    active: boolean;
    Icon: Icon;
    label: string;
}

const FilterButton: React.FC<FilterButtonProps> = (props) => (
    <Col span={3}>
        <Tooltip title={props.label}>
            <div>
                <props.Icon
                    onClick={props.onClick}
                    style={filterIconStyle(props.active)}
                />
            </div>
        </Tooltip>
    </Col>
);

const FiltersBar: React.FC<TitleBarProps> = (props) => {
    const { filters } = props;

    const toggleFilter = (f: keyof Filters) => {
        return () => props.toggleFilters(f);
    };

    return (
        <div
            data-id="map-filters-bar"
        >
            <Row
                justify="space-around"
                align="middle"                
            >
                <FilterButton
                    Icon={CriticalIcon}
                    active={filters.critical}
                    onClick={toggleFilter("critical")}
                    label="Critical Alerts"
                />
                <FilterButton
                    Icon={WarningIcon}
                    active={filters.warning}
                    onClick={toggleFilter("warning")}
                    label="Warning Alerts"
                />
                <FilterButton
                    Icon={InfoIcon}
                    active={filters.info}
                    onClick={toggleFilter("info")}
                    label="Info Messages"
                />
                <FilterButton
                    Icon={DM1Icon}
                    active={filters.dm1}
                    onClick={toggleFilter("dm1")}
                    label="DM1s"
                />
                <FilterButton
                    Icon={OnlineIcon}
                    active={filters.online}
                    onClick={toggleFilter("online")}
                    label="Online"
                />
                <FilterButton
                    Icon={OfflineIcon}
                    active={filters.offline}
                    onClick={toggleFilter("offline")}
                    label="Offline"
                />
                <FilterButton
                    Icon={AssetDown}
                    active={filters.asset_down}
                    onClick={toggleFilter("asset_down")}
                    label="Asset Down"
                />
            </Row>
        </div>
    );
};

export default FiltersBar;
