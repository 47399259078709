import Axios from "axios";
import { errorResponse } from "../../../../utils/apiHelpers/apiHelpers";
import { appConfigs } from "../../../../utils/configurations";

export function getVideoTutorials() {
    
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/get_tutorials"
        )
        .then(response => { 
                dispatch({ type: "VIDEOTUTORIALS_GET_SUCCESS", data: response.data.data.tutorials });
                console.log(response.data.data.tutorials);
            }
        )
        .catch(function (error) {
            console.log(`error`, error)
        });
    };
}

export function getTutorial(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    tutorialId: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/tutorial/id/" + tutorialId, {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.operator);
                }
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error);
            }
            errorResponse(me, error);
        });
    };
}

export function tutorialsSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "TUTORIAL_SET_SEARCH", searchText: searchText });
    }
}  

export function setTab(
    tabNumber: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "SET_TAB", tabNumber: tabNumber });
    }
}   
