import React from "react";
import { Tooltip } from "antd";
import { ReactComponent as OfflinePin } from "../../assets/images/map-pins/offline-pin.svg";
import { ReactComponent as OnlinePin } from "../../assets/images/map-pins/online-pin.svg";

interface Props {
  assetStatus: boolean;
}
const AssetStatus: React.FC<Props> = ({ assetStatus }) => {
  if (assetStatus) {
    return (
      <Tooltip title="Online">
        <OnlinePin style={{ verticalAlign: "middle" }} />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title="Offline">
        <OfflinePin style={{ verticalAlign: "middle" }} />
      </Tooltip>
    );
  }
};

export default AssetStatus;
