import React from "react";
import { connect } from "react-redux";
import { Form, Input, Typography, Select, Table, Checkbox, Row, Col, Tooltip, Button, message, Switch } from 'antd';
import { PlusOutlined, EllipsisOutlined, UserDeleteOutlined, EditOutlined, DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { FormInstance } from 'antd/lib/form';
import { appConfigs } from "../../../utils/configurations";
import { getPermissions, getPermissionsList, permissionsList, convertBinaryToValue, rolespermissionsList} from "../../../utils/commonHelpers";
import { successResponse, errorResponse, resetFormErrors } from "../../../utils/apiHelpers/apiHelpers";
import { FormState, formInit } from "../../../utils/apiHelpers/formHelpers";
import { SuccessAlert, ErrorAlert } from "../../../utils/Components/formAlerts";
import { closePopover } from "../../../utils/commonHelpers";
import * as actions from "../actions";
import "./roleMaintInfo.css";
import * as common from '../common';
import history from "../../../utils/history";

const { Option } = Select;

interface permissionsList {
    permissionId: number;
    permissions: number[];
}

interface Prop { 
    roleMaint: Function,
    getRolePermissions: Function,   
    getOrgRolesMaint: Function,
    rolePermissionMaint: Function,
    getOrgsMaint: Function,
    rolesMaintClose: Function, 
    getSubmitButton: Function,  
    context: any,
    roleList: any,
    rolePermissionList: any,
    permissions: any,   
    customerOrgPermissions: any,
    issuborgsread: boolean
}

interface State {
    selectedRowKeys?: any[];
    formError?: boolean;
    formErrorMessage?: string[];
    formSuccess?: boolean;
    formSuccessMessage?: string;
    formCommit?: boolean;   
    orgId: string;
    roleId: string;
    roleName: string;
    isRoleAvailable: boolean;
    permissionsAddList: permissionsList[];
    mode: string;
}

class RolePermissionMaintInfo extends React.Component<Prop, State> {

    state:State = {
        orgId: "", 
        roleId: "",
        roleName: "",
        isRoleAvailable: false,
        permissionsAddList: [],
        mode:"add"
    }
    
    drawerWidth:number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);
    formRef = React.createRef<FormInstance>();
    
    componentDidMount() {
       
        const { appContext } = this.props.context;
        this.setState({roleName: appContext.roleName, orgId: appContext.orgId});
       
        let permissionList = [];
        permissionList = rolespermissionsList(this.props.roleList.tableData);
        this.state.roleId = this.props.roleList.roleMaint.record.roleId;
        this.setState({permissionsAddList: permissionList}); 
        this.props.getOrgsMaint(null, null, null, null, null);      
        this.componentUpdate(this.props.roleList.roleMaint.record);
    }
    
    componentDidUpdate(prevProps: Prop, prevState: any) { 
        
          if(prevState.roleName !== this.state.roleName) {
            let permissionList = [];
            permissionList = rolespermissionsList(this.props.roleList.tableData);
            this.setState({permissionsAddList: permissionList});
            
        }
        
        if (this.props.rolePermissionList.rolePermissionMaint.instance !== prevProps.rolePermissionList.rolePermissionMaint.instance) {
            this.componentUpdate(this.props.rolePermissionList.rolePermissionMaint.record);
        }       
    }

    componentUpdate(record: any ) {
       
        formInit(this);
        if (this.formRef.current != null) {
            this.formRef.current!.resetFields();
            this.formRef.current!.setFieldsValue({                

            })
        }
    }

    onFinish = (values:any) => {
        if(values.roleName.toLowerCase() !== "admin" && values.roleName.toLowerCase() !== "guest" && values.roleName.toLowerCase() !== "user" && values.roleName.toLowerCase() !== "super admin"){
            // let roleIdVal; 
            // if((this.props.roleList.roleMaint.record.roleId || "") !== ""){
            //     this.setState({mode: "update"});
            //     this.state.roleId =  this.props.roleList.roleMaint.record.roleId;
            //     roleIdVal = this.state.roleId;
            // } else {
            //     roleIdVal = null;
            // }
            let permissions = convertBinaryToValue(this.state.permissionsAddList);
            let permissionObject = { mode: this.state.mode, roleName: values.roleName, description: values.description,
                                     isRoleAvailable: this.state.isRoleAvailable, isActive: true, permissions: permissions
                                   };
            let orgId = values.orgId === undefined ? this.state.orgId : values.orgId;

            this.props.rolePermissionMaint(this, this.onFinishSuccess, this.onFinishFailure, permissionObject, orgId);
        }
        else
        {
            message.error("Add will not be allowed to " + values.roleName + " role.");
        }

    };

    onFinishSuccess = (recordOld:any) => {
        this.props.getSubmitButton(false);
        successResponse(this, "Role and Permissions added successfully.");
        // const me = this;
        // setTimeout(function () {           
        //     me.props.rolesMaintClose();
        //     history.push("/roles");
        // }, 1500); 
    }

    onFinishFailure = (error: any) => {        
        errorResponse(this, error);
    }

    onRoleAvailableChange = (checked:any) =>{   
        this.props.getSubmitButton(true);     
        this.setState({isRoleAvailable: checked});
    }

    onSubmitEnable = () => {
        this.props.getSubmitButton(true);
    }

    onCheckedChange = (e: any, permissionId: number) => { 
        this.props.getSubmitButton(true);
        const permissionType = +e.target.id.split(':')[1];
        this.setState({
            permissionsAddList: this.state.permissionsAddList.map((p: any) => {
            if (p.permissionId === permissionId) {
              p.permissions[permissionType] = +e.target.checked;
              return p;
            } else {
              return p;
            }
          }),
        });
    };


    checkMaxPermissions = (record: any, operation: string) => {
        //ops-2689
        //const { customerOrgPermissions, context } = this.props;
        const { roleList, context } = this.props;
        
        const orgId = context.appContext.orgId;
        //ops-2689
        //return common.checkMaxPermissions(record, operation, customerOrgPermissions, orgId);
        return common.checkMaxPermissions(record, operation, roleList.tableData.data.permissions, orgId);
        
    }

   
    render() {        
        const { appContext } = this.props.context;       
        const { tableData } = this.props.roleList;
        const { orgs } = this.props.roleList.roleMaint;
       
        let permissionList = [];
        
        for (let i=0; i<tableData.data.permissions.length; i++){
            //ops-2689
            // let permission = { permissionId:tableData.data.permissions[i].permissionId, 
            //     entityName:tableData.data.permissions[i].entity, 
            //     create:false, read:false, update:false, remove: false
            // };

            if(tableData.data.permissions[i].permissionLevel > 0){
                let permission = { permissionId:tableData.data.permissions[i].permissionId, 
                    entityName:tableData.data.permissions[i].entity, 
                    create:false, read:false, update:false, remove: false
                };
                permissionList.push(permission);
            }
        }
        
        const { permissionsAddList } = this.state;
                
        const tableColumns = [
            {
            title: "Permissions",
            dataIndex: "entityName",
            width: "50%",
            },
            {
            title: "View",
            dataIndex: "read",
            key: "read",
            width: "12%",
            render: (read: any, record: any) => (
                <>
                {
                    this.checkMaxPermissions(record, "View") &&                
                    <Checkbox
                        id={`${record.permissionId.toString()}:1`}
                        key={`${record.permissionId.toString()}:1`}
                        checked={
                            permissionsAddList.find(
                            (p: any) => p.permissionId === record.permissionId
                        )?.permissions[1] === 1
                            ? true
                            : false
                        }                    
                        onChange={(e: any) =>
                        this.onCheckedChange(e, record.permissionId)
                        }
                    ></Checkbox>
                }
                </>
            ),
            },
            {
            title: "Add",
            dataIndex: "create",
            key: "create",
            width: "12%",
            render: (create: any, record: any) => (
                <>
                {
                    this.checkMaxPermissions(record, "Create") &&  
                    <Checkbox
                    id={`${record.permissionId.toString()}:0`}
                    key={`${record.permissionId.toString()}:0`}
                    checked={
                        permissionsAddList.find(
                        (p: any) => p.permissionId === record.permissionId
                    )?.permissions[0] === 1
                        ? true
                        : false
                    }
                    onChange={(e: any) =>
                    this.onCheckedChange(e, record.permissionId)
                    }                    
                ></Checkbox>
                }
                
                </>
            ),
            },
            {
            title: "Edit",
            dataIndex: "update",
            key: "update",
            width: "12%",
            render: (update: any, record: any) => (
                <>
                {
                    this.checkMaxPermissions(record, "Update") &&
                    <Checkbox
                    id={`${record.permissionId.toString()}:2`}
                    key={`${record.permissionId.toString()}:2`}
                    checked={
                        permissionsAddList.find(
                        (p: any) => p.permissionId === record.permissionId
                    )?.permissions[2] === 1
                        ? true
                        : false
                    }
                    onChange={(e: any) =>
                    this.onCheckedChange(e, record.permissionId)
                    }
                
                ></Checkbox>
                }
                
                </>
            ),
            },
            {
                title: "Delete",
                dataIndex: "remove",
                key: "remove",
                width: "12%",
                render: (remove: any, record: any) => (
                    <>
                    {                        
                        this.checkMaxPermissions(record, "Delete") &&
                        <Checkbox
                        id={`${record.permissionId.toString()}:3`}
                        key={`${record.permissionId.toString()}:3`}
                        checked={
                            permissionsAddList.find(
                            (p: any) => p.permissionId === record.permissionId
                        )?.permissions[3] === 1
                            ? true
                            : false
                        }
                        onChange={(e: any) =>
                        this.onCheckedChange(e, record.permissionId)
                        }
                    
                    ></Checkbox>
                    }                    
                    </>
                )
            },
        ];

        return (
            <div data-id="role-add-maint-info">
                {this.state.formSuccess && !this.state.formError && <SuccessAlert data-id="role-maint-info-success-message"  message={this.state.formSuccessMessage}/>}
                {this.state.formError && !this.state.formSuccess && <ErrorAlert data-id="role-maint-info-error-message" message={this.state.formErrorMessage}/>}
                {/* {this.state.formError && !this.state.formSuccess && 
                this.state.formErrorMessage && this.state.formErrorMessage.length > 0 &&
                 <ErrorAlert data-id="role-maint-info-error-message" message={this.state.formErrorMessage}/>} */}
                
                
                {/* <Row gutter={8} style={{marginTop: 15}}>
                    <Col offset={1} span={23}> */}
                    <Form
                        {...appConfigs.settings.formLayout.mainLayout}
                        id="roleAddMaintInfoForm"
                        ref={this.formRef}
                        onFinish={this.onFinish}
                        className="mt-15"
                    >
                        {
                            (this.props.issuborgsread) &&
                            <Form.Item
                                name="orgId"
                                label="Org Name"        
                            >                    
                                <Select 
                                    data-id="roles-maint-info-org-id"
                                    defaultValue= {this.props.context.appContext.orgId}   
                                    showSearch
                                    showArrow={true}
                                    allowClear
                                    placeholder="Select..."
                                    optionFilterProp="children"
                                    className="select-before"  
                                    onChange={this.onSubmitEnable}
                                >
                                    {orgs !== null && orgs.map((record:any, index:number) => (
                                        <Option key={record.orgId} value={record.orgId}>
                                            {record.name}
                                        </Option>
                                    ))}
                                </Select>                         
                            </Form.Item>
                            
                        }
                        {
                            !(this.props.issuborgsread) &&
                            <Form.Item                                 
                                 label="Org Name"
                            >
                                <span>{this.props.context.user.homeOrg.name}</span>
                            </Form.Item>
                        }
                        
                        <Form.Item
                            label="Role Name"
                            name="roleName"                                   
                            shouldUpdate={true}                                 
                            rules={[
                                { required: true, message: appConfigs.errors.fieldErrors.valueRequired },
                                // { validator:(_, value) => (value.length || 0) > 1 ? Promise.resolve() : Promise.reject(appConfigs.errors.fieldErrors.atLeastTwoCharacters) }
                            ]}                            
                        >
                            <Input maxLength={200} onChange={this.onSubmitEnable}/>
                        </Form.Item>
                    
                        <Form.Item
                            label="Description"
                            name="description"
                        >
                            <Input maxLength={255} onChange={this.onSubmitEnable} />
                        </Form.Item>
                    
                        <Form.Item 
                            label="Can Sub Orgs use this Role?"                                 
                            name="isRoleAvailable"
                            valuePropName="checked"
                            style={{ width: "1000px" }}
                        >
                            <Switch  defaultChecked={false}
                                onChange={this.onRoleAvailableChange}
                            />
                        </Form.Item>
                           
                    </Form>
                {/* </Col>
            </Row>   */}
               <div className="mt-15">
                <Table
                  data-id="dashboard-roles"
                  rowKey={(record) => record.permissionId}
                  columns={tableColumns}
                  dataSource={permissionList}
                />
              </div> 
        </div>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        permissions: state.contextReducer.data,   
        roleList: state.rolesReducer.rolesState.roleList,
        rolePermissionList: state.rolesReducer.rolesState.rolePermissionList,
        customerOrgPermissions: state.rolesReducer.rolesState.customerOrgPermissions
    };
};

export default connect(
    mapStateToProps,
    actions
)(RolePermissionMaintInfo);