import React, { useEffect, useState, useCallback, CSSProperties, useRef, useContext } from "react";
import ReactDOMServer from 'react-dom/server'
import { Popover, Tooltip, Button, Row, Col, Card, Input, Divider, Tag, Form, message, Select } from "antd";

import Icon, { BorderOuterOutlined, CloseOutlined, FilterOutlined, RadarChartOutlined, UnorderedListOutlined } from "@ant-design/icons";
import onlineIcon from "../../assets/images/map-status/online.png";
import offlineIcon from "../../assets/images/map-status/offline.png";
import criticalIcon from "../../assets/images/map-status/critical.png";
import warningIcon from "../../assets/images/map-status/warning.png";
import infoIcon from "../../assets/images/map-status/info.png";
import dm1Icon from "../../assets/images/map-status/dm1.png";

import { connect } from "react-redux";
import * as actions from "./actions";
import history from '../../utils/history';
import moment from "moment"
import tzlookup  from "tz-lookup";

import "./MapToolTip.css";
import { formatGps, formatSignals } from "../../utils/commonHelpers";
import { getTopThreeGeoTabAssets } from "../ServiceTrucks/serviceTrucksHelper";
import getAssetIcon, { assetsIcons } from "./VehicleIcons";
import { errorResponse, resetFormErrors, successResponse } from "../../utils/apiHelpers/apiHelpers";

import TextArea from "antd/lib/input/TextArea";
import { appConfigs } from "../../utils/configurations";
const { Option } = Select;
function displayCount(count: number) {
    return count < 100 ? count : "99+";
}

interface Position {
    lat: number;
    lng: number;
}

interface Props {  
    context: any,
    fleetOverviewState: any,
    serviceTruckState: any,
    permissions: any,
    toggleMarkerToolTip: any,
    position?: Position
    getPreferedSignals: Function,
    updateToolTipAssetDetails: Function,
    updateToolTipOverlay: Function,
    submitAssetDown: Function,
    getFleetOverview: Function
}

const goToAlerts = (
    e: any, mastertag: 
    string, alertType: string, 
    status: string, 
) => {
    e.preventDefault();
    history.push({
        pathname: "/alerts",
        state: [{ 
            mode: alertType, 
            mastertag: mastertag,
            status: alertType === "signal-alarms" ? status : ""
        }]
    });    
}; 

const AssetStatusBody = (props:any) => {
    return (
        <>
            <img style={{marginTop: "-3px"}} src={props.icon} alt="" title={props.status === "Critical"? "Critical Alerts":props.status === "Warning"? "Warning Alerts": props.status==="Info"? "Info Messages": props.status==="dm1"? "DM1s": props.status}/>
            <span style={{marginLeft: "5px"}}> {displayCount(props.count)} </span>
        </>
    );
};

interface AssetStatusProps {
    context: string;
    mastertag: string;
    alertType: string;
    status: string,
    icon: any;
    count: number;
}


const AssetStatus: React.FC<AssetStatusProps> = (props) => {
    return (
        <Col span={6}>
            <div className="map-assets-list-element">
                <a href="# " onClick={(e) => {goToAlerts(e, props.mastertag, props.alertType, props.status)}}> 
                    <AssetStatusBody
                        status={props.status}
                        icon={props.icon} 
                        count={props.count} 
                    />
                </a>
            </div>
        </Col>
    );
};

const GeotabAssetInfo = (props: any) => {
    return (
        <>
            {/* <Col span={12}><span><b>VIN:</b> {props.GeotabAsset.VIN.substring(props.GeotabAsset.VIN.length-6)}</span></Col> */}
            <Col span={18}><span> {props.GeotabAsset.operatorName?.length > 0 ? props.GeotabAsset.operatorName : `${props.GeotabAsset.VIN.substring(props.GeotabAsset.VIN.length - 6)} - (No Name Assigned)`}</span></Col>
            <Col span={6}><span> {props.GeotabAsset.distance.toFixed(2)} <span style={{ fontSize: '10px' }}>mi.</span></span></Col>
        </>
    );
}

function useOutsideClick(ref:any, props:any) {
    useEffect(() => {

        let moved:any;

        function handleClickOutside(event:any) {
            if (!moved) {
                if (ref.current && !ref.current.contains(event.target)) {
                    props.toggleMarkerToolTip(false)
                    props.fleetOverviewState.tooltip.overlay.setMap(null)
                }
            }
        }

        let downListener = () => { moved = false }
        let moveListener = () => { moved = true }

        document.addEventListener('mousedown', downListener)
        document.addEventListener('mousemove', moveListener)
        document.addEventListener('mouseup', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("mousemove", handleClickOutside);
            document.removeEventListener("mouseup", handleClickOutside);
        };

    }, [ref]);
}


let tooltipSubmenu:any = {
    iconSelection: false,
    reportAssetDown: false
}

const MapToolTip = (props:Props) => { 
    
    const wrapperRef = useRef(null);
    const inputRef = useRef<TextArea>(null);
    useOutsideClick(wrapperRef, props);

    const [assetDetails, setAssetDetails] = useState<any>();
    const [pinnedSingals, setPinnedSignals] = useState<any>(null);
    const [showSubMenu, setShowSubMenu] = useState<any>(tooltipSubmenu);
    const [sideMenuHeight, setSideMenuHeight] = useState(50);

    const [form] = Form.useForm();

    


    const showSubMenuVisibilty = (menuName: string) => {
        inputRef.current?.focus();
        let NewSubmenuState:any = {}

        setSideMenuHeight(document.getElementsByClassName("map-marker-tooltip")[0]?.clientHeight)

        for (const [key, value] of Object.entries(tooltipSubmenu)) {
            if(key === menuName){
                if(showSubMenu[menuName] === true){
                    NewSubmenuState[key] = false;
                } else {
                    NewSubmenuState[key] = true;
                }
            } else {
                NewSubmenuState[key] = false;
            }
        }

        setShowSubMenu(NewSubmenuState)
    }


    const submitAssetDown = (values: any) => {
        if(assetDetails.assetCustomFields?.additional_info === 'active' || values.description?.length>=5){
            values.description = assetDetails.assetCustomFields?.additional_info === 'active' ? '' : values.description
            values.assetId = assetDetails.id
            values.customFieldId = assetDetails.assetCustomFields ? assetDetails.assetCustomFields.id : 0
            values.clearDownStatus = assetDetails.assetCustomFields?.additional_info === 'active' ? 'inactive' : 'active'
            values.assetDownCategory = assetDetails.assetCustomFields?.additional_info === 'active' ? '' : values.category
            values.mastertag=assetDetails?.mastertag;
            values.orgId=assetDetails?.orgId;
            values.orgName=assetDetails?.orgName;
            values.assetName=assetDetails?.name || assetDetails?.mastertag;
            props.submitAssetDown(onFinishSuccess, onFinishFailure, values);
        }
        else{
            message.error("Please enter atleast 5 characters")
            inputRef.current?.focus();
            return
        }
    }
    const focusAddDescription=()=>{
        inputRef.current?.focus();
            return
    }
    const onFinishSuccess = (description:any) => { 
        showSubMenuVisibilty('reportAssetDown')
        props.getFleetOverview(null, null, 'map', null, 'useEffect')
        form.resetFields()  
        message.success("SUCCESS"); 
    }

    const onFinishFailure = (error: any) => { 
        console.log(error);
        message.error("Error while submitting, please try again later or contact system admin")
    }



    const closeOverlayToolTip = () => {
        props.toggleMarkerToolTip(false)
        setPinnedSignals(null)
        props.fleetOverviewState.tooltip.overlay.setMap(null)
        
    }

    const getPrefereSignalsSuccess = (response: any) => {
        const { assetId, signals = []} = response; 
        let assets: any;        
        if(signals.length > 0) { 
            setPinnedSignals(formatSignals(signals))       
        }
    }

    const getAssetDetails = (assetId:string) => {

        if(assetId){
            if (assetId.length === 36) {
                props.fleetOverviewState.fleet.filteredAssets.forEach(function(asset:any, index:number) {
                    if (asset.id === assetId) {
                        let tmpAsset = Object.assign({}, asset)
                        if(asset.assetCustomFields){
                            tmpAsset.location = tzlookup(asset.position.latitude, asset.position.longitude);

                            tmpAsset.assetCustomFields = JSON.parse(asset.assetCustomFields);
                            tmpAsset.zone=moment.tz(tmpAsset.location).format('z');
                            if(Number.isSafeInteger(parseInt(tmpAsset.zone))){
                                tmpAsset.zone=tmpAsset.location;
                            }
                        }
                        props.updateToolTipAssetDetails(tmpAsset)                              
                        setAssetDetails(tmpAsset)                              
                        props.getPreferedSignals(getPrefereSignalsSuccess, assetId);
                        props.fleetOverviewState.tooltip.overlay.draw();
                        
                        if(tmpAsset.assetCustomFields?.additional_info === 'active'){
                            form.setFieldsValue({
                                description: tmpAsset.assetCustomFields?.field_value,
                                category:tmpAsset.assetCustomFields?.additional_properties?.AssetDownCategory
                            });
                        }else{
                            form.resetFields();
                        }
                        
                        
                    }
                });
            }        
        }

    }

    useEffect(() => {
        getAssetDetails(props.fleetOverviewState.tooltip.marker?.id)
        
    }, [props.fleetOverviewState.tooltip.marker])



    const goToAsset = (
        e: any, 
        mastertag: string, 
    ) => {
        e.preventDefault();
        history.push("/assets/" + mastertag);
    };  
    
    const FindAssetTimeZone = (coordinates: any, updated_at: any) => {
        var locale_date_str = new Date(updated_at).toLocaleString("en-US",
            {
                timeZone: tzlookup(coordinates.latitude, coordinates.longitude)              
            });
            var geo_local_date_str = new Date(locale_date_str);
        return geo_local_date_str;
    }
    const AssetIconSelected = (icon: any) => {
        // console.log(icon);
    };
    const onCategoryClick = (e:any) => {
        e.nativeEvent.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        e.stopPropagation();
        e.preventDefault();
      };
    return (
        
            <div ref={wrapperRef} className="box map-marker-tooltip" >
                <Row style={{ }} justify="start" align="top" className={showSubMenu.iconSelection ? 'show-submenu' : 'hide-submenu'} >
                    <Col span={24}>
                        <Divider  style={{ margin: 0, padding: 0}} orientation="center" plain>
                            Select Asset Icon
                        </Divider>
                    </Col>
                    
                    <Col style={{marginTop: '5px'}} span={24}>
                        <Row justify="center" align="top">
                            {
                                Object.values(assetsIcons).map((icon:any) => {
                                    return (
                                        <Col key={icon.name} onClick={() => AssetIconSelected(icon.name)} className="icon-col" span={4}>
                                            <Icon style={{fontSize: '30px'}} component={icon.icon} />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>

                </Row>

                <Row style={{ }} justify="start" align="top" className={showSubMenu.reportAssetDown ? 'show-submenu' : 'hide-submenu'}>
                    <Col span={24}>
                        <Divider  style={{ margin: 0, padding: 0}} orientation="center" plain>
                            Flag Asset Down
                        </Divider>
                    </Col>

                    <Col style={{marginTop: '5px'}} span={24}>
                        <Row justify="center" align="top">
                            <Col className="asset-down-form-col" span={24}>
                                <Form
                                    form={form}
                                    onFinish={submitAssetDown}
                                    autoComplete="off"
                                    style={{padding: "0px", margin:"0px" }}
                                    initialValues={{
                                        ["category"]: "mechanical" 
                                      }}               
                                >
                                    <Form.Item  name="category" label="Category">
                    <Select
                    style={{width: "100%", fontSize:"15px"}}
                      onMouseDown={onCategoryClick}
                      disabled={
                        assetDetails?.assetCustomFields?.additional_info ===
                        "active"
                          ? true
                          : false
                      }
                    >
                      <Option value="mechanical">Mechanical</Option>
                      <Option value="accident">Accident</Option>
                      <Option value="other">Other</Option>
                    </Select>
                  </Form.Item>
                                    <Form.Item
                                        name="description"
                                        style={{padding: "0px", marginTop:"15px" }}
                                    >
                                        <TextArea 
                                            style={{width: "100%", fontSize:"15px", color:"rgba(0,0,0,.65)"}}                                            
                                            placeholder="Add Description (Mandatory)"
                                            disabled={assetDetails?.assetCustomFields?.additional_info === 'active' ? true : false}
                                            rows={3}
                                            maxLength={255}
                                            ref={inputRef}
                                            onClick={focusAddDescription}
                                            className="assetDownDesc"
                                        />
                                    </Form.Item>

                                    { assetDetails?.assetCustomFields?.additional_info === 'active'  &&
                                        <div style={{ marginTop: '-10px' }}>
                                            <span><b>Reporter Name:</b> {assetDetails?.assetCustomFields?.reportername}</span><br />

                                            <span><b>Reported At ({assetDetails.zone}):</b> {moment(FindAssetTimeZone(assetDetails?.position, assetDetails?.assetCustomFields?.updated_at)).format('MMM Do YYYY, h:mm:ss A')}</span>
                                       </div>
                                    }

                                    <Form.Item
                                        style={{padding: "0px", margin:"0px", paddingTop: '5px'}}
                                    >
                                        <Button
                                            htmlType="submit"
                                            block
                                            type="primary"
                                            danger
                                            style={{padding: "0px", margin:"0px",
                                            background: assetDetails?.assetCustomFields?.additional_info === 'active' ? '#1eb17f' : '#ff3333', borderColor: assetDetails?.assetCustomFields?.additional_info === 'active' ? '#1eb17f' : '#ff3333',}}
                                            disabled={!props.context.user.contextUserIsAdmin && assetDetails?.assetCustomFields?.additional_info === 'active'}
                                        >
                                            {
                                            assetDetails?.assetCustomFields?.additional_info === 'active' ? 'Remove Flag' : 'Add Flag'
                                            }
                                        </Button>
                                    </Form.Item>
                                    
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row justify="start" align="top" style={{padding: "0px", margin:"0px"}}>
                    <Col span={24}>
                        <Card 
                            size='small'
                            style={{ width: '100%' }}
                        >
                            <Tooltip title="Close">
                                <Button
                                    type="primary"
                                    danger
                                    shape="circle"
                                    onClick={closeOverlayToolTip}
                                    icon={<CloseOutlined />}
                                    style={{position: 'absolute', marginTop: '-30px', right: '-20px'}}
                                />
                            </Tooltip>  

                            <Row>
                                <Col className="tooltip-header" span={24}>
                                    <Row>
                                        <Col span={20}>
                                            <span className="map-tooltip-title" onClick={(e) => {goToAsset(e, assetDetails?.mastertag)}}>{assetDetails?.name || assetDetails?.mastertag} </span>
                                            <br />
                                            <span className="map-tooltip-org"> {assetDetails?.orgName} </span> 
                                        </Col>

                                        <Col /* onClick={() => showSubMenuVisibilty('iconSelection')} */ style={{ width: '100%'}} span={4} className="assets-list-connection-status">
                                            {/* <img width="35" src={onlineIcon} title="Online" alt=""/> */}
                                            {/* <img width="35" src={offlineIcon} title="Offline" alt=""/>    */}
                                            {
                                                assetDetails?.connectionStatus === "online" &&
                                                <Tooltip title="Asset is Online">
                                                    <Icon style={{fontSize: '30px', stroke:"#52D298", color: "transparent"}} component={getAssetIcon(assetDetails?.iconName)} />
                                                    
                                                </Tooltip>
                                            }
                                            {
                                                assetDetails?.connectionStatus !== "online" &&
                                                <Tooltip title="Asset is Offline">
                                                    <Icon style={{fontSize: '30px', stroke:"#bfbfbf", strokeWidth: 1, color: "transparent"}} component={getAssetIcon(assetDetails?.iconName)} />
                                                </Tooltip>                                 
                                            }
                                        </Col>

                                        { pinnedSingals && 
                                            <Col span={24}>
                                                {/* <Divider style={{ marginTop: '15px', padding: 0}} orientation="center" plain>
                                                    Pinned Signals List
                                                </Divider> */}
                                                <br />
                                                <span style={{ whiteSpace: "pre-line" }} className="map-tooltip-subtitle"> { pinnedSingals.split("<br/>").join("\n") } </span>
                                            </Col>
                                        }
                                    </Row>
                                        
                                        
                                </Col>

                                
                                    
                                {/* <Col style={{ marginTop: 0, padding: 0}} span={24}>
                                    <Divider  style={{ margin: 0, padding: 0}} orientation="center" plain>
                                        Alarms Details
                                    </Divider>
                                </Col> */}

                                <Col className="tooltip-links" span={24}>
                                    <Row gutter={8} className="assets-list-status-line">
                                        <AssetStatus
                                            context={props.context}
                                            mastertag={assetDetails?.mastertag}
                                            alertType="signal-alarms"
                                            status="Critical"
                                            icon={criticalIcon}
                                            count={assetDetails?.recentCriticalCount}
                                        />
                                        <AssetStatus
                                            context={props.context}
                                            mastertag={assetDetails?.mastertag}
                                            alertType="signal-alarms"
                                            status="Warning"
                                            icon={warningIcon}
                                            count={assetDetails?.recentWarningCount}
                                        />
                                        <AssetStatus 
                                            context={props.context}
                                            mastertag={assetDetails?.mastertag}
                                            alertType="signal-alarms"
                                            status="Info"
                                            icon={infoIcon} 
                                            count={assetDetails?.recentInfoCount} 
                                        />
                                        <AssetStatus 
                                            context={props.context}
                                            mastertag={assetDetails?.mastertag}
                                            alertType="dm1-faults"                                
                                            status="dm1"
                                            icon={dm1Icon} 
                                            count={assetDetails?.recentDm1Count} 
                                        />
                                                            
                                    </Row>
                                </Col>
                                
                                {   assetDetails && props.serviceTruckState.serviceTruckList.assets && (props.serviceTruckState.serviceTruckList.assets.length > 0) &&
                                    <>
                                        <Col style={{ marginTop: '2px', padding: 0}} span={24}>
                                            <Divider style={{ margin: 0, padding: 5}} orientation="center" plain>
                                                Nearest 3 Service Trucks
                                            </Divider>
                                        </Col>

                                    
                                        <Col style={{ marginTop: '2px', padding: 0}} span={24}>
                                            <Row className='closest-service-truck-list' >
                                                <Col span={18}><h4 style={{color: '#349beb'}}>Operator</h4></Col>
                                                <Col span={6}><h4 style={{color: '#349beb'}}>Distance</h4></Col>
                                                {
                                                    getTopThreeGeoTabAssets(assetDetails, props.serviceTruckState.serviceTruckList.assets).map((geotabAsset: any) => (
                                                        <GeotabAssetInfo GeotabAsset={geotabAsset} key={geotabAsset.VIN} />
                                                    ))
                                                }
                                            </Row>
                                        </Col>
                                    </>
                                }


                                <Col style={{ marginTop: '5px', padding: 0}} span={24}>
                                    <Divider style={{ margin: 0, padding: 5}} />
                                </Col>

                                <Col span={24} style={{padding: "0px", margin:"0px", paddingBottom: "2px"}}  >
                                    
                                    <Tooltip
                                    title={
                                        assetDetails?.assetCustomFields?.additional_info !== 'active' && "This action will mark the asset as down and necessitate the inclusion of an issue description for incident report"}
                                    >
                                        <Button
                                            block
                                            type="dashed"
                                            danger
                                            style={{padding: "0px", margin:"0px"}}
                                            onClick={() => showSubMenuVisibilty('reportAssetDown')}
                                        >
                                            { assetDetails?.assetCustomFields?.additional_info === 'active' ? (
                                                    <span>View Asset Down Info</span>
                                                ): (
                                                    <span>Report Asset Down</span> 
                                                )
                                            }
                                            
                                        </Button>
                                    </Tooltip>
                                </Col>

                            </Row>

                            

                            
                        </Card>
                    </Col>
                </Row>
                    
            </div>

    );

}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        fleetOverviewState: state.fleetOverviewReducers.fleetOverviewState,
        serviceTruckState: state.serviceTruckReducer.serviceTruckState,
        permissions: state.contextReducer.data,
    };
};

export default connect(
    mapStateToProps,
    actions
)(MapToolTip);