import React, { useEffect, useState } from "react";
import * as Api from "./api";
import { appConfigs } from "../../utils/configurations";

const getConnectionStatus = (response: any): boolean => {
  const errorresponseFilter = response.filter((res:any) => (res instanceof Error));
  const responseFilter = response.filter((res:any) => !(res instanceof Error));
  if(responseFilter.length > 0) {
    const deviceConnectedText = appConfigs.app.assetOverview.deviceconnectedtext;
    const responseData = response.map((res: any) => res.data) || [];
    const { signals } = responseData[0];
    if (signals) {
      const { value } = signals[0];
      if (value === deviceConnectedText) {
        return true;
      }
    }
  }  
  return false;
};

export const useAssetStatus = (gatewayId: string): boolean => {
  const [assetStatus, setAssetStatus] = useState<boolean>(false);
  const datapointId = [
    appConfigs.app.assetOverview.connectdisconnectdatapointid,
  ];
  useEffect(() => {
    if (gatewayId) {
      Api.loadSignalsData(gatewayId, datapointId)
        .then((response) => setAssetStatus(getConnectionStatus(response)))
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return assetStatus;
};
