import React from "react";

import { Modal, Button, Checkbox } from "antd";

import { connect } from "react-redux";
import * as actions from "./actions";

import { appConfigs } from "../../utils/configurations";
import { loadPage, getLoginUrl, loadingIndicatorStart, loadingIndicatorEnd, clientStorageClear } from "../../utils/commonHelpers";

import "./terms.css";

interface State {
    visible: boolean;
    acceptButtonDisabled: boolean;
    acceptCheckboxDisabled: boolean;
}

interface Prop {
    context: any,
    visible: boolean,
    text: string,

    acceptTermsAndConditions: Function
}

class Terms extends React.Component<Prop, State> {  

    state:State = {
        visible: false,
        acceptButtonDisabled: true,
        acceptCheckboxDisabled: true
    }

    componentDidMount () {        
        this.setState({ visible: this.props.visible });  
        if (this.props.visible) {
            this.setModal();     
        }
    }  

    componentWillUnmount() {
        this.cleanModal();
    }
     
    componentDidUpdate(prevProps: Prop) {
        if (this.props.visible !== prevProps.visible) {
            this.setState({ visible: this.props.visible });   
            this.setModal();    
        }        
    }    

    setModal = () => {
        const me = this;        

        setTimeout(function() {
            const 
                body = document.querySelectorAll(".ant-modal-body")[0] as HTMLElement,
                mask = document.querySelectorAll(".ant-modal-mask")[0] as HTMLElement;
            
            if (body !== undefined) {        
                mask.style.backgroundColor="#666";
                body.addEventListener("scroll", me.trackScrolling);
            }

            me.trackScrolling();
        }, 250);  

        setTimeout(function() {
            loadingIndicatorEnd();
        }, 1000);  
    }

    cleanModal = () => {
        const 
            body = document.querySelectorAll(".ant-modal-body")[0] as HTMLElement,
            mask = document.querySelectorAll(".ant-modal-mask")[0] as HTMLElement;

        if (body !== undefined) {        
            mask.style.backgroundColor="rgba(0, 0, 0, 0.45)";
            body.removeEventListener("scroll", this.trackScrolling);
        }
    }

    accept = (e: any) => {
        this.props.acceptTermsAndConditions(this, this.acceptTermsAndConditionsSuccess, null, this.props.context.termsAndConditions.templateId, this.props.context.termsAndConditions.orgId);
    }

    acceptTermsAndConditionsSuccess = () => {
        this.cleanModal();
        this.setState({ visible: false });           
    }

    reject = (e: any) => {
        e.preventDefault();

        const loginError = {
            "stop" : true, 
            "silent": true
        };
                
        const loginPage = getLoginUrl();
        clientStorageClear();
        loadingIndicatorStart();    
        //==>
        window.sessionStorage.setItem("loginError", JSON.stringify(loginError));          
        loadPage(loginPage);
    }

    isBottom() {
        const 
            body = document.querySelectorAll(".ant-modal-body")[0] as HTMLElement,
            bodyHeight = (body.scrollHeight - body.offsetHeight - 10);

        return bodyHeight >= 0 && body.scrollTop >= bodyHeight;
    }

    trackScrolling = () => {   
        if (this.isBottom()) {
            this.setState({ acceptCheckboxDisabled: false });   
        }
    }

    acceptCheckbox = (e: any) => {
        this.setState({ acceptButtonDisabled: !e.target.checked });   
    }

    render() {

        return (
            <Modal 
                wrapClassName="terms-and-conditions-accept-container"               
                title={
                    <>
                        <div className="ant-modal-title">{appConfigs.app.termsAndConditions.title}</div>
                        <div className="mt-10"><h5>{appConfigs.app.termsAndConditions.subTitle}</h5></div>
                    </>
                }
                visible={this.state.visible}
                footer={
                    <>
                        <div data-id="terms-and-conditions-accept-checkbox">
                            <Checkbox key="back" disabled={this.state.acceptCheckboxDisabled} onChange={this.acceptCheckbox}>I have read and accepted the Terms and Conditions</Checkbox>
                        </div>
                        <div className="mt-15">
                            <Button key="cancel" onClick={this.reject}>
                                Cancel
                            </Button>
                            <Button key="accept" type="primary" disabled={this.state.acceptButtonDisabled} onClick={this.accept}>
                                Accept
                            </Button>
                        </div>
                    </>
                }
            >
                <div dangerouslySetInnerHTML={{__html: this.props.text}}></div> 
            </Modal>
        );
    }
};

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context
    };
};

export default connect(
    mapStateToProps,
    actions
)(Terms);
  