import {
  XYChart,
  DateAxis,
  ValueAxis,
  Legend,
  XYCursor,
  LineSeries,
  CircleBullet,
} from "@amcharts/amcharts4/charts";
import * as amCore from "@amcharts/amcharts4/core";

import { AxisRange } from "../models";

import {
  signalLabel,
  TOOLTIP_FORMAT,
  DATE_FORMAT,
  NUMBER_FORMAT,
  inputSignalMapper,
  findTimezone,
} from "../common";
import { appConfigs } from "../../../../utils/configurations";
import { Template, WidgetFilterSetting, WidgetSignalId } from "../../models";
import { DataStore } from "../../state";
import moment from "moment";

export interface Options {
  axisRange?: AxisRange;
  rightYAxisRange?:AxisRange;
  enableRightYAxis?: boolean;
}

export type TimeSeriesSignal = {
  name: string;
  color: string;
  units: string;
  values: Array<{ timestamp: Date; value: number }>;
  userPreference?: any;
};

const createYAxisSignalSeries = inputSignalMapper((meta, data) => ({
  ...meta,
  values: data,
}));

class LineChart {
  // private _chart: XYChart;
  private _chart: XYChart = {} as XYChart;
  private _timescaleAPIZooming: Function | undefined;
  private _widgetFilterSetting: WidgetFilterSetting | undefined;
  private  count = 0 ;
  private timezone:any;
  constructor(ref: HTMLDivElement, series: TimeSeriesSignal[], rightAxisSeries:TimeSeriesSignal[], opts?: Options, timescaleAPIZooming?: Function, state?:any, widgetFilterSetting?: WidgetFilterSetting) {
    const chart = amCore.create(ref, XYChart);
    const xAxis = chart.xAxes.push(new DateAxis());
    xAxis.cursorTooltipEnabled = false;

    this.timezone = state.timezone;
    this._widgetFilterSetting = widgetFilterSetting;
    const yAxis = chart.yAxes.push(new ValueAxis());
    yAxis.cursorTooltipEnabled = false;
    yAxis.min = opts?.axisRange?.min;
    yAxis.max = opts?.axisRange?.max;

    //right value axis
    const rightYAxis = chart.yAxes.push(new ValueAxis());
       
    const cursor = (chart.cursor = new XYCursor());
    cursor.lineY.disabled = true;
    // Show only one tooltip
    // https://www.amcharts.com/docs/v4/concepts/chart-cursor/#Relation_to_series
    cursor.maxTooltipDistance = -1;

    chart.legend = new Legend();
    chart.legend.maxWidth = 1000000;
    chart.legend.useDefaultMarker = true;
    // chart.legend.labels.template.truncate = false;
    chart.legend.labels.template.maxWidth = 1000000;
    chart.legend.valueLabels.template.align = "left";
    chart.legend.valueLabels.template.textAlign = "start";
    chart.legend.contentAlign = "left"; 
    chart.legend.itemContainers.template.paddingTop = 1;
    chart.legend.itemContainers.template.paddingBottom = 1;
    chart.legend.itemContainers.template.tooltipText = "{name}";

    chart.legend.markers.template.width = 15;
    chart.legend.markers.template.height = 15;

    chart.paddingLeft = 0;
    chart.marginLeft = 0;
    chart.marginTop = 12;
    
    chart.dateFormatter.dateFormat = DATE_FORMAT;
    chart.numberFormatter.numberFormat = NUMBER_FORMAT;

    chart.cursor.behavior = 'zoomX';
    cursor?.events?.on('zoomended', function (ev: any) {
      let range = ev.target.xRange;
      if (range) {
        let xAxisTmp = ev.target.chart.xAxes.getIndex(0);
        let StartTime = xAxisTmp?.positionToDate(xAxisTmp?.toAxisPosition(range.start));
        let EndTime = xAxisTmp?.positionToDate(xAxisTmp?.toAxisPosition(range.end));
        if (timescaleAPIZooming) {
          timescaleAPIZooming(moment(StartTime), moment(EndTime))
        }
      }
    });

    this._chart = chart;
    this._timescaleAPIZooming = timescaleAPIZooming;
   
    series.forEach(this.addSeries);
   
    if(opts?.enableRightYAxis){
      rightYAxis.cursorTooltipEnabled = false;
      rightYAxis.min = opts?.rightYAxisRange?.min;
      rightYAxis.max = opts?.rightYAxisRange?.max;
      rightYAxis.renderer.opposite = true;
      rightAxisSeries.forEach(item=>this.addRightAxisSeries(item,rightYAxis))
    }   
   
    // this.addDayNightXAxisShades();
  }
  addRightAxisSeries=(dataSeries: TimeSeriesSignal,rightYAxis:ValueAxis)=>{
    const series2 = new LineSeries();
   
    series2.dataFields = {
      valueY: "value",
      dateX: "timestamp",
    };
    var tooltipDetails:any;
    if(dataSeries && dataSeries.values.length>0){
     tooltipDetails=findTimezone(this.timezone);
    }
    series2.data = dataSeries.values;      
    series2.strokeWidth = 2;
    
    series2.stroke = dataSeries.color ? amCore.color(dataSeries.color) : amCore.color(appConfigs.colorCodes.colorCodesLineChart[this.count]);
    series2.fill = dataSeries.color ? amCore.color(dataSeries.color) : amCore.color(appConfigs.colorCodes.colorCodesLineChart[this.count]);
       
    series2.name = signalLabel(dataSeries,dataSeries.userPreference);
    // series2.tooltipText = "{dateX}" + " " + tooltipDetails?.timezone + "\n{name}: {valueY}";
    let tooltip_text = `{dateX} ${this._widgetFilterSetting?.granularity === '1 Day' ? 'UTC' : tooltipDetails?.timezone} \n{name}: {valueY}`;
    series2.tooltipText = (this._widgetFilterSetting === undefined || this._widgetFilterSetting?.granularity === 'Raw Data') ? tooltip_text : tooltip_text + ' (' + this._widgetFilterSetting?.aggregate + ')';
    
    
    series2.yAxis=rightYAxis;

    this._chart.series.push(series2);

    this.count++;
  }
  addSeries = (dataSeries: TimeSeriesSignal) => {

    const series = new LineSeries();
   
    series.dataFields = {
      valueY: "value",
      dateX: "timestamp",
    };
    series.data = dataSeries.values;     
    series.strokeWidth = 2;
    var tooltipDetails:any;
    if(dataSeries && dataSeries.values.length>0){
     tooltipDetails=findTimezone(this.timezone);
    }
    series.stroke = dataSeries.color ? amCore.color(dataSeries.color) : amCore.color(appConfigs.colorCodes.colorCodesLineChart[this.count]);
    series.fill = dataSeries.color ? amCore.color(dataSeries.color) : amCore.color(appConfigs.colorCodes.colorCodesLineChart[this.count]);
       
    series.name = signalLabel(dataSeries,dataSeries.userPreference);
    // series.tooltipText = "{dateX}" + " " + tooltipDetails?.timezone + "\n{name}: {valueY}";
    let tooltip_text = `{dateX} ${this._widgetFilterSetting?.granularity === '1 Day' ? 'UTC' : tooltipDetails?.timezone} \n{name}: {valueY}`;
    series.tooltipText = (this._widgetFilterSetting === undefined || this._widgetFilterSetting?.granularity === 'Raw Data') ? tooltip_text : tooltip_text + ' ('+this._widgetFilterSetting?.aggregate+')';
    
    if (this._timescaleAPIZooming !== undefined) {
      let bullet = series.bullets.push(new CircleBullet());
      bullet.circle.stroke = amCore.color("#FFFFFF"); // Optional: Style for the bullet's stroke (border)
      bullet.circle.strokeWidth = 0.1; // Optional: Stroke width
      bullet.circle.radius = 2; // Radius of the bullet
    }

    this._chart.series.push(series);

    this.count++;
  };
  addDayNightXAxisShades = () => {
    if (!this._chart || !this._chart.data || this._chart.data.length === 0) {
      console.warn("Chart data is empty or not initialized");
      return;
    }
  
    const xAxis = this._chart.xAxes.getIndex(0);
    if (!xAxis) {
      console.warn("X-axis is not defined");
      return;
    }
  
    let startDate = new Date(this._chart.data[0].timestamp);
    let endDate = new Date(this._chart.data[this._chart.data.length - 1].timestamp);
  
    while (startDate < endDate) {
      let range = xAxis.axisRanges.create() as any;
      range.date = new Date(startDate.setHours(20, 0, 0, 0)); // Start of night at 8 PM
      range.endDate = new Date(startDate.setHours(32, 0, 0, 0)); // End of night at 6 AM next day
      range.axisFill.fill = amCore.color("#000000"); // Use a noticeable color for testing
      range.axisFill.fillOpacity = 0.5; // Use higher opacity for visibility
      range.grid.disabled = true;
      startDate.setDate(startDate.getDate() + 1);
    }
  
    this._chart.invalidateData();
  };
  



  destroy() {
    this._chart?.dispose();
  }
}

export default LineChart;