import React from 'react';
import { Result, Button } from 'antd';

import history from '../../utils/history';
import "./notFound404.css";

const goHome = (e:any) => {
    e.preventDefault();
    history.push("/map");
}

const NotFound404: any = () => {
    
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
                <Button type="primary" onClick={(e:any) => goHome(e)}>Back Home</Button>
            }
        />
    );
};

export default NotFound404;