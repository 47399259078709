import Axios from "axios";
import { Asset, DBWidgetConfig } from "./models";
import { DbLayoutConfig } from "./state";
import { constructAssetData } from "./AssetApiResp";
import { WidgetConfig } from "./widgets";
import { WidgetFilterConfig } from "./WidgetFilterForm";
import { appConfigs } from "../../utils/configurations";
import { constructUrl } from "./WidgetFilterForm/FilterApi";

export interface AssetDashboardResponse {
  asset: Asset;
}
interface DbSignal {
  _id: string;
  unit: string;
  name: string;
  label: string;
}

export interface Datapoint {
  _id: string;
  type: string;
  name: string;
  values: DbSignal[];
}

export async function loadAssetDashboard(
  mastertag: string
): Promise<AssetDashboardResponse> {  
  const { data } = await Axios.get(
    `${appConfigs.server.URL}/ui/api/assets?mastertag=${mastertag}&allData=true`,
    {
      params: {
        alertInterval: appConfigs.app.assetOverview.alertsInterval,
      },
    }
  );
  return constructAssetData(data, mastertag);
}

export async function loadSignalsData(
  gatewayId: string,
  signalCollectionId: string[],
  filterConfig?: WidgetFilterConfig,
  type?: WidgetConfig["type"]
): Promise<any> {
  let promiseArray = signalCollectionId
    .map((datapoint) =>
      Axios.get(constructUrl(gatewayId, datapoint, filterConfig, type))
    )
    .map((p) => p.catch((e) => e));
  return Promise.all(promiseArray);
}

export function saveWidget(
  templateId: string,
  widgetDetails: DBWidgetConfig
): Promise<any> {
  return Axios.put(
    `${appConfigs.server.URL}/ui/api/assets/templates/${templateId}/widget`,
    widgetDetails,
    {
      responseType: "json",
    }
  );
}

export function deleteWidget(
  templateId: string,
  widgetId: string
): Promise<any> {
  return Axios.delete(
    `${appConfigs.server.URL}/ui/api/assets/templates/${templateId}/widget/${widgetId}`
  );
}

export function saveLayoutChange(
  templateId: string,
  layout: (DbLayoutConfig & { _id: string })[]
): Promise<any> {
  return Axios.put(
    `${appConfigs.server.URL}/ui/api/assets/templates/${templateId}/widget/layout`,
    layout,
    {
      responseType: "json",
    }
  );
}

export function getAlerts(mastertag: string): Promise<any> {
  const dmAlertsUrl = Axios.get(
    `${appConfigs.server.URL}/ui/api/alerts/dm1?mastertag=${mastertag}`
  );
  const restAlertsUrl = Axios.get(
    `${appConfigs.server.URL}/ui/api/alerts/signals?mastertag=${mastertag}`
  );
  return Promise.all([dmAlertsUrl, restAlertsUrl]);
}

export function csvExportApi(url: string): Promise<any> {
  return Axios.get(url);
}
