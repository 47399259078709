import React from "react";
import { Drawer, Tabs, Button, Space } from "antd";

import { connect } from "react-redux";
import * as actions from "./actions";

import { appConfigs } from "../../utils/configurations";

import UserMaintInfo from "./userMaintInfo/UserMaintInfo";
import UserMaintOrgs from "./userMaintOrgs/UserMaintOrgs";
import UserMaintSubscriptions from "./userMaintSubscriptions/UserMaintSubscriptions";
import { getPermissions } from "../../utils/commonHelpers";
import ActivityMaintSubscription from "./activityMaintSubscription/activityMaintSubscription";

const { TabPane } = Tabs;

interface Prop {
    usersListRefreshOnUpdate: Function,
    getUsers: Function,
    userMaintClose: Function,    
    userMaintTabChange: Function,
    usersList: any,
    permissions: any
}

class UserMaint extends React.Component<Prop, {}> {  
   
    componentDidUpdate(prevProps: Prop) {
        if (this.props.usersList.userMaint.instance !== prevProps.usersList.userMaint.instance) {
            this.closeCollapsed();
        }
    }    

    onDrawerClose = () => {
        if (this.props.usersList.refreshOnUpdate) {
            this.props.getUsers(this, null, null, this.props.usersList.tableConfig.pagination.current, this.props.usersList.searchText, true);
        }
        
        this.props.userMaintClose(this);
    };

    onTabChange = (key:string) => {
        this.props.userMaintTabChange(this, key);
    }

    closeCollapsed() {       
        let active:any = document.querySelectorAll("[data-id=user-maint-drawer] .ant-collapse-item-active");    
        if (active.length) {
            for (var i = 0; i < active.length; i++) { 
                active[i]!.children[0].click();  
            }
        }
    }

    render() {

        const { userMaint } = this.props.usersList;
        const result = getPermissions(this.props.permissions.permissions.users);

        let iscreate = result[0];
        let isread = result[1];
        let isupdate =  result[2];
        let isdelete = result[3];

        return (
            <Drawer
                data-id="user-maint-drawer" 
                title={userMaint.title}
                width={userMaint.width}
                onClose={this.onDrawerClose}
                visible={userMaint.visible}
                destroyOnClose={true}
                footer={
                    <Space size="large" style={{ float: 'right' }}>
                        <Button data-id="users-maint-close-button" type="default" onClick={this.onDrawerClose}>
                            {appConfigs.settings.drawers.closeButtonText}
                        </Button>
                        {
                            userMaint.activeTab === "1" &&
                            <Button data-id="users-maint-submit-button" type="primary" form="userMaintInfoForm" key="submit" htmlType="submit">
                                {appConfigs.settings.form.submitButtonText}
                            </Button>
                        }
                    </Space>
                }
            >
                <Tabs data-id="users-maint-info-tabs" activeKey={userMaint.activeTab} size="large" type="card" onChange={this.onTabChange}>
                    <TabPane data-id="users-maint-info-tab-button" tab={userMaint.tabs[0].title} key="1">                    
                        <UserMaintInfo iscreate={iscreate} isread={isread} isupdate={isupdate} />
                    </TabPane>  
                    <TabPane data-id="users-maint-orgs-tab-button" tab={userMaint.tabs[1].title} key="2" disabled={userMaint.tabs[1].disabled}>
                        <UserMaintOrgs iscreate={iscreate} isread={isread} isupdate={isupdate} isdelete={isdelete}/>
                    </TabPane>  
                    <TabPane data-id="users-maint-subscriptions-tab-button" tab={userMaint.tabs[2].title} key="3" disabled={userMaint.tabs[2].disabled}>
                        <UserMaintSubscriptions />
                    </TabPane>  
                    <TabPane data-id="users-maint-subscriptions-tab-button" tab={userMaint.tabs[3].title} key="4" disabled={userMaint.tabs[3].disabled}>
                        <ActivityMaintSubscription/>
                    </TabPane>
                </Tabs>            
            </Drawer>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        usersList: state.usersReducers.usersState.usersList,
        permissions: state.contextReducer.data
    };
};

export default connect(
    mapStateToProps,
    actions
)(UserMaint);