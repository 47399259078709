import React from "react";
import { connect } from "react-redux";
import { Drawer, Tabs, Button, Space } from "antd";
import * as actions from "./actions";
import { appConfigs } from "../../utils/configurations";
import RoleMaintInfo from "./roleMaintInfo/RoleMaintInfo";

const { TabPane } = Tabs;

interface Prop {
    roleMaintClose: Function,
    roleList: any
    rolePermissionList: any
}

class RoleMaint extends React.Component<Prop, {}> {
    
    componentDidUpdate(prevProps: Prop) {
       
        if (this.props.roleList.roleMaint.instance !== prevProps.roleList.roleMaint.instance) {
            this.closeCollapsed();
        }
    }
    
    closeCollapsed() {       
        let active:any = document.querySelectorAll("[data-id=role-maint-drawer] .ant-collapse-item-active");    
        if (active.length) {
            for (var i = 0; i < active.length; i++) { 
                active[i]!.children[0].click();  
            }
        }
    }

    onDrawerClose = () => {
        
        // if (this.props.rolePermissionList.refreshOnUpdate) {
        //      this.props.getUsers(this, null, null, this.props.roleList.tableConfig.pagination.current, this.props.roleList.searchText, true);
        // }
         this.props.roleMaintClose(this);
    };

    render() {

        const { roleMaint } = this.props.roleList;
        //const { permissions } = this.props.rolePermissionList;
        return(
            <Drawer
                data-id="role-maint-drawer" 
                title={roleMaint.title}
                width={roleMaint.width}
                onClose={this.onDrawerClose}
                visible={roleMaint.visible}
                destroyOnClose={true}
                footer={
                    <Space size="large" style={{ float: 'right' }}>
                        <Button data-id="role-maint-close-button" type="default" onClick={this.onDrawerClose}>
                            {appConfigs.settings.drawers.closeButtonText}
                        </Button>
                        {/* {
                            roleMaint.activeTab === "1" &&
                            <Button data-id="role-maint-submit-button" type="primary" form="roleMaintInfoForm" key="submit" htmlType="submit">
                                {appConfigs.settings.form.submitButtonText}
                            </Button>
                        } */}
                    </Space>
                }
            >
                <Tabs data-id="role-maint-info-tabs" activeKey={roleMaint.activeTab} size="large" type="card">
                    <TabPane data-id="role-maint-info-tab-button" tab="Roles Info" key="1">                    
                            <RoleMaintInfo />
                    </TabPane>
                </Tabs>

            </Drawer>
        );
    }

}
const mapStateToProps = (state:any) => {
    return {
        roleList: state.usersReducers.usersState.roleList,
        rolePermissionList: state.usersReducers.usersState.data
    };
};

export default connect(
    mapStateToProps,
    actions
)(RoleMaint);