import React, { useRef, useLayoutEffect } from "react";
import BarChartClass, { Options, Signal } from "./BarChartClass";
import { CommonWidgetProps } from "../../models";
import { WidgetDetails } from "../models";
import { inputSignalMapper, signalLabel } from "../common";
import useDomNode from "../../../../lib/use-dom-node";
import { connect } from "react-redux";
import * as actions from "../../../../actions";

interface Props extends CommonWidgetProps {
  settings?: Options;
}

export const details: WidgetDetails = {
  type: "bar",
  name: "Bar Chart",
};

const createSignals = inputSignalMapper<Signal>((meta, data, userPreference) => ({
  ...meta,
  ...data[0],
  userPreference,
  name: signalLabel(meta,userPreference),
}));

const Widget: React.FC<Props> = (props) => {
  const [node, setNode] = useDomNode<HTMLDivElement>();
  const chartRef = useRef<BarChartClass>();

  const { template, data, settings, signals,state } = props;

  let barChartData = createSignals(signals, template, data,state.temperature.value)

  /* updating barChartData with live value if present (SignalR) */
  if (Object.keys(props.signalRData.data).length > 0) {
    barChartData = barChartData.map((signal: any) => {
      if (props.signalRData.data.hasOwnProperty(signal.id)){
        signal.value = props.signalRData.data[signal.id][0].value
        signal.timestamp = new Date(props.signalRData.data[signal.id][0].bt - props.signalRData.data[signal.id][0].time)
      }
      return signal
    })
  }

  useLayoutEffect(() => {
    if (node) {
      chartRef.current = new BarChartClass(
        node,
        barChartData,
        settings,
        state.timezone
      );
      return () => chartRef.current?.destroy();
    }
  }, [node, template, settings, signals, data, barChartData]);

  return <div style={{ height: "100%" }} ref={setNode} />;
};


// export default Widget;
const mapStateToProps = (state: any) => {
  return {
    signalRData: state.contextReducer.signalRData,
  };
};
export default connect(
  mapStateToProps,
  // actions
)(Widget);
