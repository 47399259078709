import React from "react";
import { List, Tooltip } from "antd";
import Dot from "../../../../sharedComponents/Dot";
import { findTimezone, formatDate, inputSignalMapper, signalLabel } from "../common";
import { CommonWidgetProps, CommonWidgetConfig } from "../../models";
import { WidgetDetails } from "../models";
import { connect } from "react-redux";
import * as actions from "../../../../actions";

interface Signal {
  signalId: string;
  name: string;
  color: string;
  values: { timestamp: Date; value: boolean; }[];
}

export const details: WidgetDetails = {
  type: "boolean",
  name: "Boolean Widget"
}

export type Config = CommonWidgetConfig & { type: "boolean" };

const createSignals = inputSignalMapper<Signal>((meta, data,userPreference) => ({
  signalId: meta.id,
  name: signalLabel(meta,userPreference, false),
  color: meta.color,
  values: [
    {
      timestamp: data[0].timestamp,
      value: !!data[0].value,
    },
  ],
}));

const BooleanWidget: React.FC<CommonWidgetProps> = (props) => {
  const { template, data, state } = props;
  let signals = createSignals(props.signals, template, data,state.temperature.value);

  /* updating with live value if present (SignalR) */
  if (Object.keys(props.signalRData.data).length > 0) {
    signals = signals.map((signal: Signal) => {
      if (props.signalRData.data.hasOwnProperty(signal.signalId)){
        signal.values[0].value = !!props.signalRData.data[signal.signalId][0].value
        signal.values[0].timestamp = new Date(props.signalRData.data[signal.signalId][0].bt - props.signalRData.data[signal.signalId][0].time)
      }
      return signal
    })
  }

  var onColor = "#008000";
  var offColor = "#FF0000";
  var details:any;
  if (signals && signals.length > 0) {
       if (signals[0].color) {
          let arr = signals[0].color.split("$");
          onColor = arr[0];
          offColor = arr[1];
       }
       details=findTimezone(state.timezone);
  }
  return (
    <List
      dataSource={signals}
      renderItem={(signal) => (
        <List.Item>
          <List.Item.Meta title={signal.name} />
          <Tooltip
            title={formatDate(signal.values[0].timestamp)+" ".concat(details?.timezone)}
            placement="topRight"
            arrowPointAtCenter={true}
          >
            <div>
              <Dot
               // color={signal.values[0].value ? onColor : offColor}
                color={signal.color? 
                    (signal.values[0].value ? signal.color.split("$")[0] : signal.color.split("$")[1]) 
                      : (signal.values[0].value ? onColor : offColor)}
                size="20px"
              />
            </div>
          </Tooltip>
        </List.Item>
      )}
    />
  );
};

// export default BooleanWidget;
const mapStateToProps = (state: any) => {
  return {
    signalRData: state.contextReducer.signalRData,
  };
};
export default connect(
  mapStateToProps,
  // actions
)(BooleanWidget);
