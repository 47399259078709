import {
  SignalId,
  Template,
  Signal,
  SignalDatapoint,
  EventData,
  WidGetSignalConfig,
  SignalCollectionId,
  WidgetSignalId,
  AlertSignalDatapointData,
} from "../models";
import { DataStore, State, WidgetState } from "../state";
import { Layout } from "react-grid-layout";
import moment, { Moment } from "moment";
import tzlookup  from "tz-lookup";
import { appConfigs } from "../../../utils/configurations";

export const DATE_FORMAT = "EEE MMM dd yyyy HH:mm:ss";

export function signalLabel(
  signal: { name: string; units: string },
  userPreference?: any,
  amCharts: boolean = true
): string {
  const openBracket = amCharts ? "[[" : "[";
  const closeBracket = amCharts ? "]]" : "]";

  const temperatureFUnitsArray = ['°F', 'Deg F', 'Degrees F'];
  const temperatureCUnitsArray = ['°C', 'Deg C', 'Degrees C'];
  
  let signalName = signal.name;
  if (signal.units) {
    
    if ( temperatureFUnitsArray.some(substring => signal.units.includes(substring)) || signal.units === 'F' ) {
      signal.units = "°F";
    }
    if ( temperatureCUnitsArray.some(substring => signal.units.includes(substring)) || signal.units === 'C' ) {
      signal.units = "°C";
    }

    if (signal.units === "°F" || signal.units === "°C") {
      signalName += ` ${openBracket}${userPreference || "°F"}${closeBracket}`;
    }
    else {
      signalName += ` ${openBracket}${signal.units || "-"}${closeBracket}`;
    }
  }
  
  return signalName;
}

export const TOOLTIP_FORMAT = "{dateX}\n{name}: {valueY}";
export const TOOLTIP_FORMAT_H = "{dateX}\n{name}: {valueX}";
export const TOOLTIP_COLOR = "#333";

export const NUMBER_FORMAT = "#.######";

export function formatDate(date: Date): string {
  const dateString = moment(date).format("ddd MMM DD YYYY HH:mm:ss").toString();
  // const dateString = date.toString();
   //const idx = dateString.indexOf("GMT");
   return dateString
  // return dateString.slice(0, idx);
 }

export function inputSignalMapper<T>(
  mapFunc: (meta: Signal, data: SignalDatapoint[], userPreference: any) => T
) {
  return (signalIds: WidgetSignalId[], template: Template, data: DataStore,userPreference?: any) => {
    const result: T[] = [];

    for (let index = 0; index < signalIds.length; index++) {
      const id = signalIds[index];
      if (id.id) {
          const signalData = data.get(id.id);
          const signalMeta = template.signals.find((s) => s.id === id.id);
          if (!!signalData && !!signalMeta) {
            if (id.color === "#000000" || id.color == null) {
              signalMeta!.color = appConfigs.colorCodes.colorCodesLineChart[index];
            } else {
              signalMeta!.color = id.color;
            }
            result.push(mapFunc(signalMeta, signalData,userPreference));
          }
      } else {
        let signalId = String(id);
        const signalData = data.get(signalId);
        const signalMeta = template.signals.find((s) => s.id === signalId);
        if (!!signalData && !!signalMeta) {
            signalMeta!.color = "#000000"; 
            result.push(mapFunc(signalMeta, signalData,userPreference));
        }
      }     
    }

    return result;
  };
}

export function extendRange(
  limit: number,
  percent: number,
  direction: "min" | "max"
) {
  const sign = Math.sign(limit);
  const dir = direction === "max" ? 1 : -1;

  return limit * (1 + (dir * sign * percent) / 100);
}

export function addDateAndTime(dateVal: Moment, timeVal?: Moment) {
  //let newDate = moment(dateVal, "ddd MMM D YYYY HH:mm:ss ZZ");
  if (timeVal) {
    dateVal = dateVal.set({
      h: timeVal.toDate().getHours(),
      m: timeVal.toDate().getMinutes(),
      s: timeVal.toDate().getSeconds(),
      ms: 0
    });
  }  
  return dateVal;
}

export function getUnixDate(dateVal: Moment): number {
  return dateVal.valueOf();
}

export function validateForms(startDate: Moment, endDate: Moment, startTime: Moment, endTime: Moment) : Promise<any> {
  if (startDate) {
    let startDateTime = addDateAndTime(startDate, startTime);
    if( startDateTime > moment())  {
      return Promise.reject(
        "Start must come before current time"
      );
    }
    if(endDate) {
      const unixStartDate = getUnixDate(startDateTime);
      const unixEndDateTime = getUnixDate(addDateAndTime(endDate, endTime));
      const unixCurrentDate = getUnixDate(moment());
      if (endDate < startDate) {
        return Promise.reject(
          "Start must come before End proposed"
        );
      }
      if (
        moment.duration(unixEndDateTime - unixStartDate).asDays() > appConfigs.app.assetOverview.startdateenddatedaysdiff
      ) {
        return Promise.reject(
          "Start and End can be no more than 7 days apart"
        );
      }
      // Cosmos will have past 90 days of data
      // So Start & End date can't be past 90 days
      // if (
      //   moment.duration(unixCurrentDate - unixStartDate).asDays() > appConfigs.app.assetOverview.cosmosdataretentiondays
      //   || 
      //   moment.duration(unixCurrentDate - unixEndDateTime).asDays() > appConfigs.app.assetOverview.cosmosdataretentiondays
      // ) {
      //   return Promise.reject(
      //     "Start/End Date cannot be past 90 days"
      //   );
      // }      
    }
  }  
  return Promise.resolve();
}

export function exportValidateForms(startDate: Moment, endDate: Moment, startTime: Moment, endTime: Moment) : Promise<any> {
  if (startDate) {
    let startDateTime = addDateAndTime(startDate, startTime);
    if( startDateTime > moment())  {
      return Promise.reject(
        "Start must come before current time"
      );
    }
    if(endDate) {
      const unixStartDate = getUnixDate(startDateTime);
      const unixEndDateTime = getUnixDate(addDateAndTime(endDate, endTime));
      const unixCurrentDate = getUnixDate(moment());
      if (endDate < startDate) {
        return Promise.reject(
          "Start must come before End proposed"
        );
      }
      if (
        moment.duration(unixEndDateTime - unixStartDate).asDays() > appConfigs.app.assetOverview.exportstartdateenddatedaysdiff
      ) {
        return Promise.reject(
          `Start and End can be no more than ${appConfigs.app.assetOverview.exportstartdateenddatedaysdiff} days apart`
        );
      }
      // Cosmos will have past 90 days of data
      // So Start & End date can't be past 90 days
      // if (
      //   moment.duration(unixCurrentDate - unixStartDate).asDays() > appConfigs.app.assetOverview.cosmosdataretentiondays
      //   || 
      //   moment.duration(unixCurrentDate - unixEndDateTime).asDays() > appConfigs.app.assetOverview.cosmosdataretentiondays
      // ) {
      //   return Promise.reject(
      //     "Start/End Date cannot be past 90 days"
      //   );
      // }   
    }
  }  
  return Promise.resolve();
}

export function constructRows(
  response: any,
  signals: WidgetSignalId[]
): Map<SignalId, SignalDatapoint[] | AlertSignalDatapointData[]> {
  let dataSet = new Map<SignalId, SignalDatapoint[] | AlertSignalDatapointData[]>();
  //Get the response data from api and remove undefined entry
  const responseDataSet = response.map((res: any) => res.data).filter(Boolean);
  if (responseDataSet.length === 0) {
    return dataSet;
  }
  //if there are 3 diff datapoint request, there would be 3 array of array
  //Flatmap will make the collection to a single one dimensional array
  const eventsArrays: EventData[] = responseDataSet.flatMap((res: any) => res);
  //get the unique signal ids present in the event array.
  const ids = new Set(
    eventsArrays.flatMap((event) =>
      event.signals.flatMap((signal) => signal._id)
    )
  );
  const signalIds = Array.from(ids);
  //filtered the signals with the widget signals
  const filteredSignals = signalIds.filter((s) => signals.findIndex(x => (x.id ? x.id : x) === s) > -1);

  function constrcuctSignalMap(id: SignalId): SignalDatapoint[] {
    const signalCollections = eventsArrays.filter(
      (event) => event.signals.findIndex((signal) => signal._id === id) > -1
    );
    const signalDataPointArray = signalCollections.reduce(
      (acc: SignalDatapoint[] | AlertSignalDatapointData[], current) => {

        if(current.signals.filter((s) => s._id === id)[0].hasOwnProperty('severity')){
          acc.push({
            timestamp: new Date(current.createdAt),
            value: current.signals.filter((s) => s._id === id)[0].value,
            severity: current.signals.filter((s) => s._id === id)[0]?.severity,
            ruleName: current.signals.filter((s) => s._id === id)[0]?.name
          });
        } else {
          acc.push({
            timestamp: new Date(current.createdAt),
            value: current.signals.filter((s) => s._id === id)[0].value,
          });
        }

        return acc;
      },
      []
    );
    return signalDataPointArray.sort(
      (cur, next) => cur.timestamp.getTime() - next.timestamp.getTime()
    );
  }

  filteredSignals.forEach((id) => {
    dataSet.set(id, constrcuctSignalMap(id));
  });

  return dataSet;
}

export function findTimezone(timezone: any){
  let location:any
  let timezoneDetails:any
  if(timezone.coordinates.latitude && timezone.coordinates.longitude){
     location = tzlookup(timezone.coordinates.latitude, timezone.coordinates.longitude);
     timezoneDetails= moment.tz(location).format('z');
  }
  timezoneDetails=(timezone.isGpsSignalAvailable && timezone.value=="asset" && timezoneDetails)?timezoneDetails:(moment().tz(moment.tz.guess()).format('z'))
  return {
    timezone:timezoneDetails
  }

}

export function FindAssetTimeZone(coordinates: any, updated_at: any){
  var location = tzlookup(coordinates.latitude, coordinates.longitude);
  var timezone= moment.tz(location).format('z');
  var locale_date_str = new Date(updated_at).toLocaleString("en-US",
      {
          timeZone: tzlookup(coordinates.latitude, coordinates.longitude)              
      });
      var geo_local_date_str = new Date(locale_date_str);
  return {
    timezoneDetails:{
    "timezone":timezone,
    "geo_local_date_str":geo_local_date_str
  }
};
}

