import React from "react";
import { Form, Input, Switch, Tooltip, Typography } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { appConfigs } from "../../../utils/configurations";
import { setHeaderUserName } from "../../../utils/commonHelpers";
import { successResponse, errorResponse, resetFormErrors } from "../../../utils/apiHelpers/apiHelpers";
import { FormState, formInit } from "../../../utils/apiHelpers/formHelpers";
import { SuccessAlert, ErrorAlert } from "../../../utils/Components/formAlerts";

import UserHomeOrg from "./UserHomeOrg";
//import UserAvatar from "./UserAvatar";
//import UserPhone from "./UserPhone";
//import UserCopy from "./UserCopy";

import { connect } from "react-redux";
import * as actions from "../actions";

import "./userMaintInfo.css";

const { Paragraph } = Typography;

interface Prop {
    userMaint: Function,
    usersListRefreshOnUpdate: Function,
    userMaintTabsEnable: Function,
    userMaintRefreshInstance: Function,
    getOrgRoles: Function,
    context: any,
    usersList: any,
    roleList: any,
    iscreate: boolean,
    isupdate: boolean,
    isread: boolean
}

class UserMaintInfo extends React.Component<Prop, FormState> {  
        
    state: FormState = {};

    formRef = React.createRef<FormInstance>();
    
    componentDidMount() {
        this.props.getOrgRoles(this,null,null,this.props.usersList.userMaint.record.orgIdHome);  
        this.componentUpdate(this.props.usersList.userMaint.record);
    }

    componentDidUpdate(prevProps: Prop, prevState: FormState) {       
        if (this.props.usersList.userMaint.instance !== prevProps.usersList.userMaint.instance) {
            this.componentUpdate(this.props.usersList.userMaint.record);
        }
    }

    componentUpdate(record:any) {
        formInit(this);
        if (this.formRef.current != null) {
            this.formRef.current!.resetFields();    

            this.formRef.current!.setFieldsValue({               
                userId: record.userId,
                firstName: record.firstName,
                lastName: record.lastName,
                email: record.email,
                phoneNumber: record.phoneNumber,
                phoneNumberIsoCode2: record.phoneNumberIsoCode2 || ((record.userId || "") === "" ? "US" : ""),               
                orgIdHome: (record.orgIdHome || "") === "" ? this.props.context.appContext.orgId : record.orgIdHome,                      
                orgHomeRole: (record.orgHomeRole || "") === "" ? this.props.context.appContext.roleName : record.orgHomeRole,

                // ops-2784
                // orgIdHome: (record.orgIdHome || "") === "" ? (this.props.context.appContext.roleName === "admin" ? this.props.context.appContext.orgId: null) : record.orgIdHome,       
                // orgHomeRole: (record.orgHomeRole || "") === "" ? "user" : record.orgHomeRole,

                superUser: record.superUser || false,
                oktaUserId: record.oktaUserId
                /*        
                    OPS-968          
                    avatarUploadId: record.avatarUploadId,
                    avatarUploadSrc: record.avatarUploadSrc
                */
            });
        }
    };

    onFinish = (values: any) => {  
        resetFormErrors(this, this.formRef.current);
        this.props.userMaint(this, this.onFinishSuccess, this.onFinishFailure, values);
    };

    onFinishSuccess = (recordOld:any, recordNew: any) => { 
        this.props.usersListRefreshOnUpdate(this);
        this.componentUpdate(recordNew);
        this.props.userMaintRefreshInstance(this, [1,2]);

        if (recordNew.userId === this.props.context.user.userId) {
            //OPS-968 setHeaderAvatar(appConfigs.server.URL + recordNew.avatarUploadSrc);            
           
            setHeaderUserName(recordNew.firstName + " " + recordNew.lastName);
        }
        if ((recordNew.userId || "") !== "") {
            this.props.userMaintTabsEnable(this);
        }

        if ((recordOld.userId || "") === "") {
            successResponse(this, "User has been successfully created");        
        }
        else {
            successResponse(this, "User has been successfully updated");        
        }
    }

    onFinishFailure = (error: any) => { 
        errorResponse(this, error);

    }

    render() {
        
        const { user:contextUser } = this.props.context;
        const { userMaint } = this.props.usersList;
        
        const superUserLabel = (
            <>
                <span>Super User</span>
                <Tooltip placement="topLeft" title="This option is accessible to super users only">
                    <QuestionCircleOutlined style={{ marginLeft: '5px'}}/>
                </Tooltip>
            </>
        );

        const tempPasswordLabel = (
            <>
                <span>Temp Password</span>
                <Tooltip placement="topLeft" title="This option is accessible to administrators until the user signs in for the first time">
                    <QuestionCircleOutlined style={{ marginLeft: '5px'}}/>
                </Tooltip>
            </>
        );

        return (

            <div data-id="user-maint-info">
                {this.state.formSuccess && !this.state.formError && <SuccessAlert data-id="users-maint-info-success-message"  message={this.state.formSuccessMessage}/>}
                {this.state.formError && !this.state.formSuccess && <ErrorAlert data-id="users-maint-info-error-message" message={this.state.formErrorMessage}/>}
                {/*
                    OPS-968 
                    <Row gutter={8} style={{marginTop: 15}}>
                        <Col xs={24} md={15}>
                */}
                <Form
                    {...appConfigs.settings.formLayout.mainLayout}
                    id="userMaintInfoForm"
                    ref={this.formRef}
                    onFinish={this.onFinish}
                    className="mt-15"
                >       
                    <Form.Item 
                        name="userId"
                        style={{ display: 'none' }}
                    >
                        <Input type="hidden" />
                    </Form.Item>
                       
                    <Form.Item 
                        name="oktaUserId"
                        style={{ display: 'none' }}
                    >
                        <Input type="hidden" />
                    </Form.Item>
                    {/*
                        OPS-968 
                        <Form.Item 
                            name="avatarUploadId"
                            style={{ display: 'none' }}
                        >
                            <Input type="hidden" />
                        </Form.Item>
                    */}
                    <Form.Item
                        label="First Name"
                        name="firstName"                                 
                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                    >
                        <Input maxLength={255}/>
                    </Form.Item>

                    <Form.Item
                        label="Last Name"
                        name="lastName"                            
                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                    >
                        <Input maxLength={255}/>
                    </Form.Item>

                    {/* OPS 1149 */}                                                            
                    <Form.Item
                        label="Phone"
                        name="phoneNumber"                            
                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                    >
                        <Input maxLength={32}/>
                    </Form.Item>

                    {/*
                        OPS 1149
                        <UserPhone/>
                    */}                    

                    <Form.Item
                        label="Email"
                        name="email"                            
                        rules={[
                            { required: true, message: appConfigs.errors.fieldErrors.valueRequired },
                            { type: "email", message: appConfigs.errors.fieldErrors.invalidEmail}
                        ]}
                        
                    >
                        <Input 
                            maxLength={255} 
                            placeholder="example@acme.com"
                            disabled={this.formRef.current?.getFieldValue("userId")}
                        />
                    </Form.Item>

                    {                    
                        contextUser.contextUserIsSuperUser &&
                        <Form.Item
                            label={superUserLabel}
                            name="superUser"
                            valuePropName="checked"
                        >
                            <Switch />
                            
                        </Form.Item> 
                    }

                    {                    
                        (contextUser.contextUserIsSuperUser || userMaint.record.objectAdmin) &&
                        (userMaint.record.oktaTempPassword || "") !== "" &&
                        <Form.Item
                            label={tempPasswordLabel}
                        >
                            <Paragraph copyable>{userMaint.record.oktaTempPassword}</Paragraph>                            
                        </Form.Item> 
                    }

                    <UserHomeOrg formRef={this.formRef.current} iscreate={this.props.iscreate} isread={this.props.isread} isupdate={this.props.isupdate}/>

                </Form>
                {/*
                    OPS-968 
                        </Col>
                        <Col xs={24} md={9}>
                            <div data-id="users-maint-info-avatar-container">
                                {
                                    userMaint.record.avatarUploadAllowed &&
                                    <UserAvatar formRef={this.formRef.current}/>
                                }
                                {
                                    !userMaint.record.avatarUploadAllowed && userMaint.record.avatarUploadSrc &&
                                    <Avatar shape="square" size={100}  src={userMaint.record.avatarUploadSrc} />
                                }
                                {
                                    !userMaint.record.avatarUploadAllowed && !userMaint.record.avatarUploadSrc &&
                                    <Avatar shape="square" size={100} icon={<UserOutlined />} />
                                }

                            </div>
                        </Col>
                    </Row>  
                */}
                {/*
                    OPS 1150                     
                    userMaint.record.objectAdmin && userMaint.record.userId &&
                    <UserCopy />
                */}
            </div>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        usersList: state.usersReducers.usersState.usersList,
        roleList: state.usersReducers.usersState.roleList
    };
};

export default connect(
    mapStateToProps,
    actions
)(UserMaintInfo);