import { CheckCircleOutlined, MinusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, } from "antd";
import { FormInstance } from "antd/lib/form";
import React from "react";
import { connect } from "react-redux";
import { appConfigs } from "../../../utils/configurations";
import * as actions from "../actions";

interface Prop {
    connected?: boolean,
    Name?: String,
    Password?: String,
    index: number,
    remove: (index:any)=>void
}
const formRef = React.createRef<FormInstance>();
const WiFiConnect: React.FC<Prop>=(props) =>{
    const RemoveSSID=(index:any)=>{
       
        props.remove(index);
      
    }
   
    return (
    <Row gutter={8}>
        <Col span={11}>
            <Form.Item
                label="Network (SSID)"
                name={"ssidname"+ props.index }                                         
                shouldUpdate={true}                                 
                rules={[
                    { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                    //{ validator:(_, value) => (value.length || 0) > 1 ? Promise.resolve() : Promise.reject(appConfigs.errors.fieldErrors.atLeastTwoCharacters) }
                ]}
            >
                 <Input placeholder="SSID Name" suffix={props.connected && <span className="asset-status-green pr-5" ><CheckCircleOutlined /></span>} maxLength={255} disabled={false}></Input>
            </Form.Item>
        </Col>                        
        <Col span={11}>
            <Form.Item
                label="Password"
                name={"password"+ props.index}                             
                rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
            >
                <Input.Password placeholder="Password" maxLength={255} disabled={false}></Input.Password>
                
            </Form.Item>
        </Col> 
        <Col span={2}>
                <Button type="primary" name={props.index.toString()} disabled={props.index===0 && props.Name === undefined && props.Password === undefined} onClick={(e:any)=>RemoveSSID(e.currentTarget.name)} icon={<MinusOutlined></MinusOutlined>}></Button>
            
        </Col>                   
    </Row>
    // </Form>
    )
  }
  const mapStateToProps = (state: any) => {
    return {
        context: state.contextReducer.context,
        contractMaintOptions: state.contractsReducers.contractsState.contracts.contractMaintOptions
    };
};
  export default connect(
    mapStateToProps,
    actions
)(WiFiConnect);