
import React, { useContext } from "react"
import { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import MapContext from "./MapContext"
import { connect } from "react-redux";
import * as actions from "./actions";

interface OverLayProps {
    // children: JSX.Element|JSX.Element[],
    children?: React.ReactNode
    context: any,
    permissions: any,
    fleetOverviewState: any,
    geofencesList: any,
    permissionsset: any,
    toggleMarkerToolTip: Function,
    updateToolTipOverlay: Function

}


class OverlayViewContainer extends React.Component<OverLayProps> {

    public overlay:any = new google.maps.OverlayView();
    public el:any = null;    
    
    componentWillUnmount() {
        this.overlay.setMap(null);
        this.props.toggleMarkerToolTip(false)
        if(this.props.fleetOverviewState.tooltip.overlay){
            this.props.fleetOverviewState.tooltip.overlay.setMap(null)
        }
        delete this.overlay
    }

    render() {

        return (
            <MapContext.Consumer>
            {(map: any) => {
                if (map && this.props.fleetOverviewState.tooltip.marker?.position && this.props.fleetOverviewState.tooltip.visibility) {
                    
                    this.el = this.el || createOverlayElement();

                    let me = this;

                    this.overlay.onAdd = function() {
                        me.props.toggleMarkerToolTip(true)
                        this.getPanes().floatPane.appendChild(me.el);
                    };

                    this.overlay.onRemove = function() {
                        if (me.el.parentElement) {
                            me.props.toggleMarkerToolTip(false)
                            me.el.parentElement.removeChild(me.el);
                        }
                    };

                    this.overlay.draw = function() {
                        const divPosition = this.getProjection().fromLatLngToDivPixel(
                            me.props.fleetOverviewState.tooltip.marker.position
                        );

                        var divWidth = document.getElementsByClassName("map-marker-overview")[0]?.clientWidth;
                        var divHeight = document.getElementsByClassName("map-marker-overview")[0]?.clientHeight;
                        // console.log(`divWidth`, divWidth)
                        // console.log(`divHeight`, divHeight)

                        //TODO: if the opened tooltip is outside of map borders then move the map to fit the tooltip 

                        me.el.style.left = (divPosition.x - (divWidth/2)) + 'px';
                        me.el.style.top = (divPosition.y - (divHeight + (divHeight * 0.12))) + 'px';

                    };
                    
                    if(this.overlay){
                        this.overlay.setMap(map.map);
                        this.props.updateToolTipOverlay(this.overlay)
                        return ReactDOM.createPortal( this.props.children, this.el);
                    } else {
                        return null
                    }

                    
                } else {
                    return null;
                }
            }}
            </MapContext.Consumer>
        );
    }
}


function createOverlayElement() {
    const el = document.createElement('div');
    el.classList.add("map-marker-overview");
    el.style.position = 'relative';
    el.style.display = 'inline-block';
    el.style.margin = '0 0 0 0';
    el.style.padding = '0 0 0 0';
    el.style.width = '350px';
    el.style.zIndex = '19999';
    return el;
}


const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        permissions: state.contextReducer.data,
        fleetOverviewState: state.fleetOverviewReducers.fleetOverviewState,
        geofencesList: state.geofencesReducer.geofencesState.geofencesList,
        permissionsset: state.permissionsReducer      
    };
};

export default connect(
    mapStateToProps,
    actions
)(OverlayViewContainer);