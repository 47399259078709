/// <reference path="../../../global.d.ts" />
import React, { Component }  from "react";
import { Form, Row, Col, Input, Select, message, Card, Typography, Space,Alert,Tag, Badge, Checkbox,Switch, Tooltip, InputNumber, Collapse} from 'antd';
import { PhoneOutlined, MailOutlined, MobileOutlined, EyeInvisibleOutlined,EyeOutlined, EyeInvisibleFilled, FontSizeOutlined } from "@ant-design/icons";
import { FormInstance } from 'antd/lib/form';
import { KeyOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { appConfigs } from "../../../utils/configurations";
import { successResponse, errorResponse, resetFormErrors } from "../../../utils/apiHelpers/apiHelpers";
import { formInit } from "../../../utils/apiHelpers/formHelpers";
import { SuccessAlert, ErrorAlert } from "../../../utils/Components/formAlerts";

import { connect } from "react-redux";
import * as actions from "../actions";
import { getPermissions } from "../../../utils/commonHelpers";
import { ReactComponent as OperatorIcon } from "../../../assets/images/operator.svg";
import Icon from "@ant-design/icons";
import AssetHistory from "./AssetHistory";

const { Option } = Select;
const { Paragraph } = Typography;
const { Panel } = Collapse;

interface Prop {
    assetMaint: Function,
    assetMaintTabsEnable: Function,
    eligibleAssets: Function,
    eligibleAssetsLov: Function,
    getOrgTags: Function,
    getFilterTags: Function,
    assetsListRefreshOnUpdate: Function,
    assetsMaintSetTitle: Function,
    assetTemplatesGet: Function,
    assetOrgGet: Function,
    assetIqanConnectKey: Function,
    IqanConnectKeyDeviceTwin: Function,    
    assetwifipassword: Function,
    context: any,
    assetsList: any,
    guestRole: boolean,
    permissions: any,
    onMtagReplacement:Function,
    assetMaintFormRef:Function
}

interface State {
    orgId: string,
    formError?: boolean;
    formErrorMessage?: string[];
    formSuccess?: boolean;
    formSuccessMessage?: string;
    formCommit?: boolean;
    planQuotaId: number;
    quotaName: string;
    partNumber: string;
    packageId: any;
    noOfSignals: number;
    minCollectionFreq: number;
    maxDayDm1: number;
    mastertag: string;
    status: string;
    partNumberDesc: string; 
    hideShowWifiPwd: boolean;    
    enableIQAN: boolean;
    allowViewSleepMode: boolean;
    enableMtag: boolean; 
}

class AssetMaintInfo extends React.Component<Prop, State> {  
        
    state: State = {
        orgId: "",
        planQuotaId: 1,
        quotaName : "Unlimited", 
        partNumber: "",
        packageId: "",
        noOfSignals: -1,
        minCollectionFreq: -1,
        maxDayDm1: -1, 
        mastertag: "",
        status: "",
        partNumberDesc:"",
        hideShowWifiPwd: false,
        enableIQAN:false, 
        // enableSleepMode: false, 
        // sleepModeInSeconds: "", 
        allowViewSleepMode: false,  
        enableMtag:false    
    };
    
    formRef = React.createRef<FormInstance>();

    
    
    componentDidMount() {    
        this.props.assetMaintFormRef(this.formRef);
        if (this.props.assetsList.assetMaint.visible) {           
            this.componentUpdate(this.props.assetsList.assetMaint.record);
        }
    }

    componentDidUpdate(prevProps: Prop) {
        if (this.props.assetsList.assetMaint.tabs[0].instance !== prevProps.assetsList.assetMaint.tabs[0].instance) {
            this.componentUpdate(this.props.assetsList.assetMaint.record);
        }
    }

    componentUpdate(record:any) {
        if ((record.orgId || "") === "") {
            if (record.gatewayId) {
                this.props.eligibleAssets(this, this.eligibleAssetsSuccess, null, null);
            } else {
                // Fast performance of mastertag tag drop down list when create a new asset from whitelisted,inactive state
                //ops-3368:While creating whitelisted asset, we are getting inactive master-tags.
                //this.props.eligibleAssetsLov(this, this.eligibleAssetsLovSuccess, null, "whitelisted,inactive", null);
                                
                this.props.eligibleAssetsLov(this, this.eligibleAssetsLovSuccess, null, "whitelisted", null);
            }
           
            this.props.assetOrgGet(this, this.assetOrgGetSuccess, null, null/*record.orgId*/);
        }
        if ((record.orgId || "") !== "") {
            {/* Rollback gateway replacement changes */}
            this.props.eligibleAssetsLov(this, this.eligibleAssetsLovSuccess, null, "whitelisted,inactive", null);
            this.props.assetOrgGet(this, this.assetOrgGetSuccess, null, null/*record.orgId*/);
        }
        /* OPS-2271: When a null template or no template assigned to asset, allow user to
        assign a template for that asset. So, removing/commenting below if condition*/
        //if ((record.templateId || "") !== "") {
            this.props.assetTemplatesGet(this, this.assetTemplatesGetSuccess, null, null, record.mastertag /*record.templateId*/, record.orgId, null);
            this.props.getOrgTags(this, null, null, 'all', record.orgId);
        //}
        if (this.props.assetsList.assetMaint.record.objectAdmin && (record.gatewayId || "") !== "" && (record.status || "") !== "whitelisted") {
            this.props.getOrgTags(this, null, null, 'all', record.orgId);
        }
        
        // if the user has iqan_key permission but not sub-org permission
        // the user shall be able to view iqan key in the current org
        if ((record.gatewayId || "") !== "" && (record.status || "") !== "whitelisted") {
            
            // ops-3589: Getting IqanConnect key from Devicetwin.
            //this.props.assetIqanConnectKey(this, null, null, record.gatewayId);
            this.props.IqanConnectKeyDeviceTwin(this,null, null, record.mastertag);
           
            //ops-3663,3801: gateway wifi password         
            this.props.assetwifipassword(this,null,null,record.mastertag);
        }

        formInit(this);     
        if (this.formRef.current != null) {
            this.formRef.current!.resetFields();    
            this.formRef.current!.setFieldsValue({
                gatewayId: record.gatewayId,
                nickname: record.nickname,
                mastertag: record.mastertag,
                mac: record.mac,
                model: record.model,
                templateId: record.templateId,
                orgId: record.orgId,
                tags: record.tags?.map((tag:any) => { return {value: tag.tagName.toUpperCase(), label: tag.tagName.toUpperCase(), key: tag.tagName.toUpperCase()} }),
                mode: (record.gatewayId || "") !== "" ? "update" : "add", 
                planQuotaId: record.planplanQuotaId,
                quotaName: record.planQuotaName,
                partNumber: record.partNumber,
                noOfSignals: record.noOfSignals,
                minCollectionFreq: record.minCollectionFreq,
                maxDayDm1: record.maxDayDm1,
                customFieldId:record.customFieldId,
                enableIQANConnect:record.enableIQANConnect,
                iqanConnectTimeOut:record.iqanConnectTimeOut,
                hdnEnableIQANConnect:record.enableIQANConnect,
                hdnIQanTimeout:record.iqanConnectTimeOut, 
                allowViewSleepMode: record.allowViewSleepMode,
                enableMtag:false
                // enableSleepMode:record.enableSleepMode,
                // sleepCycleInSeconds:record.sleepCycleInSeconds
            });
            this.setState({orgId: record.orgId, planQuotaId: record.planQuotaId, quotaName: record.quotaName, partNumber: record.partNumber, 
                noOfSignals: record.noOfSignals, minCollectionFreq: record.minCollectionFreq, maxDayDm1: record.maxDayDm1, 
                mastertag: record.mastertag ,status: record.status, partNumberDesc:record.partNumberDesc,enableIQAN:record.enableIQANConnect,
                allowViewSleepMode: record.allowViewSleepMode,enableMtag:false
            });                
                
             this.props.onMtagReplacement(false);
        }
    };

    assetOrgGetSuccess = (orgId: string, data: any) => { 
        if ((data.length || 0) === 0) {
            this.formRef.current!.setFieldsValue({
                orgId: ""
            });
        }
    };

    assetTemplatesGetSuccess = (data: any) => { 
        if ((data.length || 0) === 0) {
            this.formRef.current!.setFieldsValue({
                templateId: ""
            });
            if (this.state.planQuotaId > 1) {
                let interval;
                let intervalUnit;
                if (this.state.minCollectionFreq > -1 && this.state.minCollectionFreq % 3600 == 0) {
                    interval =  this.state.minCollectionFreq /3600;
                    intervalUnit = 'h';
                } else if ( this.state.minCollectionFreq % 60 == 0) {
                        interval =  this.state.minCollectionFreq /60;
                        intervalUnit = 'm';
                } else {
                         interval =  this.state.minCollectionFreq; 
                         intervalUnit = 's'; 
                }
                let intervalMsg = interval + intervalUnit; 
                message.error("No eligible template meets min " 
                + intervalMsg + " collection freq and max " +
                this.state.noOfSignals + " signals");
            }
        }
    };

    eligibleAssetsSuccess = (data: any) => { 
        if ((data.length || 0) === 0) {
            message.warning("No eligible assets were found to create");
        }
    }

    eligibleAssetsLovSuccess = (data: any) => { 
        if ((data.length || 0) === 0) {
            message.warning("No eligible assets were found to create");
        }
    }

    onFinish = (values: any) => { 
        resetFormErrors(this, this.formRef.current);   
        this.props.assetMaint(this, this.onFinishSuccess, this.onFinishFailure, values);
    }

    onFinishSuccess = (record: any) => { 
        this.props.assetsMaintSetTitle(this, "Asset" + (record.nickname ? " :: " + record.nickname : ""));
        
        this.props.getFilterTags(this, null, null, 'all');
        {/* Rollback gateway replacement changes */}
        let isEnableMtag:boolean=this.formRef.current!.getFieldValue("enableMtag");
        this.componentUpdate(record);

        if ((record.gatewayId || "") !== "") {
           this.props.assetMaintTabsEnable(this);
        }
        {/* Rollback gateway replacement changes */}
        let successMsg:string=!isEnableMtag?"Asset has been successfully processed":"Asset has been replaced successfully";
       // let successMsg:string="Asset has been successfully processed";
        successResponse(this, successMsg); 
    }

    onFinishFailure = (error: any) => { 
        errorResponse(this, error);
    }
    

    onMastertagChange = (value: string, option:any) => {
        if ((value || "") !== "") {     

            this.props.assetsList.assetMaint.tabs[0].assetsEligible.forEach((record: any) => {     
                {/* Rollback gateway replacement changes */}
                if (record.mastertag === value && !this.state.enableMtag) {
               // if (record.mastertag === value ) {
                    this.props.assetOrgGet(this, null, null, record.orgId);
                    this.setState({ orgId: record.orgId });
                    this.state.planQuotaId = record.planQuotaId;
                    this.state.quotaName = record.quotaName;
                    this.state.partNumber = record.partNumber;
                    this.state.packageId= record.packageId;
                    this.state.noOfSignals = record.noOfSignals;
                    this.state.minCollectionFreq = record.minCollectionFreq;
                    this.state.maxDayDm1 = record.maxDayDm1;
                    this.state.mastertag = record.mastertag;
                    this.setState({status: record.status});
                    this.state.partNumberDesc=record.partNumberDescription;
                    this.setState({orgId: record.orgId});
                    this.formRef.current!.setFieldsValue({ gatewayId: option.key, orgId: record.orgId, templateId: null });
                    this.props.assetTemplatesGet(this, this.assetTemplatesGetSuccess, null, null, value, null, null); 
                }
            });
        {/* Rollback gateway replacement changes */} 
            if(this.state.mastertag!==value && this.state.enableMtag)   {
                this.props.onMtagReplacement(true);
                this.formRef.current!.setFieldsValue({enableMtag:true});
           }             
       }
       else {
            this.setState({ orgId: "" });
            this.setState({status: ""});
            this.formRef.current!.setFieldsValue({ gatewayId: "", orgId: "", templateId: null });
       }
    }
    
    onOrgIdChange = (value: string) => {
        this.props.assetTemplatesGet(this, this.getTemplatesSuccess, null, null, this.formRef.current?.getFieldValue('mastertag'), value, null);
        this.props.getOrgTags(this, null, null, 'all', value);
    }

    onTemplateIdChange = (value: string) => {
        if ((value || "") !== "") {
           this.formRef.current!.setFieldsValue({templateId:value});
        }
    }

    getTemplatesSuccess = (data: any) => {
        const templateId = this.props.assetsList.assetMaint.record.templateId;
        const findTemplateIndex = data.findIndex((t: any) => t.templateId === templateId);
        if(findTemplateIndex > -1) {
            this.formRef.current!.setFieldsValue({
                templateId: templateId
            });
        } else {
            this.formRef.current!.setFieldsValue({
                templateId: ""
            });
            if ((data.length || 0) === 0 && this.state.planQuotaId > 1) {
                let interval;
                let intervalUnit;
                if (this.state.minCollectionFreq > -1 && this.state.minCollectionFreq % 3600 == 0) {
                    interval =  this.state.minCollectionFreq /3600;
                    intervalUnit = 'h';
                } else if ( this.state.minCollectionFreq % 60 == 0) {
                        interval =  this.state.minCollectionFreq /60;
                        intervalUnit = 'm';
                } else {
                         interval =  this.state.minCollectionFreq; 
                         intervalUnit = 's';
                }
                let intervalMsg = interval + intervalUnit; 
                message.error("No eligible template meets the min " 
                + intervalMsg + " collection freq and max " +
                this.state.noOfSignals + " signals" );
            }
        }        
        // this.formRef.current!.setFieldsValue({
        //     tags: []
        // });
    }
    
    iqanConnectKeyRender = (status: string, value: string) => {
        switch(status) {
            case "signal-not-exists":
                return "Not Configured";
            case "signal-no-value":
                return "Configuration Error";
            case "signal-value-null":
                return "Not Detected";
            case "signal-value-error":
                return "Signal Data Processing Error";
            case "signal-value-invalid":
                return "Signal Detected, Value Invalid: " + value;
            case "signal-value":
                return value;
            case "true":
                return value;
            case "signal-value-redis":
                return value;
            case "false":
                return "IQAN Connect key is not available";
            default:
                return "Unknown Status";
        }
    }
    
    getLiteTemplateDetails=()=>{
        return <span style={{color:'#0052CC',fontSize:'12px'}}>{"Max no of Signals: " + this.state.noOfSignals + " | " + "Min Collection Frequency: " + this.state.minCollectionFreq /3600+"Hrs" + " | " + "Max DM1s per Day: " + this.state.maxDayDm1}</span>;
    }

    getMtagLiteDetails=()=>{
       return <span style={{color:'#0052CC',fontSize:'12px'}}>{"Part Number: " + this.state.partNumber + " | " + "Description: " +  (this.state.partNumberDesc||"NA")}</span>
    }

    hideShowWifiPassword =()=>{

        if(this.state.hideShowWifiPwd){
            this.setState({hideShowWifiPwd:false});
        }
        else{
            this.setState({hideShowWifiPwd:true});
        }       
    }

    iqanConnectHandleChange=(checked:any)=>{
        this.setState({ enableIQAN: checked });  
        let timeOutValue=this.props.assetsList.assetMaint.record.iqanConnectTimeOut!==null
                             ?this.props.assetsList.assetMaint.record.iqanConnectTimeOut:0;
        this.formRef.current!.setFieldsValue({
            iqanConnectTimeOut:checked?timeOutValue:0
        });
        
    }
    /* Rollback gateway replacement changes */
    onMtagReplacement = (checked:any) => {
        this.props.onMtagReplacement(false);
        this.formRef.current!.setFieldsValue({enableMtag:false});
        this.setState({ enableMtag: checked,formSuccess:false });  
        if(!checked)
        {
            this.componentUpdate(this.props.assetsList.assetMaint.record);
        }
    };   

    onSubmit=()=>{
        this.formRef.current!.submit();
    }

   render() {      
        const { assetMaint } = this.props.assetsList;    
        let barkoGuest = this.props.guestRole;       
        let isAdmin = this.props.context.user.contextUserIsAdmin;  
        //ops-2839: Remove the iqanconnect key permission check  
        //const iqankey = getPermissions(this.props.permissions.permissions.iqan_key);
        //let isiqankey = iqankey[1];
        const ParkerOrgId = 'c6adcb40-be92-11e6-9ed6-a5bc9cb5279b'

        const result = getPermissions(this.props.permissions.permissions.operator_support);
        let isOprRead = result[1];

        const tagPermissions = getPermissions(this.props.permissions.permissions.asset_tagging);
        let canTagCreate = tagPermissions[0];
        let canTagRead = tagPermissions[1];
        let canTagDelete = tagPermissions[3];

        let ishideShow = this.state.hideShowWifiPwd;
       
        const checkTagsValidations = async (rule: any, options: any) => {
            let newValues:any = [];
            let existingValues = [];
            newValues = options?.map((o:any) => o.value);
            existingValues = assetMaint.record.tags?.map((o: any) => o.tagName);
            if (!canTagDelete && existingValues !== undefined) {
                if (!existingValues.every((tag: any) => newValues.includes(tag))) {
                    return Promise.reject(`you do not have enough permission privilege to delete tags`);
                }
            }
            if (options?.length) {
                if (canTagCreate) {
                    if (options?.length > appConfigs.app.assetTags.maxAssetTags) {
                        options.pop();
                        return Promise.reject(`Maximum of ${appConfigs.app.assetTags.maxAssetTags} tags allowed`);
                    }
                    if (options[options.length - 1]?.value.length > appConfigs.app.assetTags.maxAllowCharactersPerTags) {
                        options.pop();
                        return Promise.reject(`Maximum of ${appConfigs.app.assetTags.maxAllowCharactersPerTags} characters allowed per tags`);
                    }
                } else {
                    //if we are deleting don't remove additional tag
                    if (existingValues === undefined) {
                        existingValues = []
                    }
                    if (options?.length > assetMaint.record.tags?.length || existingValues?.every((tag: any) => !newValues.includes(tag))) {
                        options.pop();
                        return Promise.reject(`you do not have permission to add tags`);
                    }
                }
            }
            return Promise.resolve();
        };
       
        return (

            <>
                {this.state.formSuccess && !this.state.formError && <SuccessAlert data-id="assets-maint-info-success-message" message={this.state.formSuccessMessage}/>}
                {this.state.formError && !this.state.formSuccess && <ErrorAlert data-id="assets-maint-info-error-message" message={this.state.formErrorMessage}/>}
                
                <Row gutter={8} style={{marginTop: 15}}>
                    <Col offset={1} span={23}>
                        <Form
                            id="assetMaintInfoForm"
                            ref={this.formRef}
                            layout="vertical"
                            onFinish={this.onFinish}
                        >       
                            <Row gutter={8}>
                                <Col span={24}>
                                <Form.Item 
                                        name="hdnEnableIQANConnect"
                                        style={{ display: 'none' }}
                                    >
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Form.Item 
                                        name="hdnIQanTimeout"
                                        style={{ display: 'none' }}
                                    >
                                        <Input type="hidden" />
                                    </Form.Item>
                                <Form.Item 
                                        name="customFieldId"
                                        style={{ display: 'none' }}
                                    >
                                        <Input type="hidden" />
                                    </Form.Item>
                                    <Form.Item 
                                        name="gatewayId"
                                        style={{ display: 'none' }}
                                    >
                                        <Input type="hidden" />
                                    </Form.Item>

                                    <Form.Item 
                                        name="mode"
                                        style={{ display: 'none' }}
                                    >
                                        <Input type="hidden" />
                                    </Form.Item>
                                    {/* Rollback gateway replacement changes */}
                                    <Form.Item 
                                        name="enableMtag"
                                        style={{ display: 'none' }}
                                    >
                                        <Input type="hidden" />
                                    </Form.Item>
                                    {
                                    assetMaint.record.status==='active' && isAdmin &&
                                    <Tooltip placement="topRight" title="Replace Asset">
                                    <Form.Item style={{marginTop:'-15px',flexDirection:'row',marginLeft:'535px'}}   label="Replace Asset: " valuePropName="checked">
                                  
                                          <Switch checked={this.state.enableMtag} onChange={this.onMtagReplacement} style={{margin:'0px 0px 5px 5px'}}/>
                                         
                                    </Form.Item>
                                    </Tooltip>
                                   }
                                    <Form.Item
                                        label="Master Tag"
                                        name="mastertag"                                 
                                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >
                                        <Select 
                                            data-id="assets-maint-asset-info-mastertag"
                                            showSearch
                                            showArrow={true}
                                            optionFilterProp="children"
                                            className="select-before" 
                                            placeholder="Select..."
                                            onChange={this.onMastertagChange}
                                            allowClear
                                            disabled={(assetMaint.record.gatewayId || assetMaint.record.status == 'inactive') && !this.state.enableMtag
                                        }
                                        > 
                                        {/* Rollback gateway replacement changes */}                                           
                                             {
                                                !this.state.enableMtag &&
                                                assetMaint.tabs[0].assetsEligible.map((record:any, index:number) => ( 
                                                <Option key={record.gatewayId} value={record.mastertag}>
                                                    {record.mastertag}
                                                </Option>
                                                ))
                                            }
                                            {
                                                this.state.enableMtag &&
                                                assetMaint.tabs[0].assetsEligible.filter((a:any) => {return a.status==="whitelisted"})
                                                .map((record:any, index:number) => (
                                                    (record.packageId===5 && assetMaint.record.orgId === record.orgId)?
                                                    <Option key={record.gatewayId} value={record.mastertag}>
                                                        {record.mastertag}
                                                    </Option>:""
                                                ))
                                            } 
                                        </Select>  
                                    </Form.Item>    
                                </Col>
                                {/* Rollback gateway replacement changes */}
                               {
                                this.state.enableMtag &&
                                <Col span={24}>
                                <Form.Item
                                        label="Reason for Replacement"
                                        name="status"                                 
                                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                    >
                                        <Select 
                                            data-id="assets-maint-asset-info-mastertag-replacement-reason"
                                            showSearch
                                            showArrow={true}
                                            optionFilterProp="children"
                                            className="select-before" 
                                            placeholder="Select..."
                                            allowClear
                                        >                                                   
                                                <Option key="faulty" value="faulty">Faulty</Option>
                                                <Option key="migrate-from-3G-to-4G" value="migrate-from-3G-to-4G">Migrate from 3G to 4G</Option>
                                                <Option key="other" value="other">Other</Option>
                                        </Select>  
                                    </Form.Item>
                                </Col>
                                }
                                <div className="lite-info">
                                {
                                         this.state.partNumber &&
                                         this.state.minCollectionFreq > 0 &&
                                         this.getMtagLiteDetails()
                                }  
                             </div>  
                            </Row>   
                          
                            {
                                (assetMaint.record.status || "") !== "whitelisted" &&
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Asset Name"
                                            name="nickname"    
                                            shouldUpdate={true}                                 
                                            rules={[
                                                { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                                                //{ validator:(_, value) => (value.length || 0) > 1 ? Promise.resolve() : Promise.reject(appConfigs.errors.fieldErrors.atLeastTwoCharacters) }
                                            ]}
                                        >
                                             <Input maxLength={255} disabled={!this.formRef.current?.getFieldValue("mastertag") || assetMaint.record.status == 'inactive' || this.state.enableMtag }
                                             />
                                        </Form.Item>
                                    </Col>                        
                                    <Col span={12}>
                                        <Form.Item
                                            label="Serial Number"
                                            name="mac"                                 
                                            rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                        >
                                            <Input maxLength={255} disabled={!this.formRef.current?.getFieldValue("mastertag") || barkoGuest || assetMaint.record.status == 'inactive' ||this.state.enableMtag} 
                                            />
                                        </Form.Item>
                                    </Col> 
                                </Row>     
                            } 
                            {
                                (assetMaint.record.status || "") !== "whitelisted" &&                 
                                <Row gutter={8}>
                            
                                    <Col span={12}>
                                    <Form.Item
                                        label="Asset Model"
                                        name="model"
                                    >
                                        <Input maxLength={255} disabled={!this.formRef.current?.getFieldValue("mastertag") || barkoGuest || assetMaint.record.status == 'inactive' || this.state.enableMtag} 
                                        />
                                    </Form.Item>
                                    </Col>
                                    <Col span={12}>                                
                                    <Form.Item 
                                        label="Org Name"
                                        name="orgId"                                                
                                    >
                                         <Select 
                                            data-id="assets-maint-asset-info-org-id"
                                            showSearch
                                            showArrow={true}
                                            optionFilterProp="children"
                                            className="select-before" 
                                            placeholder="Select..."
                                            allowClear
                                            onChange={this.onOrgIdChange}
                                            disabled={
                                                !this.formRef.current?.getFieldValue("mastertag") ||
                                                !assetMaint.record.gatewayId || barkoGuest || assetMaint.record.status == 'inactive' ||this.state.enableMtag
                                            }       
                                        >
                                            {assetMaint.tabs[0].orgs !== null && assetMaint.tabs[0].orgs.map((record:any, index:number) => (
                                                <Option key={index} value={record.orgId}>                                                    
                                                    {record.name}
                                                </Option>
                                            ))}
                                        </Select>                                          
                                    </Form.Item>  
                                </Col> 
                                </Row>
                            }
                            {
                                (assetMaint.record.status === "whitelisted") &&
                                <Row gutter={8}>
                                     <Col span={24}>
                                     <Form.Item 
                                        label="Org Name"
                                        name="orgId"                                                
                                    >
                                         <Select 
                                            data-id="assets-maint-asset-info-org-id"
                                            showSearch
                                            showArrow={true}
                                            optionFilterProp="children"
                                            className="select-before" 
                                            placeholder="Select..."
                                            allowClear
                                            onChange={this.onOrgIdChange}
                                            disabled={
                                                !this.formRef.current?.getFieldValue("mastertag") ||
                                                !assetMaint.record.gatewayId || barkoGuest ||this.state.enableMtag
                                            }       
                                        >
                                            {assetMaint.tabs[0].orgs !== null && assetMaint.tabs[0].orgs.map((record:any, index:number) => (
                                                <Option key={index} value={record.orgId}>
                                                    {record.name}
                                                </Option>
                                            ))}
                                        </Select>                                          
                                    </Form.Item> 

                                     </Col>
                                </Row>
                            }
                                   
                            {
                                (!assetMaint.record.gatewayId || (this.state.status || "") === "whitelisted") &&
                                (assetMaint.record.status || "") !== "whitelisted" &&
                                <Row gutter={8}>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Template"
                                            name="templateId"                                 
                                            rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                        >            
                                         <Select 
                                                data-id="assets-maint-asset-info-template-id"
                                                showSearch
                                                showArrow={true}
                                                optionFilterProp="children"
                                                className="select-before" 
                                                placeholder="Select..."
                                                onChange={this.onTemplateIdChange}
                                                allowClear
                                                disabled={
                                                    this.state.packageId === 5 || this.state.packageId === 6 || !this.formRef.current?.getFieldValue("mastertag") || barkoGuest || assetMaint.record.status == 'inactive' || this.state.enableMtag
                                                }   
                                            >           
                                                {assetMaint.tabs[0].templates !== null && assetMaint.tabs[0].templates.map((record:any, index:number) => (
                                                    record.templateVisibleToSubOrg ?                                                    
                                                        <Option key={index} value={record.templateId} style={{color: "blue"}}>
                                                            {record.name}                                          
                                                        </Option>
                                                     : 
                                                    <Option key={index} value={record.templateId}>
                                                            {record.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                            { (this.state.packageId === 5 || this.state.packageId === 6) &&
                                                <span style={{color:'#0052CC',fontSize:'14px'}}>{"Require Service Plan to use the asset"}</span> 
                                            }    
                                        </Form.Item>   
                                    </Col> 
                                    <div className="lite-info">
                                    {
                                             this.state.partNumber &&
                                             this.state.minCollectionFreq > 0 &&
                                             this.getLiteTemplateDetails()
                                    }
                                    </div>
                                </Row>  
                            }                                   
                            {
                                assetMaint.record.gatewayId &&
                                (assetMaint.record.status || "") !== "whitelisted" &&
                                (this.state.status || "") !== "whitelisted" &&
                                <Row gutter={8}>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Template"
                                            name="templateId"                                 
                                            rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                                        >            
                                         <Select 
                                                data-id="assets-maint-asset-info-template-id"
                                                showSearch
                                                showArrow={true}
                                                optionFilterProp="children"
                                                className="select-before" 
                                                placeholder= {this.state.partNumber == "IOT10680180LSP" ? "Cannot assign template to WiFi only device" : "Select..."}
                                                allowClear
                                                disabled={
                                                    !this.formRef.current?.getFieldValue("mastertag") || barkoGuest || assetMaint.record.status == 'inactive' || this.state.enableMtag || this.state.partNumber == "IOT10680180LSP"
                                                }   
                                            >           
                                                {assetMaint.tabs[0].templates !== null && assetMaint.tabs[0].templates.map((record:any, index:number) => (
                                                    record.templateVisibleToSubOrg ?                                                    
                                                        <Option key={index} value={record.templateId} style={{color: "blue"}}>
                                                            {record.name}                                          
                                                        </Option>
                                                     : 
                                                    <Option key={index} value={record.templateId}>
                                                            {record.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>   
                                    </Col> 
                                    <div className="lite-info">
                                    {
                                             this.state.partNumber &&
                                             this.state.minCollectionFreq > 0 &&
                                             this.getLiteTemplateDetails()
                                    }
                                    </div>
                                </Row>  
                            }                                        

                            {

                                (canTagRead) && 
                                <Row gutter={8}>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Custom Tags"
                                            name="tags"
                                            initialValue={assetMaint.record.tags?.map((tag: any) => { return { value: tag.tagName.toUpperCase(), label: tag.tagName.toUpperCase(), key: tag.tagName.toUpperCase() } })}
                                            rules={[{ validator: checkTagsValidations }]}
                                        >            
                                        <Select 

                                                mode="tags"
                                                style={{ width: '100%', textTransform: "uppercase" }}
                                                placeholder="Select Tags"
                                                disabled={ (!canTagCreate && !canTagDelete) || assetMaint.record.status == 'inactive' ||this.state.enableMtag
                                                }
                                                labelInValue
                                        >
                                            {assetMaint.tabs[0].orgTags?.map((tagName:any, index:number) => (
                                                <Option key={index} value={tagName.toUpperCase()}>
                                                    {tagName.toUpperCase()}
                                                </Option>
                                            ))}
                                        </Select>  
                                        </Form.Item>   
                                    </Col>
                                    <Col span={12} >
                                    
                                   
                                </Col> 
                                </Row>
                            }
                            { // ops-2839, Removed the Iqanconnect key permission check: Allow a user with iqan_key read permission to read iqan_key in home org even though it does not have sub-org permission
                             //ops-2839: Remove the iqanconnect key permission check 
                                (assetMaint.record.status || "") !== "whitelisted" &&
                                this.formRef.current?.getFieldValue("gatewayId") &&
                                (assetMaint.iqanConnectKey.status || "access-forbidden") !== "access-forbidden" &&
                                <Card 
                                    data-id="assets-maint-asset-info-iqan-connect-key"
                                    size="small" 
                                    title={
                                        <>
                                            <KeyOutlined className="pr-5"/> 
                                            <span>IQAN Connect Key</span>
                                        </>
                                    }
                                    className="mt-10"
                                >
                                    <Paragraph copyable className="mt-10">
                                        {
                                        assetMaint.iqanConnectKey.value ? assetMaint.iqanConnectKey.value :
                                        this.iqanConnectKeyRender(assetMaint.iqanConnectKey.status, assetMaint.iqanConnectKey.value)
                                        }
                                    </Paragraph>
                                    {
                                    assetMaint.record.deviceNotExistsInIoT &&
                                    <Alert
                                    message="Asset is not available in IoT hub"
                                  
                                    type="info"
                                    showIcon
                                  />
                                  
                                   }
                               {
                                // this.props.context.appContext.orgId === 'c6adcb40-be92-11e6-9ed6-a5bc9cb5279b' &&
                                <Row gutter={8}>
                                   
                                    <Col span={6}>
                                    <Form.Item
                                    label="IQAN Connect"
                                                        name="enableIQANConnect"
                                                        valuePropName="checked"
                                                        >
                                                        <Switch onChange={this.iqanConnectHandleChange}
                                                            
                                                        />
                                                    </Form.Item> 
                                    </Col>
                                    <Col span={12}>
                                    <Form.Item
                                
                                name="iqanConnectTimeOut"
                                label={(<span>
                                        IQAN Connect Timeout (in min)&nbsp;&nbsp;
                                        <Tooltip title="if a timeout value is specified, the IQAN Connect program on gateway will stop after this timeout period. If the timeout is specified as zero, the IQAN Connect will continue running until disabled from this UI.">
                                        <InfoCircleOutlined />
                                </Tooltip>
                                </span>)}
                                
                            >
                                <InputNumber 
                                    min={0}
                                    max={1440}
                                    pattern="[0-9]*"
                                    disabled={!this.state.enableIQAN} 
                                    style={{ width: '70%' }} 
                                    />
                                    </Form.Item>
                                    </Col>
                                    </Row>
                               }     
                            
                                </Card>      
                            }  
                            {
                                isOprRead &&
                                (assetMaint.record.operatorIdValue ||
                                assetMaint.record.accessCardValue) &&

                                <Card
                                    data-id="assets-maint-asset-info-iqan-connect-key"
                                    size="small"
                                    title={
                                        <>
                                            <Space>
                                                <Icon type="setting" component={OperatorIcon} />
                                                {(assetMaint.record.operatorIdValue || "") === "" ?
                                                    <span>RFID</span> : <span>Operator ID</span>
                                                }
                                            </Space>
                                        </>
                                    }
                                    className="mt-10"
                                >
                                    <Paragraph className="mt-10">
                                        {(assetMaint.record.operatorIdValue || "") === "" ?
                                            assetMaint.record.accessCardValue
                                            : assetMaint.record.operatorIdValue
                                        }
                                    </Paragraph>
                                </Card>

                            } 
                             {/* {
                                this.props.context.appContext.orgId == 'c6adcb40-be92-11e6-9ed6-a5bc9cb5279b' &&

                                <Card

                                data-id="assets-maint-asset-info-gateway-password"
                                size="small"
                                title={
                                    <>
                                        <Space>
                                            {this.wifiPasswordLabel}                                        
                                        </Space>
                                    </>
                                }
                                className="mt-10"                                 

                                >
                                    {(ishideShow) &&
                                        <Paragraph copyable className="mt-10"> 
                                            {assetMaint.wifipassword} 
                                            
                                        </Paragraph>
                                    } 
                                </Card>   
                            } */}
                            {
                                //this.props.context.appContext.orgId == 'c6adcb40-be92-11e6-9ed6-a5bc9cb5279b' &&
                                isAdmin &&
                                <Card

                                data-id="assets-maint-asset-info-gateway-password"
                                size="small"
                                title={
                                    <>
                                        <Space>
                                        Wi-Fi Password                                   
                                        </Space>
                                    </>
                                }
                                className="mt-10"                                 

                                >
                                    { assetMaint.wifipassword && assetMaint.wifipassword.length !== 8?
                                        "N/A"
                                        :
                                        ishideShow?
                                        <>
                                        <Row>
                                        <Paragraph copyable={ishideShow?true:false} className="mt-10">
                                             {assetMaint.record.isBspVersion?
                                                "IQAN_Connect":assetMaint.wifipassword
                                             }
                                        </Paragraph>
                                        
                                        <Tooltip title="hide Wi-Fi password">
                                             <EyeInvisibleFilled style={{ margin: '15px 0px 0px 10px'}} onClick={() => this.hideShowWifiPassword()}/>
                                         </Tooltip>
                                        </Row>
                                       
                                        </>
                                        :
                                        <>
                                        <span className="wifi-password">********</span>
                                        <Tooltip title="show Wi-Fi password">
                                        <EyeOutlined style={{marginLeft: '10px'}} onClick={() => this.hideShowWifiPassword()}/>
                                        </Tooltip>
                                     </>
                                    } 
                                </Card>   
                            }
                            {/* Rollback gateway replacement changes */}
                              { 
                                assetMaint.record.status==='active' && 
                                this.props.context.appContext.orgId === ParkerOrgId &&
                                assetMaint.record.assetHistory && 
                                assetMaint.record.assetHistory.length > 0 &&
                                // <Card 
                                //     // style={{display:'none'}}
                                //     data-id="assets-maint-asset-info-iqan-connect-key"
                                //     size="small" 
                                //     title={
                                //         <>
                                           
                                //             <span>Asset Replacement History</span>
                                //         </>
                                //     }
                                //     className="mt-10"
                                // >
                                //     <AssetHistory/>
                                // </Card>      
                                <Collapse  className="mt-30">
                                    <Panel header="Replacement History" key="1">
                                        <AssetHistory/>
                                    </Panel>
                                </Collapse>
                            }

                        </Form>                        
                    </Col>
                </Row>                
            </>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        assetsList: state.assetsReducer.assetsState.assetsList,
        permissions: state.contextReducer.data
    };
};

export default connect(
    mapStateToProps,
    actions
)(AssetMaintInfo);

