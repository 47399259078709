export const initialState = {    
    context:  {
        initialized: false,
        appContext: {
            mode: "",
            orgId: "",
            userId: "",
            roleLevel: "",
            roleName: "",
            roleId:""
        },
        httpRequest: {
            contentType: "",
            host: ""
        },
        token: {
            instance: "",
            type: "",
            value: ""
        },
        orgSettings: {
            headerColor: "#000000",
            fleetMapDefaultView: "",
            logo: ""
        },
        user: {
            active: false,
            avatarUploadAllowed: false,
            avatarUploadId: "",
            avatarUploadSrc: "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==",
            contextUserIsAdmin: false,
            contextUserIsParkerAdmin: false,
            contextUserIsSuperUser: false,
            email: "",
            firstName: "",
            fullName: "",
            homeOrg: {
                logo: null,
                name: "",
                orgId: "",
                orgIdParent: "",
                orgTree: []
            },
            lastName: "",
            locale: "",
            orgHomeRoleId: "",
            oktaUserId: "",
            phoneNumber: "",
            type: "",
            userId: ""
        },
        termsAndConditions: null,
    },
    data: {
        initialized: false, 
        "status": "",
        "timestamp": "",    
        "permissions": {
            alert_settings: "",
            asset_widgets: "",
            assets: "",
            dm1_settings: "",
            global_view: "",
            iqan_key: "",
            master_tag: "",
            my_notifications: "",
            my_profile: "",
            org_options: "",
            org_settings: "",
            reports: "",
            role_settings: "",
            share_orgs: "",
            support_mode: "",
            templates: "",
            user_notifications: "",
            user_profile: "",
            users: "",
            timeout_settings:""
        }
    },
    map: null,
    stats: {
        initialized: false,
        loading: false,
        visible: false,
        timestamp: new Date(),
        data: {},
        dm1DailyCount: null,
        dm1HeatMapData: [],
        assetsWithDm1: []
    },
    notifications: {
        initialized: false,
        loading: false,
        visible: false,
        timestamp: new Date(),
        data: {},
    },
    signalRData: {
        enabled: false,
        data: [],
        SignalRInitialData: []
    }, 
    
    maxCommandPermissionValue: 0,
    gpsCoordinates:{
        latitude:"",
        longitude:""
    }
};
  