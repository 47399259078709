import React from "react";
import { Card, Row } from "antd";

interface Props {
  title?: string;
}

const SectionTitle: React.FC<Props> = (props) => {
  return props.title ? (
    <h3 style={{ marginTop: "18px", marginBottom: "-12px" }}>{props.title}</h3>
  ) : (
    <></>
  );
};

const Container: React.FC<Props> = (props) => {
  return (
    <>
      <SectionTitle title={props.title} />
      <Row>
        <Card style={{ width: "100%" }}>{props.children}</Card>
      </Row>
    </>
  );
};

export default Container;
