import React from "react";
import { CommonWidgetProps } from "../../models";
import { findTimezone, inputSignalMapper } from "../common";
import { connect } from "react-redux";
import { State } from "../../state";
import "./ChatWidget.css"
import { hexToString} from "../../../../utils/commonHelpers";

interface Signal {
  signalId: string;
  values: Array<{
    timestamp: Date;
    value: number;
  }>;
}

const createSignals = inputSignalMapper<Signal>((meta, data) => ({
  signalId: meta.id,
  values: data,
}));

export type TimeSeriesMessage = {
  signalId: string,
  firstName: string, 
  lastName: string,
  type: string, 
  timestamp: Date; 
  text: string 
}

interface ChatPlayWidgetProps extends CommonWidgetProps{
  state: State; 
}

function formatD2CText(v: string): string {
  //  v = "0x2a6d61655420694"; // "0x006d6165542069486d616554206948"
    var msg = hexToString(v);
    // console.log("rawhex", v)
    // console.log("raw msg", msg)
    // remove the deliminator from the single/multi text frame
      if (msg.length <=9) {
        const lastFrameStartPos = msg.length -9;
        // console.log("lastFrameStartPos", lastFrameStartPos)
        const delimiterPos = msg.indexOf("*", lastFrameStartPos);
        // console.log("delimiterPos", lastFrameStartPos)
        if (delimiterPos >=0) { // only applicable to singleframe, since multi-frame does not have delimiter,
            // therefore, multi-frame delimiterPos = -1
            msg = msg.substring(0, delimiterPos );
            // console.log("msg returned", msg)
        } 
      } 
      
      return msg;
}

// O(n log n) time & O(n) space
let mergeTwo = (arr1:TimeSeriesMessage[], arr2:TimeSeriesMessage[]) => {
  let result = [...arr1, ...arr2];

  let sortedResult =  result.sort((a,b) => b.timestamp.getTime()-a.timestamp.getTime());
  
  return sortedResult; 
}

// const getTextSignalValues = (signals: any, state: any) => {
//   const adjustedC2DSeries = [{ firstName:"Me", lastName:"Cloud",  type:"C2D-SELF", timestamp: new Date("2022-06-01T13:00:00"), text:"Morging, guys!"}, 
//   { firstName:"Other", lastName:"Cloud", type:"C2D-OTHER", timestamp: new Date("2021-03-01T13:05:00"), text:"Does any truck need service today?"}, 
//   { firstName:"Me", lastName:"Cloud", type:"C2D-SELF", timestamp: new Date("2022-06-01T13:05:00"), text:"Truck1 had time-to-service alerts "},
//   {  firstName:"Me", lastName:"Cloud",  type:"C2D-SELF", timestamp: new Date("2022-06-01T14:00:00"), text:"The service truck is only available after 2p!"}];//  C2DSeries; 
//   const adjustedD2CSeries =[ 
//   { firstName:"", lastName:"Device", type:"D2C", timestamp: new Date("2022-06-01T13:20:00"), text:"Please send service truck to central station by noon"},
//   { firstName:"", lastName:"Device", type:"D2C", timestamp: new Date("2022-06-01T14:20:00"), text:"See you at 2pm"}]; //  D2CSeries; 
//  let messages = mergeTwo(adjustedC2DSeries, adjustedD2CSeries);

// return messages;

// }

const getD2CMessages = (props: ChatPlayWidgetProps) => {

  var D2CMessages:TimeSeriesMessage[] = []; //getD2CMessages((signals, state )
  
  const { template, data, signals, signalRData } = props;
  let D2CTextSignalValues = createSignals(signals, template, data);
  
   D2CTextSignalValues.map((signal: any) => {
    signal.values.forEach((signalData: any, index: number) => {
      D2CMessages.push({
        signalId: signalData.signalId,
        firstName: signalData.ruleName, 
        lastName: "D",
        type: "D2C",
        timestamp: new Date(signalData.timestamp),
        text: formatD2CText(signalData.value)
      });
    });
  });

  return D2CMessages;
};

const getAllMessages =(( props:ChatPlayWidgetProps) => {
  let d2c:TimeSeriesMessage[] = getD2CMessages(props );
  let c2d:TimeSeriesMessage[] = [];
  const { signals } = props;
  
   signals.map((s) => s.toString()).forEach((widgetSignalId) => {
    props.state.asset.c2dMessages.forEach((msg) => {
       if (widgetSignalId == msg.signalId) {
        c2d.push({
          signalId: msg.signalId,
          firstName: msg.firstName, 
          lastName: msg.lastName,
          text: msg.text,
          type: msg.type,
          timestamp: new Date(msg.timestamp)
        });
      }  
    });
  
  })

  var widgetSignals: TimeSeriesMessage[] = [];
  mergeTwo(c2d, d2c).forEach((msg: TimeSeriesMessage) => {
    widgetSignals.push(msg)
  });
  return widgetSignals;

});

const formatShortDateTime = ((d:Date) => {
  let month = d.getMonth() < 9 ? "0" + (d.getMonth() + 1).toString(): (d.getMonth()+1).toString();
  let day = d.getDate() < 10 ? "0" + (d.getDate()).toString(): (d.getDate()).toString();
  let hr = d.getHours() < 10 ? "0" + (d.getHours()).toString(): (d.getHours()).toString();
  let mi =  d.getMinutes() < 10 ? "0" + (d.getMinutes()).toString(): (d.getMinutes()).toString();
 return  d.getFullYear() + 
    "-" + month + 
    "-" + day +
    " " + hr +
    ":" + mi;
});

const Widget: React.FC<ChatPlayWidgetProps> = (props:ChatPlayWidgetProps) => {

  let widgetSignals: TimeSeriesMessage[] = getAllMessages(props);

  return (
      <div className="widgetcontent">
        {
          widgetSignals && widgetSignals.length > 0 && widgetSignals.map((signal:any) => {
            let details=findTimezone(props.state.timezone);
            let aligns = signal.type==="C2D-SELF"?"left-side":(signal.type==="C2D-OTHER"?"right-side-cloud": "right-side-gateway");
            return (
              <div>
                <div className={aligns}>
                  <strong>{signal.firstName + " " + signal.lastName + "  "}</strong> 
                  <em style= {{ fontSize:"80%"}}>{formatShortDateTime(signal.timestamp).concat(details?.timezone)}</em>
                </div>
                <div className={aligns} >{signal.text}
                </div>
              </div>
            )
        })
      }
      </div>
  );

};

const mapStateToProps = (state: any) => {
  return {
    signalRData: state.contextReducer.signalRData,
  };
};
export default connect(
  mapStateToProps
)(Widget);
