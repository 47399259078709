import Axios from "axios";
import { notification } from 'antd';
import { appConfigs } from "../../../utils/configurations";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";
import { getEmailTemplate,sendEmail,getTemporaryPassword } from "../../../utils/emailNotification/apiEmailnotification";


export function userAvatarMaint(
    me: any, 
    callbackFileSuccess: any,
    callbackFileFailure: any,
    callbackFnSuccess: any,
    callbackFnFailure: any,
    action: string,
    formData: any,
    file: any
) {
    return async (dispatch: any) => {
        Axios.post(
            action,
            formData,
            {
                responseType: "json",       
                headers: {
                    "content-type": "multipart/form-data" 
                }
            }
        )
        .then(response => {                
                callbackFileSuccess(response.data, file);
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.avatar.uploadId, response.data.data.avatar.url);
                }
            }
        )        
        .catch(function (error) {
                errorResponse(me, error, false);
                callbackFileFailure(error);
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    };
}    

export function usersCopySearchUsers(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    userId: string,
    searchText: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/users/okta", {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText,
                    administered: true,
                    page: 1,
                    pageSize: appConfigs.tables.pageSize
                }
            }
        )
        .then(response => {            
                let data = response.data.data.users === null ? [] : response.data.data.users;
                data.filter((user:any) => {
                    return user.userId !== userId;
                })
                .map((user:any) => ({
                    text: user.fullName,
                    textExtra: user.orgHomeName,
                    value: user.userId
                }));
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "USERS_GET_USERS_COPY_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function usersCopyMaint(
    me: any, 
    callbackSuccessFn: any,
    callbackErrorFn: any,
    copyFromUserId: string,
    copyToUserId: string,
    copyOrganizations: boolean,
    copySubscriptions: boolean
) {  
    return async (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL + "/ui/api/users/settings/maint",
            {
                copyFromUserId: copyFromUserId,
                copyToUserId: copyToUserId,
                copyOrganizations: copyOrganizations,
                copySubscriptions: copySubscriptions
            },
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
                if (callbackSuccessFn != null) {
                    callbackSuccessFn(response.data.data.user);
                }
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackErrorFn != null) {
                    callbackErrorFn();
                }
            }
        );
        
    };
}

export function getCountries(
    me: any, 
    callbackFnSuccess?: any,
    callbackFnFailure?: any
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/countries", {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.countries);
                }
                dispatch({ type: "USERS_COUNTRIES_SUCCESS", payload: {data: response.data.data.countries} });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
    };
}

export function userMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    record: any
) {
    
    return async (dispatch: any) => {
        Axios.post(
            appConfigs.server.URL + "/ui/api/users/okta",
            record,
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(record, response.data.data.users);
                    var url = appConfigs.okta.oidc.issuer;

                  
                    if(record.userId === undefined)
                        sendUserCreationNotification(response.data.data.users);
                    
                }
                dispatch({ type: "USERS_MAINT_SUCCESS", payload: {data: response.data.data.users} });
            }
        )
        .catch(function (error) {
                const errorStatus:number = !error.response ? 999 : (error.response.status || 999);
                let propagateError:boolean = true;

                if (errorStatus === 400) { 
                    if (error.response.data != null && error.response.data.errors != null && (error.response.data.errors.length || 0) !== 0) {
                        error.response.data.errors.forEach(function(record:any) {
                             if (record.message.toLowerCase().indexOf("parker okta") !== -1 && record.message.toLowerCase().indexOf("not okta users") !== -1) {
                                propagateError = false;
                                notification.error({
                                    duration: 0,
                                    message: "User Registration Issue",
                                    description: "Parker user not registered in Okta. Have user to register at " + appConfigs.okta.parkerUserRegstrationUrl + " Only after that request completed you will be able to maintain user information here."
                                });
                             }
                        });
                    }
                }
                if (propagateError && callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
    }
}

export async function sendUserCreationNotification(  
    responseObject: any   
) {   
    
    var  emailAddress = responseObject.email;
    var firstName = responseObject.firstName;   
    var lastName = responseObject.lastName;
    var orgId = responseObject.orgIdHome;    

    var emailTemplate = await getEmailTemplate(
        (responseObject.oktaTempPassword == null && emailAddress.trim().indexOf('@parker.com') == -1)
            ? 'account_recreation'
            : 'account_creation'
        , orgId, emailAddress);
    
    if(emailTemplate !=undefined)
    {
    
    var emailTemplateBody = emailTemplate.data.emailTemplate.emailTemplate;
    var orgLogo = emailTemplate.data.emailTemplate.orgLogo;
    var orgNameAndLoginUrl = emailTemplate.data.emailTemplate.orgName;
    var orgId = emailTemplate.data.emailTemplate.orgId;
    var tempPassword = responseObject.oktaTempPassword != null ? responseObject.oktaTempPassword : "Please contact administrator for temporary password";   
    var subject = emailTemplate.data.emailTemplate.emailSubject;
    var userName = firstName +' '+ lastName

       //commented out for fixing ops-1899
       //if(responseObject.oktaTempPassword == null)
       //{
       //     var tempPwd = await getTemporaryPassword(emailAddress);
       //     tempPassword =  tempPwd.data.user.tempPassword;
       //}
   
    const organdurl = orgNameAndLoginUrl.split('-');

    var url = window.config?.CDN_URL+ '/login/'+  organdurl[1];   

    if(emailAddress.trim().indexOf('@parker.com') !== -1 ) //true
           tempPassword = appConfigs.emailNotifyLogicApps.parker_AD_Password;

    var replacedBody = emailTemplateBody.replace("<logo>", orgLogo);
    replacedBody =  replacedBody.replace("temporaryPassword", tempPassword)
    .replace("url", url)
    .replace("emailAddress", emailAddress)
    .replace("userName", userName);  
   
    
       var emailSent = await sendEmail(orgId,
            emailAddress,
            (responseObject.oktaTempPassword == null && emailAddress.trim().indexOf('@parker.com') == -1)
                ? 'account_recreation'
                : 'account_creation',
            subject,
            replacedBody);
        if(emailTemplate == undefined )
        {
            errorResponse(emailAddress,  false);
        }
    }
    else
    {
        errorResponse(emailAddress,  false);
    }
}

