import React, {useState} from 'react';
import {Drawer, Button } from 'antd';
import { WidgetFilterConfig } from '.';
import Event from '../../../lib/event';
import FilterFormContainer from './FilterFormContainer';
import { appConfigs } from '../../../utils/configurations';

interface Props {  
  config?: WidgetFilterConfig;
  onFinish: (wfg: WidgetFilterConfig) => void;  
  title: string;
  visible: boolean;
  onClose: () => void;
  
}


const WidgetFilterDrawer: React.FC<Props> = (props) => {
  const [submitter] = useState(new Event());
  const drawerWidth:number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);

  return (
    <Drawer
      width={drawerWidth}
      placement="right"
      {...props}
      footer={
          
            <Button
            type="primary"
            htmlType="button"
            onClick={() => submitter.trigger()}
            >
            Save
            </Button>
      }
      onClose={props.onClose}
      destroyOnClose
    >
      <FilterFormContainer
        {...props}
        onFinish={(cfg) => {
          props.onFinish(cfg);
          props.onClose();          
        }}
        submitTrigger={submitter}
      />
    </Drawer>
  );
};

export default WidgetFilterDrawer;